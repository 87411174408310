export const QUICK_SETUP_NAVIGATION_PARAMS = {
  STEP: 'step',
  FLOW: 'flow',
  TEMPLATE: 'template',
  FLOW_NAME: 'flowName',
  MEETING_ID: 'meetingId',
  CALENDER_ID: 'calenderId',
  MEETING_NAME: 'meetingName',
};

export const QUICK_SETUP_FLOWS = {
  SURVEY_TEAM: 'survey_Team',
};
