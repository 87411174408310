import * as Yup from 'yup';

import { SETTINGS_EDIT_NAME_LANGUAGE } from '../../../languages/en/settings';

export const userNameValidationSchema = Yup.object().shape({
  userName: Yup.string()
    .min(3, SETTINGS_EDIT_NAME_LANGUAGE.MIN_LENGTH)
    .required(SETTINGS_EDIT_NAME_LANGUAGE.REQUIRE_NAME)
    .trim()
    .matches(/^.*\s.*$/, SETTINGS_EDIT_NAME_LANGUAGE.FIRST_LAST_NAME),
});
