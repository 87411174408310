import {
  NotebookSectionDetails,
  TaskCategories,
} from '../../../../interfaces/notebook';
import {
  TODAY,
  OVERDUE,
  UPCOMING,
  UNSCHEDULED,
  COMPLETED,
  ARCHIVED,
} from '../../../../languages/en/notebook';
import { capitalizeFirstLetter } from '../../../../Utils/text';

export const notebookSectionDetails: Record<
  TaskCategories,
  NotebookSectionDetails
> = {
  [TaskCategories.TODAY]: {
    title: capitalizeFirstLetter(TODAY),
    isSectionOpenOnLoad: true,
    isCreateTaskSectionShown: true,
  },
  [TaskCategories.OVERDUE]: {
    title: capitalizeFirstLetter(OVERDUE),
    isSectionOpenOnLoad: true,
    isCreateTaskSectionShown: true,
  },
  [TaskCategories.UPCOMING]: {
    title: capitalizeFirstLetter(UPCOMING),
    isSectionOpenOnLoad: true,
    isCreateTaskSectionShown: true,
  },
  [TaskCategories.UNSCHEDULED]: {
    title: capitalizeFirstLetter(UNSCHEDULED),
    isSectionOpenOnLoad: true,
    isCreateTaskSectionShown: true,
  },
  [TaskCategories.COMPLETED]: {
    title: capitalizeFirstLetter(COMPLETED),
    isSectionOpenOnLoad: false,
    isCreateTaskSectionShown: false,
  },
  [TaskCategories.ARCHIVED]: {
    title: capitalizeFirstLetter(ARCHIVED),
    isSectionOpenOnLoad: false,
    isCreateTaskSectionShown: false,
    isLastSection: true,
  },
};
