import React, { useCallback, useEffect, useState } from 'react';

import BasicModalTemplate from '../../templates/BasicModalTemplate';
import Divider from '../../atoms/Divider';
import {
  ModalContentWrapper,
  SearchFieldWrapper,
  SearchResults,
  UserListItem,
  NoOptionsTextWrapper,
} from './styles';
import TextField from '../TextField_V2';
import ThemeV2 from '../../../componentsV2/theme';
import useStringSearch from '../../../hooks/useSearchString';
import { UserListModalProps } from './interfaces';
import UserNameCard from '../UserNameCard';
import { ComponentStatus } from '../../../interfaces/component';
import { FeedPostUserProps } from '../../../interfaces/Feed';
import { isDeactivatedUser } from '../../../Utils/user';
import Body from '../../atoms/Body';
import { NO_MATCHING_OPTIONS } from '../../../languages/en/feed';

const stringSearchKeys = ['firstName', 'lastName'];

const UserListModal = ({
  handleClose,
  users,
  isOpen,
  onShowMoreUserClick,
  heading = 'Celebrated',
  placeholder = 'Search for celebrated members',
  className,
}: UserListModalProps) => {
  const [query, setQuery] = useState('');

  const { models: searchModels, operations: searchOperations } =
    useStringSearch({ data: users, keys: stringSearchKeys });
  const { searchResult } = searchModels;

  const handleTextFieldChange = useCallback((inputValue: string) => {
    setQuery(inputValue);
  }, []);

  const onUserClick = useCallback(
    (member: FeedPostUserProps) => () => {
      if (!isDeactivatedUser(member)) {
        onShowMoreUserClick(member);
      }
    },
    [onShowMoreUserClick],
  );

  useEffect(() => {
    searchOperations.search(query);
  }, [query, searchOperations]);

  return (
    <BasicModalTemplate
      childrenPadding="0px"
      heading={`${heading} (${users.length})`}
      isOpen={isOpen}
      onModalClose={handleClose}
      className={className}
    >
      <ModalContentWrapper>
        <SearchFieldWrapper>
          <TextField
            icon="search"
            onChange={handleTextFieldChange}
            placeholder={placeholder}
            value={query}
          />
        </SearchFieldWrapper>

        <Divider color={ThemeV2.palette.gray4} isFullWidth />

        <SearchResults>
          {searchResult.length > 0 ? (
            searchResult.map((user) => (
              <UserListItem key={user.memberID} onClick={onUserClick(user)}>
                <UserNameCard
                  status={ComponentStatus.LOADED}
                  firstName={user.firstName}
                  lastName={user.lastName}
                  image={user.image}
                  imageSize="medium"
                  key={user.memberID}
                  userId={user.memberID}
                  isDeactivatedUser={isDeactivatedUser(user)}
                  memberState={user.memberState}
                />
              </UserListItem>
            ))
          ) : (
            <NoOptionsTextWrapper justifyContent="center">
              <Body variant="body2">{NO_MATCHING_OPTIONS}</Body>
            </NoOptionsTextWrapper>
          )}
        </SearchResults>
      </ModalContentWrapper>
    </BasicModalTemplate>
  );
};

const MemoizedUserListModal = React.memo(UserListModal);
MemoizedUserListModal.displayName = 'UserListModal';
export default MemoizedUserListModal;
