import React from 'react';
import styled from 'styled-components';

import { ADMIN } from '../../../languages/en/home/mainFeedDrawer';
import Avatar from '../../atoms/Avatar';
import Body from '../../atoms/Body';
import SVGIcon from '../../atoms/SVGIcon';
import ThemeV2 from '../../../componentsV2/theme';
import useToggle from '../../../hooks/useToggle';
import { MemberState } from '../../../interfaces/user';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
  border-radius: 4px;
  margin-top: 4px;
  margin-bottom: 4px;

  &:hover {
    background-color: ${ThemeV2.palette.geekBlue1};
    cursor: pointer;
  }
`;

const StyledAvatar = styled(Avatar)`
  margin: 4px 8px 4px 8px;
`;

export interface MembersListEntryProps {
  fullName: string;
  id: string;
  isAdmin?: boolean;
  onClick: () => void;
  profilePicture?: string;
  memberState?: MemberState;
}

const MembersListEntry = ({
  fullName,
  id,
  isAdmin = false,
  onClick,
  profilePicture = '',
  memberState,
}: MembersListEntryProps) => {
  const {
    models: { toggleValue: isHovered },
    operations: { setToggleToFalse, setToggleToTrue },
  } = useToggle();

  return (
    <Wrapper
      data-testid="members-list-entry"
      onClick={onClick}
      onMouseEnter={setToggleToTrue}
      onMouseLeave={setToggleToFalse}
    >
      {memberState === MemberState.PENDING && (
        <StyledAvatar
          img={profilePicture}
          name={fullName}
          size="24px"
          userId={id}
          icon="pending-person"
        />
      )}
      {memberState !== MemberState.PENDING && (
        <StyledAvatar
          img={profilePicture}
          name={fullName}
          size="24px"
          userId={id}
        />
      )}
      <Body color={isHovered ? 'geekBlue6' : 'gray9'} variant="body3">
        {fullName}
      </Body>
      {isAdmin && (
        <>
          &nbsp; &middot; &nbsp;
          <SVGIcon
            color={
              isHovered ? ThemeV2.palette.geekBlue6 : ThemeV2.palette.gray7
            }
            icon="admin"
            size="16px"
          />
          <Body color={isHovered ? 'geekBlue6' : 'gray7'} variant="body3">
            {ADMIN}
          </Body>
        </>
      )}
    </Wrapper>
  );
};

const MemoizedMembersListEntry = React.memo(MembersListEntry);
MemoizedMembersListEntry.displayName = 'MembersListEntry';
export default MemoizedMembersListEntry;
