import React from 'react';

import ThemeV2 from '../../../componentsV2/theme';
import { defaultFlow } from '../../../Utils/flows';
import { mapHexCodeToEmoticon } from '../../../Utils/mappers';
import FlowsFeedHeader from '../../../atomic/molecules/FlowsFeedHeader';
import { CELEBRATE_YOUR_TEAMMATES } from '../../../languages/en/flows';

const RecognitionFeedHeaderController = () => {
  return (
    <>
      <FlowsFeedHeader
        title={defaultFlow.name}
        description={CELEBRATE_YOUR_TEAMMATES}
        subTitle={defaultFlow.description}
        backgroundColor={ThemeV2.palette.geekBlue2}
        icon={mapHexCodeToEmoticon(defaultFlow.icon.value)}
      />
    </>
  );
};

export default RecognitionFeedHeaderController;
