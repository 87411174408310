import styled from 'styled-components';
import Body from '../../atoms/Body';

import Button from '../../atoms/Button';
import Link from '../../atoms/Link';

export const StyledRewardsEmptyState = styled.div`
  text-align: center;
  max-width: 310px;
  padding-top: 56px;
  margin-left: auto;
  margin-right: auto;
`;

export const StyledRewardsEmptyStateImage = styled.img`
  margin-bottom: 20px;
`;

export const StyledRewardsEmptyStateTitle = styled(Body)`
  margin-bottom: 8px;
`;

export const StyledRewardsEmptyStateButton = styled(Button)`
  margin-top: 20px;
`;

export const StyledRewardsEmptyStateLink = styled(Link)`
  text-decoration: none;
  margin-top: 4px;
  display: inline-block;
`;
