import { useMemo } from 'react';
import add from 'date-fns/add';
import startOfMonth from 'date-fns/startOfMonth';
import format from 'date-fns/format';
import { CreditState } from '../../../interfaces/assembly';
import { UserStatus } from '../../../interfaces/user';
import {
  useProfileInfoFetchQuery,
  useRedeemInfoFetchQuery,
} from '../../../queries/Profile';
import {
  useDonationFetchQuery,
  useGiftCardFetchQuery,
} from '../../../queries/Rewards';
import { changeTimezone } from '../../../Utils/date';
import { canCurrentUserGiveAllowance, isUserAdmin } from '../../../Utils/user';
import { LEFT_DRAWER_ANALYTICS_EVENTS } from '../../../Utils/analytics/constants';
import { DEFAULT_REWARDS_QUERY_PARAMS } from '../../../Utils/data/rewards';
import {
  V2_CHARITY_REWARDS,
  V2_CULTURE_REWARDS,
  V2_GIFTCARD_REWARDS,
} from '../../../constants/routes';
import { trackLeftDrawerEvent } from '../../../Utils/analytics';

const useRewardsButtonController = () => {
  const { data: profileData } = useProfileInfoFetchQuery();
  const { data: redeemInfoData } = useRedeemInfoFetchQuery();

  const refreshDate = useMemo(() => {
    const timezoneDate = changeTimezone(
      new Date(),
      profileData?.assembly.timeZone,
    );

    return format(startOfMonth(add(timezoneDate, { months: 1 })), 'yyyy-MM-dd');
  }, [profileData]);

  const { data: giftCardsData } = useGiftCardFetchQuery(
    DEFAULT_REWARDS_QUERY_PARAMS.giftCards,
  );
  const { data: charityRewardsData } = useDonationFetchQuery(
    DEFAULT_REWARDS_QUERY_PARAMS.charitySortParams,
  );

  const hasGiftCardsSetup = useMemo(() => {
    if (giftCardsData && giftCardsData.rewards) {
      return giftCardsData.rewards.length > 0;
    }

    return false;
  }, [giftCardsData]);

  const hasCharitiesSetup = useMemo(() => {
    if (charityRewardsData && charityRewardsData.rewards) {
      return charityRewardsData.rewards.length > 0;
    }

    return false;
  }, [charityRewardsData]);

  let rewardType;

  if (hasGiftCardsSetup) {
    rewardType = V2_GIFTCARD_REWARDS;
  }

  if (!hasGiftCardsSetup && hasCharitiesSetup) {
    rewardType = V2_CHARITY_REWARDS;
  }

  if (!hasGiftCardsSetup && !hasCharitiesSetup) {
    rewardType = V2_CULTURE_REWARDS;
  }

  const trackLeftDrawerRedeemClick = () => {
    trackLeftDrawerEvent(
      LEFT_DRAWER_ANALYTICS_EVENTS.LEFT_DRAWER_EVENTS_REDEEM_CLICKED,
    );
  };

  const isObserver = profileData?.member.status === UserStatus.OBSERVER;

  const isRedeemable = redeemInfoData ? redeemInfoData.isRedeemable : false;

  const hideRewardsLink = () => {
    if (
      profileData?.assembly.accounts.creditState === CreditState.DENIED_BY_ADMIN
    ) {
      return true;
    }
    if (
      !profileData?.member ||
      (!isRedeemable && !isUserAdmin(profileData.member))
    ) {
      return true;
    }
    if (isUserAdmin(profileData.member)) {
      return false;
    }
    return !isRedeemable;
  };

  const hideAllowanceDetails = () => {
    if (!profileData?.member) {
      return true;
    }
    return !canCurrentUserGiveAllowance(profileData.member);
  };

  const hideRewardsInfo = () => {
    if (!profileData?.member) {
      return true;
    }
    return (
      (profileData.member.status === UserStatus.OBSERVER ||
        profileData.member.status === UserStatus.GIVER) &&
      profileData.member.pointsLeftThisCycle === 0
    );
  };

  return {
    currentUserData: profileData?.member,
    assemblyData: profileData?.assembly,
    isObserver: isObserver,
    hideRedeemButton: !isRedeemable,
    hideAllowanceDetails: hideAllowanceDetails(),
    hideRewardsLink: hideRewardsLink(),
    hideRewardsInfo: hideRewardsInfo(),
    refreshDate,
    rewardType: rewardType,
    trackLeftDrawerRedeemClick,
  };
};

export default useRewardsButtonController;
