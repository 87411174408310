import { MemberState } from '../user';

export interface AssemblyFromAPI {
  assemblyId: string;
  domain: string;
  logo: string;
  name: string;
}

export interface MemberFromAPI {
  email: string;
  reportIds: string[];
  managerIds: string[];
  memberId: string;
  totalPointsEarned: number;
  pointsEarned: number;
  totalPointsGiven: number;
  role: string[];
  profile: {
    birthday: BirthdayFromAPI;
    department: string;
    firstName: string;
    hiredday: HiredDayFromAPI;
    image: string;
    isManager: boolean;
    lastName: string;
    timezone: string;
    username: string;
    jobTitle: string;
    location: string;
  };
  timeZone?: string;
  state?: MemberState;
}

export interface BirthdayFromAPI {
  day?: number;
  month?: number;
}
export interface HiredDayFromAPI {
  day?: number;
  month?: number;
  year?: number;
}

export enum AssemblyPlanType {
  BUSINESS = 'Business',
  FREE = 'Free',
  TEAM = 'Team',
  ENTERPRISE = 'Enterprise',
}
