import React, { useEffect, useMemo } from 'react';
import { Redirect, useHistory } from 'react-router-dom';

import {
  ExternalParticipationTemplateError,
  LoadedParticipationTemplate as ParticipationTemplate,
  ParticipationTemplateError,
} from '../../../atomic/pages/ParticipationTemplate';

import { ComponentStatus } from '../../../interfaces/component';
import { profileData } from '../../../Utils/home/feeds/dummyData';
import { DynamicBlockState, FlowVariants } from '../../../interfaces/Flow';

import { SIGN_IN } from '../../../constants/routes';
import {
  ANONYMOUS_BANNER_TEXT,
  ARE_ANSWERING,
  ASSEMBLY_HOMEPAGE,
  EXTERNAL_PARTICIPATION_ERROR_DESCRIPTION,
  EXTERNAL_PARTICIPATION_ERROR_SCREENS_TEXTS,
  POST_ANONYMOUSLY,
} from '../../../languages/en/flows/participation';

import {
  ExternalFlowCreatorDetails,
  ExternalFlowDetailsResponse,
  useGetExternalFlowsQuery,
} from '../../../queries/Flows/ExternalFlows';

import useLoadedExternalFlowsController from './useLoadedExternalFlowsController';
import { FlowInstanceResponse } from '../../../queries/Flows/interfaces';
import { renderParticipationSlide, RenderSlideInfoObject } from '../utils';
import {
  StyledBodyFlowName,
  StyledBodyHeaderTitle,
  StyledEmoticon,
} from '../../../atomic/molecules/FlowsParticipationHeader/styles';
import { Flex } from '../../../Utils/styles/display';
import Body from '../../../atomic/atoms/Body';
import { mapHexCodeToEmoticon } from '../../../Utils/mappers';
import { YOU } from '../../../languages/en/home';
import Button from '../../../atomic/atoms/Button';
import {
  ExternalParticipationTemplateErrorTypes,
  ParticipationTemplateErrorTypes,
} from '../../../atomic/pages/ParticipationTemplate/types';
import ParticipationFlowHeader from '../../../atomic/molecules/FlowsParticipationHeader';
import { FLOW_NOT_FOUND } from '../../../languages/en/flows';
import FlowsParticipationFooter from '../../../atomic/molecules/FlowsParticipationFooter';
import styled from 'styled-components';
import { getErrorMessageAndType } from './useLoadedExternalFlowsController/util';
import useExternalFlowsController from './useExternalFlowsController';

const profileInfo = profileData;
const isAnonymousPost = true;
const isMutationLoading = false;

type LoadedExternalFlowsProps = {
  flowInstance: FlowInstanceResponse & ExternalFlowDetailsResponse;
  dynamicBlockData?: DynamicBlockState;
  creator: ExternalFlowCreatorDetails;
  flowId: string;
  identifier: string;
  errorType: string | undefined;
};

const ErrorTemplateWrapper = styled(Flex)`
  flex-grow: 1;
`;

const LoadedExternalFlows = (props: LoadedExternalFlowsProps) => {
  const { flowInstance, creator, flowId, identifier, errorType } = props;
  const { models, operations } = useLoadedExternalFlowsController({
    flowId,
    flow: flowInstance,
    creator,
    identifier,
    errorType,
  });

  const {
    stepData,
    values,
    currentStep,
    blockErrors,
    containerRef,
    dynamicBlockData,
    fieldErrors,
    isAnonymityEnabled,
    isPostDisabled,
    remainingAllowance,
    touched,
    uppyInstances,
    flowEndTime,
    submitErrors,
    loadingCaptcha,
    isSubmitInProgress,
  } = models;

  const {
    goToNextStep,
    goToPreviousStep,
    handleDeleteFileClick,
    handleModalClose,
    onFormCompleteClick,
    onNeedHelpButtonClick,
    onStepChange,
    setFieldValue,
  } = operations;

  const history = useHistory();

  useEffect(() => {
    // This is to hide the Google Captcha badge
    // https://developers.google.com/recaptcha/docs/faq#id-like-to-hide-the-recaptcha-badge.-what-is-allowed
    setTimeout(() => {
      const items = document.getElementsByClassName('grecaptcha-badge');
      if (items?.length) {
        items[0].setAttribute('style', 'visibility: hidden; position: fixed;');
      }
    }, 500);
  }, [loadingCaptcha]);

  const footerButtons: JSX.Element[] = [
    <Button
      disabled={isPostDisabled}
      isLoading={isMutationLoading || isSubmitInProgress}
      onClick={onFormCompleteClick}
      size="large"
      key="1"
    >
      {POST_ANONYMOUSLY}
    </Button>,
  ];

  const flowHeaderContent = useMemo(() => {
    return {
      TitleContent: (
        <Flex>
          <StyledBodyHeaderTitle color="gray8" variant="body2">
            <Body color="geekblue6" inline variant="body2Medium">
              {`${YOU} `}
            </Body>
            {`${ARE_ANSWERING} `}
          </StyledBodyHeaderTitle>
          <StyledEmoticon>
            {mapHexCodeToEmoticon(flowInstance.icon.value)}
          </StyledEmoticon>
          <StyledBodyFlowName color="geekBlue6" inline variant="body2Medium">
            {flowInstance.name}
          </StyledBodyFlowName>
        </Flex>
      ),
      DescriptionContent: flowInstance.description,
    };
  }, [flowInstance.description, flowInstance.icon.value, flowInstance.name]);

  const footerButtonsForError: JSX.Element[] = [
    <Button
      disabled={false}
      isLoading={false}
      onClick={() => {
        history.push('/home');
      }}
      size="large"
      key="1"
      color={submitErrors?.buttonType}
    >
      {ASSEMBLY_HOMEPAGE}
    </Button>,
  ];

  if (submitErrors) {
    return (
      <Flex flexDirection="column" height="100vh">
        <ParticipationFlowHeader
          onCloseModal={() => {}}
          status={ComponentStatus.LOADED}
          member={profileData.member}
          isAnonymous
          showCloseModalOption={false}
          flowHeaderContent={
            submitErrors?.errorMessage ===
            ExternalParticipationTemplateErrorTypes.ACTIVE_OCCURRENCE_ENDED
              ? flowHeaderContent
              : {
                  TitleContent: (
                    <Flex>
                      <StyledBodyHeaderTitle color="gray8" variant="body2">
                        {`${FLOW_NOT_FOUND} `}
                      </StyledBodyHeaderTitle>
                    </Flex>
                  ),
                  DescriptionContent: EXTERNAL_PARTICIPATION_ERROR_DESCRIPTION,
                }
          }
        />
        <ErrorTemplateWrapper>
          {submitErrors?.type === 'external' ? (
            <ExternalParticipationTemplateError
              externalTemplateError={
                submitErrors?.errorMessage as ExternalParticipationTemplateErrorTypes
              }
            />
          ) : (
            <ParticipationTemplateError
              member={profileInfo.member}
              onCloseModal={() => {}}
              flowId={flowId}
              templateError={
                submitErrors?.errorMessage as ParticipationTemplateErrorTypes
              }
              headerStatus={ComponentStatus.LOADED}
              flowHeaderContent={{
                TitleContent: <></>,
                DescriptionContent: '',
              }}
              returnOnlyContent
            />
          )}
        </ErrorTemplateWrapper>
        <FlowsParticipationFooter
          status={ComponentStatus.LOADED}
          hideNavigationButtons
          shouldDisplayHelpButton={false}
          footerButtons={footerButtonsForError}
          creator={creator}
          isExternalFlow
          isArrowKeyNavigationSupported={false}
        />
      </Flex>
    );
  }

  const renderSlideInfo: RenderSlideInfoObject = {
    blockErrors,
    currentStep,
    fieldErrors,
    flowInstance,
    goToNextStep,
    handleDeleteFileClick,
    onStepChange,
    values,
    profileInfo,
    remainingAllowance,
    setFieldValue,
    stepData,
    touched,
    uppyInstances,
    isPreviewFlow: false,
    flowId,
    isExternalFlow: true,
    flowEndTime,
  };

  return (
    <ParticipationTemplate
      headerStatus={ComponentStatus.LOADED}
      currentStep={currentStep}
      goToNextStep={goToNextStep}
      goToPreviousStep={goToPreviousStep}
      isNextButtonDisabled={currentStep === stepData.length - 1}
      member={profileData.member}
      isPreviousButtonDisabled={currentStep === 0}
      onCloseModal={handleModalClose}
      footerButtons={footerButtons}
      onStepChange={onStepChange}
      dynamicBlockData={dynamicBlockData}
      singleSlideContainerRef={containerRef}
      flowVariant={FlowVariants.EXTERNAL_FLOW}
      flowId={flowId}
      onNeedHelpButtonClick={onNeedHelpButtonClick}
      flowHeaderContent={flowHeaderContent}
      bannerProps={
        isAnonymityEnabled || isAnonymousPost
          ? {
              bannerIcon: 'anonymous',
              bannerText: ANONYMOUS_BANNER_TEXT,
            }
          : undefined
      }
      isFullScreen
      creator={creator}
    >
      {renderParticipationSlide(renderSlideInfo)}
    </ParticipationTemplate>
  );
};

export const ErroredExternalFlows = (props: {
  flowId: string;
  errorType: string | undefined;
}) => {
  const { errorType, flowId } = props;
  const { data: externalFlowData } = useGetExternalFlowsQuery(flowId);

  const submitErrors = useMemo(
    () => getErrorMessageAndType(errorType || ''),
    [errorType],
  );

  const history = useHistory();

  const flowHeaderContent = useMemo(() => {
    return {
      TitleContent: (
        <Flex>
          <StyledBodyHeaderTitle color="gray8" variant="body2">
            {`${EXTERNAL_PARTICIPATION_ERROR_SCREENS_TEXTS.ACTIVE_OCCURRENCE_ENDED_HEADING}`}
          </StyledBodyHeaderTitle>
        </Flex>
      ),
      DescriptionContent:
        EXTERNAL_PARTICIPATION_ERROR_SCREENS_TEXTS.ACTIVE_OCCURRENCE_ENDED_SUB_HEADING,
    };
  }, [
    externalFlowData?.data.flow.description,
    externalFlowData?.data.flow.icon.value,
    externalFlowData?.data.flow.name,
  ]);

  const footerButtonsForError: JSX.Element[] = [
    <Button
      disabled={false}
      isLoading={false}
      onClick={() => {
        history.push('/home');
      }}
      size="large"
      key="1"
      color={submitErrors?.buttonType}
    >
      {ASSEMBLY_HOMEPAGE}
    </Button>,
  ];

  return (
    <Flex flexDirection="column" height="100vh">
      <ParticipationFlowHeader
        onCloseModal={() => {}}
        status={ComponentStatus.LOADED}
        member={profileData.member}
        isAnonymous
        showCloseModalOption={false}
        flowHeaderContent={
          submitErrors?.errorMessage ===
          ExternalParticipationTemplateErrorTypes.NO_ACTIVE_OCCURRENCE_FOUND
            ? flowHeaderContent
            : {
                TitleContent: (
                  <Flex>
                    <StyledBodyHeaderTitle color="gray8" variant="body2">
                      {`${FLOW_NOT_FOUND} `}
                    </StyledBodyHeaderTitle>
                  </Flex>
                ),
                DescriptionContent: EXTERNAL_PARTICIPATION_ERROR_DESCRIPTION,
              }
        }
      />
      <ErrorTemplateWrapper>
        {submitErrors?.type === 'external' ? (
          <ExternalParticipationTemplateError
            externalTemplateError={
              submitErrors?.errorMessage as ExternalParticipationTemplateErrorTypes
            }
          />
        ) : (
          <ParticipationTemplateError
            member={profileInfo.member}
            onCloseModal={() => {}}
            flowId={flowId}
            templateError={
              submitErrors?.errorMessage as ParticipationTemplateErrorTypes
            }
            headerStatus={ComponentStatus.LOADED}
            flowHeaderContent={{
              TitleContent: <></>,
              DescriptionContent: '',
            }}
            returnOnlyContent
          />
        )}
      </ErrorTemplateWrapper>
      <FlowsParticipationFooter
        status={ComponentStatus.LOADED}
        hideNavigationButtons
        shouldDisplayHelpButton={false}
        footerButtons={footerButtonsForError}
        isExternalFlow
        isArrowKeyNavigationSupported={false}
      />
    </Flex>
  );
};

const ExternalFlowsController = () => {
  const {
    flowId,
    isFlowAuthorizationError,
    externalFlowData,
    validationError,
  } = useExternalFlowsController();

  if (isFlowAuthorizationError && validationError?.code !== 409) {
    return <Redirect to={SIGN_IN} />;
  }

  if (validationError?.code === 409 && !externalFlowData) {
    return (
      <ErroredExternalFlows
        flowId={flowId}
        errorType={validationError?.message}
      />
    );
  }

  if (externalFlowData) {
    return (
      <LoadedExternalFlows
        flowInstance={externalFlowData?.data?.flow}
        creator={externalFlowData?.data?.creator}
        flowId={flowId}
        identifier={externalFlowData?.data?.identifier}
        errorType={validationError?.message}
      />
    );
  }

  return null;
};

export default ExternalFlowsController;
