import React, { SyntheticEvent } from 'react';

import { AutocompleteDropdownItem } from '../../../organism/Autocomplete/interfaces';
import FlowsBaseInputBlock from '../FlowsBaseInputBlock';
import NavigationInstructions from '../../FlowsInputBlockNavigationInstructions';
import { SingleSelectAutoComplete } from '../../../organism/Autocomplete';

export interface FlowsDropdownInputBlockProps<
  T extends string | number,
  U = undefined,
> {
  blockError?: string;
  fieldError?: string;
  description?: string;
  isLastBlock?: boolean;
  goToNextStep: () => void;
  isRequired: boolean;
  subDescription?: string;
  options: AutocompleteDropdownItem<T, U>[];
  onChange: (val: AutocompleteDropdownItem<T, U> | null) => void;
  title: string;
  value: AutocompleteDropdownItem<T, U> | null;
  inputLabel?: string;
  textboxValue?: string;
  onTextboxValueChange?: (value: string) => void;
  loading?: boolean;
  onOptionsScroll?: () => void;
  isPreviewFlow?: boolean;
}

function FlowsDropdownInputBlock<T extends string | number, U>({
  description,
  goToNextStep,
  isRequired,
  subDescription,
  onChange,
  options,
  title,
  value,
  inputLabel,
  blockError,
  fieldError,
  textboxValue,
  onTextboxValueChange,
  loading,
  onOptionsScroll,
  isPreviewFlow,
  isLastBlock = false,
}: FlowsDropdownInputBlockProps<T, U>) {
  const handleOptionsScroll = (event: SyntheticEvent) => {
    const listboxNode = event.currentTarget;
    if (
      listboxNode.scrollHeight * 0.7 <=
      listboxNode.scrollTop + listboxNode.clientHeight
    ) {
      if (onOptionsScroll) {
        onOptionsScroll();
      }
    }
  };

  return (
    <FlowsBaseInputBlock
      description={description}
      navigationInstructions={
        <NavigationInstructions
          type={isLastBlock ? 'last+enter' : 'enter'}
          goToNextStep={goToNextStep}
          isPreviewFlow={isPreviewFlow}
        />
      }
      isRequired={isRequired}
      subDescription={subDescription}
      title={title}
      blockError={blockError}
      fieldError={fieldError}
    >
      <SingleSelectAutoComplete
        autoFocus
        label={inputLabel || 'Select an option(s)'}
        onChange={onChange}
        options={options}
        value={value}
        multiple={false}
        textboxSize="large"
        textboxValue={textboxValue}
        onTextboxValueChange={onTextboxValueChange}
        loading={loading}
        ListboxProps={{
          onScroll: handleOptionsScroll,
        }}
      />
    </FlowsBaseInputBlock>
  );
}

export default FlowsDropdownInputBlock;
