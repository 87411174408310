// @ts-nocheck TODO: type issues need to be fixed in this file
import _ from 'lodash';
import moment from 'moment';
import getter from './objectUtils/getter';
import { getMonthFromDateObject } from './date';

export const checkAdmin = (role) => {
  let isAdmin = false;
  if (role && role.find((rol) => rol === 'Admin' || rol === 'Owner')) {
    isAdmin = true;
  }
  return isAdmin;
};

export const checkSuperAdmin = (user) => {
  let isAdmin = false;
  if (user) {
    isAdmin = checkAdmin(user.role);
  }
  if (isAdmin === true && user.email) {
    isAdmin = /@joinassembly\.com|@carrothr\.com/.test(user.email);
  }
  return isAdmin;
};

export const checkManager = (user) =>
  _.get(user, ['profile', 'isManager'], false);

export const getUserDepartment = (user) =>
  _.get(user, ['profile', 'department'], 'false');

export const hasRewardsPayments = (employer) => {
  if (!employer) {
    return false;
  }
  const isChargeable =
    getter(['accounts', 'rewards', 'isChargeable'], employer) || false;
  return isChargeable;
};
export const hasRewardsPaymentsCompleted = (employer) => {
  if (!employer) {
    return false;
  }
  const isComplete =
    getter(['accounts', 'rewards', 'isComplete'], employer) || false;
  return isComplete;
};

export const hasPlatformPayments = (employer) => {
  if (!employer) {
    return false;
  }
  const cardStatus =
    getter(['accounts', 'stripe', 'subscription', 'status'], employer) || false;
  return cardStatus;
};

export const checkOwner = (role) => {
  let isOwner = false;
  if (role && role.find((rol) => rol === 'Owner')) {
    isOwner = true;
  }
  return isOwner;
};

export const checkIfProduction = () =>
  process.env.REACT_APP_STAGE === 'production';

export const canReceiveCarrots = (user) => {
  const { profile } = user;
  if (profile) {
    if (profile.status === 'observer' || profile.status === 'giver') {
      return false;
    }
  }
  return true;
};
export const canGiveCarrots = (user) => {
  const { profile } = user;
  if (profile) {
    if (profile.status === 'observer' || profile.status === 'receiver') {
      return false;
    }
  }
  return true;
};
export const ifUserObserver = (user) => {
  const { profile } = user;
  if (profile) {
    if (profile.status === 'observer') {
      return true;
    }
  }
  return false;
};

export const getAllowanceValueForUser = (user, employer) => {
  const { allowanceType } = user.profile;
  if (!employer) {
    return 0;
  }
  if (allowanceType === 'regular') {
    return employer.regularAllowance;
  }
  if (allowanceType === 'custom') {
    return user.profile.customAllowance;
  }
  return 0;
};

export const getAllowanceTypeLiteralForUser = (user) => {
  const allowanceType = _.get(user, ['profile', 'allowanceType'], '');
  if (allowanceType === 'regular') {
    return 'Default';
  }
  if (allowanceType === 'custom') {
    return 'Custom';
  }
  return 'No Allowance';
};

export const getUserStatusTypeLiteralForUser = (user) => {
  return _.get(user, ['profile', 'status'], 'normal');
};
export const getUserTotalPointsGiven = (user) => {
  return _.get(user, ['totalPointsGiven'], 0);
};

export const getUserBirthday = (user) => {
  if (user.profile.birthday) {
    const birthDay = user.profile.birthday.date;
    const birthMonth = getMonthFromDateObject(user.profile.birthday);
    if (birthDay && birthMonth) {
      return `${birthMonth}, ${birthDay}`;
    }
  }
  return 'NA';
};

export const getUserAnniversary = (user) => {
  if (user.profile.hiredday) {
    const hireDay = user.profile.hiredday.date;
    const hireMonth = getMonthFromDateObject(user.profile.hiredday);
    const hireYear = user.profile.hiredday.year;
    if (hireDay && hireMonth && hireYear) {
      return moment(
        `${hireMonth} ${hireDay} ${hireYear}`,
        'MMMM D YYYY',
      ).format('MM/D/YYYY');
    }
  }
  return 'NA';
};

export const getDontShowClickedOnSlackSetupModal = () =>
  JSON.parse(localStorage.getItem('dontShowClickedOnSlackSetupModal'));

export const setDontShowClickedOnSlackSetupModal = (value) => {
  localStorage.setItem('dontShowClickedOnSlackSetupModal', value);
};

export const getDontShowQuickSetupProgress = () =>
  JSON.parse(localStorage.getItem('dontShowQuickSetupProgress'));

export const setDontShowQuickSetupProgress = (value) => {
  localStorage.setItem('dontShowQuickSetupProgress', value);
};
