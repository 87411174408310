import styled from 'styled-components';
import ThemeV2 from '../../../componentsV2/theme';
import { iconButtonColors } from '../Button/buttonConfigs';
import { StylesComponentsProps } from './interface';

const ButtonSize = {
  large: '48px',
  normal: '32px',
  small: '24px',
};

const noShadow = (color: string) => {
  return (
    color !== 'text' &&
    color !== 'textWithBackground' &&
    color !== 'tertiary' &&
    color !== 'selectedTertiary'
  );
};

export const IconButtonRoot = styled.button<StylesComponentsProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${(props) => ButtonSize[props.size || 'normal']};
  width: ${(props) => ButtonSize[props.size || 'normal']};
  line-height: ${(props) => ButtonSize[props.size || 'normal']};
  border-radius: ${(props) => (props.rounded ? '20px' : '4px')};
  border: none;
  outline: none;
  cursor: pointer;
  box-shadow: ${({ color, isSelected }) =>
    noShadow(color) &&
    (isSelected
      ? '0px 0px 2px rgba(0, 0, 0, 0.04), 0px 2px 2px rgba(0, 0, 0, 0.02), 0px 1px 3px rgba(0, 0, 0, 0.12)'
      : '0px 2px 4px rgba(0, 0, 0, 0.04), 0px 3px 4px rgba(0, 0, 0, 0.02), 0px 1px 5px rgba(0, 0, 0, 0.12)')};
  background: ${({ isSelected, color }) =>
    isSelected
      ? iconButtonColors[color].focus.background
      : iconButtonColors[color].default.background};
  transition: 0.2s ease;
  transition-property: box-shadow background;
  & span svg path {
    fill: ${({ isSelected, color, icon }) => {
      if (icon === 'more' && !isSelected) {
        return ThemeV2.palette.gray6;
      }
      return isSelected
        ? iconButtonColors[color].focus.color
        : iconButtonColors[color].default.color;
    }};
  }
  &:disabled {
    background: ${({ iconBgColor }) => iconBgColor || ThemeV2.palette.gray3};
    cursor: default;
    box-shadow: none;
    & span svg path {
      fill: ${ThemeV2.palette.gray6};
    }
  }
  &:not(:disabled):hover,
  &:not(:disabled):focus-visible {
    background: ${({ isSelected, color }) =>
      isSelected
        ? iconButtonColors[color].focus.background
        : iconButtonColors[color].hover.background};
    box-shadow: ${({ color, isSelected }) =>
      noShadow(color) &&
      !isSelected &&
      '0px 8px 10px rgba(0, 0, 0, 0.04), 0px 3px 14px rgba(0, 0, 0, 0.02), 0px 4px 5px rgba(0, 0, 0, 0.12)'};
    & span svg path {
      fill: ${({ isSelected, color }) =>
        isSelected
          ? iconButtonColors[color].focus.color
          : iconButtonColors[color].hover.color};
    }
  }
  &:not(:disabled):active {
    background: ${({ color }) => iconButtonColors[color].focus.background};
    box-shadow: ${({ color }) =>
      noShadow(color) &&
      '0px 0px 2px rgba(0, 0, 0, 0.04), 0px 2px 2px rgba(0, 0, 0, 0.02), 0px 1px 3px rgba(0, 0, 0, 0.12)'};
    & span svg path {
      fill: ${({ color }) => iconButtonColors[color].focus.color};
    }
  }
`;
IconButtonRoot.displayName = 'IconButtonRoot';
