import ToolTip from '../ToolTipV2';
import styled from 'styled-components';
import ThemeV2 from '../../../componentsV2/theme';
import ClickableLink from '../ClickableLink';

export const StyledClickableLink = styled(ClickableLink)`
  margin-left: 4px;
`;

export const StyledFlowsPostHeaderContent = styled.div`
  padding: 0 8px;
`;

export const StyledVisibilityIcon = styled(ToolTip)`
  position: relative;
  display: flex;
  margin-left: 12px;
  &:before {
    content: '';
    position: absolute;
    top: 6px;
    left: -8px;
    height: 4px;
    width: 4px;
    border-radius: 4px;
    background: ${ThemeV2.palette.gray7};
  }
`;
