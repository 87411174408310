import { PopoverItemProps } from '../../../atomic/organism/PopoverWithSubMenu/types';

import {
  CriteriaRule,
  CriteriaField,
  CriteriaOperator,
} from '../../../interfaces/Flow/Builder';

const popoverItems: PopoverItemProps[] = [
  {
    id: 'member',
    title: 'Member',
    icon: 'person-filled',
  },
  {
    id: 'email',
    title: 'Email',
    icon: 'mail',
  },
  {
    id: 'department',
    title: 'Department',
    icon: 'department',
  },
];

export const defaultPopoverItems: PopoverItemProps[] = [
  {
    id: 'everyone',
    title: 'Entire organization',
    icon: 'assembly',
  },
  ...popoverItems,
];

export const popOverItemsWithDisabledEveryone: PopoverItemProps[] = [
  {
    id: 'everyone',
    title: 'Entire organization',
    icon: 'assembly',
    disabled: true,
  },
  ...popoverItems,
];

export const getCriteriaFieldIcon = (criteriaField: CriteriaField) => {
  switch (criteriaField) {
    case 'department':
      return 'department';
    case 'email':
      return 'mail';
    case 'member':
      return 'person-filled';
    case 'everyone':
    default:
      return 'assembly';
  }
};

export const getCriteriaCondition = (criteriaOperator: CriteriaOperator) => {
  if (criteriaOperator === 'isNot') {
    return 'is not';
  }

  return 'is';
};

export const getCriteriaValue = (
  criteriaRule: CriteriaRule,
  defaultCriteriaValue: { id: string; value: string },
) => {
  if (criteriaRule.field === 'everyone') {
    return '';
  }

  return defaultCriteriaValue.value;
};
