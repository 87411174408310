import { useState, useEffect } from 'react';
import { repeatOnDayList } from './data';
import { DayId, RepeatEvery, UseCustomRecurrenceProps } from './types';

const useCustomRecurrence = (props: UseCustomRecurrenceProps) => {
  const {
    defaultDayIds,
    repeatEveryCountDefault,
    repeatEveryDefault,
    selectedMonthlyOnOptionDefault,
    monthlyOnOptions,
  } = props;
  const [repeatEveryCount, setRepeatEveryCount] = useState(
    repeatEveryCountDefault,
  );
  const [repeatEvery, setRepeatEvery] = useState(repeatEveryDefault);
  const [selectedMonthlyOnOption, setSelectedMonthlyOnOption] = useState(
    selectedMonthlyOnOptionDefault,
  );
  const [repeatOnMonth, setRepeatOnMonth] = useState<string>(
    monthlyOnOptions[0].items[0].id.toString(),
  );

  const daysWithDefaultDay = [...repeatOnDayList].map((item) =>
    defaultDayIds.includes(item.id) ? { ...item, isSelected: true } : item,
  );

  const [repeatOnDays, setRepeatOnDays] = useState([...daysWithDefaultDay]);

  const handleRepeatCountChange = (value: number) => {
    setRepeatEveryCount(value);
  };

  const handleRepeatEveryChange = (value: string | number) => {
    setRepeatEvery(value as RepeatEvery);
  };

  const handleRepeatOnDayClick = (id: DayId) => {
    const newRepeatOnDays = [...repeatOnDays];
    const currentItemIndex = newRepeatOnDays.findIndex(
      (item) => item.id === id,
    );
    if (currentItemIndex !== -1) {
      newRepeatOnDays[currentItemIndex].isSelected =
        !newRepeatOnDays[currentItemIndex].isSelected;
    }

    if (newRepeatOnDays.filter((item) => item.isSelected).length === 0) {
      setRepeatOnDays(daysWithDefaultDay);
    } else {
      setRepeatOnDays(newRepeatOnDays);
    }
  };

  const handleMonthlyOnOptionClick = (id: string | number) => {
    const selectedItem = monthlyOnOptions[0].items.find(
      (item) => item.id === id,
    );

    if (selectedItem) {
      setSelectedMonthlyOnOption(selectedItem.value as string);
      setRepeatOnMonth(selectedItem?.id.toString());
    }
  };

  useEffect(() => {
    if (selectedMonthlyOnOptionDefault) {
      setSelectedMonthlyOnOption(selectedMonthlyOnOptionDefault);
    }
  }, [selectedMonthlyOnOptionDefault]);

  return {
    models: {
      repeatEveryCount,
      repeatEvery,
      selectedMonthlyOnOption,
      repeatOnDays,
      repeatOnMonth,
    },
    operations: {
      handleRepeatCountChange,
      handleRepeatEveryChange,
      handleRepeatOnDayClick,
      handleMonthlyOnOptionClick,
    },
  };
};

export default useCustomRecurrence;
