import React, { useEffect, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import shallow from 'zustand/shallow';

import { HeaderType } from '../../../atomic/molecules/PageHeader/interfaces';
import Loader from '../Loader';
import PageHeader from '../../../atomic/molecules/PageHeader';
import {
  useGetFlowResponseQuery,
  useGetSinglePostQuery,
} from '../../../queries/Feed';
import { getFullName } from '../../../Utils/user';
import { getAgoTime } from '../../../Utils/time';
import {
  POST_BY,
  POST_NOT_FOUND,
  POST_SUBHEADER,
} from '../../../languages/en/header';
import { PostTypes } from '../../../interfaces/Home';
import { ANNIVERSARY_TEXT, BIRTHDAY_TEXT } from '../../../languages/en/feed';
import Error from '../../../atomic/molecules/PageHeader/Error';
import useLayoutStore from '../../../stores/layoutStore';
import { ANONYMOUS } from '../../../languages/en/singleWords';

const SingleFlowFeedPostHeaderController = () => {
  const { postType } = useParams<{ postType: string }>();
  const { search } = useLocation();
  const { isRightAsideOpen, toggleRightAsideOpen } = useLayoutStore(
    (state) => ({
      isRightAsideOpen: state.isRightAsideOpen,
      toggleRightAsideOpen: state.toggleRightAsideOpen,
    }),
    shallow,
  );

  useEffect(() => {
    if (isRightAsideOpen) {
      toggleRightAsideOpen();
    }
    // Leaving the field blank here as we want this function to only run once on initial render and not again afterwards
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let postId = '';
  let flowId = '';
  let responseId = '';

  if (postType === 'recognition') {
    postId = new URLSearchParams(search).get('postId') || '';
  }
  if (postType === 'flows') {
    flowId = new URLSearchParams(search).get('flowId') || '';
    responseId = new URLSearchParams(search).get('responseId') || '';
  }
  const isFlowPost = postType === 'flows';
  const legacySinglePostQuery = useGetSinglePostQuery(postId, !isFlowPost);
  const flowFeedPostQuery = useGetFlowResponseQuery(
    flowId,
    responseId,
    isFlowPost,
  );
  const pageTitle = useMemo(() => {
    if (!legacySinglePostQuery.data) {
      return '';
    }
    if (legacySinglePostQuery.data.type === PostTypes.ANNIVERSARY) {
      return ANNIVERSARY_TEXT;
    }
    if (legacySinglePostQuery.data.type === PostTypes.BIRTHDAY) {
      return BIRTHDAY_TEXT;
    }
    return `${POST_BY} ${getFullName(legacySinglePostQuery.data.from)}`;
  }, [legacySinglePostQuery.data]);

  if (!isFlowPost && legacySinglePostQuery.isLoading) {
    return <Loader />;
  }

  if (isFlowPost && flowFeedPostQuery.isLoading) {
    return <Loader />;
  }

  if (
    (legacySinglePostQuery.isError && postId) ||
    (flowFeedPostQuery.isError && flowId && responseId)
  ) {
    return <Error title={POST_NOT_FOUND} subTitle={POST_SUBHEADER} />;
  }

  return (
    <PageHeader
      headerType={HeaderType.OTHER}
      icon="post"
      title={
        flowFeedPostQuery.data
          ? `${POST_BY} ${
              flowFeedPostQuery.data.respondent === null
                ? ANONYMOUS
                : getFullName(flowFeedPostQuery.data.respondent)
            }`
          : pageTitle
      }
      subTitle={
        flowFeedPostQuery.data
          ? getAgoTime(flowFeedPostQuery.data.createdAt)
          : getAgoTime(legacySinglePostQuery?.data?.createdAt)
      }
      showMainFeedLinkInOthers
    />
  );
};

export default SingleFlowFeedPostHeaderController;
