import { InfiniteData } from 'react-query';

import { getFormattedMessage } from '../../message';
import { PostTypes } from '../../../interfaces/Home';
import { ANONYMOUS } from '../../../languages/en/singleWords';
import {
  defaultCurrency,
  getAssemblyCurrency,
  getCanDeletePosts,
} from '../../../queries/Profile/utils';
import { GetProfileInfoResponse } from '../../../queries/Profile';
import { RECOGNITION_POST_LABELS } from '../../../languages/en/flows/feed';
import { AssemblyCurrencyIconType } from '../../../atomic/atoms/AssemblyCurrencyIcon/interface';

import {
  FeedItemFromAPI,
  GetFeedResponse,
  MemberInteractionType,
  FeedProps,
} from '../../../interfaces/Feed';

import { BlockProps } from '../../../atomic/organism/FlowPostBody/types';

import {
  DropDownBlockResponseType,
  FeedResponse,
  FileUploadResponseType,
  FlowFeedResponse,
  GifBlockResponseType,
  GiveTrophiesBlockResponseType,
  KeyValuePair,
  OpenEndedBlockResponseType,
  PersonSelectorBlockResponseType,
  ScaleBlockResponseType,
} from '../../../queries/Flows/Feed/interfaces';

import {
  LegacyFileType,
  FileUploadStatus,
} from '../../../atomic/molecules/FileCard/types';
import { copyToClipboard } from '../../common';
import { getProfileFullName } from '../../user';
import { showSuccessMessage } from '../../toast';
import { COPIED_TO_CLIPBOARD } from '../../../languages/en/flows';
import { mapRecognitionPostToReactionProps } from '../../../controllers/flows/FlowsFeedController/utils';
import { ReactionType } from '../../../interfaces/Feed/Reaction';
import { MemberState } from '../../../interfaces/user';

import ThemeV2 from '../../../componentsV2/theme';

export const checkIfFlowPostHasTrophies = (flowPost: FlowFeedResponse) => {
  let hasTrophies = false;
  flowPost.responses.forEach((response) => {
    if (response.type === 'GIVE_POINTS_STACK') {
      hasTrophies = response.response?.value > 0;
    }
  });
  return hasTrophies;
};

const getCurrencyFromProfileData = (profile: GetProfileInfoResponse) => {
  let emoticon: AssemblyCurrencyIconType = {
    id: defaultCurrency.name,
    currencyName: defaultCurrency.name,
    ...defaultCurrency,
  };

  if (profile) {
    emoticon = {
      id: profile.assembly.currency.name,
      assemblyCurrency: profile.assembly.currency,
      currencyName: profile.assembly.currency.name,
    };
  }
  return emoticon;
};

const mapFilesResponseToFiles = (feed: FeedResponse, ImageTypes: string[]) => {
  return feed.response.files.map((file) => {
    if (ImageTypes.includes(file.type) && file.thumbnails) {
      return {
        ...file,
        url: file.thumbnails[360],
        type: LegacyFileType.Image,
      };
    }

    return {
      ...file,
      type: LegacyFileType.File,
    };
  });
};

export const getFlowPostUrl = (flowId: string, responseId: string) => {
  return `${window.location.origin}/post/flow/flows?flowId=${flowId}&responseId=${responseId}`;
};

export const getRecognitionPostUrl = (postId: string) => {
  return `${window.location.origin}/post/flow/recognition?postId=${postId}`;
};

export const getFlowHeaderAttributesFromData = (
  data: FeedItemFromAPI,
  profile: GetProfileInfoResponse,
) => {
  const isFlowPost = data.type === PostTypes.FLOW && data.flowResponse;
  const createdAt = data.createdAt.toString();
  const postType = isFlowPost ? PostTypes.FLOW : data.type;
  const currentUserId = profile.member.memberId;
  const person =
    isFlowPost && data.flowResponse
      ? data.flowResponse.respondent
        ? {
            ...data.flowResponse.respondent,
            isDeleted: false,
            memberState:
              data.flowResponse.respondent.memberState === 'ACTIVE'
                ? MemberState.ACTIVE
                : MemberState.DEACTIVATED,
          }
        : null
      : data.from
      ? {
          ...data.from,
          memberState:
            data.from?.memberState === 'ACTIVE'
              ? MemberState.ACTIVE
              : MemberState.DEACTIVATED,
        }
      : null;
  const visibility =
    data.flowResponse?.isPrivate || data.isPrivate
      ? 'private'
      : data.flowResponse?.visibility;

  const flow =
    isFlowPost && data.flowResponse
      ? data.flowResponse.flow
      : {
          flowId: 'recognition',
          name: 'Celebrate a Teammate',
          icon: { kind: 'HEX_CODE', value: '1F389' },
          kind: '',
          version: 0,
        };
  return {
    responseId: data.flowResponse?.responseId,
    flow,
    createdAt,
    postType,
    currentUserId,
    visibility,
    person,
  };
};

export const newMapLegacyRecognitionResponseToFeedBodyProps = (
  data: FeedItemFromAPI,
  profile: GetProfileInfoResponse,
  onMemberClick: (member: MemberInteractionType) => void,
) => {
  const blocks: BlockProps[] = [];
  const { coreValue, gifURL, message, pointsEach, taggedUsers, to } = data;

  if (to.length) {
    const celebrateBlock: BlockProps = {
      id: '1',
      type: 'PERSON_SELECTOR',
      content: {
        title: RECOGNITION_POST_LABELS.CELEBRATE_LABEL,
        persons: to.map((user) => ({
          id: user.memberID,
          label: user.name || ANONYMOUS,
          avatar: {
            img: user.image,
            userId: user.memberID,
            name: user.firstName,
            icon:
              user.memberState === MemberState.PENDING ? 'pending-person' : '',
            iconColor: ThemeV2.palette.white,
          },
        })),
      },
    };
    blocks.push(celebrateBlock);
  }

  if (coreValue) {
    const coreValueBlock: BlockProps = {
      id: '2',
      type: 'DROPDOWN',
      content: {
        title: RECOGNITION_POST_LABELS.CORE_VALUE_LABEL,
        options: [],
        value: [coreValue],
      },
    };

    blocks.push(coreValueBlock);
  }

  if (message) {
    const openEndedBlock: BlockProps = {
      id: '3',
      type: 'OPEN_ENDED',
      content: {
        gifUrl: gifURL,
        title: RECOGNITION_POST_LABELS.OPEN_ENDED_LABEL,
        message: getFormattedMessage(message, taggedUsers, onMemberClick),
      },
    };

    blocks.push(openEndedBlock);
  }

  if (pointsEach) {
    const emoticon = getCurrencyFromProfileData(profile);

    const coreValueBlock: BlockProps = {
      id: '4',
      type: 'GIVE_TROPHIES',
      content: {
        title: `${RECOGNITION_POST_LABELS.TROPHIES_BLOCK_LABEL} ${
          getAssemblyCurrency(profile).pluralName
        }!`,
        content: pointsEach.toString(),
        showEachText: to.length > 1,
        emoticon,
      },
    };

    blocks.push(coreValueBlock);
  }

  return blocks;
};

// TODO: FEED-REFACTOR
// DELETE THIS UTIL FUNCTION IF ITS NOT NEEDED AFTER FEED REFACTOR IS COMPLETED IN ALL PAGES
export const mapLegacyRecognitionResponseToFeedBodyProps = (
  data: FeedItemFromAPI,
  profile: GetProfileInfoResponse,
  onMemberClick: (member: MemberInteractionType) => void,
) => {
  const blocks: BlockProps[] = [];
  const { coreValue, gifURL, message, pointsEach, taggedUsers, to } = data;

  if (to.length) {
    const celebrateBlock: BlockProps = {
      id: '1',
      type: 'PERSON_SELECTOR',
      content: {
        title: RECOGNITION_POST_LABELS.CELEBRATE_LABEL,
        persons: to.map((user) => ({
          id: user.memberID,
          label: user.name || ANONYMOUS,
          avatar: {
            img: user.image,
            userId: user.memberID,
            name: user.firstName,
            icon:
              user.memberState === MemberState.PENDING ? 'pending-person' : '',
            iconColor: ThemeV2.palette.white,
          },
        })),
      },
    };
    blocks.push(celebrateBlock);
  }

  if (coreValue) {
    const coreValueBlock: BlockProps = {
      id: '2',
      type: 'DROPDOWN',
      content: {
        title: RECOGNITION_POST_LABELS.CORE_VALUE_LABEL,
        options: [],
        value: [coreValue],
      },
    };

    blocks.push(coreValueBlock);
  }

  if (message) {
    const openEndedBlock: BlockProps = {
      id: '3',
      type: 'OPEN_ENDED',
      content: {
        gifUrl: gifURL,
        title: RECOGNITION_POST_LABELS.OPEN_ENDED_LABEL,
        message: getFormattedMessage(message, taggedUsers, onMemberClick),
      },
    };

    blocks.push(openEndedBlock);
  }

  if (pointsEach) {
    const emoticon = getCurrencyFromProfileData(profile);

    const coreValueBlock: BlockProps = {
      id: '4',
      type: 'GIVE_TROPHIES',
      content: {
        title: `${RECOGNITION_POST_LABELS.TROPHIES_BLOCK_LABEL} ${
          getAssemblyCurrency(profile).pluralName
        }!`,
        content: pointsEach.toString(),
        showEachText: to.length > 1,
        emoticon,
      },
    };

    blocks.push(coreValueBlock);
  }

  return { blocks };
};

// TODO: FEED-REFACTOR
// DELETE THIS UTIL FUNCTION IF ITS NOT NEEDED AFTER FEED REFACTOR IS COMPLETED IN ALL PAGES
export const mapLegacyRecognitionToFeedHeaderProps = (
  data: FeedItemFromAPI,
  profile: GetProfileInfoResponse,
  handleMemberClick: (member: MemberInteractionType) => void,
  handleOnFlowClick: (flow: { flowId: string }) => void,
) => {
  const canDeletePosts = getCanDeletePosts(profile);
  return {
    flow: {
      flowId: 'recognition',
      name: 'Celebrate a Teammate',
      icon: { kind: 'HEX_CODE', value: '1F389' },
      kind: '',
      version: 0,
    },
    postType: data.type as PostTypes,
    hasTrophies: data.pointsEach > 0,
    showDeleteOption: canDeletePosts,
    createdAt: data.createdAt.toString(),
    person: data.from
      ? {
          ...data.from,
          memberState:
            data.from?.memberState === 'ACTIVE'
              ? MemberState.ACTIVE
              : MemberState.DEACTIVATED,
        }
      : null,
    onCopyLink: () => {
      if (data.postID) {
        const url = getRecognitionPostUrl(data.postID);
        copyToClipboard(url);
        showSuccessMessage(COPIED_TO_CLIPBOARD);
      }
    },
    handleMemberClick,
    handleOnFlowClick,
    currentUserId: profile.member.memberId,
    visibility: data.isPrivate ? 'private' : data.flowResponse?.visibility,
  };
};

// TODO: FEED-REFACTOR
// DELETE THIS UTIL FUNCTION IF ITS NOT NEEDED AFTER FEED REFACTOR IS COMPLETED IN ALL PAGES
export const mapLegacyRecognitionToReactionProps = (
  data: FeedItemFromAPI,
  profile: GetProfileInfoResponse,
) => {
  const { postID } = data;
  const userId = profile?.member.memberId || '';
  const reactions = data.reactions.map((reaction) => ({
    members: reaction.members.map((member) => ({
      name: member.name,
      memberID: member.memberID,
    })),
    reaction: {
      name: reaction.name,
      type:
        reaction.type === 'REGULAR'
          ? ReactionType.REGULAR
          : ReactionType.CUSTOM,
      displayName: reaction?.displayName,
      value: reaction.value,
    },
    timesReacted: reaction.members.length,
    active: reaction.members.some((member) => member.memberID === userId),
  }));

  return {
    reactions,
    contentId: postID,
    currentUserId: userId,
  };
};

// TODO: FEED-REFACTOR
// DELETE THIS UTIL FUNCTION IF ITS NOT NEEDED AFTER FEED REFACTOR IS COMPLETED IN ALL PAGES
export const mapFeedResponseToFeedHeaderProps = (
  data: FlowFeedResponse,
  profile: GetProfileInfoResponse,
  onMemberClick: (member: MemberInteractionType) => void,
  handleOnFlowClick: (flow: { flowId: string }) => void,
) => {
  const hasTrophies = checkIfFlowPostHasTrophies(data);
  return {
    flow: data.flow,
    hasTrophies,
    showDeleteOption: data.canDelete,
    postType: PostTypes.FLOW,
    createdAt: data.createdAt.toString(),
    person: data.respondent
      ? {
          ...data.respondent,
          isDeleted: false,
          memberState:
            data.respondent.memberState === 'ACTIVE'
              ? MemberState.ACTIVE
              : MemberState.DEACTIVATED,
        }
      : null,
    onCopyLink: () => {
      if (data.flow.flowId && data.responseId) {
        // eslint-disable-next-line max-len
        const url = getFlowPostUrl(data.flow.flowId, data.responseId);
        copyToClipboard(url);
        showSuccessMessage(COPIED_TO_CLIPBOARD);
      }
    },
    handleMemberClick: onMemberClick,
    handleOnFlowClick,
    currentUserId: profile.member.memberId,
    visibility: data.isPrivate ? 'private' : data.visibility,
    kind: data?.kind,
  };
};

export const newMapFeedResponseToFeedBodyProps = (
  feeds: FlowFeedResponse,
  profile: GetProfileInfoResponse,
  onMemberClick: (member: MemberInteractionType) => void,
) => {
  let selectedPersons: number;
  const ImageTypes = ['jpg', 'jpeg', 'png', 'svg', 'gif'];
  const blocks: BlockProps[] = [];
  feeds.responses.forEach((feed) => {
    if (feed.response && feed.response.persons) {
      selectedPersons = feed.response.persons.length;
    }
    if (feed.state === 'SKIPPED') {
      blocks.push({
        id: feed.blockId,
        type: 'NO_RESPONSE',
        content: {
          title: feed.content.title,
          value: 'No answer',
        },
      });
      return;
    }
    switch (feed.type) {
      case 'PERSON_SELECTOR':
        blocks.push({
          id: feed.blockId,
          type: 'PERSON_SELECTOR',
          content: {
            ...(feed.content as PersonSelectorBlockResponseType),
            persons: feed.response.persons
              ? feed.response.persons.map((person) => ({
                  id: person.memberId,
                  label: getProfileFullName(person.name),
                  avatar: {
                    userId: person.memberId,
                    name: person.name.firstName,
                    img: person.image,
                    icon:
                      person.memberState === MemberState.PENDING
                        ? 'pending-person'
                        : '',
                    iconColor: ThemeV2.palette.white,
                  },
                }))
              : [],
          },
        });
        break;
      case 'GIVE_POINTS_STACK':
        blocks.push({
          id: feed.blockId,
          type: 'GIVE_TROPHIES',
          content: {
            ...(feed.content as GiveTrophiesBlockResponseType),
            content: feed.response.value.toString(),
            emoticon: getCurrencyFromProfileData(profile),
            showEachText: selectedPersons > 1,
          },
        });
        break;
      case 'MULTI_CHOICE':
      case 'DROPDOWN':
        blocks.push({
          id: feed.blockId,
          type: 'DROPDOWN',
          content: {
            ...(feed.content as DropDownBlockResponseType),
            value: (feed.response.value as KeyValuePair[]).map((x) => x.value),
          },
        });
        break;
      case 'OPEN_ENDED':
        blocks.push({
          id: feed.blockId,
          type: 'OPEN_ENDED',
          content: {
            ...(feed.content as OpenEndedBlockResponseType),
            gifUrl: feed.response.gifUrl,
            files:
              feed.response.files && mapFilesResponseToFiles(feed, ImageTypes),
            message: getFormattedMessage(
              feed.response.value as string,
              feed.response.mentions
                ? feed.response.mentions.map((mention) => ({
                    ...mention,
                    memberID: mention.memberId,
                    isDeleted: false,
                  }))
                : [],
              onMemberClick,
              feed.response.tags,
            ),
          },
        });
        break;
      case 'SCALE':
        blocks.push({
          id: feed.blockId,
          type: 'SCALE',
          content: {
            ...(feed.content as ScaleBlockResponseType),
            value: feed.response.value as number,
          },
        });
        break;
      case 'GIF':
        blocks.push({
          id: feed.blockId,
          type: 'GIF_SELECTOR',
          content: {
            ...(feed.content as GifBlockResponseType),
            src: feed.response.value as string,
          },
        });
        break;
      case 'FILE_UPLOAD':
        blocks.push({
          id: feed.blockId,
          type: 'FILE_UPLOAD',
          content: {
            ...(feed.content as FileUploadResponseType),
            isParticipation: false,
            status: FileUploadStatus.Success,
            files:
              feed.response.files && mapFilesResponseToFiles(feed, ImageTypes),
          },
        });
        break;
      default:
        break;
    }
  });

  return blocks;
};

// TODO: FEED-REFACTOR
// DELETE THIS UTIL FUNCTION IF ITS NOT NEEDED AFTER FEED REFACTOR IS COMPLETED IN ALL PAGES
export const mapFeedResponseToFeedBodyProps = (
  feeds: FlowFeedResponse,
  profile: GetProfileInfoResponse,
  onMemberClick: (member: MemberInteractionType) => void,
) => {
  let selectedPersons: number;
  const ImageTypes = ['jpg', 'jpeg', 'png', 'svg', 'gif'];
  const blocks: BlockProps[] = [];
  feeds.responses.forEach((feed) => {
    if (feed.response && feed.response.persons) {
      selectedPersons = feed.response.persons.length;
    }
    if (feed.state === 'SKIPPED') {
      blocks.push({
        id: feed.blockId,
        type: 'NO_RESPONSE',
        content: {
          title: feed.content.title,
          value: 'No answer',
        },
      });
      return;
    }
    switch (feed.type) {
      case 'PERSON_SELECTOR':
        blocks.push({
          id: feed.blockId,
          type: 'PERSON_SELECTOR',
          content: {
            ...(feed.content as PersonSelectorBlockResponseType),
            persons: feed.response.persons
              ? feed.response.persons.map((person) => ({
                  id: person.memberId,
                  label: getProfileFullName(person.name),
                  avatar: {
                    userId: person.memberId,
                    name: person.name.firstName,
                    img: person.image,
                    icon:
                      person.memberState === MemberState.PENDING
                        ? 'pending-person'
                        : '',
                    iconColor: ThemeV2.palette.white,
                  },
                }))
              : [],
          },
        });
        break;
      case 'GIVE_POINTS_STACK':
        blocks.push({
          id: feed.blockId,
          type: 'GIVE_TROPHIES',
          content: {
            ...(feed.content as GiveTrophiesBlockResponseType),
            content: feed.response.value.toString(),
            emoticon: getCurrencyFromProfileData(profile),
            showEachText: selectedPersons > 1,
          },
        });
        break;
      case 'MULTI_CHOICE':
      case 'DROPDOWN':
        blocks.push({
          id: feed.blockId,
          type: 'DROPDOWN',
          content: {
            ...(feed.content as DropDownBlockResponseType),
            value: (feed.response.value as KeyValuePair[]).map((x) => x.value),
          },
        });
        break;
      case 'OPEN_ENDED':
        blocks.push({
          id: feed.blockId,
          type: 'OPEN_ENDED',
          content: {
            ...(feed.content as OpenEndedBlockResponseType),
            gifUrl: feed.response.gifUrl,
            files:
              feed.response.files && mapFilesResponseToFiles(feed, ImageTypes),
            message: getFormattedMessage(
              feed.response.value as string,
              feed.response.mentions
                ? feed.response.mentions.map((mention) => ({
                    ...mention,
                    memberID: mention.memberId,
                    isDeleted: false,
                  }))
                : [],
              onMemberClick,
              feed.response.tags,
            ),
          },
        });
        break;
      case 'SCALE':
        blocks.push({
          id: feed.blockId,
          type: 'SCALE',
          content: {
            ...(feed.content as ScaleBlockResponseType),
            value: feed.response.value as number,
          },
        });
        break;
      case 'GIF':
        blocks.push({
          id: feed.blockId,
          type: 'GIF_SELECTOR',
          content: {
            ...(feed.content as GifBlockResponseType),
            src: feed.response.value as string,
          },
        });
        break;
      case 'FILE_UPLOAD':
        blocks.push({
          id: feed.blockId,
          type: 'FILE_UPLOAD',
          content: {
            ...(feed.content as FileUploadResponseType),
            isParticipation: false,
            status: FileUploadStatus.Success,
            files:
              feed.response.files && mapFilesResponseToFiles(feed, ImageTypes),
          },
        });
        break;
      default:
        break;
    }
  });

  return { blocks };
};

// TODO: FEED-REFACTOR
// DELETE THIS UTIL FUNCTION IF ITS NOT NEEDED AFTER FEED REFACTOR IS COMPLETED IN ALL PAGES
export const mapPostToPostProps = (
  feed: FeedItemFromAPI,
  profileData: GetProfileInfoResponse,
  onMemberClick: (member: MemberInteractionType) => void,
  handleOnFlowClick: (flow: { flowId: string }) => void,
) => {
  return {
    instanceId: feed.flowResponse?.instanceId || '',
    flowId: feed.flowResponse?.flow.flowId,
    responseId: feed.flowResponse?.responseId,
    postId: feed.postID,
    key: feed.flowResponse?.responseId || feed.postID,
    type: feed.type,
    headerProps:
      feed.type === PostTypes.FLOW && feed.flowResponse
        ? mapFeedResponseToFeedHeaderProps(
            feed.flowResponse,
            profileData,
            onMemberClick,
            handleOnFlowClick,
          )
        : mapLegacyRecognitionToFeedHeaderProps(
            feed,
            profileData,
            onMemberClick,
            handleOnFlowClick,
          ),
    bodyProps:
      feed.type === PostTypes.FLOW && feed.flowResponse
        ? mapFeedResponseToFeedBodyProps(
            feed.flowResponse,
            profileData,
            onMemberClick,
          )
        : mapLegacyRecognitionResponseToFeedBodyProps(
            feed,
            profileData,
            onMemberClick,
          ),
    reactionBarProps:
      feed.type === PostTypes.FLOW && feed.flowResponse
        ? mapRecognitionPostToReactionProps(feed.flowResponse, profileData)
        : mapLegacyRecognitionToReactionProps(feed, profileData),
    commentsCount:
      feed.type === PostTypes.FLOW && feed.flowResponse
        ? feed.flowResponse.commentsCount
        : feed.commentsCount,
    trophyReceivers:
      feed.type === PostTypes.FLOW && feed.flowResponse ? [] : feed.to,
  };
};

// TODO: FEED-REFACTOR
// DELETE THIS UTIL FUNCTION IF ITS NOT NEEDED AFTER FEED REFACTOR IS COMPLETED IN ALL PAGES
export const mapFlowFeedPostToFeedProps = (
  flowPost: FlowFeedResponse,
  profileData: GetProfileInfoResponse,
  onMemberClick: (member: MemberInteractionType) => void,
  handleOnFlowClick: (flow: { flowId: string }) => void,
) => {
  return {
    instanceId: flowPost.instanceId || '',
    flowId: flowPost.flow.flowId,
    responseId: flowPost.responseId,
    postId: '',
    key: flowPost.responseId,
    type: PostTypes.FLOW,
    headerProps: mapFeedResponseToFeedHeaderProps(
      flowPost,
      profileData,
      onMemberClick,
      handleOnFlowClick,
    ),
    bodyProps: mapFeedResponseToFeedBodyProps(
      flowPost,
      profileData,
      onMemberClick,
    ),
    reactionBarProps: mapRecognitionPostToReactionProps(flowPost, profileData),
    commentsCount: flowPost.commentsCount,
    trophyReceivers: [],
    kind: flowPost?.kind,
  };
};

// TODO: FEED-REFACTOR
// DELETE THIS UTIL FUNCTION IF ITS NOT NEEDED AFTER FEED REFACTOR IS COMPLETED IN ALL PAGES
export const mapFlowFeedResponseToFeedProps = (
  data: FlowFeedResponse[],
  profileData: GetProfileInfoResponse,
  onMemberClick: (member: MemberInteractionType) => void,
  handleOnFlowClick: (flow: { flowId: string }) => void,
) => {
  return data.map((flowPost) => {
    return mapFlowFeedPostToFeedProps(
      flowPost,
      profileData,
      onMemberClick,
      handleOnFlowClick,
    );
  });
};

export const getCommonFlowPostAttributes = (post: FeedItemFromAPI) => ({
  postId: post.postID,
  responseId: post.flowResponse?.responseId,
  flowId: post.flowResponse?.flow.flowId,
  postType: post.type,
  hasTrophies:
    post.type === PostTypes.FLOW && post.flowResponse
      ? checkIfFlowPostHasTrophies(post.flowResponse)
      : post.pointsEach > 0,
});

// TODO: FEED-REFACTOR
// DELETE THIS UTIL FUNCTION IF ITS NOT NEEDED AFTER FEED REFACTOR IS COMPLETED IN ALL PAGES
export const mapFeedResponseToFeedProps = (
  data: InfiniteData<GetFeedResponse>,
  profileData: GetProfileInfoResponse,
  onMemberClick: (member: MemberInteractionType) => void,
  handleOnFlowClick: (flow: { flowId: string }) => void,
) => {
  return data.pages.reduce<FeedProps[]>(
    (acc, page) => [
      ...acc,
      ...page.data.map((feed) => {
        return mapPostToPostProps(
          feed,
          profileData,
          onMemberClick,
          handleOnFlowClick,
        );
      }),
    ],
    [],
  );
};

export const getFlowFeedUrl = (flowId: string) => {
  return `/flows/${flowId}`;
};
