import { useCallback, useMemo, useState, useEffect } from 'react';
import shallow from 'zustand/shallow';

import { IMemberDTO } from '../../../../interfaces/member';
import { useMembersFetchInfiniteScrollQuery } from '../../../../queries/Members';
import { useMembersSearch } from '../../../../hooks/useMembersSearch';
import { useProfileInfoFetchQuery } from '../../../../queries/Profile';
import useLayoutStore from '../../../../stores/layoutStore';

import useGetPageName from '../../../../hooks/useGetPageName';
import {
  INVITE_MEMBER_EVENTS,
  RIGHT_DRAWER_EVENTS,
} from '../../../../Utils/analytics/constants';
import { canInviteMembers } from '../../../../Utils/permissions';
import { useQuickSetupStore } from '../../../../stores/quickSetupStore';
import { QuickSetupStep } from '../../../quickSetup/QuickSetupController/types';
import { trackInviteMemberActionEvent } from '../../../../Utils/analytics/inviteMembers';
import { trackRightDrawerEvent } from '../../../../Utils/analytics';

const usePeopleDropdownListController = () => {
  // Quick Setup
  const { currentStep } = useQuickSetupStore();

  const [isOpen, setIsOpen] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { toggleLeftAsideOpen, setMemberAndToggleRightAsideOpen } =
    useLayoutStore(
      (state) => ({
        toggleLeftAsideOpen: state.toggleLeftAsideOpen,
        setMemberAndToggleRightAsideOpen:
          state.setMemberAndToggleRightAsideOpen,
      }),
      shallow,
    );

  const { page } = useGetPageName();

  const widthCheck = window.matchMedia('(min-width: 769px)');

  const handleEntryClick = useCallback(
    (firstName: string, memberId: string) => {
      setMemberAndToggleRightAsideOpen({ firstName, memberId });
      trackRightDrawerEvent(RIGHT_DRAWER_EVENTS.MEMBER_CLICKED, {
        feedViewed: page,
        drawerTabViewed: 'people',
      });
      if (!widthCheck.matches) {
        toggleLeftAsideOpen();
      }
    },
    [
      page,
      widthCheck.matches,
      toggleLeftAsideOpen,
      setMemberAndToggleRightAsideOpen,
    ],
  );

  const { data, hasNextPage, fetchNextPage } =
    useMembersFetchInfiniteScrollQuery();

  const { data: profileData } = useProfileInfoFetchQuery();

  const hasInvitePermissions = canInviteMembers(
    profileData?.member,
    profileData?.assembly.settings,
  );

  const infiniteMembers = useMemo(() => {
    let allMembers: IMemberDTO[] = [];

    if (data) {
      data.pages.forEach((dataPage) => {
        const currentPageMembers = dataPage.members;
        allMembers = allMembers.concat(currentPageMembers);
      });
    }

    return allMembers;
  }, [data]);

  const {
    models: {
      isFetching: isMembersSearchFetching,
      isLoading: isMembersSearchLoading,
      searchedMembers,
      value: membersSearchValue,
    },
    operations: { onChange },
  } = useMembersSearch();

  const onMembersSearchChange = useCallback(
    (value: string) => {
      onChange(value);
      trackRightDrawerEvent(RIGHT_DRAWER_EVENTS.MEMBER_SEARCHED, {
        feedViewed: page,
        drawerTabViewed: 'people',
      });
    },
    [onChange, page],
  );

  const toggleExpandNavigation = () => {
    setIsOpen(!isOpen);
  };

  const toggleInviteModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    if (isModalOpen) {
      trackInviteMemberActionEvent({
        action: INVITE_MEMBER_EVENTS.INVITE_START,
        inviterId: profileData?.member.memberId,
        inviterRole: profileData?.member.role,
        source: page,
        subSource: 'leftDrawer',
      });
    }
  }, [isModalOpen, profileData, page]);

  return {
    disableParticipantsMenuList: currentStep === QuickSetupStep.COMPLETION,
    hasMoreMembersToFetch: hasNextPage,
    isMembersSearchLoading: isMembersSearchFetching || isMembersSearchLoading,
    canInviteMembers: hasInvitePermissions,
    isOpen,
    isModalOpen,
    infiniteMembers,
    membersSearchValue,
    onMembersSearchChange,
    searchedMembers,
    totalNumberOfAssemblyMembers: data?.pages[0].total,
    fetchMoreInfiniteMembers: fetchNextPage,
    handleEntryClick,
    toggleExpandNavigation,
    toggleInviteModal,
  };
};

export default usePeopleDropdownListController;
