import { ReactionMember } from '../../../interfaces/Feed/Reaction';

export const formatReactedMembersList = (
  members: ReactionMember[],
  currentUserId: string,
): string => {
  const membersClone = [...members];
  const indexOfCurrentUser = membersClone.findIndex(
    ({ memberID }) => memberID === currentUserId,
  );

  const hasCurrentMemberReacted = indexOfCurrentUser > -1;
  if (hasCurrentMemberReacted) {
    membersClone.splice(indexOfCurrentUser, 1);
    membersClone.unshift({ memberID: currentUserId, name: 'You' });
  }

  if (membersClone.length === 1) {
    return `${membersClone[0].name} reacted with this emoji.`;
  }
  if (membersClone.length === 2) {
    return `${membersClone[0].name} and ${membersClone[1].name} reacted with this emoji.`;
  }

  const allMemberNames = membersClone.map(({ name }) => name);
  const lastMemberName = allMemberNames.pop();
  return `${allMemberNames.join(
    ', ',
  )}, and ${lastMemberName} reacted with this emoji.`;
};
