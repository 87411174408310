import { AvatarProps } from '../../atomic/atoms/Avatar/interfaces';
import { Icon } from '../../queries/Flows/Feed/interfaces';
import { FeedItemKind } from '../Feed';
import { PostTypes } from '../Home';
import { Profile, User } from '../user';

export enum NotificationTypes {
  POST = 'post',
  TAGGED_IN_POST = 'tagged_in_post',
  TAGGED_IN_COMMENT = 'tagged_in_comment',
  COMMENT = 'comment',
  LIKE = 'like',
  PLATFORM = 'platform',
  FLOW_TRIGGERED = 'flow_triggered',
  FLOW_REMINDER = 'flow_reminder',
  MENTIONS = 'mentions',
}
interface ProfileWithImage extends Profile {
  image: {
    original: {
      relativeUrl: string;
    };
    resized?: {
      relativeUrl: string;
    };
  };
}

interface Comment {
  carrots: number;
  message: string;
  taggedUsers: User[];
  _id: string;
}

interface Post {
  message: string;
  type: PostTypes;
  taggedUsers: User[];
  _id: string;
}

export enum FromUserStates {
  NEW = 'NEW',
  ACTIVE = 'ACTIVE',
  DEACTIVATED = 'DEACTIVATED',
  PENDING = 'PENDING',
}

interface Flow {
  icon: Icon;
  _id: string;
  name: string;
  description: string;
}

interface FlowDetails {
  flow: Flow;
  occurrence: string;
}

export interface Notification {
  isRead: boolean;
  carrots: number;
  isDeleted: boolean;
  type: NotificationTypes;
  updatedAt: string;
  post?: Post;
  user?: User;
  _id: string;
  from?: {
    // isDeleted is deprecated: https://joinassembly.slack.com/archives/CDMQWLTNX/p1623251877063900
    isDeleted: boolean;
    profile: ProfileWithImage;
    state: FromUserStates;
    _id: string;
  };
  createdAt: string;
  comment?: Comment;
  flowDetails?: FlowDetails;
  responseId?:
    | string
    | {
        _id: string;
        kind: FeedItemKind;
      };
  commentId?: string;
  isAnonymous?: boolean;
}

export interface FormattedNotifications {
  read: boolean;
  timeAgo: string;
  headerText: string;
  bodyText: string;
  id: string;
  avatar: AvatarProps;
  icon?: string;
  postID: string;
  type: NotificationTypes;
  emoticon?: string;
  flowId?: string;
  occurrenceId?: string;
  responseId?:
    | string
    | {
        _id: string;
        kind: FeedItemKind;
      };
  commentId?: string;
}
