import React, { ReactNode, useCallback } from 'react';
import styled from 'styled-components';
import useOnClickOutside from '../../../hooks/useOnOutsideClick';
import DropdownCard from '../../atoms/DropdownCard_V2';
import {
  DropdownCategoryLabel,
  StyledDropdownListItem,
} from '../Dropdown_V2/styles';
import { DropdownCardWrapper } from '../../atoms/DropdownCard_V2/styles';
import { DropdownPlacement, MenuItemProps } from '../Dropdown_V2/interfaces';

export interface DropdownProps {
  id?: string | number;
  items?: any; // FIXME: Used only in TextField_V2, needs refactor.
  disabled?: boolean;
  isFullWidth?: boolean;
  menuItems: MenuItemProps[];
  onItemClick?: (value: string | number) => void;
  multiSelect?: boolean;
  getItemLabel?: (value: string) => ReactNode;
  onFocus?: () => void;
}
interface DropdownWrapperProps {
  ref: React.MutableRefObject<null>;
  isFullWidth?: boolean;
}

export const DropdownWrapper = styled.div<DropdownWrapperProps>`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  width: ${(props) => (props.isFullWidth ? '100%' : 'auto')};
`;
DropdownWrapper.displayName = 'DropdownWrapper';

export interface DropdownListHolderProps extends DropdownProps {
  referenceElement: React.MutableRefObject<null>;
  isOpen: boolean;
  closeDropdown: () => void;
  dropdownPlacement?: DropdownPlacement;
  isDropdownPositionFixed?: boolean;
  anchorEl: React.MutableRefObject<null>;
}

const DropdownListHolder = (props: DropdownListHolderProps) => {
  const {
    id: popperId,
    referenceElement,
    menuItems,
    onItemClick = () => {},
    disabled,
    isFullWidth,
    multiSelect,
    isOpen,
    closeDropdown,
    dropdownPlacement = DropdownPlacement.Bottom,
    isDropdownPositionFixed,
    getItemLabel,
    anchorEl,
  } = props;

  useOnClickOutside(referenceElement, closeDropdown);

  const handleMenuItemClick = useCallback(
    (id: string | number) => {
      if (!multiSelect) {
        closeDropdown();
      }
      onItemClick(id);
    },
    [multiSelect, onItemClick, closeDropdown],
  );

  return (
    <DropdownCardWrapper isFullWidth={isFullWidth}>
      <DropdownCard
        id={popperId as string | undefined}
        isOpen={!disabled && isOpen}
        anchorEl={anchorEl.current}
        dropdownPlacement={dropdownPlacement}
        isDropdownPositionFixed={isDropdownPositionFixed}
        isFullWidth={isFullWidth}
      >
        {menuItems.map((menuItem) => (
          <div key={menuItem.id}>
            {menuItem.category && (
              <DropdownCategoryLabel variant="body3">
                {menuItem.category}
              </DropdownCategoryLabel>
            )}
            {menuItem.items.map((item) => (
              <StyledDropdownListItem
                key={item.id}
                value={item.value}
                id={item.id}
                isSelected={item.isSelected}
                prefixIcon={item.prefixIcon}
                helperText={item.helperText}
                prefixImg={item.prefixImg}
                disabled={item.disabled}
                deleteItem={item.deleteItem}
                assemblyCurrency={item.assemblyCurrency}
                emoji={item.emoji}
                onItemClick={handleMenuItemClick}
                getItemLabel={getItemLabel}
              />
            ))}
          </div>
        ))}
      </DropdownCard>
    </DropdownCardWrapper>
  );
};

const MemoizedDropdownListHolder = React.memo(DropdownListHolder);
MemoizedDropdownListHolder.displayName = 'DropdownListHolder';
export default MemoizedDropdownListHolder;
