import styled from 'styled-components';
import ThemeV2 from '../../../../componentsV2/theme';
import IconButton from '../../../atoms/IconButton_V2';
import Heading from '../../../atoms/Heading';
import Button from '../../../atoms/Button';
import { Flex } from '../../../../Utils/styles/display';
import TextField_V2 from '../../TextField_V2';

export const ModalBackground = styled.div`
  display: flex;
  flex-direction: row;
  width: 500px;
  background: ${ThemeV2.palette.gray1};
  border-radius: 2px;
  max-height: 80vh;
  overflow: hidden;
  @media (max-width: 580px) {
    width: 100%;
    flex-wrap: wrap;
    overflow: auto;
  }
`;

export const PrimaryButton = styled(Button)`
  width: 50%;
  margin: 0px 8px;
`;

export const SecondaryButton = styled(Button)`
  width: 50%;
  margin: 0px 8px;
`;

export const ModalHeading = styled(Heading)`
  margin-left: auto;
  text-align: center;
  display: flex;
  height: 64px;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px;
`;

export const ModalBody = styled.div<{
  hasDropdown: boolean;
}>`
  padding: 32px;
  overflow: auto;
  min-height: ${({ hasDropdown }) => (hasDropdown ? `160px` : 'auto')};
  position: relative;
  z-index: 2;
`;

export const ModalFooter = styled(Flex)`
  padding: 16px;
  width: 100%;
`;

export const CloseButton = styled.button`
  position: absolute;
  background: transparent;
  box-shadow: none;
  border: none;
  top: 18px;
  right: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  color: ThemeV2.palette.gray2;
`;

export const CloseIconButton = styled(IconButton)`
  margin-left: 12px;
  position: absolute;
  top: 16px;
  right: 16px;
  height: 32px;
`;

export const CloseIcon = styled.span`
  display: block;
  margin-bottom: 5px;
`;

export const ModalContentWrapper = styled(Flex)`
  min-height: 200px;
`;

export const Dropdown = styled(TextField_V2)`
  position: fixed;
  width: calc(100% - 64px);
  margin-top: 16px;
`;
