import styled from 'styled-components';
import ContentLoader from 'react-content-loader';

import { SingleSelectAutoComplete } from '../../Autocomplete';
import FlowRequestTemplateCard from '../../../molecules/FlowRequestTemplateCard';

import {
  StyledButton,
  StyledCardFooter,
} from '../../../molecules/FlowRequestTemplateCard/styles';
import EmptyErrorStateTemplate from '../../../molecules/EmptyErrorStateTemplate';

export const StyledAutocomplete = styled(SingleSelectAutoComplete)`
  max-width: 156px;
  width: 100%;
  margin-left: 16px;
`;

export const TemplateCardsWrapper = styled.div`
  display: grid;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  grid-template-columns: 1fr 1fr;
  padding-bottom: 20px;
  margin-top: 24px;
  max-width: 502px;
  width: 100%;
`;

export const StyledFlowRequestTemplateCard = styled(FlowRequestTemplateCard)`
  ${StyledCardFooter} {
    padding: 0 16px 4px 16px;
  }

  ${StyledButton} {
    padding: auto;
    width: auto;
  }
`;

export const DropdownLoader = styled(ContentLoader)`
  margin-left: 16px;
`;

export const TemplateCardLoader = styled(ContentLoader)`
  margin-left: 24px;
`;

export const StyledEmptyTemplateState = styled(EmptyErrorStateTemplate)`
  margin: auto;
`;
