import styled, { css } from 'styled-components';
import ThemeV2 from '../../../componentsV2/theme';
import Body from '../../atoms/Body';
import IconButton from '../../atoms/IconButton_V2';
import TextWithIcon from '../../atoms/TextWithIcon';
import Image from '../../atoms/Image';
import { StyledButton } from '../../../Utils/styles/button';
import SVGIcon from '../../atoms/SVGIcon';
import DropdownIconButton from '../DropdownIconButton';

const cardSize = '132px';

const shadow = css`
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), 0px 3px 4px rgba(0, 0, 0, 0.02),
    0px 1px 5px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
`;

const topRightIconStyles = css`
  position: absolute;
  top: 4px;
  right: 4px;
`;

export const StyledBody = styled(Body)`
  margin-bottom: 12px;
`;

export const StyledFileCard = styled.div<{
  isParticipation: boolean;
  isKnowledgeCenterEnabled?: boolean;
}>`
  display: flex;
  margin: 2px;
  width: ${({ isKnowledgeCenterEnabled }) =>
    isKnowledgeCenterEnabled ? '176px' : cardSize};
  height: ${cardSize};
  background-color: ${ThemeV2.palette.gray1};
  border-radius: 4px;
  border: 1px solid ${ThemeV2.palette.gray4};
  transition: all 200ms ease-in-out;

  &:hover {
    transform: ${({ isParticipation }) => !isParticipation && 'scale(1.015)'};
    cursor: ${({ isParticipation }) => !isParticipation && '-moz-zoom-in'};
    cursor: ${({ isParticipation }) => !isParticipation && '-webkit-zoom-in'};
    cursor: ${({ isParticipation }) => !isParticipation && 'zoom-in'};
  }
`;

export const StyledFileCardMain = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const StyledFileCardHeader = styled.div`
  display: flex;
`;

export const StyledFileCardFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
  padding: 8px 12px;
`;

export const StyledImage = styled(Image)`
  border-radius: 4px;
  width: ${cardSize};
  height: ${cardSize};
  object-fit: cover;
  object-position: center;
  ${shadow}
`;

export const StyledFileCardFileSize = styled(Body)`
  margin-top: 8px;
  margin-left: 8px;
`;

export const StyledFileCardIconContainer = styled(Body)`
  flex-grow: 1;
  text-align: center;
`;

export const StyledFileCardCloseIcon = styled(IconButton)`
  margin-left: auto;
  margin-top: 4px;
  margin-right: 4px;
`;

export const StyledClickableImageCloseIcon = styled(StyledButton)`
  ${topRightIconStyles}
`;

export const StyledImageDownloadIcon = styled(IconButton)`
  ${topRightIconStyles}
  background-color: rgba(255, 255, 255);
`;

export const StyledBodyFileName = styled(Body)`
  word-break: break-word;
`;

export const StyledTextWithIcon = styled(TextWithIcon)`
  > div {
    justify-content: left;
    margin-right: 0;
  }
`;

export const StyledImageContainer = styled.div<{
  isParticipation: boolean;
}>`
  position: relative;
  display: inline-block;
  display: flex;
  margin: 2px;
  width: ${cardSize};
  height: ${cardSize};
  background-color: ${ThemeV2.palette.gray1};
  border-radius: 4px;
  border: 1px solid ${ThemeV2.palette.gray4};
  transition: all 200ms ease-in-out;

  &:hover {
    transform: ${({ isParticipation }) => !isParticipation && 'scale(1.015)'};
    cursor: ${({ isParticipation }) => !isParticipation && '-moz-zoom-in'};
    cursor: ${({ isParticipation }) => !isParticipation && '-webkit-zoom-in'};
    cursor: ${({ isParticipation }) => !isParticipation && 'zoom-in'};
  }
`;

export const StyledLoaderContainer = styled.div`
  padding: 40px 24px 0;
`;

//TODO Remove above styles after feature flag removal

interface CardTopProps {
  backgroundColor: string;
}

export const StyledCard = styled.div<{
  isParticipation: boolean;
  cardWidth: string;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 2px;
  width: ${({ cardWidth }) => cardWidth};
  height: ${cardSize};
  background-color: ${ThemeV2.palette.gray1};
  border-radius: 4px;
  border: 1px solid ${ThemeV2.palette.gray4};
  transition: all 200ms ease-in-out;

  &:hover {
    transform: ${({ isParticipation }) => !isParticipation && 'scale(1.015)'};
    cursor: ${({ isParticipation }) => !isParticipation && '-moz-zoom-in'};
    cursor: ${({ isParticipation }) => !isParticipation && '-webkit-zoom-in'};
    cursor: ${({ isParticipation }) => !isParticipation && 'zoom-in'};
  }
`;

export const NewStyledImage = styled(Image)`
  width: 100%;
  border-radius: 3px 3px 0 0;
  object-fit: cover;
`;

export const StyledSVGIcon = styled(SVGIcon)`
  position: absolute;
  margin: 8px 0 0 8px;
`;

export const CardTop = styled.div<CardTopProps>`
  flex-shrink: 1;
  width: 100%;
  min-height: 48px;
  max-height: 96px;
  height: 76px;
  border-radius: 3px 3px 0 0;
  background-color: ${({ backgroundColor }) => backgroundColor};
  overflow: hidden;
`;

export const CardBottom = styled.div`
  flex-grow: 1;
  min-height: 36px;
  max-height: 84px;
  height: 56px;
  width: 100%;
  padding: 8px 12px;
  overflow: hidden;
`;

export const CardHeader = styled.div`
  position: relative;
`;

export const NewStyledBody = styled(Body)<{
  isParticipation: boolean;
}>`
  overflow-wrap: ${({ isParticipation }) => !isParticipation && 'break-word'};
  white-space: ${({ isParticipation }) =>
    isParticipation ? 'nowrap' : 'pre-wrap'};
  overflow: ${({ isParticipation }) => isParticipation && 'hidden'};
  text-overflow: ${({ isParticipation }) => isParticipation && 'ellipsis'};
`;

export const AvatarWrapper = styled.div`
  margin-right: 4px;
`;

export const StyledDropdownIconButton = styled(DropdownIconButton)`
  position: absolute;
  right: 0;
  margin-top: 8px;
  margin-right: 8px;
`;

export const StyledIconButton = styled(IconButton)`
  position: absolute;
  right: 0;
  margin-top: 8px;
  margin-right: 8px;
`;

export const NameWrapper = styled.div`
  display: flex;
`;

CardHeader.displayName = 'CardHeader';
