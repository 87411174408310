import React from 'react';
import styled from 'styled-components';

import Body from '../../atoms/Body';
import Heading from '../../atoms/Heading';

export interface FeedEmptyStateProps {
  image: string;
  title: string;
  message: string;
  className?: string;
}

const StyledWrapper = styled.div`
  max-width: 420px;
  margin: 55px auto 0 auto;
  text-align: center;
`;

const StyledImage = styled.img`
  margin-bottom: 44px;
`;

const StyledHeading = styled(Heading)`
  margin: 0;
  margin-bottom: 4px;
`;

const FeedEmptyState = (props: FeedEmptyStateProps) => {
  const { image, title, message, className } = props;

  return (
    <StyledWrapper className={className}>
      <StyledImage src={image} alt={title} />
      <StyledHeading variant="h6">{title}</StyledHeading>
      <Body color="gray9" variant="body1">
        {message}
      </Body>
    </StyledWrapper>
  );
};

export default FeedEmptyState;
