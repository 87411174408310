import ContentLoader from 'react-content-loader';
import React from 'react';

import ThemeV2 from '../../../componentsV2/theme';

const ProfileCelebrationStatisticsSectionLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={293}
      height={138}
      viewBox="0 0 293 138"
      backgroundColor={ThemeV2.palette.gray4}
      foregroundColor={ThemeV2.palette.gray3}
      uniqueKey="profile-celebration-statistics-section-loader"
    >
      <rect x="23" y="16" rx="4" width="148" height="20" />

      <circle cx="40" cy="62" r="16" />
      <rect x="65" y="54" rx="4" width="110" height="16" />

      <circle cx="40" cy="102" r="16" />
      <rect x="65" y="94" rx="4" width="110" height="16" />
    </ContentLoader>
  );
};

export default ProfileCelebrationStatisticsSectionLoader;
