import React, { ReactNode } from 'react';
import PrimaryLayout from '../../../atomic/templates/PrimaryLayout';
import CreateFlowsModalController from '../../flows/FlowsTabsController/CreateFlowsModalController';
import ParticipationFlowController from '../../flows/ParticipationFlowController';
import usePrimaryLayoutController from './usePrimaryLayoutController';
import { useFeatureSplit } from '../../../hooks/useFeatureSplit';
import {
  SplitNames,
  TreatmentTypes,
} from '../../../hooks/useSplitSdkConfig/constants';
import { Redirect } from 'react-router-dom';
import { getAnswerFlowRoute } from '../../../Utils/flows';

const PrimaryLayoutController = ({ children }: { children: ReactNode }) => {
  const { treatment: pageParticipationTreatment } = useFeatureSplit(
    SplitNames.NEW_PAGE_PARTICIPATION_VIEW,
  );

  const {
    models: {
      flowInstanceData,
      instanceError,
      isFlowOwner,
      isCreateFlowModalOpen,
      isLoading,
      isShortcutRespondedView,
      profileData,
      onSecondaryDrawerToggle,
      participationFlow,
      isNotebookView,
      hasTabComponent,
      hasContentFooter,
    },
    operations: {
      closeCreateFlowModal,
      closeParticipationModal,
      onAnswerWithShortcutButtonClick,
    },
  } = usePrimaryLayoutController();

  if (
    participationFlow &&
    participationFlow.participationFlowId &&
    pageParticipationTreatment === TreatmentTypes.ON
  ) {
    const { occurrenceId, participationFlowId: flowId } = participationFlow;
    return (
      <Redirect
        to={getAnswerFlowRoute(flowId, occurrenceId, location.pathname)}
      />
    );
  }

  return (
    <>
      <PrimaryLayout
        isFlowOwner={isFlowOwner}
        onSecondaryDrawerToggle={onSecondaryDrawerToggle}
        isNotebookView={isNotebookView}
        hasTabComponent={hasTabComponent}
        hasContentFooter={hasContentFooter}
      >
        {children}
      </PrimaryLayout>
      {!!participationFlow &&
        profileData &&
        pageParticipationTreatment !== TreatmentTypes.ON && (
          <ParticipationFlowController
            flowInstance={flowInstanceData}
            toggleParticipationModalOpen={closeParticipationModal}
            profileInfo={profileData}
            isLoading={isLoading}
            flowId={participationFlow.participationFlowId}
            flowErrorStatus={instanceError}
            isShortcutRespondedView={!!isShortcutRespondedView}
            onAnswerWithShortcutButtonClick={onAnswerWithShortcutButtonClick}
          />
        )}
      {isCreateFlowModalOpen && (
        <CreateFlowsModalController handleModalClose={closeCreateFlowModal} />
      )}
    </>
  );
};

export default PrimaryLayoutController;
