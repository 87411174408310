import { hoursToMinutes, minutesToHours } from 'date-fns';
import { AutocompleteDropdownItem } from '../../../../atomic/organism/Autocomplete/interfaces';

import { TimeUnitTypes } from '../../../../atomic/organism/TriggerConfigureSettings/types';
// eslint-disable-next-line max-len
import { FlowFrequencyOptions } from '../../../../controllers/flowsBuilder/FlowsBuilderTriggerController/ScheduleDateTimeController/types';

export const reduceMinutesFromDeadline = (deadlineInMinutes: number) =>
  deadlineInMinutes - 5;

/* to get the deadline in minutes - this is needed,
  say when the user set the deadline as 1 week, then we have to convert it to mins, like wise we have to do for all the
  deadline types[days, weeks & hours]
  Because we have to send the deadline in minutes to the BE
*/
export const getDeadlineInMinutes = (
  deadlineConfiguration: number,
  deadlineConfigurationTIme: TimeUnitTypes,
): number => {
  switch (deadlineConfigurationTIme) {
    case 'weeks': {
      const days = deadlineConfiguration * 7;
      const hours = days * 24;
      return hoursToMinutes(hours);
    }
    case 'days': {
      const hours = deadlineConfiguration * 24;
      return hoursToMinutes(hours);
    }
    case 'hours': {
      return hoursToMinutes(deadlineConfiguration);
    }
    default:
      return parseInt(deadlineConfiguration.toString(), 10);
  }
};

// to get the deadline configuration type like whether its a day,week,minutes or month
export const getDeadlineConfigurationTimeType = (
  endTimeInMinutes: number,
): TimeUnitTypes => {
  const hours = minutesToHours(endTimeInMinutes);
  const days = Math.round(hours / 24);

  if (days === 7 || days === 14 || days === 21 || days === 28) {
    return 'weeks';
  }
  if (days !== 0) {
    return 'days';
  }
  if (hours > 1) {
    return 'hours';
  }
  return 'minutes';
};

// to display the deadline
export const getDaysFromDeadline = (endTimeInMinutes: number) => {
  const deadlineConfigurationTimeType =
    getDeadlineConfigurationTimeType(endTimeInMinutes);
  const minutes = endTimeInMinutes;
  const hours = minutesToHours(endTimeInMinutes);
  const days = Math.round(hours / 24);
  const weeks = Math.round(days / 7);

  switch (deadlineConfigurationTimeType) {
    case 'weeks': {
      return weeks;
    }
    case 'days': {
      return days;
    }
    case 'hours': {
      return hours;
    }
    default:
      return minutes;
  }
};

export const getMinutesLimit = (
  selectedFrequencyOption: AutocompleteDropdownItem<string, undefined>,
) => {
  switch (selectedFrequencyOption.id) {
    case FlowFrequencyOptions.DAILY:
    case FlowFrequencyOptions.ONCE:
    case FlowFrequencyOptions.EVERY_WEEKDAY:
      return 1440;
    case FlowFrequencyOptions.WEEKLY:
      return 10080;
    case FlowFrequencyOptions.BIWEEKLY:
      return 20160;
    case FlowFrequencyOptions.MONTHLY:
    case FlowFrequencyOptions.MONTHLY_LAST:
      return 40320;
    case FlowFrequencyOptions.QUARTERLY:
    case FlowFrequencyOptions.QUARTERLY_LAST:
    default:
      // default indicates annually
      return 44640;
  }
};
