import isEqual from 'lodash/isEqual';
import uuid from 'uuid';
import {
  Assignee,
  AssignmentOperations,
  MiscTaskCategories,
  NotebookTask,
  PersonTypeFromAPI,
  TaskCategories,
} from '../../../interfaces/notebook';
import {
  TaskContentFromAPI,
  TiptapContentGroup,
} from '../../../interfaces/notebook/editor';

import addDays from 'date-fns/addDays';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import subDays from 'date-fns/subDays';
import ThemeV2 from '../../../componentsV2/theme';
import {
  ARCHIVE_BODY_TEXT,
  ARCHIVE_TASK,
  ASSIGNMENT_MODAL_FALLBACK,
  DELETE_BODY_TEXT,
  DELETE_TASK,
  getArchiveAndDeleteModalTextForAssignments,
  getReassignModalText,
  getReassignModalTextInSameTab,
  getUnassignModalText,
  getUnassignModalTextInSameTab,
  MARK_INCOMPLETE,
  MARK_INCOMPLETE_BODY_TEXT,
  REASSIGN_TASK,
  UNARCHIVE_BODY_TEXT,
  UNARCHIVE_TASK,
  UNASSIGN_TASK,
} from '../../../languages/en/notebook';
import {
  ARCHIVE,
  CANCEL,
  DELETE,
  UNARCHIVE,
} from '../../../languages/en/singleWords';
import { GetProfileInfoResponse } from '../../../queries/Profile';
import { defaultModalProps } from '../../../stores/notebookStore';
import { ModalProps } from '../../../stores/notebookStore/types';
import { getCurrentDateForTimeZone } from '../../../Utils/date';
import {
  isNotebookEditorEmpty,
  isTiptapEditorEmpty,
} from '../../../Utils/notebook';
import { capitalizeFirstLetter } from '../../../Utils/text';
import { MockPersonTypeUser } from '../NotebookGroupController/dummyData';

export const getTaskItemDueDate = (
  taskType: TaskCategories,
  timezone: string,
) => {
  const currentDateForTimeZone = getCurrentDateForTimeZone(timezone);
  switch (taskType) {
    case TaskCategories.TODAY:
      return currentDateForTimeZone.toISOString();

    case TaskCategories.UPCOMING: {
      return addDays(currentDateForTimeZone, 7).toISOString();
    }

    case TaskCategories.OVERDUE: {
      return subDays(currentDateForTimeZone, 1).toISOString();
    }

    default:
      return undefined;
  }
};

export const generateTask = (
  category: TaskCategories = TaskCategories.UNSCHEDULED,
  note: TaskContentFromAPI[] = [],
  timezone: string,
): NotebookTask => ({
  noteId: `${+new Date()}`,
  taskId: uuid.v4(),
  createdAt: getCurrentDateForTimeZone(timezone).toISOString(),
  dueDate: getTaskItemDueDate(category, timezone),
  note: isNotebookEditorEmpty(note) ? [] : note,
  type: category,
  isDeleted: false,
  assignedTo: null,
  description: [],
  createdBy: null,
  isUnread: false,
});

export const generateMockTask = (
  category: TaskCategories = TaskCategories.UNSCHEDULED,
) => {
  const mockNote: TaskContentFromAPI[] = [
    {
      type: 'text',
      value: 'Task 1234',
    },
  ];
  return {
    ...generateTask(category, mockNote, 'America/Los_Angeles'),
    customSortingIndex: Math.random(),
    createdBy: MockPersonTypeUser,
  };
};

export const generateTiptapEditorText = (
  content: TiptapContentGroup[] = [],
) => ({
  type: 'doc',
  content: isTiptapEditorEmpty(content) ? [] : content,
});

export const getDueDateBasedButtonColor = (
  dueDateString: string | undefined,
  taskCategory: TaskCategories,
  timeZone: string,
) => {
  if (
    taskCategory === TaskCategories.ARCHIVED ||
    taskCategory === TaskCategories.COMPLETED
  )
    return {
      background: ThemeV2.palette.gray3,
      hover: ThemeV2.palette.gray4,
    };

  if (dueDateString) {
    const dueDate = new Date(dueDateString);

    const noOfDaysTillDueDate = differenceInCalendarDays(
      dueDate,
      getCurrentDateForTimeZone(timeZone),
    );

    // If it is due today or tomorrow
    if (
      taskCategory === TaskCategories.TODAY ||
      noOfDaysTillDueDate === 0 ||
      noOfDaysTillDueDate === 1
    )
      return {
        background: ThemeV2.palette.orange2,
        hover: ThemeV2.palette.orange3,
      };

    if (
      (noOfDaysTillDueDate >= 2 && noOfDaysTillDueDate <= 14) ||
      taskCategory === TaskCategories.UPCOMING
    )
      return {
        background: ThemeV2.palette.geekBlue2,
        hover: ThemeV2.palette.geekBlue3,
      };

    if (noOfDaysTillDueDate < 0 || taskCategory === TaskCategories.OVERDUE)
      return {
        background: ThemeV2.palette.dustRed2,
        hover: ThemeV2.palette.dustRed4,
      };
  }

  return {
    background: ThemeV2.palette.gray3,
    hover: ThemeV2.palette.gray4,
  };
};

export const getTaskCategoryBasedOnDueDate = (
  dueDate: string | undefined,
  timezone: string,
): TaskCategories => {
  if (dueDate) {
    const currentDateForTimeZone = getCurrentDateForTimeZone(timezone);
    const noOfDaysTillDueDate = differenceInCalendarDays(
      new Date(dueDate),
      currentDateForTimeZone,
    );
    if (noOfDaysTillDueDate === 0) return TaskCategories.TODAY;

    if (noOfDaysTillDueDate > 0) return TaskCategories.UPCOMING;

    if (noOfDaysTillDueDate < 0) return TaskCategories.OVERDUE;
  }
  return TaskCategories.UNSCHEDULED;
};

export const getModalPropsBasedOnTab = (
  currentUser: string | undefined,
  operation: AssignmentOperations,
  task: NotebookTask | undefined,
  previousAssignee?: string,
): ModalProps => {
  if (currentUser === task?.createdBy?.memberID) {
    if (task?.assignedTo?.memberID !== currentUser && previousAssignee) {
      return {
        heading:
          operation === 'reassign' ? `${REASSIGN_TASK}?` : `${UNASSIGN_TASK}`,
        bodyText:
          operation === 'reassign'
            ? getReassignModalTextInSameTab(
                task?.assignedTo?.name || '',
                previousAssignee || '',
              )
            : getUnassignModalTextInSameTab(previousAssignee || ''),
        primaryButtonText: capitalizeFirstLetter(REASSIGN_TASK),
        secondaryButtonText: capitalizeFirstLetter(CANCEL),
        primaryButtonType: 'default',
      };
    }
    return {
      heading:
        operation === 'reassign' ? `${REASSIGN_TASK}?` : `${UNASSIGN_TASK}`,
      bodyText:
        operation === 'reassign'
          ? getReassignModalText(true, task?.assignedTo?.name || '')
          : getUnassignModalText(true),
      primaryButtonText: capitalizeFirstLetter(
        operation === 'reassign' ? REASSIGN_TASK : UNASSIGN_TASK,
      ),
      secondaryButtonText: capitalizeFirstLetter(CANCEL),
      primaryButtonType: 'default',
    };
  }

  if (currentUser !== task?.createdBy?.memberID) {
    return {
      heading:
        operation === 'reassign' ? `${REASSIGN_TASK}?` : `${UNASSIGN_TASK}`,
      bodyText:
        operation === 'reassign'
          ? getReassignModalText(
              false,
              task?.assignedTo?.name || '',
              task?.createdBy?.name,
            )
          : getUnassignModalText(false, task?.createdBy?.name),
      primaryButtonText: capitalizeFirstLetter(
        operation === 'reassign' ? REASSIGN_TASK : UNASSIGN_TASK,
      ),
      secondaryButtonText: capitalizeFirstLetter(CANCEL),
      primaryButtonType: 'default',
    };
  }

  return {
    heading: `${UNASSIGN_TASK}?`,
    bodyText: ASSIGNMENT_MODAL_FALLBACK,
    primaryButtonText: capitalizeFirstLetter(UNASSIGN_TASK),
    secondaryButtonText: capitalizeFirstLetter(CANCEL),
    primaryButtonType: 'default',
  };
};

export const getModalPropsBasedOnCategory = (
  taskCategory: TaskCategories | MiscTaskCategories | undefined,
): ModalProps => {
  switch (taskCategory) {
    case MiscTaskCategories.INCOMPLETE:
      return {
        heading: `${MARK_INCOMPLETE}?`,
        bodyText: MARK_INCOMPLETE_BODY_TEXT,
        primaryButtonText: capitalizeFirstLetter(MARK_INCOMPLETE),
        secondaryButtonText: capitalizeFirstLetter(CANCEL),
        primaryButtonType: 'default',
      };

    case TaskCategories.ARCHIVED:
      return {
        heading: `${ARCHIVE_TASK}?`,
        bodyText: ARCHIVE_BODY_TEXT,
        primaryButtonText: capitalizeFirstLetter(ARCHIVE),
        secondaryButtonText: capitalizeFirstLetter(CANCEL),
        primaryButtonType: 'warning',
      };

    case MiscTaskCategories.UNARCHIVE:
      return {
        heading: `${UNARCHIVE_TASK}?`,
        bodyText: UNARCHIVE_BODY_TEXT,
        primaryButtonText: capitalizeFirstLetter(UNARCHIVE),
        secondaryButtonText: capitalizeFirstLetter(CANCEL),
        primaryButtonType: 'default',
      };

    case MiscTaskCategories.DELETE:
      return {
        heading: `${DELETE_TASK}?`,
        bodyText: DELETE_BODY_TEXT,
        primaryButtonText: capitalizeFirstLetter(DELETE),
        secondaryButtonText: capitalizeFirstLetter(CANCEL),
        primaryButtonType: 'warning',
      };

    default:
      return defaultModalProps;
  }
};

export const getModalPropsBasedOnCategoryAndTab = (
  task: NotebookTask,
  currentUserId: string,
  taskCategory: TaskCategories | MiscTaskCategories | undefined,
): ModalProps => {
  const isTaskCreatedByCurrentUser =
    currentUserId === task?.createdBy?.memberID;
  const isTaskAssigneeCreator = currentUserId === task?.assignedTo?.memberID;

  switch (taskCategory) {
    case TaskCategories.ARCHIVED:
      return {
        heading: `${ARCHIVE_TASK}?`,
        bodyText: getArchiveAndDeleteModalTextForAssignments(
          isTaskCreatedByCurrentUser,
          isTaskAssigneeCreator,
          task?.createdBy?.name || '',
          task?.assignedTo?.name || '',
        ),
        primaryButtonText: capitalizeFirstLetter(ARCHIVE),
        secondaryButtonText: capitalizeFirstLetter(CANCEL),
        primaryButtonType: 'warning',
      };

    default:
      return getModalPropsBasedOnCategory(taskCategory);
  }
};

export const hasTaskChanged = (
  notes: NotebookTask[],
  updatedTask: NotebookTask,
) => {
  const currentTask = notes.find((task) => task.taskId === updatedTask.taskId);
  return !isEqual(currentTask, updatedTask);
};

export const extractAssignedToInformation = (
  profileInfo: GetProfileInfoResponse | undefined,
): Assignee =>
  profileInfo
    ? ({
        username: profileInfo?.member?.profile?.username,
        memberID: profileInfo?.member?.memberId,
        name: `${profileInfo?.member?.profile?.firstName} ${profileInfo?.member?.profile?.lastName}`,
        timezone: profileInfo?.member?.timeZone,
        image: profileInfo?.member?.profile?.image,
      } as Assignee)
    : null;

export const extractAssignedToFromAPIResponse = (
  assignedTo: PersonTypeFromAPI,
): Assignee =>
  assignedTo
    ? ({
        username: assignedTo?.username,
        memberID: assignedTo?.memberID,
        name: assignedTo?.name,
        timezone: '',
        image: assignedTo?.image,
      } as Assignee)
    : null;

export const getCreatedByFromProfile = (
  profileInfo: GetProfileInfoResponse | undefined,
): PersonTypeFromAPI | null =>
  profileInfo
    ? ({
        username: profileInfo?.member?.profile?.username,
        memberID: profileInfo?.member?.memberId,
        name: `${profileInfo?.member?.profile?.firstName} ${profileInfo?.member?.profile?.lastName}`,
        timezone: profileInfo?.member?.timeZone,
        image: profileInfo?.member?.profile?.image,
        firstName: profileInfo?.member?.profile?.firstName,
        lastName: profileInfo?.member?.profile?.lastName,
        pointsGiven: '',
        totalPointsGiven: '',
        memberState: profileInfo?.member?.status,
        role: profileInfo?.member?.role,
      } as PersonTypeFromAPI)
    : null;

export const getCurrentTab = (inputPath: string) =>
  inputPath.substring(inputPath.lastIndexOf('/') + 1);
