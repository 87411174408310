import differenceInMinutes from 'date-fns/differenceInMinutes';
import format from 'date-fns/format';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RRule } from 'rrule';

import { AutocompleteDropdownItem } from '../../../../atomic/organism/Autocomplete/interfaces';
import timezones from '../../../../Utils/data/timezones/index.json';

import {
  FlowDeadlineProps,
  FlowEndProps,
  FlowRecurProps,
  FlowStartProps,
  FlowTimezoneProps,
  ShortcutConfigurationProps,
  TimeUnitTypes,
} from '../../../../atomic/organism/TriggerConfigureSettings/types';

import Body from '../../../../atomic/atoms/Body';
import IconInfo from '../../../../atomic/molecules/IconInfo';
import ThemeV2 from '../../../../componentsV2/theme';

import {
  MenuItemIndividualItem,
  MenuItemProps,
} from '../../../../atomic/molecules/Dropdown_V2/interfaces';
import { SingleSelectAutoComplete } from '../../../../atomic/organism/Autocomplete';
import {
  CustomRecurrenceProps,
  SelectedCustomRecurrenceTypes,
} from '../../../../atomic/organism/CustomRecurrence/types';
import {
  StyledCalendarInput,
  StyledErrorBody,
  StyledFlex,
  StyledFlowEndCalendarInputWrapper,
  StyledSingleSelectAutoCompleteWrapper,
} from '../../../../atomic/organism/TriggerConfigureSettings/styles';
import useGetTimeOptions from '../../../../hooks/useGetTimeOptions';
import useToggle from '../../../../hooks/useToggle';
import { ErrorEntry } from '../../../../interfaces/Flow/Builder';
import {
  CHOOSE_TIMEZONE,
  CONFIGURE_SETTINGS_ERROR_MESSAGE,
  CONFIGURE_SETTINGS_FLOW_END_BLOCK,
  CONFIGURE_SETTINGS_PICK_A_DATE,
  CONFIGURE_SETTINGS_PICK_A_TIME,
  DAYS,
  HOURS,
  MINUTES,
  MONTHLY_ON_THE,
  WEEKS,
} from '../../../../languages/en/flows/builder';
import { AT } from '../../../../languages/en/singleWords';
import { useProfileInfoFetchQuery } from '../../../../queries/Profile';
import {
  getDayIdsForCustomOccurrence,
  getWeekDetails,
  isSameDay,
} from '../../../../Utils/flows/scheduler';
import { getDefaultSelectedCustomRecurrenceTypes } from '../../../../Utils/flows/scheduler/customOccurrence';
import {
  getDefaultFlowEndDate,
  getDefaultFlowEndDateTime,
  getDefaultFlowFrequencyTime,
  getDefaultFlowStartDate,
} from '../../../../Utils/flows/scheduler/dateAndTime';
import {
  getDaysFromDeadline,
  getDeadlineConfigurationTimeType,
  getDeadlineInMinutes,
  getMinutesLimit,
  reduceMinutesFromDeadline,
} from '../../../../Utils/flows/scheduler/deadline';
import {
  checkFlowDeadlineSelection,
  checkRecurrenceError,
  customOccurrenceEndTimeLimit,
  customOccurrenceErrorCheck,
} from '../../../../Utils/flows/scheduler/errorCheck';
import {
  getDayNameFromNumber,
  getFlowFrequencyOptions,
  hasWeekDays,
} from '../../../../Utils/flows/scheduler/flowFrequencyOptions';
import {
  getDateMonthYear,
  getRRuleString,
  getTime,
} from '../../../../Utils/flows/scheduler/formRRulString';
import { getFlowFrequency } from '../../../../Utils/flows/scheduler/frequencyValue';
import {
  getDefaultDisableScheduleEndDatePicker,
  getDefaultScheduleEndValue,
} from '../../../../Utils/flows/scheduler/scheduleEnd';
import {
  FlowFrequencyOptions,
  GetRRuleStringProps,
  ScheduleDateTimeControllerTypes,
} from './types';

import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import isEmpty from 'lodash/isEmpty';
import useWindowFocus from '../../../../hooks/useWindowFocus';
import useFlowBuilderStore from '../../../../stores/flowBuilderStore';
import {
  flowNameSelector,
  templateNameSelector,
} from '../../../../stores/flowBuilderStore/selectors';
import { FLOW_BUILDER_EVENTS } from '../../../../Utils/analytics/constants';
import { trackFlowBuilderActionEvent } from '../../../../Utils/analytics/flowsBuilder';

const useScheduleDateTimeController = (
  props: ScheduleDateTimeControllerTypes,
) => {
  const {
    templateId,
    endTimeInMinutes,
    updateEndTimeInMinutes,
    shortcut: isOccurrenceOn,
    onShortcutToggle: setIsOccurrenceOn,
    setIsNextButtonDisabled,
    triggerBlockData,
    hasActiveOccurrence,
    updateErrorData,
    updateTriggerBlockData,
    isInEditMode,
  } = props;

  const templateName = useFlowBuilderStore(templateNameSelector);
  const flowName = useFlowBuilderStore(flowNameSelector);

  const [isFromTemplate, setIsFromTemplate] = useState(Boolean(templateId));
  const { isWindowFocused } = useWindowFocus();
  const { flowFrequencyTimeOptions: timeOptions } = useGetTimeOptions();

  const {
    models: { toggleValue: isCustomRecurrenceModalOpen },
    operations: {
      setToggleToTrue: openCustomRecurrenceModal,
      setToggleToFalse: closeCustomRecurrenceModal,
    },
  } = useToggle(false);

  const isValidationEnabled = useMemo(
    () =>
      (isInEditMode &&
        triggerBlockData.isSchedulerTouched &&
        isWindowFocused) ||
      (!isInEditMode && isWindowFocused),
    [isInEditMode, isWindowFocused, triggerBlockData.isSchedulerTouched],
  );
  const selectedCustomRecurrenceTypes = useMemo(
    () => getDefaultSelectedCustomRecurrenceTypes(triggerBlockData),
    [triggerBlockData],
  );

  const [maxMinutes, setMaxMinutes] = useState(1440);
  const [disableWeekOption, setDisableWeekOption] = useState(false);
  const [disableScheduleEndOptions, setDisableScheduleEndOptions] = useState(
    () => {
      if (
        triggerBlockData.triggerType === 'SCHEDULED' &&
        triggerBlockData.schedule
      ) {
        const parsedString = RRule.parseString(triggerBlockData.schedule.rule);
        const flowFrequencyValue = getFlowFrequency(parsedString);
        if (flowFrequencyValue === FlowFrequencyOptions.ONCE) {
          return true;
        }
        return false;
      }
      return false;
    },
  );
  const [disableScheduleEndDatePicker, setDisableScheduleEndDatePicker] =
    useState(getDefaultDisableScheduleEndDatePicker(triggerBlockData));

  const flowStartDate = useMemo(
    () => getDefaultFlowStartDate(triggerBlockData.schedule),
    [triggerBlockData.schedule],
  );

  const [isDueDateInterruptsOccurrence, setDueDateInterruptsOccurrence] =
    useState(false);
  const [isStartDateOccurredInPast, setStartDateOccurredInPast] =
    useState(false);
  const [isEndDateOccurredInPast, setEndDateOccurredInPast] = useState(false);

  const { data: profileData } = useProfileInfoFetchQuery();

  const formattedTimezones = useMemo(() => {
    return timezones.map((timezone) => {
      return {
        value: timezone.value,
        id: timezone.key,
        title: timezone.value,
      };
    });
  }, []);

  const defaultTimezone = profileData?.assembly.timeZone;
  const userTimeZone = profileData?.member.timeZone;

  const getTimeZoneIndex = useCallback(
    (timeZoneString: string | undefined) => {
      let timeZoneIndex = 0;
      if (timeZoneString) {
        timeZoneIndex = formattedTimezones.findIndex(
          (timezone) => timezone.id === timeZoneString,
        );

        if (timeZoneIndex < 0) {
          return 0;
        }
      }

      return timeZoneIndex;
    },
    [formattedTimezones],
  );

  const selectedTimezone = useMemo(() => {
    const aliasTimeZones: Record<string, string> = {
      'Asia/Calcutta': 'Asia/Kolkata',
    };

    if (
      triggerBlockData.triggerType === 'SCHEDULED' &&
      triggerBlockData.schedule
    ) {
      const parsedString = RRule.parseString(triggerBlockData.schedule.rule);
      if (parsedString.tzid) {
        const tzid = aliasTimeZones[parsedString.tzid]
          ? aliasTimeZones[parsedString.tzid]
          : parsedString.tzid;

        return formattedTimezones[getTimeZoneIndex(tzid)];
      }
    }
    return userTimeZone
      ? formattedTimezones[getTimeZoneIndex(userTimeZone)]
      : formattedTimezones[getTimeZoneIndex(defaultTimezone)];
  }, [
    defaultTimezone,
    userTimeZone,
    formattedTimezones,
    getTimeZoneIndex,
    triggerBlockData,
  ]);

  /* Flow recur */
  const [day, dayNumber, weekDetails] = useMemo(
    () =>
      flowStartDate
        ? [
            format(flowStartDate, 'EEEE'),
            format(flowStartDate, 'd'),
            getWeekDetails(flowStartDate),
          ]
        : ['', ''],
    [flowStartDate],
  );

  const flowFrequencyOptions: AutocompleteDropdownItem<string>[] = useMemo(
    () => getFlowFrequencyOptions(flowStartDate, selectedCustomRecurrenceTypes),
    [flowStartDate, selectedCustomRecurrenceTypes],
  );

  const [flowFrequencyTimeOptions, setFlowFrequencyTimeOptions] =
    useState(timeOptions);

  const flowFrequencyTime = useMemo(
    () =>
      getDefaultFlowFrequencyTime(
        isFromTemplate,
        triggerBlockData,
        flowFrequencyTimeOptions,
      ),
    [flowFrequencyTimeOptions, triggerBlockData, isFromTemplate],
  );

  const [previousFlowFrequency, setPreviousFlowFrequency] =
    useState<AutocompleteDropdownItem<string> | null>(flowFrequencyOptions[0]);

  const flowFrequency = useMemo(() => {
    if (
      triggerBlockData.triggerType === 'SCHEDULED' &&
      triggerBlockData.schedule
    ) {
      const parsedString = RRule.parseString(triggerBlockData.schedule.rule);
      const getFlowFrequencyValue = getFlowFrequency(parsedString);
      const selectedFrequencyOption:
        | AutocompleteDropdownItem<string, undefined>[]
        | null = flowFrequencyOptions.filter(
        (flowFrequencyOption) =>
          flowFrequencyOption.id === getFlowFrequencyValue,
      );
      if (
        selectedFrequencyOption[0]?.id === FlowFrequencyOptions.DAILY ||
        selectedFrequencyOption[0]?.id === FlowFrequencyOptions.EVERY_WEEKDAY
      ) {
        setDisableWeekOption(true);
      }
      if (
        selectedFrequencyOption[0]?.id === FlowFrequencyOptions.REPEAT_WEEKS
      ) {
        setDisableWeekOption(false);
      }
      if (
        selectedFrequencyOption === undefined ||
        selectedFrequencyOption.length === 0
      ) {
        switch (getFlowFrequencyValue) {
          case FlowFrequencyOptions.MONTHLY: {
            const filteredOption = flowFrequencyOptions.filter(
              (option) => option.id === FlowFrequencyOptions.MONTHLY_LAST,
            )[0];
            setMaxMinutes(getMinutesLimit(filteredOption));
            return filteredOption;
          }
          case FlowFrequencyOptions.QUARTERLY: {
            const filteredOption = flowFrequencyOptions.filter(
              (option) => option.id === FlowFrequencyOptions.QUARTERLY_LAST,
            )[0];
            setMaxMinutes(getMinutesLimit(filteredOption));
            return filteredOption;
          }
          case FlowFrequencyOptions.QUARTERLY_LAST: {
            const filteredOption = flowFrequencyOptions.filter(
              (option) => option.id === FlowFrequencyOptions.QUARTERLY,
            )[0];
            setMaxMinutes(getMinutesLimit(filteredOption));
            return filteredOption;
          }
          case FlowFrequencyOptions.MONTHLY_LAST: {
            const filteredOption = flowFrequencyOptions.filter(
              (option) => option.id === FlowFrequencyOptions.MONTHLY,
            )[0];
            setMaxMinutes(getMinutesLimit(filteredOption));
            return filteredOption;
          }
          default: {
            setMaxMinutes(getMinutesLimit(selectedFrequencyOption[0]));
            return selectedFrequencyOption[0];
          }
        }
      }
      if (selectedCustomRecurrenceTypes !== undefined) {
        switch (selectedCustomRecurrenceTypes.repeatEvery) {
          case 'days': {
            if (selectedCustomRecurrenceTypes.repeatEveryCount === 1) {
              const selectedOption = flowFrequencyOptions.filter(
                (option) => option.id === FlowFrequencyOptions.DAILY,
              );
              return selectedOption[0];
            }
            return flowFrequencyOptions[flowFrequencyOptions.length - 2];
          }
          case 'months': {
            if (flowStartDate) {
              if (
                selectedCustomRecurrenceTypes.repeatEveryCount === 1 &&
                weekDetails &&
                selectedCustomRecurrenceTypes.repeatOnMonth ===
                  'repeatMonthlyOn'
              ) {
                if (weekDetails.isLastWeek) {
                  const selectedOption = flowFrequencyOptions.filter(
                    (option) => option.id === FlowFrequencyOptions.MONTHLY_LAST,
                  );
                  return selectedOption[0];
                }
                const selectedOption = flowFrequencyOptions.filter(
                  (option) => option.id === FlowFrequencyOptions.MONTHLY,
                );
                return selectedOption[0];
              }
              if (
                selectedCustomRecurrenceTypes.repeatEveryCount === 3 &&
                weekDetails &&
                selectedCustomRecurrenceTypes.repeatOnMonth ===
                  'repeatMonthlyOn'
              ) {
                if (weekDetails.isLastWeek) {
                  const selectedOption = flowFrequencyOptions.filter(
                    (option) =>
                      option.id === FlowFrequencyOptions.QUARTERLY_LAST,
                  );
                  return selectedOption[0];
                }
                const selectedOption = flowFrequencyOptions.filter(
                  (option) => option.id === FlowFrequencyOptions.QUARTERLY,
                );
                return selectedOption[0];
              }
              return flowFrequencyOptions[flowFrequencyOptions.length - 2];
            }
            break;
          }
          case 'weeks': {
            const dayNames = selectedCustomRecurrenceTypes.selectedDayIds.map(
              (val) => getDayNameFromNumber(val),
            );
            const includeWeekDays = hasWeekDays(
              selectedCustomRecurrenceTypes.selectedDayIds,
            );
            const receivedDay = selectedCustomRecurrenceTypes.selectedDayIds[0];
            if (
              includeWeekDays &&
              selectedCustomRecurrenceTypes.repeatEveryCount === 1
            ) {
              const selectedOption = flowFrequencyOptions.filter(
                (option) => option.id === FlowFrequencyOptions.EVERY_WEEKDAY,
              );
              return selectedOption[0];
            }
            if (
              selectedCustomRecurrenceTypes.repeatEveryCount === 1 &&
              dayNames.length === 1 &&
              isSameDay(flowStartDate, receivedDay)
            ) {
              const selectedOption = flowFrequencyOptions.filter(
                (option) => option.id === FlowFrequencyOptions.WEEKLY,
              );
              return selectedOption[0];
            }
            if (
              selectedCustomRecurrenceTypes.repeatEveryCount === 2 &&
              dayNames.length === 1
            ) {
              const selectedOption = flowFrequencyOptions.filter(
                (option) => option.id === FlowFrequencyOptions.BIWEEKLY,
              );
              return selectedOption[0];
            }
            return flowFrequencyOptions[flowFrequencyOptions.length - 2];
          }
          default: {
            if (selectedCustomRecurrenceTypes.repeatEveryCount === 1) {
              const selectedOption = flowFrequencyOptions.filter(
                (option) => option.id === FlowFrequencyOptions.ANNUALLY,
              );
              return selectedOption[0];
            }
            const selectedOption = flowFrequencyOptions.filter(
              (option) => option.id === FlowFrequencyOptions.REPEAT_YEARS,
            );
            return selectedOption[0];
          }
        }
      }
      if (
        selectedFrequencyOption.length > 0 &&
        selectedCustomRecurrenceTypes === undefined
      ) {
        setMaxMinutes(getMinutesLimit(selectedFrequencyOption[0]));
        return selectedFrequencyOption[0];
      }
    }
    return flowFrequencyOptions[0];
  }, [
    triggerBlockData,
    selectedCustomRecurrenceTypes,
    flowFrequencyOptions,
    flowStartDate,
    weekDetails,
  ]);

  /* Flow deadline */
  const [deadlineConfiguration, setDeadlineConfiguration] = useState(() => {
    if (endTimeInMinutes > 0) {
      return getDaysFromDeadline(endTimeInMinutes);
    }
    return 10;
  });
  const [deadlineConfigurationTIme, setDeadlineConfigurationTime] =
    useState<TimeUnitTypes>(() => {
      if (endTimeInMinutes > 0) {
        const deadlineConfigurationTimeValue =
          getDeadlineConfigurationTimeType(endTimeInMinutes);
        return deadlineConfigurationTimeValue;
      }
      return 'minutes';
    });
  const [reminderExtendsError, setReminderExtendsError] = useState(false);
  const [recurrenceError, setRecurrenceError] = useState<string | null>(null);

  useEffect(() => {
    const error: ErrorEntry = {
      id: 'reminder',
      message: 'reminder',
    };
    if (isValidationEnabled) {
      if (
        reminderExtendsError ||
        recurrenceError ||
        isDueDateInterruptsOccurrence
      ) {
        trackFlowBuilderActionEvent({
          action: FLOW_BUILDER_EVENTS.SCHEDULE_DUE_DATE_ERROR,
          templateId,
          templateName,
          flowTitle: flowName,
        });
      }
      if (isStartDateOccurredInPast) {
        trackFlowBuilderActionEvent({
          action: FLOW_BUILDER_EVENTS.SCHEDULE_START_TIME_ERROR,
          templateId,
          templateName,
          flowTitle: flowName,
        });
        trackFlowBuilderActionEvent({
          action: FLOW_BUILDER_EVENTS.SCHEDULE_START_DATE_ERROR,
          templateId,
          templateName,
          flowTitle: flowName,
        });
      }
      if (isEndDateOccurredInPast) {
        trackFlowBuilderActionEvent({
          action: FLOW_BUILDER_EVENTS.SCHEDULE_END_DATE_ERROR,
          templateId,
          templateName,
          flowTitle: flowName,
        });
        trackFlowBuilderActionEvent({
          action: FLOW_BUILDER_EVENTS.SCHEDULE_END_DATE_ERROR,
          templateId,
          templateName,
          flowTitle: flowName,
        });
      }
      if (
        (reminderExtendsError ||
          recurrenceError ||
          isStartDateOccurredInPast ||
          isEndDateOccurredInPast ||
          isDueDateInterruptsOccurrence) &&
        isEmpty(triggerBlockData.errors)
      ) {
        updateErrorData(error);
      } else if (
        !reminderExtendsError &&
        !recurrenceError &&
        !isStartDateOccurredInPast &&
        !isEndDateOccurredInPast &&
        !isDueDateInterruptsOccurrence
      ) {
        // Update to null if no error exists
        updateErrorData(null);
      }
    }
  }, [
    recurrenceError,
    updateErrorData,
    isValidationEnabled,
    reminderExtendsError,
    triggerBlockData.errors,
    isEndDateOccurredInPast,
    isStartDateOccurredInPast,
    isDueDateInterruptsOccurrence,
    templateId,
    templateName,
    flowName,
  ]);

  /* Flow schedule end */

  const flowEndDate = useMemo(
    () => getDefaultFlowEndDate(triggerBlockData.schedule),
    [triggerBlockData.schedule],
  );
  const flowEndDateTime = useMemo(
    () => getDefaultFlowEndDateTime(triggerBlockData, flowFrequencyTimeOptions),
    [flowFrequencyTimeOptions, triggerBlockData],
  );
  const [scheduleEndValue, setScheduleEndValue] = useState(
    getDefaultScheduleEndValue(triggerBlockData),
  );

  /* Shortcut configuration */
  const onOccurrenceToggle = () => {
    trackFlowBuilderActionEvent({
      action: FLOW_BUILDER_EVENTS.SHORT_CUT,
      flowTitle: flowName,
      templateId,
      templateName,
    });
    setIsOccurrenceOn(!isOccurrenceOn);
  };

  /* Flow recur */
  const onFlowFrequencyChange = useCallback(
    (val: AutocompleteDropdownItem<string> | null) => {
      trackFlowBuilderActionEvent({
        action: FLOW_BUILDER_EVENTS.RECURRENCE_SELECTED,
        recurrenceSelected: val?.id,
        templateId,
        templateName,
        flowTitle: flowName,
      });
      const getRRuleStringProps: GetRRuleStringProps = {
        flowStartDate,
        flowEndDate:
          val?.id === FlowFrequencyOptions.ONCE ? undefined : flowEndDate,
        flowFrequency: val,
        flowFrequencyTime,
        flowEndDateTime:
          val?.id === FlowFrequencyOptions.ONCE ? null : flowEndDateTime,
        selectedTimezone,
        selectedCustomRecurrenceTypes:
          val?.id.includes('repeat') !== true &&
          val?.id !== FlowFrequencyOptions.CUSTOM
            ? undefined
            : selectedCustomRecurrenceTypes,
      };
      const updatedRRuleString = getRRuleString(getRRuleStringProps);

      let receivedMaxMinutes = 0;
      const deadlineInMinutes = getDeadlineInMinutes(
        deadlineConfiguration,
        deadlineConfigurationTIme,
      );
      let updatedEndTimeInMinutes = deadlineInMinutes;
      if (val !== null) {
        receivedMaxMinutes = getMinutesLimit(val);
        setMaxMinutes(receivedMaxMinutes);
      }

      if (
        receivedMaxMinutes === deadlineInMinutes &&
        val?.id.includes(FlowFrequencyOptions.ONCE) === false
      ) {
        updatedEndTimeInMinutes = reduceMinutesFromDeadline(deadlineInMinutes);
      }

      updateTriggerBlockData({
        ...triggerBlockData,
        selectedCustomRecurrenceTypes:
          val?.id.includes('repeat') !== true &&
          val?.id !== FlowFrequencyOptions.CUSTOM
            ? undefined
            : selectedCustomRecurrenceTypes,
        schedule: {
          rule: updatedRRuleString.toString(),
        },
        endTimeInMinutes: updatedEndTimeInMinutes,
      });

      setPreviousFlowFrequency(flowFrequency);

      if (val?.id.includes('repeat')) {
        const hasCustomOccurrenceError = customOccurrenceErrorCheck(
          endTimeInMinutes,
          selectedCustomRecurrenceTypes,
        );
        setReminderExtendsError(hasCustomOccurrenceError);
        const customOccurrenceLimit = customOccurrenceEndTimeLimit(
          endTimeInMinutes,
          selectedCustomRecurrenceTypes,
        );
        if (customOccurrenceLimit) {
          updateEndTimeInMinutes(customOccurrenceLimit);
        } else if (reminderExtendsError) {
          setReminderExtendsError(false);
        }
      } else {
        setReminderExtendsError(
          checkFlowDeadlineSelection(
            deadlineConfiguration,
            deadlineConfigurationTIme,
            flowFrequency?.id,
          ),
        );
      }

      if (val !== null) {
        setReminderExtendsError(
          checkFlowDeadlineSelection(
            deadlineConfiguration,
            deadlineConfigurationTIme,
            val.id,
          ),
        );
        setRecurrenceError(
          checkRecurrenceError(
            deadlineConfiguration,
            deadlineConfigurationTIme,
          ),
        );
        if (val.id === FlowFrequencyOptions.CUSTOM) {
          openCustomRecurrenceModal();
        }
        if (val.id === FlowFrequencyOptions.ONCE) {
          setDisableScheduleEndOptions(true);
        } else {
          setDisableScheduleEndOptions(false);
        }
        if (
          val.id === FlowFrequencyOptions.DAILY ||
          val.id === FlowFrequencyOptions.EVERY_WEEKDAY
        ) {
          setDisableWeekOption(true);
        } else {
          setDisableWeekOption(false);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      flowStartDate,
      flowEndDate,
      flowEndDateTime,
      flowFrequency,
      flowFrequencyTime,
      selectedTimezone,
      selectedCustomRecurrenceTypes,
      triggerBlockData,
      updateTriggerBlockData,
      deadlineConfiguration,
      deadlineConfigurationTIme,
      openCustomRecurrenceModal,
      endTimeInMinutes,
      deadlineConfiguration,
      deadlineConfigurationTIme,
    ],
  );

  const onFlowFrequencyTimeChange = useCallback(
    (val: AutocompleteDropdownItem<number> | null) => {
      trackFlowBuilderActionEvent({
        action: FLOW_BUILDER_EVENTS.SCHEDULE_START_TIME_SELECTED,
        scheduleStartTimeSelected: val?.title,
        templateId,
        templateName,
        flowTitle: flowName,
      });
      setIsFromTemplate(false);
      if (val === null) {
        /* to reset the timeOptions dropdown values */
        setFlowFrequencyTimeOptions(timeOptions);
      } else {
        const getRRuleStringProps: GetRRuleStringProps = {
          flowStartDate,
          flowEndDate,
          flowFrequency,
          flowFrequencyTime: val,
          flowEndDateTime,
          selectedTimezone,
          selectedCustomRecurrenceTypes,
        };
        const updatedRRuleString = getRRuleString(getRRuleStringProps);

        updateTriggerBlockData({
          ...triggerBlockData,
          schedule: {
            rule: updatedRRuleString.toString(),
          },
        });
      }
    },
    [
      templateId,
      templateName,
      flowName,
      timeOptions,
      flowStartDate,
      flowEndDate,
      flowFrequency,
      flowEndDateTime,
      selectedTimezone,
      selectedCustomRecurrenceTypes,
      updateTriggerBlockData,
      triggerBlockData,
    ],
  );

  /* Flow deadline */
  const onDeadlineConfigurationChange = (val: number) => {
    let receivedMaxMinutes = maxMinutes;
    if (
      flowFrequency &&
      flowFrequency.id.includes('repeat') === false &&
      flowFrequency.id.includes(FlowFrequencyOptions.ONCE) === false
    ) {
      receivedMaxMinutes = getMinutesLimit(flowFrequency);
      setMaxMinutes(receivedMaxMinutes);
    }
    const deadlineInMinutes = getDeadlineInMinutes(
      val,
      deadlineConfigurationTIme,
    );
    if (receivedMaxMinutes === deadlineInMinutes) {
      updateEndTimeInMinutes(reduceMinutesFromDeadline(deadlineInMinutes));
      trackFlowBuilderActionEvent({
        action: FLOW_BUILDER_EVENTS.DUE_DATE,
        dueDate: reduceMinutesFromDeadline(deadlineInMinutes),
        templateId,
        templateName,
        flowTitle: flowName,
      });
    } else {
      updateEndTimeInMinutes(deadlineInMinutes);
      trackFlowBuilderActionEvent({
        action: FLOW_BUILDER_EVENTS.DUE_DATE,
        dueDate: deadlineInMinutes,
        templateId,
        templateName,
        flowTitle: flowName,
      });
    }
    setDeadlineConfiguration(val);
    setReminderExtendsError(
      checkFlowDeadlineSelection(
        val,
        deadlineConfigurationTIme,
        flowFrequency?.id,
      ),
    );
    setRecurrenceError(checkRecurrenceError(val, deadlineConfigurationTIme));
  };
  const onDeadlineConfigurationTImeChange = (ddValue: string | number) => {
    let receivedMaxMinutes = maxMinutes;
    if (
      flowFrequency &&
      flowFrequency.id.includes('repeat') === false &&
      flowFrequency.id.includes(FlowFrequencyOptions.ONCE) === false
    ) {
      receivedMaxMinutes = getMinutesLimit(flowFrequency);
      setMaxMinutes(receivedMaxMinutes);
    }
    const deadlineInMinutes = getDeadlineInMinutes(
      deadlineConfiguration,
      ddValue as TimeUnitTypes,
    );
    if (receivedMaxMinutes === deadlineInMinutes) {
      updateEndTimeInMinutes(reduceMinutesFromDeadline(deadlineInMinutes));
      trackFlowBuilderActionEvent({
        action: FLOW_BUILDER_EVENTS.DUE_DATE,
        dueDate: reduceMinutesFromDeadline(deadlineInMinutes),
        templateId,
        templateName,
        flowTitle: flowName,
      });
    } else {
      updateEndTimeInMinutes(deadlineInMinutes);
      trackFlowBuilderActionEvent({
        action: FLOW_BUILDER_EVENTS.DUE_DATE,
        dueDate: deadlineInMinutes,
        templateId,
        templateName,
        flowTitle: flowName,
      });
    }

    setDeadlineConfigurationTime(ddValue as TimeUnitTypes);
    setReminderExtendsError(
      checkFlowDeadlineSelection(
        deadlineConfiguration,
        ddValue as TimeUnitTypes,
        flowFrequency?.id,
      ),
    );
    setRecurrenceError(
      checkRecurrenceError(deadlineConfiguration, ddValue as TimeUnitTypes),
    );
  };

  /* Flow schedule end */
  const onScheduleEndValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setScheduleEndValue(e.target.value);
    if (e.target.value === 'MANUAL') {
      setEndDateOccurredInPast(false);
      setDueDateInterruptsOccurrence(false);
      setDisableScheduleEndDatePicker(true);

      const getRRuleStringProps: GetRRuleStringProps = {
        flowStartDate,
        flowEndDate: undefined,
        flowFrequency,
        flowFrequencyTime,
        flowEndDateTime: null,
        selectedTimezone,
        selectedCustomRecurrenceTypes,
      };
      const updatedRRuleString = getRRuleString(getRRuleStringProps);

      updateTriggerBlockData({
        ...triggerBlockData,
        schedule: {
          rule: updatedRRuleString.toString(),
        },
        isSchedulerTouched: true,
      });
    } else {
      setDisableScheduleEndDatePicker(false);
      updateTriggerBlockData({
        ...triggerBlockData,
        isSchedulerTouched: true,
      });
    }
  };

  const onFlowEndDateTimeChange = useCallback(
    (val: AutocompleteDropdownItem<number> | null) => {
      trackFlowBuilderActionEvent({
        action: FLOW_BUILDER_EVENTS.SCHEDULE_END_TIME_SELECTED,
        scheduleEndTimeSelected: val?.title,
        templateId,
        templateName,
        flowTitle: flowName,
      });
      if (val === null) {
        setEndDateOccurredInPast(false);
        setDueDateInterruptsOccurrence(false);
        setFlowFrequencyTimeOptions(timeOptions);
      } else {
        const getRRuleStringProps: GetRRuleStringProps = {
          flowStartDate,
          flowEndDate,
          flowFrequency,
          flowFrequencyTime,
          flowEndDateTime: val,
          selectedTimezone,
          selectedCustomRecurrenceTypes,
        };
        const updatedRRuleString = getRRuleString(getRRuleStringProps);

        updateTriggerBlockData({
          ...triggerBlockData,
          schedule: {
            rule: updatedRRuleString.toString(),
          },
        });
      }
    },
    [
      templateId,
      templateName,
      flowName,
      timeOptions,
      flowStartDate,
      flowEndDate,
      flowFrequency,
      flowFrequencyTime,
      selectedTimezone,
      selectedCustomRecurrenceTypes,
      updateTriggerBlockData,
      triggerBlockData,
    ],
  );

  const disabledDays = useMemo(() => {
    return { before: new Date() };
  }, []);

  const ManualOptionChildren = () => {
    if (!disableScheduleEndOptions) {
      return (
        <IconInfo
          backgroundColor={ThemeV2.palette.geekBlue2}
          icon="info-icon"
          iconColor={ThemeV2.palette.geekBlue6}
          iconSize="16px"
          paddingTopBottom="8px"
          paddingLeftRight="8px"
        >
          <Body variant="body3">
            {CONFIGURE_SETTINGS_FLOW_END_BLOCK.MANUALLY.INFO_TEXT}
          </Body>
        </IconInfo>
      );
    }
    return null;
  };

  const updateFlowEndDate = useCallback(
    (val: Date | undefined) => {
      trackFlowBuilderActionEvent({
        action: FLOW_BUILDER_EVENTS.SCHEDULE_END_DATE_SELECTED,
        scheduleEndTimeSelected: val?.toString(),
        templateId,
        templateName,
        flowTitle: flowName,
      });
      const getRRuleStringProps: GetRRuleStringProps = {
        flowStartDate,
        flowEndDate: val,
        flowFrequency,
        flowFrequencyTime,
        flowEndDateTime,
        selectedTimezone,
        selectedCustomRecurrenceTypes,
      };
      const updatedRRuleString = getRRuleString(getRRuleStringProps);

      updateTriggerBlockData({
        ...triggerBlockData,
        schedule: {
          rule: updatedRRuleString.toString(),
        },
      });
    },
    [
      templateId,
      templateName,
      flowStartDate,
      flowName,
      flowFrequency,
      flowFrequencyTime,
      flowEndDateTime,
      selectedTimezone,
      selectedCustomRecurrenceTypes,
      updateTriggerBlockData,
      triggerBlockData,
    ],
  );

  const filterOptionsFunction = createFilterOptions({
    matchFrom: 'start',
    stringify: (option: AutocompleteDropdownItem<number, undefined>) =>
      option.title,
  });

  const radioOptionsForScheduleEnd = useMemo(() => {
    return [
      {
        value: 'MANUAL',
        label: CONFIGURE_SETTINGS_FLOW_END_BLOCK.MANUALLY.LABEL_TEXT,
        children: <ManualOptionChildren />,
        disabled: disableScheduleEndOptions,
      },
      {
        value: 'ON',
        label: CONFIGURE_SETTINGS_FLOW_END_BLOCK.ON,
        inlineChildren: (
          <StyledFlex>
            <StyledFlowEndCalendarInputWrapper>
              <StyledCalendarInput
                name="flowEndDate"
                value={flowEndDate}
                onChange={updateFlowEndDate}
                placeholder={CONFIGURE_SETTINGS_PICK_A_DATE}
                showDropdownIcon
                hideCalendarIcon
                disabled={
                  isValidationEnabled &&
                  (disableScheduleEndOptions || disableScheduleEndDatePicker)
                }
                disabledDays={disabledDays}
                clearTextEnabled={false}
                onBlur={() =>
                  updateTriggerBlockData({
                    ...triggerBlockData,
                    isSchedulerTouched: true,
                  })
                }
              />
            </StyledFlowEndCalendarInputWrapper>
            <Body variant="body3">{AT}</Body>
            <StyledSingleSelectAutoCompleteWrapper>
              <SingleSelectAutoComplete
                label={CONFIGURE_SETTINGS_PICK_A_TIME}
                value={flowEndDateTime}
                onChange={onFlowEndDateTimeChange}
                options={flowFrequencyTimeOptions}
                disabled={
                  isValidationEnabled &&
                  (disableScheduleEndOptions || disableScheduleEndDatePicker)
                }
                hasError={isValidationEnabled && isDueDateInterruptsOccurrence}
                onFocus={() =>
                  updateTriggerBlockData({
                    ...triggerBlockData,
                    isSchedulerTouched: true,
                  })
                }
                filterOptionsFunction={filterOptionsFunction}
                autoSelect
                showClearIcon={false}
              />
              {isValidationEnabled && isEndDateOccurredInPast && (
                <StyledErrorBody variant="body4" color="red6">
                  {
                    CONFIGURE_SETTINGS_ERROR_MESSAGE.END_TIME_OCCURRED_IN_THE_PAST
                  }
                </StyledErrorBody>
              )}
              {isValidationEnabled &&
                !isEndDateOccurredInPast &&
                isDueDateInterruptsOccurrence && (
                  <StyledErrorBody variant="body4" color="red6">
                    {
                      CONFIGURE_SETTINGS_ERROR_MESSAGE.DUE_DATE_CANNOT_BE_LONGER_THAN_FLOW_SCHEDULE
                    }
                  </StyledErrorBody>
                )}
            </StyledSingleSelectAutoCompleteWrapper>
          </StyledFlex>
        ),
        disabled: disableScheduleEndOptions,
      },
    ];
  }, [
    flowEndDate,
    disabledDays,
    flowEndDateTime,
    triggerBlockData,
    updateFlowEndDate,
    isValidationEnabled,
    updateTriggerBlockData,
    onFlowEndDateTimeChange,
    isEndDateOccurredInPast,
    flowFrequencyTimeOptions,
    disableScheduleEndOptions,
    disableScheduleEndDatePicker,
    isDueDateInterruptsOccurrence,
  ]);

  const deadlineConfigurationTimeOptions: MenuItemProps[] = useMemo(
    () => [
      {
        id: 'trigger-flow',
        items: [
          {
            value: MINUTES,
            id: 'minutes',
          },
          {
            value: HOURS,
            id: 'hours',
          },
          {
            value: DAYS,
            id: 'days',
          },
          {
            value: WEEKS,
            id: 'weeks',
            disabled: disableWeekOption,
          },
        ],
      },
    ],
    [disableWeekOption],
  );

  const handleTimeZoneChange = useCallback(
    (val: any) => {
      const getRRuleStringProps: GetRRuleStringProps = {
        flowStartDate,
        flowEndDate,
        flowFrequency,
        flowFrequencyTime,
        flowEndDateTime,
        selectedTimezone: val,
        selectedCustomRecurrenceTypes,
      };
      const updatedRRuleString = getRRuleString(getRRuleStringProps);

      updateTriggerBlockData({
        ...triggerBlockData,
        schedule: {
          rule: updatedRRuleString.toString(),
        },
      });
    },
    [
      flowStartDate,
      flowEndDate,
      flowEndDateTime,
      flowFrequency,
      flowFrequencyTime,
      selectedCustomRecurrenceTypes,
      triggerBlockData,
      updateTriggerBlockData,
    ],
  );

  const updateFlowStartDate = useCallback(
    (val: Date | undefined) => {
      trackFlowBuilderActionEvent({
        action: FLOW_BUILDER_EVENTS.SCHEDULE_START_DATE_SELECTED,
        scheduleEndTimeSelected: val?.toString(),
        templateId,
        templateName,
        flowTitle: flowName,
      });
      const getRRuleStringProps: GetRRuleStringProps = {
        flowStartDate: val,
        flowEndDate,
        flowFrequency,
        flowFrequencyTime,
        flowEndDateTime,
        selectedTimezone,
        selectedCustomRecurrenceTypes,
      };
      const updatedRRuleString = getRRuleString(getRRuleStringProps);

      updateTriggerBlockData({
        ...triggerBlockData,
        schedule: {
          rule: updatedRRuleString.toString(),
        },
      });
    },
    [
      templateId,
      templateName,
      flowName,
      flowEndDate,
      flowFrequency,
      flowFrequencyTime,
      flowEndDateTime,
      selectedTimezone,
      selectedCustomRecurrenceTypes,
      updateTriggerBlockData,
      triggerBlockData,
    ],
  );

  const onFlowFrequencyTimeFocusHandler = () => {
    updateTriggerBlockData({
      ...triggerBlockData,
      isSchedulerTouched: true,
    });
  };

  const flowStartProps: FlowStartProps = {
    flowStartDate,
    onFlowStartDateChange: updateFlowStartDate,
    disabled: hasActiveOccurrence,
    onFlowStartBlur: () =>
      updateTriggerBlockData({
        ...triggerBlockData,
        isSchedulerTouched: true,
      }),
  };

  const flowRecurProps: FlowRecurProps = {
    flowFrequency,
    onFlowFrequencyChange,
    flowFrequencyOptions,
    flowFrequencyTime,
    onFlowFrequencyTimeChange,
    flowFrequencyTimeOptions,
    disabled: hasActiveOccurrence,
    hasPastTimeError: isValidationEnabled ? isStartDateOccurredInPast : false,
    onFlowFrequencyFocus: () =>
      updateTriggerBlockData({
        ...triggerBlockData,
        isSchedulerTouched: true,
      }),
    onFlowFrequencyTimeFocus: onFlowFrequencyTimeFocusHandler,
  };

  const flowDeadlineProps: FlowDeadlineProps = {
    deadlineConfiguration,
    onDeadlineConfigurationChange,
    deadlineConfigurationTIme,
    onDeadlineConfigurationTImeChange,
    deadlineConfigurationTimeOptions,
    recurrenceError: recurrenceError !== null,
    reminderExtendsError,
    recurrenceErrorMessage: recurrenceError || '',
    onDeadlineConfigurationBlur: () =>
      updateTriggerBlockData({
        ...triggerBlockData,
        isSchedulerTouched: true,
      }),
    onDeadlineConfigurationTimeFocus: () =>
      updateTriggerBlockData({
        ...triggerBlockData,
        isSchedulerTouched: true,
      }),
  };

  const flowEndProps: FlowEndProps = {
    scheduleEndValue,
    onScheduleEndValueChange,
    scheduleEndValueOptions: radioOptionsForScheduleEnd,
    disableScheduleEnd: disableScheduleEndOptions,
  };

  const flowShortcutConfigurationProps: ShortcutConfigurationProps = {
    isOccurrenceOn,
    onOccurrenceToggle,
  };

  const flowTimezoneProps: FlowTimezoneProps = {
    selectedTimezone,
    handleTimeZoneChange,
    options: formattedTimezones,
    label: CHOOSE_TIMEZONE,
    disabled: hasActiveOccurrence,
    onFocus: () =>
      updateTriggerBlockData({
        ...triggerBlockData,
        isSchedulerTouched: true,
      }),
  };

  const handleClosCustomRecurrenceModal = useCallback(() => {
    if (previousFlowFrequency !== null) {
      const receivedMaxMinutes = getMinutesLimit(previousFlowFrequency);
      setMaxMinutes(receivedMaxMinutes);
    }

    closeCustomRecurrenceModal();
  }, [closeCustomRecurrenceModal, previousFlowFrequency]);

  const handleCustomRecurrenceSubmit = useCallback(
    (data: SelectedCustomRecurrenceTypes) => {
      const getRRuleStringProps: GetRRuleStringProps = {
        flowStartDate,
        flowEndDate,
        flowFrequency,
        flowFrequencyTime,
        flowEndDateTime,
        selectedTimezone,
        selectedCustomRecurrenceTypes: data,
      };
      const updatedRRuleString = getRRuleString(getRRuleStringProps);

      /* error check */
      const hasCustomOccurrenceError = customOccurrenceErrorCheck(
        endTimeInMinutes,
        data,
      );
      setReminderExtendsError(hasCustomOccurrenceError);
      const customOccurrenceLimit = customOccurrenceEndTimeLimit(
        endTimeInMinutes,
        data,
      );
      const deadlineInMinutes = getDeadlineInMinutes(
        deadlineConfiguration,
        deadlineConfigurationTIme,
      );
      let updatedEndTimeInMinutes = deadlineInMinutes;
      if (customOccurrenceLimit) {
        updatedEndTimeInMinutes = customOccurrenceLimit;
      }
      /* error check */

      updateTriggerBlockData({
        ...triggerBlockData,
        selectedCustomRecurrenceTypes: data,
        schedule: {
          rule: updatedRRuleString.toString(),
        },
        endTimeInMinutes: updatedEndTimeInMinutes,
      });
      closeCustomRecurrenceModal();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      flowFrequency,
      flowFrequencyTime,
      flowStartDate,
      closeCustomRecurrenceModal,
      flowEndDate,
      flowEndDateTime,
      selectedTimezone,
      triggerBlockData,
      updateTriggerBlockData,
      endTimeInMinutes,
      deadlineConfiguration,
      deadlineConfigurationTIme,
      reminderExtendsError,
    ],
  );

  const monthlyOnOptions: MenuItemProps[] = useMemo(() => {
    const monthlyOnOptionsIndividualItems: MenuItemIndividualItem[] = [
      {
        value: `${MONTHLY_ON_THE} day ${dayNumber}`,
        id: 'repeatMonthly',
      },
    ];
    if (weekDetails) {
      switch (weekDetails.weekNumber) {
        case 1: {
          monthlyOnOptionsIndividualItems.push({
            value: `${MONTHLY_ON_THE} first ${day}`,
            id: 'repeatMonthlyOn',
          });
          break;
        }
        case 2: {
          monthlyOnOptionsIndividualItems.push({
            value: `${MONTHLY_ON_THE} second ${day}`,
            id: 'repeatMonthlyOn',
          });
          break;
        }
        case 3: {
          monthlyOnOptionsIndividualItems.push({
            value: `${MONTHLY_ON_THE} third ${day}`,
            id: 'repeatMonthlyOn',
          });
          break;
        }
        case 4: {
          if (weekDetails.isLastWeek) {
            monthlyOnOptionsIndividualItems.push({
              value: `${MONTHLY_ON_THE} last ${day}`,
              id: 'repeatMonthlyOn',
            });
          } else {
            monthlyOnOptionsIndividualItems.push({
              value: `${MONTHLY_ON_THE} fourth ${day}`,
              id: 'repeatMonthlyOn',
            });
          }
          break;
        }
        default:
          monthlyOnOptionsIndividualItems.push({
            value: `${MONTHLY_ON_THE} last ${day}`,
            id: 'repeatMonthlyOn',
          });
          break;
      }
    }
    return [
      {
        id: 'monthlyOptions',
        items: monthlyOnOptionsIndividualItems,
      },
    ];
  }, [day, dayNumber, weekDetails]);

  const customRecurrenceProps: CustomRecurrenceProps = useMemo(() => {
    let defaultDayIds = flowStartDate
      ? [getDayIdsForCustomOccurrence(flowStartDate)]
      : [1];
    if (
      selectedCustomRecurrenceTypes &&
      selectedCustomRecurrenceTypes?.selectedDayIds.length > 0
    ) {
      defaultDayIds = [...selectedCustomRecurrenceTypes?.selectedDayIds];
    }
    return {
      isModalOpen: isCustomRecurrenceModalOpen,
      repeatEveryCountDefault: 1,
      repeatEveryDefault: 'days',
      selectedMonthlyOnOptionDefault: monthlyOnOptions[0].items[0].value,
      defaultDayIds,
      monthlyOnOptions,
      handleSubmit: handleCustomRecurrenceSubmit,
      handleModalClose: handleClosCustomRecurrenceModal,
    };
  }, [
    flowStartDate,
    handleClosCustomRecurrenceModal,
    handleCustomRecurrenceSubmit,
    isCustomRecurrenceModalOpen,
    monthlyOnOptions,
    selectedCustomRecurrenceTypes,
  ]);

  /* to handle,
  1. start & end date occurred in the past
  2. due date is less than the occurrence
      ie, start date & end date is same, but time is
      3.00pm & 3.15pm respectively for start & end.
      But occurrence duration is set to 1 day, so now we have to
      throw an error msg
  */
  useEffect(() => {
    const startDate = getDateMonthYear(flowStartDate);
    const startTime = getTime(flowFrequencyTime?.title);
    const endDate = getDateMonthYear(flowEndDate);
    const endTime = getTime(flowEndDateTime?.title);
    if (isValidationEnabled) {
      if (startDate && startTime) {
        const difference = differenceInMinutes(
          new Date(
            startDate.year,
            startDate.month,
            startDate.date,
            startTime.hours,
            startTime.minutes,
          ),
          new Date(
            new Date().toLocaleString('en-US', {
              timeZone: selectedTimezone.id,
            }),
          ),
        );
        if (difference < 1) {
          setStartDateOccurredInPast(true);
        } else {
          setStartDateOccurredInPast(false);
        }
      }
      if (endDate && endTime) {
        const difference = differenceInMinutes(
          new Date(
            endDate.year,
            endDate.month,
            endDate.date,
            endTime.hours,
            endTime.minutes,
          ),
          new Date(
            new Date().toLocaleString('en-US', {
              timeZone: selectedTimezone.id,
            }),
          ),
        );
        if (difference < 1) {
          setEndDateOccurredInPast(true);
        } else {
          setEndDateOccurredInPast(false);
        }
      }
      if (startDate && startTime && endDate && endTime) {
        const difference = differenceInMinutes(
          new Date(
            endDate.year,
            endDate.month,
            endDate.date,
            endTime.hours,
            endTime.minutes,
          ),
          new Date(
            startDate.year,
            startDate.month,
            startDate.date,
            startTime.hours,
            startTime.minutes,
          ),
        );
        if (endTimeInMinutes >= difference) {
          setDueDateInterruptsOccurrence(true);
        } else {
          setDueDateInterruptsOccurrence(false);
        }
      }
    }
  }, [
    flowEndDate,
    flowStartDate,
    flowEndDateTime,
    endTimeInMinutes,
    selectedTimezone,
    flowFrequencyTime,
    isValidationEnabled,
    deadlineConfiguration,
    deadlineConfigurationTIme,
  ]);

  useEffect(() => {
    /* Input validations */
    if (
      flowFrequency?.title === undefined ||
      flowFrequencyTime?.title === undefined ||
      selectedTimezone === null ||
      deadlineConfiguration.toString() === '0' ||
      deadlineConfiguration.toString() === '' ||
      deadlineConfiguration.toString() === undefined ||
      reminderExtendsError === true ||
      recurrenceError !== null
    ) {
      setIsNextButtonDisabled(true);
    } else if (
      reminderExtendsError === false &&
      recurrenceError === null &&
      scheduleEndValue === 'ON' &&
      (flowEndDate === undefined || flowEndDateTime === null)
    ) {
      if (disableScheduleEndOptions) {
        setIsNextButtonDisabled(false);
      } else {
        setIsNextButtonDisabled(true);
      }
    } else {
      setIsNextButtonDisabled(false);
    }
    /* Input validations */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    flowStartDate,
    flowEndDate,
    flowFrequency,
    flowFrequencyTime,
    flowEndDateTime,
    deadlineConfiguration,
    deadlineConfigurationTIme,
    selectedTimezone,
    scheduleEndValue,
    reminderExtendsError,
    recurrenceError,
  ]);

  /* to disable next button */
  useEffect(() => {
    if (isValidationEnabled) {
      if (
        isStartDateOccurredInPast ||
        isEndDateOccurredInPast ||
        isDueDateInterruptsOccurrence
      ) {
        setIsNextButtonDisabled(true);
      }
    }
  }, [
    isValidationEnabled,
    setIsNextButtonDisabled,
    isEndDateOccurredInPast,
    isStartDateOccurredInPast,
    isDueDateInterruptsOccurrence,
  ]);

  /* to check Errors for custom occurrence */
  useEffect(() => {
    if (flowFrequency?.id.includes('repeat')) {
      const hasCustomOccurrenceError = customOccurrenceErrorCheck(
        endTimeInMinutes,
        selectedCustomRecurrenceTypes,
      );
      setReminderExtendsError(hasCustomOccurrenceError);
      const customOccurrenceLimit = customOccurrenceEndTimeLimit(
        endTimeInMinutes,
        selectedCustomRecurrenceTypes,
      );
      if (customOccurrenceLimit) {
        updateEndTimeInMinutes(customOccurrenceLimit);
      }
    } else {
      setReminderExtendsError(
        checkFlowDeadlineSelection(
          deadlineConfiguration,
          deadlineConfigurationTIme,
          flowFrequency?.id,
        ),
      );
    }
    /* it should run only when deadline updates */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endTimeInMinutes, deadlineConfiguration, deadlineConfigurationTIme]);

  /* need to run this when the component renders for the 1st time
  to avoid the following issues,
  1. when the user creates a flow without touching/making any changes to scheduler - default deadline is 1 day
  So we have to reduce 5 mins from it, otherwise user will not be able to create a flow
  2. Same as 1st step, and it throws an error since it doesn't create any rrule yet!
  */
  useEffect(() => {
    let receivedMaxMinutes = maxMinutes;
    if (flowFrequency) {
      receivedMaxMinutes = getMinutesLimit(flowFrequency);
      setMaxMinutes(receivedMaxMinutes);
    }
    const deadlineInMinutes = getDeadlineInMinutes(
      deadlineConfiguration,
      deadlineConfigurationTIme,
    );

    const getRRuleStringProps: GetRRuleStringProps = {
      flowStartDate,
      flowEndDate,
      flowFrequency,
      flowFrequencyTime,
      flowEndDateTime,
      selectedTimezone,
      selectedCustomRecurrenceTypes,
    };
    const updatedRRuleString = getRRuleString(getRRuleStringProps);

    updateTriggerBlockData({
      ...triggerBlockData,
      endTimeInMinutes:
        receivedMaxMinutes === deadlineInMinutes
          ? reduceMinutesFromDeadline(deadlineInMinutes)
          : deadlineInMinutes,
      selectedCustomRecurrenceTypes:
        getDefaultSelectedCustomRecurrenceTypes(triggerBlockData),
      schedule: {
        rule: updatedRRuleString.toString(),
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* to revert changes */
  useEffect(() => {
    if (triggerBlockData.isSchedulerTouched === false && isInEditMode) {
      if (endTimeInMinutes > 0) {
        setDeadlineConfiguration(getDaysFromDeadline(endTimeInMinutes));
        const deadlineConfigurationTimeValue =
          getDeadlineConfigurationTimeType(endTimeInMinutes);
        setDeadlineConfigurationTime(deadlineConfigurationTimeValue);
      }
      setRecurrenceError(null);
      setReminderExtendsError(false);
      setScheduleEndValue(getDefaultScheduleEndValue(triggerBlockData));
      setDisableScheduleEndDatePicker(() =>
        getDefaultDisableScheduleEndDatePicker(triggerBlockData),
      );
      setDisableScheduleEndOptions(() => {
        if (
          triggerBlockData.triggerType === 'SCHEDULED' &&
          triggerBlockData.schedule
        ) {
          const parsedString = RRule.parseString(
            triggerBlockData.schedule.rule,
          );
          const flowFrequencyValue = getFlowFrequency(parsedString);
          if (flowFrequencyValue === FlowFrequencyOptions.ONCE) {
            return true;
          }
          return false;
        }
        return false;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerBlockData.isSchedulerTouched, isInEditMode]);

  return {
    models: {
      flowStartProps,
      flowRecurProps,
      flowDeadlineProps,
      flowEndProps,
      flowShortcutConfigurationProps,
      customRecurrenceProps,
      selectedTimezone,
      flowTimezoneProps,
    },
  };
};

export default useScheduleDateTimeController;
