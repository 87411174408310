import React from 'react';

import Body from '../../atoms/Body';
import Divider from '../../atoms/Divider';
import ThemeV2 from '../../../componentsV2/theme';
import searching from '../../../img/searching.svg';
import ActionMenuItem from '../../molecules/ActionMenuItem';
import { FLOWS_ACTION_BAR_OPTIONS } from '../../../languages/en/flows';

import { addNewFlowItem } from './data';
import {
  DividerWrapper,
  FlowMenuItemElementWrapper,
  FullWidth,
  NoResultsFoundWrapper,
  StyledBody,
  StyledFlowMenuItemsWrapper,
  StyledLink,
  StyledNoItemsFoundText,
  StyledSearchImage,
  StyledHelpLink,
  StyledSectionTitle,
} from './styles';
import { ActionBarItem } from '../../molecules/ActionMenuItem/types';
import { FlowsActionBarOptionsProps } from './types';
import { getDueTime } from '../../../Utils/time';

const LoadedComponent = (props: FlowsActionBarOptionsProps) => {
  const {
    todos,
    feeds,
    shortcuts,
    onFlowClick,
    onAddFlowClick,
    onFlowFeedClick,
    searchText,
    isAddNewFlowOn,
    onNeedHelpClick,
  } = props;

  const hasFlowItems =
    todos.length > 0 || feeds.length > 0 || shortcuts.length > 0;

  const renderSectionTitle = (
    sectionItemsCount: number,
    sectionTitle: string,
  ) => {
    if (!searchText) {
      return (
        <StyledSectionTitle variant="body2" color="gray7">
          {sectionTitle}
        </StyledSectionTitle>
      );
    }
    if (sectionItemsCount > 0) {
      return (
        <StyledSectionTitle variant="body2" color="gray7">
          {sectionTitle}
        </StyledSectionTitle>
      );
    }

    return null;
  };

  return (
    <>
      {searchText && !hasFlowItems && (
        <FullWidth>
          <StyledFlowMenuItemsWrapper>
            <Body variant="body2" color="gray7">
              {FLOWS_ACTION_BAR_OPTIONS.SEARCH_RESULTS}
            </Body>

            <NoResultsFoundWrapper>
              <StyledSearchImage src={searching} alt={searchText} />
              <div>
                <StyledBody variant="body1">
                  {FLOWS_ACTION_BAR_OPTIONS.NO_RESULTS}
                </StyledBody>
                <Body variant="body2">
                  {FLOWS_ACTION_BAR_OPTIONS.TRY_SEARCHING_SOMETHING_ELSE}
                </Body>
              </div>
            </NoResultsFoundWrapper>
          </StyledFlowMenuItemsWrapper>
        </FullWidth>
      )}
      <FullWidth>
        <>
          {todos.length > 0 && (
            <StyledFlowMenuItemsWrapper>
              {renderSectionTitle(
                todos.length,
                FLOWS_ACTION_BAR_OPTIONS.FLOWS_TO_DO,
              )}

              {todos.map((todo: ActionBarItem) => (
                <ActionMenuItem
                  {...todo}
                  key={todo.id}
                  subTitle={`Answer Flow, Due in ${getDueTime(
                    todo.occurrence?.activeOccurrence?.endTime,
                  )}`}
                  onClick={() =>
                    onFlowClick(
                      todo.id,
                      todo.occurrence.activeOccurrence?.occurrenceId,
                    )
                  }
                />
              ))}
            </StyledFlowMenuItemsWrapper>
          )}

          <StyledFlowMenuItemsWrapper>
            {renderSectionTitle(
              shortcuts.length,
              FLOWS_ACTION_BAR_OPTIONS.ANSWER_ANYTIME,
            )}
            {shortcuts.length
              ? shortcuts.map((shortcut: ActionBarItem) => (
                  <ActionMenuItem
                    {...shortcut}
                    key={shortcut.id}
                    subTitle={FLOWS_ACTION_BAR_OPTIONS.ANSWER_ANYTIME}
                    onClick={() =>
                      onFlowClick(
                        shortcut.id,
                        shortcut.occurrence.activeOccurrence?.occurrenceId,
                      )
                    }
                  />
                ))
              : !searchText && (
                  <StyledNoItemsFoundText variant="body2">
                    {FLOWS_ACTION_BAR_OPTIONS.NO_FLOWS_TO_ANSWER}
                  </StyledNoItemsFoundText>
                )}
          </StyledFlowMenuItemsWrapper>
          <StyledFlowMenuItemsWrapper>
            {renderSectionTitle(
              feeds.length,
              FLOWS_ACTION_BAR_OPTIONS.VIEW_FEEDS,
            )}
            {feeds.length
              ? feeds.map((feed: ActionBarItem) => (
                  <ActionMenuItem
                    {...feed}
                    key={feed.id}
                    subTitle="View Feed"
                    onClick={() => {
                      onFlowFeedClick(feed.id);
                    }}
                  />
                ))
              : !searchText && (
                  <StyledNoItemsFoundText variant="body2">
                    {FLOWS_ACTION_BAR_OPTIONS.NO_FEEDS}
                  </StyledNoItemsFoundText>
                )}
          </StyledFlowMenuItemsWrapper>
        </>

        <FullWidth>
          {isAddNewFlowOn && (
            <>
              <DividerWrapper>
                <Divider isFullWidth color={ThemeV2.palette.gray4} />
              </DividerWrapper>
              <FlowMenuItemElementWrapper>
                <ActionMenuItem {...addNewFlowItem} onClick={onAddFlowClick} />
              </FlowMenuItemElementWrapper>
            </>
          )}
          <DividerWrapper>
            <Divider isFullWidth color={ThemeV2.palette.gray4} />
          </DividerWrapper>
          <StyledLink
            to={FLOWS_ACTION_BAR_OPTIONS.NEED_HELP_REDIRECT_URL}
            isAnchor
            openInNewTab
            onClick={onNeedHelpClick}
          >
            <StyledHelpLink variant="body2" color="geekBlue6">
              {FLOWS_ACTION_BAR_OPTIONS.NEED_HELP}
            </StyledHelpLink>
          </StyledLink>
        </FullWidth>
      </FullWidth>
    </>
  );
};

export default LoadedComponent;
