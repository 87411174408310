import React, { useCallback, useEffect, useMemo, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import pluralize from 'pluralize';
import useToggle from '../../../hooks/useToggle';

import FlowAccordionItem from '../../../atomic/molecules/FlowAccordionItem';

import {
  FlowAccordionItemState,
  FlowAccordionItemValidations,
} from '../../../atomic/molecules/FlowAccordionItem/interface';

import FlowBlockHeader from '../../../atomic/molecules/FlowBlockHeader';
import {
  Border,
  FlowAccordionItemContainer,
  FlowBuilderBlockContainer,
  FlowAccordionItemTriggerContainer,
  StyledNextButton,
} from '../FlowsBuilderController/styles';
import { FlowsBuilderTriggerControllerProps } from './types';

import {
  ANSWER,
  ANYTIME,
  NEXT,
  DAYS,
  RECURS,
} from '../../../languages/en/singleWords';
import {
  ANSWER_ANYTIME,
  BUILDER_TRIGGER_BLOCK,
  LEARN_MORE_HELPER_LINK,
} from '../../../languages/en/flows/builder';
import FlowTriggerType from '../../../atomic/molecules/FlowTriggerType';
import Divider from '../../../atomic/atoms/Divider';

import ThemeV2 from '../../../componentsV2/theme';
import ScheduleDateTimeController from './ScheduleDateTimeController';
import {
  ErrorEntry,
  FlowBuilderBlockTypes,
  TriggerBuilderBlockData,
  TriggerType,
} from '../../../interfaces/Flow/Builder';
import { ScheduleDateTimeControllerTypes } from './ScheduleDateTimeController/types';
import OnDemandController from './OnDemandController';
import { TimeUnitTypes } from '../../../atomic/organism/TriggerConfigureSettings/types';
import { getFlowFrequencyText } from '../../../Utils/flows/builder/utils';
import {
  getDaysFromDeadline,
  getDeadlineConfigurationTimeType,
  getDeadlineInMinutes,
} from '../../../Utils/flows/scheduler/deadline';
import { FLOW_BUILDER_EVENTS } from '../../../Utils/analytics/constants';
import ShortcutConfiguration from '../../../atomic/organism/TriggerConfigureSettings/ShortcutConfiguration';
import { trackFlowBuilderActionEvent } from '../../../Utils/analytics/flowsBuilder';
import useFlowBuilderStore from '../../../stores/flowBuilderStore';
import {
  flowNameSelector,
  templateNameSelector,
} from '../../../stores/flowBuilderStore/selectors';

const FlowsBuilderTriggerController = ({
  id,
  index,
  isTouched,
  isUnlocked,
  hasError,
  isExpanded,
  templateId,
  triggerBlockData,
  setSpecificBlockData,
  goToNextBlock,
  onEditButtonClick,
  hasActiveOccurrence,
  isInEditMode,
}: FlowsBuilderTriggerControllerProps) => {
  const flowName = useFlowBuilderStore(flowNameSelector);
  const templateName = useFlowBuilderStore(templateNameSelector);
  const [isTriggerNextButtonDisabled, setIsTriggerNextButtonDisabled] =
    useState<boolean>(false);

  const { triggerType, endTimeInMinutes, shortcut, schedule } =
    triggerBlockData;

  const {
    models: triggerTypeToggleState,
    operations: triggerTypeToggleOperations,
  } = useToggle(false);

  const updateTriggerBlockData = useCallback(
    (updatedContentBlock: TriggerBuilderBlockData) =>
      setSpecificBlockData(FlowBuilderBlockTypes.TRIGGER, updatedContentBlock),
    [setSpecificBlockData],
  );

  const [triggerTypeStatus, setTriggerTypeStatus] = useState(
    FlowAccordionItemValidations.None,
  );
  const [triggerTypeSubTitle, setTriggerTypeSubTitle] = useState('');
  const [configureSettingsSubTitle, setConfigureSettingsSubTitle] =
    useState('');

  // Configuration Settings
  const {
    models: configurationSettingsToggleState,
    operations: configurationSettingsToggleOperations,
  } = useToggle(false);

  const [configurationSettingsStatus, setConfigurationSettingsStatus] =
    useState(FlowAccordionItemValidations.None);

  // Props for ON DEMAND configure settings
  const [defaultTimeValue, setDefaultTimeValue] = useState<number>(() => {
    if (endTimeInMinutes > 0) {
      return getDaysFromDeadline(endTimeInMinutes);
    }
    return 1;
  });
  const [defaultDueDateType, setDefaultDueDateType] = useState<TimeUnitTypes>(
    () => {
      if (endTimeInMinutes > 0) {
        const deadlineConfigurationTimeValue =
          getDeadlineConfigurationTimeType(endTimeInMinutes);
        return deadlineConfigurationTimeValue;
      }
      return DAYS;
    },
  );

  const getConfigureSettingsSubtitle = useMemo(() => {
    if (triggerBlockData.triggerType === 'ONDEMAND') {
      const dueDateTypeText = pluralize(
        defaultDueDateType,
        defaultTimeValue,
        false,
      );
      if (triggerBlockData.shortcut) {
        return `${defaultTimeValue} ${dueDateTypeText} ${BUILDER_TRIGGER_BLOCK.DUE_DATE}, ${ANSWER} ${ANYTIME}`;
      }
      return `${defaultTimeValue} ${dueDateTypeText} ${BUILDER_TRIGGER_BLOCK.DUE_DATE}`;
    }

    if (schedule) {
      const flowFrequencyText = getFlowFrequencyText(schedule.rule);
      return `${RECURS} ${
        flowFrequencyText.charAt(0).toLowerCase() + flowFrequencyText.slice(1)
      }`;
    }

    if (triggerBlockData.triggerType === 'NO_TRIGGER') {
      return ANSWER_ANYTIME;
    }

    return '';
  }, [
    defaultDueDateType,
    defaultTimeValue,
    schedule,
    triggerBlockData.shortcut,
    triggerBlockData.triggerType,
  ]);

  const updateDefaultTimeValue = (updatedDefaultTimeValue: number) => {
    const deadlineInMinutes = getDeadlineInMinutes(
      updatedDefaultTimeValue,
      defaultDueDateType,
    );
    setDefaultTimeValue(updatedDefaultTimeValue);
    updateTriggerBlockData({
      ...triggerBlockData,
      endTimeInMinutes: deadlineInMinutes,
    });
  };

  const updateDefaultDueDateType = (value: string | number) => {
    const updatedDefaultDueDateType = value.toString() as TimeUnitTypes;
    setDefaultDueDateType(updatedDefaultDueDateType);
    const deadlineInMinutes = getDeadlineInMinutes(
      defaultTimeValue,
      updatedDefaultDueDateType,
    );
    updateTriggerBlockData({
      ...triggerBlockData,
      endTimeInMinutes: deadlineInMinutes,
    });
  };

  const updateEndTimeInMinutes = (updatedEndTimeInMinutes: number) => {
    updateTriggerBlockData({
      ...triggerBlockData,
      endTimeInMinutes: updatedEndTimeInMinutes,
    });
  };

  const toggleShortcut = () => {
    updateTriggerBlockData({
      ...triggerBlockData,
      shortcut: !shortcut,
    });
  };

  const onShortcutToggle = (updatedShortcut: boolean) => {
    updateTriggerBlockData({
      ...triggerBlockData,
      shortcut: updatedShortcut,
    });
  };

  const updateErrorData = (error: ErrorEntry | null) => {
    const errors =
      error !== null ? [...(triggerBlockData?.errors || []), error] : [];

    if (!isEqual(triggerBlockData?.errors, errors)) {
      if (!isEmpty(errors)) {
        setConfigurationSettingsStatus(FlowAccordionItemValidations.Error);
      }
      updateTriggerBlockData({
        ...triggerBlockData,
        errors,
      });
    }
  };

  const scheduleDateTimeControllerTypes: ScheduleDateTimeControllerTypes = {
    templateId,
    endTimeInMinutes,
    updateEndTimeInMinutes,
    shortcut,
    onShortcutToggle,
    setIsNextButtonDisabled: setIsTriggerNextButtonDisabled,
    triggerBlockData,
    hasActiveOccurrence,
    updateErrorData,
    updateTriggerBlockData,
    isInEditMode,
  };

  const onDemandControllerTypes = {
    canAnswerAnytime: shortcut,
    onAnswerAnytimeToggle: toggleShortcut,
    defaultTimeValue,
    onDefaultTimeValueChange: updateDefaultTimeValue,
    defaultDueDateType,
    onDefaultDueDateTypeChange: updateDefaultDueDateType,
    updateErrorData,
    errors: triggerBlockData.errors,
  };

  useEffect(() => {
    setConfigureSettingsSubTitle(getConfigureSettingsSubtitle);
  }, [getConfigureSettingsSubtitle]);

  useEffect(() => {
    if (templateId) {
      if (triggerType === 'ONDEMAND') {
        setTriggerTypeSubTitle(
          BUILDER_TRIGGER_BLOCK.ON_DEMAND_ACCORDION_HEADING_TEXT,
        );
      } else if (triggerType === 'SCHEDULED') {
        setTriggerTypeSubTitle(
          BUILDER_TRIGGER_BLOCK.SCHEDULED_DATE_TIME_ACCORDION_HEADING_TEXT,
        );
      } else if (triggerType === 'NO_TRIGGER') {
        setTriggerTypeSubTitle(
          BUILDER_TRIGGER_BLOCK.NO_TRIGGER_ACCORDION_HEADING_TEXT,
        );
      }
      triggerTypeToggleOperations.setToggleToFalse();
      configurationSettingsToggleOperations.setToggleToTrue();
    } else {
      setTriggerTypeSubTitle('');
    }
    // eslint-disable-next-line
  }, [templateId, triggerType]);

  // Notifications
  const {
    models: notificationsToggleState,
    operations: notificationsToggleOperations,
  } = useToggle(false);

  const [notificationsStatus] = useState(FlowAccordionItemValidations.None);

  const [blockStatus, setBlockStatus] = useState(FlowAccordionItemState.Focus);

  useEffect(() => {
    if (
      triggerType === 'SCHEDULED' ||
      triggerType === 'ONDEMAND' ||
      triggerType === 'NO_TRIGGER'
    ) {
      setTriggerTypeStatus(FlowAccordionItemValidations.Success);
    } else {
      setTriggerTypeStatus(FlowAccordionItemValidations.None);
    }

    switch (triggerType) {
      case 'SCHEDULED':
        setTriggerTypeSubTitle(
          `${BUILDER_TRIGGER_BLOCK.SCHEDULED_DATE_TIME_ACCORDION_HEADING_TEXT}`,
        );
        break;

      case 'ONDEMAND':
        setTriggerTypeSubTitle(
          `${BUILDER_TRIGGER_BLOCK.ON_DEMAND_ACCORDION_HEADING_TEXT}`,
        );
        break;
      case 'NO_TRIGGER':
        setTriggerTypeSubTitle(
          `${BUILDER_TRIGGER_BLOCK.NO_TRIGGER_ACCORDION_HEADING_TEXT}`,
        );
        break;
      default:
        break;
    }
  }, [triggerType, isUnlocked]);

  useEffect(() => {
    if (isExpanded) {
      setBlockStatus(FlowAccordionItemState.Focus);
      if (!configurationSettingsToggleState.toggleValue) {
        triggerTypeToggleOperations.setToggleToTrue();
      }
    } else if (isUnlocked) {
      setBlockStatus(FlowAccordionItemState.Blur);
      triggerTypeToggleOperations.setToggleToFalse();
      configurationSettingsToggleOperations.setToggleToFalse();
      notificationsToggleOperations.setToggleToFalse();
    } else {
      setBlockStatus(FlowAccordionItemState.Disabled);
    }
    // eslint-disable-next-line max-len
    // Note: Adding "triggerTypeToggleOperations,configurationSettingsToggleOperations,notificationsToggleOperations" in the dependency will cause block behavior issues
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUnlocked, isExpanded]);

  useEffect(() => {
    if ((isTouched && hasError) || !isEmpty(triggerBlockData.errors)) {
      setConfigurationSettingsStatus(FlowAccordionItemValidations.Error);
    }
    if (isUnlocked && isEmpty(triggerBlockData.errors) && !hasError) {
      setConfigurationSettingsStatus(FlowAccordionItemValidations.Success);
    }
    if (
      isEmpty(triggerBlockData.errors) &&
      !hasError &&
      configurationSettingsStatus === FlowAccordionItemValidations.Error
    ) {
      setConfigurationSettingsStatus(FlowAccordionItemValidations.None);
    }
  }, [
    isTouched,
    hasError,
    triggerBlockData.errors,
    isUnlocked,
    configurationSettingsStatus,
  ]);

  useEffect(() => {
    return () => {
      setDefaultTimeValue(1);
      setDefaultDueDateType(DAYS);
    };
  }, [templateId]);

  const handleOnNextButtonClick = useCallback(() => {
    triggerTypeToggleOperations.setToggleToFalse();
    configurationSettingsToggleOperations.setToggleToFalse();
    notificationsToggleOperations.setToggleToFalse();
    goToNextBlock();
    setConfigurationSettingsStatus(FlowAccordionItemValidations.Success);
  }, [
    triggerTypeToggleOperations,
    configurationSettingsToggleOperations,
    notificationsToggleOperations,
    goToNextBlock,
  ]);

  const onTriggerTypeAccordionClick = useCallback(
    (triggerTypeSelected?: string) => {
      if (triggerTypeSelected) {
        trackFlowBuilderActionEvent({
          action: FLOW_BUILDER_EVENTS.TRIGGER_SELECTED,
          triggerTypeSelected: triggerTypeSelected,
          templateId,
          templateName,
          flowTitle: flowName,
        });
        setTriggerTypeStatus(FlowAccordionItemValidations.Success);
        updateTriggerBlockData({
          ...triggerBlockData,
          triggerType: triggerTypeSelected as TriggerType,
          shortcut:
            (triggerTypeSelected as TriggerType) === 'NO_TRIGGER'
              ? true
              : triggerBlockData.shortcut,
        });
        triggerTypeToggleOperations.setToggleToFalse();
        configurationSettingsToggleOperations.setToggleValue();
      }
    },
    [
      templateId,
      templateName,
      flowName,
      updateTriggerBlockData,
      triggerBlockData,
      triggerTypeToggleOperations,
      configurationSettingsToggleOperations,
    ],
  );

  return (
    <FlowBuilderBlockContainer blockStatus={blockStatus} id={id}>
      <FlowBlockHeader
        title={BUILDER_TRIGGER_BLOCK.TITLE_TEXT}
        icon="trigger"
        dataTestId="triggerBlockHeader"
        onEditButtonClick={() => {
          onEditButtonClick(index);
          triggerTypeToggleOperations.setToggleValue();
          if (configurationSettingsToggleState) {
            configurationSettingsToggleOperations.setToggleToFalse();
          }
        }}
        onLearnMoreButtonClick={() => {
          window.open(LEARN_MORE_HELPER_LINK);
        }}
        state={blockStatus}
      />
      <FlowAccordionItem
        icon="trigger"
        title={BUILDER_TRIGGER_BLOCK.TYPE_ACCORDION_ITEM_TEXT}
        subtitle={triggerTypeToggleState.toggleValue ? '' : triggerTypeSubTitle}
        dataTestId="triggerTypeAccordionItem"
        validation={triggerTypeStatus}
        state={blockStatus}
        isOpen={triggerTypeToggleState.toggleValue}
        onAccordionItemClick={() => {
          triggerTypeToggleOperations.setToggleValue();
          configurationSettingsToggleOperations.setToggleToFalse();
          notificationsToggleOperations.setToggleToFalse();
          onEditButtonClick(index);
        }}
      >
        <FlowAccordionItemTriggerContainer>
          <FlowTriggerType
            typeValue="SCHEDULED"
            onClick={onTriggerTypeAccordionClick}
            headingText={
              BUILDER_TRIGGER_BLOCK.SCHEDULED_DATE_TIME_ACCORDION_HEADING_TEXT
            }
            dataTestId="scheduled-clickable"
            bodyText={
              BUILDER_TRIGGER_BLOCK.SCHEDULED_DATE_TIME_ACCORDION_BODY_TEXT
            }
            icon="clock"
            isSelected={triggerType === 'SCHEDULED'}
          />
          <Divider
            marginTop="12px"
            marginBottom="12px"
            color={ThemeV2.palette.gray4}
            isFullWidth
          />
          <FlowTriggerType
            typeValue="ONDEMAND"
            onClick={onTriggerTypeAccordionClick}
            headingText={BUILDER_TRIGGER_BLOCK.ON_DEMAND_ACCORDION_HEADING_TEXT}
            bodyText={BUILDER_TRIGGER_BLOCK.ON_DEMAND_ACCORDION_BODY_TEXT}
            icon="run-now"
            dataTestId="ondemand-clickable"
            isSelected={triggerType === 'ONDEMAND'}
          />
          <Divider
            marginTop="12px"
            marginBottom="12px"
            color={ThemeV2.palette.gray4}
            isFullWidth
          />
          <FlowTriggerType
            typeValue="NO_TRIGGER"
            onClick={onTriggerTypeAccordionClick}
            headingText={
              BUILDER_TRIGGER_BLOCK.NO_TRIGGER_ACCORDION_HEADING_TEXT
            }
            bodyText={BUILDER_TRIGGER_BLOCK.NO_TRIGGER_ACCORDION_BODY_TEXT}
            icon="close-circle-filled"
            dataTestId="noTrigger-clickable"
            isSelected={triggerType === 'NO_TRIGGER'}
          />
        </FlowAccordionItemTriggerContainer>
      </FlowAccordionItem>

      <FlowAccordionItem
        icon="setting-filled"
        title={BUILDER_TRIGGER_BLOCK.CONFIGURATION_SETTINGS_ACCORDION_ITEM_TEXT}
        subtitle={
          configurationSettingsToggleState.toggleValue
            ? ''
            : configureSettingsSubTitle
        }
        dataTestId="settingsAccordionItem"
        validation={configurationSettingsStatus}
        state={
          !isEmpty(triggerBlockData.triggerType)
            ? blockStatus
            : FlowAccordionItemState.Disabled
        }
        isOpen={configurationSettingsToggleState.toggleValue}
        onAccordionItemClick={() => {
          triggerTypeToggleOperations.setToggleToFalse();
          configurationSettingsToggleOperations.setToggleValue();
          notificationsToggleOperations.setToggleToFalse();
          onEditButtonClick(index);
        }}
      >
        <FlowAccordionItemContainer>
          {triggerType === 'SCHEDULED' && (
            <ScheduleDateTimeController {...scheduleDateTimeControllerTypes} />
          )}
          {triggerType === 'ONDEMAND' && (
            <OnDemandController {...onDemandControllerTypes} />
          )}
          {triggerType === 'NO_TRIGGER' && (
            <ShortcutConfiguration
              isOccurrenceOn
              onOccurrenceToggle={() => {}}
              showTooltip
            />
          )}
        </FlowAccordionItemContainer>
      </FlowAccordionItem>

      {(triggerTypeToggleState.toggleValue ||
        configurationSettingsToggleState.toggleValue ||
        notificationsToggleState.toggleValue) &&
        blockStatus === FlowAccordionItemState.Focus && (
          <Border>
            <StyledNextButton
              variant="outlined"
              onClick={handleOnNextButtonClick}
              disabled={
                (triggerType === 'SCHEDULED' && isTriggerNextButtonDisabled) ||
                !(
                  triggerTypeStatus === FlowAccordionItemValidations.Success ||
                  notificationsStatus ===
                    FlowAccordionItemValidations.Success ||
                  configurationSettingsStatus ===
                    FlowAccordionItemValidations.Success
                )
              }
            >
              {NEXT}
            </StyledNextButton>
          </Border>
        )}
    </FlowBuilderBlockContainer>
  );
};

const MemoizedFlowsBuilderTriggerController = React.memo(
  FlowsBuilderTriggerController,
);

MemoizedFlowsBuilderTriggerController.displayName =
  'FlowsBuilderTriggerController';

export default MemoizedFlowsBuilderTriggerController;
