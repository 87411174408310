import React, { memo } from 'react';
import styled from 'styled-components';

import { lineHeightForFont } from '../../../Utils/styles/text';

import ThemeV2 from '../../../componentsV2/theme';

import Clickable from '../../atoms/Clickable_V2';

export interface ClickableLinkProps {
  children: string;
  onClickCallback: (data: unknown) => void;
  onHoverCallback?: (data: unknown) => void;
  data?: unknown;
  isActive?: boolean;
  fontSize?: string;
  lineHeight?: string;
}
interface StyledClickableLinkProps {
  fontSize?: string;
  lineHeight?: string;
  onClickCallback: (data: unknown) => void;
  onHoverCallback?: (data: unknown) => void;
  data: unknown;
}

const StyledClickableLink = styled(Clickable)<StyledClickableLinkProps>`
  font-family: ${ThemeV2.typography.adminFontFamily};
  font-size: ${(props) => props.fontSize};
  line-height: ${(props) => props.lineHeight};
  color: ${ThemeV2.palette.geekBlue6};
  font-weight: ${ThemeV2.typography.fontWeightMedium};
  &:hover {
    color: ${ThemeV2.palette.geekBlue5};
  }
  &:active {
    color: ${ThemeV2.palette.geekBlue7};
  }
`;

const ClickableLink = (props: ClickableLinkProps) => {
  const {
    children,
    fontSize = '14px',
    onClickCallback,
    onHoverCallback,
    lineHeight = lineHeightForFont(fontSize),
    data,
    ...rest
  } = props;
  return (
    <StyledClickableLink
      onClickCallback={onClickCallback}
      onHoverCallback={onHoverCallback}
      fontSize={fontSize}
      lineHeight={lineHeight}
      data={data}
      {...rest}
    >
      {children}
    </StyledClickableLink>
  );
};

const MemoizedClickableLink = memo(ClickableLink);
MemoizedClickableLink.displayName = 'ClickableLink';

export default MemoizedClickableLink;
