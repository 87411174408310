import React, { useState } from 'react';

import {
  feedPostHeaderModalSubmitButtonText,
  feedPostHeaderModalCancelButtonText,
  GET_DELETE_FLOW_CONFIRMATION_MODAL_INFO,
  GET_DELETE_FLOW_MODEL_INFO,
} from '../../../languages/en/home';

import { FlowsPostHeaderOptionProps } from './interface';
import { DropdownColor } from '../../molecules/Dropdown_V2/interfaces';
import { ButtonSize } from '../../atoms/IconButton_V2/interface';

import Body from '../../atoms/Body';
import DropdownIconButton from '../../molecules/DropdownIconButton';
import BasicModalTemplate, {
  ModalButtonProps,
} from '../../templates/BasicModalTemplate';

import {
  ModalBodyDropdown,
  ModalBodyHeading,
} from '../FeedPostHeaderOption/styles';
import { ModalContentProps } from '../FeedPostHeaderOption/interface';
import { feedPostDeleteModalDropdown } from '../FeedPostHeaderOption/data';

const FlowsPostHeaderOption = (props: FlowsPostHeaderOptionProps) => {
  const {
    dropdownOptions,
    icon,
    title,
    hasTrophies,
    dropdownToggleButtonSize = ButtonSize.Normal,
    onDeleteModalSubmitClick,
    onModalClose,
    onEditPost,
    onCopyLink,
    onDeletePostSelectedOption,
  } = props;
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [modalInfoContent, setModalInfoContent] = useState<ModalContentProps>(
    {},
  );
  const [modalButtonDropdownValue, setModalButtonDropdownValue] = useState('');
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState<boolean>(false);

  const handleModalClose = () => {
    setIsModalOpen(false);
    setIsDeleteConfirmationModalOpen(false);
    setModalButtonDropdownValue('');
    if (onModalClose) {
      onModalClose();
    }
  };

  const handleModalSubmit = () => {
    if (selectedOption === 'delete') {
      if (hasTrophies) {
        setModalInfoContent(GET_DELETE_FLOW_CONFIRMATION_MODAL_INFO);
        setIsDeleteConfirmationModalOpen(true);
        if (modalButtonDropdownValue !== '' && onDeleteModalSubmitClick) {
          onDeleteModalSubmitClick();
          handleModalClose();
        }
      } else if (onDeleteModalSubmitClick) {
        onDeleteModalSubmitClick();
        handleModalClose();
      }
    }
    return null;
  };

  const primaryButton: ModalButtonProps = {
    text: feedPostHeaderModalSubmitButtonText,
    status: 'warning',
    isLoading: false,
    onClick: handleModalSubmit,
    disabled: isDeleteConfirmationModalOpen && !modalButtonDropdownValue,
  };
  const secondaryButton: ModalButtonProps = {
    text: feedPostHeaderModalCancelButtonText,
    isLoading: false,
    onClick: handleModalClose,
  };

  const onOptionsSelect = (value: string | number) => {
    if (value === 'edit-post') {
      if (onEditPost) {
        onEditPost();
      }
    }
    if (value === 'delete') {
      setModalInfoContent(GET_DELETE_FLOW_MODEL_INFO);
      setIsModalOpen(true);
    }
    if (value === 'copy-link') {
      if (onCopyLink) {
        onCopyLink();
      }
    }
    setSelectedOption(value as string);
  };

  const handleDropdownButtonOptionSelect = (value: string | number) => {
    const selectedItem =
      (feedPostDeleteModalDropdown &&
        feedPostDeleteModalDropdown.options[0].items.filter(
          (dropdownItem) => dropdownItem.id === value,
        )) ||
      '';
    setModalButtonDropdownValue(selectedItem && selectedItem[0].value);
    if (onDeletePostSelectedOption) {
      onDeletePostSelectedOption(selectedItem[0].value || '');
    }
  };

  return (
    <>
      <DropdownIconButton
        menuItems={dropdownOptions}
        icon={icon}
        title={title}
        onItemClick={onOptionsSelect}
        size={dropdownToggleButtonSize}
      />
      <BasicModalTemplate
        isOpen={isModalOpen}
        heading={modalInfoContent.headerText || ''}
        primaryButton={primaryButton}
        secondaryButton={secondaryButton}
        onModalClose={handleModalClose}
        disableContentScroll
      >
        {modalInfoContent.contentHeading && (
          <ModalBodyHeading variant="h5">
            {modalInfoContent.contentHeading}
          </ModalBodyHeading>
        )}
        {modalInfoContent.contentText && (
          <Body variant="body2">{modalInfoContent.contentText}</Body>
        )}
        {isDeleteConfirmationModalOpen && (
          <ModalBodyDropdown
            isFullWidth
            color={DropdownColor.Secondary}
            value={
              modalButtonDropdownValue || feedPostDeleteModalDropdown.value
            }
            menuItems={feedPostDeleteModalDropdown.options}
            onItemClick={handleDropdownButtonOptionSelect}
          />
        )}
      </BasicModalTemplate>
    </>
  );
};

const MemoizedFlowsPostHeaderOption = React.memo(FlowsPostHeaderOption);
MemoizedFlowsPostHeaderOption.displayName = 'FlowsPostHeaderOption';
export default FlowsPostHeaderOption;
