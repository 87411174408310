import React from 'react';
import { CardWithIconProps, CardWithIconSize, LoadedProps } from './interfaces';
import { StyledEmoticonHolder, Wrapper, WrapperBase } from './styles';
import ThemeV2 from '../../../componentsV2/theme';
import { EmoticonHolderSizes } from '../EmoticonHolder/interface';
import { ComponentStatus } from '../../../interfaces/component';
import LoaderSmall from './LoaderSmall';
import LoaderRegular from './LoaderRegular';

const LoadedCardWithIcon = (props: LoadedProps) => {
  const {
    children,
    emoticon = '🧠',
    color = ThemeV2.palette.geekBlue2,
    className,
    onClick,
    onMouseEnter,
    onMouseLeave,
    dataTestId = '',
    size = CardWithIconSize.Regular,
  } = props;
  return (
    <Wrapper
      className={className}
      size={size}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      data-testid={dataTestId}
    >
      <StyledEmoticonHolder
        color={color}
        size={
          size === CardWithIconSize.Regular
            ? EmoticonHolderSizes.Large
            : EmoticonHolderSizes.Small
        }
        emoticon={emoticon}
      />
      {children}
    </Wrapper>
  );
};

const CardWithIcon = (props: CardWithIconProps) => {
  switch (props.status) {
    case ComponentStatus.LOADING: {
      if (props.size === CardWithIconSize.Small) {
        return (
          <WrapperBase>
            <LoaderSmall />
          </WrapperBase>
        );
      }

      return (
        <WrapperBase>
          <LoaderRegular />
        </WrapperBase>
      );
    }
    case ComponentStatus.LOADED:
      return <LoadedCardWithIcon {...props} />;
    default:
      throw new Error('Invalid Component Status');
  }
};

export default CardWithIcon;
CardWithIcon.displayName = 'CardWithIcon';
