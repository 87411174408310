import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Grid } from '../../../Utils/styles/display';
import KnowledgeCenterCard from '../../molecules/KnowledgeCenterCard';
import {
  FeedAttachmentItemFromAPI,
  GetFeedAttachmentResponse,
} from '../../../interfaces/Feed';
import { InfiniteData } from 'react-query';

const KnowledgeCenterCardGrid = (props: {
  data: InfiniteData<GetFeedAttachmentResponse>;
  hasNextPage: boolean | undefined;
  handleFetchNextPage: () => void;
  handleSetSelectedFile: (value: FeedAttachmentItemFromAPI | undefined) => void;
  copyPostLink: (flowId: string, responseId: string) => void;
  onDownloadFileClick: (
    fileDownload: FeedAttachmentItemFromAPI,
  ) => Promise<void>;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const {
    data,
    hasNextPage,
    handleFetchNextPage,
    handleSetSelectedFile,
    copyPostLink,
    onDownloadFileClick,
    setModalOpen,
  } = props;
  return (
    <InfiniteScroll
      next={handleFetchNextPage}
      dataLength={data.pages.length}
      hasMore={Boolean(hasNextPage)}
      style={{ overflow: 'inherit' }}
      scrollableTarget="scrollableFeedContent"
      loader={undefined}
    >
      <Grid columnGap={'16px'} rowGap={'16px'} data-testid="kc-grid">
        {data.pages.map((page, pageIndex) => (
          <>
            {page.data.map((card, cardIndex) => (
              <KnowledgeCenterCard
                key={
                  card.response.blockId +
                  card.fileInfo.name +
                  card.response.responseId
                }
                card={card}
                handleSetSelectedFile={handleSetSelectedFile}
                openPreviewer={setModalOpen}
                onClickDownload={(cardFile) => onDownloadFileClick(cardFile)}
                copyPostLink={copyPostLink}
                pageIndex={pageIndex}
                cardIndex={cardIndex}
              />
            ))}
          </>
        ))}
      </Grid>
    </InfiniteScroll>
  );
};

export default KnowledgeCenterCardGrid;
