import { DELETE } from '../../../../languages/en/singleWords';

export const dropdownOptions = [
  {
    id: 'feed-post-header-options',
    items: [
      {
        id: DELETE,
        value: DELETE,
        deleteItem: true,
        isSelected: false,
        prefixIcon: 'delete',
      },
    ],
  },
];
