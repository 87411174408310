import React from 'react';
import ContentLoader from 'react-content-loader';
import ThemeV2 from '../../../componentsV2/theme';

import styled from 'styled-components';

const StyledWrapper = styled.div`
  border: 1px solid ${ThemeV2.palette.gray4};
  border-bottom: 0;
  width: 100%;
`;

export const EntityItemLoader = () => {
  return (
    <StyledWrapper>
      <ContentLoader
        speed={2}
        width={'100%'}
        height={48}
        viewBox="0 0 100% 48"
        backgroundColor={ThemeV2.palette.gray4}
        foregroundColor={ThemeV2.palette.gray3}
        data-testid="loadingWrapper"
      >
        <rect x="5%" y="13" width="90%" height="30" />
      </ContentLoader>
    </StyledWrapper>
  );
};
export default EntityItemLoader;
