// @ts-nocheck TODO: type issues need to be fixed in this file
import React from 'react';
import { node, string } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import SVGIcon from '../../atoms/SVGIcon';
import IconBadge from '../../molecules/IconBadge';
import ThemeV2 from '../../../componentsV2/theme';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  content: {
    marginLeft: 16,
  },
});

const IconBadgeInfo = ({
  icon,
  iconColor,
  badgeColor,
  children,
  className: parentClassName,
}) => {
  const classes = useStyles();
  const className = `${classes.wrapper} ${parentClassName}`;
  return (
    <div className={className}>
      <IconBadge color={badgeColor}>
        <SVGIcon icon={icon} color={iconColor} />
      </IconBadge>
      <span className={classes.content}>{children}</span>
    </div>
  );
};

IconBadgeInfo.propTypes = {
  children: node.isRequired,
  icon: string.isRequired,
  iconColor: string,
  badgeColor: string,
  className: string,
};

IconBadgeInfo.defaultProps = {
  iconColor: ThemeV2.palette.white,
  badgeColor: ThemeV2.palette.aqua,
  className: '',
};

export default IconBadgeInfo;
