import styled from 'styled-components';

import Body from '../../atoms/Body';
import Image from '../../atoms/Image';
import ThemeV2 from '../../../componentsV2/theme';
import { Flex } from '../../../Utils/styles/display';
import MembersListEntryLoader from '../MembersListEntry/Loader';
import { zIndexCard } from '../../../Utils/styles/z-index';
import { ellipsisStyle } from '../../../Utils/styles/css';

export const Wrapper = styled.div<{
  restrictHeight: boolean;
  stickyHeader?: boolean;
}>`
  padding: ${({ stickyHeader = false }) =>
    stickyHeader ? '0px 24px 12px 24px' : '24px 24px 12px 24px'};
  background-color: ${ThemeV2.palette.gray3};
  border-left: 1px solid ${ThemeV2.palette.gray4};
  overflow-y: auto;
  max-height: ${({ restrictHeight = false }) =>
    restrictHeight ? '400px' : 'none'};
`;

export const TopWrapper = styled(Flex)`
  margin-bottom: 8px;
`;

export const StickyTopWrapper = styled(Flex)`
  padding: 24px 0 12px 0;
  position: sticky;
  top: 0;
  background-color: ${ThemeV2.palette.gray3};
  z-index: ${zIndexCard};
`;

export const BottomWrapper = styled.div`
  margin-bottom: 0;
`;

export const NoParticipantsWrapper = styled.div`
  max-width: 192px;
  margin-top: 32px;
`;

export const UserWrapper = styled(Flex)`
  height: 36px;
  margin-bottom: 8px;
  overflow: hidden;
`;

export const StyledFlex = styled(Flex)`
  overflow: hidden;
`;

export const StyledImage = styled(Image)`
  margin-bottom: 16px;
`;

export const StyledContentWrapper = styled.div`
  overflow: hidden;
`;

export const StyledBody = styled(Body)`
  ${ellipsisStyle}
  padding: 0 8px;
`;

export const StyledMembersListEntryLoader = styled(MembersListEntryLoader)`
  margin-left: 0;
`;

export const StyledParticipantsListTitle = styled(Body)`
  margin-right: 5px;
`;
