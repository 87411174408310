import React, { useMemo } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import NavigationPrompt from 'react-router-navigation-prompt';
import ConfirmationModal from '../../../atomic/molecules/Notebook/ConfirmationModal';
import RouteTabs from '../../../atomic/organism/RouteTabs';
import { NOTEBOOK } from '../../../constants/routes';
import { NOTEBOOK_NAVIGATION_MODAL_LABELS } from '../../../languages/en/notebook';
import NotebookGroupController from '../NotebookGroupController';
import {
  NotebookContainer,
  NotebookNavigationModal,
  TabContent,
} from './styles';
import useNotebookPageController from './useNotebookPageController';
import {
  notebookSections,
  notebookTabsData,
  TabsIndexMap,
} from './useNotebookPageController/data';

const NotebookController = () => {
  const {
    isOpen,
    modalProps,
    hasDropdown,
    hasCheckbox,
    currentTab,
    erroredTaskIDs,
    hideNotebookNavigationModal,
    shouldShowModalForCurrentUser,
    handleTabChange,
    onDragEnd,
    setModalData,
    handleDisableModalPrompts,
    markTasksAsRead,
    onPrimaryButtonClick,
    onSecondaryButtonClick,
  } = useNotebookPageController();

  const tabsValue = useMemo(() => TabsIndexMap[currentTab], [currentTab]);

  const tabsComponent = useMemo(
    () => (
      <DragDropContext onDragEnd={onDragEnd}>
        {notebookSections.map((section) => {
          return (
            <NotebookGroupController
              tabType={currentTab}
              key={section}
              type={section}
              markTasksAsRead={markTasksAsRead}
              shouldShowModalForCurrentUser={shouldShowModalForCurrentUser}
            />
          );
        })}
      </DragDropContext>
    ),
    [currentTab, shouldShowModalForCurrentUser, markTasksAsRead, onDragEnd],
  );

  return (
    <>
      <RouteTabs
        routeTabButtons={notebookTabsData}
        urlBase={NOTEBOOK}
        handleTabChange={handleTabChange}
        isFixed
      />
      <NotebookContainer>
        {notebookTabsData.map((data) => (
          <TabContent value={tabsValue} key={data.value} index={data.index}>
            {tabsComponent}
          </TabContent>
        ))}
        <ConfirmationModal
          isOpen={isOpen && (shouldShowModalForCurrentUser || !hasCheckbox)}
          modalProps={modalProps}
          toggleOpen={setModalData}
          onPrimaryButtonClick={onPrimaryButtonClick}
          onSecondaryButtonClick={onSecondaryButtonClick}
          hasDropdown={hasDropdown}
          hasCheckbox={hasCheckbox}
          handleDisableModalPrompts={handleDisableModalPrompts}
        />
        <NavigationPrompt
          when={(currentLocation, nextLocation) =>
            !hideNotebookNavigationModal &&
            erroredTaskIDs.length > 0 &&
            location.pathname.includes('notebook') &&
            !nextLocation?.pathname.includes('notebook')
          }
          renderIfNotActive={false}
        >
          {({ onConfirm, onCancel }) => (
            <NotebookNavigationModal
              isOpen={!hideNotebookNavigationModal}
              onModalClose={onCancel}
              heading={NOTEBOOK_NAVIGATION_MODAL_LABELS.HEADING}
              primaryButton={{
                text: NOTEBOOK_NAVIGATION_MODAL_LABELS.PRIMARY_BUTTON.TEXT,
                onClick: onConfirm,
                status: 'warning',
              }}
              secondaryButton={{
                text: NOTEBOOK_NAVIGATION_MODAL_LABELS.SECONDARY_BUTTON.TEXT,
                onClick: onCancel,
              }}
            >
              {
                NOTEBOOK_NAVIGATION_MODAL_LABELS.NOTEBOOK_NAVIGATION_MODAL_PREFIX_TEXT
              }
            </NotebookNavigationModal>
          )}
        </NavigationPrompt>
      </NotebookContainer>
    </>
  );
};

export default NotebookController;
