import React from 'react';
import styled from 'styled-components';

import ThemeV2 from '../../../componentsV2/theme';
import Body from '../Body';
import {
  StyledNavItem,
  StyledEmoticon,
  Badge,
  StyledSVGIcon,
  RightWrapper,
  StyledSVGPrefixIcon,
  ChildrenWrapper,
  StyledIconContainer,
  StyledMainIcon,
} from './styles';
import { NavItemProps } from './types';
import Avatar from '../Avatar';

const StyledAvatar = styled(Avatar)`
  margin-right: 8px;
`;

const NavItem = (props: NavItemProps) => {
  const {
    children,
    textVariant,
    color,
    emoticon,
    avatar,
    fullName,
    userId,
    isActive = false,
    badgeText,
    badgeColor,
    badgeTextColor,
    prefixIcon,
    prefixIconColor,
    suffixIcon,
    className,
    mainIcon,
    mainIconBg = ThemeV2.palette.geekBlue6,
    mainIconColor = ThemeV2.palette.white,
  } = props;

  const renderAvatar = () => {
    if (mainIcon) {
      return (
        <StyledIconContainer
          bgColor={mainIconBg}
          borderRadius="50%"
          size="24px"
        >
          <StyledMainIcon
            hoverColor={mainIconColor}
            color={mainIconColor}
            icon={mainIcon}
            size="13px"
          />
        </StyledIconContainer>
      );
    }

    if (avatar) {
      return (
        <StyledAvatar
          img={avatar}
          name={fullName}
          size="24px"
          userId={userId}
        />
      );
    }

    if (fullName && !avatar) {
      return <StyledAvatar name={fullName} size="24px" userId={userId} />;
    }

    return null;
  };

  return (
    <StyledNavItem
      isActive={isActive}
      variant={textVariant}
      color={color}
      className={className}
      textAlign="initial"
    >
      {prefixIcon && (
        <StyledSVGPrefixIcon
          icon={prefixIcon}
          size="16px"
          color={prefixIconColor || color}
        />
      )}
      {emoticon && (
        <StyledEmoticon role="img" aria-label="emotion">
          {emoticon}
        </StyledEmoticon>
      )}
      {renderAvatar()}
      <ChildrenWrapper>{children}</ChildrenWrapper>
      <RightWrapper>
        {suffixIcon && (
          <StyledSVGIcon icon={suffixIcon} size="16px" color={color} />
        )}
        {badgeText && (
          <Badge color={badgeColor}>
            <Body variant="body3" color={badgeTextColor || 'gray1'}>
              {badgeText}
            </Body>
          </Badge>
        )}
      </RightWrapper>
    </StyledNavItem>
  );
};

export default NavItem;
