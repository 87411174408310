import React, { useCallback, useMemo } from 'react';
import { EmojiData } from 'emoji-mart';
import { ReactionBarProps } from './interface';

import {
  ReactedMembersListWrapper,
  StyledToggleButton,
  StyledReactionButton,
} from './styles';
import EmoticonPickerPopover from '../../molecules/EmoticonPickerPopover';
import {
  FEED_POST_REACTIONS_REACT_TEXT,
  REACTION_PICKER_TOOLTIP_TEXT,
} from '../../../languages/en/home';
import ToolTip from '../../molecules/ToolTipV2';

import { formatReactedMembersList } from './utils';
import { PopoverChildrenProps } from '../../../hooks/usePopover/interface';
import { Reaction } from '../../../interfaces/Feed';

const ReactionBar = (props: ReactionBarProps) => {
  const {
    currentUserId,
    reactions,
    isPickerDisabled = false,
    onReactButtonClick,
    onReactionToggleClick,
    onEmoticonSelect,
    toggleButtonVariant,
    toggleButtonIconSize,
    reactionButtonSize,
    contentId,
  } = props;

  const handleEmoticonSelect = useCallback(
    (emoji: EmojiData) => {
      onEmoticonSelect(emoji, contentId);
    },
    [onEmoticonSelect, contentId],
  );

  const handleReactionButtonClick = useCallback(
    (reaction) => {
      onReactionToggleClick(reaction as Reaction, contentId);
    },
    [onReactionToggleClick, contentId],
  );

  const renderEmoticon = useMemo(
    () => (
      <EmoticonPickerPopover onEmoticonSelect={handleEmoticonSelect}>
        {({ togglePopover }: PopoverChildrenProps) => (
          <StyledToggleButton
            onClick={() => {
              togglePopover();
              if (onReactButtonClick) {
                onReactButtonClick();
              }
            }}
            icon="reaction"
            disabled={isPickerDisabled}
            variant={toggleButtonVariant}
            iconSize={toggleButtonIconSize}
          >
            {FEED_POST_REACTIONS_REACT_TEXT}
          </StyledToggleButton>
        )}
      </EmoticonPickerPopover>
    ),
    [
      handleEmoticonSelect,
      isPickerDisabled,
      onReactButtonClick,
      toggleButtonIconSize,
      toggleButtonVariant,
    ],
  );

  return (
    <>
      <ToolTip
        position="top"
        disabled={!isPickerDisabled}
        toolTipComponent={renderEmoticon}
      >
        {REACTION_PICKER_TOOLTIP_TEXT}
      </ToolTip>
      {reactions.map((reactionObj: Reaction) => {
        const { active, timesReacted, reaction } = reactionObj;
        return (
          <React.Fragment key={reaction.name}>
            <ToolTip
              position="top"
              toolTipComponent={
                <StyledReactionButton
                  onClick={() => {
                    handleReactionButtonClick(reactionObj);
                  }}
                  active={active}
                  label={reaction.displayName || reaction.name}
                  reaction={reaction}
                  size={reactionButtonSize}
                >
                  {timesReacted}
                </StyledReactionButton>
              }
            >
              <ReactedMembersListWrapper>
                {formatReactedMembersList(reactionObj.members, currentUserId)}
              </ReactedMembersListWrapper>
            </ToolTip>
          </React.Fragment>
        );
      })}
    </>
  );
};

const MemoizedReactionBar = React.memo(ReactionBar);
MemoizedReactionBar.displayName = 'ReactionBar';
export default MemoizedReactionBar;
