import React from 'react';
import { singularizeWord } from '../../../Utils/text';
import Heading from '../../atoms/Heading';
import { ModalButtonProps } from '../../templates/BasicModalTemplate';
import {
  DropdownColor,
  DropdownPlacement,
  DropdownVariants,
} from '../../molecules/Dropdown_V2/interfaces';
import { repeatEveryOptions } from './data';
import {
  RepeatEveryRow,
  RepeatOnRow,
  StyledBasicModalTemplate,
  StyledDropdown,
  StyledInput,
  StyledLabel,
} from './styles';
import {
  CustomRecurrenceProps,
  DayId,
  SelectedCustomRecurrenceTypes,
} from './types';
import SelectableButton from '../../atoms/SelectableButton';
import { ModalBodyDropdown } from '../FeedPostHeaderOption/styles';
import Body from '../../atoms/Body';
import {
  CUSTOM_RECURRENCE,
  MONTHLY_ON_THE,
  REPEAT_EVERY,
  REPEAT_ON,
} from '../../../languages/en/flows/builder';
import useCustomRecurrence from './useCustomRecurrence';

const CustomRecurrence = (props: CustomRecurrenceProps) => {
  const {
    isModalOpen,
    repeatEveryCountDefault = 1,
    repeatEveryDefault = 'weeks',
    defaultDayIds,
    selectedMonthlyOnOptionDefault = '',
    monthlyOnOptions,
    handleModalClose,
    handleSubmit,
  } = props;

  const {
    models: {
      repeatOnDays,
      repeatEveryCount,
      repeatEvery,
      repeatOnMonth,
      selectedMonthlyOnOption,
    },
    operations: {
      handleRepeatCountChange,
      handleRepeatEveryChange,
      handleRepeatOnDayClick,
      handleMonthlyOnOptionClick,
    },
  } = useCustomRecurrence({
    defaultDayIds,
    repeatEveryCountDefault,
    repeatEveryDefault,
    selectedMonthlyOnOptionDefault,
    monthlyOnOptions,
  });

  const onRepeatEveryItemClick = (e: string | number) => {
    handleRepeatEveryChange(e);
  };

  const onRepeatEveryCountChange = (e: { target: { value: number } }) => {
    handleRepeatCountChange(e.target.value);
  };

  const primaryButton: ModalButtonProps = {
    text: 'Done',
    onClick: () => {
      const selectedDayIds = repeatOnDays
        .filter((item) => item.isSelected)
        .map((item) => item.id);
      const data: SelectedCustomRecurrenceTypes = {
        repeatEveryCount: parseInt(repeatEveryCount.toString(), 10),
        repeatEvery,
        selectedDayIds,
        repeatOnMonth,
      };
      handleSubmit(data);
    },
  };
  const secondaryButton: ModalButtonProps = {
    text: 'Cancel',
    onClick: () => {
      handleModalClose();
    },
  };

  const onModalClose = () => {
    handleModalClose();
  };

  return (
    <StyledBasicModalTemplate
      isOpen={isModalOpen}
      hasTitleSection={false}
      isLoading={false}
      primaryButton={primaryButton}
      secondaryButton={secondaryButton}
      onModalClose={onModalClose}
      alignActionButtons="right"
    >
      <Heading variant="h6" weight="regular" marginBottom="20px">
        {CUSTOM_RECURRENCE}
      </Heading>
      <StyledLabel>{REPEAT_EVERY}</StyledLabel>
      <RepeatEveryRow>
        <StyledInput
          name="repeat-every"
          onChange={onRepeatEveryCountChange}
          value={repeatEveryCount}
        />
        <StyledDropdown
          menuItems={repeatEveryOptions}
          value={repeatEvery}
          variant={DropdownVariants.Text}
          color={DropdownColor.Secondary}
          dropdownPlacement={DropdownPlacement.BottomStart}
          onItemClick={onRepeatEveryItemClick}
          getItemLabel={(label: string) =>
            repeatEveryCount > 1 ? label : singularizeWord(label)
          }
          isDropdownPositionFixed
        />
      </RepeatEveryRow>
      {repeatEvery === 'weeks' && (
        <>
          <StyledLabel>{REPEAT_ON}</StyledLabel>
          <RepeatOnRow>
            {repeatOnDays.map((day) => (
              <SelectableButton
                key={day.id}
                onClick={() => handleRepeatOnDayClick(day.id as DayId)}
                isActive={day.isSelected}
              >
                {day.label}
              </SelectableButton>
            ))}
          </RepeatOnRow>
        </>
      )}
      {repeatEvery === 'months' && (
        <>
          <Body>{MONTHLY_ON_THE}</Body>
          <ModalBodyDropdown
            isFullWidth
            color={DropdownColor.Secondary}
            value={selectedMonthlyOnOption}
            menuItems={monthlyOnOptions}
            onItemClick={handleMonthlyOnOptionClick}
            isDropdownPositionFixed
          />
        </>
      )}
    </StyledBasicModalTemplate>
  );
};

export default CustomRecurrence;
