import React, { useEffect, useMemo, useState } from 'react';
import {
  useFlowBlockMembersSearch,
  useGetMembersFromCriteria,
} from '../../../../hooks/useMembersSearch';
import usePrevious from '../../../../hooks/usePrevious';
import { SelectablePeopleSelectorOptions } from '../../../../interfaces/Flow';
import { IMemberDTO } from '../../../../interfaces/member';
import { generateMemberAutocompleteOptions } from '../../../../Utils/flows';
import { mapRulesFromCriteriaGroups } from '../../../../Utils/flows/builder/utils';
import { AutocompleteDropdownItem } from '../../../organism/Autocomplete/interfaces';
import FlowMultiselectInputBlock from '../FlowMultiselectInputBlock';
import FlowsDropdownInputBlock from '../FlowsDropdownInputBlock';

type PossibleValues =
  | AutocompleteDropdownItem<string, unknown>[]
  | AutocompleteDropdownItem<string, unknown>
  | null;

type FlowPersonSelectorBlockProps = {
  blockError?: string;
  fieldError?: string;
  title: string;
  description?: string;
  goToNextStep: () => void;
  inputLabel: string;
  value: PossibleValues;
  onChange: (val: PossibleValues) => void;
  isLastBlock?: boolean;
  isRequired: boolean;
  warning?: React.ReactNode;
  optionsMaxLimit?: number;
  currentUserId: string;
  flowId: string;
  blockId: string;
  referenceStackMaxPoints?: number;
  referenceStackValue?: string;
  onPointsStackChange?: (newValue: string) => void;
  isPreviewFlow?: boolean;
  selectableCriteria?: SelectablePeopleSelectorOptions;
  owner?: AutocompleteDropdownItem<string, IMemberDTO>[];
  isExternalFlow?: boolean;
};

const FlowPersonSelectorBlock = ({
  owner,
  goToNextStep,
  inputLabel,
  isRequired,
  title,
  blockError,
  description,
  fieldError,
  warning,
  value,
  onChange,
  currentUserId,
  blockId,
  flowId,
  optionsMaxLimit,
  referenceStackValue,
  onPointsStackChange,
  referenceStackMaxPoints,
  isLastBlock = false,
  isPreviewFlow,
  selectableCriteria,
  isExternalFlow = false,
}: FlowPersonSelectorBlockProps) => {
  const previewCriteriaRules = useMemo(() => {
    if (isPreviewFlow && selectableCriteria) {
      return selectableCriteria.type === 'CUSTOM'
        ? { criteria: selectableCriteria.criteria }
        : mapRulesFromCriteriaGroups(selectableCriteria.criteria);
    }
    return null;
  }, [isPreviewFlow, selectableCriteria]);
  const membersFromCriteriaObject = useGetMembersFromCriteria(
    previewCriteriaRules,
    20,
    selectableCriteria?.type === 'VIEWERS' && owner
      ? {
          isViewingCriteria: true,
          ownerId: owner[0]?.id,
          isNewMembersAdded: true,
        }
      : {
          isNewMembersAdded: true,
        },
  );
  const flowBlockMembersObject = useFlowBlockMembersSearch(
    flowId,
    blockId,
    !previewCriteriaRules,
    isExternalFlow,
  );

  const {
    models: {
      value: textboxValue,
      searchedMembers,
      isFetching: isMembersFetching,
      hasMoreMembers,
    },
    operations: { onChange: onTextboxValueChange, fetchMoreMembers },
  } = useMemo(
    () =>
      previewCriteriaRules ? membersFromCriteriaObject : flowBlockMembersObject,
    [previewCriteriaRules, membersFromCriteriaObject, flowBlockMembersObject],
  );
  const [havePointsBeenAdjusted, setHavePointsBeenAdjusted] = useState(false);
  const previousMaxPoints = usePrevious<undefined | number>(
    referenceStackMaxPoints,
  );

  const memberOptions: AutocompleteDropdownItem<string, IMemberDTO>[] =
    useMemo(() => {
      const removeCurrentUser = referenceStackValue !== undefined;
      if (searchedMembers) {
        return generateMemberAutocompleteOptions(
          searchedMembers,
          currentUserId,
          removeCurrentUser,
        );
      }
      return [];
    }, [currentUserId, referenceStackValue, searchedMembers]);

  const onPeopleOptionsScroll = () => {
    if (hasMoreMembers && !isMembersFetching) {
      fetchMoreMembers();
    }
  };
  const handleChange = (val: PossibleValues) => {
    onChange(val);
    onTextboxValueChange('');
  };

  useEffect(() => {
    if (
      onPointsStackChange &&
      previousMaxPoints &&
      Boolean(referenceStackValue) &&
      referenceStackMaxPoints !== previousMaxPoints
    ) {
      setHavePointsBeenAdjusted(true);
      if (referenceStackMaxPoints) {
        onPointsStackChange(String(referenceStackMaxPoints));
      }
    }
  }, [
    previousMaxPoints,
    referenceStackMaxPoints,
    onPointsStackChange,
    referenceStackValue,
  ]);

  if (Array.isArray(value)) {
    return (
      <FlowMultiselectInputBlock
        description={description}
        fieldError={fieldError}
        blockError={blockError}
        goToNextStep={goToNextStep}
        title={title}
        inputLabel={inputLabel}
        isRequired={isRequired}
        value={value}
        onChange={handleChange}
        onBlur={() => {
          onTextboxValueChange('');
        }}
        options={memberOptions}
        loading={isMembersFetching}
        textboxValue={textboxValue}
        onTextboxValueChange={onTextboxValueChange}
        inputBaseRightPadding="48px"
        onOptionsScroll={onPeopleOptionsScroll}
        warning={havePointsBeenAdjusted && warning}
        optionsMaxLimit={optionsMaxLimit}
        isLastBlock={isLastBlock}
        isPreviewFlow={isPreviewFlow}
      />
    );
  }
  return (
    <FlowsDropdownInputBlock
      fieldError={fieldError}
      blockError={blockError}
      goToNextStep={goToNextStep}
      title={title}
      inputLabel={inputLabel}
      value={value}
      onChange={handleChange}
      options={memberOptions}
      isRequired={isRequired}
      textboxValue={textboxValue}
      onTextboxValueChange={onTextboxValueChange}
      loading={isMembersFetching}
      onOptionsScroll={onPeopleOptionsScroll}
      description={description}
      isLastBlock={isLastBlock}
      isPreviewFlow={isPreviewFlow}
    />
  );
};

export default FlowPersonSelectorBlock;
