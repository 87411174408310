import { useCallback, useEffect, useMemo, useState } from 'react';

import { FooterControllerProps } from '../type';
import { QuickSetupStep } from '../../QuickSetupController/types';
import { useUpdateQuickSetupStatus } from '../../../../queries/QuickSetup';
import {
  InviteNewMembersResponse,
  useInviteUserPostQuery,
} from '../../../../queries/Admin/invites';
import { quickSetupButtonTexts } from '../../../../languages/en/quickSetup';
import { ModalButtonProps } from '../../../../atomic/templates/BasicModalTemplate';

import {
  QuickSetupStatusEnum,
  QuickSetupDirectionEnum,
  QuickSetupCalendarStepsEnum,
  SurveyStepsEnum,
} from '../../../../queries/QuickSetup/interfaces';
import {
  ACCOUNT_ONBOARDING_EVENTS,
  INVITE_MEMBER_EVENTS,
} from '../../../../Utils/analytics/constants';
import { trackOnboardingActionEvent } from '../../../../Utils/analytics/onboarding';
import { AxiosResponse } from 'axios';
import { QUICK_SETUP } from '../../../../constants/routes';
import { useHistory } from 'react-router-dom';
import { useProfileInfoFetchQuery } from '../../../../queries/Profile';
import { trackInviteMemberActionEvent } from '../../../../Utils/analytics/inviteMembers';

const useFooterController = (props: FooterControllerProps) => {
  const { mutate: mutateQuickSetupStatus } = useUpdateQuickSetupStatus();

  const {
    meetingId,
    calenderId,
    flow,
    meetingParticipants,
    currentStep,
    onQuickSetupStepChange,
    onTemplateChange,
    templateId,
    meetingName,
    meetingReccurence,
    isInviteButtonDisabled,
    onInviteNewMembers,
    invitedMembers,
    onInviteNewMembersChange,
  } = props;

  const history = useHistory();

  const { mutate: uploadInviteUserAction } = useInviteUserPostQuery();
  const { data: profileData } = useProfileInfoFetchQuery();
  // Modal
  const [isModalOpen, setModalOpen] = useState(false);

  const [modalPrimaryButtonProps, setModalPrimaryButtonProps] = useState<
    ModalButtonProps | undefined
  >(undefined);

  const [modalSecondaryButtonProps, setModalSecondaryButtonProps] = useState<
    ModalButtonProps | undefined
  >(undefined);

  const handleModalClose = useCallback(() => {
    trackOnboardingActionEvent({
      action: ACCOUNT_ONBOARDING_EVENTS.INVITE_LATER,
      path: flow,
      templateId: templateId,
      meetingTitle: meetingName,
      meetingParticipants: invitedMembers,
      meetingRecurs: meetingReccurence
        ? !meetingReccurence.includes('COUNT=1')
        : undefined,
    });

    if (isModalOpen) {
      setModalOpen(false);
    }
  }, [
    flow,
    isModalOpen,
    meetingName,
    meetingReccurence,
    templateId,
    invitedMembers,
  ]);

  // Footer Buttons

  const [alignButtons, setAlignButtons] = useState<string>('right');

  const [primaryButtonProps, setPrimaryButtonProps] = useState<
    ModalButtonProps | undefined
  >(undefined);

  const [secondaryButtonProps, setSecondaryButtonProps] = useState<
    ModalButtonProps | undefined
  >(undefined);

  const [tertiaryButtonProps, setTertiaryButtonProps] = useState<
    ModalButtonProps | undefined
  >(undefined);

  /* Mix Panel */
  const source = 'onboarding';
  const subSource = 'calendarConnect';
  /* Mix Panel */

  const handleCreateFlowButtonClick = useCallback(() => {
    trackInviteMemberActionEvent({
      action: INVITE_MEMBER_EVENTS.INVITE_START,
      inviterId: profileData?.member.memberId,
      inviterRole: profileData?.member.role,
      source: source,
      subSource: subSource,
    });
    trackOnboardingActionEvent({
      action: ACCOUNT_ONBOARDING_EVENTS.INVITE_CREATE,
      path: flow,
      templateId: templateId,
      meetingTitle: meetingName,
      meetingParticipants: invitedMembers,
      meetingRecurs: meetingReccurence
        ? !meetingReccurence.includes('COUNT=1')
        : undefined,
    });

    const emails = meetingParticipants
      .filter(
        (participant) =>
          participant.email &&
          !participant.owner &&
          participant.status !== `Don't Invite`,
      )
      .map((participant) => ({ email: participant.email }));
    const payload = {
      data: emails,
    };

    uploadInviteUserAction(payload, {
      onSettled: () => {
        onInviteNewMembers(true);
        onQuickSetupStepChange(QuickSetupStep.COMPLETION);
      },
      onSuccess: (data: AxiosResponse<InviteNewMembersResponse>) => {
        const membersList = data.data.addedMembers.map((x) => x.memberId);
        onInviteNewMembersChange(membersList);
        onInviteNewMembers(true);
        onQuickSetupStepChange(QuickSetupStep.COMPLETION);
        /* Mix Panel */
        trackInviteMemberActionEvent({
          action: INVITE_MEMBER_EVENTS.INVITE_COMPLETE,
          inviterId: profileData?.member.memberId,
          inviterRole: profileData?.member.role,
          source: source,
          subSource: subSource,
        });
        /* Mix Panel */
      },
    });
  }, [
    flow,
    invitedMembers,
    meetingName,
    meetingParticipants,
    meetingReccurence,
    onInviteNewMembers,
    onInviteNewMembersChange,
    onQuickSetupStepChange,
    profileData,
    templateId,
    uploadInviteUserAction,
  ]);

  const handleLeaveSetupButtonClick = useCallback(() => {
    setModalOpen(true);
    setModalPrimaryButtonProps({
      text: quickSetupButtonTexts.leaveSetup.primaryButtonText,
      variant: 'contained',
      onClick: () => {
        trackOnboardingActionEvent({
          action: ACCOUNT_ONBOARDING_EVENTS.LEAVE_SETUP,
          path: flow,
          templateId: templateId,
          meetingTitle: meetingName,
          meetingRecurs: meetingReccurence
            ? !meetingReccurence.includes('COUNT=1')
            : undefined,
        });
        onQuickSetupStepChange(QuickSetupStep.COMPLETION);
        history.push(`${QUICK_SETUP}?step=COMPLETION`);
        mutateQuickSetupStatus({
          direction: QuickSetupDirectionEnum.FORWARD,
          status: QuickSetupStatusEnum.SKIPPED,
        });
      },
      status: 'warning',
    });
    setModalSecondaryButtonProps({
      text: quickSetupButtonTexts.leaveSetup.secondaryButtonText,
      variant: 'contained',
      onClick: () => {
        setModalOpen(false);
      },
      color: 'secondary',
    });
    // Removed history from the deps because it is causing excessive re-renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    flow,
    templateId,
    meetingName,
    meetingReccurence,
    onQuickSetupStepChange,
    mutateQuickSetupStatus,
  ]);

  const handleInviteLaterButtonClick = useCallback(() => {
    setModalOpen(true);
    setModalPrimaryButtonProps({
      text: quickSetupButtonTexts.inviteLater.primaryButtonText,
      variant: 'contained',
      onClick: handleCreateFlowButtonClick,
      disabled: isInviteButtonDisabled,
    });
    setModalSecondaryButtonProps({
      text: quickSetupButtonTexts.inviteLater.secondaryButtonText,
      variant: 'contained',
      onClick: () => {
        setModalOpen(false);
      },
      color: 'secondary',
    });
  }, [handleCreateFlowButtonClick, isInviteButtonDisabled]);

  const leaveSetupButtonProps: ModalButtonProps = useMemo(() => {
    return {
      text: quickSetupButtonTexts.leaveSetupButton,
      onClick: handleLeaveSetupButtonClick,
      variant: 'text',
      color: 'secondary',
    };
  }, [handleLeaveSetupButtonClick]);

  useEffect(() => {
    switch (currentStep) {
      case QuickSetupStep.CALENDAR_SELECTION:
        setPrimaryButtonProps(leaveSetupButtonProps);
        setSecondaryButtonProps(undefined);
        setTertiaryButtonProps({
          text: quickSetupButtonTexts.goBackButton,
          onClick: () => {
            trackOnboardingActionEvent({
              action: ACCOUNT_ONBOARDING_EVENTS.GO_BACK,
              path: flow,
              templateId: templateId,
              meetingTitle: meetingName,
              meetingRecurs: meetingReccurence
                ? !meetingReccurence.includes('COUNT=1')
                : undefined,
            });
            onQuickSetupStepChange(QuickSetupStep.QUICK_SETUP_OPTION_SELECTION);
            mutateQuickSetupStatus({
              direction: QuickSetupDirectionEnum.BACKWARD,
            });
          },
          variant: 'text',
          color: 'secondary',
          icon: 'arrow-left',
        });
        setAlignButtons('left');
        break;

      case QuickSetupStep.SURVEY_TEAM:
        setPrimaryButtonProps({
          text: quickSetupButtonTexts.exploreTemplatesMyself,
          onClick: () => {
            onQuickSetupStepChange(QuickSetupStep.SELECT_TEMPLATE);
            trackOnboardingActionEvent({
              action: ACCOUNT_ONBOARDING_EVENTS.EXPLORE_TEMPLATES,
              path: flow,
              templateId: templateId,
              meetingTitle: meetingName,
            });
            mutateQuickSetupStatus({
              direction: QuickSetupDirectionEnum.FORWARD,
              survey: {
                value: SurveyStepsEnum.LIST_TEMPLATE,
              },
            });
          },
          variant: 'text',
          color: 'secondary',
        });
        setSecondaryButtonProps(undefined);
        setTertiaryButtonProps({
          text: quickSetupButtonTexts.goBackButton,
          onClick: () => {
            trackOnboardingActionEvent({
              action: ACCOUNT_ONBOARDING_EVENTS.GO_BACK,
              path: flow,
              templateId: templateId,
              meetingTitle: meetingName,
              meetingRecurs: meetingReccurence
                ? !meetingReccurence.includes('COUNT=1')
                : undefined,
            });
            onQuickSetupStepChange(QuickSetupStep.QUICK_SETUP_OPTION_SELECTION);
          },
          variant: 'text',
          color: 'secondary',
          icon: 'arrow-left',
        });
        setAlignButtons('left');
        break;

      case QuickSetupStep.MEETINGS_LIST:
        setPrimaryButtonProps({
          text: quickSetupButtonTexts.meetingsListScreenButtons
            .primaryButtonText,
          onClick: () => {
            trackOnboardingActionEvent({
              action: ACCOUNT_ONBOARDING_EVENTS.NEXT_STEP,
              path: flow,
              templateId: templateId,
              meetingTitle: meetingName,
              meetingRecurs: meetingReccurence
                ? !meetingReccurence.includes('COUNT=1')
                : undefined,
            });

            if (flow === 'survey_Team') {
              mutateQuickSetupStatus({
                direction: QuickSetupDirectionEnum.FORWARD,
                survey: {
                  value: SurveyStepsEnum.LIST_TEMPLATE,
                  metaData: {
                    meetingId: meetingId,
                    calenderId: calenderId,
                    meetingName: meetingName,
                    rrule: meetingReccurence,
                  },
                },
              });
            } else {
              mutateQuickSetupStatus({
                direction: QuickSetupDirectionEnum.FORWARD,
                calendar: {
                  value: QuickSetupCalendarStepsEnum.LIST_TEMPLATE,
                  metaData: {
                    meetingId: meetingId,
                    calenderId: calenderId,
                    meetingName: meetingName,
                    rrule: meetingReccurence,
                  },
                },
              });
            }

            onQuickSetupStepChange(QuickSetupStep.SELECT_TEMPLATE);
          },
          variant: 'contained',
          color: 'primary',
          disabled: meetingName === '' || meetingName === 'undefined',
        });
        setSecondaryButtonProps(leaveSetupButtonProps);
        setTertiaryButtonProps(undefined);
        setAlignButtons('left');
        break;

      case QuickSetupStep.SELECT_TEMPLATE:
        setPrimaryButtonProps(leaveSetupButtonProps);
        setSecondaryButtonProps(undefined);
        setTertiaryButtonProps({
          text: quickSetupButtonTexts.goBackButton,
          onClick: () => {
            trackOnboardingActionEvent({
              action: ACCOUNT_ONBOARDING_EVENTS.GO_BACK,
              path: flow,
              templateId: templateId,
              meetingTitle: meetingName,
              meetingRecurs: meetingReccurence
                ? !meetingReccurence.includes('COUNT=1')
                : undefined,
            });
            mutateQuickSetupStatus({
              direction: QuickSetupDirectionEnum.BACKWARD,
            });
            if (flow === 'survey_Team') {
              onQuickSetupStepChange(QuickSetupStep.SURVEY_TEAM);
            } else {
              onQuickSetupStepChange(QuickSetupStep.MEETINGS_LIST);
            }
          },
          variant: 'text',
          color: 'secondary',
          icon: 'arrow-left',
        });
        setAlignButtons('left');
        break;

      case QuickSetupStep.ADD_PARTICIPANTS:
        setPrimaryButtonProps({
          text: quickSetupButtonTexts.addParticipantsScreenButtons
            .primaryButtonText,
          onClick: handleCreateFlowButtonClick,
          variant: 'contained',
          color: 'primary',
          disabled: isInviteButtonDisabled,
        });
        setSecondaryButtonProps({
          text: quickSetupButtonTexts.addParticipantsScreenButtons
            .secondaryButtonText,
          onClick: handleInviteLaterButtonClick,
          variant: 'text',
          color: 'secondary',
        });
        setTertiaryButtonProps({
          text: quickSetupButtonTexts.goBackButton,
          onClick: () => {
            trackOnboardingActionEvent({
              action: ACCOUNT_ONBOARDING_EVENTS.GO_BACK,
              path: flow,
              templateId: templateId,
              meetingTitle: meetingName,
              meetingRecurs: meetingReccurence
                ? !meetingReccurence.includes('COUNT=1')
                : undefined,
            });
            onQuickSetupStepChange(QuickSetupStep.SELECT_TEMPLATE);
            onTemplateChange(undefined);
            mutateQuickSetupStatus({
              direction: QuickSetupDirectionEnum.BACKWARD,
            });
          },
          variant: 'text',
          color: 'secondary',
          icon: 'arrow-left',
        });
        setAlignButtons('left');
        break;

      default:
        setPrimaryButtonProps({
          text: quickSetupButtonTexts.exploreOnOwnButton,
          onClick: () => {
            onQuickSetupStepChange(QuickSetupStep.COMPLETION);
            history.push(`${QUICK_SETUP}?step=COMPLETION`);
            mutateQuickSetupStatus({
              direction: QuickSetupDirectionEnum.FORWARD,
              status: QuickSetupStatusEnum.SKIPPED,
            });
          },
          variant: 'text',
          color: 'secondary',
        });
        setSecondaryButtonProps(undefined);
        setTertiaryButtonProps(undefined);
        setAlignButtons('right');
    }
    // Removed history from the deps because it is causing excessive re-renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    meetingName,
    currentStep,
    meetingParticipants,
    leaveSetupButtonProps,
    handleInviteLaterButtonClick,
    uploadInviteUserAction,
    handleCreateFlowButtonClick,
    onQuickSetupStepChange,
    onTemplateChange,
    isInviteButtonDisabled,
    mutateQuickSetupStatus,
    flow,
    meetingId,
    calenderId,
    templateId,
    meetingReccurence,
  ]);

  return {
    models: {
      isModalOpen,
      alignButtons,
      modalPrimaryButtonProps,
      modalSecondaryButtonProps,
      primaryButton: primaryButtonProps,
      tertiaryButton: tertiaryButtonProps,
      secondaryButton: secondaryButtonProps,
    },
    operations: {
      handleModalClose,
    },
  };
};

export default useFooterController;
