import React, { useState, useMemo, useCallback, useEffect } from 'react';

import {
  CULTURE_REWARDS_SEARCH_PLACEHOLDER,
  CULTURE_REWARDS_FILTER_PLACEHOLDER,
  REWARDS_SORT_PLACEHOLDER,
} from '../../../languages/en/rewards';
import ThemeV2 from '../../../componentsV2/theme';
import {
  rewardTypeMenuItems,
  sortByMenuItems,
} from '../../../Utils/data/rewards';
import { filterItems } from '../../../Utils/rewards';
import useStringSearch from '../../../hooks/useSearchString';
import {
  TextFieldType,
  TextFieldProps,
} from '../../../atomic/molecules/TextField_V2/interface';
import { CultureReward } from '../../../queries/Rewards/interfaces';

interface CultureRewardsComponentProps {
  rewards: CultureReward[];
  onSortChange: (value: unknown) => void;
  resetCurrentPage: () => void;
}

const useCultureControllerLogic = (props: CultureRewardsComponentProps) => {
  const { rewards, onSortChange, resetCurrentPage } = props;
  const [searchValue, setSearchValue] = useState('');
  const [filterValue, setFilterValue] = useState('');
  const [sortValue, setSortValue] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedReward, setSelectedReward] = useState<
    CultureReward | undefined
  >();

  const activeRewards = rewards.filter((reward) => reward.isActive);

  let rewardsToDisplay = [...activeRewards];

  const { models: searchModels, operations: searchOperations } =
    useStringSearch({ data: rewardsToDisplay, keys: ['name'] });

  const { searchResult } = searchModels;

  useEffect(() => {
    searchOperations.search(searchValue);
  }, [searchValue, searchOperations]);

  const onSearchChangeHandler = useCallback(
    (value: React.SetStateAction<string>) => {
      resetCurrentPage();
      setSearchValue(value);
    },
    [resetCurrentPage],
  );

  const onFilterChangeHandler = useCallback(
    (value: React.SetStateAction<string>) => {
      resetCurrentPage();
      setFilterValue(value);
    },
    [resetCurrentPage],
  );

  const onSortChangeHandler = useCallback(
    (value: React.SetStateAction<string>, id?: string | number) => {
      if (value !== sortValue) {
        resetCurrentPage();
        setSortValue(value);
        onSortChange(id);
      }
    },
    [onSortChange, resetCurrentPage, sortValue],
  );

  const searchProps: TextFieldProps = useMemo(() => {
    return {
      placeholder: CULTURE_REWARDS_SEARCH_PLACEHOLDER,
      type: TextFieldType.Text,
      value: searchValue,
      onChange: onSearchChangeHandler,
      icon: 'search',
    };
  }, [onSearchChangeHandler, searchValue]);

  const filterProps: TextFieldProps = useMemo(() => {
    return {
      placeholder: CULTURE_REWARDS_FILTER_PLACEHOLDER,
      type: TextFieldType.Text,
      icon: 'filter',
      value: filterValue,
      onChange: onFilterChangeHandler,
      dropdownItems: rewardTypeMenuItems,
      isEditable: false,
      inputIconColor: ThemeV2.palette.gray9,
    };
  }, [filterValue, onFilterChangeHandler]);

  const sortProps: TextFieldProps = useMemo(() => {
    return {
      placeholder: REWARDS_SORT_PLACEHOLDER,
      type: TextFieldType.Text,
      icon: 'sort',
      value: sortValue,
      onChange: onSortChangeHandler,
      dropdownItems: sortByMenuItems,
      isEditable: false,
      inputIconColor: ThemeV2.palette.gray9,
    };
  }, [sortValue, onSortChangeHandler]);

  // search logic
  rewardsToDisplay = searchResult;

  const handleOnRewardSelect = useCallback(
    (id) => {
      const currentReward = rewards.find((reward) => reward._id === id);
      if (currentReward) {
        setIsModalOpen(true);
        setSelectedReward(currentReward);
      }
    },
    [rewards],
  );

  const onCloseRedeemModal = useCallback(() => {
    setIsModalOpen(false);
    setSelectedReward(undefined);
  }, []);

  if (filterValue) {
    let filterId = filterValue;
    if (filterValue === 'Custom') {
      filterId = 'other';
    }
    rewardsToDisplay = filterItems({
      arr: rewardsToDisplay,
      key: 'type',
      value: filterId,
    });
  }

  return {
    searchProps,
    filterProps,
    sortProps,
    rewardsToDisplay,
    isModalOpen,
    setIsModalOpen,
    selectedReward,
    handleOnRewardSelect,
    onCloseRedeemModal,
  };
};

export default useCultureControllerLogic;
