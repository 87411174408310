import { EmojiData } from 'emoji-mart';
import styled from 'styled-components';
import React, { useMemo } from 'react';

import FlowFeedPost from '../../../atomic/organism/FlowFeedPost';
import {
  FeedItemKind,
  FeedProps,
  MemberInteractionType,
  Reaction,
} from '../../../interfaces/Feed';
import { GetProfileInfoResponse } from '../../../queries/Profile';
import useFlowPostController from './useFlowPostController';
import FlowsPostHeaderOption from '../../../atomic/organism/FlowsPostHeaderOption';
import {
  menuItemsWithCopyLinkAndDeletePost,
  menuItemsWithCopyLink,
} from '../../../atomic/organism/FlowFeedPost/data';

type FlowPostControllerProps = {
  post: FeedProps;
  onReactionSet: (emoji: EmojiData, contentId: string) => void;
  onReactionUnSet: (reaction: Reaction, contentId: string) => void;
  onMemberClick: (person: MemberInteractionType) => void;
  profileData?: GetProfileInfoResponse;
  onPostDelete?: () => void;
};

const DropdownContainer = styled.div`
  margin-left: auto;
`;

const FlowPostController = (props: FlowPostControllerProps) => {
  const { post } = props;

  const { operations, commentInputProps, postCommentsProps, fileToPreview } =
    useFlowPostController(props);

  const {
    handleDeletePostOptionChange,
    handleDeletePost,
    onCopyLinkClick,
    onFlowClick,
    handleMemberClick,
    onSeeFullPostClick,
    onDownloadFileClick,
    handleReactionSet,
    handleReactionUnSet,
    handleSetFilePreview,
  } = operations;

  const dropdownComponent = useMemo(
    () => (
      <DropdownContainer>
        <FlowsPostHeaderOption
          dropdownOptions={
            post.headerProps?.showDeleteOption
              ? menuItemsWithCopyLinkAndDeletePost
              : menuItemsWithCopyLink
          }
          icon="more"
          title="flows post options"
          onCopyLink={onCopyLinkClick}
          hasTrophies={post.headerProps.hasTrophies}
          onDeletePostSelectedOption={handleDeletePostOptionChange}
          onDeleteModalSubmitClick={handleDeletePost}
        />
      </DropdownContainer>
    ),
    [
      handleDeletePostOptionChange,
      handleDeletePost,
      onCopyLinkClick,
      post.headerProps,
    ],
  );

  return (
    <FlowFeedPost
      key={post.key}
      onReactionSet={handleReactionSet}
      onReactionUnSet={handleReactionUnSet}
      onMemberClick={handleMemberClick}
      bodyProps={{
        blocks: post.bodyProps.blocks,
        fileToPreview,
        handleSetFilePreview,
        onSeeFullPostClick,
        onDownloadFileClick,
      }}
      isAnonymous={
        post?.kind === FeedItemKind.EXTERNAL_ANONYMOUS ||
        post?.kind === FeedItemKind.INTERNAL_ANONYMOUS
      }
      reactionBarProps={post.reactionBarProps}
      headerProps={post.headerProps}
      commentInputProps={commentInputProps}
      commentsProps={postCommentsProps}
      onUserNameClick={operations.onUserNameClick}
      onFlowClick={onFlowClick}
      onCommentTextFocus={operations.onCommentTextFocus}
      onCommentExited={operations.onCommentExited}
      onCommentPosted={operations.onCommentPosted}
      dropdownComponent={dropdownComponent}
    />
  );
};

export default FlowPostController;
