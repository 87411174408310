import { useState, useCallback, useEffect, useMemo } from 'react';
import CookieHandler from 'react-cookies';
import { useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { parse } from 'qs';

import {
  getIdentityManagerDetails,
  MERGE,
} from '../../Utils/identity/allowedSSOs';
import {
  IMConfig,
  IdentityManagerResult,
  IdentityManagerIntegrationData,
  UseIdentityManagerProps,
} from '../../interfaces/identityManager/common';
import accountManagementTypes from '../../Utils/identity/accountManagementTypes';
import IMStatus from '../../Utils/identity/status';
import { showSuccessMessage, showErrorMessage } from '../../Utils/flashHandler';
import routesList from '../../aV2/routes/routesList';
import {
  DISCONNECT_IDENTITY_MANAGER,
  GET_SSO_OAUTH_CONFIG,
} from '../useFetch/endpoints';
import {
  GET_INTEGRATION_DATA,
  GET_PAGINATED_INTEGRATION_DATA_MAIN,
} from '../../constants/endpoints';
import useFetch from '../useFetch';
import { successMessages } from '../../Utils/data/identityManager/ssoADP';
import useNotification, { NotificationType } from '../useNotification';
import { identityManagerSteps } from '../../Utils/data/identityManager/common';
import {
  getIdentityManagerErrorData,
  IdentityManagerErrorCodes,
} from '../../Utils/data/identityManager/error';
import { COOKIE_CONSTANTS } from '../../Utils/constants';
import { APP_URL } from '../../config';
import useJobPolling from '../useJobPolling/useJobPolling';
import { useFeatureSplit } from '../useFeatureSplit';
import { SplitNames, TreatmentTypes } from '../useSplitSdkConfig/constants';

const useIdentityManager = ({
  identityManager,
  IMsMetadata,
  canGetIMConfigOnLoad = true,
}: UseIdentityManagerProps) => {
  const { id, status } = identityManager;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  /* @ts-ignore */
  const { ssoParam, displayName, helperLink } = getIdentityManagerDetails(
    id,
    identityManager.metaData,
  );
  const REDIRECT_URI = useMemo(
    () => `${APP_URL}${routesList.IM_AUTHORIZE_SSO(ssoParam)}`,
    [ssoParam],
  );
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { search } = useLocation();
  const parsedParams = parse(search, {
    ignoreQueryPrefix: true,
  });

  const [activeStep, setActiveStep] = useState<string>(
    identityManagerSteps.WHAT_IS_THIS,
  );
  const [imConfig, setIMConfig] = useState<IMConfig | null>(null);
  const [result, setResult] = useState<IdentityManagerResult>({
    accountManagementType: IMsMetadata
      ? IMsMetadata.accountManagementType
      : accountManagementTypes.ALL_USERS,
    applicationCacheId: '',
    accountManagementList: [],
    accountManagementDeselectionList: [],
    autoSendInvites: true,
  });
  const [showCancelModal, setCancelModal] = useState<boolean>(false);
  const [configureJobId, setConfigureJobId] = useState<string | null>(null);

  // TODO: remove all notifications on component will unMount.
  const { notifications, addNotification } = useNotification({});

  const { treatment: paginatedIntegrationDataTreatment } = useFeatureSplit(
    SplitNames.PAGINATED_INTEGRATION_DATA,
  );

  const { treatment: combinedUsersManageInviteTreatment } = useFeatureSplit(
    SplitNames.COMBINED_USERS_MANAGE_INVITE,
  );

  const { treatment: adminRefactorTreatment } = useFeatureSplit(
    SplitNames.ADMIN_NEW_APP,
  );

  const isAdminTreatmentOn = adminRefactorTreatment === TreatmentTypes.ON;

  const isPaginatedIntegrationDataEnabled =
    paginatedIntegrationDataTreatment === TreatmentTypes.ON;

  const isCombinedUsersManageInviteOn =
    combinedUsersManageInviteTreatment !== TreatmentTypes.OFF;

  // Handling Error from query param
  useEffect(() => {
    if (parsedParams.error) {
      const message = getIdentityManagerErrorData(
        parsedParams.error as IdentityManagerErrorCodes,
        displayName,
      );
      addNotification({ message, type: NotificationType.ERROR });
    }
  }, [addNotification, displayName, parsedParams.error]);

  const onDisconnectIMSuccess = () => {
    if (
      status !== IMStatus.APP_INSTALLED &&
      status !== IMStatus.NOT_AUTHORIZED
    ) {
      dispatch(showSuccessMessage(`${displayName} disconnected successfully!`));
      if (isAdminTreatmentOn) {
        window.location.href = `${routesList.ADMIN_USER_MANAGE}`;
      } else {
        push(routesList.ADMIN_USER_MANAGE_LEGACY);
      }
    }
  };

  // Handle Error
  const handleError = useCallback(
    ({ message, error }) => {
      // message is passed by backend
      // error is passed by Google SSO Client Library
      const errorMessage = getIdentityManagerErrorData(
        message || error,
        displayName,
      );
      addNotification({
        message: errorMessage,
        type: NotificationType.ERROR,
        autoClose: false,
      });
      if (activeStep === identityManagerSteps.WHAT_IS_THIS) {
        return;
      }
      if (
        activeStep === identityManagerSteps.CREATE_ACCOUNTS ||
        activeStep === identityManagerSteps.CONFIGURE_INVITES
      ) {
        setActiveStep(identityManagerSteps.CONFIGURE_INVITES);
      } else {
        dispatch(showErrorMessage(errorMessage));
        if (isAdminTreatmentOn) {
          window.location.href = `${routesList.ADMIN_USER_MANAGE}`;
        } else {
          push(routesList.ADMIN_USER_MANAGE_LEGACY);
        }
      }
    },
    [
      displayName,
      addNotification,
      activeStep,
      dispatch,
      isAdminTreatmentOn,
      push,
    ],
  );

  const { makeRequest: getIMConfig } = useFetch({
    apiEndpoint: GET_SSO_OAUTH_CONFIG,
    onSuccess: setIMConfig,
    onFailure: handleError,
  });

  // Setting Active Step on Load based on IM Status
  useEffect(() => {
    if (status === IMStatus.NOT_AUTHORIZED) {
      setActiveStep(identityManagerSteps.WHAT_IS_THIS);
    } else if (
      status === IMStatus.AUTHORIZED ||
      status === IMStatus.CONNECTED ||
      status === IMStatus.CONFIGURATION_COMPLETE
    ) {
      setActiveStep(identityManagerSteps.SELECT_PEOPLE);
    } else if (status === IMStatus.APP_INSTALLED) {
      setActiveStep(identityManagerSteps.AUTHORIZE_ADP);
    }
  }, [status, setActiveStep]);

  useEffect(() => {
    if (!imConfig && canGetIMConfigOnLoad) {
      getIMConfig({
        params: { redirectUri: REDIRECT_URI },
        substitutions: { sso: ssoParam },
      });
    }
  }, [imConfig, canGetIMConfigOnLoad, getIMConfig, ssoParam, REDIRECT_URI]);

  const SSOApiEndpointDict: { [Key: string]: string } = {
    SLACK: DISCONNECT_IDENTITY_MANAGER,
    OFFICE365: DISCONNECT_IDENTITY_MANAGER,
    GSUITE: DISCONNECT_IDENTITY_MANAGER,
    ADP: DISCONNECT_IDENTITY_MANAGER,
  };
  const disconnectApiToUse =
    SSOApiEndpointDict[id] || DISCONNECT_IDENTITY_MANAGER;
  const { makeRequest: disconnectIdentityManager } = useFetch({
    apiEndpoint: disconnectApiToUse,
    onSuccess: onDisconnectIMSuccess,
    onFailure: handleError,
  });

  const handleDisconnectClick = () => {
    setCancelModal(true);
  };
  const handleViewPeopleClick = () => {
    if (isAdminTreatmentOn) {
      window.location.href = `${routesList.ADMIN_USER_MANAGE}`;
    } else {
      push(routesList.ADMIN_USER_MANAGE_LEGACY);
    }
  };
  const handleViewQueuedPeopleClick = () => {
    if (isAdminTreatmentOn) {
      const queuedMembersUrl = isCombinedUsersManageInviteOn
        ? routesList.ADMIN_USER_MANAGE_QUEUED
        : routesList.ADMIN_USER_INVITE;
      window.location.href = queuedMembersUrl;
    } else {
      const queuedMembersUrl = isCombinedUsersManageInviteOn
        ? routesList.ADMIN_USER_MANAGE_QUEUED_LEGACY
        : routesList.ADMIN_USER_INVITE_LEGACY;
      push(queuedMembersUrl);
    }
  };

  const handleSuccess = useCallback(() => {
    dispatch(showSuccessMessage(successMessages.CREATING_ASSEMBLY));
  }, [dispatch]);

  const handleCloseCancelModal = () => {
    setCancelModal(false);
  };

  const handleConfirmCancelModal = () => {
    disconnectIdentityManager({ substitutions: { sso: ssoParam } });
  };
  const handleAuthorizeClick = useCallback(() => {
    CookieHandler.save(
      COOKIE_CONSTANTS.FROM_IDENTITY_MANAGER_AUTHORIZE,
      'true',
      { path: '/' },
    );
    push(`/${routesList.IM_AUTHORIZE_SSO(ssoParam)}?auto=true`);
  }, [push, ssoParam]);

  const verifySSOCode = useCallback(
    (code: string) => {
      CookieHandler.save(
        COOKIE_CONSTANTS.FROM_IDENTITY_MANAGER_AUTHORIZE,
        'true',
        { path: '/' },
      );
      push(`/${routesList.IM_AUTHORIZE_SSO(ssoParam)}?code=${code}`);
    },
    [push, ssoParam],
  );

  const handleConfigInvitesBackClick = () => {
    setActiveStep(identityManagerSteps.SELECT_PEOPLE);
  };

  const handleConfigureAccountsSuccess = useCallback(
    ({ statusJobId, autoSendInvites, autoApproveMembers }) => {
      if (
        result.accountManagementType !== accountManagementTypes.SELECTED_USERS
      ) {
        setResult({ ...result, autoSendInvites, autoApproveMembers });
      } else {
        setResult({ ...result, autoSendInvites });
      }
      setConfigureJobId(statusJobId);
      setActiveStep(identityManagerSteps.CREATE_ACCOUNTS);
    },
    [setResult, result, setActiveStep, setConfigureJobId],
  );

  const handleCancelClick = () => {
    if (
      status === IMStatus.NOT_AUTHORIZED ||
      status === IMStatus.APP_INSTALLED ||
      status === IMStatus.CONNECTED
    ) {
      if (isAdminTreatmentOn) {
        window.location.href = `${routesList.ADMIN_USER_MANAGE}`;
      } else {
        push(routesList.ADMIN_USER_MANAGE_LEGACY);
      }
    } else {
      setCancelModal(true);
    }
  };
  const handleSelectPeopleDone = useCallback(
    ({
      accountManagementType: selectedAccountManagementType,
      accountManagementList,
      accountManagementDeselectionList,
      applicationCacheId,
      autoSendInvites,
      autoApproveMembers,
    }) => {
      const newResult = {
        ...result,
        autoSendInvites,
        autoApproveMembers,
        applicationCacheId,
        accountManagementType: selectedAccountManagementType,
        accountManagementList,
        accountManagementDeselectionList,
      };
      setResult(newResult);
      setActiveStep(identityManagerSteps.CONFIGURE_INVITES);
    },
    [result],
  );

  const [integrationData, setIntegrationData] =
    useState<IdentityManagerIntegrationData | null>(null);

  const handleProgressComplete = useCallback(
    (data: IdentityManagerIntegrationData) => {
      // setIntegrationData(data);
      const {
        applicationCacheId,
        accountManagementType: selectedAccountManagementType,
        autoApproveMembers,
        autoSendInvites,
      } = data;
      const newResult = {
        ...result,
        accountManagementType: selectedAccountManagementType,
        applicationCacheId,
        autoApproveMembers,
      };
      if (autoSendInvites !== undefined) {
        newResult.autoSendInvites = autoSendInvites;
      }
      setResult(newResult);
    },
    [result, setResult],
  );
  const showApprovalRadioGroup =
    result.accountManagementType !== accountManagementTypes.SELECTED_USERS;

  // Polling Integration Data
  const targetEndpointOptions = useMemo(
    () => ({
      substitutions: { sso: ssoParam },
    }),
    [ssoParam],
  );

  const hasPaginatedIntegrationData =
    id === MERGE && isPaginatedIntegrationDataEnabled;

  const {
    error: pollingError,
    responseData: pollingResponseData,
    startPollingRequests,
  } = useJobPolling(
    id === MERGE && isPaginatedIntegrationDataEnabled
      ? GET_PAGINATED_INTEGRATION_DATA_MAIN
      : GET_INTEGRATION_DATA,
    false,
    targetEndpointOptions,
    hasPaginatedIntegrationData,
  );
  useEffect(() => {
    if (
      status === IMStatus.AUTHORIZED ||
      status === IMStatus.CONNECTED ||
      status === IMStatus.CONFIGURATION_COMPLETE
    ) {
      startPollingRequests();
    }
  }, [startPollingRequests, status]);

  useEffect(() => {
    if (pollingResponseData) {
      const data =
        pollingResponseData as unknown as IdentityManagerIntegrationData;
      setIntegrationData(data);
    }
  }, [pollingResponseData]);
  useEffect(() => {
    if (pollingError) {
      handleError(pollingError);
    }
  }, [pollingError, handleError]);

  return {
    models: {
      status,
      ssoParam,
      displayName,
      activeStep,
      imConfig,
      result,
      cancelModal: {
        isOpen: showCancelModal,
        handleClose: handleCloseCancelModal,
        handleConfirm: handleConfirmCancelModal,
      },
      notifications,
      showApprovalRadioGroup,
      integrationData,
      configureJobId,
      helperLink,
    },
    operations: {
      setActiveStep,
      handleDisconnectClick,
      handleCancelClick,
      handleViewPeopleClick,
      handleViewQueuedPeopleClick,
      handleSuccess,
      handleError,
      handleAuthorizeClick,
      handleConfigInvitesBackClick,
      handleConfigureAccountsSuccess,
      handleSelectPeopleDone,
      handleProgressComplete,
      verifySSOCode,
      startPollingIntegrationData: startPollingRequests,
    },
  };
};

export default useIdentityManager;
