import { useCallback, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import useToggle from '../../../../hooks/useToggle';
import useLayoutStore from '../../../../stores/layoutStore';
import { useProfileInfoFetchQuery } from '../../../../queries/Profile';
import { useGetProfileFeedQuery } from '../../../../queries/ProfileFeed';
import { useFetchMembersDetailsQuery } from '../../../../queries/MembersDetails';

import {
  FeedProps,
  FeedItemFromAPI,
  MemberInteractionType,
} from '../../../../interfaces/Feed';

import {
  getFlowFeedUrl,
  mapFeedResponseToFeedProps,
} from '../../../../Utils/home/feeds';

import { getFeedArrayFromQueryData } from '../../../../Utils/home/feeds/reactions';

import {
  useProfileFeedUpdatePostReactionMutation,
  useProfileFeedUpdateFlowPostReactionMutation,
} from '../../../../queries/Feed';
import { useFeedReactions } from '../../../../hooks/useFeedReactions';
import useGetParsedFilterOptions from '../../../../hooks/useGetParsedFilterOptions';
import { transformMemberToAutoCompleteDropdownItem } from '../utils';
import { LayoutStoreState } from '../../../../interfaces/Layout';
import { useFeatureSplit } from '../../../../hooks/useFeatureSplit';
import {
  SplitNames,
  TreatmentTypes,
} from '../../../../hooks/useSplitSdkConfig/constants';
import { RECOGNITION_PARTICIPATE } from '../../../../constants/routes';

const layoutSelector = (state: LayoutStoreState) =>
  state.setMemberAndToggleRightAsideOpen;

const useProfileFeedController = () => {
  const history = useHistory();
  const { userId } = useParams<{ userId: string }>();

  const { filter } = useGetParsedFilterOptions();
  const {
    data,
    refetch,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useGetProfileFeedQuery({ userId, filter });

  const setMemberAndToggleRightAsideOpen = useLayoutStore(layoutSelector);

  const { data: currentUserData } = useProfileInfoFetchQuery();
  const { data: profileUserData } = useFetchMembersDetailsQuery([userId]);

  const { mutate: mutateFlowPostReaction } =
    useProfileFeedUpdateFlowPostReactionMutation({ userId, filter });

  const { mutate: mutatePostReaction } =
    useProfileFeedUpdatePostReactionMutation({ userId, filter });

  const {
    models: { toggleValue: isCelebrateTeammateModalOpen },
    operations: { setToggleValue: toggleIsCelebrateTeammateModalOpen },
  } = useToggle();

  const { treatment: pageParticipationTreatment } = useFeatureSplit(
    SplitNames.NEW_PAGE_PARTICIPATION_VIEW,
  );

  const handleCelebrateTeammateClick = useCallback(() => {
    if (pageParticipationTreatment === TreatmentTypes.ON) {
      history.push(
        `${RECOGNITION_PARTICIPATE}?redirectUrl=${location.pathname}&userId=${userId}`,
      );
    } else {
      toggleIsCelebrateTeammateModalOpen();
    }
  }, [history, pageParticipationTreatment, toggleIsCelebrateTeammateModalOpen]);

  const feedData: FeedItemFromAPI[] = useMemo(() => {
    return getFeedArrayFromQueryData(data);
  }, [data]);

  const { onReactionSet, onReactionUnSet } = useFeedReactions({
    profileData: currentUserData,
    feedData,
    mutateFlowPostReaction,
    mutatePostReaction,
  });

  const handleOnMemberClick = useCallback(
    (member: MemberInteractionType) => {
      setMemberAndToggleRightAsideOpen({
        memberId: member.memberID,
        firstName: member.firstName,
      });
    },
    [setMemberAndToggleRightAsideOpen],
  );

  const handleOnFlowClick = useCallback(
    (flow: { flowId: string }) => {
      history.push(getFlowFeedUrl(flow.flowId));
    },
    [history],
  );

  const feedProps: FeedProps[] = useMemo(() => {
    if (!data || !currentUserData) {
      return [];
    }

    return mapFeedResponseToFeedProps(
      data,
      currentUserData,
      handleOnMemberClick,
      handleOnFlowClick,
    );
  }, [data, currentUserData, handleOnMemberClick, handleOnFlowClick]);

  const isOwnProfileFeed: boolean = useMemo(() => {
    return currentUserData?.member.memberId === userId;
  }, [currentUserData, userId]);

  const profileUser = useMemo(() => {
    if (profileUserData && profileUserData.membersDetails?.length > 0) {
      return profileUserData.membersDetails[0];
    }
    return null;
  }, [profileUserData]);

  const onMemberClick = useCallback(
    (person: MemberInteractionType) => {
      setMemberAndToggleRightAsideOpen({
        memberId: person.memberID,
        firstName: person.firstName,
      });
    },
    [setMemberAndToggleRightAsideOpen],
  );
  const profileDataForRecognitionModalDropdown = useMemo(() => {
    if (isOwnProfileFeed || !profileUserData) {
      return undefined;
    }

    const memberDetails = profileUserData.membersDetails[0];
    return transformMemberToAutoCompleteDropdownItem(memberDetails);
  }, [isOwnProfileFeed, profileUserData]);

  return {
    models: {
      isLoading,
      hasNextPage,
      profileUser,
      data: feedProps,
      isOwnProfileFeed,
      isFetchingNextPage,
      currentUser: currentUserData,
      isCelebrateTeammateModalOpen,
      profileDataForRecognitionModalDropdown,
    },
    operations: {
      onMemberClick,
      onReactionSet,
      fetchNextPage,
      onReactionUnSet,
      refetchProfileFeed: refetch,
      handleCelebrateTeammateClick,
      toggleIsCelebrateTeammateModalOpen,
    },
  };
};

export default useProfileFeedController;
