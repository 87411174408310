import React from 'react';

import { AutocompleteDropdownItem } from '../../../atomic/organism/Autocomplete/interfaces';
import {
  canGiveCustomAmountOfPoints,
  getAssemblyCurrency,
  getCanUserPostPrivately,
} from '../../../queries/Profile/utils';
import Body from '../../../atomic/atoms/Body';
import FlowsPointsAdjustmentWarning from '../../../atomic/molecules/FlowsPointsAdjustmentWarning';
import { GetProfileInfoResponse } from '../../../queries/Profile';
import FlowsTrophiesInputBlock from '../../../atomic/molecules/FlowInputBlocks/FlowsTrophiesInputBlock';
import { LoadedParticipationTemplate as ParticipationTemplate } from '../../../atomic/pages/ParticipationTemplate';
import FlowMultiselectInputBlock from '../../../atomic/molecules/FlowInputBlocks/FlowMultiselectInputBlock';
import FlowsDropdownInputBlock from '../../../atomic/molecules/FlowInputBlocks/FlowsDropdownInputBlock';
import FlowsOpenEndedInputBlock from '../../../atomic/molecules/FlowInputBlocks/FlowsOpenEndedInputBlock';
import {
  ARE_GIVING_RECOGNITION,
  RECOGNITION_FLOW_HEADER_DESCRIPTION,
  VALIDATION_ERRORS,
} from '../../../languages/en/flows/participation';
import useRecognitionFlowController from './useRecognitionFlowController';
import { STEP_IDS } from './data';
import {
  FIRST_SLIDE_LABEL,
  FIRST_SLIDE_TITLE,
  generateTrophiesBlockHeading,
  SECOND_SLIDE_DESCRIPTION,
  SECOND_SLIDE_TITLE,
  THIRD_SLIDE_LABEL,
  THIRD_SLIDE_TITLE,
} from '../../../languages/en/recognitionFlow';
import FlowUserReceivingPointsInfo from '../../../atomic/molecules/FlowUsersReceivingPointsInfo';
import { FlowVariants } from '../../../interfaces/Flow';
import { isBlockContentValid } from '../../../hooks/useParticipationFlow/utils';
import { POST, YOU } from '../../../languages/en/singleWords';
import { ParticipationFlowHeaderContent } from '../../../atomic/molecules/FlowsParticipationHeader';
import { ComponentStatus } from '../../../interfaces/component';
import SplitButton from '../../../atomic/molecules/SplitButton';
import Button from '../../../atomic/atoms/Button';
import { useFeatureSplit } from '../../../hooks/useFeatureSplit';
import {
  SplitNames,
  TreatmentTypes,
} from '../../../hooks/useSplitSdkConfig/constants';

interface RecognitionFlowControllerProps {
  profileInfo: GetProfileInfoResponse;
  selectedTeammateDropdownItem?: AutocompleteDropdownItem<string>;
  toggleIsRecognitionFlowModalOpen: () => void;
  onPostSuccess?: () => void;
}

const recognitionFlowHeaderContent: ParticipationFlowHeaderContent = {
  TitleContent: (
    <>
      <Body color="geekblue6" inline variant="body2Medium">
        {`${YOU} `}
      </Body>
      {`${ARE_GIVING_RECOGNITION} `}
    </>
  ),
  DescriptionContent: RECOGNITION_FLOW_HEADER_DESCRIPTION,
};

const RecognitionFlowController = ({
  profileInfo,
  selectedTeammateDropdownItem,
  toggleIsRecognitionFlowModalOpen,
  onPostSuccess,
}: RecognitionFlowControllerProps) => {
  const {
    models: {
      blockErrors,
      currentStep,
      updatedSlideData,
      values,
      memberOptions,
      isMembersFetching,
      textboxValue,
      totalMembers,
      havePointsBeenAdjusted,
      assembly,
      fieldErrors,
      coreValueOptions,
      containerRef,
      errors,
      hasVisitedLastStep,
      isPosting,
      isPrivatePost,
      member,
      pointsLeftThisCycle,
      dynamicBlockData,
      isSingleMemberAssembly,
    },
    operations: {
      onStepChange,
      setFieldValue,
      onTextboxValueChange,
      handleAdjustmentWarningCTAClick,
      handleModalClose,
      handleTrophiesValueChange,
      onFormCompleteClick,
      setIsPrivatePost,
      goToNextStep,
      goToPreviousStep,
      onPeopleOptionsScroll,
    },
  } = useRecognitionFlowController(
    profileInfo,
    toggleIsRecognitionFlowModalOpen,
    selectedTeammateDropdownItem,
    onPostSuccess,
  );

  const { treatment: pageParticipationTreatment } = useFeatureSplit(
    SplitNames.NEW_PAGE_PARTICIPATION_VIEW,
  );

  const ispageParticipationTreatmentOn =
    pageParticipationTreatment === TreatmentTypes.ON;

  const renderSlide = () => {
    const { id: currentStepId, type } = updatedSlideData[currentStep];
    if (isSingleMemberAssembly && blockErrors[currentStepId]) {
      blockErrors[currentStepId] = VALIDATION_ERRORS.INVITE_AT_LEAST_ONE_MEMBER;
    }

    switch (type) {
      case 'MULTI_PERSON_SELECTOR_DROPDOWN': {
        return (
          <FlowMultiselectInputBlock
            blockError={blockErrors[currentStepId]}
            goToNextStep={goToNextStep}
            title={FIRST_SLIDE_TITLE}
            inputLabel={FIRST_SLIDE_LABEL}
            isRequired
            value={values[currentStepId]}
            onChange={(val) => {
              setFieldValue(currentStepId, val);
              onTextboxValueChange('');
            }}
            onBlur={() => {
              onTextboxValueChange('');
            }}
            options={memberOptions}
            loading={isMembersFetching}
            textboxValue={textboxValue}
            onTextboxValueChange={onTextboxValueChange}
            inputBaseRightPadding="48px"
            hasNoOptions={totalMembers === 1}
            onOptionsScroll={onPeopleOptionsScroll}
            warning={
              havePointsBeenAdjusted && (
                <FlowsPointsAdjustmentWarning
                  adjustedTo={values[STEP_IDS.TROPHIES]}
                  assemblyCurrency={assembly.currency}
                  onCallToActionClick={handleAdjustmentWarningCTAClick}
                />
              )
            }
          />
        );
      }
      case 'OPEN_ENDED': {
        return (
          <FlowsOpenEndedInputBlock
            blockError={blockErrors[currentStepId]}
            blockValue={values[STEP_IDS.WHAT_DID_THEY_DO]}
            description={SECOND_SLIDE_DESCRIPTION}
            fieldError={fieldErrors[currentStepId] as string}
            gifRatings={assembly.settings.gifAccessibility.value}
            goToNextStep={goToNextStep}
            hasError={
              !!blockErrors[currentStepId] || !!fieldErrors[currentStepId]
            }
            isRequired
            onBlockChange={(newBlockValue) =>
              setFieldValue(STEP_IDS.WHAT_DID_THEY_DO, newBlockValue)
            }
            title={SECOND_SLIDE_TITLE}
            hideAttachment
            flowVariant={FlowVariants.RECOGNITION_FLOW}
          />
        );
      }
      case 'SINGLE_SELECT_DROPDOWN': {
        return (
          <FlowsDropdownInputBlock
            goToNextStep={goToNextStep}
            title={THIRD_SLIDE_TITLE}
            inputLabel={THIRD_SLIDE_LABEL}
            value={values[currentStepId]}
            onChange={(val) => setFieldValue(currentStepId, val)}
            options={coreValueOptions}
            isRequired={false}
          />
        );
      }
      case 'TROPHIES': {
        const assemblyCurrency = getAssemblyCurrency(profileInfo);
        const pluralCurrencyName = assemblyCurrency.pluralName;
        const canGiveUpToRemainingAllowance =
          canGiveCustomAmountOfPoints(profileInfo);
        const splitBy = values[STEP_IDS.SELECT_TEAMMATE].length || 1;
        const maximumGivingPercentage =
          profileInfo.assembly.settings.postImpactLevel.value.levels[4]
            .percentage;
        const maxPoints = canGiveUpToRemainingAllowance
          ? Math.floor(pointsLeftThisCycle / splitBy)
          : Math.min(
              Math.floor(pointsLeftThisCycle / splitBy),
              Math.floor(
                (member.allowance.points * maximumGivingPercentage) / 100,
              ),
            );
        return (
          <FlowsTrophiesInputBlock
            assemblyCurrency={assembly.currency}
            fieldError={errors[currentStepId] as string}
            monthlyAllowance={member.allowance.points}
            remainingAllowance={pointsLeftThisCycle}
            splitBy={splitBy}
            title={generateTrophiesBlockHeading(pluralCurrencyName)}
            onChange={handleTrophiesValueChange}
            maxPoints={maxPoints}
            value={values[currentStepId]}
            info={
              values[STEP_IDS.SELECT_TEAMMATE]?.length ? (
                <FlowUserReceivingPointsInfo
                  onEditPeopleClick={() => onStepChange(0)}
                  users={values[STEP_IDS.SELECT_TEAMMATE]}
                />
              ) : null
            }
          />
        );
      }
      default: {
        return null;
      }
    }
  };

  const isPostDisabled = !(
    updatedSlideData.filter(
      (step) => values[step.id] && isBlockContentValid(step, values[step.id]),
    ).length > 0 && hasVisitedLastStep
  );

  const footerButtons: JSX.Element[] = [
    getCanUserPostPrivately(profileInfo) ? (
      <SplitButton
        disabled={isPostDisabled}
        isLoading={isPosting}
        isPrivatePost={isPrivatePost}
        onPrivatePostToggle={() => setIsPrivatePost(!isPrivatePost)}
        onPostClick={onFormCompleteClick}
        isAnonymousPost={false}
        key="1"
      />
    ) : (
      <Button
        disabled={isPostDisabled}
        isLoading={isPosting}
        onClick={onFormCompleteClick}
        size="large"
        key="1"
      >
        {POST}
      </Button>
    ),
  ];

  return (
    <ParticipationTemplate
      isFullScreen={ispageParticipationTreatmentOn}
      currentStep={currentStep}
      goToNextStep={goToNextStep}
      goToPreviousStep={goToPreviousStep}
      isNextButtonDisabled={currentStep === updatedSlideData.length - 1}
      isPreviousButtonDisabled={currentStep === 0}
      member={member}
      onCloseModal={handleModalClose}
      onStepChange={onStepChange}
      dynamicBlockData={dynamicBlockData}
      singleSlideContainerRef={containerRef}
      flowVariant={FlowVariants.RECOGNITION_FLOW}
      flowId=""
      flowHeaderContent={recognitionFlowHeaderContent}
      headerStatus={ComponentStatus.LOADED}
      footerButtons={footerButtons}
    >
      {renderSlide()}
    </ParticipationTemplate>
  );
};

export default RecognitionFlowController;
