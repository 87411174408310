import React from 'react';

import ToolTip from '../ToolTipV2';
import { InputGroupProps } from './type';
import { Flex } from '../../../Utils/styles/display';
import { DropdownColor } from '../Dropdown_V2/interfaces';

import {
  DropDownWrapper,
  StyledDropdown,
  StyledEmptyDiv,
  StyledIconButton,
  StyledTextField,
} from './styles';

const InputGroup = (props: InputGroupProps) => {
  const {
    id,
    label,
    margin,
    onBlur,
    hasError,
    menuItems,
    helperText,
    onItemClick,
    toolTipText,
    dropdownValue,
    textFieldValue,
    onDeleteIconClick,
    canShowToolTip,
    canShowDeleteIcon,
    isDropdownDisabled,
    isTextFieldDisabled,
    onTextFieldValueChange,
  } = props;

  return (
    <Flex margin={margin} alignItems={hasError ? 'baseline' : 'center'}>
      {canShowDeleteIcon ? (
        <StyledIconButton
          icon="delete"
          onClick={onDeleteIconClick}
          color="iconWithoutBackground"
        />
      ) : (
        <StyledEmptyDiv></StyledEmptyDiv>
      )}
      <StyledTextField
        name={id}
        label={label}
        onBlur={onBlur}
        autoComplete="off"
        hasError={hasError}
        value={textFieldValue}
        disabled={isTextFieldDisabled}
        onChange={onTextFieldValueChange}
        helperText={hasError && helperText}
      />
      {canShowToolTip ? (
        <ToolTip
          toolTipComponent={
            <DropDownWrapper>
              <StyledDropdown
                isFullWidth
                multiSelect={false}
                value={dropdownValue}
                menuItems={menuItems}
                onItemClick={onItemClick}
                disabled={isDropdownDisabled}
                color={DropdownColor.Secondary}
                isDontInviteOptionSelected={dropdownValue === "Don't Invite"}
              />
            </DropDownWrapper>
          }
          position="top"
          positionStrategy="fixed"
          toolTipSize="196px"
        >
          {toolTipText}
        </ToolTip>
      ) : (
        <StyledDropdown
          isFullWidth
          multiSelect={false}
          value={dropdownValue}
          menuItems={menuItems}
          onItemClick={onItemClick}
          disabled={isDropdownDisabled}
          color={DropdownColor.Secondary}
          caretIconSize="16px"
          isDontInviteOptionSelected={dropdownValue === "Don't Invite"}
        />
      )}
    </Flex>
  );
};

export default InputGroup;
