import { useMemo } from 'react';
import { useFeatureSplit } from '../../../hooks/useFeatureSplit';
import {
  SplitNames,
  TreatmentTypes,
} from '../../../hooks/useSplitSdkConfig/constants';
import {
  useCurrentPlanFetchQuery,
  useProfileInfoFetchQuery,
} from '../../../queries/Profile';
import { isFeatureEnabledInCurrentPlan } from '../../../queries/Profile/utils';
import {
  useAxomoRewardsFetchQuery,
  useDonationFetchQuery,
  useGetGeneralRewardsInfo,
  useGiftCardFetchQuery,
  useRewardsFetchQuery,
} from '../../../queries/Rewards';
import { FEATURE_LIST } from '../../../Utils/admin/account';
import { DEFAULT_REWARDS_QUERY_PARAMS } from '../../../Utils/data/rewards';
import { isUserAdmin } from '../../../Utils/user';
import { rewardsTabButtons } from './data';

const useRewardsHeaderController = () => {
  const { data: profileData } = useProfileInfoFetchQuery();

  const { data: currentPlan } = useCurrentPlanFetchQuery();

  const isAdmin = profileData ? isUserAdmin(profileData.member) : false;
  const isChargeable = profileData
    ? profileData.assembly.accounts.rewards.isChargeable
    : false;

  const { treatment: axomoRewards } = useFeatureSplit(SplitNames.AXOMO_REWARDS);

  const isAxomoRewardsOn = axomoRewards === TreatmentTypes.ON;

  const isCultureRewardsEnabled = useMemo(() => {
    return isFeatureEnabledInCurrentPlan(
      FEATURE_LIST.CULTURE_REWARDS,
      currentPlan,
    );
  }, [currentPlan]);

  const isGiftCardsEnabled = useMemo(() => {
    return isFeatureEnabledInCurrentPlan(
      FEATURE_LIST.GIFT_CARD_REWARDS,
      currentPlan,
    );
  }, [currentPlan]);

  const isCharitiesEnabled = useMemo(() => {
    return isFeatureEnabledInCurrentPlan(
      FEATURE_LIST.CHARITY_REWARDS,
      currentPlan,
    );
  }, [currentPlan]);

  const isAxomoSwagsEnabled = useMemo(() => {
    return isFeatureEnabledInCurrentPlan(FEATURE_LIST.AXOMO_SWAGS, currentPlan);
  }, [currentPlan]);

  const { data: cultureRewardsData } = useRewardsFetchQuery(
    DEFAULT_REWARDS_QUERY_PARAMS.cultureRewards,
  );
  const { data: giftCardsData } = useGiftCardFetchQuery(
    DEFAULT_REWARDS_QUERY_PARAMS.giftCards,
  );
  const { data: charityRewardsData } = useDonationFetchQuery(
    DEFAULT_REWARDS_QUERY_PARAMS.charitySortParams,
  );

  const { data: swagRewardsData } = useAxomoRewardsFetchQuery({
    category: '',
  });

  const { data: generalRewardsInfo } = useGetGeneralRewardsInfo(true);

  const isSwagsTabTurnedOn = useMemo(() => {
    if (generalRewardsInfo) {
      return generalRewardsInfo.data.swags.tabVisibility === 'ON';
    }
  }, [generalRewardsInfo]);

  const hasCultureRewardsSetup = useMemo(() => {
    if (cultureRewardsData && cultureRewardsData.rewards) {
      const activeRewards = cultureRewardsData.rewards.filter(
        (reward) => reward.isActive,
      );
      return activeRewards.length > 0;
    }

    return false;
  }, [cultureRewardsData]);

  const hasGiftCardsSetup = useMemo(() => {
    if (giftCardsData && giftCardsData.rewards) {
      return giftCardsData.rewards.length > 0;
    }

    return false;
  }, [giftCardsData]);

  const hasCharitiesSetup = useMemo(() => {
    if (charityRewardsData && charityRewardsData.rewards) {
      return charityRewardsData.rewards.length > 0;
    }

    return false;
  }, [charityRewardsData]);

  const hasSwagRewardsSetup = useMemo(() => {
    if (swagRewardsData) {
      return swagRewardsData.length > 0;
    }

    return false;
  }, [swagRewardsData]);

  const hideCultureRewards = useMemo(() => {
    return !isAdmin && (!isCultureRewardsEnabled || !hasCultureRewardsSetup);
  }, [hasCultureRewardsSetup, isAdmin, isCultureRewardsEnabled]);

  const hideGiftCards = useMemo(() => {
    return (
      !isAdmin && (!isGiftCardsEnabled || !hasGiftCardsSetup || !isChargeable)
    );
  }, [hasGiftCardsSetup, isAdmin, isChargeable, isGiftCardsEnabled]);

  const hideCharities = useMemo(() => {
    return (
      !isAdmin && (!isCharitiesEnabled || !hasCharitiesSetup || !isChargeable)
    );
  }, [hasCharitiesSetup, isAdmin, isChargeable, isCharitiesEnabled]);

  const hideAxomoSwags = useMemo(() => {
    return (
      !isAdmin &&
      (!isAxomoSwagsEnabled ||
        !isChargeable ||
        !hasSwagRewardsSetup ||
        !isSwagsTabTurnedOn)
    );
  }, [
    hasSwagRewardsSetup,
    isAdmin,
    isAxomoSwagsEnabled,
    isChargeable,
    isSwagsTabTurnedOn,
  ]);
  const filteredRewardsTabButtons = useMemo(() => {
    return rewardsTabButtons.filter((item) => {
      if (item.id === 'culture') {
        if (hideCultureRewards) {
          return false;
        }
        return true;
      }

      if (item.id === 'gift-cards') {
        if (hideGiftCards) {
          return false;
        }
        return true;
      }

      if (item.id === 'charities') {
        if (hideCharities) {
          return false;
        }
        return true;
      }

      if (item.id === 'swag') {
        if (isAxomoRewardsOn && !hideAxomoSwags) {
          return true;
        }
        return false;
      }

      return true;
    });
  }, [
    hideCultureRewards,
    hideGiftCards,
    hideCharities,
    isAxomoRewardsOn,
    hideAxomoSwags,
  ]);

  return {
    filteredRewardsTabButtons,
  };
};

export default useRewardsHeaderController;
