import format from 'date-fns/format';
import { BaseEmoji, CustomEmoji } from 'emoji-mart';

import RRule from 'rrule';
import { FlowPermissions } from '../../analytics/interfaces';
import { ReactionType } from '../../../interfaces/Feed/Reaction';
import { getDateMonthYear, getTime } from '../scheduler/formRRulString';
import {
  getUTCDateFromDate,
  getUTCTimeFromDate,
} from '../scheduler/dateAndTime';

export const mapEmojiToReaction = (emoji: BaseEmoji | CustomEmoji) => {
  const customEmoji = { ...emoji } as CustomEmoji;

  if (customEmoji.imageUrl) {
    return {
      type: ReactionType.CUSTOM,
      name: customEmoji.colons || '',
      value: customEmoji.imageUrl,
      displayName: customEmoji.name,
    };
  }

  const baseEmoji = { ...emoji } as BaseEmoji;
  return {
    type: ReactionType.REGULAR,
    name: baseEmoji.colons,
    value: '',
    displayName: baseEmoji.id,
  };
};

export const getFlowPerms = (
  data:
    | {
        isFlowOwner: boolean;
        isFlowViewer: boolean;
        isFlowParticipant: boolean;
      }
    | undefined,
) => {
  const flowPerms: FlowPermissions[] = [];
  if (data) {
    if (data.isFlowOwner) {
      flowPerms.push('owner');
    }
    if (data.isFlowViewer) {
      flowPerms.push('viewer');
    }
    if (data.isFlowParticipant) {
      flowPerms.push('participant');
    }
  }
  return flowPerms;
};

export const getFormattedDateAndTimeFromUtcTime = (
  rrule: string | undefined,
) => {
  if (!rrule) {
    return {
      formattedDate: '',
      formattedTime: '',
    };
  }

  if (rrule !== '') {
    const parsedString = RRule.parseString(rrule);
    if (parsedString.dtstart) {
      const dateDetails = getDateMonthYear(
        getUTCDateFromDate(parsedString.dtstart),
      );
      const timeDetails = getTime(getUTCTimeFromDate(parsedString.dtstart));
      if (dateDetails !== null && timeDetails !== null) {
        const scheduledDate = new Date(
          dateDetails.year,
          dateDetails.month,
          dateDetails.date,
          timeDetails.hours,
          timeDetails.minutes,
        );
        const formattedDate = format(scheduledDate, 'MMMM d, yyyy');
        const formattedTime = format(scheduledDate, 'h:mm a');
        const formattedFullDate = format(
          scheduledDate,
          "EEEE, LLLL do y 'at' hh:mmaaa",
        );
        return {
          formattedDate,
          formattedTime,
          formattedFullDate,
        };
      }
    }
  }
  return {
    formattedDate: '',
    formattedTime: '',
  };
};

export const getFormattedNextTriggerDateAndTime = (
  nextTimeInUTC: string | undefined,
) => {
  if (!nextTimeInUTC) {
    return {
      formattedNextTriggerDate: '',
      formattedNextTriggerTime: '',
    };
  }
  const nextTriggerDate = new Date(nextTimeInUTC);
  const formattedNextTriggerDate = format(nextTriggerDate, 'MMMM d, yyyy');
  const formattedNextTriggerTime = format(nextTriggerDate, 'h:mm a');
  return {
    formattedNextTriggerDate,
    formattedNextTriggerTime,
  };
};
