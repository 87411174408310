import { orderBy } from 'lodash';

import {
  GiftCardReward,
  ExchangeRates,
  Denomination,
} from '../../queries/Rewards/interfaces';
import {
  MenuItemIndividualItem,
  MenuItemProps,
} from '../../atomic/molecules/Dropdown_V2/interfaces';
import { TrophyRangeItem } from '../../atomic/molecules/RewardCard/interface';
import { getCountryName } from '../admin/rewards';
import { SELECT_GIFT_CARD_VALUE } from '../../languages/en/rewards';
import { USD } from '../../constants/rewards';

const DEFAULT_CURRENCY_CODE = 'USD';

export const getCarrotsForCurrencyExchange = (
  faceValue: number,
  exchangeRate: number,
  currencyExchangeRate: number,
): number => {
  return Math.ceil((faceValue * exchangeRate) / currencyExchangeRate);
};

export const getCountryCodes = (giftCards: GiftCardReward[] = []) => {
  const countries: string[] = [];
  giftCards.forEach((giftCard) => {
    if (giftCard.items.length > 0) {
      giftCard.items[0].countries.forEach((country: string) => {
        if (countries.indexOf(country) === -1 && country !== 'UK') {
          countries.push(country);
        }
      });
    }
  });
  return countries;
};

export const getCurrencyCodes = (giftCards: GiftCardReward[] = []) => {
  const currencyCodes: string[] = [];
  giftCards.forEach((giftCard) => {
    if (giftCard.items.length > 0) {
      if (currencyCodes.indexOf(giftCard.items[0].currencyCode) === -1) {
        currencyCodes.push(giftCard.items[0].currencyCode);
      }
    }
  });
  return currencyCodes;
};

const getSortedDenominations = (denominations: Denomination[]) => {
  return denominations.sort(
    (itemA, itemB) => itemA.faceValue - itemB.faceValue,
  );
};

export const getTrophyRange = (
  denominations: Denomination[] = [],
  exchangeRates: ExchangeRates,
  exchangeRate: number,
) => {
  const range: TrophyRangeItem[] = [];
  const sortedDenominations = getSortedDenominations(denominations);
  sortedDenominations.forEach((denomination) => {
    const { faceValue } = denomination;
    const currencyCode = denomination.currencyCode || DEFAULT_CURRENCY_CODE;

    let currencyExchangeRate;
    if (currencyCode === USD) {
      currencyExchangeRate = 1;
    } else {
      currencyExchangeRate = exchangeRates[currencyCode];
    }

    const rangeItem = {
      cost: faceValue,
      trophies: getCarrotsForCurrencyExchange(
        faceValue,
        exchangeRate,
        currencyExchangeRate,
      ),
      currencyCode,
    };
    range.push(rangeItem);
  });
  return {
    range,
  };
};

export const getDropdownItems = (
  denominations: Denomination[],
  exchangeRates: ExchangeRates,
  exchangeRate: number,
  pointsEarned: number,
): MenuItemProps[] => {
  const dropdownItems: MenuItemIndividualItem[] = [];

  const sortedDenominations = getSortedDenominations(denominations);
  sortedDenominations.forEach((denomination) => {
    const id = denomination._id;

    const { faceValue } = denomination;
    const currencyCode = denomination.currencyCode || DEFAULT_CURRENCY_CODE;

    let currencyExchangeRate;
    if (currencyCode === USD) {
      currencyExchangeRate = 1;
    } else {
      currencyExchangeRate = exchangeRates[currencyCode];
    }

    const trophies = getCarrotsForCurrencyExchange(
      faceValue,
      exchangeRate,
      currencyExchangeRate,
    );

    const dropdownItem = {
      value: id,
      id,
      isSelected: false,
      disabled: pointsEarned < trophies,
    };

    dropdownItems.push(dropdownItem);
  });

  const dropdownOptions = [
    {
      id: 'category-one',
      category: SELECT_GIFT_CARD_VALUE,
      items: dropdownItems,
    },
  ];

  return dropdownOptions;
};

interface FilterItemsType {
  arr: any[];
  key: string;
  value: string;
}

interface FilterByCountry {
  arr: any[];
  countryCode: string;
}

export const filterItems = ({ arr, key, value }: FilterItemsType) => {
  return arr.filter((item) => {
    return item[key].toLowerCase().includes(value.toLowerCase());
  });
};

export const filterByCountry = ({ arr, countryCode }: FilterByCountry) => {
  return arr.filter((item) => {
    return item.countries.includes(countryCode);
  });
};

export const getCountriesMenu = (
  rewards: GiftCardReward[],
  defaultCountryCode: string,
): MenuItemProps[] => {
  const countryCodes = getCountryCodes(rewards);

  let giftCardCountryMenuItems = countryCodes.map((code) => {
    return {
      id: code,
      value: getCountryName(code),
      isSelected: code === defaultCountryCode,
    };
  });

  giftCardCountryMenuItems = orderBy(giftCardCountryMenuItems, 'value', 'asc');

  return [
    {
      id: 'filter-by',
      items: giftCardCountryMenuItems,
    },
  ];
};

export const canDisableRewardCardActionButton = (
  trophyRange: TrophyRangeItem[] = [],
  pointsEarned: number,
) => {
  return (
    trophyRange.length === 0 ||
    (trophyRange.length > 0 && pointsEarned < trophyRange[0].trophies)
  );
};
