import { useState, useCallback, useMemo } from 'react';
import {
  MultiSelectDropdownValues,
  MultiSelectDropdownType,
  SettingsMultiSelectDropdownProps,
} from './interface';
import { MULTI_SELECT_DROPDOWN_LANGUAGE } from '../../../languages/en/settings';
import { TextFieldProps } from '../TextField_V2/interface';

const useSettingsMultiSelectDropdownLogic = (
  props: SettingsMultiSelectDropdownProps,
) => {
  const {
    isDisabled,
    onSaveChanges,
    initialValues,
    monthDropDownItems,
    dayDropDownItems,
    yearDropDownItems,
    multiSelectDropDownType,
  } = props;
  const {
    fieldOneValue,
    fieldTwoValue,
    fieldThreeValue,
  }: MultiSelectDropdownValues = initialValues;

  const [monthValue, setMonthValue] = useState(fieldOneValue);
  const [dayValue, setDayValue] = useState(fieldTwoValue);
  const [yearValue, setYearValue] = useState(fieldThreeValue);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const dateValues: MultiSelectDropdownValues = useMemo(() => {
    return {
      fieldOneValue: monthValue,
      fieldTwoValue: dayValue,
      fieldThreeValue: yearValue,
    };
  }, [dayValue, monthValue, yearValue]);

  const isSaveButtonDisabled = useCallback((inputValue, defaultValue) => {
    if (inputValue !== defaultValue) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, []);

  const handleFieldOneChange = useCallback(
    (inputValue: string) => {
      isSaveButtonDisabled(inputValue, fieldOneValue);
      setMonthValue(inputValue);
    },
    [fieldOneValue, isSaveButtonDisabled],
  );

  const handleFieldTwoChange = useCallback(
    (inputValue: string) => {
      isSaveButtonDisabled(inputValue, fieldTwoValue);
      setDayValue(inputValue);
    },
    [fieldTwoValue, isSaveButtonDisabled],
  );

  const handleFieldThreeChange = useCallback(
    (inputValue: string) => {
      isSaveButtonDisabled(inputValue, fieldThreeValue);
      setYearValue(inputValue);
    },
    [fieldThreeValue, isSaveButtonDisabled],
  );

  const isButtonDisabled = useMemo(() => {
    if (multiSelectDropDownType === MultiSelectDropdownType.Birthday) {
      if (!buttonDisabled) {
        return !(monthValue.length > 0 && dayValue.length > 0);
      }
    }
    if (multiSelectDropDownType === MultiSelectDropdownType.StartDate) {
      if (!buttonDisabled && yearValue) {
        return !(
          monthValue.length > 0 &&
          dayValue.length > 0 &&
          yearValue.length > 0
        );
      }
    }
    return true;
  }, [
    buttonDisabled,
    dayValue.length,
    monthValue.length,
    multiSelectDropDownType,
    yearValue,
  ]);

  const fieldOneValues: TextFieldProps = useMemo(() => {
    return {
      placeholder: MULTI_SELECT_DROPDOWN_LANGUAGE.MONTH,
      label: MULTI_SELECT_DROPDOWN_LANGUAGE.MONTH,
      dropdownItems: monthDropDownItems,
      value: monthValue,
      onChange: handleFieldOneChange,
      disabled: isDisabled,
    };
  }, [handleFieldOneChange, isDisabled, monthDropDownItems, monthValue]);

  const fieldTwoValues: TextFieldProps = useMemo(() => {
    return {
      placeholder: MULTI_SELECT_DROPDOWN_LANGUAGE.DAY,
      label: MULTI_SELECT_DROPDOWN_LANGUAGE.DAY,
      dropdownItems: dayDropDownItems,
      value: dayValue,
      onChange: handleFieldTwoChange,
      disabled: isDisabled,
    };
  }, [dayDropDownItems, dayValue, handleFieldTwoChange, isDisabled]);

  const fieldThreeValues: TextFieldProps = useMemo(() => {
    return {
      placeholder: MULTI_SELECT_DROPDOWN_LANGUAGE.YEAR,
      label: MULTI_SELECT_DROPDOWN_LANGUAGE.YEAR,
      dropdownItems: yearDropDownItems,
      value: yearValue || '',
      onChange: handleFieldThreeChange,
      disabled: isDisabled,
    };
  }, [handleFieldThreeChange, isDisabled, yearDropDownItems, yearValue]);

  // const helperTextMessage = isDisabled
  //   ? NOT_ADMIN_HELPER_TEXT
  //   : ADMIN_HELPER_TEXT;

  const helperTextMessage = MULTI_SELECT_DROPDOWN_LANGUAGE.CANT_EDIT_TEXT;

  const onSaveChangesClick = useCallback(() => {
    onSaveChanges(dateValues);
  }, [dateValues, onSaveChanges]);

  const multiSelectProps = useMemo(() => {
    if (multiSelectDropDownType === MultiSelectDropdownType.StartDate) {
      return {
        fieldOne: fieldOneValues,
        fieldTwo: fieldTwoValues,
        fieldThree: fieldThreeValues,
      };
    }
    return {
      fieldOne: fieldOneValues,
      fieldTwo: fieldTwoValues,
    };
  }, [
    fieldOneValues,
    fieldThreeValues,
    fieldTwoValues,
    multiSelectDropDownType,
  ]);

  return {
    models: {
      helperTextMessage,
      isButtonDisabled,
      isDisabled,
      multiSelectProps,
    },
    operations: {
      onSaveChangesClick,
    },
  };
};

export default useSettingsMultiSelectDropdownLogic;
