import { useCallback, useMemo, useState } from 'react';
import copy from 'copy-to-clipboard';

import { EmojiData } from 'emoji-mart';
import { FeedPostHeaderProps } from '../../../atomic/molecules/FeedPostHeader/interfaces';
import { FeedPostBodyProps } from '../../../atomic/organism/FeedPostBody/interface';
import { FeedPostReactionsProps } from '../../../atomic/organism/FeedPostReactions/interface';
import {
  FeedOptionsInterface,
  UpdateReactionAction,
  UpdateReactionMutationVariables,
  Reaction,
  FeedItemFromAPI,
  MemberInteractionType,
} from '../../../interfaces/Feed';
import { getFormattedMessage } from '../../../Utils/message';
import { GetProfileInfoResponse } from '../../../queries/Profile';
import { useAdminDeleteMedia, useAdminDeletePost } from '../../../queries/Feed';
import { isUserAdmin } from '../../../Utils/user';
import {
  GIVE_TROPHIES_BACK_TO_SENDER,
  ALLOW_THE_RECEIVER_TO_KEEP,
} from '../../../languages/en/home';
import { dropdownOptions as postOptionsDropdownOptions } from '../../../atomic/organism/FeedPostHeaderOption/data';
import {
  MenuItemProps,
  DropdownColor,
} from '../../../atomic/molecules/Dropdown_V2/interfaces';
import { APP_URL } from '../../../config';
import { V2_COMMENT_PAGE, V2_POST_PAGE } from '../../../constants/routes';
import { PostInputProps } from '../../../atomic/organism/PostInput';
import useGiveRecognitionFormLogic from '../../../atomic/organism/GiveRecognitionForm/useGiveRecognitionFormLogic';
import {
  PostType,
  GiveRecognitionFormBottomButtonsBaseProps,
} from '../../../atomic/molecules/GiveRecognitionFormBottomButtons/interface';
import { AssemblyCurrency } from '../../../interfaces/assembly';
import {
  AddTrophiesInputProps,
  AddTrophiesValue,
} from '../../../atomic/molecules/AddTrophiesInput';
import { GiveRecognitionTipsProps } from '../../../atomic/molecules/GiveRecognitionTips';
import { EditorProps } from '../../../atomic/organism/RichTextEditor';
import { PostInputBaseProps } from '../../../atomic/organism/PostInput/interface';
import { AvatarProps } from '../../../atomic/atoms/Avatar/interfaces';
import { CurrentUser } from '../../../interfaces/currentUser';
import {
  getCanCheckForBadWords,
  getCanDeletePosts,
  getCanShowAddTrophies,
  getGifRatings,
  getTrophies,
} from '../../../queries/Profile/utils';
import useAtMentions from '../../../hooks/useAtMentions';
import {
  getEmojiPayload,
  serializeReactions,
  serializeReactionTogglePayload,
} from '../../../Utils/serializers/feed';
import {
  useAdminDeleteComment,
  useGetPostComments,
  useUpdateCommentReactionMutation,
} from '../../../queries/Feed/comments';
import useCreatePostComment from '../../../queries/Feed/useCreatePostComment';
import { FeedPostCommentsProps } from '../../../atomic/organism/FeedPostComments';
import { getCommentPropsFromResponse } from '../../../queries/Feed/utils';
import {
  COMMENT_CREATION_ERROR_MESSAGE,
  POST_DELETION_ERROR_MESSAGE,
  POST_DELETION_INPROGRESS_MESSAGE,
  POST_DELETION_SUCCESS_MESSAGE,
  COMMENT_DELETION_SUCCESS_MESSAGE,
  COMMENT_DELETION_ERROR_MESSAGE,
  COMMENT_DELETION_INPROGRESS_MESSAGE,
  PROFANE_ERROR_MESSAGE_COMMENT,
} from '../../../languages/en/giveRecognitionForm';
import {
  showInfoMessage,
  showSuccessMessage,
  showErrorMessage,
  dismissAllToasts,
} from '../../../Utils/toast';
import { checkForProfanity } from '../../../Utils/giveCarrots/validation';
import useOnClickOutside from '../../../hooks/useOnOutsideClick';
import { getDropDownOptions, isGifURLPresent } from '../../../Utils/posts';

const emptyFunction = () => {};
export interface PostControllerProps {
  post: FeedItemFromAPI;
  onMemberClick: (member: MemberInteractionType) => void;
  feedOptions?: FeedOptionsInterface;
  updateReaction: (variables: UpdateReactionMutationVariables) => void;
  currentUser: CurrentUser;
  assemblyCurrency: AssemblyCurrency;
  currentUserAvatar: AvatarProps;
  profileData?: GetProfileInfoResponse;
  onPostDelete?: () => void;
}

const usePostControllerLogic = (props: PostControllerProps) => {
  const {
    post,
    onMemberClick,
    feedOptions,
    updateReaction,
    currentUser,
    assemblyCurrency,
    currentUserAvatar,
    profileData,
    onPostDelete,
  } = props;
  const {
    postID,
    from,
    to,
    type,
    message,
    createdAt,
    isPrivate,
    gifURL,
    imageURL,
    coreValue,
    taggedUsers,
    pointsEach,
    commentsCount,
  } = post;
  const hasTrophies = pointsEach !== 0;
  const [isCommentEditorFocused, setCommentEditorFocused] = useState(false);
  const [isOutsideClicked, setIsOutsideClicked] = useState(false);

  // Copied from PrimaryNavController
  const [isCurrentUserAdmin, currentUserId] = useMemo(() => {
    if (!currentUser) {
      return [false, ''];
    }
    return [isUserAdmin(currentUser), currentUser.memberId];
  }, [currentUser]);

  const serializedReactions = useMemo(
    () => serializeReactions(post, currentUserId),
    [currentUserId, post],
  );

  const { mutate: deletePostMedia } = useAdminDeleteMedia();

  const handleAdminDeletePostSuccess = useCallback(() => {
    dismissAllToasts();
    showSuccessMessage(POST_DELETION_SUCCESS_MESSAGE);
    if (onPostDelete) {
      onPostDelete();
    }
  }, [onPostDelete]);
  const handleAdminDeletePostError = useCallback(() => {
    dismissAllToasts();
    showErrorMessage(POST_DELETION_ERROR_MESSAGE);
  }, []);
  const { mutate: adminDeletePost } = useAdminDeletePost(
    handleAdminDeletePostSuccess,
    handleAdminDeletePostError,
  );
  const { mutate: updateCommentReaction } = useUpdateCommentReactionMutation();

  const copyPostLink = useCallback(() => {
    // slice removes the ending /
    const link = `${APP_URL.slice(0, -1)}${V2_POST_PAGE}`.replace(
      ':postId',
      postID,
    );
    copy(link);
  }, [postID]);

  const removePostGif = useCallback(() => {
    deletePostMedia({ postId: postID, feedOptions });
  }, [deletePostMedia, postID, feedOptions]);
  const [deletePostCarrotOption, setDeletePostCarrotOption] =
    useState<string>();
  const handleDeletePostOptionChange = useCallback(
    (value: string) => {
      setDeletePostCarrotOption(value);
    },
    [setDeletePostCarrotOption],
  );
  const deletePost = useCallback(() => {
    if (isCurrentUserAdmin) {
      dismissAllToasts();
      showInfoMessage(POST_DELETION_INPROGRESS_MESSAGE);
      if (hasTrophies) {
        if (deletePostCarrotOption === GIVE_TROPHIES_BACK_TO_SENDER) {
          adminDeletePost({ postId: postID, isWithCarrots: true, feedOptions });
        }
        if (deletePostCarrotOption === ALLOW_THE_RECEIVER_TO_KEEP) {
          adminDeletePost({
            postId: postID,
            isWithCarrots: false,
            feedOptions,
          });
        }
      } else {
        adminDeletePost({ postId: postID, isWithCarrots: false, feedOptions });
      }
    }
  }, [
    adminDeletePost,
    feedOptions,
    hasTrophies,
    isCurrentUserAdmin,
    postID,
    deletePostCarrotOption,
  ]);

  const onUserClicked = useCallback(
    (member: MemberInteractionType) => {
      onMemberClick(member);
    },
    [onMemberClick],
  );

  const canDeletePosts = useMemo(
    () => getCanDeletePosts(profileData),
    [profileData],
  );

  const dropdownOptions: MenuItemProps[] = useMemo(
    () =>
      getDropDownOptions(
        postOptionsDropdownOptions,
        isCurrentUserAdmin,
        isGifURLPresent(post?.gifURL),
        canDeletePosts,
      ),
    [canDeletePosts, isCurrentUserAdmin, post],
  );

  const feedPostHeaderProps: FeedPostHeaderProps = useMemo(
    () => ({
      from,
      to,
      type,
      trophies: pointsEach !== 0 ? pointsEach : undefined,
      createdAt,
      isPrivate,
      currentUserId,
      currency: assemblyCurrency,
      onClickUserName: onMemberClick,
      hasTrophies,
      showEach: to.length > 1,
      onRemoveGif: removePostGif,
      onCopyLink: copyPostLink,
      onDeletePostSelectedOption: handleDeletePostOptionChange,
      onDeleteModalSubmitClick: deletePost,
      dropdownOptions,
    }),
    [
      from,
      to,
      type,
      pointsEach,
      createdAt,
      isPrivate,
      currentUserId,
      assemblyCurrency,
      onMemberClick,
      hasTrophies,
      removePostGif,
      copyPostLink,
      handleDeletePostOptionChange,
      deletePost,
      dropdownOptions,
    ],
  );

  const memoizedMessageText = useMemo(
    () => getFormattedMessage(message, taggedUsers, onUserClicked),
    [message, onUserClicked, taggedUsers],
  );

  const image = gifURL || imageURL;
  const feedPostBodyProps: FeedPostBodyProps = useMemo(
    () => ({
      coreValue,
      messageText: memoizedMessageText,
      image,
    }),
    [coreValue, image, memoizedMessageText],
  );

  const onEmoticonSelect = useCallback(
    (emoji: EmojiData, contentID: string) => {
      const [selectedReaction, payload] = getEmojiPayload(
        serializedReactions,
        emoji,
      );
      // CURRENTLY HANDLING IF AN EMOTICON THAT IS ALREADY SELECTED BY THE USER IS CLICKED
      if (currentUser && !selectedReaction?.active) {
        updateReaction(
          serializeReactionTogglePayload(
            payload,
            contentID,
            UpdateReactionAction.SET,
            currentUser,
          ),
        );
      }
    },
    [currentUser, serializedReactions, updateReaction],
  );

  const onReactButtonClick = useCallback(() => {}, []);
  const onReactionToggleClick = useCallback(
    ({ reaction, active }: Reaction, contentID: string) => {
      if (currentUser) {
        updateReaction(
          serializeReactionTogglePayload(
            reaction,
            contentID,
            active ? UpdateReactionAction.UNSET : UpdateReactionAction.SET,
            currentUser,
          ),
        );
      }
    },
    [currentUser, updateReaction],
  );

  const { models, operations } = useGiveRecognitionFormLogic();
  const {
    message: commentMessage,
    editorState,
    gifUrl,
    hasText,
    trophiesInputValue,
    refElement,
    containerRef,
    isPrivate: isPrivateComment,
    inputErrorMessage,
    selectedMentions,
  } = models;
  const {
    setEditorState,
    onTrophiesInputChange,
    onAtMentionClick,
    onEmoticonClick,
    onGifSelectorClick,
    onGifRemoveClick,
    onAddMention,
    onEditorFocus,
    onEditorBlur,
    onPrivateMessageClick,
    onAttachmentClick,
    resetInput,
    setInputErrorMessage,
    setEditorFocus,
  } = operations;
  const {
    suggestions,
    onMentionsChange,
    isLoading: isMentionsLoading,
  } = useAtMentions();

  const onCommentButtonClick = useCallback(() => {
    setIsOutsideClicked(false);
    if (isOutsideClicked === false) {
      setCommentEditorFocused(true);
      setEditorFocus();
    }
  }, [isOutsideClicked, setEditorFocus]);

  const onOutsideClickHandler = useCallback(() => {
    if (isCommentEditorFocused) {
      setIsOutsideClicked(true);
      setCommentEditorFocused(false);
    }
  }, [isCommentEditorFocused]);
  useOnClickOutside(containerRef, onOutsideClickHandler);

  const feedPostReactionsProps: FeedPostReactionsProps = useMemo(
    () => ({
      currentUserId: profileData?.member.memberId || '',
      reactions: serializedReactions,
      onCommentButtonClick,
      onEmoticonSelect,
      onReactButtonClick,
      onReactionToggleClick,
      contentId: post.postID,
    }),
    [
      onCommentButtonClick,
      onEmoticonSelect,
      onReactButtonClick,
      onReactionToggleClick,
      post.postID,
      profileData,
      serializedReactions,
    ],
  );

  const addTrophies: AddTrophiesInputProps = useMemo(
    () =>
      getTrophies(
        trophiesInputValue,
        to.filter(({ memberID }) => currentUser.memberId !== memberID).length,
        onTrophiesInputChange,
        profileData,
        DropdownColor.Primary,
      ),
    [onTrophiesInputChange, profileData, trophiesInputValue, to, currentUser],
  );

  const gifRatings = useMemo(() => getGifRatings(profileData), [profileData]);

  const showAddTrophy = useMemo(() => {
    const canShowAddTrophies = getCanShowAddTrophies(profileData);
    const isCurrentUserOnlyReceiver =
      to.length === 1 ? to[0].memberID === currentUserId : false;
    return canShowAddTrophies && !isCurrentUserOnlyReceiver;
  }, [profileData, to, currentUserId]);

  const handleEditorFocus = useCallback(() => {
    onEditorFocus();
    setCommentEditorFocused(true);
  }, [onEditorFocus]);

  const editorProps: EditorProps = useMemo(
    () => ({
      editorState,
      editorRefElement: refElement,
      onAddMention,
      onEditorBlur,
      onEditorChange: setEditorState,
      onEditorFocus: handleEditorFocus,
      suggestions,
      onSearchChange: onMentionsChange,
      isMentionsLoading,
    }),
    [
      editorState,
      refElement,
      onAddMention,
      onEditorBlur,
      setEditorState,
      handleEditorFocus,
      suggestions,
      onMentionsChange,
      isMentionsLoading,
    ],
  );

  const canCheckForBadWords = useMemo(
    () => getCanCheckForBadWords(profileData),
    [profileData],
  );

  const handleCreatePostCommentSuccess = useCallback(() => {
    resetInput();
  }, [resetInput]);

  const handleCreatePostCommentError = useCallback(() => {
    dismissAllToasts();
    showErrorMessage(COMMENT_CREATION_ERROR_MESSAGE);
  }, []);

  const { mutate: createPostComment, isLoading: isCreatingComment } =
    useCreatePostComment(
      handleCreatePostCommentSuccess,
      handleCreatePostCommentError,
    );
  const handlePostClick = useCallback(() => {
    // from GiveCarrots.js
    const hasBadWords = checkForProfanity(commentMessage);
    if (canCheckForBadWords && hasBadWords) {
      showErrorMessage(PROFANE_ERROR_MESSAGE_COMMENT);
    } else {
      setInputErrorMessage(undefined);
      createPostComment({
        postId: postID,
        text: commentMessage,
        points: trophiesInputValue > 0 ? trophiesInputValue : undefined,
        gifUrl,
        selectedMentions,
      });
    }
  }, [
    commentMessage,
    canCheckForBadWords,
    setInputErrorMessage,
    createPostComment,
    postID,
    trophiesInputValue,
    gifUrl,
    selectedMentions,
  ]);

  const onCommentReactionToggleClick = useCallback(
    ({ reaction, active }: Reaction, contentID: string) => {
      if (currentUser) {
        serializeReactionTogglePayload(
          reaction,
          contentID,
          active ? UpdateReactionAction.UNSET : UpdateReactionAction.SET,
          currentUser,
        );
        updateCommentReaction({
          ...serializeReactionTogglePayload(
            reaction,
            contentID,
            active ? UpdateReactionAction.UNSET : UpdateReactionAction.SET,
            currentUser,
          ),
          postID: post.postID,
        });
      }
    },
    [currentUser, post.postID, updateCommentReaction],
  );

  const onCommentEmoticonClick = useCallback(
    (reactions: Reaction[]) => (emoji: EmojiData, contentID: string) => {
      const [selectedReaction, payload] = getEmojiPayload(reactions, emoji);
      // CURRENTLY HANDLING IF AN EMOTICON THAT IS ALREADY SELECTED BY THE USER IS CLICKED
      if (currentUser && !selectedReaction?.active) {
        updateCommentReaction({
          ...serializeReactionTogglePayload(
            payload,
            contentID,
            UpdateReactionAction.SET,
            currentUser,
          ),
          postID: post.postID,
        });
      }
    },
    [currentUser, post.postID, updateCommentReaction],
  );

  const giveRecognitionFormBottomButtonsProps: GiveRecognitionFormBottomButtonsBaseProps =
    useMemo(
      () => ({
        postType: PostType.Comment,
        onAtMentionClick,
        onEmoticonClick,
        onGifSelectorClick,
        onAttachmentClick,
        isGifSelected: gifUrl !== '',
        // isAttachmentSelected,
        isPrivateMessageSelected: isPrivateComment,
        onPrivateMessageClick,
        onPostClick: handlePostClick,
        showAddTrophy,
        isCreatingPostOrComment: isCreatingComment,
        isAttachmentButtonHidden: true,
        isPrivatePostButtonHidden: true,
        gifRatings,
      }),
      [
        gifUrl,
        isPrivateComment,
        isCreatingComment,
        showAddTrophy,
        gifRatings,
        onAtMentionClick,
        onAttachmentClick,
        onEmoticonClick,
        onGifSelectorClick,
        onPrivateMessageClick,
        handlePostClick,
      ],
    );

  const postInputBaseProps: PostInputBaseProps = useMemo(
    () => ({
      containerRef,
      currentUser: currentUserAvatar,
      hasText: hasText && trophiesInputValue !== AddTrophiesValue.Input,
      isEditorFocused: isCommentEditorFocused,
      showRecognitionTips: false,
      selectedGif: gifUrl,
      inputErrorMessage,
      onGifRemoveClick,
      setEditorFocus,
    }),
    [
      containerRef,
      currentUserAvatar,
      hasText,
      trophiesInputValue,
      isCommentEditorFocused,
      gifUrl,
      inputErrorMessage,
      onGifRemoveClick,
      setEditorFocus,
    ],
  );

  // Following are not needed as they are for give recognition tips and are not applicable for comments
  const giveRecognitionTipsProps: GiveRecognitionTipsProps = useMemo(
    () => ({
      isOpen: false,
      onToggleClick: emptyFunction,
      onToggleKeyPress: emptyFunction,
    }),
    [],
  );

  const {
    data: commentsPaginatedData,
    hasNextPage: commentsHasNextPage,
    fetchNextPage: fetchNextComments,
  } = useGetPostComments(postID, commentsCount > 0);

  const onCopyCommentLink = useCallback(
    (commentID) => {
      // slice removes the ending /
      const link = `${APP_URL.slice(0, -1)}${V2_COMMENT_PAGE}`
        .replace(':commentId', commentID)
        .replace(':postId', postID);
      copy(link);
    },
    [postID],
  );

  const handleAdminDeleteCommentSuccess = useCallback(() => {
    dismissAllToasts();
    showSuccessMessage(COMMENT_DELETION_SUCCESS_MESSAGE);
    if (onPostDelete) {
      onPostDelete();
    }
  }, [onPostDelete]);
  const handleAdminDeleteCommentError = useCallback(() => {
    showErrorMessage(COMMENT_DELETION_ERROR_MESSAGE);
  }, []);
  const { mutate: deleteComment } = useAdminDeleteComment(
    handleAdminDeleteCommentSuccess,
    handleAdminDeleteCommentError,
  );
  const onDeleteCommentClick = useCallback(
    (commentId, commentHasTrophies, deleteCommentCarrotOption) => {
      if (isCurrentUserAdmin) {
        dismissAllToasts();
        showInfoMessage(COMMENT_DELETION_INPROGRESS_MESSAGE);
        if (commentHasTrophies) {
          if (deleteCommentCarrotOption === GIVE_TROPHIES_BACK_TO_SENDER) {
            deleteComment({ postId: postID, commentId, isWithCarrots: true });
          }
          if (deleteCommentCarrotOption === ALLOW_THE_RECEIVER_TO_KEEP) {
            deleteComment({ postId: postID, commentId, isWithCarrots: false });
          }
        } else {
          deleteComment({ postId: postID, commentId, isWithCarrots: false });
        }
      }
    },
    [deleteComment, isCurrentUserAdmin, postID],
  );

  const feedPostCommentsProps: FeedPostCommentsProps = useMemo(
    () => ({
      feedCommentProps: getCommentPropsFromResponse(
        commentsPaginatedData,
        currentUser.memberId,
        isCurrentUserAdmin,
        canDeletePosts,
        onUserClicked,
        assemblyCurrency,
        onDeleteCommentClick,
        onCopyCommentLink,
        onCommentEmoticonClick,
        onCommentReactionToggleClick,
      ),
      showMoreCommentsButton:
        commentsCount > 3 && (commentsHasNextPage || false),
      onShowMoreCommentsButtonClick: fetchNextComments,
    }),
    [
      commentsPaginatedData,
      currentUser.memberId,
      isCurrentUserAdmin,
      canDeletePosts,
      onUserClicked,
      assemblyCurrency,
      onDeleteCommentClick,
      onCopyCommentLink,
      onCommentEmoticonClick,
      onCommentReactionToggleClick,
      commentsCount,
      commentsHasNextPage,
      fetchNextComments,
    ],
  );

  const feedCommentInputProps: PostInputProps = useMemo(
    () => ({
      ...giveRecognitionTipsProps,
      ...addTrophies,
      ...editorProps,
      ...giveRecognitionFormBottomButtonsProps,
      ...postInputBaseProps,
    }),
    [
      giveRecognitionTipsProps,
      addTrophies,
      editorProps,
      giveRecognitionFormBottomButtonsProps,
      postInputBaseProps,
    ],
  );

  return {
    feedPostHeaderProps,
    feedPostBodyProps,
    feedPostReactionsProps,
    feedPostCommentsProps,
    feedCommentInputProps,
  };
};
export default usePostControllerLogic;
