export enum ComponentStatus {
  LOADING = 'loading',
  LOADED = 'loaded',
  ERROR = 'error',
  EMPTY = 'empty',
}

export type LoadingProps = {
  status: ComponentStatus.LOADING;
};

export type ErrorProps = {
  status: ComponentStatus.ERROR;
  message: string;
};
