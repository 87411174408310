import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import {
  GET_ACTIVE_FLOWS,
  GET_FLOW_DETAILS,
  GET_TODO_FLOWS,
} from '../../constants/endpoints';
import { PostTypes } from '../../interfaces/Home';
import { useGetFlowsTodoCountQuery } from '../../queries/ActionBar';
import { useProfileInfoFetchQuery } from '../../queries/Profile';
import { pusher } from '../../pusher/pusher-base';
import {
  SocketPayloadType,
  UseCommonFeedSocketParamTypes,
  WebSocketFeedTypes,
} from './types';
import { Channel } from 'pusher-js';
import useHasActionBar from '../useHasActionBar';
import {
  useGetFlowPostComments,
  useGetPostComments,
} from '../../queries/Feed/comments';

let channelMember: Channel;
let channelAssembly: Channel;

const useCommonFeedSocket = (options: UseCommonFeedSocketParamTypes) => {
  const hasActionBar = useHasActionBar();
  const { data, refetch } = useProfileInfoFetchQuery();
  const { refetch: refreshToDoCount } = useGetFlowsTodoCountQuery(hasActionBar);

  const [flowId, setFlowId] = useState('');
  const [responseId, setResponseId] = useState('');
  const [postId, setPostId] = useState('');
  const [commentId, setCommentId] = useState('');

  const { refetch: refetchFlowPostComments } = useGetFlowPostComments(
    flowId,
    responseId,
    false,
  );

  const { refetch: refetchPostComments } = useGetPostComments(postId, false);

  useEffect(() => {
    if (flowId && responseId) {
      refetchFlowPostComments();
    }
  }, [flowId, refetchFlowPostComments, responseId, commentId]);

  useEffect(() => {
    if (postId) {
      refetchPostComments();
    }
  }, [postId, refetchPostComments, commentId]);

  const queryClient = useQueryClient();

  useEffect(() => {
    let channelAssemblyEventHandler: (
      eventName: string,
      socketPayload: SocketPayloadType,
    ) => void | undefined;
    let channelMemberEventHandler: (
      eventName: string,
      socketPayload: SocketPayloadType,
    ) => void | undefined;
    if (data) {
      const { assemblyId } = data.assembly;
      const { memberId } = data.member;

      channelMember = pusher.subscribe(`private-member-${memberId}`);
      channelAssembly = pusher.subscribe(`private-assembly-${assemblyId}`);

      channelAssemblyEventHandler = (
        eventName: string,
        socketPayload: SocketPayloadType,
      ) => {
        switch (eventName) {
          case 'NEW_FEED': {
            if (socketPayload?.fromMemberId !== memberId) {
              if (options.type === WebSocketFeedTypes.MAIN) {
                options.setMainFeedShowLoadMoreButtonToTrue();
              }
              if (
                options.type === WebSocketFeedTypes.CELEBRATE_TEAMMATE &&
                options.setCelebrateTeammateFeedShowLoadMoreButtonToTrue
              ) {
                options.setCelebrateTeammateFeedShowLoadMoreButtonToTrue();
              }
            }
            break;
          }
          case 'ADMIN_SETTINGS_UPDATED': {
            refetch();
            break;
          }
          case 'FLOW_RESPONSE_DELETED': {
            if (options.type === WebSocketFeedTypes.MAIN) {
              options.onMainFeedFlowResponseDelete();
            }

            if (options.type === WebSocketFeedTypes.FLOW) {
              if (options.flowId === socketPayload?.flowId) {
                options.onFlowFeedFlowResponseDelete();
              }
            }

            break;
          }
          case 'POST_DELETED': {
            if (options.type === WebSocketFeedTypes.MAIN) {
              options.onMainFeedPostDeleted();
            }

            if (options.type === WebSocketFeedTypes.CELEBRATE_TEAMMATE) {
              if (
                socketPayload?.postType === PostTypes.RECOGNITION &&
                options.onCelebrateATeammatePostDeleted
              ) {
                options.onCelebrateATeammatePostDeleted();
              }
            }

            break;
          }
          case 'OCCURRENCE_CREATED':
          case 'OCCURRENCE_ENDED': {
            refreshToDoCount();
            queryClient.invalidateQueries([GET_TODO_FLOWS]);
            if (socketPayload?.flowId) {
              queryClient.invalidateQueries([
                GET_FLOW_DETAILS,
                socketPayload.flowId,
              ]);
            }
            queryClient.invalidateQueries([GET_ACTIVE_FLOWS]);
            break;
          }
          case 'NEW_COMMENT': {
            if (socketPayload?.fromMemberId !== memberId) {
              if (socketPayload.commentID) {
                setCommentId(socketPayload.commentID);
              }
              if (options.type === WebSocketFeedTypes.MAIN) {
                if (socketPayload.postId) {
                  const currentPost = options.feedProps.find(
                    (item) =>
                      item.type === PostTypes.RECOGNITION &&
                      item.postID === socketPayload.postId,
                  );
                  if (currentPost) {
                    setPostId(socketPayload.postId);
                  }
                } else if (socketPayload.flowId && socketPayload.responseId) {
                  const currentPost = options.feedProps.find(
                    (item) =>
                      item.type === PostTypes.FLOW &&
                      item.flowResponse?.flow.flowId === socketPayload.flowId &&
                      item.flowResponse?.responseId ===
                        socketPayload.responseId,
                  );

                  if (currentPost) {
                    setFlowId(socketPayload.flowId);
                    setResponseId(socketPayload.responseId);
                  }
                }
              }

              if (options.type === WebSocketFeedTypes.CELEBRATE_TEAMMATE) {
                if (socketPayload.postId) {
                  const currentPost = options.feedProps.find(
                    (item) =>
                      item.type === PostTypes.RECOGNITION &&
                      item.postId === socketPayload.postId,
                  );
                  if (currentPost) {
                    setPostId(socketPayload.postId);
                  }
                }
              }

              if (options.type === WebSocketFeedTypes.FLOW) {
                if (options.flowId === socketPayload?.flowId) {
                  if (socketPayload.flowId && socketPayload.responseId) {
                    const currentPost =
                      options.feedProps &&
                      options.feedProps.find(
                        (item) =>
                          item.type === PostTypes.FLOW &&
                          item.flowId === socketPayload.flowId &&
                          item.responseId === socketPayload.responseId,
                      );

                    if (currentPost) {
                      setFlowId(socketPayload.flowId);
                      setResponseId(socketPayload.responseId);
                    }
                  }
                }
              }
            }
            break;
          }
          default:
            break;
        }
      };

      channelMemberEventHandler = (
        eventName: string,
        socketPayload: SocketPayloadType,
      ) => {
        switch (eventName) {
          case 'NEW_FLOW_RESPONSE': {
            if (options.type === WebSocketFeedTypes.MAIN) {
              options.setMainFeedShowLoadMoreButtonToTrue();
            }

            if (options.type === WebSocketFeedTypes.FLOW) {
              if (
                socketPayload?.fromMemberId !== memberId &&
                socketPayload?.flowId === options.flowId
              ) {
                options.setFlowFeedShowLoadMoreButtonToTrue();
              }
            }
            break;
          }
          default:
            break;
        }
      };

      channelMember.bind_global(channelMemberEventHandler);
      channelAssembly.bind_global(channelAssemblyEventHandler);
    }

    return () => {
      if (data) {
        channelMember.unbind_global(channelMemberEventHandler);
        channelAssembly.unbind_global(channelAssemblyEventHandler);
      }
    };
  }, [data, refetch, options, refreshToDoCount, queryClient]);
};

export default useCommonFeedSocket;
