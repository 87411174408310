import Uppy, { UppyFile } from '@uppy/core';
import AwsS3 from '@uppy/aws-s3';

import { makeAPICallWithDataReturn } from '../../queries/utils';
import { kilobytes } from '../../controllers/flows/FlowsFileUploadInputBlockController/utils';

const useUploadProfilePicUppyInstance = (requestURL: string) => {
  const createUppyInstance = () => {
    return Uppy({
      autoProceed: true,
      restrictions: {
        allowedFileTypes: ['.jpg', '.jpeg', '.png', '.gif'],
        minFileSize: kilobytes(10),
      },
    }).use(AwsS3, {
      getUploadParameters: async (file: UppyFile) => {
        const payload = { fileName: file.name };
        const params = undefined;
        const urlSubstitutions = {};
        const { url: signedUrl } = await makeAPICallWithDataReturn(
          requestURL,
          payload,
          params,
          urlSubstitutions,
        );

        return {
          method: 'PUT',
          url: signedUrl,
          headers: {
            'Content-Type': file.type,
          },
        };
      },
    });
  };

  return { createUppyInstance };
};

export default useUploadProfilePicUppyInstance;
