import styled from 'styled-components';
import ContentEditable from 'react-contenteditable';
import ThemeV2 from '../../../componentsV2/theme';

export const SentenceInput = styled(ContentEditable)<{
  required: boolean;
}>`
  padding: 4px 0;
  border: none;
  font-family: inherit;
  font-size: inherit;
  position: absolute;
  vertical-align: top;
  top: 0;
  left: 0;
  width: 100%;
  background: ${ThemeV2.palette.white};

  &:focus-visible {
    outline: none;
  }

  &[placeholder]:empty:before {
    content: attr(placeholder);
    color: ${ThemeV2.palette.gray6};
  }

  &::after {
    content: '*';
    color: ${(props) =>
      props.required ? ThemeV2.palette.red6 : ThemeV2.palette.white};
  }
`;

export const InputWrapper = styled.label`
  padding: 4px 0;
  display: inline-block;
  position: relative;
  min-width: 2em;
  min-height: 1.4em;
  margin-bottom: 0px;
  width: 100%;
`;

export const WidthControl = styled.span<{
  required: boolean;
}>`
  font-family: inherit;
  font-size: inherit;
  color: ${ThemeV2.palette.gray6};
  word-break: break-word;
  white-space: break-spaces;

  &::after {
    content: '*';
    color: ${(props) =>
      props.required ? ThemeV2.palette.red6 : ThemeV2.palette.white};
  }
`;
