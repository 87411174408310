import React from 'react';
import Loader from '../../../atomic/molecules/PageHeader/Loader';
import Error from '../../../atomic/molecules/PageHeader/Error';
import {
  FLOW_NOT_FOUND,
  FLOW_NOT_FOUND_DESCRIPTION,
  FLOW_REMINDER_PARTICIPANT_MODAL,
  FLOWS_ARCHIVE_MODAL,
  FLOWS_END_OCCURRENCE_MODAL,
  ON_DEMAND,
  NO_TRIGGER,
} from '../../../languages/en/flows';
import { getFlowHeaderOptionItems } from './utils';
import { mapHexCodeToEmoticon } from '../../../Utils/mappers';
import useFlowFeedHeaderControllerLogic from './useFlowFeedHeaderControllerLogic';
import FlowRunNowModal from '../../../atomic/organism/FlowRunNowModal';
import FlowEndOccurrenceModal from '../../../atomic/molecules/FlowEndOccurrenceModal';
import Body from '../../../atomic/atoms/Body';
import BasicModalTemplate from '../../../atomic/templates/BasicModalTemplate';
import { YOU } from '../../../languages/en/home';
import { ARCHIVE, RUNS, WHEN } from '../../../languages/en/singleWords';
import EmoticonWithText from '../../../atomic/atoms/EmoticonWithText';
import FlowsFeedHeader from '../../../atomic/molecules/FlowsFeedHeader';
import { getFlowNameWithEmoticon } from '../../../Utils/flows';
import {
  FlowItemResponse,
  FlowsActivityStates,
} from '../../../queries/Flows/interfaces';
import { getFlowFrequencyText } from '../../../Utils/flows/builder/utils';
import { getColorById } from '../../../Utils/flows/colors';

const getFlowTypeFromFlowDetails = (flowDetails: FlowItemResponse) => {
  if (flowDetails.kind === 'SCHEDULED') {
    const scheduleRule = flowDetails.schedule?.rule;
    const flowFrequencyText = getFlowFrequencyText(scheduleRule);
    return `${RUNS} ${
      flowFrequencyText.charAt(0).toLowerCase() + flowFrequencyText.slice(1)
    }`;
  } else if (flowDetails.kind === 'NO_TRIGGER') {
    return NO_TRIGGER;
  }
  return ON_DEMAND;
};

const FlowFeedHeaderController = () => {
  const { models, operations } = useFlowFeedHeaderControllerLogic();
  const {
    isLoading,
    isError,
    data,
    isTriggerFlowModalOpen,
    dueDateValue,
    occurrenceValue,
    participants,
    flowVisibilityCriteria,
    isTriggerFlowLoading,
    isEndOccurrenceModalOpen,
    isArchiveModalOpen,
    isArchiveFlowLoading,
    isEndOccurrenceModalLoading,
    hasMoreMembers,
    isUsersError,
    isUsersLoading,
    hasNextPage,
    memberSearchValue,
    users,
    isRemindParticipantsModalOpen,
    isRemindParticipantsLoading,
    hasActiveOccurrence,
    pendingMembersParticipantCount,
  } = models;
  const {
    handleMenuOptionOnClick,
    setTriggerFlowModalClose,
    handleDueDateValueChange,
    setOccurrenceValue,
    onRunNowButtonClick,
    setEndOccurrenceModalClose,
    onEndOccurrenceClick,
    handleOnArchiveClick,
    setArchiveModalClose,
    fetchMoreMembers,
    fetchMoreUsers,
    handleMemberSearchChange,
    setRemindParticipantsModalClose,
    onRemindParticipantsClick,
    onMemberClick,
    openRunNowModal,
    openRemindParticipantsModal,
    trackFlowFeedMenuOpened,
    trackViewersHovered,
    setShareSheetModalOpen,
    handleCopyFlowFeedLink,
  } = operations;

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return (
      <Error title={FLOW_NOT_FOUND} subTitle={FLOW_NOT_FOUND_DESCRIPTION} />
    );
  }

  if (data) {
    const {
      icon,
      name,
      description,
      flowId,
      activityState,
      isFlowOwner,
      isFlowParticipant,
      isFlowViewer,
      occurrence,
      visibility,
      participantsCount,
      kind,
      schedule,
      isShortcut,
    } = data;
    const headerOptionItems = getFlowHeaderOptionItems({
      isFlowOwner,
      isFlowParticipant,
      isFlowViewer,
      occurrence,
      isMuted: activityState === FlowsActivityStates.Muted,
    });

    const flowFeedHeaderOptions = [
      {
        id: 'flow-feed-header-options',
        items: headerOptionItems,
      },
    ];

    const userDetails = {
      visibility,
      users,
      hasMoreUsersToFetch: !!hasNextPage,
      memberSearchValue,
      onMemberSearchValueChange: handleMemberSearchChange,
      isUsersError,
      isUsersLoading,
      fetchMoreMembers: fetchMoreUsers,
    };

    const emoticon = mapHexCodeToEmoticon(icon.value);
    const emoticonBg = getColorById(flowId);

    const ArchiveModal = (
      <BasicModalTemplate
        isOpen={isArchiveModalOpen}
        heading={FLOWS_ARCHIVE_MODAL.MODAL_HEADING}
        primaryButton={{
          text: `${FLOWS_ARCHIVE_MODAL.ARCHIVE_LABEL}`,
          onClick: handleOnArchiveClick,
          status: 'warning',
          isLoading: isArchiveFlowLoading,
        }}
        secondaryButton={{
          text: `${FLOWS_ARCHIVE_MODAL.CANCEL_LABEL}`,
          onClick: setArchiveModalClose,
        }}
        onModalClose={setArchiveModalClose}
      >
        <Body variant="body2" inline>
          {`${WHEN} ${YOU.toLocaleLowerCase()} ${ARCHIVE.toLocaleLowerCase()} `}
        </Body>
        <Body variant="body2Medium" inline>
          <EmoticonWithText emoticon={emoticon} text={name} hasBoldText />
        </Body>
        <Body variant="body2" inline>
          {', '}
          {FLOWS_ARCHIVE_MODAL.MODAL_CONTENT_TEXT}
        </Body>
      </BasicModalTemplate>
    );

    const RemindParticipantsModal = (
      <BasicModalTemplate
        isOpen={isRemindParticipantsModalOpen}
        heading={FLOW_REMINDER_PARTICIPANT_MODAL.MODAL_HEADING}
        primaryButton={{
          text: `${FLOW_REMINDER_PARTICIPANT_MODAL.SEND_REMINDER_LABEL}`,
          onClick: onRemindParticipantsClick,
          isLoading: isRemindParticipantsLoading,
        }}
        secondaryButton={{
          text: `${FLOW_REMINDER_PARTICIPANT_MODAL.CANCEL_LABEL}`,
          onClick: setRemindParticipantsModalClose,
        }}
        onModalClose={setRemindParticipantsModalClose}
      >
        <Body variant="body2" inline>
          {FLOW_REMINDER_PARTICIPANT_MODAL.SEND_A_REMINDER}
        </Body>
        {occurrence.activeOccurrence && (
          <Body variant="body2Medium" inline>
            {' '}
            {FLOW_REMINDER_PARTICIPANT_MODAL.get_not_responded_participants_count(
              occurrence.activeOccurrence.participantsNotRespondedCount,
            )}{' '}
          </Body>
        )}
        <Body variant="body2" inline>
          {FLOW_REMINDER_PARTICIPANT_MODAL.THAT_HAVEN_NOT_ANSWERED}
        </Body>
        <Body variant="body2Medium" inline>
          {' '}
          <EmoticonWithText emoticon={emoticon} text={name} hasBoldText />
        </Body>
      </BasicModalTemplate>
    );

    return (
      <>
        <FlowsFeedHeader
          title={name}
          subTitle={getFlowTypeFromFlowDetails(data)}
          description={description}
          icon={mapHexCodeToEmoticon(icon.value)}
          backgroundColor={emoticonBg}
          membersCount={participantsCount}
          menuOptions={flowFeedHeaderOptions}
          onMenuOptionClick={handleMenuOptionOnClick}
          userDetails={userDetails}
          onMemberClick={onMemberClick}
          isFlowViewer={isFlowViewer}
          isFlowOwner={isFlowOwner}
          openRunNowModel={openRunNowModal}
          openRemindParticipantsModal={openRemindParticipantsModal}
          hasActiveOccurrence={hasActiveOccurrence}
          onClick={trackFlowFeedMenuOpened}
          onPopOverOpen={trackViewersHovered}
          schedule={schedule}
          kind={kind}
          isShortcut={isShortcut}
          openShareSheetModal={setShareSheetModalOpen}
          handleCopyFlowFeedLink={handleCopyFlowFeedLink}
        />
        <FlowRunNowModal
          isOpen={isTriggerFlowModalOpen}
          modalHeading={getFlowNameWithEmoticon(data)}
          onModalClose={setTriggerFlowModalClose}
          value={dueDateValue}
          onChange={handleDueDateValueChange}
          participants={participants}
          participantsCount={participantsCount}
          occurrenceValue={occurrenceValue}
          onOccurrenceChange={setOccurrenceValue}
          onRunNowButtonClick={onRunNowButtonClick}
          visibilityCriteria={flowVisibilityCriteria}
          isLoading={isTriggerFlowLoading}
          hasMoreMembers={hasMoreMembers}
          fetchNextPage={fetchMoreMembers}
          pendingMembersParticipantCount={pendingMembersParticipantCount}
        />
        <FlowEndOccurrenceModal
          heading={FLOWS_END_OCCURRENCE_MODAL.modalHeading}
          isOpen={isEndOccurrenceModalOpen}
          onCancelClick={setEndOccurrenceModalClose}
          onEndOccurrenceClick={onEndOccurrenceClick}
          onModalClose={setEndOccurrenceModalClose}
          isLoading={isEndOccurrenceModalLoading}
        />

        {ArchiveModal}
        {RemindParticipantsModal}
      </>
    );
  }

  return null;
};

export default FlowFeedHeaderController;
