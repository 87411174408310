import {
  COPY_LINK,
  GO_TO_POST,
  DOWNLOAD_FILE,
  DELETE_FILE,
} from '../../../languages/en/home';

const copyLinkItem = {
  id: 'copy-link',
  prefixIcon: 'Link',
  isSelected: false,
  value: COPY_LINK,
};

const copyLinkItemNoText = {
  id: 'copy-link',
  prefixIcon: 'Link',
  isSelected: false,
  value: '',
};

const goToPostItem = {
  id: 'go-to-post',
  prefixIcon: 'go-to-post',
  isSelected: false,
  value: GO_TO_POST,
};

const goToPostItemNoText = {
  id: 'go-to-post',
  prefixIcon: 'go-to-post',
  isSelected: false,
  value: '',
};

const downloadFileItem = {
  id: 'download-file',
  prefixIcon: 'download',
  isSelected: false,
  value: DOWNLOAD_FILE,
};

const downloadFileItemNoText = {
  id: 'download-file',
  prefixIcon: 'download',
  isSelected: false,
  value: '',
};

const deleteItem = {
  id: 'delete',
  prefixIcon: 'delete',
  deleteItem: true,
  isSelected: false,
  value: DELETE_FILE,
};

export const flowItemsWithoutDeletePost = [
  {
    id: 'feed-post-header-options',
    items: [copyLinkItem, downloadFileItem],
  },
];

export const menuItemsWithoutDeletePost = [
  {
    id: 'feed-post-header-options',
    items: [copyLinkItem, goToPostItem, downloadFileItem],
  },
];

export const menuItemsWithoutDeletePostNoText = [
  {
    id: 'feed-post-header-options',
    items: [copyLinkItemNoText, goToPostItemNoText, downloadFileItemNoText],
  },
];

export const menuItemsWithDeletePost = [
  {
    id: 'feed-post-header-options',
    items: [copyLinkItem, goToPostItem, downloadFileItem, deleteItem],
  },
];
