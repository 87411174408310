import { trackEvent } from '../index';
import { TemplateGalleryEventsProps } from '../interfaces';
import { AnalyticsEvents, TEMPLATE_GALLERY_EVENT } from '../constants';

const trackTemplateGalleryShowEvent = (
  eventProps: TemplateGalleryEventsProps,
) => {
  trackEvent(TEMPLATE_GALLERY_EVENT, undefined, {
    ...eventProps,
    event: AnalyticsEvents.SHOW,
  });
};

const trackTemplateGalleryActionEvent = (
  eventProps: TemplateGalleryEventsProps,
) => {
  trackEvent(TEMPLATE_GALLERY_EVENT, undefined, {
    ...eventProps,
    event: AnalyticsEvents.ACTION,
  });
};

const trackTemplateGalleryErrorEvent = (
  eventProps: TemplateGalleryEventsProps,
) => {
  trackEvent(TEMPLATE_GALLERY_EVENT, undefined, {
    ...eventProps,
    event: AnalyticsEvents.ERROR,
  });
};

export {
  trackTemplateGalleryShowEvent,
  trackTemplateGalleryErrorEvent,
  trackTemplateGalleryActionEvent,
};
