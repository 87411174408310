import styled from 'styled-components';

import { LayoutStyleProps } from '../../../interfaces/Layout';
import ThemeV2 from '../../../componentsV2/theme';

import {
  device,
  AsideWidthWhenClosed,
  AsideWidthWhenOpen,
} from '../../../constants/layout';

export const StyledAside = styled.aside<LayoutStyleProps>`
  transition: all 0.5s;
  box-sizing: border-box;
  position: fixed;
  top: 72px;
  display: 'block';
  left: ${({ isRightAsideOpen }) => (isRightAsideOpen ? '0' : '100%')};
  width: 100%;
  height: ${({ isKioskTurnedOn }) =>
    isKioskTurnedOn ? 'calc(100vh - 114px)' : 'calc(100vh - 72px)'};
  border-left: 1px solid ${ThemeV2.palette.gray4};
  background-color: ${ThemeV2.palette.gray1};

  @media ${device.tabletV2} {
    width: ${AsideWidthWhenOpen}px;
    left: ${({ isRightAsideOpen, showRightAside }) =>
      isRightAsideOpen || showRightAside ? 'unset' : '100%'};
    right: ${({ isRightAsideOpen }) =>
      isRightAsideOpen
        ? '0'
        : `${-AsideWidthWhenOpen + AsideWidthWhenClosed}px`};
  }
  z-index: 3;
`;

export const AsideInner = styled.div<LayoutStyleProps>`
  position: relative;
  height: 100%;
  overflow-y: scroll;
`;

export const IconButton = styled.button<{
  isActive?: boolean;
  showUnread?: number;
}>`
  background-color: ${({ isActive }) =>
    isActive ? ThemeV2.palette.gray1 : ThemeV2.palette.gray2};
  border: 0;
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  outline: none;
  width: 48px;
  height: 48px;
  box-shadow: ${({ isActive }) =>
    isActive
      ? `0px 2px 4px rgba(0, 0, 0, 0.04), 0px 3px 4px rgba(0, 0, 0, 0.02),
    0px 1px 5px rgba(0, 0, 0, 0.12)`
      : 'none'};
  border-radius: 4px;
  cursor: pointer;
  margin-top: 16px;
  color: ${({ isActive }) =>
    isActive ? ThemeV2.palette.geekBlue6 : ThemeV2.palette.gray7};
  &:hover {
    background-color: ${ThemeV2.palette.gray1};
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), 0px 3px 4px rgba(0, 0, 0, 0.02),
      0px 1px 5px rgba(0, 0, 0, 0.12);
  }
  & > *,
  & > span > svg {
    height: 24px;
    min-width: 24px;
    width: 24px;
  }
  @media ${device.tabletV2} {
    width: 64px;
    height: 64px;
    & > *,
    & > span > svg {
      height: 20px;
      min-width: 20px;
    }
  }
  ${({ showUnread }) =>
    showUnread &&
    `
    &::before {
      content: '';
      position: absolute;
      top: 25%;
      right: 20%;
      width: 12px;
      height: 12px;
      border-radius: 100%;
      background-color: ${ThemeV2.palette.geekBlue6};
      border: 2px solid ${ThemeV2.palette.gray1};
      z-index: 2;
      @media ${device.tabletV2} {
        right: 25%;
      }
    }
`}
`;

export const StyledIconButton = styled(IconButton)<LayoutStyleProps>`
  position: fixed;
  transition: right 0.5s;
  display: ${({ showRightAside }) => (!showRightAside ? 'none' : 'flex')};
  z-index: 99;
  bottom: ${({ isKioskTurnedOn }) => (isKioskTurnedOn ? '64px' : '32px')};
  right: ${({ isRightAsideOpen, isHovered }) =>
    isRightAsideOpen || isHovered ? `307px` : `10px`};
  width: 24px;
  height: 24px;
  border-radius: 50%;

  &:after {
    position: absolute;
    content: '';
    width: 48px;
    height: 48px;
  }
  &:hover {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), 0px 4px 5px rgba(0, 0, 0, 0.02),
      0px 1px 10px rgba(0, 0, 0, 0.12);
  }
  @media ${device.mobile} {
    display: none;
  }
`;

/*
transition-duration: ${({ isRightAsideOpen }) =>
  isRightAsideOpen ? `400ms` : `400ms`};
transition-timing-function: ease;
transition-property: right;
*/
