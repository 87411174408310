// @ts-nocheck TODO: type issues need to be fixed in this file
import React from 'react';
import {
  string,
  arrayOf,
  shape,
  object,
  number,
  oneOfType,
  node,
} from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

import Image from '../../atoms/Image';
import Body from '../../atoms/Body';
import ProgressBar from '../../atoms/ProgressBar';
import SVGIcon from '../../atoms/SVGIcon';
import { buttonShape } from '../../organism/StepsWizard';
import ThemeV2 from '../../../componentsV2/theme';
import IdentityManagerFlowTemplate from '../../templates/IdentityManagerFlowTemplate';

const loadingGif = 'https://media.giphy.com/media/VXypZf1a1jpUk/giphy.gif';

const useStyles = makeStyles({
  heading: {
    marginBottom: '4px',
  },
  loadingBlock: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginTop: '16px',
  },
  loadingImage: {
    borderRadius: '6px',
    maxWidth: '245px',
  },
  successTextStyle: {
    display: 'flex',
    alignItems: 'center',
    color: ThemeV2.palette.green7,
  },
  successCheckIcon: {
    display: 'flex',
    marginRight: '6px',
    '& svg > path:last-of-type': {
      fill: ThemeV2.palette.green7,
    },
  },
});

const StyledProgressBar = styled(ProgressBar)`
  margin: 16px 0;
  width: 200px;
`;

const IdentityManagerCreateAccounts = (props) => {
  const classes = useStyles(props);
  const {
    title,
    steps,
    activeStep,
    leftButton,
    nextButton,
    progressValue,
    infoHeading,
    infoSubheading,
    helperLink,
  } = props;
  const cancelButton = { hidden: true };
  const stepsWizard = {
    title,
    steps,
    activeStep,
    nextButton,
    cancelButton,
    leftButton,
    helperLink,
  };
  return (
    <IdentityManagerFlowTemplate stepsWizard={stepsWizard}>
      <div className={classes.root}>
        <Body variant="body1Bold" className={classes.heading}>
          {infoHeading}
        </Body>
        <Body variant="body2">{infoSubheading}</Body>
        <div className={classes.loadingBlock}>
          <Image
            src={loadingGif}
            alt="Loading gif"
            classes={{
              root: classes.loadingImage,
            }}
          />
          <StyledProgressBar color="success" value={progressValue} />
          {progressValue === 100 ? (
            <Body
              variant="body2"
              className={progressValue === 100 && classes.successTextStyle}
            >
              <div className={classes.successCheckIcon}>
                <SVGIcon icon="check" size="14px" />
              </div>
              Complete and invites ready
            </Body>
          ) : (
            <Body variant="body2">Creating your team’s accounts</Body>
          )}
        </div>
      </div>
    </IdentityManagerFlowTemplate>
  );
};

IdentityManagerCreateAccounts.propTypes = {
  title: string.isRequired,
  activeStep: string.isRequired,
  steps: arrayOf(
    shape({
      id: string.isRequired,
      title: string.isRequired,
      leftButton: object,
      nextButton: object,
    }),
  ).isRequired,
  nextButton: buttonShape.isRequired,
  leftButton: buttonShape.isRequired,
  progressValue: number.isRequired,
  infoHeading: string.isRequired,
  infoSubheading: oneOfType([string, node]).isRequired,
  helperLink: string,
};

IdentityManagerCreateAccounts.defaultProps = {
  helperLink: '',
};

export default IdentityManagerCreateAccounts;
