import React, { useEffect, useState } from 'react';
import Error from './error';
import Loading from './loading';

import CsvViewer from './formats/csvViewer';
import XlsxViewer from './formats/xlsxViewer';

const createRequest = (
  filePath: string | URL,
  responseType: XMLHttpRequestResponseType,
  setData: (arg0: string) => void,
  setError: (arg0: { error: string }) => void,
) => {
  let xhr = new XMLHttpRequest();

  if ('withCredentials' in xhr) {
    // XHR for Chrome/Firefox/Opera/Safari.
    xhr.open('GET', filePath, true);
    /* @ts-ignore-next-line */
  } else if (typeof XDomainRequest !== 'undefined') {
    // XDomainRequest for IE.
    /* @ts-ignore-next-line */
    xhr = new XDomainRequest();
    xhr.open('GET', filePath);
  } else {
    // CORS not supported.
    return null;
  }
  if (responseType) {
    xhr.responseType = responseType;
  }

  xhr.onload = () => {
    if (xhr.status >= 400) {
      setError({ error: `fetch error with status ${xhr.status}` });
      return;
    }
    const resp = responseType ? xhr.response : xhr.responseText;

    setData(resp);
  };

  return xhr;
};

const xhrFetch = (xhr: XMLHttpRequest | null) => {
  if (xhr) {
    xhr.send();
  }
};

/*
const xhrAbort = (xhr) => {
  if (xhr) {
    xhr.abort();
  }
};
*/

const TableWrapper = (props: {
  filePath: string;
  fileType: string;
  onError?: (e: Error) => void;
}) => {
  const { filePath, fileType, onError } = props;
  const [responseType] = useState<XMLHttpRequestResponseType>(
    fileType === 'csv' ? '' : 'arraybuffer',
  );
  const [data, setData] = useState<string>();
  const [error, setError] = useState<{ error: string }>();
  const [xhr] = useState(
    createRequest(filePath, responseType, setData, setError),
  );

  useEffect(() => {
    try {
      xhrFetch(xhr);
    } catch (e: any) {
      if (onError) {
        onError(e);
      }
      setError({ error: 'fetch error' });
    }
  }, [onError]);

  return (
    <>
      {!xhr && <h1>CORS not supported..</h1>}
      {error && <Error {...props} error={error} />}
      {data && data !== undefined ? (
        <>
          {fileType === 'csv' ? (
            <CsvViewer data={data} {...props} />
          ) : (
            <XlsxViewer data={data} {...props} />
          )}
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default TableWrapper;
