export const MODAL_USER_SELECT_HEADING = 'Select who you’re celebrating';
export const TEXT_INPUT_PLACEHOLDER = 'Search name, @username, or email';
export const REMOVE_SELECTED_PROMPT =
  'Are you sure you want to deselect these?';
export const HEADING_SELECTED = 'Selected • ';
export const HEADING_EVERYONE = 'Everyone';
export const HEADING_SEARCH = 'Search results • ';
export const EMPTY_SEARCH_HEADING =
  'Whoops! We found no results for that search';
export const EMPTY_SEARCH_SUBHEADING = 'You may want to check for typos.';

export const NO_MEMBERS_HEADER =
  'Invite some team members to start celebrating';
export const NO_MEMBERS_CONTENT =
  'You are currently the only member of your Assembly. In order to give recognition, you’ll have to invite others.';
export const INVITE_MEMBERS = 'Invite team members';
