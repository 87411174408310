import produce from 'immer';
import { uniq } from 'lodash';
import { NotebookTabs } from '../../controllers/notebook/NotebookPageController/useNotebookPageController/types';
import {
  extractAssignedToFromAPIResponse,
  getTaskCategoryBasedOnDueDate,
} from '../../controllers/notebook/NotebookPageController/utils';
import {
  AssigneeTaskIndicators,
  MiscTaskCategories,
  NotebookOperations,
  NotebookTask,
  NotebookTransaction,
  TaskCategories,
  TaskFromAPI,
  TaskPayload,
  TaskStateInAPI,
  TransactionData,
} from '../../interfaces/notebook';
import {
  TiptapEditorContent,
  TiptapContentGroup,
  TiptapInnerContent,
  TaskContentFromAPI,
} from '../../interfaces/notebook/editor';
import { ASSIGNED_BY_ME, MY_WORK } from '../../languages/en/notebook';
import { NotebookTaskAnalyticsBaseProps } from '../analytics/interfaces';

export const getTabCategoryForTask = (
  task: NotebookTask,
  currentMemberId: string,
) =>
  task.assignedTo?.memberID === currentMemberId
    ? NotebookTabs.myWork
    : NotebookTabs.ato;

export const createNewTask = (): TiptapEditorContent => ({
  type: 'doc',
  content: [
    {
      type: 'paragraph',
      content: [{ type: 'text', text: '' }],
    },
  ],
});

const NotebookAnalyticsTabs = {
  [NotebookTabs.ato]: ASSIGNED_BY_ME,
  [NotebookTabs.myWork]: MY_WORK,
};

export const deserializeTaskFromAPI = (
  taskContent: TaskContentFromAPI[],
): TiptapContentGroup[] => {
  const content: TiptapContentGroup[] = [
    {
      type: 'paragraph',
      content: taskContent.map((task): TiptapInnerContent => {
        switch (task.type) {
          case 'text': {
            if (task.props?.link) {
              return { type: 'link', text: task.value };
            }
            return { type: 'text', text: task.value };
          }
          case 'break': {
            return { type: 'hardBreak' };
          }
          default: {
            return { type: 'text' };
          }
        }
      }),
    },
  ];
  return content;
};

export const serializeContentToTaskAPI = (content: TiptapContentGroup[]) => {
  const editorContent = content[0].content;
  if (editorContent) {
    return editorContent.map((subContent): TaskContentFromAPI => {
      switch (subContent.type) {
        case 'hardBreak': {
          return { type: 'break' };
        }
        case 'text': {
          return { type: 'text', value: subContent.text || '' };
        }
        case 'link': {
          return {
            type: 'text',
            value: subContent.text || '',
            props: { link: subContent.text || '' },
          };
        }
        default: {
          return { type: 'text', value: '' };
        }
      }
    });
  }
  return [];
};

export const isNotebookEditorEmpty = (content: TaskContentFromAPI[]) => {
  if (content.length === 0) {
    return true;
  }
  if (
    content.filter((i) => !(i.type === 'break' || i.value === '')).length === 0
  ) {
    return true;
  }
  return false;
};

export const isTiptapEditorEmpty = (content?: TiptapContentGroup[]) => {
  const textContent = content ? content[0]?.content : undefined;
  if (textContent && textContent[0].text) {
    return false;
  }
  return true;
};

export const getTaskState = (
  taskCategory: TaskCategories | MiscTaskCategories,
): TaskStateInAPI => {
  switch (taskCategory) {
    case TaskCategories.ARCHIVED: {
      return 'ARCHIVED';
    }
    case TaskCategories.COMPLETED: {
      return 'COMPLETED';
    }
    case TaskCategories.OVERDUE:
    case TaskCategories.TODAY:
    case TaskCategories.UNSCHEDULED:
    case TaskCategories.UPCOMING: {
      return 'ACTIVE';
    }
    default: {
      return 'DELETED';
    }
  }
};

export const getTaskCategoryFromAPI = (
  state: TaskStateInAPI,
  timezone: string,
  dueDate?: string,
): TaskCategories => {
  if (state === 'COMPLETED') {
    return TaskCategories.COMPLETED;
  }
  if (state === 'ARCHIVED') {
    return TaskCategories.ARCHIVED;
  }

  return getTaskCategoryBasedOnDueDate(dueDate, timezone || '');
};

export const convertTaskToAPITask = (
  { dueDate, taskId, note, noteId, assignedTo, description = [] }: NotebookTask,
  taskCategory: TaskCategories,
  beforeTaskId?: string,
  afterTaskId?: string,
  isCreateMode = false,
): TaskPayload => {
  // If noteId is only numeric it is generated on FE and the task has not been saved in backend successfully
  const isNoteIdNumeric = noteId.match(/^[0-9]+$/);
  const dateParsed = dueDate ? new Date(Date.parse(dueDate)) : undefined;
  const isDateISOString = dateParsed
    ? dateParsed.toISOString() == dueDate
    : false;

  return {
    dueDate:
      dateParsed && !isDateISOString ? dateParsed.toISOString() : dueDate,
    description,
    transactionId: taskId,
    title: note,
    state: getTaskState(taskCategory),
    afterTransactionId: afterTaskId,
    beforeTransactionId: beforeTaskId,
    assignedTo: assignedTo !== null ? assignedTo?.memberID : null,
    ...(!isCreateMode && !isNoteIdNumeric && { noteId }),
  };
};

export const convertAPITaskToTaskAndCategory = (
  {
    createdAt,
    transactionId,
    dueDate,
    title,
    description = [],
    state,
    id,
    stateEffectiveAt,
    assignedTo,
    createdBy,
    assigneeTaskIndicator = [],
  }: TaskFromAPI,
  timezone: string,
): [NotebookTask, TaskCategories] => {
  const type = getTaskCategoryFromAPI(state, timezone, dueDate);
  const assignedToInfo = extractAssignedToFromAPIResponse(assignedTo);
  return [
    {
      noteId: id,
      createdAt,
      taskId: transactionId,
      dueDate,
      note: title,
      description,
      stateEffectiveAt,
      type,
      isDeleted: state === 'DELETED',
      assignedTo: assignedToInfo,
      createdBy,
      isUnread: assigneeTaskIndicator.includes(AssigneeTaskIndicators.UNREAD),
    },
    type,
  ];
};

export const getTransactionFromTask = (
  task: NotebookTask,
  taskCategory: TaskCategories,
  beforeTaskId?: string, // taskId of the task present before the current task
  afterTaskId?: string, // taskId of the task present after the current task,
  previousTaskCategory?: TaskCategories,
  // send afterTaskId param to append to the top of the section
  isCreateMode = false,
  operation?: NotebookOperations,
): NotebookTransaction => ({
  taskId: task.taskId,
  taskData: convertTaskToAPITask(
    task,
    taskCategory,
    beforeTaskId,
    afterTaskId,
    isCreateMode,
  ),
  previousTaskCategory,
  operation: operation || NotebookOperations.UPDATE,
});

export const addTransactionToQueue = (
  currentTransaction: NotebookTransaction,
  transactionData: TransactionData,
): TransactionData =>
  produce(transactionData, (draft) => {
    const { transactionQueue } = draft;
    const currentTaskInQueueIndex = transactionQueue.findIndex(
      (transaction) => currentTransaction.taskId === transaction.taskId,
    );
    if (currentTaskInQueueIndex > -1) {
      transactionQueue.splice(currentTaskInQueueIndex, 1);
    }
    transactionQueue.push(currentTransaction);
  });

export const getModifiedCategories = (
  transactionQueue: NotebookTransaction[],
  timezone: string,
) => {
  const allModifiedCategories: TaskCategories[] = [];
  transactionQueue.forEach((item) => {
    const { previousTaskCategory, taskData } = item;
    if (previousTaskCategory) {
      allModifiedCategories.push(previousTaskCategory);
    }
    allModifiedCategories.push(
      getTaskCategoryFromAPI(taskData.state, timezone, taskData.dueDate),
    );
  });
  return uniq(allModifiedCategories);
};

export const convertStringToTokenizedObject = (content: string) => {
  return content.split(' ').reduce<TaskContentFromAPI[]>((acc, str) => {
    const { length } = acc;
    const lastItem = acc[length - 1];
    try {
      const { href, protocol } = new URL(str);
      if (!(protocol === 'http:' || protocol === 'https:')) {
        throw new Error();
      }
      if (length && lastItem.type === 'text') {
        lastItem.value += ' ';
      }
      return [...acc, { value: href, type: 'text', props: { link: href } }];
    } catch {
      if (length) {
        if (lastItem.type === 'text' && !lastItem.props) {
          lastItem.value += ` ${str}`;
          return acc;
        }
        return [...acc, { value: ` ${str}`, type: 'text' }];
      }
      return [...acc, { value: str, type: 'text' }];
    }
  }, []);
};

export const convertTokenizedObjectToString = (content: TaskContentFromAPI[]) =>
  content.reduce((acc, obj) => {
    if (obj.type === 'text') {
      return acc + obj.value || '';
    }
    return `${acc}\n`;
  }, '');

export const getLinksFromTokenizedObject = (content: TaskContentFromAPI[]) =>
  content.reduce<string[]>((acc, obj) => {
    if (obj.type === 'text' && obj?.props?.link) {
      return [...acc, obj?.props?.link];
    }
    return acc;
  }, []);

export const getNotebookTaskAnalyticsProps = (
  originalTask?: NotebookTask,
  originalCategory?: TaskCategories | MiscTaskCategories,
  updatedTask?: NotebookTask,
  updatedCategory?: TaskCategories | MiscTaskCategories,
  tab?: NotebookTabs,
) => {
  const titleLinkURL = updatedTask
    ? getLinksFromTokenizedObject(updatedTask?.note)
    : getLinksFromTokenizedObject(originalTask?.note || []);

  const taskProps: NotebookTaskAnalyticsBaseProps = {
    taskId: `${updatedTask ? updatedTask?.taskId : originalTask?.taskId}`,
    dueDate: updatedTask ? updatedTask?.dueDate : originalTask?.dueDate,
    creationDate: updatedTask
      ? updatedTask?.createdAt
      : originalTask?.createdAt,
    source: 'notebook',
    subSource: (updatedCategory ||
      originalCategory ||
      TaskCategories.UNSCHEDULED) as TaskCategories,
    type: 'task',
    title: updatedTask
      ? convertTokenizedObjectToString(updatedTask?.note)
      : convertTokenizedObjectToString(originalTask?.note || []),
    status: getTaskState(
      updatedCategory || originalCategory || TaskCategories.UNSCHEDULED,
    ),
    lastTitle: convertTokenizedObjectToString(originalTask?.note || []),
    lastDueDate: originalTask?.dueDate,
    lastStatus: getTaskState(originalCategory || TaskCategories.UNSCHEDULED),
    titleLinkURL,
    assigneeId: updatedTask
      ? updatedTask?.assignedTo?.memberID
      : originalTask?.assignedTo?.memberID,
    lastAssigneeId: originalTask?.assignedTo?.memberID,
    tab: tab && NotebookAnalyticsTabs[tab],
  };
  return taskProps;
};

export const getNotebookAnalyticsProps = (
  section?: TaskCategories,
  lastSection?: TaskCategories,
  taskIDs?: string[],
  tabType?: NotebookTabs,
) => {
  return {
    section,
    lastSection,
    [`${section}Tasks`]: taskIDs,
    type: 'task',
    tab: tabType && NotebookAnalyticsTabs[tabType],
  };
};
