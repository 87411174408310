import React from 'react';

import {
  StyledWrapper,
  StyledSpacing,
  StyledUserListModal,
  StyledDownloadButton,
  StyledDescription,
  StyledClickable,
  StyledAvatar,
  StyledBody,
  LinkBody,
} from './styles';
import Body from '../../../atoms/Body';
import Avatar from '../../../atoms/Avatar';
import { Flex } from '../../../../Utils/styles/display';

import {
  FLOW_ID,
  RESPONSE_VISIBILITY,
  DOWNLOAD_ALL_RESPONSES,
  ONLY_OWNER,
  SEARCH_PLACEHOLDER_TEXT,
  LINK_ACCESS_SETTINGS_LABEL,
} from '../../../../languages/en/flows';
import {
  OWNER,
  CUSTOM,
  DESCRIPTION,
  ABOUT,
  FLOW,
  ANSWER,
  ANYTIME,
  SCHEDULE,
} from '../../../../languages/en/singleWords';
import { ENTIRE_ORGANIZATION } from '../../../../languages/en';

import { capitalizeFirstLetter } from '../../../../Utils/text';
import { FlowDetailsLoadedProps } from './types';
import DrawerHeaderWithIcon from '../../../molecules/DrawerHeaderWithIcon';
import UserListDropdownWithPagination from '../../../molecules/UserListDropdownWithPagination';
import {
  ANYONE_WITH_LINK,
  CAN_RESPOND,
  CAN_RESPOND_AND_VIEW,
  CAN_VIEW,
  EVERYONE_IN_THIS_WORKSPACE_LABEL,
  SPECIFIC_PEOPLE_OR_GROUPS,
} from '../../../../languages/en/flows/sharesheet';
import {
  AccessRole,
  LinkAccessType,
} from '../../../../queries/Flows/interfaces';
import { getIconSettingsForLinkAccessType } from './utils';
import { BUILDER_VISIBILITY_BLOCK } from '../../../../languages/en/flows/builder';

const LoadedComponent = (props: FlowDetailsLoadedProps) => {
  const {
    kind,
    isShortcut,
    flowOwnerProps,
    flowDescription,
    flowId,
    visibility,
    visibilityCount,
    userDetails,
    linkAccess,
    onPopOverOpen,
    onMemberClick,
    toggleRightAsideOpen,
    viewersModalProps,
    onDownloadClick,
    flowFrequencyText,
    onAnswerAnytimeClick,
    showDownloadOption = false,
    disableDownloadOption = false,
  } = props;

  const renderVisibilityText = () => {
    if (visibility === 'custom') {
      return `${capitalizeFirstLetter(CUSTOM)} (${visibilityCount})`;
    }
    if (visibility === 'everyone') {
      return ENTIRE_ORGANIZATION;
    }
    if (visibility === 'onlyParticipants') {
      return BUILDER_VISIBILITY_BLOCK.PARTICIPANTS_ONLY;
    }
    return ONLY_OWNER;
  };

  return (
    <>
      {showDownloadOption && (
        <StyledDownloadButton
          color="secondary"
          onClick={onDownloadClick}
          icon="download"
          disabled={disableDownloadOption}
          isFullWidth
        >
          {DOWNLOAD_ALL_RESPONSES}
        </StyledDownloadButton>
      )}
      <DrawerHeaderWithIcon icon="info" onClick={toggleRightAsideOpen}>
        {ABOUT}{' '}
      </DrawerHeaderWithIcon>
      <StyledWrapper>
        <Body variant="body2" color="gray7">
          {capitalizeFirstLetter(OWNER)}
        </Body>
        <StyledSpacing margin="0 0 8px" />
        <Flex>
          <Avatar {...flowOwnerProps} size="24px" />
          <StyledSpacing margin="0 4px 0" />
          <Body color="gray9">{flowOwnerProps.name} </Body>
        </Flex>
        <StyledSpacing margin="0 0 24px" />
        {flowDescription !== '' && (
          <>
            <Body variant="body2" color="gray7">
              {DESCRIPTION}
            </Body>
            <StyledSpacing margin="0 0 8px" />
            <StyledDescription>{flowDescription}</StyledDescription>
          </>
        )}
        <StyledSpacing margin="0 0 24px" />
        {kind === 'SCHEDULED' && flowFrequencyText && (
          <>
            <Body variant="body2" color="gray7">
              {`${capitalizeFirstLetter(FLOW)} ${SCHEDULE}`}
            </Body>
            <StyledSpacing margin="0 0 8px" />
            <StyledDescription>{flowFrequencyText}</StyledDescription>
            {isShortcut && (
              <StyledClickable
                onClickCallback={() => {
                  if (onAnswerAnytimeClick) {
                    onAnswerAnytimeClick();
                  }
                }}
              >
                {`${ANSWER} ${ANYTIME}`}
              </StyledClickable>
            )}
          </>
        )}
        {linkAccess && (
          <>
            <StyledSpacing margin="0 0 24px" />
            <Body variant="body2" color="gray7">
              {LINK_ACCESS_SETTINGS_LABEL}
            </Body>
            <StyledSpacing margin="0 0 8px" />
            <Flex>
              <StyledAvatar
                size="24px"
                icon={getIconSettingsForLinkAccessType(linkAccess.type).icon}
                iconBgColor={
                  getIconSettingsForLinkAccessType(linkAccess.type).bgColor
                }
                iconColor={
                  getIconSettingsForLinkAccessType(linkAccess.type).iconColor
                }
              />
              <Body>
                {linkAccess.type === LinkAccessType.INTERNAL &&
                  EVERYONE_IN_THIS_WORKSPACE_LABEL}
                {linkAccess.type === LinkAccessType.EXTERNAL &&
                  ANYONE_WITH_LINK}
                {linkAccess.type === LinkAccessType.RESTRICTED &&
                  SPECIFIC_PEOPLE_OR_GROUPS}
              </Body>
            </Flex>
            {linkAccess.type === LinkAccessType.INTERNAL && (
              <StyledBody variant="body2" color="gray7">
                {linkAccess.roles.length === 1 &&
                  linkAccess.roles.includes(AccessRole.PARTICIPANT) &&
                  CAN_RESPOND}
                {linkAccess.roles.length === 1 &&
                  linkAccess.roles.includes(AccessRole.VIEWER) &&
                  CAN_VIEW}
                {linkAccess.roles.length === 2 &&
                  linkAccess.roles.includes(AccessRole.VIEWER) &&
                  CAN_RESPOND_AND_VIEW}
              </StyledBody>
            )}
          </>
        )}
        <StyledSpacing margin="0 0 24px" />
        <Body variant="body2" color="gray7">
          {RESPONSE_VISIBILITY}
        </Body>
        <StyledSpacing margin="0 0 8px" />
        <Flex>
          {visibilityCount && userDetails && (
            <>
              <UserListDropdownWithPagination
                users={userDetails.users}
                isError={userDetails.isUsersError}
                placeholder={SEARCH_PLACEHOLDER_TEXT}
                canPopoverTriggerByClick={false}
                fetchMoreMembers={userDetails.fetchMoreMembers}
                hasMoreUsersToFetch={userDetails.hasMoreUsersToFetch}
                memberSearchValue={userDetails.memberSearchValue}
                visibility={visibility}
                isLoading={userDetails.isUsersLoading}
                viewCount={visibilityCount}
                onMemberSearchValueChange={
                  userDetails.onMemberSearchValueChange
                }
                onMemberClick={onMemberClick}
                onPopOverOpen={onPopOverOpen}
                position={false}
              >
                <Flex>
                  <LinkBody variant="body1" color="geekBlue6">
                    {renderVisibilityText()}
                  </LinkBody>
                </Flex>
              </UserListDropdownWithPagination>
            </>
          )}
        </Flex>
        <StyledSpacing margin="0 0 24px" />
        <Body variant="body2" color="gray7">
          {FLOW_ID}
        </Body>
        <StyledSpacing margin="0 0 8px" />
        <Body>{flowId}</Body>
      </StyledWrapper>
      {visibility === 'custom' && viewersModalProps && (
        <StyledUserListModal {...viewersModalProps} />
      )}
    </>
  );
};

export default LoadedComponent;
