import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import IdentityManagerCreateAccounts from '../../atomic/pages/IdentityManagerCreateAccounts';
import configureAssemblyStatus from '../../Utils/identity/configureAssemblyStatus';
import { buttonText } from '../../Utils/data/identityManager/common';
import { IdentitySSO } from '../../Utils/identity/allowedSSOs';
import useFetch from '../../hooks/useFetch';
import { GET_JOB_STATUS } from '../../hooks/useFetch/endpoints';
import {
  ApiResponseSuccess,
  ApiResponseError,
  IdentityCreateAccountsPageContainerProps,
} from '../../interfaces/identityManager/common';
import BotDisconnectModalContainer from '../BotDisconnectModalContainer';
import routesList from '../../aV2/routes/routesList';
import { useFeatureSplit } from '../../hooks/useFeatureSplit';
import {
  SplitNames,
  TreatmentTypes,
} from '../../hooks/useSplitSdkConfig/constants';

const IdentityCreateAccountsPageContainer = ({
  identityManager,
  result,
  configureJobId,
  onViewQueuedPeopleClick,
  onViewPeopleClick,
  title,
  steps,
  activeStep,
  createAccountsHeading,
  createAccountsSubHeading,
  createAccountsSubHeadingQueued,
  onCreateAccountsSuccess,
  onCreateAccountsFailure,
  intervalTimeOut = 3000,
  helperLink,
}: IdentityCreateAccountsPageContainerProps) => {
  const { id } = identityManager;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  /* @ts-ignore */
  const { displayName: identityManagerDisplayName } = IdentitySSO[id];
  const { push } = useHistory();
  const { treatment: adminRefactorTreatment } = useFeatureSplit(
    SplitNames.ADMIN_NEW_APP,
  );

  const isAdminTreatmentOn = adminRefactorTreatment === TreatmentTypes.ON;

  const [configJobStatus, setConfigJobStatus] = useState(null);
  const [configureIntervalId, setConfigureIntervalId] = useState<number>(0);
  const [isDisconnectModalOpen, setIsDisconnectModalOpen] =
    useState<boolean>(false);
  const toggleDisconnectModal = useCallback(() => {
    setIsDisconnectModalOpen(!isDisconnectModalOpen);
  }, [isDisconnectModalOpen]);

  const handleError = useCallback(
    (error: ApiResponseError) => {
      clearInterval(configureIntervalId);
      onCreateAccountsFailure(error);
    },
    [configureIntervalId, onCreateAccountsFailure],
  );

  const onJobStatusSuccess = ({ data }: ApiResponseSuccess) => {
    if (data.data && data.data.status) {
      const { status: jobStatus } = data.data;
      setConfigJobStatus(jobStatus);
      if (
        jobStatus === configureAssemblyStatus.SUCCESS ||
        jobStatus === configureAssemblyStatus.ERROR
      ) {
        clearInterval(configureIntervalId);
      }
    }
  };

  const { makeRequest: getJobStatus } = useFetch({
    apiEndpoint: GET_JOB_STATUS,
    onSuccess: onJobStatusSuccess,
    onFailure: handleError,
  });

  useEffect(() => {
    if (configJobStatus === configureAssemblyStatus.ERROR) {
      handleError({ message: '', status: 500 });
    } else if (configJobStatus === configureAssemblyStatus.SUCCESS) {
      onCreateAccountsSuccess();
    }
  }, [configJobStatus, handleError, onCreateAccountsSuccess]);

  // Component did Mount
  useEffect(() => {
    const interval = window.setInterval(() => {
      getJobStatus({ substitutions: { jobId: configureJobId } });
    }, intervalTimeOut);
    setConfigureIntervalId(interval);
  }, [configureJobId, getJobStatus, intervalTimeOut]);

  // Component will UnMount
  // Clean up any running setIntervals
  useEffect(() => {
    return () => {
      window.clearInterval(configureIntervalId);
    };
  }, [configureIntervalId]);

  const stepDisconnectButton = {
    hidden: false,
    disabled:
      configJobStatus !== configureAssemblyStatus.SUCCESS &&
      configJobStatus !== configureAssemblyStatus.ERROR,
    text: buttonText.STEP_CREATE_ACCOUNTS_BACK.replace(
      ':IMDisplayName',
      identityManagerDisplayName,
    ),
    variant: 'text',
    status: 'warning',
    icon: 'api-filled',
    onClick: toggleDisconnectModal,
  };
  let selectCreateAccountsNextButtonText =
    buttonText.STEP_CREATE_ACCOUNTS_NEXT_DEFAULT;
  let selectCreateAccountSubheading: string | React.ReactNode =
    createAccountsSubHeading;
  if (!result.autoSendInvites) {
    selectCreateAccountsNextButtonText =
      buttonText.STEP_CREATE_ACCOUNTS_NEXT_QUEUED_INVITES;
    selectCreateAccountSubheading = createAccountsSubHeadingQueued;
  }
  const stepViewPeopleClick = {
    hidden: false,
    disabled:
      configJobStatus !== configureAssemblyStatus.SUCCESS &&
      configJobStatus !== configureAssemblyStatus.ERROR,
    text: selectCreateAccountsNextButtonText,
    onClick: !result.autoSendInvites
      ? onViewQueuedPeopleClick
      : onViewPeopleClick,
  };

  let configProgressValue = 10;
  if (configJobStatus === configureAssemblyStatus.QUEUED) {
    configProgressValue = 40;
  } else if (configJobStatus === configureAssemblyStatus.PROCESSING) {
    configProgressValue = 60;
  } else if (
    configJobStatus === configureAssemblyStatus.SUCCESS ||
    configJobStatus === configureAssemblyStatus.ERROR
  ) {
    configProgressValue = 100;
  }

  const handleBotDisconnectSuccess = useCallback(() => {
    toggleDisconnectModal();
    if (isAdminTreatmentOn) {
      window.location.href = `${routesList.ADMIN_USER_MANAGE_LEGACY}`;
    } else {
      push(routesList.ADMIN_USER_MANAGE_LEGACY);
    }
  }, [isAdminTreatmentOn, push, toggleDisconnectModal]);

  return (
    <>
      <IdentityManagerCreateAccounts
        title={title}
        steps={steps}
        activeStep={activeStep}
        leftButton={stepDisconnectButton}
        nextButton={stepViewPeopleClick}
        progressValue={configProgressValue}
        infoHeading={createAccountsHeading}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        /* @ts-ignore */
        infoSubheading={selectCreateAccountSubheading}
        helperLink={helperLink}
      />
      {isDisconnectModalOpen && (
        <BotDisconnectModalContainer
          ssoId={id}
          onModalClose={toggleDisconnectModal}
          onBotDisconnectSuccess={handleBotDisconnectSuccess}
          disconnectIM
        />
      )}
    </>
  );
};

export default IdentityCreateAccountsPageContainer;
