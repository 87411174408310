import styled from 'styled-components';
import IconButton from '../../atoms/IconButton_V2';

export const LeftArrowButton = styled(IconButton)<{ isHidden: boolean }>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -16px;
  display: ${({ isHidden }) => (isHidden ? 'none' : 'flex')};
`;

export const RightArrowButton = styled(IconButton)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -16px;
`;

export const CarouselContainer = styled.div<{
  isGifsLoading: boolean;
  isDisabled: boolean;
}>`
  margin-top: 8px;
  position: relative;
  .giphy-carousel {
    scroll-behavior: smooth;
    height: ${({ isGifsLoading }) => (isGifsLoading ? '0px' : '100%')};
    pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};
  }
`;

export const NoGifsFoundContainer = styled.div`
  height: 206px;
  width: 100%:
`;
