import React from 'react';
import GeneralMenuAccordion from '../../../atomic/organism/MenuAccordion/GeneralMenuAccordion';
import useLeftDrawerGeneralListController from './useLeftDrawerGeneralListController';

const LeftDrawerGeneralListController = ({
  isLeftAsideOpen,
  isLeftNavHovered,
}: {
  isLeftAsideOpen: boolean;
  isLeftNavHovered: boolean;
}) => {
  const {
    model: { heading, isExpanded, generalListItems },
    operations: { handleExpand, handleOnItemClick },
  } = useLeftDrawerGeneralListController();

  return (
    <GeneralMenuAccordion
      isAccordionOpen={isExpanded}
      onAccordionClick={handleExpand}
      onItemClick={(e) => {
        handleOnItemClick(e as React.MouseEvent<HTMLButtonElement, MouseEvent>);
      }}
      accordionTitle={heading}
      lists={generalListItems}
      isHovered={isLeftNavHovered || isLeftAsideOpen}
    />
  );
};

export default LeftDrawerGeneralListController;
