import React, { ReactNode } from 'react';

import Body from '../../atoms/Body';
import SVGIcon from '../../atoms/SVGIcon';
import ThemeV2 from '../../../componentsV2/theme';
import { ChildrenWrapper, StyledBody, TopWrapper, Wrapper } from './styles';

export interface ProfileDetailsEntryProps {
  children: ReactNode;
  icon: string;
  title: string;
  subtitle?: string;
}

const ProfileDetailsEntry = ({
  children,
  icon,
  title,
  subtitle,
}: ProfileDetailsEntryProps) => {
  return (
    <Wrapper>
      <TopWrapper>
        <SVGIcon color={ThemeV2.palette.gray9} icon={icon} size="16px" />
        <StyledBody variant="body3Medium">{title}</StyledBody>
        {subtitle?.length && (
          <>
            <SVGIcon icon="dot" size="16px" />
            <Body variant="body3Medium">{subtitle}</Body>
          </>
        )}
      </TopWrapper>
      <ChildrenWrapper>{children}</ChildrenWrapper>
    </Wrapper>
  );
};

export default ProfileDetailsEntry;
