import styled from 'styled-components';
import Popover from '@material-ui/core/Popover';
import ThemeV2 from '../../../componentsV2/theme';
import { Card } from '../UserNameCard/styles';
import TextField from '../NewTextfield';
import Body from '../../atoms/Body';

export const ShowAllButton = styled.button`
  width: 100%;
  border: none;
  border-radius: 4px;
  padding: 8px;
  font-size: 14px;
  text-align: center;
  color: ${ThemeV2.palette.geekBlue6};
  background-color: ${ThemeV2.palette.gray1};
  cursor: pointer;

  &:hover {
    background-color: ${ThemeV2.palette.geekBlue1};
    color: ${ThemeV2.palette.geekBlue6};
  }
`;

export const UserListItem = styled.li`
  list-style: none;
  &:not(:last-child) {
    margin-bottom: 8px;
  }
  ${Card} {
    height: 32px;
  }
`;

export const StyledTextField = styled(TextField)`
  padding: 8px;
  margin-bottom: 4px;
`;

export const VisibilityText = styled(Body)`
  margin: 0px 12px 0px 12px;
`;

export const PopoverContentWrapper = styled.div`
  padding: 4px;
  max-height: 450px;
`;

export const StyledBody = styled(Body)`
  max-width: 175px;
  margin: 8px auto 8px;
  text-align: center;
  color: ${ThemeV2.palette.gray9};
`;

export const StyledPopover = styled(Popover)`
  pointer-events: none;
  & .MuiPopover-paper {
    pointer-events: auto;
  }
`;
