import styled from 'styled-components';
import { AccordionRoot } from '../../molecules/AccordionItem/styles';

import SVGIcon from '../../atoms/SVGIcon';
import ThemeV2 from '../../../componentsV2/theme';

export const StyledAccordionRoot = styled(AccordionRoot)`
  background-color: ${ThemeV2.palette.gray1};
  max-width: 643px;
  margin: 0 auto;
`;

export const EmailRowWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 56px;
  margin-left: 16px;
`;

export const StyledSVGIcon = styled(SVGIcon)`
  margin-right: 8px;
`;
