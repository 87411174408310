import styled from 'styled-components';
import GifContainer from '../../../atomic/molecules/GifContainer';

export const SetupCompletionContainer = styled.section`
  width: 450px;
`;

export const LoaderGifContainer = styled(GifContainer)`
  margin: 24px 0;
`;
