import React, { useState, useRef, useEffect } from 'react';
import Loading from '../loading';
import { usePdf } from './parsers/pdfParser';

function PdfViewer({
  height,
  width,
  filePath,
  SubComponent,
  WrapperComponent,
  idPrefix,
  hideLoader,
}: {
  height: number;
  width: number;
  filePath: string;
  SubComponent?: (props: { index: number }) => JSX.Element;
  WrapperComponent?: (wrapperProps: {
    index: number;
    children: React.ReactNode;
  }) => JSX.Element;
  idPrefix?: string;
  hideLoader?: boolean;
}) {
  /* @ts-ignore-next-line */
  const [pdfDocument, setPdfDocument] = useState<pdfjs.PDFDocumentProxy>();
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [canvasElements, setCanvasElements] = useState<JSX.Element[]>([]);
  const canvassesRef = useRef([]);

  useEffect(() => {
    (async function () {
      // We import this here so that it's only loaded during client-side rendering.
      const pdfJS = await import('pdfjs-dist/build/pdf');
      pdfJS.GlobalWorkerOptions.workerSrc =
        window.location.origin + '/pdf.worker.min.js';
      /* @ts-ignore-next-line */
      const config: pdfjs.PDFSource = { url: filePath };
      await pdfJS.getDocument(config).promise.then((loadedPdfDocument: any) => {
        setPdfDocument(loadedPdfDocument);
      });
    })();
  }, [filePath]);

  useEffect(() => {
    if (pdfDocument) {
      setNumberOfPages(pdfDocument.numPages);
    }
  }, [pdfDocument]);

  useEffect(() => {
    const canvasses = [];
    for (let pageNum = 1; pageNum <= numberOfPages; pageNum++) {
      canvasses.push(
        <div>
          {WrapperComponent ? (
            <>
              <WrapperComponent index={pageNum}>
                <canvas
                  /* @ts-ignore-next-line */
                  ref={(el) => (canvassesRef.current[pageNum] = el)}
                  className={'wrappedCanvas'}
                />
              </WrapperComponent>
              {SubComponent && <SubComponent index={pageNum} />}
            </>
          ) : (
            <>
              <canvas
                /* @ts-ignore-next-line */
                ref={(el) => (canvassesRef.current[pageNum] = el)}
                id={`${idPrefix}` + pageNum}
              />
              {SubComponent && <SubComponent index={pageNum} />}
            </>
          )}
        </div>,
      );
    }
    setCanvasElements(canvasses);
  }, [SubComponent, WrapperComponent, height, idPrefix, numberOfPages, width]);

  usePdf({
    pdfDocument,
    numberOfPages,
    canvassesRef,
    height,
    width,
  });

  return (
    <>
      {!pdfDocument && !hideLoader && <Loading />}
      {canvasElements}
    </>
  );
}

export default PdfViewer;
