import { UPDATE_PROFILE_INFO } from '../../constants/endpoints';
import { makeAPICall } from '../../queries/utils';

type UpdateShowLeftDrawerRequest = {
  showLeftDrawer: boolean;
};

export const updateShowLeftDrawer = async (
  request: UpdateShowLeftDrawerRequest,
) => {
  await makeAPICall(UPDATE_PROFILE_INFO, request, undefined);
};
