import styled, { css } from 'styled-components';

import Body from '../../atoms/Body';
import ButtonBase from '../../atoms/ButtonBase';
import SVGIcon from '../../atoms/SVGIcon';

import { ellipsisStyle } from '../../../Utils/styles/css';
import { FlexCSS, Flex } from '../../../Utils/styles/display';
import ThemeV2 from '../../../componentsV2/theme';
import { device } from '../../../constants/layout';

export const StyledBody = styled(Body)`
  width: 180px;
  text-align: left;
  padding-left: 8px;
  ${ellipsisStyle};
  opacity: 0;
  transition: opacity 0.4s;
`;

export const PrefixSVGIconWrapper = styled.div<{
  isExpanded?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 24px;
  height: 24px;
  opacity: ${({ isExpanded }) => (isExpanded ? '1' : '0')};
  transition: opacity 0.4s;
`;

export const PrefixSVGIcon = styled(SVGIcon)``;

export const IconBackground = styled.div<{
  isSelected?: boolean;
  isExpanded?: boolean;
}>`
  position: absolute;
  background: ${({ isSelected, isExpanded }) =>
    isSelected && !isExpanded && ThemeV2.palette.geekBlue2};
  width: 32px;
  height: 32px;
  left: -4px;
  top: -4px;
  border-radius: 4px;
  transition: 0.4s;
`;

export const StyledSVGIcon = styled(SVGIcon)``;
export const SVGIconWithBackgroundWrapper = styled(Flex)`
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background-color: ${ThemeV2.palette.gray3};
  transition: all 0.2s ease 0s;
`;

export const StyledEmoticon = styled(Body)`
  position: relative;
  width: 24px;
`;

const hoverStyles = css`
  background: ${ThemeV2.palette.geekBlue1};
  ${StyledBody} {
    color: ${ThemeV2.palette.geekBlue6};
  }
  ${StyledSVGIcon} {
    svg > path {
      fill: ${ThemeV2.palette.geekBlue6};
    }
  }
  ${SVGIconWithBackgroundWrapper} {
    background: ${ThemeV2.palette.geekBlue2};
    svg > path {
      fill: ${ThemeV2.palette.geekBlue6};
    }
  }
`;

export const StyledMenuItem = styled(ButtonBase)<{
  isSelected?: boolean;
  isExpanded?: boolean;
  disabled?: boolean;
  isMuted?: boolean;
  disableHoverState?: boolean;
}>`
  ${FlexCSS};
  position: relative;
  width: 272px;
  padding: 4px;
  padding-left: 0;
  padding-right: 8px;
  cursor: pointer;
  border-radius: 0 4px 4px 0;
  background: ${({ isSelected, isExpanded }) =>
    isSelected && isExpanded && ThemeV2.palette.geekBlue2};
  & ${StyledBody} {
    color: ${({ isSelected }) => isSelected && ThemeV2.palette.geekBlue6};
  }
  & ${StyledSVGIcon} {
    svg > path {
      fill: ${({ isSelected }) => isSelected && ThemeV2.palette.geekBlue6};
    }
  }
  ${StyledBody} {
    opacity: ${({ isExpanded }) => isExpanded && 1};
  }
  &:not(:hover) {
    filter: ${({ isMuted, disabled }) =>
      isMuted && !disabled && 'grayscale(1)'};
  }
  &:hover {
    background: ${({ disabled }) => !disabled && ThemeV2.palette.geekBlue1}
      ${StyledBody} {
      color: ${ThemeV2.palette.geekBlue6};
    }
    ${StyledSVGIcon} {
      svg > path {
        fill: ${ThemeV2.palette.geekBlue6};
      }
    }
    ${SVGIconWithBackgroundWrapper} {
      background: ${ThemeV2.palette.geekBlue2};
      svg > path {
        fill: ${ThemeV2.palette.geekBlue6};
      }
    }
    cursor: ${({ disabled }) => disabled && 'not-allowed'};
    ${({ disableHoverState }) => !disableHoverState && hoverStyles};
  }

  @media ${device.mobile} {
    width: 100%;
  }
`;
