export const formatFileSize = (bytes: number): string => {
  const ONE_MILLION = 1000000;
  if (bytes < ONE_MILLION) {
    const kilobytes = Math.floor(bytes / 1000);
    return `${kilobytes}KB`;
  }

  const megabytes = Math.floor(bytes / ONE_MILLION);
  return `${megabytes}MB`;
};

export const truncate = (str: string, maxLen: number) => {
  if (str.length > maxLen) {
    const extensionStartIndex = str.lastIndexOf('.');
    const truncatedFileName = str
      .slice(0, extensionStartIndex)
      .slice(0, maxLen);
    const extension = str.slice(extensionStartIndex, str.length);
    return `${truncatedFileName}..${extension}`;
  }
  return str;
};
