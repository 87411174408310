import React from 'react';

import Body from '../../atoms/Body';
import { LeftWrapper, RightWrapper, StyledAvatar, Wrapper } from './styles';
import { ME } from '../../../languages/en/singleWords';
import MyRankingEntryLoader from './Loader';

export interface MyRankingEntryProps {
  className?: string;
  count?: number;
  isLoading: boolean;
  fullName?: string;
  onClick?: () => void;
  profilePicture?: string;
  userId?: string;
}

const MyRankingEntry = ({
  className,
  count,
  fullName,
  isLoading,
  onClick,
  profilePicture = '',
  userId,
}: MyRankingEntryProps) => {
  if (isLoading) {
    return <MyRankingEntryLoader className={className} />;
  }

  if (count || (count === 0 && userId && fullName)) {
    return (
      <Wrapper
        className={className}
        data-testid="my-ranking-entry"
        onClick={onClick}
      >
        <LeftWrapper>
          <StyledAvatar
            img={profilePicture}
            name={fullName}
            size="32px"
            userId={userId}
          />
          <Body color="gray9" variant="body3Bold">
            {ME}
          </Body>
        </LeftWrapper>

        <RightWrapper>
          <Body color="gray9" variant="body3Bold">
            {count}
          </Body>
        </RightWrapper>
      </Wrapper>
    );
  }

  return null;
};

const MemoizedMyRankingEntry = React.memo(MyRankingEntry);
MemoizedMyRankingEntry.displayName = 'MyRankingEntry';
export default MemoizedMyRankingEntry;
