import isEmpty from 'lodash/isEmpty';
import {
  QueryClient,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from 'react-query';
import {
  GET_NOTEBOOK_RIGHT_DRAWER_TASK,
  GET_NOTEBOOK_SECTION,
  GET_NOTEBOOK_TASK_ASSIGNEE_LIST,
  GET_NOTEBOOK_TASK_HISTORY,
  GET_NOTEBOOK_TODO_COUNT,
  POST_NOTEBOOK_TASKS,
  PUT_NOTEBOOK_MARK_TASKS_AS_READ,
} from '../../constants/endpoints';
import { NOTEBOOK_PAGINATION_LIMIT } from '../../constants/notebook';
import { NotebookTabs } from '../../controllers/notebook/NotebookPageController/useNotebookPageController/types';
import {
  GetNotebookSectionResponse,
  GetNotebookTaskAssigneeResponse,
  GetTaskHistoryLogResponse,
  TaskCategories,
  TaskPayload,
} from '../../interfaces/notebook';
import { makeAPICall, makeAPICallWithDataReturn } from '../utils';

export const useUpdateTasksAsRead = () =>
  useMutation((taskIds: string[]) =>
    makeAPICall(PUT_NOTEBOOK_MARK_TASKS_AS_READ, { taskIds }),
  );

export const invalidateTaskHistoryQuery = (
  client: QueryClient,
  taskId: string,
) => {
  client.invalidateQueries([GET_NOTEBOOK_TASK_HISTORY, taskId]);
};

export const useGetNotebookSectionQuery = (
  type: TaskCategories,
  tabType: NotebookTabs,
  limit = 20,
  enabled = true,
) =>
  useInfiniteQuery<GetNotebookSectionResponse>(
    [GET_NOTEBOOK_SECTION, type, tabType],
    ({ pageParam = '' }) =>
      makeAPICallWithDataReturn(
        GET_NOTEBOOK_SECTION,
        undefined,
        {
          limit,
          ...(!isEmpty(pageParam) ? { cursor: pageParam } : {}),
        },
        {
          section: type.toLowerCase(),
          tab: tabType.toLowerCase(),
        },
      ),
    {
      enabled,
      getNextPageParam: (lastPage) =>
        lastPage.metadata.pagination?.cursor?.next || undefined,
      getPreviousPageParam: (lastPage) => {
        return lastPage.metadata.pagination.cursor.previous;
      },
    },
  );

export const usePostNotebookTasksMutation = () =>
  useMutation(({ payload }: { payload: TaskPayload[] }) => {
    return makeAPICall(POST_NOTEBOOK_TASKS, {
      data: payload,
    });
  });

export const useGetNotebookTodoCountQuery = () =>
  useQuery([GET_NOTEBOOK_TODO_COUNT], () =>
    makeAPICallWithDataReturn(GET_NOTEBOOK_TODO_COUNT, undefined),
  );

export const useGetNotebookRightDrawerTaskQuery = (
  taskId: string,
  enabled: boolean,
  onError?: (error: unknown) => void,
) => {
  return useQuery(
    [GET_NOTEBOOK_RIGHT_DRAWER_TASK, taskId],
    () =>
      makeAPICallWithDataReturn(
        GET_NOTEBOOK_RIGHT_DRAWER_TASK,
        undefined,
        undefined,
        {
          taskId,
        },
      ),
    {
      enabled,
      onError,
    },
  );
};

export const prefetchOtherNotebookTab = (
  queryClient: QueryClient,
  queryKey: string[],
  type: TaskCategories,
  prefetchTab: NotebookTabs,
) =>
  queryClient.prefetchInfiniteQuery(queryKey, () =>
    makeAPICallWithDataReturn(
      GET_NOTEBOOK_SECTION,
      undefined,
      {
        limit: NOTEBOOK_PAGINATION_LIMIT,
      },
      {
        section: type.toLowerCase(),
        tab: prefetchTab.toLowerCase(),
      },
    ),
  );

export const useGetNotebookTaskAssigneeList = (
  taskId: string,
  enabled: boolean,
  searchValueForAssignees: string,
) => {
  return useInfiniteQuery<GetNotebookTaskAssigneeResponse>(
    [GET_NOTEBOOK_TASK_ASSIGNEE_LIST, taskId, searchValueForAssignees],
    (data) => {
      const { pageParam = '' } = data;
      return makeAPICallWithDataReturn(
        GET_NOTEBOOK_TASK_ASSIGNEE_LIST,
        undefined,
        !isEmpty(pageParam)
          ? {
              cursor: pageParam,
            }
          : undefined,
        {
          taskId,
          keyword: searchValueForAssignees,
        },
      );
    },
    {
      enabled,
      getNextPageParam: (lastPage) =>
        lastPage.metadata.pagination?.cursor?.next || undefined,
      getPreviousPageParam: (lastPage) => {
        return lastPage.metadata.pagination.cursor.previous;
      },
    },
  );
};

export const useGetNotebookTaskHistoryQuery = (
  taskId: string,
  sort: string,
) => {
  return useInfiniteQuery<GetTaskHistoryLogResponse>(
    [GET_NOTEBOOK_TASK_HISTORY, taskId, sort],
    (data) => {
      const { pageParam = '' } = data;
      return makeAPICallWithDataReturn(
        GET_NOTEBOOK_TASK_HISTORY,
        undefined,
        {
          limit: 10,
          cursor: !isEmpty(pageParam) ? pageParam : undefined,
          sortBy: sort,
        },
        {
          taskId,
        },
      );
    },
    {
      enabled: true,
      getNextPageParam: (lastPage) =>
        lastPage.metadata.pagination?.cursor?.next || undefined,
      getPreviousPageParam: (lastPage) => {
        return lastPage.metadata.pagination.cursor.previous;
      },
    },
  );
};
