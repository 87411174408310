import { trackEvent } from '../index';
import { ShareSheetEventProps } from '../interfaces';
import { AnalyticsEvents, SHARE_SHEET_EVENT } from '../constants';

export const trackShareSheetShowEvent = (eventProps: ShareSheetEventProps) => {
  trackEvent(SHARE_SHEET_EVENT, undefined, {
    ...eventProps,
    event: AnalyticsEvents.SHOW,
  });
};

export const trackShareSheetActionEvent = (
  eventProps: ShareSheetEventProps,
) => {
  trackEvent(SHARE_SHEET_EVENT, undefined, {
    ...eventProps,
    event: AnalyticsEvents.ACTION,
  });
};

export const trackShareSheetErrorEvent = (eventProps: ShareSheetEventProps) => {
  trackEvent(SHARE_SHEET_EVENT, undefined, {
    ...eventProps,
    event: AnalyticsEvents.ERROR,
  });
};
