import { useMutation, useQuery } from 'react-query';
import { makeAPICall, makeAPICallWithDataReturn } from '../../utils';

import {
  GET_ALL_FLOWS_TEMPLATES,
  GET_FLOW_TEMPLATE,
  GET_FLOWS_TEMPLATE_CATEGORIES,
  REQUEST_TEMPLATE,
  REQUEST_TEMPLATE_FROM_SCRATCH,
} from '../../../constants/endpoints';
import {
  GetAllFlowTemplatesResponse,
  GetFlowTemplateCategoriesResponse,
  GetFlowTemplateResponse,
} from './types';

export const useGetFlowTemplate = (templateId: string) => {
  return useQuery<GetFlowTemplateResponse>(
    [GET_FLOW_TEMPLATE, templateId],
    () =>
      makeAPICallWithDataReturn(GET_FLOW_TEMPLATE, {}, undefined, {
        templateId,
      }),
    {
      staleTime: Infinity,
      refetchOnMount: false,
      enabled: !!templateId,
    },
  );
};

export const useGetFlowsTemplateCategories = () => {
  return useQuery<GetFlowTemplateCategoriesResponse>(
    [GET_FLOWS_TEMPLATE_CATEGORIES],
    () => makeAPICallWithDataReturn(GET_FLOWS_TEMPLATE_CATEGORIES),
    {
      staleTime: Infinity,
      refetchOnMount: 'always',
    },
  );
};

export const useGetAllFlowsTemplates = () => {
  return useQuery<GetAllFlowTemplatesResponse>(
    [GET_ALL_FLOWS_TEMPLATES],
    () => makeAPICallWithDataReturn(GET_ALL_FLOWS_TEMPLATES),
    {
      staleTime: Infinity,
      refetchOnMount: 'always',
    },
  );
};

export const useRequestTemplate = () => {
  return useMutation((params: { templateId: string }) =>
    makeAPICall(REQUEST_TEMPLATE, undefined, undefined, {
      templateId: params.templateId,
    }),
  );
};

export const useRequestTemplateFromScratch = () => {
  return useMutation(() => makeAPICall(REQUEST_TEMPLATE_FROM_SCRATCH));
};
