import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import {
  getFlowFeedUrl,
  mapFlowFeedPostToFeedProps,
} from '../../../../Utils/home/feeds';
import useLayoutStore from '../../../../stores/layoutStore';
import { FeedProps, MemberInteractionType } from '../../../../interfaces/Feed';
import { useGetFlowResponseQuery } from '../../../../queries/Feed';
import { useProfileInfoFetchQuery } from '../../../../queries/Profile';
import { useFlowFeedReactions } from '../../../../hooks/useFeedReactions';
import { useUpdateSingleFlowReactionMutation } from '../../../../queries/Flows/Feed';
import routesList from '../../../../aV2/routes/routesList';
import { LayoutStoreState } from '../../../../interfaces/Layout';

const layoutSelector = (state: LayoutStoreState) =>
  state.setMemberAndToggleRightAsideOpen;

const useNewSingleFlowFeedPostController = () => {
  const { search } = useLocation();
  const flowId = new URLSearchParams(search).get('flowId') || '';
  const responseId = new URLSearchParams(search).get('responseId') || '';
  const history = useHistory();

  const {
    isLoading: isSingleFlowFeedPostLoading,
    isError,
    data,
  } = useGetFlowResponseQuery(flowId, responseId);

  const { data: profileData, isLoading: isProfileInfoLoading } =
    useProfileInfoFetchQuery();

  const { mutate: mutateSingleFlowPostReaction } =
    useUpdateSingleFlowReactionMutation(flowId);

  const setMemberAndToggleRightAsideOpen = useLayoutStore(layoutSelector);

  const isLoading = isSingleFlowFeedPostLoading || isProfileInfoLoading;

  const { onReactionSet, onReactionUnSet } = useFlowFeedReactions({
    profileData,
    flowFeedData: data ? [data] : [],
    mutateFlowPostReaction: mutateSingleFlowPostReaction,
    flowId,
  });

  const handleOnMemberClick = useCallback(
    (member: MemberInteractionType) => {
      setMemberAndToggleRightAsideOpen({
        memberId: member.memberID,
        firstName: member.firstName,
      });
    },
    [setMemberAndToggleRightAsideOpen],
  );

  const onMemberClick = useCallback(
    (person: MemberInteractionType) => {
      setMemberAndToggleRightAsideOpen({
        memberId: person.memberID,
        firstName: person.firstName,
      });
    },
    [setMemberAndToggleRightAsideOpen],
  );

  const handleOnFlowClick = useCallback(
    (flow: { flowId: string }) => {
      history.push(getFlowFeedUrl(flow.flowId));
    },
    [history],
  );

  const flowFeedPostProps: FeedProps | null = useMemo(() => {
    if (!data || !profileData) {
      return null;
    }
    return mapFlowFeedPostToFeedProps(
      data,
      profileData,
      handleOnMemberClick,
      handleOnFlowClick,
    );
  }, [data, handleOnFlowClick, handleOnMemberClick, profileData]);

  const handlePostDelete = useCallback(() => {
    history.push(routesList.HOME);
  }, [history]);

  return {
    isError,
    isLoading,
    onMemberClick,
    flowFeedPostProps,
    onReactionSet,
    onReactionUnSet,
    profileData,
    onPostDelete: handlePostDelete,
  };
};

export default useNewSingleFlowFeedPostController;
