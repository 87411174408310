import {
  LAST_7_DAYS,
  LAST_30_DAYS,
  THIS_MONTH,
  THIS_QUARTER,
} from '../../atomic/molecules/Leaderboard/data';

const getFirstDayOfQuarter = (date: Date) => {
  const month = date.getMonth();

  let firstMonthOfQuarter;
  if (month <= 2) {
    const januaryAsNumber = 0;
    firstMonthOfQuarter = januaryAsNumber;
  } else if (month <= 5) {
    const aprilAsNumber = 3;
    firstMonthOfQuarter = aprilAsNumber;
  } else if (month <= 8) {
    const julyAsNumber = 6;
    firstMonthOfQuarter = julyAsNumber;
  } else {
    const octoberAsNumber = 9;
    firstMonthOfQuarter = octoberAsNumber;
  }

  const day = 1;
  const firstDayOfQuarter = new Date(
    date.getFullYear(),
    firstMonthOfQuarter,
    day,
  );
  return firstDayOfQuarter;
};

export const calculateFromQueryParameter = (
  timeFrame: string,
  nowInMilliseconds: number,
) => {
  switch (timeFrame) {
    case LAST_7_DAYS.id: {
      const sevenDaysInMilliseconds = 1000 * 60 * 60 * 24 * 7;
      return nowInMilliseconds - sevenDaysInMilliseconds;
    }
    case LAST_30_DAYS.id: {
      const thirtyDaysInMilliseconds = 1000 * 60 * 60 * 24 * 30;
      return nowInMilliseconds - thirtyDaysInMilliseconds;
    }
    case THIS_MONTH.id: {
      const nowAsDate = new Date(nowInMilliseconds);
      const firstDayOfTheMonthInMilliseconds = new Date(
        nowAsDate.getFullYear(),
        nowAsDate.getMonth(),
        1,
      ).getTime();
      return firstDayOfTheMonthInMilliseconds;
    }
    case THIS_QUARTER.id: {
      const firstDayOfQuarter = getFirstDayOfQuarter(
        new Date(nowInMilliseconds),
      );
      return firstDayOfQuarter.getTime();
    }
    default: {
      const beginningOfTimeInMilliseconds = 0;
      return beginningOfTimeInMilliseconds;
    }
  }
};
