import React from 'react';
import Heading from '../../../atomic/atoms/Heading';
import Body from '../../../atomic/atoms/Body';

import {
  SETUP_FLOWS_HEADER,
  SETUP_PRELOADED_FLOWS_LOADING_INFO,
} from '../../../languages/en/flows';
import Navbar from '../../../componentsV2/core/Navbar';
import { CardContainer, MainContainer, StyledGifContainer } from './styles';
import LoadingPulse from '../../../atomic/atoms/LoadingPulse';
import useOnBoardingSetup from './useOnBoardingSetup';

const OnBoardingSetup = () => {
  useOnBoardingSetup();
  return (
    <div>
      <Navbar />
      <MainContainer>
        <CardContainer>
          <Heading variant="h5">{SETUP_FLOWS_HEADER}</Heading>
          <Body variant="body2">{SETUP_PRELOADED_FLOWS_LOADING_INFO}</Body>
          <StyledGifContainer src="https://media2.giphy.com/media/SWVzkIlHdEckF81gnA/200.gif" />
          <LoadingPulse size={32} />
        </CardContainer>
      </MainContainer>
    </div>
  );
};

export default OnBoardingSetup;
