import styled from 'styled-components';
import Body from '../Body';
import SVGIcon from '../SVGIcon';

export const Wrapper = styled.span<{ color: string }>`
  display: inline-flex;
  align-items: center;
  height: 24px;
  width: auto;
  padding: 2px 8px;
  background: ${({ color }) => color};
  border-radius: 20px;
  box-sizing: border-box;
`;

export const StyledSVGIcon = styled(SVGIcon)<{ color: string }>`
  margin-right: 4px;
  svg path {
    fill: ${({ color }) => color};
  }
`;

export const StyledBody = styled(Body)<{ color: string }>`
  color: ${({ color }) => color};
  min-width: 14px;
  text-align: center;
`;
