import styled from 'styled-components';
import Tooltip from '../ToolTipV2';
import ThemeV2 from '../../../componentsV2/theme';
import { device } from '../../../constants/layout';

export const FlowStepperContainer = styled.div`
  position: relative;
  width: 8px;
  flex-grow: 1;
  border-radius: 4px;
  background-color: ${ThemeV2.palette.gray4};
  display: flex;
  flex-direction: column;
  div {
    flex-grow: 1;
  }
`;

export const Step = styled.span<{
  active?: boolean;
  isError?: boolean;
  isCurrentStep?: boolean;
}>`
  height: 100%;
  width: 100%;
  border-radius: 4px;
  cursor: ${({ active }) => (active ? 'default' : 'pointer')};
  background-color: ${({ active, isError }) => {
    if (active) {
      return ThemeV2.palette.geekBlue6;
    }
    return isError ? ThemeV2.palette.red6 : 'transparent';
  }};
  transition-property: opacity;
  transition-duration: 1s;
  opacity: ${({ isCurrentStep }) => (isCurrentStep ? 0 : 1)};
`;

export const ActiveTooltip = styled(Tooltip)<{
  steps: number;
  currentStep: number;
}>`
  height: ${({ steps }) => `calc(100% / ${steps})`};
  width: 100%;
  position: absolute;
  transition-property: transform;
  transition-duration: 1s;
  transform: ${({ currentStep }) => `translateY(${currentStep * 100}%)`};
`;

export const Container = styled.div`
  width: 40px;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  padding: 32px 0 20px;
  box-sizing: border-box;
  border-right: 1px solid ${ThemeV2.palette.gray5};
  @media ${device.tabletV2} {
    width: 72px;
  }
  grid-area: stepper;
`;

export const ProgressContainer = styled.div`
  margin-top: 36px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledTooltip = styled(Tooltip)<{
  isError?: boolean;
  isCurrentStep?: boolean;
}>`
  pointer-events: ${({ isCurrentStep }) =>
    isCurrentStep ? 'none' : 'initial'};
  ${({ isError }) =>
    isError &&
    `
  & > span:not([data-step-number]) {
    background: ${ThemeV2.palette.gray1};
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), 0px 3px 4px rgba(0, 0, 0, 0.02),
      0px 1px 5px rgba(0, 0, 0, 0.12);
    div {
      color: ${ThemeV2.palette.darkGray9};
    }
  }
  `};
`;

export const ErrorSymbolSpan = styled.span`
  color: ${ThemeV2.palette.red6};
  margin-right: 4px;
`;
