import { useInfiniteQuery } from 'react-query';

import { makeAPICallWithDataReturn } from '../utils';
import { GET_MAIN_FEED } from '../../constants/endpoints';

import { GetFeedResponse, PostMainFeedPayload } from '../../interfaces/Feed';

export const useGetMainFeedQuery = ({
  feedsSort,
  filter,
}: PostMainFeedPayload) => {
  return useInfiniteQuery<GetFeedResponse>(
    [GET_MAIN_FEED, feedsSort, filter],
    ({ pageParam = '' }) =>
      makeAPICallWithDataReturn(GET_MAIN_FEED, {
        cursor: pageParam,
        sortBy: feedsSort,
        filter,
      }),
    {
      staleTime: Infinity,
      refetchOnMount: 'always',
      getNextPageParam: (lastPage) =>
        lastPage.metadata.pagination?.cursor?.next || undefined,
      getPreviousPageParam: (lastPage) => {
        return lastPage.metadata.pagination.cursor.previous;
      },
    },
  );
};
