import React from 'react';

import FlowDetails from '../../../../atomic/organism/RightDrawerAbout/FlowDetails';

import { ComponentStatus } from '../../../../interfaces/component';
import useFlowDetailsControllerLogic from './useFlowDetailsControllerLogic';

const FlowDetailsController = () => {
  const { models } = useFlowDetailsControllerLogic();
  const { flowDetailsProps, isLoading, isError } = models;

  if (isLoading) {
    return (
      <FlowDetails {...flowDetailsProps} status={ComponentStatus.LOADING} />
    );
  }
  if (isError) {
    return <FlowDetails {...flowDetailsProps} status={ComponentStatus.ERROR} />;
  }
  return <FlowDetails {...flowDetailsProps} />;
};

export default FlowDetailsController;
