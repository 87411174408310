import styled from 'styled-components';

import { Flex } from '../../../Utils/styles/display';
import ThemeV2 from '../../../componentsV2/theme';

import IconButtonWithToolTip from '../IconButtonWithToolTip';
import AddTrophiesInput from '../AddTrophiesInput';
import ToolTip from '../ToolTipV2';
import { PostType } from './interface';
import EmoticonPickerPopover from '../EmoticonPickerPopover';
import { zIndexPopup } from '../../../Utils/styles/z-index';

export const Wrapper = styled(Flex)<{
  postType: PostType;
  showAddTrophy: boolean;
}>`
  justify-content: ${({ postType, showAddTrophy }) =>
    postType === PostType.Comment && showAddTrophy
      ? 'space-between'
      : 'flex-end'};
  padding: ${({ postType }) =>
    postType === PostType.Comment ? '4px' : '8px 0'};
  align-items: ${({ postType }) =>
    postType === PostType.Comment ? 'flex-end' : 'center'};
  max-width: 100%;
  background: ${ThemeV2.palette.gray1};
  border-radius: 0px 0px 4px 4px;
`;

export const IconButtonWrapper = styled(IconButtonWithToolTip)`
  padding: 0px;
  & span {
    height: 16px;
    width: 16px;
    svg {
      height: 16px;
      width: 16px;
      min-width: 16px;
    }
  }
`;

export const StyledTrophiesInput = styled(AddTrophiesInput)`
  & button {
    border-bottom-left-radius: 20px;
    &:before {
      border-bottom-left-radius: 20px;
    }
  }
  &:not(:disabled):hover {
    & button {
      &:before {
        background: transparent;
        border: none;
      }
    }
  }
`;

export const StyledToolTip = styled(ToolTip)`
  margin-left: 8px;
`;

export const TooltipContent = styled.div`
  width: 182px;
`;

export const StyledEmoticonPickerPopover = styled(EmoticonPickerPopover)`
  z-index: ${zIndexPopup};
`;

export const ButtonContentFlexWrapper = styled(Flex)`
  & > div {
    margin-right: 8px;
  }
`;
