import React, { useEffect, useRef, useState } from 'react';

import { FlowRequestModalProps } from './types';
import {
  StyledHeaderWrapper,
  StyledModal,
  TemplateCardsWrapper,
  TemplateCardWrapper,
  StyledCategoryTitle,
  Wrapper,
  ScrollableContent,
  CreateFlowBanner,
  StyledButton,
  LoaderCardWrapper,
  BannerLoaderWrapper,
} from './styles';
import FlowRequestTemplateModalHeader from '../FlowRequestTemplateModalHeader';
import FlowRequestTemplateCard from '../../molecules/FlowRequestTemplateCard';
import { ComponentStatus } from '../../../interfaces/component';
import { HeadingType } from '../../molecules/EmptyErrorStateTemplate/interfaces';
import EmptyErrorStateTemplate from '../../molecules/EmptyErrorStateTemplate';
import {
  FLOW_REQUEST_MODAL_LOADING_STATES,
  FLOW_REQUEST_TEMPLATE_MODAL,
} from '../../../languages/en/flows/requestFlowModal';

import searching from '../../../img/searching.svg';
import Body from '../../atoms/Body';
import ContentLoader from 'react-content-loader';
import ThemeV2 from '../../../componentsV2/theme';

const FlowRequestModal = (props: FlowRequestModalProps) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const [headerHeight, setHeaderHeight] = useState<string>('auto');
  const {
    isOpen,
    value,
    className,
    handleClose,
    onCategoryClick,
    onCategoryBlur,
    categories,
    onChange,
    selectedCategory,
    templateCardItems,
    isGetTemplatesLoading,
    isGetCategoriesLoading,
    onTemplateClick,
    onRequestTemplateFromScratchClick,
  } = props;

  useEffect(() => {
    if (headerRef && headerRef.current) {
      setHeaderHeight(`${headerRef.current.offsetHeight + 16}px`);
    }
  }, [headerRef, templateCardItems.length]);

  return (
    <StyledModal
      className={className}
      isOpen={isOpen}
      handleClose={handleClose}
      showMobileFullScreen
    >
      <Wrapper
        isLoading={isGetTemplatesLoading && templateCardItems.length === 0}
        headerHeight={headerHeight}
      >
        <StyledHeaderWrapper ref={headerRef}>
          {isGetTemplatesLoading ? (
            <FlowRequestTemplateModalHeader
              status={ComponentStatus.LOADING}
              inputPlaceholder="Search"
            />
          ) : (
            <FlowRequestTemplateModalHeader
              status={ComponentStatus.LOADED}
              heading={FLOW_REQUEST_TEMPLATE_MODAL.heading}
              subHeading={FLOW_REQUEST_TEMPLATE_MODAL.subHeading}
              inputPlaceholder={
                FLOW_REQUEST_TEMPLATE_MODAL.searchTemplatePlaceHolder
              }
              value={value}
              onChange={onChange}
              onCloseButtonClick={handleClose}
              categories={categories}
              onCategoryChange={onCategoryClick}
              onCategoryBlur={onCategoryBlur}
              isGetCategoriesLoading={isGetCategoriesLoading}
              selectedCategory={selectedCategory}
            />
          )}
        </StyledHeaderWrapper>
        <ScrollableContent>
          <>
            {!isGetTemplatesLoading && templateCardItems.length === 0 && (
              <EmptyErrorStateTemplate
                variant={HeadingType.Regular}
                image={searching}
                heading={FLOW_REQUEST_MODAL_LOADING_STATES.NOTFOUND.HEADING}
                subHeading={
                  FLOW_REQUEST_MODAL_LOADING_STATES.NOTFOUND.SUB_HEADING
                }
              />
            )}

            {isGetTemplatesLoading && templateCardItems.length === 0 && (
              <div>
                <BannerLoaderWrapper>
                  <ContentLoader
                    speed={2}
                    width={1070}
                    height={96}
                    viewBox="0 0 1070 96"
                    backgroundColor={ThemeV2.palette.gray4}
                    foregroundColor={ThemeV2.palette.gray3}
                  >
                    <rect x="0" y="24" rx="4" ry="4" width="1070" height="78" />
                  </ContentLoader>
                </BannerLoaderWrapper>
                <LoaderCardWrapper>
                  <TemplateCardWrapper status={ComponentStatus.LOADING} />
                </LoaderCardWrapper>
              </div>
            )}
            {templateCardItems && templateCardItems.length > 0 && (
              <>
                <CreateFlowBanner>
                  <div>
                    <Body variant="body1Medium">
                      {
                        FLOW_REQUEST_TEMPLATE_MODAL.flowsBuildFromScratchBannerTitle
                      }
                    </Body>
                    <Body variant="body2">
                      {
                        FLOW_REQUEST_TEMPLATE_MODAL.flowsBuildFromScratchBannerSubTitle
                      }
                    </Body>
                  </div>
                  <StyledButton
                    color="secondary"
                    onClick={onRequestTemplateFromScratchClick}
                  >
                    {
                      FLOW_REQUEST_TEMPLATE_MODAL.flowsBuildFromScratchBannerButtonText
                    }
                  </StyledButton>
                </CreateFlowBanner>
                <StyledCategoryTitle variant="h6" weight="medium">
                  {selectedCategory?.id !== 'all'
                    ? selectedCategory?.name
                    : FLOW_REQUEST_TEMPLATE_MODAL.allTemplatesText}
                </StyledCategoryTitle>
                <TemplateCardsWrapper>
                  {templateCardItems.map((templateCardItem) => (
                    <FlowRequestTemplateCard
                      key={templateCardItem.templateId}
                      onPreviewFlowClick={() =>
                        onTemplateClick(templateCardItem.templateId)
                      }
                      {...templateCardItem}
                    />
                  ))}
                </TemplateCardsWrapper>
              </>
            )}
          </>
        </ScrollableContent>
      </Wrapper>
    </StyledModal>
  );
};
export default FlowRequestModal;
