import ContentLoader from 'react-content-loader';
import React from 'react';

import ThemeV2 from '../../../componentsV2/theme';

const MembersCountLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={127}
      height={12}
      viewBox="0 0 127 12"
      backgroundColor={ThemeV2.palette.gray4}
      foregroundColor={ThemeV2.palette.gray3}
      uniqueKey="members-count-loader"
    >
      <rect x="0" y="0" rx="4" width="127" height="12" />
    </ContentLoader>
  );
};

export default MembersCountLoader;
