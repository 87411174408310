import React from 'react';

import { FeedTypes } from '../../atomic/molecules/ActionBar/types';
import MainFeedActionBarContainer from '../../atomic/molecules/MainFeedActionBarContainer';
import useGetUserFlowPermissions from '../../hooks/useGetUserFlowPermissions';
import { ANSWER_A_FLOW } from '../../languages/en/flows';
import { isMobile, isTablet } from '../../Utils/window';
import useActionBarControllerLogic from './useActionBarControllerLogic';

const ActionBarController = () => {
  const { models, operations } = useActionBarControllerLogic();
  const {
    isLoading,
    isSuccess,
    isError,
    todos,
    shortcuts,
    feeds,
    todoNotification,
    profileData,
    searchText,
    isRecognitionFlowModalOpen,
    openActionBarOnLoad,
  } = models;

  const {
    handleSearchTextChange,
    handleFlowClick,
    handleAddNewFlowClick,
    handleFlowFeedClick,
    handleCelebrateTeammateClick,
    clearSearchText,
    toggleIsRecognitionFlowModalOpen,
    closeModalCb,
    onClickActionBarButtonCb,
    onNeedHelpClick,
    handleSuffixAdornmentClickCb,
  } = operations;

  const { canUserCreateFlow = false } = useGetUserFlowPermissions();

  const isAddNewFlowOn = !isMobile && !isTablet && canUserCreateFlow;

  if (!profileData) {
    return null;
  }

  return (
    <MainFeedActionBarContainer
      avatar={profileData.member.profile.image}
      feedType={FeedTypes.Main}
      notification={todoNotification}
      openActionBarOnLoad={openActionBarOnLoad}
      text={ANSWER_A_FLOW}
      userId={profileData.member.memberId}
      userName={profileData.member.profile.firstName}
      todos={todos}
      shortcuts={shortcuts}
      feeds={feeds}
      isLoading={isLoading}
      isError={isError}
      isSuccess={isSuccess}
      searchText={searchText}
      handleSearchTextChange={handleSearchTextChange}
      handleCelebrateTeammateClick={handleCelebrateTeammateClick}
      onFlowClick={handleFlowClick}
      onFlowFeedClick={handleFlowFeedClick}
      onAddNewFlowClick={handleAddNewFlowClick}
      clearSearchText={clearSearchText}
      profileInfo={profileData}
      isRecognitionFlowModalOpen={isRecognitionFlowModalOpen}
      toggleIsRecognitionFlowModalOpen={toggleIsRecognitionFlowModalOpen}
      isAddNewFlowOn={isAddNewFlowOn}
      closeModalCb={closeModalCb}
      onClickActionBarButtonCb={onClickActionBarButtonCb}
      onNeedHelpClick={onNeedHelpClick}
      handleSuffixAdornmentClickCb={handleSuffixAdornmentClickCb}
    />
  );
};

export default ActionBarController;
