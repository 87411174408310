import React, { useState } from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import shallow from 'zustand/shallow';
import { focusManager, useQueryClient } from 'react-query';
import { GET_NOTEBOOK_SECTION } from '../../../../constants/endpoints';
import {
  MiscTaskCategories,
  NotebookOperations,
  TaskCategories,
} from '../../../../interfaces/notebook';
import {
  usePostNotebookTasksMutation,
  useUpdateTasksAsRead,
} from '../../../../queries/Notebook';
import useNotebookStore from '../../../../stores/notebookStore';
import { NOTEBOOK_EVENTS } from '../../../../Utils/analytics/constants';
import {
  trackNotebookActionEvent,
  trackNotebookErrorEvent,
} from '../../../../Utils/analytics/notebook';
import { getNotebookAnalyticsProps } from '../../../../Utils/notebook';
import { notebookSections, NotebookTabs } from './data';
import { NotebookStore } from '../../../../stores/notebookStore/types';
import useNotebookPusher from '../useNotebookPusher';
import { getCurrentTab } from '../utils';
import { useHistory } from 'react-router-dom';
import { showSuccessMessage } from '../../../../Utils/toast';
import { NOTEBOOK_ASSIGNMENT_SUCCESS_TOAST } from '../../../../languages/en/notebook';
import { useProfileInfoFetchQuery } from '../../../../queries/Profile';
import { getCurrentDateForTimeZone } from '../../../../Utils/date';

const notebookPageSelector = (state: NotebookStore) => ({
  notes: state.notes,
  transactions: state.transactions,
  updateTransactions: state.updateTransactions,
  updateErrorIDs: state.updateErrorIDs,
  dragAndDropTask: state.dragAndDropTask,
  isOpen: state.isOpen,
  setModalData: state.setModalData,
  modalProps: state.modalProps,
  callback: state.callback,
  currentTaskData: state.currentTaskData,
  erroredTaskIDs: state.erroredTaskIDs,
  updateNoteIds: state.updateNoteIds,
  resetNotes: state.resetNotes,
  currentTab: state.currentTab,
  toggleCurrentTab: state.toggleCurrentTab,
  setIsRefetchQuery: state.setIsRefetchQuery,
  resetNotebookStoreData: state.resetNotebookStoreData,
  hasCheckbox: state.hasCheckbox,
});

const useNotebookPageController = () => {
  const {
    isOpen,
    notes,
    transactions,
    hasCheckbox,
    updateTransactions,
    updateErrorIDs,
    dragAndDropTask,
    setModalData,
    modalProps,
    callback,
    currentTaskData,
    erroredTaskIDs,
    updateNoteIds,
    currentTab,
    toggleCurrentTab,
    setIsRefetchQuery,
    resetNotebookStoreData,
  } = useNotebookStore(notebookPageSelector, shallow);
  const [hideNotebookNavigationModal, setNotebookNavigationModal] =
    useState(true);

  const [shouldShowModalForCurrentUser, setShouldShowModalForCurrentUser] =
    useState(true);

  useEffect(() => {
    focusManager.setEventListener(() => {
      const listener = () => {
        if (focusManager.isFocused()) {
          setTimeout(() => setIsRefetchQuery(false), 2000);
        } else {
          setIsRefetchQuery(true);
        }
      };
      if (typeof window !== 'undefined' && window.addEventListener) {
        window.addEventListener('visibilitychange', listener, false);
        window.addEventListener('focus', listener, false);
      }

      return () => {
        window.removeEventListener('visibilitychange', listener);
        window.removeEventListener('focus', listener);
      };
    });
  }, [setIsRefetchQuery]);

  const handleTabChange = useCallback(
    (event: React.ChangeEvent<unknown>, newValue: string) => {
      const updatedTab = newValue as NotebookTabs;
      toggleCurrentTab(updatedTab);
    },
    [toggleCurrentTab],
  );

  const queryClient = useQueryClient();

  const { mutate } = usePostNotebookTasksMutation();

  const history = useHistory();

  useNotebookPusher();

  const { data: profileInfo } = useProfileInfoFetchQuery();
  const currentUserTimezone = useMemo(
    () => profileInfo?.member.timeZone || '',
    [profileInfo?.member.timeZone],
  );
  const currentUserId = useMemo(
    () => profileInfo?.member.memberId,
    [profileInfo?.member.memberId],
  );
  const { mutate: markTasksAsRead } = useUpdateTasksAsRead();

  useEffect(() => {
    const currentTabOnLoad = getCurrentTab(window.location.pathname);
    setNotebookNavigationModal(false);
    if (Object.keys(NotebookTabs).includes(currentTabOnLoad)) {
      const updatedTab = currentTabOnLoad as NotebookTabs;
      toggleCurrentTab(updatedTab);
    } else {
      history.push(`${history.location.pathname}/myWork`);
      toggleCurrentTab(NotebookTabs.myWork);
    }
    const userInfo = localStorage.getItem('notebook');
    if (userInfo) {
      const parsedUserInfo = JSON.parse(userInfo);
      setShouldShowModalForCurrentUser(
        !!parsedUserInfo[`${currentUserId}`]?.showModal,
      );
    }
    return () => {
      resetNotebookStoreData();
      notebookSections.forEach((section) => {
        queryClient.removeQueries([GET_NOTEBOOK_SECTION, section]);
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasDropdown = useMemo(
    () =>
      currentTaskData?.updatedCategory === MiscTaskCategories.UNARCHIVE ||
      currentTaskData?.updatedCategory === MiscTaskCategories.INCOMPLETE,
    [currentTaskData?.updatedCategory],
  );

  useEffect(() => {
    const { transactionQueue } = transactions;
    if (transactionQueue.length) {
      const payload = transactionQueue.map((item) => item.taskData);
      mutate(
        { payload },
        {
          onSuccess: (response) => {
            const doesPayloadHaveACreateTask = payload.some(
              ({ noteId }) => !noteId,
            );
            const processedTaskIDs = payload.map((item) => item.transactionId);
            const updatedTransactionQueue = transactionQueue.filter(
              (item) => !processedTaskIDs.includes(item.taskId),
            );
            if (
              transactionQueue[transactionQueue.length - 1].operation ===
              NotebookOperations.ASSIGN
            ) {
              showSuccessMessage(NOTEBOOK_ASSIGNMENT_SUCCESS_TOAST);
            }
            if (doesPayloadHaveACreateTask) {
              const noteIds = response.data.map(
                (res: { noteId: string }) => res.noteId,
              );
              updateNoteIds(transactions, noteIds);
            }
            updateTransactions({
              ...transactions,
              transactionQueue: updatedTransactionQueue,
            });
            if (erroredTaskIDs.length !== 0) {
              updateErrorIDs([]);
            }
          },
          onError: () => {
            const processedNoteIDs = payload.map(
              ({ transactionId }) => transactionId,
            );
            if (
              processedNoteIDs.filter((id) => !erroredTaskIDs.includes(id))
                .length > 0
            ) {
              updateErrorIDs(processedNoteIDs);
              trackNotebookErrorEvent({
                error: NOTEBOOK_EVENTS.NOTEBOOK_ERROR,
                source: 'notebook',
                subSource: 'taskUpdate',
                errorIds: processedNoteIDs.map((id) => `${id}`),
              });
            }
          },
        },
      );
    }
  }, [
    updateNoteIds,
    transactions,
    mutate,
    updateTransactions,
    updateErrorIDs,
    erroredTaskIDs,
  ]);

  const onPrimaryButtonClick = useCallback(
    (currentSelection?: string) => {
      callback(currentSelection as TaskCategories);
      setModalData(false, undefined, undefined, () => {});
    },
    [callback, setModalData],
  );

  const onSecondaryButtonClick = useCallback(() => {
    if (currentTaskData?.callback) {
      currentTaskData.callback(true);
    }
  }, [currentTaskData]);

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    const originalCategory = source.droppableId as TaskCategories;
    const updatedCategory = destination.droppableId as TaskCategories;
    dragAndDropTask(
      result.draggableId,
      {
        index: source.index,
        category: originalCategory,
      },
      {
        index: destination.index,
        category: updatedCategory,
      },
      currentUserTimezone,
    );
    const notebookAnalyticsProps = getNotebookAnalyticsProps(
      originalCategory,
      updatedCategory,
    );
    trackNotebookActionEvent({
      action: NOTEBOOK_EVENTS.NOTEBOOK_SECTION_CHANGED,
      ...notebookAnalyticsProps,
    });
  };

  const handlePersistingModalFlag = useCallback(() => {
    setShouldShowModalForCurrentUser(false);
    localStorage.setItem(
      'notebook',
      JSON.stringify({
        [`${currentUserId}`]: {
          showModal: false,
          timestamp: getCurrentDateForTimeZone(currentUserTimezone),
        },
      }),
    );
  }, [currentUserId, currentUserTimezone]);

  return {
    notes,
    isOpen,
    modalProps,
    hasDropdown,
    currentTab,
    onDragEnd,
    setModalData,
    onPrimaryButtonClick,
    onSecondaryButtonClick,
    handleTabChange,
    markTasksAsRead,
    hideNotebookNavigationModal,
    hasCheckbox,
    handleDisableModalPrompts: handlePersistingModalFlag,
    shouldShowModalForCurrentUser,
    erroredTaskIDs,
  };
};

export default useNotebookPageController;
