import React from 'react';
import { Flex } from '../../../Utils/styles/display';
import hideBuilder from '../../../img/hide-builder.svg';
import { BetaLabel, FlowFeedsContainer } from './styles';
import { isMobile, isTablet } from '../../../Utils/window';
import { FLOWS, NEW_FLOWS_BUILDER } from '../../../constants/routes';
import { EDITOR, FEED, INSIGHTS } from '../../../languages/en/singleWords';
import EmptyErrorStateTemplate from '../../../atomic/molecules/EmptyErrorStateTemplate';

import {
  HIDE_BUILDER_BODY,
  HIDE_BUILDER_HEADER,
} from '../../../languages/en/flows/builder';

import FlowsBuilderController from '../FlowsBuilderController';
import FlowsFeedController from '../../flows/FlowsFeedController';
import FlowsInsightsController from '../../flowsInsights/FlowsInsightsController';

const HideFlowsBuilder = () => {
  return (
    <Flex justifyContent="center">
      <EmptyErrorStateTemplate
        image={hideBuilder}
        heading={HIDE_BUILDER_HEADER}
        subHeading={HIDE_BUILDER_BODY}
      />
    </Flex>
  );
};

export const getTabComponents = (flowId: string, showInsightsTab: boolean) => {
  const blockOnMobileAndTab = isMobile || isTablet;

  if (flowId) {
    const tabItems = [
      {
        tabHeader: {
          label: FEED,
          path: `${FLOWS}/${flowId}`,
        },
        tabContent: (
          <FlowFeedsContainer>
            <FlowsFeedController />
          </FlowFeedsContainer>
        ),
      },
      {
        tabHeader: {
          label: EDITOR,
          path: `${FLOWS}/${flowId}/editor`,
        },
        tabContent: blockOnMobileAndTab ? (
          <HideFlowsBuilder />
        ) : (
          <FlowsBuilderController />
        ),
      },
    ];

    if (showInsightsTab) {
      tabItems.splice(1, 0, {
        tabHeader: {
          label: INSIGHTS,
          path: `${FLOWS}/${flowId}/insights`,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          icon: (
            <BetaLabel>
              <div>BETA</div>
            </BetaLabel>
          ),
        },
        tabContent: <FlowsInsightsController />,
      });
    }

    return {
      tabItems,
      isNavigationEnabled: true,
    };
  }

  return {
    tabItems: [
      {
        tabHeader: {
          label: FEED,
          path: `${FLOWS}`,
          disabled: true,
        },
        tabContent: null,
      },
      {
        tabHeader: {
          label: EDITOR,
          path: `${NEW_FLOWS_BUILDER}`,
          disabled: true,
        },
        tabContent: blockOnMobileAndTab ? (
          <HideFlowsBuilder />
        ) : (
          <FlowsBuilderController />
        ),
      },
    ],
    isNavigationEnabled: true,
  };
};
