import { useCallback } from 'react';
import { GOOGLE_RECAPTCHA_ENDPOINT } from '../../config';
import useScript from '../useScript';

export enum GoogleReCaptchaActionTypes {
  EXTERNAL_PARTICIPATION_SUBMISSION = 'EXTERNAL_PARTICIPATION_SUBMISSION',
}

export interface ReCAPTCHA {
  enterprise: {
    execute(
      siteKey: string,
      action: {
        action: GoogleReCaptchaActionTypes;
      },
    ): Promise<void>;
    reset(): () => void;
    ready(fn: () => void): Promise<void>;
  };
}

const useGoogleEnterpriseRecaptcha = (
  siteKey: string,
  action: GoogleReCaptchaActionTypes,
  enabled = true,
) => {
  const src = `${GOOGLE_RECAPTCHA_ENDPOINT}?render=${siteKey}`;
  const { loading } = useScript({ src, enabled });

  const generateToken = useCallback(async () => {
    return new Promise((resolve) => {
      window.grecaptcha?.enterprise?.ready(() => {
        window?.grecaptcha?.enterprise
          ?.execute(siteKey, {
            action,
          })
          .then((token) => {
            resolve(token);
          });
      });
    });
  }, [action, siteKey]);

  return {
    generateToken,
    loading,
  };
};

declare global {
  interface Window {
    grecaptcha?: ReCAPTCHA;
  }
}

export default useGoogleEnterpriseRecaptcha;
