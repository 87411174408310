import { YOU } from '../singleWords';

export const ANNIVERSARY_TEXT = 'Happy Anniversary';

export const BIRTHDAY_TEXT = 'Happy Birthday';

export const SEE_MORE_COMMENTS = 'See more comments';

export const ALL_POSTS = 'All Posts';

export const RELEVANT_POSTS = 'Relevant Posts';

export const CELEBRATE_TEAM_MEMBER = 'Celebrate a Teammate';

export const GET_CELEBRATE_MEMBER = (memberName: string) =>
  `Celebrate ${memberName}`;

export const SEE_NEW_POSTS = 'See new posts';

export const NOT_INVOLVED_IN_POSTS_HEADER = (name: string) =>
  `${name} hasn't given or received any recognition`;

export const YOU_NOT_INVOLVED_IN_POSTS_HEADER = `${YOU} haven't given or received any recognition`;

const BALL_ROLLING = 'Want to get the ball rolling?';

export const NOT_INVOLVED_IN_POSTS_BODY = `${BALL_ROLLING} Send them some!`;

export const YOU_NOT_INVOLVED_IN_POSTS_BODY = `${BALL_ROLLING} Celebrate a teammate!`;

export const COULD_NOT_FIND_POSTS = `We couldn’t find posts matching this search.`;

export const CHANGE_YOUR_SEARCH = 'Change your search and try again.';

export const NO_MATCHING_OPTIONS = 'No members found that match your criteria.';

export const INVITE_MEMBERS = ' Invite team members';

export const EASY_COME_EASY_FLOW = 'Easy come, easy flow';
export const FIND_YOUR_FLOW = 'Find your flow';
export const GET_THIS_FLOW_ON_THE_ROAD = 'Get this flow on the road';
export const GET_YOUR_CREATIVE_JUICES_FLOWING =
  'Get your creative juices flowing';
export const GO_WITH_THE_FLOW = 'Go with the flow';
export const HOW_FLOW_CAN_YOU_GO = 'How flow can you go?';
export const MAKE_LIKE_A_RIVER_AND_FLOW = 'Make like a river and flow';
export const NO_EBBS_JUST_FLOWS = 'No ebbs, just flows';
export const QUID_PRO_FLOW = 'Quid pro flow';
export const SEE_WHERE_IT_FLOWS = 'See where it flows';
export const SEARCH_ANY_WAY_THE_WIND_FLOWS = 'Search any way the wind flows';
export const SEARCH_HIGH_AND_FLOW = 'Search high and flow';
export const WELL_ALL_FLOAT_ON = 'We’ll all flowt on';
export const NOTIFY_EVERY_FLOW_VIEWER = 'Notify every flow viewer';
export const THE_ASSEMBLY = 'The Assembly';

export const VISIBILITY = 'Visibility';
export const DATE_NOT_FOUND = 'Date not found';
export const FILE_NOT_FOUND = 'File not found';
export const SHARED_IN = 'Shared in';
export const MAIN_FEED_ACTION_BAR_ANONYMOUS_TOOLTIP_TEXT =
  'All answers are anonymous';
