import React from 'react';

import ThemeV2 from '../../../componentsV2/theme';
import accountManagementTypes from '../../identity/accountManagementTypes';
import Link from '../../../atomic/atoms/Link';
import routesList from '../../../aV2/routes/routesList';
import {
  accountManagementTypeLanguageMap,
  identityManagerSteps,
} from './common';

const getWizardTitle = (displayName: string) =>
  `Set up ${displayName} as your identity provider`;
const steps = [
  {
    id: identityManagerSteps.WHAT_IS_THIS,
    title: 'Connect',
  },
  {
    id: identityManagerSteps.SELECT_PEOPLE,
    title: 'Select People',
  },
  {
    id: identityManagerSteps.CONFIGURE_INVITES,
    title: 'Configure Invites',
  },
  {
    id: identityManagerSteps.CREATE_ACCOUNTS,
    title: 'Create Accounts',
  },
];

const getIntroInfoItems = (displayName: string) => [
  {
    icon: 'api',
    badgeColor: ThemeV2.palette.cyan7,
    info: `Connecting ${displayName} is a simple way of importing people from your business into Assembly.`,
  },
  {
    icon: 'team',
    badgeColor: ThemeV2.palette.geekBlue6,
    info: 'Choose whether to import everyone, or select specific people.',
  },
  {
    icon: 'sync',
    badgeColor: ThemeV2.palette.yellow2,
    info: `When you add or remove people from ${displayName}, we'll automatically add or remove them from Assembly.`,
  },
  {
    icon: 'idcard',
    badgeColor: ThemeV2.palette.brown,
    info: `Update people's profile info in ${displayName} and we'll sync these changes on Assembly too.`,
  },
];

const getIntroHeading = (displayName: string) =>
  `How does the ${displayName} connection work?`;
const introSecondHeading = 'Permission requirements';
const getRecommendation = (displayName: string) =>
  `You must be a ${displayName} admin to connect Assembly with your ${displayName} account.`;

const progressHeading =
  'Assembly is gathering your team members, please give us a moment';

const getUserSelectHeading = (displayName: string) =>
  `Who do you want to add from ${displayName}?`;
const userSelectSubHeading =
  "We'll keep your details in sync if you choose an automated option";
const getUserSelectRadioGroupOptions = (displayName: string) => [
  {
    value: accountManagementTypes.ALL_USERS,
    label: `Everyone that joins or leaves the ${displayName} directory`,
  },
  {
    value: accountManagementTypes.BUSINESS_UNIT,
    label: `Anyone that joins or leaves a specific ${displayName} ${
      accountManagementTypeLanguageMap[accountManagementTypes.BUSINESS_UNIT]
    }`,
  },
  {
    value: accountManagementTypes.DEPARTMENT,
    label: `Anyone that joins or leaves a specific ${displayName} ${
      accountManagementTypeLanguageMap[accountManagementTypes.DEPARTMENT]
    }`,
  },
  {
    value: accountManagementTypes.WORK_LOCATION,
    label: `Anyone that joins or leaves a specific ${displayName} ${
      accountManagementTypeLanguageMap[accountManagementTypes.WORK_LOCATION]
    }`,
  },
  {
    value: accountManagementTypes.GROUPS,
    label: `Anyone that joins or leaves a specific ${displayName} ${
      accountManagementTypeLanguageMap[accountManagementTypes.GROUPS]
    }`,
  },
  {
    value: accountManagementTypes.CHANNELS,
    label: `Anyone that joins or leaves a specific ${displayName} ${
      accountManagementTypeLanguageMap[accountManagementTypes.CHANNELS]
    }`,
  },
  {
    value: accountManagementTypes.SELECTED_USERS,
    label: 'I want to manage all members manually on Assembly',
  },
];

const invitesHeading = 'Ready to send your invitations?';
const invitesSubHeading = `Once accounts are created, you'll need to send invitations so
people can access Assembly. Decide whether to send these invitations now or later`;
const getInvitesRadioGroupOptions = (displayName: string) => [
  {
    value: true,
    labelHeader: 'Send Invitations Now',
    label: ` – Send them now, and whenever someone is added to your ${displayName}, they'll automatically
    be sent an invitation too.`,
  },
  {
    value: false,
    labelHeader: `I'll send them later`,
    label: ` – If you choose to send them later, we'll create the accounts and put the invitations
    in your Invites page, so you can send them when you're ready.`,
  },
];

const approvalHeading =
  'Auto approve all new members or require invite and request approvals?';
const getApprovalSubHeading = (
  displayName: string,
) => `When new members join your ${displayName} directory, we can either
send you and admins an approval email or we can immediately send the member an invite`;
const getApprovalRadioGroupOptions = (displayName: string) => [
  {
    value: true,
    labelHeader: 'Auto approve new members',
    label: ` – When we see a new ${displayName} member that fits the correct criteria, we’ll send them an
    invite and they’ll have access when they accept`,
  },
  {
    value: false,
    labelHeader: `Admin approval only`,
    label: ` – When we see a new ${displayName} member that fits the correct criteria, we’ll send you and
    admins a request to explicitly approve first`,
  },
];

const createAccountsHeading = 'Creating your Assembly accounts';
const createAccountsSubHeading = `This may take a while. You can leave this page and continue with other tasks.
 Invites will be sent once we’re done creating everyone’s account`;
const createAccountsSubHeadingQueued = (
  <>
    This may take a while. You can leave this page and continue with other
    tasks. Go <Link to={routesList.ADMIN_USER_INVITE}>here</Link> to send out
    invitations for people to activate their new accounts when ready
  </>
);

const getGIFLoadingModal = (displayName: string) => ({
  HEADING: 'Hold tight!',
  MAIN_CONTENT: `We’re setting up your ${displayName} connection.`,
  SUB_CONTENT: `It might take a minute to get ${displayName} on the line, so thanks for holding tight!`,
  GIF_URL: 'https://media.giphy.com/media/SWVzkIlHdEckF81gnA/giphy.gif',
});

export const getIMLanguages = (displayName: string) => ({
  stepsWizardTitle: getWizardTitle(displayName),
  steps,
  introInfoItems: getIntroInfoItems(displayName),
  introHeading: getIntroHeading(displayName),
  introSecondHeading,
  recommendation: getRecommendation(displayName),
  progressHeading,
  userSelectHeading: getUserSelectHeading(displayName),
  userSelectSubHeading,
  userSelectRadioGroupOptions: getUserSelectRadioGroupOptions(displayName),
  invitesHeading,
  invitesSubHeading,
  invitesRadioGroupOptions: getInvitesRadioGroupOptions(displayName),
  approvalHeading,
  approvalSubHeading: getApprovalSubHeading(displayName),
  approvalRadioGroupOptions: getApprovalRadioGroupOptions(displayName),
  createAccountsHeading,
  createAccountsSubHeading,
  createAccountsSubHeadingQueued,
  gifLoadingModal: getGIFLoadingModal(displayName),
});
