import React, { memo, useCallback, useMemo } from 'react';
import StarRating from '../../atoms/StarRating';
import TwoColumnModal from '../../molecules/TwoColumnModal';
import {
  RewardImage,
  RewardDropdown,
  ContentLeftWrapper,
  ContentLeftFooterButtonWrapper,
  ContentLeftWrapperInner,
  RewardSuccessHeading,
  RewardSuccessTitle,
  StarRatingContainer,
  StarRatingContainerText,
  StarRatingWrapper,
  RewardSuccessRatedHeading,
  RewardSuccessRatedText,
  StyledRewardsModalVerticalMiddleContent,
  ContentLeftWrapperInnerBeforeRedeem,
  SwagDetailsWrapper,
  StyledAutoComplete,
  FormikWrapper,
  Wrapper,
  SelectedRewardImage,
  StyledHeading,
  StyledFormikField,
  ConfirmationScreenWrapper,
  StyledBody,
  RedeemScreenWrapper,
  StyledInfoAlert,
} from './styles';
import { RedeemSelectModalProps, ShippingStatus } from './interface';
import { DropdownColor } from '../../molecules/Dropdown_V2/interfaces';
import Button from '../../atoms/Button';
import {
  REWARDS_CLOSE,
  REWARDS_REDEEM,
  REWARDS_REDEEM_SUCCESS_COMPLETED_RATING_HEADING,
  REWARDS_REDEEM_SUCCESS_COMPLETED_RATING_TEXT,
  REWARDS_REDEEM_SUCCESS_RATING_TEXT,
  PLACE_ORDER,
  CONTINUE_TO_SHIPPING,
  CHOOSE_A_SIZE,
  QUANTITY,
  BUTTON_TEXT_NEXT,
  LEAVE_CHECKOUT_TEXT,
  LEAVE_CHECKOUT_HEADING,
  LEAVE_CHECKOUT,
  GO_BACK,
  REDEEM_CONTENT_MODAL_TEXTS,
  MY_REWARDS,
  CHOOSE_COLOR,
} from '../../../languages/en/rewards';

import rewardRedeemImage from '../../../img/svgs/RedeemRewardRatedBG.svg';
import Image from '../../atoms/Image';
import RedeemModalHeaderMolecule from '../../molecules/RedeemModalHeaderMolecule';
import BasicModalTemplate from '../../templates/BasicModalTemplate';
import { Flex } from '../../../Utils/styles/display';
import Body from '../../atoms/Body';
import Heading from '../../atoms/Heading';
import AssemblyCurrencyIcon from '../../atoms/AssemblyCurrencyIcon';
import { BACK } from '../../../languages/en/singleWords';
import ClickableLink from '../../molecules/ClickableLink';
import FormikField from '../../molecules/FormikField';

const RedeemSelectModal = (props: RedeemSelectModalProps) => {
  const {
    id,
    isOpen,
    onClose,
    dropdownItems,
    rewardTitle,
    rewardImage,
    rewardCost,
    assemblyCurrency,
    rewardDescription,
    label,
    hasRedeemed,
    onDropdownSelect,
    dropdownValue,
    onActionButtonClick,
    rewardSuccessHeading,
    rewardSuccessTitle,
    rewardSuccessContent,
    isRedeemButtonDisabled,
    redeemPolicyContent,
    isRewardRated,
    rewardRating = 0,
    handleRewardRatingChange,
    isLoadingPostRedeem = false,
    getItemLabel,
    rewardType,
    shippingDetails,
    swagSizeProps,
    swagQuantityProps,
    swagColorProps,
    shippingCost,
  } = props;

  const handleOnActionButtonClick = useCallback(() => {
    onActionButtonClick(id);
  }, [id, onActionButtonClick]);

  const handleOnDropdownItemClick = useCallback(
    (dropdownItemId: number | string) => {
      if (onDropdownSelect) {
        onDropdownSelect(dropdownItemId);
      }
    },
    [onDropdownSelect],
  );

  const handleContentLeftButtonClick = useCallback(() => {
    if (hasRedeemed) {
      onClose();
    } else handleOnActionButtonClick();
  }, [hasRedeemed, handleOnActionButtonClick, onClose]);

  const mainButtonText = useMemo(() => {
    return hasRedeemed ? REWARDS_CLOSE : REWARDS_REDEEM;
  }, [hasRedeemed]);

  const primaryButtonText = useMemo(() => {
    switch (shippingDetails?.shippingStatus) {
      case ShippingStatus.SWAG_DETAILS:
        return CONTINUE_TO_SHIPPING;
      case ShippingStatus.SHIPPING_ADDRESS:
        return BUTTON_TEXT_NEXT;
      case ShippingStatus.ORDER_DETAILS_CONFIRMATION:
        return PLACE_ORDER;
      case ShippingStatus.SUCCESSFULLY_REDEEMED:
        return REWARDS_CLOSE;
      default:
        return '';
    }
  }, [shippingDetails?.shippingStatus]);

  const infoAlertTextNode = useMemo(() => {
    return (
      <Body variant="body2">
        {REDEEM_CONTENT_MODAL_TEXTS.CHECK_STATUS}{' '}
        <ClickableLink
          onClickCallback={() => {
            shippingDetails?.handleMyRewardsButtonClick();
          }}
        >
          {MY_REWARDS}
        </ClickableLink>{' '}
        {REDEEM_CONTENT_MODAL_TEXTS.TRACK_STATUS}
      </Body>
    );
  }, [shippingDetails]);

  const renderConfirmationAndRedeemContent = useMemo(() => {
    return (
      <ConfirmationScreenWrapper>
        <Heading marginBottom="12px" marginTop="0">
          {shippingDetails?.shippingStatus ===
          ShippingStatus.SUCCESSFULLY_REDEEMED
            ? REDEEM_CONTENT_MODAL_TEXTS.CONGRATS
            : REDEEM_CONTENT_MODAL_TEXTS.CONFIRM}
        </Heading>
        {rewardImage && (
          <Image
            alt={rewardTitle}
            src={rewardImage}
            width="80px"
            height="80px"
          />
        )}
        <StyledBody variant="body1Bold">{rewardTitle}</StyledBody>
        <Body variant="body3">
          {REDEEM_CONTENT_MODAL_TEXTS.TOTAL_COST}
          <AssemblyCurrencyIcon
            assemblyCurrency={assemblyCurrency}
            currencyName={assemblyCurrency.name}
            id={assemblyCurrency.name}
            size="12px"
          />
          {rewardCost &&
            swagQuantityProps?.quantity?.title &&
            shippingCost &&
            rewardCost * parseInt(swagQuantityProps?.quantity?.title) +
              shippingCost}
        </Body>
        <Body variant="body3">
          {REDEEM_CONTENT_MODAL_TEXTS.GET_QUANTITY_SIZE_TEXT(
            swagQuantityProps?.quantity?.title,
          )}
          {swagSizeProps &&
            swagSizeProps?.sizes.length > 0 &&
            REDEEM_CONTENT_MODAL_TEXTS.GET_SIZE_TEXT(
              swagSizeProps?.size?.title,
            )}
        </Body>
        <StyledBody variant="body2Bold">
          {REDEEM_CONTENT_MODAL_TEXTS.SENT_TO}
        </StyledBody>
        <Body variant="body3">
          {`${shippingDetails?.firstName} ${shippingDetails?.lastName}`}
        </Body>
        <Body variant="body3">
          {`${shippingDetails?.shippingFormik.values.address} ${shippingDetails?.shippingFormik.values.apartment}`}{' '}
        </Body>
        <Body variant="body3">
          {/*eslint-disable-next-line max-len*/}
          {`${shippingDetails?.shippingFormik.values.city}, ${shippingDetails?.shippingFormik.values.state?.title} ${shippingDetails?.shippingFormik.values.pincode}`}{' '}
        </Body>
        {shippingDetails?.shippingStatus ===
          ShippingStatus.SUCCESSFULLY_REDEEMED && (
          <StyledInfoAlert
            id="redeemedStatus"
            text={infoAlertTextNode}
            icon="info-circle"
            alertType="info"
          />
        )}
      </ConfirmationScreenWrapper>
    );
  }, [
    shippingDetails?.shippingStatus,
    shippingDetails?.shippingFormik.values,
    rewardImage,
    rewardTitle,
    assemblyCurrency,
    rewardCost,
    swagQuantityProps?.quantity?.title,
    shippingCost,
    infoAlertTextNode,
    shippingDetails?.firstName,
    shippingDetails?.lastName,
    swagSizeProps,
  ]);

  const quantitySelected = useMemo(() => {
    if (swagQuantityProps?.quantity?.title) {
      return swagQuantityProps?.quantity?.title === '0'
        ? 1
        : parseInt(swagQuantityProps?.quantity?.title);
    }
    return 1;
  }, [swagQuantityProps?.quantity?.title]);

  const rewardCostWithShippingCost = useMemo(() => {
    if (quantitySelected && shippingCost && rewardCost) {
      return String(rewardCost * quantitySelected + shippingCost);
    } else if (quantitySelected && rewardCost) {
      return String(rewardCost * quantitySelected);
    }
    return '0';
  }, [quantitySelected, rewardCost, shippingCost]);

  const shippingCostDetails = useMemo(() => {
    if (shippingCost && shippingCost !== 0) {
      return (
        <>
          <AssemblyCurrencyIcon
            assemblyCurrency={assemblyCurrency}
            currencyName={assemblyCurrency.name}
            id={assemblyCurrency.name}
            size="14px"
          />
          {rewardCost && rewardCost * quantitySelected} (swag)&nbsp; +&nbsp;
          <AssemblyCurrencyIcon
            assemblyCurrency={assemblyCurrency}
            currencyName={assemblyCurrency.name}
            id={assemblyCurrency.name}
            size="14px"
          />
          {shippingCost} (shipping)
        </>
      );
    }
  }, [assemblyCurrency, quantitySelected, rewardCost, shippingCost]);

  const renderShippingNotRedeemedContent = useMemo(() => {
    if (shippingDetails?.shippingStatus === ShippingStatus.SWAG_DETAILS) {
      return (
        <SwagDetailsWrapper>
          <RedeemModalHeaderMolecule
            image={rewardImage}
            title={rewardTitle}
            cost={rewardCostWithShippingCost}
            assemblyCurrency={assemblyCurrency}
            shippingCostDetails={shippingCostDetails}
          />
          {swagSizeProps && swagSizeProps.sizes.length > 0 && (
            <StyledAutoComplete
              label={CHOOSE_A_SIZE}
              onChange={swagSizeProps.handleSizeChange}
              options={swagSizeProps.sizes}
              value={swagSizeProps.size || null}
              dataTestId="chooseSize"
              showClearIcon={false}
            />
          )}
          {swagQuantityProps && (
            <StyledAutoComplete
              label={QUANTITY}
              onChange={swagQuantityProps.handleQuantityChange}
              options={swagQuantityProps.availableQuantities}
              value={swagQuantityProps.quantity || null}
              dataTestId="chooseQuantity"
              showClearIcon={false}
            />
          )}
          {swagColorProps && (
            <StyledAutoComplete
              label={CHOOSE_COLOR}
              onChange={swagColorProps.handleColorChange}
              options={swagColorProps.colors}
              value={swagColorProps.color || null}
              dataTestId="chooseColor"
              showClearIcon={false}
            />
          )}
        </SwagDetailsWrapper>
      );
    }

    if (shippingDetails?.shippingStatus === ShippingStatus.SHIPPING_ADDRESS) {
      return (
        <>
          <Wrapper>
            <Flex>
              {rewardImage && (
                <SelectedRewardImage alt={rewardTitle} src={rewardImage} />
              )}
              <div>
                <Body variant="body2Bold">
                  {REDEEM_CONTENT_MODAL_TEXTS.GET_SHIPPING_ADDRESS_TITLE(
                    swagQuantityProps?.quantity?.title,
                    rewardTitle,
                  )}
                  {swagSizeProps &&
                    swagSizeProps?.sizes.length > 0 &&
                    REDEEM_CONTENT_MODAL_TEXTS.GET_SIZE_TEXT(
                      swagSizeProps?.size?.title,
                    )}
                </Body>
                <Body variant="body3">
                  {REDEEM_CONTENT_MODAL_TEXTS.TOTAL_COST}
                  <AssemblyCurrencyIcon
                    assemblyCurrency={assemblyCurrency}
                    currencyName={assemblyCurrency.name}
                    id={assemblyCurrency.name}
                    size="12px"
                  />
                  {rewardCostWithShippingCost}
                </Body>
              </div>
            </Flex>
            <div>
              <StyledHeading variant="h5">
                {REDEEM_CONTENT_MODAL_TEXTS.SHIPPING_ADDRESS}
              </StyledHeading>
              <FormikWrapper>
                <form
                  onSubmit={shippingDetails.shippingFormik.handleSubmit}
                  autoComplete="off"
                  name="lastpass-disable-search"
                >
                  <StyledFormikField
                    formik={shippingDetails.shippingFormik}
                    type="singleSelectAutoComplete"
                    name="country"
                    label={
                      shippingDetails.countriesData.length > 1 &&
                      'Country/Region*'
                    }
                    hasDropdown
                    disableClearButton
                    dropdownItems={shippingDetails.countriesData}
                    autoComplete="none"
                    disabled={
                      shippingDetails.countriesData.length === 1 ||
                      !shippingDetails.countriesData.length
                    }
                    helperText={
                      shippingDetails.countriesData.length === 1 ||
                      !shippingDetails.countriesData.length
                        ? 'International shipping is not available for this item'
                        : ''
                    }
                  />
                  <StyledFormikField
                    formik={shippingDetails.shippingFormik}
                    type="textV3_text"
                    name="fullName"
                    label="Full name*"
                    disableClearButton
                    autoComplete="none"
                  />
                  <StyledFormikField
                    formik={shippingDetails.shippingFormik}
                    type="textV3_text"
                    name="company"
                    label="Company (optional)"
                    disableClearButton
                    autoComplete="none"
                  />
                  <StyledFormikField
                    formik={shippingDetails.shippingFormik}
                    type="textV3_text"
                    name="address"
                    label="Address*"
                    disableClearButton
                    autoComplete="none"
                  />
                  <StyledFormikField
                    formik={shippingDetails.shippingFormik}
                    type="textV3_text"
                    name="apartment"
                    label="Apt, suite, etc (optional)"
                    disableClearButton
                    autoComplete="none"
                  />
                  <Flex
                    height={
                      shippingDetails.isCityOrStateError ? '68px' : '40px'
                    }
                    alignItems="flex-start"
                    margin="0 0 16px 0"
                  >
                    <FormikField
                      formik={shippingDetails.shippingFormik}
                      type="textV3_text"
                      name="city"
                      label="City*"
                      disableClearButton
                      autoComplete="none"
                    />
                    <StyledFormikField
                      formik={shippingDetails.shippingFormik}
                      type="singleSelectAutoComplete"
                      name="state"
                      label="State/Province*"
                      hasDropdown
                      disableClearButton
                      dropdownItems={shippingDetails.statesData}
                      marginLeft="8px"
                      disabled={shippingDetails.statesData.length === 0}
                      autoComplete="none"
                      expandableDropdown
                    />
                  </Flex>
                  <StyledFormikField
                    formik={shippingDetails.shippingFormik}
                    type="textV3_text"
                    name="pincode"
                    label="ZIP code*"
                    disableClearButton
                    autoComplete="none"
                  />
                  <StyledFormikField
                    formik={shippingDetails.shippingFormik}
                    type="textV3_text"
                    name="phoneNumber"
                    label="Phone number"
                    disableClearButton
                    autoComplete="none"
                  />
                </form>
              </FormikWrapper>
            </div>
          </Wrapper>
        </>
      );
    }

    if (
      shippingDetails?.shippingStatus ===
      ShippingStatus.ORDER_DETAILS_CONFIRMATION
    ) {
      return renderConfirmationAndRedeemContent;
    }

    if (
      shippingDetails?.shippingStatus === ShippingStatus.SUCCESSFULLY_REDEEMED
    ) {
      return (
        <RedeemScreenWrapper>
          {renderConfirmationAndRedeemContent}
        </RedeemScreenWrapper>
      );
    }

    return <></>;
  }, [
    shippingDetails,
    rewardImage,
    rewardTitle,
    rewardCost,
    assemblyCurrency,
    shippingCost,
    swagSizeProps,
    swagQuantityProps,
    swagColorProps,
    renderConfirmationAndRedeemContent,
    rewardCostWithShippingCost,
    shippingCostDetails,
  ]);

  const renderModalLeftContent = useCallback(() => {
    let modalContentLeft;
    if (!hasRedeemed) {
      modalContentLeft = (
        <ContentLeftWrapperInnerBeforeRedeem rewardType={rewardType}>
          <RedeemModalHeaderMolecule
            image={rewardImage}
            title={rewardTitle}
            cost={rewardCost}
            description={rewardDescription}
            assemblyCurrency={assemblyCurrency}
          />

          {dropdownItems && (
            <RewardDropdown
              value={dropdownValue}
              menuItems={dropdownItems}
              isFullWidth
              color={DropdownColor.Secondary}
              onItemClick={handleOnDropdownItemClick}
              multiSelect={false}
              getItemLabel={getItemLabel}
            />
          )}
        </ContentLeftWrapperInnerBeforeRedeem>
      );
    } else {
      modalContentLeft = (
        <ContentLeftWrapperInner>
          <RewardSuccessHeading variant="h5">
            {rewardSuccessHeading}
          </RewardSuccessHeading>
          {rewardImage && <RewardImage src={rewardImage} alt={rewardTitle} />}
          {rewardSuccessTitle && (
            <RewardSuccessTitle variant="body1Bold">
              {rewardSuccessTitle}
            </RewardSuccessTitle>
          )}
          {rewardSuccessContent && <div>{rewardSuccessContent}</div>}
        </ContentLeftWrapperInner>
      );
    }
    return (
      <ContentLeftWrapper>
        {modalContentLeft}
        <ContentLeftFooterButtonWrapper>
          <Button
            isFullWidth
            disabled={!hasRedeemed && isRedeemButtonDisabled}
            color={hasRedeemed ? 'secondary' : 'primary'}
            onClick={handleContentLeftButtonClick}
            dataTestId="redeem-button"
            isLoading={isLoadingPostRedeem}
          >
            {mainButtonText}
          </Button>
        </ContentLeftFooterButtonWrapper>
      </ContentLeftWrapper>
    );
  }, [
    assemblyCurrency,
    dropdownItems,
    dropdownValue,
    getItemLabel,
    handleOnDropdownItemClick,
    hasRedeemed,
    isLoadingPostRedeem,
    isRedeemButtonDisabled,
    rewardCost,
    rewardDescription,
    rewardImage,
    rewardSuccessContent,
    rewardSuccessHeading,
    rewardSuccessTitle,
    rewardTitle,
    rewardType,
    handleContentLeftButtonClick,
    mainButtonText,
  ]);

  const renderShippingModalLeftContent = useCallback(() => {
    return (
      <ContentLeftWrapper>
        {renderShippingNotRedeemedContent}
        <ContentLeftFooterButtonWrapper>
          {(shippingDetails?.shippingStatus ===
            ShippingStatus.SHIPPING_ADDRESS ||
            shippingDetails?.shippingStatus ===
              ShippingStatus.ORDER_DETAILS_CONFIRMATION) && (
            <Button
              isFullWidth
              disabled={
                (!hasRedeemed && isRedeemButtonDisabled) || isLoadingPostRedeem
              }
              color="secondary"
              onClick={shippingDetails.handleGoBackButtonClick}
              dataTestId="back-button"
              isLoading={isLoadingPostRedeem}
            >
              {BACK}
            </Button>
          )}
          <Button
            isFullWidth
            disabled={
              (!hasRedeemed && isRedeemButtonDisabled) ||
              (shippingDetails?.shippingStatus ===
                ShippingStatus.SHIPPING_ADDRESS &&
                !(
                  shippingDetails?.shippingFormik.isValid &&
                  shippingDetails.shippingFormik.dirty
                )) ||
              (swagSizeProps &&
                swagSizeProps?.sizes.length > 0 &&
                !swagSizeProps?.size?.name) ||
              !swagQuantityProps?.quantity?.name ||
              !swagColorProps?.color?.name
            }
            color={hasRedeemed ? 'secondary' : 'primary'}
            onClick={
              hasRedeemed ? onClose : shippingDetails?.handlePrimaryButtonClick
            }
            dataTestId="redeem-button"
            isLoading={isLoadingPostRedeem}
          >
            {primaryButtonText}
          </Button>
        </ContentLeftFooterButtonWrapper>
      </ContentLeftWrapper>
    );
  }, [
    renderShippingNotRedeemedContent,
    shippingDetails?.shippingStatus,
    shippingDetails?.handleGoBackButtonClick,
    shippingDetails?.shippingFormik,
    shippingDetails?.handlePrimaryButtonClick,
    hasRedeemed,
    isRedeemButtonDisabled,
    isLoadingPostRedeem,
    swagSizeProps,
    swagQuantityProps?.quantity?.name,
    swagColorProps?.color?.name,
    onClose,
    primaryButtonText,
  ]);

  const renderModalRightContent = useCallback(() => {
    if (!hasRedeemed) {
      return redeemPolicyContent;
    }

    if (!isRewardRated) {
      return (
        <StarRatingContainer>
          <StyledRewardsModalVerticalMiddleContent>
            <StarRatingContainerText variant="body2Bold">
              {REWARDS_REDEEM_SUCCESS_RATING_TEXT}
            </StarRatingContainerText>
            <StarRatingWrapper data-testid="reward-star-rating">
              <StarRating
                handleRatingChange={handleRewardRatingChange}
                value={rewardRating}
                name="reward-star-rating"
              />
            </StarRatingWrapper>
          </StyledRewardsModalVerticalMiddleContent>
        </StarRatingContainer>
      );
    }
    return (
      <StarRatingContainer>
        <StyledRewardsModalVerticalMiddleContent>
          <Image
            src={rewardRedeemImage}
            alt="reward redeem success rated image"
          />
          <RewardSuccessRatedHeading variant="h5">
            {REWARDS_REDEEM_SUCCESS_COMPLETED_RATING_HEADING}
          </RewardSuccessRatedHeading>
          <RewardSuccessRatedText variant="body2">
            {REWARDS_REDEEM_SUCCESS_COMPLETED_RATING_TEXT}
          </RewardSuccessRatedText>
        </StyledRewardsModalVerticalMiddleContent>
      </StarRatingContainer>
    );
  }, [
    handleRewardRatingChange,
    hasRedeemed,
    isRewardRated,
    redeemPolicyContent,
    rewardRating,
  ]);

  return (
    <>
      <TwoColumnModal
        isOpen={isOpen}
        onClose={() => {
          if (
            shippingDetails?.shippingStatus ===
              ShippingStatus.SHIPPING_ADDRESS ||
            shippingDetails?.shippingStatus ===
              ShippingStatus.ORDER_DETAILS_CONFIRMATION
          ) {
            shippingDetails?.handleSecondaryButtonClick();
          } else {
            onClose();
          }
        }}
        contentLeft={
          shippingDetails
            ? renderShippingModalLeftContent()
            : renderModalLeftContent()
        }
        contentRight={renderModalRightContent()}
        label={label}
        height={
          shippingDetails?.shippingStatus === ShippingStatus.SHIPPING_ADDRESS
            ? '706px'
            : '600px'
        }
        contentRightHeight={
          shippingDetails?.shippingStatus === ShippingStatus.SHIPPING_ADDRESS
            ? '706px'
            : '600px'
        }
      />
      {shippingDetails && (
        <BasicModalTemplate
          isOpen={shippingDetails.secondaryModalOpen}
          onModalClose={shippingDetails.onSecondaryModalClose}
          heading={LEAVE_CHECKOUT_HEADING}
          primaryButton={{
            color: 'primary',
            status: 'warning',
            text: LEAVE_CHECKOUT,
            onClick: () => {
              shippingDetails.onSecondaryModalClose();
              onClose();
            },
          }}
          secondaryButton={{
            color: 'secondary',
            text: GO_BACK,
            onClick: () => {
              shippingDetails.onSecondaryModalClose();
              shippingDetails.handleGoBackButtonClick();
            },
          }}
        >
          {LEAVE_CHECKOUT_TEXT}
        </BasicModalTemplate>
      )}
    </>
  );
};
const MemoizedRedeemSelectModal = memo(RedeemSelectModal);
MemoizedRedeemSelectModal.displayName = 'RedeemSelectModal';
export default MemoizedRedeemSelectModal;
