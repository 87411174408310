import React, { memo, useEffect, useMemo, useState } from 'react';

import { Collapse } from '@material-ui/core';
import {
  GiveRecognitionFormBottomButtonsProps,
  PostType,
} from '../../molecules/GiveRecognitionFormBottomButtons/interface';
import GifContainer from '../../molecules/GifContainer';
import Editor, { EditorProps } from '../RichTextEditor';
import mentionsStyles from '../RichTextEditor/mentionsStyles.module.css';
import editorStyles from './editor.module.css';

import ThemeV2 from '../../../componentsV2/theme';
import { Flex } from '../../../Utils/styles/display';
import Avatar from '../../atoms/Avatar';
import { ButtonSize } from '../../atoms/IconButton_V2/interface';
import { EditorPlaceholder as EditorPlaceholderForComments } from '../../../languages/en/commentsForm/index';
import { AddTrophiesInputProps } from '../../molecules/AddTrophiesInput';
import { GiveRecognitionTipsProps } from '../../molecules/GiveRecognitionTips';

import { PostInputBaseProps } from './interface';
import {
  StyledFormButtons,
  CommentWrapper,
  StyledGifContainer,
  StyledBody,
  StyledGiveRecognitionTips,
  RecognitionSectionWrapper,
  RecognitionEditorWrapper,
  InputErrorMessageBody,
  StyledIconButton,
} from './styles';

export type PostInputProps = PostInputBaseProps &
  GiveRecognitionFormBottomButtonsProps &
  AddTrophiesInputProps &
  EditorProps &
  GiveRecognitionTipsProps & {
    onCommentButtonClick?: () => void;
  };

const PostInput = (props: PostInputProps) => {
  const {
    containerRef,
    currentUser,
    hasText = false,
    isEditorFocused = false,
    showRecognitionTips,
    selectedGif,
    onGifRemoveClick,
    inputErrorMessage,
    isDummy = false,
    setEditorFocus,
  }: PostInputBaseProps = props;
  const {
    postType = PostType.Recognition,
    onAtMentionClick,
    onEmoticonClick,
    onGifSelectorClick,
    onAttachmentClick,
    isGifSelected,
    isAttachmentSelected,
    isPrivateMessageSelected,
    onPrivateMessageClick,
    onPostClick,
    showAddTrophy,
    isCreatingPostOrComment,
    isAttachmentButtonHidden,
    isPrivatePostButtonHidden,
    gifRatings,
    buttonColor,
  }: GiveRecognitionFormBottomButtonsProps = props;
  const {
    value,
    maxValue,
    buttonText,
    assemblyCurrency,
    menuItems,
    splitBy,
    canAllowCustomAmount,
    onBlur,
    onChange,
    onItemClick,
  }: AddTrophiesInputProps = props;
  const {
    editorState,
    editorRefElement,
    onAddMention,
    onEditorBlur,
    onEditorChange,
    onEditorFocus,
    suggestions,
    onSearchChange,
    isMentionsLoading,
  }: EditorProps = props;
  const { isOpen, onToggleClick, onToggleKeyPress }: GiveRecognitionTipsProps =
    props;

  const recognitionEditor = useMemo(() => {
    if (postType === PostType.Recognition) {
      return (
        <>
          <Flex padding="16px 16px 0">
            <Avatar size="24px" {...currentUser} />
            <StyledBody variant="body2" color={ThemeV2.palette.gray9}>
              {currentUser.name}
            </StyledBody>
          </Flex>
          <RecognitionEditorWrapper onClick={setEditorFocus}>
            <Editor
              editorRefElement={editorRefElement}
              editorState={editorState}
              onEditorChange={onEditorChange}
              suggestions={suggestions}
              onAddMention={onAddMention}
              onSearchChange={onSearchChange}
              onEditorFocus={onEditorFocus}
              onEditorBlur={onEditorBlur}
              isMentionsLoading={isMentionsLoading}
            />
            {inputErrorMessage && (
              <InputErrorMessageBody variant="body3" color="red6">
                {inputErrorMessage}
              </InputErrorMessageBody>
            )}
          </RecognitionEditorWrapper>
        </>
      );
    }
    return null;
  }, [
    postType,
    currentUser,
    editorRefElement,
    editorState,
    onEditorChange,
    suggestions,
    isMentionsLoading,
    inputErrorMessage,
    onAddMention,
    onSearchChange,
    onEditorFocus,
    onEditorBlur,
    setEditorFocus,
  ]);

  const [hasAttachment, setAttachment] = useState(!!selectedGif);

  useEffect(() => {
    setAttachment(!!selectedGif);
  }, [selectedGif]);

  const renderIconButton = useMemo(
    () => (
      <StyledIconButton
        icon="email-send"
        dataTestId="createComment"
        size={ButtonSize.Normal}
        rounded
        color="primary"
        disabled={(!hasText || isCreatingPostOrComment) && !isGifSelected}
        onClick={onPostClick}
      />
    ),
    [hasText, isCreatingPostOrComment, isGifSelected, onPostClick],
  );

  const isFormButtonCollapsed =
    postType === PostType.Comment
      ? isEditorFocused || hasText || hasAttachment
      : true;

  return (
    <div ref={containerRef}>
      {postType === PostType.Comment && selectedGif !== '' && (
        <StyledGifContainer
          src={selectedGif}
          onGifRemoveClick={onGifRemoveClick}
        />
      )}
      <RecognitionSectionWrapper>
        {recognitionEditor}
        {postType === PostType.Recognition && selectedGif !== '' && (
          <GifContainer src={selectedGif} onGifRemoveClick={onGifRemoveClick} />
        )}
      </RecognitionSectionWrapper>
      <CommentWrapper
        postType={postType}
        isEditorFocused={isEditorFocused}
        hasText={hasText}
        hasAttachment={hasAttachment}
      >
        {postType === PostType.Comment && (
          <Flex justifyContent="space-between" alignItems="flex-start">
            <Avatar size="32px" {...currentUser} />
            <Editor
              editorRefElement={editorRefElement}
              editorState={editorState}
              onEditorChange={onEditorChange}
              suggestions={suggestions}
              onAddMention={onAddMention}
              onSearchChange={onSearchChange}
              onEditorFocus={onEditorFocus}
              onEditorBlur={onEditorBlur}
              mentionsStyles={mentionsStyles}
              editorStyles={editorStyles}
              editorPlaceholder={EditorPlaceholderForComments}
              isMentionsLoading={isMentionsLoading}
            />
            {/* How to show Error message must be redesigned */}
            {/* {inputErrorMessage && (
              <InputErrorMessageBody variant="body3" color="red6">
                {inputErrorMessage}
              </InputErrorMessageBody>
            )} */}
            {renderIconButton}
          </Flex>
        )}
        {postType === PostType.Recognition && showRecognitionTips && (
          <StyledGiveRecognitionTips
            isOpen={isOpen}
            onToggleClick={onToggleClick}
            onToggleKeyPress={onToggleKeyPress}
          />
        )}
        <Collapse in={isFormButtonCollapsed}>
          {!isDummy && (
            <StyledFormButtons
              postType={postType}
              isGifSelected={isGifSelected}
              isAttachmentSelected={isAttachmentSelected}
              isPrivateMessageSelected={isPrivateMessageSelected}
              onAtMentionClick={onAtMentionClick}
              onEmoticonClick={onEmoticonClick}
              onGifSelectorClick={onGifSelectorClick}
              onAttachmentClick={onAttachmentClick}
              onPrivateMessageClick={onPrivateMessageClick}
              onPostClick={onPostClick}
              isCreatingPostOrComment={isCreatingPostOrComment}
              value={value}
              onChange={onChange}
              maxValue={maxValue}
              buttonText={buttonText}
              menuItems={menuItems}
              splitBy={splitBy}
              canAllowCustomAmount={canAllowCustomAmount}
              assemblyCurrency={assemblyCurrency}
              disabled={!hasText}
              showAddTrophy={showAddTrophy}
              onBlur={onBlur}
              onItemClick={onItemClick}
              isAttachmentButtonHidden={isAttachmentButtonHidden}
              isPrivatePostButtonHidden={isPrivatePostButtonHidden}
              gifRatings={gifRatings}
              buttonColor={buttonColor}
            />
          )}
        </Collapse>
      </CommentWrapper>
    </div>
  );
};

const MemoizedPostOrganism = memo(PostInput);
MemoizedPostOrganism.displayName = 'PostInput';

export default MemoizedPostOrganism;
