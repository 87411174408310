import React, { useCallback, useMemo } from 'react';

import {
  AnonymityStates,
  FlowInstanceResponse,
} from '../../../queries/Flows/interfaces';
import {
  LoadedParticipationTemplate as ParticipationTemplate,
  LoadingParticipationTemplate,
  ParticipationTemplateError,
} from '../../../atomic/pages/ParticipationTemplate';
import { FlowSubmissionDetails, FlowVariants } from '../../../interfaces/Flow';
import { GetProfileInfoResponse } from '../../../queries/Profile';
import useLoadedParticipationFlowController from './useLoadedParticipationFlowController';
import useUppyInstances from './useUppyInstances';
import { isBlockContentValid } from '../../../hooks/useParticipationFlow/utils';
import {
  deleteAttachmentFile,
  getVisibilityIcon,
  getVisibilityTooltipText,
} from '../../../Utils/flows';
import { FeedPostUserProps } from '../../../interfaces/Feed';
import { FLOW_NOT_FOUND, VISIBILITY_LABEL } from '../../../languages/en/flows';
import {
  ANONYMOUS_BANNER_TEXT,
  ARE_ANSWERING,
  GO_BACK_TO_MAIN_FEED,
  POST_ANONYMOUSLY,
} from '../../../languages/en/flows/participation';
import ThemeV2 from '../../../componentsV2/theme';
import Body from '../../../atomic/atoms/Body';
import {
  StyledBodyFlowName,
  StyledBodyHeaderTitle,
  StyledEmoticon,
} from '../../../atomic/molecules/FlowsParticipationHeader/styles';
import { POST, YOU } from '../../../languages/en/singleWords';
import {
  StyledSpan,
  StyledSvgIcon,
  StyledVisibiltyLabel,
} from '../../../atomic/molecules/PageHeader/styles';
import { Flex } from '../../../Utils/styles/display';
import { mapHexCodeToEmoticon } from '../../../Utils/mappers';
import UserListDropdownWithPagination from '../../../atomic/molecules/UserListDropdownWithPagination';
import { ParticipationFlowHeaderContent } from '../../../atomic/molecules/FlowsParticipationHeader';
import useParticipationFlowController from './useParticipationFlowController';
import { ComponentStatus } from '../../../interfaces/component';
import SplitButton from '../../../atomic/molecules/SplitButton';
import Button from '../../../atomic/atoms/Button';
import { renderParticipationSlide, RenderSlideInfoObject } from '../utils';
import SVGIcon from '../../../atomic/atoms/SVGIcon';
import { ParticipationTemplateErrorTypes } from '../../../atomic/pages/ParticipationTemplate/types';
import { GET_FILE_UPLOAD_SIGNED_URL } from '../../../constants/endpoints';

type LoadedParticipationFlowControllerProps = {
  flowInstance: FlowInstanceResponse;
  toggleParticipationModalOpen: () => void;
  profileInfo: GetProfileInfoResponse;
  headerStatus: ComponentStatus;
  flowHeaderContent: ParticipationFlowHeaderContent;
  isPrivatePost: boolean;
  isAnonymousPost: boolean;
  togglePrivatePost: () => void;
  toggleAnonymousPost?: () => void;
  isMutationLoading?: boolean;
  onFlowSubmit: (submissionDetails: FlowSubmissionDetails) => void;
};

const LoadedParticipationFlowController = ({
  flowInstance,
  toggleParticipationModalOpen,
  profileInfo,
  flowHeaderContent,
  headerStatus,
  isPrivatePost,
  isAnonymousPost,
  isMutationLoading,
  togglePrivatePost,
  toggleAnonymousPost,
  onFlowSubmit,
}: LoadedParticipationFlowControllerProps) => {
  const {
    models: {
      blockErrors,
      currentStep,
      fieldErrors,
      hasVisitedLastStep,
      dynamicBlockData,
      values,
      stepData,
      containerRef,
      touched,
      allowPrivateResponse,
      isAnonymityEnabled,
      allowAnonymousResponse,
    },
    operations: {
      onFormCompleteClick,
      onStepChange,
      setFieldValue,
      goToNextStep,
      goToPreviousStep,
      handleModalClose,
      onNeedHelpButtonClick,
    },
  } = useLoadedParticipationFlowController(
    flowInstance,
    toggleParticipationModalOpen,
    profileInfo,
    onFlowSubmit,
  );

  const uppyInstances = useUppyInstances(
    flowInstance.blocks,
    {
      flowId: flowInstance.flowId,
      instanceId: flowInstance.instanceId,
    },
    GET_FILE_UPLOAD_SIGNED_URL,
  );

  const remainingAllowance = profileInfo.member.pointsLeftThisCycle;

  const handleDeleteFileClick = useCallback(
    async (fileName) => {
      const currentStepData = stepData[currentStep];
      const { id: blockId } = currentStepData;
      await deleteAttachmentFile({
        blockId,
        instanceId: flowInstance.instanceId,
        fileName,
        flowId: flowInstance.flowId,
      });
    },
    [currentStep, flowInstance.flowId, flowInstance.instanceId, stepData],
  );

  const renderSlideInfo: RenderSlideInfoObject = {
    blockErrors,
    currentStep,
    fieldErrors,
    flowInstance,
    goToNextStep,
    handleDeleteFileClick,
    onStepChange,
    values,
    profileInfo,
    remainingAllowance,
    setFieldValue,
    stepData,
    touched,
    uppyInstances,
    isPreviewFlow: false,
  };

  const isPostDisabled = !(
    stepData.filter((step) => isBlockContentValid(step, values[step.id]))
      .length > 0 && hasVisitedLastStep
  );

  const footerButtons: JSX.Element[] = [
    allowPrivateResponse || allowAnonymousResponse ? (
      <SplitButton
        disabled={isPostDisabled}
        isLoading={isMutationLoading}
        isPrivatePost={isPrivatePost}
        isAnonymousPost={isAnonymousPost}
        onPrivatePostToggle={togglePrivatePost}
        isAnonymityEnabled={isAnonymityEnabled}
        onAnonymousPostToggle={
          allowAnonymousResponse ? toggleAnonymousPost : undefined
        }
        onPostClick={onFormCompleteClick}
        key="1"
      />
    ) : (
      <Button
        disabled={isPostDisabled}
        isLoading={isMutationLoading}
        onClick={onFormCompleteClick}
        size="large"
        key="1"
      >
        {isAnonymityEnabled ? POST_ANONYMOUSLY : POST}
      </Button>
    ),
  ];

  return (
    <ParticipationTemplate
      currentStep={currentStep}
      goToNextStep={goToNextStep}
      goToPreviousStep={goToPreviousStep}
      isNextButtonDisabled={currentStep === flowInstance.blocks.length - 1}
      member={profileInfo.member}
      isPreviousButtonDisabled={currentStep === 0}
      onCloseModal={handleModalClose}
      footerButtons={footerButtons}
      onStepChange={onStepChange}
      dynamicBlockData={dynamicBlockData}
      singleSlideContainerRef={containerRef}
      flowVariant={FlowVariants.PARTICIPATION_FLOW}
      flowId={flowInstance.flowId}
      onNeedHelpButtonClick={onNeedHelpButtonClick}
      flowHeaderContent={flowHeaderContent}
      headerStatus={headerStatus}
      bannerProps={
        isAnonymityEnabled || isAnonymousPost
          ? {
              bannerIcon: 'anonymous',
              bannerText: ANONYMOUS_BANNER_TEXT,
            }
          : undefined
      }
    >
      {renderParticipationSlide(renderSlideInfo)}
    </ParticipationTemplate>
  );
};

type ParticipationFlowControllerProps = {
  flowInstance?: FlowInstanceResponse;
  toggleParticipationModalOpen: () => void;
  onParticipationFlowPostSuccess?: () => void;
  profileInfo: GetProfileInfoResponse;
  isLoading?: boolean;
  flowErrorStatus?: ParticipationTemplateErrorTypes;
  flowId: string;
  isShortcutRespondedView: boolean;
  onAnswerWithShortcutButtonClick: () => void;
};

const ParticipationFlowController = ({
  isLoading,
  profileInfo,
  toggleParticipationModalOpen,
  flowErrorStatus,
  flowInstance,
  flowId,
  isShortcutRespondedView,
  onAnswerWithShortcutButtonClick,
  onParticipationFlowPostSuccess,
}: ParticipationFlowControllerProps) => {
  const {
    flowDetails,
    flowViewsObject,
    isFlowDetailsLoading,
    isFlowDetailsError,
    onViewVisibilityPopOverOpen,
    isPrivatePost,
    isAnonymousPost,
    togglePrivatePost,
    toggleAnonymousPost,
    onFlowSubmit,
    isMutationLoading,
    customError,
    setCustomError,
  } = useParticipationFlowController(
    flowInstance,
    toggleParticipationModalOpen,
    onParticipationFlowPostSuccess,
  );

  const [flowHeaderContent, headerStatus]: [
    ParticipationFlowHeaderContent,
    ComponentStatus,
  ] = useMemo(() => {
    if (isFlowDetailsError) {
      return [
        {
          TitleContent: FLOW_NOT_FOUND,
          DescriptionContent: GO_BACK_TO_MAIN_FEED,
        },
        ComponentStatus.ERROR,
      ];
    }
    if (isFlowDetailsLoading || !flowDetails) {
      return [
        {
          TitleContent: '',
          DescriptionContent: '',
        },
        ComponentStatus.LOADING,
      ];
    }

    const {
      data: { visibility, name, icon, responseSettings },
    } = flowDetails;
    const isAnonymityEnabled =
      responseSettings.anonymity.state === AnonymityStates.ENABLED;
    const {
      models: {
        searchedMembers,
        isError: isUsersError,
        value: memberSearchValue,
        isLoading: isUsersLoading,
        isFetching: isUsersFetching,
        hasMoreMembers: hasNextPage,
      },
      operations: { fetchMoreMembers, onChange: onMemberSearchChange },
    } = flowViewsObject;

    const searchedUsers: FeedPostUserProps[] = searchedMembers
      ? searchedMembers.map(
          (member) =>
            ({
              ...member,
              image: member.image,
            } as FeedPostUserProps),
        )
      : [];

    return [
      {
        TitleContent: (
          <Flex>
            <StyledBodyHeaderTitle color="gray8" variant="body2">
              <Body color="geekblue6" inline variant="body2Medium">
                {`${YOU} `}
              </Body>
              {`${ARE_ANSWERING} `}
            </StyledBodyHeaderTitle>
            <StyledEmoticon>{mapHexCodeToEmoticon(icon.value)}</StyledEmoticon>
            <StyledBodyFlowName color="geekBlue6" inline variant="body2Medium">
              {name}
            </StyledBodyFlowName>
          </Flex>
        ),
        DescriptionContent: (
          <Flex key="description">
            <Body variant="body3" color="gray7">
              {`${VISIBILITY_LABEL}:`}
            </Body>
            <UserListDropdownWithPagination
              users={searchedUsers}
              isError={isUsersError}
              placeholder="Search Members"
              canPopoverTriggerByClick={false}
              fetchMoreMembers={fetchMoreMembers}
              hasMoreUsersToFetch={!!hasNextPage}
              memberSearchValue={memberSearchValue}
              visibility={visibility.criteria}
              isLoading={isUsersLoading || isUsersFetching}
              viewCount={visibility.viewersCount}
              onMemberSearchValueChange={onMemberSearchChange}
              disableUserInteraction
              onPopOverOpen={onViewVisibilityPopOverOpen}
            >
              <Flex>
                <StyledVisibiltyLabel variant="body3" color="gray7">
                  {getVisibilityTooltipText(
                    visibility.criteria,
                    visibility.viewersCount,
                  )}
                </StyledVisibiltyLabel>
                <Body variant="body3" color="gray7">
                  <span>
                    <StyledSvgIcon
                      icon={getVisibilityIcon(visibility.criteria)}
                      size="16px"
                      color={ThemeV2.palette.gray7}
                    />
                  </span>
                </Body>
                {(isAnonymityEnabled || isAnonymousPost) && (
                  <>
                    <StyledSpan> • </StyledSpan>
                    <SVGIcon
                      icon="anonymous"
                      size="16px"
                      color={ThemeV2.palette.gray7}
                    />
                  </>
                )}
              </Flex>
            </UserListDropdownWithPagination>
          </Flex>
        ),
      },
      ComponentStatus.LOADED,
    ];
  }, [
    isFlowDetailsError,
    isFlowDetailsLoading,
    flowDetails,
    flowViewsObject,
    onViewVisibilityPopOverOpen,
    isAnonymousPost,
  ]);

  if (isLoading) {
    return (
      <LoadingParticipationTemplate
        member={profileInfo.member}
        onCloseModal={toggleParticipationModalOpen}
        flowId={flowId}
        headerStatus={headerStatus}
        flowHeaderContent={flowHeaderContent}
      />
    );
  }
  if (flowErrorStatus) {
    return (
      <ParticipationTemplateError
        member={profileInfo.member}
        onCloseModal={toggleParticipationModalOpen}
        flowId={flowId}
        templateError={flowErrorStatus}
        headerStatus={headerStatus}
        flowHeaderContent={flowHeaderContent}
      />
    );
  }

  if (customError) {
    return (
      <ParticipationTemplateError
        member={profileInfo.member}
        onCloseModal={toggleParticipationModalOpen}
        flowId={flowId}
        templateError={customError}
        headerStatus={headerStatus}
        flowHeaderContent={flowHeaderContent}
        onAnswerWithShortcutButtonClick={onAnswerWithShortcutButtonClick}
        setCustomError={setCustomError}
      />
    );
  }

  if (isShortcutRespondedView) {
    return (
      <ParticipationTemplateError
        member={profileInfo.member}
        onCloseModal={toggleParticipationModalOpen}
        flowId={flowId}
        templateError={
          ParticipationTemplateErrorTypes.OCCURRENCE_ALREADY_RESPONDED
        }
        onAnswerWithShortcutButtonClick={onAnswerWithShortcutButtonClick}
        headerStatus={headerStatus}
        flowHeaderContent={flowHeaderContent}
      />
    );
  }

  if (flowInstance) {
    return (
      <LoadedParticipationFlowController
        flowInstance={flowInstance}
        toggleParticipationModalOpen={toggleParticipationModalOpen}
        profileInfo={profileInfo}
        headerStatus={headerStatus}
        flowHeaderContent={flowHeaderContent}
        isPrivatePost={isPrivatePost}
        isAnonymousPost={isAnonymousPost}
        toggleAnonymousPost={toggleAnonymousPost}
        togglePrivatePost={togglePrivatePost}
        isMutationLoading={isMutationLoading}
        onFlowSubmit={onFlowSubmit}
      />
    );
  }
  return null;
};

export default ParticipationFlowController;
