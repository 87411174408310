import React, { useMemo } from 'react';
import ThemeV2 from '../../../componentsV2/theme';
import CircularProgress from '../CircularProgress';
import Body from '../../atoms/Body';
import {
  FlowStepperContainer,
  StyledTooltip,
  ProgressContainer,
  Container,
  Step,
  ActiveTooltip,
  ErrorSymbolSpan,
} from './styles';
import { truncateText } from '../../../Utils/formatting';
import { StepperErrorType, DynamicBlockState } from '../../../interfaces/Flow';
import SVGIcon from '../../atoms/SVGIcon';
import { LoadingProps, ComponentStatus } from '../../../interfaces/component';

export interface LoadedFlowStepperProps {
  currentStep: number;
  status: ComponentStatus;
  stepStates: DynamicBlockState[];
  onStepChange: (step: number) => void;
  isPreviewFlow?: boolean;
  isExternalFlow?: boolean;
}

export type FlowStepperProps = LoadingProps | LoadedFlowStepperProps;

const ProgressComponent = ({
  flowCompletedPercentage,
}: {
  flowCompletedPercentage: number;
}) => (
  <ProgressContainer data-testid="circular-progress-container">
    {flowCompletedPercentage === 100 ? (
      <SVGIcon
        icon="checkmark-circle"
        size="18px"
        color={ThemeV2.palette.geekBlue6}
      />
    ) : (
      <CircularProgress
        value={flowCompletedPercentage}
        showCircularShadow
        backgroundColor={ThemeV2.palette.gray4}
        thickness={8}
        shadowColor={ThemeV2.palette.gray4}
        size="18px"
      />
    )}
    <Body variant="body3" color="geekBlue6">
      {flowCompletedPercentage}%
    </Body>
  </ProgressContainer>
);

const LoadedFlowStepper = ({
  stepStates,
  currentStep,
  onStepChange,
  isPreviewFlow,
  isExternalFlow,
}: LoadedFlowStepperProps) => {
  const flowCompletedPercentage = useMemo(() => {
    if (isExternalFlow) {
      return Math.round(
        (stepStates.filter((step) => step.isValid && !step.isError).length /
          (stepStates.length - 1)) *
          100,
      );
    }
    return isPreviewFlow
      ? 0
      : Math.round(
          (stepStates.filter((step) => step.isValid && !step.isError).length /
            stepStates.length) *
            100,
        );
  }, [isPreviewFlow, isExternalFlow, stepStates]);

  const handleStepChange = (e: React.MouseEvent) => {
    const target = e.target as HTMLElement;
    const step = target.getAttribute('data-step-number');
    if (step) {
      const stepNumber = parseInt(step, 10);
      if (stepNumber !== currentStep) {
        onStepChange(stepNumber);
      }
    }
  };
  return (
    <Container>
      <FlowStepperContainer onClick={handleStepChange}>
        {stepStates.map(
          ({ isError, errorMessage, title, id, errorType }, index) => {
            const hasError = isPreviewFlow ? false : isError;
            const isCurrentStep = index === currentStep;
            let errorSymbol = null;
            if (hasError) {
              errorSymbol = errorType === StepperErrorType.REQUIRED ? '*' : '!';
            }
            return (
              <StyledTooltip
                isError={hasError}
                key={id}
                isCurrentStep={isCurrentStep}
                toolTipComponent={
                  <Step
                    data-step-number={index}
                    isError={hasError}
                    data-testid={`step-${index}`}
                    isCurrentStep={isCurrentStep}
                  />
                }
                position="right"
              >
                {errorSymbol && (
                  <ErrorSymbolSpan>{errorSymbol}</ErrorSymbolSpan>
                )}
                {truncateText(20, errorMessage || title)}
              </StyledTooltip>
            );
          },
        )}
        {stepStates.length > 0 && (
          <ActiveTooltip
            steps={stepStates.length}
            currentStep={currentStep}
            toolTipComponent={<Step active />}
            position="right"
          >
            {truncateText(20, stepStates[currentStep].title)}
          </ActiveTooltip>
        )}
      </FlowStepperContainer>
      <ProgressComponent flowCompletedPercentage={flowCompletedPercentage} />
    </Container>
  );
};

const FlowStepper = (props: FlowStepperProps) => {
  switch (props.status) {
    case ComponentStatus.LOADED: {
      return <LoadedFlowStepper {...props} />;
    }
    default: {
      return (
        <Container>
          <FlowStepperContainer />
          <ProgressComponent flowCompletedPercentage={0} />
        </Container>
      );
    }
  }
};

export default FlowStepper;
