import ContentLoader from 'react-content-loader';
import React from 'react';
import styled from 'styled-components';

import Divider from '../../atoms/Divider';
import ProfileDetailsEntryLoader from '../ProfileDetailsEntry/Loader';
import ThemeV2 from '../../../componentsV2/theme';

const StyledDivider = styled(Divider)`
  margin-left: 16px;
  margin-right: 16px;
  width: auto;
`;

const ProfileDetailsSectionLoader = () => {
  return (
    <>
      <ContentLoader
        speed={2}
        width={293}
        height={38}
        viewBox="0 0 293 38"
        backgroundColor={ThemeV2.palette.gray4}
        foregroundColor={ThemeV2.palette.gray3}
        uniqueKey="profile-details-section-loader"
      >
        <rect x="23" y="18" rx="4" width="63" height="20" />
      </ContentLoader>

      <ProfileDetailsEntryLoader />
      <StyledDivider color={ThemeV2.palette.gray4} isFullWidth />
      <ProfileDetailsEntryLoader />
      <StyledDivider color={ThemeV2.palette.gray4} isFullWidth />
      <ProfileDetailsEntryLoader />
      <StyledDivider color={ThemeV2.palette.gray4} isFullWidth />
      <ProfileDetailsEntryLoader />
      <StyledDivider color={ThemeV2.palette.gray4} isFullWidth />
      <ProfileDetailsEntryLoader />
    </>
  );
};

export default ProfileDetailsSectionLoader;
