import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { QUICK_SETUP } from '../../constants/routes';
import OnBoardingSetup from '../onBoarding/OnBoardingSetup';
import { useFeatureSplit } from '../../hooks/useFeatureSplit';

import {
  SplitNames,
  TreatmentTypes,
} from '../../hooks/useSplitSdkConfig/constants';
import { fireConversionForEmployerOnboard } from '../../Utils/analytics/gtag';

const UserOnBoardingController = () => {
  const history = useHistory();
  const { treatment } = useFeatureSplit(SplitNames.QUICK_SETUP);

  useEffect(() => {
    fireConversionForEmployerOnboard();
  }, []);

  if (treatment === TreatmentTypes.ON) {
    history.push(QUICK_SETUP);
  } else if (treatment === TreatmentTypes.OFF) {
    return <OnBoardingSetup />;
  }

  return null;
};

export default UserOnBoardingController;
