import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import Body from '../../../../../atomic/atoms/Body';
import { RadioOptionProps } from '../../../../../atomic/molecules/RadioGroup';

import useFlowBuilderStore from '../../../../../stores/flowBuilderStore';
import {
  newVisibilityMembersToInviteSelector,
  ownerSelector,
  setNewVisibilityMembersToInviteSelector,
} from '../../../../../stores/flowBuilderStore/selectors';

import { Participant } from '../../../../../atomic/molecules/ParticipantsList/types';
import { useGetMembersFromCriteria } from '../../../../../hooks/useMembersSearch';
import {
  CriteriaGroups,
  VisibilityBuilderBlockData,
} from '../../../../../interfaces/Flow/Builder';
import { IMemberDTO } from '../../../../../interfaces/member';
import { BUILDER_VISIBILITY_BLOCK } from '../../../../../languages/en/flows/builder';
import { isArrayEquals } from '../../../../../Utils/common';
import {
  getFormattedNewMembers,
  getParticipantBlockForFlowParticipant,
} from '../../../../../Utils/flows';
import { mapRulesFromCriteriaGroups } from '../../../../../Utils/flows/builder/utils';
import { visibilityRadioOptions } from '../../data';
import { VisibilityTypes } from '../../types';
import { ShareSheetParticipantsControllerProps } from '../index';

const useShareSheetVisibilityController = (
  props: ShareSheetParticipantsControllerProps,
) => {
  const {
    visibilityBlockData,
    visibilityParticipantsCriteria,
    updateVisibilityParticipantsBlockData,
  } = props;
  const owner = useFlowBuilderStore(ownerSelector);

  const getRadioOptionCustomContent = useCallback((type: string) => {
    switch (type) {
      case VisibilityTypes.CUSTOM:
        return (
          <Body variant="body3" color="gray8">
            {BUILDER_VISIBILITY_BLOCK.CUSTOM_OPTION_TEXT}
          </Body>
        );
      case VisibilityTypes.PARTICIPANTS_ONLY:
        return (
          <Body variant="body3" color="gray8">
            {BUILDER_VISIBILITY_BLOCK.PARTICIPANTS_ONLY_OPTION_TEXT}
          </Body>
        );
      default:
        return null;
    }
  }, []);

  const radioOptions = useMemo(() => {
    return visibilityRadioOptions.map((option: RadioOptionProps) => {
      const children = getRadioOptionCustomContent(option.value);
      if (children) {
        return {
          ...option,
          children,
        };
      }
      return option;
    });
  }, [getRadioOptionCustomContent]);

  const [value, setValue] = useState(VisibilityTypes.ENTIRE_ORGANIZATION);

  useEffect(() => {
    if (visibilityBlockData?.onlyParticipants) {
      setValue(VisibilityTypes.PARTICIPANTS_ONLY);
    }

    if (visibilityBlockData?.onlyOwners) {
      setValue(VisibilityTypes.OWNER_ONLY);
    }

    if (visibilityBlockData?.everyone) {
      setValue(VisibilityTypes.ENTIRE_ORGANIZATION);
    }

    if (visibilityBlockData?.custom) {
      setValue(VisibilityTypes.CUSTOM);
    }
  }, [visibilityBlockData]);

  const [participants, setParticipants] = useState<Participant[] | undefined>(
    undefined,
  );

  const newVisibilityMembersToInvite = useFlowBuilderStore(
    newVisibilityMembersToInviteSelector,
  );
  const setNewVisibilityMembersToInvite = useFlowBuilderStore(
    setNewVisibilityMembersToInviteSelector,
  );

  const rulesFromCriteria = useMemo(() => {
    const ownerOnlyCriteria = {
      criteria: {
        onlyOwners: true,
      },
    };
    switch (value) {
      case VisibilityTypes.ENTIRE_ORGANIZATION:
        return {
          criteria: {
            everyone: true,
          },
        };

      case VisibilityTypes.PARTICIPANTS_ONLY:
        return mapRulesFromCriteriaGroups(visibilityParticipantsCriteria);

      case VisibilityTypes.CUSTOM:
        return visibilityBlockData?.criteriaGroups &&
          visibilityBlockData?.criteriaGroups.groups.length
          ? mapRulesFromCriteriaGroups(
              visibilityBlockData?.criteriaGroups,
              undefined,
              false,
            )
          : ownerOnlyCriteria;

      case VisibilityTypes.OWNER_ONLY:
        return ownerOnlyCriteria;

      default:
        return null;
    }
  }, [value, visibilityParticipantsCriteria, visibilityBlockData]);

  // isUnlocked is replaced as true
  const isEnabled = useMemo(() => {
    switch (value) {
      case VisibilityTypes.ENTIRE_ORGANIZATION:
      case VisibilityTypes.OWNER_ONLY:
        return true;

      case VisibilityTypes.PARTICIPANTS_ONLY:
        return visibilityParticipantsCriteria !== undefined;

      case VisibilityTypes.CUSTOM:
        return (
          (visibilityBlockData?.criteriaGroups !== undefined &&
            !isEmpty(visibilityBlockData?.criteriaGroups?.groups)) ||
          true
        );

      default:
        return false;
    }
  }, [value, visibilityParticipantsCriteria, visibilityBlockData]);

  const {
    models: {
      isLoading,
      hasMoreMembers,
      searchedMembers,
      totalMembers,
      newMembers,
      totalPendingMembers,
    },
    operations: { fetchMoreMembers },
  } = useGetMembersFromCriteria(isEnabled ? rulesFromCriteria : undefined, 20, {
    isViewingCriteria: true,
    ownerId: owner[0]?.id,
    isNewMembersAdded: true,
  });

  const participantsCount = useMemo(() => {
    if (
      !isEmpty(visibilityParticipantsCriteria) &&
      !isEmpty(visibilityParticipantsCriteria?.groups)
    ) {
      if (newMembers) {
        return totalMembers + newMembers.length;
      }
      return totalMembers;
    }

    return 0;
  }, [visibilityParticipantsCriteria, newMembers, totalMembers]);

  const pendingMembersParticipantCount = useMemo(() => {
    if (
      !isEmpty(visibilityParticipantsCriteria) &&
      !isEmpty(visibilityParticipantsCriteria?.groups)
    ) {
      if (newMembers) {
        return totalPendingMembers + newMembers.length;
      }
      return totalPendingMembers;
    }

    return 0;
  }, [newMembers, visibilityParticipantsCriteria, totalPendingMembers]);

  const updateVisibilityBlockData = useCallback(
    (updatedContentBlock: VisibilityBuilderBlockData) =>
      updateVisibilityParticipantsBlockData(updatedContentBlock),
    [updateVisibilityParticipantsBlockData],
  );

  useEffect(() => {
    const participantsList: Participant[] = searchedMembers
      ? searchedMembers.map((participant: IMemberDTO) => {
          if (participant.memberID === owner[0]?.id)
            // Owner is always appended at 0th index for visibility
            return getParticipantBlockForFlowParticipant(participant, true);
          return getParticipantBlockForFlowParticipant(participant, false);
        })
      : [];

    const formattedNewMembers = getFormattedNewMembers(newMembers);
    const allParticipants = [...formattedNewMembers, ...participantsList];

    setParticipants(uniqBy(allParticipants, 'memberId'));
    // don't add newMembers
  }, [owner, searchedMembers, setNewVisibilityMembersToInvite]);

  const handleOnCriteriaGroupsChange = useCallback(
    (val: CriteriaGroups | undefined) => {
      const updatedVisibilityBlockDataData = {
        ...visibilityBlockData,
        criteriaGroups: val || undefined,
        everyone: visibilityBlockData?.everyone,
        onlyParticipants: visibilityBlockData?.onlyParticipants,
        onlyOwners: visibilityBlockData?.onlyOwners,
        custom: visibilityBlockData?.custom,
        type: value,
      };
      updateVisibilityBlockData(updatedVisibilityBlockDataData);
    },
    [value, updateVisibilityBlockData, visibilityBlockData],
  );

  const handleVisibilitySelection = useCallback(
    (selectionType: string) => {
      const visibilityToggle = {
        everyone: false,
        onlyParticipants: false,
        onlyOwners: false,
        custom: false,
      };
      switch (selectionType) {
        case VisibilityTypes.ENTIRE_ORGANIZATION:
          visibilityToggle.everyone = true;
          break;
        case VisibilityTypes.PARTICIPANTS_ONLY:
          visibilityToggle.onlyParticipants = true;
          break;
        case VisibilityTypes.OWNER_ONLY:
          visibilityToggle.onlyOwners = true;
          break;
        case VisibilityTypes.CUSTOM:
          visibilityToggle.custom = true;
          break;
        default:
          break;
      }
      const updatedVisibilityBlockDataData: VisibilityBuilderBlockData = {
        ...visibilityBlockData,
        ...visibilityToggle,
        type: selectionType,
      };

      updateVisibilityBlockData(updatedVisibilityBlockDataData);
    },
    [updateVisibilityBlockData, visibilityBlockData],
  );

  useEffect(() => {
    if (!isArrayEquals(newMembers, newVisibilityMembersToInvite)) {
      if (newMembers) {
        setNewVisibilityMembersToInvite(newMembers);
      }

      if (!newMembers) {
        setNewVisibilityMembersToInvite([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newMembers]);

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const updatedType: string = e.target.value;
      setValue(updatedType as VisibilityTypes);
      handleVisibilitySelection(updatedType);
    },
    [handleVisibilitySelection],
  );

  return {
    models: {
      value,
      isLoading,
      radioOptions,
      hasNextPage: hasMoreMembers,
      participantsCount,
      participants,
      blockData: visibilityBlockData,
      pendingMembersParticipantCount,
    },
    operations: {
      onChange,
      fetchNextPage: fetchMoreMembers,
      handleOnCriteriaGroupsChange,
      handleVisibilitySelection,
    },
  };
};

export default useShareSheetVisibilityController;
