export const FLOW_REQUEST_TEMPLATE_MODAL = {
  heading: 'Flow Templates',
  subHeading:
    'Jumpstart your ability to work smart with these pre-made expert templates.',
  searchTemplatePlaceHolder: 'Search templates',
  autoCompletePlaceholder: 'Use Cases',
  flowsBuildFromScratchBannerTitle:
    'Build your own use case from a blank slate',
  flowsBuildFromScratchBannerSubTitle: 'Let those creative juices flow!',
  flowsBuildFromScratchBannerButtonText: 'Start from scratch',
  allTemplatesText: 'All Templates',
};

export const FLOW_REQUEST_MODAL_LOADING_STATES = {
  ERROR: {
    HEADING: 'We had trouble loading the templates',
    SUB_HEADING: 'Please refresh your page and try again.',
  },
  NOTFOUND: {
    HEADING: 'We couldn’t find any templates matching this search.',
    SUB_HEADING: 'Change your search and try again.',
  },
};

export const REQUEST_MODAL_HUBSPOT = {
  HEADING_FOR_NEW_TEMPLATE: 'Request a new Flow',
  HEADING_FOR_TEMPLATE: 'Request template',
  BOOK_DEMO_NEW_TEMPLATE: `Book time with an employee engagement expert and we'll help you craft the perfect flow.`,
  BOOK_DEMO_FOR_TEMPLATE_START: `We’ll just need a few more details from you to make sure`,
  // eslint-disable-next-line max-len
  BOOK_DEMO_FOR_TEMPLATE_END: `is tailored to your use case. Book some time with an employee engagement professional to get your flow up and running.`,
};
