import {
  MenuItemIndividualItem,
  MenuItemProps,
} from '../../../molecules/Dropdown_V2/interfaces';
import {
  ADVANCED_COLLECT_LABEL,
  COLLECT_LABEL,
  DROPDOWN_LABEL,
  FILE_UPLOAD_LABEL,
  GIF_SELECTOR_LABEL,
  MULTI_CHOICE_LABEL,
  OPEN_ENDED_LABEL,
  PERSON_SELECTOR_LABEL,
  SCALE_LABEL,
  GIVE_POINTS_STACK_LABEL,
  GIVE_POINTS_STACK_EXISTING_LABEL,
} from '../../../../languages/en/flows/builder';

export const getGivePointsStack = (
  isExisitent = false,
): MenuItemIndividualItem => ({
  isSelected: false,
  prefixIcon: 'trophy',
  id: 'GIVE_POINTS_STACK',
  value: isExisitent
    ? GIVE_POINTS_STACK_EXISTING_LABEL
    : GIVE_POINTS_STACK_LABEL,
  disabled: isExisitent,
});

export const menuItems: MenuItemProps[] = [
  {
    id: 'collect',
    category: COLLECT_LABEL,
    items: [
      {
        isSelected: false,
        prefixIcon: 'open-ended',
        value: OPEN_ENDED_LABEL,
        id: 'OPEN_ENDED',
      },
      {
        isSelected: false,
        prefixIcon: 'caret',
        value: DROPDOWN_LABEL,
        id: 'DROPDOWN',
      },
      {
        isSelected: false,
        prefixIcon: 'scale',
        id: 'SCALE',
        value: SCALE_LABEL,
      },
      {
        isSelected: false,
        prefixIcon: 'multiple-choice',
        value: MULTI_CHOICE_LABEL,
        id: 'MULTI_CHOICE',
      },
      {
        isSelected: false,
        prefixIcon: 'file-upload',
        id: 'FILE_UPLOAD',
        value: FILE_UPLOAD_LABEL,
      },
      {
        isSelected: false,
        prefixIcon: 'person-filled',
        id: 'PERSON_SELECTOR',
        value: PERSON_SELECTOR_LABEL,
      },
      {
        isSelected: false,
        prefixIcon: 'gif-selector-icon',
        value: GIF_SELECTOR_LABEL,
        id: 'GIF',
      },
    ],
  },
];

const withGivePointsStackItem = [
  ...menuItems,
  {
    id: 'advanced-collect',
    category: ADVANCED_COLLECT_LABEL,
    items: [getGivePointsStack()],
  },
];

export const getMenuItems = (hasGivePointsStackPermission: boolean) => {
  return hasGivePointsStackPermission ? withGivePointsStackItem : menuItems;
};
