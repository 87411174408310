export const leaderboardCategoryDropdownValues = {
  TOP_CELEBRATED: 'Top 10 celebrated',
  TOP_CELEBRATORS: 'Top 10 celebrators',
  TOP_COMMENTERS: 'Top 10 commenters',
};

export const leaderboardTimeFrameDropdownValues = {
  ALL_TIME: 'All time',
  LAST_7_DAYS: 'Last 7 days',
  LAST_30_DAYS: 'Last 30 days',
  THIS_MONTH: 'This month',
  THIS_QUARTER: 'This quarter',
};

export const ADMIN = 'Admin';
export const CURRENT_MEMBERS = 'Current members';
export const MEMBERS_IN = 'Members in';
export const SHOW_LESS = 'Show less';
export const SHOW_MORE = 'Show more';

export const FIND_MEMBERS = 'Find members';

export const NO_MEMBERS_FOUND_THAT_MATCH_YOUR_CRITERIA_INVITE =
  "No results. Click 'Add Teammates' below to invite new people to Assembly";
export const NO_MEMBERS_FOUND_THAT_MATCH_YOUR_CRITERIA =
  'No results. Try a different search';
export const PLEASE_ENTER_MORE_THAN_2_CHARACTERS =
  'No results. Please enter more than 2 characters to search.';

export const dummyLeaderboardTexts = {
  WELCOME_TEXT:
    'Welcome to the leaderboard, where you can get high level visibility into your team’s activity!',
  HELPER_TEXT:
    // eslint-disable-next-line max-len
    'This will populate once you have more data and your team’s activity grows. It’ll look a little something like this:',
};

export const LEADERBOARD_NO_ACTIVITY_HEADING =
  'There’s no activity for this configuration yet.';

export const LEADERBOARD_NO_ACTIVITY_TEXT = 'Check back once there’s more!';
