import create from 'zustand';
import { QuickSetupStore } from './types';

export const useQuickSetupStore = create<QuickSetupStore>((set) => ({
  currentStep: '',
  selectedTemplateName: '',
  selectedTemplateEmoji: '',
  setCurrentStep: (step: string) => set(() => ({ currentStep: step })),
  setSelectedTemplateName: (templateName: string) =>
    set(() => ({ selectedTemplateName: templateName })),
  setSelectedTemplateEmoji: (selectedTemplateEmoji: string) =>
    set(() => ({ selectedTemplateEmoji: selectedTemplateEmoji })),
}));
