import { trackEvent } from '../index';
import { InviteMembersEventProps } from '../interfaces';
import { AnalyticsEvents, INVITE_MEMBER_EVENT } from '../constants';

export const trackInviteMemberActionEvent = (
  eventProps: InviteMembersEventProps,
) => {
  trackEvent(INVITE_MEMBER_EVENT, undefined, {
    ...eventProps,
    event: AnalyticsEvents.ACTION,
  });
};

export const trackInviteMemberErrorEvent = (
  eventProps: InviteMembersEventProps,
) => {
  trackEvent(INVITE_MEMBER_EVENT, undefined, {
    ...eventProps,
    event: AnalyticsEvents.ERROR,
  });
};
