import React from 'react';

import { HeaderType } from '../../../atomic/molecules/PageHeader/interfaces';
import Error from '../Error';
import Loader from '../Loader';
import PageHeader from '../../../atomic/molecules/PageHeader';
import { ERROR_FINDING_USER, PROFILE_FEED } from '../../../languages/en/header';

import useProfileHeaderController from './useProfileHeaderController';

const ProfileHeaderController = () => {
  const { userId, query, trackHomeClicked } = useProfileHeaderController();

  if (query.isLoading || query.isIdle) {
    return <Loader />;
  }

  if (query.isError) {
    return <Error header={ERROR_FINDING_USER} subheader={PROFILE_FEED} />;
  }

  const { profile } = query.data.membersDetails[0];
  const { department, firstName, image, lastName, username } = profile;

  return (
    <PageHeader
      headerType={HeaderType.PROFILE}
      profile={{
        firstName,
        departments: department,
        imageUrl: image,
        lastName,
        username,
      }}
      userId={userId}
      onClick={trackHomeClicked}
    />
  );
};

export default ProfileHeaderController;
