import styled from 'styled-components';

import ThemeV2 from '../../../../componentsV2/theme';
import { Flex } from '../../../../Utils/styles/display';
import Divider from '../../../atoms/Divider';
import TextEditor from '../../../atoms/TextEditor';
import InfoAlert from '../../../molecules/InfoAlert';

export const InputBlockWrapper = styled.div``;

export const ContentFlex = styled(Flex)`
  padding-left: 16px;
  width: 100%;
`;
export const ContentWrapperFlex = styled(Flex)`
  width: 100%;
`;

export const StyledInfoAlert = styled(InfoAlert)`
  padding: 8px;
  margin-bottom: 16px;
  width: 100%;
`;

export const TitleTextField = styled(TextEditor)`
  font-size: 16px;
  line-height: 24px;
  color: ${ThemeV2.palette.gray9};
  font-weight: ${ThemeV2.typography.fontWeightMedium};
  font-family: ${ThemeV2.typography.adminFontFamily};
`;

export const DescriptionTextField = styled(TextEditor)`
  font-size: 14px;
  line-height: 22px;
  color: ${ThemeV2.palette.gray8};
  font-weight: ${ThemeV2.typography.fontWeightRegular};
  font-family: ${ThemeV2.typography.adminFontFamily};
`;

export const StyledDivider = styled(Divider)`
  margin: 16px 0;
`;

export const SettingsContainer = styled(Flex)`
  min-width: 64px;
`;
