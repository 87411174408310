import { ReactNode } from 'react';
import { AssemblyCurrency } from '../../../interfaces/assembly';
import { MenuItemProps } from '../../molecules/Dropdown_V2/interfaces';
import { FormikType } from '../../molecules/FormikField';
import { RewardTypes } from '../../molecules/RewardCard/interface';
import { AutocompleteDropdownItem } from '../Autocomplete/interfaces';

export type DropdownValue = {
  id: string;
  key: string;
  name: string;
  title: string;
};

export enum ShippingStatus {
  SWAG_DETAILS = 'SWAG_DETAILS',
  SHIPPING_ADDRESS = 'SHIPPING_ADDRESS',
  ORDER_DETAILS_CONFIRMATION = 'ORDER_DETAILS_CONFIRMATION',
  SUCCESSFULLY_REDEEMED = 'SUCCESSFULLY_REDEEMED',
}

export interface RedeemSelectModalProps {
  id: string;
  isOpen: boolean;
  onClose: () => void;
  dropdownItems?: MenuItemProps[];
  rewardTitle: string;
  rewardImage?: string;
  rewardCost?: number;
  shippingCost?: number;
  assemblyCurrency: AssemblyCurrency;
  rewardDescription?: string;
  label?: string;
  hasRedeemed?: boolean;
  onDropdownSelect?: (value: string | number) => void;
  dropdownValue?: string;
  onActionButtonClick: (id: string) => void;
  rewardSuccessHeading: string;
  rewardSuccessTitle?: string;
  rewardSuccessContent?: string | React.ReactNode;
  isRedeemButtonDisabled?: boolean;
  redeemPolicyContent: React.ReactNode;
  isRewardRated: boolean;
  rewardRating?: number;
  handleRewardRatingChange: (newRating: number) => void;
  isLoadingPostRedeem: boolean;
  getItemLabel?: (value: string) => ReactNode;
  rewardType?: RewardTypes;
  shippingDetails?: {
    shippingStatus: string;
    handlePrimaryButtonClick: () => void;
    handleSecondaryButtonClick: () => void;
    secondaryModalOpen: boolean;
    onSecondaryModalClose: () => void;
    shippingFormik: FormikType<{
      country: {
        title: string;
        id: string;
      };
      fullName: string;
      companyName?: string;
      address: string;
      apartment?: string;
      city: string;
      state: {
        title: string;
        id: string;
      };
      pincode: string;
      phoneNumber: string;
    }>;
    isCityOrStateError: boolean;
    countriesData: AutocompleteDropdownItem<string>[];
    statesData: AutocompleteDropdownItem<string>[];
    handleMyRewardsButtonClick: () => void;
    handleGoBackButtonClick: () => void;
    firstName: string;
    lastName: string;
  };
  swagSizeProps?: {
    size: DropdownValue | undefined;
    sizes: DropdownValue[];
    handleSizeChange: (
      val: AutocompleteDropdownItem<string | number, unknown> | null,
    ) => void;
  };
  swagQuantityProps?: {
    quantity: DropdownValue | undefined;
    availableQuantities: DropdownValue[];
    handleQuantityChange: (
      val: AutocompleteDropdownItem<string | number, unknown> | null,
    ) => void;
  };
  swagColorProps?: {
    color: DropdownValue | undefined;
    colors: DropdownValue[];
    handleColorChange: (
      val: AutocompleteDropdownItem<string | number, unknown> | null,
    ) => void;
  };
  quantityDropdownValues?: any;
  isRewardItemLoading?: boolean;
}
