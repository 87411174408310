import { useMutation } from 'react-query';

import { makeAPICall } from '../utils';
import { OnboardingRequest } from './interfaces';
import { VERIFY_EMAIL_ONBOARDING } from '../../constants/endpoints';

export const useValidateEmailRequestForOnboarding = () => {
  return useMutation((payload: OnboardingRequest) =>
    makeAPICall(VERIFY_EMAIL_ONBOARDING, payload),
  );
};
