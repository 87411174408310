// @ts-nocheck TODO: type issues need to be fixed in this file
import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { string, arrayOf, shape, object, func, bool, number } from 'prop-types';

import { buttonShape } from '../../organism/StepsWizard';
import Body from '../../atoms/Body';
import RadioGroup from '../../molecules/RadioGroup';
import IconInfo from '../../molecules/IconInfo';
import EditEntityComponent from '../../organism/EditEntityComponent';
import IdentityManagerFlowTemplate from '../../templates/IdentityManagerFlowTemplate';
import LoadingPulse from '../../atoms/LoadingPulse';
import Image from '../../atoms/Image';
import { SelectPeopleLoadingStateContent } from '../../../Utils/data/identityManager/common';

const useStyles = makeStyles({
  heading: {
    marginBottom: '4px',
  },
  subHeading: {
    marginBottom: '16px',
  },
  // FIXME: Fix adding a wrapper just to apply bottom margin
  radioGroup: {
    marginBottom: '24px',
  },
  iconInfo: {
    marginBottom: '24px',
  },
  loadingBlock: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginTop: '16px',
  },
  loadingImage: {
    borderRadius: '6px',
    maxWidth: '245px',
  },
});

const IdentityUserSelectionPage = (props) => {
  const {
    title,
    steps,
    activeStep,
    nextButton,
    cancelButton,
    heading,
    subHeading,
    radioGroupOptions,
    radioGroupValue,
    onRadioGroupChange,
    entityList,
    paginatedDataAddedEntityList,
    paginatedDataRemovedEntityList,
    onEntityListChange,
    onItemClick,
    entityCountMap,
    showInfo,
    infoHeading,
    infoSubheading,
    allListEntityTableHeading,
    newListEntityTableHeading,
    removeListEntityTableHeading,
    searchBoxPlaceHolder,
    notifications,
    isEditTableLoading,
    showSelectAll,
    helperLink,
    showLoadingState,
    allEntitySearchText,
    handleAllEntitySearchText,
    handleFetchNextPage,
    hasNextPage,
    hasPaginatedIntegrationData,
    isJobPolling,
  } = props;
  const classes = useStyles(props);
  const leftButton = { hidden: true };
  const stepsWizard = {
    title,
    steps,
    activeStep,
    nextButton,
    cancelButton,
    leftButton,
    helperLink,
  };
  const headingComponent = useMemo(
    () => (
      <Body variant="body1Bold" className={classes.heading}>
        {heading}
      </Body>
    ),
    [classes.heading, heading],
  );
  const subHeadingComponent = useMemo(
    () => (
      <Body variant="body2" className={classes.subHeading}>
        {subHeading}
      </Body>
    ),
    [classes.subHeading, subHeading],
  );
  const radioGroupComponent = useMemo(
    () => (
      <div className={classes.radioGroup}>
        <RadioGroup
          radioOptions={radioGroupOptions}
          value={radioGroupValue}
          onChange={onRadioGroupChange}
        />
      </div>
    ),
    [
      classes.radioGroup,
      onRadioGroupChange,
      radioGroupOptions,
      radioGroupValue,
    ],
  );
  const infoComponent = useMemo(
    () => (
      <div className={classes.iconInfo}>
        <IconInfo>
          <Body variant="body2Bold" inline>
            {infoHeading}
          </Body>
          &nbsp;
          <Body variant="body2" inline>
            {infoSubheading}
          </Body>
        </IconInfo>
      </div>
    ),
    [classes.iconInfo, infoHeading, infoSubheading],
  );

  return (
    <IdentityManagerFlowTemplate
      stepsWizard={stepsWizard}
      notifications={notifications}
    >
      {headingComponent}
      {subHeadingComponent}
      {!hasPaginatedIntegrationData && radioGroupComponent}
      {hasPaginatedIntegrationData && !isJobPolling && radioGroupComponent}
      {showInfo && infoComponent}
      {entityList && !showLoadingState && radioGroupValue !== 'ALL_USERS' && (
        <EditEntityComponent
          handleFetchNextPage={handleFetchNextPage}
          hasNextPage={hasNextPage}
          hasPaginatedIntegrationData={hasPaginatedIntegrationData}
          allEntitySearchText={allEntitySearchText}
          handleAllEntitySearchText={
            hasPaginatedIntegrationData ? handleAllEntitySearchText : null
          }
          entityList={entityList}
          paginatedDataAddedEntityList={paginatedDataAddedEntityList}
          paginatedDataRemovedEntityList={paginatedDataRemovedEntityList}
          onChange={onEntityListChange}
          onItemClick={onItemClick}
          entityCountMap={entityCountMap}
          allListEntityTableHeading={allListEntityTableHeading}
          newListEntityTableHeading={newListEntityTableHeading}
          removeListEntityTableHeading={removeListEntityTableHeading}
          searchBoxPlaceHolder={searchBoxPlaceHolder}
          showSelectAll={showSelectAll}
        />
      )}
      {showLoadingState && (
        <div className={classes.root}>
          <Body variant="body1Bold" className={classes.heading}>
            {SelectPeopleLoadingStateContent.HEADING}
          </Body>
          <Body variant="body2">{SelectPeopleLoadingStateContent.BODY}</Body>
          <div className={classes.loadingBlock}>
            <Image
              src={SelectPeopleLoadingStateContent.LOADING_GIF}
              alt="Loading gif"
              classes={{
                root: classes.loadingImage,
              }}
            />
          </div>
        </div>
      )}
      {isEditTableLoading && !showLoadingState && <LoadingPulse />}
    </IdentityManagerFlowTemplate>
  );
};

IdentityUserSelectionPage.propTypes = {
  title: string.isRequired,
  activeStep: string.isRequired,
  steps: arrayOf(
    shape({
      id: string.isRequired,
      title: string.isRequired,
      leftButton: object,
      cancelButton: object,
      nextButton: object,
    }),
  ).isRequired,
  nextButton: buttonShape.isRequired,
  cancelButton: buttonShape.isRequired,
  heading: string.isRequired,
  subHeading: string.isRequired,
  allListEntityTableHeading: string.isRequired,
  newListEntityTableHeading: string.isRequired,
  removeListEntityTableHeading: string.isRequired,
  searchBoxPlaceHolder: string.isRequired,
  radioGroupOptions: arrayOf(
    shape({
      value: string.isRequired,
      label: string.isRequired,
    }),
  ).isRequired,
  radioGroupValue: string.isRequired,
  onRadioGroupChange: func.isRequired,
  entityList: arrayOf(
    shape({
      id: string.isRequired,
      avatarUrl: string,
      mainText: string.isRequired,
      isSelected: bool,
      sideText: string,
    }),
  ),
  paginatedDataAddedEntityList:
    arrayOf(
      shape({
        id: string.isRequired,
        avatarUrl: string,
        mainText: string.isRequired,
        isSelected: bool,
        sideText: string,
      }),
    ) || [],
  paginatedDataRemovedEntityList:
    arrayOf(
      shape({
        id: string.isRequired,
        avatarUrl: string,
        mainText: string.isRequired,
        isSelected: bool,
        sideText: string,
      }),
    ) || [],
  onEntityListChange: func,
  showInfo: bool.isRequired,
  infoHeading: string,
  infoSubheading: string,
  // TODO: replace with interface in useNotification after tsx conversation
  notifications: arrayOf(
    shape({
      id: number.isRequired,
      message: string.isRequired,
      type: string.isRequired,
    }),
  ),
  isEditTableLoading: bool,
  onItemClick: func,
  entityCountMap: shape({}),
  showSelectAll: bool,
  helperLink: string,
  showLoadingState: bool,
  allEntitySearchText: string,
  handleAllEntitySearchText: func,
  handleFetchNextPage: func,
  hasNextPage: bool,
  hasPaginatedIntegrationData: bool,
  isJobPolling: bool,
};

IdentityUserSelectionPage.defaultProps = {
  entityList: null,
  helperLink: '',
  onEntityListChange: () => {},
  infoHeading: '',
  infoSubheading: '',
  notifications: [],
  isEditTableLoading: false,
  onItemClick: undefined,
  entityCountMap: undefined,
  showSelectAll: false,
  showLoadingState: false,
};

export default IdentityUserSelectionPage;
