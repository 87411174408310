import React, { useEffect } from 'react';

import '../styles/docx.scss';
import Loading from '../loading';

const TxtViewer = (props: { filePath: string | URL }) => {
  const { filePath } = props;
  useEffect(() => {
    const jsonFile = new XMLHttpRequest();
    jsonFile.open('GET', filePath, true);
    jsonFile.send();
    jsonFile.responseType = 'arraybuffer';
    jsonFile.onreadystatechange = async () => {
      if (jsonFile.readyState === 4 && jsonFile.status === 200) {
        const blob = new Blob([jsonFile.response], { type: 'text/plain' });
        const reader = new FileReader();
        reader.onload = function (evt) {
          const docEl = document.createElement('div');
          docEl.className = 'document-container';
          docEl.innerHTML =
            '<p>' +
            /* @ts-ignore-next-line */
            evt.target.result
              /* @ts-ignore-next-line */
              .replace(/\n{2,}/g, '</p><p>')
              .replace(/\n/g, '<br>') +
            '</p>';
          /* @ts-ignore-next-line */
          document.getElementById('txt').innerHTML = docEl.outerHTML;
        };
        reader.readAsText(blob, 'UTF-8');
      }
    };
  });

  return (
    <div id="txt">
      <Loading />
    </div>
  );
};

export default TxtViewer;
/*
Alternate Version

import React, { Component } from 'react';
import Loading from '../loading';

export default class extends Component {
    componentDidMount() {
        fetch(this.props.filePath)
            .then(response => response.text()
                .then(data => {
                    const docEl = document.createElement('div');
                    docEl.className = 'document-container';
                    docEl.innerHTML = data.replace(/(?:\r\n|\r|\n)/g, '<br />');
                    document.getElementById('txt').innerHTML = docEl.outerHTML;
                })
                .catch(error => console.log('Error: ', error))
            )
            .catch(error => console.log('Error: ', error));
    }

    render() {
        return (
            <div id="txt">
                <Loading />
            </div>
        );
    }
}
*/
