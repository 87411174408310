import create from 'zustand';
import {
  LayoutStoreState,
  SecondaryPanel,
  SelectedMember,
} from '../../interfaces/Layout';
import { isMobile } from '../../Utils/window';

const useLayoutStore = create<LayoutStoreState>((set) => ({
  isLeftAsideOpen: !isMobile,
  isRightAsideOpen: !isMobile,
  showRightAside: true,
  activeNavItem: SecondaryPanel.ASSEMBLY,
  activeSecondaryPanel: SecondaryPanel.ASSEMBLY,
  isLeftNavHovered: false,
  taskIdForNotebook: '',
  toggleLeftAsideOpen: () =>
    set(({ isLeftAsideOpen }) => ({ isLeftAsideOpen: !isLeftAsideOpen })),
  toggleRightAsideOpen: () =>
    set(({ isRightAsideOpen }) => ({ isRightAsideOpen: !isRightAsideOpen })),
  toggleShowRightAside: () =>
    set(({ showRightAside }) => ({ showRightAside: !showRightAside })),
  setLeftNavHoveredToTrue: () => set(() => ({ isLeftNavHovered: true })),
  setLeftNavHoveredToFalse: () => set(() => ({ isLeftNavHovered: false })),
  setLeftAsideOpenToTrue: () => set(() => ({ isLeftAsideOpen: true })),
  setLeftAsideOpenToFalse: () => set(() => ({ isLeftAsideOpen: false })),
  setRightAsideOpenToTrue: () => set(() => ({ isRightAsideOpen: true })),
  setRightAsideOpenToFalse: () => set(() => ({ isRightAsideOpen: false })),
  setShowRightAsideToTrue: () => set(() => ({ showRightAside: true })),
  setShowRightAsideToFalse: () => set(() => ({ showRightAside: false })),
  setActiveNavItem: (navItem) =>
    set(() => ({
      activeNavItem: navItem,
    })),
  setActiveSecondaryPanel: (navItem) =>
    set(() => ({
      activeSecondaryPanel: navItem,
    })),
  selectedMember: undefined,
  removeSelectedMember: () => {
    set(() => ({ selectedMember: undefined }));
  },
  setSelectedMember: (selectedMember: SelectedMember) => {
    set(() => ({ selectedMember }));
  },
  setMemberAndToggleRightAsideOpen: (selectedMember: SelectedMember) => {
    set(() => ({ selectedMember, isRightAsideOpen: true }));
  },
  setTaskIdForNotebook: (taskId: string) =>
    set(() => ({
      taskIdForNotebook: taskId,
    })),
}));

export default useLayoutStore;
