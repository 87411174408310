// @ts-nocheck TODO: type issues need to be fixed in this file
import React from 'react';
import styled from 'styled-components';

import Accordion from '../../../molecules/Accordion';
import Menu from '../../../molecules/Menu';
import MenuList from '../../../molecules/MenuItem';
import MenuListLoader from '../../../molecules/MenuItem/Loader';

import ThemeV2 from '../../../../componentsV2/theme';
import Body from '../../../atoms/Body';
import { DropdownPlacement } from '../../../molecules/Dropdown_V2/interfaces';
import useDropDown from '../../../../hooks/useDropDown';
import { FLOW_LEFT_DRAWER_ADD_NEW_FLOWS } from '../../../../languages/en/flows';
import {
  FlowsMenuAccordionLoadedProps,
  // FlowsMenuAccordionLoading,
  FlowsMenuAccordionProps,
  NavItemProps,
} from './types';
import { ComponentStatus } from '../../../../interfaces/component';

const StyledMenuListItem = styled(MenuList.Item)`
  margin-bottom: 4px;
  &:last-child {
    margin-bottom: 0px;
  }
`;

const StyledMenu = styled(Menu)`
  padding-right: 16px;
`;

const StyledAccordion = styled(Accordion)`
  padding: 8px 0px;
  overflow: hidden;
`;

const AccordionHeader = ({
  title,
  isAccordionOpen,
  totalNotificationsCount,
  isExpanded,
  canAddFlows,
  addFlowsButtonClick,
  disabled,
}: {
  title: string;
  isAccordionOpen: boolean;
  isExpanded: boolean;
  totalNotificationsCount?: number;
  canAddFlows: boolean;
  addFlowsButtonClick: () => void;
  disabled?: boolean;
}) => {
  return (
    <StyledMenu
      icon="caret-rounded"
      text={title}
      rotate={isAccordionOpen}
      count={totalNotificationsCount}
      showCount={!isAccordionOpen}
      isExpanded={isExpanded}
      prefix="routine"
      showAddButton={canAddFlows}
      onAddButtonClick={addFlowsButtonClick}
      addButtonText={FLOW_LEFT_DRAWER_ADD_NEW_FLOWS}
      disabled={disabled}
    />
  );
};

type LisItemProps = Omit<FlowsMenuAccordionLoadedProps, 'navItems'> & {
  navItem: NavItemProps;
};

const ListItem = (props: LisItemProps) => {
  const {
    onMenuItemClick,
    dropdownMenuItems,
    onDropdownMenuItemClick,
    navItem,
    onMenuItemRightClick,
    isHover,
    disabled,
  } = props;

  const { anchorProps } = useDropDown({
    menuItems: dropdownMenuItems || [],
    onItemClick: onDropdownMenuItemClick,
  });
  const { referenceElement, anchorEl, toggleDropdown } = anchorProps;

  const isActive =
    navItem.occurrence?.activeOccurrence &&
    navItem.isFlowParticipant &&
    !navItem.occurrence?.activeOccurrence.hasResponded
      ? true
      : false;

  const textStyle = navItem.isUnread ? 'body1Bold' : 'body1';
  let textColor = 'gray8';
  if (navItem.isMuted) {
    textColor = 'gray6';
  }
  if (navItem.isUnread) {
    textColor = 'gray9';
  }

  return (
    <StyledMenuListItem
      key={navItem.id}
      isSelected={navItem.isActive}
      onClick={() => {
        if (!disabled) onMenuItemClick(navItem.id);
      }}
      onRightClick={(e) => {
        e.preventDefault();
        toggleDropdown();
        if (onMenuItemRightClick && !disabled) {
          onMenuItemRightClick(navItem);
        }
      }}
      refElement={referenceElement}
      anchorEl={anchorEl}
      isExpanded={isHover}
      disabled={disabled}
    >
      {isActive ? (
        <MenuList.PrefixElement
          icon="answer"
          color={ThemeV2.palette.geekBlue6}
        />
      ) : (
        <></>
      )}
      {/* <MenuList.PrefixElement icon="answer" color={ThemeV2.palette.geekBlue6} /> */}
      <MenuList.Emoticon emoticon={navItem.emoticon} />
      <MenuList.Text variant={textStyle} color={textColor}>
        {navItem.label}
      </MenuList.Text>
      {navItem.isUnread ? (
        <MenuList.SuffixElement>
          <Body variant="body3" color="gray1">
            {navItem.badgeText}
          </Body>
        </MenuList.SuffixElement>
      ) : (
        <></>
      )}
      <MenuList.DropdownListHolder
        {...anchorProps}
        dropdownPlacement={DropdownPlacement.BottomEnd}
      />
    </StyledMenuListItem>
  );
};

const FlowsMenuAccordionLoaded = (props: FlowsMenuAccordionLoadedProps) => {
  const {
    accordionTitle,
    isAccordionOpen = false,
    onAccordionClick,
    navItems,
    children,
    isAddNewFlowOn = true,
    addFlowsButtonClick,
    totalNotificationsCount,
    onMouseEnter,
    onMouseLeave,
    isHover = false,
    canAddFlows,
    disabled,
  } = props;

  return (
    <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <StyledAccordion
        isAccordionOpen={isAccordionOpen}
        accordionHeader={
          <AccordionHeader
            title={accordionTitle}
            isAccordionOpen={isAccordionOpen}
            totalNotificationsCount={totalNotificationsCount}
            isExpanded={isHover}
            canAddFlows={canAddFlows}
            addFlowsButtonClick={addFlowsButtonClick}
            disabled={disabled}
          />
        }
        onAccordionClick={() => {
          if (!disabled) onAccordionClick();
        }}
        disabled={disabled}
      >
        <MenuList>
          <>
            {navItems.map((menuItem) => (
              <ListItem
                key={menuItem.id}
                navItem={menuItem}
                {...props}
                disabled={disabled}
              />
            ))}
          </>
          {isAddNewFlowOn ? (
            <StyledMenuListItem
              onClick={addFlowsButtonClick}
              isExpanded={isHover}
            >
              <MenuList.IconButton icon="union-rounded" size="16px" />
              <MenuList.Text>{FLOW_LEFT_DRAWER_ADD_NEW_FLOWS}</MenuList.Text>
            </StyledMenuListItem>
          ) : (
            <></>
          )}
        </MenuList>
        {children}
      </StyledAccordion>
    </div>
  );
};

const FlowsMenuAccordionLoading = (props: FlowsMenuAccordionProps) => {
  const {
    accordionTitle,
    isAccordionOpen,
    onAccordionClick,
    isHover = false,
    onMouseEnter,
    onMouseLeave,
  } = props;

  const Header = () => {
    return (
      <Menu
        icon="caret-rounded"
        text={accordionTitle}
        rotate={isAccordionOpen}
        isExpanded={isHover}
        prefix="routine"
      />
    );
  };
  return (
    <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <StyledAccordion
        isAccordionOpen={isAccordionOpen}
        accordionHeader={<Header />}
        onAccordionClick={onAccordionClick}
      >
        <div style={{ padding: '0 12px 0 24px' }}>
          <MenuListLoader isExpanded={isHover} />
        </div>
      </StyledAccordion>
    </div>
  );
};

const FlowsMenuAccordion = (props: FlowsMenuAccordionProps) => {
  const { status } = props;
  switch (status) {
    case ComponentStatus.LOADED:
      return <FlowsMenuAccordionLoaded {...props} />;
      break;
    case ComponentStatus.LOADING:
      return <FlowsMenuAccordionLoading {...props} />;
    default:
      return <div>Empty</div>;
      break;
  }
};

export default FlowsMenuAccordion;
