import React from 'react';

import MeetingList from '../../../atomic/organism/QuickSetup/MeetingList';
import { MeetingSelectionControllerProps } from './type';

import useMeetingSelectionController from './useMeetingsSelectionController';

const MeetingSelectionController = (props: MeetingSelectionControllerProps) => {
  const { models, operations } = useMeetingSelectionController(props);

  const {
    recurringEvents,
    nonRecurringEvents,
    selectedMeetingType,
    calendarSelectBarProps,
    meetingTypeDropdownItems,
    meetingListComponentStatus,
  } = models;

  const {
    handleOnMeetingSelected,
    handleOnMeetingTypeChanged,
    handleExploreOnOwnClick,
  } = operations;

  return (
    <MeetingList
      {...calendarSelectBarProps}
      value={selectedMeetingType.value}
      status={meetingListComponentStatus}
      menuItems={meetingTypeDropdownItems}
      meetingType={selectedMeetingType.value}
      onItemClick={handleOnMeetingTypeChanged}
      recurringMeetingItems={recurringEvents}
      onMeetingItemClick={handleOnMeetingSelected}
      nonRecurringMeetingItems={nonRecurringEvents}
      onExploreOwnClick={handleExploreOnOwnClick}
    />
  );
};

export default MeetingSelectionController;
