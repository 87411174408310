import React, { useMemo, useState, useEffect } from 'react';
import styled from 'styled-components';

import { SingleSelectAutoComplete } from '../../Autocomplete';
import FormsEditorBaseInputBlock from '../FormsEditorInputBaseBlock';
import {
  ContentBlockLocalProperties,
  ContentPersonSelectorBlockState,
  PersonSelectorBlockBuilderSettingState,
  SelectableParticipantSelection,
  CriteriaGroups,
} from '../../../../interfaces/Flow/Builder';
import { SelectablePeopleTypes } from '../../../../interfaces/Flow';
import {
  BLOCK_MUST_BE_LINKED_TO,
  GIVE_POINTS,
  PERSON_SELECTION,
  PERSON_SELECTOR_PLACEHOLDER_LABEL,
  PERSON_SELECTOR_BUILDER_BLOCK_LABEL,
} from '../../../../languages/en/flows/builder';
import ThemeV2 from '../../../../componentsV2/theme';
import CustomPersonSelectorModal from './CustomPersonSelectorModal';
// eslint-disable-next-line max-len
import { Participant } from '../../FlowRunNowModal/types';
import { BLOCK, CUSTOM, THIS } from '../../../../languages/en/singleWords';
import { mapRulesFromCriteriaGroups } from '../../../../Utils/flows/builder/utils';
import { capitalizeFirstLetter } from '../../../../Utils/text';
import Body from '../../../atoms/Body';
import { useGetMembersFromCriteria } from '../../../../hooks/useMembersSearch';
import { MemberState } from '../../../../interfaces/user';
import useFlowBuilderStore from '../../../../stores/flowBuilderStore';
import { isArrayEquals } from '../../../../Utils/common';
import {
  newContentMembersToInviteSelector,
  setNewContentMembersToInviteSelector,
} from '../../../../stores/flowBuilderStore/selectors';

export interface FormsEditorPersonSelectorInputBlockProps
  extends ContentBlockLocalProperties {
  blockData: ContentPersonSelectorBlockState;
}
const StyledSingleSelectAutoComplete = styled(SingleSelectAutoComplete)`
  margin: 16px 0 12px 0;
  width: 100%;
  pointer-events: none;

  input {
    background: ${ThemeV2.palette.white};
  }
`;

const bannerText = (
  <>
    {`${capitalizeFirstLetter(THIS)} `}
    <Body inline variant="body3Medium" color="geekBlue6">
      {PERSON_SELECTION}
    </Body>
    {` ${BLOCK_MUST_BE_LINKED_TO} `}
    <Body inline variant="body3Medium" color="geekBlue6">
      {GIVE_POINTS}
    </Body>
    {` ${BLOCK.toLowerCase()}. ${PERSON_SELECTOR_BUILDER_BLOCK_LABEL}`}
  </>
);

const FormsEditorPersonSelectorBlock = ({
  blockData,
  currentIndex,
  handleToggleIsDescriptionNeeded,
  handleToggleIsRequired,
  onValueChange,
  totalNumberOfItems,
  onDeleteClick,
  onMoveDownClick,
  onMoveUpClick,
}: FormsEditorPersonSelectorInputBlockProps) => {
  const {
    chosenParticipantSelection,
    selectedBlockParticipants,
    description,
    isRequired,
    criteriaGroups,
    customPersonSelectorCount,
    assemblyCurrency,
    isLinkedBlock = false,
  } = blockData;

  const rulesFromCriteria = useMemo(
    () => mapRulesFromCriteriaGroups(criteriaGroups),
    [criteriaGroups],
  );

  const newContentMembersToInvite = useFlowBuilderStore(
    newContentMembersToInviteSelector,
  );
  const setNewContentMembersToInvite = useFlowBuilderStore(
    setNewContentMembersToInviteSelector,
  );

  const {
    models: {
      isLoading,
      hasMoreMembers,
      searchedMembers,
      totalMembers,
      newMembers,
      totalPendingMembers,
    },
    operations: { fetchMoreMembers },
  } = useGetMembersFromCriteria(rulesFromCriteria, undefined, {
    isNewMembersAdded: true,
  });

  const [prevBlockParticipants, setPrevBlockParticipants] =
    useState<SelectablePeopleTypes | null>(null);

  useEffect(() => {
    if (newMembers) {
      if (customPersonSelectorCount !== totalMembers + newMembers.length) {
        onValueChange({
          ...blockData,
          customPersonSelectorCount: totalMembers + newMembers.length,
        });
      }
    } else {
      if (customPersonSelectorCount !== totalMembers)
        onValueChange({
          ...blockData,
          customPersonSelectorCount: totalMembers,
        });
    }
  }, [
    blockData,
    customPersonSelectorCount,
    newMembers,
    onValueChange,
    totalMembers,
  ]);

  const participants = useMemo(() => {
    if (!searchedMembers || (!searchedMembers.length && !newMembers?.length)) {
      return [];
    }

    let formattedNewMembers = [];

    const formattedParticipants = searchedMembers.map(
      (participant) =>
        ({
          image: participant.image || '',
          memberId: participant.memberID,
          lastName: participant.lastName,
          firstName: participant.firstName,
          memberState: participant.memberState,
        } as Participant),
    );

    if (newMembers) {
      formattedNewMembers = newMembers.map((email) => {
        return {
          image: '',
          memberId: email,
          firstName: email,
          lastName: '',
          memberState: MemberState.NEW,
        };
      });

      return [...formattedNewMembers, ...formattedParticipants];
    }

    return formattedParticipants;
  }, [newMembers, searchedMembers]);

  const participantsCount = useMemo(() => {
    if (newMembers) {
      return totalMembers + newMembers.length;
    }
    return totalMembers;
  }, [newMembers, totalMembers]);

  const pendingMembersParticipantCount = useMemo(() => {
    if (newMembers) {
      return totalPendingMembers + newMembers.length;
    }
    return totalPendingMembers;
  }, [newMembers, totalPendingMembers]);

  useEffect(() => {
    if (!isArrayEquals(newMembers, newContentMembersToInvite)) {
      if (newMembers) {
        setNewContentMembersToInvite(newMembers);
      }

      if (!newMembers) {
        setNewContentMembersToInvite([]);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newMembers]);

  const changeChosenParticipantSelection = (
    updatedSelection: SelectableParticipantSelection,
  ) => {
    if (onValueChange) {
      onValueChange({
        ...blockData,
        chosenParticipantSelection: updatedSelection,
      });
    }
  };

  const handleOnCriteriaGroupsChange = (
    updatedCriteriaGroups: CriteriaGroups | undefined,
  ) => {
    if (onValueChange && updatedCriteriaGroups) {
      onValueChange({
        ...blockData,
        criteriaGroups: updatedCriteriaGroups,
      });
    }
  };

  const changeBlockParticipants = (updatedSelection: SelectablePeopleTypes) => {
    if (onValueChange) {
      setPrevBlockParticipants(selectedBlockParticipants);
      onValueChange({
        ...blockData,
        selectedBlockParticipants: updatedSelection,
        ...(updatedSelection !== selectedBlockParticipants &&
        selectedBlockParticipants === CUSTOM.toUpperCase()
          ? {
              customPersonSelectorCount: 0,
              criteriaGroups: undefined,
            }
          : {}),
      });
    }
  };

  const postCustomModalClose = () => {
    if (
      selectedBlockParticipants === CUSTOM.toUpperCase() &&
      customPersonSelectorCount === 0 &&
      prevBlockParticipants !== null &&
      onValueChange
    ) {
      onValueChange({
        ...blockData,
        selectedBlockParticipants: prevBlockParticipants,
      });
    }
  };

  const blockSettingState: PersonSelectorBlockBuilderSettingState = {
    type: 'PERSON_SELECTOR',
    isDescriptionNeeded: description !== null,
    isRequired,
    toggleIsRequired: handleToggleIsRequired,
    toggleDescriptionNeeded: handleToggleIsDescriptionNeeded,
    chosenParticipantSelection,
    changeChosenParticipantSelection,
    selectedBlockParticipants,
    changeBlockParticipants,
    customPersonSelectorCount,
    postCustomModalClose,
    customModalOptions: {
      component: (
        <CustomPersonSelectorModal
          hasMoreMembers={hasMoreMembers}
          isLoading={isLoading}
          participants={participants}
          fetchNextPage={fetchMoreMembers}
          participantsCount={participantsCount}
          criteriaGroups={criteriaGroups}
          handleOnCriteriaGroupsChange={handleOnCriteriaGroupsChange}
          pendingMembersParticipantCount={pendingMembersParticipantCount}
        />
      ),
    },
    disableRequiredToggle: isLinkedBlock,
  };

  return (
    <FormsEditorBaseInputBlock
      blockData={blockData}
      currentIndex={currentIndex !== undefined ? currentIndex : 0}
      assemblyCurrency={assemblyCurrency}
      totalNumberOfItems={totalNumberOfItems}
      blockSettingState={blockSettingState}
      onDeleteClick={onDeleteClick}
      onMoveDownClick={onMoveDownClick}
      onMoveUpClick={onMoveUpClick}
      onValueChange={onValueChange}
      bannerText={isLinkedBlock ? bannerText : undefined}
    >
      <StyledSingleSelectAutoComplete
        autoFocus
        label={PERSON_SELECTOR_PLACEHOLDER_LABEL}
        value={null}
        onChange={() => {}}
        options={[]}
        multiple={false}
        textboxSize="large"
        onTextboxValueChange={() => {}}
        disabled
      />
    </FormsEditorBaseInputBlock>
  );
};

export default FormsEditorPersonSelectorBlock;
