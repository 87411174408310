import _ from 'lodash';
import RRule, { Frequency, Options, rrulestr } from 'rrule';

import { hasAllWeekDays, hasWeekDaysInParsedRRule } from '..';
// eslint-disable-next-line max-len
import { FlowFrequencyOptions } from '../../../../controllers/flowsBuilder/FlowsBuilderTriggerController/ScheduleDateTimeController/types';

// to get getFlowFrequencyValue for the builder/to edit
export const getFlowFrequency = (rruleString: Partial<Options>) => {
  const { freq, count, interval, byweekday, bysetpos } = rruleString;

  const rule = new RRule(rruleString);
  const RRuleStr = rrulestr(rule.toString());

  /* DAILY */
  if (freq === Frequency.DAILY) {
    if (interval === undefined || interval === 1) {
      return FlowFrequencyOptions.DAILY;
    }
  }

  /* EVERY WEEKDAY */
  if (
    freq === Frequency.WEEKLY &&
    (interval === undefined || interval === 1) &&
    hasWeekDaysInParsedRRule(RRuleStr.options.byweekday)
  ) {
    return FlowFrequencyOptions.EVERY_WEEKDAY;
  }

  /* WEEKLY ON DAY(Mon) */
  if (
    freq === Frequency.WEEKLY &&
    _.isArray(byweekday) &&
    byweekday.length === 1 &&
    (interval === undefined || interval === 1)
  ) {
    return FlowFrequencyOptions.WEEKLY;
  }

  /* BI-WEEKLY ON DAY(Mon) */
  if (
    freq === Frequency.WEEKLY &&
    _.isArray(byweekday) &&
    byweekday.length === 1 &&
    interval === 2
  ) {
    return FlowFrequencyOptions.BIWEEKLY;
  }

  /* MONTHLY ON (1st, 2nd, 3rd & 4th) DAY(Mon) */
  if (
    freq === Frequency.MONTHLY &&
    (interval === undefined || interval === 1) &&
    _.isArray(byweekday) &&
    byweekday.length === 1 &&
    bysetpos !== -1
  ) {
    return FlowFrequencyOptions.MONTHLY;
  }

  /* MONTHLY ON LAST DAY(Mon) */
  if (
    freq === Frequency.MONTHLY &&
    (interval === undefined || interval === 1) &&
    _.isArray(byweekday) &&
    byweekday.length === 1 &&
    bysetpos === -1
  ) {
    return FlowFrequencyOptions.MONTHLY_LAST;
  }

  /* QUARTERLY ON (1st, 2nd, 3rd & 4th) DAY(Mon) */
  if (
    freq === Frequency.MONTHLY &&
    (interval === 3 || interval === undefined) &&
    _.isArray(byweekday) &&
    byweekday.length === 1 &&
    bysetpos !== -1
  ) {
    return FlowFrequencyOptions.QUARTERLY;
  }

  /* QUARTERLY ON (1st, 2nd, 3rd & 4th) DAY(Mon) */
  if (
    freq === Frequency.MONTHLY &&
    (interval === 3 || interval === undefined) &&
    _.isArray(byweekday) &&
    byweekday.length === 1 &&
    bysetpos === -1
  ) {
    return FlowFrequencyOptions.QUARTERLY_LAST;
  }

  /* ANNUALLY */
  if (freq === Frequency.YEARLY && (interval === undefined || interval === 1)) {
    return FlowFrequencyOptions.ANNUALLY;
  }

  /* ONLY ONCE */
  if (freq === undefined && count === 1 && interval === undefined) {
    return FlowFrequencyOptions.ONCE;
  }

  /* CUSTOM */
  // REPEAT DAYS - EVERY 3 DAYS
  if (freq === Frequency.DAILY && interval !== undefined && interval > 1) {
    return FlowFrequencyOptions.REPEAT_DAYS;
  }

  // REPEAT WEEK DAYS - WEEKLY ON ALL DAYS
  if (
    freq === Frequency.WEEKLY &&
    interval !== undefined &&
    (interval === 1 || interval > 1) &&
    hasAllWeekDays(RRuleStr.options.byweekday.sort())
  ) {
    return FlowFrequencyOptions.REPEAT_ALL_DAYS;
  }

  // REPEAT WEEK DAYS - WEEKLY ON WEEK DAYS
  if (
    freq === Frequency.WEEKLY &&
    interval !== undefined &&
    interval > 1 &&
    hasWeekDaysInParsedRRule(RRuleStr.options.byweekday.sort())
  ) {
    return FlowFrequencyOptions.REPEAT_WEEK_DAYS;
  }

  // REPEAT WEEKS - Every (1/3) WEEKS ON (Mon/Tue)
  if (
    freq === Frequency.WEEKLY &&
    interval !== undefined &&
    interval > 0 &&
    _.isArray(byweekday) &&
    byweekday.length > 0
  ) {
    return FlowFrequencyOptions.REPEAT_WEEKS;
  }

  // REPEAT YEARS - ANNUALLY & EVERY (2/3) YEARS
  if (freq === Frequency.YEARLY && interval !== undefined && interval > 1) {
    return FlowFrequencyOptions.REPEAT_YEARS;
  }

  // REPEAT MONTHLY - MONTHLY/EVERY MONTH ON DAY (1/16/31)
  if (
    freq === Frequency.MONTHLY &&
    interval !== undefined &&
    interval > 0 &&
    bysetpos === undefined
  ) {
    return FlowFrequencyOptions.REPEAT_MONTHS;
  }

  // REPEAT MONTHLY ON - MONTHLY/EVERY MONTH ON (1st,2nd,3rd,4th & last) (Mon/Tue)
  if (
    freq === Frequency.MONTHLY &&
    interval !== undefined &&
    interval > 0 &&
    bysetpos !== undefined &&
    _.isArray(byweekday) &&
    byweekday.length > 0
  ) {
    return FlowFrequencyOptions.REPEAT_MONTHS;
  }
  /* CUSTOM */
  return FlowFrequencyOptions.EVERY_WEEKDAY;
};
