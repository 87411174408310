export const VIEW_MY_ACTIVITY = 'View my activity';
export const viewMembersActivity = (firstName: string) =>
  `View ${firstName}'s activity`;

export const CELEBRATION_STATISTICS = 'Celebration statistics';
export const GIVEN = 'given';
export const RECEIVED = 'received';
export const SINCE = 'Since';

export const FLOW_STATISTICS = 'Flow Statistics';
export const CREATED = 'created';
export const PARTICIPATING = 'participating';

export const DETAILS = 'Details';
export const JOB_TITLE = 'Job Title';
export const DEPARTMENT = 'Department';
export const EMAIL = 'Email';
export const MANAGER = 'Manager';
export const DIRECT_REPORTS = 'Direct Reports';
export const LOCAL_TIME = 'Local Time';
export const LOCATION = 'Location';
export const BIRTHDAY = 'Birthday';
export const WORK_ANNIVERSARY = 'Work Anniversary';
export const EDIT_MY_DETAILS = 'Edit my details';

export const VIEW_PROFILE = 'View Profile';
export const GO_TO_ADMIN = 'Go to Admin';
export const GO_TO_HELP_CENTER = 'Go to help center';
export const GET_LIVE_SUPPORT = 'Get live support';
export const GIVE_US_FEEDBACK = 'Give us feedback';
export const SIGN_OUT = 'Sign out';
