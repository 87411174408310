import styled from 'styled-components';
import { StyledButton } from '../../../Utils/styles/button';

import { SelectableButtonProps } from './types';

export const StyledSelectableButton = styled(
  StyledButton,
)<SelectableButtonProps>`
  background: ${({ bgColor, activeBgColor, isActive }) =>
    isActive ? activeBgColor : bgColor};
  color: ${({ textColor, activeTextColor, isActive }) =>
    isActive ? activeTextColor : textColor};
  border-radius: 50%;
  height: ${({ size }) => `${size}px`};
  width: ${({ size }) => `${size}px`};
  font-weight: 500;
`;
