// @ts-nocheck TODO: type issues need to be fixed in this file
import { useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';

import { useSisenseAuthQuery } from '../../../../queries/Auth/Sisense';

import { SISENSE_CONFIG } from '../../../../config';
import useScript from '../../../../hooks/useScript';
import { BlockItemType, SisenseFilter } from './types';

import {
  applyFilter,
  applySisenseFilter,
  trendsWidgetConfig,
  dashboardWidgetConfig,
  removeChildrenByParentIds,
} from './utils';

import useGetSisenseFilter from './useGetSisenseFilter';
import { MENTION_REGEX_GLOBAL } from '../../../../Utils/text';
import { ANONYMOUS } from '../../../../languages/en/singleWords';
import { MemberFromAPI } from '../../../../interfaces/UserProfile';
import { makeAPICallWithDataReturn } from '../../../../queries/utils';
import { GET_MEMBERS_DETAILS } from '../../../../constants/endpoints';
import { useFetchFlowDetailsQuery } from '../../../../queries/Flows/Feed';
import { useGetOccurrencesForFlowIdQuery } from '../../../../queries/Flows/Insights';
import {
  trackFlowInsightsShowEvent,
  trackFlowInsightsActionEvent,
} from '../../../../Utils/analytics/flowInsights';

const renderGeneralSummaryStats = async (
  sisenseApp: any,
  flowId: string,
  sisenseFilter: SisenseFilter,
) => {
  const generalStatsDashboard = await sisenseApp.dashboards.load(
    SISENSE_CONFIG.DASHBOARD.GENERAL_SUMMARY_STATS,
  );

  applyFilter(generalStatsDashboard, '[responses.flowId]', flowId);
  applySisenseFilter(sisenseFilter, generalStatsDashboard);

  generalStatsDashboard.widgets.get(SISENSE_CONFIG.WIDGETS.STARTS).container =
    document.getElementById('starts');

  generalStatsDashboard.widgets.get(
    SISENSE_CONFIG.WIDGETS.TOTAL_RESPONSES,
  ).container = document.getElementById('total_responses');

  generalStatsDashboard.widgets.get(
    SISENSE_CONFIG.WIDGETS.COMPLETION_RATE,
  ).container = document.getElementById('completion_rate');

  generalStatsDashboard.widgets.get(
    SISENSE_CONFIG.WIDGETS.PERSON_RESPONSES_STAT,
  ).container = document.getElementById('participation_stats');

  generalStatsDashboard.refresh();
};

const renderTrendsStats = async (
  sisenseApp: any,
  flowId: string,
  sisenseFilter: SisenseFilter,
) => {
  const nodesToRemove = ['trends_stats'];

  nodesToRemove.forEach((node: string) => {
    const nodeToRemove = document.getElementById(node);
    if (nodeToRemove) {
      nodeToRemove.replaceChildren();
    }
  });

  // Render Stats Dashboard.
  const generalStatsDashboard = await sisenseApp.dashboards.load(
    SISENSE_CONFIG.DASHBOARD.STATS_OVERTIME_WITH_OCCURRENCES,
  );

  applyFilter(generalStatsDashboard, '[flows._id]', flowId);
  applySisenseFilter(sisenseFilter, generalStatsDashboard);

  generalStatsDashboard.widgets.get(
    SISENSE_CONFIG.WIDGETS.COMPLETION_RATE_BY_WEEK,
  ).container = document.getElementById('rate_over_time');

  generalStatsDashboard.widgets.get(
    SISENSE_CONFIG.WIDGETS.TOTAL_RESPONSES_BY_WEEK,
  ).container = document.getElementById('respondants_by_week');

  generalStatsDashboard.widgets.get(
    SISENSE_CONFIG.WIDGETS.PERCENTAGE_OF_BLOCKS_COMPLETED_OVERTIME,
  ).container = document.getElementById(
    'percentage_of_blocks_completed_overtime',
  );

  generalStatsDashboard.refresh();
};

const formatSummaryStats = () => {
  const query = `#participation_stats .dataTable td`;

  document.querySelectorAll(query).forEach((element) => {
    if (element.textContent) {
      element.textContent = element.textContent.replace(
        new RegExp(/N\\A/gm),
        ANONYMOUS,
      );
    }
  });
};

const formatPivotTableWithMemberDetails = async (blockId: string) => {
  let memberIds: string[] = [];
  const query = `#block${blockId} .pivot-container .table-grid__content__inner`;
  const otherBlocksQuery = `#block${blockId} .table-grid__tbody .table-grid__content__inner`;

  document.querySelectorAll(otherBlocksQuery).forEach((element) => {
    if (element.textContent) {
      element.textContent = element.textContent.replace(
        new RegExp(/N\\A/gm),
        ANONYMOUS,
      );
    }
  });

  document.querySelectorAll(query).forEach((element) => {
    if (element.textContent) {
      const matchedMemberIds = element.textContent.match(MENTION_REGEX_GLOBAL);

      if (matchedMemberIds && matchedMemberIds.length > 0) {
        memberIds = [...memberIds, ...matchedMemberIds];
      }
    }
  });

  if (memberIds.length > 0) {
    const response = await makeAPICallWithDataReturn(GET_MEMBERS_DETAILS, {
      memberIds: Array.from(
        new Set(memberIds.map((x) => x.replace('@__', '').replace('__@', ''))),
      ),
      filter: { allowDeactivateCheck: 'no' },
    });

    const mappedMembers: Record<string, string> = {};

    if (response && response.membersDetails && response.membersDetails.length) {
      response.membersDetails.map((x: MemberFromAPI) => {
        mappedMembers[
          `@__${x.memberId}__@`
        ] = `${x.profile.firstName} ${x.profile.lastName}`;
      });
    }

    document.querySelectorAll(query).forEach((element) => {
      if (element.textContent) {
        element.textContent = element.textContent.replace(
          new RegExp(/N\\A/gm),
          ANONYMOUS,
        );

        element.textContent = element.textContent.replace(
          '@__tag:everyone__@',
          'Everyone',
        );

        for (const key in mappedMembers) {
          element.textContent = element.textContent.replace(
            key,
            mappedMembers[key],
          );
        }
      }
    });
  }
};

const renderWidget = async (
  sisenseApp: any,
  blockId: string,
  flowId: string,
  dashboardId: string,
  widgetId: string,
  sisenseFilter: SisenseFilter,
  elementId: string,
  enableBlockFilter = true,
) => {
  const dashboard = await sisenseApp.dashboards.load(dashboardId);

  applyFilter(dashboard, '[flows_blocks._id]', flowId);
  applyFilter(dashboard, '[responses.flowId]', flowId);
  applyFilter(dashboard, '[flows._id]', flowId);

  if (enableBlockFilter) {
    applyFilter(dashboard, '[responses_responses.blockId]', blockId);
    applyFilter(dashboard, '[dim_responses_responses_values.blockId]', blockId);
    applyFilter(
      dashboard,
      '[dim_responses_responses_persons.blockId]',
      blockId,
    );
  }

  applySisenseFilter(sisenseFilter, dashboard);

  dashboard.widgets.get(widgetId).container =
    document.getElementById(elementId);

  dashboard.widgets.get(widgetId).on('ready', async () => {
    if (dashboard.widgets.get(widgetId).type !== 'pivot2') {
      return;
    }

    await formatPivotTableWithMemberDetails(blockId);
  });

  dashboard.refresh();
};

const useFlowsInsightsController = () => {
  // Sisense App State
  const SISENSE = `${SISENSE_CONFIG.SERVER}/js/sisense.v1.js`;

  const [sisenseSrc, setSisenseSrc] = useState(SISENSE);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const isSisensePreloaded = window.Sisense !== undefined;
  const { loading: isScriptLoading, error: isScriptLoadError } = useScript({
    src: sisenseSrc,
    enabled: !isSisensePreloaded,
  });

  // Sisense Connection State
  const [isSisenseConnected, setIsSisenseConnected] = useState(false);

  // Sisense Auth State
  const { data, isFetching, error } = useSisenseAuthQuery('app/main', true);

  // Filter Params
  const sisenseFilter = useGetSisenseFilter();

  // Flows State
  const { flowId } = useParams<{ flowId: string }>();
  const { data: flowDetails, isLoading: isFlowDetailsLoading } =
    useFetchFlowDetailsQuery(flowId, 'builder');

  const { data: occurrencesData, isFetching: isFetchingOccurrenceData } =
    useGetOccurrencesForFlowIdQuery(flowId);

  const [sisenseApp, setSisenseApp] = useState<any>(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [blockWidgets, setBlockWidgets] = useState<BlockItemType[]>([]);
  const [trendsWidgets, setTrendsWidgets] = useState<BlockItemType[]>([]);

  const onLoadSisenseSsoLoaded = useCallback(() => {
    const ssoFrame = document.getElementById(
      'SisenseFrame',
    ) as HTMLIFrameElement;

    if (ssoFrame && ssoFrame.src) {
      setSisenseSrc(`${SISENSE}?retry=1`);
    }
  }, []);

  const handleOnTabChanged = useCallback(
    (index: number) => {
      setSelectedIndex(index);
      trackFlowInsightsShowEvent({ tab: index === 0 ? 'summary' : 'trends' });
    },
    [setSelectedIndex],
  );

  useEffect(() => {
    const ssoFrame = document.getElementById(
      'SisenseFrame',
    ) as HTMLIFrameElement;

    if (data && ssoFrame) {
      ssoFrame.src = data.redirectUrl;
    }
  }, [data]);

  useEffect(() => {
    trackFlowInsightsShowEvent({ tab: 'summary' });
  }, []);

  useEffect(() => {
    if (sisenseFilter) {
      trackFlowInsightsActionEvent({
        action: 'filterApplied',
        filter: sisenseFilter,
        tab: selectedIndex === 0 ? 'summary' : 'trends',
      });
    }
  }, [sisenseFilter, selectedIndex]);

  useEffect(() => {
    if (flowDetails && flowDetails.data.action) {
      setBlockWidgets(
        flowDetails.data.action.blocks.map((block) => ({
          title: block.title,
          id: String('block' + block.blockId),
          description: block.description?.text,
          isRequired: Boolean(block.rules?.required),
        })),
      );

      setTrendsWidgets(
        flowDetails.data.action.blocks.map((block) => ({
          title: block.title,
          id: String('trends' + block.blockId),
          description: block.description?.text,
          isRequired: Boolean(block.rules?.required),
        })),
      );
    }
  }, [flowDetails]);

  useEffect(() => {
    setIsSisenseConnected(false);
  }, [selectedIndex]);

  useEffect(async () => {
    if (window.Sisense && !sisenseApp) {
      setSisenseApp(await Sisense.connect(SISENSE_CONFIG.SERVER, false));
    }
  }, [window.Sisense, sisenseApp]);

  useEffect(() => {
    setIsSisenseConnected(false);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const Sisense = window.Sisense;
    if (
      flowId &&
      Sisense &&
      flowDetails &&
      !isScriptLoading &&
      !isScriptLoadError &&
      !isFlowDetailsLoading &&
      !isFetchingOccurrenceData
    ) {
      const nodesToRemove = [
        'stats',
        'starts',
        'total_responses',
        'completion_rate',
        'blocks_completed',
        'total_participation',
        'number_of_occurances',
        'current_participants',
        'occurance_participantion_rate',
        'answer_any_time_participation_rate',
        'answer_any_time_response_per_person',
        'unique_respondants',
        'participation_rate',
        'participation_stats',
        'unique_flow_participants',
        'rate_over_time',
        'respondants_by_week',
        'percentage_of_blocks_completed_overtime',
        ...blockWidgets.map((x) => x.id),
        ...trendsWidgets.map((x) => x.id),
      ];
      removeChildrenByParentIds(nodesToRemove);

      if (selectedIndex === 1) {
        (async () => {
          try {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            /* @ts-ignore */
            await renderTrendsStats(sisenseApp, flowId, sisenseFilter);
            setIsSisenseConnected(true);

            const blocks = flowDetails.data.action?.blocks;
            if (!blocks || !blocks.length) {
              return;
            }

            for (const block of blocks) {
              const blockId = String(block.blockId);
              const elementId = 'trends' + blockId;
              switch (block.type) {
                case 'OPEN_ENDED':
                case 'GIF':
                case 'FILE_UPLOAD':
                case 'SCALE':
                case 'DROPDOWN':
                case 'GIVE_POINTS_STACK':
                case 'MULTI_CHOICE':
                  await renderWidget(
                    sisenseApp,
                    blockId,
                    flowId,
                    trendsWidgetConfig[block.type].dashboard,
                    trendsWidgetConfig[block.type].widget,
                    sisenseFilter,
                    elementId,
                  );
                  break;
                case 'PERSON_SELECTOR':
                  if (block.select_type === 'MULTI_PERSON') {
                    await renderWidget(
                      sisenseApp,
                      blockId,
                      flowId,
                      SISENSE_CONFIG.DASHBOARD.PERSON_SELECTOR_MULTIPLE_TRENDS,
                      SISENSE_CONFIG.WIDGETS.PERSON_SELECTOR_MULTIPLE_TRENDS,
                      sisenseFilter,
                      elementId,
                    );
                  } else {
                    await renderWidget(
                      sisenseApp,
                      blockId,
                      flowId,
                      SISENSE_CONFIG.DASHBOARD.PERSON_SELECTOR_SINGLE_TRENDS,
                      SISENSE_CONFIG.WIDGETS.PERSON_SELECTOR_SINGLE_TRENDS,
                      sisenseFilter,
                      elementId,
                    );
                  }
                  break;
                default:
                  break;
              }

              const element = document.getElementById(elementId);
              if (element) {
                element.style.height = '422px';
              }
            }
            // eslint-disable-next-line no-empty
          } catch (e: unknown) {}
        })();
      } else {
        (async () => {
          try {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            /* @ts-ignore */
            await renderGeneralSummaryStats(sisenseApp, flowId, sisenseFilter);
            setIsSisenseConnected(true);

            const blocks = flowDetails.data.action?.blocks;
            if (!blocks || !blocks.length) {
              return;
            }

            for (const block of blocks) {
              formatSummaryStats();
              const blockId = String(block.blockId);
              const elementId = 'block' + blockId;

              switch (block.type) {
                case 'OPEN_ENDED':
                case 'GIF':
                case 'FILE_UPLOAD':
                case 'SCALE':
                case 'GIVE_POINTS_STACK':
                case 'DROPDOWN':
                  await renderWidget(
                    sisenseApp,
                    blockId,
                    flowId,
                    dashboardWidgetConfig[block.type].dashboard,
                    dashboardWidgetConfig[block.type].widget,
                    sisenseFilter,
                    elementId,
                    block.type !== 'GIVE_POINTS_STACK',
                  );
                  break;
                case 'MULTI_CHOICE':
                  if (block.rules?.allowOther) {
                    await renderWidget(
                      sisenseApp,
                      blockId,
                      flowId,
                      SISENSE_CONFIG.DASHBOARD.MULTI_SELECT_DASHBOARD_OTHERS,
                      SISENSE_CONFIG.WIDGETS.MULTI_SELECT_OTHERS,
                      sisenseFilter,
                      elementId,
                    );
                    await renderWidget(
                      sisenseApp,
                      blockId,
                      flowId,
                      SISENSE_CONFIG.DASHBOARD.MULTI_SELECT_DASHBOARD_OTHERS,
                      SISENSE_CONFIG.WIDGETS.MULTI_SELECT_OTHERS_VALUE,
                      sisenseFilter,
                      elementId + 'others',
                    );
                    const othersElement = document.getElementById(
                      elementId + 'others',
                    );
                    if (othersElement) {
                      othersElement.style.height = '422px';
                    }
                  } else {
                    await renderWidget(
                      sisenseApp,
                      blockId,
                      flowId,
                      SISENSE_CONFIG.DASHBOARD.MULTI_SELECT_DASHBOARD,
                      SISENSE_CONFIG.WIDGETS.MULTI_SELECT,
                      sisenseFilter,
                      elementId,
                    );
                  }
                  break;
                case 'PERSON_SELECTOR':
                  if (block.select_type === 'MULTI_PERSON') {
                    await renderWidget(
                      sisenseApp,
                      blockId,
                      flowId,
                      SISENSE_CONFIG.DASHBOARD.PERSON_SELECTOR_MULTI_DASHBOARD,
                      SISENSE_CONFIG.WIDGETS.PERSON_SELECTOR_MULTI,
                      sisenseFilter,
                      elementId,
                    );
                  } else {
                    await renderWidget(
                      sisenseApp,
                      blockId,
                      flowId,
                      SISENSE_CONFIG.DASHBOARD.PERSON_SELECTOR_SINGLE_DASHBOARD,
                      SISENSE_CONFIG.WIDGETS.PERSON_SELECTOR_SINGLE,
                      sisenseFilter,
                      elementId,
                    );
                  }
                  break;
                default:
                  break;
              }

              const autoSizeCharts = [
                'GIF',
                'OPEN_ENDED',
                'FILE_UPLOAD',
                'GIVE_POINTS_STACK',
              ];
              const element = document.getElementById(elementId);
              if (element && !autoSizeCharts.includes(block.type)) {
                element.style.height = '422px';
                element.style.pointerEvents = 'none';
              }
            }
            // eslint-disable-next-line no-empty
          } catch (e: unknown) {}
        })();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    flowId,
    sisenseApp,
    selectedIndex,
    isScriptLoading,
    occurrencesData,
    isScriptLoadError,
    isFlowDetailsLoading,
    sisenseFilter.endDate,
    sisenseFilter.startDate,
    isFetchingOccurrenceData,
    sisenseFilter.selectedResponses,
    sisenseFilter.selectedDepartment,
    sisenseFilter.occurenceStartDate,
    sisenseFilter.selectedParticipant,
    sisenseFilter.selectedManagerStatus,
  ]);

  return {
    models: {
      blockWidgets,
      trendsWidgets,
      selectedIndex,
      occurrencesData,
      hasError: Boolean(error),
      isLoading: isFetching || isScriptLoading || !isSisenseConnected,
    },
    operations: {
      handleOnTabChanged,
      onLoadSisenseSsoLoaded,
    },
  };
};

export default useFlowsInsightsController;
