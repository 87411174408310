import React from 'react';

import Body from '../../../atoms/Body';
import Divider from '../../../atoms/Divider';
import Heading from '../../../atoms/Heading';

import { SummaryTabPanelProps } from './type';
import ThemeV2 from '../../../../componentsV2/theme';

import { StatsLoader, WidgetLoader } from '../Loader';
import { Flex } from '../../../../Utils/styles/display';

import {
  Starts,
  StyledBody,
  TabChildren,
  StyledHeading,
  LoaderWrapper,
  ContentWrapper,
  TotalResponses,
  CompletionRate,
  WidgetContainer,
  ParticipationStats,
  GeneralSummaryStats,
} from './styles';

import {
  OVERVIEW_LABEL,
  BLOCK_BY_BLOCK_LABEL,
  OVERVIEW_DESCRIPTION,
  BLOCK_BY_BLOCK_DESCRIPTION,
} from '../../../../languages/en/flows/insights';

const SummaryTabPanel = (props: SummaryTabPanelProps) => {
  const { isLoading, widgets } = props;

  return (
    <TabChildren>
      <Heading variant="h5">{OVERVIEW_LABEL}</Heading>
      <Body variant="body1" color="gray8">
        {OVERVIEW_DESCRIPTION}
      </Body>

      {isLoading && (
        <LoaderWrapper>
          <StatsLoader />
        </LoaderWrapper>
      )}
      <ContentWrapper isLoading={isLoading}>
        <GeneralSummaryStats>
          <Starts id="starts" />
          <TotalResponses id="total_responses" />
          <CompletionRate id="completion_rate" />
          <ParticipationStats id="participation_stats" />
        </GeneralSummaryStats>
      </ContentWrapper>
      <Divider
        isFullWidth
        marginTop="24px"
        marginBottom="24px"
        color={ThemeV2.palette.gray4}
      />
      <Heading variant="h5">{BLOCK_BY_BLOCK_LABEL}</Heading>
      <Body variant="body1" color="gray8">
        {BLOCK_BY_BLOCK_DESCRIPTION}
      </Body>

      {isLoading && (
        <LoaderWrapper>
          <WidgetLoader />
        </LoaderWrapper>
      )}

      <section id="blocks-container">
        {!isLoading &&
          widgets.map((widget) => (
            <WidgetContainer key={widget.id}>
              <Flex>
                <StyledHeading variant="h6">{widget.title}</StyledHeading>
                {widget.isRequired && (
                  <StyledHeading color="red" variant="h6">
                    *
                  </StyledHeading>
                )}
              </Flex>

              {widget.description && (
                <StyledBody variant="body1" color="gray8">
                  {widget.description}
                </StyledBody>
              )}
              <div id={widget.id} />
              <div id={widget.id + 'others'} />
            </WidgetContainer>
          ))}
      </section>
    </TabChildren>
  );
};

export default SummaryTabPanel;
