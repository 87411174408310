import { parse } from 'qs';
import { useHistory, useLocation } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useState } from 'react';

import useToggle from '../../../../hooks/useToggle';
import {
  FeedProps,
  MemberInteractionType,
  PostSortOptions,
  PostSortValues,
} from '../../../../interfaces/Feed';
import useSearchOrGetFeedData from '../useSearchOrGetFeedData';
import { useProfileInfoFetchQuery } from '../../../../queries/Profile';

import { getCanCurrentUserGiveRecognition } from '../../../../queries/Profile/utils';

import {
  getSearchPayload,
  isFeedSearchEmpty,
} from '../../../../queries/Feed/utils';

import {
  defaultValues,
  UseSearchDropDownValues,
} from '../../../../atomic/organism/SearchDropdown';
import useLayoutStore from '../../../../stores/layoutStore';
import { FEED_ANALYTICS_EVENTS } from '../../../../Utils/analytics/constants';
import useCommonFeedSocket from '../../../../hooks/useCommonFeedSocket';
import { WebSocketFeedTypes } from '../../../../hooks/useCommonFeedSocket/types';
import { LayoutStoreState } from '../../../../interfaces/Layout';
import { trackFeedEvent as trackFeedEventMetric } from '../../../../Utils/analytics';
import { FeedEventNameType } from '../../../../Utils/analytics/interfaces';
import { useFeatureSplit } from '../../../../hooks/useFeatureSplit';
import {
  SplitNames,
  TreatmentTypes,
} from '../../../../hooks/useSplitSdkConfig/constants';
import { RECOGNITION_PARTICIPATE } from '../../../../constants/routes';

const layoutSelector = (state: LayoutStoreState) =>
  state.setMemberAndToggleRightAsideOpen;

const useRecognitionController = () => {
  const history = useHistory();
  const setMemberAndToggleRightAsideOpen = useLayoutStore(layoutSelector);

  const [feedsSortValue, setFeedsSortValue] = useState<PostSortOptions>(
    PostSortOptions.DESC,
  );

  const trackFeedEvent = useCallback(
    (action: FeedEventNameType) => {
      trackFeedEventMetric(action, {
        feedSort: PostSortValues[feedsSortValue],
      });
    },
    [feedsSortValue],
  );

  const [searchFeedValue, setSearchFeedValue] =
    useState<UseSearchDropDownValues>({ ...defaultValues });

  const { data: profileData } = useProfileInfoFetchQuery();

  const [isFilterApplied, setIsFilterApplied] = useState(false);

  // Search
  const [searchPayload, showSearchResults] = useMemo(() => {
    if (isFeedSearchEmpty(searchFeedValue)) {
      return [{}, false];
    }
    return [getSearchPayload(searchFeedValue), true];
  }, [searchFeedValue]);

  const getIsFilterApplied = (obj: UseSearchDropDownValues) => {
    const { from, to, keywords, startDate, endDate, currencyMin, currencyMax } =
      obj;

    if (
      from.length !== 0 ||
      to.length !== 0 ||
      keywords.length !== 0 ||
      startDate ||
      endDate ||
      currencyMin !== 0 ||
      currencyMax !== 0
    ) {
      return true;
    }

    return false;
  };

  const onSearchChange = useCallback(
    (values: UseSearchDropDownValues) => {
      setIsFilterApplied(getIsFilterApplied(values));
      setSearchFeedValue(values);
    },
    [setSearchFeedValue],
  );

  // Celebrate Teammate
  const { search } = useLocation();
  const parsedParams = parse(search, {
    ignoreQueryPrefix: true,
  });

  const openRecognitionFlowModalOnLoad =
    parsedParams && parsedParams?.openRecognitionFlowModalOnLoad === 'true';

  const {
    models: { toggleValue: isRecognitionFlowModalOpen },
    operations: { setToggleValue: toggleIsRecognitionFlowModalOpen },
  } = useToggle();

  const {
    models: { toggleValue: showLoadMoreButton },
    operations: {
      setToggleToTrue: setShowLoadMoreButtonToTrue,
      setToggleToFalse: setShowLoadMoreButtonToFalse,
    },
  } = useToggle();

  const { treatment: pageParticipationTreatment } = useFeatureSplit(
    SplitNames.NEW_PAGE_PARTICIPATION_VIEW,
  );

  const handleCelebrateTeammateClick = useCallback(() => {
    if (pageParticipationTreatment === TreatmentTypes.ON) {
      history.push(
        `${RECOGNITION_PARTICIPATE}?redirectUrl=${location.pathname}`,
      );
    } else {
      toggleIsRecognitionFlowModalOpen();
    }
  }, [history, pageParticipationTreatment, toggleIsRecognitionFlowModalOpen]);

  useEffect(() => {
    if (openRecognitionFlowModalOnLoad) {
      toggleIsRecognitionFlowModalOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Current User Permissions
  const canCurrentUserGiveRecognition = useMemo(
    () => getCanCurrentUserGiveRecognition(profileData),
    [profileData],
  );

  const onMemberClick = useCallback(
    (person: MemberInteractionType) => {
      setMemberAndToggleRightAsideOpen({
        memberId: person.memberID,
        firstName: person.firstName,
      });
    },
    [setMemberAndToggleRightAsideOpen],
  );

  // Feed Data
  const {
    isLoading,
    fetchNextPost,
    feedProps,
    hasMorePosts,
    isFetchingMoreFeeds,
    onReactionSet,
    onReactionUnSet,
    refetchFeedQuery,
  } = useSearchOrGetFeedData(searchPayload, feedsSortValue, showSearchResults);

  useCommonFeedSocket({
    type: WebSocketFeedTypes.CELEBRATE_TEAMMATE,
    setCelebrateTeammateFeedShowLoadMoreButtonToTrue:
      setShowLoadMoreButtonToTrue,
    onCelebrateATeammatePostDeleted: refetchFeedQuery,
    feedProps,
  });

  const handleFeedSortClick = useCallback(
    (val: string | number) => {
      setFeedsSortValue(val as PostSortOptions);
      trackFeedEvent(FEED_ANALYTICS_EVENTS.FEED_SORTED);
    },
    [trackFeedEvent],
  );

  const handleFetchNextPage = useCallback(() => {
    fetchNextPost();
    trackFeedEvent(FEED_ANALYTICS_EVENTS.FEED_SCROLLED);
  }, [fetchNextPost, trackFeedEvent]);

  return {
    models: {
      isLoading,
      profileData,
      feedsSortValue,
      isFilterApplied,
      searchFeedValue,
      showSearchResults,
      showLoadMoreButton,
      hasNextPage: hasMorePosts,
      isRecognitionFlowModalOpen,
      canCurrentUserGiveRecognition,
      data: feedProps as FeedProps[],
      isFetchingNextPage: isFetchingMoreFeeds,
    },
    operations: {
      onMemberClick,
      onSearchChange,
      handleFeedSortClick,
      onReactionSet,
      refetchFeedQuery,
      onReactionUnSet,
      handleCelebrateTeammateClick,
      setShowLoadMoreButtonToFalse,
      toggleIsRecognitionFlowModalOpen,
      fetchNextPage: handleFetchNextPage,
    },
  };
};

export default useRecognitionController;
