import styled from 'styled-components';
import InfoAlert from '../../../../atomic/molecules/InfoAlert';

import ThemeV2 from '../../../../componentsV2/theme';
import HeaderBackground from '../../../../img/admin-axomo-header.jpg';
import { Flex } from '../../../../Utils/styles/display';

export const HeaderCardWrapper = styled.div`
  margin-top: 15px;
  padding: 25px;
  width: 100%;
  background: url(${HeaderBackground});
  background-position: center top;
  background-size: 100% auto;
  background-repeat: no-repeat;
`;
export const HeaderCard = styled(Flex)`
  flex-direction: column;
  align-items: center;
  gap: 6px;
  justify-content: center;
  background-color: ${ThemeV2.palette.white};
  border: 1px solid ${ThemeV2.palette.white};
  color: ${ThemeV2.palette.gray8};
  border-width: 1px;
  border-style: solid;
  padding: 15px 16px;
  max-width: 350px;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.3s ease-in;
  box-sizing: border-box;
`;

export const StyledMainContainer = styled.div`
  flex-direction: column;
  justify-content: flex-start;
`;

export const StyledManageInventory = styled.div`
  margin-top: 15px;
  padding-bottom: 25px;
  min-width: 100%;
`;

export const StyledInfoAlert = styled(InfoAlert)`
  margin: 24px 0 20px 0;
`;

export const StyledFlexInventoryHeader = styled(Flex)`
  min-width: 100%;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 10px;

  &:div {
    display: flex;
    flex-direction: row;
  }
`;

export const StyledTurnItemsSubheader = styled(Flex)`
  flex-direction: row;
  gap: 12px;
  padding-right: 5px;
  margin-bottom: 3px;
`;
export const StyledManageInventoryBody = styled(Flex)`
  flex-direction: column;
  gap: 20px;
`;
