import React from 'react';
import { useSelector } from 'react-redux';
import {
  Route,
  useHistory,
  useLocation,
  RouteComponentProps,
} from 'react-router-dom';
import _ from 'lodash';

import { checkIfAdmin } from '../../containers/Roles';
import LoadingPulse from '../../atomic/atoms/LoadingPulse';

interface ProtectedRouteProps {
  adminCheck: boolean;
}

const ProtectedRoute = ({
  adminCheck,
  ...props
}: ProtectedRouteProps & RouteComponentProps) => {
  const history = useHistory();
  const location = useLocation();
  const { user, authenticated, employer } = useSelector((state) => ({
    user: _.get(state, ['me', 'me'], null),
    authenticated: _.get(state, ['auth', 'authenticated'], false),
    employer: _.get(state, ['admin', 'employer'], null),
  }));
  if (!authenticated) {
    history.push({
      pathname: '/',
      state: { origin: location.pathname },
      search: location.search,
    });
  }
  if (adminCheck && user) {
    if (!checkIfAdmin(user)) {
      history.push('/404');
    }
  }
  if (user && employer) {
    return <Route {...props} />;
  }
  return <LoadingPulse hasPadding isLoading />;
};

export default ProtectedRoute;
