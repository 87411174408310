import React from 'react';
import styled from 'styled-components';
import FeedPostLoader from '../../atomic/organism/FeedPost/Loader';
import { Flex } from '../../Utils/styles/display';
import Image from '../../atomic/atoms/Image';
import PostController from '../home/PostController';
import useSinglePostController from './useSinglePostController';
import PostNotFoundImage from '../../img/searching.svg';
import {
  COULDNT_FIND_POST,
  COULDNT_FIND_POST_COMMENT,
} from '../../languages/en/header';
import Body from '../../atomic/atoms/Body';

export const StyledImage = styled(Image)`
  margin: 48px 0;
`;

const SinglePostController = () => {
  const {
    actions: { onMemberClick, updateReaction, onPostDelete },
    data: { isError, currentUserAvatar, postData, profileData },
    metadata: { isLoading, isProfileInfoLoading },
  } = useSinglePostController();
  if (isLoading || isProfileInfoLoading) {
    return <FeedPostLoader />;
  }
  if (isError) {
    return (
      <Flex flexDirection="column" alignItems="center" margin="32px 0 0">
        <StyledImage alt={COULDNT_FIND_POST} src={PostNotFoundImage} />
        <Body variant="subHead2Bold">{COULDNT_FIND_POST}</Body>
        <Body variant="body1">{COULDNT_FIND_POST_COMMENT}</Body>
      </Flex>
    );
  }
  if (postData && profileData) {
    return (
      <PostController
        post={postData}
        onMemberClick={onMemberClick}
        currentUser={profileData.member}
        assemblyCurrency={profileData.assembly.currency}
        currentUserAvatar={currentUserAvatar}
        profileData={profileData}
        updateReaction={updateReaction}
        onPostDelete={onPostDelete}
      />
    );
  }
  return null;
};

export default SinglePostController;
