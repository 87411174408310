import { getProfileFullName, getTaggedUsersFromMessage } from '../text';
import { getAgoTime } from '../time';
import {
  Notification,
  NotificationTypes,
  FormattedNotifications,
  FromUserStates,
} from '../../interfaces/Notifications';
import { PostTypes } from '../../interfaces/Home';
import { AvatarProps } from '../../atomic/atoms/Avatar/interfaces';
import {
  getAnniversaryMessage,
  getBirthdayMessage,
  getDeletedUserAddedAndCommented,
  getDeletedUserGave,
  getUserAddedAndCommented,
  getUserCommented,
  getUserGave,
  getUserMentionInComment,
  getUserRecognizedInPost,
} from '../languages/notifications';
import {
  DEACTIVATED_USER_COMMENTED,
  DEACTIVATED_USER_MENTIONED_IN_COMMENT,
  DEACTIVATED_USER_MENTIONED_IN_POST,
  getBodyTextForDeactivatedOrAnonymousMentions,
} from '../../languages/en/notifications';
import { AssemblyCurrency } from '../../interfaces/assembly';
import { mapHexCodeToEmoticon } from '../mappers';
import { CELEBRATE_TEAM_MEMBER } from '../../languages/en/feed';
import { FeedItemKind } from '../../interfaces/Feed';

const getHowManyCarrotsString = (
  carrots: number,
  name: string,
  pluralName: string,
) => `${carrots} ${carrots === 1 ? name : pluralName}`;

const getNotificationTexts = (
  n: Notification,
  { name, pluralName }: AssemblyCurrency,
) => {
  let headerText = '';
  let bodyText = '';

  const { post, type, carrots, from, comment, flowDetails, responseId } = n;
  const isAnonymous =
    typeof responseId !== 'string' &&
    (responseId?.kind === FeedItemKind.EXTERNAL_ANONYMOUS ||
      responseId?.kind === FeedItemKind.INTERNAL_ANONYMOUS);
  const shouldShowDeactivatedFromUserTag =
    from?.state !== FromUserStates.ACTIVE || isAnonymous;

  if (type === NotificationTypes.POST) {
    if (post?.type === PostTypes.BIRTHDAY) {
      bodyText = getBirthdayMessage(getProfileFullName(n.user));
    } else if (post?.type === PostTypes.ANNIVERSARY) {
      bodyText = getAnniversaryMessage(getProfileFullName(n.user));
    } else if (n.carrots === 0) {
      bodyText = shouldShowDeactivatedFromUserTag
        ? DEACTIVATED_USER_MENTIONED_IN_POST
        : getUserRecognizedInPost(getProfileFullName(from));
    } else {
      const howMuch = getHowManyCarrotsString(carrots, name, pluralName);
      bodyText = shouldShowDeactivatedFromUserTag
        ? getDeletedUserGave(howMuch, isAnonymous)
        : getUserGave(getProfileFullName(from), howMuch);
    }
    if (post) {
      const body = getTaggedUsersFromMessage(post.message, post.taggedUsers);
      headerText = body.length > 60 ? `${body.substring(0, 60 - 3)}...` : body;
    }
  }
  if (
    (type === NotificationTypes.COMMENT ||
      type === NotificationTypes.TAGGED_IN_COMMENT) &&
    comment
  ) {
    const commentHasCarrots = comment.carrots > 0;
    if (type === NotificationTypes.TAGGED_IN_COMMENT) {
      bodyText = shouldShowDeactivatedFromUserTag
        ? DEACTIVATED_USER_MENTIONED_IN_COMMENT
        : getUserMentionInComment(getProfileFullName(from));
    } else if (commentHasCarrots) {
      const howMuch = getHowManyCarrotsString(
        comment.carrots,
        name,
        pluralName,
      );
      bodyText = shouldShowDeactivatedFromUserTag
        ? getDeletedUserAddedAndCommented(howMuch)
        : getUserAddedAndCommented(getProfileFullName(from), howMuch);
    } else if (flowDetails) {
      bodyText = shouldShowDeactivatedFromUserTag
        ? DEACTIVATED_USER_COMMENTED
        : getProfileFullName(from);
    } else {
      bodyText = shouldShowDeactivatedFromUserTag
        ? DEACTIVATED_USER_COMMENTED
        : getUserCommented(getProfileFullName(from));
    }
    const textLength = commentHasCarrots ? 40 : 60;
    const body = getTaggedUsersFromMessage(
      comment.message,
      comment.taggedUsers,
      true,
    );

    if (flowDetails) {
      const { flow } = flowDetails;
      if (flow) {
        if (flow.icon) {
          const emoticon = mapHexCodeToEmoticon(flow.icon.value);
          headerText = `${emoticon} ${flow.name}`;
        } else {
          headerText = `${flow.name}`;
        }
      }
    } else {
      headerText =
        body.length > textLength
          ? `${body.substring(0, textLength - 3)}...`
          : body;
    }
  }

  if (
    type === NotificationTypes.MENTIONS ||
    type === NotificationTypes.TAGGED_IN_POST
  ) {
    bodyText = shouldShowDeactivatedFromUserTag
      ? getBodyTextForDeactivatedOrAnonymousMentions(isAnonymous)
      : getProfileFullName(from);
  }

  if (
    type === NotificationTypes.FLOW_TRIGGERED ||
    type === NotificationTypes.FLOW_REMINDER ||
    type === NotificationTypes.MENTIONS
  ) {
    if (flowDetails) {
      const { flow } = flowDetails;
      if (flow) {
        if (flow.icon) {
          const emoticon = mapHexCodeToEmoticon(flow.icon.value);
          headerText = `${emoticon} ${flow.name}`;
        } else {
          headerText = `${flow.name}`;
        }
      }
    }

    if (post && comment) {
      headerText = `🎉 ${CELEBRATE_TEAM_MEMBER}`;
    }

    if (post && !comment) {
      if (n.carrots === 0) {
        bodyText = shouldShowDeactivatedFromUserTag
          ? DEACTIVATED_USER_MENTIONED_IN_POST
          : getUserRecognizedInPost(getProfileFullName(from));
      } else {
        const howMuch = getHowManyCarrotsString(carrots, name, pluralName);
        bodyText = shouldShowDeactivatedFromUserTag
          ? getDeletedUserGave(howMuch, isAnonymous)
          : getUserGave(getProfileFullName(from), howMuch);
      }

      const body = getTaggedUsersFromMessage(post.message, post.taggedUsers);
      headerText = body.length > 60 ? `${body.substring(0, 60 - 3)}...` : body;
    }
  }

  if (type === NotificationTypes.TAGGED_IN_POST) {
    headerText = `🎉 ${CELEBRATE_TEAM_MEMBER}`;
  }

  return [headerText, bodyText];
};

type SerializeNotificationsType = (
  notifications: Notification[],
  currency: AssemblyCurrency,
) => FormattedNotifications[];

export const serializeNotifications: SerializeNotificationsType = (
  notifications,
  currency,
) =>
  notifications.map((notification) => {
    const [bodyText, headerText] = getNotificationTexts(notification, currency);
    let icon;
    const {
      _id: id,
      from,
      createdAt,
      isRead,
      post,
      flowDetails,
      type,
      responseId,
      comment,
    } = notification;
    const postType = post?.type;
    const postID = post?._id;
    let emoticon = '';
    let isAnonymous = false;

    if (flowDetails) {
      const { flow } = flowDetails;
      if (flow && flow.icon) {
        emoticon = mapHexCodeToEmoticon(flow.icon.value);
      }
    }

    if (postType === PostTypes.ANNIVERSARY) {
      icon = 'anniversary';
    } else if (postType === PostTypes.BIRTHDAY) {
      icon = 'birthday';
    }

    if (
      (type === NotificationTypes.COMMENT ||
        type === NotificationTypes.MENTIONS) &&
      typeof responseId !== 'string'
    ) {
      isAnonymous =
        responseId?.kind === FeedItemKind.INTERNAL_ANONYMOUS ||
        responseId?.kind === FeedItemKind.EXTERNAL_ANONYMOUS;
    }

    const avatarImg =
      from?.profile?.image?.resized?.relativeUrl ||
      from?.profile?.image?.original?.relativeUrl ||
      '';

    return {
      read: isRead,
      timeAgo: getAgoTime(createdAt),
      headerText,
      bodyText,
      id,
      avatar: {
        img: avatarImg,
        isDeleted: from?.state !== FromUserStates.ACTIVE && !isAnonymous,
        name: getProfileFullName(from),
        userId: from?._id || '',
        ...(isAnonymous && {
          iconSize: '40px',
        }),
        isAnonymous,
      } as AvatarProps,
      icon,
      postID: postID || '',
      flowId: flowDetails?.flow._id,
      occurrenceId: flowDetails?.occurrence,
      emoticon,
      type,
      responseId,
      commentId: comment?._id || '',
      isAnonymous,
    };
  });
