import React, { memo } from 'react';
import Modal from '../../atoms/Modal';
import SVGIcon from '../../atoms/SVGIcon';
import {
  ModalBackground,
  ColumnLeft,
  ColumnRight,
  ModalNavigation,
  ModalNavigationButton,
  ModalCloseButton,
  ColumnRightContent,
} from './styles';
import { TwoColumnModalProps } from './interface';
import ThemeV2 from '../../../componentsV2/theme';

const TwoColumnModal = (props: TwoColumnModalProps) => {
  const {
    isOpen,
    onClose,
    onPrevButtonClick,
    onNextButtonClick,
    contentLeft,
    contentRight,
    label,
    height = '444px',
    width = '750px',
    contentRightHeight,
  } = props;
  return (
    <Modal aria-labelledby={label} isOpen={isOpen} handleClose={onClose}>
      <ModalBackground height={height} width={width}>
        <ColumnLeft>{contentLeft}</ColumnLeft>
        <ColumnRight>
          <ModalNavigation>
            {onPrevButtonClick && onPrevButtonClick.enabled && (
              <ModalNavigationButton
                onClick={onPrevButtonClick.onClick}
                rounded
                disabled={onPrevButtonClick.disabled}
                dataTestId="modal-nav-prev-button"
              >
                <SVGIcon icon="back-arrow" color={ThemeV2.palette.gray6} />
              </ModalNavigationButton>
            )}
            {onNextButtonClick && onNextButtonClick.enabled && (
              <ModalNavigationButton
                onClick={onNextButtonClick.onClick}
                rounded
                disabled={onNextButtonClick.disabled}
                dataTestId="modal-nav-next-button"
              >
                <SVGIcon
                  icon="back-arrow"
                  rotate={180}
                  color={ThemeV2.palette.gray6}
                />
              </ModalNavigationButton>
            )}
            <ModalCloseButton
              onClick={onClose}
              rounded
              dataTestId="modal-close-button"
            >
              <SVGIcon
                icon="modal-close"
                size="32px"
                color={ThemeV2.palette.gray6}
              />
            </ModalCloseButton>
          </ModalNavigation>
          <ColumnRightContent height={contentRightHeight}>
            {contentRight}
          </ColumnRightContent>
        </ColumnRight>
      </ModalBackground>
    </Modal>
  );
};
const MemoizedTwoColumnModal = memo(TwoColumnModal);
MemoizedTwoColumnModal.displayName = 'TwoColumnModal';
export default MemoizedTwoColumnModal;
