// @ts-nocheck TODO: type issues need to be fixed in this file
import React, { useMemo } from 'react';
import {
  string,
  object,
  arrayOf,
  shape,
  func,
  bool,
  oneOfType,
  node,
} from 'prop-types';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import Typography from '../../../componentsV2/core/Typography';
import Button from '../../atoms/Button';
import ThemeV2 from '../../../componentsV2/theme';
import { Flex } from '../../../Utils/styles/display';
import LinkWithIcon from '../../molecules/LinkWithIcon';
import Body from '../../atoms/Body';
import { HELPER_LINK_TEXT } from '../../../languages/en/settings';

const useStyles = makeStyles({
  StepperRoot: {
    border: `1px solid ${ThemeV2.palette.lightGray4}`,
    borderRadius: 2,
    background: ThemeV2.palette.gray3,
  },
  wizardInnerContent: {
    position: 'relative',
    maxHeight: '51vh',
    overflowY: 'auto',
  },
  wizardRoot: (props) => ({
    height: '68vh',
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
    border: `1px solid ${ThemeV2.palette.lightGray4}`,
    marginTop: 24,
    borderRadius: '2px',
    padding: 16,
    backgroundColor: props.backgroundColor,
  }),
  wizardHeader: {
    borderBottom: `1px solid ${ThemeV2.palette.lightGray4}`,
    paddingRight: 16,
    paddingBottom: 16,
    marginBottom: 16,
  },
  wizardTitle: {
    fontFamily: ThemeV2.typography.adminFontFamily,
    fontSize: '16px',
    fontWeight: `${ThemeV2.typography.fontWeightSemibold} !important`,
    marginBottom: 4,
  },
  wizardStepCount: {
    fontFamily: ThemeV2.typography.adminFontFamily,
    fontSize: '14px',
    color: ThemeV2.palette.gray9,
  },
  wizardContent: {
    minHeight: 200,
    padding: '16px 0 52px 0',
  },
  wizardFooter: {
    position: 'absolute',
    left: '0px',
    right: '0px',
    bottom: '0px',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '16px 16px 16px 16px',
    background: 'rgba(255,255,255,1)',
    // eslint-disable-next-line max-len
    // 'linear-gradient(to bottom, rgb(255 255 255 / 1%) -4%,rgb(255 255 255) 30%,rgba(255,255,255,1) 100%)',
    zIndex: '2',
  },
  leftButton: {
    marginRight: 'auto',
  },
  nextButton: {
    marginLeft: 16,
  },
  stepperWrapper: {
    listStyleType: 'none',
    paddingLeft: 0,
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    marginBottom: '0',
    marginTop: 0,
    overflowX: 'auto',
    overflowY: 'hidden',
  },
  stepperItem: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    width: '100%',
    padding: '10px 15px 10px 15px',
    whiteSpace: 'nowrap',
    color: ThemeV2.palette.darkText,
    fontFamily: ThemeV2.typography.adminFontFamily,
    fontSize: '16px',
    lineHeight: '24px',
    minWidth: '164px',
    '&:not(:last-child)': {
      '&:before': {
        content: "''",
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        right: '-2.22rem',
        width: 0,
        height: 0,
        borderStyle: 'solid',
        borderWidth: '39px 21px 39px 21px',
        borderColor: `transparent transparent transparent ${ThemeV2.palette.lightGray4}`,
        lineHeight: '0px',
        zIndex: 1,
      },
      '&:after': {
        content: "''",
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        right: '-2.1rem',
        width: 0,
        height: 0,
        borderStyle: 'solid',
        borderWidth: '39px 20px 39px 21px',
        borderColor: `transparent transparent transparent ${ThemeV2.palette.gray3}`,
        lineHeight: '0px',
        zIndex: 2,
      },
    },
    '&.completed': {
      background: ThemeV2.palette.gray1,
      color: ThemeV2.palette.green7,
      '& svg': {
        fill: ThemeV2.palette.green7,
        marginRight: 6,
        fontSize: 20,
      },
      '&:after': {
        borderColor: `transparent transparent transparent ${ThemeV2.palette.gray1} !important`,
      },
    },
    '&.active': {
      background: `${ThemeV2.palette.geekBlue8}`,
      color: ThemeV2.palette.gray1,
      fontWeight: `${ThemeV2.typography.fontWeightSemibold}`,
      '&:after': {
        borderColor: `transparent transparent transparent ${ThemeV2.palette.geekBlue8} !important`,
      },
    },
  },
});

const StepsWizard = (props) => {
  const {
    steps,
    title,
    activeStep,
    leftButton,
    cancelButton,
    nextButton,
    children,
    backgroundColor,
    helperLink,
  } = props;
  const classes = useStyles({ backgroundColor });
  let activeStepIndex = 0;
  const completedSteps = [];
  for (let i = 0; i < steps.length; i += 1) {
    const step = steps[i];
    if (step.id === activeStep) {
      break;
    } else {
      activeStepIndex += 1;
      completedSteps.push(step.id);
    }
  }
  const titleComponent = useMemo(
    () => <Typography className={classes.wizardTitle}>{title}</Typography>,
    [classes.wizardTitle, title],
  );
  const stepNumberComponent = useMemo(
    () => (
      <Typography className={classes.wizardStepCount}>
        Step {activeStepIndex + 1} of {steps.length}
      </Typography>
    ),
    [activeStepIndex, classes.wizardStepCount, steps.length],
  );

  return (
    <div className={classes.wizardRoot}>
      <div className={classes.wizardHeader}>
        <Flex justifyContent="space-between">
          <div>
            {titleComponent}
            {stepNumberComponent}
          </div>
          <LinkWithIcon
            icon="question-mark"
            iconColor={ThemeV2.palette.geekBlue6}
            iconSize="16px"
            to={helperLink}
          >
            <Body variant="body2" color="geekBlue6">
              {HELPER_LINK_TEXT}
            </Body>
          </LinkWithIcon>
        </Flex>
      </div>
      <div
        className={classes.wizardInnerContent}
        id="steps-wizard-scrollable-container"
      >
        <div className={classes.StepperRoot}>
          <ul className={classes.stepperWrapper}>
            {steps &&
              steps.map((step, index) => (
                <li
                  className={classnames(
                    classes.stepperItem,
                    step.id === activeStep && 'active',
                    !(step.id === activeStep) &&
                      completedSteps.indexOf(step.id) !== -1 &&
                      'completed',
                  )}
                  key={step.id}
                >
                  {!(step.id === activeStep) &&
                  completedSteps.indexOf(step.id) !== -1 ? (
                    <CheckIcon />
                  ) : (
                    `${index + 1}. `
                  )}{' '}
                  {step.title}
                </li>
              ))}
          </ul>
        </div>
        <div className={classes.wizardContent}>{children}</div>
      </div>
      <div className={classes.wizardFooter}>
        {!leftButton.hidden && (
          <Button
            color="secondary"
            className={classes.leftButton}
            onClick={leftButton.onClick}
            disabled={leftButton.disabled}
            isLoading={leftButton.isLoading}
            variant={leftButton.variant}
            status={leftButton.status}
            icon={leftButton.icon}
          >
            {leftButton.text}
          </Button>
        )}
        {!cancelButton.hidden && (
          <Button
            color="secondary"
            onClick={cancelButton.onClick}
            disabled={cancelButton.disabled}
          >
            {cancelButton.text}
          </Button>
        )}
        {!nextButton.hidden && (
          <Button
            color="primary"
            className={classes.nextButton}
            onClick={nextButton.onClick}
            disabled={nextButton.disabled}
            isLoading={nextButton.isLoading}
            dataTestId="nextButton"
          >
            {nextButton.text}
          </Button>
        )}
      </div>
    </div>
  );
};

export const buttonShape = shape({
  onClick: func,
  disabled: bool,
  isLoading: bool,
  text: string,
  hidden: bool,
  variant: string,
  status: string,
  icon: string,
});

const buttonDefaultProps = {
  onClick: () => {},
  disabled: false,
  text: '',
  hidden: false,
};

StepsWizard.propTypes = {
  steps: arrayOf(
    shape({
      id: string.isRequired,
      title: string.isRequired,
      leftButton: object,
      cancelButton: object,
      nextButton: object,
    }),
  ).isRequired,
  backgroundColor: string,
  activeStep: string,
  nextButton: buttonShape,
  leftButton: buttonShape,
  cancelButton: buttonShape,
  title: string,
  helperLink: string,
  children: oneOfType([arrayOf(node), node]).isRequired,
};

StepsWizard.defaultProps = {
  title: '',
  helperLink: '',
  backgroundColor: ThemeV2.palette.white,
  activeStep: 0,
  nextButton: buttonDefaultProps,
  leftButton: buttonDefaultProps,
  cancelButton: buttonDefaultProps,
};

export default StepsWizard;
