// @ts-nocheck TODO: type issues need to be fixed in this file
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { string, object, arrayOf, shape, number } from 'prop-types';

import { buttonShape } from '../../organism/StepsWizard';
import IconBadgeInfo from '../../organism/IconBadgeInfo';
import Body from '../../atoms/Body';
import IconInfo from '../../molecules/IconInfo';
import IdentityManagerFlowTemplate from '../../templates/IdentityManagerFlowTemplate';

const useStyles = makeStyles({
  heading: {
    marginTop: '8px',
    marginBottom: '16px',
  },
  infoItemsWrapper: {
    marginBottom: '24px',
  },
  iconBadgeInfoWrapper: {
    marginBottom: '16px',
  },
});

const IdentitySSOIntro = ({
  title,
  steps,
  activeStep,
  infoItems,
  heading,
  secondHeading,
  recommendation,
  nextButton,
  cancelButton,
  notifications,
  helperLink,
}) => {
  const classes = useStyles();
  const leftButton = { hidden: true };
  const stepsWizard = {
    title,
    steps,
    activeStep,
    nextButton,
    cancelButton,
    leftButton,
    helperLink,
  };
  return (
    <IdentityManagerFlowTemplate
      stepsWizard={stepsWizard}
      notifications={notifications}
    >
      <>
        <Body variant="body1Bold" className={classes.heading}>
          {heading}
        </Body>
        <div className={classes.infoItemsWrapper}>
          {infoItems.map((item) => (
            <IconBadgeInfo
              key={item.icon}
              icon={item.icon}
              badgeColor={item.badgeColor}
              className={classes.iconBadgeInfoWrapper}
            >
              <Body variant="body2">{item.info}</Body>
            </IconBadgeInfo>
          ))}
        </div>
        <Body variant="body1Bold" className={classes.heading}>
          {secondHeading}
        </Body>
        <IconInfo>
          <Body variant="body2">{recommendation}</Body>
        </IconInfo>
      </>
    </IdentityManagerFlowTemplate>
  );
};

IdentitySSOIntro.propTypes = {
  title: string.isRequired,
  heading: string.isRequired,
  secondHeading: string.isRequired,
  recommendation: string.isRequired,
  activeStep: string.isRequired,
  steps: arrayOf(
    shape({
      id: string.isRequired,
      title: string.isRequired,
      leftButton: object,
      cancelButton: object,
      nextButton: object,
    }),
  ).isRequired,
  infoItems: arrayOf(shape({ icon: string, badgeColor: string, info: string }))
    .isRequired,
  nextButton: buttonShape.isRequired,
  cancelButton: buttonShape.isRequired,
  helperLink: string,
  notifications: arrayOf(
    shape({
      id: number.isRequired,
      message: string.isRequired,
      type: string.isRequired,
    }),
  ),
};

IdentitySSOIntro.defaultProps = {
  notifications: [],
  helperLink: '',
};

export default IdentitySSOIntro;
