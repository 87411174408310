import React, { useCallback } from 'react';

import Body from '../../../../atoms/Body';
import MeetingType from './MeetingType';
import Image from '../../../../atoms/Image';
import { EmptyComponentProps } from '../type';
import { Flex } from '../../../../../Utils/styles/display';
import { StyledContentWrapper, StyledLink } from '../styles';
import ImportantDatesImage from '../../../../../img/important-dates.svg';
import {
  PRIMARY_CALENDAR,
  quickSetupMeetingListScreen,
} from '../../../../../languages/en/quickSetup';
// eslint-disable-next-line max-len
import CalendarSelectDropdownBarWithRefresh from '../../../../molecules/QuickSetup/CalendarSelectDropdownBarWithRefresh';

const EmptyComponent = (props: EmptyComponentProps) => {
  const {
    value,
    menuItems,
    onItemClick,
    meetingType,
    selectedValue,
    dropdownMenuItems,
    onExploreOwnClick,
    onRefreshListClick,
    onDropdownItemClick,
    dropdownPlaceholderImage,
  } = props;

  const handleGoToCalendarClick = useCallback(() => {
    onDropdownItemClick(PRIMARY_CALENDAR);
  }, [onDropdownItemClick]);

  const handleExploreOnOwnClick = useCallback(() => {
    if (onExploreOwnClick) {
      onExploreOwnClick();
    }
  }, [onExploreOwnClick]);

  return (
    <>
      <CalendarSelectDropdownBarWithRefresh
        selectedValue={selectedValue}
        dropdownMenuItems={dropdownMenuItems}
        onRefreshListClick={onRefreshListClick}
        onDropdownItemClick={onDropdownItemClick}
        dropdownPlaceholderImage={dropdownPlaceholderImage}
      />
      <MeetingType
        value={value}
        menuItems={menuItems}
        meetingType={meetingType}
        onItemClick={onItemClick}
      />
      <Flex alignItems="initial">
        <Image src={ImportantDatesImage} alt="important-dates" />
        <StyledContentWrapper>
          <Body variant="body1Medium">
            {quickSetupMeetingListScreen.emptyComponent.contentHeading}
          </Body>
          <Body variant="body2" color="gray8">
            {quickSetupMeetingListScreen.emptyComponent.contentSubHeading}
            <StyledLink onClickCallback={handleGoToCalendarClick}>
              {quickSetupMeetingListScreen.emptyComponent.goToCalendar}
            </StyledLink>
            {quickSetupMeetingListScreen.emptyComponent.separator}
            <StyledLink onClickCallback={handleExploreOnOwnClick}>
              {quickSetupMeetingListScreen.emptyComponent.exploreOnOwm}
            </StyledLink>
          </Body>
        </StyledContentWrapper>
      </Flex>
    </>
  );
};

export default EmptyComponent;
