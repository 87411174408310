import {
  GIVE_TROPHIES_BACK_TO_SENDER,
  ALLOW_THE_RECEIVER_TO_KEEP,
  COPY_POST_LINK,
  REMOVE_GIF,
  DELETE_POST,
} from '../../../languages/en/home';

export const dropdownOptions = [
  {
    id: 'feed-post-header-options',
    items: [
      {
        value: COPY_POST_LINK,
        id: 'copy-link',
        prefixIcon: 'Link',
        isSelected: false,
      },
      {
        value: REMOVE_GIF,
        id: 'remove-gif',
        prefixIcon: 'gif-selector-icon',
        isSelected: false,
        permissionRequired: true,
      },
      {
        value: DELETE_POST,
        id: 'delete',
        prefixIcon: 'delete',
        deleteItem: true,
        isSelected: false,
        permissionRequired: true,
      },
    ],
  },
];

const postDeleteModalDropdownOptions = [
  {
    id: 'delete-post-modal-options',
    items: [
      {
        value: GIVE_TROPHIES_BACK_TO_SENDER,
        id: '1',
        isSelected: false,
      },
      {
        value: ALLOW_THE_RECEIVER_TO_KEEP,
        id: '2',
        isSelected: false,
      },
    ],
  },
];

export const feedPostDeleteModalDropdown = {
  value: 'Select an option',
  options: postDeleteModalDropdownOptions,
};
