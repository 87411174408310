import React from 'react';

import FlowPostBody from '../FlowPostBody';
import FeedPostReactions from '../FeedPostReactions';
import FlowsPostHeader from '../../molecules/FlowsPostHeader';

import { FlowFeedPostProps } from './types';
import { FlowFeedPostWrapper } from './styles';
import FeedPostComments from '../FeedPostComments';
import PostInput from '../PostInput';
import { FeedCommentInputWrapper } from '../FeedPost/styles';

const emptyButtonClick = () => {};

const FlowFeedPost = (props: FlowFeedPostProps) => {
  const {
    bodyProps,
    className,
    headerProps,
    onFlowClick,
    onMemberClick,
    onReactionSet,
    commentsProps,
    onReactionUnSet,
    onUserNameClick,
    onCommentExited,
    onCommentPosted,
    reactionBarProps,
    commentInputProps,
    onCommentTextFocus,
    dropdownComponent,
    isAnonymous,
  } = props;

  return (
    <FlowFeedPostWrapper className={className}>
      <FlowsPostHeader
        flow={headerProps.flow}
        createdAt={headerProps.createdAt}
        postType={headerProps.postType}
        person={headerProps.person}
        visibility={headerProps.visibility}
        handleOnClickUserName={onUserNameClick}
        onFlowClick={onFlowClick}
        dropdownComponent={dropdownComponent}
        kind={headerProps?.kind}
      />
      <FlowPostBody
        {...bodyProps}
        flow={headerProps.flow}
        createdAt={headerProps.createdAt}
        person={headerProps.person}
        visibility={headerProps.visibility}
        onMemberClick={onMemberClick}
        isAnonymous={isAnonymous}
      />
      {reactionBarProps && onReactionSet && onReactionUnSet && (
        <FeedPostReactions
          {...reactionBarProps}
          onCommentButtonClick={
            commentInputProps?.onCommentButtonClick || emptyButtonClick
          }
          onEmoticonSelect={onReactionSet}
          onReactionToggleClick={onReactionUnSet}
        />
      )}
      {commentsProps && <FeedPostComments {...commentsProps} isDummy={false} />}
      {commentInputProps && (
        <FeedCommentInputWrapper>
          <PostInput
            {...commentInputProps}
            onEditorBlur={onCommentExited}
            onEditorFocus={onCommentTextFocus}
            onPostClick={onCommentPosted}
          />
        </FeedCommentInputWrapper>
      )}
    </FlowFeedPostWrapper>
  );
};

export default FlowFeedPost;
