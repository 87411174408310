import React from 'react';
import { IconContainerProps } from './types';
import { StyledIconContainer } from './styles';

const IconContainer = (props: IconContainerProps) => {
  const {
    className,
    size = '40px',
    bgColor = '',
    borderRadius = '',
    children,
  } = props;

  return (
    <StyledIconContainer
      size={size}
      bgColor={bgColor}
      borderRadius={borderRadius}
      className={className}
    >
      {children}
    </StyledIconContainer>
  );
};

export default IconContainer;
