import React, { useState } from 'react';
import styled from 'styled-components';
import { Collapse } from '@material-ui/core';

import Body from '../../../atomic/atoms/Body';
import EmoticonWithText from '../../../atomic/atoms/EmoticonWithText';
import { LeftDrawerCollapsibleNavItemProps } from '../../../atomic/molecules/FlowLeftDrawerCollapsibleNavigation/types';
import BasicModalTemplate from '../../../atomic/templates/BasicModalTemplate';
import {
  ARCHIVED_FLOWS,
  FLOWS_UNARCHIVE_MODAL,
} from '../../../languages/en/flows';
import { UNARCHIVE } from '../../../languages/en/singleWords';
import { LEFT_DRAWER_ANALYTICS_EVENTS } from '../../../Utils/analytics/constants';
import useFlowsArchivedListController from './useFlowsArchivedListController';
import ThemeV2 from '../../../componentsV2/theme';
import MenuList from '../../../atomic/molecules/MenuItem';
import { trackLeftDrawerEvent } from '../../../Utils/analytics';

const StyledMenuListItem = styled(MenuList.Item)`
  margin-bottom: 4px;
  &:last-child {
    margin-bottom: 0px;
  }
`;

const FlowsArchivedListController = ({ isHover }: { isHover: boolean }) => {
  const [flowItem, setFlowItem] = useState<
    LeftDrawerCollapsibleNavItemProps | undefined
  >(undefined);
  const {
    models: {
      isOpen,
      archiveFlowsNavItems,
      isArchiveModalOpen,
      isUnArchiveModalLoading,
      unArchivingFlowDetails,
    },
    operations: {
      onExpandClick,
      onMenuItemClick,
      onUnArchiveClick,
      onModalClose,
    },
  } = useFlowsArchivedListController();

  const getFlowItemOnMenuClick = (id: string) => {
    const individualFlowItem = archiveFlowsNavItems.find(
      (flow) => flow.id === id,
    );
    return individualFlowItem;
  };

  const AccordionHeader = () => {
    return (
      <MenuList>
        <MenuList.Item
          isExpanded={isHover}
          disableHoverState
          onClick={() => {
            onExpandClick();
            if (isOpen) {
              trackLeftDrawerEvent(
                LEFT_DRAWER_ANALYTICS_EVENTS.LEFT_DRAWER_EVENTS_ARCHIVED_LIST_COLLAPSED,
              );
            } else {
              trackLeftDrawerEvent(
                LEFT_DRAWER_ANALYTICS_EVENTS.LEFT_DRAWER_EVENTS_ARCHIVED_LIST_EXPANDED,
              );
            }
          }}
        >
          <MenuList.Icon icon={isOpen ? 'carat-up' : 'carat-down'} />
          <MenuList.Text variant="body1">
            {ARCHIVED_FLOWS.MAIN_TEXT_LABEL}
          </MenuList.Text>
        </MenuList.Item>
      </MenuList>
    );
  };

  if (archiveFlowsNavItems.length) {
    return (
      <>
        <AccordionHeader />
        <Collapse
          in={isOpen}
          aria-expanded={isOpen}
          data-testid="accordionBody"
        >
          <MenuList>
            {archiveFlowsNavItems.map((navItem) => (
              <StyledMenuListItem
                key={navItem.id}
                id={navItem.id}
                onClick={() => {
                  onMenuItemClick(navItem.id);
                  setFlowItem(getFlowItemOnMenuClick(navItem.id));
                }}
                isExpanded={isHover}
                isSelected={navItem.isActive}
                isMuted={navItem.isArchived}
              >
                <MenuList.Emoticon emoticon={navItem.emoticon} />
                <MenuList.Text variant="body1" color={ThemeV2.palette.gray9}>
                  {navItem.label}
                </MenuList.Text>
                {navItem.isUnread ? (
                  <MenuList.SuffixElement>
                    <Body
                      variant="body3"
                      color={
                        navItem.isMuted
                          ? ThemeV2.palette.gray6
                          : ThemeV2.palette.gray9
                      }
                    >
                      {navItem.badgeText}
                    </Body>
                  </MenuList.SuffixElement>
                ) : (
                  <></>
                )}
              </StyledMenuListItem>
            ))}
          </MenuList>
        </Collapse>
        {/* <StyledExpandableListHolder
          onClick={() => {
            onExpandClick();
            if (isOpen) {
              trackLeftDrawerEvent(
                LEFT_DRAWER_ANALYTICS_EVENTS.LEFT_DRAWER_EVENTS_ARCHIVED_LIST_COLLAPSED,
              );
            } else {
              trackLeftDrawerEvent(
                LEFT_DRAWER_ANALYTICS_EVENTS.LEFT_DRAWER_EVENTS_ARCHIVED_LIST_EXPANDED,
              );
            }
          }}
          arrowIcon="carat-down"
          arrowIconSize="16px"
          heading="Archived flows"
          isExpanded={isOpen}
        >
          {archiveFlowsNavItems.length &&
            archiveFlowsNavItems.map((navItem) => (
              <StyledClickable
                key={navItem.id}
                id={navItem.id}
                onClick={handleItemClick}
              >
                <StyledNavItem
                  emoticon={navItem.emoticon}
                  color={
                    navItem.isMuted
                      ? ThemeV2.palette.gray6
                      : ThemeV2.palette.gray9
                  }
                  badgeText={navItem.isUnread ? navItem.badgeText : ''}
                  badgeColor={
                    (navItem.isMuted && ThemeV2.palette.gray6) || undefined
                  }
                  textVariant={navItem.isUnread ? 'body1Medium' : 'body1'}
                  isActive={navItem.isActive}
                  isArchived={navItem.isArchived}
                >
                  {navItem.label}
                </StyledNavItem>
              </StyledClickable>
            ))}
        </StyledExpandableListHolder> */}
        <BasicModalTemplate
          isOpen={isArchiveModalOpen}
          heading={FLOWS_UNARCHIVE_MODAL.MODAL_HEADING}
          alignActionButtons="right"
          primaryButton={{
            text: `${FLOWS_UNARCHIVE_MODAL.UNARCHIVE_LABEL}`,
            onClick: () => {
              onUnArchiveClick();
              trackLeftDrawerEvent(
                LEFT_DRAWER_ANALYTICS_EVENTS.LEFT_DRAWER_EVENTS_FLOW_UNARCHIVED,
                flowItem,
              );
            },
            isLoading: isUnArchiveModalLoading,
          }}
          secondaryButton={{
            text: `${FLOWS_UNARCHIVE_MODAL.CANCEL_LABEL}`,
            onClick: () => {
              onModalClose();
              trackLeftDrawerEvent(
                LEFT_DRAWER_ANALYTICS_EVENTS.LEFT_DRAWER_EVENTS_FLOW_UNARCHIVE_CANCELLED,
                flowItem,
              );
            },
          }}
          onModalClose={() => {
            onModalClose();
            trackLeftDrawerEvent(
              LEFT_DRAWER_ANALYTICS_EVENTS.LEFT_DRAWER_EVENTS_FLOW_UNARCHIVE_CANCELLED,
              flowItem,
            );
          }}
        >
          <Body variant="body2">
            {UNARCHIVE}{' '}
            <EmoticonWithText
              text={unArchivingFlowDetails?.label}
              emoticon={unArchivingFlowDetails?.emoticon || ''}
              hasBoldText
            />{' '}
            {FLOWS_UNARCHIVE_MODAL.MODAL_CONTENT_TEXT}
          </Body>
        </BasicModalTemplate>
      </>
    );
  }

  return null;
};

export default FlowsArchivedListController;
