import styled from 'styled-components';
import { Flex } from '../../../../Utils/styles/display';
import ThemeV2 from '../../../../componentsV2/theme';
import Checkbox from '../../../atoms/Checkbox';
import Body from '../../../atoms/Body';
import Tooltip from '../../../molecules/ToolTipV2';
import TaskContent from '../../../molecules/Notebook/TaskContent';
import DropdownIconButton from '../../../molecules/DropdownIconButton';

export const TaskItemWrapper = styled(Flex)<{
  isNewTask?: boolean;
  isDragging: boolean;
  isUnread: boolean;
  highlight: boolean;
}>`
  align-items: flex-start;
  padding: 12px;
  background: ${({ isDragging, highlight }) =>
    isDragging || highlight ? ThemeV2.palette.gray2 : 'inherit'};
  outline: ${({ isUnread }) =>
    isUnread
      ? `solid 2px ${ThemeV2.palette.geekBlue6}`
      : 'solid 2px transparent'};
  transition: outline 0.2s linear;
  margin: 0.5em;
  border-radius: 4px;
  margin-bottom: 8px;

  &:hover {
    background: ${ThemeV2.palette.gray2};
    ${({ isUnread }) => (isUnread ? 'outline-width: 0px' : '')};
    border-radius: '4px';
    .hover-icons {
      display: block;
    }
  }

  @keyframes fadeIn {
    0% {
      height: 0;
      opacity: 0;
      transition: height 0.5s ease-out;
    }
    100% {
      height: auto;
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    0% {
      height: auto;
      opacity: 1;
    }
    100% {
      height: 0;
      opacity: 0;
      transition: height 0.5s ease-out;
    }
  }
`;
export const DragIconWrapper = styled.div`
  margin: 4px;
  min-width: 14px;
`;

export const DragIconWithTooltip = styled(Tooltip)`
  display: none;
  span:nth-child(3) {
    width: 200px;
  }
`;

export const EditorWrapper = styled.div`
  flex-grow: 100;
  position: relative;
`;

export const EditIconWrapper = styled.div`
  padding: 4px 8px;
  position: absolute;
  right: 0;
  height: 100%;
  background: linear-gradient(270deg, #fafafa 50%, rgba(250, 250, 250, 0) 100%);
  cursor: pointer;
  display: none;
`;

export const StyledCheckbox = styled(Checkbox)`
  margin: 2px 12px 4px 4px;
`;

export const StyledDropdownIconButton = styled(DropdownIconButton)`
  margin-left: 4px;
  button {
    height: 24px;
    width: 24px;
  }
`;

export const CreatedByText = styled(Body)`
  margin-right: 4px;
`;

export const ErrorWrapper = styled.span`
  margin-left: 6px;
  display: inline-flex;
  align-items: center;
`;

export const ErrorText = styled(Body)`
  margin-left: 4px;
`;

export const EditorFooterText = styled(Flex)`
  margin-top: 7px;
  cursor: default;
`;

export const StyledTiptapEditorWrapper = styled.div`
  .custom-tiptap {
    padding: 0px;
    font-size: 16px;
    line-height: 20px;

    p {
      margin: 0px;
    }

    &:focus-visible {
      outline: none;
    }
  }
`;

export const TryAgain = styled.span`
  text-decoration: underline;
  cursor: pointer;
  margin-left: 4px;
`;

export const StyledTaskContent = styled(TaskContent)`
  padding: 0px;
  font-size: 16px;
  line-height: 20px;
`;

export const CreatedByName = styled(Body)`
  cursor: pointer;
`;
