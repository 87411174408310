import styled from 'styled-components';

import AssemblyCurrencyIcon from '../../atoms/AssemblyCurrencyIcon';
import Body from '../../atoms/Body';
import { DropdownSizes } from '../Dropdown_V2/interfaces';
import ThemeV2 from '../../../componentsV2/theme';

interface WrapperProps {
  isFocused: boolean;
  isError: boolean;
  buttonSize: DropdownSizes;
}

const SMALL_BUTTON_HEIGHT = '32px';
const MEDIUM_BUTTON_HEIGHT = '40px';
const LARGE_BUTTON_HEIGHT = '48px';

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: ${({ buttonSize }) => {
    switch (buttonSize) {
      case DropdownSizes.Small:
        return SMALL_BUTTON_HEIGHT;
      case DropdownSizes.Medium:
        return MEDIUM_BUTTON_HEIGHT;
      default:
        return LARGE_BUTTON_HEIGHT;
    }
  }};
  padding: 4px 12px;
  border: 1px solid
    ${({ isFocused, isError }: WrapperProps) =>
      // eslint-disable-next-line no-nested-ternary
      isError
        ? ThemeV2.palette.red6
        : isFocused
        ? ThemeV2.palette.geekBlue6
        : ThemeV2.palette.gray9};
  border-radius: 4px;
`;

export const StyledInput = styled.input<{
  buttonSize: DropdownSizes;
  isFullWidth: boolean;
  valueLength: number;
}>`
  outline: none;
  border: none;
  width: ${({ isFullWidth, valueLength }) =>
    isFullWidth ? `${valueLength * 8 + 15}px` : '40px'};
  text-align: right;

  & :focus {
    outline: none;
  }

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

export const LimitBody = styled(Body)`
  margin-right: 8px;
`;

export const CaretIconSpan = styled.span`
  margin-left: auto;
  cursor: pointer;
`;

export const StyledAssemblyCurrencyIcon = styled(AssemblyCurrencyIcon)`
  margin-right: 4px;
`;
