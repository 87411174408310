import { useMutation, useQuery, useQueryClient } from 'react-query';
import { GET_TOURS, UPDATE_TOURS } from '../../constants/endpoints';
import { ToursResponse } from '../../Utils/featureIntros/types';
import { makeAPICall } from '../utils';

export const useFeatureIntroQuery = (name = '') => {
  return useQuery<ToursResponse>(
    [GET_TOURS, name],
    () =>
      makeAPICall(
        GET_TOURS,
        undefined,
        {
          names: name,
        },
        undefined,
      ),
    {
      retry: false,
      staleTime: Infinity,
    },
  );
};

export const useUpdateFeatureIntrosQuery = (name = '') => {
  const queryClient = useQueryClient();
  return useMutation(
    () => {
      return makeAPICall(UPDATE_TOURS, { status: 'COMPLETED' }, undefined, {
        tourName: name,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_TOURS);
      },
      onError: () => {},
    },
  );
};
