import { StaticBlockState } from '../../../interfaces/Flow';
import {
  FIRST_SLIDE_TITLE,
  FOURTH_SLIDE_TITLE,
  SECOND_SLIDE_TITLE,
  THIRD_SLIDE_TITLE,
} from '../../../languages/en/recognitionFlow';

export const STEP_IDS = {
  SELECT_TEAMMATE: 'select-teammate',
  WHAT_DID_THEY_DO: 'what-did-they-do',
  SELECT_CORE_VALUE: 'select-core-value',
  TROPHIES: 'trophies',
};

export const recognitionFlowStepData: StaticBlockState[] = [
  {
    id: STEP_IDS.SELECT_TEAMMATE,
    title: FIRST_SLIDE_TITLE,
    isRequired: true,
    type: 'MULTI_PERSON_SELECTOR_DROPDOWN',
    helperText: 'Choose people',
  },
  {
    id: STEP_IDS.WHAT_DID_THEY_DO,
    title: SECOND_SLIDE_TITLE,
    isRequired: true,
    type: 'OPEN_ENDED',
  },
  {
    id: STEP_IDS.SELECT_CORE_VALUE,
    title: THIRD_SLIDE_TITLE,
    isRequired: false,
    type: 'SINGLE_SELECT_DROPDOWN',
    options: [],
    helperText: 'Choose option',
  },
  {
    id: STEP_IDS.TROPHIES,
    title: FOURTH_SLIDE_TITLE,
    isRequired: false,
    type: 'TROPHIES',
  },
];
