import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { V2_SETTINGS } from '../../../constants/routes';
import { SelectedMember } from '../../../interfaces/Layout';
import { MemberDetails } from '../../../interfaces/MemberDetails';
import {
  BIRTHDAY,
  DEPARTMENT,
  DETAILS,
  DIRECT_REPORTS,
  EDIT_MY_DETAILS,
  EMAIL,
  JOB_TITLE,
  LOCAL_TIME,
  LOCATION,
  MANAGER,
  WORK_ANNIVERSARY,
} from '../../../languages/en/home/profileDrawer';
import { ONE_MINUTE } from '../../../Utils/constants/times';
import {
  getAbbreviatedMonthByNumber,
  getCurrentTimeInTimeZone,
} from '../../../Utils/date';
import Body from '../../atoms/Body';
import Button from '../../atoms/Button';
import Link from '../../atoms/Link_V2';
import SVGIcon from '../../atoms/SVGIcon';
import { OthersLabel } from '../FeedPostHeader/styles';
import ProfileDetailsEntry from '../ProfileDetailsEntry';
import { TopWrapper } from '../ProfileDetailsEntry/styles';
import UserListDropdown from '../UserListDropdown';
import {
  MembersWrapper,
  OthersLabelWrapper,
  StyledAvatar,
  SubsectionsWrapper,
  Wrapper,
} from './styles';

export interface ProfileDetailsSectionProps {
  birthday?: {
    day: number;
    month: number;
  } | null;
  department?: string;
  email?: string;
  isCurrentUser?: boolean;
  jobTitle?: string;
  location?: string;
  manager?: {
    firstName: string;
    lastName: string;
    memberId: string;
    profilePicture: string;
  };
  directReportsDetails: MemberDetails[];
  timeZone?: string;
  workAnniversary?: {
    day: number;
    month: number;
    year: number;
  } | null;
  toggleRightAsideOpen: () => void;
  setSelectedMemberProfile: (selectedMember: SelectedMember) => void;
}

const formatWorkAnniversary = (year: number, month: number, day: number) => {
  return `${getAbbreviatedMonthByNumber(month)} ${day}, ${year}`;
};

const ProfileDetailsSection = ({
  birthday,
  department,
  email,
  isCurrentUser,
  jobTitle,
  location,
  manager,
  timeZone,
  workAnniversary,
  toggleRightAsideOpen,
  directReportsDetails,
  setSelectedMemberProfile,
}: ProfileDetailsSectionProps) => {
  const [localTime, setLocalTime] = useState(() =>
    getCurrentTimeInTimeZone(timeZone),
  );
  const handleOnUseClick = useCallback(
    (firstName: string, memberId: string) => {
      setSelectedMemberProfile({
        firstName,
        memberId,
      });
    },
    [setSelectedMemberProfile],
  );
  const updateLocalTime = useCallback(
    () => setLocalTime(getCurrentTimeInTimeZone(timeZone)),
    [timeZone],
  );
  const repeatEveryMinute = useCallback(() => {
    // Check current time and calculate the delay until next interval
    const now = new Date();
    const delay = ONE_MINUTE - (now.valueOf() % ONE_MINUTE);

    // Delay execution until it's an even interval
    setTimeout(() => setInterval(updateLocalTime, ONE_MINUTE), delay);
  }, [updateLocalTime]);

  useEffect(() => {
    repeatEveryMinute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      <Body variant="body2Medium">{DETAILS}</Body>

      <SubsectionsWrapper>
        {jobTitle && (
          <ProfileDetailsEntry icon="job-title" title={JOB_TITLE}>
            <Body variant="body3">{jobTitle}</Body>
          </ProfileDetailsEntry>
        )}

        {department && (
          <ProfileDetailsEntry icon="department" title={DEPARTMENT}>
            <Body variant="body3">{department}</Body>
          </ProfileDetailsEntry>
        )}

        {email && (
          <ProfileDetailsEntry icon="mail" title={EMAIL}>
            <Body variant="body3">{email}</Body>
          </ProfileDetailsEntry>
        )}

        {manager && (
          <ProfileDetailsEntry icon="manager" title={MANAGER}>
            <MembersWrapper
              onClick={() =>
                handleOnUseClick(manager.firstName, manager.memberId)
              }
            >
              <StyledAvatar
                name={manager.firstName}
                userId={manager.memberId}
                img={manager.profilePicture}
                size="20px"
              />
              <Body variant="body3">
                {manager.firstName} {manager.lastName}
              </Body>
            </MembersWrapper>
          </ProfileDetailsEntry>
        )}

        {directReportsDetails && directReportsDetails.length > 0 && (
          <ProfileDetailsEntry
            icon="users"
            title={DIRECT_REPORTS}
            subtitle={directReportsDetails.length.toString()}
          >
            {directReportsDetails
              .slice(0, 4)
              .map((memberDetails: MemberDetails) => (
                <MembersWrapper
                  key={memberDetails.memberId}
                  onClick={() =>
                    handleOnUseClick(
                      memberDetails.profile.firstName,
                      memberDetails.memberId,
                    )
                  }
                >
                  <StyledAvatar
                    name={memberDetails.profile.firstName}
                    userId={memberDetails.memberId}
                    img={memberDetails.profile.image}
                    size="20px"
                  />
                  <Body variant="body3">
                    {memberDetails.profile.firstName}{' '}
                    {memberDetails.profile.lastName}
                  </Body>
                </MembersWrapper>
              ))}

            {directReportsDetails.length > 4 && (
              <UserListDropdown
                startIndex={4}
                onUserClick={(e) => handleOnUseClick(e.firstName, e.memberID)}
                endIndex={14}
                users={directReportsDetails.map(
                  (memberDetails: MemberDetails) => {
                    return {
                      memberID: memberDetails.memberId,
                      firstName: memberDetails.profile.firstName,
                      lastName: memberDetails.profile.lastName,
                      image: memberDetails.profile.image,
                      isDeleted: false,
                      memberState: memberDetails.memberState,
                    };
                  },
                )}
              >
                <OthersLabelWrapper>
                  <OthersLabel variant="body2Medium" color="geekBlue6">
                    <TopWrapper>
                      <SVGIcon icon="users-with-circle" size="20px" />
                      <span style={{ paddingLeft: '5px' }}>
                        and {directReportsDetails.length - 4} others
                      </span>
                    </TopWrapper>
                  </OthersLabel>
                </OthersLabelWrapper>
              </UserListDropdown>
            )}
          </ProfileDetailsEntry>
        )}

        {localTime && (
          <ProfileDetailsEntry icon="clock" title={LOCAL_TIME}>
            <Body variant="body3">{localTime}</Body>
          </ProfileDetailsEntry>
        )}

        {location && (
          <ProfileDetailsEntry icon="location" title={LOCATION}>
            <Body variant="body3">{location}</Body>
          </ProfileDetailsEntry>
        )}

        {birthday && (
          <ProfileDetailsEntry icon="birthday-cake" title={BIRTHDAY}>
            <Body variant="body3">
              {moment(birthday.month.toString(), 'MM').format('MMMM')}{' '}
              {birthday.day}
            </Body>
          </ProfileDetailsEntry>
        )}

        {workAnniversary && (
          <ProfileDetailsEntry icon="calendar" title={WORK_ANNIVERSARY}>
            <Body variant="body3">
              {formatWorkAnniversary(
                workAnniversary.year,
                workAnniversary.month,
                workAnniversary.day,
              )}
            </Body>
          </ProfileDetailsEntry>
        )}
      </SubsectionsWrapper>

      {isCurrentUser && (
        <Link to={V2_SETTINGS}>
          <Button isFullWidth onClick={toggleRightAsideOpen} variant="text">
            {EDIT_MY_DETAILS}
          </Button>
        </Link>
      )}
    </Wrapper>
  );
};

export default ProfileDetailsSection;
