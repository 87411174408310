import React from 'react';
import styled from 'styled-components';

import { ButtonSize } from '../../atoms/IconButton_V2/interface';
import {
  ChildrenWrapper,
  HeadingWrapper,
  IconButtonWrapper,
  StyledBody,
} from './styles';
import IconButton from '../../atoms/IconButton_V2';
import { StyledDrawerHeaderWithIcon } from '../Leaderboard/styles';

export interface ProfileDrawerProps {
  children: React.ReactNode;
  name: string;
  removeSelectedMember: () => void;
  shouldShowCloseIcon: boolean;
}

const StyledWrapper = styled.div`
  max-height: calc(100vh - 75px);
  overflow-y: auto;
`;

const ProfileDrawer = ({
  children,
  name,
  removeSelectedMember,
  shouldShowCloseIcon,
}: ProfileDrawerProps) => (
  <StyledWrapper>
    <HeadingWrapper>
      <StyledDrawerHeaderWithIcon icon="badge-bubble">
        About <StyledBody variant="body1Medium">{name}</StyledBody>
      </StyledDrawerHeaderWithIcon>
      {shouldShowCloseIcon && (
        <IconButtonWrapper>
          <IconButton
            icon="close-rounded"
            onClick={removeSelectedMember}
            size={ButtonSize.Small}
            title="close profile"
          />
        </IconButtonWrapper>
      )}
    </HeadingWrapper>

    <ChildrenWrapper>{children}</ChildrenWrapper>
  </StyledWrapper>
);
const MemoizedProfileDrawer = React.memo(ProfileDrawer);
MemoizedProfileDrawer.displayName = 'ProfileDrawer';
export default MemoizedProfileDrawer;
