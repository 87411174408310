import { useCallback, useEffect, useMemo, useState } from 'react';
import { AvatarProps } from '../../atomic/atoms/Avatar/interfaces';
import { SettingsDropdownProps } from '../../atomic/molecules/SettingsDropdown/interface';
import { SettingsStringFieldProps } from '../../atomic/molecules/SettingsEditStringField';
import { SettingsAvatarProps } from '../../atomic/organism/SettingsAvatar/interface';
import { UserInfo } from '../../interfaces/Settings/index';
import { userNameValidationSchema } from './../../atomic/molecules/SettingsEditStringField/validationSchema';
import { JOB_TITLE, LOCATION } from './../../languages/en/home/profileDrawer';

import { menuItems } from '../../atomic/molecules/Dropdown_V2/data';
import {
  MultiSelectDropdownType,
  SettingsMultiSelectDropdownProps,
} from '../../atomic/molecules/SettingsMultiSelectDropdown/interface';
import { SelectedItemProps } from '../../atomic/molecules/TextField_V2/interface';
import {
  SettingsEmailProps,
  SettingsHandleProps,
} from '../../atomic/organism/GeneralSettingsAccordion';

import { UpdateUserInfoPayload } from '../../queries/Settings';
import { UpdatedEmployeeDepartments } from '../../Utils/CompanyDetailsDatastore';

import { OptionsProps } from '../../atomic/molecules/DropdownWithMultiSelect';
import {
  SettingsAutocompleteProps,
  SettingsAutocompleteValues,
} from '../../atomic/molecules/SettingsAutocomplete';
import { useAccordionToggleLogic } from '../../atomic/organism/GeneralSettingsAccordion/useAccordionToggleLogic';
import { useMembersSearch } from '../../hooks/useMembersSearch';
import { IMemberDTO } from '../../interfaces/member';
import { LABEL } from '../../languages/en/settings';
import {
  AVATAR,
  DEPARTMENT,
  DIRECT,
  MANAGER,
  NAME,
  REPORTS,
  TIME_ZONE,
} from '../../languages/en/singleWords';
import { autoCompleteEmpty } from '../../Utils/data/feedSearch/dropdown';
import { getIds } from '../../Utils/text';

import { GET_PROFILE_PICTURE_UPLOAD_SIGNED_URL } from '../../constants/endpoints';
import useUploadProfilePicUppyInstance from '../../hooks/useUploadProfilePicUppyInstance';
import { useFetchMembersDetailsQuery } from '../../queries/MembersDetails';
import { serializeMembersDetailsFromApi } from '../../queries/MembersDetails/serializer';
import timezones from '../../Utils/data/timezones/index.json';
import { mapTimezoneValue } from '../../Utils/date';
import { dataUrlToFile } from '../../Utils/file';
import { showErrorMessage } from '../../Utils/toast';

const createPayloadForUpdateSettings = (user: UserInfo, dataToUpdate: any) => {
  const { department, firstName, lastName, managers, reports } = user;
  return {
    department,
    firstName,
    lastName,
    managers,
    reports,
    ...dataToUpdate,
  };
};

const useSettingsControllerLogic = (
  updateUserSettings: (payload: UpdateUserInfoPayload) => void,
  updateProfilePictureSettings: (payload: UpdateUserInfoPayload) => void,
  currentUserInfo: UserInfo | null,
) => {
  const {
    models: { searchedMembers, isLoading: isMembersSearchLoading },
    operations: { onChange: onUsersSearchChange },
  } = useMembersSearch();
  const {
    models: { accordionItems },
    operations: { handleCollapse, handleSaveClick },
  } = useAccordionToggleLogic();

  const [isAutoCompletePristine, setIsAutoCompletePristine] =
    useState<boolean>(true);

  const [profilePictureUploadError, setProfilePictureUploadError] =
    useState('');

  const [isProfilePictureUploading, setProfilePictureUploading] =
    useState(false);

  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);

  const [avatarImage, setAvatarImage] = useState<string | undefined>();

  const [isAvatarSet, setAvatarSet] = useState(false);

  useEffect(() => {
    if (profilePictureUploadError !== '') {
      showErrorMessage(profilePictureUploadError);
      setProfilePictureUploadError('');
    }
  }, [currentUserInfo, profilePictureUploadError]);

  useEffect(() => {
    if (currentUserInfo?.image !== undefined && !isAvatarSet) {
      setAvatarImage(currentUserInfo.image);
      setAvatarSet(true);
    }
  }, [currentUserInfo, isAvatarSet]);

  const { createUppyInstance } = useUploadProfilePicUppyInstance(
    GET_PROFILE_PICTURE_UPLOAD_SIGNED_URL,
  );

  const settingsAvatarProps: SettingsAvatarProps = useMemo(() => {
    const removeImageHandler = () => {
      setButtonDisabled(false);
      setAvatarImage('');
    };
    const handleImageSelectedClick = async (src: string, fileName: string) => {
      const file = await dataUrlToFile(src, fileName);

      const fileExtension = fileName
        .substring(fileName.lastIndexOf('.') + 1)
        .toLowerCase();
      const allowedExtensions = ['jpg', 'jpeg', 'png', 'gif'];

      if (allowedExtensions.indexOf(fileExtension) < 0) {
        setAvatarImage('');
        setButtonDisabled(true);
      } else {
        setAvatarImage(src);
        setButtonDisabled(false);
      }

      const uppy = createUppyInstance();

      try {
        uppy.addFile({
          name: file.name,
          type: file.type,
          data: file,
        });
      } catch (err: any) {
        if (err?.isRestriction) {
          setProfilePictureUploadError(err?.message);
        } else {
          console.error(err);
        }
      }

      uppy.on('upload', () => {
        setProfilePictureUploading(true);
      });

      uppy.on('upload-success', () => {
        setProfilePictureUploading(false);
        setAvatarImage(src);
      });
    };
    const handleSaveChangesClick = async (fileName: string) => {
      const updatedData = {
        file: avatarImage,
        fileType: 'profile',
        fileName,
      };

      if (currentUserInfo) {
        const payload = createPayloadForUpdateSettings(
          currentUserInfo,
          updatedData,
        );
        updateProfilePictureSettings(payload);
        handleSaveClick(AVATAR);
        setButtonDisabled(true);
      }
    };
    return {
      avatarImage: avatarImage,
      onSaveChanges: handleSaveChangesClick,
      onImageSelected: handleImageSelectedClick,
      isLoading: isProfilePictureUploading,
      isDisabled: buttonDisabled,
      onRemoveImageClick: removeImageHandler,
    };
  }, [
    avatarImage,
    isProfilePictureUploading,
    buttonDisabled,
    createUppyInstance,
    currentUserInfo,
    updateProfilePictureSettings,
    handleSaveClick,
  ]);

  const settingsEditNameProps: SettingsStringFieldProps = useMemo(() => {
    return {
      fieldName: 'userName',
      label: 'Change name',
      validationSchema: userNameValidationSchema,
      initialValues: {
        userName: currentUserInfo?.fullName || '',
      },
      onSaveChanges: ({ userName }) => {
        const updatedFullName = userName?.split(' ') || '';
        const updatedData = {
          firstName: updatedFullName[0],
          lastName: updatedFullName[1],
        };
        if (currentUserInfo) {
          const payload = createPayloadForUpdateSettings(
            currentUserInfo,
            updatedData,
          );
          updateUserSettings(payload);
          handleSaveClick(NAME);
        }
      },
    };
  }, [currentUserInfo, handleSaveClick, updateUserSettings]);

  const settingsEditJobTitleProps: SettingsStringFieldProps = useMemo(() => {
    return {
      fieldName: 'jobTitle',
      label: 'Change job title',
      initialValues: {
        jobTitle: currentUserInfo?.jobTitle || '',
      },
      onSaveChanges: ({ jobTitle }) => {
        if (currentUserInfo) {
          const payload = createPayloadForUpdateSettings(currentUserInfo, {
            jobTitle,
          });
          updateUserSettings(payload);
          handleSaveClick(JOB_TITLE);
        }
      },
    };
  }, [currentUserInfo, handleSaveClick, updateUserSettings]);

  const settingsEditLocationProps: SettingsStringFieldProps = useMemo(() => {
    return {
      fieldName: 'location',
      label: 'Change location',
      initialValues: {
        location: currentUserInfo?.location || '',
      },
      onSaveChanges: ({ location }) => {
        if (currentUserInfo) {
          const payload = createPayloadForUpdateSettings(currentUserInfo, {
            location,
          });
          updateUserSettings(payload);
          handleSaveClick(LOCATION);
        }
      },
    };
  }, [currentUserInfo, handleSaveClick, updateUserSettings]);

  const settingsHandleProps: SettingsHandleProps = useMemo(() => {
    return {
      handle: currentUserInfo?.handle,
    };
  }, [currentUserInfo]);

  const settingsEmailProps: SettingsEmailProps = useMemo(() => {
    return {
      email: currentUserInfo?.email,
    };
  }, [currentUserInfo]);

  const settingsDepartmentProps: SettingsDropdownProps = useMemo(() => {
    const onSaveChanges = (val: SelectedItemProps[] | string | undefined) => {
      const updatedData = {
        department: val,
      };
      if (currentUserInfo) {
        const payload = createPayloadForUpdateSettings(
          currentUserInfo,
          updatedData,
        );
        updateUserSettings(payload);
        handleSaveClick(DEPARTMENT);
      }
    };
    return {
      dropdownType: 'SINGLE_SELECT',
      label: LABEL.ADD_DEPARTMENT,
      initialValue: currentUserInfo?.department || '',
      dropdownItems: UpdatedEmployeeDepartments,
      onSaveChanges,
    };
  }, [currentUserInfo, handleSaveClick, updateUserSettings]);

  const settingsTimezoneProps: SettingsDropdownProps = useMemo(() => {
    const formattedTimezones = timezones.map((timezone) => {
      return {
        value: timezone.value,
        id: timezone.key,
      };
    });
    const dropdownItemsTimezones = [
      {
        id: 'time-zone',
        items: formattedTimezones,
      },
    ];

    const onSaveChanges = (val: SelectedItemProps[] | string | undefined) => {
      if (currentUserInfo && val) {
        const filteredTimezoneDetails = formattedTimezones.filter(
          (timezone) => timezone.value === val,
        );
        if (filteredTimezoneDetails.length > 0) {
          const updatedData = {
            timeZone: filteredTimezoneDetails[0].id,
          };
          const payload = createPayloadForUpdateSettings(
            currentUserInfo,
            updatedData,
          );
          updateUserSettings(payload);
          handleSaveClick(TIME_ZONE);
        } else {
          showErrorMessage('Invalid timezone');
        }
      } else {
        showErrorMessage('Select a valid timezone');
      }
    };

    return {
      dropdownType: 'SINGLE_SELECT',
      label: LABEL.TIMEZONE,
      initialValue:
        currentUserInfo?.timeZone !== undefined
          ? mapTimezoneValue(currentUserInfo.timeZone)
          : '',
      dropdownItems: dropdownItemsTimezones,
      onSaveChanges,
    };
  }, [currentUserInfo, handleSaveClick, updateUserSettings]);

  const settingsBirthdayProps: SettingsMultiSelectDropdownProps =
    useMemo(() => {
      const onSaveChangesButtonClick = () => {
        // eslint-disable-next-line no-alert
        alert('Save Clicked');
      };
      return {
        multiSelectDropDownType: MultiSelectDropdownType.Birthday,
        isDisabled: true,
        initialValues: {
          fieldOneValue: currentUserInfo?.birthday.day?.toString() || '',
          fieldTwoValue: currentUserInfo?.birthday.month || '',
        },
        monthDropDownItems: menuItems,
        dayDropDownItems: menuItems,
        onSaveChanges: onSaveChangesButtonClick,
      };
    }, [currentUserInfo]);

  const settingsStartDateProps: SettingsMultiSelectDropdownProps =
    useMemo(() => {
      const onSaveChangesButtonClick = () => {
        // eslint-disable-next-line no-alert
        alert('Save Clicked');
      };
      return {
        multiSelectDropDownType: MultiSelectDropdownType.StartDate,
        isDisabled: true,
        initialValues: {
          fieldOneValue:
            currentUserInfo?.startDate.joiningDay?.toString() || '',
          fieldTwoValue: currentUserInfo?.startDate.joiningMonth || '',
          fieldThreeValue:
            currentUserInfo?.startDate.joiningYear?.toString() || '',
        },
        monthDropDownItems: menuItems,
        dayDropDownItems: menuItems,
        yearDropDownItems: menuItems,
        onSaveChanges: onSaveChangesButtonClick,
      };
    }, [currentUserInfo]);

  const currentUserID = useMemo(() => currentUserInfo?._id, [currentUserInfo]);

  const membersOptions: OptionsProps[] = useMemo(() => {
    const members = searchedMembers || [];
    const filteredMembers = members.map(
      ({ memberID, firstName, lastName, username, image }: IMemberDTO) => {
        const fullName = `${firstName} ${lastName}`;
        return {
          displayText: fullName,
          secondaryText: username && username.length > 0 ? `@${username}` : '',
          img: image || undefined,
          id: memberID,
          avatar: {
            img: image,
            name: fullName,
            userId: memberID,
          } as AvatarProps,
        };
      },
    );
    return filteredMembers.filter((member) => member.id !== currentUserID);
  }, [currentUserID, searchedMembers]);

  const handleAutoCompleteInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      onUsersSearchChange(value);
    },
    [onUsersSearchChange],
  );

  const handleAutoCompleteFocus = useCallback(() => {
    setIsAutoCompletePristine(true);
  }, []);

  const managerId = currentUserInfo?.managers && currentUserInfo?.managers[0];
  const isManagerFetchQueryEnabled = !!managerId;
  const { data: managerDetailsData } = useFetchMembersDetailsQuery(
    managerId ? [managerId] : undefined,
    isManagerFetchQueryEnabled,
  );

  const memoizedManager = useMemo(() => {
    if (managerDetailsData && managerDetailsData?.membersDetails.length > 0) {
      const manager = serializeMembersDetailsFromApi(
        managerDetailsData.membersDetails[0],
      );
      return {
        firstName: manager.profile.firstName,
        lastName: manager.profile.lastName,
        memberId: manager.memberId,
        profilePicture: manager.profile.image,
        username: manager.profile.username,
      };
    }

    return undefined;
  }, [managerDetailsData]);

  const settingsManagerProps: SettingsAutocompleteProps = useMemo(() => {
    let setInitialValues = null;
    if (memoizedManager !== undefined) {
      setInitialValues = {
        displayText: `${memoizedManager.firstName} ${memoizedManager.lastName}`,
        id: memoizedManager.memberId,
        secondaryText: memoizedManager.username,
      };
    }

    const initialValues = {
      coWorkers: setInitialValues,
    };
    const onSaveChanges = (values: SettingsAutocompleteValues) => {
      const { coWorkers } = values;
      const managers = [];
      if (coWorkers && !Array.isArray(coWorkers)) {
        managers.push(coWorkers?.id);
      }
      const updatedData = {
        managers,
      };
      if (currentUserInfo) {
        const payload = createPayloadForUpdateSettings(
          currentUserInfo,
          updatedData,
        );
        updateUserSettings(payload);
        handleSaveClick(MANAGER);
      }
    };
    return {
      label: LABEL.MANAGER,
      initialValues,
      autoCompleteOptions: membersOptions,
      onAutoCompleteInputChange: handleAutoCompleteInputChange,
      onAutoCompleteFocus: handleAutoCompleteFocus,
      isAutoCompleteLoading: isMembersSearchLoading,
      autoCompleteEmptyText: isAutoCompletePristine
        ? autoCompleteEmpty.PRISTINE
        : autoCompleteEmpty.TOUCHED,
      onSaveChanges,
      isMultiSelectEnable: false,
    };
  }, [
    handleAutoCompleteFocus,
    handleAutoCompleteInputChange,
    isAutoCompletePristine,
    isMembersSearchLoading,
    membersOptions,
    currentUserInfo,
    updateUserSettings,
    handleSaveClick,
    memoizedManager,
  ]);

  const reportsIds = currentUserInfo?.reports;
  const isReportsFetchQueryEnabled = !!reportsIds;
  const { data: reportsDetailsData } = useFetchMembersDetailsQuery(
    reportsIds && reportsIds.length > 0 ? reportsIds : undefined,
    isReportsFetchQueryEnabled,
  );

  const memoizedReports = useMemo(() => {
    if (reportsDetailsData?.membersDetails) {
      return reportsDetailsData?.membersDetails.map((memberDetail) => {
        const report = serializeMembersDetailsFromApi(memberDetail);
        return {
          displayText: `${report.profile.firstName} ${report.profile.lastName}`,
          id: report.memberId,
          secondaryText: report.profile.username,
        };
      });
    }
    return undefined;
  }, [reportsDetailsData]);

  const settingsReportsProps: SettingsAutocompleteProps = useMemo(() => {
    const setInitialValues = memoizedReports
      ? memoizedReports.map((coWorker: OptionsProps) => {
          return {
            displayText: coWorker.displayText,
            id: coWorker.id,
            secondaryText: coWorker.img,
          };
        })
      : [];

    const initialValues = {
      coWorkers: setInitialValues,
    };
    const onSaveChanges = (values: SettingsAutocompleteValues) => {
      const { coWorkers } = values;
      const reports = getIds(coWorkers);
      const updatedData = {
        reports,
      };
      if (currentUserInfo) {
        const payload = createPayloadForUpdateSettings(
          currentUserInfo,
          updatedData,
        );
        updateUserSettings(payload);
        handleSaveClick(`${DIRECT} ${REPORTS}`);
      }
    };
    return {
      label: LABEL.REPORTS,
      initialValues,
      autoCompleteOptions: membersOptions,
      onAutoCompleteInputChange: handleAutoCompleteInputChange,
      onAutoCompleteFocus: handleAutoCompleteFocus,
      isAutoCompleteLoading: isMembersSearchLoading,
      autoCompleteEmptyText: isAutoCompletePristine
        ? autoCompleteEmpty.PRISTINE
        : autoCompleteEmpty.TOUCHED,
      onSaveChanges,
    };
  }, [
    handleAutoCompleteFocus,
    handleAutoCompleteInputChange,
    isAutoCompletePristine,
    isMembersSearchLoading,
    membersOptions,
    currentUserInfo,
    updateUserSettings,
    handleSaveClick,
    memoizedReports,
  ]);

  const { initialValues } = settingsManagerProps;
  const managerPlaceholder = useMemo(() => {
    if (initialValues.coWorkers && !Array.isArray(initialValues.coWorkers)) {
      return initialValues.coWorkers?.displayText;
    }
    return '';
  }, [initialValues.coWorkers]);

  const { initialValues: reportsInitialValues } = settingsReportsProps;
  const reportsPlaceholder = useMemo(() => {
    if (
      reportsInitialValues.coWorkers &&
      Array.isArray(reportsInitialValues.coWorkers)
    ) {
      return reportsInitialValues.coWorkers
        .map((report: OptionsProps) => report.displayText)
        .join(', ');
    }
    return '';
  }, [reportsInitialValues.coWorkers]);

  const {
    initialValues: { fieldOneValue: birthDay, fieldTwoValue: birthdayMonth },
  } = settingsBirthdayProps;
  const birthDayPlaceholder =
    birthdayMonth && birthDay ? `${birthdayMonth} ${birthDay}` : '';

  const { initialValues: startDateValues } = settingsStartDateProps;
  const {
    fieldOneValue: joiningDay,
    fieldTwoValue: joiningMonth,
    fieldThreeValue: joiningYear,
  } = startDateValues;
  const startDatePlaceholder =
    joiningMonth && joiningDay && joiningYear
      ? `${joiningMonth} ${joiningDay}, ${joiningYear}`
      : '';

  return {
    models: {
      settingsAvatarProps,
      settingsEditNameProps,
      settingsEditJobTitleProps,
      settingsEditLocationProps,
      settingsHandleProps,
      settingsEmailProps,
      settingsDepartmentProps,
      settingsTimezoneProps,
      settingsManagerProps,
      settingsReportsProps,
      settingsStartDateProps,
      settingsBirthdayProps,
      managerPlaceholder,
      reportsPlaceholder,
      birthDayPlaceholder,
      startDatePlaceholder,
      accordionItems,
    },
    operations: {
      handleCollapse,
    },
    metadata: {},
  };
};

export default useSettingsControllerLogic;
