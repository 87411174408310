export const StringReplace = {
  pointsName: `:pointsName`,
  pointsIcon: `:pointsIcon`,
  remainingPoints: `:remainingPoints`,
  usedPoints: `:usedPoints`,
  monthlyAllowance: `:monthlyAllowance`,
  pointsRefreshDate: `:pointsRefreshDate`,
};

export const CELEBRATE_TEAMMATE_FLOW_ID = 'recognition';

export const PLAN_FEATURE_ALL_FLOW_CREATION_PERMISSION =
  'ALLOW_TO_CHANGE_FLOW_CREATION_PERMISSION';
