import { useInfiniteQuery } from 'react-query';

import { makeAPICallWithDataReturn } from '../utils';
import { GET_MAIN_FEED_ATTACHMENTS } from '../../constants/endpoints';

import { GetFeedAttachmentResponse } from '../../interfaces/Feed';

export const useGetMainFeedAttachmentsQuery = () => {
  return useInfiniteQuery<GetFeedAttachmentResponse>(
    [GET_MAIN_FEED_ATTACHMENTS],
    ({ pageParam = '' }) =>
      makeAPICallWithDataReturn(GET_MAIN_FEED_ATTACHMENTS, {
        cursor: pageParam,
        limit: 20,
      }),
    {
      staleTime: Infinity,
      refetchOnMount: 'always',
      getNextPageParam: (lastPage) =>
        lastPage.metadata.pagination?.cursor?.next || undefined,
      getPreviousPageParam: (lastPage) => {
        return lastPage.metadata.pagination.cursor.previous;
      },
    },
  );
};
