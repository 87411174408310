import React from 'react';
import ContentLoader from 'react-content-loader';

import { LoadingWrapper } from '../styles';
import { LoadingComponentProps } from '../type';
import ThemeV2 from '../../../../../componentsV2/theme';
import { ComponentStatus } from '../../../../../interfaces/component';
import ClickableSelectionOptionItem from '../../../../molecules/QuickSetup/ClickableSelectionOptionItem';
// eslint-disable-next-line max-len
import CalendarSelectDropdownBarWithRefresh from '../../../../molecules/QuickSetup/CalendarSelectDropdownBarWithRefresh';

const LoadingComponent = (props: LoadingComponentProps) => {
  const {
    selectedValue,
    dropdownMenuItems,
    onRefreshListClick,
    onDropdownItemClick,
    dropdownPlaceholderImage,
  } = props;

  return (
    <>
      <CalendarSelectDropdownBarWithRefresh
        isRefreshButtonDisabled
        selectedValue={selectedValue}
        dropdownMenuItems={dropdownMenuItems}
        onRefreshListClick={onRefreshListClick}
        onDropdownItemClick={onDropdownItemClick}
        dropdownPlaceholderImage={dropdownPlaceholderImage}
      />
      <LoadingWrapper>
        <ContentLoader
          speed={2}
          width={371}
          height={24}
          viewBox="0 371 24"
          className="contentLoader"
          data-testid="content-loader"
          backgroundColor={ThemeV2.palette.gray4}
          foregroundColor={ThemeV2.palette.gray3}
        >
          <rect x="0" y="0" rx="4" ry="4" width="371" height="24" />
        </ContentLoader>
      </LoadingWrapper>
      <ClickableSelectionOptionItem status={ComponentStatus.LOADING} />
    </>
  );
};

export default LoadingComponent;
