import React, { ReactNode, useRef, useState } from 'react';

import { StyledPopover } from './styles';
import useOnClickOutside from '../../../hooks/useOnOutsideClick';

export interface PopoverProps {
  popoverContent: ReactNode;
  referenceContent: ReactNode;
  xAxisOffset?: number;
  yAxisOffset?: number;
}

const FlowDescriptionPopover = ({
  referenceContent,
  popoverContent,
}: PopoverProps) => {
  const [openedPopover, setOpenedPopover] = useState(false);
  const popoverAnchor = useRef(null);
  const popoverComponent = useRef(null);

  const handlePopoverOpen = () => {
    setOpenedPopover(true);
  };

  const handlePopoverClose = () => {
    setOpenedPopover(false);
  };

  const handleKeypress = (event: { key: string }) => {
    if (event.key === 'Enter') {
      setOpenedPopover(true);
    }
  };

  const handlePopoverOutsideClick = () => {
    setOpenedPopover(false);
  };

  useOnClickOutside(popoverComponent, handlePopoverOutsideClick);

  return (
    <>
      <div
        role="link"
        ref={popoverAnchor}
        aria-owns="mouse-over-popover"
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        onClick={handlePopoverOpen}
        onKeyPress={handleKeypress}
        tabIndex={0}
      >
        {referenceContent}
      </div>
      <StyledPopover
        ref={popoverComponent}
        id="mouse-over-popover"
        open={openedPopover}
        anchorEl={popoverAnchor.current}
        transformOrigin={{ horizontal: 12, vertical: 8 }}
        PaperProps={{
          onMouseEnter: handlePopoverOpen,
          onMouseLeave: handlePopoverClose,
        }}
        data-testid="popover"
      >
        {popoverContent}
      </StyledPopover>
    </>
  );
};

export default FlowDescriptionPopover;
