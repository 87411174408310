import { useMutation } from 'react-query';

import { makeAPICall } from '../utils';
import { INVITE_USERS_CREATE } from '../../constants/endpoints';

interface CreateInvite {
  data: {
    email: string;
  }[];
}

export interface AddedMember {
  memberId: string;
  memberState: string;
  inviteState: string;
  email: string;
  status: string;
}

export interface InviteNewMembersResponse {
  addedMembers: AddedMember[];
}

export const useInviteUserPostQuery = () => {
  return useMutation((payload: CreateInvite) =>
    makeAPICall(INVITE_USERS_CREATE, payload),
  );
};
