import React, { useState, useCallback } from 'react';

import IdentityConfigureInvitesPage from '../../atomic/pages/IdentityConfigureInvitesPage';
import { buttonText } from '../../Utils/data/identityManager/common';
import {
  IdentityConfigureInvitesPageContainerProps,
  ApiResponseSuccess,
  IdentityManagerResult,
} from '../../interfaces/identityManager/common';
import useFetch from '../../hooks/useFetch';
import { CONFIGURE_IDENTITY_MANAGER } from '../../hooks/useFetch/endpoints';
import { IdentitySSO } from '../../Utils/identity/allowedSSOs';
import accountManagementTypes from '../../Utils/identity/accountManagementTypes';

const IdentityConfigureInvitesPageContainer = ({
  identityManager,
  result,
  initialAutoSendInvites = true,
  initialAutoApproveMembers = true,
  title,
  steps,
  activeStep,
  onBackClick,
  onCancelClick,
  onCreateAccountsSuccess,
  onCreateAccountsError,
  invitesHeading,
  invitesSubHeading,
  invitesRadioGroupOptions,
  showApprovalRadioGroup,
  approvalHeading,
  approvalSubHeading,
  approvalRadioGroupOptions,
  notifications,
  helperLink,
}: IdentityConfigureInvitesPageContainerProps) => {
  const { id } = identityManager;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  /* @ts-ignore */
  const { ssoParam } = IdentitySSO[id];
  const [isCreatingAssembly, setIsCreatingAssembly] = useState<boolean>(false);
  const [autoSendInvites, setAutoSendInvites] = useState(
    initialAutoSendInvites,
  );
  const [autoApproveMembers, setAutoApproveMembers] = useState(
    initialAutoApproveMembers,
  );
  const onConfigureIMSuccess = useCallback(
    ({ data }: ApiResponseSuccess) => {
      const { integrationDataJob } = data;
      const { id: statusJobId } = integrationDataJob;
      setIsCreatingAssembly(false);
      onCreateAccountsSuccess({
        statusJobId,
        autoSendInvites,
        autoApproveMembers,
      });
    },
    [autoApproveMembers, autoSendInvites, onCreateAccountsSuccess],
  );

  const onConfigureIMFailure = useCallback(
    (error) => {
      setIsCreatingAssembly(false);
      onCreateAccountsError(error);
    },
    [onCreateAccountsError],
  );

  const { makeRequest: configureIdentityManager } = useFetch({
    apiEndpoint: CONFIGURE_IDENTITY_MANAGER,
    onSuccess: onConfigureIMSuccess,
    onFailure: onConfigureIMFailure,
  });

  const handleInvitesRadioGroupChange = useCallback((e) => {
    const { value } = e.target;
    setAutoSendInvites(value === 'true');
  }, []);

  const handleApprovalRadioGroupChange = useCallback((e) => {
    const { value } = e.target;
    setAutoApproveMembers(value === 'true');
  }, []);
  const handleCreateAccountClick = useCallback(() => {
    const { autoApproveMembers: notNeededVariable, ...rest } = result;
    const newResult: IdentityManagerResult = { ...rest, autoSendInvites };
    if (
      result.accountManagementType !== accountManagementTypes.SELECTED_USERS
    ) {
      newResult.autoApproveMembers = autoApproveMembers;
    }
    setIsCreatingAssembly(true);
    configureIdentityManager({
      data: newResult,
      substitutions: { sso: ssoParam },
    });
  }, [
    configureIdentityManager,
    result,
    ssoParam,
    autoSendInvites,
    autoApproveMembers,
  ]);
  const createAccountButton = {
    hidden: false,
    isLoading: isCreatingAssembly,
    text: buttonText.STEP_CONFIGURE_INVITES_NEXT,
    onClick: handleCreateAccountClick,
  };
  const cancelButton = {
    hidden: false,
    disabled: isCreatingAssembly,
    text: buttonText.STEP_WHAT_IS_THIS_CANCEL,
    onClick: onCancelClick,
  };
  const backButton = {
    hidden: false,
    disabled: isCreatingAssembly,
    text: buttonText.STEP_CONFIGURE_INVITES_BACK,
    onClick: onBackClick,
  };
  return (
    <IdentityConfigureInvitesPage
      title={title}
      steps={steps}
      activeStep={activeStep}
      nextButton={createAccountButton}
      cancelButton={cancelButton}
      leftButton={backButton}
      invitesHeading={invitesHeading}
      invitesSubHeading={invitesSubHeading}
      invitesRadioGroupOptions={invitesRadioGroupOptions}
      invitesRadioGroupValue={autoSendInvites}
      onInvitesRadioGroupChange={handleInvitesRadioGroupChange}
      showApprovalRadioGroup={showApprovalRadioGroup}
      approvalHeading={approvalHeading}
      approvalSubHeading={approvalSubHeading}
      approvalRadioGroupOptions={approvalRadioGroupOptions}
      approvalRadioGroupValue={autoApproveMembers}
      onApprovalRadioGroupChange={handleApprovalRadioGroupChange}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      /* @ts-ignore */
      notifications={notifications}
      helperLink={helperLink}
    />
  );
};

export default IdentityConfigureInvitesPageContainer;
