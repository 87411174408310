import React, { useState, memo } from 'react';
import { motion } from 'framer-motion';
import {
  NEW_TASK_TITLE,
  NEW_TASK_DUE_DATE_LABEL,
  INITIAL_TASK_CHECKBOX_TOOLTIP,
  TASK_CREATED_BY,
} from '../../../../../languages/en/notebook';
import SVGIcon from '../../../../atoms/SVGIcon';
import {
  DueDateText,
  NewTaskIconWrapper,
  NewTaskText,
  TaskItemWrapper,
} from './styles';
import ThemeV2 from '../../../../../componentsV2/theme';
import {
  NotebookAnalyticsParameters,
  NotebookTask,
  NotebookTaskAnalyticsParameters,
  TaskCategories,
} from '../../../../../interfaces/notebook';
import {
  CreatedByText,
  DragIconWrapper,
  EditorFooterText,
  EditorWrapper,
  StyledCheckbox,
  StyledTiptapEditorWrapper,
} from '../styles';
// eslint-disable-next-line max-len
import { opacityAnimationProps } from '../../../../../controllers/notebook/NotebookPageController/useNotebookPageController/data';
import Tooltip from '../../../ToolTipV2';
import { generateTask } from '../../../../../controllers/notebook/NotebookPageController/utils';
import { ON, YOU } from '../../../../../languages/en/singleWords';
import { convertStringToTokenizedObject } from '../../../../../Utils/notebook';
import StyledTextareaAutosize from '../TaskItemTextArea';
import { NOTEBOOK_EVENTS } from '../../../../../Utils/analytics/constants';
import { getCurrentDateForTimeZone } from '../../../../../Utils/date';
import format from 'date-fns/format';
import { FORMAT_DATE } from '../../../../../constants/notebook';

export type CreateNewTaskItemProps = {
  type: TaskCategories;
  handleTaskCreation: (task: NotebookTask) => void;
  onTaskDataChange: (
    data: NotebookAnalyticsParameters | NotebookTaskAnalyticsParameters,
  ) => void;
  currentUserTimezone: string;
};

const CreateNewTaskItem = ({
  type,
  currentUserTimezone,
  handleTaskCreation,
  onTaskDataChange,
}: CreateNewTaskItemProps) => {
  const [isCreateMode, setCreateMode] = useState(false);

  const handleTaskItemCreation = (content: string) => {
    if (content) {
      handleTaskCreation({
        ...generateTask(type, [], currentUserTimezone),
        note: convertStringToTokenizedObject(content),
      });
    }
  };

  const onTextAreaFocusOut = (content: string) => {
    handleTaskItemCreation(content);
    setCreateMode(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onEnterKeydown = (content: string) => {
    handleTaskItemCreation(content);
    if (!content) {
      setCreateMode(false);
    }
  };

  return (
    <>
      <motion.div {...opacityAnimationProps}>
        <TaskItemWrapper
          alignItems="flex-start"
          data-testid="create-new-task"
          onClick={() => {
            setCreateMode(true);
            onTaskDataChange({
              action: NOTEBOOK_EVENTS.NOTEBOOK_TASK_CREATION,
              section: type,
            });
          }}
          key={type}
          isNewTask
        >
          <DragIconWrapper />
          {!isCreateMode && (
            <>
              <NewTaskIconWrapper>
                <SVGIcon
                  icon="union"
                  size="16px"
                  color={ThemeV2.palette.gray8}
                />
              </NewTaskIconWrapper>
            </>
          )}
          {isCreateMode && (
            <Tooltip
              toolTipComponent={
                <StyledCheckbox
                  name={`note-create`}
                  onChange={() => {}}
                  size="16px"
                  disabled
                />
              }
              position="top"
            >
              {INITIAL_TASK_CHECKBOX_TOOLTIP}
            </Tooltip>
          )}
          <EditorWrapper>
            {!isCreateMode && (
              <>
                <NewTaskText variant="body1" color="gray7">
                  {NEW_TASK_TITLE}
                </NewTaskText>
                <DueDateText variant="body3" color="gray7">
                  {NEW_TASK_DUE_DATE_LABEL[type]}
                </DueDateText>
              </>
            )}

            {isCreateMode && (
              <>
                <StyledTiptapEditorWrapper>
                  <StyledTextareaAutosize
                    isCreateItem
                    handleEnterClick={onEnterKeydown}
                    onBlur={onTextAreaFocusOut}
                    hasHoverEffect={false}
                  />
                </StyledTiptapEditorWrapper>
                <EditorFooterText>
                  <CreatedByText inline variant="body3" color="gray7">
                    {`${TASK_CREATED_BY} ${YOU.toLocaleLowerCase()} ${ON} ${format(
                      getCurrentDateForTimeZone(currentUserTimezone),
                      FORMAT_DATE,
                    )} `}
                  </CreatedByText>
                </EditorFooterText>
              </>
            )}
          </EditorWrapper>
        </TaskItemWrapper>
      </motion.div>
    </>
  );
};

export default memo(CreateNewTaskItem);
