import { WritableDraft } from 'immer/dist/types/types-external';

import { PaginationResponse } from '../interfaces';
import { FlowFeedResponse, Member, ReactionRequestUser } from './interfaces';
import { ReactionEmoji, UpdateReactionAction } from '../../../interfaces/Feed';

export const findInPaginatedFlowFeedData = (
  pages: WritableDraft<PaginationResponse<FlowFeedResponse>>[],
  id: string,
) => {
  let postIndex;

  const postFoundPage = pages.find((page) => {
    postIndex = page.data.findIndex((post) => post.responseId === id);
    return postIndex !== -1;
  });

  if (postIndex !== undefined && postFoundPage) {
    return postFoundPage.data[postIndex];
  }

  return undefined;
};

export const updatePostReactionsForFlowFeedInDraft = (
  updatedPost: WritableDraft<FlowFeedResponse>,
  payload: ReactionEmoji,
  action: UpdateReactionAction,
  user?: ReactionRequestUser,
) => {
  const index = updatedPost.reactions.findIndex(
    (reaction) => reaction.name === payload.name,
  );

  if (action === UpdateReactionAction.SET) {
    const member = { ...user } as Member;
    if (index < 0) {
      updatedPost.reactions.push({
        ...payload,
        members: [{ ...member }],
      });
    } else {
      updatedPost.reactions[index].members.push({
        ...member,
      });
    }
  } else {
    const currentReaction = updatedPost.reactions[index];
    if (currentReaction.members.length > 1) {
      currentReaction.members = currentReaction.members.filter(
        (member) => member.memberID !== user?.memberID,
      );
    } else {
      updatedPost.reactions.splice(index, 1);
    }
  }
};
