import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import useFetch from '../../hooks/useFetch';
import {
  DISCONNECT_BOT_MANAGER,
  GET_IDENTITY_MANAGER_DATA,
  GET_BOT_MANAGER_DATA,
  DISCONNECT_IDENTITY_MANAGER,
} from '../../hooks/useFetch/endpoints';
import { IdentitySSO } from '../../Utils/identity/allowedSSOs';
import IMStatus from '../../Utils/identity/status';
import {
  ConnectionStatusEnum,
  IntegrationProvidersEnum,
} from '../../screens/admin/content/settings/Integrations/interfaces';
import {
  ApiResponseSuccess,
  ApiResponseError,
} from '../../interfaces/identityManager/common';
import {
  botDisconnectModalLanguage,
  getGeneralDisconnectModalLanguage,
} from '../../Utils/data/botManager/disconnect';
import BotDisconnectModal from '../../atomic/molecules/BotDisconnectModal';
import { showErrorMessage } from '../../Utils/flashHandler';

const SSOApiEndpointDict: { [Key: string]: string } = {
  SLACK: DISCONNECT_BOT_MANAGER,
  MSTEAM: DISCONNECT_BOT_MANAGER,
  OFFICE365: DISCONNECT_IDENTITY_MANAGER,
  GSUITE: DISCONNECT_IDENTITY_MANAGER,
  ADP: DISCONNECT_IDENTITY_MANAGER,
};
export interface BotDisconnectModalContainerProps {
  ssoId: string;
  onModalClose(): void;
  disconnectIM?: boolean;
  onBotDisconnectSuccess?(response: ApiResponseSuccess): void;
  onBotDisconnectError?(error: ApiResponseError): void;
}

const BotDisconnectModalContainer = (
  props: BotDisconnectModalContainerProps,
) => {
  const {
    ssoId,
    onModalClose,
    onBotDisconnectSuccess,
    onBotDisconnectError,
    disconnectIM = false,
  } = props;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  /* @ts-ignore */
  const { ssoParam, displayName } = IdentitySSO[ssoId];
  const dispatch = useDispatch();
  const [contentBody, setContentBody] = useState<string | null>(null);
  const [imStatus, setImStatus] = useState<string | null>(null);
  const [botStatus, setBotStatus] = useState<string | null>(null);

  const handleDisconnectBotManagerSuccess = useCallback(
    (response: ApiResponseSuccess) => {
      if (onBotDisconnectSuccess) {
        onBotDisconnectSuccess(response);
      }
    },
    [onBotDisconnectSuccess],
  );
  const handleError = useCallback(
    (error) => {
      const { message } = error;
      dispatch(showErrorMessage(message));
      if (onBotDisconnectError) {
        onBotDisconnectError(error);
      }
    },
    [dispatch, onBotDisconnectError],
  );

  // TODO: Need to handle from which flow its called and
  // select endpoints and languages accordingly
  let disconnectApiToUse =
    SSOApiEndpointDict[ssoId] || DISCONNECT_IDENTITY_MANAGER;
  if (disconnectIM) {
    disconnectApiToUse = DISCONNECT_IDENTITY_MANAGER;
  }

  const { makeRequest: disconnectBotManager } = useFetch({
    apiEndpoint: disconnectApiToUse,
    onSuccess: handleDisconnectBotManagerSuccess,
    onFailure: handleError,
  });

  const handleGetIdentityManagerDataSuccess = useCallback(
    ({ data }: ApiResponseSuccess) => {
      const status = data?.identityManagers.find(
        (im: { id: string }) => im.id === ssoId,
      )?.status;
      setImStatus(status);
    },
    [ssoId],
  );

  const { makeRequest: getIdentityManagerStatus } = useFetch({
    apiEndpoint: GET_IDENTITY_MANAGER_DATA,
    onSuccess: handleGetIdentityManagerDataSuccess,
    onFailure: handleError,
  });

  const handleGetBotManagerDataSuccess = useCallback(
    ({ data }: ApiResponseSuccess) => {
      const status = data?.bots.find(
        (im: { id: string }) => im.id === ssoId,
      )?.status;
      setBotStatus(status);
    },
    [ssoId],
  );

  const { makeRequest: getBotManagerStatus } = useFetch({
    apiEndpoint: GET_BOT_MANAGER_DATA,
    onSuccess: handleGetBotManagerDataSuccess,
    onFailure: handleError,
  });

  useEffect(() => {
    getIdentityManagerStatus();
    getBotManagerStatus();
  }, [getBotManagerStatus, getIdentityManagerStatus]);

  const handlePrimaryButtonOnClick = useCallback(() => {
    disconnectBotManager({ substitutions: { sso: ssoParam } });
  }, [disconnectBotManager, ssoParam]);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  /* @ts-ignore */
  let language = botDisconnectModalLanguage[ssoId];
  if (disconnectIM && ssoId === IntegrationProvidersEnum.SLACK) {
    language = botDisconnectModalLanguage['SLACK-IM'];
  }
  if (!language) {
    language = getGeneralDisconnectModalLanguage(displayName);
  }

  const isLoading = imStatus === null || botStatus === null;

  if (
    !isLoading &&
    contentBody === null &&
    ssoId === IntegrationProvidersEnum.SLACK
  ) {
    if (disconnectIM) {
      setContentBody(language.contentBodyIMOnly);
    } else {
      setContentBody(language.contentBodyBotOnly);
    }
  } else if (!isLoading && contentBody === null) {
    if (
      imStatus === IMStatus.CONNECTED &&
      botStatus === ConnectionStatusEnum.CONNECTED
    ) {
      setContentBody(language.contentBodyIMAndBot);
    } else if (imStatus === IMStatus.CONNECTED) {
      setContentBody(language.contentBodyIMOnly);
    } else if (botStatus === ConnectionStatusEnum.CONNECTED) {
      setContentBody(language.contentBodyBotOnly);
    }
  }

  return (
    <BotDisconnectModal
      isLoading={isLoading}
      heading={language.heading}
      contentHeading={language.contentHeading}
      contentBody={contentBody || ''}
      secondaryButtonText={language.secondaryButtonText}
      primaryButtonText={language.primaryButtonText}
      onModalClose={onModalClose}
      onPrimaryButtonOnClick={handlePrimaryButtonOnClick}
      onSecondaryButtonOnClick={onModalClose}
    />
  );
};

export default BotDisconnectModalContainer;
