import InfiniteScroll from 'react-infinite-scroll-component';
import React from 'react';
import styled from 'styled-components';

import Button from '../../atoms/Button';
import { IMemberDTO, MemberRole } from '../../../interfaces/member';
import MembersListEntry from '../MembersListEntry';
import MembersListEntryLoader from '../MembersListEntry/Loader';
import {
  SHOW_MORE,
  SHOW_LESS,
} from '../../../languages/en/home/mainFeedDrawer';

const FooterWrapper = styled.footer`
  padding-top: 16px;
`;

const InfiniteScrollWrapper = styled.div`
  max-height: 380px;
  overflow-y: auto;
`;
export interface MembersListProps {
  hasMoreMembersToFetch: boolean;
  isExpanded: boolean;
  fetchMoreMembers: () => void;
  members: IMemberDTO[];
  onExpansionButtonClick: () => void;
  totalNumberOfMembers: number;
  handleEntryClick: (firstName: string, memberId: string) => void;
}

const MembersList = ({
  fetchMoreMembers,
  hasMoreMembersToFetch,
  isExpanded,
  members,
  onExpansionButtonClick,
  totalNumberOfMembers,
  handleEntryClick,
}: MembersListProps) => {
  const membersToDisplay = isExpanded ? members : members.slice(0, 9);

  return (
    <>
      <InfiniteScrollWrapper id="scrollable-div">
        <InfiniteScroll
          dataLength={members.length}
          hasMore={hasMoreMembersToFetch}
          loader={<MembersListEntryLoader />}
          next={fetchMoreMembers}
          scrollableTarget="scrollable-div"
        >
          {membersToDisplay.map(
            ({ firstName, image, lastName, memberID, role, memberState }) => (
              <MembersListEntry
                fullName={`${firstName} ${lastName}`}
                isAdmin={
                  role.includes(MemberRole.Admin) ||
                  role.includes(MemberRole.Owner)
                }
                id={memberID}
                key={memberID}
                onClick={() => handleEntryClick(firstName, memberID)}
                profilePicture={image || ''}
                memberState={memberState}
              />
            ),
          )}
        </InfiniteScroll>
      </InfiniteScrollWrapper>

      {totalNumberOfMembers > 10 && (
        <FooterWrapper>
          <Button onClick={onExpansionButtonClick} size="small" variant="text">
            {isExpanded ? SHOW_LESS : SHOW_MORE}
          </Button>
        </FooterWrapper>
      )}
    </>
  );
};

const MemoizedMembersList = React.memo(MembersList);
MemoizedMembersList.displayName = 'MembersList';
export default MemoizedMembersList;
