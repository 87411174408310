import React from 'react';
import parse from 'html-react-parser';

import { replaceAll } from '../../../Utils/text';
import {
  RewardSuccessContentList,
  RewardSuccessContentRedeemLink,
  StyledBarcode,
} from '../../../atomic/organism/RedeemSelectModal/styles';

interface CredentialList {
  label: string;
  value: string;
}

enum CredentialType {
  Url = 'url',
  Barcode = 'barcode',
}

const renderTextReward = (credentialList: CredentialList) => (
  <RewardSuccessContentRedeemLink variant="body2">
    {credentialList.label}&nbsp;:&nbsp;{credentialList.value}
  </RewardSuccessContentRedeemLink>
);
const renderURLReward = (credentialList: CredentialList) => (
  <RewardSuccessContentRedeemLink variant="body2">
    {credentialList.label}&nbsp;:&nbsp;
    <a href={credentialList.value} target="_blank" rel="noopener noreferrer">
      Click here
    </a>
  </RewardSuccessContentRedeemLink>
);

const renderBarcodeReward = (credentialList: CredentialList) => (
  <RewardSuccessContentRedeemLink variant="body2">
    {credentialList.label}&nbsp;:
    <StyledBarcode alt="barcode" src={credentialList.value} />
  </RewardSuccessContentRedeemLink>
);

const renderInstructions = (redemptionInstructions: string) => (
  <RewardSuccessContentList>
    {parse(replaceAll(redemptionInstructions, '<a ', "<a target='_blank' "))}
  </RewardSuccessContentList>
);

export const parseOrder = (orderInfo: string) => {
  const orderJSON = JSON.parse(orderInfo);
  if (orderJSON && orderJSON.reward) {
    const { reward } = orderJSON;
    let type = '';
    if (
      reward &&
      reward.credentialList &&
      reward.credentialList[0] &&
      reward.credentialList[0].type
    ) {
      type = reward.credentialList[0].type;
    }

    if (type === CredentialType.Url) {
      return (
        <>
          {renderURLReward(reward.credentialList[0])}
          {renderInstructions(reward.redemptionInstructions)}
        </>
      );
    }
    if (type === CredentialType.Barcode) {
      return (
        <>
          {renderBarcodeReward(reward.credentialList[0])}
          {renderInstructions(reward.redemptionInstructions)}
        </>
      );
    }
    if (type === '') {
      return <>{renderInstructions(reward.redemptionInstructions)}</>;
    }

    return (
      <>
        {renderTextReward(reward.credentialList[0])}
        {renderInstructions(reward.redemptionInstructions)}
      </>
    );
  }
  return <></>;
};
