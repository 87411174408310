import styled from 'styled-components';

import GeneralSettingsAccordion from '../../atomic/organism/GeneralSettingsAccordion';

import useSettingsControllerLogic from './useSettingsControllerLogic';
import useSettingsControllerModel from './useSettingsControllerModel';

import React from 'react';
import LoadingPulse from '../../atomic/atoms/LoadingPulse';

const SettingsControllerWrapper = styled.div`
  max-width: 642px;
  margin: 0 auto;
`;

const SettingsController = () => {
  const {
    models: { currentUserInfo },
    operations: { updateUserSettings, updateProfilePictureSettings },
    metadata: { isCurrentUserInfoLoading },
  } = useSettingsControllerModel();

  const {
    models: {
      settingsAvatarProps,
      settingsEditNameProps,
      settingsEditJobTitleProps,
      settingsEditLocationProps,
      settingsHandleProps,
      settingsEmailProps,
      settingsDepartmentProps,
      settingsTimezoneProps,
      settingsManagerProps,
      settingsReportsProps,
      settingsStartDateProps,
      settingsBirthdayProps,
      managerPlaceholder,
      reportsPlaceholder,
      birthDayPlaceholder,
      startDatePlaceholder,
      accordionItems,
    },
    operations: { handleCollapse },
  } = useSettingsControllerLogic(
    updateUserSettings,
    updateProfilePictureSettings,
    currentUserInfo,
  );

  if (isCurrentUserInfoLoading) {
    return <LoadingPulse isLoading />;
  }
  return (
    <SettingsControllerWrapper>
      <GeneralSettingsAccordion
        settingsAvatarProps={settingsAvatarProps}
        settingsEditNameProps={settingsEditNameProps}
        settingsEditJobTitleProps={settingsEditJobTitleProps}
        settingsEditLocationProps={settingsEditLocationProps}
        settingsHandleProps={settingsHandleProps}
        settingsEmailProps={settingsEmailProps}
        settingsDepartmentProps={settingsDepartmentProps}
        settingsTimezoneProps={settingsTimezoneProps}
        settingsManagerProps={settingsManagerProps}
        settingsReportsProps={settingsReportsProps}
        settingsStartDateProps={settingsStartDateProps}
        settingsBirthdayProps={settingsBirthdayProps}
        managerPlaceholder={managerPlaceholder}
        reportsPlaceholder={reportsPlaceholder}
        birthDayPlaceholder={birthDayPlaceholder}
        startDatePlaceholder={startDatePlaceholder}
        accordionItems={accordionItems}
        handleCollapse={handleCollapse}
      />
    </SettingsControllerWrapper>
  );
};

export default SettingsController;
