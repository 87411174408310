import { useEffect, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import shallow from 'zustand/shallow';
import useLayoutStore from '../../../stores/layoutStore';
import { V2_USER_PROFILE } from '../../../constants/routes';

import { ProfileViewControllerProps } from './interfaces';
import { useProfileInfoFetchQuery } from '../../../queries/Profile';
import { useFetchMembersDetailsQuery } from '../../../queries/MembersDetails';
import { useMemberRecognitionQuery } from '../../../queries/Feed/MemberRecognition';
import { serializeMembersDetailsFromApi } from '../../../queries/MembersDetails/serializer';
import { MemberFromAPI } from '../../../interfaces/UserProfile';

const mapMembersFromApiToMembersDetails = (membersFromApi: MemberFromAPI[]) => {
  if (!membersFromApi || !membersFromApi.length) {
    return [];
  }

  return membersFromApi.map((memberFromApi) =>
    serializeMembersDetailsFromApi(memberFromApi),
  );
};

const useProfileViewController = ({
  firstName,
  memberId,
}: ProfileViewControllerProps) => {
  const {
    showRightAside,
    setSelectedMember,
    removeSelectedMember,
    toggleRightAsideOpen,
  } = useLayoutStore(
    (state) => ({
      showRightAside: state.showRightAside,
      setSelectedMember: state.setSelectedMember,
      removeSelectedMember: state.removeSelectedMember,
      toggleRightAsideOpen: state.toggleRightAsideOpen,
    }),
    shallow,
  );

  const routeMatch = useRouteMatch(V2_USER_PROFILE);
  const isOnProfileRoute = !!routeMatch;

  const { data: ownProfileData } = useProfileInfoFetchQuery();
  const isCurrentUser =
    !memberId || memberId === ownProfileData?.member.memberId;
  const isFetchMembersDetailsQueryEnabled = !isCurrentUser;

  const {
    data: membersDetailsFetchData,
    isError: isMembersDetailsError,
    isLoading: isMembersDetailsFetchQueryLoading,
  } = useFetchMembersDetailsQuery(
    memberId ? [memberId] : undefined,
    isFetchMembersDetailsQueryEnabled,
  );

  const memoizedMemberDetails = useMemo(() => {
    if (isFetchMembersDetailsQueryEnabled) {
      if (
        membersDetailsFetchData?.membersDetails &&
        membersDetailsFetchData?.membersDetails.length > 0
      ) {
        return serializeMembersDetailsFromApi(
          membersDetailsFetchData.membersDetails[0],
        );
      }
      return null;
    }

    return ownProfileData?.member
      ? serializeMembersDetailsFromApi(ownProfileData.member)
      : null;
  }, [
    isFetchMembersDetailsQueryEnabled,
    membersDetailsFetchData,
    ownProfileData,
  ]);

  const managerId = memoizedMemberDetails?.managerIds[0];
  const isManagerFetchQueryEnabled = !!managerId;
  const {
    data: managerDetailsData,
    isError: isManagerDetailsError,
    isLoading: isManagerFetchQueryLoading,
  } = useFetchMembersDetailsQuery(
    managerId ? [managerId] : undefined,
    isManagerFetchQueryEnabled,
  );

  const directReportIds = memoizedMemberDetails?.reportIds;
  const isDirectReportsFetchQueryEnabled = !!directReportIds?.length;
  const {
    data: directReportsDetailsData,
    isError: isDirectReportsDetailsError,
    isLoading: isDirectReportsFetchQueryLoading,
  } = useFetchMembersDetailsQuery(
    directReportIds && directReportIds.length > 0 ? directReportIds : undefined,
    isDirectReportsFetchQueryEnabled,
  );

  const memoizedManager = useMemo(() => {
    if (
      managerDetailsData?.membersDetails &&
      managerDetailsData?.membersDetails.length > 0
    ) {
      const manager = serializeMembersDetailsFromApi(
        managerDetailsData.membersDetails[0],
      );
      return {
        firstName: manager.profile.firstName,
        lastName: manager.profile.lastName,
        memberId: manager.memberId,
        profilePicture: manager.profile.image,
      };
    }

    return undefined;
  }, [managerDetailsData]);

  const {
    data: memberRecognitionCount,
    isLoading: isMemberRecognitionCountLoading,
    error: isMemberRecognitionCountError,
  } = useMemberRecognitionQuery(memberId);

  useEffect(() => {
    if (
      !firstName &&
      membersDetailsFetchData &&
      membersDetailsFetchData.membersDetails.length > 0
    ) {
      setSelectedMember({
        memberId,
        firstName: membersDetailsFetchData.membersDetails[0].profile.firstName,
      });
    }
  }, [firstName, memberId, membersDetailsFetchData, setSelectedMember]);

  const handleRemoveSelectedMember = () => {
    if (!showRightAside) {
      toggleRightAsideOpen();
      removeSelectedMember();
    } else {
      removeSelectedMember();
    }
  };

  const isError =
    isMembersDetailsError ||
    isManagerDetailsError ||
    isDirectReportsDetailsError ||
    isMemberRecognitionCountError;

  const areQueriesLoading =
    isMembersDetailsFetchQueryLoading ||
    isDirectReportsFetchQueryLoading ||
    isManagerFetchQueryLoading ||
    isMemberRecognitionCountLoading;

  return {
    areQueriesLoading,
    isOnProfileRoute,
    memberDetails: memoizedMemberDetails,
    setSelectedMemberProfile: setSelectedMember,
    directReportsDetails: directReportsDetailsData?.membersDetails
      ? mapMembersFromApiToMembersDetails(
          directReportsDetailsData.membersDetails,
        )
      : [],
    memberRecognitionCount,
    manager: memoizedManager,
    isError,
    isCurrentUser,
    toggleRightAsideOpen,
    removeSelectedMember: handleRemoveSelectedMember,
  };
};

export default useProfileViewController;
