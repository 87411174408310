// https://joinassembly.atlassian.net/wiki/spaces/AS/pages/1220214813/Block+-+File+upload
export const defaultAllowedFileTypes = [
  '.jpg',
  '.jpeg',
  '.png',
  '.svg',
  '.gif',
  '.pdf',
  '.doc',
  '.docx',
  '.ppt',
  '.pptx',
  '.pps',
  '.ppsx',
  '.xls',
  '.xlsx',
  '.csv',
  '.txt',
  '.odt',
  '.zip',
  '.mp3',
  '.m4a',
  '.ogg',
  '.wav',
  '.mp4',
  '.m4v',
  '.mov',
  '.wmv',
  '.avi',
  '.mpg',
  '.ogv',
  '.3gp',
  '.3g2',
];
