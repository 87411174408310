import React from 'react';

import TriggerConfigureSettings from '../../../../atomic/organism/TriggerConfigureSettings';

import { ScheduleDateTimeControllerTypes } from './types';
import useScheduleDateTimeController from './useScheduleDateTimeController';

const ScheduleDateTimeController = (props: ScheduleDateTimeControllerTypes) => {
  const { models } = useScheduleDateTimeController(props);
  const {
    flowStartProps,
    flowRecurProps,
    flowDeadlineProps,
    flowEndProps,
    flowShortcutConfigurationProps,
    flowTimezoneProps,
    customRecurrenceProps,
  } = models;

  return (
    <div>
      <TriggerConfigureSettings
        triggerType="SCHEDULED"
        flowStartProps={flowStartProps}
        flowRecurProps={flowRecurProps}
        flowDeadlineProps={flowDeadlineProps}
        flowEndProps={flowEndProps}
        flowShortcutConfigurationProps={flowShortcutConfigurationProps}
        flowTimezoneProps={flowTimezoneProps}
        customRecurrenceProps={customRecurrenceProps}
      />
    </div>
  );
};

export default ScheduleDateTimeController;
