import React from 'react';

import { StyledErrorStateTemplate } from '../styles';
import ErrorImage from '../../../../../img/error.svg';
import { quickSetupMeetingListScreen } from '../../../../../languages/en/quickSetup';

const ErrorComponent = () => (
  <StyledErrorStateTemplate
    image={ErrorImage}
    heading={quickSetupMeetingListScreen.errorComponent.heading}
    subHeading={quickSetupMeetingListScreen.errorComponent.subHeading}
  />
);

export default ErrorComponent;
