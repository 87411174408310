import React, { useState, useMemo, useCallback, useEffect } from 'react';

import {
  GIFT_CARD_REWARDS_SEARCH_PLACEHOLDER,
  GIFT_CARD_REWARDS_FILTER_PLACEHOLDER,
  REWARDS_SORT_PLACEHOLDER,
} from '../../../languages/en/rewards';
import { giftCardsSortByMenuItems } from '../../../Utils/data/rewards';
import ThemeV2 from '../../../componentsV2/theme';
import { filterByCountry, getCountriesMenu } from '../../../Utils/rewards';
import { getCountryCode } from '../../../Utils/admin/rewards';
import {
  TextFieldType,
  TextFieldProps,
} from '../../../atomic/molecules/TextField_V2/interface';
import { GiftCardReward } from '../../../queries/Rewards/interfaces';
import useStringSearch from '../../../hooks/useSearchString';

interface GiftCardRewardsComponentProps {
  rewards: GiftCardReward[];
  onSortChange: (value: unknown) => void;
  defaultCountry: string;
  resetCurrentPage: () => void;
}

const GiftCardControllerLogic = (props: GiftCardRewardsComponentProps) => {
  const { rewards, onSortChange, defaultCountry, resetCurrentPage } = props;

  const filteredByDefaultCountry = filterByCountry({
    arr: rewards,
    countryCode: getCountryCode(defaultCountry),
  });

  const defaultCountryFilter =
    filteredByDefaultCountry.length === 0 ? '' : defaultCountry;

  const [searchValue, setSearchValue] = useState('');
  const [filterValue, setFilterValue] = useState(defaultCountryFilter);
  const [sortValue, setSortValue] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedReward, setSelectedReward] = useState<
    GiftCardReward | undefined
  >();

  let rewardsToDisplay = [...rewards];

  const { models: searchModels, operations: searchOperations } =
    useStringSearch({ data: rewardsToDisplay, keys: ['brandName'] });

  const { searchResult } = searchModels;

  useEffect(() => {
    searchOperations.search(searchValue);
  }, [searchValue, searchOperations]);

  useEffect(() => {
    setFilterValue(defaultCountryFilter);
  }, [defaultCountryFilter]);

  const onSearchChangeHandler = useCallback(
    (value: React.SetStateAction<string>) => {
      resetCurrentPage();
      setSearchValue(value);
    },
    [resetCurrentPage],
  );

  const onFilterChangeHandler = useCallback(
    (value: React.SetStateAction<string>) => {
      resetCurrentPage();
      setFilterValue(value);
    },
    [resetCurrentPage],
  );

  const onSortChangeHandler = useCallback(
    (value: React.SetStateAction<string>, id?: string | number) => {
      if (value !== sortValue) {
        resetCurrentPage();
        setSortValue(value);
        onSortChange(id);
      }
    },
    [onSortChange, resetCurrentPage, sortValue],
  );

  const countriesMenu = useMemo(() => {
    return getCountriesMenu(rewards, defaultCountry);
  }, [rewards, defaultCountry]);

  const searchProps: TextFieldProps = useMemo(() => {
    return {
      placeholder: GIFT_CARD_REWARDS_SEARCH_PLACEHOLDER,
      type: TextFieldType.Text,
      value: searchValue,
      onChange: onSearchChangeHandler,
      icon: 'search',
    };
  }, [onSearchChangeHandler, searchValue]);

  const filterProps: TextFieldProps = useMemo(() => {
    return {
      placeholder: GIFT_CARD_REWARDS_FILTER_PLACEHOLDER,
      type: TextFieldType.Text,
      icon: 'filter',
      value: filterValue,
      onChange: onFilterChangeHandler,
      dropdownItems: countriesMenu,
      isEditable: true,
      inputIconColor: ThemeV2.palette.gray9,
      hasDropdownFilter: true,
    };
  }, [countriesMenu, filterValue, onFilterChangeHandler]);

  const sortProps: TextFieldProps = useMemo(() => {
    return {
      placeholder: REWARDS_SORT_PLACEHOLDER,
      type: TextFieldType.Text,
      icon: 'sort',
      value: sortValue,
      onChange: onSortChangeHandler,
      dropdownItems: giftCardsSortByMenuItems,
      isEditable: false,
      inputIconColor: ThemeV2.palette.gray9,
    };
  }, [sortValue, onSortChangeHandler]);

  // search logic
  rewardsToDisplay = searchResult;

  const handleOnRewardSelect = useCallback(
    (id) => {
      const currentReward = rewards.find((reward) => reward.brandKey === id);
      if (currentReward) {
        setIsModalOpen(true);
        setSelectedReward(currentReward);
      }
    },
    [rewards],
  );

  const onCloseRedeemModal = useCallback(() => {
    setIsModalOpen(false);
    setSelectedReward(undefined);
  }, []);

  if (filterValue) {
    rewardsToDisplay = filterByCountry({
      arr: rewardsToDisplay,
      countryCode: getCountryCode(filterValue),
    });
  }

  return {
    searchProps,
    filterProps,
    sortProps,
    rewardsToDisplay,
    handleOnRewardSelect,
    isModalOpen,
    selectedReward,
    onCloseRedeemModal,
  };
};

export default GiftCardControllerLogic;
