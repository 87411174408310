import React, { useEffect, useMemo } from 'react';

import { useHistory, useLocation, useParams } from 'react-router-dom';
import shallow from 'zustand/shallow';

import useLayoutStore from '../../../stores/layoutStore';

import { getTabComponents } from './utils';
import { StyledTabComponent } from './styles';
import { useFeatureSplit } from '../../../hooks/useFeatureSplit';

import {
  SplitNames,
  TreatmentTypes,
} from '../../../hooks/useSplitSdkConfig/constants';

import { V2_HOME } from '../../../constants/routes';
import { useProfileInfoFetchQuery } from '../../../queries/Profile';
import { useFetchFlowDetailsQuery } from '../../../queries/Flows/Feed';
import useFlowBuilderStore from '../../../stores/flowBuilderStore';
import { shareSheetModalOpenSelector } from '../../../stores/flowBuilderStore/selectors';
import FlowsShareSheetController from '../../flows/FlowsShareSheetController';

const FlowsBuilderTabsController = () => {
  const isShareSheetModalOpen = useFlowBuilderStore(
    shareSheetModalOpenSelector,
  );

  const history = useHistory();
  const location = useLocation();

  const { flowId } = useParams<{ flowId: string }>();

  const { data: profileInfo, isFetching: isFetchingProfileInfo } =
    useProfileInfoFetchQuery();
  const { data: flowDetails, isFetching: isFetchingFlowDetails } =
    useFetchFlowDetailsQuery(flowId);

  useEffect(() => {
    if (profileInfo && flowDetails) {
      if (profileInfo.member.memberId !== flowDetails.data.owner.memberID) {
        history.push(V2_HOME);
      }
    }
  }, [profileInfo, flowDetails]);

  const { treatment: flowsInsightsTreatment } = useFeatureSplit(
    SplitNames.FLOWS_INSIGHTS,
  );

  const { isLeftAsideOpen, toggleLeftAsideOpen } = useLayoutStore(
    (state) => ({
      isLeftAsideOpen: state.isLeftAsideOpen,
      toggleLeftAsideOpen: state.toggleLeftAsideOpen,
    }),
    shallow,
  );

  useEffect(() => {
    if (isLeftAsideOpen && location.pathname.includes('editor')) {
      toggleLeftAsideOpen();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const tabComponentProps = useMemo(
    () =>
      getTabComponents(flowId, flowsInsightsTreatment === TreatmentTypes.ON),
    [flowId, flowsInsightsTreatment],
  );

  return (
    <>
      {isShareSheetModalOpen && <FlowsShareSheetController />}
      {!isFetchingFlowDetails && !isFetchingProfileInfo && (
        <div id="sisenseApp">
          <StyledTabComponent {...tabComponentProps} isTabPositionFixed />
        </div>
      )}
    </>
  );
};

export default FlowsBuilderTabsController;
