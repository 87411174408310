import React from 'react';
import theme from '../../../../componentsV2/theme';
import { flowSelectTooltipText } from '../../../../languages/en/quickSetup';
import { PEOPLE, PERSON } from '../../../../languages/en/singleWords';
import { Flex } from '../../../../Utils/styles/display';
import Body from '../../../atoms/Body';
import SVGIcon from '../../../atoms/SVGIcon';
import Radio from '../../../atoms/Radio';
import {
  CardTitle,
  HelperText,
  ParticipantsCountText,
  StyledPopover,
  Wrapper,
} from './styles';
import { ClickableSelectionOptionItemProps } from './types';
import ThemeV2 from '../../../../componentsV2/theme';
import { ComponentStatus } from '../../../../interfaces/component';
import ContentLoader from 'react-content-loader';

const ClickableSelectionOptionItem = (
  props: ClickableSelectionOptionItemProps,
) => {
  switch (props.status) {
    case ComponentStatus.LOADED:
      return (
        <Wrapper
          className={props.className}
          onClick={props.onClick}
          data-testid="selection-option--item"
          isChecked={props.isChecked || false}
        >
          <Flex flexDirection="column" alignItems="start">
            <Flex>
              <CardTitle variant="body1Medium">{props.title}</CardTitle>
              {props.isStared && (
                <StyledPopover
                  popoverContent={
                    <Body variant="body3" color="gray1">
                      {flowSelectTooltipText}
                    </Body>
                  }
                  popoverPosition="top"
                  elementPadding="4px 8px"
                  referenceContent={
                    <SVGIcon
                      icon="star"
                      size="16px"
                      color={
                        props.isChecked
                          ? theme.palette.geekBlue6
                          : theme.palette.sunriseYellow6
                      }
                    ></SVGIcon>
                  }
                />
              )}
            </Flex>
            <Flex>
              <HelperText variant="body3" inline>
                {props.helperText}
              </HelperText>
              <SVGIcon icon="people" size="16px"></SVGIcon>
              <ParticipantsCountText variant="body3" inline>
                {props.participantsCount === 0
                  ? `1 ${PERSON}`
                  : `${props.participantsCount} ${PEOPLE}`}
              </ParticipantsCountText>
            </Flex>
          </Flex>
          <Flex>
            <Radio checked={props.isChecked}></Radio>
          </Flex>
        </Wrapper>
      );
    default:
      return (
        <Wrapper
          className={props.className}
          data-testid="selection-option--item"
          isChecked={false}
          isLoading={true}
        >
          <ContentLoader
            speed={2}
            width={2510}
            height={390}
            viewBox="0 2510 390"
            backgroundColor={ThemeV2.palette.gray4}
            foregroundColor={ThemeV2.palette.gray3}
            className="contentLoader"
            data-testid="content-loader"
          >
            <rect x="16" y="20" rx="4" ry="4" width="90" height="17" />
            <rect x="16" y="45" rx="4" ry="4" width="268" height="17" />
            <circle cx="475" cy="38" r="10" />
          </ContentLoader>
        </Wrapper>
      );
  }
};

export default ClickableSelectionOptionItem;
