import React, { MouseEvent, useCallback, useMemo, useState } from 'react';
import { IconButton, PopoverContainer } from './styles';
import { ProfileButtonProps } from './interface';
import { SecondaryPanel } from '../../../interfaces/Layout';
import SVGIcon from '../../atoms/SVGIcon';
import Avatar from '../../atoms/Avatar';
import Popover from '@material-ui/core/Popover';
import ProfilePopover from '../../molecules/ProfilePopover';
import { getFullName } from '../../../Utils/user';
import ThemeV2 from '../../../componentsV2/theme';

const ProfileButton = (props: ProfileButtonProps) => {
  const {
    currentUserId,
    currentUserProfile,
    isCurrentUserAdmin,
    hideRewardsLink,
    onViewProfileClick,
    rewardsProps,
  } = props;

  const [isProfileOpen, setIsProfileOpen] = React.useState<boolean>(false);
  const [popoverAnchorEl, setPopoverAnchorEl] = useState<HTMLLIElement | null>(
    null,
  );

  const openProfilePopover = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      /* @ts-ignore-next-line */
      setPopoverAnchorEl(event.currentTarget);
      setIsProfileOpen(!isProfileOpen);
    },
    [isProfileOpen],
  );

  const closeProfile = useCallback(() => {
    setIsProfileOpen(false);
  }, []);

  const currentUserFullName = useMemo(
    () => getFullName(currentUserProfile),
    [currentUserProfile],
  );

  const currentProfileImage = useMemo(() => {
    return currentUserProfile.image;
  }, [currentUserProfile.image]);

  const widthCheck = window.matchMedia('(min-width: 769px)');

  const ProfileContent = useMemo(
    () => (
      <PopoverContainer width={widthCheck.matches ? '232px' : '100vw'}>
        <ProfilePopover
          img={currentProfileImage}
          onProfilePopoverClick={onViewProfileClick}
          userName={currentUserFullName}
          userId={currentUserId}
          isCurrentUserAdmin={isCurrentUserAdmin}
          hideRewardsLink={hideRewardsLink}
          rewardsProps={rewardsProps}
          widthCheck={widthCheck.matches}
          closePopover={closeProfile}
        />
      </PopoverContainer>
    ),
    [
      widthCheck.matches,
      currentProfileImage,
      onViewProfileClick,
      currentUserFullName,
      currentUserId,
      isCurrentUserAdmin,
      hideRewardsLink,
      rewardsProps,
      closeProfile,
    ],
  );

  return (
    <>
      <IconButton
        type="button"
        onClick={openProfilePopover}
        data-id={SecondaryPanel.PROFILE}
        aria-label={SecondaryPanel.PROFILE}
        isProfileOpen={isProfileOpen}
      >
        <Avatar
          img={currentUserProfile.image}
          name={currentUserFullName}
          userId={currentUserId}
          size="32px"
        />
        <SVGIcon
          color={ThemeV2.palette.gray8}
          icon="caret-rounded"
          rotate={isProfileOpen ? 180 : 0}
        />
      </IconButton>
      <Popover
        PaperProps={{ style: { maxWidth: '100%', maxHeight: '100%' } }}
        open={isProfileOpen}
        onClose={closeProfile}
        anchorEl={popoverAnchorEl}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: widthCheck.matches ? -8 : -16,
        }}
        marginThreshold={0}
      >
        {ProfileContent}
      </Popover>
    </>
  );
};

export default ProfileButton;
