import React from 'react';
import { FlowRequestTemplateCardProps } from './type';
import Popover from '../../molecules/Popover';
import Avatar from '../../atoms/Avatar';
import {
  AvatarText,
  AvatarWrapper,
  StyledCardFooter,
  StyledButton,
  StyledFlowsTemplateCard,
  StyledHeading,
  TruncateWrapper,
  CardHeader,
  CardBody,
} from './styles';
import useHover from '../../../hooks/useHover';
import { PREVIEW } from '../../../languages/en/singleWords';
import Body from '../../atoms/Body';
import {
  StatsContainer,
  StatsWrapper,
  StyledSVGIcon,
  StyledBody,
} from '../FlowsToDoCard/styles';

const FlowRequestTemplateCard = (props: FlowRequestTemplateCardProps) => {
  const {
    emoticon,
    color,
    title,
    className,
    onPreviewFlowClick,
    avatarProps,
    canShowParticipants,
    noOfParticipants,
  } = props;

  const {
    model: { isHovering },
    operations: {
      handlePopoverOpen: onMouseEnter,
      handlePopoverClose: onMouseLeave,
    },
  } = useHover();

  const cardTitleRef = React.useRef<HTMLDivElement>(null);

  const isHeadingTruncated =
    cardTitleRef &&
    cardTitleRef.current &&
    cardTitleRef.current.offsetWidth < cardTitleRef.current.scrollWidth;

  return (
    <StyledFlowsTemplateCard
      className={className}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      data-testid="flowTemplateCard"
      onClick={onPreviewFlowClick}
    >
      <CardHeader background={color}>{emoticon}</CardHeader>
      <CardBody canShowParticipants={canShowParticipants}>
        {title && (
          <Popover
            popoverContent={<Body variant="body3Medium">{title}</Body>}
            popoverPosition="top"
            referenceContent={
              <TruncateWrapper ref={cardTitleRef}>
                <StyledHeading
                  variant="body1Medium"
                  data-testid="regularCardH5Heading"
                >
                  {title}
                </StyledHeading>
              </TruncateWrapper>
            }
            elementPadding="4px 8px"
            xAxisOffset={4}
            disabled={!isHeadingTruncated}
          />
        )}
      </CardBody>
      <StyledCardFooter>
        {avatarProps && (
          <AvatarWrapper>
            <Avatar {...avatarProps} size="16px" />
            <AvatarText variant="body2Medium">{avatarProps.name}</AvatarText>
          </AvatarWrapper>
        )}
        {isHovering ? (
          <StyledButton
            color="primary"
            variant="text"
            size="small"
            isFullWidth
            onClick={onPreviewFlowClick}
          >
            {PREVIEW}
          </StyledButton>
        ) : (
          canShowParticipants && (
            <StatsContainer>
              <StatsWrapper>
                <StyledSVGIcon icon="people" size="16px" />
                <StyledBody variant="body2">{noOfParticipants}</StyledBody>
              </StatsWrapper>
            </StatsContainer>
          )
        )}
      </StyledCardFooter>
    </StyledFlowsTemplateCard>
  );
};

export default FlowRequestTemplateCard;
