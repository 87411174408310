import React from 'react';
import { ComponentStatus } from '../../../interfaces/component';
import { MemberState } from '../../../interfaces/user';
import { DEACTIVATED_USER } from '../../../languages/en/notifications';
import { LOADING } from '../../../languages/en/singleWords';
import { UserNameCardProps } from './interfaces';
import {
  Card,
  StyledAvatar,
  StyledBody,
  StyledIconContainer,
  StyledMainIcon,
} from './styles';
import ThemeV2 from '../../../componentsV2/theme';

const imageSizes = {
  small: '24px',
  medium: '32px',
};

const UserNameCard = (props: UserNameCardProps) => {
  switch (props.status) {
    case ComponentStatus.LOADING:
      return (
        <Card>
          <StyledAvatar size={imageSizes.small} />
          <StyledBody color="gray8" variant="body2">
            {LOADING}...
          </StyledBody>
        </Card>
      );
    case ComponentStatus.ERROR: {
      const { message } = props;
      return <div>Error: {message}</div>;
    }
    default: {
      const {
        firstName,
        image,
        imageSize,
        lastName,
        userId,
        isDeactivatedUser,
        disableUserInteraction,
        onClick,
        icon,
        memberState = 'PENDING',
      } = props;
      const fullName = isDeactivatedUser
        ? DEACTIVATED_USER
        : `${firstName} ${lastName}`;
      return (
        <Card
          isDeactivatedUser={isDeactivatedUser}
          disableUserInteraction={disableUserInteraction}
          onClick={onClick}
        >
          {memberState !== MemberState.PENDING && (
            <StyledAvatar
              img={image}
              name={fullName}
              size={imageSizes[imageSize]}
              userId={userId}
              isDeleted={isDeactivatedUser}
              icon={icon}
            />
          )}
          {memberState === MemberState.PENDING && (
            <StyledIconContainer
              bgColor={ThemeV2.palette.geekBlue6}
              borderRadius="50%"
              size={imageSizes[imageSize]}
            >
              <StyledMainIcon
                color={ThemeV2.palette.white}
                icon="pending-person"
                size="13px"
              />
            </StyledIconContainer>
          )}
          <StyledBody variant="body2">{fullName}</StyledBody>
        </Card>
      );
    }
  }
};

const MemoizedUserNameCard = React.memo(UserNameCard);
MemoizedUserNameCard.displayName = 'UserNameCard';
export default MemoizedUserNameCard;
