import { useState } from 'react';
import { useLocation } from 'react-router';
import shallow from 'zustand/shallow';

import {
  categoryMenuItems,
  timeFrameMenuItems,
} from '../../../atomic/molecules/Leaderboard/data';
import useLayoutStore from '../../../stores/layoutStore';

const useLeaderboardControllerLogic = () => {
  const location = useLocation();
  const [selectedCategory, setSelectedCategory] = useState(
    categoryMenuItems[0].value,
  );

  const [selectedTimeFrame, setSelectedTimeFrame] = useState(
    timeFrameMenuItems[1].value,
  );

  const { setSelectedMemberProfile, toggleRightAsideOpen } = useLayoutStore(
    (state) => ({
      setSelectedMemberProfile: state.setSelectedMember,
      toggleRightAsideOpen: state.toggleRightAsideOpen,
    }),
    shallow,
  );

  return {
    location,
    selectedCategory,
    selectedTimeFrame,
    setSelectedCategory,
    setSelectedTimeFrame,
    setSelectedMemberProfile,
    toggleRightAsideOpen,
  };
};

export default useLeaderboardControllerLogic;
