import React from 'react';

import { ResponseBlockProps } from './types';
import { StyledBody } from './styles';

const ResponseBlock = (props: ResponseBlockProps) => {
  const { label, children } = props;

  return (
    <div>
      <StyledBody variant="body3" color="gray8">
        {label}
      </StyledBody>
      {children}
    </div>
  );
};

export default ResponseBlock;
