import React from 'react';
import Body from '../../../atomic/atoms/Body';

import { Flex } from '../../../Utils/styles/display';
import Link from '../../../atomic/atoms/Link';
import ReactCanvasConfetti from 'react-canvas-confetti';
import AssemblyLogo from '../../../atomic/atoms/SVGIcon/icons/assemblyLogo.svg';
import assemblyLogo from '../../../img/svgs/assembly-logo-mono.svg';
import {
  Wrapper,
  Header,
  LogoContainer,
  StyledLogo,
  CustomHeader,
  Content,
  Footer,
  AssemblyImage,
  StyledTextInput,
  StyledFlex,
  StyledBody,
  StyledHeading,
} from './styles';
import { EXTERNAL_PARTICIPATION_ERROR_SCREENS_TEXTS } from '../../../languages/en/flows/participation';
import Button from '../../../atomic/atoms/Button';
import { useExternalFlowsCompletionController } from './useExternalFlowsCompletionController';
import { EXTERNAL_FLOWS_COMPLETION } from '../../../languages/en/flows';
import { SIGN_IN_BUTTON_TEXT } from '../../../languages/en/signIn';
import { SIGN_IN } from '../../../constants/routes';

const ExternalFlowsCompletionController = () => {
  const {
    setEmail,
    hasError,
    handleExploreForFreeClick,
    getInstance,
    email,
    creator,
    setHasError,
  } = useExternalFlowsCompletionController();

  const TextInputAndButtonElement = (
    <Flex
      margin="40px 0 0 0"
      width="100%"
      alignItems="baseline"
      justifyContent="center"
      height="72px"
    >
      <StyledTextInput
        value={email}
        onChange={(e) => {
          setHasError(false);
          setEmail(e.target.value);
        }}
        placeholder={
          EXTERNAL_PARTICIPATION_ERROR_SCREENS_TEXTS.PLACEHOLDER_TEXT
        }
        hasError={hasError}
        helperText={
          hasError ? EXTERNAL_PARTICIPATION_ERROR_SCREENS_TEXTS.ERROR_TEXT : ''
        }
        label={
          hasError
            ? EXTERNAL_PARTICIPATION_ERROR_SCREENS_TEXTS.PLACEHOLDER_TEXT
            : ''
        }
      />
      <Button size="large" key="error" onClick={handleExploreForFreeClick}>
        {EXTERNAL_PARTICIPATION_ERROR_SCREENS_TEXTS.EXPLORE_FOR_FREE}
      </Button>
    </Flex>
  );

  return (
    <Wrapper>
      <ReactCanvasConfetti
        refConfetti={getInstance}
        style={{
          position: 'fixed',
          pointerEvents: 'none',
          height: '100vh',
          width: '100vw',
          top: 0,
        }}
      />
      <Header>
        <Flex>
          <LogoContainer
            alignItems="center"
            justifyContent="center"
            height="40px"
            width="40px"
          >
            <StyledLogo src={creator?.logo || AssemblyLogo} alt="Express" />
          </LogoContainer>
          <CustomHeader>{creator?.name || ''}</CustomHeader>
        </Flex>
      </Header>
      <Content>
        <Flex
          alignItems="center"
          justifyContent="center"
          height="100%"
          flexDirection="column"
        >
          <AssemblyImage alt="assemblyLogo" src={assemblyLogo} />
          <StyledFlex flexDirection="column">
            <StyledHeading variant="h3" textAlign="center">
              {EXTERNAL_FLOWS_COMPLETION.HEADING}
            </StyledHeading>
            <StyledBody variant="body1" textAlign="center">
              {EXTERNAL_FLOWS_COMPLETION.SUB_HEADING}
            </StyledBody>
            {TextInputAndButtonElement}
          </StyledFlex>
        </Flex>
      </Content>
      <Footer>
        <Flex alignItems="center" justifyContent="center">
          <Body variant="body1">
            {EXTERNAL_FLOWS_COMPLETION.ALREADY_MEMBER}{' '}
            <Link to={SIGN_IN}>{SIGN_IN_BUTTON_TEXT}</Link>
          </Body>
        </Flex>
      </Footer>
    </Wrapper>
  );
};

export default ExternalFlowsCompletionController;
