import React, { ButtonHTMLAttributes, MutableRefObject } from 'react';
import styled from 'styled-components';

const StyledButton = styled.button<React.HTMLProps<HTMLButtonElement>>`
  border: none;
  background: none;
  padding: 0;
  margin: 0;
`;

const ButtonBase = (
  props: ButtonHTMLAttributes<HTMLButtonElement>,
  ref:
    | MutableRefObject<HTMLButtonElement | null>
    | null
    | ((instance: HTMLButtonElement | null) => void),
) => {
  return <StyledButton ref={ref} {...props} />;
};

export default React.forwardRef(ButtonBase);
