import styled from 'styled-components';

import Button from '../../atoms/Button';
import Body from '../../atoms/Body';
import CheckboxLoader from './Loader';
import { SingleSelectAutoComplete } from '../Autocomplete';

export const StyledSingleSelectAutoComplete = styled(SingleSelectAutoComplete)<{
  showDiv: boolean;
}>`
  transform: ${({ showDiv }) => (showDiv ? 'scale(1)' : 'scale(0)')};
  transition: transform 0.6s;
`;

export const StyledButton = styled(Button)`
  margin-left: 8px;
`;

export const StyledBody = styled(Body)`
  margin-bottom: 8px;
`;

export const StyledCheckboxLoader = styled(CheckboxLoader)`
  margin-bottom: 8px;
  :last-child {
    margin-bottom: 0;
  }
`;

export const CollapseBodyWrapper = styled.div`
  margin-top: 12px;
`;
