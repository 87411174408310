import React, { useState } from 'react';
import '../styles/video.scss';
import Loading from '../loading';

const VideoViewer = (props: { filePath: string; height: number }) => {
  const { filePath, height } = props;
  const [loading, setLoading] = useState(true);

  return (
    <div className="pg-driver-view">
      <div className="video-container">
        {loading && <Loading />}
        <video
          height={height}
          className={loading ? 'video-loading' : ''}
          controls
          onCanPlay={() => setLoading(false)}
          src={filePath}
        >
          Video playback is not supported by your browser.
        </video>
      </div>
    </div>
  );
};

export default VideoViewer;
