import {
  DAYS,
  WEEKS,
  MINUTES,
  HOURS,
} from '../../../../languages/en/singleWords';

export const defaultDueDateTypeOptions = [
  {
    id: 'on-demand-due-date',
    items: [
      {
        value: MINUTES,
        id: MINUTES,
      },
      {
        value: HOURS,
        id: HOURS,
      },
      {
        value: DAYS,
        id: DAYS,
      },
      {
        value: WEEKS,
        id: WEEKS,
      },
    ],
  },
];
