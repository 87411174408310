import { useCallback, useState, useMemo } from 'react';

import { useParams } from 'react-router-dom';
import shallow from 'zustand/shallow';

import { IMemberDTO } from '../../../interfaces/member';
import { useMembersFetchInfiniteScrollQuery } from '../../../queries/Members';
import { useMembersSearch } from '../../../hooks/useMembersSearch';
import { useProfileInfoFetchQuery } from '../../../queries/Profile';
import useLayoutStore from '../../../stores/layoutStore';

import useGetPageName from '../../../hooks/useGetPageName';
import { RIGHT_DRAWER_EVENTS } from '../../../Utils/analytics/constants';
import { trackRightDrawerEvent } from '../../../Utils/analytics';

const useAssemblyMembersControllerModel = () => {
  const { setSelectedMemberProfile, toggleRightAsideOpen } = useLayoutStore(
    (state) => ({
      setSelectedMemberProfile: state.setSelectedMember,
      toggleRightAsideOpen: state.toggleRightAsideOpen,
    }),
    shallow,
  );
  const [isListExpanded, setIsListExpanded] = useState(false);
  const { flowId } = useParams<{ flowId: string }>();

  const { page } = useGetPageName();

  const toggleIsListExpanded = useCallback(() => {
    if (isListExpanded) {
      trackRightDrawerEvent(RIGHT_DRAWER_EVENTS.SHOW_LESS_CLICKED, {
        feedViewed: page,
        drawerTabViewed: flowId ? 'about' : 'people',
        flowId,
      });
    } else {
      trackRightDrawerEvent(RIGHT_DRAWER_EVENTS.SHOW_MORE_CLICKED, {
        feedViewed: page,
        drawerTabViewed: flowId ? 'about' : 'people',
        flowId,
      });
    }
    setIsListExpanded(!isListExpanded);
  }, [flowId, isListExpanded, page]);

  const handleEntryClick = useCallback(
    (firstName: string, memberId: string) => {
      setSelectedMemberProfile({ firstName, memberId });
      trackRightDrawerEvent(RIGHT_DRAWER_EVENTS.MEMBER_CLICKED, {
        feedViewed: page,
        drawerTabViewed: 'people',
      });
    },
    [setSelectedMemberProfile, page],
  );

  const {
    data,
    hasNextPage,
    isError: isFetchInfiniteScrollQueryError,
    isLoading,
    fetchNextPage,
  } = useMembersFetchInfiniteScrollQuery();

  const { data: profileData } = useProfileInfoFetchQuery();

  const infiniteMembers = useMemo(() => {
    let allMembers: IMemberDTO[] = [];

    if (data) {
      data.pages.forEach((dataPage) => {
        const currentPageMembers = dataPage.members;
        allMembers = allMembers.concat(currentPageMembers);
      });
    }

    return allMembers;
  }, [data]);

  const {
    models: {
      isError: isMembersSearchError,
      isFetching: isMembersSearchFetching,
      isLoading: isMembersSearchLoading,
      searchedMembers,
      value: membersSearchValue,
    },
    operations: { onChange },
  } = useMembersSearch();

  const onMembersSearchChange = useCallback(
    (value: string) => {
      onChange(value);
      trackRightDrawerEvent(RIGHT_DRAWER_EVENTS.MEMBER_SEARCHED, {
        feedViewed: page,
        drawerTabViewed: 'people',
      });
    },
    [onChange, page],
  );

  return {
    models: {
      assemblyName: profileData?.assembly.name,
      hasMoreMembersToFetch: hasNextPage,
      infiniteMembers,
      toggleRightAsideOpen,
      isError: isFetchInfiniteScrollQueryError || isMembersSearchError,
      isListExpanded,
      isLoading,
      isMembersSearchLoading: isMembersSearchFetching || isMembersSearchLoading,
      membersSearchValue,
      onMembersSearchChange,
      searchedMembers,
      toggleIsListExpanded,
      totalNumberOfAssemblyMembers: data?.pages[0].total,
    },
    operations: {
      fetchMoreInfiniteMembers: fetchNextPage,
      handleEntryClick,
    },
  };
};

export default useAssemblyMembersControllerModel;
