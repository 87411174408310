import React from 'react';
import ContentLoader from 'react-content-loader';

import ThemeV2 from '../../../componentsV2/theme';

import { RightWrapper, Wrapper } from './styles';

const LoadingComponent = () => {
  return (
    <Wrapper>
      <div>
        <ContentLoader
          speed={2}
          width={40}
          height={40}
          viewBox="0 0 40 40"
          backgroundColor={ThemeV2.palette.gray11}
          foregroundColor={ThemeV2.palette.silver}
          data-testid="percentage-loader"
        >
          <rect x="0" y="0" rx="4" ry="4" width="40" height="40" />
        </ContentLoader>
      </div>

      <RightWrapper>
        <ContentLoader
          speed={2}
          width={171}
          height={20}
          viewBox="0 0 171 20"
          backgroundColor={ThemeV2.palette.gray11}
          foregroundColor={ThemeV2.palette.silver}
          data-testid="percentage-loader"
        >
          <rect x="0" y="0" rx="4" ry="4" width="171" height="20" />
        </ContentLoader>

        <ContentLoader
          speed={2}
          width={171}
          height={12}
          viewBox="0 0 171 12"
          backgroundColor={ThemeV2.palette.gray11}
          foregroundColor={ThemeV2.palette.silver}
          data-testid="percentage-loader"
        >
          <rect x="0" y="0" rx="4" ry="4" width="171" height="12" />
        </ContentLoader>
      </RightWrapper>
    </Wrapper>
  );
};

export default LoadingComponent;
