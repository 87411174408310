import ContentLoader from 'react-content-loader';
import React, { useEffect, useMemo } from 'react';

import ThemeV2 from '../../../../componentsV2/theme';
import { CalendarSetupProps } from './type';
import BlockSection from '../../BlockSection';
import { quickSetupCalendarSetupScreen } from '../../../../languages/en/quickSetup';
import {
  WorkatoEventMessage,
  WORKATO_ORIGIN,
} from '../../../../containersV2/WorkatoIMContainer';
import { WorkatoEventMessageData } from '../../../../hooks/useWorkatoIdentityManager';
import GoogleCalendarImage from '../../../../img/svgs/googleCalendar.svg';
import OutlookCalendarImage from '../../../../img/svgs/outlookCalendar.svg';
import CalendarSelectionItem from '../../../molecules/QuickSetup/CalendarSelectionItem';
import { StyledErrorStateTemplate } from '../MeetingList/styles';
import ErrorImage from '../../../../img/error.svg';
import { HeadingType } from '../../../molecules/EmptyErrorStateTemplate/interfaces';

const CalendarSetup = (props: CalendarSetupProps) => {
  const {
    googleEmbedUrl,
    outlookEmbedUrl,
    onCalendarSelected,
    isWorkatoSetupError,
    isWorkatoSetupComplete,
  } = props;
  const headingComponent = useMemo(() => {
    return (
      <>
        Let’s quickly create an important <s>work</s>
        flow made just for you
      </>
    );
  }, []);

  useEffect(() => {
    const fn = (message: WorkatoEventMessage) => {
      try {
        if (message.origin === WORKATO_ORIGIN) {
          if (typeof message.data === 'string') {
            const eventData = JSON.parse(
              message.data,
            ) as Partial<WorkatoEventMessageData>;
            if (eventData.type && eventData.type === 'connectionStatusChange') {
              if (eventData.payload?.connected) {
                if (onCalendarSelected)
                  onCalendarSelected({
                    providerName: eventData.payload.provider,
                    connectionId: eventData.payload.id,
                  });
              }
            }
          }
        }
      } catch (e) {
        console.log(e, message);
      }
    };
    window.addEventListener('message', fn);
    return () => {
      window.removeEventListener('message', fn);
    };
  }, [onCalendarSelected]);

  const renderCalendarSetupChildren = useMemo(() => {
    if (isWorkatoSetupError) {
      return (
        <StyledErrorStateTemplate
          image={ErrorImage}
          heading={quickSetupCalendarSetupScreen.errorScreenHeading}
          subHeading={quickSetupCalendarSetupScreen.errorScreenSubHeading}
          variant={HeadingType.Bold}
        />
      );
    }
    if (!isWorkatoSetupComplete) {
      return (
        <ContentLoader
          speed={2}
          width={502}
          height={64}
          viewBox="0 502 64"
          className="contentLoader"
          data-testid="calendar-selection-content-loader"
          backgroundColor={ThemeV2.palette.gray4}
          foregroundColor={ThemeV2.palette.gray3}
        >
          <rect x="0" y="0" rx="4" ry="4" width="502" height="64" />
        </ContentLoader>
      );
    }
    if (isWorkatoSetupComplete) {
      return (
        <>
          {googleEmbedUrl && (
            <CalendarSelectionItem
              embedUrl={googleEmbedUrl}
              imageSrc={GoogleCalendarImage}
              title={quickSetupCalendarSetupScreen.googleCalendarTitle}
            />
          )}
          {outlookEmbedUrl && (
            <CalendarSelectionItem
              embedUrl={outlookEmbedUrl}
              imageSrc={OutlookCalendarImage}
              title={quickSetupCalendarSetupScreen.outlookCalendarTitle}
            />
          )}
        </>
      );
    }
  }, [
    googleEmbedUrl,
    outlookEmbedUrl,
    isWorkatoSetupError,
    isWorkatoSetupComplete,
  ]);

  return (
    <BlockSection
      headingVariant="h4"
      subHeadingColor="gray8"
      subHeadingVariant="body1"
      heading={headingComponent}
      subHeading={quickSetupCalendarSetupScreen.subHeadingText}
    >
      {renderCalendarSetupChildren}
    </BlockSection>
  );
};

export default CalendarSetup;
