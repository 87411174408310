import React, { useMemo } from 'react';
import styled from 'styled-components';

import Body from '../../atoms/Body';
import Button from '../../atoms/Button';
import { Flex } from '../../../Utils/styles/display';
import Popover from '../Popover';
import SVGIcon from '../../atoms/SVGIcon';
import ToggleSwitch from '../../atoms/ToggleSwitch';
import {
  POST_ANONYMOUSLY,
  POST_ANONYMOUSLY_AND_PRIVATELY,
  POST_PRIVATELY,
} from '../../../languages/en/flows/participation';
import { POST } from '../../../languages/en/singleWords';

const First = styled(Button)`
  border-radius: 4px 0 0 4px;
  margin-right: 1px;
`;

const DropdownButton = styled(Button)`
  border-radius: 0 4px 4px 0;
  padding: 16px 8px;
  & span {
    line-height: 16px;
  }
`;

const StyledToggleLabel = styled.label`
  margin-right: 26px;
  margin-bottom: 0;
  cursor: pointer;
`;

const StyledPopoverContent = styled.div`
  min-width: fit-content;

  padding: 4px;
`;

export interface SplitButtonProps {
  disabled?: boolean;
  isLoading?: boolean;
  isPrivatePost: boolean;
  isAnonymousPost: boolean;
  isAnonymityEnabled?: boolean;
  onAnonymousPostToggle?: () => void;
  onPostClick: () => void;
  onPrivatePostToggle: () => void;
}

const SplitButton = ({
  disabled = false,
  isLoading = false,
  isPrivatePost,
  onPostClick,
  onPrivatePostToggle,
  onAnonymousPostToggle,
  isAnonymousPost,
  isAnonymityEnabled,
}: SplitButtonProps) => {
  const firstButtonText = useMemo(() => {
    if ((isAnonymousPost || isAnonymityEnabled) && isPrivatePost) {
      return POST_ANONYMOUSLY_AND_PRIVATELY;
    }
    if (isAnonymousPost || isAnonymityEnabled) {
      return POST_ANONYMOUSLY;
    }
    if (isPrivatePost) {
      return POST_PRIVATELY;
    }
    return POST;
  }, [isAnonymityEnabled, isAnonymousPost, isPrivatePost]);
  return (
    <Flex>
      <First
        onClick={onPostClick}
        disabled={disabled}
        isLoading={isLoading}
        size="large"
      >
        {firstButtonText}
      </First>
      <Popover
        containerPadding="2px"
        disabled={disabled || isLoading}
        popoverPosition={onAnonymousPostToggle ? 'top' : 'bottom-end'}
        elementPadding="4px"
        referenceContent={
          <DropdownButton
            dataTestId="splitButtonDropdown"
            disabled={disabled}
            size="large"
          >
            <SVGIcon icon="caret-rounded" size="16px" color="gray1" />
          </DropdownButton>
        }
        popoverContent={
          <StyledPopoverContent>
            {onAnonymousPostToggle && (
              <Flex
                alignItems="center"
                justifyContent="space-between"
                margin="0 0 8px 0"
              >
                <StyledToggleLabel htmlFor="isAnonymousPost">
                  <Body color="gray8" inline variant="body2">
                    {POST_ANONYMOUSLY}
                  </Body>
                </StyledToggleLabel>

                <ToggleSwitch
                  id="isAnonymousPost"
                  isOn={isAnonymousPost}
                  onChange={onAnonymousPostToggle}
                />
              </Flex>
            )}
            <Flex alignItems="center" justifyContent="space-between">
              <StyledToggleLabel htmlFor="isPrivatePost">
                <Body color="gray8" inline variant="body2">
                  {POST_PRIVATELY}
                </Body>
              </StyledToggleLabel>

              <ToggleSwitch
                id="isPrivatePost"
                isOn={isPrivatePost}
                onChange={onPrivatePostToggle}
              />
            </Flex>
          </StyledPopoverContent>
        }
      />
    </Flex>
  );
};

export default SplitButton;
