import cloneDeep from 'lodash/cloneDeep';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { EmailNotificationGlobalSettings } from '../../interfaces/UserSettings';
import {
  GET_USER_INFO,
  UPDATE_USER_SETTINGS,
  GET_MEMBER_NOTIFICATION_PREFERENCES,
  UPDATE_MEMBER_NOTIFICATION_PREFERENCES,
  GET_PROFILE_INFO,
  UPDATE_PROFILE_PICTURE,
} from '../../constants/endpoints';
import { makeAPICall, makeAPICallWithDataReturn } from '../utils';
import { EmoticonResponse } from '../Flows/interfaces';
import { GET_MEMBERS_INFINITE } from '../Members/utils';

export interface Timezone {
  name: string | undefined;
}
export interface User {
  _id: string;
  email: string;
  profile: {
    department: string;
    firstName: string;
    lastName: string;
    managers: string[];
    reports: string[];
    username: string;
    image?: {
      original?: {
        relativeUrl: string;
      };
      resized?: {
        relativeUrl: string;
      };
    };
    birthday?: {
      date?: number;
      monthNumber?: number;
      month?: string;
    };
    hiredday?: {
      date?: number;
      monthNumber?: number;
      month?: string;
      year?: number;
    };
  };
  settings: {
    emailPreferences: EmailNotificationGlobalSettings;
    timeZone?: Timezone;
  };
}

export interface UserInfoResponse {
  data: {
    user: User;
  };
}

export interface UpdateUserInfoPayload {
  firstName: string;
  lastName: string;
  managers: string[];
  reports: string[];
  department: string;
  file?: string;
  fileName?: string;
  fileType?: string;
  notifyActivity: boolean;
  notifyAllowance: boolean;
  notifyAnniversary: boolean;
  notifyComments: boolean;
  notifyNewCarrots: boolean;
}

export enum FlowsNotificationItemPreferenceTypes {
  FLOW_TRIGGERED = 'flow_triggered',
  FLOW_REMINDER = 'flow_reminder',
}

export type FlowsNotificationItem = {
  entityId: string;
  entityType: 'FLOW';
  name: string;
  icon: EmoticonResponse;
  preferences: {
    type: FlowsNotificationItemPreferenceTypes;
    value: boolean;
  }[];
};

export type FlowsNotificationResponse = {
  email: {
    entities: {
      flows: FlowsNotificationItem[];
    };
    global: {
      [key: string]: boolean;
    };
  };
};

export type FlowsNotificationPayload = {
  email: {
    entities: {
      entityId: string;
      entityType: 'FLOW';
      type: FlowsNotificationItemPreferenceTypes;
      value: boolean;
    }[];
  };
};

export type GlobalNotificationPayload = {
  email: {
    global: {
      [key: string]: boolean;
    };
  };
};

export const useUserInfoQuery = () => {
  return useQuery(
    GET_USER_INFO,
    () => makeAPICallWithDataReturn(GET_USER_INFO),
    {
      select: (res: UserInfoResponse) => res.data,
      staleTime: Infinity,
    },
  );
};

export const useGetFlowNotificationPreferencesQuery = () => {
  return useQuery(
    GET_MEMBER_NOTIFICATION_PREFERENCES,
    () => makeAPICallWithDataReturn(GET_MEMBER_NOTIFICATION_PREFERENCES),
    {
      select: (res: FlowsNotificationResponse) => res,
      staleTime: Infinity,
    },
  );
};

export const useUpdateFlowNotificationPreferencesQuery = () => {
  return useQuery(UPDATE_MEMBER_NOTIFICATION_PREFERENCES, () =>
    makeAPICallWithDataReturn(UPDATE_MEMBER_NOTIFICATION_PREFERENCES),
  );
};

export const updateUserInfo = (
  previousUserInfo: UserInfoResponse,
  newSettings: UpdateUserInfoPayload,
) => {
  const updatedUserInfo = cloneDeep(previousUserInfo);

  const {
    notifyActivity,
    notifyAllowance,
    notifyAnniversary,
    notifyComments,
    notifyNewCarrots,
  } = newSettings;
  updatedUserInfo.data.user.settings.emailPreferences = {
    notifyActivity,
    notifyAllowance,
    notifyAnniversary,
    notifyComments,
    notifyNewCarrots,
  };

  return updatedUserInfo;
};

export const updateTimeZoneInfo = (
  previousUserInfo: UserInfoResponse,
  updatedTimeZone: { timeZone: string },
) => {
  const updatedUserInfo = cloneDeep(previousUserInfo);
  updatedUserInfo.data.user.settings.timeZone = {
    name: updatedTimeZone.timeZone,
  };
  return updatedUserInfo;
};

export const useUpdateTimeZoneMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (payload: { timeZone: string }) =>
      makeAPICall(UPDATE_USER_SETTINGS, payload),
    {
      onMutate: async (updatedTimeZone: { timeZone: string }) => {
        await queryClient.cancelQueries(GET_USER_INFO);

        const previousUserInfo =
          queryClient.getQueryData<UserInfoResponse>(GET_USER_INFO);

        if (previousUserInfo) {
          const newUserInfo = updateTimeZoneInfo(
            previousUserInfo,
            updatedTimeZone,
          );
          queryClient.setQueryData(GET_USER_INFO, newUserInfo);
        }
        return { previousUserInfo };
      },
      onSuccess: () => {
        queryClient.refetchQueries(GET_PROFILE_INFO);
      },
      onError: (err, variables, context) => {
        if (context?.previousUserInfo) {
          queryClient.setQueryData(GET_USER_INFO, context.previousUserInfo);
        }
      },
    },
  );
};

export const useUserSettingsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (payload: UpdateUserInfoPayload) =>
      makeAPICall(UPDATE_USER_SETTINGS, payload),
    {
      onMutate: async (newSettings: UpdateUserInfoPayload) => {
        await queryClient.cancelQueries(GET_USER_INFO);

        const previousUserInfo =
          queryClient.getQueryData<UserInfoResponse>(GET_USER_INFO);

        if (previousUserInfo) {
          const newUserInfo = updateUserInfo(previousUserInfo, newSettings);
          queryClient.setQueryData(GET_USER_INFO, newUserInfo);
        }
        return { previousUserInfo };
      },
      onSuccess: () => {
        queryClient.refetchQueries(GET_PROFILE_INFO);
      },
      onError: (err, variables, context) => {
        if (context?.previousUserInfo) {
          queryClient.setQueryData(GET_USER_INFO, context.previousUserInfo);
        }
      },
    },
  );
};

export const useUserProfilePictureMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (payload: UpdateUserInfoPayload) =>
      makeAPICall(UPDATE_PROFILE_PICTURE, { fileName: payload.fileName }),
    {
      onMutate: async (newSettings: UpdateUserInfoPayload) => {
        await queryClient.cancelQueries(GET_USER_INFO);

        const previousUserInfo =
          queryClient.getQueryData<UserInfoResponse>(GET_USER_INFO);

        if (previousUserInfo) {
          const newUserInfo = updateUserInfo(previousUserInfo, newSettings);
          queryClient.setQueryData(GET_USER_INFO, newUserInfo);
        }
        return { previousUserInfo };
      },
      onSuccess: () => {
        queryClient.invalidateQueries(GET_PROFILE_INFO);
        queryClient.invalidateQueries([GET_MEMBERS_INFINITE]);
      },
      onError: (err, variables, context) => {
        if (context?.previousUserInfo) {
          queryClient.setQueryData(GET_USER_INFO, context.previousUserInfo);
        }
      },
    },
  );
};

const updateFlowEmailPreference = (
  previousFlowSettings: FlowsNotificationResponse,
  newSettings: FlowsNotificationPayload,
) => {
  const flowSettings = cloneDeep(previousFlowSettings);
  const updatingFlowItem = flowSettings.email.entities.flows.find(
    (item) => item.entityId === newSettings.email.entities[0].entityId,
  );

  const updatingFlowItemPreference = updatingFlowItem?.preferences.find(
    (preference) => preference.type === newSettings.email.entities[0].type,
  );

  if (updatingFlowItemPreference) {
    updatingFlowItemPreference.value = newSettings.email.entities[0].value;
  }
  return flowSettings;
};

export const useFlowsEmailSettingsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (payload: FlowsNotificationPayload) =>
      makeAPICall(UPDATE_MEMBER_NOTIFICATION_PREFERENCES, payload),
    {
      onMutate: (newSettings: FlowsNotificationPayload) => {
        const previousFlowSettings =
          queryClient.getQueryData<FlowsNotificationResponse>(
            GET_MEMBER_NOTIFICATION_PREFERENCES,
          );

        if (previousFlowSettings) {
          const updatedFlowEmailPreference = updateFlowEmailPreference(
            previousFlowSettings,
            newSettings,
          );
          queryClient.setQueryData(
            GET_MEMBER_NOTIFICATION_PREFERENCES,
            updatedFlowEmailPreference,
          );
        }

        return { previousFlowSettings };
      },
      onError: (err, variables, context) => {
        if (context?.previousFlowSettings) {
          queryClient.setQueryData(
            GET_MEMBER_NOTIFICATION_PREFERENCES,
            context.previousFlowSettings,
          );
        }
      },
    },
  );
};

export const useGlobalEmailSettingsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (payload: GlobalNotificationPayload) =>
      makeAPICall(UPDATE_MEMBER_NOTIFICATION_PREFERENCES, payload),
    {
      onMutate: () => {
        const previousGlobalSettings =
          queryClient.getQueryData<FlowsNotificationResponse>(
            GET_MEMBER_NOTIFICATION_PREFERENCES,
          );
        const updatingGlobalSettings = cloneDeep(previousGlobalSettings);

        if (updatingGlobalSettings) {
          updatingGlobalSettings.email.global.mentions =
            !updatingGlobalSettings.email.global.mentions;

          queryClient.setQueryData(
            GET_MEMBER_NOTIFICATION_PREFERENCES,
            updatingGlobalSettings,
          );
        }

        return { previousGlobalSettings };
      },
      onError: (err, variables, context) => {
        if (context?.previousGlobalSettings) {
          queryClient.setQueryData(
            GET_MEMBER_NOTIFICATION_PREFERENCES,
            context.previousGlobalSettings,
          );
        }
      },
    },
  );
};
