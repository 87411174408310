import React from 'react';

import Divider from '../../atoms/Divider';

import FlowStart from './FlowStart';
import FlowRecur from './FlowRecur';
import FlowDeadline from './FlowDeadline';
import FlowEnd from './FlowEnd';
import ShortcutConfiguration from './ShortcutConfiguration';
import CustomRecurrence from '../CustomRecurrence';

import ThemeV2 from '../../../componentsV2/theme';
import {
  TriggerScheduledConfigureSettingsProps,
  TriggerOnDemandConfigureSettingsProps,
} from './types';
import FlowTimezone from './FlowTimezone';
import FlowDefaultDueDate from './FlowDefaultDueDate';
import FlowAnswerAnytime from './FlowAnswerAnytime';

const RenderDivider = () => (
  <Divider
    marginTop="24px"
    marginBottom="24px"
    color={ThemeV2.palette.gray4}
    isFullWidth
  />
);

const TriggerConfigureSettings = (
  props:
    | TriggerOnDemandConfigureSettingsProps
    | TriggerScheduledConfigureSettingsProps,
) => {
  const { triggerType } = props;
  switch (triggerType) {
    case 'ONDEMAND': {
      const { flowAnswerAnytimeProps, flowDefaultDueDateProps } =
        props as TriggerOnDemandConfigureSettingsProps;
      return (
        <>
          <FlowDefaultDueDate {...flowDefaultDueDateProps} />
          <RenderDivider />
          <FlowAnswerAnytime {...flowAnswerAnytimeProps} />
        </>
      );
    }

    case 'SCHEDULED': {
      const {
        flowStartProps,
        flowRecurProps,
        flowTimezoneProps,
        flowDeadlineProps,
        flowEndProps,
        flowShortcutConfigurationProps,
        customRecurrenceProps,
      } = props as TriggerScheduledConfigureSettingsProps;

      return (
        <>
          <FlowStart {...flowStartProps} />
          <RenderDivider />
          <FlowRecur {...flowRecurProps} />
          <FlowTimezone {...flowTimezoneProps} />
          <RenderDivider />
          <FlowDeadline {...flowDeadlineProps} />
          <RenderDivider />
          <FlowEnd {...flowEndProps} />
          <RenderDivider />
          <ShortcutConfiguration {...flowShortcutConfigurationProps} />
          <CustomRecurrence {...customRecurrenceProps} />
        </>
      );
    }

    default:
      return null;
  }
};

export default TriggerConfigureSettings;
