import styled from 'styled-components';
import Body from '../../atoms/Body';
import Heading from '../../atoms/Heading';
import ThemeV2 from '../../../componentsV2/theme';
import IconButton from '../../atoms/IconButton_V2';
import { SingleSelectAutoComplete } from '../Autocomplete';
import { device } from '../../../constants/layout';

export const Wrapper = styled.div`
  position: relative;
  padding: 28px 40px 0px 40px;
  @media ${device.mobile} {
    padding: 28px 24px 0px 24px;
  }
`;

export const StyledHeading = styled(Heading)`
  margin: 0;
`;

export const StyledBody = styled(Body)`
  margin-top: 4px;
  margin-bottom: 20px;
  color: ${ThemeV2.palette.gray8};
`;

export const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: 20px;
  right: 20px;
`;

export const HeaderWrapper = styled.div`
  max-width: 634px;
  margin-bottom: 20px;
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledAutocomplete = styled(SingleSelectAutoComplete)`
  max-width: 223px;
  width: 100%;
  margin-left: 16px;
`;
