import { useQuery } from 'react-query';

import { makeAPICallWithDataReturn } from '../../utils';
import {
  GetFlowsFilterResponse,
  GetPersonsFilterResponse,
  GetVersionsFilterResponse,
  GetBlocksFilterResponse,
  GetDateFilterResponse,
} from '../../../interfaces/RightDrawer/Filter';
import {
  GET_FLOWS_FILTER_OPTIONS,
  GET_POSTED_BY_FILTER_OPTIONS,
  GET_FLOWS_VERSIONS_FILTER_OPTIONS,
  GET_FLOWS_BLOCKS_FILTER_OPTIONS,
  GET_FLOWS_MENTIONS_FILTER_OPTIONS,
  GET_DATE_FILTER_OPTIONS,
} from '../../../constants/endpoints';

export const useGetFlowsFilterOptionsQuery = (profileId = '') => {
  return useQuery<GetFlowsFilterResponse>(
    [GET_FLOWS_FILTER_OPTIONS, profileId],
    () =>
      makeAPICallWithDataReturn(GET_FLOWS_FILTER_OPTIONS, undefined, {
        profileId,
      }),
    {
      staleTime: Infinity,
      refetchOnMount: 'always',
    },
  );
};

export const useGetPostedByFilterOptionsQuery = (
  flowId?: string,
  profileId?: string,
) => {
  return useQuery<GetPersonsFilterResponse>(
    [GET_POSTED_BY_FILTER_OPTIONS, flowId, profileId],
    () =>
      makeAPICallWithDataReturn(GET_POSTED_BY_FILTER_OPTIONS, undefined, {
        flowId: flowId || '',
        profileId: profileId || '',
      }),
    {
      staleTime: Infinity,
      refetchOnMount: 'always',
    },
  );
};

export const useGetVersionsFilterOptionsQuery = (flowId: string) => {
  return useQuery<GetVersionsFilterResponse>(
    [GET_FLOWS_VERSIONS_FILTER_OPTIONS, flowId],
    () =>
      makeAPICallWithDataReturn(GET_FLOWS_VERSIONS_FILTER_OPTIONS, undefined, {
        flowId,
      }),
    {
      staleTime: Infinity,
      refetchOnMount: 'always',
    },
  );
};

export const useGetBlocksFilterOptionsQuery = (flowId: string) => {
  return useQuery<GetBlocksFilterResponse>(
    [GET_FLOWS_BLOCKS_FILTER_OPTIONS, flowId],
    () =>
      makeAPICallWithDataReturn(GET_FLOWS_BLOCKS_FILTER_OPTIONS, undefined, {
        flowId,
      }),
    {
      staleTime: Infinity,
      refetchOnMount: 'always',
    },
  );
};

export const useGetMentionsFilterOptionsQuery = (
  flowId?: string,
  profileId?: string,
) => {
  return useQuery<GetPersonsFilterResponse>(
    [GET_FLOWS_MENTIONS_FILTER_OPTIONS, flowId, profileId],
    () =>
      makeAPICallWithDataReturn(GET_FLOWS_MENTIONS_FILTER_OPTIONS, undefined, {
        flowId: flowId || '',
        profileId: profileId || '',
      }),
    {
      staleTime: Infinity,
      refetchOnMount: 'always',
    },
  );
};

export const useGetDateFilterOptionsQuery = (flowId?: string) => {
  return useQuery<GetDateFilterResponse>(
    [GET_DATE_FILTER_OPTIONS, flowId],
    () =>
      makeAPICallWithDataReturn(GET_DATE_FILTER_OPTIONS, undefined, {
        flowId: flowId || '',
      }),
    {
      staleTime: Infinity,
      refetchOnMount: 'always',
    },
  );
};
