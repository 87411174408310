import styled from 'styled-components';
import React, { useMemo } from 'react';

import FlowFeedPost from '../../../atomic/organism/FlowFeedPost/newIndex';
import { FeedItemFromAPI } from '../../../interfaces/Feed';
import { GetProfileInfoResponse } from '../../../queries/Profile';
import useFlowPostController from './useFlowPostController/newIndex';
import FlowsPostHeaderOption from '../../../atomic/organism/FlowsPostHeaderOption';
import {
  menuItemsWithCopyLinkAndDeletePost,
  menuItemsWithCopyLink,
} from '../../../atomic/organism/FlowFeedPost/data';

type FlowPostControllerProps = {
  post: FeedItemFromAPI;
  profileData?: GetProfileInfoResponse;
  onPostDelete?: () => void;
};

const DropdownContainer = styled.div`
  margin-left: auto;
`;

const FlowPostController = (props: FlowPostControllerProps) => {
  const { post, profileData } = props;

  const {
    commentInputProps,
    commentsData,
    onShowMoreButtonClick,
    showMoreCommentsButton,
    hasTrophies,
    showDeleteOption,
    fileToPreview,
    handleSetFilePreview,
    handleDeletePostOptionChange,
    onCopyLinkClick,
    onFlowClick,
    handleMemberClick,
    onSeeFullPostClick,
    onDownloadFileClick,
    handleReactionSet,
    handleReactionUnSet,
    onCommentExited,
    onCommentPosted,
    onCommentTextFocus,
    onDeleteModalSubmitClick,
    blocks,
  } = useFlowPostController(props);

  const dropdownComponent = useMemo(
    () => (
      <DropdownContainer>
        <FlowsPostHeaderOption
          dropdownOptions={
            showDeleteOption
              ? menuItemsWithCopyLinkAndDeletePost
              : menuItemsWithCopyLink
          }
          icon="more"
          title="flows post options"
          onCopyLink={onCopyLinkClick}
          hasTrophies={hasTrophies}
          onDeletePostSelectedOption={handleDeletePostOptionChange}
          onDeleteModalSubmitClick={onDeleteModalSubmitClick}
        />
      </DropdownContainer>
    ),
    [
      hasTrophies,
      handleDeletePostOptionChange,
      onDeleteModalSubmitClick,
      onCopyLinkClick,
      showDeleteOption,
    ],
  );
  if (profileData) {
    return (
      <FlowFeedPost
        profileData={profileData}
        post={post}
        key={post.flowResponse?.responseId || post.postID}
        onReactionSet={handleReactionSet}
        onReactionUnSet={handleReactionUnSet}
        onMemberClick={handleMemberClick}
        bodyProps={{
          blocks,
          fileToPreview,
          handleSetFilePreview,
          onSeeFullPostClick,
          onDownloadFileClick,
        }}
        commentInputProps={commentInputProps}
        onFlowClick={onFlowClick}
        onCommentTextFocus={onCommentTextFocus}
        onCommentExited={onCommentExited}
        onCommentPosted={onCommentPosted}
        dropdownComponent={dropdownComponent}
        commentsData={commentsData}
        onShowMoreButtonClick={onShowMoreButtonClick}
        showMoreCommentsButton={showMoreCommentsButton}
      />
    );
  }
  return null;
};

export default FlowPostController;
