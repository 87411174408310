import { useQuery } from 'react-query';

import { makeAPICallWithDataReturn } from '../utils';
import { MemberFromAPI } from '../../interfaces/UserProfile';
import { GET_MEMBERS_DETAILS } from '../../constants/endpoints';

export interface GetMembersDetailsApiResponse {
  membersDetails: MemberFromAPI[];
}

export const useFetchMembersDetailsQuery = (
  memberIds?: string[],
  isQueryEnabled = true,
) => {
  const queryKey = [GET_MEMBERS_DETAILS, memberIds];
  const payload = { memberIds };
  return useQuery<GetMembersDetailsApiResponse>(
    queryKey,
    () => makeAPICallWithDataReturn(GET_MEMBERS_DETAILS, payload),
    {
      enabled: !!(memberIds && memberIds.length > 0 && isQueryEnabled),
      staleTime: Infinity,
    },
  );
};
