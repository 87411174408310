// @ts-nocheck TODO: type issues need to be fixed in this file
import ProfanityJSON from '../profanity.json';

const Filter = require('bad-words');

const filter = new Filter({ emptyList: true });
filter.addWords(...ProfanityJSON);

export function checkForProfanity(message) {
  return filter.isProfane(message);
}
