import { ReactionEmoji } from '../../../interfaces/Feed';
import { ButtonSize } from '../IconButton_V2/interface';

export enum ToggleButtonVariant {
  Regular = 'regular',
  Text = 'text',
}

export interface ToggleButtonProps {
  variant?: ToggleButtonVariant;
  active?: boolean;
  disabled?: boolean;
  size?: ButtonSize;
  children: React.ReactNode;
  reaction?: ReactionEmoji;
  icon?: string;
  iconSize?: string;
  onClick: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
  className?: string;
  label?: string;
  isRounded?: boolean;
}

export interface StylesComponentsProps {
  active?: boolean;
  size?: ButtonSize;
  isRounded?: boolean;
}
