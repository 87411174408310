import { useQueryClient } from 'react-query';
import { useCallback, useEffect, useMemo, useState } from 'react';
import GoogleCalendarImage from '../../../../img/svgs/googleCalendar.svg';
import OutlookCalendarImage from '../../../../img/svgs/outlookCalendar.svg';

import {
  useGetCalendarListQuery,
  useCalendarAuthorization,
  useGetCalendarEventsList,
  useUpdateQuickSetupStatus,
} from '../../../../queries/QuickSetup';

import { AxiosError } from 'axios';
import { getCalendarListDropdownItems } from '../utils';
import { groupBy, isEmpty } from '../../../../Utils/common';
import { ComponentStatus } from '../../../../interfaces/component';
import { GET_CALENDAR_EVENTS_LIST } from '../../../../constants/endpoints';
import { MenuItemProps } from '../../../../atomic/molecules/Dropdown_V2/interfaces';
import { meetingTypeDropdownItems } from '../../../../atomic/organism/QuickSetup/MeetingList/data';

import {
  PRIMARY_CALENDAR,
  CALENDAR_ALREADY_CONNECTED,
} from '../../../../languages/en/quickSetup';

import {
  MeetingItem,
  NonRecurringItem,
} from '../../../../atomic/organism/QuickSetup/MeetingList/type';

import {
  getDayFromDate,
  getFormattedTimeFromDate,
  getRRuleStringFromEvent,
} from './utils';

// eslint-disable-next-line max-len
import { CalendarSelectDropdownBarWithRefreshProps } from '../../../../atomic/molecules/QuickSetup/CalendarSelectDropdownBarWithRefresh/types';
import { MeetingSelectionControllerProps } from '../type';
import { QuickSetupStep } from '../../QuickSetupController/types';
import {
  QuickSetupDirectionEnum,
  QuickSetupStatusEnum,
} from '../../../../queries/QuickSetup/interfaces';
import RRule, { rrulestr } from 'rrule';
import { trackOnboardingActionEvent } from '../../../../Utils/analytics/onboarding';
import { ACCOUNT_ONBOARDING_EVENTS } from '../../../../Utils/analytics/constants';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import { QUICK_SETUP } from '../../../../constants/routes';
import { useHistory } from 'react-router-dom';

const useMeetingSelectionController = (
  props: MeetingSelectionControllerProps,
) => {
  const {
    isWorkatoSetupComplete,
    onCalenderIdChange,
    onMeetingChange,
    calenderId,
    connectionId,
    providerName,
    onQuickSetupStepChange,
  } = props;

  const queryClient = useQueryClient();

  const history = useHistory();

  // Authorize Calendar Connection
  const [isCalendarConnectionAuthorized, setCalendarConnectionAuthorized] =
    useState(false);

  const {
    error,
    data: authStatus,
    isLoading: isAuthLoading,
  } = useCalendarAuthorization(
    providerName,
    connectionId,
    connectionId !== 0 && !isEmpty(providerName) && isWorkatoSetupComplete,
  );

  useEffect(() => {
    if (authStatus) {
      setCalendarConnectionAuthorized(true);
    }
    if (error) {
      const { response } = error as AxiosError<{
        body?: string;
        message?: string;
      }>;
      if (response?.data.message === CALENDAR_ALREADY_CONNECTED) {
        setCalendarConnectionAuthorized(true);
      }
    }
  }, [authStatus, error]);

  const { mutate: mutateQuickSetupStatus } = useUpdateQuickSetupStatus();

  // Calendar List Bar
  const {
    data,
    isLoading,
    isError: isCalendarListError,
  } = useGetCalendarListQuery(isCalendarConnectionAuthorized);

  const [calendarItems, setCalendarItems] = useState<MenuItemProps[]>([]);
  const [selectedCalendar, setSelectedCalendar] = useState(PRIMARY_CALENDAR);
  useEffect(() => {
    if (data && !isLoading) {
      setCalendarItems(getCalendarListDropdownItems(data.data));
      data.data.forEach((item) => {
        if (item.primary) {
          item.summary = PRIMARY_CALENDAR;
        }
        if (item.summary === selectedCalendar) {
          const transformedCalendarId = item.id.replace('#', '%23');
          onCalenderIdChange(transformedCalendarId);
        }
      });
    }
  }, [data, isLoading, onCalenderIdChange, selectedCalendar]);

  const calendarSelectBarProps: CalendarSelectDropdownBarWithRefreshProps =
    useMemo(
      () => ({
        dropdownPlaceholderImage:
          providerName === 'outlook'
            ? OutlookCalendarImage
            : GoogleCalendarImage,
        onRefreshListClick: () => {
          queryClient.refetchQueries([GET_CALENDAR_EVENTS_LIST, calenderId]);
        },
        onDropdownItemClick: (value: string | number) => {
          setSelectedCalendar(value as string);
          onMeetingChange('', '', '', 0);
        },
        selectedValue: selectedCalendar,
        dropdownMenuItems: calendarItems,
        isLoading: isLoading || isAuthLoading,
      }),
      [
        providerName,
        selectedCalendar,
        calendarItems,
        isLoading,
        isAuthLoading,
        queryClient,
        calenderId,
        onMeetingChange,
      ],
    );

  // Meetings List
  const [selectedMeetingType, setSelectedMeetingType] = useState(
    meetingTypeDropdownItems[0].items[0],
  );

  const {
    data: eventsList,
    isLoading: isEventsListLoading,
    isFetching: isEventsListFetching,
    isError: isEventsListError,
  } = useGetCalendarEventsList(calenderId, !isEmpty(calenderId));
  const [recurringEvents, setRecurringEvents] = useState<MeetingItem[]>([]);
  const [nonRecurringEvents, setNonRecurringEvents] = useState<
    NonRecurringItem[]
  >([]);

  const handleOnMeetingSelected = useCallback(
    (meetingId: string, meetingName: string, recurrence: string) => {
      const getTimeDuration = (event: MeetingItem | undefined): number => {
        let duration = 1395;
        if (event?.start.dateTime && event?.end.dateTime) {
          duration = differenceInMinutes(
            new Date(event.end.dateTime),
            new Date(event.start.dateTime),
          );
          if (duration > 44640) {
            duration = 44640;
          }
        }

        return duration;
      };
      const mapDateAsUTC = (date: Date) => {
        return new Date(
          Date.UTC(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            date.getHours(),
            date.getMinutes(),
            date.getSeconds(),
          ),
        );
      };

      // Recurring Meeting
      if (recurrence) {
        let rule: RRule;
        const selectedEvent = recurringEvents.find(
          (item) => item.id === meetingId,
        );
        if (recurrence.includes('EXDATE')) {
          rule = rrulestr(recurrence);
        } else {
          rule = RRule.fromString(recurrence);
        }

        const options = {
          ...rule.origOptions,
          dtstart:
            selectedEvent && selectedEvent.start && selectedEvent.start.dateTime
              ? mapDateAsUTC(new Date(selectedEvent.start.dateTime))
              : new Date(),
          tzid: Intl.DateTimeFormat().resolvedOptions().timeZone,
        };

        const rrule = new RRule(options);
        onMeetingChange(
          meetingId,
          meetingName,
          rrule.toString(),
          getTimeDuration(selectedEvent),
        );

        trackOnboardingActionEvent({
          action: ACCOUNT_ONBOARDING_EVENTS.SELECT_MEETING,
          meetingTitle: meetingName,
          meetingRecurs: true,
        });
      } else {
        // Non-Recurring Meeting
        const selectedEvent = nonRecurringEvents
          .map((x) => x.items)
          .flat()
          .find((x) => x.id === meetingId);

        const rule = new RRule({
          dtstart:
            selectedEvent && selectedEvent.start
              ? mapDateAsUTC(new Date(selectedEvent.start.dateTime))
              : new Date(),
          count: 1,
          tzid: Intl.DateTimeFormat().resolvedOptions().timeZone,
        });

        onMeetingChange(
          meetingId,
          meetingName,
          rule.toString(),
          getTimeDuration(selectedEvent),
        );

        trackOnboardingActionEvent({
          action: ACCOUNT_ONBOARDING_EVENTS.SELECT_MEETING,
          meetingTitle: meetingName,
          meetingRecurs: false,
        });
      }

      setRecurringEvents(
        recurringEvents.map((item) => {
          return {
            ...item,
            isChecked: item.id === meetingId,
          };
        }),
      );

      setNonRecurringEvents(
        nonRecurringEvents.map((item) => {
          return {
            title: item.title,
            items: item.items.map((event) => {
              return {
                ...event,
                isChecked: event.id === meetingId,
              };
            }),
          };
        }),
      );
    },
    [nonRecurringEvents, onMeetingChange, recurringEvents],
  );
  useEffect(() => {
    if (eventsList && eventsList.length) {
      const mappedRecurringEvents: MeetingItem[] = eventsList
        .filter((event) => event.recurrence !== null)
        .map((event) => ({
          id: event.id,
          isStarted: false,
          isChecked: false,
          end: event.end,
          start: event.start,
          title: event.summary,
          status: ComponentStatus.LOADED,
          participants:
            event.attendeesCount !== 0 && providerName === 'outlook'
              ? event.attendeesCount + 1
              : event.attendeesCount,
          recurrence: event.recurrence?.length
            ? event.recurrence[event.recurrence.length - 1]
            : '',
          subTitle: `${getRRuleStringFromEvent(event)}
            ${getFormattedTimeFromDate(
              new Date(event.start.dateTime),
            )} - ${getFormattedTimeFromDate(new Date(event.end.dateTime))}`,
        }));

      setRecurringEvents(mappedRecurringEvents);
    } else {
      setRecurringEvents([]);
    }
  }, [eventsList, providerName]);

  useEffect(() => {
    if (eventsList && eventsList.length) {
      const mappedNonRecurringEvents = eventsList
        .filter(
          (event) =>
            isEmpty(event.recurrence) && !isEmpty(event.start.dateTime),
        )
        .sort((first, second) => {
          return (
            new Date(first.start.dateTime).getTime() -
            new Date(second.start.dateTime).getTime()
          );
        })
        .map((event) => {
          return {
            id: event.id,
            isStarted: false,
            isChecked: false,
            title: event.summary,
            status: ComponentStatus.LOADED,
            participants:
              event.attendeesCount !== 0 && providerName === 'outlook'
                ? event.attendeesCount + 1
                : event.attendeesCount,
            subTitle: `${getDayFromDate(
              new Date(event.start.dateTime),
            )} at  ${getFormattedTimeFromDate(
              new Date(event.start.dateTime),
            )} - ${getFormattedTimeFromDate(new Date(event.end.dateTime))}`,
            start: event.start,
            end: event.end,
            date: new Date(event.start.dateTime).toLocaleDateString(),
          };
        });

      const groupedItems = groupBy(mappedNonRecurringEvents, 'date');
      const nonRecurringItems = Object.keys(groupedItems).map((key) => ({
        title: key,
        items: groupedItems[key],
      }));

      const formattedNonRecurringItems: NonRecurringItem[] = [];

      for (let i = 0; i < nonRecurringItems.length; i++) {
        const item = nonRecurringItems[i];
        const nextItem = nonRecurringItems[i + 1];
        const currentItemDate = new Date(item.items[0].start.dateTime);
        const nextItemDate = new Date(nextItem?.items[0].start.dateTime);

        const newTitle = `${getDayFromDate(
          currentItemDate,
        )} ${currentItemDate.toLocaleString('en-US', {
          month: 'short',
        })} ${currentItemDate.getDate()}, ${currentItemDate.getFullYear()}`;

        formattedNonRecurringItems.push({
          title: newTitle,
          items: item.items,
        });

        if (i + 1 < nonRecurringItems.length) {
          const diff = Math.abs(
            nextItemDate.getDate() - currentItemDate.getDate(),
          );
          const diffDays = Math.ceil(diff / (1000 * 60 * 60 * 24));
          if (diffDays >= 1) {
            currentItemDate.setDate(currentItemDate.getDate() + 1);
            nextItemDate.setDate(nextItemDate.getDate() - 1);
            const title = `${getDayFromDate(
              currentItemDate,
            )} ${currentItemDate.toLocaleString('en-US', {
              month: 'short',
            })} ${currentItemDate.getDate()}, ${currentItemDate.getFullYear()} - ${getDayFromDate(
              nextItemDate,
            )} ${nextItemDate.toLocaleString('en-US', {
              month: 'short',
            })} ${nextItemDate.getDate()}, ${nextItemDate.getFullYear()}`;

            formattedNonRecurringItems.push({
              title: title,
              items: [],
            });
          }
        }
      }

      setNonRecurringEvents(formattedNonRecurringItems);
    } else {
      setNonRecurringEvents([]);
    }
  }, [eventsList, providerName]);

  const handleOnMeetingTypeChanged = useCallback(
    (meetingType: string | number) => {
      setSelectedMeetingType(
        meetingTypeDropdownItems[0].items[+meetingType - 1],
      );
    },
    [],
  );

  const handleExploreOnOwnClick = useCallback(() => {
    onQuickSetupStepChange(QuickSetupStep.COMPLETION);
    history.push(`${QUICK_SETUP}?step=COMPLETION`);
    mutateQuickSetupStatus({
      direction: QuickSetupDirectionEnum.FORWARD,
      status: QuickSetupStatusEnum.SKIPPED,
    });
  }, [history, mutateQuickSetupStatus, onQuickSetupStepChange]);

  const meetingListComponentStatus = useMemo(() => {
    if (isEventsListError || isCalendarListError) {
      return ComponentStatus.ERROR;
    }
    if (isEventsListLoading || isEventsListFetching || !calenderId) {
      return ComponentStatus.LOADING;
    }
    if (
      ((meetingTypeDropdownItems[0].items[0].id === selectedMeetingType.id &&
        recurringEvents.length === 0) ||
        (meetingTypeDropdownItems[0].items[1].id === selectedMeetingType.id &&
          nonRecurringEvents.length === 0)) &&
      !isEventsListLoading &&
      !isEventsListFetching &&
      calenderId
    ) {
      return ComponentStatus.EMPTY;
    }
    if (isEventsListError || isCalendarListError) {
      return ComponentStatus.ERROR;
    }
    return ComponentStatus.LOADED;
  }, [
    calenderId,
    isCalendarListError,
    isEventsListError,
    isEventsListFetching,
    isEventsListLoading,
    recurringEvents.length,
    selectedMeetingType,
    nonRecurringEvents.length,
  ]);

  return {
    models: {
      recurringEvents,
      nonRecurringEvents,
      selectedMeetingType,
      calendarSelectBarProps,
      meetingTypeDropdownItems,
      meetingListComponentStatus,
    },
    operations: {
      handleOnMeetingSelected,
      handleExploreOnOwnClick,
      handleOnMeetingTypeChanged,
    },
  };
};

export default useMeetingSelectionController;
