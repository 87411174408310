import React from 'react';
import { CloseInfoPanel, InfoPanel, Nav, StyledIconButton } from './styles';
import { PrimaryNavbarProps } from './interface';
import SVGIcon from '../../atoms/SVGIcon';
import ThemeV2 from '../../../componentsV2/theme';

const PrimaryNavbar = (props: PrimaryNavbarProps) => {
  const {
    isLeftAsideOpen,
    isRightAsideOpen,
    toggleLeftAsideOpen,
    renderInfoPanelContent,
    isLeftNavHovered,
    setLeftNavHoveredToTrue,
    setLeftNavHoveredToFalse,
    isKioskTurnedOn,
  } = props;

  return (
    <>
      <Nav
        isLeftAsideOpen={isLeftAsideOpen}
        isRightAsideOpen={isRightAsideOpen}
        aria-label="primary navigation menu"
        role="navigation"
        onMouseEnter={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
          const target = e.target as HTMLButtonElement;
          if (target.id !== 'float-button') {
            setLeftNavHoveredToTrue();
          }
        }}
        onMouseLeave={() => {
          setLeftNavHoveredToFalse();
        }}
        isHovered={isLeftNavHovered}
      >
        <InfoPanel
          isLeftAsideOpen={isLeftAsideOpen}
          isHovered={isLeftNavHovered}
        >
          <CloseInfoPanel onClick={toggleLeftAsideOpen}>
            <SVGIcon icon="round-close" color={ThemeV2.palette.gray6} />
          </CloseInfoPanel>
          {renderInfoPanelContent({
            isHoveredWhenCollapsed: isLeftNavHovered && !isLeftAsideOpen,
          })}
        </InfoPanel>
        <StyledIconButton
          type="button"
          isActive
          onClick={toggleLeftAsideOpen}
          isLeftAsideOpen={isLeftAsideOpen}
          isRightAsideOpen={isRightAsideOpen}
          isHovered={isLeftNavHovered}
          id="float-button"
          isKioskTurnedOn={isKioskTurnedOn}
        >
          {isLeftAsideOpen ? (
            <SVGIcon icon="carat-left" size="20px" />
          ) : (
            <SVGIcon icon="carat-right" size="20px" />
          )}
        </StyledIconButton>
      </Nav>
    </>
  );
};

const MemoizedPrimaryNav = React.memo(PrimaryNavbar);
MemoizedPrimaryNav.displayName = 'PrimaryNavbar';

export default MemoizedPrimaryNav;
