// @ts-nocheck TODO: type issues need to be fixed in this file
import React from 'react';
import { PulseLoader } from 'react-spinners';
import Grid from '@material-ui/core/Grid';

import themeV2 from '../../componentsV2/theme';

const LoadingPulse = (props) => (
    <Grid
      container
      justify="center"
      style={props.hasPadding ? { padding: 16 } : { margin: '16 0' }}
    >
      {/* <Grid item xs={1} alignItems="center"> */}
      <PulseLoader
        sizeUnit="px"
        size={15}
        color={themeV2.palette.primary.main}
        loading={props.isLoading}
      />
      {/* </Grid> */}
    </Grid>
);

export default LoadingPulse;
