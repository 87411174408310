import { AutocompleteDropdownItem } from '../../../atomic/organism/Autocomplete/interfaces';
import { MemberFromAPI } from '../../../interfaces/UserProfile';

export const transformMemberToAutoCompleteDropdownItem = (
  member: MemberFromAPI,
): AutocompleteDropdownItem<string> => {
  const fullName = `${member.profile.firstName} ${member.profile.lastName}`;

  return {
    title: fullName,
    id: member.memberId,
    avatar: {
      img: member.profile.image,
      name: fullName,
      userId: member?.memberId,
    },
  };
};
