import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Flex } from '../../Utils/styles/display';
import Image from '../../atomic/atoms/Image';
import KnowledgeCenterCardGrid from '../../atomic/organism/KnowledgeCenterCardGrid';
import KnowledgeCenterList from '../../atomic/organism/KnowledgeCenterList';
import FilePreviewModal from '../../atomic/molecules/FilePreviewModal';
import useKnowledgeCenterController from './useKnowledgeCenterController';
import KnowledgeCenterLoadingImage from '../../img/opening-folder.gif';
import KnowledgeCenterErrorImage from '../../img/error.svg';
import KnowledgeCenterEmptyImage from '../../img/group-waiting.svg';
import {
  KNOWLEDGE_CENTER_LOADING,
  KNOWLEDGE_CENTER_LOADING_SUB,
  KNOWLEDGE_CENTER_EMPTY,
  KNOWLEDGE_CENTER_EMPTY_SUB,
  KNOWLEDGE_CENTER_ERROR,
  KNOWLEDGE_CENTER_ERROR_SUB,
} from '../../languages/en/header';
import Body from '../../atomic/atoms/Body';
import useLayoutStore from '../../stores/layoutStore';
import { LayoutStoreState } from '../../interfaces/Layout';
import KnowledgeCenterSearch, {
  KnowledgeCenterLayout,
} from '../../atomic/molecules/KnowledgeCenterSearch';

const StyledImage = styled(Image)`
  margin: 48px 0;
`;

const layoutSelector = (state: LayoutStoreState) =>
  state.setRightAsideOpenToFalse;

const KnowledgeCenterController = () => {
  const {
    isLoading,
    data,
    selectedFile,
    fileToPreview,
    hasNextPage,
    handleFetchNextPage,
    handleSetSelectedFile,
    onDownloadFileClick,
    copyPostLink,
  } = useKnowledgeCenterController();
  const [modalOpen, setModalOpen] = useState(false);
  const [activeView, setActiveView] = useState<KnowledgeCenterLayout>(
    KnowledgeCenterLayout.card,
  );

  const setRightAsideOpenToFalse = useLayoutStore(layoutSelector);

  useEffect(() => {
    setRightAsideOpenToFalse();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return (
      <Flex
        flexDirection="column"
        alignItems="center"
        margin="32px 0 0"
        data-testid="loading-kc"
      >
        <StyledImage
          alt={KNOWLEDGE_CENTER_LOADING}
          src={KnowledgeCenterLoadingImage}
        />

        <Body variant="subHead2Bold">{KNOWLEDGE_CENTER_LOADING}</Body>

        <Body variant="body1">{KNOWLEDGE_CENTER_LOADING_SUB}</Body>
      </Flex>
    );
  } else if (data?.pages?.length === 0 || data?.pages[0].data?.length === 0) {
    return (
      <Flex
        flexDirection="column"
        alignItems="center"
        margin="32px 0 0"
        data-testid="empty-kc"
      >
        <StyledImage
          alt={KNOWLEDGE_CENTER_EMPTY}
          src={KnowledgeCenterEmptyImage}
        />

        <Body variant="subHead2Bold">{KNOWLEDGE_CENTER_EMPTY}</Body>

        <Body variant="body1" style={{ width: '315px', marginTop: '4px' }}>
          {KNOWLEDGE_CENTER_EMPTY_SUB}
        </Body>
      </Flex>
    );
  } else if (!data) {
    return (
      <Flex
        flexDirection="column"
        alignItems="center"
        margin="32px 0 0"
        data-testid="error-kc"
      >
        <StyledImage
          alt={KNOWLEDGE_CENTER_ERROR}
          src={KnowledgeCenterErrorImage}
        />

        <Body variant="subHead2Bold">{KNOWLEDGE_CENTER_ERROR}</Body>

        <Body variant="body1">{KNOWLEDGE_CENTER_ERROR_SUB}</Body>
      </Flex>
    );
  } else {
    return (
      <div style={{ padding: '8px', height: '100%' }}>
        <KnowledgeCenterSearch
          invokeSearch={console.log('trying2Search')}
          activeView={activeView}
          setActiveView={setActiveView}
        />
        {activeView === KnowledgeCenterLayout.card ? (
          <KnowledgeCenterCardGrid
            data={data}
            hasNextPage={hasNextPage}
            handleFetchNextPage={handleFetchNextPage}
            handleSetSelectedFile={handleSetSelectedFile}
            copyPostLink={copyPostLink}
            onDownloadFileClick={onDownloadFileClick}
            setModalOpen={setModalOpen}
          />
        ) : (
          <KnowledgeCenterList
            data={data}
            hasNextPage={hasNextPage}
            handleFetchNextPage={handleFetchNextPage}
            handleSetSelectedFile={handleSetSelectedFile}
            copyPostLink={copyPostLink}
            onDownloadFileClick={onDownloadFileClick}
            setModalOpen={setModalOpen}
          />
        )}
        <FilePreviewModal
          person={selectedFile?.respondent}
          createdAt={selectedFile?.fileInfo.createdAt}
          fileInformation={selectedFile?.fileInfo}
          fileToPreview={fileToPreview}
          isOpen={modalOpen}
          onClickDownload={() => onDownloadFileClick(selectedFile)}
          onCloseModal={() => {
            setModalOpen(false);
            handleSetSelectedFile(undefined);
          }}
          flow={selectedFile?.flowInfo}
          visibility={selectedFile?.flowInfo.visibility}
          data-testid="knowledge-center-file-previewer"
        />
      </div>
    );
  }
};

export default KnowledgeCenterController;
