import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useMemo,
} from 'react';
import { useSelector } from 'react-redux';

import IdentityUserSelectionPage from '../../atomic/pages/IdentityUserSelectionPage';
import accountManagementTypes from '../../Utils/identity/accountManagementTypes';
import {
  buttonText,
  accountManagementTypeLanguageMap,
  selectPeopleEntityTableHeadings,
  userSelectSelectedUsersIconInfo,
  getEntityTableSearchBoxPlaceHolder,
  getSelectUsersNextButtonText,
} from '../../Utils/data/identityManager/common';
import {
  EntityListChangeData,
  EntityListItem,
  IdentitySelectPeoplePageContainerProps,
  IntegrationDataEntity,
  IntegrationDataMember,
} from '../../interfaces/identityManager/common';
import IMStatus from '../../Utils/identity/status';
import { checkIMUserGroupAccess } from '../../modules/admin/billing/account/selector';
import PlanUpgradeModalContainer from '../PlanUpgradeModalContainer';
import useRequest from '../../hooks/useRequest/useRequest';
import { GET_IM_ENTITY_COUNT } from '../../constants/endpoints';
import {
  calculateInitialEntityList,
  EntityCountMapType,
  getCheckedValue,
  getIdsFromList,
  getMembersCount,
  getSelectedCount,
} from '../../Utils/identity/selectPeople';
import usePrevious from '../../hooks/usePrevious';
import { MERGE } from '../../Utils/identity/allowedSSOs';
import debounce from 'lodash/debounce';
import uniqBy from 'lodash/uniqBy';
import { useGetIntegrationData } from '../../queries/Admin/Common';
import {
  SplitNames,
  TreatmentTypes,
} from '../../hooks/useSplitSdkConfig/constants';
import { useFeatureSplit } from '../../hooks/useFeatureSplit';
import { INTEGRATION_DATA_KIND } from '../../constants/im';
import { HALF_SECOND } from '../../Utils/constants/times';
import { useHistory } from 'react-router-dom';
import routesList from '../../aV2/routes/routesList';

interface UserGroupRadioOptionProps {
  value: string;
  label: string;
  disabled?: boolean;
  upgradeInfo?: {
    upgradeText: string;
    onUpgradeClick?: () => void;
    toolTipText: string;
  };
}

const initialAccountManagementListSelections = {
  allList: [],
  newList: [],
  removeList: [],
};

const IdentitySelectPeoplePageContainer = ({
  identityManager,
  accountManagementType: accountManagementTypeFromBackend,
  integrationData,
  heading,
  subHeading,
  title,
  steps,
  activeStep,
  radioGroupOptions,
  onCancelClick,
  onSelectPeopleDone,
  startPollingIntegrationData,
  accountManagementList,
  accountManagementDeselectionList,
  IMsMetadata,
  helperLink,
}: IdentitySelectPeoplePageContainerProps) => {
  const history = useHistory();
  const { id, status } = identityManager;
  const [accountManagementListSelections, setAccountManagementListSelections] =
    useState<EntityListChangeData>(initialAccountManagementListSelections);
  const [entityList, setEntityList] = useState<EntityListItem[] | null>(null);
  const [paginatedDataAddedEntityList, setPaginatedDataAddedEntityList] =
    useState<EntityListItem[] | []>([]);
  const [
    paginatedDataAddedEntityListToRender,
    setPaginatedDataAddedEntityListToRender,
  ] = useState<EntityListItem[] | []>([]);

  const [paginatedDataRemovedEntityList, setPaginatedDataRemovedEntityList] =
    useState<EntityListItem[] | []>([]);
  const [
    paginatedDataRemovedEntityListToRender,
    setPaginatedDataRemovedEntityListToRender,
  ] = useState<EntityListItem[] | []>([]);

  const [accountManagementType, setAccountManagementType] = useState<string>(
    accountManagementTypeFromBackend || accountManagementTypes.ALL_USERS,
  );
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState<boolean>(false);
  const { treatment: adminRefactorTreatment } = useFeatureSplit(
    SplitNames.ADMIN_NEW_APP,
  );

  const isAdminTreatmentOn = adminRefactorTreatment === TreatmentTypes.ON;

  const getIntegrationDataKind = (accManagementType: string) => {
    if (accManagementType === accountManagementTypes.COMPANIES) {
      return INTEGRATION_DATA_KIND.COMPANY;
    }
    if (accManagementType === accountManagementTypes.GROUPS) {
      return INTEGRATION_DATA_KIND.TEAM;
    }
    if (accManagementType === accountManagementTypes.SELECTED_USERS) {
      return INTEGRATION_DATA_KIND.EMPLOYEE;
    }
    return '';
  };

  const [integrationDataKind, setIntegrationDataKind] = useState(
    getIntegrationDataKind(
      accountManagementTypeFromBackend || accountManagementTypes.ALL_USERS,
    ),
  );

  const [allEntitySearchText, setAllEntitySearchText] = useState<string>('');
  const [apiAllEntitySearchText, setApiAllEntitySearchText] =
    useState<string>('');
  const [hasPaginatedDataJobPollSuccess, setHasPaginatedDataJobPollSuccess] =
    useState(false);

  const { treatment: paginatedIntegrationDataTreatment } = useFeatureSplit(
    SplitNames.PAGINATED_INTEGRATION_DATA,
  );

  const isPaginatedIntegrationDataEnabled =
    paginatedIntegrationDataTreatment !== TreatmentTypes.OFF;

  const hasPaginatedIntegrationData =
    id === MERGE && isPaginatedIntegrationDataEnabled;

  const clearSearch = () => {
    setAllEntitySearchText('');
    setApiAllEntitySearchText('');
  };

  const resetAddedItems = () => {
    setAccountManagementListSelections({
      allList: [],
      newList: [],
      removeList: [],
    });
    setEntityList([]);
    setPaginatedDataAddedEntityList([]);
    setPaginatedDataAddedEntityListToRender([]);
    setPaginatedDataRemovedEntityList([]);
    setPaginatedDataRemovedEntityListToRender([]);
  };

  const {
    refetch: refetchIntegrationData,
    data: paginatedIntegrationData,
    fetchNextPage,
    hasNextPage,
    error: paginatedIntegrationDataError,
  } = useGetIntegrationData({
    type: id.toLowerCase(),
    enabled:
      hasPaginatedIntegrationData &&
      Boolean(integrationDataKind) &&
      Boolean(integrationData),
    keyword: apiAllEntitySearchText,
    kind: integrationDataKind,
  });

  const handleFetchNextPage = useCallback(() => {
    fetchNextPage();
  }, [fetchNextPage]);

  let selectUsersScreenNextButtonText =
    buttonText.STEP_SELECT_PEOPLE_NEXT_DEFAULT;
  let selectUsersScreenNextButtonDisabled = true;
  const { allList, newList, removeList } = accountManagementListSelections;
  const selectedLength = getSelectedCount(allList);
  const usersScreenNextButtonText = () => {
    selectUsersScreenNextButtonText = getSelectUsersNextButtonText(
      accountManagementType,
      selectedLength,
    );
  };

  const allItems = useMemo(() => {
    if (
      !paginatedIntegrationData ||
      !paginatedIntegrationData.pages ||
      !Array.isArray(paginatedIntegrationData.pages[0].data)
    ) {
      return [];
    }
    if (!hasPaginatedDataJobPollSuccess) {
      setHasPaginatedDataJobPollSuccess(true);
    }
    return paginatedIntegrationData.pages.reduce<
      IntegrationDataMember[] | IntegrationDataEntity[]
    >((arr, page) => [...arr, ...page.data], []);
  }, [hasPaginatedDataJobPollSuccess, paginatedIntegrationData]);

  useEffect(() => {
    if (hasPaginatedIntegrationData && integrationData) {
      if (integrationDataKind) {
        refetchIntegrationData();
      }
    }
  }, [
    integrationDataKind,
    refetchIntegrationData,
    apiAllEntitySearchText,
    hasPaginatedIntegrationData,
    integrationData,
  ]);

  useEffect(() => {
    if (paginatedIntegrationDataError) {
      // Error, while load more: Data expired, redirect to manage page, so again jobPoll call will start
      setHasPaginatedDataJobPollSuccess(false);
      if (isAdminTreatmentOn) {
        window.location.href = `${routesList.ADMIN_USER_MANAGE}`;
      } else {
        history.push(routesList.ADMIN_USER_MANAGE_LEGACY);
      }
    }
  }, [history, isAdminTreatmentOn, paginatedIntegrationDataError]);

  useEffect(() => {
    if (!apiAllEntitySearchText) {
      if (integrationData && integrationData?.accountManagementType) {
        if (paginatedIntegrationData?.pages[0].integrationDataJob) {
          if (hasPaginatedDataJobPollSuccess) {
            // Error: Data expired, redirect to manage page, so again jobPoll call will start
            setHasPaginatedDataJobPollSuccess(false);
            // history.push(routesList.ADMIN_USER_MANAGE_LEGACY);
            window.location.href = `${routesList.ADMIN_USER_MANAGE}`;
          }
        }
      }
    }
  }, [
    apiAllEntitySearchText,
    hasPaginatedDataJobPollSuccess,
    history,
    integrationData,
    paginatedIntegrationData,
  ]);

  const handleRadioGroupChange = useCallback(
    (e) => {
      const accManagementType = e.target.value;
      setEntityList(null);
      if (hasPaginatedIntegrationData) {
        clearSearch();
        resetAddedItems();
      }

      setAccountManagementType(accManagementType);

      if (hasPaginatedIntegrationData) {
        setIntegrationDataKind(getIntegrationDataKind(accManagementType));
      }

      if (
        integrationData === null &&
        accManagementType !== accountManagementTypes.ALL_USERS &&
        startPollingIntegrationData
      ) {
        startPollingIntegrationData();
      }
    },
    [integrationData, hasPaginatedIntegrationData, startPollingIntegrationData],
  );
  const [entityCountMap, setEntityCountMap] = useState<EntityCountMapType>({});

  const debounceSearchText = useRef(
    debounce((newValue: string) => {
      setApiAllEntitySearchText(newValue);
    }, HALF_SECOND),
  );

  const handleAllEntitySearchText = useCallback((newValue: string) => {
    setAllEntitySearchText(newValue);
    debounceSearchText.current(newValue);
  }, []);

  const {
    response: entityCountResponse,
    fireRequest: makeGetIMEntityCountRequest,
  } = useRequest(GET_IM_ENTITY_COUNT);

  const previousEntityCountResponse = usePrevious(entityCountResponse);
  useEffect(() => {
    // We check if response is present
    if (
      entityCountResponse &&
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      /* @ts-ignore */
      entityCountResponse.data
    ) {
      // We see if its the first time we got response and if not, then it must be a new data
      // only then we proceed to update entityCountMap
      if (
        !previousEntityCountResponse ||
        previousEntityCountResponse !== entityCountResponse
      ) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        /* @ts-ignore */
        const { accountId, count } = entityCountResponse?.data;
        const newEntityCountMap = { ...entityCountMap };
        newEntityCountMap[accountId] = { isLoading: false, count };
        setEntityCountMap(newEntityCountMap);
      }
    }
  }, [entityCountMap, entityCountResponse, previousEntityCountResponse]);

  const fetchEntityCount = useCallback(
    (entityId: string) => {
      makeGetIMEntityCountRequest({
        params: {
          identityName: id,
          accountManagementType,
          accountId: entityId,
          applicationCacheId: integrationData?.applicationCacheId,
        },
      });
      const newEntityCountMap = { ...entityCountMap };
      newEntityCountMap[entityId] = { isLoading: true, count: 0 };
      setEntityCountMap(newEntityCountMap);
    },
    [
      accountManagementType,
      entityCountMap,
      id,
      integrationData,
      makeGetIMEntityCountRequest,
    ],
  );

  const handleItemClick = useCallback(
    ({ id: entityId }: EntityListItem) => {
      if (
        entityId &&
        !entityCountMap[entityId] &&
        accountManagementType !== accountManagementTypes.SELECTED_USERS
      ) {
        if (paginatedIntegrationData) {
          fetchEntityCount(entityId);
        }

        if (!paginatedIntegrationData && id !== MERGE) {
          fetchEntityCount(entityId);
        }
      }
    },
    [
      entityCountMap,
      accountManagementType,
      paginatedIntegrationData,
      id,
      fetchEntityCount,
    ],
  );

  const handleEntityListChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    ({ allList, newList, removeList }: EntityListChangeData) => {
      setAccountManagementListSelections({
        allList,
        newList,
        removeList,
      });
    },
    [],
  );

  useEffect(() => {
    setPaginatedDataAddedEntityList(
      uniqBy([...paginatedDataAddedEntityList, ...newList], 'id'),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newList]);

  useEffect(() => {
    setPaginatedDataRemovedEntityList(
      uniqBy([...paginatedDataRemovedEntityList, ...removeList], 'id'),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeList]);

  useEffect(() => {
    const allListIds = allList.map((item) => item.id);
    const newAddedItems = paginatedDataAddedEntityList.filter((item) => {
      if (allListIds.includes(item.id)) {
        const itemId = item.id;
        const itemIndex = allItems.findIndex((i) => i.id === itemId);
        if (itemIndex !== -1) {
          return allList[itemIndex].checked;
        }
        return false;
      }
      return true;
    });

    setPaginatedDataAddedEntityListToRender(newAddedItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allList, paginatedDataAddedEntityList]);

  useEffect(() => {
    const allListIds = allList.map((item) => item.id);
    const newRemovedItems = paginatedDataRemovedEntityList.filter((item) => {
      if (allListIds.includes(item.id)) {
        const itemId = item.id;
        const itemIndex = allItems.findIndex((i) => i.id === itemId);
        if (itemIndex !== -1) {
          if (allList[itemIndex].isSelected) {
            if (entityList) {
              return !entityList[itemIndex].checked;
            }
            return false;
          }
          return false;
        }
        return false;
      }
      return true;
    });

    setPaginatedDataRemovedEntityListToRender(newRemovedItems);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allList, entityList, paginatedDataRemovedEntityList]);

  const handleNextClick = () => {
    if (accountManagementType !== accountManagementTypes.ALL_USERS) {
      const selectedFromAllList =
        accountManagementListSelections.allList.filter((listItem) =>
          getCheckedValue(listItem),
        );
      const newResult = {
        autoApproveMembers: integrationData?.autoApproveMembers,
        autoSendInvites: integrationData?.autoSendInvites,
        applicationCacheId: integrationData?.applicationCacheId,
        accountManagementType,
        accountManagementList: getIdsFromList(selectedFromAllList),
        accountManagementDeselectionList: getIdsFromList(
          accountManagementListSelections.removeList,
        ),
      };
      onSelectPeopleDone(newResult);
    } else {
      onSelectPeopleDone({
        autoApproveMembers: integrationData?.autoApproveMembers,
        autoSendInvites: integrationData?.autoSendInvites,
        applicationCacheId: integrationData?.applicationCacheId,
        accountManagementType,
        accountManagementList: [],
        accountManagementDeselectionList: [],
      });
    }
  };

  const isIMUserGroupsAccessActive = useSelector(checkIMUserGroupAccess);
  const [userSelectRadioGroupOptions, setUserSelectRadioGroupOptions] =
    useState<UserGroupRadioOptionProps[]>([]);
  const handleUpgradeClick = () => setIsUpgradeModalOpen(true);
  const handleModalClose = () => setIsUpgradeModalOpen(false);
  useEffect(() => {
    let availableRadioGroupOptions = radioGroupOptions;
    if (IMsMetadata) {
      if (IMsMetadata.availableAccountManagementTypes) {
        availableRadioGroupOptions = radioGroupOptions.filter(
          (option: UserGroupRadioOptionProps) =>
            IMsMetadata?.availableAccountManagementTypes?.indexOf(
              option.value,
            ) !== -1,
        );
      }
    }
    if (isIMUserGroupsAccessActive) {
      setUserSelectRadioGroupOptions(availableRadioGroupOptions);
    } else {
      setUserSelectRadioGroupOptions(
        availableRadioGroupOptions.map(
          (radioOption: UserGroupRadioOptionProps) => {
            if (
              radioOption.value === accountManagementTypes.ALL_USERS ||
              radioOption.value === accountManagementTypes.SELECTED_USERS
            ) {
              return radioOption;
            }
            return {
              ...radioOption,
              disabled: true,
              upgradeInfo: {
                upgradeText: 'Upgrade now',
                onUpgradeClick: handleUpgradeClick,
                toolTipText: 'This is a premium feature',
              },
            };
          },
        ),
      );
    }
  }, [isIMUserGroupsAccessActive, radioGroupOptions, IMsMetadata]);

  // List already containing selected entities and no new additions OR a new addition
  const canContinueWithSelectedEntities =
    (newList.length === 0 && selectedLength > 0) || newList.length > 0;
  if (accountManagementType === accountManagementTypes.ALL_USERS) {
    selectUsersScreenNextButtonText =
      buttonText.STEP_SELECT_PEOPLE_NEXT_ALL_USERS;
    selectUsersScreenNextButtonDisabled = false;
  } else if (removeList.length > 0) {
    selectUsersScreenNextButtonText = buttonText.STEP_SELECT_PEOPLE_NEXT_EDIT;
    selectUsersScreenNextButtonDisabled = false;
  } else if (accountManagementType === accountManagementTypes.BUSINESS_UNIT) {
    if (canContinueWithSelectedEntities) {
      usersScreenNextButtonText();
      selectUsersScreenNextButtonDisabled = false;
    }
  } else if (accountManagementType === accountManagementTypes.DEPARTMENT) {
    if (canContinueWithSelectedEntities) {
      usersScreenNextButtonText();
      selectUsersScreenNextButtonDisabled = false;
    }
  } else if (accountManagementType === accountManagementTypes.CHANNELS) {
    if (canContinueWithSelectedEntities) {
      usersScreenNextButtonText();
      selectUsersScreenNextButtonDisabled = false;
    }
  } else if (
    accountManagementType === accountManagementTypes.GROUPS ||
    accountManagementType === accountManagementTypes.COMPANIES
  ) {
    if (canContinueWithSelectedEntities) {
      usersScreenNextButtonText();
      selectUsersScreenNextButtonDisabled = false;
    }
  } else if (
    accountManagementType === accountManagementTypes.ORGANIZATIONAL_UNIT
  ) {
    if (canContinueWithSelectedEntities) {
      usersScreenNextButtonText();
      selectUsersScreenNextButtonDisabled = false;
    }
  } else if (accountManagementType === accountManagementTypes.SELECTED_USERS) {
    if (canContinueWithSelectedEntities) {
      usersScreenNextButtonText();
      selectUsersScreenNextButtonDisabled = false;
    }
  }
  // We are waiting for applicationCacheId to enable the button
  // along with selectUsersScreenNextButtonDisabled logic
  const nextButton = {
    hidden: false,
    disabled:
      selectUsersScreenNextButtonDisabled ||
      !integrationData?.applicationCacheId ||
      (hasPaginatedIntegrationData &&
        paginatedIntegrationData?.pages[0].integrationDataJob),
    text: selectUsersScreenNextButtonText,
    onClick: handleNextClick,
  };

  const cancelButton = {
    hidden: false,
    disabled: false,
    text: buttonText.STEP_WHAT_IS_THIS_CANCEL,
    onClick: onCancelClick,
  };

  const currentIntegrationData = useMemo(() => {
    let pageParams;

    if (paginatedIntegrationData) {
      pageParams = paginatedIntegrationData?.pages[0];
    }

    return {
      allDataItems: allItems,
      ...pageParams,
    };
  }, [allItems, paginatedIntegrationData]);

  const entityTableHeadings =
    selectPeopleEntityTableHeadings[accountManagementType];
  let allListCount = 0;
  let newListCount = 0;
  let removeListCount = 0;
  switch (accountManagementType) {
    case accountManagementTypes.BUSINESS_UNIT:
    case accountManagementTypes.DEPARTMENT:
    case accountManagementTypes.CHANNELS:
    case accountManagementTypes.GROUPS:
    case accountManagementTypes.COMPANIES:
    case accountManagementTypes.ORGANIZATIONAL_UNIT:
      if (hasPaginatedIntegrationData) {
        allListCount = currentIntegrationData?.total
          ? currentIntegrationData.total
          : 0;
      } else {
        allListCount = entityList ? entityList.length : 0;
      }

      newListCount = getMembersCount(
        hasPaginatedIntegrationData
          ? paginatedDataAddedEntityListToRender
          : newList,
      );
      removeListCount = getMembersCount(
        hasPaginatedIntegrationData
          ? paginatedDataRemovedEntityListToRender
          : removeList,
      );
      break;
    case accountManagementTypes.SELECTED_USERS:
      if (hasPaginatedIntegrationData) {
        allListCount = currentIntegrationData?.total
          ? currentIntegrationData.total
          : 0;
      } else {
        allListCount = entityList ? entityList.length : 0;
      }

      newListCount = hasPaginatedIntegrationData
        ? paginatedDataAddedEntityListToRender.length
        : newList.length;
      removeListCount = hasPaginatedIntegrationData
        ? paginatedDataRemovedEntityListToRender.length
        : removeList.length;
      break;
    case accountManagementTypes.ALL_USERS:
    default:
      allListCount = 0;
      newListCount = 0;
      removeListCount = 0;
      break;
  }
  const searchBoxPlaceHolder = getEntityTableSearchBoxPlaceHolder(
    accountManagementTypeLanguageMap[accountManagementType],
  );
  const entityTableHeadingOperation =
    status === IMStatus.CONNECTED ? 'to edit' : '';
  const allListEntityTableHeading = entityTableHeadings.allList
    .replace(':count', `${allListCount}`)
    .replace(':operation', entityTableHeadingOperation);
  const newListEntityTableHeading = entityTableHeadings.newList.replace(
    ':count',
    `${newListCount}`,
  );
  const removeListEntityTableHeading = entityTableHeadings.removeList.replace(
    ':count',
    `${removeListCount}`,
  );

  const updateData = (canResetState = true) => {
    if (
      (!hasPaginatedIntegrationData &&
        integrationData?.accountManagementType) ||
      (hasPaginatedIntegrationData &&
        currentIntegrationData?.accountManagementType)
    ) {
      let list = calculateInitialEntityList(
        accountManagementType,
        hasPaginatedIntegrationData ? currentIntegrationData : integrationData,
        entityCountMap,
      );

      if (list) {
        const newListIds = paginatedDataAddedEntityList.map((item) => item.id);
        if (
          accountManagementList.length > 0 ||
          accountManagementDeselectionList.length > 0 ||
          newListIds.length > 0
        ) {
          list = list.map((listItem) => {
            if (accountManagementList.indexOf(listItem.id) !== -1) {
              return {
                ...listItem,
                checked: !(listItem.isSelected && listItem.isSelected === true),
              };
            }
            if (accountManagementDeselectionList.indexOf(listItem.id) !== -1) {
              return {
                ...listItem,
                checked: true,
              };
            }
            if (newListIds.includes(listItem.id)) {
              return {
                ...listItem,
                checked: true,
              };
            }

            return listItem;
          });
        }
        setEntityList(list);
        if (canResetState) {
          setAccountManagementListSelections({
            allList: list,
            newList: [],
            removeList: [],
          });
        }
        setTimeout(() => {
          if (
            accountManagementType !== accountManagementTypes.SELECTED_USERS &&
            list
          ) {
            if (paginatedIntegrationData) {
              list
                // We want to fetch the count only for the items which user has interacted, not from server side
                .filter(({ checked }) => checked === true)
                .forEach((item) => fetchEntityCount(item.id));
            }

            if (!paginatedIntegrationData && id !== MERGE) {
              list
                // We want to fetch the count only for the items which user has interacted, not from server side
                .filter(({ checked }) => checked === true)
                .forEach((item) => fetchEntityCount(item.id));
            }
          }
          if (
            accountManagementType !== accountManagementTypes.SELECTED_USERS &&
            list
          ) {
            if (!paginatedIntegrationData && id === MERGE) {
              const newEntityCountMap = { ...entityCountMap };
              list.forEach((item) => {
                newEntityCountMap[item.id] = {
                  count: item.membersCount || 0,
                  isLoading: false,
                };
              });
              setEntityCountMap(newEntityCountMap);
            }
          }
        }, 100);
      }
    }
  };

  if (!entityList && currentIntegrationData) {
    updateData();
  }

  useEffect(() => {
    updateData(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIntegrationData]);

  const infoHeading = userSelectSelectedUsersIconInfo.heading;
  const infoSubheading = userSelectSelectedUsersIconInfo.subHeading;

  const isEditTableLoading = hasPaginatedIntegrationData
    ? !currentIntegrationData?.accountManagementType &&
      accountManagementType !== accountManagementTypes.ALL_USERS
    : integrationData === null &&
      accountManagementType !== accountManagementTypes.ALL_USERS;

  const canShowLoadingState = useMemo(() => {
    if (hasPaginatedIntegrationData) {
      if (!apiAllEntitySearchText) {
        if (accountManagementType === accountManagementTypes.ALL_USERS) {
          return !integrationData?.applicationCacheId;
        } else {
          return (
            !integrationData?.applicationCacheId ||
            !currentIntegrationData?.accountManagementType
          );
        }
      }
      return false;
    } else {
      return integrationData === null;
    }
  }, [
    accountManagementType,
    apiAllEntitySearchText,
    currentIntegrationData?.accountManagementType,
    hasPaginatedIntegrationData,
    integrationData,
  ]);

  const isJobPolling = !integrationData?.applicationCacheId;

  return (
    <>
      <IdentityUserSelectionPage
        hasPaginatedIntegrationData={hasPaginatedIntegrationData}
        isJobPolling={isJobPolling}
        handleFetchNextPage={handleFetchNextPage}
        hasNextPage={Boolean(hasNextPage)}
        allEntitySearchText={allEntitySearchText}
        handleAllEntitySearchText={handleAllEntitySearchText}
        entityList={entityList}
        paginatedDataAddedEntityList={paginatedDataAddedEntityListToRender}
        paginatedDataRemovedEntityList={paginatedDataRemovedEntityListToRender}
        allListEntityTableHeading={allListEntityTableHeading}
        newListEntityTableHeading={newListEntityTableHeading}
        removeListEntityTableHeading={removeListEntityTableHeading}
        searchBoxPlaceHolder={searchBoxPlaceHolder}
        onRadioGroupChange={handleRadioGroupChange}
        onEntityListChange={handleEntityListChange}
        onItemClick={handleItemClick}
        entityCountMap={entityCountMap}
        showInfo={
          accountManagementType === accountManagementTypes.SELECTED_USERS
        }
        // UI props
        heading={heading}
        subHeading={subHeading}
        title={title}
        steps={steps}
        activeStep={activeStep}
        cancelButton={cancelButton}
        nextButton={nextButton}
        radioGroupOptions={userSelectRadioGroupOptions}
        radioGroupValue={accountManagementType}
        infoHeading={infoHeading}
        infoSubheading={infoSubheading}
        isEditTableLoading={isEditTableLoading}
        helperLink={helperLink}
        showSelectAll={
          accountManagementType === accountManagementTypes.SELECTED_USERS
        }
        showLoadingState={canShowLoadingState}
      />

      {isUpgradeModalOpen && (
        <PlanUpgradeModalContainer onModalClose={handleModalClose} />
      )}
    </>
  );
};

export default IdentitySelectPeoplePageContainer;
