import { FLOW_FEED_HEADER_OPTIONS } from '../../../languages/en/flows';

export const allFlowFeedHeaderOptions = {
  copyFlowFeedLink: {
    value: FLOW_FEED_HEADER_OPTIONS.COPY_FLOW_FEED_LINK,
    id: 'copyFlowFeedLink',
    prefixIcon: 'Link',
    isSelected: false,
  },
  hideActivity: {
    value: FLOW_FEED_HEADER_OPTIONS.HIDE_ACTIVITY,
    id: 'hideActivity',
    prefixIcon: 'private',
    isSelected: false,
  },
  showActivity: {
    value: FLOW_FEED_HEADER_OPTIONS.SHOW_ACTIVITY,
    id: 'showActivity',
    prefixIcon: 'eye',
    isSelected: false,
  },
  editNotificationSettings: {
    value: FLOW_FEED_HEADER_OPTIONS.EDIT_NOTIFICATION_SETTINGS,
    id: 'editNotificationSettings',
    prefixIcon: 'settings-2',
    isSelected: false,
  },
  runFlowNow: {
    value: FLOW_FEED_HEADER_OPTIONS.RUN_FLOW_NOW,
    id: 'runFlowNow',
    prefixIcon: 'play',
    isSelected: false,
  },
  archiveFlow: {
    value: FLOW_FEED_HEADER_OPTIONS.ARCHIVE_FLOW,
    id: 'archiveFlow',
    prefixIcon: 'archive',
    isSelected: false,
    deleteItem: true,
    disabled: false,
  },
  remindParticipants: {
    value: FLOW_FEED_HEADER_OPTIONS.REMIND_PARTICIPANTS,
    id: 'remindParticipants',
    prefixIcon: 'active-notification',
    isSelected: false,
  },
  endOccurrence: {
    value: FLOW_FEED_HEADER_OPTIONS.END_OCCURRENCE,
    id: 'endOccurrence',
    prefixIcon: 'stop',
    isSelected: false,
  },
};
