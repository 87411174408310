import React from 'react';
import styled from 'styled-components';
import ThemeV2 from '../../../../componentsV2/theme';
import SVGIcon from '../../../atoms/SVGIcon';
import Body from '../../../atoms/Body';
import {
  FILE_UPLOAD_TITLE,
  FILE_UPLOAD_LIMIT,
} from '../../../../languages/en/flows/builder';
import { Flex } from '../../../../Utils/styles/display';
import {
  ContentBlockLocalProperties,
  ContentFileUploadBlockState,
  FileUploadBlockBuilderSettingState,
} from '../../../../interfaces/Flow/Builder';
import FormsEditorBaseInputBlock from '../FormsEditorInputBaseBlock';

export interface FormsEditorFileUploadInputBlockProps
  extends ContentBlockLocalProperties {
  blockData: ContentFileUploadBlockState;
}

const StyledWrapper = styled.div`
  margin: 16px 0 12px 0;
  border: 0.5px dashed ${ThemeV2.palette.geekBlue6};
  padding: 20px 0;
  width: 100%;
  border-radius: 4px;
`;

const FormsEditorFileUploadBlock = ({
  blockData,
  currentIndex,
  handleToggleIsDescriptionNeeded,
  handleToggleIsRequired,
  onValueChange,
  totalNumberOfItems,
  onDeleteClick,
  onMoveDownClick,
  onMoveUpClick,
}: FormsEditorFileUploadInputBlockProps) => {
  const { isRequired, description, assemblyCurrency } = blockData;

  const blockSettingState: FileUploadBlockBuilderSettingState = {
    type: 'FILE_UPLOAD',
    isRequired,
    isDescriptionNeeded: description !== null,
    toggleIsRequired: handleToggleIsRequired,
    toggleDescriptionNeeded: handleToggleIsDescriptionNeeded,
  };

  return (
    <FormsEditorBaseInputBlock
      blockData={blockData}
      currentIndex={currentIndex !== undefined ? currentIndex : 0}
      assemblyCurrency={assemblyCurrency}
      totalNumberOfItems={totalNumberOfItems}
      blockSettingState={blockSettingState}
      onDeleteClick={onDeleteClick}
      onMoveDownClick={onMoveDownClick}
      onMoveUpClick={onMoveUpClick}
      onValueChange={onValueChange}
    >
      <StyledWrapper>
        <Flex justifyContent="center" flexDirection="column">
          <SVGIcon icon="cloud-upload" />
          <Body variant="body1">{FILE_UPLOAD_TITLE}</Body>
          <Body variant="body2" color="gray6">
            {FILE_UPLOAD_LIMIT}
          </Body>
        </Flex>
      </StyledWrapper>
    </FormsEditorBaseInputBlock>
  );
};

export default FormsEditorFileUploadBlock;
