import styled from 'styled-components';
import ThemeV2 from '../../../componentsV2/theme';
import {
  device,
  PrimaryNavWidth,
  SecondaryNavWidth,
  PrimaryNavWidthMobile,
} from '../../../constants/layout';
import { LayoutStyleProps } from '../../../interfaces/Layout';
import { zIndexNav, zIndexPrimaryNav } from '../../../Utils/styles/z-index';

export const Nav = styled.nav<LayoutStyleProps>`
  position: fixed;
  display: flex;
  z-index: ${zIndexNav};
  transition: all 0.5s;
  width: 100vw;
  height: calc(100vh - 72px);

  @media ${device.tabletV2} {
    width: ${({ isLeftAsideOpen }) => (isLeftAsideOpen ? `288px` : `72px`)};
    bottom: unset;
    top: 72px;
  }
  @media ${device.tablet} {
    /* left: ${({ isRightAsideOpen }) =>
      isRightAsideOpen ? `-${SecondaryNavWidth}px` : '0px'}; */
  }
  @media ${device.mobile} {
    left: ${({ isLeftAsideOpen }) => (isLeftAsideOpen ? '0px' : '-100%')};
    /* width: ${({ isLeftAsideOpen }) => (isLeftAsideOpen ? '100%' : '0')}; */
  }
`;

export const PrimaryNav = styled.ul<LayoutStyleProps>`
  position: ${({ isLeftAsideOpen }) =>
    isLeftAsideOpen ? 'relative' : 'fixed'};
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  width: ${PrimaryNavWidthMobile}px;
  list-style: none;
  height: 100%;
  z-index: ${zIndexPrimaryNav};
  box-sizing: border-box;
  background-color: ${ThemeV2.palette.gray2};
  border-right: 1px solid ${ThemeV2.palette.gray4};
  @media ${device.tabletV2} {
    padding-top: 16px;
    width: ${PrimaryNavWidth}px;
    border-top: 0;
  }
  @media ${device.tablet} {
    position: fixed;
    /* left: 0px; */
  }
  ${({ hasAdminOption }) =>
    hasAdminOption &&
    `& li:last-of-type {
    margin-top: auto;
    margin-bottom: 16px;
  }`}
`;

export const InfoPanel = styled.div<LayoutStyleProps>`
  transition-duration: 0.5s;
  transition-timing-function: ease;
  transition-property: left;

  position: relative;
  /* left: ${({ isLeftAsideOpen }) =>
    isLeftAsideOpen ? '0' : `-${SecondaryNavWidth}px`}; */
  width: ${({ isLeftAsideOpen, isHovered }) =>
    !isLeftAsideOpen && !isHovered ? '72px' : `288px`};
  box-sizing: border-box;
  background-color: ${ThemeV2.palette.gray1};
  /* width: calc(100% - 16px - ${PrimaryNavWidthMobile}px); */
  height: calc(100vh - 72px);
  z-index: 1;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.04), 0px 3px 14px rgba(0, 0, 0, 0.02),
    0px 4px 5px rgba(0, 0, 0, 0.12);
  transition-duration: 0.5s;
  transition-timing-function: ease;
  transition-property: width;
  @media ${device.tabletV2} {
    box-shadow: none;
    /* width: ${SecondaryNavWidth}px; */
    width: ${({ isLeftAsideOpen, isHovered }) =>
      !isLeftAsideOpen && !isHovered ? '72px' : `288px`};
    border-right: 1px solid ${ThemeV2.palette.gray4};
  }
  @media ${device.tablet} {
    /* margin-left: ${`${PrimaryNavWidth}px`}; */
  }
  @media ${device.mobile} {
    overflow-y: auto;
    width: ${({ isLeftAsideOpen }) => (isLeftAsideOpen ? '100%' : '0')};
  }
`;

export const CloseInfoPanel = styled.span`
  width: 24px;
  height: 24px;
  position: absolute;
  right: 24px;
  top: 24px;
  @media ${device.tabletV2} {
    display: none;
  }
`;

export const IconButton = styled.button<{
  isActive?: boolean;
  showUnread?: number;
}>`
  background-color: ${({ isActive }) =>
    isActive ? ThemeV2.palette.gray1 : ThemeV2.palette.gray2};
  border: 0;
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  outline: none;
  width: 48px;
  height: 48px;
  box-shadow: ${({ isActive }) =>
    isActive
      ? `0px 2px 4px rgba(0, 0, 0, 0.04), 0px 3px 4px rgba(0, 0, 0, 0.02),
    0px 1px 5px rgba(0, 0, 0, 0.12)`
      : 'none'};
  border-radius: 4px;
  cursor: pointer;
  margin-top: 16px;
  color: ${({ isActive }) =>
    isActive ? ThemeV2.palette.geekBlue6 : ThemeV2.palette.gray7};
  &:hover {
    background-color: ${ThemeV2.palette.gray1};
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), 0px 3px 4px rgba(0, 0, 0, 0.02),
      0px 1px 5px rgba(0, 0, 0, 0.12);
  }
  & > *,
  & > span > svg {
    height: 24px;
    min-width: 24px;
    width: 24px;
  }
  @media ${device.tabletV2} {
    width: 64px;
    height: 64px;
    & > *,
    & > span > svg {
      height: 20px;
      min-width: 20px;
    }
  }
  ${({ showUnread }) =>
    showUnread &&
    `
    &::before {
      content: '';
      position: absolute;
      top: 25%;
      right: 20%;
      width: 12px;
      height: 12px;
      border-radius: 100%;
      background-color: ${ThemeV2.palette.geekBlue6};
      border: 2px solid ${ThemeV2.palette.gray1};
      z-index: 2;
      @media ${device.tabletV2} {
        right: 25%;
      }
    }
`}
`;

export const StyledIconButton = styled(IconButton)<LayoutStyleProps>`
  position: fixed;
  transition: left 0.5s;
  /* z-index: ${zIndexPrimaryNav}; */
  z-index: 99;
  bottom: ${({ isKioskTurnedOn }) => (isKioskTurnedOn ? '64px' : '32px')};
  /* left: ${({ isLeftAsideOpen, isHovered }) =>
    isLeftAsideOpen || isHovered
      ? `${PrimaryNavWidth + SecondaryNavWidth - (24 + 40)}px`
      : `${PrimaryNavWidth + 24}px`}; */
  left: ${({ isLeftAsideOpen, isHovered }) =>
    isLeftAsideOpen || isHovered ? `276px` : `60px`};
  width: 24px;
  height: 24px;
  border-radius: 50%;

  /* @media ${device.tablet} {
    display: ${({ isRightAsideOpen }) => isRightAsideOpen && 'none'};
  } */

  &:hover {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), 0px 4px 5px rgba(0, 0, 0, 0.02),
      0px 1px 10px rgba(0, 0, 0, 0.12);
  }

  @media ${device.mobile} {
    display: none;
  }
  &:after {
    position: absolute;
    content: '';
    width: 48px;
    height: 48px;
  }
`;

export const PopoverContainer = styled.div`
  width: 224px;
  @media ${device.tabletV2} {
    width: 332px;
  }
`;
