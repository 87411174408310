import React from 'react';
import styled from 'styled-components';

import Body from '../../atoms/Body';
import { IMemberDTO } from '../../../interfaces/member';
import { isAdmin } from '../../../Utils/member';
import {
  NO_MEMBERS_FOUND_THAT_MATCH_YOUR_CRITERIA,
  PLEASE_ENTER_MORE_THAN_2_CHARACTERS,
} from '../../../languages/en/home/mainFeedDrawer';
import MembersListEntry from '../MembersListEntry';
import MembersListEntryLoader from '../MembersListEntry/Loader';

const StyledBody = styled(Body)`
  text-align: center;
`;

export interface SearchedMembersListProps {
  isLoading?: boolean;
  members?: IMemberDTO[];
  handleEntryClick: (firstName: string, memberId: string) => void;
}

const SearchedMembersList = ({
  isLoading,
  members,
  handleEntryClick,
}: SearchedMembersListProps) => {
  if (isLoading) {
    return <MembersListEntryLoader />;
  }

  if (!members) {
    return (
      <StyledBody variant="body3">
        {PLEASE_ENTER_MORE_THAN_2_CHARACTERS}
      </StyledBody>
    );
  }

  if (members.length === 0) {
    return (
      <StyledBody variant="body3">
        {NO_MEMBERS_FOUND_THAT_MATCH_YOUR_CRITERIA}
      </StyledBody>
    );
  }

  return (
    <div>
      {members?.map((member) => {
        const { firstName, image, lastName, memberID, memberState } = member;
        return (
          <MembersListEntry
            fullName={`${firstName} ${lastName}`}
            isAdmin={isAdmin(member)}
            id={memberID}
            key={memberID}
            onClick={() => handleEntryClick(firstName, memberID)}
            profilePicture={image || ''}
            memberState={memberState}
          />
        );
      })}
    </div>
  );
};

const MemoizedSearchedMembersList = React.memo(SearchedMembersList);
MemoizedSearchedMembersList.displayName = 'SearchedMembersList';
export default MemoizedSearchedMembersList;
