/* eslint-disable no-param-reassign */
import create from 'zustand';
import { produce } from 'immer';
import {
  Assignee,
  AssignmentOperations,
  MiscTaskCategories,
  NotebookCategoryData,
  NotebookOperations,
  NotebookTask,
  NotesData,
  TaskCategories,
  TaskPositionDataType,
  TransactionData,
} from '../../interfaces/notebook';
import {
  CurrentTaskDataProps,
  ModalData,
  ModalProps,
  NotebookStore,
} from './types';
import {
  addTransactionToQueue,
  convertAPITaskToTaskAndCategory,
  getTabCategoryForTask,
  getTransactionFromTask,
} from '../../Utils/notebook';
import {
  getModalPropsBasedOnCategory,
  getModalPropsBasedOnCategoryAndTab,
  getModalPropsBasedOnTab,
  getTaskItemDueDate,
} from '../../controllers/notebook/NotebookPageController/utils';
import { NotebookTabs } from '../../controllers/notebook/NotebookPageController/useNotebookPageController/types';

export const defaultModalProps: ModalProps = {
  heading: '',
  bodyText: '',
  primaryButtonText: '',
  secondaryButtonText: '',
  primaryButtonType: 'warning',
};

export const setupNotebookData = <T>(fn: () => T) => ({
  [NotebookTabs.myWork]: {
    ARCHIVED: fn(),
    COMPLETED: fn(),
    OVERDUE: fn(),
    TODAY: fn(),
    UNSCHEDULED: fn(),
    UPCOMING: fn(),
  },
  [NotebookTabs.ato]: {
    ARCHIVED: fn(),
    COMPLETED: fn(),
    OVERDUE: fn(),
    TODAY: fn(),
    UNSCHEDULED: fn(),
    UPCOMING: fn(),
  },
});

const defaultNotes = setupNotebookData<NotebookCategoryData>(() => ({
  tasks: [],
  total: 0,
  initialRequest: true,
  totalUnreadCount: 0,
}));

const createDefaultNotebookModalStore = (): ModalData => ({
  modalProps: defaultModalProps,
  isOpen: false,
  currentTaskData: null,
  callback: () => {},
  hasCheckbox: false,
});

const createDefaultNotebookStore = (
  totalTodayAndOverdueTasks?: number | undefined,
): {
  notes: NotesData;
  transactions: TransactionData;
  erroredTaskIDs: string[];
  totalTodayAndOverdueTasks: number | undefined;
  allNotes: Record<number, NotebookTask>;
  idMap: Record<string, string>;
  currentTab: NotebookTabs;
  taskPositionData: Record<string, TaskPositionDataType>;
  isRefetchQuery: boolean;
} => ({
  totalTodayAndOverdueTasks: totalTodayAndOverdueTasks || undefined,
  notes: defaultNotes,
  transactions: { transactionQueue: [] },
  erroredTaskIDs: [],
  allNotes: {},
  idMap: {},
  currentTab: NotebookTabs.myWork,
  taskPositionData: {},
  isRefetchQuery: false,
});

const getNotebookLeftDrawerCount = (notes: NotesData) =>
  notes[NotebookTabs.myWork][TaskCategories.TODAY].total +
  notes[NotebookTabs.myWork][TaskCategories.OVERDUE].total;

const useNotebookStore = create<NotebookStore>((set) => ({
  ...createDefaultNotebookStore(),
  ...createDefaultNotebookModalStore(),
  setIsRefetchQuery: (val: boolean) =>
    set(() => ({
      isRefetchQuery: val,
    })),
  toggleCurrentTab: (newTab: NotebookTabs) =>
    set(() => ({
      currentTab: newTab,
    })),
  updateCount: (count: number) =>
    set(() => {
      return {
        totalTodayAndOverdueTasks: count,
      };
    }),
  addSingleNote: (task) =>
    set(({ allNotes, idMap }) => {
      const { taskId, noteId } = task;
      if (allNotes[taskId]) {
        return { allNotes, idMap };
      }
      return {
        allNotes: {
          ...allNotes,
          [taskId]: task,
        },
        idMap: {
          ...idMap,
          [noteId]: taskId,
        },
      };
    }),
  updateNoteIds: (transactions: TransactionData, noteIds: string[]) =>
    set((currentData) => {
      const { allNotes, idMap } = currentData;
      const { transactionQueue } = transactions;
      const allUpdatedNotes = {
        ...allNotes,
      };
      const updatedIdMap = {
        ...idMap,
      };
      transactionQueue.forEach((transaction, index) => {
        // Assumption: Transactions and noteIds are serialized in the same order
        const noteId = noteIds[index];
        const { taskId } = transaction;
        allUpdatedNotes[taskId] = {
          ...allUpdatedNotes[taskId],
          noteId,
        };
        updatedIdMap[noteId] = taskId;
      });
      return {
        allNotes: allUpdatedNotes,
        idMap: updatedIdMap,
      };
    }),
  uploadSectionData: (taskCategory, tab, tasks, total, limit = 20) =>
    set((currentData) => {
      const {
        notes,
        allNotes,
        idMap,
        taskPositionData,
        isRefetchQuery,
        erroredTaskIDs,
      } = currentData;
      const currentNotesArr = notes[tab][taskCategory].tasks;
      const allUpdatedNotes = {
        ...allNotes,
      };
      const updatedIdMap = {
        ...idMap,
      };
      const updatedTaskPositionData = {
        ...taskPositionData,
      };

      let updatedSectionalTasks: string[] = [];
      if (isRefetchQuery) {
        erroredTaskIDs.forEach((errorId) => {
          if (currentNotesArr.includes(errorId)) {
            updatedSectionalTasks.push(errorId);
          }
        });
        tasks.forEach((task) => {
          const { taskId, noteId } = task;
          allUpdatedNotes[taskId] = {
            ...task,
            isUnread: task?.isUnread && tab === NotebookTabs.myWork,
          };
          updatedIdMap[noteId] = taskId;
          updatedSectionalTasks.push(taskId);
          updatedTaskPositionData[taskId] = {
            tab,
            taskCategory,
          };
        });
        const totalUnreadCount: number = tasks?.filter(
          (task) => task.isUnread,
        ).length;
        const updatedNotes: NotesData = {
          ...notes,
          [tab]: {
            ...notes[tab],
            [taskCategory]: {
              tasks: updatedSectionalTasks,
              total,
              totalUnreadCount,
            },
          },
        };
        return {
          allNotes: allUpdatedNotes,
          idMap: updatedIdMap,
          notes: updatedNotes,
          totalTodayAndOverdueTasks: getNotebookLeftDrawerCount(updatedNotes),
          taskPositionData: updatedTaskPositionData,
        };
      }

      if (!notes[tab][taskCategory].initialRequest) {
        // Pagination response to be updated
        erroredTaskIDs.forEach((errorId) => {
          if (currentNotesArr.includes(errorId)) {
            updatedSectionalTasks.push(errorId);
          }
        });
        tasks.forEach((task) => {
          const { taskId, noteId } = task;
          allUpdatedNotes[taskId] = {
            ...task,
            isUnread: task?.isUnread && tab === NotebookTabs.myWork,
          };
          updatedIdMap[noteId] = taskId;
          updatedSectionalTasks.push(taskId);
          updatedTaskPositionData[taskId] = {
            tab,
            taskCategory,
          };
        });
        const totalUnreadCount: number = tasks?.filter(
          (task) => task.isUnread,
        ).length;
        const updatedNotes: NotesData = {
          ...notes,
          [tab]: {
            ...notes[tab],
            [taskCategory]: {
              tasks: updatedSectionalTasks,
              total,
              totalUnreadCount,
            },
          },
        };
        return {
          allNotes: allUpdatedNotes,
          idMap: updatedIdMap,
          notes: updatedNotes,
          totalTodayAndOverdueTasks: getNotebookLeftDrawerCount(updatedNotes),
          taskPositionData: updatedTaskPositionData,
        };
      }

      const numberOfItemsToSliceFromArrEnd = tasks.length % limit || limit;
      const trimmedTasks =
        tasks.length > limit
          ? tasks.slice(-numberOfItemsToSliceFromArrEnd)
          : tasks;
      updatedSectionalTasks = [...currentNotesArr];
      trimmedTasks.forEach((task) => {
        const currentPosition = taskPositionData[task.taskId];
        if (
          !(
            currentPosition &&
            currentPosition.taskCategory === taskCategory &&
            currentPosition.tab === tab
          )
        ) {
          const { taskId, noteId } = task;
          if (!erroredTaskIDs.includes(taskId)) {
            allUpdatedNotes[taskId] = {
              ...task,
              isUnread: task?.isUnread && tab === NotebookTabs.myWork,
            };
          }
          updatedSectionalTasks.push(taskId);
          updatedIdMap[noteId] = taskId;
          updatedTaskPositionData[taskId] = {
            tab,
            taskCategory,
          };
        }
      });
      const totalUnreadCount: number = trimmedTasks?.filter(
        (task) => task.isUnread,
      ).length;
      const updatedNotes: NotesData = {
        ...notes,
        [tab]: {
          ...notes[tab],
          [taskCategory]: {
            tasks: updatedSectionalTasks,
            total,
            totalUnreadCount,
          },
        },
      };
      return {
        allNotes: allUpdatedNotes,
        idMap: updatedIdMap,
        notes: updatedNotes,
        totalTodayAndOverdueTasks: getNotebookLeftDrawerCount(updatedNotes),
        taskPositionData: updatedTaskPositionData,
      };
    }),
  createTask: (task) =>
    set((currentData) => {
      const { currentTab, notes, allNotes, transactions, taskPositionData } =
        currentData;
      const { taskId, type } = task;
      const taskIdOfFirstItemInSection: string =
        notes[currentTab][type].tasks[0];
      const updatedNotes = produce(notes, (draft) => {
        draft[currentTab][type].tasks.unshift(taskId);
        draft[currentTab][type].total += 1;
      });
      const allUpdatedNotes = {
        ...allNotes,
        [taskId]: task,
      };
      const updatedTaskPositionData = {
        ...taskPositionData,
        [taskId]: {
          tab: currentTab,
          taskCategory: type,
        },
      };
      const updatedTransactions = addTransactionToQueue(
        getTransactionFromTask(
          task,
          type,
          undefined,
          taskIdOfFirstItemInSection
            ? allUpdatedNotes[taskIdOfFirstItemInSection].taskId
            : undefined,
          undefined,
          true,
          NotebookOperations.CREATE,
        ),
        transactions,
      );
      return {
        notes: updatedNotes,
        transactions: updatedTransactions,
        totalTodayAndOverdueTasks: getNotebookLeftDrawerCount(updatedNotes),
        allNotes: allUpdatedNotes,
        taskPositionData: updatedTaskPositionData,
      };
    }),
  editTaskContent: (updatedTask) =>
    set((currentData) => {
      const { currentTab, notes, allNotes, transactions } = currentData;
      const { taskId, type } = updatedTask;
      const shouldTaskBeDeleted = !updatedTask.note;
      const tasksArr = notes[currentTab][type].tasks;
      const noteIndex = tasksArr.indexOf(taskId);
      const updatedNotes = produce(notes, (draft) => {
        const { tasks } = draft[currentTab][type];
        if (shouldTaskBeDeleted) {
          tasks.splice(noteIndex, 1);
        } /* else {
          tasks.splice(noteIndex, 1, updatedTask);
        } */
        // Commented this out as we will never change the id of the note while editing
      });
      const updatedTransactions = addTransactionToQueue(
        getTransactionFromTask(
          updatedTask,
          type,
          undefined,
          undefined,
          undefined,
          false,
          NotebookOperations.UPDATE,
        ),
        transactions,
      );
      const allUpdatedNotes = {
        ...allNotes,
      };
      if (shouldTaskBeDeleted) {
        delete allUpdatedNotes[taskId];
      } else {
        allUpdatedNotes[taskId] = updatedTask;
      }
      return {
        allNotes: allUpdatedNotes,
        notes: updatedNotes,
        transactions: updatedTransactions,
        totalTodayAndOverdueTasks: getNotebookLeftDrawerCount(updatedNotes),
      };
    }),
  updateTask: (updatedTask, previousCategory, memberId, isAssignment = false) =>
    set((currentData) => {
      const { notes, allNotes, transactions, taskPositionData, currentTab } =
        currentData;
      const { taskId, type } = updatedTask;
      const updatedTab = getTabCategoryForTask(updatedTask, memberId);
      const allUpdatedNotes = {
        ...allNotes,
        [taskId]: updatedTask,
      };
      const updatedTaskPositionData = {
        ...taskPositionData,
      };
      const updatedNotes = produce(notes, (draft) => {
        const taskToBeMovedIndex =
          draft[currentTab][previousCategory].tasks.indexOf(taskId);
        if (previousCategory !== type || updatedTab !== currentTab) {
          if (taskToBeMovedIndex >= 0) {
            updatedTaskPositionData[taskId] = {
              tab: updatedTab,
              taskCategory: type,
            };
            draft[currentTab][previousCategory].tasks.splice(
              taskToBeMovedIndex,
              1,
            );
            draft[currentTab][previousCategory].total -= 1;
            draft[updatedTab][type].tasks.unshift(taskId);
            draft[updatedTab][type].total += 1;
          }
        }
      });
      let taskIdOfFirstItemInSection: string | undefined;
      if (
        updatedNotes[updatedTab][type].tasks.length > 1 &&
        previousCategory !== type
      ) {
        taskIdOfFirstItemInSection = updatedNotes[updatedTab][type].tasks[0];
      }
      const updatedTransactions = addTransactionToQueue(
        getTransactionFromTask(
          updatedTask,
          type,
          undefined,
          taskIdOfFirstItemInSection
            ? allUpdatedNotes[taskIdOfFirstItemInSection].taskId
            : undefined,
          previousCategory,
          false,
          isAssignment ? NotebookOperations.ASSIGN : NotebookOperations.UPDATE,
        ),
        transactions,
      );
      return {
        notes: updatedNotes,
        transactions: updatedTransactions,
        totalTodayAndOverdueTasks: getNotebookLeftDrawerCount(updatedNotes),
        allNotes: allUpdatedNotes,
        taskPositionData: updatedTaskPositionData,
      };
    }),
  deleteTask: (taskId) =>
    set((currentData) => {
      const {
        currentTab,
        notes,
        allNotes,
        transactions,
        idMap,
        taskPositionData,
      } = currentData;
      const deletedTaskIndex = notes[currentTab].ARCHIVED.tasks.indexOf(taskId);
      const deletedTask = allNotes[taskId];
      const updatedNotes = produce(notes, (draft) => {
        draft[currentTab].ARCHIVED.tasks.splice(deletedTaskIndex, 1);
        draft[currentTab].ARCHIVED.total -= 1;
      });
      const updatedIdMap = {
        ...idMap,
      };
      const updatedTaskPositionData = {
        ...taskPositionData,
      };
      const allUpdatedNotes = {
        ...allNotes,
        [taskId]: {
          ...allNotes[taskId],
          isDeleted: true,
        },
      };
      delete updatedTaskPositionData[taskId];
      delete updatedIdMap[taskId];
      const deletedAPITask = getTransactionFromTask(
        deletedTask,
        TaskCategories.ARCHIVED,
        undefined,
        undefined,
        TaskCategories.ARCHIVED,
        false,
        NotebookOperations.DELETE,
      );
      deletedAPITask.taskData.state = 'DELETED';
      const updatedTransactions = addTransactionToQueue(
        deletedAPITask,
        transactions,
      );
      return {
        notes: updatedNotes,
        transactions: updatedTransactions,
        totalTodayAndOverdueTasks: getNotebookLeftDrawerCount(updatedNotes),
        allNotes: allUpdatedNotes,
        taskPositionData: updatedTaskPositionData,
      };
    }),
  updateTransactions: (transactions: TransactionData) =>
    set(() => ({
      transactions,
    })),
  setInitialRequestToFalse: (tab: NotebookTabs, taskCategory: TaskCategories) =>
    set(({ notes }) => ({
      notes: {
        ...notes,
        [tab]: {
          ...notes[tab],
          [taskCategory]: {
            ...notes[tab][taskCategory],
            initialRequest: false,
          },
        },
      },
    })),
  updateErrorIDs: (errorIDs: string[]) =>
    set(() => ({ erroredTaskIDs: errorIDs })),
  dragAndDropTask: (taskIdInString, source, destination, timezone) =>
    set((currentData) => {
      const { currentTab, notes, allNotes, transactions, taskPositionData } =
        currentData;
      const taskId = taskIdInString;
      const hasCategoryChanged = source.category !== destination.category;
      const allUpdatedNotes = hasCategoryChanged
        ? {
            ...allNotes,
            [taskId]: {
              ...allNotes[taskId],
              dueDate: getTaskItemDueDate(destination.category, timezone),
            },
          }
        : allNotes;
      const updatedTaskPositionData = hasCategoryChanged
        ? {
            ...taskPositionData,
            [taskId]: {
              ...taskPositionData[taskId],
              taskCategory: destination.category,
            },
          }
        : taskPositionData;
      const updatedNotes = produce(notes, (draft) => {
        draft[currentTab][source.category].tasks.splice(source.index, 1);
        draft[currentTab][destination.category].tasks.splice(
          destination.index,
          0,
          taskId,
        );
        draft[currentTab][source.category].total -= 1;
        draft[currentTab][destination.category].total += 1;
      });
      const beforeTaskId =
        destination.index === 0
          ? undefined
          : updatedNotes[currentTab][destination.category].tasks[
              destination.index - 1
            ];
      const afterTaskId =
        destination.index + 1 >=
        updatedNotes[currentTab][destination.category].tasks.length
          ? undefined
          : updatedNotes[currentTab][destination.category].tasks[
              destination.index + 1
            ];
      const updatedTransactions = addTransactionToQueue(
        getTransactionFromTask(
          allUpdatedNotes[taskId],
          destination.category,
          beforeTaskId || undefined,
          afterTaskId || undefined,
          undefined,
          false,
          NotebookOperations.UPDATE,
        ),
        transactions,
      );
      return {
        notes: updatedNotes,
        transactions: updatedTransactions,
        totalTodayAndOverdueTasks: getNotebookLeftDrawerCount(updatedNotes),
        allNotes: allUpdatedNotes,
        taskPositionData: updatedTaskPositionData,
      };
    }),
  setModalData: (
    toggle: boolean,
    category?: TaskCategories | MiscTaskCategories,
    currentTaskData?: CurrentTaskDataProps,
    callback?: () => void,
    currentUserId?: string,
  ) =>
    set(() => {
      return {
        isOpen: toggle,
        currentTaskData: currentTaskData || null,
        modalProps: currentTaskData?.updatedTask
          ? getModalPropsBasedOnCategoryAndTab(
              currentTaskData?.updatedTask,
              currentUserId || '',
              currentTaskData?.updatedCategory,
            )
          : getModalPropsBasedOnCategory(category),
        callback: callback || (() => {}),
        hasCheckbox: false,
      };
    }),
  setModalDataForTabChange: (
    toggle: boolean,
    currentUser: string | undefined,
    operation: AssignmentOperations,
    task: NotebookTask,
    callback?: () => void,
    oldAssignee?: Assignee,
  ) =>
    set(() => {
      return {
        isOpen: toggle,
        currentTaskData:
          {
            updatedTask: task,
            updatedCategory: task?.type,
            previousCategory: task?.type,
          } || null,
        modalProps: getModalPropsBasedOnTab(
          currentUser,
          operation,
          task,
          oldAssignee?.name,
        ),
        callback: callback || (() => {}),
        hasCheckbox: true,
      };
    }),
  resetNotebookStoreData: () =>
    set((currentData) =>
      createDefaultNotebookStore(currentData.totalTodayAndOverdueTasks),
    ),
  resetNotes: () => set(() => ({ notes: defaultNotes })),
  onPusherCreate: (newTask, memberId, timezone) =>
    set((currentData) => {
      const {
        notes,
        allNotes,
        idMap,
        totalTodayAndOverdueTasks,
        taskPositionData,
      } = currentData;
      const [serializedTask, taskCategory] = convertAPITaskToTaskAndCategory(
        newTask,
        timezone,
      );
      const taskBelongsToTab = getTabCategoryForTask(serializedTask, memberId);
      const { taskId, noteId } = serializedTask;
      const taskCategoryIds = notes[taskBelongsToTab][taskCategory].tasks;
      const taskAlreadyExists = taskCategoryIds.includes(taskId);
      if (taskAlreadyExists) {
        return {
          allNotes,
          idMap,
          notes,
          totalTodayAndOverdueTasks,
          taskPositionData,
        };
      }
      const updatedTaskPositionData = {
        ...taskPositionData,
        [taskId]: {
          tab: taskBelongsToTab,
          taskCategory,
        },
      };
      const isTaskUnread =
        serializedTask?.isUnread && taskBelongsToTab === NotebookTabs.myWork;
      const updatedNotes = produce(notes, (draft) => {
        draft[taskBelongsToTab][taskCategory].tasks.unshift(taskId);
        draft[taskBelongsToTab][taskCategory].total += 1;
        if (isTaskUnread)
          draft[taskBelongsToTab][taskCategory].totalUnreadCount += 1;
      });
      const allUpdatedNotes = {
        ...allNotes,
        [taskId]: {
          ...serializedTask,
          isUnread: isTaskUnread,
        },
      };
      const updatedIdMap = {
        ...idMap,
        [noteId]: taskId,
      };
      return {
        allNotes: allUpdatedNotes,
        idMap: updatedIdMap,
        notes: updatedNotes,
        totalTodayAndOverdueTasks: getNotebookLeftDrawerCount(updatedNotes),
        taskPositionData: updatedTaskPositionData,
      };
    }),
  onPusherDelete: (deletedTask, timezone) =>
    set((currentData) => {
      const {
        notes,
        allNotes,
        idMap,
        totalTodayAndOverdueTasks,
        taskPositionData,
      } = currentData;
      const [serializedTask] = convertAPITaskToTaskAndCategory(
        deletedTask,
        timezone,
      );
      const { taskId, noteId } = serializedTask;
      const deletedNoteInStore = allNotes[taskId];
      if (deletedNoteInStore) {
        const allUpdatedNotes = {
          ...allNotes,
        };
        const updatedTaskPositionData = {
          ...taskPositionData,
        };
        const updatedIdMap = {
          ...idMap,
        };
        delete allUpdatedNotes[taskId];
        delete updatedIdMap[noteId];
        delete updatedTaskPositionData[taskId];
        const updatedNotes = produce(notes, (draft) => {
          const position = taskPositionData[taskId];
          if (position) {
            const categoryTasks = draft[position.tab][position.taskCategory];
            const index = categoryTasks.tasks.indexOf(taskId);
            categoryTasks.tasks.splice(index, 1);
            categoryTasks.total -= 1;
            if (
              categoryTasks.totalUnreadCount > 0 &&
              allNotes[taskId].isUnread
            ) {
              categoryTasks.totalUnreadCount -= 1;
            }
          }
        });
        return {
          allNotes: allUpdatedNotes,
          idMap: updatedIdMap,
          notes: updatedNotes,
          totalTodayAndOverdueTasks: getNotebookLeftDrawerCount(updatedNotes),
          taskPositionData: updatedTaskPositionData,
        };
      }
      return {
        allNotes,
        idMap,
        notes,
        totalTodayAndOverdueTasks,
        taskPositionData,
      };
    }),
  onPusherUpdate: (updatedTask, memberId, timezone) =>
    set((currentData) => {
      const { notes, allNotes, taskPositionData } = currentData;
      const [serializedTask, taskUpdatedCategory] =
        convertAPITaskToTaskAndCategory(updatedTask, timezone);
      const { taskId } = serializedTask;
      const taskUpdatedTab = getTabCategoryForTask(serializedTask, memberId);
      const allUpdatedNotes = {
        ...allNotes,
      };
      const updatedTaskPositionData = {
        ...taskPositionData,
      };
      const updatedNotes = produce(notes, (draft) => {
        const position = taskPositionData[taskId];
        if (position) {
          const { tab, taskCategory } = position;
          if (
            !(tab === taskUpdatedTab && taskCategory === taskUpdatedCategory)
          ) {
            const categoryTasks = draft[tab][taskCategory];
            const index = categoryTasks.tasks.indexOf(taskId);
            categoryTasks.tasks.splice(index, 1);
            categoryTasks.total -= 1;
            if (
              categoryTasks.totalUnreadCount > 0 &&
              allUpdatedNotes[taskId].isUnread
            ) {
              categoryTasks.totalUnreadCount -= 1;
            }
            const updatedCategoryTasks =
              draft[taskUpdatedTab][taskUpdatedCategory];
            updatedCategoryTasks.tasks.unshift(taskId);
            updatedCategoryTasks.total += 1;
            updatedCategoryTasks.totalUnreadCount += 1;
            updatedTaskPositionData[taskId] = {
              tab: taskUpdatedTab,
              taskCategory: taskUpdatedCategory,
            };
          }
          allUpdatedNotes[taskId] = {
            ...serializedTask,
            isUnread:
              serializedTask?.isUnread &&
              taskUpdatedTab === NotebookTabs.myWork,
          };
        }
      });
      return {
        notes: updatedNotes,
        allNotes: allUpdatedNotes,
        taskPositionData: updatedTaskPositionData,
        totalTodayAndOverdueTasks: getNotebookLeftDrawerCount(updatedNotes),
      };
    }),
  updateStoreForUnreadTasks: (taskId, tab, taskCategory) =>
    set((currentData) => {
      const { allNotes, notes } = currentData;

      const allUpdatedNotes = {
        ...allNotes,
      };

      const updatedNotes = produce(notes, (draft) => {
        draft[tab][taskCategory].totalUnreadCount -= 1;
      });
      allUpdatedNotes[taskId] = {
        ...allUpdatedNotes[taskId],
        isUnread: false,
      };
      return {
        allNotes: allUpdatedNotes,
        notes: updatedNotes,
      };
    }),
}));

export default useNotebookStore;
