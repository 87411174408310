import { InfiniteData } from 'react-query';
import { PostTypes } from '../Home';
import { ALL_POSTS, RELEVANT_POSTS } from '../../languages/en/feed';
import {
  FlowFeedResponse,
  VisibilityType,
} from '../../queries/Flows/Feed/interfaces';
import {
  BlockProps,
  DownloadBlockType,
  FlowPostBodyProps,
} from '../../atomic/organism/FlowPostBody/types';
import { PostReaction, ReactionMember, ReactionType } from './Reaction';
import { MemberRole, MemberState } from '../user';
import { EmojiData } from 'emoji-mart';
import { File } from '../../atomic/molecules/FileCard/types';

interface CommonCommentInteractionType {
  currentPostId: string;
  currentPostType: PostTypes;
  currentFlowId?: string;
  currentResponseId?: string;
}

export interface DeletePostClickArgs extends CommonCommentInteractionType {
  hasTrophies: boolean;
}

export type DeletePostClickType = (
  obj: DeletePostClickArgs,
  deleteCarrotOption?: string,
) => void;

export interface FeedContextState {
  onReactionSet: (
    emoji: EmojiData,
    contentId: string,
    post: FeedItemFromAPI,
  ) => void;
  onReactionUnSet: (
    reaction: Reaction,
    contentId: string,
    post: FeedItemFromAPI,
  ) => void;
  onMemberClick: (person: MemberInteractionType) => void;
  onFlowClick: (flowId: string) => void;
  onDeleteCommentClick: (
    commentId: string,
    hasTrophies: boolean,
    deleteCommentOption: string,
    post: FeedItemFromAPI,
  ) => void;
  onCommentReactionToggleClick: (
    reaction: Reaction,
    currentContentId: string,
    post: FeedItemFromAPI,
  ) => void;
  onCommentEmoticonClick: (
    reactions: Reaction[],
    emoji: EmojiData,
    contentID: string,
    post: FeedItemFromAPI,
  ) => void;
  onDeletePost: DeletePostClickType;
  onCopyCommentLink: (commentId: string, post: FeedItemFromAPI) => void;
  onDownloadFileClick: (
    fileDownload: DownloadBlockType,
    instanceId: string,
    flowId: string,
  ) => void;
}

export interface UpdateReactionMutationVariables {
  payload: ReactionEmoji;
  contentID: string;
  action: UpdateReactionAction;
  userData: ReactionMember;
}

export interface UpdateCommentReactionMutationVariables
  extends UpdateReactionMutationVariables {
  postID: string;
  postType?: PostTypes;
  flowId?: string;
  responseId?: string;
}

export interface GetFeedResponse {
  metadata: PaginationMetadata;
  total: number;
  data: FeedItemFromAPI[];
}

export interface GetFeedAttachmentResponse {
  metadata: PaginationMetadata;
  total: number;
  data: FeedAttachmentItemFromAPI[];
}

export interface GetCommentByPostResponse {
  data: FeedCommentFromAPI[];
  metadata: PaginationMetadata;
  total: number;
}

export interface ReactionEmoji {
  name: string;
  value: string;
  type: ReactionType;
  displayName?: string;
}

export interface Reaction {
  members: ReactionMember[];
  reaction: ReactionEmoji;
  timesReacted: number;
  active: boolean;
}

export interface CreatePostPayload {
  to: string[];
  message: string;
  carrotsEach?: number;
  coreValue?: string;
  gifUrl?: string;
  isPrivate?: boolean;
}

interface CommonAttributes {
  createdAt: string;
  updatedAt: string;
  gifURL: string;
  imageURL: string;
  isDeleted: boolean;
  message: string;
  pointsEach: number;
  postID: string;
  taggedUsers: FeedPostUserProps[];
  reactions: PostReaction[];
}

export enum FeedItemKind {
  INTERNAL = 'INTERNAL',
  INTERNAL_ANONYMOUS = 'INTERNAL_ANONYMOUS',
  EXTERNAL_ANONYMOUS = 'EXTERNAL_ANONYMOUS',
}

export interface FeedItemFromAPI extends CommonAttributes {
  type: PostTypes;
  from: FeedPostUserProps;
  to: FeedPostUserProps[];
  isPrivate: boolean;
  coreValue: string;
  assemblyID: string;
  commentsCount: number;
  flowResponse?: FlowFeedResponse;
  kind?: FeedItemKind;
}

export interface FeedAttachmentItemFromAPI extends CommonAttributes {
  fileInfo: File;
  flowInfo: FlowType;
  respondent: FeedAttachmentUserProps;
  response: {
    flowId: string;
    responseId: string;
    blockId: string;
    postId: string;
  };
  canDelete: string;
}

export interface FeedCommentFromAPI extends CommonAttributes {
  commentID: string;
  fromMember: FeedPostUserProps;
}

export interface PaginationMetadata {
  pagination: {
    cursor: {
      next: string;
      previous: string;
    };
  };
}

export enum PostSortOptions {
  DESC = 'desc',
  ASC = 'asc',
}

export enum PostFilterOptions {
  ALL = 'all',
  RELEVANT = 'relevance',
}

export const PostFilterValues = {
  [PostFilterOptions.ALL]: ALL_POSTS,
  [PostFilterOptions.RELEVANT]: RELEVANT_POSTS,
};

export const PostSortValues = {
  [PostSortOptions.ASC]: 'Oldest',
  [PostSortOptions.DESC]: 'Newest',
};

export enum UpdateReactionAction {
  SET = 'set',
  UNSET = 'unset',
}

export type FilterPayload = {
  startDate?: string;
  endDate?: string;
  flowIds?: string[];
  flowVersions?: number[];
  blockIds?: string[];
  respondedBy?: string[];
  mentions?: string[];
  isAnonymous?: boolean;
};
export interface PostMainFeedPayload {
  feedsSort: PostSortOptions;
  filter?: FilterPayload;
}

export interface PostFlowFeedPayload {
  flowId: string;
  flowFeedsSort: PostSortOptions;
  filter?: FilterPayload;
}

export interface PostProfileFeedPayload {
  userId: string;
  filter?: FilterPayload;
}
export interface ReactionMutationPayload {
  payload: ReactionEmoji;
  contentID: string;
  action: UpdateReactionAction;
  userData: ReactionMember;
}

export interface FlowPostReactionMutationPayload {
  payload: ReactionEmoji;
  contentID: string;
  action: UpdateReactionAction;
  userData: ReactionMember;
  flowId: string;
  responseId: string;
}

export interface FeedSearchPayload {
  to?: string[];
  from?: string[];
  keywords?: string[];
  startDate?: Date;
  endDate?: Date;
  currencyMin?: number;
  currencyMax?: number;
}

export interface FeedOptionsInterface {
  postFilter: PostFilterOptions;
  postsSort: PostSortOptions;
  showSearchResults: boolean;
  searchPayload: FeedSearchPayload;
}

export interface FeedPostUserProps {
  name?: string;
  firstName: string;
  lastName: string;
  memberID: string;
  image?: string;
  username?: string;
  isDeleted?: boolean;
  pointsGiven?: number;
  totalPointsGiven?: number;
  memberState?: MemberState;
}

export interface FeedAttachmentUserProps {
  name?: string;
  firstName: string;
  lastName: string;
  memberID: string;
  username?: string;
  image?: string;
  resizedImage?: string;
  memberState?: MemberState;
  role: MemberRole[];
  pointsGiven: number;
  totalPointsGiven: number;
}

export type ReactionUpdateContext =
  | {
      previousData:
        | InfiniteData<GetFeedResponse>
        | SinglePostResponse
        | undefined;
    }
  | undefined;

export interface SinglePostResponse {
  post: FeedItemFromAPI;
}

export interface MemberRecognitionCount {
  timesGiven: number;
  timesReceived: number;
}

export type MemberInteractionType = {
  memberID: string;
  firstName: string;
};

export type FlowHeaderProps = {
  flow: FlowType;
  createdAt: string;
  postType: PostTypes;
  hasTrophies: boolean;
  onCopyLink: () => void;
  showDeleteOption: boolean;
  person?: FeedPostUserProps | null;
  handleMemberClick: (member: MemberInteractionType) => void;
  handleOnFlowClick: (flow: { flowId: string }) => void;
  visibility: VisibilityType | undefined;
  kind?: FeedItemKind;
};

export type NewFlowHeaderProps = {
  flow: FlowType;
  createdAt: string;
  postType: PostTypes;
  hasTrophies: boolean;
  onCopyLink: () => void;
  showDeleteOption: boolean;
  person?: FeedPostUserProps | null;
  handleMemberClick: (member: MemberInteractionType) => void;
  handleOnFlowClick: (flowId: string) => void;
  visibility: VisibilityType | undefined;
  currentUserId: string;
};

type FlowType = {
  name: string;
  kind: string;
  flowId: string;
  version: number;
  icon: {
    kind: string;
    value: string;
  };
  visibility?: VisibilityType;
};

export type FlowReactionsProps = {
  contentId: string;
  currentUserId: string;
  reactions: Reaction[];
};

export type FeedProps = {
  key: string;
  type: PostTypes;
  instanceId: string;
  responseId?: string;
  flowId?: string;
  postId: string;
  headerProps: FlowHeaderProps;
  bodyProps: FlowPostBodyProps;
  reactionBarProps: FlowReactionsProps;
  commentsCount: number;
  trophyReceivers: FeedPostUserProps[];
  kind?: string;
};

export type NewFeedProps = {
  key: string;
  type: PostTypes;
  instanceId: string;
  responseId?: string;
  flowId?: string;
  postId: string;
  headerProps: NewFlowHeaderProps;
  blocks: BlockProps[];
  reactionBarProps: FlowReactionsProps;
  commentsCount: number;
  trophyReceivers: FeedPostUserProps[];
};

export type PageType =
  | 'main'
  | 'flow'
  | 'rewards'
  | 'profile'
  | 'recognition'
  | 'single post'
  | 'single post recognition'
  | 'single post flows'
  | 'notebook';
