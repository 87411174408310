import React, { memo } from 'react';
import ContentLoader from 'react-content-loader';
import styled from 'styled-components';
import ThemeV2 from '../../../componentsV2/theme';
import { StyledCard } from './styles';

import { RewardTypes } from './interface';

const LoaderStyledCard = styled(StyledCard)`
  width: 215px;
`;

interface RewardCardLoaderProps {
  rewardType: RewardTypes;
}

const RewardCardLoader = (props: RewardCardLoaderProps) => {
  const { rewardType } = props;
  const cultureRewardCardHeight = 331;
  const rewardCardHeight = 311;

  let viewBoxValues;
  let loaderHeight;
  let actionButtonYPosition;

  if (rewardType === RewardTypes.Culture) {
    loaderHeight = cultureRewardCardHeight;
    viewBoxValues = `0 0 215 ${cultureRewardCardHeight}`;
    actionButtonYPosition = 270;
  } else {
    loaderHeight = rewardCardHeight;
    viewBoxValues = `0 0 215 ${rewardCardHeight}`;
    actionButtonYPosition = 250;
  }

  return (
    <LoaderStyledCard>
      <ContentLoader
        speed={2}
        width="100%"
        height={loaderHeight}
        viewBox={viewBoxValues}
        backgroundColor={ThemeV2.palette.gray4}
        foregroundColor={ThemeV2.palette.gray3}
      >
        <rect x="0" y="0" rx="0" ry="4" width="215" height="125" />
        <rect x="16" y="149" rx="5" ry="4" width="183" height="24" />
        <rect x="16" y="177" rx="5" ry="4" width="148" height="16" />
        <rect
          x="16"
          y={actionButtonYPosition}
          rx="4"
          ry="4"
          width="183"
          height="40"
        />
      </ContentLoader>
    </LoaderStyledCard>
  );
};

const MemoizedRewardCardLoader = memo(RewardCardLoader);
MemoizedRewardCardLoader.displayName = 'RewardCardLoader';
export default MemoizedRewardCardLoader;
