import React, { MouseEvent, useCallback, useMemo, useState } from 'react';
import { IconButton, PopoverContainer } from './styles';
import { NotificationButtonProps } from './interface';
import { SecondaryPanel } from '../../../interfaces/Layout';
import SVGIcon from '../../atoms/SVGIcon';
import Popover from '@material-ui/core/Popover';
import NotificationDropdown from '../NotificationDropdown';
import ThemeV2 from '../../../componentsV2/theme';

const NotificationsButton = (props: NotificationButtonProps) => {
  const {
    unreadNotificationsCount,
    notificationData,
    isLoading,
    isError,
    onMarkReadClick,
    handleNotificationItemClick,
    onUnreadClick,
    onShowAllClick,
    onNotificationSettingsClick,
  } = props;

  const [isNotificationsOpen, setIsNotificationsOpen] =
    React.useState<boolean>(false);
  const [popoverAnchorEl, setPopoverAnchorEl] = useState<HTMLLIElement | null>(
    null,
  );

  const openNotificationsPopover = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      /* @ts-ignore-next-line */
      setPopoverAnchorEl(event.currentTarget);
      setIsNotificationsOpen(!isNotificationsOpen);
    },
    [isNotificationsOpen],
  );

  const closeNotifications = useCallback(() => {
    setIsNotificationsOpen(false);
  }, []);

  const widthCheck = window.matchMedia('(min-width: 769px)');

  const NotificationsContent = useMemo(
    () => (
      <PopoverContainer width={widthCheck.matches ? '320px' : '100vw'}>
        <NotificationDropdown
          notificationData={notificationData}
          data-testid="notificationsContent"
          isLoading={isLoading}
          isError={isError}
          onMarkReadClick={onMarkReadClick}
          handleNotificationItemClick={handleNotificationItemClick}
          onUnreadClick={onUnreadClick}
          onShowAllClick={onShowAllClick}
          onNotificationSettingsClick={onNotificationSettingsClick}
          closePopover={closeNotifications}
        />
      </PopoverContainer>
    ),
    [
      closeNotifications,
      handleNotificationItemClick,
      isError,
      isLoading,
      notificationData,
      onMarkReadClick,
      onNotificationSettingsClick,
      onShowAllClick,
      onUnreadClick,
      widthCheck.matches,
    ],
  );

  return (
    <>
      <IconButton
        type="button"
        onClick={openNotificationsPopover}
        data-testid={SecondaryPanel.NOTIFICATIONS}
        aria-label={SecondaryPanel.NOTIFICATIONS}
        showUnread={unreadNotificationsCount}
        isNotificationsOpen={isNotificationsOpen}
      >
        <SVGIcon
          size="24px"
          icon="notification"
          color={ThemeV2.palette.gray8}
        />
      </IconButton>
      <Popover
        PaperProps={{ style: { maxWidth: '100%', maxHeight: '100%' } }}
        open={isNotificationsOpen}
        onClose={closeNotifications}
        anchorEl={popoverAnchorEl}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: widthCheck.matches ? -8 : -16,
        }}
        marginThreshold={0}
      >
        {NotificationsContent}
      </Popover>
    </>
  );
};

export default NotificationsButton;
