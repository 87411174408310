import { useMemo } from 'react';
import addDays from 'date-fns/addDays';
import eachMinuteOfInterval from 'date-fns/eachMinuteOfInterval';
import format from 'date-fns/format';

import { AutocompleteDropdownItem } from '../../atomic/organism/Autocomplete/interfaces';

const useGetTimeOptions = () => {
  const endDate = addDays(new Date(1, 1, 1), 1); // random date just to calculate the time difference
  const dateTimeWithInterval = eachMinuteOfInterval(
    {
      start: new Date(1, 1, 1),
      end: endDate,
    },
    {
      step: 15,
    },
  );
  const flowFrequencyTimeOptions: AutocompleteDropdownItem<
    number,
    undefined
  >[] = useMemo(
    () =>
      dateTimeWithInterval
        .filter((data, index) => index !== dateTimeWithInterval.length - 1)
        .map((opDate, index) => {
          return {
            title: format(opDate, 'h:mm aaa'),
            id: index,
          };
        }),
    [dateTimeWithInterval],
  );
  return {
    flowFrequencyTimeOptions,
  };
};

export default useGetTimeOptions;
