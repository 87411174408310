import '@uppy/core/dist/style.css';
import '@uppy/drag-drop/dist/style.css';

import React, { useEffect, useMemo, useRef, useState } from 'react';
import Uppy, { UploadedUppyFile, UppyFile } from '@uppy/core';
import { DragDrop } from '@uppy/react';
import { useUppyStore } from '../../../../stores/uppyStore';

import {
  determineFileStatus,
  determineFileType,
  transformUploadedUppyFileToFileForAPI,
} from './utils';
import FileCard from '../../FileCard';
import FlowsBaseInputBlock from '../FlowsBaseInputBlock';
import { showErrorMessage } from '../../../../Utils/toast';
import { FlowFileForAPI } from '../../../../interfaces/Flow';
import NavigationInstructions from '../../FlowsInputBlockNavigationInstructions';
import {
  HiddenInput,
  StyledFileCardList,
  StyleFileUploadContainer,
  DisabledDragandDrop,
  DisabledDragDropInner,
} from './styles';
import Body from '../../../atoms/Body';
import {
  FLOW_FILE_UPLOAD_ERROR_TEXTS,
  ERROR_UPLOADING,
  UPLOAD_FILE,
  MAXIMUM_UPLOAD_LIMIT,
  PREVIEW_DISABLE_UPLOAD_TEXT,
} from '../../../../languages/en/flows';

export type FlowsFileUploadInputBlockProps = {
  blockId: string;
  description?: string;
  goToNextStep: () => void;
  isRequired: boolean;
  isLastBlock?: boolean;
  subDescription?: string;
  onChange: (newValue: FlowFileForAPI[] | null) => void;
  onBlur?: () => void;
  title: string;
  uppy: Uppy.Uppy;
  value: string | null;
  onDeleteFileClick: (fileName: string) => void;
  isPreviewFlow?: boolean;
};

function FlowsFileUploadInputBlock({
  blockId,
  description,
  isRequired,
  onChange,
  subDescription,
  title,
  uppy,
  goToNextStep,
  onDeleteFileClick,
  isPreviewFlow,
  isLastBlock = false,
}: FlowsFileUploadInputBlockProps) {
  const [fileUploadError, setFileUploadError] = useState('');
  const hiddenInputRef = useRef<HTMLInputElement | null>(null);
  const files = useUppyStore((state) => state.stores[blockId]?.files);

  const uppyFiles = useMemo(
    () => (files ? Object.keys(files).map((fileId) => files[fileId]) : []),
    [files],
  );

  useEffect(() => {
    const uploadedFiles = uppyFiles.filter(
      (uppyFile: UppyFile) =>
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        /* @ts-ignore */
        Boolean(uppyFile.uploadURL) as UploadedUppyFile<null, null>[],
    );

    const transformedFiles = uploadedFiles.map((uploadedFile) => {
      return transformUploadedUppyFileToFileForAPI(uploadedFile);
    });

    onChange(transformedFiles);
  }, [onChange, uppy, uppyFiles]);

  uppy.on('restriction-failed', (file, error) => {
    if (error) {
      setFileUploadError(error.message);
    }
  });

  uppy.on('file-removed', () => {
    setFileUploadError('');
  });

  useEffect(() => {
    if (fileUploadError !== '') {
      showErrorMessage(fileUploadError);
    }
  }, [fileUploadError]);

  uppy.on('file-added', () => {
    hiddenInputRef.current?.focus();
  });

  const placeHolderText = useMemo(() => {
    if (fileUploadError === FLOW_FILE_UPLOAD_ERROR_TEXTS.MORE_FILES)
      return FLOW_FILE_UPLOAD_ERROR_TEXTS.MORE_THAN_15_FILES;
    if (fileUploadError === FLOW_FILE_UPLOAD_ERROR_TEXTS.MAX_FILE_SIZE)
      return FLOW_FILE_UPLOAD_ERROR_TEXTS.MORE_THAN_25_MB;
    return 'Upload File';
  }, [fileUploadError]);

  return (
    <FlowsBaseInputBlock
      description={description}
      isRequired={isRequired}
      navigationInstructions={
        <NavigationInstructions
          type={isLastBlock ? 'last+enter' : 'enter'}
          goToNextStep={goToNextStep}
          isPreviewFlow={isPreviewFlow}
        />
      }
      subDescription={subDescription}
      title={title}
    >
      <HiddenInput type="button" ref={hiddenInputRef} />
      <StyleFileUploadContainer>
        {uppyFiles.length > 0 && (
          <StyledFileCardList>
            {uppyFiles.map((file: UppyFile) => {
              return (
                <FileCard
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  /* @ts-ignore */
                  errorMessage={file.error && ERROR_UPLOADING}
                  key={file.id}
                  loadingProgress={file.progress?.percentage}
                  onClickRetry={() => uppy.retryUpload(file.id)}
                  file={{
                    type: determineFileType(file),
                    name: file.name,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    /* @ts-ignore */
                    url: URL.createObjectURL(file.data),
                    size: file.size,
                  }}
                  isParticipation
                  onClickClose={() => {
                    uppy.removeFile(file.id);
                    onDeleteFileClick(file.name);
                  }}
                  status={determineFileStatus(file)}
                />
              );
            })}
          </StyledFileCardList>
        )}
        {!isPreviewFlow ? (
          <DragDrop
            width="100%"
            height="100%"
            note={MAXIMUM_UPLOAD_LIMIT}
            uppy={uppy}
            locale={{
              strings: {
                dropHereOr: UPLOAD_FILE,
              },
            }}
          />
        ) : (
          <DisabledDragandDrop>
            <DisabledDragDropInner>
              <Body variant="body1" color="gray8">
                {placeHolderText}
              </Body>
              <Body variant="body2" color="gray6">
                {PREVIEW_DISABLE_UPLOAD_TEXT}
              </Body>
            </DisabledDragDropInner>
          </DisabledDragandDrop>
        )}
      </StyleFileUploadContainer>
    </FlowsBaseInputBlock>
  );
}

export default FlowsFileUploadInputBlock;
