/* eslint-disable max-len */
import styled from 'styled-components';
import ThemeV2 from '../../../componentsV2/theme';
import Body from '../../atoms/Body';
import SVGIcon from '../../atoms/SVGIcon';
import {
  FlowAccordionItemValidations,
  FlowAccordionItemState,
} from './interface';
import { device } from '../../../constants/layout';

const colorMap = {
  [FlowAccordionItemValidations.Warning]: ThemeV2.palette.gold6,
  [FlowAccordionItemValidations.Error]: ThemeV2.palette.dustRed6,
  [FlowAccordionItemValidations.None]: ThemeV2.palette.green7,
  [FlowAccordionItemValidations.Success]: ThemeV2.palette.green7,
};

export const AccordionItemHeader = styled.button<{
  state: FlowAccordionItemState;
  isOpen?: boolean;
}>`
  position: relative;
  width: 100%;
  background: ${ThemeV2.palette.gray1};
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${ThemeV2.palette.gray4};
  padding: 12px 24px;
  cursor: ${({ state }) =>
    state === FlowAccordionItemState.Disabled ? 'default' : 'pointer'};
  pointer-events: ${({ state }) =>
    state === FlowAccordionItemState.Disabled ? 'none' : 'all'};
  outline: none;
  border: none;
  &:focus-visible {
    background: ${ThemeV2.palette.gray2};
  }
  ${({ isOpen }) =>
    !isOpen &&
    `&:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 1px;
        width: calc(100% - 20px);
        margin: auto;
        background: ${ThemeV2.palette.gray4};
      }
    `}
  @media ${device.mobile}, ${device.tablet} {
    display: grid;
    grid-template-columns: 18px 30px auto 18px;
    grid-template-rows: auto;
    grid-template-areas: 'accordionArrow accordionIcon title statusIcon' 'titleContent titleContent titleContent titleContent';
  }
  box-sizing: border-box;
`;

export const Wrapper = styled.div`
  background: ${ThemeV2.palette.gray1};
  border-radius: 4px;
  &:last-child {
    ${AccordionItemHeader} {
      &:after {
        display: none;
      }
    }
  }
`;

export const StyledArrowIcon = styled(SVGIcon)<{
  state: FlowAccordionItemState;
}>`
  svg path {
    transition: 0.2s ease;
    transition-property: fill, opacity;
    fill: ${({ state }) =>
      state === FlowAccordionItemState.Disabled
        ? ThemeV2.palette.gray6
        : ThemeV2.palette.gray8};
  }
  opacity: ${({ state }) => state === FlowAccordionItemState.Blur && '0.8'};
  @media ${device.mobile}, ${device.tablet} {
    grid-area: accordionArrow;
  }
`;

export const StyledAccordionIcon = styled(SVGIcon)<{
  state: FlowAccordionItemState;
}>`
  margin-left: 8px;
  svg path {
    transition: 0.2s ease;
    transition-property: fill, opacity;
    fill: ${({ state }) =>
      state === FlowAccordionItemState.Disabled
        ? ThemeV2.palette.gray6
        : ThemeV2.palette.geekBlue6};
  }
  opacity: ${({ state }) => state === FlowAccordionItemState.Blur && '0.8'};
  @media ${device.mobile}, ${device.tablet} {
    grid-area: accordionIcon;
  }
`;

export const AccordionTitle = styled(Body)<{ state: FlowAccordionItemState }>`
  margin-left: 4px;
  transition: 0.2s ease;
  transition-property: color, opacity;
  color: ${({ state }) =>
    state === FlowAccordionItemState.Disabled
      ? ThemeV2.palette.gray6
      : ThemeV2.palette.gray9};
  opacity: ${({ state }) => state === FlowAccordionItemState.Blur && '0.8'};
  @media ${device.mobile}, ${device.tablet} {
    grid-area: title;
  }
`;

export const AccordionSubTitle = styled(Body)<{
  state: FlowAccordionItemState;
}>`
  margin-left: 4px;
  color: ${({ state }) =>
    state === FlowAccordionItemState.Disabled
      ? ThemeV2.palette.gray6
      : ThemeV2.palette.gray9};
  opacity: ${({ state }) => state === FlowAccordionItemState.Blur && '0.8'};
`;

export const TitleContent = styled.div<{
  state: FlowAccordionItemState;
}>`
  transition: 0.2s ease;
  transition-property: opacity;
  opacity: ${({ state }) => state === FlowAccordionItemState.Blur && '0.8'};
  @media ${device.mobile}, ${device.tablet} {
    grid-area: titleContent;
  }
`;

export const TitleContentText = styled(Body)`
  margin-left: 4px;
  color: ${ThemeV2.palette.gray9};
`;

export const AccordionItemBody = styled.div``;

export const StatusIcon = styled(SVGIcon)<{
  validation: FlowAccordionItemValidations;
  state: FlowAccordionItemState;
}>`
  margin-left: auto;
  transition: 0.2s ease;
  transition-property: fill, opacity;
  svg path {
    fill: ${({ validation }) => colorMap[validation]};
  }
  opacity: ${({ state }) => state === FlowAccordionItemState.Blur && '0.8'};
  @media ${device.mobile}, ${device.tablet} {
    grid-area: statusIcon;
  }
`;

export const ValidationText = styled(Body)<{
  validation: FlowAccordionItemValidations;
  state: FlowAccordionItemState;
}>`
  margin-left: 4px;
  color: ${({ validation }) => colorMap[validation]};
  transition: 0.2s ease;
  transition-property: color, opacity;
  opacity: ${({ state }) => state === FlowAccordionItemState.Blur && '0.8'};
`;
