import React from 'react';
import styled from 'styled-components';

import Accordion from '../../../molecules/Accordion';
import Menu from '../../../molecules/Menu';
import MenuList from '../../../molecules/MenuItem';

import ThemeV2 from '../../../../componentsV2/theme';
import Body from '../../../atoms/Body';
export interface GeneralMenuAccordionProps {
  isAccordionOpen?: boolean;
  onAccordionClick: () => void;
  accordionTitle: string;
  lists: {
    id: string;
    title: string;
    icon: string;
    isActive?: boolean;
    isEnabled?: boolean;
    count?: number;
  }[];
  onItemClick: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | string,
  ) => void;
  isHovered?: boolean;
}

const StyledAccordion = styled(Accordion)`
  overflow: hidden;
`;

const MenuListWrapper = styled.div`
  margin-top: 4px;
`;

const StyledMenuListItem = styled(MenuList.Item)`
  margin-bottom: 4px;
  &:last-child {
    margin-bottom: 0px;
  }
`;

const AccordionHeader = ({
  title,
  isAccordionOpen,
  isExpanded,
}: {
  title: string;
  isAccordionOpen: boolean;
  isExpanded: boolean;
}) => {
  return (
    <Menu
      prefix="view-all"
      icon="caret-rounded"
      text={title}
      rotate={isAccordionOpen}
      isExpanded={isExpanded}
    />
  );
};

const GeneralMenuAccordion = (props: GeneralMenuAccordionProps) => {
  const {
    accordionTitle,
    isAccordionOpen = false,
    onAccordionClick,
    lists,
    onItemClick,
    isHovered = false,
  } = props;

  return (
    <StyledAccordion
      isAccordionOpen={isAccordionOpen}
      accordionHeader={
        <AccordionHeader
          title={accordionTitle}
          isAccordionOpen={isAccordionOpen}
          isExpanded={isHovered}
        />
      }
      onAccordionClick={onAccordionClick}
    >
      <MenuListWrapper>
        <MenuList>
          {lists
            .filter((list) => list.isEnabled)
            .map((list) => {
              return (
                <StyledMenuListItem
                  id={list.id}
                  key={list.id}
                  isSelected={list.isActive}
                  onClick={onItemClick}
                  isExpanded={isHovered}
                >
                  <MenuList.Icon
                    icon={list.icon}
                    color={ThemeV2.palette.gray9}
                  />
                  <MenuList.Text>{list.title}</MenuList.Text>
                  {list.count !== undefined && list.count > 0 ? (
                    <MenuList.SuffixElement>
                      <Body variant="body3" color="gray1">
                        {list.count}
                      </Body>
                    </MenuList.SuffixElement>
                  ) : (
                    <></>
                  )}
                </StyledMenuListItem>
              );
            })}
        </MenuList>
      </MenuListWrapper>
    </StyledAccordion>
  );
};

export default GeneralMenuAccordion;
