import React, { memo, useMemo, useCallback, ReactNode } from 'react';

import { GIVEN_PRIVATELY, AND, OTHERS } from '../../../languages/en/home';
import { getAgoTime } from '../../../Utils/time';
import { getDisplayUserName, isDeactivatedUser } from '../../../Utils/user';

import Avatar from '../../atoms/Avatar';
import Body from '../../atoms/Body';
import ClickableLink from '../ClickableLink';
import AssemblyCurrencyIcon from '../../atoms/AssemblyCurrencyIcon';
import FeedPostHeaderOption from '../../organism/FeedPostHeaderOption';
import UserListDropdown from '../UserListDropdown';
import { Flex } from '../../../Utils/styles/display';

import { FeedPostHeaderProps } from './interfaces';
import {
  StyledFeedPostHeaderContent,
  OthersLabel,
  FeedPostHeaderOptionsContainer,
} from './styles';
import { PostTypes } from '../../../interfaces/Home';
import birthdayIcon from '../../atoms/SVGIcon/icons/birthday.svg';
import anniversaryIcon from '../../atoms/SVGIcon/icons/anniversary.svg';
import {
  BIRTHDAY_TEXT,
  ANNIVERSARY_TEXT,
} from '../../../languages/en/feed/index';
import { EACH, CELEBRATED } from '../../../languages/en/singleWords';
import { FeedPostUserProps } from '../../../interfaces/Feed';
import { DEACTIVATED_USER } from '../../../languages/en/notifications';

const FeedPostHeader = (props: FeedPostHeaderProps) => {
  const {
    from,
    to,
    type,
    trophies,
    currency,
    createdAt,
    isPrivate,
    currentUserId,
    avatarSize = '40px',
    hasTrophies,
    onClickUserName,
    showEach,
    showOptions = true,
    onRemoveGif,
    onCopyLink,
    onDeletePostSelectedOption,
    onDeleteModalSubmitClick,
    dropdownOptions,
    isDummy = false,
  } = props;

  const handleOnClickUserName = useCallback(
    (data: unknown) => {
      const member = data as FeedPostUserProps;
      onClickUserName(member);
    },
    [onClickUserName],
  );

  const getUserName = useCallback(
    (user: FeedPostUserProps, showOnlyFirstName: boolean | undefined) => {
      return getDisplayUserName({
        user,
        currentUserId,
        showOnlyFirstName,
      });
    },
    [currentUserId],
  );

  const renderUser = useCallback(
    (
      user: FeedPostUserProps,
      separator?: string | ReactNode,
      showOnlyFirstName?: boolean,
    ) => {
      if (isDummy) {
        return (
          <span key={user.memberID}>
            <Body inline variant="body2Medium" color="gray8">
              {getUserName(user, showOnlyFirstName)}
            </Body>
            {separator && (
              <Body inline variant="body2Medium" color="gray8">
                {separator}&nbsp;
              </Body>
            )}
          </span>
        );
      }

      return (
        <span key={user.memberID}>
          {isDeactivatedUser(user) ? (
            <Body inline variant="body2Medium" color="gray6" textAlign="center">
              {DEACTIVATED_USER}
            </Body>
          ) : (
            <ClickableLink data={user} onClickCallback={handleOnClickUserName}>
              {getUserName(user, showOnlyFirstName)}
            </ClickableLink>
          )}

          {separator && (
            <Body inline variant="body2Medium" color="geekBlue6">
              {separator}&nbsp;
            </Body>
          )}
        </span>
      );
    },
    [getUserName, handleOnClickUserName, isDummy],
  );

  const renderAvatar = useMemo(() => {
    if (type === PostTypes.BIRTHDAY) {
      return (
        <Avatar
          img={birthdayIcon}
          size={avatarSize}
          fontSize="16px"
          name={BIRTHDAY_TEXT}
        />
      );
    }
    if (type === PostTypes.ANNIVERSARY) {
      return (
        <Avatar
          img={anniversaryIcon}
          size={avatarSize}
          fontSize="16px"
          name={ANNIVERSARY_TEXT}
        />
      );
    }
    if (from !== null) {
      return (
        <Avatar
          img={from.image ? from.image : ''}
          size={avatarSize}
          fontSize="16px"
          name={from.firstName}
          userId={from.memberID}
          isDeleted={isDeactivatedUser(from)}
        />
      );
    }
    return null;
  }, [avatarSize, from, type]);

  const renderFrom = useMemo(() => {
    if (from !== null) {
      return renderUser(from);
    }
    if (type === PostTypes.BIRTHDAY) {
      return (
        <Body variant="body2Bold" color="gray8" inline>
          {BIRTHDAY_TEXT}
        </Body>
      );
    }
    if (type === PostTypes.ANNIVERSARY) {
      return (
        <Body variant="body2Bold" color="gray8" inline>
          {ANNIVERSARY_TEXT}
        </Body>
      );
    }
    return from !== null ? renderUser(from) : '';
  }, [from, renderUser, type]);

  const renderType = useMemo(() => {
    if (type === PostTypes.BIRTHDAY || type === PostTypes.ANNIVERSARY) {
      return (
        <Body inline variant="body2" color="gray8">
          &nbsp;to&nbsp;
        </Body>
      );
    }
    return (
      <Body inline variant="body2" color="gray8">
        &nbsp;{type === 'recognition' ? CELEBRATED : type}&nbsp;
      </Body>
    );
  }, [type]);

  const renderTo = useMemo(() => {
    const toUsers = [...to];
    const toUsersLength = toUsers.length;

    // To swap current user position at first
    const currentUserIndex = toUsers.findIndex(
      (user) => user.memberID === currentUserId,
    );

    if (currentUserIndex !== -1 && currentUserIndex !== 0) {
      const currentUser = toUsers[currentUserIndex];
      toUsers.splice(currentUserIndex, 1);
      toUsers.unshift(currentUser);
    }

    return toUsers.slice(0, 3).map((user: FeedPostUserProps, index: number) => {
      let separator: string | ReactNode = '';
      switch (toUsersLength) {
        case 1:
          return renderUser(user);
        case 2:
          separator =
            index === 0 ? (
              <Body inline variant="body2" color="gray8">
                {' '}
                {AND}
              </Body>
            ) : (
              ''
            );
          return renderUser(user, separator, true);
        case 3:
          if (index === 0) {
            separator = ',';
          } else if (index === 1) {
            separator = (
              <>
                ,{' '}
                <Body inline variant="body2" color="gray8">
                  {AND}
                </Body>
              </>
            );
          }

          return renderUser(user, separator, true);
        default:
          if (index === 2) {
            return null;
          }
          if (index === 0) {
            separator = ',';
          } else if (index === 1) {
            separator = (
              <>
                ,{' '}
                <Body inline variant="body2" color="gray8">
                  {AND}
                </Body>
              </>
            );
          }

          return renderUser(user, separator, true);
      }
    });
  }, [currentUserId, renderUser, to]);

  const renderOthers = useMemo(() => {
    const otherUsers = [...to];
    const toUsersLength = otherUsers.length;
    const othersCount = toUsersLength - 2;
    const label = `${othersCount} ${OTHERS}`;
    // To swap current user position at first
    const currentUserIndex = otherUsers.findIndex(
      (user) => user.memberID === currentUserId,
    );

    if (currentUserIndex > 0) {
      const currentUser = otherUsers[currentUserIndex];
      otherUsers.splice(currentUserIndex, 1);
      otherUsers.unshift(currentUser);
    }
    return toUsersLength > 3 ? (
      <UserListDropdown users={otherUsers} onUserClick={onClickUserName}>
        <OthersLabel variant="body2Medium" color="geekBlue6">
          {label}
        </OthersLabel>
      </UserListDropdown>
    ) : null;
  }, [currentUserId, onClickUserName, to]);

  return (
    <div>
      <Flex padding="16px" alignItems="start">
        {renderAvatar}
        <StyledFeedPostHeaderContent>
          <Flex flexWrap="wrap">
            {renderFrom}
            {renderType}
            {renderTo}
            {renderOthers}
          </Flex>
          <div>
            <Body inline variant="body3" color="gray7">
              {getAgoTime(createdAt)}
            </Body>
            {isPrivate && (
              <>
                <Body inline variant="body3" color="gray7">
                  &nbsp;•&nbsp;{GIVEN_PRIVATELY}
                </Body>
              </>
            )}
            {trophies && (
              <Body data-testid="trophies" inline variant="body3" color="gray7">
                &nbsp;•&nbsp;
                {currency && (
                  <AssemblyCurrencyIcon
                    assemblyCurrency={currency}
                    id="id"
                    currencyName="currencyName"
                    size="12px"
                  />
                )}
                {trophies}
                &nbsp;
                {showEach && (
                  <Body inline variant="body3" color="gray7">
                    {EACH}
                  </Body>
                )}
              </Body>
            )}
          </div>
        </StyledFeedPostHeaderContent>
        {showOptions && !isDummy && (
          <FeedPostHeaderOptionsContainer>
            <FeedPostHeaderOption
              dropdownOptions={dropdownOptions}
              icon="more"
              title="feed post options"
              hasTrophies={hasTrophies}
              onRemoveGif={onRemoveGif}
              onCopyLink={onCopyLink}
              onDeletePostSelectedOption={onDeletePostSelectedOption}
              onDeleteModalSubmitClick={onDeleteModalSubmitClick}
            />
          </FeedPostHeaderOptionsContainer>
        )}
      </Flex>
    </div>
  );
};

const MemoizedFeedPostHeader = memo(FeedPostHeader);
MemoizedFeedPostHeader.displayName = 'FeedPostHeader';

export default MemoizedFeedPostHeader;
