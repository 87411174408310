import { IMemberDTO } from '../../../../interfaces/member';
import { FlowOccurrenceDetail } from '../../../../queries/Flows/interfaces';
// eslint-disable-next-line max-len
import { getFormattedTimeFromDate } from '../../../quickSetup/MeetingSelectionController/useMeetingsSelectionController/utils';
import { ANONYMOUS_MEMBER_ID } from '../../../../Utils/rightDrawer';

export const mapDepartmentsDataToFilterOptions = (
  data: string[] | undefined,
) => {
  return !data || !data.length
    ? []
    : data.map((department: string) => {
        return {
          value: false,
          label: department,
          id: department,
        };
      });
};

export const mapParticipantsDataToFilterOptions = (
  searchedMembers: IMemberDTO[] | undefined,
) => {
  return !searchedMembers || !searchedMembers.length
    ? []
    : searchedMembers.map((member: IMemberDTO) => {
        return {
          value: false,
          label: member.name || '',
          id: member.memberID || '',
          img: member.image || '',
          showAvatar: true,
          memberState: member.memberState,
          isAnonymous: member.memberID === ANONYMOUS_MEMBER_ID,
        };
      });
};

export const mapOccurrenceDateToFilterOptions = (
  data: FlowOccurrenceDetail[],
) => {
  return data.map((occurrence: FlowOccurrenceDetail) => {
    return {
      value: false,
      label: `${new Date(
        occurrence.startTime,
      ).toLocaleDateString()} at ${getFormattedTimeFromDate(
        new Date(occurrence.startTime),
      )}`,
      id: occurrence.occurrenceId,
    };
  });
};
