import React, { useState } from 'react';
import Body from '../../../atomic/atoms/Body';
import EmoticonWithText from '../../../atomic/atoms/EmoticonWithText';
import FlowEndOccurrenceModal from '../../../atomic/molecules/FlowEndOccurrenceModal';
import FlowLeftDrawerCollapsibleNavigation from '../../../atomic/molecules/FlowLeftDrawerCollapsibleNavigation';
import BasicModalTemplate from '../../../atomic/templates/BasicModalTemplate';
import { ComponentStatus } from '../../../interfaces/component';
import {
  FLOWS_ARCHIVE_MODAL,
  FLOWS_END_OCCURRENCE_MODAL,
  FLOWS_LEFT_DRAWER_NAVIGATION_TITLE,
} from '../../../languages/en/flows';
import { ARCHIVE, WHEN, YOU } from '../../../languages/en/singleWords';
import useFlowsActiveListController from './useFlowsActiveListController';
import { LeftDrawerCollapsibleNavItemProps } from '../../../atomic/molecules/FlowLeftDrawerCollapsibleNavigation/types';
import { LEFT_DRAWER_ANALYTICS_EVENTS } from '../../../Utils/analytics/constants';
import { allFlowFeedHeaderOptions } from '../FlowFeedHeaderController/data';
import { isMobile, isTablet } from '../../../Utils/window';
import useGetUserFlowPermissions from '../../../hooks/useGetUserFlowPermissions';
import FlowsArchivedListController from '../FlowsArchivedListController';
import FlowsMenuAccordion from '../../../atomic/organism/MenuAccordion/FlowsMenuAccordion';
import { trackLeftDrawerEvent } from '../../../Utils/analytics';

const FlowsActiveListController = ({
  isInQuickSetup,
  isLeftAsideOpen,
  isLeftNavHovered,
}: {
  isInQuickSetup: boolean;
  isLeftAsideOpen: boolean;
  isLeftNavHovered: boolean;
}) => {
  const [flowItem, setFlowItem] = useState<
    LeftDrawerCollapsibleNavItemProps | undefined
  >(undefined);
  const {
    models: {
      isOpen,
      dropdownMenuItems,
      menuItems,
      totalNotificationsCount,
      isLoading,
      isError,
      isSuccess,
      isEndOccurrenceModalOpen,
      isArchiveModalOpen,
      isArchiveFlowLoading,
      updatingFlowDetails,
      isEndOccurrenceModalLoading,
      canCreateFlows,
    },
    operations: {
      handleMenuItemClick,
      handleMenuItemOptionsSelect,
      toggleExpandNavigation,
      onDropdownMenuItemRightClick,
      handleAddNewFlowClick,
      setEndOccurrenceModalClose,
      onEndOccurrenceClick,
      handleOnArchiveClick,
      setArchiveModalClose,
    },
  } = useFlowsActiveListController(isInQuickSetup);

  const onDropdownMenuItemClick = (option: any) => {
    handleMenuItemOptionsSelect(option);
    if (option === allFlowFeedHeaderOptions.copyFlowFeedLink.id) {
      trackLeftDrawerEvent(
        LEFT_DRAWER_ANALYTICS_EVENTS.LEFT_DRAWER_EVENTS_FLOW_LINK_COPIED,
        flowItem,
      );
    } else if (
      option === allFlowFeedHeaderOptions.editNotificationSettings.id
    ) {
      trackLeftDrawerEvent(
        LEFT_DRAWER_ANALYTICS_EVENTS.LEFT_DRAWER_EVENTS_EDIT_NOTIFICATION_SETTINGS_CLICKED,
        flowItem,
      );
    }
  };

  const getFlowItemOnMenuClick = (id: string) => {
    const individualFlowItem = menuItems.find((flow) => flow.id === id);
    return individualFlowItem;
  };

  const { canUserCreateFlow = false } = useGetUserFlowPermissions();

  const isAddNewFlowOn = canUserCreateFlow && !isMobile && !isTablet;

  if (isLoading) {
    return (
      <FlowsMenuAccordion
        status={ComponentStatus.LOADING}
        accordionTitle={FLOWS_LEFT_DRAWER_NAVIGATION_TITLE}
        isAccordionOpen={isOpen}
        onAccordionClick={toggleExpandNavigation}
        isHover={isLeftNavHovered || isLeftAsideOpen}
      />
    );
  }

  const ArchiveModal = (
    <BasicModalTemplate
      isOpen={isArchiveModalOpen}
      heading={FLOWS_ARCHIVE_MODAL.MODAL_HEADING}
      primaryButton={{
        text: `${FLOWS_ARCHIVE_MODAL.ARCHIVE_LABEL}`,
        onClick: () => {
          handleOnArchiveClick();
          trackLeftDrawerEvent(
            LEFT_DRAWER_ANALYTICS_EVENTS.LEFT_DRAWER_EVENTS_FLOW_ARCHIVED,
            flowItem,
          );
        },
        status: 'warning',
        isLoading: isArchiveFlowLoading,
      }}
      secondaryButton={{
        text: `${FLOWS_ARCHIVE_MODAL.CANCEL_LABEL}`,
        onClick: setArchiveModalClose,
      }}
      onModalClose={setArchiveModalClose}
    >
      <Body variant="body2" inline>
        {`${WHEN} ${YOU.toLocaleLowerCase()} ${ARCHIVE.toLocaleLowerCase()} `}
      </Body>
      <Body variant="body2Medium" inline>
        <EmoticonWithText
          emoticon={updatingFlowDetails?.emoticon || ''}
          text={updatingFlowDetails?.label}
          hasBoldText
        />
      </Body>
      <Body variant="body2" inline>
        {', '}
        {FLOWS_ARCHIVE_MODAL.MODAL_CONTENT_TEXT}
      </Body>
    </BasicModalTemplate>
  );

  if (isSuccess) {
    if (menuItems.length) {
      return (
        <>
          {ArchiveModal}
          <FlowEndOccurrenceModal
            heading={FLOWS_END_OCCURRENCE_MODAL.modalHeading}
            isOpen={isEndOccurrenceModalOpen}
            onCancelClick={setEndOccurrenceModalClose}
            onEndOccurrenceClick={() => {
              onEndOccurrenceClick();
              trackLeftDrawerEvent(
                LEFT_DRAWER_ANALYTICS_EVENTS.LEFT_DRAWER_EVENTS_OCCURRENCE_ENDED,
                flowItem,
              );
            }}
            onModalClose={setEndOccurrenceModalClose}
            isLoading={isEndOccurrenceModalLoading}
          />
          {
            <FlowsMenuAccordion
              status={ComponentStatus.LOADED}
              isAccordionOpen={isOpen}
              accordionTitle={FLOWS_LEFT_DRAWER_NAVIGATION_TITLE}
              onAccordionClick={() => {
                toggleExpandNavigation();
                if (isOpen) {
                  trackLeftDrawerEvent(
                    LEFT_DRAWER_ANALYTICS_EVENTS.LEFT_DRAWER_EVENTS_FEEDS_LIST_COLLAPSED,
                  );
                } else {
                  trackLeftDrawerEvent(
                    LEFT_DRAWER_ANALYTICS_EVENTS.LEFT_DRAWER_EVENTS_FEEDS_LIST_EXPANDED,
                  );
                }
              }}
              navItems={menuItems}
              onMenuItemClick={(id) => {
                handleMenuItemClick(id as string);
                trackLeftDrawerEvent(
                  LEFT_DRAWER_ANALYTICS_EVENTS.LEFT_DRAWER_EVENTS_FLOW_CLICKED,
                  getFlowItemOnMenuClick(id as string),
                );
              }}
              dropdownMenuItems={dropdownMenuItems}
              onDropdownMenuItemClick={onDropdownMenuItemClick}
              onMenuItemRightClick={(item) => {
                onDropdownMenuItemRightClick(item);
                trackLeftDrawerEvent(
                  LEFT_DRAWER_ANALYTICS_EVENTS.LEFT_DRAWER_EVENTS_FLOW_MENU_OPENED,
                  item,
                );
                setFlowItem(item);
              }}
              isAddNewFlowOn={!isInQuickSetup && isAddNewFlowOn}
              addFlowsButtonClick={handleAddNewFlowClick}
              totalNotificationsCount={totalNotificationsCount}
              isHover={isLeftNavHovered || isLeftAsideOpen}
              canAddFlows={!isInQuickSetup && canCreateFlows}
              disabled={isInQuickSetup}
            >
              <FlowsArchivedListController
                isHover={isLeftNavHovered || isLeftAsideOpen}
              />
            </FlowsMenuAccordion>
          }
        </>
      );
    }
    return (
      <FlowLeftDrawerCollapsibleNavigation
        isExpanded={isOpen}
        onExpandClick={toggleExpandNavigation}
        heading={FLOWS_LEFT_DRAWER_NAVIGATION_TITLE}
        canAddFlows
        addFlowsButtonClick={handleAddNewFlowClick}
        status={ComponentStatus.EMPTY}
        isAddNewFlowOn={isAddNewFlowOn}
      />
    );
  }

  if (isError) {
    return (
      <FlowLeftDrawerCollapsibleNavigation
        isExpanded={isOpen}
        onExpandClick={toggleExpandNavigation}
        heading={FLOWS_LEFT_DRAWER_NAVIGATION_TITLE}
        status={ComponentStatus.ERROR}
      />
    );
  }

  return null;
};

export default FlowsActiveListController;
