import React from 'react';

import imgUrl from '../../../img/DummyLeaderBoard.jpg';
import { dummyLeaderboardTexts } from '../../../languages/en/home/mainFeedDrawer';
import {
  StyledDummyLeaderboardImage,
  StyledHelperText,
  StyledWelcomeText,
} from './styles';

const DummyLeaderboard = () => {
  return (
    <div>
      <StyledWelcomeText variant="body1Medium">
        {dummyLeaderboardTexts.WELCOME_TEXT}
      </StyledWelcomeText>
      <StyledHelperText variant="body2">
        {dummyLeaderboardTexts.HELPER_TEXT}
      </StyledHelperText>
      <StyledDummyLeaderboardImage src={imgUrl} alt="DummyLeaderBoardImage" />
    </div>
  );
};

export default DummyLeaderboard;
