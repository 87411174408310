import { MemberState, UserStatus } from './user';

export enum MemberRole {
  Admin = 'Admin',
  Employee = 'Employee',
  Owner = 'Owner',
}

export interface IMemberDTO {
  memberID: string;
  firstName: string;
  lastName: string;
  role: MemberRole[];
  username: string;
  image?: string | null;
  status?: UserStatus;
  name?: string;
  pointsGiven?: number;
  totalPointsGiven?: number;
  memberState?: MemberState;
  email?: string;
}
