import { BaseEmoji } from 'emoji-mart/dist-es';
import { ReactNode } from 'react';
import { FlowBlockAPITypes, SelectablePeopleTypes } from '..';
import { MenuItemIndividualItem } from '../../../atomic/molecules/Dropdown_V2/interfaces';
import { OptionItemProps } from '../../../atomic/molecules/OptionItemsEditor/interface';
import { AutocompleteDropdownItem } from '../../../atomic/organism/Autocomplete/interfaces';
import { SelectedCustomRecurrenceTypes } from '../../../atomic/organism/CustomRecurrence/types';
import { AssemblyCurrency } from '../../assembly';
import { IMemberDTO } from '../../member';
import { MemberState } from '../../user';

export enum FlowBuilderBlockTypes {
  ACTION = 'ACTION',
  TRIGGER = 'TRIGGER',
  CONTENT = 'CONTENT',
  VISIBILITY = 'VISIBILITY',
  PARTICIPANTS = 'PARTICIPANTS',
  COMPLETION = 'COMPLETION',
}

export type ErrorEntry = {
  id: string;
  message: string;
};

export interface FlowBuilderBlockData {
  [FlowBuilderBlockTypes.ACTION]: ActionBuilderBlockData | null;
  [FlowBuilderBlockTypes.TRIGGER]: TriggerBuilderBlockData;
  [FlowBuilderBlockTypes.CONTENT]: ContentBuilderBlockData;
  [FlowBuilderBlockTypes.VISIBILITY]: VisibilityBuilderBlockData | null;
  [FlowBuilderBlockTypes.PARTICIPANTS]: ParticipantsBuilderBlockData;
}

export type ScheduleRule = {
  rule: string;
};

export type FlowBuilderState = {
  blockData: FlowBuilderBlockData;
  isFlowDataChangeConfirmed: boolean;
  description: string;
  flowName: string;
  templateId: string;
  templateName: string;
  isInEditMode: boolean;
  showDataChangeConfirm: boolean;
  owner: AutocompleteDropdownItem<string, IMemberDTO>[];
  emoji: BaseEmoji | null;
  templateType: TriggerType | undefined;
  disableNavigationModal: boolean;
  isInviteNewTeammatesModalOpen: boolean;
  anonymousSettings: MenuItemIndividualItem;
  // this is only used to check whether the RRule has updated or not while editing the flow
  currentSchedule: ScheduleRule | undefined;
  pendingEmails: string[];
  newParticipantsToInvite: string[];
  newVisibilityMembersToInvite: string[];
  newContentMembersToInvite: string[];
  isShareSheetModalOpen: boolean;
  setFlowName: (updatedName: string) => void;
  setTemplateId: (updatedTemplateId: string) => void;
  setTemplateName: (updatedTemplateName: string) => void;
  setIsFlowDataChangeConfirmed: (
    updatedIsFlowDataChangeConfirmed: boolean,
  ) => void;
  setShowDataChangeConfirm: (showUpdatedDataChangeConfirm: boolean) => void;
  setIsInEditMode: (updatedIsInEditMode: boolean) => void;
  setDescription: (updatedDescription: string) => void;
  setEmoji: (selectedEmoji: BaseEmoji) => void;
  revertBlockDataToDefaultState: () => void;
  setOwner: (
    updatedOwner: AutocompleteDropdownItem<string, IMemberDTO>[],
  ) => void;
  setSpecificBlockData: <Key extends keyof FlowBuilderBlockData>(
    blockKey: Key,
    updatedBlockData: FlowBuilderBlockData[Key],
  ) => void;
  setCurrentSchedule: (
    updatedCurrentSchedule: ScheduleRule | undefined,
  ) => void;
  setDisableNavigationModal: (disable: boolean) => void;
  setTemplateType: (updatedTemplateType: TriggerType | undefined) => void;

  setIsInviteNewTeammatesModalOpen: (
    updateIsInviteNewTeammatesModalOpen: boolean,
  ) => void;
  setPendingEmails: (updatedEmails: string[]) => void;
  setNewParticipantsToInvite: (updatedNewMembers: string[]) => void;
  setNewVisibilityMembersToInvite: (updatedNewMembers: string[]) => void;
  setNewContentMembersToInvite: (updatedNewMembers: string[]) => void;
  setShareSheetModalOpen: (updateIsShareSheetModalOpen: boolean) => void;
  setAnonymousSettings: (
    updatedAnonymousSettings: MenuItemIndividualItem,
  ) => void;
};

export type OpenEndedBlockSettingOptions = {
  mentions?: boolean;
  emojis?: boolean;
  gifs?: boolean;
  attachments?: boolean;
};

export type PersonSelectorBlockSettingOptions = {
  selectedBlockParticipants: SelectablePeopleTypes;
  chosenParticipantSelection: SelectableParticipantSelection;
};

export type OptionsSelectTypes = 'EXACT_NUMBER' | 'RANGE' | 'UNLIMITED_OPTIONS';

export interface BaseOptionsSelectObject {
  type: OptionsSelectTypes;
}

export interface UnlimitedOptionsSelectObject extends BaseOptionsSelectObject {
  type: 'UNLIMITED_OPTIONS';
}

export interface RangeOptionsSelectObject extends BaseOptionsSelectObject {
  type: 'RANGE';
  minOptions: number;
  maxOptions: number;
}

export interface ExactOptionsSelectObject extends BaseOptionsSelectObject {
  type: 'EXACT_NUMBER';
  exactOptions: number;
}

export type OptionsSelectObject =
  | ExactOptionsSelectObject
  | RangeOptionsSelectObject
  | UnlimitedOptionsSelectObject;

export interface BaseBlockBuilderSettingState {
  type: FlowBlockAPITypes;
  isDescriptionNeeded: boolean;
  isRequired: boolean;
  toggleIsRequired: () => void;
  toggleDescriptionNeeded: () => void;
  disableRequiredToggle?: boolean;
  postCustomModalClose?: () => void;
}

export interface OptionSelectAttributes {
  currentOptionSelectObject: OptionsSelectObject;
  changeCurrentOptionSelectObject: (updatedOption: OptionsSelectObject) => void;
  maximumSelectableOptions: number;
}

export interface OpenEndedBlockBuilderSettingState
  extends BaseBlockBuilderSettingState {
  type: 'OPEN_ENDED';
  minimumCharacters?: number;
  maximumCharacters?: number;
  onCharactersLimitChange: (
    minimumCharacters?: number,
    maximumCharacters?: number,
  ) => void;
  openEndedOptions: OpenEndedBlockSettingOptions;
  changeOpenEndedOptions: (
    updatedOptions: OpenEndedBlockSettingOptions,
  ) => void;
}

export interface MultiOptionBlockBuilderSettingState
  extends BaseBlockBuilderSettingState,
    OptionSelectAttributes {
  type: 'DROPDOWN' | 'MULTI_CHOICE';
}

export interface ScaleBlockBuilderSettingState
  extends BaseBlockBuilderSettingState {
  type: 'SCALE';
  minimumRange: number;
  maximumRange: number;
  onRangeChange: (minimumRange: number, maximumRange: number) => void;
}

export interface FileUploadBlockBuilderSettingState
  extends BaseBlockBuilderSettingState {
  type: 'FILE_UPLOAD';
  // ALLOWED FILE TYPES
}

export interface GifBlockBuilderSettingState
  extends BaseBlockBuilderSettingState {
  type: 'GIF';
}

export type SelectableParticipantSelection = 'ONE_PERSON' | 'UNLIMITED_PEOPLE';
export interface PersonSelectorBlockBuilderSettingState
  extends BaseBlockBuilderSettingState {
  type: 'PERSON_SELECTOR';
  chosenParticipantSelection: SelectableParticipantSelection;
  changeChosenParticipantSelection: (
    updatedSelection: SelectableParticipantSelection,
  ) => void;
  selectedBlockParticipants: SelectablePeopleTypes;
  changeBlockParticipants: (newViewers: SelectablePeopleTypes) => void;
  customModalOptions?: {
    component: ReactNode;
  };
  customPersonSelectorCount?: number;
}

export type GivePointsStackCurrencyAmount = {
  type: 'PERCENT' | 'EXACT';
  value: number;
};

export interface GivePointsBlockBuilderSettingState
  extends BaseBlockBuilderSettingState {
  type: 'GIVE_POINTS_STACK';
  hideCurrencyValues: boolean;
  limitAmountDetails?: GivePointsStackCurrencyAmount;
  toggleHideCurrencyValues: () => void;
  changeAmountDetails: (amountDetails?: GivePointsStackCurrencyAmount) => void;
}

export type BlockBuilderSettingState =
  | OpenEndedBlockBuilderSettingState
  | MultiOptionBlockBuilderSettingState
  | ScaleBlockBuilderSettingState
  | FileUploadBlockBuilderSettingState
  | GifBlockBuilderSettingState
  | PersonSelectorBlockBuilderSettingState
  | GivePointsBlockBuilderSettingState;

export interface ContentBlockLocalProperties {
  onDeleteClick: (
    blockId: string,
    blockType: string,
    isLinkedBlock?: boolean,
  ) => void;
  onMoveUpClick: (blockId: string) => void;
  onMoveDownClick: (blockId: string) => void;
  handleToggleIsRequired: () => void;
  handleToggleIsDescriptionNeeded: () => void;
  onValueChange: (updatedBlock: ContentBlockState) => void;
  currentIndex: number;
  totalNumberOfItems: number;
}

export interface ContentBlockBaseState {
  id: string;
  type: FlowBlockAPITypes;
  title: string;
  description: string | null;
  blockId?: string;
  isRequired: boolean;
  assemblyCurrency: AssemblyCurrency;
  isLinkedBlock?: boolean;
}

export interface ContentScaleBlockState extends ContentBlockBaseState {
  type: 'SCALE';
  labelInputOne: string;
  labelInputTwo: string;
  labelInputThree: string;
  minimumRange: number;
  maximumRange: number;
}
export interface ContentFileUploadBlockState extends ContentBlockBaseState {
  type: 'FILE_UPLOAD';
}

export interface ContentGifBlockState extends ContentBlockBaseState {
  type: 'GIF';
}

export interface ContentGivePointsBlockState extends ContentBlockBaseState {
  type: 'GIVE_POINTS_STACK';
  hideCurrencyValues: boolean;
  limitAmountDetails?: GivePointsStackCurrencyAmount;
}

export interface ContentOpenEndedBlockState extends ContentBlockBaseState {
  type: 'OPEN_ENDED';
  openEndedOptions: OpenEndedBlockSettingOptions;
  maximumCharacters?: number;
  minimumCharacters?: number;
}
export interface ContentPersonSelectorBlockState extends ContentBlockBaseState {
  type: 'PERSON_SELECTOR';
  chosenParticipantSelection: SelectableParticipantSelection;
  selectedBlockParticipants: SelectablePeopleTypes;
  isLinkedBlock: boolean;
  criteriaGroups?: CriteriaGroups | undefined;
  customPersonSelectorCount?: number;
}
export interface ContentMultiOptionBlockState extends ContentBlockBaseState {
  type: 'DROPDOWN' | 'MULTI_CHOICE';
  currentOptionSelectObject: OptionsSelectObject;
  maximumSelectableOptions: number;
  options: OptionItemProps[];
  optionType?: 'SINGLE' | 'MULTI';
}

export type ContentBlockState =
  | ContentScaleBlockState
  | ContentGifBlockState
  | ContentGivePointsBlockState
  | ContentOpenEndedBlockState
  | ContentPersonSelectorBlockState
  | ContentMultiOptionBlockState
  | ContentFileUploadBlockState;
export interface ActionBuilderBlockData {
  actionType: 'SEND_FORM';
  errors?: Array<ErrorEntry> | null;
}
export interface TriggerConfigurationSettings {
  answerAnytime: boolean;
  dueDateTimeType: 'MONTH' | 'WEEK' | 'DAY' | 'MINUTES';
  dueDateTimeValue: number;
}
export type ContentBuilderBlockData = {
  contentBlocks: ContentBlockState[];
  errors?: Array<ErrorEntry> | null;
};

export type TriggerType = 'ONDEMAND' | 'SCHEDULED' | 'NO_TRIGGER';

export type TriggerBuilderBlockData = {
  triggerType: TriggerType | undefined;
  errors?: Array<ErrorEntry> | null;
  endTimeInMinutes: number;
  shortcut: boolean;
  selectedCustomRecurrenceTypes: SelectedCustomRecurrenceTypes | undefined;
  schedule: ScheduleRule | undefined;
  isSchedulerTouched?: boolean;
};
export interface VisibilityBuilderBlockData {
  everyone: boolean | undefined;
  onlyParticipants: boolean | undefined;
  onlyOwners: boolean | undefined;
  custom: boolean | undefined;
  skippedMembers?: Array<string> | undefined;
  criteriaGroups?: CriteriaGroups | undefined;
  type: string;
  errors?: Array<ErrorEntry> | null;
}

export interface ParticipantsBuilderBlockData {
  participantsCriteria: CriteriaGroups | undefined;
  errors?: Array<ErrorEntry> | null;
}

export type CriteriaCondition = 'and' | 'or';
export type CriteriaField = 'everyone' | 'department' | 'email' | 'member';
export type CriteriaOperator = 'is' | 'isNot';
export type CriteriaValueItem = {
  id: string;
  value: string;
  memberState?: MemberState;
};
export type CriteriaValue = CriteriaValueItem[];

export type CriteriaRule = {
  ruleId: string;
  field: CriteriaField;
  operator: CriteriaOperator;
  value: CriteriaValue;
};

export type CriteriaGroup = {
  groupId: string;
  groupCondition?: CriteriaCondition;
  groupRules: CriteriaRule[];
};

export type CriteriaGroups = {
  groupsCondition: CriteriaCondition;
  groups: CriteriaGroup[];
};

export type CriteriaPayloadRule = {
  field: CriteriaField;
  operator: CriteriaOperator;
  value: string[];
};
