import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import shallow from 'zustand/shallow';

import {
  THERES_NO_REWARDS,
  ON_ANY_PAID_PLAN_YOU_ARE_ABLE,
  TAKE_ME_TO_TURN_ON_CHARITIES,
  CHARITY_POLICY_TITLE,
  CHARITY_POLICY_DESCRIPTION,
  CHARITIES_PER_PAGE,
  COULD_NOT_FIND_ANY_REWARDS,
  TRY_DIFFERENT_SEARCH,
  TAKE_ME_TO_TURN_ON_PAID_ALLOWANCE,
  CHARITY_SETUP_TEXT,
  PREVIOUS_PAGE,
  NEXT_PAGE,
} from '../../../languages/en/rewards';
import {
  REWARDS_PER_PAGE_VALUES,
  REWARDS_PER_PAGE_DEFAULT,
  SortByQueryParams,
} from '../../../Utils/data/rewards';
import {
  canDisableRewardCardActionButton,
  getTrophyRange,
} from '../../../Utils/rewards';
import { useProfileInfoFetchQuery } from '../../../queries/Profile';
import useCharityControllerModel from './useCharityControllerModel';
import useLayoutStore from '../../../stores/layoutStore';
import {
  StyledRewardsWrapper,
  StyledRewardsLoaderWrapper,
  RewardsFooter,
} from '../../../atomic/organism/RouteTabs/styles';
import RewardsEmptyState from '../../../atomic/molecules/RewardsEmptyState';
import RewardsHeader from '../../../atomic/molecules/RewardsHeader';
import RewardCard from '../../../atomic/molecules/RewardCard';
import RewardCardLoader from '../../../atomic/molecules/RewardCard/Loader';
import {
  RewardTypes,
  BackgroundSizeTypes,
} from '../../../atomic/molecules/RewardCard/interface';
import useCharityControllerLogic from './useCharityControllerLogic';
import RedeemModalController from '../RedeemModalController';
import { CharityReward } from '../../../queries/Rewards/interfaces';
import PaginationComponent from '../../../atomic/molecules/PaginationComponent';
import { ADMIN_CHARITIES, V2_HOME } from '../../../constants/routes';
import rewardsNotFoundImage from '../../../img/searching.svg';
import routesList from '../../../aV2/routes/routesList';
import { Flex } from '../../../Utils/styles/display';
import usePageNavigationLogic from '../usePageNavigationLogic';
import {
  StyledNavigateNextButton,
  StyledNavigatePreviousButton,
} from '../styles';

export interface CharityControllerProp {
  isAdmin: boolean;
  isCharitiesEnabled?: boolean;
}

const CharityController = (props: CharityControllerProp) => {
  const history = useHistory();
  const { isAdmin, isCharitiesEnabled } = props;
  const [sortParams, setSortParams] = useState({
    sort: SortByQueryParams.FAVORITES,
  });
  const [itemsPerPage, setItemsPerPage] = useState(REWARDS_PER_PAGE_DEFAULT);
  const [pageNumber, setPageNumber] = useState(1);

  const { isLoading, isError, refetch, rewardsData, exchangeRates } =
    useCharityControllerModel(sortParams);
  const { data: profileData } = useProfileInfoFetchQuery();

  const { isLeftAsideOpen, isRightAsideOpen } = useLayoutStore(
    (state) => ({
      isLeftAsideOpen: state.isLeftAsideOpen,
      isRightAsideOpen: state.isRightAsideOpen,
    }),
    shallow,
  );

  const onSortChange = useCallback((value = SortByQueryParams.FAVORITES) => {
    setSortParams({ sort: value });
  }, []);

  useEffect(() => {
    refetch();
  }, [refetch, sortParams]);

  const onItemsPerPageValueChange = useCallback((value) => {
    setItemsPerPage(value);
  }, []);
  const onHandlePaginationChange = useCallback((num) => {
    setPageNumber(num);
  }, []);

  const resetCurrentPage = useCallback(() => {
    setPageNumber(1);
  }, []);

  const {
    searchProps,
    sortProps,
    rewardsToDisplay,
    handleOnRewardSelect,
    isModalOpen,
    selectedReward,
    onCloseRedeemModal,
  } = useCharityControllerLogic({
    rewards: (rewardsData && rewardsData.rewards) || [],
    onSortChange,
    resetCurrentPage,
  });

  const rewardsPageNavigationProps = {
    pageNumber,
    itemCount: rewardsToDisplay.length,
    itemsPerPage,
    onHandlePaginationChange,
  };
  const { models, operations } = usePageNavigationLogic(
    rewardsPageNavigationProps,
  );
  const { numberOfPages } = models;
  const { onPreviousButtonClick, onNextButtonClick } = operations;

  const currentRewards = useMemo(() => {
    if (rewardsToDisplay.length > 0) {
      return rewardsToDisplay.slice(
        (pageNumber - 1) * itemsPerPage,
        (pageNumber - 1) * itemsPerPage + itemsPerPage,
      );
    }

    return [];
  }, [itemsPerPage, pageNumber, rewardsToDisplay]);

  const hasCharitiesSetup = useMemo(() => {
    if (rewardsData && rewardsData.rewards) {
      return rewardsData.rewards.length > 0;
    }

    return false;
  }, [rewardsData]);

  if (isError) return <div>Error...</div>;

  // Redirect to home
  if (!isAdmin && !isCharitiesEnabled) {
    history.push(V2_HOME);
  }

  // Show upgrade link
  if (isAdmin && !isCharitiesEnabled) {
    return (
      <RewardsEmptyState
        title={THERES_NO_REWARDS}
        message={ON_ANY_PAID_PLAN_YOU_ARE_ABLE}
        hasUpgradeLink
        hasActionButton={false}
      />
    );
  }

  // show !isChargeable state
  if (isAdmin && profileData && !isLoading) {
    const { isChargeable } = profileData.assembly.accounts.rewards;
    if (!isChargeable) {
      return (
        <RewardsEmptyState
          title={THERES_NO_REWARDS}
          message={ON_ANY_PAID_PLAN_YOU_ARE_ABLE}
          actionButtonText={TAKE_ME_TO_TURN_ON_PAID_ALLOWANCE}
          actionButtonLink={routesList.ADMIN_BILLING_ACCOUNT}
        />
      );
    }
  }

  // Show setup link
  if (isAdmin && isCharitiesEnabled && !hasCharitiesSetup && !isLoading) {
    return (
      <RewardsEmptyState
        title={THERES_NO_REWARDS}
        message={CHARITY_SETUP_TEXT}
        actionButtonText={TAKE_ME_TO_TURN_ON_CHARITIES}
        actionButtonLink={ADMIN_CHARITIES}
      />
    );
  }

  return (
    <>
      <RewardsHeader search={searchProps} sort={sortProps} />
      {isLoading && (
        <StyledRewardsLoaderWrapper>
          <RewardCardLoader rewardType={RewardTypes.Charity} />
        </StyledRewardsLoaderWrapper>
      )}
      {!isLoading &&
        isCharitiesEnabled &&
        rewardsData &&
        profileData &&
        exchangeRates && (
          <>
            {currentRewards.length === 0 && (
              <RewardsEmptyState
                title={COULD_NOT_FIND_ANY_REWARDS}
                message={TRY_DIFFERENT_SEARCH}
                hasActionButton={false}
                image={rewardsNotFoundImage}
              />
            )}
            <StyledRewardsWrapper
              isLeftAsideOpen={isLeftAsideOpen}
              isRightAsideOpen={isRightAsideOpen}
            >
              {currentRewards.map((reward: CharityReward) => {
                const trophyRange = getTrophyRange(
                  reward.denominations,
                  exchangeRates,
                  rewardsData.exchangeRate,
                );
                return (
                  <RewardCard
                    id={reward.brandKey}
                    key={reward.brandKey}
                    backgroundSize={BackgroundSizeTypes.Cover}
                    rewardType={RewardTypes.Charity}
                    image={reward.imageUrls['278w-326ppi']}
                    title={reward.brandName}
                    assemblyCurrency={profileData.assembly.currency}
                    description={reward.description}
                    onRewardSelect={handleOnRewardSelect}
                    disabled={canDisableRewardCardActionButton(
                      trophyRange.range,
                      profileData.member.pointsEarned,
                    )}
                    trophyRange={trophyRange}
                    rewardsCurrentAvailability={reward.maxLimit}
                  />
                );
              })}
            </StyledRewardsWrapper>

            {numberOfPages > 1 && (
              <Flex justifyContent="center">
                <StyledNavigatePreviousButton
                  onClick={onPreviousButtonClick}
                  disabled={pageNumber === 1}
                  icon="arrow-left"
                >
                  {PREVIOUS_PAGE}
                </StyledNavigatePreviousButton>
                <StyledNavigateNextButton
                  onClick={onNextButtonClick}
                  disabled={pageNumber === numberOfPages}
                  icon="arrow-right"
                  isEndIcon
                >
                  {NEXT_PAGE}
                </StyledNavigateNextButton>
              </Flex>
            )}

            {currentRewards.length > 0 && (
              <RewardsFooter
                isLeftAsideOpen={isLeftAsideOpen}
                isRightAsideOpen={isRightAsideOpen}
              >
                <PaginationComponent
                  dropdownLabelText={CHARITIES_PER_PAGE}
                  itemCount={rewardsToDisplay.length}
                  itemsPerPage={itemsPerPage}
                  itemsPerPageValues={REWARDS_PER_PAGE_VALUES}
                  onItemsPerPageValueChange={onItemsPerPageValueChange}
                  onHandlePaginationChange={onHandlePaginationChange}
                  pageNumber={pageNumber}
                  hasRightSpacing
                />
              </RewardsFooter>
            )}
            {isModalOpen && selectedReward && (
              <RedeemModalController
                policyTitle={CHARITY_POLICY_TITLE}
                policyDescription={CHARITY_POLICY_DESCRIPTION}
                isModalOpen={isModalOpen}
                reward={selectedReward}
                exchangeRates={exchangeRates}
                exchangeRate={rewardsData.exchangeRate}
                profileEmail={profileData.member.email}
                pointsEarned={profileData.member.pointsEarned}
                assemblyCurrency={profileData.assembly.currency}
                onCloseRedeemModal={onCloseRedeemModal}
              />
            )}
          </>
        )}
    </>
  );
};

export default CharityController;
