import Pusher from 'pusher-js';
import { API_URL_WITH_V3, PUSHER } from '../config';
import Ajax from 'pusher-js/types/src/core/http/ajax';

// Enable pusher logging - don't include this in production
// Pusher.logToConsole = true;

Pusher.Runtime.createXHR = () => {
  const xhr = new XMLHttpRequest();
  xhr.withCredentials = true;
  return xhr as Ajax;
};

export const pusher = new Pusher(PUSHER.KEY, {
  cluster: 'mt1',
  forceTLS: true,
  authEndpoint: `${API_URL_WITH_V3}/users/pusher/auth`,
});
