export enum QuickSetupStep {
  COMPLETION = 'COMPLETION',
  SURVEY_TEAM = 'SURVEY_TEAM',
  MEETINGS_LIST = 'MEETINGS_LIST',
  POST_COMPLETED = 'POST_COMPLETED',
  SELECT_TEMPLATE = 'SELECT_TEMPLATE',
  ADD_PARTICIPANTS = 'ADD_PARTICIPANTS',
  CALENDAR_SELECTION = 'CALENDAR_SELECTION',
  QUICK_SETUP_OPTION_SELECTION = 'QUICK_SETUP_OPTION_SELECTION',
}

export type QuickSetupChildrenGenericProps = {
  onQuickSetupStepChange: (step: QuickSetupStep) => void;
};
