import React from 'react';

import ContentLoader from 'react-content-loader';
import ThemeV2 from '../../../../componentsV2/theme';

export const NotebookCountLoader = () => (
  <ContentLoader
    speed={2}
    width="32px"
    height="24px"
    backgroundColor={ThemeV2.palette.gray4}
    foregroundColor={ThemeV2.palette.gray3}
  >
    <rect x="8px" y="0" rx="4px" ry="4px" width="20px" height="24px" />
  </ContentLoader>
);

export const NotebookTaskLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width="100%"
      height="62px"
      backgroundColor={ThemeV2.palette.gray4}
      foregroundColor={ThemeV2.palette.gray3}
    >
      <rect x="38px" y="8px" rx="4px" ry="4px" width="16px" height="16px" />
      <rect
        x="68px"
        y="0px"
        rx="4px"
        ry="4px"
        width="calc(100% - 140px)"
        height="32px"
      />
      <rect x="68px" y="40px" rx="4px" ry="4px" width="143px" height="12px" />
    </ContentLoader>
  );
};

export const NotebookRightDrawerTaskLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width="100%"
      height="60px"
      backgroundColor={ThemeV2.palette.gray4}
      foregroundColor={ThemeV2.palette.gray3}
    >
      <rect x="0px" y="0px" rx="4px" ry="4px" width="100%" height="12px" />
      <rect x="0px" y="20px" rx="4px" ry="4px" width="100%" height="12px" />
      <rect x="0px" y="40px" rx="4px" ry="4px" width="50%" height="12px" />
    </ContentLoader>
  );
};

export const NotebookRightDrawerDateLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width="100%"
      height="32px"
      backgroundColor={ThemeV2.palette.gray4}
      foregroundColor={ThemeV2.palette.gray3}
    >
      <rect x="0px" y="0px" rx="4px" ry="4px" width="50%" height="32px" />
    </ContentLoader>
  );
};

export const NotebookRightDrawerAssigneeLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width="100%"
      height="32px"
      backgroundColor={ThemeV2.palette.gray4}
      foregroundColor={ThemeV2.palette.gray3}
    >
      <rect x="0px" y="0px" rx="4px" ry="4px" width="70%" height="32px" />
    </ContentLoader>
  );
};

export const NotebookRightDrawerTaskHistoryLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width="100%"
      height="80px"
      backgroundColor={ThemeV2.palette.gray4}
      foregroundColor={ThemeV2.palette.gray3}
    >
      <rect x="0px" y="0px" rx="4px" ry="4px" width="50%" height="12px" />
      <rect x="0px" y="20px" rx="8px" ry="8px" width="16px" height="16px" />
      <rect
        x="28px"
        y="20px"
        rx="4px"
        ry="4px"
        width="calc(100% - 16px)"
        height="16px"
      />
      <rect x="28px" y="44px" rx="4px" ry="4px" width="100%" height="12px" />
      <rect x="28px" y="64px" rx="4px" ry="4px" width="50%" height="12px" />
    </ContentLoader>
  );
};

export const NotebookAssigneeLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width="100%"
      height="40px"
      backgroundColor={ThemeV2.palette.gray4}
      foregroundColor={ThemeV2.palette.gray3}
    >
      <rect x="8px" y="8px" rx="24px" ry="24px" width="24px" height="24px" />
      <rect
        x="40px"
        y="8px"
        rx="4px"
        ry="4px"
        width="calc(100% - 88px)"
        height="24px"
      />
    </ContentLoader>
  );
};
