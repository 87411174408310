import React from 'react';
import { Collapse } from '@material-ui/core';

import { StyledAccordionHeader, Wrapper } from './styles';

interface AccordionProps {
  accordionHeader: React.ReactNode;
  children: React.ReactNode | null;
  isAccordionOpen: boolean;
  onAccordionClick: () => void;
  className?: string;
  disabled?: boolean;
}

const Accordion = (props: AccordionProps) => {
  const {
    accordionHeader,
    children,
    isAccordionOpen,
    onAccordionClick,
    className,
    disabled,
  } = props;
  return (
    <Wrapper className={className} disabled={disabled}>
      <StyledAccordionHeader
        onClick={onAccordionClick}
        data-testid="accordionHeader"
      >
        {accordionHeader}
      </StyledAccordionHeader>
      <Collapse
        in={isAccordionOpen}
        aria-expanded={isAccordionOpen}
        data-testid="accordionBody"
      >
        {children}
      </Collapse>
    </Wrapper>
  );
};

export default Accordion;
