import React, { useMemo } from 'react';
import useDropDown from '../../../hooks/useDropDown';
import Button from '../../atoms/Button';
import DropdownListHolder, {
  DropdownWrapper,
  DropdownProps,
} from '../DropdownListHolder';
import { ButtonDropdownProps, DropdownPlacement } from './interfaces';

const Dropdown = (props: ButtonDropdownProps & DropdownProps) => {
  const {
    value,
    color,
    size,
    variant,
    icon,
    className,
    onItemClick,
    getItemLabel,
    dropdownPlacement = DropdownPlacement.Bottom,
    isDropdownPositionFixed,
    onFocus,
    caretIconSize,
    hasBoxShadow = false,
  } = props;

  const { anchorProps } = useDropDown(props);
  const {
    id,
    referenceElement,
    isOpen,
    toggleDropdown,
    isFullWidth,
    disabled,
    anchorEl,
  } = anchorProps;

  const renderValue = useMemo(() => {
    return getItemLabel && value ? getItemLabel(value) : value;
  }, [getItemLabel, value]);

  return (
    <DropdownWrapper
      ref={referenceElement}
      isFullWidth={isFullWidth}
      className={className}
      onFocus={onFocus}
    >
      <Button
        variant={variant}
        disabled={disabled}
        isFullWidth={isFullWidth}
        color={color}
        size={size}
        icon={icon}
        onClick={toggleDropdown}
        enableCaret
        caretOpen={!disabled && isOpen}
        aria-describedby={id}
        ref={anchorEl}
        dataTestId="dropdown"
        caretIconSize={caretIconSize}
        hasBoxShadow={hasBoxShadow}
      >
        {renderValue}
      </Button>
      <DropdownListHolder
        {...anchorProps}
        dropdownPlacement={dropdownPlacement}
        onItemClick={onItemClick}
        getItemLabel={getItemLabel}
        isDropdownPositionFixed={isDropdownPositionFixed}
      />
    </DropdownWrapper>
  );
};

Dropdown.defaultProps = {
  disabled: false,
  isFullWidth: false,
  color: 'primary',
  size: 'medium',
  variant: 'contained',
  icon: '',
  selectedMenuItemID: null,
  menuItems: [],
  onItemClick: () => {},
  multiSelect: false,
  className: '',
};

const MemoizedDropdown = React.memo(Dropdown);
MemoizedDropdown.displayName = 'Dropdown';
export default MemoizedDropdown;
