import { GeneralCollapsibleNavigationItemProps } from '../../../../atomic/organism/GeneralCollapsibleNavigation/types';
import {
  V2_HOME,
  NOTEBOOK,
  V2_REWARDS,
  QUICK_SETUP,
  KNOWLEDGE_CENTER,
} from '../../../../constants/routes';

export const GeneralListItems: GeneralCollapsibleNavigationItemProps[] = [
  {
    id: V2_HOME,
    title: 'Main Feed',
    icon: 'feed',
    isActive: false,
    isEnabled: true,
  },
  {
    id: V2_REWARDS,
    title: 'Rewards',
    icon: 'gift',
    isActive: false,
    isEnabled: true,
  },
  {
    id: NOTEBOOK,
    title: 'Notebook',
    icon: 'note',
    isActive: false,
    isEnabled: true,
  },
];

export const GeneralListWithKnowledgeCenter: GeneralCollapsibleNavigationItemProps[] =
  [
    {
      id: V2_HOME,
      title: 'Main Feed',
      icon: 'feed',
      isActive: false,
      isEnabled: true,
    },
    {
      id: V2_REWARDS,
      title: 'Rewards',
      icon: 'gift',
      isActive: false,
      isEnabled: true,
    },
    {
      id: NOTEBOOK,
      title: 'Notebook',
      icon: 'note',
      isActive: false,
      isEnabled: true,
    },
    {
      id: KNOWLEDGE_CENTER,
      title: 'Knowledge Center',
      icon: 'knowledge',
      isActive: false,
      isEnabled: true,
    },
  ];

export const SetupListItems: GeneralCollapsibleNavigationItemProps[] = [
  {
    id: QUICK_SETUP,
    title: 'Quick Setup',
    icon: 'feed',
    isActive: true,
    isEnabled: true,
  },
];
