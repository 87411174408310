import styled from 'styled-components';
import ThemeV2 from '../../../../componentsV2/theme';
import Body from '../../../atoms/Body';
import Popover from '../../Popover';
import { PopoverElement } from '../../Popover/styles';

export const Wrapper = styled.div<{ isChecked: boolean; isLoading?: boolean }>`
  display: grid;
  width: 100%;
  margin-bottom: 12px;
  box-sizing: border-box;
  border-radius: 4px;
  border: ${({ isChecked }) =>
    `1px solid ${
      isChecked ? ThemeV2.palette.geekBlue6 : ThemeV2.palette.gray4
    }`};
  background: ${({ isChecked }) =>
    isChecked ? ThemeV2.palette.geekBlue1 : ThemeV2.palette.gray1};
  cursor: pointer;
  padding: 16px;
  transition: 0.2s ease;
  transition-property: border, color, background;

  ${({ isChecked, isLoading }) =>
    !isLoading &&
    isChecked &&
    `
    * {
      color: ${ThemeV2.palette.geekBlue6};
    }
    svg path {
      fill: ${ThemeV2.palette.geekBlue6};
    }
    ${PopoverElement} {
      * {
        color: ${ThemeV2.palette.gray1};
      }
    }
  `}

  ${({ isLoading }) =>
    !isLoading &&
    `
    grid-template-columns: auto 30px;
    grid-template-rows: auto;
    &:hover {
      border-color: ${ThemeV2.palette.geekBlue6};
      background: ${ThemeV2.palette.geekBlue1};
      & * {
        color: ${ThemeV2.palette.geekBlue6};
      }
      ${PopoverElement} {
        * {
          color: ${ThemeV2.palette.gray1};
        }
      }
      svg path {
        fill: ${ThemeV2.palette.geekBlue6};
      }
    }
  `}

  ${({ isLoading }) =>
    isLoading &&
    `
    cursor: default;
    padding:0px;
    .contentLoader{
      width:100%;
      max-width: 100%;
      height:78px;
    }
  `};
`;

export const CardTitle = styled(Body)`
  margin-right: 4px;
`;

export const HelperText = styled(Body)`
  position: relative;
  padding-right: 9px;
  &:after {
    content: '';
    position: absolute;
    top: 9px;
    right: 4px;
    height: 2px;
    width: 2px;
    background: ${ThemeV2.palette.gray9};
    border-radius: 20px;
  }
`;

export const ParticipantsCountText = styled(Body)`
  margin-left: 4px;
`;

export const StyledPopover = styled(Popover)`
  ${PopoverElement} {
    max-width: 150px;
    background: ${ThemeV2.palette.gray9};
    &:hover {
      color: ${ThemeV2.palette.gray1};
    }
  }
`;
