export const WORKING_ON_SUPPORTING =
  'We’re working on supporting all file types. In the meantime, download this file.';

export const UNABLE_TO_PREVIEW = 'Unable to preview file';

export const PREVIEW_NOT_AVAILABLE = 'Preview not available';

export const FOR = 'for';

export const FILES = 'files';
