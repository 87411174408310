export enum FlowAccordionItemValidations {
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
  None = 'none',
}

export enum FlowAccordionItemState {
  Focus = 'focus',
  Blur = 'blur',
  Disabled = 'disabled',
}

export interface FlowAccordionItemProps {
  isOpen?: boolean;
  icon: string;
  title: string;
  subtitle?: string;
  titleContent?: React.ReactNode;
  validation?: FlowAccordionItemValidations;
  state?: FlowAccordionItemState;
  onAccordionItemClick: () => void;
  dataTestId?: string;
  children: React.ReactNode;
}
