import React, { ReactNode } from 'react';
import BlockScale from '../../BlockScale';

import FlowsBaseInputBlock from '../FlowsBaseInputBlock';
import NavigationInstructions from '../../FlowsInputBlockNavigationInstructions';
import { ScaleLabels } from '../../../../interfaces/Flow';

export interface FlowsScaleInputBlockProps {
  fieldError?: string;
  goToNextStep: () => void;
  blockError?: string;
  isRequired?: boolean;
  isLastBlock?: boolean;
  title: string;
  info?: ReactNode;
  minValue: number;
  maxValue: number;
  labels?: ScaleLabels;
  value: number | null;
  onChange: (value: number | null) => void;
  description?: string;
  isPreviewFlow?: boolean;
}

const FlowsScaleInputBlock = ({
  goToNextStep,
  isRequired,
  fieldError,
  blockError,
  title,
  info,
  minValue,
  maxValue,
  labels,
  value,
  onChange,
  description,
  isLastBlock = false,
  isPreviewFlow,
}: FlowsScaleInputBlockProps) => (
  <FlowsBaseInputBlock
    fieldError={fieldError}
    blockError={blockError}
    hasError={!!fieldError}
    isRequired={isRequired}
    title={title}
    info={info}
    description={description}
    navigationInstructions={
      <NavigationInstructions
        type={isLastBlock ? 'last+enter' : 'enter'}
        goToNextStep={goToNextStep}
        isPreviewFlow={isPreviewFlow}
      />
    }
  >
    <BlockScale
      type="normal"
      min={minValue}
      max={maxValue}
      labels={labels}
      value={value}
      onChange={onChange}
    />
  </FlowsBaseInputBlock>
);

export default FlowsScaleInputBlock;
