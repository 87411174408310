import React, { useMemo } from 'react';

// eslint-disable-next-line max-len
import ClickableSelectionOptionItem from '../../../../molecules/QuickSetup/ClickableSelectionOptionItem';
// eslint-disable-next-line max-len
import CalendarSelectDropdownBarWithRefresh from '../../../../molecules/QuickSetup/CalendarSelectDropdownBarWithRefresh';

import MeetingType from './MeetingType';
import { LoadedComponentProps } from '../type';
import { StyledBody, StyledNonRecurringTitle } from '../styles';
import { quickSetupMeetingListScreen } from '../../../../../languages/en/quickSetup';

const LoadedComponent = (props: LoadedComponentProps) => {
  const {
    value,
    isLoading,
    menuItems,
    meetingType,
    onItemClick,
    selectedValue,
    dropdownMenuItems,
    onRefreshListClick,
    onDropdownItemClick,
    onMeetingItemClick,
    recurringMeetingItems,
    nonRecurringMeetingItems,
    dropdownPlaceholderImage,
  } = props;

  const renderMeetingItems = useMemo(() => {
    if (meetingType === 'Non-recurring meetings') {
      return nonRecurringMeetingItems?.map((item) => {
        return (
          <div key={item.title} style={{ width: '100%' }}>
            <StyledNonRecurringTitle variant="body1" color="gray9">
              {item.title}
            </StyledNonRecurringTitle>
            {item.items.length > 0 ? (
              item.items.map((meetingItem) => (
                <ClickableSelectionOptionItem
                  key={meetingItem.id}
                  title={meetingItem.title}
                  status={meetingItem.status}
                  isStared={meetingItem.isStarted}
                  isChecked={meetingItem.isChecked}
                  helperText={meetingItem.subTitle}
                  participantsCount={meetingItem.participants}
                  onClick={() => {
                    if (onMeetingItemClick)
                      onMeetingItemClick(
                        meetingItem.id,
                        meetingItem.title,
                        meetingItem.recurrence,
                      );
                  }}
                />
              ))
            ) : (
              <StyledBody variant="body2" color="gray8">
                {
                  quickSetupMeetingListScreen.loadedComponent
                    .noNonRecurringItemsText
                }
              </StyledBody>
            )}
          </div>
        );
      });
    }
    return recurringMeetingItems?.map((item) => (
      <ClickableSelectionOptionItem
        key={item.id}
        title={item.title}
        status={item.status}
        isStared={item.isStarted}
        isChecked={item.isChecked}
        helperText={item.subTitle}
        participantsCount={item.participants}
        onClick={() => {
          if (onMeetingItemClick)
            onMeetingItemClick(item.id, item.title, item.recurrence);
        }}
      />
    ));
  }, [
    meetingType,
    onMeetingItemClick,
    recurringMeetingItems,
    nonRecurringMeetingItems,
  ]);

  return (
    <>
      <CalendarSelectDropdownBarWithRefresh
        isLoading={isLoading}
        selectedValue={selectedValue}
        dropdownMenuItems={dropdownMenuItems}
        onRefreshListClick={onRefreshListClick}
        onDropdownItemClick={onDropdownItemClick}
        dropdownPlaceholderImage={dropdownPlaceholderImage}
      />
      <MeetingType
        value={value}
        menuItems={menuItems}
        meetingType={meetingType}
        onItemClick={onItemClick}
      />
      {renderMeetingItems}
    </>
  );
};

export default LoadedComponent;
