import { useEffect, useState } from 'react';

const useWindowFocus = () => {
  const [isWindowFocused, setWindowFocused] = useState(true);
  // User has switched back to the tab
  const onFocus = () => setWindowFocused(true);
  // User has switched away from the tab
  const onBlur = () => setWindowFocused(false);
  useEffect(() => {
    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);

    return () => {
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
    };
  });
  return {
    isWindowFocused,
  };
};

export default useWindowFocus;
