import { useCallback } from 'react';
import { CreditState } from '../../../interfaces/assembly';
import { MemberInteractionType } from '../../../interfaces/Feed';
import { LayoutStoreState } from '../../../interfaces/Layout';
import {
  useProfileInfoFetchQuery,
  useRedeemInfoFetchQuery,
} from '../../../queries/Profile';
import useLayoutStore from '../../../stores/layoutStore';
import { isUserAdmin } from '../../../Utils/user';

const layoutSelector = (state: LayoutStoreState) =>
  state.setMemberAndToggleRightAsideOpen;

const usePrimaryHeaderController = () => {
  const { data: profileData } = useProfileInfoFetchQuery();
  const { data: redeemInfoData } = useRedeemInfoFetchQuery();

  const setMemberAndToggleRightAsideOpen = useLayoutStore(layoutSelector);

  const onViewProfileClick = useCallback(
    (person: MemberInteractionType) => {
      setMemberAndToggleRightAsideOpen({
        memberId: person.memberID,
        firstName: person.firstName,
      });
    },
    [setMemberAndToggleRightAsideOpen],
  );

  const isRedeemable = redeemInfoData ? redeemInfoData.isRedeemable : false;

  const hideRewardsLink = () => {
    if (
      profileData?.assembly.accounts.creditState === CreditState.DENIED_BY_ADMIN
    ) {
      return true;
    }
    if (
      !profileData?.member ||
      (!isRedeemable && !isUserAdmin(profileData.member))
    ) {
      return true;
    }
    if (isUserAdmin(profileData.member)) {
      return false;
    }
    return !isRedeemable;
  };

  const isCurrentUserAdmin = profileData?.member
    ? isUserAdmin(profileData?.member)
    : false;

  return {
    currentUserData: profileData?.member,
    hideRewardsLink: hideRewardsLink(),
    isCurrentUserAdmin,
    onViewProfileClick,
  };
};

export default usePrimaryHeaderController;
