import { useEffect, useState } from 'react';

import { ErrorState, ScriptProps } from './type';

const useScript = (props: ScriptProps) => {
  const { src, async, defer, enabled = true, ...attributes } = props;

  const [loading, setLoading] = useState(Boolean(src));
  const [error, setError] = useState<ErrorState>(null);

  const isBrowser =
    typeof window !== 'undefined' && typeof window.document !== 'undefined';

  useEffect(() => {
    if (!isBrowser || !src || !enabled) {
      setLoading(false);
      setError(null);
      return;
    }

    const scriptEl = document.createElement('script');
    scriptEl.setAttribute('src', src);
    scriptEl.setAttribute('data-status', 'loading');

    Object.keys(attributes).forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (scriptEl[key] === undefined) {
        scriptEl.setAttribute(key, attributes[key]);
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        scriptEl[key] = attributes[key];
      }
    });

    const handleLoad = () => {
      scriptEl.setAttribute('data-status', 'ready');
      setLoading(false);
      setError(null);
    };

    const handleError = (errorEvent: ErrorEvent) => {
      scriptEl.setAttribute('data-status', 'error');
      setLoading(false);
      setError(errorEvent);
    };

    scriptEl.addEventListener('load', handleLoad);
    scriptEl.addEventListener('error', handleError);

    document.body.appendChild(scriptEl);

    return () => {
      scriptEl.removeEventListener('load', handleLoad);
      scriptEl.removeEventListener('error', handleError);
    };
    // we need to ignore the attributes as they're a new object per call, so we'd never skip an effect call
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src, enabled]);

  return { loading, error };
};

export default useScript;
