import React, { useEffect } from 'react';
import shallow from 'zustand/shallow';

import Body from '../../../../atomic/atoms/Body';
import { Flex } from '../../../../Utils/styles/display';
import { StyledImage } from '../../../SinglePostController';
import PostNotFoundImage from '../../../../img/searching.svg';
import FlowsFeedPostLoader from '../../../flows/FlowsFeedController/Loader';
import {
  COULDNT_FIND_POST,
  COULDNT_FIND_POST_COMMENT,
} from '../../../../languages/en/header';

import useNewSingleFlowFeedPostController from './useNewSingleFlowFeedPostController';
import useLayoutStore from '../../../../stores/layoutStore';
import FlowPostController from '../../../flows/FlowPostController';
import useSinglePostSocket, {
  WebSocketSinglePostTypes,
} from '../../../../hooks/useSinglePostSocket';

const NewSingleFlowFeedPostController = () => {
  const {
    isError,
    isLoading,
    flowFeedPostProps,
    onMemberClick,
    onReactionSet,
    onReactionUnSet,
    profileData,
    onPostDelete,
  } = useNewSingleFlowFeedPostController();

  const { isRightAsideOpen, toggleRightAsideOpen, setRightAsideOpenToTrue } =
    useLayoutStore(
      (state) => ({
        isRightAsideOpen: state.isRightAsideOpen,
        toggleRightAsideOpen: state.toggleRightAsideOpen,
        setRightAsideOpenToTrue: state.setRightAsideOpenToTrue,
      }),
      shallow,
    );

  useSinglePostSocket({
    type: WebSocketSinglePostTypes.NEW_FLOW_POST,
    flowId: flowFeedPostProps?.flowId,
  });

  useEffect(() => {
    return () => {
      setRightAsideOpenToTrue();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isError) {
    if (isRightAsideOpen) {
      toggleRightAsideOpen();
    }
    return (
      <Flex flexDirection="column" alignItems="center" margin="32px 0 0">
        <StyledImage alt={COULDNT_FIND_POST} src={PostNotFoundImage} />
        <Body variant="subHead2Bold">{COULDNT_FIND_POST}</Body>
        <Body variant="body1">{COULDNT_FIND_POST_COMMENT}</Body>
      </Flex>
    );
  }

  if (isLoading) {
    return <FlowsFeedPostLoader />;
  }

  if (flowFeedPostProps)
    return (
      <FlowPostController
        post={flowFeedPostProps}
        onReactionSet={onReactionSet}
        onReactionUnSet={onReactionUnSet}
        onMemberClick={onMemberClick}
        profileData={profileData}
        onPostDelete={onPostDelete}
      />
    );

  return null;
};

export default NewSingleFlowFeedPostController;
