import React, { useEffect, useMemo } from 'react';

import IMStatus from '../../Utils/identity/status';
import { getIMLanguages } from '../../Utils/data/identityManager/IMWorkato';
import {
  buttonText,
  identityManagerSteps,
  modalText,
} from '../../Utils/data/identityManager/common';
import IdentitySSOIntro from '../../atomic/pages/IdentitySSOIntro';
import { IdentityManagerContainerProps } from '../../interfaces/identityManager/common';
import IdentitySelectPeoplePageContainer from '../IdentitySelectPeoplePageContainer';
import IdentityConfigureInvitesPageContainer from '../IdentityConfigureInvitesPageContainer';
import IdentityCreateAccountsPageContainer from '../IdentityCreateAccountsPageContainer';
import Modal from '../../atomic/organism/Modal';
import SimpleModal from '../../atomic/atoms/Modal';
import useWorkatoIdentityManager, {
  WorkatoEventMessageData,
} from '../../hooks/useWorkatoIdentityManager';
import GifLoadingModal from '../../atomic/molecules/GifLoadingModal';

export const WORKATO_ORIGIN = 'https://app.workato.com';

const EMPTY_FUNCTION = () => {};
interface WorkatoIFrameProps {
  src: string;
  onEvent: (event: WorkatoEventMessageData) => void;
}

export interface WorkatoEventMessage {
  origin: string;
  data: string;
}

const WorkatoIFrame = (props: WorkatoIFrameProps) => {
  const { src, onEvent } = props;
  useEffect(() => {
    const fn = (message: WorkatoEventMessage) => {
      try {
        // Adding a check on origin, to avoid cross site scripting attacks
        if (message.origin === WORKATO_ORIGIN) {
          if (typeof message.data === 'string') {
            // At this point we are not sure that eventData.type exists
            // That's why Partial<WorkatoEventMessageData>
            const eventData = JSON.parse(
              message.data,
            ) as Partial<WorkatoEventMessageData>;
            if (eventData.type && eventData.type === 'connectionStatusChange') {
              onEvent(eventData as WorkatoEventMessageData);
            }
          }
        }
      } catch (e) {
        console.log(e, message);
      }
    };
    window.addEventListener('message', fn);
    return () => {
      window.removeEventListener('message', fn);
    };
  }, [onEvent]);
  return (
    <div style={{ padding: '32px' }}>
      <iframe
        title="workato"
        width="768px"
        height="400px"
        frameBorder={0}
        src={src}
      />
    </div>
  );
};

const WorkatoIMContainer = ({
  identityManager,
  IMsMetadata,
}: IdentityManagerContainerProps) => {
  const {
    models: {
      status,
      displayName: identityManagerDisplayName,
      activeStep,
      result,
      cancelModal: cancelModalProps,
      notifications,
      showApprovalRadioGroup,
      integrationData,
      configureJobId,
      canShowIMSetupLoading,
      workatoEmbedUrl,
      helperLink,
    },
    operations: {
      handleCancelClick,
      handleViewPeopleClick,
      handleViewQueuedPeopleClick,
      handleSuccess,
      handleError,
      handleConfigInvitesBackClick,
      handleConfigureAccountsSuccess,
      handleSelectPeopleDone,
      getIMSetupJob,
      startPollingIntegrationData,
      handleWorkatoEvent,
      resetIMSetupJob,
    },
  } = useWorkatoIdentityManager({
    identityManager,
    IMsMetadata,
    canGetIMConfigOnLoad: false,
  });
  const {
    stepsWizardTitle,
    steps,
    introInfoItems,
    introHeading,
    introSecondHeading,
    recommendation,
    userSelectHeading,
    userSelectSubHeading,
    userSelectRadioGroupOptions,
    invitesHeading,
    invitesSubHeading,
    invitesRadioGroupOptions,
    approvalHeading,
    approvalSubHeading,
    approvalRadioGroupOptions,
    createAccountsHeading,
    createAccountsSubHeading,
    createAccountsSubHeadingQueued,
    gifLoadingModal,
  } = useMemo(
    () => getIMLanguages(identityManagerDisplayName),
    [identityManagerDisplayName],
  );

  const stepConnectButton = {
    hidden: false,
    disabled: false,
    text: buttonText.STEP_SETUP_UP_INFO_NEXT.replace(
      ':IMDisplayName',
      identityManagerDisplayName,
    ),
    onClick: getIMSetupJob,
  };

  const stepCancelButton = {
    hidden: false,
    disabled: false,
    text: buttonText.STEP_WHAT_IS_THIS_CANCEL,
    onClick: handleCancelClick,
  };

  const cancelModal = (
    <Modal
      {...cancelModalProps}
      heading={modalText.CANCEL.replace(
        ':displayName',
        identityManagerDisplayName,
      )}
      leftButton={modalText.NEVER_MIND}
      rightButton={modalText.SURE}
    />
  );

  switch (status) {
    case IMStatus.NOT_AUTHORIZED:
      switch (activeStep) {
        case identityManagerSteps.WHAT_IS_THIS:
          if (!workatoEmbedUrl) {
            return (
              <>
                <IdentitySSOIntro
                  title={stepsWizardTitle}
                  steps={steps}
                  activeStep={activeStep}
                  infoItems={introInfoItems}
                  heading={introHeading}
                  secondHeading={introSecondHeading}
                  recommendation={recommendation}
                  nextButton={stepConnectButton}
                  cancelButton={stepCancelButton}
                  helperLink={helperLink}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  /* @ts-ignore */
                  notifications={notifications}
                />
                {canShowIMSetupLoading && (
                  <GifLoadingModal
                    isOpen
                    onModalClose={EMPTY_FUNCTION}
                    heading={gifLoadingModal.HEADING}
                    mainContent={gifLoadingModal.MAIN_CONTENT}
                    subContent={gifLoadingModal.SUB_CONTENT}
                    gifURL={gifLoadingModal.GIF_URL}
                    canShowLoading
                  />
                )}
              </>
            );
          }
          if (!canShowIMSetupLoading && workatoEmbedUrl) {
            return (
              <SimpleModal isOpen handleClose={resetIMSetupJob}>
                <WorkatoIFrame
                  src={workatoEmbedUrl}
                  onEvent={handleWorkatoEvent}
                />
              </SimpleModal>
            );
          }
          return <div>Invalid Identity Manager States: {status}</div>;
        default:
          return <div>Invalid Identity Manager States: {status}</div>;
      }
    case IMStatus.AUTHORIZED:
    case IMStatus.CONFIGURATION_COMPLETE:
    case IMStatus.CONNECTED:
      switch (activeStep) {
        case identityManagerSteps.SELECT_PEOPLE:
          return (
            <>
              <IdentitySelectPeoplePageContainer
                identityManager={identityManager}
                IMsMetadata={IMsMetadata}
                accountManagementType={result.accountManagementType}
                accountManagementList={result.accountManagementList}
                accountManagementDeselectionList={
                  result.accountManagementDeselectionList
                }
                integrationData={integrationData}
                heading={userSelectHeading}
                subHeading={userSelectSubHeading}
                title={stepsWizardTitle}
                steps={steps}
                activeStep={activeStep}
                radioGroupOptions={userSelectRadioGroupOptions}
                onCancelClick={handleCancelClick}
                onSelectPeopleDone={handleSelectPeopleDone}
                startPollingIntegrationData={startPollingIntegrationData}
                helperLink={helperLink}
              />
              {cancelModal}
            </>
          );
        case identityManagerSteps.CONFIGURE_INVITES:
          return (
            <>
              <IdentityConfigureInvitesPageContainer
                identityManager={identityManager}
                result={result}
                initialAutoSendInvites={result.autoSendInvites}
                initialAutoApproveMembers={
                  result.autoApproveMembers !== undefined
                    ? result.autoApproveMembers
                    : true
                }
                title={stepsWizardTitle}
                steps={steps}
                activeStep={activeStep}
                onCreateAccountsSuccess={handleConfigureAccountsSuccess}
                onCreateAccountsError={handleError}
                onBackClick={handleConfigInvitesBackClick}
                onCancelClick={handleCancelClick}
                invitesHeading={invitesHeading}
                invitesSubHeading={invitesSubHeading}
                invitesRadioGroupOptions={invitesRadioGroupOptions}
                showApprovalRadioGroup={showApprovalRadioGroup}
                approvalHeading={approvalHeading}
                approvalSubHeading={approvalSubHeading}
                approvalRadioGroupOptions={approvalRadioGroupOptions}
                notifications={notifications}
                helperLink={helperLink}
              />
              {cancelModal}
            </>
          );
        case identityManagerSteps.CREATE_ACCOUNTS:
          return (
            <>
              <IdentityCreateAccountsPageContainer
                identityManager={identityManager}
                result={result}
                configureJobId={configureJobId || ''}
                onViewQueuedPeopleClick={handleViewQueuedPeopleClick}
                onViewPeopleClick={handleViewPeopleClick}
                title={stepsWizardTitle}
                steps={steps}
                activeStep={activeStep}
                createAccountsHeading={createAccountsHeading}
                createAccountsSubHeading={createAccountsSubHeading}
                createAccountsSubHeadingQueued={createAccountsSubHeadingQueued}
                onCreateAccountsSuccess={handleSuccess}
                onCreateAccountsFailure={handleError}
                helperLink={helperLink}
              />
              {cancelModal}
            </>
          );
        default:
          return <div>Invalid Identity Manager States: {status}</div>;
      }
    default:
      return <div>Invalid Identity Manager States: {status}</div>;
  }
};

export default WorkatoIMContainer;
