import React from 'react';
import styled from 'styled-components';
import { RightClickableProps } from './types';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
`;

const RightClickable = (props: RightClickableProps) => {
  const { role, onKeyDown, onClick, children, ...rest } = props;
  return (
    <Wrapper
      role={role || 'option'}
      onKeyDown={onKeyDown}
      onClick={onClick}
      onContextMenu={onClick}
      tabIndex={0}
      {...rest}
    >
      {children}
    </Wrapper>
  );
};

export default RightClickable;
