import styled from 'styled-components';
import TextField from '../../molecules/TextField_V2';
import { TextFieldProps } from '../../molecules/TextField_V2/interface';
import {
  TextFieldRoot,
  TextInputFieldWrapper,
} from '../../molecules/TextField_V2/styles';

export const TextFieldGroup = styled.div`
  display: flex;
  width: 100%;
  & ${TextInputFieldWrapper} {
    & ${TextFieldRoot} {
      border-radius: 0px;
    }
    &:first-child {
      & ${TextFieldRoot} {
        border-radius: 4px 0px 0px 4px;
      }
    }
    &:last-child {
      & ${TextFieldRoot} {
        border-radius: 0px 4px 4px 0px;
      }
    }
    &:not(:first-child) {
      & ${TextFieldRoot} {
        margin-left: -1px;
      }
    }
  }
`;

export const StyledTextField = styled(TextField)<{
  fieldThree?: TextFieldProps;
}>`
  width: ${({ fieldThree }) => (fieldThree ? '33.33%' : '50%')};
`;
