import React, { useMemo } from 'react';
import styled from 'styled-components';

import Button from '../../atoms/Button';
import TextField from '../TextField_V2';
import { SAVE_CHANGES } from '../../../languages/en/settings';

import { SettingsDropdownProps } from './interface';
import useSettingsManagerLogic from './useSettingsDropdownLogic';

const StyledButton = styled(Button)`
  margin-top: 16px;
`;

const StyledWrapper = styled.div`
  input {
    z-index: 2;
  }
`;

const SettingsDropdown = (props: SettingsDropdownProps) => {
  const { dropdownType, dropdownItems, label } = props;

  const { models, operations } = useSettingsManagerLogic(props);
  const { value, selectedItems, isButtonDisabled, enableChip } = models;
  const { handleSelectItemChange, handleOnChange, handleSaveClick } =
    operations;

  const textFieldProps = useMemo(() => {
    if (dropdownType === 'MULTI_SELECT') {
      return {
        value: '',
        selectedItems,
        onSelectItemChange: handleSelectItemChange,
        enableChip,
        label,
        dropdownItems,
        isEditable: true,
      };
    }

    return {
      value,
      onChange: handleOnChange,
      label,
      enableChip,
      dropdownItems,
      isEditable: true,
    };
  }, [
    dropdownItems,
    dropdownType,
    enableChip,
    handleOnChange,
    handleSelectItemChange,
    label,
    selectedItems,
    value,
  ]);

  return (
    <StyledWrapper>
      <TextField
        {...textFieldProps}
        disableClearButton={dropdownType === 'SINGLE_SELECT'}
      />
      <StyledButton
        onClick={handleSaveClick}
        disabled={isButtonDisabled}
        size="small"
      >
        {SAVE_CHANGES}
      </StyledButton>
    </StyledWrapper>
  );
};

export default SettingsDropdown;
