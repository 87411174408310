import React from 'react';
import ContentLoader from 'react-content-loader';
import ThemeV2 from '../../../componentsV2/theme';
import { LoaderProps } from './interfaces';
import { StyledFlex } from './styles';

const Loader = (props: LoaderProps) => {
  const { hasBottomBorder = true } = props;
  return (
    <StyledFlex padding="16px" borderBottom={hasBottomBorder}>
      <ContentLoader
        speed={2}
        viewBox="0 0 406 50"
        height="48px"
        backgroundColor={ThemeV2.palette.gray4}
        foregroundColor={ThemeV2.palette.gray3}
        data-testid="loader"
      >
        <rect x="0" y="2" rx="4" ry="4" width="48" height="48" />
        <rect x="56" y="4" rx="4" ry="4" width="318" height="24" />
        <rect x="56" y="36" rx="4" ry="4" width="318" height="12" />
      </ContentLoader>
    </StyledFlex>
  );
};

export default Loader;
