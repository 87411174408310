import React from 'react';

import {
  StyledBody,
  StyledCalendarInputWrapper,
  StyledCalendarInput,
} from './styles';
import { FlowStartProps } from './types';

import {
  CONFIGURE_SETTINGS_FLOW_START,
  CONFIGURE_SETTINGS_PICK_A_DATE,
} from '../../../languages/en/flows/builder';

const FlowStart = (props: FlowStartProps) => {
  const {
    flowStartDate,
    onFlowStartDateChange,
    clearDateEnabled = false,
    disabled = false,
    onFlowStartBlur,
  } = props;

  const disabledDays = {
    before: new Date(),
  };

  return (
    <>
      <StyledBody variant="body1Medium">
        {CONFIGURE_SETTINGS_FLOW_START}
      </StyledBody>
      <StyledCalendarInputWrapper>
        <StyledCalendarInput
          name="flowStartDate"
          value={flowStartDate}
          onChange={onFlowStartDateChange}
          placeholder={CONFIGURE_SETTINGS_PICK_A_DATE}
          showDropdownIcon
          hideCalendarIcon
          disabled={disabled}
          clearTextEnabled={clearDateEnabled}
          disabledDays={disabledDays}
          onBlur={onFlowStartBlur}
        />
      </StyledCalendarInputWrapper>
    </>
  );
};

export default FlowStart;
