import React, { useCallback } from 'react';
import shallow from 'zustand/shallow';
import HomeDrawer from '../../../atomic/organism/HomeDrawer';
import PrimaryNav from '../../../atomic/organism/PrimaryNavbar';
import { SecondaryPanel } from '../../../interfaces/Layout';
import useLayoutStore from '../../../stores/layoutStore';
import usePrimaryNavLogic from './usePrimaryNavLogic';
import usePrimaryNavModel from './usePrimaryNavModel';
import { APP_NAME } from '../../../languages/en';
import useNotificationSocket from '../../../hooks/useNotificationFeedSocket';
import FlowsActiveListController from '../../flows/FlowsActiveListController';
import LeftDrawerGeneralListController from '../../home/LeftDrawerGeneralListController';
import PeopleDropdownListController from '../../home/PeopleDropdownListController';
import useFlowActivityUpdatesSocket from '../../../hooks/useFlowActivityUpdatesSocket';
import { updateShowLeftDrawer } from '../../../Utils/leftDrawer';
import useKioskStore from '../../../stores/kioskStore';

const PrimaryNavController: React.FC = () => {
  const { isTurnedOn: isKioskTurnedOn } = useKioskStore();
  const {
    activeSecondaryPanel,
    activeNavItem,
    isLeftAsideOpen,
    isRightAsideOpen,
    toggleLeftAsideOpen,
    isLeftNavHovered,
    setLeftNavHoveredToTrue,
    setLeftNavHoveredToFalse,
  } = useLayoutStore(
    (state) => ({
      activeSecondaryPanel: state.activeSecondaryPanel,
      activeNavItem: state.activeNavItem,
      isLeftAsideOpen: state.isLeftAsideOpen,
      isRightAsideOpen: state.isRightAsideOpen,
      toggleLeftAsideOpen: state.toggleLeftAsideOpen,
      isLeftNavHovered: state.isLeftNavHovered,
      setLeftNavHoveredToTrue: state.setLeftNavHoveredToTrue,
      setLeftNavHoveredToFalse: state.setLeftNavHoveredToFalse,
    }),
    shallow,
  );

  const {
    models: {
      isInQuickSetup,
      hideFlowsList,
      hideParticipantsList,
      unreadNotificationsCount,
      pathname,
      currentUserData,
      hideRedeemButton,
      assemblyData,
      hideAllowanceDetails,
      hideRewardsLink,
      hideRewardsInfo,
      isCurrentUserAdmin,
      isPremiumUser,
      isQuickSetupDataLoading,
      quickSetupProgress,
      showQuickSetupOption,
    },
  } = usePrimaryNavModel();

  const {
    operations: {
      toggleNavBar,
      onProfilePopoverClick,
      onProfileClick,
      onProfileOutsideClick,
      onProfileAnywhereElseClick,
      trackLeftDrawerCollapsed,
      trackLeftDrawerExpanded,
      trackLeftDrawerRedeemClick,
      trackLeftDrawerMainFeedClick,
      trackLeftDrawerRewardsClick,
      handleScroll,
    },
    models: { showQuickSetupPanel, refreshDate },
  } = usePrimaryNavLogic(
    isQuickSetupDataLoading,
    isCurrentUserAdmin,
    showQuickSetupOption,
  );

  useFlowActivityUpdatesSocket();
  useNotificationSocket();

  const renderInfoPanelContent = useCallback(
    ({ isHoveredWhenCollapsed }) => {
      switch (activeSecondaryPanel) {
        case SecondaryPanel.ASSEMBLY:
        case SecondaryPanel.PROFILE: {
          if (!currentUserData || !assemblyData) {
            return <div />;
          }
          return (
            <div data-testid="assemblyContent">
              <HomeDrawer
                isKioskTurnedOn={isKioskTurnedOn}
                isHoveredWhenCollapsed={isHoveredWhenCollapsed}
                isBeta={false}
                trophyBalance={currentUserData.pointsEarned}
                totMonthlyAllowance={currentUserData.allowance.points}
                remainingMonthlyAllowance={currentUserData.pointsLeftThisCycle}
                refreshInfoDate={refreshDate}
                pathname={pathname}
                assemblyCurrency={assemblyData.currency}
                hideAllowanceDetails={hideAllowanceDetails}
                redeemButtonClicked={() => {
                  trackLeftDrawerRedeemClick();
                }}
                hideRedeemButton={hideRedeemButton}
                hideRewardsLink={hideRewardsLink}
                hideRewardsInfo={hideRewardsInfo}
                assemblyName={isPremiumUser ? assemblyData.name : APP_NAME}
                onRewardsLinkClick={trackLeftDrawerRewardsClick}
                onHomeLinkClick={trackLeftDrawerMainFeedClick}
                handleScroll={handleScroll}
              >
                <LeftDrawerGeneralListController
                  isLeftAsideOpen={isLeftAsideOpen}
                  isLeftNavHovered={isLeftNavHovered}
                />
                {!hideFlowsList && (
                  <FlowsActiveListController
                    isLeftAsideOpen={isLeftAsideOpen}
                    isLeftNavHovered={isLeftNavHovered}
                    isInQuickSetup={isInQuickSetup}
                  />
                )}
                {!hideParticipantsList && (
                  <PeopleDropdownListController
                    isLeftAsideOpen={isLeftAsideOpen}
                    isLeftNavHovered={isLeftNavHovered}
                  />
                )}
              </HomeDrawer>
            </div>
          );
        }
        case SecondaryPanel.QUICK_SETUP:
          return null;

        default: {
          return 'Default';
        }
      }
    },
    [
      activeSecondaryPanel,
      currentUserData,
      assemblyData,
      refreshDate,
      pathname,
      hideAllowanceDetails,
      hideRedeemButton,
      hideRewardsLink,
      hideRewardsInfo,
      isPremiumUser,
      trackLeftDrawerRewardsClick,
      trackLeftDrawerMainFeedClick,
      handleScroll,
      isLeftAsideOpen,
      isLeftNavHovered,
      hideFlowsList,
      isInQuickSetup,
      hideParticipantsList,
      trackLeftDrawerRedeemClick,
    ],
  );

  if (!currentUserData) {
    return null;
  }

  return (
    <PrimaryNav
      isLeftAsideOpen={isLeftAsideOpen}
      isRightAsideOpen={isRightAsideOpen}
      isLeftNavHovered={isLeftNavHovered}
      setLeftNavHoveredToTrue={setLeftNavHoveredToTrue}
      setLeftNavHoveredToFalse={setLeftNavHoveredToFalse}
      toggleLeftAsideOpen={() => {
        toggleLeftAsideOpen();
        updateShowLeftDrawer({ showLeftDrawer: !isLeftAsideOpen });
        if (isLeftAsideOpen) {
          trackLeftDrawerCollapsed();
        } else {
          trackLeftDrawerExpanded();
        }
      }}
      activeNavItem={activeNavItem}
      activeSecondaryPanel={activeSecondaryPanel}
      toggleNavBar={toggleNavBar}
      onProfilePopoverClick={onProfilePopoverClick}
      onProfileClick={onProfileClick}
      onProfileOutsideClick={onProfileOutsideClick}
      onProfileAnywhereElseClick={onProfileAnywhereElseClick}
      unreadNotificationsCount={unreadNotificationsCount}
      renderInfoPanelContent={renderInfoPanelContent}
      currentUserId={currentUserData.memberId}
      currentUserProfile={currentUserData.profile}
      isCurrentUserAdmin={isCurrentUserAdmin}
      showQuickStepProgress={showQuickSetupPanel}
      quickSetupProgress={quickSetupProgress}
      hideRewardsLink={hideRewardsLink}
      hideQuickSetup
      isKioskTurnedOn={isKioskTurnedOn}
    />
  );
};

export default PrimaryNavController;
