import React from 'react';

import Body from '../../../atoms/Body';
import BlockSection from '../../BlockSection';
import { GetStartedScreenProps } from './type';
import { getStartedScreenClickableItems } from './data';
import { FooterPlaceholder, StyledClickableSelectionItem } from './styles';
import { quickSetupGetStartedScreen } from '../../../../languages/en/quickSetup';

const GetStartedScreen = (props: GetStartedScreenProps) => {
  const { onSelected } = props;

  return (
    <>
      <BlockSection
        headingVariant="h4"
        subHeadingColor="gray8"
        subHeadingVariant="body1"
        heading={quickSetupGetStartedScreen.headingText}
        subHeading={quickSetupGetStartedScreen.subHeadingText}
      >
        {getStartedScreenClickableItems.map((item) => (
          <StyledClickableSelectionItem
            key={item.id}
            variant="regular"
            title={item.title}
            imageSrc={item.imgSrc}
            onClick={() => {
              onSelected(item.id);
            }}
            backGroundColor={item.backgroundColor}
          >
            <Body variant="body2" color="gray8">
              {item.subTitle}
            </Body>
          </StyledClickableSelectionItem>
        ))}
      </BlockSection>
      <FooterPlaceholder></FooterPlaceholder>
    </>
  );
};

export default GetStartedScreen;
