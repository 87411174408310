import React from 'react';

import Body from '../../atoms/Body';
import {
  CELEBRATION_STATISTICS,
  GIVEN,
  RECEIVED,
  SINCE,
  FLOW_STATISTICS,
  CREATED,
  PARTICIPATING,
} from '../../../languages/en/home/profileDrawer';
import { formatNumberWithCommas } from '../../../Utils/formatting';
import { getMonthByNumber } from '../../../Utils/date';
import {
  RowWrapper,
  StyledHeading,
  StyledSVGIcon,
  Wrapper,
  StyledDivider,
} from './styles';

export interface ProfileCelebrationStatisticsSectionProps {
  hiredDay?: {
    month: number;
    year: number;
  } | null;
  timesGiven: number;
  timesReceived: number;
  flowsCreated?: number;
  flowsParticipated?: number;
}

const ProfileCelebrationStatisticsSection = ({
  hiredDay,
  timesGiven,
  timesReceived,
  flowsCreated = 0,
  flowsParticipated = 0,
}: ProfileCelebrationStatisticsSectionProps) => {
  return (
    <Wrapper>
      {flowsCreated !== 0 && flowsParticipated !== 0 && (
        <>
          <Body variant="body2Medium">{FLOW_STATISTICS}</Body>

          <RowWrapper>
            <StyledSVGIcon icon="create-bubble" size="36px" />
            <StyledHeading variant="h6" weight="regular">
              {flowsCreated} {CREATED}
            </StyledHeading>
          </RowWrapper>

          <RowWrapper>
            <StyledSVGIcon icon="groups-bubble" size="36px" />
            <StyledHeading variant="h6" weight="regular">
              {flowsParticipated} {PARTICIPATING}
            </StyledHeading>
          </RowWrapper>
          <StyledDivider />
        </>
      )}

      <Body variant="body2Medium">{CELEBRATION_STATISTICS}</Body>

      <RowWrapper>
        <StyledSVGIcon icon="celebration-bubble" size="36px" />
        <StyledHeading variant="h6" weight="regular">
          {formatNumberWithCommas(timesGiven)} {GIVEN}
        </StyledHeading>
      </RowWrapper>

      <RowWrapper>
        <StyledSVGIcon icon="star-bubble" size="36px" />
        <StyledHeading variant="h6" weight="regular">
          {formatNumberWithCommas(timesReceived)} {RECEIVED}
        </StyledHeading>
      </RowWrapper>

      {hiredDay && (
        <Body>
          {SINCE} {getMonthByNumber(hiredDay.month)} {hiredDay.year}
        </Body>
      )}
    </Wrapper>
  );
};

export default ProfileCelebrationStatisticsSection;
