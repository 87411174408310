export const AVATAR_SIZE = {
  MIN_DIMENSIONS: {
    WIDTH: 220,
    HEIGHT: 220,
  },
  FILE_SIZE_LIMIT: 15 * 1000 * 1000, // 15mb
};

export const CULTURE_REWARDS_IMAGE_SIZE = {
  MIN_DIMENSIONS: {
    WIDTH: 215,
    HEIGHT: 125,
  },
  FILE_SIZE_LIMIT: 15 * 1000 * 1000,
};

export const COMPANY_LOGO_SIZE = {
  MIN_DIMENSIONS: {
    WIDTH: 150,
    HEIGHT: 150,
  },
  FILE_SIZE_LIMIT: 15 * 1000 * 1000,
};
