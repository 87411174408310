import { useState } from 'react';
import { useQueryClient } from 'react-query';

import { isValidEmail } from '../../Utils/validation';
import { copyToClipboard } from '../../Utils/common';
import {
  showSuccessMessage,
  showInfoMessage,
  showErrorMessage,
  dismissAllToasts,
} from '../../Utils/toast';

import {
  COPIED_TO_CLIPBOARD,
  SINGLE_INVALID_EMAIL,
  MULTI_INVALID_EMAILS,
  INVITE_SUCCESS,
  INVITE_PENDING,
  INVITE_ERROR,
  REQUEST_INVITE_PENDING,
  REQUEST_INVITE_SUCCESS,
  REQUEST_INVITE_ERROR,
} from '../../languages/en/flows';

import { useInviteUserPostQuery } from '../../queries/Admin/invites';
import { useProfileInfoFetchQuery } from '../../queries/Profile';
import { getIsInviteRequiresApproval } from '../../Utils/permissions';
import { GET_MEMBERS_INFINITE } from '../../queries/Members/utils';
import {
  trackInviteMemberActionEvent,
  trackInviteMemberErrorEvent,
} from '../../Utils/analytics/inviteMembers';
import { INVITE_MEMBER_EVENTS } from '../../Utils/analytics/constants';
import useGetPageName from '../../hooks/useGetPageName';

interface InviteModalControllerLogicProps {
  closeInviteModal: () => void;
}

const useInviteModalController = (props: InviteModalControllerLogicProps) => {
  const { closeInviteModal } = props;
  const [emails, setEmails] = useState<string[]>([]);
  const [textFieldValue, setTextFieldValue] = useState<string>('');
  const { mutate: uploadInviteUserAction } = useInviteUserPostQuery();
  const { data: profileData } = useProfileInfoFetchQuery();

  const { page } = useGetPageName();
  const queryClient = useQueryClient();

  const canShowCopyInviteLink =
    profileData?.assembly.settings?.userManagement?.canLoginUsingLink.enabled;

  const handleEmailsChange = (
    event: React.ChangeEvent<unknown>,
    value: string[],
  ) => {
    setEmails(value);
  };

  const handleCloseInviteModal = () => {
    closeInviteModal();
    setEmails([]);
  };

  const handleTextFieldChange = (event: React.FormEvent<HTMLInputElement>) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setTextFieldValue(event.target.value);
  };

  /* Mix Panel */
  const subSource = 'leftDrawer';
  /* Mix Panel */

  const onCopyInvite = () => {
    /* Mix panel */
    trackInviteMemberActionEvent({
      action: INVITE_MEMBER_EVENTS.COPY_INVITE_LINK,
      inviterId: profileData?.member.memberId,
      inviterRole: profileData?.member.role,
      source: page,
      subSource: subSource,
    });
    /* Mix panel */
    const urlString = `${window.location.protocol}//${
      window.location.hostname
    }${window.location.port ? `:${window.location.port}` : ''}`;
    copyToClipboard(
      `${urlString}/join/${profileData && profileData.assembly.assemblyId}`,
    );
    showSuccessMessage(COPIED_TO_CLIPBOARD);
  };

  const validation = (value: string) => {
    return isValidEmail(value);
  };

  const isInviteRequiresApproval = getIsInviteRequiresApproval(profileData);

  const onInviteClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    const validEmails: string[] = [];
    const hasErrors = [];

    emails.forEach((email) => {
      if (email.length > 0) {
        if (isValidEmail(email)) {
          validEmails.push(email);
        } else {
          hasErrors.push(email);
        }
      }
    });

    if (textFieldValue.length > 0) {
      if (isValidEmail(textFieldValue)) {
        if (!validEmails.includes(textFieldValue)) {
          validEmails.push(textFieldValue);
        }
      } else if (emails.length === 0) {
        hasErrors.push(textFieldValue);
      }
    }

    let goThrough = true;
    if (hasErrors.length > 0) {
      goThrough = false;
      let emailsErrors;
      if (hasErrors.length === 1) {
        emailsErrors = SINGLE_INVALID_EMAIL;
      } else {
        emailsErrors = MULTI_INVALID_EMAILS;
      }
      showErrorMessage(emailsErrors);
    }

    if (goThrough) {
      const userInvites = validEmails.map((email) => {
        const userInvite = {
          email,
        };
        return userInvite;
      });

      const payload = {
        data: userInvites,
      };

      showInfoMessage(
        isInviteRequiresApproval ? REQUEST_INVITE_PENDING : INVITE_PENDING,
      );

      uploadInviteUserAction(payload, {
        onSuccess: (inviteSuccessData: any) => {
          dismissAllToasts();
          if (inviteSuccessData.data.addedMembers.length > 0) {
            showSuccessMessage(
              isInviteRequiresApproval
                ? REQUEST_INVITE_SUCCESS
                : INVITE_SUCCESS,
            );
            /* Mix panel */
            trackInviteMemberActionEvent({
              action: INVITE_MEMBER_EVENTS.INVITE_COMPLETE,
              inviterId: profileData?.member.memberId,
              inviterRole: profileData?.member.role,
              inviteesEmails: validEmails,
              source: page,
              subSource: subSource,
            });
            /* Mix panel */
            queryClient.invalidateQueries(GET_MEMBERS_INFINITE);
          }
          closeInviteModal();
          setEmails([]);
          inviteSuccessData.data.failedMembers.forEach((element: any) => {
            showInfoMessage(element.message);
          });
        },
        onError: () => {
          trackInviteMemberErrorEvent({
            action: INVITE_MEMBER_EVENTS.INVITE_COMPLETE,
            inviterId: profileData?.member.memberId,
            inviterRole: profileData?.member.role,
            inviteesEmails: validEmails,
            source: page,
            subSource: subSource,
          });
          showErrorMessage(
            isInviteRequiresApproval ? REQUEST_INVITE_ERROR : INVITE_ERROR,
          );
        },
      });
    }
  };

  return {
    emails,
    textFieldValue,
    handleEmailsChange,
    handleCloseInviteModal,
    onInviteClick,
    onCopyInvite,
    setTextFieldValue: handleTextFieldChange,
    validation,
    isInviteRequiresApproval,
    canShowCopyInviteLink,
  };
};

export default useInviteModalController;
