// @ts-nocheck TODO: type issues need to be fixed in this file
import { EditorState, Modifier } from 'draft-js';
import { EmojiData } from 'emoji-mart';
import { Mention } from '../../interfaces/user';

import {
  getLiteralTextFromEditor,
  getTextFromEditorWithoutMentionsandTags,
} from './mention';

export const addAtSymbolToEditorState = (editorState: EditorState) => {
  const contentState = editorState.getCurrentContent();
  const targetRange = editorState.getSelection();
  const newContentState = Modifier.insertText(contentState, targetRange, '@');

  const newEditorState = EditorState.push(
    editorState,
    newContentState,
    'insert-fragment',
  );
  return newEditorState;
};

export const addEmojiToEditorState = (
  emojiData: EmojiData,
  editorState: EditorState,
) => {
  const contentState = editorState.getCurrentContent();
  const targetRange = editorState.getSelection();
  const newContentState = Modifier.insertText(
    contentState,
    targetRange,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    /* @ts-ignore */
    `${emojiData.native} `,
  );

  const newEditorState = EditorState.push(
    editorState,
    newContentState,
    'insert-fragment',
  );
  return newEditorState;
};

export const getCharacterCount = (editorState: EditorState) => {
  const literalMessage = getLiteralTextFromEditor(editorState);
  return literalMessage.trim().length;
};

export const getCharacterCountWithoutMentions = (
  editorState: EditorState,
  mentions: Mention[],
  tags: Mention[],
) => {
  const literalMessage = getTextFromEditorWithoutMentionsandTags(
    editorState,
    mentions,
    tags,
  );
  return literalMessage.trim().length;
};
