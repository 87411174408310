import styled from 'styled-components';

import ThemeV2 from '../../componentsV2/theme';

export const StyledButton = styled.button.attrs((props) => ({
  type: props.type || 'button',
}))`
  background: none;
  color: inherit;
  border: none;
  padding: 0px;
  font: inherit;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  outline: inherit;
  font-family: ${ThemeV2.typography.adminFontFamily};
  font-weight: ${ThemeV2.typography.adminFontFamily};
  font-size: 14px;
  line-height: 22px;

  &:focus {
    outline: 0;
  }
`;
