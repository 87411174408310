import React, { memo } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import Body from '../../../atomic/atoms/Body';
import SVGIcon from '../../../atomic/atoms/SVGIcon';
import { NotebookTaskLoader } from '../../../atomic/molecules/Notebook/Loader';
import CreateNewTaskItem from '../../../atomic/molecules/Notebook/TaskItem/CreateNewTaskItem';
import { NOTEBOOK_PAGINATION_LIMIT } from '../../../constants/notebook';
import { TaskCategories } from '../../../interfaces/notebook';
import {
  NO_TASKS_LABELS,
  getSectionalBannerText,
  NOTEBOOK_SECTIONAL_ERROR_TEXT,
  SHOW_MORE,
} from '../../../languages/en/notebook';
import {
  StyledInfoAlert,
  AccordionEmptyText,
  ErrorTextWrapper,
  DragIconWrapper,
  ErrorText,
  NotebookTaskLoaderWrapper,
  ShowMoreWrapper,
  ShowMoreButton,
} from '../NotebookGroupController/styles';
import {
  heightAnimationProps,
  opacityAnimationProps,
} from '../NotebookPageController/useNotebookPageController/data';
import { NotebookTabs } from '../NotebookPageController/useNotebookPageController/types';
import ThemeV2 from '../../../componentsV2/theme';
import useNotebookTasksController from './useNotebookTasksController';
import TaskItem from '../../../atomic/organism/Notebook/TaskItem';

type NotebookTaskControllerProps = {
  type: TaskCategories;
  placeholder?: React.ReactElement | null;
  tabType: NotebookTabs;
  isCreateTaskSectionShown: boolean;
  hasError: boolean;
  isLoading: boolean;
  isFetchingMoreTasks: boolean;
  fetchMoreTasks: () => void;
  hasNextPage: boolean;
  markTasksAsRead: (taskIds: string[]) => void;
  shouldShowModalForCurrentUser: boolean;
};

const renderSectionalEmptyState = (
  type: TaskCategories,
  tabType: NotebookTabs,
) => {
  switch (type) {
    case TaskCategories.COMPLETED:
    case TaskCategories.ARCHIVED:
      return (
        <StyledInfoAlert
          id={+new Date()}
          text={NO_TASKS_LABELS[tabType][type]}
          icon="info-outlined"
          alertType="info"
          bodyVariant="body3"
        />
      );
    case TaskCategories.TODAY:
    case TaskCategories.OVERDUE:
    case TaskCategories.UPCOMING:
    case TaskCategories.UNSCHEDULED:
    default:
      return (
        <AccordionEmptyText>
          {NO_TASKS_LABELS[tabType][type]}
        </AccordionEmptyText>
      );
  }
};

const renderSectionalBannerState = (
  type: TaskCategories,
  tabType: NotebookTabs,
  total: number,
) => {
  switch (type) {
    case TaskCategories.COMPLETED:
    case TaskCategories.ARCHIVED:
      return (
        <StyledInfoAlert
          id={+new Date()}
          text={getSectionalBannerText(type, tabType, total)}
          icon="info-outlined"
          alertType="info"
          bodyVariant="body3"
        />
      );
    case TaskCategories.TODAY:
    case TaskCategories.OVERDUE:
    case TaskCategories.UPCOMING:
    case TaskCategories.UNSCHEDULED:
    default:
      return null;
  }
};

const NotebookTasksController = ({
  type,
  tabType,
  placeholder,
  isCreateTaskSectionShown,
  hasError,
  isLoading,
  hasNextPage,
  isFetchingMoreTasks,
  shouldShowModalForCurrentUser,
  fetchMoreTasks,
  markTasksAsRead,
}: NotebookTaskControllerProps) => {
  const {
    currentUserId,
    tasks,
    total,
    erroredTaskIDs = [],
    dropdownOptions,
    assigneesList,
    hasMoreAssigneesToFetch,
    isFetchingMoreAssignees,
    searchValue,
    currentUserTimezone = '',
    selectedRightDrawerTaskId,
    editTaskContent,
    updateTask,
    handleTaskCreation,
    onTaskDataChange,
    onTaskClick,
    setTaskIdOfFocusedTask,
    fetchMoreAssignees,
    handleAssigneeChange,
    handleSearchOnAssigneeList,
    updateReadStatusOfTask,
    handleCreatorLinkClick,
  } = useNotebookTasksController(
    type,
    tabType,
    shouldShowModalForCurrentUser,
    markTasksAsRead,
  );
  return (
    <>
      {tasks.length === 0 && !isLoading && !hasError && (
        <AnimatePresence>
          <motion.div {...opacityAnimationProps}>
            {renderSectionalEmptyState(type, tabType)}
          </motion.div>
        </AnimatePresence>
      )}
      {total > 0 && renderSectionalBannerState(type, tabType, total)}
      {isCreateTaskSectionShown && !isLoading && !hasError && (
        <CreateNewTaskItem
          type={type}
          onTaskDataChange={onTaskDataChange}
          handleTaskCreation={handleTaskCreation}
          currentUserTimezone={currentUserTimezone}
        />
      )}
      {tasks.map((taskId, index) => {
        return (
          <motion.div key={taskId} {...heightAnimationProps}>
            <TaskItem
              currentUserId={currentUserId}
              index={index}
              type={type}
              taskId={taskId}
              dropdownOptions={dropdownOptions}
              onEditorUpdate={editTaskContent}
              updateTask={updateTask}
              hasError={erroredTaskIDs.includes(taskId)}
              onTaskDataChange={onTaskDataChange}
              onTaskClick={onTaskClick}
              setTaskIdOfFocusedTask={setTaskIdOfFocusedTask}
              fetchMoreAssignees={fetchMoreAssignees}
              assigneesList={assigneesList}
              hasMoreAssigneesToFetch={hasMoreAssigneesToFetch || false}
              isFetchingMoreAssignees={isFetchingMoreAssignees || false}
              handleSearchOnAssigneeList={handleSearchOnAssigneeList}
              searchValue={searchValue}
              handleAssigneeChange={handleAssigneeChange}
              currentUserTimezone={currentUserTimezone}
              markTasksAsRead={updateReadStatusOfTask}
              handleCreatorLinkClick={handleCreatorLinkClick}
              highlight={selectedRightDrawerTaskId === taskId}
              shouldShowModalForCurrentUser={shouldShowModalForCurrentUser}
            />
          </motion.div>
        );
      })}

      {placeholder ? placeholder : null}
      {hasError && (
        <ErrorTextWrapper>
          <DragIconWrapper />
          <SVGIcon
            icon={'error-warning'}
            size="16px"
            data-testid="error"
            color={ThemeV2.palette.dustRed6}
          />
          <ErrorText variant="body1" color="dustRed6" inline>
            {NOTEBOOK_SECTIONAL_ERROR_TEXT}
          </ErrorText>
        </ErrorTextWrapper>
      )}
      {(isFetchingMoreTasks || isLoading) && (
        <NotebookTaskLoaderWrapper>
          <NotebookTaskLoader />
        </NotebookTaskLoaderWrapper>
      )}
      {!isFetchingMoreTasks &&
        hasNextPage &&
        total > NOTEBOOK_PAGINATION_LIMIT &&
        tasks.length !== total && (
          <ShowMoreWrapper justifyContent="center">
            <ShowMoreButton
              size="small"
              status="default"
              variant="text"
              onClick={fetchMoreTasks}
            >
              <Body variant="body2" color="geekBlue6" inline>
                {SHOW_MORE}
              </Body>
            </ShowMoreButton>
          </ShowMoreWrapper>
        )}
    </>
  );
};

export default memo(NotebookTasksController);
