import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import useLayoutStore from '../../../../stores/layoutStore';
import useFlowBuilderStore from '../../../../stores/flowBuilderStore';

import { useProfileInfoFetchQuery } from '../../../../queries/Profile';
import { useCreateFlowMutation } from '../../../../queries/Flows/Builder';

import { SetupCompletionControllerProps } from '../types';
import { showErrorMessage } from '../../../../Utils/toast';
import { LayoutStoreState } from '../../../../interfaces/Layout';
import { QuickSetupStep } from '../../QuickSetupController/types';
import { SETUP_FLOWS_ERROR } from '../../../../languages/en/flows';
import { FLOWS_ACCOUNT_SETUP } from '../../../../constants/endpoints';
import { useUpdateQuickSetupStatus } from '../../../../queries/QuickSetup';
import { getEmailsFromParticipantByRole, getRulesCriteria } from './utils';
import useMultipleJobsPollingV2 from '../../../../hooks/useMultipleJobsPolling';
import { serializeBuilderBlockData } from '../../../../Utils/flows/builder/utils';
import useGetFlowDetailsFromTemplate from '../../../../hooks/useGetFlowDetailsFromTemplate';
import { FLOWS, V2_HOME } from '../../../../constants/routes';
import {
  QuickSetupStatusEnum,
  QuickSetupDirectionEnum,
} from '../../../../queries/QuickSetup/interfaces';
import { trackOnboardingActionEvent } from '../../../../Utils/analytics/onboarding';
import { ACCOUNT_ONBOARDING_EVENTS } from '../../../../Utils/analytics/constants';

const layoutSelector = (state: LayoutStoreState) => state.toggleRightAsideOpen;

const useSetupCompletionController = (
  props: SetupCompletionControllerProps,
) => {
  const {
    flow,
    meetingParticipants,
    templateId,
    meetingReccurence,
    onQuickSetupStepChange,
    meetingName,
    isInviteMembersSuccess,
    invitedMembers,
    duration,
    meetingId,
    calenderId,
  } = props;
  // Callback from Hubspot
  const { search } = useLocation();

  // Create Flow from selected template
  const { mutate, error: flowCreationError } = useCreateFlowMutation();
  const { data: profileInfoQueryData } = useProfileInfoFetchQuery();
  const { mutate: mutateQuickSetupStatus } = useUpdateQuickSetupStatus();
  const [isFlowCreated, setIsFlowCreated] = useState(false);

  const storeData = useFlowBuilderStore();
  useGetFlowDetailsFromTemplate(templateId, meetingName);

  useEffect(() => {
    return () => {
      onQuickSetupStepChange(QuickSetupStep.POST_COMPLETED);
    };
  }, [onQuickSetupStepChange]);

  // Flow Id
  const [flowId, setFlowId] = useState('');

  useEffect(() => {
    if (
      storeData &&
      storeData.flowName &&
      flow !== 'survey_Team' &&
      isInviteMembersSuccess &&
      !isFlowCreated
    ) {
      const viewerCriteria = ['Participant & Viewer', 'Viewer Only'];
      const participantCriteria = ['Participant & Viewer', 'Participant Only'];

      const participantList = getEmailsFromParticipantByRole(
        meetingParticipants,
        participantCriteria,
        [],
      );

      const viewersList = getEmailsFromParticipantByRole(
        meetingParticipants,
        viewerCriteria,
        [],
      );

      const payload = {
        ...serializeBuilderBlockData({
          ...storeData,
          canIncludeNewInviteMembers: false,
        }),
        endTimeInMinutes: duration,
        viewing: getRulesCriteria(viewersList),
        ownerId: profileInfoQueryData?.member.memberId,
        schedule: {
          rule: meetingReccurence,
        },
        kind: 'SCHEDULED',
        participation: getRulesCriteria(participantList),
        description: '',
        metaData: {
          eventId: meetingId,
          calendarId: calenderId,
        },
      };
      setIsFlowCreated(true);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      mutate(payload, {
        onSettled: (res) => {
          if (res) {
            setFlowId(String(res.data.flowId));
          }
        },
      });
    }
  }, [
    mutate,
    storeData,
    meetingReccurence,
    meetingParticipants,
    profileInfoQueryData,
    flow,
    isInviteMembersSuccess,
    isFlowCreated,
    duration,
    meetingId,
    calenderId,
  ]);

  // User on-boarding Jobs
  const history = useHistory();
  const toggleRightAsideOpen = useLayoutStore(layoutSelector);

  const { error, isJobStatusSuccessful } = useMultipleJobsPollingV2<undefined>({
    preJobsAPI: {
      apiName: FLOWS_ACCOUNT_SETUP,
    },
    enabled: true,
  });

  useEffect(() => {
    if (error) {
      showErrorMessage(SETUP_FLOWS_ERROR);
    }
  }, [error]);

  // Track Completion Event
  const trackCompletionEvent = useCallback(() => {
    trackOnboardingActionEvent({
      action: ACCOUNT_ONBOARDING_EVENTS.FINISH_SETUP,
      path: flow,
      templateId: templateId,
      meetingTitle: meetingName,
      meetingRecurs: meetingReccurence
        ? !meetingReccurence.includes('COUNT=1')
        : undefined,
      meetingParticipants: invitedMembers,
    });

    trackOnboardingActionEvent({
      action: ACCOUNT_ONBOARDING_EVENTS.COMPLETE_ONBOARDING,
      path: flow,
      templateId: templateId,
      meetingTitle: meetingName,
      meetingParticipants: invitedMembers,
      meetingRecurs: meetingReccurence
        ? !meetingReccurence.includes('COUNT=1')
        : undefined,
    });
  }, [flow, meetingName, meetingReccurence, templateId, invitedMembers]);

  useEffect(() => {
    const query = new URLSearchParams(search);
    if (
      query.get('step') === 'COMPLETION' &&
      (isJobStatusSuccessful || error)
    ) {
      trackCompletionEvent();
      mutateQuickSetupStatus({
        status: QuickSetupStatusEnum.COMPLETED,
        direction: QuickSetupDirectionEnum.FORWARD,
      });
      history.push(V2_HOME);
    }

    if (flow === 'survey_Team' && (isJobStatusSuccessful || error)) {
      mutateQuickSetupStatus({
        status: QuickSetupStatusEnum.COMPLETED,
        direction: QuickSetupDirectionEnum.FORWARD,
      });
      history.push(`flows/builder?template=${templateId}&flow=survey_Team`);
      toggleRightAsideOpen();
      trackCompletionEvent();
    }

    if (
      storeData &&
      storeData.flowName &&
      flow !== 'survey_Team' &&
      (isJobStatusSuccessful || error) &&
      (flowId || flowCreationError)
    ) {
      mutateQuickSetupStatus({
        status: QuickSetupStatusEnum.COMPLETED,
        direction: QuickSetupDirectionEnum.FORWARD,
      });
      storeData.revertBlockDataToDefaultState();
      if (flowId) {
        history.push(`${FLOWS}/${flowId}?quickSetup=true`);
      } else {
        history.push(V2_HOME);
      }
      toggleRightAsideOpen();
      trackCompletionEvent();
    } else {
      if (flow === 'engage_Team' && (isJobStatusSuccessful || error)) {
        mutateQuickSetupStatus({
          direction: QuickSetupDirectionEnum.FORWARD,
          status: QuickSetupStatusEnum.SKIPPED,
        });
        history.push('/home?setProfile=invite');
        toggleRightAsideOpen();
        trackCompletionEvent();
      }
    }
  }, [
    isJobStatusSuccessful,
    history,
    error,
    toggleRightAsideOpen,
    flowId,
    mutateQuickSetupStatus,
    storeData,
    flow,
    flowCreationError,
    templateId,
    trackCompletionEvent,
    search,
  ]);
};

export default useSetupCompletionController;
