import { AxiosError } from 'axios';
import { useHistory, useLocation } from 'react-router';
import { useArchiveFlow } from '../../queries/Flows/FlowFeedOptions/Archive';
import { showErrorMessage, showSuccessMessage } from '../../Utils/toast';
import { FLOW_ARCHIVE_RESPONSE_MESSAGE } from '../../languages/en/flows/feed';
import { getErrorMessage } from '../../Utils/message';
import useToggle from '../useToggle';
import { V2_HOME } from '../../constants/routes';

const useArchiveFlowOption = (flowId: string) => {
  const history = useHistory();
  const location = useLocation();
  const {
    models: { toggleValue: isArchiveModalOpen },
    operations: {
      setToggleToFalse: setArchiveModalClose,
      setToggleToTrue: setArchiveModalOpen,
    },
  } = useToggle();

  const isUserOnTheCurrentFlow = location.pathname.includes(flowId);

  const { mutate: mutateArchiveFlows, isLoading: isArchiveFlowLoading } =
    useArchiveFlow();

  const handleOnArchiveClick = () => {
    mutateArchiveFlows(
      { flowId },
      {
        onError: (error) => {
          showErrorMessage(
            getErrorMessage(
              error as AxiosError,
              FLOW_ARCHIVE_RESPONSE_MESSAGE.ERROR,
            ),
          );
        },
        onSuccess: () => {
          showSuccessMessage(FLOW_ARCHIVE_RESPONSE_MESSAGE.SUCCESS);
          setArchiveModalClose();
          if (isUserOnTheCurrentFlow) {
            history.push(V2_HOME);
          }
        },
      },
    );
  };
  return {
    models: { isArchiveModalOpen, isArchiveFlowLoading },
    operations: {
      handleOnArchiveClick,
      setArchiveModalOpen,
      setArchiveModalClose,
    },
  };
};

export default useArchiveFlowOption;
