import styled from 'styled-components';
import SVGIcon from '../../atoms/SVGIcon';
import ThemeV2 from '../../../componentsV2/theme';
import { Flex } from '../../../Utils/styles/display';

export const StyledDropdownArrowIcon = styled(SVGIcon)`
  svg path {
    transition: 0.2s ease;
    transition-property: fill, opacity;
    fill: ${ThemeV2.palette.gray8};
  }
`;

export const ExpandableDropdownItemContainer = styled(Flex)<{
  $depthLevel?: number;
}>`
  white-space: nowrap;
  min-width: 158px;
  padding: 4px 8px;
  background: ${ThemeV2.palette.gray1};
  text-align: left;
  cursor: pointer;
  margin-left: ${({ $depthLevel }) =>
    $depthLevel && `${($depthLevel - 1) * 16}px`};
  &:hover {
    background: ${ThemeV2.palette.geekBlue1};
    color: ${ThemeV2.palette.geekBlue6};
    border-radius: 4px;
  }
`;
