import {
  OptionItemProps,
  RadioOptionValueTypes,
} from '../../../../atomic/organism/FlowParticipationBuilderOptionsContent/types';
import { PopoverItemProps } from '../../../../atomic/organism/PopoverWithSubMenu/types';

export const optionItems: OptionItemProps[] = [
  {
    label: 'is',
    value: RadioOptionValueTypes.Is,
  },
  {
    label: 'is not',
    value: RadioOptionValueTypes.IsNot,
  },
];

export const popoverItems: PopoverItemProps[] = [
  {
    id: 'member',
    title: 'Member',
    icon: 'person-filled',
  },
  {
    id: 'email',
    title: 'Email',
    icon: 'mail',
  },
  {
    id: 'department',
    title: 'Department',
    icon: 'department',
  },
];

export const searchMemberInitialLoadLimit = 20;
