import {
  alpha,
  lighten,
  darken,
} from '@material-ui/core/styles/colorManipulator';
import ThemeV2 from '../../../componentsV2/theme';

const white = ThemeV2.palette.gray1;
const primary = ThemeV2.palette.geekBlue6;
const success = ThemeV2.palette.green7;
const warning = ThemeV2.palette.red6;
const info = ThemeV2.palette.gold6;

export const disabledStyle = {
  background: ThemeV2.palette.gray3,
  textColor: ThemeV2.palette.gray6,
  borderColor: ThemeV2.palette.gray3,
};

const showMoreDefault = {
  background: ThemeV2.palette.geekBlue6,
  textColor: ThemeV2.palette.gray1,
  borderColor: 'transparent',
  hover: {
    background: ThemeV2.palette.geekBlue6,
    textColor: ThemeV2.palette.gray1,
    borderColor: 'transparent',
  },
  active: {
    background: ThemeV2.palette.geekBlue6,
    textColor: ThemeV2.palette.gray1,
    borderColor: 'transparent',
  },
};

const showMore = {
  default: showMoreDefault,
  disabled: disabledStyle,
  success: showMoreDefault,
  warning: showMoreDefault,
  info: showMoreDefault,
};

export const iconButtonColors = {
  text: {
    default: {
      background: 'transparent',
      color: ThemeV2.palette.gray8,
    },
    hover: {
      background: ThemeV2.palette.geekBlue1,
      color: ThemeV2.palette.geekBlue6,
    },
    focus: {
      background: ThemeV2.palette.geekBlue2,
      color: ThemeV2.palette.geekBlue6,
    },
  },
  textWithBackground: {
    default: {
      background: ThemeV2.palette.gray3,
      color: ThemeV2.palette.gray8,
    },
    hover: {
      background: ThemeV2.palette.geekBlue1,
      color: ThemeV2.palette.geekBlue6,
    },
    focus: {
      background: ThemeV2.palette.geekBlue2,
      color: ThemeV2.palette.geekBlue6,
    },
  },
  primary: {
    default: {
      background: ThemeV2.palette.geekBlue6,
      color: ThemeV2.palette.gray1,
    },
    hover: {
      background: ThemeV2.palette.geekBlue5,
      color: ThemeV2.palette.gray1,
    },
    focus: {
      background: ThemeV2.palette.geekBlue7,
      color: ThemeV2.palette.gray1,
    },
  },
  secondary: {
    default: {
      background: ThemeV2.palette.gray1,
      color: ThemeV2.palette.geekBlue6,
    },
    hover: {
      background: ThemeV2.palette.gray1,
      color: ThemeV2.palette.geekBlue5,
    },
    focus: {
      background: ThemeV2.palette.gray1,
      color: ThemeV2.palette.geekBlue7,
    },
  },
  tertiary: {
    default: {
      background: ThemeV2.palette.gray1,
      color: ThemeV2.palette.gray8,
    },
    hover: {
      background: ThemeV2.palette.gray3,
      color: ThemeV2.palette.gray8,
    },
    focus: {
      background: ThemeV2.palette.gray3,
      color: ThemeV2.palette.gray9,
    },
  },
  selectedTertiary: {
    default: {
      background: ThemeV2.palette.gray3,
      color: ThemeV2.palette.gray9,
    },
    hover: {
      background: ThemeV2.palette.gray3,
      color: ThemeV2.palette.gray9,
    },
    focus: {
      background: ThemeV2.palette.gray3,
      color: ThemeV2.palette.gray9,
    },
  },
  iconWithoutBackground: {
    default: {
      background: 'transparent',
      color: ThemeV2.palette.dustRed6,
    },
    hover: {
      background: 'transparent',
      color: ThemeV2.palette.dustRed6,
    },
    focus: {
      background: 'transparent',
      color: ThemeV2.palette.dustRed6,
    },
  },
};

export const buttonColors = {
  primary: {
    contained: {
      default: {
        background: primary,
        textColor: white,
        borderColor: primary,
        hover: {
          background: ThemeV2.palette.geekBlue5,
          textColor: white,
          borderColor: ThemeV2.palette.geekBlue5,
        },
        active: {
          background: ThemeV2.palette.geekBlue7,
          textColor: white,
          borderColor: ThemeV2.palette.geekBlue7,
        },
      },
      success: {
        background: success,
        textColor: white,
        borderColor: success,
        hover: {
          background: lighten(success, 0.1),
          textColor: white,
          borderColor: lighten(success, 0.1),
        },
        active: {
          background: darken(success, 0.1),
          textColor: white,
          borderColor: darken(success, 0.1),
        },
      },
      warning: {
        background: warning,
        textColor: white,
        borderColor: warning,
        hover: {
          background: lighten(warning, 0.1),
          textColor: white,
          borderColor: lighten(warning, 0.1),
        },
        active: {
          background: darken(warning, 0.1),
          textColor: white,
          borderColor: darken(warning, 0.1),
        },
      },
      info: {
        background: info,
        textColor: white,
        borderColor: info,
        hover: {
          background: lighten(info, 0.1),
          textColor: white,
          borderColor: lighten(info, 0.1),
        },
        active: {
          background: darken(info, 0.1),
          textColor: white,
          borderColor: darken(info, 0.1),
        },
      },
      disabled: disabledStyle,
    },
    outlined: {
      default: {
        background: 'transparent',
        textColor: primary,
        borderColor: primary,
        hover: {
          background: 'transparent',
          textColor: ThemeV2.palette.geekBlue5,
          borderColor: ThemeV2.palette.geekBlue5,
        },
        active: {
          background: 'transparent',
          textColor: ThemeV2.palette.geekBlue7,
          borderColor: ThemeV2.palette.geekBlue7,
        },
      },
      success: {
        background: 'transparent',
        textColor: success,
        borderColor: success,
        hover: {
          background: 'transparent',
          textColor: lighten(success, 0.1),
          borderColor: lighten(success, 0.1),
        },
        active: {
          background: 'transparent',
          textColor: darken(success, 0.1),
          borderColor: darken(success, 0.1),
        },
      },
      warning: {
        background: 'transparent',
        textColor: warning,
        borderColor: warning,
        hover: {
          background: 'transparent',
          textColor: lighten(warning, 0.1),
          borderColor: lighten(warning, 0.1),
        },
        active: {
          background: 'transparent',
          textColor: darken(warning, 0.1),
          borderColor: darken(warning, 0.1),
        },
      },
      info: {
        background: 'transparent',
        textColor: info,
        borderColor: info,
        hover: {
          background: 'transparent',
          textColor: lighten(info, 0.1),
          borderColor: lighten(info, 0.1),
        },
        active: {
          background: 'transparent',
          textColor: darken(info, 0.1),
          borderColor: darken(info, 0.1),
        },
      },
      disabled: disabledStyle,
    },
    text: {
      default: {
        background: 'transparent',
        textColor: primary,
        borderColor: 'transparent',
        hover: {
          background: ThemeV2.palette.gray3,
          textColor: ThemeV2.palette.geekBlue5,
          borderColor: ThemeV2.palette.gray3,
        },
        active: {
          background: ThemeV2.palette.gray3,
          textColor: ThemeV2.palette.geekBlue7,
          borderColor: ThemeV2.palette.gray3,
        },
      },
      success: {
        background: 'transparent',
        textColor: success,
        borderColor: 'transparent',
        hover: {
          background: alpha(success, 0.05),
          textColor: success,
          borderColor: 'transparent',
        },
        active: {
          background: alpha(success, 0.1),
          textColor: success,
          borderColor: 'transparent',
        },
      },
      warning: {
        background: 'transparent',
        textColor: warning,
        borderColor: 'transparent',
        hover: {
          background: alpha(warning, 0.05),
          textColor: warning,
          borderColor: 'transparent',
        },
        active: {
          background: alpha(warning, 0.1),
          textColor: warning,
          borderColor: 'transparent',
        },
      },
      info: {
        background: 'transparent',
        textColor: info,
        borderColor: 'transparent',
        hover: {
          background: alpha(info, 0.05),
          textColor: info,
          borderColor: 'transparent',
        },
        active: {
          background: alpha(info, 0.1),
          textColor: info,
          borderColor: 'transparent',
        },
      },
      disabled: {
        background: 'transparent',
        textColor: ThemeV2.palette.gray6,
        borderColor: 'transparent',
      },
    },
    golden: {
      default: {
        background: ThemeV2.palette.gold6,
        textColor: ThemeV2.palette.gray1,
        borderColor: ThemeV2.palette.gold6,
        hover: {
          background: ThemeV2.palette.gold6,
          textColor: ThemeV2.palette.gray1,
          borderColor: ThemeV2.palette.gold6,
        },
        active: {
          background: ThemeV2.palette.gold6,
          textColor: ThemeV2.palette.gray1,
          borderColor: ThemeV2.palette.gold6,
        },
      },
      success: {
        background: ThemeV2.palette.gold6,
        textColor: ThemeV2.palette.gray1,
        borderColor: ThemeV2.palette.gold6,
        hover: {
          background: lighten(ThemeV2.palette.gold6, 0.1),
          textColor: ThemeV2.palette.gray1,
          borderColor: lighten(ThemeV2.palette.gold6, 0.1),
        },
        active: {
          background: darken(ThemeV2.palette.gold6, 0.1),
          textColor: ThemeV2.palette.gray1,
          borderColor: darken(ThemeV2.palette.gold6, 0.1),
        },
      },
      warning: {
        background: warning,
        textColor: white,
        borderColor: warning,
        hover: {
          background: lighten(warning, 0.1),
          textColor: white,
          borderColor: lighten(warning, 0.1),
        },
        active: {
          background: darken(warning, 0.1),
          textColor: white,
          borderColor: darken(warning, 0.1),
        },
      },
      info: {
        background: info,
        textColor: white,
        borderColor: info,
        hover: {
          background: lighten(info, 0.1),
          textColor: white,
          borderColor: lighten(info, 0.1),
        },
        active: {
          background: darken(info, 0.1),
          textColor: white,
          borderColor: darken(info, 0.1),
        },
      },
      disabled: disabledStyle,
    },
    showMore,
  },
  secondary: {
    contained: {
      default: {
        background: white,
        textColor: primary,
        borderColor: white,
        hover: {
          background: white,
          textColor: ThemeV2.palette.geekBlue5,
          borderColor: white,
        },
        active: {
          background: white,
          textColor: ThemeV2.palette.geekBlue7,
          borderColor: white,
        },
      },
      success: {
        background: white,
        textColor: success,
        borderColor: white,
        hover: {
          background: white,
          textColor: lighten(success, 0.1),
          borderColor: white,
        },
        active: {
          background: white,
          textColor: darken(success, 0.1),
          borderColor: white,
        },
      },
      warning: {
        background: white,
        textColor: warning,
        borderColor: white,
        hover: {
          background: white,
          textColor: lighten(warning, 0.1),
          borderColor: white,
        },
        active: {
          background: white,
          textColor: darken(warning, 0.1),
          borderColor: white,
        },
      },
      info: {
        background: white,
        textColor: info,
        borderColor: white,
        hover: {
          background: white,
          textColor: lighten(info, 0.1),
          borderColor: white,
        },
        active: {
          background: white,
          textColor: darken(info, 0.1),
          borderColor: white,
        },
      },
      disabled: disabledStyle,
    },
    outlined: {
      default: {
        background: disabledStyle.background,
        textColor: disabledStyle.textColor,
        borderColor: disabledStyle.borderColor,
        hover: disabledStyle,
        active: disabledStyle,
      },
      success: {
        background: disabledStyle.background,
        textColor: disabledStyle.textColor,
        borderColor: disabledStyle.borderColor,
        hover: disabledStyle,
        active: disabledStyle,
      },
      warning: {
        background: disabledStyle.background,
        textColor: disabledStyle.textColor,
        borderColor: disabledStyle.borderColor,
        hover: disabledStyle,
        active: disabledStyle,
      },
      info: {
        background: disabledStyle.background,
        textColor: disabledStyle.textColor,
        borderColor: disabledStyle.borderColor,
        hover: disabledStyle,
        active: disabledStyle,
      },
      disabled: disabledStyle,
    },
    text: {
      default: {
        background: 'transparent',
        textColor: ThemeV2.palette.gray8,
        borderColor: 'transparent',
        hover: {
          background: ThemeV2.palette.gray3,
          textColor: ThemeV2.palette.gray8,
          borderColor: ThemeV2.palette.gray3,
        },
        active: {
          background: ThemeV2.palette.gray3,
          textColor: ThemeV2.palette.gray9,
          borderColor: ThemeV2.palette.gray3,
        },
      },
      success: {
        background: 'transparent',
        textColor: success,
        borderColor: 'transparent',
        hover: {
          background: alpha(success, 0.05),
          textColor: success,
          borderColor: 'transparent',
        },
        active: {
          background: alpha(success, 0.1),
          textColor: success,
          borderColor: 'transparent',
        },
      },
      warning: {
        background: 'transparent',
        textColor: warning,
        borderColor: 'transparent',
        hover: {
          background: alpha(warning, 0.05),
          textColor: warning,
          borderColor: 'transparent',
        },
        active: {
          background: alpha(warning, 0.1),
          textColor: warning,
          borderColor: 'transparent',
        },
      },
      info: {
        background: 'transparent',
        textColor: info,
        borderColor: 'transparent',
        hover: {
          background: alpha(info, 0.05),
          textColor: info,
          borderColor: 'transparent',
        },
        active: {
          background: alpha(info, 0.1),
          textColor: info,
          borderColor: 'transparent',
        },
      },
      disabled: {
        background: 'transparent',
        textColor: ThemeV2.palette.gray6,
        borderColor: 'transparent',
      },
    },
    golden: {
      default: {
        background: ThemeV2.palette.gold6,
        textColor: ThemeV2.palette.gray1,
        borderColor: ThemeV2.palette.gold6,
        hover: {
          background: ThemeV2.palette.gold6,
          textColor: ThemeV2.palette.gray1,
          borderColor: ThemeV2.palette.gold6,
        },
        active: {
          background: ThemeV2.palette.gold6,
          textColor: ThemeV2.palette.gray1,
          borderColor: ThemeV2.palette.gold6,
        },
      },
      success: {
        background: ThemeV2.palette.gold6,
        textColor: ThemeV2.palette.gray1,
        borderColor: ThemeV2.palette.gold6,
        hover: {
          background: lighten(ThemeV2.palette.gold6, 0.1),
          textColor: ThemeV2.palette.gray1,
          borderColor: lighten(ThemeV2.palette.gold6, 0.1),
        },
        active: {
          background: darken(ThemeV2.palette.gold6, 0.1),
          textColor: ThemeV2.palette.gray1,
          borderColor: darken(ThemeV2.palette.gold6, 0.1),
        },
      },
      warning: {
        background: warning,
        textColor: white,
        borderColor: warning,
        hover: {
          background: lighten(warning, 0.1),
          textColor: white,
          borderColor: lighten(warning, 0.1),
        },
        active: {
          background: darken(warning, 0.1),
          textColor: white,
          borderColor: darken(warning, 0.1),
        },
      },
      info: {
        background: info,
        textColor: white,
        borderColor: info,
        hover: {
          background: lighten(info, 0.1),
          textColor: white,
          borderColor: lighten(info, 0.1),
        },
        active: {
          background: darken(info, 0.1),
          textColor: white,
          borderColor: darken(info, 0.1),
        },
      },
      disabled: disabledStyle,
    },
    showMore,
  },
  secondary2: {
    contained: {
      default: {
        background: ThemeV2.palette.gray3,
        textColor: ThemeV2.palette.gray8,
        borderColor: 'transparent',
        hover: {
          background: ThemeV2.palette.gray4,
          textColor: ThemeV2.palette.gray8,
          borderColor: 'transparent',
        },
        active: {
          background: ThemeV2.palette.gray4,
          textColor: ThemeV2.palette.gray8,
          borderColor: 'transparent',
        },
      },
      success: {
        background: white,
        textColor: success,
        borderColor: white,
        hover: {
          background: white,
          textColor: lighten(success, 0.1),
          borderColor: white,
        },
        active: {
          background: white,
          textColor: darken(success, 0.1),
          borderColor: white,
        },
      },
      warning: {
        background: white,
        textColor: warning,
        borderColor: white,
        hover: {
          background: white,
          textColor: lighten(warning, 0.1),
          borderColor: white,
        },
        active: {
          background: white,
          textColor: darken(warning, 0.1),
          borderColor: white,
        },
      },
      info: {
        background: white,
        textColor: info,
        borderColor: white,
        hover: {
          background: white,
          textColor: lighten(info, 0.1),
          borderColor: white,
        },
        active: {
          background: white,
          textColor: darken(info, 0.1),
          borderColor: white,
        },
      },
      disabled: disabledStyle,
    },
    outlined: {
      default: {
        background: disabledStyle.background,
        textColor: disabledStyle.textColor,
        borderColor: disabledStyle.borderColor,
        hover: disabledStyle,
        active: disabledStyle,
      },
      success: {
        background: disabledStyle.background,
        textColor: disabledStyle.textColor,
        borderColor: disabledStyle.borderColor,
        hover: disabledStyle,
        active: disabledStyle,
      },
      warning: {
        background: disabledStyle.background,
        textColor: disabledStyle.textColor,
        borderColor: disabledStyle.borderColor,
        hover: disabledStyle,
        active: disabledStyle,
      },
      info: {
        background: disabledStyle.background,
        textColor: disabledStyle.textColor,
        borderColor: disabledStyle.borderColor,
        hover: disabledStyle,
        active: disabledStyle,
      },
      disabled: disabledStyle,
    },
    text: {
      default: {
        background: 'transparent',
        textColor: ThemeV2.palette.gray8,
        borderColor: 'transparent',
        hover: {
          background: ThemeV2.palette.gray3,
          textColor: ThemeV2.palette.gray8,
          borderColor: ThemeV2.palette.gray3,
        },
        active: {
          background: ThemeV2.palette.gray3,
          textColor: ThemeV2.palette.gray9,
          borderColor: ThemeV2.palette.gray3,
        },
      },
      success: {
        background: 'transparent',
        textColor: success,
        borderColor: 'transparent',
        hover: {
          background: alpha(success, 0.05),
          textColor: success,
          borderColor: 'transparent',
        },
        active: {
          background: alpha(success, 0.1),
          textColor: success,
          borderColor: 'transparent',
        },
      },
      warning: {
        background: 'transparent',
        textColor: warning,
        borderColor: 'transparent',
        hover: {
          background: alpha(warning, 0.05),
          textColor: warning,
          borderColor: 'transparent',
        },
        active: {
          background: alpha(warning, 0.1),
          textColor: warning,
          borderColor: 'transparent',
        },
      },
      info: {
        background: 'transparent',
        textColor: info,
        borderColor: 'transparent',
        hover: {
          background: alpha(info, 0.05),
          textColor: info,
          borderColor: 'transparent',
        },
        active: {
          background: alpha(info, 0.1),
          textColor: info,
          borderColor: 'transparent',
        },
      },
      disabled: {
        background: 'transparent',
        textColor: ThemeV2.palette.gray6,
        borderColor: 'transparent',
      },
    },
    golden: {
      default: {
        background: ThemeV2.palette.gold6,
        textColor: ThemeV2.palette.gray1,
        borderColor: ThemeV2.palette.gold6,
        hover: {
          background: ThemeV2.palette.gold6,
          textColor: ThemeV2.palette.gray1,
          borderColor: ThemeV2.palette.gold6,
        },
        active: {
          background: ThemeV2.palette.gold6,
          textColor: ThemeV2.palette.gray1,
          borderColor: ThemeV2.palette.gold6,
        },
      },
      success: {
        background: ThemeV2.palette.gold6,
        textColor: ThemeV2.palette.gray1,
        borderColor: ThemeV2.palette.gold6,
        hover: {
          background: lighten(ThemeV2.palette.gold6, 0.1),
          textColor: ThemeV2.palette.gray1,
          borderColor: lighten(ThemeV2.palette.gold6, 0.1),
        },
        active: {
          background: darken(ThemeV2.palette.gold6, 0.1),
          textColor: ThemeV2.palette.gray1,
          borderColor: darken(ThemeV2.palette.gold6, 0.1),
        },
      },
      warning: {
        background: warning,
        textColor: white,
        borderColor: warning,
        hover: {
          background: lighten(warning, 0.1),
          textColor: white,
          borderColor: lighten(warning, 0.1),
        },
        active: {
          background: darken(warning, 0.1),
          textColor: white,
          borderColor: darken(warning, 0.1),
        },
      },
      info: {
        background: info,
        textColor: white,
        borderColor: info,
        hover: {
          background: lighten(info, 0.1),
          textColor: white,
          borderColor: lighten(info, 0.1),
        },
        active: {
          background: darken(info, 0.1),
          textColor: white,
          borderColor: darken(info, 0.1),
        },
      },
      disabled: disabledStyle,
    },
    showMore,
  },
};

export const sizeVariant = {
  large: {
    height: '48px',
    padding: '12px 16px',
    font: 'body2',
  },
  medium: {
    height: '40px',
    padding: '8px 16px',
    font: 'body2',
  },
  small: {
    height: '32px',
    padding: '4px 16px',
    font: 'body2',
  },
};

export const FontWeight = {
  REGULAR: 400,
  SEMIBOLD: 500,
};
