import { APP_NAME } from '../../../Utils/constants';

export const CREATE_A_NEW_CULTURE_REWARD = 'Create a New Culture Reward';

export const UPGRADE_TO_CONNECT = 'Upgrade to connect';

export const SEE_ALL_INTEGRATIONS = 'See all Integrations';

export const GENERAL_SETTINGS = {
  COMPANY_NAME_LABEL: 'Company Name',
  PERMISSIONS_TITLE: 'Set who has permission to invite new people',
  PERMISSIONS_SUBTITLE: 'People who can invite new people',
  PERMISSIONS_HELPER_TEXT: 'Workspace owners & admins always have access.',
  INVITE_PERMISSIONS: 'Invite People Permissions',
  INVITE_PERMISSIONS_SUB_TITLE:
    'Configure access to who and how invites are sent.',
  ADMIN_APPROVAL_TITLE: 'Require admin approval for invites',
  ADMIN_APPROVAL_SUBTITLE:
    'If the person inviting new teammates is not an admin, do the invitees require admin approval?',
  UPGRADE_BUTTON_TEXT: 'Upgrade to edit',
  JOINING_ASSEMBLY: 'Joining This Assembly',
  FORM_LABEL_TEXT:
    'Allow anyone from a whitelisted domain below with the invite URL to join this Assembly on their own.',
  WHITELISTED_FLOATING_TEXT: 'Whitelisted domains for invite URL only:',
  // eslint-disable-next-line max-len
  WHITELISTED_SUB_TEXT: `Only people with emails matching these domains can join your ${APP_NAME} (e.g. work.com). Separate with commas.`,
  CANCEL_BUTTON_TEXT: 'Cancel',
  SAVE_BUTTON_TEXT: 'Save',
};

export const REWARDS_SWAGS = {
  HEADER_CARD_TEXT:
    'Teaming up to automate and streamline personalized company swag for your employees',
  CONNECT_AXOMOTEXT: 'Connect Axomo to your Assembly workspace',
  CREATE_OR_CONNECT: 'Create or connect an Axomo account',
  CREATE: 'Create your axomo account',
  CREATE_AXOMO_CONNECTION_DESCRIPTION:
    // eslint-disable-next-line max-len
    'Start by creating an account with Axomo and review below for how to get started. In order to connect, your email registered in Assembly must be also be registered as an Administrator within Axomo.',
  LINK_ACCOUNT_TO_ASSEMBLY: 'and link it to Assembly',
  GO_TO_AXOMO: 'Go to Axomo to create an account',
  EDIT_SHIPPING_IN_AXOMO: 'Edit shipping in Axomo',
  ENABLE_ASSEMBLY_PLUGIN: 'Enable the Assembly Plugin',
  ASSEMBLY_PLUGIN_NAVIGATION:
    'Go to Axomo admin > Store design > Plugins > Check off Assembly',
  OFFER_BRANDED_SWAG: 'Offer branded swag to your employees within assembly',
  AXOMO_CONNECTED: 'Connected',
  CONNECT_TO_AXOMO: 'Connect to Axomo',
  CONNECT_BY_INPUT: 'Connect by entering your Axomo subdomain',
  SUBDOMAIN_PLACEHOLDER: 'Your Axomo subdomain',
  API_KEY: 'Your API key',
  NEED_HELP: 'Need help?',
  WHAT_IS_MY_AXOMO_SUBDOMAIN: 'What is my Axomo subdomain?',
  WHAT_IS_MY_AXOMO_API_KEY: 'What is my Axomo API key?',
  GET_HELP: 'Get help',
  MANAGE_INVENTORY: 'Manage your inventory',
  TURN_ALL_ITEMS: 'Turn all items',
  EDIT_IN_AXOMO: 'Edit in Axomo',
  EDIT_CONNECTION: 'Edit Connection',
  SHOW_ITEM: 'Show Item',
  OUT_OF_STOCK: 'Out of Stock',
  COMING_SOON: 'Coming Soon',
  ACTIVE: 'Active',
  UPGRADE_PAYMENT: 'Upgrade to access',
  BREAK_CONNECTION: 'Disconnect Axomo',
  AXOMO_ALREADY_CONNECTED: 'Your axomo account is already connected',
  GOTO_AXOMO: 'Go to Axomo',
  AXOMO_CONNECTION_SUCCESS:
    'Connection successful! Your swag library is ready.',
  MANAGE_AXOMO_CONNECTION: 'Manage Axomo Connection',
  AXOMO_SWAG: 'Axomo Swag',
  MANAGE_AXOMO_CONNECTION_DESC:
    'Disconnecting will turn off your Swag rewards tab and remove all Swag inventory from this workspace.',
  SHOW_SWAGS_TOGGLE_TITLE: 'Show the swag tab in the rewards section',
  SHOW_SWAGS_TOGGLE_DESC:
    'Your swag tab is currently hidden, you can make edits and turn it back on here when you’re ready.',

  SHIPPING_COST_TAB_TITLE: 'Additional shipping costs',
  SHIPPING_COST_TAB_SUBTITLE:
    // eslint-disable-next-line max-len
    'If you would like members redeeming swag items to cover some of the shipping costs, set a flat rate here that will be added to the total cost of the swag items in your inventory below.',
  GET_SHIPPING_COST_TAB_DESC: (
    shippingCost: string,
    currencyName: string | undefined,
  ) =>
    ` ${
      shippingCost === '' ? 0 : shippingCost
    } added to cover shipping • Add a ${currencyName} increase to each item’s cost to cover shipping.`,
  INPUT_VALID_STORENAME: 'Please Input a valid storename',
  ENTER_CORRECT_STORENAME: 'Enter correct storename to connect again',
  VALID_USER: 'Invalid user',
  DISCONNECT: 'Disconnect',
  DISCONNECT_DESC:
    // eslint-disable-next-line max-len
    'Disconnecting Axomo will hide the Swag tab from your Rewards section, and you won’t see your inventory here in the admin section. If you no longer wish to connect your Assembly to Axomo, you will also need to disable the Assembly plugin in your Axomo account.',
  CANCEL: 'Cancel',
  ALREADY_CONNECTED: 'Your store is already connected!',
  NOT_CONNECTED_STORE: "You're not connected to an Axomo store.",
  GET_STARTED: 'Get started',
  AXOMO_CONNECTED_MESSAGE: 'Connected to Axomo Store Successfully',
  NOT_CONNECTED: 'Not connected',
  SERVER_ERROR:
    'Axomo has trouble connecting now. Please refresh the page or try again sometime later.',
  INVALID_AXOMO_EMAIL:
    'Your email in Assembly isn’t registered as an Admin in Axomo. You must register your email in Axomo to connect.',
  SWAG_TAB_NOT_TURNED_ON_TEXT:
    'Your swag tab is not turned on yet, switch the toggle above to show the swag to the rest of your workspace.',
  EMPTY_SWAGCARD_PLACEHOLDER: 'Your swag goes here',
  EMPTY_SWAGCARD_TITLE:
    'You don’t have any swag yet -- go to Axomo to get started!',
  NOT_CONNECTED_SWAG_TITLE:
    'You’re not connected to Axomo -- go to Axomo to get started!',
  EMPTY_SWAGCARD_DESCRIPTION:
    // eslint-disable-next-line max-len
    'Axomo offers a range of products for you to customize. Once you design and order your swag, you will be able to turn on the Swag tab in your workspace’s rewards section above. You’ll be able to edit the trophy cost and visibility here.',
  ERROR_SWAGCARD_PLACEHOLDER: 'Error loading swag',
  ERROR_SWAGCARD_TITLE: 'We had trouble loading your swag library',
  ERROR_SWAGCARD_DESCRIPTION: 'Please refresh to try again.',
  SWAG_NAME_CANNOT_CHANGE: 'The name of this reward is managed by Axomo',
  SWAG_DESC_CANNOT_CHANGE:
    'The description for this reward is managed by Axomo',
  INFO_MESSAGE:
    'Your swag tab is not turned on yet, switch the toggle above to show the swag to the rest of your workspace.',
  SAVE_CHANGES: 'Save changes',
  GET_SHIPPING_COST_PLACEHOLDER_TEXT: (currencyNamePlural: string) =>
    `Add additional ${currencyNamePlural}`,
  GET_SHIPPING_COST_HELPER_TEXT: (
    currencyName: string,
    shippingCost: number,
    exchangeRate: number,
  ) => `${shippingCost} ${currencyName} = $${shippingCost * exchangeRate} USD`,
  ALL_ITEMS_ON_TEXT: 'All items turned on to show in rewards',
  ALL_ITEMS_OFF_TEXT: 'All items turned off and are now hidden',
  ITEM_TURNED_ON_TEXT: 'Swag item turned on to show in rewards',
  ITEM_TURNED_OFF_TEXT: 'Swag item turned off and is now hidden',
  NEED_HELP_LINK: 'https://www.joinassembly.com/articles/set-up-axomo-swag',
  SHIPPING_COST_UPDATED: 'Shipping cost changed successfully!',
};

export const TRY_LATER = 'Please try after sometime';

export const REWARD_SWAGS_ERRORS = {
  SERVER_INFO_ERROR: `Server Error, ${TRY_LATER}`,
};

export const SINGLE_WORDS = {
  ON: 'On',
  OFF: 'Off',
};

export const ADD_A_FILTER = '+ Add a filter';
export const CLEAR_FILTERS = 'CLEAR FILTERS';
