import { useCallback, useMemo } from 'react';
import { MentionsProps } from '../../atomic/organism/RichTextEditor';
import { IMemberDTO } from '../../interfaces/member';
import { MemberState } from '../../interfaces/user';
import { NOTIFY_EVERY_FLOW_VIEWER } from '../../languages/en/feed';
import { EVERYONE } from '../../languages/en/singleWords';
import { useMembersSearch } from '../useMembersSearch';

const useAtMentions = (addEveryone = false, canFetchWhenEmpty = true) => {
  const {
    models: { searchedMembers, isLoading, value },
    operations: { onChange: onSearchChange },
  } = useMembersSearch(canFetchWhenEmpty);

  const handleMentionsChange = useCallback(
    ({ value: newValue }: { value: string }) => {
      onSearchChange(newValue);
    },
    [onSearchChange],
  );

  const suggestions = useMemo(() => {
    if (searchedMembers) {
      const modifiedArray = searchedMembers.map(
        ({
          memberID,
          firstName,
          lastName,
          image,
          memberState,
        }: IMemberDTO): MentionsProps => ({
          id: memberID,
          name: `${firstName} ${lastName}`,
          link: '',
          avatar: image || '',
          memberState,
        }),
      );
      if (
        addEveryone &&
        (value === '' || 'everyone'.includes(value.toLowerCase()))
      ) {
        modifiedArray.unshift({
          id: 'everyone',
          name: EVERYONE,
          link: '',
          avatar: '',
          icon: 'atmention',
          info: `• ${NOTIFY_EVERY_FLOW_VIEWER}`,
          memberState: MemberState.ACTIVE,
        });
      }
      return modifiedArray;
    }
    return [];
  }, [addEveryone, searchedMembers, value]);

  return { suggestions, isLoading, onMentionsChange: handleMentionsChange };
};

export default useAtMentions;
