import styled from 'styled-components';
import { Flex } from '../../../Utils/styles/display';
import Input from '../Input';

export const ModalContentWrapper = styled.div`
  height: 456px;
  overflow-y: scroll;
`;

export const SearchFieldWrapper = styled.div`
  padding: 12px;
`;

export const SearchInput = styled(Input)`
  margin: 12px;
`;
SearchInput.displayName = 'SearchInput';

export const SearchResults = styled.div`
  padding: 8px;
`;

export const UserListItem = styled.li`
  list-style: none;
  margin-bottom: 16px;
`;

export const NoOptionsTextWrapper = styled(Flex)`
  margin: 16px 24px 0;
`;
