import { useQuery } from 'react-query';

import { GET_MEMBER_RECOGNITION_COUNT } from '../../../constants/endpoints';
import { makeAPICallWithDataReturn } from '../../utils';
import { MemberRecognitionCount } from '../../../interfaces/Feed';

interface MemberRecognitionCountResponse {
  totalRecognitionGiven: number;
  totalRecognitionReceived: number;
}

const serializeMemberRecognitionCountResponse = (
  response: MemberRecognitionCountResponse,
): MemberRecognitionCount => {
  return {
    timesGiven: response.totalRecognitionGiven,
    timesReceived: response.totalRecognitionReceived,
  };
};

export const useMemberRecognitionQuery = (memberId: string) => {
  const queryKey = [GET_MEMBER_RECOGNITION_COUNT, memberId];
  const payload = null;
  const params = { memberId };

  return useQuery(
    queryKey,
    () =>
      makeAPICallWithDataReturn(GET_MEMBER_RECOGNITION_COUNT, payload, params),
    {
      staleTime: Infinity,
      select: (res: MemberRecognitionCountResponse) =>
        serializeMemberRecognitionCountResponse(res),
    },
  );
};
