import React, { useMemo, useCallback } from 'react';
import styled from 'styled-components';

import Body, { TextAlignType } from '../../atoms/Body';
import Heading from '../../atoms/Heading';
import BasicModalTemplate from '../../templates/BasicModalTemplate';
import { OK, CANCEL } from '../../../languages/en/singleWords';
import { ButtonStatuses } from '../../atoms/Button/interfaces';

const ContentWrapper = styled.div`
  text-align: center;

  & > h5 {
    margin: 0;
    margin-bottom: 8px;
  }
`;

export interface SimplePromptModalProps {
  isOpen: boolean;
  onModalClose: () => void;
  heading: string;
  mainContent?: string;
  subContent?: string;
  successButtonText?: string;
  cancelButtonText?: string;
  onSuccess?: () => void;
  onCancel?: () => void;
  mainContentTextAlign?: TextAlignType;
  primaryButtonStatus?: ButtonStatuses;
  subContentTextAlign?: TextAlignType;
  subContentColor?: string;
}

const SimplePromptModal = ({
  isOpen,
  onModalClose,
  mainContent,
  subContent,
  heading,
  successButtonText,
  cancelButtonText,
  onCancel,
  onSuccess,
  mainContentTextAlign,
  primaryButtonStatus,
  subContentTextAlign,
  subContentColor,
}: SimplePromptModalProps) => {
  const handleModalClose = useCallback(() => {
    if (onCancel) {
      onCancel();
    }
    onModalClose();
  }, [onCancel, onModalClose]);
  const handleSuccessClick = useCallback(() => {
    if (onSuccess) {
      onSuccess();
    }
    onModalClose();
  }, [onSuccess, onModalClose]);
  const primaryButton = useMemo(
    () => ({
      text: successButtonText || OK,
      isLoading: false,
      onClick: handleSuccessClick,
      status: primaryButtonStatus,
    }),
    [successButtonText, primaryButtonStatus, handleSuccessClick],
  );
  const secondaryButton = useMemo(
    () => ({
      text: cancelButtonText || CANCEL,
      disabled: false,
      onClick: handleModalClose,
    }),
    [cancelButtonText, handleModalClose],
  );

  return (
    <BasicModalTemplate
      isOpen={isOpen}
      heading={heading}
      primaryButton={primaryButton}
      secondaryButton={secondaryButton}
      onModalClose={handleModalClose}
    >
      <ContentWrapper>
        {mainContent && (
          <Heading variant="h5" textAlign={mainContentTextAlign}>
            {mainContent}
          </Heading>
        )}
        {subContent && (
          <Body
            variant="body2"
            textAlign={subContentTextAlign}
            color={subContentColor}
          >
            {subContent}
          </Body>
        )}
      </ContentWrapper>
    </BasicModalTemplate>
  );
};

export default SimplePromptModal;
