import React, { useMemo, memo, useCallback } from 'react';

import { getAgoTime } from '../../../Utils/time';
import Avatar from '../../atoms/Avatar';
import Body from '../../atoms/Body';
import { Flex } from '../../../Utils/styles/display';
import { FlowsPostHeaderProps } from './interfaces';
import {
  StyledClickableLink,
  StyledFlowsPostHeaderContent,
  StyledVisibilityIcon,
} from './styles';
import ThemeV2 from '../../../componentsV2/theme';
import { FLOWS_FEED_POST_TYPE_TEXT } from '../../../languages/en/flows';
import ClickableLink from '../ClickableLink';
import { A, ANONYMOUS, MESSAGE, SENT } from '../../../languages/en/singleWords';
import {
  getVisibilityIcon,
  getVisibilityTooltipText,
} from '../../../Utils/flows';
import { PostTypes } from '../../../interfaces/Home';
import {
  ANNIVERSARY_TEXT,
  BIRTHDAY_TEXT,
  THE_ASSEMBLY,
} from '../../../languages/en/feed';
import { DEACTIVATED_USER } from '../../../languages/en/notifications';
import birthdayIcon from '../../atoms/SVGIcon/icons/birthday.svg';
import anniversaryIcon from '../../atoms/SVGIcon/icons/anniversary.svg';
import assemblyLogo from '../../atoms/SVGIcon/icons/assemblyLogo.svg';
import { mapHexCodeToEmoticon } from '../../../Utils/mappers';
import { YOU } from '../../../languages/en/home';
import { MemberState } from '../../../interfaces/user';
import { FeedItemKind } from '../../../interfaces/Feed';

const FlowsPostHeader = (props: FlowsPostHeaderProps) => {
  const {
    flow,
    person,
    postType,
    createdAt,
    visibility,
    onFlowClick,
    currentUserId,
    handleOnClickUserName,
    dropdownComponent,
    kind,
  } = props;

  const isAnonymous = useMemo(
    () =>
      kind &&
      (kind === FeedItemKind.EXTERNAL_ANONYMOUS ||
        kind === FeedItemKind.INTERNAL_ANONYMOUS),
    [kind],
  );

  const isDefaultPost = postType === PostTypes.DEFAULT;

  const handleFlowClick = useCallback(
    () => onFlowClick(flow.flowId),
    [flow.flowId, onFlowClick],
  );

  const renderPerson = useMemo(() => {
    if (isAnonymous) {
      return <Body variant="body2Medium">{ANONYMOUS}</Body>;
    }
    if (postType === PostTypes.DEFAULT) {
      return (
        <ClickableLink data={flow} onClickCallback={handleFlowClick}>
          {THE_ASSEMBLY}
        </ClickableLink>
      );
    }
    if (person) {
      return (
        <>
          {person.memberState === MemberState.DEACTIVATED ? (
            <Body variant="body2Medium" color="gray6">
              {DEACTIVATED_USER}
            </Body>
          ) : (
            <ClickableLink
              data={person}
              onClickCallback={() =>
                handleOnClickUserName({
                  memberID: person.memberID,
                  firstName: person.firstName,
                })
              }
            >
              {person.memberID === currentUserId ? 'You' : person.firstName}
            </ClickableLink>
          )}
        </>
      );
    }

    if (postType === PostTypes.BIRTHDAY) {
      return (
        <Body variant="body2Bold" color="gray8" inline>
          {BIRTHDAY_TEXT}
        </Body>
      );
    }

    if (postType === PostTypes.ANNIVERSARY) {
      return (
        <Body variant="body2Bold" color="gray8" inline>
          {ANNIVERSARY_TEXT}
        </Body>
      );
    }

    return <Body variant="body2Medium">{ANONYMOUS}</Body>;
  }, [
    isAnonymous,
    currentUserId,
    flow,
    handleOnClickUserName,
    handleFlowClick,
    person,
    postType,
  ]);

  const renderAvatar = useMemo(() => {
    if (isAnonymous) {
      return <Avatar size="40px" fontSize="16px" iconSize="40px" isAnonymous />;
    }
    if (postType === PostTypes.DEFAULT) {
      return (
        <Avatar
          size="40px"
          fontSize="16px"
          img={assemblyLogo}
          name="assembly"
        />
      );
    }
    if (person) {
      return (
        <Avatar
          size="40px"
          fontSize="16px"
          img={(person && person.image) || ''}
          name={(person && person.firstName) || ''}
          userId={(person && person.memberID) || ''}
          isDeleted={person.memberState === MemberState.DEACTIVATED}
        />
      );
    }

    if (postType === PostTypes.BIRTHDAY) {
      return (
        <Avatar
          size="40px"
          fontSize="16px"
          img={birthdayIcon}
          name={BIRTHDAY_TEXT}
        />
      );
    }

    if (postType === PostTypes.ANNIVERSARY) {
      return (
        <Avatar
          size="40px"
          fontSize="16px"
          img={anniversaryIcon}
          name={ANNIVERSARY_TEXT}
        />
      );
    }

    return null;
  }, [isAnonymous, person, postType]);

  return (
    <Flex padding="16px 16px 0" alignItems="start">
      {renderAvatar}
      <StyledFlowsPostHeaderContent>
        <Flex flexWrap="wrap">
          {renderPerson}
          <Body variant="body2" color="gray8">
            &nbsp;{isDefaultPost ? SENT : FLOWS_FEED_POST_TYPE_TEXT}
            &nbsp;
          </Body>
          {isDefaultPost ? (
            <>
              <ClickableLink
                onClickCallback={() =>
                  person &&
                  handleOnClickUserName({
                    memberID: person.memberID,
                    firstName: person.firstName,
                  })
                }
              >
                {YOU}
              </ClickableLink>
              <Body variant="body2" color="gray8">
                &nbsp;{A} {MESSAGE}.
              </Body>
            </>
          ) : (
            <>
              <span>{mapHexCodeToEmoticon(flow.icon.value)}</span>
              <StyledClickableLink
                data={flow}
                onClickCallback={handleFlowClick}
              >
                {flow.name}
              </StyledClickableLink>
            </>
          )}
        </Flex>
        <Flex flexWrap="wrap">
          <Body inline variant="body3" color="gray7">
            {getAgoTime(createdAt)}
          </Body>
          {visibility && (
            <StyledVisibilityIcon
              icon={getVisibilityIcon(visibility)}
              iconColor={ThemeV2.palette.gray7}
              iconSize="16px"
            >
              {getVisibilityTooltipText(visibility)}
            </StyledVisibilityIcon>
          )}
        </Flex>
      </StyledFlowsPostHeaderContent>
      {dropdownComponent}
    </Flex>
  );
};

export default memo(FlowsPostHeader);
