import React, { memo, useCallback } from 'react';
import Collapse from '@material-ui/core/Collapse';
import { AccordionItemProps } from './interface';
import ThemeV2 from '../../../componentsV2/theme';
import {
  ItemRoot,
  StyledSVGIcon,
  StyledButton,
  AccordionItemInner,
  StyledBodyItemTitle,
  AccordionContent,
  StyledSVGIconItemLock,
  StyledSVGIconContentLock,
  StyledBodyPlaceholderValue,
  StyledBodyItemNoValue,
  StyledAvatar,
  ItemNoValueContentWrapper,
  StyledEmptyDiv,
  StyledAccordionContentChildren,
  StyledWrapper,
} from './styles';
import { CLOSE, EDIT } from '../../../languages/en/singleWords';
import { SETTINGS_ADD_YOUR_FIELD } from '../../../languages/en/settings';

const AccordionItem = (props: AccordionItemProps) => {
  const {
    itemTitle,
    children,
    itemIcon,
    readOnly,
    itemHasReadOnlyField,
    placeholderValue,
    avatarImage,
    itemHasNoValue,
    isCollapseOpen,
    handleCollapse,
    handleKeypress,
  } = props;
  const toggleCollapse = useCallback(() => {
    if (handleCollapse) {
      handleCollapse(itemTitle);
    }
  }, [handleCollapse, itemTitle]);
  return (
    <ItemRoot>
      <AccordionItemInner
        isCollapseOpen={isCollapseOpen}
        itemHasNoValue={itemHasNoValue}
      >
        {itemHasNoValue ? (
          <ItemNoValueContentWrapper
            type="button"
            tabIndex={0}
            onClick={toggleCollapse}
            onKeyPress={handleKeypress}
          >
            <StyledBodyItemNoValue variant="body2">
              {`+ ${SETTINGS_ADD_YOUR_FIELD} ${itemTitle}`}
            </StyledBodyItemNoValue>
          </ItemNoValueContentWrapper>
        ) : (
          <>
            {itemIcon && <StyledSVGIcon icon={itemIcon} size="20px" />}
            <StyledBodyItemTitle variant="body2Bold">
              {itemTitle}
            </StyledBodyItemTitle>
            {avatarImage && (
              <StyledAvatar img={avatarImage} name="profile" size="32px" />
            )}
            {placeholderValue && (
              <StyledBodyPlaceholderValue variant="body2Medium">
                {placeholderValue}
              </StyledBodyPlaceholderValue>
            )}
            {itemHasReadOnlyField && (
              <StyledSVGIconContentLock
                icon="lock"
                size="16px"
                color={ThemeV2.palette.gray6}
              />
            )}
            {!readOnly ? (
              <StyledButton
                variant="text"
                onClick={toggleCollapse}
                size="small"
              >
                {isCollapseOpen ? CLOSE : EDIT}
              </StyledButton>
            ) : (
              <StyledSVGIconItemLock
                icon="lock"
                size="16px"
                color={ThemeV2.palette.gray6}
              />
            )}
          </>
        )}
      </AccordionItemInner>
      {!readOnly && (
        <Collapse in={isCollapseOpen}>
          <AccordionContent
            placeholderValue={
              !!(placeholderValue && placeholderValue !== '') ||
              !!(avatarImage && avatarImage !== '')
            }
          >
            {isCollapseOpen && (
              <>
                <StyledEmptyDiv />
                <StyledWrapper>
                  <StyledAccordionContentChildren>
                    {children}
                  </StyledAccordionContentChildren>
                </StyledWrapper>
              </>
            )}
          </AccordionContent>
        </Collapse>
      )}
    </ItemRoot>
  );
};

const MemoizedAccordionItem = memo(AccordionItem);
MemoizedAccordionItem.displayName = 'AccordionItem';
export default MemoizedAccordionItem;
