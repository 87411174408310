import React from 'react';
import Body from '../../atoms/Body';

import { FeedPostBodyProps } from './interface';
import {
  FeedPostBodyRoot,
  FeedPostCoreValue,
  FeedPostMessage,
  FeedPostImage,
} from './styles';

const FeedPostBody = (props: FeedPostBodyProps) => {
  const { coreValue, messageText, image } = props;
  return (
    <FeedPostBodyRoot>
      {coreValue && (
        <FeedPostCoreValue
          icon="star"
          iconSize="12px"
          paddingTopBottom="4px"
          paddingLeftRight="8px"
        >
          <Body variant="body3">Core Value: {coreValue}</Body>
        </FeedPostCoreValue>
      )}
      <FeedPostMessage variant="body2">{messageText}</FeedPostMessage>
      {image && <FeedPostImage src={image} lazyload />}
    </FeedPostBodyRoot>
  );
};

export default FeedPostBody;
