import React from 'react';

import FeedPost from '../../../atomic/organism/FeedPost';
import usePostControllerLogic, {
  PostControllerProps,
} from './usePostControllerLogic';

const PostController = (props: PostControllerProps) => {
  const {
    feedPostHeaderProps,
    feedPostBodyProps,
    feedPostReactionsProps,
    feedPostCommentsProps,
    feedCommentInputProps,
  } = usePostControllerLogic(props);

  return (
    <FeedPost
      feedPostHeaderProps={feedPostHeaderProps}
      feedPostBodyProps={feedPostBodyProps}
      feedPostReactionsProps={feedPostReactionsProps}
      feedPostCommentsProps={feedPostCommentsProps}
      feedCommentInputProps={feedCommentInputProps}
    />
  );
};

export default React.memo(PostController);
