import { TaskCategories } from '../../../../interfaces/notebook';
import { capitalizeFirstLetter } from '../../../../Utils/text';
import {
  MenuItemIndividualItem,
  MenuItemProps,
} from '../../Dropdown_V2/interfaces';

const dropdownItems: MenuItemIndividualItem[] = [
  {
    id: TaskCategories.COMPLETED,
    value: capitalizeFirstLetter(TaskCategories.COMPLETED),
  },
  {
    id: TaskCategories.TODAY,
    value: capitalizeFirstLetter(TaskCategories.TODAY),
  },
  {
    id: TaskCategories.UPCOMING,
    value: capitalizeFirstLetter(TaskCategories.UPCOMING),
  },
  {
    id: TaskCategories.UNSCHEDULED,
    value: capitalizeFirstLetter(TaskCategories.UNSCHEDULED),
  },
  {
    id: TaskCategories.OVERDUE,
    value: capitalizeFirstLetter(TaskCategories.OVERDUE),
  },
];

export const dropdownMenu: MenuItemProps[] = [
  {
    id: 'notebook-confirmation',
    items: dropdownItems,
  },
];
