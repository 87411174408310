import styled from 'styled-components';

import Body from '../../atoms/Body';
import GifContainer from '../../molecules/GifContainer';
import GiveRecognitionFormBottomButtons from '../../molecules/GiveRecognitionFormBottomButtons';
import { PostType } from '../../molecules/GiveRecognitionFormBottomButtons/interface';
import { StyledProps } from './interface';

import ThemeV2 from '../../../componentsV2/theme';
import GiveRecognitionTips from '../../molecules/GiveRecognitionTips';
import { zIndexButton } from '../../../Utils/styles/z-index';
import IconButton from '../../atoms/IconButton_V2';

export const StyledFormButtons = styled(GiveRecognitionFormBottomButtons)`
  padding: 8px 0px 0px 0px;
`;

export const CommentWrapper = styled.div<StyledProps>`
  border-radius: 20px;
  border: ${({ postType }) =>
    postType === PostType.Comment ? `1px solid ${ThemeV2.palette.gray5}` : '0'};
  padding: ${({ postType, isEditorFocused, hasText, hasAttachment }) => {
    if (postType === PostType.Comment) {
      return isEditorFocused || hasText || hasAttachment ? '4px' : '4px';
    }
    return '0';
  }};
  transition: 0.2s ease;
`;

export const StyledGifContainer = styled(GifContainer)`
  margin-bottom: 8px;
`;

export const StyledBody = styled(Body)`
  padding-left: 8px;
`;

export const StyledGiveRecognitionTips = styled(GiveRecognitionTips)`
  margin-top: 16px;
`;

export const RecognitionSectionWrapper = styled.div``;

export const RecognitionEditorWrapper = styled.div`
  min-height: 200px;
  cursor: text;
`;

export const InputErrorMessageBody = styled(Body)`
  padding: 0 16px;
`;

export const StyledIconButton = styled(IconButton)`
  z-index: ${zIndexButton};
`;
