/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-param-reassign */
import { EmojiData } from 'emoji-mart';
import React, { useState, useCallback, useRef, useEffect } from 'react';
import Editor from '@draft-js-plugins/editor';
import { EditorState } from 'draft-js';
import produce from 'immer';

import { Reaction } from '../../../interfaces/Feed';

import FlowFeedPost from '../FlowFeedPost';
import { DummyFlowFeedPostProps } from './types';
import { getLiteralTextFromEditor } from '../../../Utils/draftjs/mention';
import { CommentProps } from '../Comment/interfaces';
import { PostInputProps } from '../PostInput';
import { AssemblyCurrencyType } from '../../../interfaces/assembly';
import { MenuItemProps } from '../../molecules/Dropdown_V2/interfaces';
import { MentionsProps } from '../RichTextEditor';
import { PostType } from '../../molecules/GiveRecognitionFormBottomButtons/interface';
import { toggleReactionInReactions, updateReactionsWithEmoji } from './utils';
import styled from 'styled-components';
import FlowsPostHeaderOption from '../FlowsPostHeaderOption';
import { menuItemsWithCopyLink } from '../FlowFeedPost/data';

const DropdownContainer = styled.div`
  margin-left: auto;
`;

const assemblyCurrency = {
  name: 'Currency',
  pluralName: 'Currencies',
  type: AssemblyCurrencyType.REGULAR,
};

const menuItems: MenuItemProps[] = [];
const suggestions: MentionsProps[] = [];

const DummyFlowFeedPost = (props: DummyFlowFeedPostProps) => {
  const { reactionBarProps, commentsProps, currentUser, ...rest } = props;
  const [reactions, setReactions] = useState(props.reactionBarProps.reactions);
  const containerRef = useRef<HTMLDivElement>(null);
  const editorRef = useRef<Editor>(null);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [comments, setComments] = useState(commentsProps.feedCommentProps);
  const commentsRef = useRef(comments);
  commentsRef.current = comments;
  const [hasText, setHasText] = useState(false);
  const [isEditorFocused] = useState(false);

  const dummyFunction = () => {};
  const onMemberClick = dummyFunction;
  const onDeletePostSelectedOption = dummyFunction;
  const onDeleteModalSubmitClick = dummyFunction;
  const onCopyLinkClick = dummyFunction;
  const onUserNameClick = dummyFunction;
  const onFlowClick = dummyFunction;
  const onCommentTextFocus = dummyFunction;
  const onCommentExited = dummyFunction;
  const onCommentPosted = dummyFunction;

  const dropdownComponent = (
    <DropdownContainer>
      <FlowsPostHeaderOption
        dropdownOptions={menuItemsWithCopyLink}
        icon="more"
        title="flows post options"
        onCopyLink={onCopyLinkClick}
        onDeletePostSelectedOption={onDeletePostSelectedOption}
        onDeleteModalSubmitClick={onDeleteModalSubmitClick}
      />
    </DropdownContainer>
  );

  const handleEditorChange = useCallback((state) => setEditorState(state), []);

  const handleCommentEmoticonSelect = (emoji: EmojiData, contentId: string) => {
    const currentComments = commentsRef.current;

    const commentIndex = currentComments.findIndex(
      (comment) => comment.contentId === contentId,
    );

    if (commentIndex > -1) {
      const commentReactionsToUpdate = currentComments[commentIndex].reactions;
      const updatedCommentReactions = updateReactionsWithEmoji(
        commentReactionsToUpdate,
        emoji,
        currentUser,
      );

      const updatedComments = produce(currentComments, (draft) => {
        draft[commentIndex].reactions = updatedCommentReactions;
      });
      setComments(updatedComments);
      commentsRef.current = updatedComments;
    }
  };

  const handleCommentReactionToggleClick = (
    reaction: Reaction,
    contentId: string,
  ) => {
    const currentComments = commentsRef.current;

    const commentIndex = currentComments.findIndex(
      (comment) => comment.contentId === contentId,
    );

    if (commentIndex > -1) {
      const reactionName = reaction.reaction.name;

      const commentReactionsToUpdate = currentComments[commentIndex].reactions;
      const updatedCommentReactions = toggleReactionInReactions(
        reactionName,
        commentReactionsToUpdate,
        currentUser,
      );

      const updatedComments = produce(currentComments, (draft) => {
        draft[commentIndex].reactions = updatedCommentReactions;
      });
      setComments(updatedComments);
      commentsRef.current = updatedComments;
    }
  };

  const handleSubmit = () => {
    const messageText = getLiteralTextFromEditor(editorState);
    setEditorState(EditorState.createEmpty());
    const newComment = {
      commentId: new Date().toISOString(),
      contentId: new Date().toISOString(),
      from: {
        firstName: currentUser.profile.firstName,
        image: currentUser.profile.image,
        isDeleted: false,
        lastName: currentUser.profile.lastName,
        memberID: currentUser.memberId,
      },
      createdAt: new Date().toISOString(),
      currentUserId: currentUser.memberId,
      messageText,
      reactions: [],
      dropdownOptions: [],
      onClickUserName: () => {},
      onEmoticonSelect: handleCommentEmoticonSelect,
      onReactionToggleClick: handleCommentReactionToggleClick,
    } as CommentProps;
    const updatedComments = [...commentsRef.current, newComment];
    setComments(updatedComments);
    commentsRef.current = updatedComments;
  };

  const handleReactionSet = (emoji: EmojiData) => {
    const updatedReactions = updateReactionsWithEmoji(
      reactions,
      emoji,
      currentUser,
    );
    setReactions(updatedReactions);
  };

  const handleOnReactionUnSet = useCallback(
    (reaction: Reaction) => {
      const reactionName = reaction.reaction.name;
      const updatedReactions = toggleReactionInReactions(
        reactionName,
        reactions,
        currentUser,
      );
      setReactions(updatedReactions);
    },
    [currentUser, reactions],
  );

  const completeReactionBarProps = {
    ...props.reactionBarProps,
    reactions,
  };

  useEffect(() => {
    setHasText(editorState.getCurrentContent().hasText());
  }, [editorState]);

  useEffect(() => {
    if (isEditorFocused) {
      editorRef.current?.focus();
    }
  }, [isEditorFocused]);

  useEffect(() => {
    const commentsWithProps = comments.map((comment) => {
      return produce(comment, (draft) => {
        draft.onEmoticonSelect = handleCommentEmoticonSelect;
        draft.onReactionToggleClick = handleCommentReactionToggleClick;
      });
    });
    setHasText(editorState.getCurrentContent().hasText());
    setComments(commentsWithProps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const currentUserAvatar = {
    img: currentUser.profile.image,
    name: currentUser.profile.firstName,
    userId: currentUser.memberId,
  };

  const feedPostCommentInputProps: PostInputProps = {
    containerRef,
    editorRefElement: editorRef,
    editorState,
    hasText,
    isDummy: true,
    isEditorFocused,
    selectedGif: '',
    onGifRemoveClick: dummyFunction,
    currentUser: currentUserAvatar,
    onAtMentionClick: dummyFunction,
    onEmoticonClick: dummyFunction,
    onGifSelectorClick: dummyFunction,
    onAttachmentClick: dummyFunction,
    onPrivateMessageClick: dummyFunction,
    onPostClick: handleSubmit,
    onChange: dummyFunction,
    maxValue: 0,
    assemblyCurrency,
    buttonText: 'button text',
    onItemClick: dummyFunction,
    menuItems,
    onEditorChange: handleEditorChange,
    onSearchChange: dummyFunction,
    onAddMention: dummyFunction,
    onEditorBlur: dummyFunction,
    onEditorFocus: dummyFunction,
    setEditorFocus: dummyFunction,
    suggestions,
    isOpen: false,
    onToggleClick: dummyFunction,
    onToggleKeyPress: dummyFunction,
    postType: PostType.Comment,
  };

  const completeCommentProps = {
    feedCommentProps: comments,
    showMoreCommentsButton: false,
    onShowMoreCommentsButtonClick: dummyFunction,
  };

  return (
    <FlowFeedPost
      {...rest}
      commentsProps={completeCommentProps}
      commentInputProps={feedPostCommentInputProps}
      reactionBarProps={completeReactionBarProps}
      onReactionSet={handleReactionSet}
      onReactionUnSet={handleOnReactionUnSet}
      onMemberClick={onMemberClick}
      onUserNameClick={onUserNameClick}
      onFlowClick={onFlowClick}
      onCommentTextFocus={onCommentTextFocus}
      onCommentExited={onCommentExited}
      onCommentPosted={onCommentPosted}
      dropdownComponent={dropdownComponent}
    />
  );
};

export default DummyFlowFeedPost;
