import React from 'react';

import useGetStartedController from './useGetStartedController';
import GetStartedScreen from '../../../atomic/organism/QuickSetup/GetStartedScreen';
import { GetStartedControllerProps } from './type';

const GetStartedController = (props: GetStartedControllerProps) => {
  const { operations } = useGetStartedController(props);

  const { handleSelectedSetupChange } = operations;

  return <GetStartedScreen onSelected={handleSelectedSetupChange} />;
};

export default GetStartedController;
