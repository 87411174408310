import styled from 'styled-components';

import NewTextfield from '../NewTextfield';
import ThemeV2 from '../../../componentsV2/theme';
import IconButton from '../../atoms/IconButton_V2';
import Dropdown from '../../molecules/Dropdown_V2';
import { DropdownCaret } from '../../atoms/Button/styles';

export const StyledIconButton = styled(IconButton)`
  box-shadow: none;
  &:not(:disabled):hover {
    box-shadow: none;
  }
  width: 24px;
  margin-top: 0;
  margin-right: 12px;
`;

export const DropDownWrapper = styled.div`
  padding-bottom: 3px;
`;

export const StyledDropdown = styled(Dropdown)<{
  isDontInviteOptionSelected: boolean;
}>`
  max-width: 188px;
  width: 100%;
  margin-left: 12px;
  span {
    overflow: unset;
    color: ${({ isDontInviteOptionSelected }) =>
      isDontInviteOptionSelected && `${ThemeV2.palette.dustRed6}`};
  }
  svg path {
    fill: ${({ isDontInviteOptionSelected }) =>
      isDontInviteOptionSelected && `${ThemeV2.palette.dustRed6}`};
  }
  ${DropdownCaret} {
    padding-left: 12px;
  }
`;

export const StyledTextField = styled(NewTextfield)<{
  disabled?: boolean;
}>`
  max-width: 266px;
  width: 100%;
  .MuiInputBase-root {
    background-color: ${({ disabled }) =>
      disabled && `${ThemeV2.palette.gray1}`};
  }
  .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
    border-color: ${({ disabled }) => disabled && `${ThemeV2.palette.gray7}`};
  }
`;

export const StyledEmptyDiv = styled.div`
  margin-right: 12px;
  width: 24px;
`;
