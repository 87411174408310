import React from 'react';

import ThemeV2 from '../../../componentsV2/theme';
import { SelectableButtonProps } from './types';
import { StyledSelectableButton } from './styles';

const SelectableButton = (props: SelectableButtonProps) => {
  const {
    size = 28,
    isActive = false,
    bgColor = ThemeV2.palette.gray3,
    textColor = ThemeV2.palette.gray8,
    activeBgColor = ThemeV2.palette.geekBlue2,
    activeTextColor = ThemeV2.palette.geekBlue6,
    children,
    onClick,
  } = props;

  return (
    <StyledSelectableButton
      size={size}
      isActive={isActive}
      bgColor={bgColor}
      textColor={textColor}
      activeBgColor={activeBgColor}
      activeTextColor={activeTextColor}
      onClick={onClick}
    >
      {children}
    </StyledSelectableButton>
  );
};

export default SelectableButton;
