import { useMemo } from 'react';
import { useQuery } from 'react-query';
import {
  GET_MEMBER_CELEBRATED_RANKING,
  GET_MEMBER_CELEBRATOR_RANKING,
  GET_MEMBER_COMMENTER_RANKING,
  GET_TOP_CELEBRATED_LEADERBOARD,
  GET_TOP_CELEBRATORS_LEADERBOARD,
  GET_TOP_COMMENTERS_LEADERBOARD,
} from '../../constants/endpoints';
import {
  TOP_CELEBRATED,
  TOP_CELEBRATORS,
  TOP_COMMENTERS,
} from '../../atomic/molecules/Leaderboard/data';
import { makeAPICallWithDataReturn } from '../utils';

interface GetCurrentMemberResponse {
  count: number;
  id: string;
  profile: {
    firstName: string;
    lastName: string;
    image: string;
  };
  ranking: number;
  total: number;
}

interface GetLeaderboardResponse {
  leaderBoard: {
    profile: {
      firstName: string;
      lastName: string;
      image: string;
    };
    count: number;
    id: string;
  }[];
}

const leaderboardEndpoints: { [key: string]: string } = {
  [TOP_CELEBRATED.id]: GET_TOP_CELEBRATED_LEADERBOARD,
  [TOP_CELEBRATORS.id]: GET_TOP_CELEBRATORS_LEADERBOARD,
  [TOP_COMMENTERS.id]: GET_TOP_COMMENTERS_LEADERBOARD,
};

const currentUserEndpoints: { [key: string]: string } = {
  [TOP_CELEBRATED.id]: GET_MEMBER_CELEBRATED_RANKING,
  [TOP_CELEBRATORS.id]: GET_MEMBER_CELEBRATOR_RANKING,
  [TOP_COMMENTERS.id]: GET_MEMBER_COMMENTER_RANKING,
};

export const useDynamicCategoryAndTimeFrameFetchQuery = (
  category: string,
  from: number,
  to: number,
  isLeaderboardQueryEnabled: boolean,
) => {
  const currentUserEndpoint = currentUserEndpoints[category];
  const currentUserQueryPayload = null;
  const currentUserQueryParams = useMemo(() => ({ from, to }), [from, to]);
  const currentUserQuery = useQuery<GetCurrentMemberResponse>(
    [currentUserEndpoint, from, to],
    () =>
      makeAPICallWithDataReturn(
        currentUserEndpoint,
        currentUserQueryPayload,
        currentUserQueryParams,
      ),
    {
      staleTime: Infinity,
      enabled: isLeaderboardQueryEnabled,
    },
  );

  const leaderboardEndpoint = leaderboardEndpoints[category];
  const leaderboardEndpointPayload = null;
  const leaderboardEndpointParams = useMemo(() => ({ from, to }), [from, to]);
  const leaderboardQuery = useQuery<GetLeaderboardResponse>(
    [leaderboardEndpoint, from, to],
    () =>
      makeAPICallWithDataReturn(
        leaderboardEndpoint,
        leaderboardEndpointPayload,
        leaderboardEndpointParams,
      ),
    {
      staleTime: Infinity,
      enabled: isLeaderboardQueryEnabled,
    },
  );

  return {
    currentUserQuery,
    leaderboardQuery,
  };
};
