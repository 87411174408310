import React, { useMemo } from 'react';
import Button from '../../../atomic/atoms/Button';
import Body from '../../../atomic/atoms/Body';
import { renderParticipationSlide, RenderSlideInfoObject } from '../utils';
import { LoadedParticipationTemplate as ParticipationTemplate } from '../../../atomic/pages/ParticipationTemplate';
import useFlowsParticipationPreviewController from './useFlowsParticipationPreviewController';
import { GetProfileInfoResponse } from '../../../queries/Profile';
import { FlowVariants } from '../../../interfaces/Flow';
import { FlowInstanceResponse } from '../../../queries/Flows/interfaces';
import { ComponentStatus } from '../../../interfaces/component';
import { ParticipationFlowHeaderContent } from '../../../atomic/molecules/FlowsParticipationHeader';
import { mapHexCodeToEmoticon } from '../../../Utils/mappers';
import {
  ARE_PREVIEWING,
  CLOSE_PREVIEW,
  NAME_YOUR_FLOW,
} from '../../../languages/en/flows/participation';
import { YOU } from '../../../languages/en/singleWords';
import {
  StyledBodyFlowName,
  StyledBodyHeaderTitle,
  StyledEmoticon,
} from '../../../atomic/molecules/FlowsParticipationHeader/styles';
import useUppyInstances from '../ParticipationFlowController/useUppyInstances';
import { Flex } from '../../../Utils/styles/display';
import { GET_FILE_UPLOAD_SIGNED_URL } from '../../../constants/endpoints';

type FlowsParticipationPreviewControllerProps = {
  profileInfo: GetProfileInfoResponse;
  togglePreviewModalOpen: () => void;
};

const FlowsParticipationPreviewController = ({
  profileInfo,
  togglePreviewModalOpen,
}: FlowsParticipationPreviewControllerProps) => {
  const {
    models: {
      blockErrors,
      containerRef,
      currentStep,
      fieldErrors,
      stepData,
      touched,
      values,
      dynamicBlockData,
      blocks,
      builderStore: {
        owner,
        emoji,
        flowName,
        blockData: {
          PARTICIPANTS: { participantsCriteria },
          VISIBILITY,
        },
      },
    },
    operations: { goToNextStep, onStepChange, setFieldValue, goToPreviousStep },
  } = useFlowsParticipationPreviewController(
    togglePreviewModalOpen,
    profileInfo,
  );

  const [flowHeaderContent, headerStatus]: [
    ParticipationFlowHeaderContent,
    ComponentStatus,
  ] = useMemo(
    () => [
      {
        TitleContent: (
          <Flex>
            <StyledBodyHeaderTitle color="gray8" variant="body2">
              <Body color="geekblue6" inline variant="body2Medium">
                {`${YOU} `}
              </Body>
              {`${ARE_PREVIEWING} `}
            </StyledBodyHeaderTitle>
            {emoji ? (
              <StyledEmoticon>
                {mapHexCodeToEmoticon(emoji.unified)}
              </StyledEmoticon>
            ) : (
              <span />
            )}
            <StyledBodyFlowName
              color="geekBlue6"
              inline
              variant="body2Medium"
              addMargin={Boolean(!emoji)}
            >
              {flowName || NAME_YOUR_FLOW}
            </StyledBodyFlowName>
          </Flex>
        ),
        DescriptionContent: '',
      },
      ComponentStatus.LOADED,
    ],
    [emoji, flowName],
  );

  const flowInstance: FlowInstanceResponse = useMemo(
    () => ({
      flowId: '',
      instanceId: '',
      state: '',
      blocks,
    }),
    [blocks],
  );

  const remainingAllowance = profileInfo.member.pointsLeftThisCycle;
  const uppyInstances = useUppyInstances(
    flowInstance.blocks,
    {
      flowId: flowInstance.flowId,
      instanceId: flowInstance.instanceId,
    },
    GET_FILE_UPLOAD_SIGNED_URL,
  );

  const renderSlideInfo: RenderSlideInfoObject = {
    owner,
    blockErrors,
    currentStep,
    fieldErrors,
    flowInstance,
    goToNextStep,
    onStepChange,
    values,
    profileInfo,
    remainingAllowance,
    setFieldValue,
    stepData,
    touched,
    uppyInstances,
    isPreviewFlow: true,
    participantsCriteria,
    viewersBlockData: VISIBILITY || undefined,
  };

  const footerButtons: JSX.Element[] = [
    <Button onClick={togglePreviewModalOpen} size="large" key="1">
      {CLOSE_PREVIEW}
    </Button>,
  ];

  const previewContent = useMemo(
    () => ({
      icon: emoji?.unified || '',
      title: flowName || NAME_YOUR_FLOW,
      isTemplate: false,
    }),
    [emoji, flowName],
  );

  return (
    <ParticipationTemplate
      flowId={flowInstance.flowId}
      currentStep={currentStep}
      goToNextStep={goToNextStep}
      goToPreviousStep={goToPreviousStep}
      isNextButtonDisabled={currentStep === flowInstance.blocks.length - 1}
      member={profileInfo.member}
      isPreviousButtonDisabled={currentStep === 0}
      onCloseModal={togglePreviewModalOpen}
      footerButtons={footerButtons}
      onStepChange={onStepChange}
      dynamicBlockData={dynamicBlockData}
      singleSlideContainerRef={containerRef}
      flowVariant={FlowVariants.PREVIEW_FLOW}
      flowHeaderContent={flowHeaderContent}
      headerStatus={headerStatus}
      previewContent={previewContent}
    >
      {renderParticipationSlide(renderSlideInfo)}
    </ParticipationTemplate>
  );
};

export default FlowsParticipationPreviewController;
