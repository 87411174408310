import React, { useMemo } from 'react';

import { SHARE_SHEET_MODAL } from '../../../languages/en/flows/builder';
import { Flex } from '../../../Utils/styles/display';
import Body from '../../atoms/Body';
import { FeatureIntro, useFeatureIntro } from '../../../hooks/useFeatureIntro';
import {
  StyledShareSheetBodyDescription,
  StyledShareSheetButton,
  StyledShareSheetSVGIcon,
  StyledShareSheetBody,
  StyledShareSheetSuffixSVGIcon,
  ShareSheetSettings,
  AccessSettingsIcon,
  ChangeLinkSettingsButton,
  ChangeResponseSettingsButton,
} from './styles';
import ThemeV2 from '../../../componentsV2/theme';
import { ShareSheetModalScreen } from '../../../controllers/flows/FlowsShareSheetController/types';
import Divider from '../../atoms/Divider';
import { useFeatureSplit } from '../../../hooks/useFeatureSplit';
import {
  SplitNames,
  TreatmentTypes,
} from '../../../hooks/useSplitSdkConfig/constants';
import { AnonymousSettings } from '../../../controllers/flows/FlowsShareSheetController/data';
import { MenuItemIndividualItem } from '../../molecules/Dropdown_V2/interfaces';
import { trackShareSheetShowEvent } from '../../../Utils/analytics/shareSheet';
import { SHARE_SHEET_EVENTS } from '../../../Utils/analytics/constants';
import {
  ANYONE_WITH_LINK,
  CAN_RESPOND_AND_VIEW_FLOW_LABEL,
  CAN_RESPOND_FLOW_LABEL,
  CAN_VIEW_FLOW_LABEL,
  EVERYONE_IN_THIS_WORKSPACE_LABEL,
  ONLY_PEOPLE_OR_RULES_LABEL,
  PARTICIPANTS_AND_VIEWERS_LABEL,
  PARTICIPANTS_LABEL,
  VIEWERS_LABEL,
} from '../../../languages/en/flows/sharesheet';

// eslint-disable-next-line max-len
import { getIconSettingsForLinkAccess } from '../../../controllers/flows/FlowsShareSheetController/LinkAccessSettingsController/utils';

type ShareSheetModalProps = {
  visibilityTitleContent?: string;
  subtitleContent?: string;
  linkAccessType: string;
  anonymousSettings: MenuItemIndividualItem;
  handleChooseParticipants: () => void;
  handleChooseViewers: () => void;
  updateModalCurrentState: (currentState: ShareSheetModalScreen) => void;
  selectedLinkPermissionRole: string;
};

const ShareSheetModal = (props: ShareSheetModalProps) => {
  trackShareSheetShowEvent({
    show: SHARE_SHEET_EVENTS.SHARE_SHEET_SHOW,
  });

  useFeatureIntro({
    feature: FeatureIntro.ACCESS_POLICY_PARTICIPANT_VIEWER,
  });
  const { treatment: anonymousSettingsTreatment } = useFeatureSplit(
    SplitNames.SHARESHEET_ANONYMOUS_SETTINGS,
  );

  const isAnonymousSettingsAvailable =
    anonymousSettingsTreatment === TreatmentTypes.ON;

  const {
    visibilityTitleContent,
    subtitleContent,
    anonymousSettings,
    handleChooseParticipants,
    handleChooseViewers,
    updateModalCurrentState,
    linkAccessType,
    selectedLinkPermissionRole,
  } = props;

  const anonymousSettingsSubtitle = useMemo(() => {
    switch (anonymousSettings?.id) {
      case AnonymousSettings.ANONYMOUS_ON:
        return SHARE_SHEET_MODAL.ANONYMOUS;
      case AnonymousSettings.ANONYMOUS_OFF:
        return SHARE_SHEET_MODAL.NOT_ANONYMOUS;
      case AnonymousSettings.ANONYMOUS_OPTIONAL:
        return SHARE_SHEET_MODAL.OPTIONALLY_ANONYMOUS;
      default:
        return '';
    }
  }, [anonymousSettings?.id]);

  return (
    <>
      <Body variant="body1Bold" className="hello">
        {SHARE_SHEET_MODAL.FLOW_MEMBERS}
      </Body>
      <StyledShareSheetBodyDescription variant="body3">
        {SHARE_SHEET_MODAL.DESCRIPTION}
      </StyledShareSheetBodyDescription>
      <StyledShareSheetButton
        onClick={handleChooseParticipants}
        className="introjs-sharesheet-modal"
      >
        <Flex alignItems="flex-start">
          <StyledShareSheetSVGIcon
            icon="people"
            size="16px"
            color={ThemeV2.palette.geekBlue6}
          />
          <div>
            <StyledShareSheetBody variant="body1Medium">
              {SHARE_SHEET_MODAL.CHOOSE_PARTICIPANTS}
            </StyledShareSheetBody>
            <StyledShareSheetBody variant="body3">
              {subtitleContent}
            </StyledShareSheetBody>
          </div>
        </Flex>
        <StyledShareSheetSuffixSVGIcon icon="carat-right" size="20px" />
      </StyledShareSheetButton>
      <StyledShareSheetButton
        onClick={handleChooseViewers}
        className="introjs-sharesheet-participants"
      >
        <Flex alignItems="flex-start">
          <StyledShareSheetSVGIcon
            icon="eye"
            size="16px"
            color={ThemeV2.palette.geekBlue6}
          />
          <div>
            <StyledShareSheetBody variant="body1Medium">
              {SHARE_SHEET_MODAL.CHOOSE_VIEWERS}
            </StyledShareSheetBody>
            <StyledShareSheetBody variant="body3">
              {visibilityTitleContent}
            </StyledShareSheetBody>
          </div>
        </Flex>
        <StyledShareSheetSuffixSVGIcon icon="carat-right" size="20px" />
      </StyledShareSheetButton>

      <>
        <Divider color={ThemeV2.palette.gray4} isFullWidth />
        <section>
          <Flex justifyContent="space-between" alignItems="center">
            <div>
              <Body variant="body1Medium" color="gray9" margin="16px 0 0 0">
                {SHARE_SHEET_MODAL.LINK_ACCESS_SETTINGS}
              </Body>
              <Flex alignItems="flex-start">
                <ShareSheetSettings
                  onClick={() =>
                    updateModalCurrentState(
                      ShareSheetModalScreen.LINK_ACCESS_SETTINGS,
                    )
                  }
                >
                  <Flex alignItems="flex-start">
                    <AccessSettingsIcon
                      size="16px"
                      color={ThemeV2.palette.geekBlue6}
                      icon={getIconSettingsForLinkAccess(linkAccessType).icon}
                    />
                    <Body
                      variant="body2Medium"
                      color="geekBlue6"
                      margin="4px 0 16px 4px"
                    >
                      {linkAccessType === EVERYONE_IN_THIS_WORKSPACE_LABEL &&
                        SHARE_SHEET_MODAL.EVERYONE_CAN_ACCESS_LABEL}
                      {linkAccessType === ONLY_PEOPLE_OR_RULES_LABEL &&
                        SHARE_SHEET_MODAL.ONLY_GROUP_I_SELECT_LABEL}
                      {linkAccessType === ANYONE_WITH_LINK &&
                        SHARE_SHEET_MODAL.ANYONE_WITH_LINK_CAN_ACCESS_LABEL}
                    </Body>
                  </Flex>
                </ShareSheetSettings>
                <Body variant="body2" color="gray9" margin="4px 0 16px 4px">
                  {linkAccessType === ONLY_PEOPLE_OR_RULES_LABEL &&
                    CAN_RESPOND_AND_VIEW_FLOW_LABEL}
                  {linkAccessType === ANYONE_WITH_LINK &&
                    CAN_RESPOND_FLOW_LABEL}
                  {linkAccessType === EVERYONE_IN_THIS_WORKSPACE_LABEL &&
                    selectedLinkPermissionRole === PARTICIPANTS_LABEL &&
                    CAN_RESPOND_FLOW_LABEL}
                  {linkAccessType === EVERYONE_IN_THIS_WORKSPACE_LABEL &&
                    selectedLinkPermissionRole === VIEWERS_LABEL &&
                    CAN_VIEW_FLOW_LABEL}
                  {linkAccessType === EVERYONE_IN_THIS_WORKSPACE_LABEL &&
                    selectedLinkPermissionRole ===
                      PARTICIPANTS_AND_VIEWERS_LABEL &&
                    CAN_RESPOND_AND_VIEW_FLOW_LABEL}
                </Body>
              </Flex>
            </div>
            <ShareSheetSettings>
              <ChangeLinkSettingsButton
                color="secondary"
                size="medium"
                variant="text"
                onClick={() =>
                  updateModalCurrentState(
                    ShareSheetModalScreen.LINK_ACCESS_SETTINGS,
                  )
                }
                dataTestId="change link access settings"
              >
                {SHARE_SHEET_MODAL.CHANGE_SETTINGS}
              </ChangeLinkSettingsButton>
            </ShareSheetSettings>
          </Flex>
        </section>
      </>

      {isAnonymousSettingsAvailable && (
        <>
          <Flex justifyContent="space-between" alignItems="center">
            <div>
              <Body variant="body1Medium" color="gray9" margin="16px 0 0 0">
                {SHARE_SHEET_MODAL.RESPONSE_SETTINGS}
              </Body>
              <Flex alignItems="flex-start">
                <Body variant="body2" color="gray9" margin="4px 0 16px 0">
                  {SHARE_SHEET_MODAL.RESPONSES_ARE}
                </Body>
                <ShareSheetSettings
                  onClick={() =>
                    updateModalCurrentState(
                      ShareSheetModalScreen.ANONYMOUS_SETTINGS,
                    )
                  }
                >
                  <Body
                    variant="body2Medium"
                    color="geekBlue6"
                    margin="4px 0 16px 4px"
                  >
                    {anonymousSettingsSubtitle}
                  </Body>
                </ShareSheetSettings>
              </Flex>
            </div>
            <ShareSheetSettings>
              <ChangeResponseSettingsButton
                color="secondary"
                size="medium"
                variant="text"
                onClick={() =>
                  updateModalCurrentState(
                    ShareSheetModalScreen.ANONYMOUS_SETTINGS,
                  )
                }
              >
                {SHARE_SHEET_MODAL.CHANGE_SETTINGS}
              </ChangeResponseSettingsButton>
            </ShareSheetSettings>
          </Flex>
        </>
      )}
    </>
  );
};

export default ShareSheetModal;
