import styled from 'styled-components';

import GifContainer from '../../GifContainer';
import GiveRecognitionFormBottomButtons from '../../GiveRecognitionFormBottomButtons';
import ThemeV2 from '../../../../componentsV2/theme';

export const StyledContentContainer = styled.div<{
  hasError: boolean;
  isFocused: boolean;
  isDisabled: boolean;
  minHeight?: number;
}>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  border: 1px solid
    ${({ hasError, isFocused, isDisabled }) => {
      if (hasError) {
        return ThemeV2.palette.dustRed6;
      }
      if (isDisabled) {
        return ThemeV2.palette.gray5;
      }
      if (isFocused) {
        return ThemeV2.palette.geekBlue6;
      }
      return ThemeV2.palette.gray7;
    }};
  border-radius: 4px;
  min-height: ${({ minHeight }) => minHeight || '150px'};

  & .public-DraftEditor-content {
    min-height: ${({ minHeight }) =>
      minHeight ? `${minHeight - 40}px` : '100px'};
    .public-DraftStyleDefault-block {
      & > span > span {
        word-break: break-word;
      }
    }
  }
`;

export const StyledGifContainer = styled(GifContainer)`
  width: 178px;
  height: 100px;

  // X icon container
  div:nth-child(2) {
    top: 4px;
    right: 4px;
  }
`;

export const StyledGifAndButtonsContainer = styled.div<{
  isDisabled: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 12px;
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};
`;

export const StyledGiveRecognitionFormBottomButtons = styled(
  GiveRecognitionFormBottomButtons,
)`
  margin-left: -4px;
`;

export const StyledFileCardList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 32px;
  margin-bottom: 32px;
  gap: 12px;

  > div {
    margin: 0;
  }
`;
