import React from 'react';
import { FlowTimezoneProps } from './types';
import { TIMEZONE_LABEL } from '../../../languages/en/flows/builder';
import { StyledBody, StyledFlowTimezone } from './styles';
import { SingleSelectAutoComplete } from '../Autocomplete';

const FlowTimezone = (props: FlowTimezoneProps) => {
  const {
    selectedTimezone = null,
    handleTimeZoneChange,
    options,
    label,
    disabled,
    onFocus,
  } = props;

  return (
    <StyledFlowTimezone>
      <StyledBody variant="body1Medium">{TIMEZONE_LABEL}</StyledBody>
      <SingleSelectAutoComplete
        value={selectedTimezone}
        onChange={handleTimeZoneChange}
        options={options}
        label={label}
        disabled={disabled}
        showClearIcon={false}
        onFocus={onFocus}
      />
    </StyledFlowTimezone>
  );
};

export default FlowTimezone;
