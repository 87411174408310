export const SHARE_SHEET_CONFIRMATION_MODAL = {
  HEADING: 'Done Editing?',
  SAVE_CHANGES_LABEL: 'Save Changes',
  DISCARD_CHANGES_LABEL: 'Discard Changes',
  BODY_TEXT:
    'You have unsaved changes. Before you go, choose whether you’d like to save or discard them.',
};

export const VIEWERS_LABEL = 'Viewers';
export const PARTICIPANTS_LABEL = 'Participants';
export const ONLY_PEOPLE_OR_RULES_LABEL = 'Only people or rules I set';
export const PARTICIPANTS_AND_VIEWERS_LABEL = 'Participants and viewers';
export const EVERYONE_IN_THIS_WORKSPACE_LABEL = 'Everyone in this workspace';
export const ANYONE_WITH_LINK = 'Anyone with the link';
export const SPECIFIC_PEOPLE_OR_GROUPS = 'Specific people or groups';
export const CAN_RESPOND_FLOW_LABEL = 'can respond to this flow';
export const CAN_VIEW_FLOW_LABEL = 'can view this flow';
export const CAN_RESPOND_AND_VIEW_FLOW_LABEL =
  'can view and respond to this flow';

export const ANYONE_CAN_RESPOND_LABEL =
  'Anyone in this workspace with the link can respond';
export const ANYONE_CAN_VIEW_LABEL =
  'Anyone in this workspace with the link can view';
export const ANYONE_CAN_VIEW_AND_RESPOND_LABEL =
  'Anyone in this workspace with the link can view and respond';

export const CAN_RESPOND_TO_FLOW_LABEL = 'Can respond to flow';
export const CAN_VIEW_ALL_RESPONSES = 'Can view all responses';
export const CAN_RESPOND_AND_VIEW_RESPONSES = 'Can respond and view responses';
export const CAN_VIEW = 'Can view';
export const CAN_RESPOND = 'Can respond';
export const CAN_RESPOND_AND_VIEW = 'Can respond and view';
export const ANYONE_WITH_LINK_CAN_RESPOND = 'Anyone with the link can respond';
export const ANONYMITY_LINK_SETTINGS_BANNER_TEXT =
  'All external responses will be anonymous by default, unless you ask for identifying information.';
