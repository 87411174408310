// @ts-nocheck TODO: type issues need to be fixed in this file
import { SisenseFilter, WidgetConfig } from './types';
import { SISENSE_CONFIG } from '../../../../config';

export const dashboardWidgetConfig: Record<string, WidgetConfig> = {
  OPEN_ENDED: {
    dashboard: SISENSE_CONFIG.DASHBOARD.OPEN_ENDED_DASHBOARD,
    widget: SISENSE_CONFIG.WIDGETS.OPEN_ENDED,
  },
  GIF: {
    dashboard: SISENSE_CONFIG.DASHBOARD.GIF_DASHBOARD,
    widget: SISENSE_CONFIG.WIDGETS.GIF,
  },
  FILE_UPLOAD: {
    dashboard: SISENSE_CONFIG.DASHBOARD.FILE_UPLOAD_DASHBOARD,
    widget: SISENSE_CONFIG.WIDGETS.FILE_UPLOAD,
  },
  SCALE: {
    dashboard: SISENSE_CONFIG.DASHBOARD.SCALE_DASHBOARD,
    widget: SISENSE_CONFIG.WIDGETS.SCALE,
  },
  DROPDOWN: {
    dashboard: SISENSE_CONFIG.DASHBOARD.DROPDOWN_DASHBOARD,
    widget: SISENSE_CONFIG.WIDGETS.DROPDOWN,
  },
  GIVE_POINTS_STACK: {
    dashboard: SISENSE_CONFIG.DASHBOARD.GIVE_POINTS_DASHBOARD,
    widget: SISENSE_CONFIG.WIDGETS.GIVE_POINTS,
  },
};

export const trendsWidgetConfig: Record<string, WidgetConfig> = {
  OPEN_ENDED: {
    dashboard: SISENSE_CONFIG.DASHBOARD.OPEN_ENDED_TRENDS,
    widget: SISENSE_CONFIG.WIDGETS.OPEN_ENDED_TRENDS,
  },
  GIF: {
    dashboard: SISENSE_CONFIG.DASHBOARD.GIF_TRENDS,
    widget: SISENSE_CONFIG.WIDGETS.GIF_TRENDS,
  },
  FILE_UPLOAD: {
    dashboard: SISENSE_CONFIG.DASHBOARD.FILE_UPLOAD_TRENDS,
    widget: SISENSE_CONFIG.WIDGETS.FILE_UPLOAD_TRENDS,
  },
  SCALE: {
    dashboard: SISENSE_CONFIG.DASHBOARD.SCALE_TRENDS,
    widget: SISENSE_CONFIG.WIDGETS.SCALE_TRENDS,
  },
  DROPDOWN: {
    dashboard: SISENSE_CONFIG.DASHBOARD.SINGLE_SELECT_TRENDS,
    widget: SISENSE_CONFIG.WIDGETS.SINGLE_SELECT_TRENDS,
  },
  MULTI_CHOICE: {
    dashboard: SISENSE_CONFIG.DASHBOARD.MULTI_SELECT_TRENDS,
    widget: SISENSE_CONFIG.WIDGETS.MULTI_SELECT_TRENDS,
  },
  GIVE_POINTS_STACK: {
    dashboard: SISENSE_CONFIG.DASHBOARD.GIVE_POINTS_TRENDS,
    widget: SISENSE_CONFIG.WIDGETS.GIVE_POINTS_TRENDS,
  },
};

export const applyFilter = (
  dashboard: any,
  filter: string,
  query: string | string[],
) => {
  const filterToChange = dashboard.filters.$$filters.find(
    (item: any) => item.jaql && item.jaql.dim === filter,
  );

  if (filterToChange && filterToChange.$$model.jaql.filter) {
    filterToChange.$$model.jaql.filter = {
      explicit: true,
      members: Array.isArray(query) ? query : [query],
      multiSelection: true,
    };

    dashboard.$$model.$dashboard.updateDashboard(dashboard.$$model, 'filters');
  }
};

export const applyQueriesFilter = (
  dashboard: any,
  filter: string,
  query: string[],
) => {
  const filterToChange = dashboard.filters.$$filters.find(
    (item: any) => !item.jaql,
  );

  if (filterToChange && filterToChange.$$model.levels) {
    filterToChange.$$model.levels = filterToChange.$$model.levels.map(
      (x: any) =>
        x.dim === filter
          ? {
              ...x,
              filter: {
                explicit: true,
                members: query,
                multiSelection: true,
              },
            }
          : x,
    );

    dashboard.$$model.$dashboard.updateDashboard(dashboard.$$model, 'filters');
  }
};

export const applyStartEndDateFilter = (
  dashboard: any,
  startDate: Date | undefined,
  endDate: Date | undefined,
) => {
  const generateDatesBetweenDates = (start: Date, end: Date) => {
    let dates: Date[] = [];
    const currentDate = new Date(start);
    while (currentDate <= end) {
      dates = [...dates, new Date(currentDate)];
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dates.map(
      (x) =>
        `${x.getMonth() + 1}/${x.getDate()}/${x
          .getFullYear()
          .toString()
          .substr(-2)}`,
    );
  };

  const filterToChange = dashboard.filters.$$filters.find(
    (item: any) => !item.jaql,
  );

  let filter: any = {
    members: [],
    explicit: true,
    multiSelection: true,
  };

  if (startDate && endDate) {
    filter = {
      ...filter,
      members: generateDatesBetweenDates(startDate, endDate),
    };
  }

  if (startDate && !endDate) {
    filter = {
      ...filter,
      members: generateDatesBetweenDates(startDate, new Date()),
    };
  }

  if (!startDate && endDate) {
    filter = {
      ...filter,
      exclude: {
        members: generateDatesBetweenDates(endDate, new Date()),
      },
    };
  }

  if (filterToChange && filterToChange.$$model.levels) {
    filterToChange.$$model.levels = filterToChange.$$model.levels.map(
      (x: any) =>
        x.dim === '[responses.createdAt (Calendar)]' ||
        x.dim === '[flowactionhistories.createdAt (Calendar)]'
          ? {
              ...x,
              filter: filter,
            }
          : x,
    );

    dashboard.$$model.$dashboard.updateDashboard(dashboard.$$model, 'filters');
  }
};

export const removeChildrenByParentIds = (ids: string[]) => {
  ids.forEach((id: string) => {
    const nodeToRemove = document.getElementById(id);
    if (nodeToRemove) {
      nodeToRemove.replaceChildren();
    }
  });
};

export const applySisenseFilter = (
  sisenseFilter: SisenseFilter,
  dashboard: any,
) => {
  applyStartEndDateFilter(
    dashboard,
    sisenseFilter.startDate,
    sisenseFilter.endDate,
  );

  if (
    sisenseFilter.occurenceStartDate &&
    sisenseFilter.occurenceStartDate.length > 0
  ) {
    applyQueriesFilter(
      dashboard,
      '[responses.occurrenceId]',
      sisenseFilter.occurenceStartDate,
    );
  }

  if (sisenseFilter.selectedParticipant.length) {
    applyFilter(
      dashboard,
      '[responses._id1]',
      sisenseFilter.selectedParticipant,
    );
  }

  if (sisenseFilter.selectedDepartment.length) {
    applyQueriesFilter(
      dashboard,
      '[responses.metaData_department]',
      sisenseFilter.selectedDepartment,
    );
  }

  if (sisenseFilter.selectedManagerStatus.length) {
    applyQueriesFilter(
      dashboard,
      '[responses.metaData_isManager]',
      sisenseFilter.selectedManagerStatus,
    );
  }
};
