import styled from 'styled-components';
import Body from '../../../atomic/atoms/Body';
import InfoAlert from '../../../atomic/molecules/InfoAlert';

export const StyledHeading = styled(Body)`
  margin-bottom: 8px;
`;

export const StyledBody = styled(Body)`
  margin-bottom: 8px;
`;

export const ColumnWrapper = styled.div`
  display: grid;
  grid-template-columns: 200px auto;
  margin-top: 24px;
`;

export const StyledInfoAlert = styled(InfoAlert)`
  margin-top: 8px;
  margin-bottom: 32px;
`;
