import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FLOWS_ACCOUNT_SETUP } from '../../../constants/endpoints';
import { showErrorMessage } from '../../../Utils/toast';
import { SETUP_FLOWS_ERROR } from '../../../languages/en/flows';
import useMultipleJobsPollingV2 from '../../../hooks/useMultipleJobsPolling';

const useOnBoardingSetup = () => {
  const history = useHistory();
  const { error, isJobStatusSuccessful } = useMultipleJobsPollingV2<undefined>({
    preJobsAPI: {
      apiName: FLOWS_ACCOUNT_SETUP,
    },
    enabled: true,
  });

  useEffect(() => {
    if (error) {
      showErrorMessage(SETUP_FLOWS_ERROR);
    }
  }, [error]);
  useEffect(() => {
    if (isJobStatusSuccessful || error) {
      history.push('/home?setProfile=invite');
    }
  }, [isJobStatusSuccessful, history, error]);
};

export default useOnBoardingSetup;
