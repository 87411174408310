import { PostSortOptions, PostSortValues } from '../../../interfaces/Feed';

export const feedSortItems = [
  {
    id: 'feed-sort',
    items: [
      {
        value: PostSortValues[PostSortOptions.DESC],
        id: PostSortOptions.DESC,
      },
      {
        value: PostSortValues[PostSortOptions.ASC],
        id: PostSortOptions.ASC,
      },
    ],
  },
];
