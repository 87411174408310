import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import ThemeV2 from '../../../../../componentsV2/theme';

const StyledTextareaAutosize = styled(TextareaAutosize)<{
  variant: string;
  hovereffect: string;
}>`
  resize: none;
  width: 100%;
  border: none;
  outline: none;
  background-color: inherit;
  font-size: 16px;
  line-height: 20px;
  font-family: Roboto;
  padding: 0;
  vertical-align: middle;
  padding: ${({ variant }) => (variant !== 'custom' ? 'none' : '4px 8px')};

  &:hover {
    ${({ hovereffect }) =>
      hovereffect === 'custom' && `background: ${ThemeV2.palette.gray3}`};
    border-radius: 4px;
  }

  &:focus {
    border: ${({ variant }) =>
      variant !== 'custom'
        ? `0.5px solid ${ThemeV2.palette.white}`
        : `0.5px solid ${ThemeV2.palette.gray7}`};
    border-radius: 4px;
  }
`;

type CustomType = 'custom' | '';

interface TaskItemTextAreaProps {
  autofocus?: boolean;
  serializedTitle?: string;
  onBlur: (value: string) => void;
  handleEnterClick: (value: string) => void;
  isCreateItem?: boolean;
  disabled?: boolean;
  stopPropagation?: boolean;
  variant?: CustomType;
  enableShiftNewLine?: boolean;
  hasHoverEffect: boolean;
}

const TaskItemTextArea = ({
  autofocus = true,
  serializedTitle,
  onBlur,
  handleEnterClick,
  isCreateItem,
  disabled,
  stopPropagation = true,
  variant = '',
  enableShiftNewLine = false,
  hasHoverEffect = false,
}: TaskItemTextAreaProps) => {
  const ref = useRef<null | HTMLTextAreaElement>(null);
  const [noteTitle, setNoteTitle] = useState(serializedTitle || '');

  useEffect(() => {
    if (ref.current && autofocus) {
      ref.current.focus();
      ref.current.setSelectionRange(
        ref.current.value.length,
        ref.current.value.length,
      );
    }
  }, [autofocus, serializedTitle]);

  useEffect(() => {
    if (serializedTitle) {
      setNoteTitle(serializedTitle || '');
    }
  }, [serializedTitle]);

  const onTextAreaBlur = () => {
    onBlur(noteTitle);
  };

  const onTextAreaKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && (enableShiftNewLine ? !e.shiftKey : true)) {
      e.preventDefault();
      handleEnterClick(noteTitle);
      if (isCreateItem) {
        setNoteTitle('');
      }
    }
  };

  return (
    <StyledTextareaAutosize
      ref={ref}
      spellCheck
      onKeyDown={onTextAreaKeyDown}
      value={noteTitle}
      onChange={(e) => setNoteTitle(e.target.value)}
      onBlur={onTextAreaBlur}
      data-testid="task-text-area"
      onClick={(e) => {
        if (stopPropagation) {
          e.stopPropagation();
        }
      }}
      disabled={disabled}
      variant={variant}
      // Material UI does not accept boolean non-standard props
      hovereffect={hasHoverEffect ? 'custom' : ''}
    />
  );
};

export default TaskItemTextArea;
