import { useState, useCallback } from 'react';

import { REDEEM } from '../../../languages/en/rewards';
import { CultureReward } from '../../../queries/Rewards/interfaces';
import { RATING_EXTERNAL_LINKS } from '../../../Utils/rewards/urls';
import { openInNewTab } from '../../../Utils/window';
import { AssemblyCurrency } from '../../../interfaces/assembly';
import cultureRewardPlaceholder from '../../../img/culture-reward-placeholder.svg';

interface CultureRedeemModalControllerLogicProps {
  reward: CultureReward;
  onCloseRedeemModal: () => void;
  assemblyCurrency: AssemblyCurrency;
  redeemRewardMutate: (customRewardId: string) => void;
  redeemRewardReset: () => void;
  profileEmail: string;
}

const useCultureRedeemModalControllerLogic = (
  props: CultureRedeemModalControllerLogicProps,
) => {
  const { reward, onCloseRedeemModal, redeemRewardMutate, redeemRewardReset } =
    props;

  const [isRewardRated, setIsRewardRated] = useState(false);

  const handleRewardRatingChange = useCallback((newRating: number) => {
    if (newRating) {
      if (newRating > 3) {
        const randomIndex = Math.floor(
          Math.random() * RATING_EXTERNAL_LINKS.length,
        );
        openInNewTab(RATING_EXTERNAL_LINKS[randomIndex]);
      }
    }
    setIsRewardRated(true);
  }, []);

  const onRedeemClick = useCallback(
    (id) => {
      if (reward) {
        redeemRewardMutate(id);
      }
    },
    [redeemRewardMutate, reward],
  );

  const onClose = useCallback(() => {
    onCloseRedeemModal();
    redeemRewardReset();
  }, [onCloseRedeemModal, redeemRewardReset]);

  const modelMethods = {
    handleRewardRatingChange,
    onActionButtonClick: onRedeemClick,
    onClose,
  };

  return {
    modelMethods,
    id: reward._id,
    rewardTitle: `${REDEEM} ${reward.name}?`,
    isRedeemButtonDisabled: false,
    rewardCost: reward.pointsToRedeem,
    rewardDescription: reward.description,
    rewardImage:
      reward.image?.original?.relativeUrl || cultureRewardPlaceholder,
    hasRedeemed: false,
    isRewardRated,
    rewardSuccessTitle: reward.name,
  };
};

export default useCultureRedeemModalControllerLogic;
