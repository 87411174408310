import React from 'react';

import { ChipProps } from '../../molecules/Chip';
import { File, FileUploadStatus } from '../../molecules/FileCard/types';
import { AssemblyCurrencyIcon } from '../../../interfaces/AssemblyCurrencyIcon';
import { GivePointsStackRules, ScaleLabels } from '../../../interfaces/Flow';
import { GetFlowFileResponse } from '../../../queries/Flows/interfaces';
import { FeedPostUserProps } from '../../../interfaces/Feed';
import { FlowType } from '../../molecules/FlowsPostHeader/interfaces';
import { VisibilityType } from '../../../queries/Flows/Feed/interfaces';

export const BlockTypes = {
  SCALE: 'SCALE',
  DROPDOWN: 'DROPDOWN',
  OPEN_ENDED: 'OPEN_ENDED',
  NO_RESPONSE: 'NO_RESPONSE',
  FILE_UPLOAD: 'FILE_UPLOAD',
  MULTI_CHOICE: 'MULTI_CHOICE',
  GIF_SELECTOR: 'GIF_SELECTOR',
  GIVE_TROPHIES: 'GIVE_TROPHIES',
  PERSON_SELECTOR: 'PERSON_SELECTOR',
  GIVE_POINTS_STACK: 'GIVE_POINTS_STACK',
};

export const changeableBlockTypes = [
  BlockTypes.DROPDOWN,
  BlockTypes.MULTI_CHOICE,
  BlockTypes.SCALE,
];

export type BlockType =
  | 'SCALE'
  | 'DROPDOWN'
  | 'OPEN_ENDED'
  | 'NO_RESPONSE'
  | 'FILE_UPLOAD'
  | 'MULTI_CHOICE'
  | 'GIF_SELECTOR'
  | 'GIVE_TROPHIES'
  | 'PERSON_SELECTOR';

export type ScaleBlockType = {
  min: number;
  max: number;
  title: string;
  value: number;
  labels: ScaleLabels;
};

export type DropDownBlockType = {
  title: string;
  options: {
    id: string;
    value: string;
  }[];
  value: string[];
};

export type OpenEndedBlockType = {
  title: string;
  files?: File[];
  gifUrl?: string;
  message: React.ReactNode;
};

export type GifBlockType = {
  src: string;
  title: string;
};

export type GiveTrophiesBlockType = {
  title: string;
  content: string;
  showEachText: boolean;
  emoticon: AssemblyCurrencyIcon;
  rules?: GivePointsStackRules;
};

export type FileUploadBlockType = {
  files?: File[];
  title: string;
  isParticipation: boolean;
  status: FileUploadStatus;
};

export type PersonSelectorBlockType = {
  title: string;
  persons: ChipProps[];
};

export type NoResponseBlockType = {
  title: string;
  value: string;
};

export type BlockProps = {
  id: string;
  type: BlockType;
  content:
    | GifBlockType
    | ScaleBlockType
    | DropDownBlockType
    | OpenEndedBlockType
    | FileUploadBlockType
    | NoResponseBlockType
    | GiveTrophiesBlockType
    | PersonSelectorBlockType;
  onDownloadFileClick?: (fileDownload: DownloadBlockType) => void;
  onMemberClick?: (person: { memberID: string; firstName: string }) => void;
  fileToPreview?: GetFlowFileResponse | undefined;
  person?: FeedPostUserProps | null;
  flow?: FlowType;
  createdAt?: string;
  visibility?: VisibilityType;
  handleSetFilePreview?: (fileToPreview: DownloadBlockType | undefined) => void;
  responseId?: string;
  isAnonymous?: boolean;
};

export type DownloadBlockType = {
  id: string;
  fileName: string;
  responseId?: string | undefined;
};

export type FlowPostBodyProps = {
  responseId?: string;
  blocks: BlockProps[];
  fileToPreview?: GetFlowFileResponse | undefined;
  person?: FeedPostUserProps | null;
  flow?: FlowType;
  createdAt?: string;
  visibility?: VisibilityType;
  handleSetFilePreview?: (fileToPreview: DownloadBlockType | undefined) => void;
  onSeeFullPostClick?: () => void;
  onDownloadFileClick?: (fileDownload: DownloadBlockType) => void;
  onMemberClick?: (person: { memberID: string; firstName: string }) => void;
  isAnonymous?: boolean;
};
