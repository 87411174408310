import styled from 'styled-components';
import ToggleButton from '../../atoms/ToggleButton';

export const ReactedMembersListWrapper = styled.div`
  max-width: 426px;
`;
ReactedMembersListWrapper.displayName = 'ReactedMembersListWrapper';

export const StyledToggleButton = styled(ToggleButton)`
  margin-bottom: 8px;
  margin-right: 8px;
  padding-left: 8px;
  padding-right: 12px;

  > div:first-child {
    margin-right: 0;
  }
`;
StyledToggleButton.displayName = 'StyledToggleButton';

export const StyledReactionButton = styled(ToggleButton)`
  margin-bottom: 8px;
  margin-right: 8px;
`;
StyledReactionButton.displayName = 'StyledReactionButton';
