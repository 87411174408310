import { useMemo, useCallback } from 'react';
import {
  useNotificationFetchQuery,
  useMarkNotificationsAsReadMutation,
} from '../../../queries/Notifications';
import { serializeNotifications } from '../../../Utils/serializers/notifications';
import { useProfileInfoFetchQuery } from '../../../queries/Profile';
import { getAssemblyCurrency } from '../../../queries/Profile/utils';
import { NotificationTypes } from '../../../interfaces/Notifications';
import useModalsStore from '../../../stores/modalsStore';
import { setParticipationFlowSelector } from '../../../stores/modalsStore/selectors';

export type HandleNotificationItemClickParamTypes = {
  notificationType: string;
  read?: boolean;
  id?: string;
  flowId?: string;
  occurrenceId?: string;
};

const useNotificationsButtonController = () => {
  const { data: profileData } = useProfileInfoFetchQuery();
  const currency = useMemo(
    () => getAssemblyCurrency(profileData),
    [profileData],
  );
  const {
    data: notificationsData,
    isLoading,
    isError,
  } = useNotificationFetchQuery();
  const { mutate } = useMarkNotificationsAsReadMutation();
  const formattedNotifications = useMemo(
    () =>
      notificationsData && currency
        ? serializeNotifications(notificationsData.notifications, currency)
        : [],
    [currency, notificationsData],
  );

  const setParticipationFlow = useModalsStore(setParticipationFlowSelector);

  const onMarkReadClick = useCallback(
    (id?: string) => {
      mutate(id);
    },
    [mutate],
  );

  const handleNotificationItemClick = ({
    read,
    notificationType,
    id,
    flowId,
    occurrenceId = '',
  }: HandleNotificationItemClickParamTypes) => {
    if (!read) {
      onMarkReadClick(id);
    }

    if (
      notificationType === NotificationTypes.FLOW_TRIGGERED ||
      notificationType === NotificationTypes.FLOW_REMINDER
    ) {
      if (flowId) {
        setParticipationFlow({
          participationFlowId: flowId,
          occurrenceId,
          canHandleAnswerWithShortcut: true,
        });
      }
    }
  };

  return {
    notifications: formattedNotifications,
    unreadNotificationsCount: notificationsData?.notificationCount || 0,
    isLoading,
    isError,
    onMarkReadClick,
    handleNotificationItemClick,
  };
};

export default useNotificationsButtonController;
