import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import Collapse from '../../../atomic/atoms/Collapse';
import SVGIcon from '../../../atomic/atoms/SVGIcon';
import ThemeV2 from '../../../componentsV2/theme';
import { NotebookCountLoader } from '../../../atomic/molecules/Notebook/Loader';
import { TaskCategories } from '../../../interfaces/notebook';
import { Flex } from '../../../Utils/styles/display';
import { NotebookTabs } from '../NotebookPageController/useNotebookPageController/data';
import { Header, UnreadCount } from './styles';
import useNotebookGroupController from './useNotebookGroupController';
import Divider from '../../../atomic/atoms/Divider';
import NotebookTasksController from '../NotebookTasksController';
import { notebookSectionDetails } from './useNotebookGroupController/data';
import { getUnreadTasksCountText } from '../../../languages/en/notebook';

type NotebookGroupControllerProps = {
  type: TaskCategories;
  placeholder?: React.ReactElement | null;
  tabType: NotebookTabs;
  markTasksAsRead: (taskIds: string[]) => void;
  shouldShowModalForCurrentUser: boolean;
};

const collapsibleIconProps = {
  iconName: 'caret',
  iconSize: '10px',
};

const HideCountSections = [TaskCategories.COMPLETED, TaskCategories.ARCHIVED];

const NotebookGroupController = ({
  type,
  tabType,
  shouldShowModalForCurrentUser,
  markTasksAsRead,
}: NotebookGroupControllerProps) => {
  const {
    total,
    totalUnreadCount,
    hasError,
    isLoading,
    hasNextPage,
    isFetchingMoreTasks,
    fetchMoreTasks,
    onCollapsibleToggle,
  } = useNotebookGroupController(type, tabType);

  const {
    title,
    isCreateTaskSectionShown,
    isSectionOpenOnLoad,
    isLastSection,
  } = notebookSectionDetails[type];

  return (
    <Droppable key={type} droppableId={`${type}`}>
      {(provided, snapshot) => {
        return (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <div data-testid={`collapsible-${type}`} key={`${type}`}>
              <Collapse
                iconProps={collapsibleIconProps}
                isCollapseOpen={
                  isSectionOpenOnLoad ||
                  (snapshot.isDraggingOver && !snapshot.draggingFromThisWith) ||
                  false
                }
                collapseOpenCallback={onCollapsibleToggle}
                collapseHeader={
                  <Flex inlineFlex>
                    <Header inline variant="subHead2Medium">
                      {title}
                    </Header>
                    {isLoading && <NotebookCountLoader />}
                    {!hasError &&
                      !isLoading &&
                      total !== undefined &&
                      !HideCountSections.includes(type) && (
                        <Header
                          inline
                          variant="subHead2Medium"
                        >{`(${total})`}</Header>
                      )}
                    {totalUnreadCount > 0 && tabType === NotebookTabs.myWork && (
                      <UnreadCount
                        text={getUnreadTasksCountText(totalUnreadCount)}
                        customColorVariant={{
                          background: ThemeV2.palette.geekBlue6,
                          foreground: ThemeV2.palette.white,
                        }}
                      />
                    )}
                    {hasError && (
                      <SVGIcon
                        icon={'error-warning'}
                        size="16px"
                        data-testid="error"
                        color={ThemeV2.palette.dustRed6}
                      />
                    )}
                  </Flex>
                }
                headerHoverColor={`${ThemeV2.palette.gray2}`}
              >
                <NotebookTasksController
                  isCreateTaskSectionShown={isCreateTaskSectionShown}
                  type={type}
                  tabType={tabType}
                  hasError={hasError}
                  isLoading={isLoading}
                  isFetchingMoreTasks={isFetchingMoreTasks}
                  fetchMoreTasks={fetchMoreTasks}
                  hasNextPage={hasNextPage || false}
                  markTasksAsRead={markTasksAsRead}
                  placeholder={provided.placeholder}
                  shouldShowModalForCurrentUser={shouldShowModalForCurrentUser}
                />
              </Collapse>
              {!isLastSection && (
                <Divider
                  color={ThemeV2.palette.gray4}
                  isFullWidth
                  marginTop="24px"
                  marginBottom="24px"
                />
              )}
            </div>
          </div>
        );
      }}
    </Droppable>
  );
};

export default NotebookGroupController;
