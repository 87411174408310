import React from 'react';

import Body from '../../atoms/Body';
import { Flex } from '../../../Utils/styles/display';
import { generateV2UserProfileRoute } from '../../../constants/routes';
import Link from '../../atoms/Link_V2';
import { OnlineStatus } from '../../atoms/Avatar/interfaces';
import {
  StyledAvatar,
  StyledButton,
  StyledHeading,
  NameAndUsernameWrapper,
  Wrapper,
} from './styles';
import {
  viewMembersActivity,
  VIEW_MY_ACTIVITY,
} from '../../../languages/en/home/profileDrawer';
import { MemberState } from '../../../interfaces/user';

export interface ProfileHeaderSectionProps {
  firstName: string;
  isCurrentUser: boolean;
  lastName: string;
  memberId: string;
  onlineStatus?: OnlineStatus;
  profilePicture: string;
  shouldDisplayViewActivtyLink: boolean;
  username: string;
  state?: MemberState;
}

const ProfileHeaderSection = ({
  firstName,
  isCurrentUser,
  lastName,
  memberId,
  onlineStatus,
  profilePicture,
  shouldDisplayViewActivtyLink,
  username,
  state,
}: ProfileHeaderSectionProps) => {
  return (
    <Wrapper>
      <Flex>
        <StyledAvatar
          img={profilePicture}
          name={firstName}
          onlineStatus={onlineStatus}
          size="48px"
          fontSize="20px"
          userId={memberId}
          icon={state === MemberState.PENDING ? 'pending-person' : ''}
        />
        <NameAndUsernameWrapper>
          <StyledHeading variant="h6">
            {firstName} {lastName}
          </StyledHeading>
          <Body color="gray8" variant="body3">
            @{username}
          </Body>
        </NameAndUsernameWrapper>
      </Flex>

      {shouldDisplayViewActivtyLink && (
        <Link to={generateV2UserProfileRoute(memberId)}>
          <StyledButton isFullWidth size="small" variant="text">
            {isCurrentUser ? VIEW_MY_ACTIVITY : viewMembersActivity(firstName)}
          </StyledButton>
        </Link>
      )}
    </Wrapper>
  );
};

export default ProfileHeaderSection;
