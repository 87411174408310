import React, { useState, useEffect } from 'react';

import FilterController from './FilterController';
import { Wrapper } from '../../../atomic/organism/RightDrawerFilter/styles';
import useGetParsedFilterOptions from '../../../hooks/useGetParsedFilterOptions';

const MainFeedRightDrawerController = () => {
  const [, setIsFiltered] = useState(false);
  const { isMainFeedFilterApplied } = useGetParsedFilterOptions();

  useEffect(() => {
    if (isMainFeedFilterApplied) {
      setIsFiltered(true);
    } else {
      setIsFiltered(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Wrapper>
        <FilterController />
      </Wrapper>
    </>
  );
};

export default MainFeedRightDrawerController;

/*
import SVGIcon from '../../../atomic/atoms/SVGIcon';
import TabComponent, {
  TabComponentProps,
} from '../../../atomic/molecules/TabComponent';
import { FILTER } from '../../../languages/en/singleWords';

import useGetPageName from '../../../hooks/useGetPageName';
import useTrackRightDrawerEvent from '../../../hooks/analytics/useTrackRightDrawerEvent';
import { RIGHT_DRAWER_EVENTS } from '../../../Utils/analytics/constants';

const { page } = useGetPageName();
const { trackRightDrawerTrackerEvent } = useTrackRightDrawerEvent();

const onTabClickHandler = useCallback(
    (drawerTabViewed: any) => {
      trackRightDrawerTrackerEvent(RIGHT_DRAWER_EVENTS.TAB_CLICKED, {
        drawerTabViewed,
        feedViewed: page,
      });
    },
    [page, trackRightDrawerTrackerEvent],
  );

  const tabComponentProps: TabComponentProps = {
    tabItems: [
      {
        tabHeader: {
          label: <span>{FILTER}</span>,
          icon: <SVGIcon icon="filter" size="16px" />,
        },
        tabContent: (
          <Wrapper>
            <FilterController setIsFiltered={setIsFiltered} />
          </Wrapper>
        ),
        isFiltered,
      },
    ],
    splitEqually: true,
    selectedTabIndex: 0,
    onTabClick: onTabClickHandler,
  };
*/
