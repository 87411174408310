import ContentLoader from 'react-content-loader';
import React from 'react';

import ThemeV2 from '../../../componentsV2/theme';

const ProfileHeaderSectionLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={293}
      height={80}
      viewBox="0 0 293 80"
      backgroundColor={ThemeV2.palette.gray4}
      foregroundColor={ThemeV2.palette.gray3}
      uniqueKey="profile-header-section-loader"
    >
      <circle cx="40" cy="40" r="24" />
      <rect x="72" y="18" rx="4" width="129" height="20" />
      <rect x="72" y="46" rx="4" width="73" height="16" />
    </ContentLoader>
  );
};

export default ProfileHeaderSectionLoader;
