import React from 'react';
import Body from '../../../../atomic/atoms/Body';

import { Flex } from '../../../../Utils/styles/display';
import { StyledImage } from '../../../SinglePostController';
import PostNotFoundImage from '../../../../img/searching.svg';
import FlowsFeedPostLoader from '../../../flows/FlowsFeedController/Loader';
import {
  COULDNT_FIND_POST,
  COULDNT_FIND_POST_COMMENT,
} from '../../../../languages/en/header';

import useLegacySingleFlowFeedPostController from './useLegacySingleFlowFeedPostController';
import FlowPostController from '../../../flows/FlowPostController';
import useSinglePostSocket, {
  WebSocketSinglePostTypes,
} from '../../../../hooks/useSinglePostSocket';

const LegacySingleFlowFeedPostController = () => {
  const {
    isError,
    isLoading,
    flowFeedPostProps,
    onMemberClick,
    profileData,
    onReactionSet,
    onReactionUnSet,
    onPostDelete,
  } = useLegacySingleFlowFeedPostController();

  useSinglePostSocket({
    type: WebSocketSinglePostTypes.LEGACY_POST,
  });

  if (isError) {
    return (
      <Flex flexDirection="column" alignItems="center" margin="32px 0 0">
        <StyledImage alt={COULDNT_FIND_POST} src={PostNotFoundImage} />
        <Body variant="subHead2Bold">{COULDNT_FIND_POST}</Body>
        <Body variant="body1">{COULDNT_FIND_POST_COMMENT}</Body>
      </Flex>
    );
  }

  if (isLoading) {
    return <FlowsFeedPostLoader />;
  }

  if (flowFeedPostProps)
    return (
      <FlowPostController
        key={flowFeedPostProps.key}
        post={flowFeedPostProps}
        onReactionSet={onReactionSet}
        onReactionUnSet={onReactionUnSet}
        onMemberClick={onMemberClick}
        profileData={profileData}
        onPostDelete={onPostDelete}
      />
    );

  return null;
};

export default LegacySingleFlowFeedPostController;
