import { trackEvent } from '..';
import { pick } from '../../common';
import {
  AnalyticsEvents,
  NOTEBOOK_EVENT,
  NOTEBOOK_TASK_EVENT,
} from '../constants';
import {
  NotebookSectionalAnalyticsProps,
  NotebookTaskAnalyticsProps,
} from '../interfaces';
import eventsAttributesMap from './data';

const trackNotebookShowEvent = (
  eventProps: NotebookSectionalAnalyticsProps,
) => {
  const selectedAttributes = eventsAttributesMap.get(
    eventProps.show,
  ) as string[];
  trackEvent(NOTEBOOK_EVENT, undefined, {
    ...pick(eventProps, selectedAttributes),
    event: AnalyticsEvents.SHOW,
  });
};

const trackNotebookActionEvent = (
  eventProps: NotebookSectionalAnalyticsProps,
) => {
  const selectedAttributes = eventsAttributesMap.get(
    eventProps?.action,
  ) as string[];
  trackEvent(NOTEBOOK_EVENT, undefined, {
    ...pick(eventProps, selectedAttributes),
    event: AnalyticsEvents.ACTION,
  });
};

const trackNotebookErrorEvent = (
  eventProps: NotebookSectionalAnalyticsProps,
) => {
  trackEvent(NOTEBOOK_EVENT, undefined, {
    ...eventProps,
    event: AnalyticsEvents.ERROR,
  });
};

const trackNotebookTaskShowEvent = (eventProps: NotebookTaskAnalyticsProps) => {
  const selectedAttributes = eventsAttributesMap.get(
    eventProps.show,
  ) as string[];
  trackEvent(NOTEBOOK_TASK_EVENT, undefined, {
    ...pick(eventProps, selectedAttributes),
    event: AnalyticsEvents.SHOW,
  });
};

const trackNotebookTaskActionEvent = (
  eventProps: NotebookTaskAnalyticsProps,
) => {
  const selectedAttributes = eventsAttributesMap.get(
    eventProps.action,
  ) as string[];
  trackEvent(NOTEBOOK_TASK_EVENT, undefined, {
    ...pick(eventProps, selectedAttributes),
    event: AnalyticsEvents.ACTION,
  });
};

const trackNotebookTaskErrorEvent = (
  eventProps: NotebookTaskAnalyticsProps,
) => {
  trackEvent(NOTEBOOK_TASK_EVENT, undefined, {
    ...eventProps,
    event: AnalyticsEvents.ERROR,
  });
};

export {
  trackNotebookShowEvent,
  trackNotebookActionEvent,
  trackNotebookErrorEvent,
  trackNotebookTaskShowEvent,
  trackNotebookTaskActionEvent,
  trackNotebookTaskErrorEvent,
};
