import { StringReplace } from './common';

export const HomeDrawerData = {
  POINTS_BALANCE: {
    TEXT: `My ${StringReplace.pointsName} Balance: `,
    TOOLTIP: `The total amount of ${StringReplace.pointsName} you’ve earned. You can redeem these for rewards.`,
    OVER_MINIMUM: `You're a rockstar, you have ${StringReplace.pointsIcon}${StringReplace.remainingPoints} to redeem`,
    UNDER_MINIMUM: `${StringReplace.pointsIcon}${StringReplace.remainingPoints} more to go to redeem`,
  },
  MONTHLY_ALLOWANCE: {
    TEXT: `Total monthly allowance`,
    // eslint-disable-next-line max-len
    TOOLTIP: `Your total allocated amount to give each month. This amount refreshes every month and you cannot use these towards redeeming your own rewards.`,
    // eslint-disable-next-line max-len
    NEW_TOOLTIP: `This represents your monthly allowance and how much you’ve given this month. You have ${StringReplace.monthlyAllowance} ${StringReplace.pointsName} each month to give. Spread some joy!`,
  },
  REMAINING_ALLOWANCE: {
    TEXT: `Remaining ${StringReplace.pointsName} to give`,
    // eslint-disable-next-line max-len
    TOOLTIP: `You have ${StringReplace.pointsIcon} ${StringReplace.remainingPoints} left to give, since you’ve already given out ${StringReplace.pointsIcon} ${StringReplace.usedPoints} this month. These refresh on the first of each month, so use them up before then!`,
  },
  // eslint-disable-next-line max-len
  REFRESH_INFO: `Refreshes to ${StringReplace.monthlyAllowance}  ${StringReplace.pointsName} on ${StringReplace.pointsRefreshDate}`,
  REDEEM_BUTTON_TEXT: 'Redeem Rewards',
  DISABLED_REDEEM_TEXT: 'Browse Rewards',
  REDEEM_HEADER: `Your earned balance`,
  // eslint-disable-next-line max-len
  REWARDS_MINIMUM_TOOLTIP: `This represents your earned balance`,
};

export const NavButtonWithIconData = {
  FLOWS: {
    TEXT: 'Flows',
    ICON: 'flows',
  },
  MAIN_FEED: {
    TEXT: 'Main Feed',
    ICON: 'news',
  },
  REWARDS: {
    TEXT: 'Rewards',
    ICON: 'rewards',
  },
  GENERAL: {
    TEXT: 'General',
    ICON: 'general-settings',
  },
  NOTIFICATIONS: {
    TEXT: 'Notifications',
    ICON: 'notification-bubble',
  },
  SECURITY_LOGIN: {
    TEXT: 'Security & Login',
    ICON: 'security-login',
  },
  NOTEBOOK: {
    TEXT: 'Notebook',
    ICON: 'create-bubble',
  },
};
