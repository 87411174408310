import React from 'react';

import Divider from '../../atoms/Divider';
import { StyledList, StyledMyRankingEntry } from './styles';
import ThemeV2 from '../../../componentsV2/theme';
import RankingEntry from '../RankingEntry';

const LeaderboardListLoader = () => {
  const entries = [];

  const numberOfEntries = 10;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < numberOfEntries; i++) {
    const isLastEntry = i === numberOfEntries - 1;

    entries.push(
      <div key={i}>
        <RankingEntry isLoading />
        {!isLastEntry && <Divider color={ThemeV2.palette.gray4} />}
      </div>,
    );
  }

  return (
    <>
      <StyledMyRankingEntry isLoading />
      <StyledList>{entries}</StyledList>
    </>
  );
};

export default LeaderboardListLoader;
