import React from 'react';
import Body from '../../atoms/Body';
import AssemblyCurrencyIcon from '../../atoms/AssemblyCurrencyIcon';

import { StyledBody } from '../FlowBlockDropdown/styles';

import { FlowBlockGiveTrophiesProps } from './type';
import { EACH } from '../../../languages/en/singleWords';

const FlowBlockGiveTrophies = (props: FlowBlockGiveTrophiesProps) => {
  const { title, content, emoticon, showEachText, rules } = props;
  if (rules && rules?.hidePoints) return null;

  return (
    <section>
      <StyledBody variant="body3" color="gray8">
        {title}
      </StyledBody>
      <Body>
        <AssemblyCurrencyIcon {...emoticon} />
        {` ${content}`}
        {showEachText && ` ${EACH}`}
      </Body>
    </section>
  );
};

export default FlowBlockGiveTrophies;
