import React, { useCallback, useMemo } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import RRule from 'rrule';

import FlowsFeedPostLoader from './Loader';
import { Flex } from '../../../Utils/styles/display';
import useFlowsFeedController from './useFlowsFeedController';
import { mapHexCodeToEmoticon } from '../../../Utils/mappers';
import {
  FeedsContainer,
  FlowCreatedModal,
  StyledActionBarWrapper,
  StyledDropdown,
  StyledImage,
  StyledModalBodyContent,
  StyledInfoAlert,
} from './styles';
import { feedSortItems } from '../../home/MainFeedController/data';
import { FLOW_FEED_STATES } from '../../../languages/en/flows/feed';
import { PostSortValues } from '../../../interfaces/Feed';
import {
  ARCHIVE_IT,
  FLOWS_ARCHIVE_MODAL,
  FLOW_ACTION_BAR_NOTIFICATION_TEXT,
  FLOW_ENDED_INFO,
  FLOW_OWNER_ENDED_INFO,
  FLOW_OWNER_SHORTCUT_ENDED_INFO,
  FLOW_SHORTCUT_ENDED_INFO,
  IS_CLOSED,
} from '../../../languages/en/flows';

import searching from '../../../img/searching.svg';
import setAccountInfo from '../../../img/set-account-info.svg';

import { FeedTypes } from '../../../atomic/molecules/ActionBar/types';
import FeedPostLoader from '../../../atomic/organism/FeedPost/Loader';
import ActionBarContainer from '../../../atomic/molecules/ActionBarContainer';
import BottomOfFeedMessage from '../../../atomic/molecules/BottomOfFeedMessage';
import EmptyErrorStateTemplate from '../../../atomic/molecules/EmptyErrorStateTemplate';
import { HeadingType } from '../../../atomic/molecules/EmptyErrorStateTemplate/interfaces';
import CelebrationImage from '../../../img/celebration.svg';

import {
  DropdownColor,
  DropdownPlacement,
  DropdownSizes,
  DropdownVariants,
} from '../../../atomic/molecules/Dropdown_V2/interfaces';
import {
  ANSWER,
  ANSWERED,
  ARCHIVE,
  CREATED,
  WHEN,
  YOU,
  FLOW,
  ANONYMOUSLY,
} from '../../../languages/en/singleWords';
import FlowRunNowModal from '../../../atomic/organism/FlowRunNowModal';
import { getFlowNameWithEmoticon } from '../../../Utils/flows';
import { capitalizeFirstLetter } from '../../../Utils/text';
import FlowPostController from '../FlowPostController';
import { getDueTime } from '../../../Utils/time';
import { ShowMoreButton } from '../../home/MainFeedController/styles';
import { SEE_NEW_POSTS } from '../../../languages/en/feed';
import {
  getFormattedDateAndTimeFromUtcTime,
  getFormattedNextTriggerDateAndTime,
} from '../../../Utils/flows/feeds';

import useGetParsedFilterOptions from '../../../hooks/useGetParsedFilterOptions';
import { BUILDER_FLOW_CREATED_MODAL } from '../../../languages/en/flows/builder';
import EmoticonWithText from '../../../atomic/atoms/EmoticonWithText';
import { getTimezoneAbbr } from '../../../Utils/flows/builder/utils';
import { LinkButton } from '../../../atomic/molecules/FlowsFeedHeader/styles';
import Body from '../../../atomic/atoms/Body';
import BasicModalTemplate from '../../../atomic/templates/BasicModalTemplate';
import {
  GENERAL_NEWS_FEED_TEMPLATE_IDS,
  ICEBREAKER_TEMPLATE_IDS,
} from './constants';
import {
  generalNewsFeedDummyPostData,
  iceBreakerDummyPostData,
  generalNewFeedHeaderProps,
  generalNewFeedHeaderFlowProps,
  iceBreakerHeaderProps,
  iceBreakerHeaderFlowProps,
} from '../../../atomic/organism/DummyFlowFeedPost/data';
import DummyFlowFeedPost from '../../../atomic/organism/DummyFlowFeedPost';
import {
  getScheduleFlowEmptyStateSubHeading,
  getScheduleFlowEmptyStateTriggeredOnceSubHeading,
} from './utils';
import {
  AnonymityStates,
  LinkAccessType,
} from '../../../queries/Flows/interfaces';
import { V2_HOME } from '../../../constants/routes';
import { useHistory } from 'react-router-dom';

const FlowsFeedController = () => {
  const { isFlowsFeedFilterApplied } = useGetParsedFilterOptions();

  const {
    feedData,
    isLoading,
    feedActions,
    flowDetails,
    flowDetailsData,
    profileData,
    showLoadMoreButton,
    flowFeedsSortValue,
    handleActionBarClick,
    handleFeedSortClick,
    isTriggerFlowModalOpen,
    dueDateValue,
    occurrenceValue,
    participants,
    flowVisibilityCriteria,
    participantsCount,
    hasMoreMembers,
    isTriggerFlowLoading,
    openRunNowModal,
    flowDetailError,
    setTriggerFlowModalClose,
    handleDueDateValueChange,
    setOccurrenceValue,
    onRunNowButtonClick,
    fetchMoreMembers,
    refetchFlowFeed,
    onMemberClick,
    setShowLoadMoreButtonToFalse,
    isFlowCreatedModalOpen,
    onFlowCreateModalClose,
    getConfirmationModalButtonProps,
    openArchiveModal,
    isArchiveModalOpen,
    isArchiveFlowLoading,
    setArchiveModalClose,
    handleOnArchiveClick,
    isFromQuickSetup,
    pendingMembersParticipantCount,
  } = useFlowsFeedController();

  const { push } = useHistory();
  const { onReactionSet, onReactionUnSet } = feedActions;

  const { data, hasNextPage, handleFetchNextPage } = feedData;

  const flowName = flowDetailsData?.name;
  const flowEmoticon = flowDetailsData?.icon.value;

  const flowTriggerScheduleDetails =
    flowDetailsData?.schedule?.rule &&
    RRule.parseString(flowDetailsData.schedule.rule);

  const { formattedFullDate } = getFormattedDateAndTimeFromUtcTime(
    flowDetailsData?.schedule?.rule,
  );

  const goToMainFeed = () => {
    push(V2_HOME);
  };

  const onLoadMoreClick = useCallback(() => {
    document.getElementById('scrollableFeedContent')?.scroll({
      top: 0,
      behavior: 'smooth',
    });
    refetchFlowFeed();
    setShowLoadMoreButtonToFalse();
  }, [refetchFlowFeed, setShowLoadMoreButtonToFalse]);

  const hasActiveOccurrence =
    flowDetailsData?.occurrence.activeOccurrence?.occurrenceId;

  const hasLastOccurrence =
    flowDetailsData?.occurrence.lastOccurrence?.occurrenceId;

  const canShowScheduleEndedBanner = useMemo(() => {
    if (flowDetailsData?.kind === 'SCHEDULED' && !hasActiveOccurrence) {
      if (
        !flowDetailsData?.schedule ||
        (flowDetailsData?.schedule &&
          flowDetailsData?.schedule.status === 'COMPLETED')
      ) {
        return true;
      }
    }
    return false;
  }, [flowDetailsData, hasActiveOccurrence]);

  if (isLoading) {
    return (
      <FeedsContainer>
        <FlowsFeedPostLoader />
      </FeedsContainer>
    );
  }

  const renderCreatedFlowModalText = () => {
    if (isFromQuickSetup) {
      return (
        <>
          {`${BUILDER_FLOW_CREATED_MODAL.QUICK_SETUP_CONTENT_TITLE} `}
          <EmoticonWithText
            emoticon={mapHexCodeToEmoticon(flowEmoticon || '')}
            text={flowName}
            hasBoldText
          />
          {` ${FLOW}`}
        </>
      );
    }
    if (formattedFullDate && getTimezoneAbbr) {
      return (
        <>
          {`${YOU} ${CREATED} `}
          <EmoticonWithText
            emoticon={mapHexCodeToEmoticon(flowEmoticon || '')}
            text={flowName}
            hasBoldText
          />{' '}
          {`${BUILDER_FLOW_CREATED_MODAL.SCHEDULED_START_ON} `}
          <b>{`${formattedFullDate} ${getTimezoneAbbr(
            flowTriggerScheduleDetails,
          )}`}</b>
          .{` ${BUILDER_FLOW_CREATED_MODAL.SCHEDULED_IN_THE_MEAN_TIME} `}
        </>
      );
    }
    if (flowDetailsData?.kind === 'NO_TRIGGER') {
      return (
        <>
          {`${YOU} ${CREATED} `}
          <EmoticonWithText
            emoticon={mapHexCodeToEmoticon(flowEmoticon || '')}
            text={flowName}
            hasBoldText
          />{' '}
          {`${BUILDER_FLOW_CREATED_MODAL.NO_TRIGGER_CONTENT_TITLE}`}
        </>
      );
    }
    return (
      <>
        {`${BUILDER_FLOW_CREATED_MODAL.ON_DEMAND_CONTENT_TITLE} `}
        <EmoticonWithText
          emoticon={mapHexCodeToEmoticon(flowEmoticon || '')}
          text={flowName}
          hasBoldText
        />
      </>
    );
  };

  let emoticon = '';
  if (flowDetailsData?.icon.value) {
    emoticon = mapHexCodeToEmoticon(flowDetailsData.icon.value);
  }

  const renderEmptyState = () => {
    if (flowDetailsData && data.length === 0) {
      const {
        isFlowOwner,
        isFlowParticipant,
        isFlowViewer,
        isShortcut,
        kind,
        schedule,
        creationSource,
        templateId = '',
      } = flowDetailsData;
      const { formattedDate, formattedTime } =
        getFormattedDateAndTimeFromUtcTime(schedule?.rule);

      const { formattedNextTriggerDate, formattedNextTriggerTime } =
        getFormattedNextTriggerDateAndTime(schedule?.nextTimeInUTC);

      const renderScheduleFlowEmptyStates = () => {
        if (!hasLastOccurrence) {
          return (
            <EmptyErrorStateTemplate
              image={setAccountInfo}
              variant={HeadingType.Bold}
              heading={FLOW_FEED_STATES.SCHEDULED_FLOW_EMPTY_STATES.HEADING}
              subHeading={getScheduleFlowEmptyStateSubHeading(
                formattedDate,
                formattedTime,
                getTimezoneAbbr(flowTriggerScheduleDetails),
                isShortcut,
                isFlowOwner,
              )}
            />
          );
        }
        return (
          <EmptyErrorStateTemplate
            image={setAccountInfo}
            variant={HeadingType.Bold}
            heading={
              FLOW_FEED_STATES.SCHEDULED_FLOW_TRIGGERED_ONCE_EMPTY_STATES
                .HEADING
            }
            subHeading={getScheduleFlowEmptyStateTriggeredOnceSubHeading(
              formattedNextTriggerDate,
              formattedNextTriggerTime,
              getTimezoneAbbr(flowTriggerScheduleDetails),
              isShortcut,
              isFlowOwner,
            )}
          />
        );
      };

      const getPreloadedFlowDummyPostData = () => {
        const isPreloadedFlow = creationSource === 'PRELOAD';

        if (isPreloadedFlow) {
          if (ICEBREAKER_TEMPLATE_IDS.includes(templateId)) {
            const headerProps = {
              ...iceBreakerHeaderProps,
              flow: iceBreakerHeaderFlowProps,
            };

            return {
              ...iceBreakerDummyPostData,
              headerProps: headerProps,
            };
          }
          if (GENERAL_NEWS_FEED_TEMPLATE_IDS.includes(templateId)) {
            const headerProps = {
              ...generalNewFeedHeaderProps,
              flow: generalNewFeedHeaderFlowProps,
            };

            return {
              ...generalNewsFeedDummyPostData,
              headerProps: headerProps,
            };
          }
          return null;
        }

        return null;
      };

      const preLoadedFlowDummyPostData = getPreloadedFlowDummyPostData();

      if (isFlowsFeedFilterApplied) {
        return (
          <EmptyErrorStateTemplate
            image={searching}
            variant={HeadingType.Bold}
            heading={FLOW_FEED_STATES.NO_MATCHING_POSTS.HEADING}
            subHeading={FLOW_FEED_STATES.NO_MATCHING_POSTS.SUB_HEADING}
          />
        );
      }
      if (preLoadedFlowDummyPostData && profileData) {
        return (
          <DummyFlowFeedPost
            currentUser={profileData.member}
            {...preLoadedFlowDummyPostData}
          />
        );
      }
      if (isFlowOwner) {
        if (isFlowParticipant || isFlowViewer) {
          if (!hasActiveOccurrence) {
            return (
              <>
                {kind === 'SCHEDULED' && renderScheduleFlowEmptyStates()}
                {kind === 'ONDEMAND' && (
                  <EmptyErrorStateTemplate
                    image={setAccountInfo}
                    variant={HeadingType.Bold}
                    heading={FLOW_FEED_STATES.FLOW_NOT_TRIGGERED.HEADING}
                    subHeading={FLOW_FEED_STATES.FLOW_NOT_TRIGGERED.SUB_HEADING}
                    button={{
                      text: `${FLOW_FEED_STATES.FLOW_NOT_TRIGGERED.BUTTON_TEXT}`,
                      icon: `${FLOW_FEED_STATES.FLOW_NOT_TRIGGERED.BUTTON_ICON}`,
                      onClick: openRunNowModal,
                    }}
                  />
                )}
                {kind === 'NO_TRIGGER' && (
                  <EmptyErrorStateTemplate
                    image={searching}
                    variant={HeadingType.Bold}
                    heading={FLOW_FEED_STATES.NO_POSTS.HEADING}
                    subHeading={FLOW_FEED_STATES.NO_POSTS.SUB_HEADING}
                  />
                )}
                <FlowRunNowModal
                  isOpen={isTriggerFlowModalOpen}
                  modalHeading={getFlowNameWithEmoticon(flowDetailsData)}
                  onModalClose={setTriggerFlowModalClose}
                  value={dueDateValue}
                  onChange={handleDueDateValueChange}
                  participants={participants}
                  participantsCount={participantsCount}
                  occurrenceValue={occurrenceValue}
                  onOccurrenceChange={setOccurrenceValue}
                  onRunNowButtonClick={onRunNowButtonClick}
                  isLoading={isTriggerFlowLoading}
                  hasMoreMembers={hasMoreMembers}
                  fetchNextPage={fetchMoreMembers}
                  visibilityCriteria={flowVisibilityCriteria}
                  pendingMembersParticipantCount={
                    pendingMembersParticipantCount
                  }
                />
                <FlowCreatedModal
                  isOpen={isFlowCreatedModalOpen || Boolean(isFromQuickSetup)}
                  onModalClose={onFlowCreateModalClose}
                  heading={BUILDER_FLOW_CREATED_MODAL.HEADING}
                  primaryButton={getConfirmationModalButtonProps.primaryButton}
                  secondaryButton={
                    getConfirmationModalButtonProps.secondaryButton
                  }
                  isFromQuickSetup={isFromQuickSetup}
                >
                  <StyledModalBodyContent variant="body2">
                    {renderCreatedFlowModalText()}
                  </StyledModalBodyContent>
                  <StyledImage
                    src={CelebrationImage}
                    alt={`flow created for ${flowName}`}
                  />
                </FlowCreatedModal>
              </>
            );
          }
          return (
            <EmptyErrorStateTemplate
              image={searching}
              variant={HeadingType.Bold}
              heading={FLOW_FEED_STATES.NO_POSTS.HEADING}
              subHeading={FLOW_FEED_STATES.NO_POSTS.SUB_HEADING}
            />
          );
        }
        return (
          <EmptyErrorStateTemplate
            image={setAccountInfo}
            variant={HeadingType.Bold}
            heading={FLOW_FEED_STATES.FLOW_NOT_VISIBLE.HEADING}
            subHeading={FLOW_FEED_STATES.FLOW_NOT_VISIBLE.SUB_HEADING}
          />
        );
      }
      if (isFlowParticipant || isFlowViewer) {
        if (!hasActiveOccurrence) {
          if (kind === 'SCHEDULED') {
            return renderScheduleFlowEmptyStates();
          }
        }
        return (
          <EmptyErrorStateTemplate
            image={searching}
            variant={HeadingType.Bold}
            heading={FLOW_FEED_STATES.NO_POSTS.HEADING}
            subHeading={FLOW_FEED_STATES.NO_POSTS.SUB_HEADING}
          />
        );
      }
    }

    if (!flowDetailsData && flowDetailError) {
      return (
        <FeedsContainer>
          <EmptyErrorStateTemplate
            image={searching}
            variant={HeadingType.Bold}
            heading={FLOW_FEED_STATES.FLOW_NOT_FOUND.HEADING}
            subHeading={FLOW_FEED_STATES.FLOW_NOT_FOUND.SUB_HEADING}
            button={{
              text: `${FLOW_FEED_STATES.FLOW_NOT_FOUND.BUTTON_TEXT}`,
              icon: `${FLOW_FEED_STATES.FLOW_NOT_FOUND.BUTTON_ICON}`,
              onClick: goToMainFeed,
            }}
          />
        </FeedsContainer>
      );
    }

    return null;
  };

  let actionBarText = '';
  let isOccurrenceClosed = false;
  const isAnonymityEnabled =
    flowDetailsData?.responseSettings.anonymity.state ===
    AnonymityStates.ENABLED;

  if (flowDetailsData) {
    const emoji = mapHexCodeToEmoticon(flowDetailsData.icon.value);
    const actionBarAnswerText = `${ANSWER} ${emoji} ${flowDetailsData.name}`;
    const actionBarAnsweredText = `${YOU} ${capitalizeFirstLetter(
      ANSWERED,
    )} ${emoji} ${flowDetailsData.name}`;
    const actionBarClosedText = `${emoji} ${flowDetailsData.name} ${IS_CLOSED}`;
    const answerAnonymouslyActionBarText = `${ANONYMOUSLY} ${ANSWER.toLowerCase()} ${emoji} ${
      flowDetailsData.name
    }`;

    actionBarText = isAnonymityEnabled
      ? answerAnonymouslyActionBarText
      : actionBarAnswerText;

    if (!flowDetailsData.isShortcut) {
      if (flowDetailsData.occurrence?.activeOccurrence) {
        if (flowDetailsData.occurrence.activeOccurrence.hasResponded) {
          isOccurrenceClosed = true;
          actionBarText = actionBarAnsweredText;
        }
      } else {
        isOccurrenceClosed = true;
        actionBarText = actionBarClosedText;
      }
    }
  }

  const canShowNotification =
    flowDetailsData?.occurrence?.activeOccurrence &&
    !flowDetailsData?.occurrence.activeOccurrence.hasResponded;

  const ScheduleEndedBannerElement = () => {
    if (flowDetailsData) {
      const emoji = mapHexCodeToEmoticon(flowDetailsData.icon.value);
      const { isFlowOwner, isShortcut } = flowDetailsData;
      if (isFlowOwner) {
        if (isShortcut) {
          return (
            <Body variant="body3">
              {FLOW_OWNER_ENDED_INFO} {emoji} {flowDetailsData.name} or{' '}
              <LinkButton type="button" onClick={openArchiveModal}>
                {ARCHIVE_IT}
              </LinkButton>
              . {FLOW_OWNER_SHORTCUT_ENDED_INFO}
            </Body>
          );
        }
        return (
          <Body variant="body3">
            {FLOW_OWNER_ENDED_INFO} {emoji} {flowDetailsData.name} or &nbsp;
            <LinkButton type="button" onClick={openArchiveModal}>
              {ARCHIVE_IT}
            </LinkButton>
          </Body>
        );
      }
      if (isShortcut) {
        return <Body variant="body3">{FLOW_SHORTCUT_ENDED_INFO}</Body>;
      }
      return <Body variant="body3">{FLOW_ENDED_INFO}</Body>;
    }

    return null;
  };

  const linkAccessType = flowDetails?.data.accessPolicy?.linkAccess.type;

  const canShowActionBar =
    flowDetailsData &&
    profileData &&
    (flowDetailsData.isFlowParticipant ||
      linkAccessType === LinkAccessType.EXTERNAL ||
      linkAccessType === LinkAccessType.INTERNAL);

  return (
    <FeedsContainer>
      {canShowScheduleEndedBanner && (
        <StyledInfoAlert
          id="flow-state-info-message"
          text={ScheduleEndedBannerElement()}
          icon="info-circle"
          alertType="info"
        />
      )}
      {canShowActionBar && (
        <StyledActionBarWrapper>
          <ActionBarContainer
            avatar={profileData.member.profile.image}
            userName={profileData.member.profile.username}
            userId={profileData.member.memberId}
            feedType={FeedTypes.Flow}
            notification={
              canShowNotification
                ? `${FLOW_ACTION_BAR_NOTIFICATION_TEXT} ● Due in ${getDueTime(
                    flowDetailsData.occurrence?.activeOccurrence?.endTime,
                  )}`
                : ''
            }
            text={actionBarText}
            onClick={handleActionBarClick}
            isDisabled={
              linkAccessType === LinkAccessType.EXTERNAL
                ? isOccurrenceClosed
                : isOccurrenceClosed || !flowDetailsData.isFlowParticipant
            }
            isAnonymous={isAnonymityEnabled}
          />
        </StyledActionBarWrapper>
      )}
      {data && data.length > 0 && (
        <Flex justifyContent="space-between" margin="0 auto 4px">
          <div />
          <div>
            <StyledDropdown
              menuItems={feedSortItems}
              size={DropdownSizes.Small}
              variant={DropdownVariants.Text}
              color={DropdownColor.Secondary}
              value={PostSortValues[flowFeedsSortValue]}
              dropdownPlacement={DropdownPlacement.BottomEnd}
              onItemClick={(val) => handleFeedSortClick(val)}
            />
          </div>
        </Flex>
      )}
      {renderEmptyState()}
      <BasicModalTemplate
        isOpen={isArchiveModalOpen}
        heading={FLOWS_ARCHIVE_MODAL.MODAL_HEADING}
        primaryButton={{
          text: `${FLOWS_ARCHIVE_MODAL.ARCHIVE_LABEL}`,
          onClick: handleOnArchiveClick,
          status: 'warning',
          isLoading: isArchiveFlowLoading,
        }}
        secondaryButton={{
          text: `${FLOWS_ARCHIVE_MODAL.CANCEL_LABEL}`,
          onClick: setArchiveModalClose,
        }}
        onModalClose={setArchiveModalClose}
      >
        <Body variant="body2" inline>
          {`${WHEN} ${YOU.toLocaleLowerCase()} ${ARCHIVE.toLocaleLowerCase()} `}
        </Body>
        <Body variant="body2Medium" inline>
          <EmoticonWithText
            emoticon={emoticon}
            text={flowDetailsData?.name}
            hasBoldText
          />
        </Body>
        <Body variant="body2" inline>
          {', '}
          {FLOWS_ARCHIVE_MODAL.MODAL_CONTENT_TEXT}
        </Body>
      </BasicModalTemplate>
      {profileData && data.length > 0 && (
        <InfiniteScroll
          next={handleFetchNextPage}
          hasMore={hasNextPage}
          dataLength={data.length}
          loader={<FeedPostLoader />}
          style={{ overflow: 'inherit' }}
          endMessage={<BottomOfFeedMessage />}
          scrollableTarget="scrollableFeedContent"
        >
          {showLoadMoreButton && (
            <ShowMoreButton
              size="small"
              variant="showMore"
              onClick={onLoadMoreClick}
              icon="arrow-thick-up"
            >
              {SEE_NEW_POSTS}
            </ShowMoreButton>
          )}
          {data.map((feed) => (
            <FlowPostController
              key={feed.responseId}
              post={feed}
              onReactionSet={onReactionSet}
              onReactionUnSet={onReactionUnSet}
              onMemberClick={onMemberClick}
              profileData={profileData}
            />
          ))}
        </InfiniteScroll>
      )}
    </FeedsContainer>
  );
};

export default FlowsFeedController;
