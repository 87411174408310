import { useEffect } from 'react';
import { ADMIN_APP_URL } from '../../config';
import { withRouter } from 'react-router-dom';

const NewAdminRoutesComponent = () => {
  useEffect(() => {
    if (window.location.href.includes(`${window.location.origin}/admin`)) {
      window.location.href = window.location.href.replace(
        `${window.location.origin}/admin`,
        ADMIN_APP_URL,
      );
    } else {
      window.location.href = window.location.href.replace(
        `${window.location.origin}`,
        ADMIN_APP_URL,
      );
    }
  });

  return <></>;
};

export default withRouter(NewAdminRoutesComponent);
