import React from 'react';

import Body from '../../atoms/Body';
import { BOTTOM_OF_FEED_MESSAGE } from '../../../languages/en/home';
import { Flex } from '../../../Utils/styles/display';

const BottomOfFeedMessage = () => (
  <Flex justifyContent="center">
    <Body color="gray8" variant="body2">
      {BOTTOM_OF_FEED_MESSAGE}{' '}
      <span aria-label="index finger pointing up" role="img">
        ☝️
      </span>
    </Body>
  </Flex>
);

export default BottomOfFeedMessage;
