import React, { useState } from 'react';

import '../styles/video.scss';
import Loading from '../loading';

const AudioViewer = (props: { filePath: string }) => {
  const [loading, setLoading] = useState(true);

  return (
    <div className="pg-driver-view">
      <div className="video-container">
        {loading && <Loading />}
        <audio
          className={loading ? 'video-loading' : ''}
          controls
          onCanPlay={() => setLoading(false)}
          src={props.filePath}
        >
          Audio playback is not supported by your browser.
        </audio>
      </div>
    </div>
  );
};

export default AudioViewer;
