import { useState, useLayoutEffect } from 'react';

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<{
    width: number;
    height: number;
  }>({
    width: 0,
    height: 0,
  });
  useLayoutEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener('resize', handleResize);

    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isMobileWidth = windowSize.width <= 768;
  const isTabletWidth = windowSize.width <= 1020;
  const isDesktopWidth = windowSize.width >= 1021;

  return { windowSize, isMobileWidth, isTabletWidth, isDesktopWidth };
};

export default useWindowSize;
