import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import {
  CheckboxFilterLoadedProps,
  CheckboxFilterValues,
} from '../../../../atomic/organism/CheckboxFilter/types';
import { DateFilterProps } from '../../../../atomic/organism/DateFilter/types';
import { SelectedMainFeedFilterValueProps } from '../../../../atomic/organism/RightDrawerFilter/MainFeedFilter/types';
import { ComponentStatus } from '../../../../interfaces/component';
import { FeedItemFromAPI, PostSortOptions } from '../../../../interfaces/Feed';
import {
  FILTER_BY_ANOTHER_FLOW,
  FILTER_BY_ANOTHER_MEMBER,
  POSTED_BY,
  SELECT_FLOWS,
  SELECT_PERSON,
} from '../../../../languages/en/flows';
import { DATES, FLOW, MENTIONED } from '../../../../languages/en/singleWords';
import { useGetMainFeedQuery } from '../../../../queries/MainFeed';
import {
  useGetDateFilterOptionsQuery,
  useGetFlowsFilterOptionsQuery,
  useGetMentionsFilterOptionsQuery,
  useGetPostedByFilterOptionsQuery,
} from '../../../../queries/RightDrawer/Filter';
import { getFeedArrayFromQueryData } from '../../../../Utils/home/feeds/reactions';

import { capitalizeFirstLetter } from '../../../../Utils/text';
import {
  createQueryParam,
  getPostedByOptionsWithAnonymousFilter,
  mapDataToFilterOptions,
  mapPersonDataToFilterOptions,
  serializeFilterValues,
} from '../../utils';

import { V2_HOME } from '../../../../constants/routes';
import useGetParsedFilterOptions from '../../../../hooks/useGetParsedFilterOptions';
import { QueryStringTypes } from '../../types';

import isEqual from 'lodash/isEqual';
import useGetPageName from '../../../../hooks/useGetPageName';
import { trackRightDrawerEvent } from '../../../../Utils/analytics';
import { RIGHT_DRAWER_EVENTS } from '../../../../Utils/analytics/constants';
import { DrawerTabType } from '../../../../Utils/analytics/interfaces';
import {
  getAppliedFilter,
  GetAppliedFilterProps,
  getFiltersForAnonymousPostedBy,
} from '../../../../Utils/rightDrawer';

const useFilterControllerLogic = () => {
  const { search, pathname } = useLocation();
  const history = useHistory();
  const {
    receivedStartDate,
    receivedEndDate,
    receivedFlowsID,
    receivedPostedById,
    receivedMentionedById,
    parsedParams,
    isAnonymousPostedBy,
  } = useGetParsedFilterOptions();

  const { page } = useGetPageName();

  const drawerTabViewed: DrawerTabType = 'filter';

  const {
    data: mainFeedData,
    isLoading: isMainFeedDataLoading,
    isError: isMainFeedDataError,
  } = useGetMainFeedQuery({ feedsSort: PostSortOptions.DESC, filter: {} });
  const {
    data: flowsData,
    isLoading: isFlowsDataLoading,
    isError: isFlowsDataError,
  } = useGetFlowsFilterOptionsQuery();
  const {
    data: postedByData,
    isLoading: isPostedByLoading,
    isError: isPostedByError,
  } = useGetPostedByFilterOptionsQuery();
  const {
    data: dateFilterData,
    isLoading: isDateFilterLoading,
    isError: isDateFilterError,
  } = useGetDateFilterOptionsQuery();
  const {
    data: mentionsData,
    isLoading: isMentionDataLoading,
    isError: isMentionsDataError,
  } = useGetMentionsFilterOptionsQuery();

  const [startDateValue, setStartDate] = useState<Date | undefined>(() =>
    receivedStartDate !== undefined ? new Date(receivedStartDate) : undefined,
  );
  const [endDateValue, setEndDate] = useState<Date | undefined>(() =>
    receivedEndDate !== undefined ? new Date(receivedEndDate) : undefined,
  );
  const [flowSelectedOptions, setFlowSelectedOptions] =
    useState<string[]>(receivedFlowsID);
  const [postedBySelectedOptions, setPostedSelectedByOptions] =
    useState<string[]>(receivedPostedById);
  const [mentionedSelectedOptions, setMentionedSelectedOptions] = useState<
    string[]
  >(receivedMentionedById);
  const [isEmpty, setIsEmpty] = useState<boolean>(false);
  const [isAnonymousPostedByFilter, setAnonymousPostedByFilter] =
    useState(isAnonymousPostedBy);

  const feedData: FeedItemFromAPI[] = useMemo(() => {
    return getFeedArrayFromQueryData(mainFeedData);
  }, [mainFeedData]);

  const initialStartDate = useMemo(() => {
    if (dateFilterData?.startDate) {
      return new Date(dateFilterData?.startDate);
    }
    return undefined;
  }, [dateFilterData]);

  const flowsDataOptions: CheckboxFilterValues = useMemo(() => {
    if (flowsData?.data) {
      return mapDataToFilterOptions(flowsData, 'flows');
    }
    return [];
  }, [flowsData]);

  const postedByOptions: CheckboxFilterValues = useMemo(() => {
    if (postedByData?.data) {
      const postedByOptionsFromData = mapPersonDataToFilterOptions(
        postedByData,
        'postedBy',
      );
      if (postedBySelectedOptions && postedBySelectedOptions?.length > 0) {
        return getPostedByOptionsWithAnonymousFilter(
          postedByOptionsFromData,
          isAnonymousPostedByFilter,
        );
      }
      return postedByOptionsFromData;
    }
    return [];
  }, [isAnonymousPostedByFilter, postedByData, postedBySelectedOptions]);

  const mentionedOptions: CheckboxFilterValues = useMemo(() => {
    if (mentionsData?.data) {
      return mapPersonDataToFilterOptions(mentionsData, 'mentionedBy');
    }
    return [];
  }, [mentionsData]);

  useEffect(() => {
    const { isAnonymous } = getFiltersForAnonymousPostedBy(
      postedBySelectedOptions,
    );
    setAnonymousPostedByFilter(isAnonymous);
  }, [postedBySelectedOptions]);

  const collapseClickHandler = useCallback(
    (isCollapseOpen: boolean) => {
      if (isCollapseOpen) {
        trackRightDrawerEvent(RIGHT_DRAWER_EVENTS.FILTER_SECTION_COLLAPSED, {
          feedViewed: page,
          drawerTabViewed,
        });
      } else {
        trackRightDrawerEvent(RIGHT_DRAWER_EVENTS.FILTER_SECTION_EXPANDED, {
          feedViewed: page,
          drawerTabViewed,
        });
      }
    },
    [page],
  );

  const dateFilterProps: DateFilterProps = {
    status: ComponentStatus.LOADED,
    startDate: startDateValue,
    endDate: endDateValue,
    onStartDateChange: setStartDate,
    onEndDateChange: setEndDate,
    initialStartDate,
    headingText: DATES,
    onCollapseClick: collapseClickHandler,
  };

  const flowsFilterProps: CheckboxFilterLoadedProps = {
    status: ComponentStatus.LOADED,
    headingText: capitalizeFirstLetter(FLOW),
    filterByText: FILTER_BY_ANOTHER_FLOW,
    autoCompleteText: SELECT_FLOWS,
    options: flowsDataOptions,
    onSelectedOptionChange: setFlowSelectedOptions,
    selectedOptions: flowSelectedOptions,
    onCollapseClick: collapseClickHandler,
  };

  const postedByFilterProps: CheckboxFilterLoadedProps = {
    status: ComponentStatus.LOADED,
    headingText: POSTED_BY,
    filterByText: FILTER_BY_ANOTHER_MEMBER,
    autoCompleteText: SELECT_PERSON,
    options: postedByOptions,
    onSelectedOptionChange: setPostedSelectedByOptions,
    selectedOptions: postedBySelectedOptions,
    onCollapseClick: collapseClickHandler,
    disableFilterBy: isAnonymousPostedByFilter,
  };

  const mentionedFilterProps: CheckboxFilterLoadedProps = {
    status: ComponentStatus.LOADED,
    headingText: MENTIONED,
    filterByText: FILTER_BY_ANOTHER_MEMBER,
    autoCompleteText: SELECT_PERSON,
    options: mentionedOptions,
    onSelectedOptionChange: setMentionedSelectedOptions,
    selectedOptions: mentionedSelectedOptions,
    onCollapseClick: collapseClickHandler,
  };

  const selectedMainFeedFilterValue: SelectedMainFeedFilterValueProps = {
    selectedDates: {
      startDate: startDateValue,
      endDate: endDateValue,
    },
    selectedFlows: serializeFilterValues(flowSelectedOptions, 'flows'),
    selectedPostedBy: serializeFilterValues(
      postedBySelectedOptions,
      'postedBy',
    ),
    selectedMentionedBy: serializeFilterValues(
      mentionedSelectedOptions,
      'mentionedBy',
    ),
  };

  const handleOnSubmit = () => {
    /* The + character has a special meaning in a URL => it means whitespace - .
     If we want to use the literal + sign, we need to URL encode it to %2b: */
    // Ref: https://stackoverflow.com/questions/5450190/how-to-encode-the-plus-symbol-in-a-url
    // Thu Jul 01 2021 12:00:00 GMT+0530 (India Standard Time) - to get the plus from the query param
    const getAppliedFilterProps: GetAppliedFilterProps = {
      startDate: startDateValue,
      endDate: endDateValue,
      flowsId: flowSelectedOptions,
      postedBy: postedBySelectedOptions,
      mentioned: mentionedSelectedOptions,
    };

    const { postedBy: filteredPostedBy } = getFiltersForAnonymousPostedBy(
      selectedMainFeedFilterValue?.selectedPostedBy,
    );
    const startDate = startDateValue?.toString().replace('+', '%2b');
    const endDate = endDateValue?.toString().replace('+', '%2b');
    const flowsId = selectedMainFeedFilterValue.selectedFlows.join();
    const postedBy = filteredPostedBy.join();
    const mentionedBy = selectedMainFeedFilterValue.selectedMentionedBy?.join();

    const queryString: QueryStringTypes = {
      startDate,
      endDate,
      flowsId,
      postedBy,
      mentionedBy,
      isAnonymousPostedByFilter,
    };
    history.push(createQueryParam(queryString, '/home?'));

    const filterApplied = getAppliedFilter(getAppliedFilterProps);

    trackRightDrawerEvent(RIGHT_DRAWER_EVENTS.FILTERS_APPLIED, {
      filterApplied,
      feedViewed: page,
      drawerTabViewed,
    });
  };

  const handleOnClear = useCallback(() => {
    trackRightDrawerEvent(RIGHT_DRAWER_EVENTS.FILTERS_CLEARED, {
      feedViewed: page,
      drawerTabViewed,
    });

    setStartDate(undefined);
    setEndDate(undefined);
    setFlowSelectedOptions([]);
    setPostedSelectedByOptions([]);
    setMentionedSelectedOptions([]);

    history.push('/home');
  }, [history, page]);

  useEffect(() => {
    if (initialStartDate === undefined || feedData.length === 0) {
      setIsEmpty(true);
    } else {
      setIsEmpty(false);
    }
  }, [feedData, initialStartDate]);

  useEffect(() => {
    /* This is needed when we click Main/Home Feed we have to reset the filter */
    if (Object.keys(parsedParams).length === 0 && pathname === V2_HOME) {
      handleOnClear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, pathname]);

  const isStartDateEqual =
    startDateValue?.toString() === receivedStartDate && true;
  const isEndDateEqual = endDateValue?.toString() === receivedEndDate && true;
  const isFlowsIdEqual = isEqual(flowSelectedOptions, receivedFlowsID);
  const isPostedByEqual = isEqual(postedBySelectedOptions, receivedPostedById);
  const isMentionedByEqual = isEqual(
    mentionedSelectedOptions,
    receivedMentionedById,
  );

  const isFilterButtonEnabled =
    isStartDateEqual &&
    isEndDateEqual &&
    isFlowsIdEqual &&
    isPostedByEqual &&
    isMentionedByEqual;

  const isLoading =
    isMainFeedDataLoading ||
    isDateFilterLoading ||
    isFlowsDataLoading ||
    isPostedByLoading ||
    isMentionDataLoading;
  const isError =
    isMainFeedDataError ||
    isDateFilterError ||
    isFlowsDataError ||
    isPostedByError ||
    isMentionsDataError;

  return {
    models: {
      isAnonymousPostedByFilter,
      flowsFilterProps,
      postedByFilterProps,
      dateFilterProps,
      mentionedFilterProps,
      isLoading,
      isError,
      isEmpty,
      isFilterButtonEnabled,
    },
    operations: {
      handleOnSubmit,
      handleOnClear,
    },
  };
};

export default useFilterControllerLogic;
