import styled from 'styled-components';
import { Flex } from '../../../../Utils/styles/display';
import Body from '../../../atoms/Body';
import TextWithIcon from '../../../atoms/TextWithIcon';
import ThemeV2 from '../../../../componentsV2/theme';

export const PreviewBlockWrapper = styled.div`
  width: inherit;
  margin-top: 16px;
`;

export const PreviewDescription = styled(Body)`
  margin-bottom: 16px;
`;

export const OwnerBlock = styled(Flex)`
  margin-top: 8px;
`;

export const ContentDescription = styled(Body)`
  margin-top: 8px;
`;

export const StyledTextWithIcon = styled(TextWithIcon)`
  &.preview-block-content {
    align-items: center;
    margin-bottom: 8px;
  }
`;

export const ContentWrapper = styled.div`
  margin-top: 24px;
`;

export const ByLabel = styled(Body)`
  margin-right: 8px;
`;

export const OwnerName = styled(Body)`
  margin-left: 4px;
`;

export const StyledIconWrapper = styled(Flex)`
  border-radius: 50%;
  background-color: ${ThemeV2.palette.volcano6};
  padding: 4px;
`;
