import React, { useEffect } from 'react';
import shallow from 'zustand/shallow';
import NotificationSettingsController from '../../NotificationSettings';
import SettingsController from '../../SettingsController';
import { SecondaryPanel } from '../../../interfaces/Layout';

import {
  V2_GENERAL_SETTINGS,
  V2_NOTIFICATION_SETTINGS,
} from '../../../constants/routes';
import useLayoutStore from '../../../stores/layoutStore';
import { Route, Switch, Redirect } from 'react-router-dom';

const GeneralSettingsPage = () => (
  <div>
    <SettingsController />
  </div>
);

const UserSettingsController = () => {
  const {
    setActiveNavItem,
    setActiveSecondaryPanel,
    setRightAsideOpenToFalse,
  } = useLayoutStore(
    (state) => ({
      setActiveNavItem: state.setActiveNavItem,
      setActiveSecondaryPanel: state.setActiveSecondaryPanel,
      setRightAsideOpenToFalse: state.setRightAsideOpenToFalse,
    }),
    shallow,
  );

  useEffect(() => {
    setActiveNavItem(SecondaryPanel.PROFILE);
    setActiveSecondaryPanel(SecondaryPanel.PROFILE);
    setRightAsideOpenToFalse();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Switch>
        <Route
          exact
          path={V2_GENERAL_SETTINGS}
          component={GeneralSettingsPage}
        />
        <Route
          exact
          path={V2_NOTIFICATION_SETTINGS}
          component={NotificationSettingsController}
        />
        <Redirect to={V2_GENERAL_SETTINGS} />
      </Switch>
    </>
  );
};

export default UserSettingsController;
