import React from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import ThemeV2 from '../../../componentsV2/theme';
import { V2_USER_PROFILE } from '../../../constants/routes';
import { MemberRecognitionCount } from '../../../interfaces/Feed';
import { SelectedMember } from '../../../interfaces/Layout';
import { MemberDetails } from '../../../interfaces/MemberDetails';
import Divider from '../../atoms/Divider';
import ProfileCelebrationStatisticsSection from '../../molecules/ProfileCelebrationStatisticsSection';
import ProfileDetailsSection from '../../molecules/ProfileDetailsSection';
import ProfileHeaderSection from '../../molecules/ProfileHeaderSection';
import { DividerWrapper } from './styles';

interface ProfileViewProps {
  isCurrentUser: boolean;
  manager?: {
    firstName: string;
    lastName: string;
    memberId: string;
    profilePicture: string;
  };
  memberDetails: MemberDetails;
  directReportsDetails: MemberDetails[];
  memberRecognitionCount: MemberRecognitionCount;
  toggleRightAsideOpen: () => void;
  setSelectedMemberProfile: (selectedMember: SelectedMember) => void;
}

const ProfileView = ({
  isCurrentUser,
  manager,
  directReportsDetails,
  memberDetails,
  memberRecognitionCount,
  toggleRightAsideOpen,
  setSelectedMemberProfile,
}: ProfileViewProps) => {
  const { email, memberId, timeZone, state } = memberDetails;
  const {
    birthday,
    department,
    firstName,
    hiredDay,
    image,
    lastName,
    username,
    jobTitle,
    location,
  } = memberDetails.profile;

  const currentLocation = useLocation();
  const matched = matchPath<{ userId: string }>(currentLocation.pathname, {
    path: V2_USER_PROFILE,
  });
  const activityFeedMemberId = matched?.params.userId;
  const shouldDisplayViewActivtyLink =
    activityFeedMemberId !== memberDetails.memberId;

  return (
    <>
      <ProfileHeaderSection
        profilePicture={image}
        isCurrentUser={isCurrentUser}
        firstName={firstName}
        lastName={lastName}
        memberId={memberId}
        shouldDisplayViewActivtyLink={shouldDisplayViewActivtyLink}
        username={username}
        state={state}
      />
      <DividerWrapper>
        <Divider color={ThemeV2.palette.gray5} isFullWidth />
      </DividerWrapper>

      <ProfileCelebrationStatisticsSection
        {...memberRecognitionCount}
        hiredDay={hiredDay}
      />

      <DividerWrapper>
        <Divider color={ThemeV2.palette.gray5} isFullWidth />
      </DividerWrapper>

      <ProfileDetailsSection
        birthday={birthday}
        department={department}
        isCurrentUser={isCurrentUser}
        manager={manager}
        timeZone={timeZone}
        jobTitle={jobTitle}
        location={location}
        setSelectedMemberProfile={setSelectedMemberProfile}
        directReportsDetails={directReportsDetails}
        workAnniversary={hiredDay}
        email={email}
        toggleRightAsideOpen={toggleRightAsideOpen}
      />
    </>
  );
};

export default ProfileView;
