import React from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import shallow from 'zustand/shallow';

import FlowsFeedController from '../FlowsFeedController';
import { FeedsContainer } from '../FlowsFeedController/styles';
import FlowsFeedPostLoader from '../FlowsFeedController/Loader';
import {
  useFetchFlowDetailsQuery,
  useVerifyFlowAuthorization,
} from '../../../queries/Flows/Feed';
import FlowsBuilderTabsController from '../../flowsBuilder/FlowsBuilderTabsController';
import { getErrorStatusCode } from '../../../Utils/message';
import useLayoutStore from '../../../stores/layoutStore';
import { FLOW_FEED_STATES } from '../../../languages/en/flows/feed';
import { AxiosError } from 'axios';
import EmptyErrorStateTemplate from '../../../atomic/molecules/EmptyErrorStateTemplate';
import searching from '../../../img/searching.svg';
import { HeadingType } from '../../../atomic/molecules/EmptyErrorStateTemplate/interfaces';
import { EXTERNAL_FLOW_PATH, V2_HOME } from '../../../constants/routes';

const FlowsController = () => {
  const { flowId } = useParams<{ flowId: string }>();

  const { isLoading, data, isError, error } = useFetchFlowDetailsQuery(flowId);
  const { data: flowAuthorizationData, isLoading: isFlowAuthorizationLoading } =
    useVerifyFlowAuthorization(flowId, isError);
  const { isRightAsideOpen, toggleRightAsideOpen } = useLayoutStore(
    (state) => ({
      isRightAsideOpen: state.isRightAsideOpen,
      toggleRightAsideOpen: state.toggleRightAsideOpen,
    }),
    shallow,
  );

  const { push } = useHistory();

  if (isLoading || isFlowAuthorizationLoading) {
    return (
      <FeedsContainer>
        <FlowsFeedPostLoader />
      </FeedsContainer>
    );
  }

  const goToMainFeed = () => {
    push(V2_HOME);
  };

  if (
    isError &&
    flowAuthorizationData &&
    !isFlowAuthorizationLoading &&
    flowAuthorizationData?.data.isLoggedIn &&
    flowAuthorizationData.data.participation === 'EXTERNAL'
  ) {
    return <Redirect to={EXTERNAL_FLOW_PATH.replace(':flowId', flowId)} />;
  }

  if (isError) {
    const flowDetailErrorCode = getErrorStatusCode(error as AxiosError);
    if (isRightAsideOpen) {
      toggleRightAsideOpen();
    }

    let heading = FLOW_FEED_STATES.FLOW_NOT_FOUND.HEADING;
    let subHeading = FLOW_FEED_STATES.FLOW_NOT_FOUND.SUB_HEADING;

    if (flowDetailErrorCode === 403) {
      heading = FLOW_FEED_STATES.ACCESS_DENIED.HEADING;
    }

    if (flowDetailErrorCode === 500) {
      heading = FLOW_FEED_STATES.SERVER_ERROR.HEADING;
      subHeading = FLOW_FEED_STATES.SERVER_ERROR.SUB_HEADING;
    }

    return (
      <FeedsContainer>
        <EmptyErrorStateTemplate
          image={searching}
          variant={HeadingType.Bold}
          heading={heading}
          subHeading={subHeading}
          button={{
            text: `${FLOW_FEED_STATES.FLOW_NOT_FOUND.BUTTON_TEXT}`,
            icon: `${FLOW_FEED_STATES.FLOW_NOT_FOUND.BUTTON_ICON}`,
            onClick: goToMainFeed,
          }}
        />
      </FeedsContainer>
    );
  }

  return data?.data.isFlowOwner ? (
    <FlowsBuilderTabsController />
  ) : (
    <FlowsFeedController />
  );
};

export default FlowsController;
