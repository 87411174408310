import React, { useState, useEffect } from 'react';
import InfiniteLoader from 'react-window-infinite-loader';
import AutoSizer from 'react-virtualized-auto-sizer';

import styled from 'styled-components';
import ThemeV2 from '../../../componentsV2/theme';
import KnowledgeCenterListItem from '../../../atomic/molecules/KnowledgeCenterListItem';
import Body from '../../../atomic/atoms/Body';
import { FixedSizeList } from 'react-window';
import { InfiniteData } from 'react-query';
import {
  FeedAttachmentItemFromAPI,
  GetFeedAttachmentResponse,
} from '../../../interfaces/Feed';

const StyledRowHeaders = styled.div`
  margin-top: 8px;
  height: 48px;
  width: 100%;
  display: flex;
  border-bottom: 1px solid ${ThemeV2.palette.gray4};
  padding: 8px;
`;

const KnowledgeCenterController = (props: {
  data: InfiniteData<GetFeedAttachmentResponse>;
  hasNextPage: boolean | undefined;
  handleFetchNextPage: () => void;
  handleSetSelectedFile: (value: FeedAttachmentItemFromAPI | undefined) => void;
  copyPostLink: (flowId: string, responseId: string) => void;
  onDownloadFileClick: (
    fileDownload: FeedAttachmentItemFromAPI,
  ) => Promise<void>;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const {
    data,
    hasNextPage,
    handleFetchNextPage,
    handleSetSelectedFile,
    copyPostLink,
    onDownloadFileClick,
    setModalOpen,
  } = props;
  const [flattenedData, setFlattenedData] = useState<
    FeedAttachmentItemFromAPI[]
  >([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemCount, setItemCount] = useState(0);

  useEffect(() => {
    if (data?.pages[currentPage]) {
      const mergedArrays = [...flattenedData, ...data?.pages[currentPage].data];
      setFlattenedData(mergedArrays);
      setCurrentPage(currentPage + 1);
      setItemCount(hasNextPage ? mergedArrays.length + 1 : mergedArrays.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const isItemLoaded = (index: number) => {
    return !hasNextPage || index < flattenedData.length;
  };

  /* @ts-ignore-next-line */
  const RowItem = ({ index, style }) => {
    const listItem = flattenedData[index];
    if (!isItemLoaded(index)) {
      return <div style={style}> Hello! </div>;
    } else if (listItem) {
      return (
        <KnowledgeCenterListItem
          key={
            listItem.response.blockId +
            listItem.fileInfo.name +
            listItem.response.responseId
          }
          listItem={listItem}
          handleSetSelectedFile={handleSetSelectedFile}
          openPreviewer={setModalOpen}
          onClickDownload={(listItemFile: FeedAttachmentItemFromAPI) =>
            onDownloadFileClick(listItemFile)
          }
          copyPostLink={copyPostLink}
          index={index}
          style={style}
        />
      );
    } else {
      return <div style={style}> All loaded! </div>;
    }
  };

  return (
    <>
      <StyledRowHeaders>
        <Body style={{ width: '50%' }} variant="body1">
          File
        </Body>
        <Body style={{ width: '25%' }} variant="body1">
          Uploaded by
        </Body>
        <Body style={{ width: '25%' }} variant="body1">
          Date shared
        </Body>
      </StyledRowHeaders>
      <AutoSizer>
        {({ height, width }) => (
          <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={itemCount}
            loadMoreItems={handleFetchNextPage}
            minimumBatchSize={20}
          >
            {({ onItemsRendered, ref }) => (
              <FixedSizeList
                itemCount={itemCount}
                onItemsRendered={onItemsRendered}
                ref={ref}
                className="List"
                itemSize={48}
                height={height - 88}
                width={width}
              >
                {RowItem}
              </FixedSizeList>
            )}
          </InfiniteLoader>
        )}
      </AutoSizer>
    </>
  );
};

export default KnowledgeCenterController;
