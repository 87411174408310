import styled from 'styled-components';

import Image from '../../atoms/Image';
import Body from '../../atoms/Body';
import ClickableLink from '../ClickableLink';
import Button from '../../atoms/Button';
import BasicModalTemplate from '../../templates/BasicModalTemplate';

import ThemeV2 from '../../../componentsV2/theme';
import { Flex } from '../../../Utils/styles/display';
import { device } from '../../../constants/layout';

export const StyledGiftCardImage = styled.img`
  min-width: 215px;
  max-width: 215px;
`;

export const StyledGiftCardBody = styled(Body)`
  margin: 12px 0 24px;
`;

export const StyledModal = styled(BasicModalTemplate)`
  @media ${device.mobile}, ${device.tablet} {
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    bottom: unset;
    min-width: unset;
    width: 90%;
    > div {
      max-height: 300px;
      padding: 16px;
    }
  }
`;

export const StyledFlexWrapper = styled(Flex)`
  background: ${ThemeV2.palette.gray1};
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  @media ${device.mobile}, ${device.tablet} {
    padding: 12px;
    display: block;
  }
`;

export const StyledFlexInnerWrapper = styled(Flex)`
  @media ${device.mobile}, ${device.tablet} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const StyledImage = styled(Image)`
  border-radius: 4px;
  margin-right: 16px;
  @media ${device.mobile}, ${device.tablet} {
    width: 100%;
    height: 150px;
    object-fit: contain;
    margin-bottom: 8px;
  }
`;

export const StyledBody = styled(Body)`
  margin-right: 4px;
`;

export const StyledClickableLink = styled(ClickableLink)`
  font-weight: 400;
`;

export const StyledToolTipContent = styled.div`
  width: 200px;
`;

export const StyledLoaderWrapper = styled.div`
  min-width: 300px;
  max-width: 100%;
`;

export const StyledButton = styled(Button)`
  @media ${device.mobile}, ${device.tablet} {
    margin: 8px auto 0;
  }
`;

export const StyledDetails = styled(Body)`
  margin-top: 16px;
`;
