import styled from 'styled-components';
import { Flex } from '../../../Utils/styles/display';
import Body from '../../../atomic/atoms/Body';
import InfoAlert from '../../../atomic/molecules/InfoAlert';
import Button from '../../../atomic/atoms/Button';
import BubbleText from '../../../atomic/atoms/BubbleText';

export const Header = styled(Body)`
  padding: 10px 2px;
`;

export const NotebookContainer = styled.section`
  max-width: 643px;
  margin: 24px auto;
`;

export const AccordionEmptyText = styled(Body)`
  padding: 4px 12px 4px 38px;
`;

export const StyledInfoAlert = styled(InfoAlert)`
  margin: 4px 0 4px 32px;
  width: auto;
`;

export const ShowMoreButton = styled(Button)`
  text-align: center;
  cursor: pointer;
`;

export const ShowMoreWrapper = styled(Flex)`
  width: 100%;
  padding: 10px;
`;

export const NotebookTaskLoaderWrapper = styled.div`
  margin-top: 16px;
`;

export const ErrorTextWrapper = styled(Flex)`
  margin-left: 16px;
`;

export const ErrorText = styled(Body)`
  margin-left: 16px;
`;

export const DragIconWrapper = styled.div`
  margin: 4px;
  min-width: 14px;
`;

export const UnreadCount = styled(BubbleText)`
  margin-left: 8px;
`;
