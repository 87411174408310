import styled from 'styled-components';
import ThemeV2 from '../../../componentsV2/theme';
import TabComponent from '../../../atomic/molecules/TabComponent';

export const FlowFeedsContainer = styled.div`
  padding: 32px 0;
`;

export const SisenseFrameWrapper = styled.section`
  visibility: hidden;
  height: 0;
  width: 0;
  iframe {
    width: 0;
    height: 0;
  }
`;

export const BetaLabel = styled.div`
  padding: 0 8px;
  font-size: 10px;
  margin-top: 4px;
  margin-left: 8px;
  border-radius: 10px;
  background: currentColor;

  div {
    color: ${ThemeV2.palette.white};
  }
`;

export const StyledTabComponent = styled(TabComponent)`
  .MuiTab-wrapper {
    flex-direction: row-reverse;
  }
`;
