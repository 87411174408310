/* eslint-disable max-len */

export const shareSheetModalIntroStrings = () => ({
  firstStep: {
    intro: `Click here to select who can respond to this flow`,
  },
  secondStep: {
    intro: `Click here to select who can see the responses to this flow`,
  },
  thirdStep: {
    intro: `Click done to finalize
    your selection.
    
    Happy sharing!`,
  },
});

export const shareSheetButtonIntroStrings = () => ({
  firstStep: {
    intro: 'Welcome to the new way to share your flows',
  },
  secondStep: {
    intro:
      'There’s a new way to share your flows! Click Share to select who can participate in a flow and view its responses',
  },
});

export const ShareSheetHintStrings = () => ({
  firstHint: {
    hint: 'There’s a new way to share your flows! Click Share to select who can participate in a flow and view its responses',
  },
});
