import create from 'zustand';

export type ParticipationFlowDetails = {
  participationFlowId: string;
  occurrenceId?: string;
  canHandleAnswerWithShortcut?: boolean;
};

export type ModalsStoreState = {
  participationFlow?: ParticipationFlowDetails;
  setParticipationFlow: (flow: ParticipationFlowDetails) => void;
  removeParticipationFlow: () => void;
  isCreateFlowModalOpen: boolean;
  setCreateFlowModalOpen: (isOpen: boolean) => void;
};

const useModalsStore = create<ModalsStoreState>((set) => ({
  participationFlow: undefined,
  setParticipationFlow: (flow: ParticipationFlowDetails) => {
    set(() => ({ participationFlow: flow }));
  },
  removeParticipationFlow: () => {
    set(() => ({ participationFlow: undefined }));
  },
  isCreateFlowModalOpen: false,
  setCreateFlowModalOpen: (isOpen: boolean) => {
    set(() => ({ isCreateFlowModalOpen: isOpen }));
  },
}));

export default useModalsStore;
