import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';

import Checkbox from '../../../atoms/Checkbox';
import { Flex } from '../../../../Utils/styles/display';
import FlowsBaseInputBlock from '../FlowsBaseInputBlock';
import { FlowsMultiChoiceOthersChoiceInput } from '../../../atoms/FlowsOtherMultipleChoiceInput';
import { MULTIPLE_CHOICE_MULTI_SELECT } from '../../../../languages/en/flows/participation';
import NavigationInstructions from '../../FlowsInputBlockNavigationInstructions';
import { generateSubDescription } from './utils';
import { Limit, MultipleChoiceOption } from './types';
import usePrevious from '../../../../hooks/usePrevious';
import useToggle from '../../../../hooks/useToggle';
import { StyledSubDescriptionBody } from '../FlowsBaseInputBlock/styles';

const StyledCheckbox = styled(Checkbox)`
  margin-bottom: 4px;
`;

export type FlowsMultipleChoiceMutliselectInputBlockProps = {
  allowOther?: boolean;
  description?: string;
  goToNextStep: () => void;
  isRequired: boolean;
  isLastBlock?: boolean;
  isTouched: boolean;
  limit?: Limit;
  options: MultipleChoiceOption[];
  onChange: (newValue: MultipleChoiceOption[]) => void;
  onBlur?: () => void;
  title: string;
  value: MultipleChoiceOption[];
  isPreviewFlow?: boolean;
};

function FlowsMultipleChoiceMultiselectInputBlock({
  allowOther = false,
  description,
  goToNextStep,
  isRequired,
  limit,
  options,
  title,
  value,
  onChange,
  isTouched,
  isLastBlock = false,
  isPreviewFlow,
}: FlowsMultipleChoiceMutliselectInputBlockProps) {
  const otherInputRef = useRef<HTMLInputElement>(null);

  const [hasValidationErrors, setHasValidationErrors] = useState(false);
  const [disableUnSelectedItems, setDisableUnSelectedItems] = useState(false);
  const [otherInputValue, setOtherInputValue] = useState(() => {
    const selectedOtherOption = value.find(
      (selectedOption) => selectedOption.id === 'other',
    );
    return selectedOtherOption ? selectedOtherOption.value : '';
  });

  const previousOtherInputValue = usePrevious(otherInputValue);

  const {
    models: { toggleValue: isOtherInputBorderVisible },
    operations: {
      setToggleToFalse: setIsOtherInputBorderVisibleToFalse,
      setToggleToTrue: setIsOtherInputBorderVisibleToTrue,
    },
  } = useToggle();

  const multipleChoiceOptions = useMemo(() => {
    if (allowOther) {
      const otherOption: MultipleChoiceOption = {
        id: 'other',
        value: otherInputValue,
      };
      const optionsWithOther = [...options];
      optionsWithOther.push(otherOption);
      return optionsWithOther;
    }

    return options;
  }, [allowOther, options, otherInputValue]);

  const validateLimit = useMemo(() => {
    if (!isTouched || !limit || limit.noLimit) {
      return false;
    }

    if (limit.exact) {
      return limit.exact !== value.length;
    }

    if (limit.range) {
      return !isRequired && value.length === 0
        ? false
        : value.length < limit.range.min || value.length > limit.range.max;
    }

    return false;
  }, [isTouched, limit, value.length, isRequired]);

  const customEnterCheck = useCallback(
    () => !hasValidationErrors,
    [hasValidationErrors],
  );

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const interactedOption = multipleChoiceOptions.find(
      (option) => option.id === event.target.id,
    );

    if (!interactedOption) return;

    if (event.target.id === 'other' && event.target.checked) {
      otherInputRef.current?.focus();
    }

    let updatedValue = [...value];
    if (event.target.checked) {
      updatedValue.push(interactedOption);
    } else {
      updatedValue = updatedValue.filter(
        (option) => option.id !== interactedOption.id,
      );
    }

    onChange(updatedValue);
  };

  useEffect(() => {
    setHasValidationErrors(validateLimit);

    if (limit?.exact) {
      setDisableUnSelectedItems(limit.exact === value.length);
    } else if (limit?.range) {
      setDisableUnSelectedItems(value.length === limit.range.max);
    } else {
      setDisableUnSelectedItems(false);
    }
  }, [onChange, validateLimit, value, limit]);

  useEffect(() => {
    if (previousOtherInputValue === otherInputValue) return;

    const otherOptionIndex = value.findIndex((option) => option.id === 'other');
    if (otherOptionIndex > -1) {
      const newValue = [...value];
      newValue[otherOptionIndex] = {
        id: 'other',
        value: otherInputValue,
      };

      onChange(newValue);
    }
  }, [onChange, otherInputValue, previousOtherInputValue, value]);

  const subDescription = useMemo(() => {
    return (
      <StyledSubDescriptionBody
        variant="body3"
        color={hasValidationErrors ? 'red6' : 'gray7'}
        data-testid="subDescription"
      >
        {limit
          ? generateSubDescription(limit, options.length)
          : MULTIPLE_CHOICE_MULTI_SELECT.CHOOSE_AS_MANY_OPTIONS_AS_YOUD_LIKE}
      </StyledSubDescriptionBody>
    );
  }, [hasValidationErrors, limit, options.length]);

  return (
    <FlowsBaseInputBlock
      description={description}
      isRequired={isRequired}
      hasError={hasValidationErrors}
      navigationInstructions={
        <NavigationInstructions
          type={isLastBlock ? 'last+enter' : 'enter'}
          goToNextStep={goToNextStep}
          customEnterCheck={customEnterCheck}
          isPreviewFlow={isPreviewFlow}
        />
      }
      subDescription={subDescription}
      title={title}
    >
      {multipleChoiceOptions.map((option) => {
        const isChecked = value.findIndex((x) => x.id === option.id) > -1;
        const label = option.id === 'other' ? 'Other' : option.value;
        return (
          <Flex key={option.id}>
            <StyledCheckbox
              key={option.id}
              name={option.id}
              label={label}
              labelMargin="0px 0px 0px 8px"
              labelPadding="4px 12px"
              labelVariant="body1"
              size="20px"
              onChange={handleCheckboxChange}
              value={isChecked}
              disabled={disableUnSelectedItems && !isChecked}
            />
            {option.id === 'other' && (
              <FlowsMultiChoiceOthersChoiceInput
                isBorderVisible={isOtherInputBorderVisible}
                onBlur={setIsOtherInputBorderVisibleToFalse}
                onChange={(event) => setOtherInputValue(event.target.value)}
                onFocus={() => {
                  setIsOtherInputBorderVisibleToTrue();
                  onChange([...value, option]);
                }}
                placeholder="Add your answer (optional)"
                ref={otherInputRef}
                value={otherInputValue}
                disabled={disableUnSelectedItems && !isChecked}
              />
            )}
          </Flex>
        );
      })}
    </FlowsBaseInputBlock>
  );
}

export default FlowsMultipleChoiceMultiselectInputBlock;
