//import pdfjs from 'pdfjs-dist/build/pdf';
import React, { useEffect, useRef } from 'react';

// eslint-disable-next-line @typescript-eslint/ban-types
function isFunction(value: any): value is Function {
  return typeof value === 'function';
}

type HookProps = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  /* @ts-ignore-next-line */
  pdfDocument: pdfjs.PDFDocumentProxy | undefined;
  numberOfPages: number;
  canvassesRef: React.MutableRefObject<HTMLCanvasElement[]>;
  height: number;
  width: number;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  /* @ts-ignore-next-line */
  onPageLoadSuccess?: (page: pdfjs.PDFPageProxy) => void;
  onPageLoadFail?: () => void;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  /* @ts-ignore-next-line */
  onPageRenderSuccess?: (page: pdfjs.PDFPageProxy) => void;
  onPageRenderFail?: () => void;
  scale?: number;
  rotate?: number;
};

export const usePdf = ({
  pdfDocument,
  numberOfPages,
  canvassesRef,
  onPageLoadSuccess,
  onPageLoadFail,
  onPageRenderSuccess,
  onPageRenderFail,
  scale = 1,
  rotate = 0,
}: HookProps) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  /* @ts-ignore-next-line */
  const renderTask = useRef<pdfjs.PDFRenderTask | null>(null);
  const onPageLoadSuccessRef = useRef(onPageLoadSuccess);
  const onPageLoadFailRef = useRef(onPageLoadFail);
  const onPageRenderSuccessRef = useRef(onPageRenderSuccess);
  const onPageRenderFailRef = useRef(onPageRenderFail);

  // assign callbacks to refs to avoid redrawing

  useEffect(() => {
    onPageLoadSuccessRef.current = onPageLoadSuccess;
  }, [onPageLoadSuccess]);

  useEffect(() => {
    onPageLoadFailRef.current = onPageLoadFail;
  }, [onPageLoadFail]);

  useEffect(() => {
    onPageRenderSuccessRef.current = onPageRenderSuccess;
  }, [onPageRenderSuccess]);

  useEffect(() => {
    onPageRenderFailRef.current = onPageRenderFail;
  }, [onPageRenderFail]);

  useEffect(() => {
    // draw a page of the pdf
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    /* @ts-ignore-next-line */
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const drawPDF = (page: pdfjs.PDFPageProxy, pageNumber: number) => {
      // Because this page's rotation option overwrites pdf default rotation value,
      // calculating page rotation option value from pdf default and this component prop rotate.
      const rotation = rotate === 0 ? page.rotate : page.rotate + rotate;
      const dpRatio = window.devicePixelRatio;
      const adjustedScale = scale * dpRatio;
      const viewport = page.getViewport({ scale: adjustedScale, rotation });
      const canvasEl = canvassesRef.current[pageNumber];
      if (!canvasEl) {
        return;
      }

      const canvasContext = canvasEl.getContext('2d');
      if (!canvasContext) {
        return;
      }

      canvasEl.style.width = `calc(100% - 64px)`;
      canvasEl.style.height = `calc(100% - 64px)`;
      canvasEl.height = viewport.height;
      canvasEl.width = viewport.width;

      // if previous render isn't done yet, we cancel it
      /*
      if (renderTask.current) {
        renderTask.current.cancel();
        return;
      }
      */

      renderTask.current = page.render({
        canvasContext,
        viewport,
      });

      return renderTask.current.promise.then(
        () => {
          renderTask.current = null;

          if (isFunction(onPageRenderSuccessRef.current)) {
            onPageRenderSuccessRef.current(page);
          }
        },
        (/*err: { name: string }*/) => {
          renderTask.current = null;

          /*
          // @ts-ignore typings are outdated
          if (err && err.name === 'RenderingCancelledException') {
            drawPDF(page, pageNumber);
          } else 
          */
          if (isFunction(onPageRenderFailRef.current)) {
            onPageRenderFailRef.current();
          }
        },
      );
    };

    if (pdfDocument) {
      for (let pageNum = 1; pageNum <= numberOfPages; pageNum++) {
        pdfDocument.getPage(pageNum).then(
          (loadedPdfPage: any) => {
            if (isFunction(onPageLoadSuccessRef.current)) {
              onPageLoadSuccessRef.current(loadedPdfPage);
            }

            drawPDF(loadedPdfPage, pageNum);
          },
          () => {
            if (isFunction(onPageLoadFailRef.current)) {
              onPageLoadFailRef.current();
            }
          },
        );
      }
    }
  }, [canvassesRef, numberOfPages, pdfDocument, rotate, scale]);
  return;
};
