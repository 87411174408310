import React from 'react';

import {
  WORKING_ON_SUPPORTING,
  PREVIEW_NOT_AVAILABLE,
  FOR,
  FILES,
} from '../languages/en';
import searching from '../../../../img/searching-girl.svg';
import '../styles/unsupported.scss';

const UnsupportedViewer = (props: {
  unsupportedComponent?: React.ReactNode;
  fileType: string;
}) => {
  const { unsupportedComponent, fileType } = props;
  return (
    <div className="pg-driver-view">
      <div className="unsupported-message">
        {unsupportedComponent ? (
          { unsupportedComponent }
        ) : (
          <div className="unsupported-content">
            <img
              alt={WORKING_ON_SUPPORTING}
              height="175"
              src={searching}
              width="196"
            />
            <div className="unsupported-header">
              {PREVIEW_NOT_AVAILABLE} {FOR} {`.${fileType}`} {FILES}{' '}
            </div>
            <div className="unsupported-body">{WORKING_ON_SUPPORTING}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UnsupportedViewer;
