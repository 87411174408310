import styled from 'styled-components';
import { Flex } from '../../../../Utils/styles/display';
import Body from '../../../atoms/Body';

export const StyledContent = styled(Flex)`
  width: 60%;
  min-height: 280px;
  padding: 20px 0 0 48px;
`;
export const StyledTitle = styled(Body)`
  margin-top: 8px;
  margin-bottom: 8px;
`;

export const StyledParticipantsListWrapper = styled(Flex)`
  width: 40%;
  & > div {
    flex-basis: 0px;
    flex-grow: 1;
    overflow-y: auto;
  }
`;

export const StyledModalTitle = styled(Body)`
  padding: 16px;
`;
