import React, { useCallback, useRef, useState } from 'react';
import Body from '../../../atoms/Body';
import { Flex } from '../../../../Utils/styles/display';
import { VariantProps } from '../../InfoAlert';
import {
  DropdownPlacement,
  MenuItemIndividualItem,
  MenuItemProps,
} from '../../Dropdown_V2/interfaces';
import DropdownWithChildren from '../../DropdownWithChildren';
import Button from '../../../atoms/Button';
import useOnClickOutside from '../../../../hooks/useOnOutsideClick';
import {
  DropdownWrapper,
  StyledInfoAlert,
  TextWrapper,
  Wrapper,
} from './styles';

export type ShareSheetAnonymousSettingsProps = {
  title: string;
  subTitle: string;
  infoAlert?: {
    icon: string;
    alertType: string;
    text: React.ReactNode;
    customVariant?: VariantProps;
  };
  handleOptionSelection: (option: string | number) => void;
  options: MenuItemProps[];
  selectedOption: MenuItemIndividualItem;
};

const ShareSheetAnonymousSettings = (
  props: ShareSheetAnonymousSettingsProps,
) => {
  const {
    title,
    subTitle,
    options,
    infoAlert,
    selectedOption,
    handleOptionSelection,
  } = props;

  const containerRef = useRef<HTMLDivElement>(null);
  const [caretOpen, toggleCaretOpen] = useState(false);

  const onOutsideClickHandler = useCallback(() => {
    if (caretOpen) toggleCaretOpen(!caretOpen);
  }, [caretOpen]);

  const handleItemClick = useCallback(
    (value: string | number) => {
      handleOptionSelection(value);
      if (caretOpen) toggleCaretOpen(!caretOpen);
    },
    [caretOpen, handleOptionSelection],
  );

  useOnClickOutside(containerRef, onOutsideClickHandler);

  return (
    <Wrapper>
      {infoAlert && (
        <StyledInfoAlert
          id="toggleInfoAlert"
          text={infoAlert.text}
          icon={infoAlert.icon}
          alertType={infoAlert.alertType}
          {...(infoAlert.alertType === 'custom' && infoAlert?.customVariant
            ? { customVariant: infoAlert?.customVariant }
            : {})}
        />
      )}
      <Flex justifyContent="space-between">
        <TextWrapper>
          <Body variant="body1Medium" color="gray9">
            {title}
          </Body>
          <Body variant="body3" color="gray8">
            {subTitle}
          </Body>
        </TextWrapper>
      </Flex>
      <DropdownWrapper ref={containerRef}>
        <DropdownWithChildren
          dropdownPlacement={DropdownPlacement.BottomStart}
          menuItems={options}
          onItemClick={handleItemClick}
        >
          <Button
            color="secondary"
            onClick={() => {
              toggleCaretOpen(!caretOpen);
            }}
            isFullWidth
            enableCaret
            caretOpen={caretOpen}
          >
            {selectedOption.value}
          </Button>
        </DropdownWithChildren>
      </DropdownWrapper>
    </Wrapper>
  );
};

export default ShareSheetAnonymousSettings;
