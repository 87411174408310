import React from 'react';

import { HubspotContainer } from './styles';
import useScript from '../../../hooks/useScript';
import BlockSection from '../../../atomic/organism/BlockSection';
import { quickSetupSurveyController } from '../../../languages/en/quickSetup';

const SurveyTeamController = () => {
  useScript({
    src: `https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js`,
  });

  return (
    <BlockSection
      headingVariant="h4"
      subHeadingColor="gray8"
      subHeadingVariant="body1"
      heading={quickSetupSurveyController.headingText}
      subHeading={quickSetupSurveyController.subHeadingText}
    >
      <HubspotContainer
        data-testid="hubSpotForm"
        className="meetings-iframe-container"
        data-src="https://meetings.hubspot.com/jonathan368/assembly-onboarding-survey-path?embed=true"
      />
    </BlockSection>
  );
};

export default SurveyTeamController;
