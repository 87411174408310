import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { parse } from 'qs';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import {
  useProfileInfoFetchQuery,
  useUserVerifyTokenQuery,
} from '../../queries/Profile';
import { userSessionCreatedAction } from '../../modules/Session';
import { CELEBRATE_TEAMMATE_FLOW_ID } from '../../constants/common';
import useToggle from '../../hooks/useToggle';
import RecognitionFlowController from '../home/RecognitionFlowController';
import AssemblyLanderLoadingScreen from '../../atomic/molecules/AssemblyLanderLoadingScreen';
import { useGetFlowInstanceQuery } from '../../queries/Flows/Dashboard';
import { AxiosError } from 'axios';
import ParticipationFlowController from '../flows/ParticipationFlowController';
import { ParticipationFlowDetails } from '../../stores/modalsStore';
import { IOS_URL } from '../../config';
import { ParticipationTemplateErrorTypes } from '../../atomic/pages/ParticipationTemplate/types';

const IOS = 'ios';
// const ANDROID = 'android';

const ClientAppController = () => {
  const authenticated = useSelector((state) =>
    get(state, ['auth', 'authenticated'], false),
  );
  const dispatch = useDispatch();
  const {
    models: { toggleValue: isRecognitionFlowModalOpen },
    operations: {
      setToggleToTrue: toggleIsRecognitionFlowModalOpen,
      setToggleToFalse: closeRecognitionFlowModal,
    },
  } = useToggle();
  const { search } = useLocation();
  const [accessToken, setAccessToken] = useState<string | undefined>(undefined);
  const [source, setSource] = useState<string | undefined>(undefined);
  const [flowId, setFlowId] = useState<string | undefined>(undefined);
  const [participationFlow, setParticipationFlow] = useState<
    ParticipationFlowDetails | undefined
  >(undefined);
  const [occurrenceId, setOccurrenceId] = useState<string | undefined>(
    undefined,
  );
  const { data: profileData, isLoading: isProfileDataLoading } =
    useProfileInfoFetchQuery(authenticated);

  const { data: userInfoData, isLoading: isVerifyTokenLoading } =
    useUserVerifyTokenQuery(accessToken, !authenticated && !!accessToken);

  const flowInstanceQueryObject = useMemo(
    () => ({
      flow: participationFlow,
      enabled: !!participationFlow,
    }),
    [participationFlow],
  );

  const {
    data: flowInstanceData,
    isLoading: isFlowInstanceLoading,
    error,
    isError,
  } = useGetFlowInstanceQuery(flowInstanceQueryObject);

  const instanceError = useMemo(() => {
    if (error) {
      const { response } = error as AxiosError<{
        body?: string;
        message?: ParticipationTemplateErrorTypes;
      }>;
      if (isError && response) {
        const {
          status,
          data: { message },
        } = response;
        if ((status === 409 || status === 403) && message) {
          return message;
        }
        return ParticipationTemplateErrorTypes.INVALID_FLOW_ID;
      }
    }
    return undefined;
  }, [error, isError]);

  const closeParticipationModal = useCallback(() => {
    //  TODO: Close the modal
    if (source === IOS) {
      window.location.href = `${IOS_URL}participation?status=close`;
    }
  }, [source]);

  const onFlowPostSuccess = useCallback(() => {
    //  TODO: Close the modal
    if (source === IOS) {
      window.location.href = `${IOS_URL}participation?status=success`;
    }
  }, [source]);

  const onAnswerWithShortcutButtonClick = useCallback(() => {
    //  TODO: Close the modal
  }, []);

  const onRecognitionPostSuccess = useCallback(() => {
    //  TODO: Call Success URL
    if (source === IOS) {
      window.location.href = `${IOS_URL}participation?status=success`;
    }
  }, [source]);

  const onRecognitionModalClose = useCallback(() => {
    //  TODO: Call Success URL
    closeRecognitionFlowModal();
    if (source === IOS) {
      window.location.href = `${IOS_URL}participation?status=close`;
    }
  }, [closeRecognitionFlowModal, source]);

  useEffect(() => {
    const parsedParams = parse(search, {
      ignoreQueryPrefix: true,
    });
    if (parsedParams) {
      console.log('parsedParams', parsedParams);
      if (parsedParams.token && parsedParams.token !== accessToken) {
        setAccessToken(parsedParams.token as string);
      }
      if (parsedParams.flowId && parsedParams.flowId !== flowId) {
        setFlowId(parsedParams.flowId as string);
      }
      if (
        parsedParams.occurrenceId &&
        parsedParams.occurrenceId !== occurrenceId
      ) {
        setOccurrenceId(parsedParams.occurrenceId as string);
      }
      if (parsedParams.source && parsedParams.source !== source) {
        setSource(parsedParams.source as string);
      }
    }
  }, [accessToken, flowId, occurrenceId, search, source]);

  useEffect(() => {
    if (userInfoData && !authenticated) {
      const { jwtToken, user } = userInfoData;
      if (user && jwtToken) {
        dispatch(userSessionCreatedAction(user, jwtToken));
      }
    }
  }, [userInfoData, authenticated, dispatch]);

  useEffect(() => {
    if (flowId && authenticated) {
      if (flowId === CELEBRATE_TEAMMATE_FLOW_ID) {
        toggleIsRecognitionFlowModalOpen();
      } else {
        setParticipationFlow({
          participationFlowId: flowId,
          occurrenceId,
        });
      }
    }
  }, [
    authenticated,
    flowId,
    occurrenceId,
    setParticipationFlow,
    toggleIsRecognitionFlowModalOpen,
  ]);

  if (isVerifyTokenLoading || isProfileDataLoading || isFlowInstanceLoading) {
    return <AssemblyLanderLoadingScreen />;
  }

  return (
    <>
      {isRecognitionFlowModalOpen && profileData && (
        <RecognitionFlowController
          profileInfo={profileData}
          toggleIsRecognitionFlowModalOpen={onRecognitionModalClose}
          onPostSuccess={onRecognitionPostSuccess}
        />
      )}
      {!!participationFlow && profileData && (
        <ParticipationFlowController
          flowInstance={flowInstanceData}
          toggleParticipationModalOpen={closeParticipationModal}
          onParticipationFlowPostSuccess={onFlowPostSuccess}
          profileInfo={profileData}
          isLoading={isFlowInstanceLoading}
          flowId={participationFlow.participationFlowId}
          flowErrorStatus={instanceError}
          isShortcutRespondedView={false} // TODO: Check this with Sharan
          onAnswerWithShortcutButtonClick={onAnswerWithShortcutButtonClick}
        />
      )}
    </>
  );
};

export default ClientAppController;
