import {
  VIEWERS_LABEL,
  ANYONE_WITH_LINK,
  PARTICIPANTS_LABEL,
  CAN_VIEW_ALL_RESPONSES,
  CAN_RESPOND_TO_FLOW_LABEL,
  ONLY_PEOPLE_OR_RULES_LABEL,
  CAN_RESPOND_AND_VIEW_RESPONSES,
  PARTICIPANTS_AND_VIEWERS_LABEL,
  EVERYONE_IN_THIS_WORKSPACE_LABEL,
} from '../../../../languages/en/flows/sharesheet';

export const linkAccessPermissionDropDownItems = [
  {
    id: 'link-access-permissions',
    items: [
      {
        id: ONLY_PEOPLE_OR_RULES_LABEL,
        value: ONLY_PEOPLE_OR_RULES_LABEL,
      },
      {
        id: EVERYONE_IN_THIS_WORKSPACE_LABEL,
        value: EVERYONE_IN_THIS_WORKSPACE_LABEL,
      },
      {
        id: ANYONE_WITH_LINK,
        value: ANYONE_WITH_LINK,
      },
    ],
  },
];

export const linkPermissions = [
  {
    id: 'link-access-permissions',
    items: [
      {
        id: PARTICIPANTS_LABEL,
        value: PARTICIPANTS_LABEL,
        helperText: CAN_RESPOND_TO_FLOW_LABEL,
      },
      {
        id: VIEWERS_LABEL,
        value: VIEWERS_LABEL,
        helperText: CAN_VIEW_ALL_RESPONSES,
      },
      {
        id: PARTICIPANTS_AND_VIEWERS_LABEL,
        value: PARTICIPANTS_AND_VIEWERS_LABEL,
        helperText: CAN_RESPOND_AND_VIEW_RESPONSES,
      },
    ],
  },
];
