import styled from 'styled-components';
import Body from '../../../atomic/atoms/Body';
import { StyledTabs } from '../../../atomic/atoms/Tabs/styles';
import InfoAlert from '../../../atomic/molecules/InfoAlert';
import { TabPanel } from '../../../atomic/atoms/Tabs';
import ThemeV2 from '../../../componentsV2/theme';
import BasicModalTemplate from '../../../atomic/templates/BasicModalTemplate';

export const Header = styled(Body)`
  padding: 10px;
`;

export const NotebookContainer = styled.section`
  max-width: 643px;
  margin: -12px auto 24px auto;
`;

export const AccordionEmptyText = styled(Body)`
  padding: 4px 12px 4px 38px;
`;

export const StyledInfoAlert = styled(InfoAlert)`
  margin-left: 32px;
  width: auto;
`;

export const Tabs = styled(StyledTabs)`
  background: ${ThemeV2.palette.white};
`;

export const TabContent = styled(TabPanel)`
  margin-top: 40px;
`;

export const NotebookNavigationModal = styled(BasicModalTemplate)`
  width: 100%;
  max-width: 500px;
`;
