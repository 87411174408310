import styled from 'styled-components';
import Body from '../../../atoms/Body';
import Clickable from '../../../atoms/Clickable_V2';

import EmptyErrorStateTemplate from '../../../molecules/EmptyErrorStateTemplate';
import ThemeV2 from '../../../../componentsV2/theme';

export const StyledLink = styled(Clickable)`
  &:hover {
    color: ${ThemeV2.palette.geekBlue5};
  }
  color: ${ThemeV2.palette.geekBlue6};
  text-decoration: underline;
`;

export const StyledBody = styled(Body)`
  margin-bottom: 12px;
`;

export const StyledContentWrapper = styled.div`
  margin-left: 24px;
`;

export const LoadingWrapper = styled.div`
  margin: 34px 0 14px 0;
`;

export const StyledNonRecurringTitle = styled(Body)`
  margin-bottom: 12px;
`;

export const StyledErrorStateTemplate = styled(EmptyErrorStateTemplate)`
  margin: auto;
`;
