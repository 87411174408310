export const DEFAULT_PATH = '/';
export const V2_HOME = `/home`;
export const V2_USER_PROFILE = `/user/:userId`;
export const V2_REWARDS = `/rewards`;
export const V2_SETTINGS = `/settings`;
export const POST = '/post';
export const V2_POST_PAGE = `${POST}/:postId`;
export const QUICK_SETUP = '/quick-setup';
export const V3_POST_PAGE = `${POST}/flow/:postType`;
export const V3_FLOWS_POST_PAGE = `${POST}/flow/flows`;
export const RECOGNITION_POST_PAGE = `${POST}/flow/recognition`;
export const V2_COMMENT_PAGE = `${V2_POST_PAGE}/comment/:commentId`;

export const V2_GIFTCARD_REWARDS = `${V2_REWARDS}/gift-cards`;
export const V2_CHARITY_REWARDS = `${V2_REWARDS}/charities`;
export const V2_CULTURE_REWARDS = `${V2_REWARDS}/culture`;
export const V2_MY_REWARDS = `${V2_REWARDS}/my-rewards`;
export const V2_AXOMO_SWAGS = `${V2_REWARDS}/swag`;

export const V2_GENERAL_SETTINGS = `${V2_SETTINGS}/general`;
export const V2_NOTIFICATION_SETTINGS = `${V2_SETTINGS}/notifications`;

export const HELP_CENTER = 'https://www.joinassembly.com/help-center';
export const PRODUCT_FEEDBACK =
  'https://my.joinassembly.com/e/flows/6259cb7dba869dfae201c791';

export const SIGN_IN = `/signin`;
export const EMPLOYEE_VERIFY_LINK = '/verify';
export const RESET_PASSWORD = '/forgotPassword';
export const CREATE_PASSWORD = '/create-password/:token';
export const USER_ACCOUNT_SETUP = '/user/account-setup';
export const ONBOARDING_SETUP = '/onboarding-setup';

export const FLOWS = '/flows';
export const ANSWER_FLOW = '/answer';
export const FLOWS_FEEDS = `${FLOWS}/:flowId`;
export const FLOWS_PARTICIPATE = `${FLOWS}/:flowId/answer`;
export const RECOGNITION_PARTICIPATE = `${FLOWS}/recognition/answer`;
export const NEW_FLOWS_BUILDER = `${FLOWS}/editor`;
export const FLOWS_BUILDER = `${FLOWS_FEEDS}/editor`;
export const FLOW_INSIGHTS = `${FLOWS_FEEDS}/insights`;
export const FLOW_FEED = `${FLOWS_FEEDS}/feed`;
export const RECOGNITION_FEEDS = `${FLOWS}/recognition`;

export const EXTERNAL_PATH = '/e';
export const SIGN_IN_PATH = '/signin';
export const KIOSK_PAGE_PATH = '/kiosk';
export const EXTERNAL_FLOW_PATH = `${EXTERNAL_PATH}/flows/:flowId`;
export const EXTERNAL_TEMPLATE_PATH = `${EXTERNAL_PATH}/template/:templateId`;
export const EXTERNAL_PATH_COMPLETION = `${EXTERNAL_PATH}/completion`;

export const generateV2UserProfileRoute = (userId: string) => `/user/${userId}`;
export const generateV3PostRoute = (postID: string) =>
  `/post/flow/recognition?postId=${postID}`;
export const generateFlowResponseRoute = (flowId: string, responseId: string) =>
  `/post/flow/flows?flowId=${flowId}&responseId=${responseId}`;
export const ADMIN_CULTURES_REWARDS = `/admin/rewards/culture`;
export const ADMIN_GIFT_CARDS = `/admin/rewards/gifts`;
export const ADMIN_CHARITIES = `/admin/rewards/charities`;
export const ADMIN_USER_INVITES = `/admin/users/invite`;

// LEGACY ADMIN ROUTES
export const ADMIN_CULTURES_REWARDS_LEGACY = '/admin/rewards/culture';
export const ADMIN_GIFT_CARDS_LEGACY = '/admin/rewards/gifts';
export const ADMIN_CHARITIES_LEGACY = '/admin/rewards/charities';
export const ADMIN_USER_INVITES_LEGACY = '/admin/users/invite';

export const AUTH_SAML = '/auth/saml';

export const KNOWLEDGE_CENTER = '/knowledge-center';

export const NOTEBOOK = `/notebook`;
export const NOTEBOOK_WITH_MYWORK_PRESELECTED = `/notebook/myWork`;

export const GOOGLE_ONE_TAP_AUTH = '/auth/gOneTap';
