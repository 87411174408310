import { trackEvent } from '..';
import { AnalyticsEvents, AXOMO_EVENT } from '../constants';
import { AxomoEventProps } from '../interfaces';

export const trackAxomoActionEvent = (eventProps: AxomoEventProps) => {
  trackEvent(AXOMO_EVENT, undefined, {
    ...eventProps,
    event: AnalyticsEvents.ACTION,
  });
};

export const trackAxomoErrorEvent = (eventProps: AxomoEventProps) => {
  trackEvent(AXOMO_EVENT, undefined, {
    ...eventProps,
    event: AnalyticsEvents.ERROR,
  });
};

export const trackAxomoShowEvent = (eventProps: AxomoEventProps) => {
  trackEvent(AXOMO_EVENT, undefined, {
    ...eventProps,
    event: AnalyticsEvents.SHOW,
  });
};
