import { useCallback, useContext, useMemo, useState } from 'react';
import { EmojiData } from 'emoji-mart';
import { useCommentInput } from '../../../../hooks/useFeedCommentInput';
import {
  DeletePostClickArgs,
  FeedCommentFromAPI,
  FeedItemFromAPI,
  MemberInteractionType,
  Reaction,
} from '../../../../interfaces/Feed';
import { PostTypes } from '../../../../interfaces/Home';
import {
  useGetFlowPostComments,
  useGetPostComments,
} from '../../../../queries/Feed/comments';
import { GetProfileInfoResponse } from '../../../../queries/Profile';
import { getCanDeletePosts } from '../../../../queries/Profile/utils';
import { useFetchFlowFileQuery } from '../../../../queries/Flows/Feed';
import { POST_ANALYTICS_EVENTS } from '../../../../Utils/analytics/constants';
import useGetPageName from '../../../../hooks/useGetPageName';
import { trackPostEvent as trackPostEventMetric } from '../../../../Utils/analytics';
import { MainFeedContext } from '../../../home/MainFeedController/newIndex';
import { DownloadBlockType } from '../../../../atomic/organism/FlowPostBody/types';
import { COPIED_TO_CLIPBOARD } from '../../../../languages/en/flows';
import copyToClipboard from 'copy-to-clipboard';
import {
  getCommonFlowPostAttributes,
  getFlowPostUrl,
  getRecognitionPostUrl,
  newMapFeedResponseToFeedBodyProps,
  newMapLegacyRecognitionResponseToFeedBodyProps,
} from '../../../../Utils/home/feeds';
import { showSuccessMessage } from '../../../../Utils/toast';

type UseFlowPostControllerProps = {
  profileData?: GetProfileInfoResponse;
  post: FeedItemFromAPI;
};
const useFlowPostController = (props: UseFlowPostControllerProps) => {
  const { profileData, post } = props;
  const { page } = useGetPageName();
  const {
    onReactionSet,
    onReactionUnSet,
    onMemberClick,
    onDeletePost,
    onFlowClick,
    onDownloadFileClick,
  } = useContext(MainFeedContext);

  const newTrackPostEvent = useCallback(
    (action: string) => {
      const postEventProps = {
        feedViewed: page,
        numPostComments: post.commentsCount,
        responseId: post.flowResponse?.responseId || null,
        numPostReactions: post.flowResponse?.reactions.length || 0,
        postId: post.type === PostTypes.RECOGNITION ? post.postID : null,
        flowId:
          post.flowResponse?.flow.flowId || PostTypes.RECOGNITION.toString(),
        flowName: post.flowResponse?.flow.name,
      };
      trackPostEventMetric('post.events', {
        ...postEventProps,
        event: 'action',
        action,
      });
    },
    [page, post],
  );

  const [deletePostCarrotOption, setDeletePostCarrotOption] =
    useState<string>();

  const [filePreviewFile, setFilePreviewFile] = useState<
    DownloadBlockType | undefined
  >(undefined);

  const {
    postType,
    commentsCount,
    postId,
    responseId,
    flowId,
    trophyReceivers,
    instanceId,
    hasTrophies,
    showDeleteOption,
    isFlowPost,
  } = useMemo(() => {
    const { flowResponse, type } = post;
    const isFlowPostBool = Boolean(type === PostTypes.FLOW && flowResponse);
    return {
      isFlowPost: isFlowPostBool,
      instanceId: flowResponse?.instanceId,
      commentsCount: isFlowPostBool
        ? flowResponse?.commentsCount || 0
        : post.commentsCount,
      ...getCommonFlowPostAttributes(post),
      trophyReceivers: isFlowPostBool ? [] : post.to,
      showDeleteOption: isFlowPostBool
        ? flowResponse?.canDelete
        : getCanDeletePosts(profileData),
    };
  }, [post, profileData]);

  const { data: fileToPreview } = useFetchFlowFileQuery(
    {
      responseId: filePreviewFile ? responseId : '',
      blockId: filePreviewFile ? filePreviewFile.id : '',
      instanceId: instanceId ? instanceId : '',
      fileName: filePreviewFile ? filePreviewFile.fileName : '',
      flowId: flowId ? flowId : '',
    },
    filePreviewFile !== undefined,
  );

  const { commentInputProps } = useCommentInput({
    profileData,
    postId,
    postType,
    responseId,
    flowId,
    trophyReceivers,
  });

  const { onEditorFocus, onEditorBlur, onPostClick } = commentInputProps;

  const getPostCommentsEnabled =
    commentsCount > 0 && postType !== PostTypes.FLOW;

  const postCommentsObj = useGetPostComments(postId, getPostCommentsEnabled);

  const getFlowPostCommentsEnabled =
    commentsCount > 0 && postType === PostTypes.FLOW;

  const flowPostCommentsObj = useGetFlowPostComments(
    flowId || '',
    responseId || '',
    getFlowPostCommentsEnabled,
  );

  const { showMoreCommentsButton, commentsData, onShowMoreButtonClick } =
    useMemo(() => {
      const {
        data: commentsPaginatedData,
        hasNextPage: commentsHasNextPage,
        fetchNextPage: fetchNextComments,
      } = postCommentsObj;
      const {
        data: flowCommentsPaginatedData,
        hasNextPage: flowCommentsHasNextPage,
        fetchNextPage: fetchNextFlowComments,
      } = flowPostCommentsObj;
      const selectedPaginationData = isFlowPost
        ? flowCommentsPaginatedData
        : commentsPaginatedData;
      const commentsArr = selectedPaginationData
        ? selectedPaginationData.pages.reduce<FeedCommentFromAPI[]>(
            (arr, currentPage) => [...arr, ...currentPage.data],
            [],
          )
        : [];
      commentsArr.sort(
        (a, b) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
      );
      return {
        commentsData: commentsArr,
        showMoreCommentsButton:
          commentsCount > 3 &&
          ((isFlowPost ? flowCommentsHasNextPage : commentsHasNextPage) ||
            false),
        onShowMoreButtonClick: () => {
          if (isFlowPost) {
            fetchNextFlowComments();
          } else {
            fetchNextComments();
          }
        },
      };
    }, [commentsCount, flowPostCommentsObj, isFlowPost, postCommentsObj]);

  const handleSetFilePreview = useCallback(
    (value: DownloadBlockType | undefined) => setFilePreviewFile(value),
    [setFilePreviewFile],
  );

  const handleDeletePostOptionChange = useCallback(
    (value: string) => setDeletePostCarrotOption(value),
    [setDeletePostCarrotOption],
  );

  const handleDeletePost = useCallback(
    (obj: DeletePostClickArgs) => onDeletePost(obj, deletePostCarrotOption),
    [deletePostCarrotOption, onDeletePost],
  );

  const handleMemberClick = useCallback(
    (member: MemberInteractionType) => {
      newTrackPostEvent(POST_ANALYTICS_EVENTS.LINK_COPIED);
      onMemberClick(member);
    },
    [newTrackPostEvent, onMemberClick],
  );

  const handleReactionSet = useCallback(
    (emoji: EmojiData, contentId: string, curentPost: FeedItemFromAPI) => {
      onReactionSet(emoji, contentId, curentPost);
      newTrackPostEvent(POST_ANALYTICS_EVENTS.COMMENT_REACTION_ADDED);
    },
    [onReactionSet, newTrackPostEvent],
  );

  const handleReactionUnSet = useCallback(
    (reaction: Reaction, contentId: string, curentPost: FeedItemFromAPI) => {
      onReactionUnSet(reaction, contentId, curentPost);
      newTrackPostEvent(POST_ANALYTICS_EVENTS.COMMENT_REACTION_REMOVED);
    },
    [onReactionUnSet, newTrackPostEvent],
  );

  const handleFlowClick = useCallback(
    (flowID: string) => {
      onFlowClick(flowID);
      newTrackPostEvent(POST_ANALYTICS_EVENTS.FLOW_CLICKED);
    },
    [newTrackPostEvent, onFlowClick],
  );

  const onSeeFullPostClick = useCallback(() => {
    newTrackPostEvent(POST_ANALYTICS_EVENTS.SEE_FULL_POST_CLICK);
  }, [newTrackPostEvent]);

  const handleDownloadFileClick = useCallback(
    async (fileDownload: DownloadBlockType) => {
      if (flowId) {
        onDownloadFileClick(fileDownload, instanceId || '', flowId);
        newTrackPostEvent(POST_ANALYTICS_EVENTS.FILE_DOWNLOADED);
      }
    },
    [flowId, onDownloadFileClick, newTrackPostEvent, instanceId],
  );

  const onCopyLinkClick = useCallback(() => {
    const { flowResponse, type, postID } = post;
    if (type === PostTypes.FLOW && flowResponse) {
      if (flowResponse.flow.flowId && flowResponse.responseId) {
        // eslint-disable-next-line max-len
        const url = getFlowPostUrl(
          flowResponse.flow.flowId,
          flowResponse.responseId,
        );
        copyToClipboard(url);
        showSuccessMessage(COPIED_TO_CLIPBOARD);
      }
    } else {
      if (postID) {
        const url = getRecognitionPostUrl(postID);
        copyToClipboard(url);
        showSuccessMessage(COPIED_TO_CLIPBOARD);
      }
    }
    newTrackPostEvent(POST_ANALYTICS_EVENTS.LINK_COPIED);
  }, [newTrackPostEvent, post]);

  const onCommentExited = useCallback(() => {
    onEditorBlur();
    newTrackPostEvent(POST_ANALYTICS_EVENTS.COMMENT_EXITED);
  }, [newTrackPostEvent, onEditorBlur]);

  const onCommentPosted = useCallback(() => {
    onPostClick();
    newTrackPostEvent(POST_ANALYTICS_EVENTS.COMMENT_POSTED);
  }, [newTrackPostEvent, onPostClick]);

  const onCommentTextFocus = useCallback(() => {
    onEditorFocus();
    newTrackPostEvent(POST_ANALYTICS_EVENTS.COMMENT_STARTED);
  }, [newTrackPostEvent, onEditorFocus]);

  const onDeleteModalSubmitClick = useCallback(() => {
    const obj = getCommonFlowPostAttributes(post);
    handleDeletePost({
      hasTrophies: obj.hasTrophies,
      currentPostId: obj.postId,
      currentPostType: obj.postType,
      currentFlowId: obj.flowId,
      currentResponseId: obj.responseId,
    });
  }, [post, handleDeletePost]);

  const blocks = useMemo(() => {
    const { type, flowResponse } = post;
    if (profileData) {
      if (type === PostTypes.FLOW && flowResponse) {
        return newMapFeedResponseToFeedBodyProps(
          flowResponse,
          profileData,
          handleMemberClick,
        );
      }
      return newMapLegacyRecognitionResponseToFeedBodyProps(
        post,
        profileData,
        handleMemberClick,
      );
    }
    return [];
  }, [profileData, post, handleMemberClick]);

  return {
    commentInputProps,
    commentsData,
    onShowMoreButtonClick,
    showMoreCommentsButton,
    hasTrophies,
    showDeleteOption,
    fileToPreview,
    handleSetFilePreview,
    onFlowClick: handleFlowClick,
    onCopyLinkClick,
    onCommentExited,
    onCommentPosted,
    onCommentTextFocus,
    handleDeletePostOptionChange,
    handleMemberClick,
    onDownloadFileClick: handleDownloadFileClick,
    onSeeFullPostClick,
    handleReactionSet,
    handleReactionUnSet,
    onDeleteModalSubmitClick,
    blocks,
  };
};

export default useFlowPostController;
