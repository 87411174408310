import { useMemo, useRef } from 'react';
import uuid from 'uuid';
import useParticipationFlow from '../../../hooks/useParticipationFlow';
import { FlowBlockFromAPI } from '../../../interfaces/Flow';
import { GetProfileInfoResponse } from '../../../queries/Profile';
import useFlowBuilderStore from '../../../stores/flowBuilderStore';
import { serializeBuilderBlockData } from '../../../Utils/flows/builder/utils';
import { processFlowBlocks } from '../ParticipationFlowController/utils';

const useFlowsParticipationPreviewController = (
  togglePreviewModalOpen: () => void,
  profileInfo: GetProfileInfoResponse,
) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const builderStore = useFlowBuilderStore();
  const blocks = useMemo(() => {
    const {
      action: { blocks: blockContent },
    } = serializeBuilderBlockData(builderStore);
    const serializedblocks: FlowBlockFromAPI[] = blockContent.map(
      (content) => ({
        blockId: uuid.v4(),
        blockType: content.type,
        content,
      }),
    );
    return serializedblocks;
  }, [builderStore]);
  const [schema, initialValues, stepData] = useMemo(
    () => processFlowBlocks(blocks, profileInfo),
    [blocks, profileInfo],
  );
  const { models, operations } = useParticipationFlow({
    staticBlockData: stepData,
    schema,
    initialValues,
    containerRef,
    onFlowSubmit: togglePreviewModalOpen,
  });
  return {
    models: {
      ...models,
      stepData,
      containerRef,
      blocks,
      builderStore,
    },
    operations,
  };
};

export default useFlowsParticipationPreviewController;
