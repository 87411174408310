import {
  AnonymityStates,
  FlowInstanceResponse,
} from '../../../../../queries/Flows/interfaces';
import { GetProfileInfoResponse } from '../../../../../queries/Profile';
import {
  FlowSubmissionDetails,
  StaticBlockState,
} from '../../../../../interfaces/Flow';
import { useEffect, useMemo, useRef } from 'react';
import { useFetchFlowDetailsQuery } from '../../../../../queries/Flows/Feed';
import { processFlowBlocks } from '../../../ParticipationFlowController/utils';
import useTrackParticipationFlow from '../../../../../hooks/analytics/useTrackParticipationFlow';
import {
  ACTION_EVENTS,
  PARTICIPATION_ANALYTICS_EVENTS,
} from '../../../../../Utils/analytics/constants';
import { isBlockEmpty } from '../../../../../hooks/useParticipationFlow/utils';
import useParticipationFlow from '../../../../../hooks/useParticipationFlow';
import { ExternalFlowDetails } from '../../../ParticipationFlowController/useLoadedParticipationFlowController';

const useLoadedFlowsParticipationController = (
  instanceDetails: FlowInstanceResponse,
  toggleParticipationModalOpen: () => void,
  profileInfo: GetProfileInfoResponse,
  onFlowSubmit: (submissionDetails: FlowSubmissionDetails) => void,
  externalFlowDetails?: ExternalFlowDetails,
) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { data: flowDetails } = useFetchFlowDetailsQuery(
    instanceDetails.flowId,
  );
  const [schema, initialValues, stepData] = useMemo(
    () =>
      processFlowBlocks(
        instanceDetails.blocks,
        profileInfo,
        undefined,
        externalFlowDetails,
      ),
    [externalFlowDetails, instanceDetails.blocks, profileInfo],
  );

  const { trackParticipationFlow } = useTrackParticipationFlow({
    ...flowDetails?.data,
  });

  useEffect(() => {
    trackParticipationFlow(
      PARTICIPATION_ANALYTICS_EVENTS.START,
      null,
      ACTION_EVENTS.SHOW,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const postStepChange = (
    blockData: StaticBlockState,
    values: Record<string, any>,
  ) => {
    const isSkipped = isBlockEmpty(blockData, values);
    trackParticipationFlow(
      isSkipped
        ? PARTICIPATION_ANALYTICS_EVENTS.BLOCK_SKIPPED
        : PARTICIPATION_ANALYTICS_EVENTS.BLOCK_ANSWERED,
      null,
      ACTION_EVENTS.ACTION,
    );
  };

  const { models, operations } = useParticipationFlow({
    staticBlockData: stepData,
    schema,
    initialValues,
    containerRef,
    postStepChange,
    onFlowSubmit,
  });

  const handleModalClose = () => {
    trackParticipationFlow(
      PARTICIPATION_ANALYTICS_EVENTS.EXIT_LEAVE_CLICKED,
      null,
      ACTION_EVENTS.ACTION,
    );
    toggleParticipationModalOpen();
    operations.resetForm();
  };

  return {
    models: {
      ...models,
      stepData,
      containerRef,
      allowPrivateResponse: Boolean(flowDetails?.data.allowPrivateResponse),
      allowAnonymousResponse: Boolean(
        flowDetails?.data.responseSettings.anonymity.state ===
          AnonymityStates.OPTIONAL,
      ),
      isAnonymityEnabled: Boolean(
        flowDetails?.data.responseSettings.anonymity.state ===
          AnonymityStates.ENABLED,
      ),
    },
    operations: {
      ...operations,
      handleModalClose,
    },
  };
};

export default useLoadedFlowsParticipationController;
