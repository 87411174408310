export const recognitionTipsHeaderText =
  'Tips on writing meaningful recognition';
export const recognitionTips = [
  {
    title: 'Opener',
    body: 'starting with a, “Thank you for” or “I really appreciate” will do.',
  },
  {
    title: 'Body',
    body:
      // eslint-disable-next-line max-len
      'Be specific and say what they did. It’s fine to express general gratitude from time to time (“Thanks for always being willing to lend a hand when I need you”), however, referring to a specific event is more effective (“Thanks for putting in extra time to make our presentation a success.”)',
  },
  {
    title: 'Closer',
    body: 'End the message with a closing line, “I really appreciate your [talent]” is a good choice.',
  },
  {
    title: 'Example',
    body:
      // eslint-disable-next-line max-len
      'I really appreciate your help getting our landing page ready for our big marketing push. It really helped that you were always willing to put in extra time if the situation called for it. I’m grateful for your attention to detail—our product pages look perfect. I specifically like that you moved the button above the fold – this will help conversions. I appreciate your pixel perfection on the frontend and all the help!',
  },
];
