import React, { useCallback, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { UNASSIGNED_MEMBER_ID } from '../../../../constants/notebook';
import useOnClickOutside from '../../../../hooks/useOnOutsideClick';
import { Assignee } from '../../../../interfaces/notebook';
import { ASSIGNEE, ERROR } from '../../../../languages/en/singleWords';
import { capitalizeFirstLetter } from '../../../../Utils/text';
import {
  Card,
  StyledPopover,
  PopoverContentWrapper,
  InfiniteScrollWrapper,
  UserAvatar,
  UnassignedIcon,
  AssigneeName,
  SelectionIndicator,
  AssigneeLabel,
  TaskItemAssigneeSelectorWrapper,
  Search,
  NoAssigneesFoundLabel,
  Placeholder,
} from './styles';
import ThemeV2 from '../../../../componentsV2/theme';
import TaskItemAssignee from '../TaskItem/TaskItemAssignee';
import { Flex } from '../../../../Utils/styles/display';
import { NotebookAssigneeLoader } from '../Loader';
import {
  ASSIGNEE_ME,
  NO_ASSIGNEES_FOUND,
} from '../../../../languages/en/notebook';

type TaskItemAssigneeSelectorProps = {
  id: string;
  isLoading: boolean;
  assigneeList: Assignee[];
  isError?: boolean;
  hasMoreUsersToFetch: boolean;
  fetchMoreMembers: () => void;
  canPopoverTriggerByClick: boolean;
  onMemberClick: (user: Assignee) => void;
  onPopOverOpen?: () => void;
  onPopOverClose?: () => void;
  position?: boolean;
  assignedTo: Assignee;
  searchValue: string;
  handleSearchOnAssigneeList: (searchValue: string) => void;
  setTaskIdOfFocusedTask: (taskId: string, isDelete?: boolean) => void;
  noPadding?: boolean;
  currentUserId: string;
};

const TaskItemAssigneeSelector = (props: TaskItemAssigneeSelectorProps) => {
  const {
    isError,
    id,
    isLoading,
    hasMoreUsersToFetch,
    canPopoverTriggerByClick,
    assigneeList,
    assignedTo,
    searchValue,
    currentUserId,
    onMemberClick,
    fetchMoreMembers,
    setTaskIdOfFocusedTask,
    handleSearchOnAssigneeList,
    noPadding = false,
  } = props;

  const popoverAnchor = useRef(null);
  const popoverComponent = useRef(null);
  const [openedPopover, setOpenedPopover] = useState(false);
  const [isFocused, setFocus] = useState(false);

  const handlePopoverClick = useCallback(() => {
    if (canPopoverTriggerByClick) {
      setOpenedPopover(true);
    }
    setFocus(true);
  }, [canPopoverTriggerByClick]);

  const handleKeypress = (event: { key: string }) => {
    if (event.key === 'Enter') {
      setOpenedPopover(true);
    }
  };

  const handlePopoverOutsideClick = () => {
    setFocus(false);
    setTaskIdOfFocusedTask('', true);
    handleSearchOnAssigneeList('');
    setOpenedPopover(false);
  };

  useOnClickOutside(popoverComponent, handlePopoverOutsideClick);

  return (
    <TaskItemAssigneeSelectorWrapper
      noPadding={noPadding}
      onClick={handlePopoverClick}
    >
      <AssigneeLabel variant="body2">{`${capitalizeFirstLetter(
        ASSIGNEE,
      )}:`}</AssigneeLabel>
      <div
        role="link"
        ref={popoverAnchor}
        aria-haspopup="true"
        onKeyPress={handleKeypress}
        tabIndex={0}
      >
        <Flex>
          <TaskItemAssignee
            currentUserId={currentUserId}
            assignedTo={assignedTo}
          />
          {isFocused ? (
            <Search
              value={searchValue}
              onChange={(e) => {
                handleSearchOnAssigneeList(e.target.value);
              }}
              autoFocus
              size="small"
              removeElevation
              padding="0px"
            />
          ) : (
            <Placeholder />
          )}
        </Flex>
      </div>
      <StyledPopover
        ref={popoverComponent}
        id={`assignee-popover-${id}`}
        open={openedPopover}
        anchorEl={popoverAnchor.current}
        data-testid="user-dropdown-popover"
        placement="bottom-start"
      >
        <PopoverContentWrapper>
          <InfiniteScrollWrapper id={id} data-testid="assignee-div">
            {assigneeList && (
              <InfiniteScroll
                dataLength={assigneeList?.length}
                hasMore={hasMoreUsersToFetch}
                loader={<></>}
                next={fetchMoreMembers}
                scrollableTarget={id}
              >
                {assigneeList?.length > 0 &&
                  assigneeList.map((user) => (
                    <Card
                      onClick={() => {
                        setOpenedPopover(false);
                        onMemberClick(user);
                      }}
                      key={user?.memberID}
                    >
                      {user?.memberID !== UNASSIGNED_MEMBER_ID ? (
                        <UserAvatar
                          img={user?.image}
                          name={user?.name}
                          userId={`${user?.memberID}`}
                          size="24px"
                        />
                      ) : (
                        <UnassignedIcon icon="unassigned" size="24px" />
                      )}
                      <AssigneeName
                        variant="body2"
                        color={`${
                          assignedTo?.memberID === user?.memberID
                            ? 'geekBlue6'
                            : 'gray8'
                        }`}
                        className="assignee-name"
                      >{`${capitalizeFirstLetter(user?.name)} ${
                        assignedTo?.memberID === user?.memberID
                          ? ASSIGNEE_ME
                          : ''
                      }
                      `}</AssigneeName>
                      {assignedTo?.memberID === user?.memberID && (
                        <SelectionIndicator
                          icon="check-rounded"
                          size="16px"
                          data-testid="selectedIcon"
                          color={ThemeV2.palette.geekBlue6}
                        />
                      )}
                    </Card>
                  ))}
              </InfiniteScroll>
            )}
            {!isLoading && assigneeList?.length === 0 && (
              <NoAssigneesFoundLabel variant="body2">
                {NO_ASSIGNEES_FOUND}
              </NoAssigneesFoundLabel>
            )}
            {isLoading && <NotebookAssigneeLoader />}
            {isError && <div>{ERROR}</div>}
          </InfiniteScrollWrapper>
        </PopoverContentWrapper>
      </StyledPopover>
    </TaskItemAssigneeSelectorWrapper>
  );
};

export default TaskItemAssigneeSelector;
