import React from 'react';

import Body from '../../atoms/Body';
import Heading from '../../atoms/Heading';
import { LOADING } from '../../../languages/en/singleWords';
import { StyledRewardsLoader, StyledRewardsLoaderImage } from './styles';

export interface GifLoaderProps {
  source: string;
  primaryText: string;
  secondaryText: string;
  tertiaryText?: string;
}

const GifLoader = (props: GifLoaderProps) => {
  const { source, primaryText, secondaryText, tertiaryText } = props;

  return (
    <StyledRewardsLoader>
      <StyledRewardsLoaderImage src={source} alt={LOADING} />
      <Heading variant="h6">{primaryText}</Heading>
      <Body variant="body1">{secondaryText}</Body>
      <Body variant="body1">{tertiaryText}</Body>
    </StyledRewardsLoader>
  );
};

export default GifLoader;
