import {
  leaderboardCategoryDropdownValues,
  leaderboardTimeFrameDropdownValues,
} from '../../../languages/en/home/mainFeedDrawer';

export const TOP_CELEBRATED = {
  id: leaderboardCategoryDropdownValues.TOP_CELEBRATED,
  value: leaderboardCategoryDropdownValues.TOP_CELEBRATED,
};

export const TOP_CELEBRATORS = {
  id: leaderboardCategoryDropdownValues.TOP_CELEBRATORS,
  value: leaderboardCategoryDropdownValues.TOP_CELEBRATORS,
};

export const TOP_COMMENTERS = {
  id: leaderboardCategoryDropdownValues.TOP_COMMENTERS,
  value: leaderboardCategoryDropdownValues.TOP_COMMENTERS,
};

export const categoryMenuItems = [
  TOP_CELEBRATED,
  TOP_CELEBRATORS,
  TOP_COMMENTERS,
];

export const ALL_TIME = {
  id: leaderboardTimeFrameDropdownValues.ALL_TIME,
  value: leaderboardTimeFrameDropdownValues.ALL_TIME,
};

export const LAST_7_DAYS = {
  id: leaderboardTimeFrameDropdownValues.LAST_7_DAYS,
  value: leaderboardTimeFrameDropdownValues.LAST_7_DAYS,
};

export const LAST_30_DAYS = {
  id: leaderboardTimeFrameDropdownValues.LAST_30_DAYS,
  value: leaderboardTimeFrameDropdownValues.LAST_30_DAYS,
};

export const THIS_MONTH = {
  id: leaderboardTimeFrameDropdownValues.THIS_MONTH,
  value: leaderboardTimeFrameDropdownValues.THIS_MONTH,
};

export const THIS_QUARTER = {
  id: leaderboardTimeFrameDropdownValues.THIS_QUARTER,
  value: leaderboardTimeFrameDropdownValues.THIS_QUARTER,
};

export const timeFrameMenuItems = [
  LAST_7_DAYS,
  LAST_30_DAYS,
  THIS_MONTH,
  THIS_QUARTER,
  ALL_TIME,
];
