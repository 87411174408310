import React, { useCallback } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import {
  FeedsContainer,
  ShowMoreButton,
  StyledActionBarWrapper,
} from './styles';

import { feedSortItems } from './data';
import { Flex } from '../../../Utils/styles/display';
import ActionBarController from '../../ActionBarController';
import useMainFeedController from './useMainFeedController/newIndex';
import Dropdown from '../../../atomic/molecules/Dropdown_V2';
import FlowsFeedPostLoader from '../../flows/FlowsFeedController/Loader';
import { PostSortValues, FeedContextState } from '../../../interfaces/Feed';
import BottomOfFeedMessage from '../../../atomic/molecules/BottomOfFeedMessage';

import {
  DropdownColor,
  DropdownSizes,
  DropdownPlacement,
  DropdownVariants,
} from '../../../atomic/molecules/Dropdown_V2/interfaces';

import FlowPostController from '../../flows/FlowPostController/newIndex';
import RecognitionFlowController from '../RecognitionFlowController';
import { SEE_NEW_POSTS } from '../../../languages/en/feed';
import EmptyErrorStateTemplate from '../../../atomic/molecules/EmptyErrorStateTemplate';
import { HeadingType } from '../../../atomic/molecules/EmptyErrorStateTemplate/interfaces';
import { FLOW_FEED_STATES } from '../../../languages/en/flows/feed';

import searching from '../../../img/searching.svg';
import useGetParsedFilterOptions from '../../../hooks/useGetParsedFilterOptions';
import DummyFlowFeedPost from '../../../atomic/organism/DummyFlowFeedPost';
import {
  generalNewFeedHeaderProps,
  celebrateTeamMateDummyPostData,
  generalNewsFeedDummyPostData,
  iceBreakerDummyPostData,
  iceBreakerHeaderProps,
  generalNewFeedHeaderFlowProps,
  iceBreakerHeaderFlowProps,
  celebrateTeammateHeaderProps,
} from '../../../atomic/organism/DummyFlowFeedPost/data';
import { getDummyPostFlowId } from '../../../Utils/flows';
import {
  GENERAL_NEWS_FEED_TEMPLATE_IDS,
  ICEBREAKER_TEMPLATE_IDS,
} from '../../flows/FlowsFeedController/constants';

const defaultFn = () => {};

export const MainFeedContext = React.createContext<FeedContextState>({
  onReactionSet: defaultFn,
  onReactionUnSet: defaultFn,
  onMemberClick: defaultFn,
  onFlowClick: defaultFn,
  onDeleteCommentClick: defaultFn,
  onCommentEmoticonClick: defaultFn,
  onCommentReactionToggleClick: defaultFn,
  onDeletePost: defaultFn,
  onCopyCommentLink: defaultFn,
  onDownloadFileClick: defaultFn,
});

const MainFeedController = () => {
  const { isMainFeedFilterApplied } = useGetParsedFilterOptions();

  const { models, operations } = useMainFeedController();

  const {
    data,
    isLoading,
    activeFlows,
    hasNextPage,
    profileData,
    feedsSortValue,
    showLoadMoreButton,
    isRecognitionFlowModalOpen,
  } = models;

  const {
    refetch,
    handleFetchNextPage,
    handleFeedSortClick,
    setShowLoadMoreButtonToFalse,
    toggleIsRecognitionFlowModalOpen,
    feedContextProps,
    onFlowClick,
  } = operations;

  const onLoadMoreClick = useCallback(() => {
    document.getElementById('scrollableFeedContent')?.scroll({
      top: 0,
      behavior: 'smooth',
    });
    refetch();
    setShowLoadMoreButtonToFalse();
  }, [refetch, setShowLoadMoreButtonToFalse]);

  const renderEmptyState = () => {
    if (profileData) {
      celebrateTeamMateDummyPostData.bodyProps.blocks[0] = {
        id: '1',
        type: 'PERSON_SELECTOR',
        content: {
          title: 'Who would you like to celebrate?',
          persons: [
            {
              id: '6081484184d8987b291e40b3',
              label: `${profileData.member.profile.firstName} ${profileData.member.profile.lastName}`,
              avatar: {
                img: profileData.member.profile.image,
                userId: profileData.member.memberId,
                name: profileData.member.profile.username,
              },
            },
          ],
        },
      };
    }

    if (
      !isMainFeedFilterApplied &&
      data.length === 0 &&
      !isLoading &&
      profileData
    ) {
      const newGeneralNewFeedHeaderFlowProps = {
        ...generalNewFeedHeaderFlowProps,
        flowId: getDummyPostFlowId(GENERAL_NEWS_FEED_TEMPLATE_IDS, activeFlows),
      };

      const newGeneralNewFeedHeaderProps = {
        ...generalNewFeedHeaderProps,
        flow: newGeneralNewFeedHeaderFlowProps,
        handleOnFlowClick: ({ flowId }: { flowId: string }) =>
          onFlowClick(flowId),
      };

      const newIceBreakerHeaderFlowProps = {
        ...iceBreakerHeaderFlowProps,
        flowId: getDummyPostFlowId(ICEBREAKER_TEMPLATE_IDS, activeFlows),
      };

      const newIceBreakerFeedHeaderProps = {
        ...iceBreakerHeaderProps,
        flow: newIceBreakerHeaderFlowProps,
        handleOnFlowClick: ({ flowId }: { flowId: string }) =>
          onFlowClick(flowId),
      };

      const newCelebrateTeammateHeaderProps = {
        ...celebrateTeammateHeaderProps,
        handleOnFlowClick: ({ flowId }: { flowId: string }) =>
          onFlowClick(flowId),
      };

      return (
        <>
          <>
            <DummyFlowFeedPost
              currentUser={profileData.member}
              headerProps={newGeneralNewFeedHeaderProps}
              {...generalNewsFeedDummyPostData}
            />
            <DummyFlowFeedPost
              currentUser={profileData.member}
              headerProps={newIceBreakerFeedHeaderProps}
              {...iceBreakerDummyPostData}
            />
          </>

          <DummyFlowFeedPost
            currentUser={profileData.member}
            headerProps={newCelebrateTeammateHeaderProps}
            {...celebrateTeamMateDummyPostData}
          />
        </>
      );
    }
    if (isMainFeedFilterApplied) {
      return (
        <EmptyErrorStateTemplate
          image={searching}
          variant={HeadingType.Bold}
          heading={FLOW_FEED_STATES.NO_MATCHING_POSTS.HEADING}
          subHeading={FLOW_FEED_STATES.NO_MATCHING_POSTS.SUB_HEADING}
        />
      );
    }
    return (
      <EmptyErrorStateTemplate
        image={searching}
        variant={HeadingType.Bold}
        heading={FLOW_FEED_STATES.NO_POSTS.HEADING}
        subHeading={FLOW_FEED_STATES.NO_POSTS.SUB_HEADING}
      />
    );
  };

  return (
    <FeedsContainer>
      <StyledActionBarWrapper>
        <ActionBarController />
      </StyledActionBarWrapper>
      <Flex justifyContent="space-between" margin="0 auto 4px">
        <div>
          <Dropdown
            menuItems={feedSortItems}
            size={DropdownSizes.Small}
            variant={DropdownVariants.Text}
            color={DropdownColor.Secondary}
            value={PostSortValues[feedsSortValue]}
            dropdownPlacement={DropdownPlacement.BottomEnd}
            onItemClick={(val) => handleFeedSortClick(val)}
          />
        </div>
        {isRecognitionFlowModalOpen && profileData && (
          <RecognitionFlowController
            profileInfo={profileData}
            onPostSuccess={refetch}
            toggleIsRecognitionFlowModalOpen={toggleIsRecognitionFlowModalOpen}
          />
        )}
      </Flex>
      {isLoading && <FlowsFeedPostLoader />}
      {data.length > 0 && (
        <InfiniteScroll
          next={handleFetchNextPage}
          dataLength={data.length}
          loader={<FlowsFeedPostLoader />}
          hasMore={Boolean(hasNextPage)}
          style={{ overflow: 'inherit' }}
          endMessage={<BottomOfFeedMessage />}
          scrollableTarget="scrollableFeedContent"
        >
          {showLoadMoreButton && (
            <ShowMoreButton
              size="small"
              variant="showMore"
              onClick={onLoadMoreClick}
              icon="arrow-thick-up"
            >
              {SEE_NEW_POSTS}
            </ShowMoreButton>
          )}
          {
            <MainFeedContext.Provider value={feedContextProps}>
              {data.map((post) => (
                <FlowPostController
                  key={post.postID}
                  post={post}
                  profileData={profileData}
                />
              ))}
            </MainFeedContext.Provider>
          }
        </InfiniteScroll>
      )}
      {!isLoading && data.length === 0 && renderEmptyState()}
    </FeedsContainer>
  );
};

export default MainFeedController;
