import React from 'react';
import { ImageHolder } from '../ClickableSelectionItem/styles';
import {
  RightSideWrapper,
  StyledWorkatoConnectButton,
  StyledWorkatoWrapper,
  Wrapper,
} from './styles';
import { CalendarSelectionItemProps } from './type';
import Image from '../../../atoms/Image';
import Body from '../../../atoms/Body';
import { quickSetupCalendarSetupScreen } from '../../../../languages/en/quickSetup';

const CalendarSelectionItem = (props: CalendarSelectionItemProps) => {
  const { backGroundColor, className, imageSrc, title, embedUrl } = props;

  return (
    <Wrapper className={className} data-testid="calendar-selection-item">
      <>
        <ImageHolder
          size="small"
          alignItems="center"
          justifyContent="center"
          backGroundColor={backGroundColor}
        >
          <Image src={imageSrc} alt="selection" />
        </ImageHolder>
        <RightSideWrapper justifyContent="space-between">
          <Body variant="body1Medium">{title}</Body>
          {title === quickSetupCalendarSetupScreen.outlookCalendarTitle ? (
            <StyledWorkatoWrapper
              id="workatoWrapper"
              onLoad={() => {
                const element = document.getElementById('workatoWrapper');
                element?.scrollTo(0, 98);
              }}
            >
              <iframe
                src={embedUrl}
                id="workato"
                title="workato"
                frameBorder={0}
                scrolling="no"
              />
            </StyledWorkatoWrapper>
          ) : (
            <StyledWorkatoConnectButton
              scrolling="no"
              src={embedUrl}
              id="workato"
              title="workato"
              frameBorder={0}
            />
          )}
        </RightSideWrapper>
      </>
    </Wrapper>
  );
};

export default CalendarSelectionItem;
