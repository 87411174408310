import React from 'react';
import ThemeV2 from '../../../componentsV2/theme';

import { CONFIGURE_SETTINGS } from '../../../languages/en/flows/builder';
import Body from '../../atoms/Body';
import { StyledLink, StyledAnswerAnytimeSwitch } from './styles';
import { FlowAnswerAnytimeProps } from './types';

const FlowAnswerAnytime = (props: FlowAnswerAnytimeProps) => {
  const { canAnswerAnytime, onAnswerAnytimeToggle } = props;

  return (
    <div>
      <Body variant="body1Medium" color={ThemeV2.palette.gray9}>
        {CONFIGURE_SETTINGS.ALLOW_ANYTIME_CONFIGURATION_HEADING_TEXT}
      </Body>
      <Body variant="body3">
        {CONFIGURE_SETTINGS.ALLOW_ANYTIME_CONFIGURATION_BODY_TEXT}
        <StyledLink
          to={CONFIGURE_SETTINGS.SHORTCUT_CONFIGURATION_LINK}
          isAnchor
          openInNewTab
        >
          {CONFIGURE_SETTINGS.SHORTCUT_CONFIGURATION_LINK_TEXT}
        </StyledLink>
      </Body>
      <StyledAnswerAnytimeSwitch
        id="answer-anytime"
        isOn={canAnswerAnytime}
        onChange={onAnswerAnytimeToggle}
      />
    </div>
  );
};

export default FlowAnswerAnytime;
