import { AxiosError } from 'axios';
import { parse } from 'qs';
import { useCallback, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import shallow from 'zustand/shallow';
import { ParticipationTemplateErrorTypes } from '../../../atomic/pages/ParticipationTemplate/types';
import {
  FLOWS,
  NEW_FLOWS_BUILDER,
  NOTEBOOK,
  V2_REWARDS,
  V2_SETTINGS,
} from '../../../constants/routes';
import useArchiveSocket from '../../../hooks/useArchiveSocket';
import useGetPageName from '../../../hooks/useGetPageName';
import useToggle from '../../../hooks/useToggle';
import { PageType } from '../../../interfaces/Feed';
import { LayoutStoreState } from '../../../interfaces/Layout';
import { useFeatureIntroQuery } from '../../../queries/FeatureIntros';
import { useGetFlowInstanceQuery } from '../../../queries/Flows/Dashboard';
import { useFetchFlowDetailsQuery } from '../../../queries/Flows/Feed';
import { useProfileInfoFetchQuery } from '../../../queries/Profile';
import { useFeatureIntrosStore } from '../../../stores/featureIntrosStore';
import { setFeatureIntrosSelector } from '../../../stores/featureIntrosStore/selectors';
import useFlowBuilderStore from '../../../stores/flowBuilderStore';
import { flowNameSelector } from '../../../stores/flowBuilderStore/selectors';
import useLayoutStore from '../../../stores/layoutStore';
import useModalsStore from '../../../stores/modalsStore';
import {
  modalsStoreSelector,
  removeParticipationFlowSelector,
  setCreateFlowModalOpenSelector,
  setParticipationFlowSelector,
} from '../../../stores/modalsStore/selectors';
import { trackRightDrawerEvent } from '../../../Utils/analytics';
import { RIGHT_DRAWER_EVENTS } from '../../../Utils/analytics/constants';
import { DrawerTabType } from '../../../Utils/analytics/interfaces';
import { TourStatusEnum } from '../../../Utils/featureIntros/types';

const layoutStoreSelector = (state: LayoutStoreState) => ({
  isRightAsideOpen: state.isRightAsideOpen,
  setTaskIdForNotebook: state.setTaskIdForNotebook,
});

const usePrimaryLayoutController = () => {
  const { page } = useGetPageName();

  const flowName = useFlowBuilderStore(flowNameSelector);
  const setFeatureIntros = useFeatureIntrosStore(setFeatureIntrosSelector);
  const featureIntroData = useFeatureIntroQuery(
    'ACCESS_POLICY_PARTICIPANT_VIEWER,ACCESS_POLICY_SHARE_BUTTON',
  );
  const featureIntroDataData = featureIntroData?.data?.data;
  if (featureIntroDataData) {
    featureIntroDataData.data.forEach((data) => {
      const test: Record<string, TourStatusEnum> = {};
      test[data.name] = data.status;
      setFeatureIntros(test);
    });
  }

  const location = useLocation();
  const parsedFlowId =
    (location.pathname.includes('/flows/') &&
      location.pathname.split('/')[2]) ||
    '';
  const parsedParams = parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const participationFlowId =
    (parsedParams && (parsedParams.participationFlowId as string)) || undefined;
  const occurrenceId =
    (parsedParams && (parsedParams.occurrenceId as string)) || undefined;
  const setParticipationFlow = useModalsStore(setParticipationFlowSelector);
  const { participationFlow, isCreateFlowModalOpen } = useModalsStore(
    modalsStoreSelector,
    shallow,
  );
  const { search } = useLocation();
  const selectedTaskId = useMemo(() => {
    const query = new URLSearchParams(search);
    return query.get('taskId') || '';
  }, [search]);

  const { isRightAsideOpen, setTaskIdForNotebook } = useLayoutStore(
    layoutStoreSelector,
    shallow,
  );

  const toggleNotebookRightDrawer = useCallback(() => {
    setTaskIdForNotebook(isRightAsideOpen ? '' : selectedTaskId);
  }, [isRightAsideOpen, selectedTaskId, setTaskIdForNotebook]);

  const secondaryDrawerToggleHandler = (isDrawerOpen: boolean) => {
    let drawerTabViewed: DrawerTabType = 'people';
    if (page === 'flow' || page === 'profile') {
      drawerTabViewed = 'about';
    }
    if (page === 'single post recognition') {
      drawerTabViewed = 'leaderboards';
    } else if (page === 'single post flows') {
      drawerTabViewed = 'people';
    } else if (page === 'recognition') {
      drawerTabViewed = 'leaderboards';
    }

    let feedViewed: PageType = page;
    if (page === 'single post flows') {
      feedViewed = 'single post';
    } else if (page === 'single post recognition') {
      feedViewed = 'single post';
    } else if (page === 'recognition') {
      feedViewed = 'flow';
    } else {
      feedViewed = page;
    }

    if (page === 'notebook') {
      toggleNotebookRightDrawer();
    }
    if (isDrawerOpen) {
      trackRightDrawerEvent(RIGHT_DRAWER_EVENTS.RIGHT_DRAWER_COLLAPSED, {
        feedViewed,
        drawerTabViewed,
        flowName,
      });
    } else {
      trackRightDrawerEvent(RIGHT_DRAWER_EVENTS.RIGHT_DRAWER_EXPANDED, {
        feedViewed,
        drawerTabViewed,
        flowName,
      });
    }
  };

  const isNotebookView = useMemo(() => page === 'notebook', [page]);

  const removeParticipationFlow = useModalsStore(
    removeParticipationFlowSelector,
  );
  const setCreateFlowModalOpen = useModalsStore(setCreateFlowModalOpenSelector);

  const { data: profileData } = useProfileInfoFetchQuery();

  const {
    models: { toggleValue: isAnswerWithShortcutView },
    operations: {
      setToggleToTrue: setAnswerWithShortcutViewTrue,
      setToggleToFalse: setAnswerWithShortcutViewFalse,
    },
  } = useToggle(true);

  const flowInstanceQueryObject = useMemo(
    () => ({
      flow: participationFlow,
      enabled: !!participationFlow,
    }),
    [participationFlow],
  );

  const {
    data: flowInstanceData,
    isLoading: isFlowInstanceLoading,
    error,
    isError,
  } = useGetFlowInstanceQuery(flowInstanceQueryObject);

  useArchiveSocket();

  const flowID = useMemo(() => {
    if (participationFlow) {
      return participationFlow.participationFlowId;
    }
    if (parsedFlowId) {
      if (parsedFlowId !== 'recognition') {
        return parsedFlowId;
      }
    }
    return '';
  }, [parsedFlowId, participationFlow]);

  // Note - Surender: Handling responded shortcut flow scenario
  // this code can be improved in future
  const { isLoading: isFlowDetailLoading, data } = useFetchFlowDetailsQuery(
    flowID,
    'builder',
  );

  const flowDetailsData = data?.data;
  const isFlowOwner =
    flowDetailsData?.owner.memberID === profileData?.member.memberId ||
    (flowDetailsData?.owner.memberID === undefined &&
      location.pathname.includes(NEW_FLOWS_BUILDER));
  const isRespondedShortcut =
    flowDetailsData?.occurrence?.activeOccurrence &&
    flowDetailsData?.occurrence.activeOccurrence.hasResponded;

  const isLoading = isFlowInstanceLoading || isFlowDetailLoading;

  const instanceError = useMemo(() => {
    if (error) {
      const { response } = error as AxiosError<{
        body?: string;
        message?: ParticipationTemplateErrorTypes;
      }>;
      if (isError && response) {
        const {
          status,
          data: { message },
        } = response;
        if ((status === 409 || status === 403) && message) {
          return message;
        }
        return ParticipationTemplateErrorTypes.INVALID_FLOW_ID;
      }
    }
    return undefined;
  }, [error, isError]);

  const closeParticipationModal = useCallback(() => {
    removeParticipationFlow();
    setAnswerWithShortcutViewTrue();
  }, [removeParticipationFlow, setAnswerWithShortcutViewTrue]);

  const hasFlowTabs = location.pathname.includes(FLOWS) && isFlowOwner;

  const hasTabComponent =
    isFlowOwner ||
    location.pathname.includes(NOTEBOOK) ||
    location.pathname.includes(NEW_FLOWS_BUILDER) ||
    location.pathname.includes(V2_REWARDS) ||
    location.pathname.includes(V2_SETTINGS);

  const hasContentFooter = location.pathname.includes(V2_REWARDS);

  const closeCreateFlowModal = useCallback(() => {
    setCreateFlowModalOpen(false);
  }, [setCreateFlowModalOpen]);

  const onAnswerWithShortcutButtonClick = useCallback(() => {
    setAnswerWithShortcutViewFalse();
  }, [setAnswerWithShortcutViewFalse]);

  const isShortcutRespondedView =
    participationFlow?.canHandleAnswerWithShortcut &&
    !!isRespondedShortcut &&
    isAnswerWithShortcutView;

  useEffect(() => {
    if (participationFlowId) {
      window.scrollTo(0, 0);
      setParticipationFlow({
        participationFlowId,
        occurrenceId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participationFlowId]);

  return {
    models: {
      isShortcutRespondedView,
      hasFlowTabs,
      isFlowOwner,
      hasTabComponent,
      hasContentFooter,
      profileData,
      instanceError,
      isLoading,
      flowInstanceData,
      isCreateFlowModalOpen,
      onSecondaryDrawerToggle: secondaryDrawerToggleHandler,
      participationFlow,
      flowDetailsData,
      isNotebookView,
    },
    operations: {
      onAnswerWithShortcutButtonClick,
      closeCreateFlowModal,
      closeParticipationModal,
    },
  };
};

export default usePrimaryLayoutController;
