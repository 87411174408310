import styled from 'styled-components';
import Avatar from '../../../../atomic/atoms/Avatar';
import { Flex } from '../../../../Utils/styles/display';
import TextField from '../../../../atomic/molecules/TextField_V2';
import ThemeV2 from '../../../../componentsV2/theme';
import Button from '../../../../atomic/atoms/Button';
import Body from '../../../../atomic/atoms/Body';

export const LinkAccessSettingsWrapper = styled.section`
  padding-top: 12px;
  padding-bottom: 12px;
`;

export const StyledAvatar = styled(Avatar)`
  margin-right: 12px;
`;

export const StyledFlex = styled(Flex)`
  padding: 8px 0;
`;

export const HelperTextWrapper = styled.div`
  display: flex;
  margin-left: 60px;
`;

export const StyledInput = styled(TextField)`
  width: 450px;
  border-radius: 4px;

  .MuiInputBase-root {
    border: 1px solid ${ThemeV2.palette.gray7};
    border-radius: 4px;
  }
`;

export const CopyLinkButton = styled(Button)`
  margin-bottom: 4px;
  margin-left: 2px;
`;

export const CopyLinkContainer = styled.div`
  margin-top: 24px;
`;

export const UpdateSelectionButton = styled(Body)`
  margin-left: 4px;
  cursor: pointer;
  text-decoration: underline;
  color: ${ThemeV2.palette.geekBlue6};
`;

export const StyledBody = styled(Body)`
  width: 456px;
  padding: 8px;
  border-radius: 4px;
  margin-top: 2px;
  margin-bottom: 8px;
  border: 1px solid ${ThemeV2.palette.gray7};
`;
