import React, { useState } from 'react';
import MembersListEntryLoader from '../MembersListEntry/Loader';
import {
  FlowLeftDrawerCollapsibleNavigationProps,
  FlowListItemProps,
} from './types';
import ThemeV2 from '../../../componentsV2/theme';
import {
  StyledNavItem,
  StyledTextWithIcon,
  StyledWrapper,
  ErrorWrapper,
  StyledErrorText,
  EmptyTextWrapper,
  StyledRightClickable,
} from './styles';
import DropdownListHolder from '../DropdownListHolder';
import { DropdownPlacement } from '../Dropdown_V2/interfaces';
import useDropDown from '../../../hooks/useDropDown';
import {
  FLOWS_LEFT_DRAWER_FEED_EMPTY,
  FLOWS_LEFT_DRAWER_FEED_ERROR,
  FLOW_LEFT_DRAWER_ADD_NEW_FLOWS,
} from '../../../languages/en/flows';
import { ComponentStatus } from '../../../interfaces/component';
import SVGIcon from '../../atoms/SVGIcon';
import ExpandableListHolder from '../ExpandableListHolder';

const ListItem = (props: FlowListItemProps) => {
  const {
    navItem,
    dropdownMenuItems,
    onDropdownMenuItemClick,
    onDropdownMenuItemRightClick,
    onMenuItemClick,
  } = props;

  const { anchorProps } = useDropDown({
    menuItems: dropdownMenuItems || [],
    onItemClick: onDropdownMenuItemClick,
  });
  const { referenceElement, anchorEl, toggleDropdown } = anchorProps;

  const handleOnMenuItemClick = (
    event:
      | React.MouseEvent<HTMLElement, MouseEvent>
      | React.KeyboardEvent<HTMLDivElement>,
  ) => {
    if (dropdownMenuItems) {
      if (event.type === 'contextmenu') {
        event.preventDefault();
        if (onDropdownMenuItemRightClick) {
          onDropdownMenuItemRightClick(navItem);
        }
        toggleDropdown();
      }
    }
    if (event.type === 'click') {
      onMenuItemClick(navItem.id);
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      if (handleOnMenuItemClick) {
        handleOnMenuItemClick(event);
      }
    }
  };

  const flowItemPrefixIcon =
    navItem.occurrence?.activeOccurrence &&
    navItem.isFlowParticipant &&
    !navItem.occurrence?.activeOccurrence.hasResponded
      ? 'answer'
      : '';

  const flowItemBadgeText = navItem.isUnread ? navItem.badgeText : '';

  const flowItemTextStyle = navItem.isUnread ? 'body1Bold' : 'body1';

  const flowItemTextColor = navItem.isMuted
    ? ThemeV2.palette.gray6
    : navItem.isUnread
    ? ThemeV2.palette.gray8
    : ThemeV2.palette.gray9;

  return (
    <StyledRightClickable
      id={navItem.id}
      role="option"
      onKeyDown={handleKeyPress}
      onClick={handleOnMenuItemClick}
      aria-selected={navItem.isActive}
      data-testid="navigationItem"
    >
      <StyledWrapper ref={referenceElement}>
        <div ref={anchorEl}>
          <StyledNavItem
            emoticon={navItem.emoticon}
            color={flowItemTextColor}
            badgeText={flowItemBadgeText}
            badgeColor={(navItem.isMuted && ThemeV2.palette.gray6) || undefined}
            textVariant={flowItemTextStyle}
            isActive={navItem.isActive}
            isArchived={navItem.isArchived}
            prefixIcon={flowItemPrefixIcon}
            prefixIconColor={
              navItem.isMuted
                ? ThemeV2.palette.gray6
                : ThemeV2.palette.geekBlue6
            }
          >
            {navItem.label}
          </StyledNavItem>
          <DropdownListHolder
            {...anchorProps}
            dropdownPlacement={DropdownPlacement.BottomEnd}
            onItemClick={onDropdownMenuItemClick}
          />
        </div>
      </StyledWrapper>
    </StyledRightClickable>
  );
};

const FlowLeftDrawerCollapsibleNavigation = (
  props: FlowLeftDrawerCollapsibleNavigationProps,
) => {
  const [canShowAddFlowButton, setCanShowAddFlowButton] = useState(false);

  const handleMouseEnter = () => {
    setCanShowAddFlowButton(true);
  };

  const handleMouseLeave = () => {
    setCanShowAddFlowButton(false);
  };

  const handleAddFlowsButtonClick = (e: React.MouseEvent) => {
    if (
      props.status === ComponentStatus.LOADED ||
      props.status === ComponentStatus.EMPTY
    ) {
      e.stopPropagation();
      if (props.addFlowsButtonClick) {
        props.addFlowsButtonClick();
      }
    }
  };

  switch (props.status) {
    case ComponentStatus.LOADING:
      return (
        <ExpandableListHolder
          onClick={props.onExpandClick}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          heading={props.heading}
          isExpanded={props.isExpanded}
          isBeta={Boolean(props.isBeta)}
        >
          <MembersListEntryLoader isLeftNavbar />
        </ExpandableListHolder>
      );
    case ComponentStatus.LOADED:
      return (
        <ExpandableListHolder
          onClick={props.onExpandClick}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          heading={props.heading}
          isExpanded={props.isExpanded}
          isBeta={Boolean(props.isBeta)}
          addButton={{
            enabled: Boolean(
              props.isAddNewFlowOn &&
                props.canAddFlows &&
                props.addFlowsButtonClick &&
                canShowAddFlowButton,
            ),
            onClick: handleAddFlowsButtonClick,
            dataTestId: 'addFlowsButton',
            text: FLOW_LEFT_DRAWER_ADD_NEW_FLOWS,
          }}
          totalNotificationsCount={props.totalNotificationsCount}
        >
          {props.navItems.length &&
            props.navItems.map((navItem) => (
              <div key={navItem.id}>
                <ListItem
                  navItem={navItem}
                  onDropdownMenuItemRightClick={
                    props.onDropdownMenuItemRightClick
                  }
                  onMenuItemClick={props.onMenuItemClick}
                  dropdownMenuItems={props.dropdownMenuItems}
                  onDropdownMenuItemClick={props.onDropdownMenuItemClick}
                />
              </div>
            ))}
          {props.isAddNewFlowOn &&
            props.canAddFlows &&
            props.addFlowsButtonClick && (
              <StyledTextWithIcon
                icon="union-rounded"
                iconSize="16px"
                iconWrapperSize="24px"
                variant="body1"
                iconWrapperRightMargin="8px"
                iconWrapperBackground={ThemeV2.palette.gray3}
                color="gray9"
                onClick={handleAddFlowsButtonClick}
              >
                {FLOW_LEFT_DRAWER_ADD_NEW_FLOWS}
              </StyledTextWithIcon>
            )}
          {props.children}
        </ExpandableListHolder>
      );
    case ComponentStatus.EMPTY:
      return (
        <ExpandableListHolder
          onClick={props.onExpandClick}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          heading={props.heading}
          isExpanded={props.isExpanded}
          isBeta={Boolean(props.isBeta)}
          addButton={{
            enabled: Boolean(
              props.isAddNewFlowOn &&
                props.canAddFlows &&
                props.addFlowsButtonClick &&
                canShowAddFlowButton,
            ),
            onClick: handleAddFlowsButtonClick,
            dataTestId: 'addFlowsButton',
            text: FLOW_LEFT_DRAWER_ADD_NEW_FLOWS,
          }}
        >
          <EmptyTextWrapper>
            <span role="img" aria-label="empty list">
              🍃
            </span>
            <StyledErrorText color="gray8">
              {FLOWS_LEFT_DRAWER_FEED_EMPTY}
            </StyledErrorText>
          </EmptyTextWrapper>
          {props.isAddNewFlowOn &&
            props.canAddFlows &&
            props.addFlowsButtonClick && (
              <StyledTextWithIcon
                icon="union-rounded"
                iconSize="16px"
                iconWrapperSize="24px"
                variant="body1"
                iconWrapperRightMargin="8px"
                iconWrapperBackground={ThemeV2.palette.gray3}
                color="gray9"
                onClick={handleAddFlowsButtonClick}
              >
                {FLOW_LEFT_DRAWER_ADD_NEW_FLOWS}
              </StyledTextWithIcon>
            )}
        </ExpandableListHolder>
      );
    default:
      return (
        <ExpandableListHolder
          onClick={props.onExpandClick}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          heading={props.heading}
          isExpanded={props.isExpanded}
          isBeta={Boolean(props.isBeta)}
        >
          <ErrorWrapper>
            <SVGIcon
              icon="error-warning"
              size="16px"
              color={ThemeV2.palette.gray6}
            />
            <StyledErrorText variant="body1" color="gray6">
              {FLOWS_LEFT_DRAWER_FEED_ERROR}
            </StyledErrorText>
          </ErrorWrapper>
        </ExpandableListHolder>
      );
  }
};

export default FlowLeftDrawerCollapsibleNavigation;
