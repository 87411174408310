import React from 'react';
import {
  KnowledgeCenterSearchWrapper,
  KnowledgeCenterViewWrapper,
  KnowledgeCenterStyledSVGIcon,
  KnowledgeCenterStyledIconButton,
} from './styles';
import { Flex } from '../../../Utils/styles/display';
import SVGIcon from '../../atoms/SVGIcon';

export enum KnowledgeCenterLayout {
  list = 'list',
  card = 'card',
}

const KnowledgeCenterSearch = (props: {
  activeView: KnowledgeCenterLayout;
  setActiveView: React.Dispatch<React.SetStateAction<KnowledgeCenterLayout>>;
  invokeSearch: void;
}) => {
  const { activeView, setActiveView } = props;

  return (
    <Flex margin="16px 0" height="40px" width="100%" flexGrow={1}>
      <KnowledgeCenterSearchWrapper>
        <KnowledgeCenterStyledSVGIcon icon={'search'} size="16px" />
      </KnowledgeCenterSearchWrapper>
      <KnowledgeCenterViewWrapper>
        <KnowledgeCenterStyledIconButton
          onClick={() => setActiveView(KnowledgeCenterLayout.card)}
          isActiveView={activeView === KnowledgeCenterLayout.card}
          color={
            activeView === KnowledgeCenterLayout.card
              ? 'selectedTertiary'
              : 'tertiary'
          }
        >
          <SVGIcon icon="grid-view" size="24px" />
        </KnowledgeCenterStyledIconButton>
        <KnowledgeCenterStyledIconButton
          onClick={() => setActiveView(KnowledgeCenterLayout.list)}
          isActiveView={activeView === KnowledgeCenterLayout.list}
          color={
            activeView === KnowledgeCenterLayout.list
              ? 'selectedTertiary'
              : 'tertiary'
          }
        >
          <SVGIcon icon="list-view" size="24px" />
        </KnowledgeCenterStyledIconButton>
      </KnowledgeCenterViewWrapper>
    </Flex>
  );
};

export default KnowledgeCenterSearch;
