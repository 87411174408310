import pluralize from 'pluralize';
import { AxiosError } from 'axios';
import shallow from 'zustand/shallow';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import { useParams } from 'react-router-dom';

import { useCallback, useEffect, useMemo, useState } from 'react';

import useFlowBuilderStore from '../../../../stores/flowBuilderStore';
import { useGetMembersFromCriteria } from '../../../../hooks/useMembersSearch';
import useFlowBuilderEdit from '../../../flowsBuilder/FlowsBuilderController/useFlowBuilderEdit';

import {
  emojiSelector,
  flowNameSelector,
  blockDataSelector,
  shareSheetModalOpenSelector,
  setSpecificBlockDataSelector,
  setShareSheetModalOpenSelector,
  newParticipantsToInviteSelector,
  shareSheetAnonymousToggleSelector,
  setNewParticipantsToInviteSelector,
  newVisibilityMembersToInviteSelector,
  setNewVisibilityMembersToInviteSelector,
} from '../../../../stores/flowBuilderStore/selectors';

import {
  getLengthOfConditions,
  serializeVisibilityBlock,
  mapRulesFromCriteriaGroups,
  getCriteriaForAnonymityBasedOnDropdown,
} from '../../../../Utils/flows/builder/utils';

import {
  ONLY,
  OWNER,
  CUSTOM,
  PARTICIPANT,
  PARTICIPANTS,
  LOADING_LABEL,
} from '../../../../languages/en/singleWords';

import { capitalizeFirstLetter } from '../../../../Utils/text';
import { ENTIRE_ORGANIZATION } from '../../../../languages/en';

import {
  FlowBuilderBlockTypes,
  ParticipantsBuilderBlockData,
  VisibilityBuilderBlockData,
} from '../../../../interfaces/Flow/Builder';

import { ShareSheetModalScreen, VisibilityTypes } from '../types';

import useToggle from '../../../../hooks/useToggle';
import useOptions from '../../../../hooks/useFlowFeedOptions/useOptions';

import { getErrorMessage } from '../../../../Utils/message';
import { showErrorMessage, showInfoMessage } from '../../../../Utils/toast';

import { useProfileInfoFetchQuery } from '../../../../queries/Profile';
import { useFetchFlowDetailsQuery } from '../../../../queries/Flows/Feed';
import { useInviteUserPostQuery } from '../../../../queries/Admin/invites';
import { useUpdateFlowAccessPolicy } from '../../../../queries/Flows/Builder';

import {
  PARTICIPANTS_LABEL,
  ONLY_PEOPLE_OR_RULES_LABEL,
  PARTICIPANTS_AND_VIEWERS_LABEL,
  EVERYONE_IN_THIS_WORKSPACE_LABEL,
  VIEWERS_LABEL,
  ANYONE_WITH_LINK,
} from '../../../../languages/en/flows/sharesheet';

import {
  AccessRole,
  LinkAccessType,
  GetFlowDetailsResponse,
} from '../../../../queries/Flows/interfaces';

import { AnonymousSettings, anonymousSettingsOptions } from '../data';
import { SHARE_SHEET_EVENTS } from '../../../../Utils/analytics/constants';
import {
  trackShareSheetActionEvent,
  trackShareSheetErrorEvent,
} from '../../../../Utils/analytics/shareSheet';

const useFlowsShareSheetController = () => {
  // Handle confirmation modal
  const {
    models: { toggleValue: isConfirmationModalOpen },
    operations: {
      setToggleToFalse: setIsConfirmationModalClose,
      setToggleToTrue: setIsConfirmationFlowModalOpen,
    },
  } = useToggle();

  const [hasModifiedChanges, setHasModifiedChanges] = useState(false);

  const isShareSheetModalOpen = useFlowBuilderStore(
    shareSheetModalOpenSelector,
  );
  const setShareSheetModalOpen = useFlowBuilderStore(
    setShareSheetModalOpenSelector,
  );
  const { anonymousSettings, setAnonymousSettings } = useFlowBuilderStore(
    shareSheetAnonymousToggleSelector,
    shallow,
  );

  const handleOnShareSheetModalClose = useCallback(() => {
    if (!hasModifiedChanges) {
      setShareSheetModalOpen(false);
    } else {
      setIsConfirmationFlowModalOpen();
    }
  }, [
    hasModifiedChanges,
    setShareSheetModalOpen,
    setIsConfirmationFlowModalOpen,
  ]);

  // Modal Handlers
  const flowName = useFlowBuilderStore(flowNameSelector);
  const emoji = useFlowBuilderStore(emojiSelector);
  const [currentModalScreen, setCurrentModalScreen] = useState(
    ShareSheetModalScreen.HOME,
  );

  const handleChooseParticipants = useCallback(() => {
    setCurrentModalScreen(ShareSheetModalScreen.PARTICIPANTS);
  }, []);

  const handleChooseViewers = useCallback(() => {
    setCurrentModalScreen(ShareSheetModalScreen.VIEWERS);
  }, []);

  const updateModalCurrentState = useCallback(
    (currentState: ShareSheetModalScreen) => {
      setCurrentModalScreen(currentState);
    },
    [],
  );

  useFlowBuilderEdit();

  const goToHomeScreen = useCallback(() => {
    setCurrentModalScreen(ShareSheetModalScreen.HOME);
  }, []);

  // Block data from Zustand Store.
  const blockData = useFlowBuilderStore(blockDataSelector);

  const setSpecificBlockData = useFlowBuilderStore(
    setSpecificBlockDataSelector,
  );

  const {
    operations: { handleCopyFlowFeedLink },
  } = useOptions();

  const copyFlowFeedLinkHandler = () => {
    trackShareSheetActionEvent({
      action: SHARE_SHEET_EVENTS.COPY_LINK,
    });
    handleCopyFlowFeedLink();
  };

  /* participant */
  const visibilityBlockData = blockData.VISIBILITY;
  const participantsBlockData = blockData.PARTICIPANTS;

  const { participantsCriteria } = participantsBlockData;
  // @ts-ignore
  const { criteriaGroups: visibilityCriteria } = visibilityBlockData;

  const [currentParticipantsBlockData, setCurrentParticipantsBlockData] =
    useState<ParticipantsBuilderBlockData>();

  useEffect(() => {
    setCurrentParticipantsBlockData(cloneDeep(participantsBlockData));
  }, [participantsBlockData]);

  const participantRulesFromCriteria = useMemo(
    () => mapRulesFromCriteriaGroups(participantsCriteria, undefined, true),
    [participantsCriteria],
  );

  const visibilityRulesFromCriteria = useMemo(
    () => mapRulesFromCriteriaGroups(visibilityCriteria, undefined, true),
    [visibilityCriteria],
  );

  const criteriaLength = useMemo(
    () => getLengthOfConditions(participantsCriteria),
    [participantsCriteria],
  );

  const updateParticipantsBlockData = useCallback(
    (updatedContentBlock: ParticipantsBuilderBlockData) =>
      setCurrentParticipantsBlockData(updatedContentBlock),
    [],
  );

  const {
    models: { totalMembers, newMembers, isLoading: isLoadingParticipantsCount },
  } = useGetMembersFromCriteria(
    participantRulesFromCriteria,
    criteriaLength && criteriaLength > 5 ? criteriaLength * 5 : 20,
    { isNewMembersAdded: true },
  );

  const {
    models: {
      totalMembers: totalVisbilityMembers,
      newMembers: newVisibilityMembers,
      searchedMembers,
      isLoading: isLoadingVisibilityMembersCount,
    },
  } = useGetMembersFromCriteria(visibilityRulesFromCriteria, 20, {
    isNewMembersAdded: true,
  });

  const participantsCount = useMemo(() => {
    if (
      !isEmpty(participantsCriteria) &&
      !isEmpty(participantsCriteria?.groups)
    ) {
      if (newMembers) {
        return totalMembers + newMembers.length;
      }

      return totalMembers;
    }

    return 0;
  }, [newMembers, totalMembers, participantsCriteria]);

  const { data: profileData } = useProfileInfoFetchQuery();

  const visibilityCount = useMemo(() => {
    if (!isEmpty(visibilityCriteria) && !isEmpty(visibilityCriteria?.groups)) {
      const visibilityMembersCount = newVisibilityMembers
        ? totalVisbilityMembers + newVisibilityMembers.length
        : totalVisbilityMembers;

      if (
        profileData &&
        searchedMembers &&
        searchedMembers.filter(
          (member) => member.memberID === profileData.member.memberId,
        ).length > 0
      ) {
        return visibilityMembersCount;
      }

      return visibilityMembersCount + 1;
    }

    return 1;
  }, [
    profileData,
    searchedMembers,
    visibilityCriteria,
    newVisibilityMembers,
    totalVisbilityMembers,
  ]);

  const subtitleContent = useMemo(() => {
    if (isLoadingParticipantsCount) {
      return LOADING_LABEL;
    }

    return participantsCount > 0
      ? `${participantsCount} ${pluralize(
          PARTICIPANT,
          participantsCount,
          false,
        )}`
      : `0 ${PARTICIPANT}`;
  }, [isLoadingParticipantsCount, participantsCount]);
  /* participant */

  /* visibility */
  const visibilityParticipantsCriteria =
    blockData.PARTICIPANTS.participantsCriteria;

  const [currentVisibilityBlockData, setCurrentVisibilityBlockData] =
    useState<VisibilityBuilderBlockData | null>(null);

  const [visibilityTitleContent, setVisibilityTitleContent] = useState('');

  useEffect(() => {
    setCurrentVisibilityBlockData(cloneDeep(visibilityBlockData));
  }, [visibilityBlockData]);

  const updateCurrentVisibilityBlockData = useCallback(
    (updatedContentBlock: VisibilityBuilderBlockData) =>
      setCurrentVisibilityBlockData(updatedContentBlock),
    [],
  );

  const handleVisibilityConfirmButtonClick = useCallback(() => {
    if (currentVisibilityBlockData) {
      trackShareSheetActionEvent({
        action: SHARE_SHEET_EVENTS.VIEWERS_SELECTED,
        modalVariant: 'choose viewers',
        viewerCriteriaSelected: serializeVisibilityBlock(blockData.VISIBILITY),
      });
      setSpecificBlockData(
        FlowBuilderBlockTypes.VISIBILITY,
        currentVisibilityBlockData,
      );
    }

    goToHomeScreen();
    setHasModifiedChanges(true);
  }, [
    blockData.VISIBILITY,
    currentVisibilityBlockData,
    goToHomeScreen,
    setSpecificBlockData,
  ]);

  const anonymousActionEventName = useMemo(() => {
    switch (anonymousSettings.id) {
      case 'anonymousOn':
        return SHARE_SHEET_EVENTS.ANONYMOUS_TOGGLE_ON;
      case 'anonymousOff':
        return SHARE_SHEET_EVENTS.ANONYMOUS_TOGGLE_OFF;
      case 'anonymousOptional':
        return SHARE_SHEET_EVENTS.ANONYMOUS_OPTIONAL_ON;
      default:
        return '';
    }
  }, [anonymousSettings.id]);

  const handleAnonymitySettingsConfirmButtonClick = useCallback(() => {
    trackShareSheetActionEvent({
      action: anonymousActionEventName,
    });
    goToHomeScreen();
    setHasModifiedChanges(true);
  }, [anonymousActionEventName, goToHomeScreen]);

  const handleVisibilityBackButtonClick = useCallback(() => {
    setCurrentVisibilityBlockData(cloneDeep(visibilityBlockData));
    goToHomeScreen();
  }, [goToHomeScreen, visibilityBlockData]);
  /* visibility */

  const handleConfirmButtonClick = useCallback(() => {
    if (currentParticipantsBlockData) {
      trackShareSheetActionEvent({
        action: SHARE_SHEET_EVENTS.PARTICIPANTS_SELECTED,
        modalVariant: 'choose participants',
        participantCriteriaSelected: mapRulesFromCriteriaGroups(
          blockData.PARTICIPANTS.participantsCriteria,
          undefined,
          false,
        ),
      });
      setSpecificBlockData(
        FlowBuilderBlockTypes.PARTICIPANTS,
        currentParticipantsBlockData,
      );
    }

    goToHomeScreen();
    setHasModifiedChanges(true);
  }, [
    blockData.PARTICIPANTS.participantsCriteria,
    currentParticipantsBlockData,
    goToHomeScreen,
    setSpecificBlockData,
  ]);

  const handleBackButtonClick = useCallback(() => {
    setCurrentParticipantsBlockData(cloneDeep(participantsBlockData));
    goToHomeScreen();
  }, [goToHomeScreen, participantsBlockData]);

  useEffect(() => {
    if (isLoadingVisibilityMembersCount) {
      setVisibilityTitleContent(LOADING_LABEL);
    }

    switch (visibilityBlockData?.type) {
      case VisibilityTypes.ENTIRE_ORGANIZATION:
        setVisibilityTitleContent(capitalizeFirstLetter(ENTIRE_ORGANIZATION));
        break;
      case VisibilityTypes.PARTICIPANTS_ONLY:
        setVisibilityTitleContent(
          `${capitalizeFirstLetter(
            PARTICIPANTS,
          )} ${ONLY} (${participantsCount})`,
        );
        break;
      case VisibilityTypes.OWNER_ONLY:
        setVisibilityTitleContent(
          `${capitalizeFirstLetter(OWNER)} ${ONLY} (1)`,
        );
        break;
      case VisibilityTypes.CUSTOM:
        setVisibilityTitleContent(
          `${capitalizeFirstLetter(CUSTOM)} (${visibilityCount})`,
        );
        break;
      default:
        break;
    }
  }, [
    visibilityCount,
    participantsCount,
    visibilityBlockData,
    isLoadingVisibilityMembersCount,
  ]);

  // Link Access Settings
  const { flowId } = useParams<{ flowId: string }>();
  const { data: flowDetails } = useFetchFlowDetailsQuery(flowId, 'builder');

  const [defaultSelectedLinkAccessType, setDefaultSelectedLinkAccessType] =
    useState(ONLY_PEOPLE_OR_RULES_LABEL);

  const [
    defaultSelectedLinkPermissionRole,
    setDefaultSelectedLinkPermissionRole,
  ] = useState(PARTICIPANTS_LABEL);

  const [defaultShowInDrawerValue, setDefaultShowInDrawerValue] =
    useState(true);

  const [selectedLinkAccessType, setSelectedLinkAccessType] = useState(
    ONLY_PEOPLE_OR_RULES_LABEL,
  );

  const [selectedLinkPermissionRole, setSelectedLinkPermissionRole] =
    useState(PARTICIPANTS_LABEL);

  const [showInDrawerValue, setShowInDrawerValue] = useState(true);

  useEffect(() => {
    const mapLinkAccessFromFlowDetails = (
      flowDetailsResponse: GetFlowDetailsResponse,
    ) => {
      const linkAccessType =
        flowDetailsResponse.data.accessPolicy?.linkAccess.type;
      const linkAccessRoles =
        flowDetailsResponse.data.accessPolicy?.linkAccess.roles;

      const showInDrawer =
        flowDetailsResponse.data.accessPolicy?.linkAccess.showInLeftDrawer;

      setDefaultShowInDrawerValue(Boolean(showInDrawer));

      if (linkAccessType === LinkAccessType.INTERNAL) {
        setDefaultSelectedLinkAccessType(EVERYONE_IN_THIS_WORKSPACE_LABEL);
      }

      if (linkAccessType === LinkAccessType.RESTRICTED) {
        setDefaultSelectedLinkAccessType(ONLY_PEOPLE_OR_RULES_LABEL);
      }

      if (linkAccessType === LinkAccessType.EXTERNAL) {
        setDefaultSelectedLinkAccessType(ANYONE_WITH_LINK);
      }

      if (linkAccessRoles && linkAccessRoles.length) {
        if (linkAccessRoles.includes(AccessRole.PARTICIPANT)) {
          setDefaultSelectedLinkPermissionRole(PARTICIPANTS_LABEL);
        }

        if (linkAccessRoles.includes(AccessRole.VIEWER)) {
          setDefaultSelectedLinkPermissionRole(VIEWERS_LABEL);
        }

        if (
          linkAccessRoles.includes(AccessRole.VIEWER) &&
          linkAccessRoles.includes(AccessRole.PARTICIPANT)
        ) {
          setDefaultSelectedLinkPermissionRole(PARTICIPANTS_AND_VIEWERS_LABEL);
        }
      }
    };

    if (flowDetails) {
      mapLinkAccessFromFlowDetails(flowDetails);
    }
  }, [flowDetails]);

  useEffect(() => {
    setSelectedLinkAccessType(defaultSelectedLinkAccessType);
  }, [defaultSelectedLinkAccessType]);

  useEffect(() => {
    setSelectedLinkPermissionRole(defaultSelectedLinkPermissionRole);
  }, [defaultSelectedLinkPermissionRole]);

  useEffect(() => {
    setShowInDrawerValue(defaultShowInDrawerValue);
  }, [defaultShowInDrawerValue]);

  const handleOnUpdateSelectionClick = useCallback(() => {
    trackShareSheetActionEvent({
      action: SHARE_SHEET_EVENTS.UPDATE_LINK,
    });

    setShowInDrawerValue(defaultShowInDrawerValue);
    setSelectedLinkAccessType(defaultSelectedLinkAccessType);
    setSelectedLinkPermissionRole(defaultSelectedLinkPermissionRole);

    goToHomeScreen();
  }, [defaultSelectedLinkAccessType, defaultSelectedLinkPermissionRole]);

  const handleOnLinkSaveButtonClick = useCallback(() => {
    trackShareSheetActionEvent({
      action: SHARE_SHEET_EVENTS.NEW_LINK_SETTINGS,
      newLinkSettings: {
        accessType: selectedLinkAccessType,
        permissions: selectedLinkPermissionRole,
      },
    });
    setDefaultShowInDrawerValue(showInDrawerValue);
    setDefaultSelectedLinkAccessType(selectedLinkAccessType);
    setDefaultSelectedLinkPermissionRole(selectedLinkPermissionRole);

    goToHomeScreen();
    setHasModifiedChanges(true);
  }, [selectedLinkAccessType, selectedLinkPermissionRole, showInDrawerValue]);

  const handleOnLinkBackButtonClick = useCallback(() => {
    setShowInDrawerValue(defaultShowInDrawerValue);
    setSelectedLinkAccessType(defaultSelectedLinkAccessType);
    setSelectedLinkPermissionRole(defaultSelectedLinkPermissionRole);

    goToHomeScreen();
  }, [
    goToHomeScreen,
    defaultShowInDrawerValue,
    defaultSelectedLinkAccessType,
    defaultSelectedLinkPermissionRole,
  ]);

  const handleOnFlowVisibleInFlowShareSheetChanged = useCallback(() => {
    setShowInDrawerValue(!showInDrawerValue);
  }, [showInDrawerValue]);

  // Update Share sheet's access policy
  const setNewParticipantsToInvite = useFlowBuilderStore(
    setNewParticipantsToInviteSelector,
  );

  const setNewVisibilityMembersToInvite = useFlowBuilderStore(
    setNewVisibilityMembersToInviteSelector,
  );

  const newVisibilityMembersToInvite = useFlowBuilderStore(
    newVisibilityMembersToInviteSelector,
  );

  const newParticipantsToInvite = useFlowBuilderStore(
    newParticipantsToInviteSelector,
  );

  const { mutateAsync: inviteUser } = useInviteUserPostQuery();

  const { mutate, isLoading: isUpdatingFlowAccessPolicy } =
    useUpdateFlowAccessPolicy(flowId);

  const handleOnDoneButtonClicked = useCallback(async () => {
    trackShareSheetActionEvent({
      action: SHARE_SHEET_EVENTS.SHARE_SHEET_COMPLETED,
      modalVariant: 'ShareSheet home',
      participantCriteriaSelected: mapRulesFromCriteriaGroups(
        blockData.PARTICIPANTS.participantsCriteria,
        undefined,
        false,
      ),
      viewerCriteriaSelected: serializeVisibilityBlock(blockData.VISIBILITY),
    });
    const membersToInvite = [
      ...newVisibilityMembersToInvite,
      ...newParticipantsToInvite,
    ];

    if (!hasModifiedChanges) {
      setShareSheetModalOpen(false);
      return;
    }

    setIsConfirmationModalClose();
    setHasModifiedChanges(false);

    if (membersToInvite.length) {
      await inviteUser({
        data: membersToInvite.map((email) => {
          return { email: email };
        }),
      });
    }

    const mapLinkAccessTypeToName = (value: string) => {
      if (value === EVERYONE_IN_THIS_WORKSPACE_LABEL) {
        return LinkAccessType.INTERNAL;
      }

      if (value === ANYONE_WITH_LINK) {
        return LinkAccessType.EXTERNAL;
      }

      return LinkAccessType.RESTRICTED;
    };

    const mapLinkPermissionRoleToName = (value: string) => {
      if (value === PARTICIPANTS_LABEL) {
        return [AccessRole.PARTICIPANT];
      }

      if (value === VIEWERS_LABEL) {
        return [AccessRole.VIEWER];
      }

      return [AccessRole.VIEWER, AccessRole.PARTICIPANT];
    };

    const payload = {
      viewing: serializeVisibilityBlock(blockData.VISIBILITY),
      participation: mapRulesFromCriteriaGroups(
        blockData.PARTICIPANTS.participantsCriteria,
        undefined,
        false,
      ),
      responseSettings: {
        anonymity: {
          state: getCriteriaForAnonymityBasedOnDropdown(
            anonymousSettings.id as AnonymousSettings,
          ),
        },
      },
      linkAccess: {
        showInLeftDrawer:
          showInDrawerValue &&
          selectedLinkAccessType === EVERYONE_IN_THIS_WORKSPACE_LABEL,
        type: mapLinkAccessTypeToName(selectedLinkAccessType),
        roles: mapLinkPermissionRoleToName(selectedLinkPermissionRole),
      },
    };

    mutate(payload, {
      onSettled: () => {
        setShareSheetModalOpen(false);
      },
      onSuccess: () => {
        setShareSheetModalOpen(false);
        showInfoMessage('Updated permissions successfully');
      },
      onError: (error: unknown) => {
        trackShareSheetErrorEvent({
          error: SHARE_SHEET_EVENTS.SHARE_SHEET_ERROR,
        });
        const axiosError = error as AxiosError;
        if (axiosError) {
          const errorMessage = getErrorMessage(axiosError);
          if (errorMessage === `data/participation must be object`) {
            showErrorMessage('At least one participant needs to be selected');
          } else {
            showErrorMessage(errorMessage);
          }
        }
      },
    });
  }, [
    blockData.PARTICIPANTS.participantsCriteria,
    blockData.VISIBILITY,
    newVisibilityMembersToInvite,
    newParticipantsToInvite,
    hasModifiedChanges,
    setIsConfirmationModalClose,
    selectedLinkAccessType,
    selectedLinkPermissionRole,
    showInDrawerValue,
    anonymousSettings?.id,
    mutate,
    setShareSheetModalOpen,
    inviteUser,
  ]);

  useEffect(() => {
    return () => {
      setNewParticipantsToInvite([]);
      setNewVisibilityMembersToInvite([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAnonymousSettingsSelection = useCallback(
    (value: string | number) => {
      if (value) {
        const selectedOption = anonymousSettingsOptions.find(
          (option) => option.id === value,
        );
        if (selectedOption) {
          setAnonymousSettings(selectedOption);
        }
      }
    },
    [setAnonymousSettings],
  );

  return {
    showInDrawerValue,
    visibilityCount,
    participantsCount,
    subtitleContent,
    selectedLinkAccessType,
    currentModalScreen,
    selectedLinkPermissionRole,
    isShareSheetModalOpen,
    visibilityTitleContent,
    isConfirmationModalOpen,
    isUpdatingFlowAccessPolicy,
    currentVisibilityBlockData,
    currentParticipantsBlockData,
    visibilityParticipantsCriteria,
    flowName: `${emoji?.native} ${flowName}`,
    anonymousSettings,
    handleAnonymousSettingsSelection,
    handleOnLinkSaveButtonClick,
    setSelectedLinkAccessType,
    goToHomeScreen,
    handleChooseViewers,
    handleBackButtonClick,
    setShareSheetModalOpen,
    handleCopyFlowFeedLink: copyFlowFeedLinkHandler,
    handleConfirmButtonClick,
    handleChooseParticipants,
    handleOnDoneButtonClicked,
    setIsConfirmationModalClose,
    updateParticipantsBlockData,
    handleOnShareSheetModalClose,
    handleVisibilityBackButtonClick,
    updateCurrentVisibilityBlockData,
    handleVisibilityConfirmButtonClick,
    updateModalCurrentState,
    handleAnonymitySettingsConfirmButtonClick,
    handleOnUpdateSelectionClick,
    handleOnLinkBackButtonClick,
    setSelectedLinkPermission: setSelectedLinkPermissionRole,
    handleOnFlowVisibleInFlowShareSheetChanged,
  };
};

export default useFlowsShareSheetController;
