import styled from 'styled-components';
import Body from '../../atoms/Body';
import BasicModalTemplate from '../../templates/BasicModalTemplate';
import Input from '../../molecules/Input';
import Dropdown from '../../molecules/Dropdown_V2';
import { DropdownItemContainer } from '../../molecules/DropdownListItem_V2/styles';

export const StyledBasicModalTemplate = styled(BasicModalTemplate)`
  max-width: 356px;
`;

export const StyledLabel = styled(Body)`
  margin-bottom: 8px;
`;

export const StyledDropdown = styled(Dropdown)`
  ${DropdownItemContainer} {
    min-width: 100px;
  }
`;

export const RepeatEveryRow = styled.div`
  display: flex;
  column-gap: 8px;
  margin-bottom: 24px;
`;

export const StyledInput = styled(Input)`
  margin-right: 8px;
  width: 72px;
  &.MuiFormControl-fullWidth,
  .MuiInputBase-input {
    box-sizing: border-box;
    width: 72px;
  }

  & .MuiFormHelperText-root {
    position: absolute;
    margin-left: 0px;
    margin-top: 44px;
    white-space: nowrap;
  }
`;

export const RepeatOnRow = styled.div`
  display: flex;
  column-gap: 16px;
`;
