import React, { useMemo } from 'react';
import ContentLoader from 'react-content-loader';

import { StyledInfoAlert, StyledLink } from './styles';
import Body from '../../../atoms/Body';
import { getMenuItems } from './utils';
import BlockSection from '../../BlockSection';
import { MeetingParticipantsListProps } from './type';
import InputGroup from '../../../molecules/InputGroup';
import { quickSetupMeetingParticipantsListScreen } from '../../../../languages/en/quickSetup';
import ThemeV2 from '../../../../componentsV2/theme';
import { Flex } from '../../../../Utils/styles/display';

const MeetingParticipantsList = (props: MeetingParticipantsListProps) => {
  const {
    onBlur,
    flowName,
    isLoading,
    onValueChange,
    onDeleteItemClick,
    meetingParticipantsList,
  } = props;

  const headingComponent = useMemo(() => {
    return `${quickSetupMeetingParticipantsListScreen.headingText} ${flowName}`;
  }, [flowName]);

  const subHeadingComponent = useMemo(() => {
    return (
      <Body variant="body1" color="gray8">
        {quickSetupMeetingParticipantsListScreen.getQuickSetupMeetingListSubHeadingText(
          flowName,
        )}
        <StyledLink
          to={'https://www.joinassembly.com/articles/flows-overview'}
          isAnchor
          openInNewTab
        >
          {quickSetupMeetingParticipantsListScreen.learnMoreLink}
        </StyledLink>
      </Body>
    );
  }, [flowName]);

  return (
    <BlockSection
      headingVariant="h4"
      subHeadingColor="gray8"
      subHeadingVariant="body1"
      heading={headingComponent}
      subHeading={subHeadingComponent}
      marginBottom="12px"
    >
      <StyledInfoAlert
        id="groupInviteAlert"
        text="If these email addresses are Groups, the people in these groups won’t be invited."
        icon="info-outlined"
        alertType="custom"
        customVariant={{
          background: ThemeV2.palette.geekBlue2,
          iconColor: ThemeV2.palette.geekBlue6,
        }}
        bodyVariant="body3"
      />
      {isLoading ? (
        <Flex justifyContent="space-between">
          <ContentLoader
            speed={2}
            width={266}
            height={48}
            viewBox="0 0 266 48"
            backgroundColor={ThemeV2.palette.gray4}
            foregroundColor={ThemeV2.palette.gray3}
            data-testid="header-loader"
          >
            <rect x="0" y="0" rx="4" ry="4" width="266" height="48" />
          </ContentLoader>
          <ContentLoader
            speed={2}
            width={188}
            height={48}
            viewBox="0 0 188 48"
            backgroundColor={ThemeV2.palette.gray4}
            foregroundColor={ThemeV2.palette.gray3}
            data-testid="header-loader"
          >
            <rect x="0" y="0" rx="4" ry="4" width="188" height="48" />
          </ContentLoader>
        </Flex>
      ) : (
        meetingParticipantsList.map((participant, index) => {
          return (
            <InputGroup
              id={participant.id}
              onBlur={onBlur}
              margin="0 0 24px 0"
              key={participant.id}
              textFieldValue={participant.email}
              dropdownValue={participant.status}
              canShowToolTip={participant.owner}
              canShowDeleteIcon={
                !participant.owner &&
                index !== meetingParticipantsList.length - 1
              }
              isTextFieldDisabled={participant.owner}
              isDropdownDisabled={!participant.email || participant.owner}
              menuItems={getMenuItems()}
              onDeleteIconClick={() => {
                onDeleteItemClick(participant.id);
              }}
              onTextFieldValueChange={(e) => {
                onValueChange({
                  id: participant.id,
                  email: e.target.value,
                  status: participant.status,
                  owner: participant.owner,
                  hasError: participant.hasError,
                });
              }}
              onItemClick={(value) => {
                onValueChange({
                  id: participant.id,
                  email: participant.email,
                  status: value as string,
                  owner: participant.owner,
                  hasError: participant.hasError,
                });
              }}
              toolTipText={quickSetupMeetingParticipantsListScreen.toolTipText}
              label={quickSetupMeetingParticipantsListScreen.label}
              hasError={participant.hasError}
              helperText={quickSetupMeetingParticipantsListScreen.helperText}
            />
          );
        })
      )}
    </BlockSection>
  );
};

export default MeetingParticipantsList;
