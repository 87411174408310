import { MenuItemIndividualItem } from '../../../atomic/molecules/Dropdown_V2/interfaces';
import { allFlowFeedHeaderOptions } from './data';
import { GetFlowHeaderOptionItemsParams } from './types';

export const getFlowHeaderOptionItems = (
  data: GetFlowHeaderOptionItemsParams,
) => {
  const { isFlowOwner, isFlowParticipant, isFlowViewer, occurrence, isMuted } =
    data;
  const { activeOccurrence } = occurrence;
  const headerOptionItems: MenuItemIndividualItem[] = [];

  if (isFlowOwner && activeOccurrence) {
    headerOptionItems.push(allFlowFeedHeaderOptions.endOccurrence);
  }

  if (isFlowParticipant || (isFlowOwner && isFlowViewer)) {
    headerOptionItems.push(allFlowFeedHeaderOptions.editNotificationSettings);
  }

  if (isFlowViewer) {
    if (isMuted) {
      headerOptionItems.push(allFlowFeedHeaderOptions.showActivity);
    } else {
      headerOptionItems.push(allFlowFeedHeaderOptions.hideActivity);
    }
  }

  if (isFlowOwner) {
    let archiveOption = allFlowFeedHeaderOptions.archiveFlow;
    if (activeOccurrence) {
      archiveOption = { ...archiveOption, disabled: true };
    }
    headerOptionItems.push(archiveOption);
  }

  return headerOptionItems;
};
