import styled from 'styled-components';

import Avatar from '../../atoms/Avatar';
import Button from '../../atoms/Button';
import Heading from '../../atoms/Heading';
import { Flex } from '../../../Utils/styles/display';

export const Wrapper = styled.div`
  padding: 16px;
`;

export const StyledFlex = styled(Flex)`
  margin-bottom: 16px;
`;

export const StyledAvatar = styled(Avatar)`
  margin-right: 16px;
`;

export const NameAndUsernameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const StyledHeading = styled(Heading)`
  margin-top: 0px;
  margin-bottom: 0px;
`;

export const StyledButton = styled(Button)`
  margin-top: 16px;
`;
