// @ts-nocheck TODO: type issues need to be fixed in this file
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { string, arrayOf, shape, object, func, bool, number } from 'prop-types';

import { buttonShape } from '../../organism/StepsWizard';
import Body from '../../atoms/Body';
import RadioGroup from '../../molecules/RadioGroup';
import IdentityManagerFlowTemplate from '../../templates/IdentityManagerFlowTemplate';

const useStyles = makeStyles({
  heading: {
    marginBottom: '4px',
  },
  subHeading: {
    marginBottom: '16px',
  },
  radioGroup: {
    marginBottom: '24px',
  },
  autoApprovalSection: {
    marginTop: '24px',
  },
});

const IdentityConfigureInvitesPage = (props) => {
  const {
    title,
    steps,
    activeStep,
    nextButton,
    cancelButton,
    leftButton,
    invitesHeading,
    invitesSubHeading,
    invitesRadioGroupOptions,
    invitesRadioGroupValue,
    onInvitesRadioGroupChange,
    showApprovalRadioGroup,
    approvalHeading,
    approvalSubHeading,
    approvalRadioGroupOptions,
    approvalRadioGroupValue,
    onApprovalRadioGroupChange,
    notifications,
    helperLink,
  } = props;
  const classes = useStyles(props);
  const stepsWizard = {
    title,
    steps,
    activeStep,
    nextButton,
    cancelButton,
    leftButton,
    helperLink,
  };
  return (
    <IdentityManagerFlowTemplate
      stepsWizard={stepsWizard}
      notifications={notifications}
    >
      <Body variant="body1Bold" className={classes.heading}>
        {invitesHeading}
      </Body>
      <Body variant="body2" className={classes.subHeading}>
        {invitesSubHeading}
      </Body>
      <RadioGroup
        classes={classes.radioGroup}
        radioOptions={invitesRadioGroupOptions}
        value={invitesRadioGroupValue}
        onChange={onInvitesRadioGroupChange}
      />
      {showApprovalRadioGroup && (
        <div className={classes.autoApprovalSection}>
          <Body variant="body1Bold" className={classes.heading}>
            {approvalHeading}
          </Body>
          <Body variant="body2" className={classes.subHeading}>
            {approvalSubHeading}
          </Body>
          <RadioGroup
            radioOptions={approvalRadioGroupOptions}
            value={approvalRadioGroupValue}
            onChange={onApprovalRadioGroupChange}
          />
        </div>
      )}
    </IdentityManagerFlowTemplate>
  );
};

IdentityConfigureInvitesPage.propTypes = {
  title: string.isRequired,
  activeStep: string.isRequired,
  steps: arrayOf(
    shape({
      id: string.isRequired,
      title: string.isRequired,
      leftButton: object,
      cancelButton: object,
      nextButton: object,
    }),
  ).isRequired,
  nextButton: buttonShape.isRequired,
  cancelButton: buttonShape.isRequired,
  leftButton: buttonShape.isRequired,

  invitesHeading: string.isRequired,
  invitesSubHeading: string.isRequired,
  invitesRadioGroupOptions: arrayOf(
    shape({
      value: bool.isRequired,
      label: string.isRequired,
    }),
  ).isRequired,
  invitesRadioGroupValue: bool.isRequired,
  onInvitesRadioGroupChange: func.isRequired,

  showApprovalRadioGroup: bool.isRequired,

  approvalHeading: string,
  approvalSubHeading: string,
  approvalRadioGroupOptions: arrayOf(
    shape({
      value: bool,
      label: string,
    }),
  ),
  approvalRadioGroupValue: bool,
  onApprovalRadioGroupChange: func,
  // TODO: replace with interface in useNotification after tsx conversation
  notifications: arrayOf(
    shape({
      id: number.isRequired,
      message: string.isRequired,
      type: string.isRequired,
    }),
  ),
  helperLink: string,
};

IdentityConfigureInvitesPage.defaultProps = {
  approvalHeading: '',
  approvalSubHeading: '',
  approvalRadioGroupOptions: [],
  approvalRadioGroupValue: null,
  onApprovalRadioGroupChange: () => {},
  notifications: [],
  helperLink: '',
};

export default IdentityConfigureInvitesPage;
