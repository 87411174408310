import React, { useEffect, useState } from 'react';

import Body from '../../atoms/Body';
import Divider from '../../atoms/Divider';
import Dropdown from '../../molecules/Dropdown_V2';
import { StyledSVGIcon } from '../../molecules/FlowsToDoCard/styles';

import { menuItems } from './data';
import ThemeV2 from '../../../componentsV2/theme';
import { Flex } from '../../../Utils/styles/display';
import { FLOW_RUN_MODAL } from '../../../languages/en/flows';
import {
  FlowRunNowHeaderProps,
  FlowRunNowModalProps,
  OccurrenceValues,
} from './types';

import {
  ContentWrapper,
  DescriptionBody,
  LeftPanelWrapper,
  StyledBasicModalTemplate,
  StyledFlex,
  StyledInput,
  StyledWrapper,
} from './styles';
import {
  DropdownColor,
  DropdownPlacement,
  DropdownVariants,
} from '../../molecules/Dropdown_V2/interfaces';
import { CANCEL, RUN } from '../../../languages/en/singleWords';
import { ModalButtonProps } from '../../templates/BasicModalTemplate';
import { FLOW_TRIGGER_MODAL_VALIDATION_ERROR } from '../../../languages/en/flows/feed';
import { singularizeWord } from '../../../Utils/text';
import Link from '../../atoms/Link_V2';
import { ASSEMBLY_EMAILS } from '../../../Utils/constants';
import { getVisibilityTooltipText } from '../../../Utils/flows';
import ParticipantsList from '../../molecules/ParticipantsList';
import { ComponentStatus } from '../../../interfaces/component';

const ContentContainer = (props: FlowRunNowHeaderProps) => {
  const { heading, subHeading, membersCount = 0, showMembersCount } = props;
  return (
    <StyledWrapper>
      <Body variant="body1Medium">{heading}</Body>
      <Body variant="body3" color="gray8">
        {subHeading}
      </Body>
      {showMembersCount && membersCount >= 0 && (
        <StyledFlex>
          <StyledSVGIcon
            icon="people"
            size="16px"
            color={ThemeV2.palette.geekBlue6}
          />
          <Body variant="body2">
            {`${membersCount} ${FLOW_RUN_MODAL.MEMBERS_LABEL}`}
          </Body>
        </StyledFlex>
      )}
    </StyledWrapper>
  );
};

const FlowRunNowModal = (props: FlowRunNowModalProps) => {
  const {
    value,
    isOpen,
    modalHeading,
    onChange,
    onModalClose,
    participants,
    visibilityCriteria,
    participantsCount,
    occurrenceValue,
    onOccurrenceChange,
    onRunNowButtonClick,
    hasMoreMembers = false,
    fetchNextPage = () => {},
    isLoading = false,
    pendingMembersParticipantCount,
  } = props;

  const [validationError, setValidationError] = useState<string | null>(null);

  const primaryButton: ModalButtonProps = {
    text: FLOW_RUN_MODAL.RUN_NOW_LABEL,
    onClick: onRunNowButtonClick,
    isLoading,
    disabled: Boolean(validationError) || value < 1,
  };

  const secondaryButton: ModalButtonProps = {
    text: CANCEL,
    onClick: onModalClose,
    variant: 'text',
  };

  const minMinutes = 5;
  const maxMinutes = 44640;
  const maxHours = 744;
  const maxDays = 31;
  const maxWeeks = 4;

  useEffect(() => {
    if (occurrenceValue === 'minutes') {
      if (value < minMinutes) {
        setValidationError(FLOW_TRIGGER_MODAL_VALIDATION_ERROR.MINUTES.LOWER);
      } else if (value > maxMinutes) {
        setValidationError(FLOW_TRIGGER_MODAL_VALIDATION_ERROR.MINUTES.HIGHER);
      } else {
        setValidationError(null);
      }
    } else if (occurrenceValue === 'hours' && value > maxHours) {
      setValidationError(FLOW_TRIGGER_MODAL_VALIDATION_ERROR.HOURS);
    } else if (occurrenceValue === 'days' && value > maxDays) {
      setValidationError(FLOW_TRIGGER_MODAL_VALIDATION_ERROR.DAYS);
    } else if (occurrenceValue === 'weeks' && value > maxWeeks) {
      setValidationError(FLOW_TRIGGER_MODAL_VALIDATION_ERROR.WEEKS);
    } else {
      setValidationError(null);
    }
  }, [value, occurrenceValue]);

  const onDropdownItemClick = (e: string | number) => {
    onOccurrenceChange(e as OccurrenceValues);
  };

  const handleOnChange = (e: { target: { value: number } }) => {
    onChange(e.target.value < 0 ? 0 : e.target.value);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === '-') {
      event.preventDefault();
    }
  };

  return (
    <StyledBasicModalTemplate
      isOpen={isOpen}
      childrenPadding="0"
      onModalClose={onModalClose}
      heading={`${RUN} ${modalHeading}`}
      primaryButton={primaryButton}
      secondaryButton={secondaryButton}
      alignActionButtons="right"
    >
      <DescriptionBody variant="body2">
        {FLOW_RUN_MODAL.DESCRIPTION_LABEL}
      </DescriptionBody>
      <Divider isFullWidth color={ThemeV2.palette.gray4} />
      <ContentWrapper>
        <LeftPanelWrapper>
          <ContentContainer
            heading={FLOW_RUN_MODAL.OCCURRENCE_LABEL}
            subHeading={FLOW_RUN_MODAL.OCCURRENCE_DESCRIPTION}
          />

          <StyledWrapper>
            <Flex data-testid="occurrence-input">
              <StyledInput
                value={value}
                name="TextField_V2"
                type="textV3_number"
                error={Boolean(validationError)}
                helperText={validationError}
                disableClearButton
                onChange={handleOnChange}
                onKeyPress={handleKeyPress}
              />
              <Dropdown
                menuItems={menuItems}
                value={occurrenceValue}
                variant={DropdownVariants.Text}
                color={DropdownColor.Secondary}
                dropdownPlacement={DropdownPlacement.Bottom}
                onItemClick={onDropdownItemClick}
                getItemLabel={(label: string) =>
                  value > 1 ? label : singularizeWord(label)
                }
              />
            </Flex>
          </StyledWrapper>

          <ContentContainer
            heading={FLOW_RUN_MODAL.PARTICIPANTS_LABEL}
            subHeading={
              <>
                {FLOW_RUN_MODAL.PARTICIPANTS_DESCRIPTION}{' '}
                <Link to={ASSEMBLY_EMAILS.SUPPORT.LINK} isAnchor>
                  {ASSEMBLY_EMAILS.SUPPORT.TEXT}
                </Link>
              </>
            }
            showMembersCount
            membersCount={participantsCount}
          />

          <ContentContainer
            heading={FLOW_RUN_MODAL.VISIBILITY_LABEL}
            subHeading={
              visibilityCriteria && getVisibilityTooltipText(visibilityCriteria)
            }
          />
        </LeftPanelWrapper>
        <ParticipantsList
          title={FLOW_RUN_MODAL.PARTICIPANT_LABEL}
          participants={participants}
          fetchNextPage={fetchNextPage}
          hasMoreMembers={hasMoreMembers}
          status={ComponentStatus.LOADED}
          participantsCount={participantsCount}
          pendingMembersParticipantCount={pendingMembersParticipantCount}
          restrictHeight
        />
      </ContentWrapper>
    </StyledBasicModalTemplate>
  );
};

export default FlowRunNowModal;
