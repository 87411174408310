import React from 'react';
import styled from 'styled-components';

import Body from '../../atoms/Body';
import { ErrorWrapper } from './styles';
import Image from '../../atoms/Image';
import RightDrawerErrorImage from '../../../img/home/right-drawer-error.svg';
import {
  SOMETHING_WENT_WRONG,
  TRY_AGAIN_LATER,
} from '../../../languages/en/home';

const ErrorMessageWrapper = styled.div`
  margin-top: 24px;
`;

const StyledImage = styled(Image)`
  margin-top: 32px;
`;

const MembersListError = () => (
  <ErrorWrapper>
    <StyledImage
      alt={SOMETHING_WENT_WRONG}
      height="175"
      src={RightDrawerErrorImage}
      width="196"
    />
    <ErrorMessageWrapper>
      <Body variant="body1Bold">{SOMETHING_WENT_WRONG}</Body>
      <Body variant="body2">{TRY_AGAIN_LATER}</Body>
    </ErrorMessageWrapper>
  </ErrorWrapper>
);

export default MembersListError;
