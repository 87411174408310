import { FeedPostUserProps } from '../../../interfaces/Feed';
import { MemberState } from '../../../interfaces/user';

export const users: FeedPostUserProps[] = [
  {
    firstName: 'Jonathan',
    lastName: 'Fields',
    username: 'jonathanfields',
    isDeleted: false,
    memberID: '5f97b51f6422d549701538co',
    memberState: MemberState.ACTIVE,
  },
  {
    firstName: 'Joshua',
    lastName: 'Purvis',
    username: 'joshuapurvis',
    isDeleted: false,
    memberID: '5f97b51f6422d549701538cd',
    memberState: MemberState.ACTIVE,
  },
  {
    firstName: 'Katya',
    lastName: 'Bachrouche',
    username: 'katyabachrouche',
    isDeleted: false,
    memberID: '5f97b51f6422d549701538ce',
    memberState: MemberState.ACTIVE,
  },
  {
    firstName: 'Brooke',
    lastName: 'Rosenthal',
    username: 'brookerosenthal',
    isDeleted: false,
    memberID: '5f97b51f6422d549701538cf',
    memberState: MemberState.ACTIVE,
  },
  {
    firstName: 'Hema',
    lastName: 'Ramakrishna',
    username: 'hemaramakrishna',
    isDeleted: false,
    memberID: '5f97b51f6422d549701538cg',
    memberState: MemberState.ACTIVE,
  },
  {
    firstName: 'Omar',
    lastName: 'De Los Santos',
    username: 'omardelossantos',
    isDeleted: false,
    memberID: '5f97b51f6422d549701538ci',
    memberState: MemberState.ACTIVE,
  },
  {
    firstName: 'Bobby',
    lastName: 'Hill',
    username: 'bobbyhill',
    isDeleted: false,
    memberID: '5f97b51f6422d549701538cj',
    memberState: MemberState.DEACTIVATED,
  },
  {
    firstName: 'Hank',
    lastName: 'Hill',
    username: 'hankhill',
    isDeleted: false,
    memberID: '5f97b51f6422d549701538cl',
    memberState: MemberState.ACTIVE,
  },
  {
    firstName: 'Peggy',
    lastName: 'Hill',
    username: 'peggyhill',
    isDeleted: false,
    memberID: '5f97b51f6422d549701538cm',
    memberState: MemberState.ACTIVE,
  },
  {
    firstName: 'Cotton',
    lastName: 'Hill',
    username: 'connorhoy',
    isDeleted: false,
    memberID: '5f97b51f6422d549701538cn',
    memberState: MemberState.ACTIVE,
  },
];
