import { useQuery } from 'react-query';

import { SisenseAuthResponse } from './type';
import { makeAPICallWithDataReturn } from '../../utils';
import { AUTHORIZE_SISENSE } from '../../../constants/endpoints';

export const useSisenseAuthQuery = (returnTo: string, enabled: boolean) => {
  return useQuery<SisenseAuthResponse>(
    AUTHORIZE_SISENSE,
    () =>
      makeAPICallWithDataReturn(AUTHORIZE_SISENSE, null, {
        returnTo: returnTo,
      }),
    {
      retry: false,
      enabled: enabled,
      staleTime: Infinity,
    },
  );
};
