import { useUpdateTimeZoneMutation } from '../../queries/Settings';
import { detectTimezone } from '../../Utils/date';

const useUpdateTimezone = (
  timeZoneValue: string | undefined,
  isLoading: boolean,
  authenticated: boolean,
) => {
  const {
    mutate: updateTimezone,
    isLoading: isUpdateUserSettingsLoading,
    isSuccess: isUpdateUserSettingsSuccess,
  } = useUpdateTimeZoneMutation();

  const detectedTimezoneValue = detectTimezone();
  if (
    authenticated &&
    !isLoading &&
    (timeZoneValue === '' || timeZoneValue === undefined) &&
    detectedTimezoneValue &&
    !isUpdateUserSettingsLoading &&
    !isUpdateUserSettingsSuccess
  ) {
    updateTimezone({ timeZone: detectedTimezoneValue });
  }
};

export default useUpdateTimezone;
