import React from 'react';

import { FlowFeedFilterProps } from './types';
import { ComponentStatus } from '../../../../interfaces/component';

import LoadedComponent from './LoadedComponent';
import LoadingComponent from '../LoadingComponent';
import EmptyComponent from '../EmptyComponent';
import ErrorComponent from '../ErrorComponent';

const FlowFeedFilter = (props: FlowFeedFilterProps) => {
  switch (props.status) {
    case ComponentStatus.LOADED:
      return <LoadedComponent {...props} />;
    case ComponentStatus.LOADING:
      return <LoadingComponent isFlowFeedFilterLoading />;
    case ComponentStatus.ERROR:
      return <ErrorComponent />;
    default: {
      return <EmptyComponent isFlowFeedFilterEmpty />;
    }
  }
};

export default FlowFeedFilter;
