import styled from 'styled-components';
import ThemeV2 from '../../../componentsV2/theme';
import Body from '../Body';

import { StyledPrimaryNavItemProps } from './interfaces';

export const StyledPrimaryNavItem = styled(Body)<StyledPrimaryNavItemProps>`
  height: 32px;
  border-radius: 4px;
  color: ${ThemeV2.palette.gray8};
  cursor: pointer;
  display: flex;
  flex: 1;
  padding: 4px 8px;
  margin: 0 4px 0 4px;
  text-decoration: none;
  align-items: center;

  background-color: ${(props) => props.isActive && ThemeV2.palette.geekBlue1};

  svg {
    margin-right: 4px;
    display: flex;
  }

  &:hover {
    color: ${ThemeV2.palette.geekBlue6};

    // Todo: too much tightly coupled code, need refactor
    svg path:not(#admin-icon-border) {
      fill: ${ThemeV2.palette.geekBlue6};
    }
  }

  &:active {
    background-color: ${ThemeV2.palette.geekBlue1};
  }

  ${({ isActive }) =>
    isActive &&
    `
    background-color: ${ThemeV2.palette.geekBlue1};
    color: ${ThemeV2.palette.geekBlue6};
    svg path:not(#admin-icon-border) {
      fill: ${ThemeV2.palette.geekBlue6};
    }
  `}
`;
