export const menuItems = [
  {
    id: 'category-one',
    category: 'Category Name',
    items: [
      {
        value: 'Dropdown Item 1',
        id: '1',
        isSelected: false,
      },
      {
        value: 'Dropdown Item 2',
        id: '2',
        isSelected: false,
      },
      {
        value: 'Dropdown Item 3',
        id: '3',
        isSelected: false,
      },
    ],
  },
];
