import styled from 'styled-components';
import NavItem from '../../atoms/NavItem';
import SVGIcon from '../../atoms/SVGIcon';
import ThemeV2 from '../../../componentsV2/theme';
import IconButton from '../../atoms/IconButton_V2';
import TextWithIcon from '../../atoms/TextWithIcon';
import { StyledIconWrapper } from '../../atoms/TextWithIcon/styles';
import ToolTip from '../ToolTipV2';
import Body from '../../atoms/Body';
import RightClickable from '../../atoms/RightClickable';
import {
  Badge,
  ChildrenWrapper,
  StyledEmoticon,
  StyledNavItem as NavItemRoot,
  StyledSVGPrefixIcon,
} from '../../atoms/NavItem/styles';

export const StyledNavItem = styled(NavItem)<{
  isArchived?: boolean;
  badgeText?: string;
}>`
  padding: 4px 12px 4px 28px;
  margin-bottom: 8px;
  border-radius: 4px;
  &:not(:hover) {
    filter: ${({ isArchived }) => isArchived && 'grayscale(1)'};
    svg {
      display: ${({ isArchived }) => isArchived && 'none'};
    }
  }
  ${NavItemRoot} {
    border-radius: 40px 0px 4px 0px;
  }
  ${StyledEmoticon} {
    width: 24px;
    height: 24px;
    display: block;
    text-align: center;
    margin-right: 8px;
    letter-spacing: -4px;
    margin-left: -4px;
    line-height: 1.6;
  }
  ${StyledSVGPrefixIcon} {
    position: absolute;
    left: 0px;
  }

  ${ChildrenWrapper} {
    max-width: ${({ badgeText }) => (badgeText !== '' ? '80%' : '100%')};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &:hover {
    ${Badge} {
      background: ${ThemeV2.palette.geekBlue6};
      transition: 0.2s ease;
      transition-property: color, background;
    }
  }
`;

export const NavigationExpandBar = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 4px;
  cursor: pointer;
  user-select: none;
`;

export const StyledWrapper = styled.div`
  min-width: 100%;
`;

export const StyledSVGIcon = styled(SVGIcon)`
  margin-right: 8px;
  svg path {
    fill: ${ThemeV2.palette.gray8};
  }
`;

export const StyledToolTip = styled(ToolTip)`
  margin-left: auto;
`;

export const StyledIconButton = styled(IconButton)`
  margin-left: auto;
`;

export const StyledTextWithIcon = styled(TextWithIcon)`
  padding: 4px 8px 4px 24px;
  cursor: pointer;
  border-radius: 4px;
  &:hover {
    background: ${ThemeV2.palette.geekBlue1};
    & ${StyledIconWrapper} {
      background: ${ThemeV2.palette.geekBlue2};
    }
    span {
      color: ${ThemeV2.palette.geekBlue6};
    }
  }
`;

export const ErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 8px;
`;

export const StyledErrorText = styled(Body)`
  margin-left: 8px;
`;

export const EmptyTextWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 8px;
  margin-bottom: 8px;
  span {
    line-height: 1;
  }
`;

export const StyledRightClickable = styled(RightClickable)`
  &:focus-visible {
    outline: 0;
    ${StyledNavItem} {
      outline: -webkit-focus-ring-color auto 1px;
    }
  }
`;
