import React from 'react';
import CalendarSetup from '../../../atomic/organism/QuickSetup/CalendarSetup';
import { CalendarSelectionProps } from './type';
import useCalendarSelectionController from './useCalendarSelectionController';

const CalendarSelectionController = (props: CalendarSelectionProps) => {
  const {
    models: { googleEmbedUrl, outlookEmbedUrl, isWorkatoSetupError },
    operations: { handleOnCalendarSelected },
  } = useCalendarSelectionController(props);

  return (
    <CalendarSetup
      googleEmbedUrl={googleEmbedUrl}
      outlookEmbedUrl={outlookEmbedUrl}
      onCalendarSelected={handleOnCalendarSelected}
      isWorkatoSetupError={isWorkatoSetupError}
      isWorkatoSetupComplete={props.isWorkatoSetupComplete}
    />
  );
};

export default CalendarSelectionController;
