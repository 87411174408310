import { UploadedUppyFile, UppyFile } from '@uppy/core';

import { LegacyFileType, FileUploadStatus } from '../../FileCard/types';
import { FlowFileForAPI } from '../../../../interfaces/Flow';

export const determineFileStatus = (file: UppyFile): FileUploadStatus => {
  if (file.progress?.uploadComplete) {
    return FileUploadStatus.Success;
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  /* @ts-ignore */
  if (file.error) {
    return FileUploadStatus.Error;
  }

  return FileUploadStatus.Loading;
};

export const determineFileType = (file: UppyFile): LegacyFileType => {
  const imageFileExtensions = ['jpg', 'jpeg', 'png', 'svg'];
  if (imageFileExtensions.includes(file.extension.toLowerCase())) {
    return LegacyFileType.Image;
  }

  return LegacyFileType.File;
};

export const sanitizeFileName = (fileName: string) => {
  const fileNameWithoutExtension =
    fileName.substring(0, fileName.lastIndexOf('.')) || fileName;
  const extension = fileName.split('.').pop();

  return `${fileNameWithoutExtension
    .replace(/[^a-z0-9]/gi, '_')
    .toLowerCase()}.${extension?.toLowerCase()}`;
};

export const transformUploadedUppyFileToFileForAPI = (
  uploadedUppyFile: UploadedUppyFile<null, null>,
): FlowFileForAPI => {
  const { extension, name, progress, size, uploadURL } = uploadedUppyFile;
  return {
    createdAt: new Date(progress?.uploadStarted || Date.now()),
    type: extension.toLowerCase(),
    location: uploadURL,
    name: sanitizeFileName(name),
    size,
  };
};
