import { AxiosError, AxiosResponse } from 'axios';
import produce from 'immer';
import { useMemo } from 'react';
import {
  useInfiniteQuery,
  InfiniteData,
  useMutation,
  useQueryClient,
  useQuery,
  QueryKey,
} from 'react-query';
import { useParams } from 'react-router-dom';
import {
  V3_GET_FEED,
  V3_SEARCH_FEED,
  CREATE_POST,
  UPDATE_POST_REACTION,
  ADMIN_DELETE_MEDIA,
  ADMIN_DELETE_POST,
  GET_PROFILE_INFO,
  GET_SINGLE_POST,
  GET_MAIN_FEED,
  UPDATE_FLOW_POST_REACTION,
  GET_PROFILE_FEED,
  GET_FLOW_RESPONSE,
  GET_DATE_FILTER_OPTIONS,
  GET_FLOWS_FILTER_OPTIONS,
  GET_FLOWS_MENTIONS_FILTER_OPTIONS,
} from '../../constants/endpoints';

import {
  FeedItemFromAPI,
  FeedOptionsInterface,
  FeedSearchPayload,
  PostFilterOptions,
  PostSortOptions,
  ReactionUpdateContext,
  ReactionMutationPayload,
  GetFeedResponse,
  CreatePostPayload,
  SinglePostResponse,
  FlowPostReactionMutationPayload,
  PostMainFeedPayload,
  PostProfileFeedPayload,
} from '../../interfaces/Feed';
import { FlowFeedResponse } from '../Flows/Feed/interfaces';

import { makeAPICall, makeAPICallWithDataReturn } from '../utils';
import {
  findInPaginatedFeedData,
  updateFlowPostReactionsInDraft,
  updatePostReactionsInDraft,
} from './utils';

export const useGetFeedQuery = (
  postsShown: PostFilterOptions,
  postsSort: PostSortOptions,
  makeSearchRequest: boolean,
) => {
  return useInfiniteQuery<GetFeedResponse>(
    [V3_GET_FEED, postsShown, postsSort],
    ({ pageParam = '' }) =>
      makeAPICallWithDataReturn(V3_GET_FEED, undefined, {
        cursor: pageParam,
        orderBy: postsShown,
        sortBy: postsSort,
      }),
    {
      staleTime: Infinity,
      refetchOnMount: 'always',
      enabled: makeSearchRequest,
      getNextPageParam: (lastPage) =>
        lastPage.metadata.pagination?.cursor?.next || undefined,
      getPreviousPageParam: (lastPage) => {
        return lastPage.metadata.pagination.cursor.previous;
      },
    },
  );
};

export const useGetSearchFeedQuery = (
  searchPayload: FeedSearchPayload,
  makeSearchRequest: boolean,
) => {
  return useInfiniteQuery<GetFeedResponse>(
    [V3_SEARCH_FEED, searchPayload],
    ({ pageParam = '' }) =>
      makeAPICallWithDataReturn(V3_SEARCH_FEED, {
        cursor: pageParam,
        search: searchPayload,
      }),
    {
      staleTime: Infinity,
      refetchOnMount: 'always',
      getNextPageParam: (lastPage) =>
        lastPage.metadata.pagination?.cursor?.next || undefined,
      enabled: makeSearchRequest,
    },
  );
};

export const useCreatePost = (
  onSuccess?: () => void,
  onError?: (error: AxiosError) => void,
) => {
  const queryClient = useQueryClient();
  const { userId } = useParams<{ userId: string }>();
  return useMutation(
    (payload: CreatePostPayload) => makeAPICall(CREATE_POST, payload),
    {
      onSuccess: (data, payload) => {
        queryClient.invalidateQueries(V3_GET_FEED);
        queryClient.invalidateQueries(GET_MAIN_FEED);
        if (payload.carrotsEach && payload.carrotsEach > 0) {
          queryClient.invalidateQueries(GET_PROFILE_INFO);
        }
        if (onSuccess) {
          onSuccess();
        }
        if (userId) {
          queryClient.invalidateQueries([GET_DATE_FILTER_OPTIONS]);
          queryClient.invalidateQueries([GET_FLOWS_FILTER_OPTIONS, userId]);
          queryClient.invalidateQueries([
            GET_FLOWS_MENTIONS_FILTER_OPTIONS,
            '',
            userId,
          ]);
        }
      },
      onError: (error: AxiosError) => {
        if (onError) {
          onError(error);
        }
      },
    },
  );
};

export const useUpdatePostReactionMutation = (
  feedOptions?: FeedOptionsInterface,
) => {
  const queryClient = useQueryClient();
  const feedQueryKey = useMemo(() => {
    if (feedOptions) {
      return feedOptions.showSearchResults
        ? [V3_SEARCH_FEED, feedOptions.searchPayload]
        : [V3_GET_FEED, feedOptions.postFilter, feedOptions.postsSort];
    }
    return undefined;
  }, [feedOptions]);
  return useMutation(
    ({ payload, contentID, action }: ReactionMutationPayload) =>
      makeAPICall(
        UPDATE_POST_REACTION,
        payload,
        {},
        { postId: contentID, action },
      ),
    {
      onMutate: ({ action, contentID, payload, userData }) => {
        const singlePostKey = [GET_SINGLE_POST, contentID];
        // feedQueryKey has value only when called by a feed and not by a single post.
        if (feedQueryKey) {
          const previousData: InfiniteData<GetFeedResponse> | undefined =
            queryClient.getQueryData(feedQueryKey);
          if (previousData) {
            const updatedData = produce(previousData, (draft) => {
              const updatedPost = findInPaginatedFeedData(
                draft.pages,
                contentID,
              );
              if (updatedPost) {
                updatePostReactionsInDraft(
                  updatedPost,
                  payload,
                  action,
                  userData,
                );
              }
            });
            queryClient.setQueryData(feedQueryKey, updatedData);
          }
          return { previousData };
        }
        const previousData: SinglePostResponse | undefined =
          queryClient.getQueryData(singlePostKey);
        if (previousData) {
          const updatedData = produce(previousData, ({ post }) => {
            updatePostReactionsInDraft(post, payload, action, userData);
          });
          queryClient.setQueryData(singlePostKey, updatedData);
        }
        return { previousData };
      },
      onSuccess: (data, { contentID }) => {
        queryClient.removeQueries({ queryKey: V3_GET_FEED, inactive: true });
        queryClient.removeQueries({ queryKey: V3_SEARCH_FEED, inactive: true });
        queryClient.removeQueries({
          queryKey: [GET_SINGLE_POST, contentID],
          inactive: true,
        });
      },
      onError: (err, { contentID }, context: ReactionUpdateContext) => {
        queryClient.setQueryData(
          feedQueryKey || [GET_SINGLE_POST, contentID],
          context?.previousData,
        );
      },
    },
  );
};

const useFeedUpdatePostReactionMutation = (queryKey: QueryKey) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ payload, contentID, action }: ReactionMutationPayload) =>
      makeAPICall(
        UPDATE_POST_REACTION,
        payload,
        {},
        { postId: contentID, action },
      ),
    {
      onMutate: ({ action, contentID, payload, userData }) => {
        const previousData:
          | InfiniteData<GetFeedResponse>
          | SinglePostResponse
          | undefined = queryClient.getQueryData(queryKey);
        if (previousData) {
          if (queryKey.length > 0 && queryKey[0] === GET_SINGLE_POST) {
            const previousSingleData = previousData as SinglePostResponse;
            const updatedData = produce(previousSingleData, ({ post }) => {
              updatePostReactionsInDraft(post, payload, action, userData);
            });
            queryClient.setQueryData(queryKey, updatedData);
          } else {
            const previousFeedData =
              previousData as InfiniteData<GetFeedResponse>;
            const updatedData = produce(previousFeedData, (draft) => {
              const updatedPost = findInPaginatedFeedData(
                draft.pages,
                contentID,
              );
              if (updatedPost) {
                updatePostReactionsInDraft(
                  updatedPost,
                  payload,
                  action,
                  userData,
                );
              }
            });
            queryClient.setQueryData(queryKey, updatedData);
          }
        }
        return { previousData };
      },
      onSuccess: () => {
        queryClient.removeQueries({
          queryKey,
          inactive: true,
        });
      },
      onError: (err, _, context: ReactionUpdateContext) => {
        queryClient.setQueryData(queryKey, context?.previousData);
      },
    },
  );
};

export const useProfileFeedUpdatePostReactionMutation = ({
  userId,
  filter,
}: PostProfileFeedPayload) => {
  return useFeedUpdatePostReactionMutation([GET_PROFILE_FEED, userId, filter]);
};

export const useMainFeedUpdatePostReactionMutation = ({
  feedsSort,
  filter,
}: PostMainFeedPayload) => {
  return useFeedUpdatePostReactionMutation([GET_MAIN_FEED, feedsSort, filter]);
};

export const useRecognitionFeedUpdatePostReactionMutation = (
  postsShown: PostFilterOptions,
  postsSort: PostSortOptions,
) => {
  return useFeedUpdatePostReactionMutation([
    V3_GET_FEED,
    postsShown,
    postsSort,
  ]);
};

export const useSearchRecognitionFeedUpdatePostReactionMutation = (
  searchPayload: FeedSearchPayload,
) => {
  return useFeedUpdatePostReactionMutation([V3_SEARCH_FEED, searchPayload]);
};

export const useSingleRecognitionUpdatePostReactionMutation = (
  postId: string,
) => {
  return useFeedUpdatePostReactionMutation([GET_SINGLE_POST, postId]);
};

const useFeedUpdateFlowPostReactionMutation = (queryKey: QueryKey) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({
      payload,
      flowId,
      responseId,
      action,
    }: FlowPostReactionMutationPayload) => {
      return makeAPICall(
        UPDATE_FLOW_POST_REACTION,
        payload,
        {},
        { flowId, responseId, action },
      );
    },
    {
      onMutate: ({ action, contentID, payload, userData }) => {
        const previousData: InfiniteData<GetFeedResponse> | undefined =
          queryClient.getQueryData(queryKey);

        if (previousData) {
          const updatedData = produce(previousData, (draft) => {
            const updatedPost = findInPaginatedFeedData(draft.pages, contentID);
            if (updatedPost) {
              updateFlowPostReactionsInDraft(
                updatedPost,
                payload,
                action,
                userData,
              );
            }
          });
          queryClient.setQueryData(queryKey, updatedData);
        }
        return { previousData };
      },
    },
  );
};

export const useProfileFeedUpdateFlowPostReactionMutation = ({
  userId,
  filter,
}: PostProfileFeedPayload) => {
  return useFeedUpdateFlowPostReactionMutation([
    GET_PROFILE_FEED,
    userId,
    filter,
  ]);
};

export const useMainFeedUpdateFlowPostReactionMutation = ({
  feedsSort,
  filter,
}: PostMainFeedPayload) => {
  return useFeedUpdateFlowPostReactionMutation([
    GET_MAIN_FEED,
    feedsSort,
    filter,
  ]);
};

const getFeedQueryKey = (feedOptions?: FeedOptionsInterface) => {
  let queryKey: string[] | (string | FeedSearchPayload)[] = [V3_GET_FEED];
  if (feedOptions) {
    const { showSearchResults, searchPayload, postsSort, postFilter } =
      feedOptions;
    queryKey = showSearchResults
      ? [V3_SEARCH_FEED, searchPayload]
      : [V3_GET_FEED, postFilter, postsSort];
  }
  return queryKey;
};

interface PostRemoveMediaResponse {
  data: {
    post: {
      _id: string;
      gifUrl: string;
    };
  };
}

const updateMultiPostFeed = (
  feedCache: InfiniteData<GetFeedResponse>,
  updatedPostData: AxiosResponse<PostRemoveMediaResponse>,
) => {
  const newPages = feedCache.pages.map((page): GetFeedResponse => {
    const newData: FeedItemFromAPI[] = page.data.map(
      (post): FeedItemFromAPI => {
        const { post: updatedPost } = updatedPostData.data.data;
        if (post.postID === updatedPost._id) {
          // Fix this after the api returns consistent values
          return {
            ...post,
            gifURL: updatedPost.gifUrl,
          };
        }
        return post;
      },
    );
    return { ...page, ...page.data, data: newData };
  });
  return { ...feedCache, pages: newPages };
};

const updateSinglePostFeed = (
  singlePostCache: SinglePostResponse,
  updatedPostData: AxiosResponse<PostRemoveMediaResponse>,
) => {
  const { post: updatedPost } = updatedPostData.data.data;
  const newSinglePostCache: SinglePostResponse = {
    ...singlePostCache,
    // Fix this after the api returns consistent values
    post: {
      ...singlePostCache.post,
      gifURL: updatedPost.gifUrl,
    },
  };
  return newSinglePostCache;
};

interface AdminDeleteMediaArgs {
  postId: string;
  feedOptions?: FeedOptionsInterface;
}

export const useAdminDeleteMedia = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ postId }: AdminDeleteMediaArgs) =>
      makeAPICall(ADMIN_DELETE_MEDIA, { feedID: postId }, undefined, {
        postId,
      }),
    {
      onSuccess: (updatedPostData, { postId, feedOptions }) => {
        const feedQueryKey = getFeedQueryKey(feedOptions);
        const feedCache =
          queryClient.getQueryData<InfiniteData<GetFeedResponse>>(feedQueryKey);
        const singlePostKey = [GET_SINGLE_POST, postId];
        const singlePostCache =
          queryClient.getQueryData<SinglePostResponse>(singlePostKey);
        if (feedCache) {
          const newFeed = updateMultiPostFeed(feedCache, updatedPostData);
          queryClient.setQueryData(feedQueryKey, newFeed);
          queryClient.removeQueries(V3_GET_FEED, { active: false });
        }
        if (singlePostCache) {
          const newSinglePostCache = updateSinglePostFeed(
            singlePostCache,
            updatedPostData,
          );
          queryClient.setQueryData(singlePostKey, newSinglePostCache);
          queryClient.removeQueries(V3_GET_FEED, { active: false });
        }
      },
    },
  );
};

const deletePostFromFeed = (
  feed: InfiniteData<GetFeedResponse>,
  postId: string,
): InfiniteData<GetFeedResponse> => {
  const newPages = feed.pages.map((page): GetFeedResponse => {
    const newData: FeedItemFromAPI[] = page.data.reduce(
      (result: FeedItemFromAPI[], post: FeedItemFromAPI) => {
        if (post.postID !== postId) {
          result.push(post);
        }
        return result;
      },
      [],
    );
    return { ...page, ...page.data, data: newData };
  });
  return { ...feed, pages: newPages };
};

interface AdminDeletePostArgs {
  postId: string;
  isWithCarrots?: boolean;
  feedOptions?: FeedOptionsInterface;
}

export const useAdminDeletePost = (
  onSuccessCallback?: () => void,
  onErrorCallback?: () => void,
) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ postId, isWithCarrots }: AdminDeletePostArgs) => {
      return makeAPICall(ADMIN_DELETE_POST, {
        postId,
        returnPoints: isWithCarrots,
      });
    },
    {
      onSuccess: (deletedPostData, { postId, feedOptions, isWithCarrots }) => {
        const feedQueryKey = getFeedQueryKey(feedOptions);
        const feed =
          queryClient.getQueryData<InfiniteData<GetFeedResponse>>(feedQueryKey);
        if (feed) {
          const newFeed = deletePostFromFeed(feed, postId);
          queryClient.setQueryData(feedQueryKey, newFeed);
          queryClient.removeQueries(V3_GET_FEED, { active: false });
        }
        if (isWithCarrots) {
          queryClient.refetchQueries(GET_PROFILE_INFO);
        }
        if (onSuccessCallback) {
          onSuccessCallback();
        }
      },
      onError: () => {
        if (onErrorCallback) {
          onErrorCallback();
        }
      },
    },
  );
};

export const useLegacyDeletePost = (
  onSuccessCallback?: () => void,
  onErrorCallback?: () => void,
) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ postId, isWithCarrots }: AdminDeletePostArgs) => {
      return makeAPICall(ADMIN_DELETE_POST, {
        postId,
        returnPoints: isWithCarrots,
      });
    },
    {
      onSuccess: (deletedPostData, { isWithCarrots }) => {
        queryClient.invalidateQueries(GET_MAIN_FEED);
        queryClient.invalidateQueries(V3_GET_FEED);
        queryClient.invalidateQueries(GET_PROFILE_FEED);
        queryClient.invalidateQueries([V3_SEARCH_FEED]);
        if (isWithCarrots) {
          queryClient.refetchQueries(GET_PROFILE_INFO);
        }
        if (onSuccessCallback) {
          onSuccessCallback();
        }
      },
      onError: () => {
        if (onErrorCallback) {
          onErrorCallback();
        }
      },
    },
  );
};

export const useGetSinglePostQuery = (postID: string, enabled = true) => {
  return useQuery(
    [GET_SINGLE_POST, postID],
    () =>
      makeAPICallWithDataReturn(GET_SINGLE_POST, undefined, undefined, {
        postID,
      }),
    {
      enabled,
      staleTime: Infinity,
      retry: 0,
      select: (res: SinglePostResponse) => res.post,
    },
  );
};

export const useGetFlowResponseQuery = (
  flowId: string,
  responseId: string,
  enabled = true,
) => {
  return useQuery(
    [GET_FLOW_RESPONSE, flowId, responseId],
    () =>
      makeAPICallWithDataReturn(GET_FLOW_RESPONSE, undefined, undefined, {
        flowId,
        responseId,
      }),
    {
      enabled,
      staleTime: Infinity,
      retry: 0,
      select: (res: FlowFeedResponse) => res,
    },
  );
};
