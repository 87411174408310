import { MemberRole, MemberState } from '../../../../interfaces/user';

export type DropdownItemOption = {
  id: string;
  value: string;
  deleteItem: boolean;
  isSelected: boolean;
  prefixIcon: string;
};

export type DropdownOptions = {
  id: string;
  items: DropdownItemOption[];
};

type NotebookResponse = {
  noteId: string;
};

export type NotebookAPIResponse = NotebookResponse[];

export enum NotebookTabs {
  myWork = 'myWork',
  ato = 'ato',
}

export type AssignedToAPIResponse = {
  name: string;
  firstName: string;
  lastName: string;
  username: string;
  memberID: string;
  image: string;
  pointsGiven: string;
  totalPointsGiven: string;
  memberState: MemberState;
  role: MemberRole[];
};
