import React, { useState, useCallback, useMemo } from 'react';

import { SelectedItemProps } from '../TextField_V2/interface';
import { SettingsDropdownProps } from './interface';

const useSettingsManagerLogic = (props: SettingsDropdownProps) => {
  const { dropdownType, initialValue, initialSelectedItems, onSaveChanges } =
    props;

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [value, setValue] = useState<string>(initialValue || '');
  const [selectedItems] = useState<SelectedItemProps[]>(
    initialSelectedItems || [],
  );
  const [trackSelectedItems, updateTrackSelectedItems] =
    useState<SelectedItemProps[]>();

  const handleSelectItemChange = React.useCallback(
    (values: SelectedItemProps[]) => {
      setIsButtonDisabled(false);
      updateTrackSelectedItems(values);
    },
    [],
  );

  const handleOnChange = useCallback((inputValue: string) => {
    setIsButtonDisabled(false);
    setValue(inputValue);
  }, []);

  const handleSaveClick = useCallback(() => {
    if (dropdownType === 'SINGLE_SELECT') {
      onSaveChanges(value);
    } else {
      onSaveChanges(trackSelectedItems);
    }
  }, [dropdownType, onSaveChanges, trackSelectedItems, value]);

  const enableChip = useMemo(() => {
    return dropdownType !== 'SINGLE_SELECT';
  }, [dropdownType]);

  return {
    models: {
      value,
      selectedItems,
      isButtonDisabled,
      enableChip,
    },
    operations: {
      handleSelectItemChange,
      handleSaveClick,
      handleOnChange,
    },
  };
};

export default useSettingsManagerLogic;
