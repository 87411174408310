import React, { useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';

import ThemeV2 from '../../../componentsV2/theme';

import { CONFIGURE_SETTINGS } from '../../../languages/en/flows/builder';
import { singularizeWord } from '../../../Utils/text';
import Body from '../../atoms/Body';
import {
  DropdownColor,
  DropdownVariants,
} from '../../molecules/Dropdown_V2/interfaces';
import {
  StyledDeadlineConfigurationFlex,
  StyledInput,
  StyledDropdown,
} from './styles';
import { FlowDefaultDeadlineProps } from './types';
import { supportedWholeNumberKeyCodes } from '../../../constants/flowsBuilder';

const FlowDefaultDueDate = (props: FlowDefaultDeadlineProps) => {
  const {
    defaultTimeValue,
    onDefaultTimeValueChange,
    defaultDueDateTypeOptions,
    defaultDueDateType,
    onDefaultDueDateTypeChange,
    recurrenceErrorMessage,
  } = props;

  const handleOnChange = (e: { target: { value: number } }) => {
    onDefaultTimeValueChange(e.target.value);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (!supportedWholeNumberKeyCodes.includes(event.key)) {
      event.preventDefault();
    }
  };
  const getItemLabel = useCallback(
    (label: string) =>
      Number(defaultTimeValue || 0) > 1 ? label : singularizeWord(label),
    [defaultTimeValue],
  );

  return (
    <div>
      <Body variant="body1Medium" color={ThemeV2.palette.gray9}>
        {CONFIGURE_SETTINGS.ONDEMAND_SET_DUE_DATE_HEADING_TEXT}
      </Body>
      <Body variant="body3">
        {CONFIGURE_SETTINGS.ONDEMAND_SET_DUE_DATE_BODY_TEXT}
      </Body>
      <StyledDeadlineConfigurationFlex>
        <StyledInput
          type="textV3_number"
          value={defaultTimeValue}
          onChange={handleOnChange}
          onKeyPress={handleKeyPress}
          name="flowDefaultDueDateInput"
        />
        <StyledDropdown
          color={'secondary' as DropdownColor}
          menuItems={defaultDueDateTypeOptions}
          onItemClick={onDefaultDueDateTypeChange}
          value={defaultDueDateType}
          variant={'text' as DropdownVariants}
          getItemLabel={getItemLabel}
        />
      </StyledDeadlineConfigurationFlex>
      {!isEmpty(recurrenceErrorMessage) && (
        <Body
          variant="body4"
          color="red6"
          data-testid="flowDefaultDueDateError"
        >
          {recurrenceErrorMessage}
        </Body>
      )}
    </div>
  );
};

export default FlowDefaultDueDate;
