import styled from 'styled-components';
import Body from '../../../atomic/atoms/Body';
import { FlowAccordionItemContainer } from '../FlowsBuilderController/styles';
import RadioGroup from '../../../atomic/molecules/RadioGroup';
import { Flex } from '../../../Utils/styles/display';

export const StyledFlowAccordianItemContainer = styled(
  FlowAccordionItemContainer,
)`
  margin: 0;
`;

export const StyledContent = styled(Flex)`
  padding: 8px 48px;
  width: 60%;
  min-height: 330px;
`;

export const StyledTitle = styled(Body)`
  margin-top: 8px;
  margin-bottom: 8px;
`;

export const StyledSubTitle = styled(Body)`
  margin-bottom: 8px;
`;

export const StyledRadioOptions = styled(RadioGroup)`
  margin-top: 8px;
  label {
    padding-bottom: 8px;
  }
  .extra-content {
    margin-bottom: 8px;
  }
`;

export const StyledParticipantsListWrapper = styled(Flex)`
  width: 40%;
  & > div {
    flex-basis: 0px;
    flex-grow: 1;
    overflow-y: auto;
  }
`;
