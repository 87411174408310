import styled, { css } from 'styled-components';
import { StyledIconContainerProps } from './types';

export const StyledIconContainer = styled.div<StyledIconContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ size }) => size};
  width: ${({ size }) => size};
  min-height: ${({ size }) => size};
  min-width: ${({ size }) => size};

  ${({ bgColor }) =>
    bgColor &&
    css`
      background-color: ${bgColor};
    `}

  ${({ borderRadius }) =>
    borderRadius &&
    css`
      border-radius: ${borderRadius};
    `}
`;
