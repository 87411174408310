import styled from 'styled-components';
import ThemeV2 from '../../../componentsV2/theme';
import Button from '../../atoms/Button';
import IconButton from '../../atoms/IconButton_V2';
import SVGIcon from '../../atoms/SVGIcon';

interface IconButtonProps {
  isActiveView: boolean;
}

export const KnowledgeCenterSearchWrapper = styled.div`
  display: flex;
  height: 40px;
  width: 100%;
  border: 1px solid ${ThemeV2.palette.gray7};
  border-radius: 4px;
`;

export const KnowledgeCenterViewWrapper = styled.div`
  display: flex;
  width: 80px;
  height: 40px;
  border: 0.5px solid ${ThemeV2.palette.gray5};
  border-radius: 4px;
  margin-left: 8px;
`;

export const KnowledgeCenterStyledSVGIcon = styled(SVGIcon)`
  margin: 12px;
`;

export const KnowledgeCenterSearchButton = styled(Button)`
  height: 32px;
  margin: 4px;
`;

export const KnowledgeCenterStyledIconButton = styled(
  IconButton,
)<IconButtonProps>`
  margin: 4px;
  box-shadow: none;
  &:not(:disabled):hover {
    box-shadow: none;
  }
`;
