import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import {
  FLOWS,
  FLOWS_BUILDER,
  FLOWS_FEEDS,
  NEW_FLOWS_BUILDER,
  RECOGNITION_FEEDS,
  V2_GENERAL_SETTINGS,
  V2_HOME,
  NOTEBOOK,
  V2_NOTIFICATION_SETTINGS,
  V2_POST_PAGE,
  V2_REWARDS,
  V2_USER_PROFILE,
  V3_POST_PAGE,
  QUICK_SETUP,
  V2_SETTINGS,
  KNOWLEDGE_CENTER,
} from '../../../constants/routes';
import {
  GENERAL,
  MY_SETTINGS,
  NOTIFICATIONS,
} from '../../../languages/en/settings';
import { HeaderType } from '../../../atomic/molecules/PageHeader/interfaces';
import MainFeedHeaderController from '../MainFeed';
import ProfileHeaderController from '../Profile';
import {
  KNOWLEDGE_CENTER_SUBTITLE,
  KNOWLEDGE_CENTER_TITLE,
  NOTEBOOK_HEADER_SUBTITLE,
  NOTEBOOK_HEADER_TITLE,
} from '../../../languages/en/header';
import PageHeader from '../../../atomic/molecules/PageHeader';
import { settingsTabButtons } from '../../../atomic/organism/RouteTabs/data';
import SinglePostHeaderController from '../SinglePost';
import FlowsBuilderHeaderController from '../../flowsBuilder/FlowsBuilderHeaderController';
import FlowFeedHeaderController from '../../flows/FlowFeedHeaderController';
import RecognitionFeedHeaderController from '../../flows/RecognitionFeedHeaderController';
import SingleFlowFeedPostHeaderController from '../SingleFlowFeedPost';
import {
  quickSetupHeader,
  quickSetupSubHeader,
} from '../../../languages/en/quickSetup';
import RewardsHeaderController from '../../rewards/RewardsHeaderController';

const GeneralSettingsHeader = () => (
  <PageHeader
    headerType={HeaderType.OTHER}
    icon="general-settings"
    title={GENERAL}
    subTitle={MY_SETTINGS}
    hasBottomBorder={false}
    pageTabs={settingsTabButtons}
    tabRouteBase={V2_SETTINGS}
  />
);

const NotificationSettingsHeader = () => (
  <PageHeader
    headerType={HeaderType.OTHER}
    icon="notification-bubble"
    title={NOTIFICATIONS}
    subTitle={MY_SETTINGS}
    hasBottomBorder={false}
    pageTabs={settingsTabButtons}
    tabRouteBase={V2_SETTINGS}
  />
);

const NotebookHeader = () => {
  return (
    <PageHeader
      headerType={HeaderType.OTHER}
      icon="notebook-checklist"
      title={NOTEBOOK_HEADER_TITLE}
      subTitle={NOTEBOOK_HEADER_SUBTITLE}
      hasBottomBorder={false}
    />
  );
};

const KnowledgeCenterHeader = () => {
  return (
    <PageHeader
      headerType={HeaderType.OTHER}
      icon="file-browsing"
      title={KNOWLEDGE_CENTER_TITLE}
      subTitle={KNOWLEDGE_CENTER_SUBTITLE}
      hasBottomBorder={true}
    />
  );
};

const QuickSetupHeader = () => (
  <PageHeader
    headerType={HeaderType.OTHER}
    title={quickSetupHeader}
    icon="notebook-checklist"
    subTitle={quickSetupSubHeader}
    hasBottomBorder={true}
  />
);

const PageHeaderRouter = () => {
  return (
    <Switch>
      <Route path={V2_HOME} component={MainFeedHeaderController} />
      <Route path={V2_REWARDS} component={RewardsHeaderController} />
      <Route path={V2_USER_PROFILE} component={ProfileHeaderController} />
      <Route path={V2_GENERAL_SETTINGS} component={GeneralSettingsHeader} />
      <Route
        path={V3_POST_PAGE}
        component={SingleFlowFeedPostHeaderController}
      />
      <Route path={V2_POST_PAGE} component={SinglePostHeaderController} />
      <Route
        path={NEW_FLOWS_BUILDER}
        component={FlowsBuilderHeaderController}
      />
      <Route path={FLOWS_BUILDER} component={FlowsBuilderHeaderController} />
      <Route
        path={RECOGNITION_FEEDS}
        component={RecognitionFeedHeaderController}
      />
      <Route path={NEW_FLOWS_BUILDER} />
      <Route path={NOTEBOOK} component={NotebookHeader} />
      <Route path={QUICK_SETUP} component={QuickSetupHeader} />
      <Route path={KNOWLEDGE_CENTER} component={KnowledgeCenterHeader} />
      <Route path={FLOWS_FEEDS} component={FlowFeedHeaderController} />
      <Route
        path={V2_NOTIFICATION_SETTINGS}
        component={NotificationSettingsHeader}
      />
      <Route path={FLOWS}>
        <Redirect from="/" to="/home" />
      </Route>
    </Switch>
  );
};

export default PageHeaderRouter;
