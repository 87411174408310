import React from 'react';
import styled from 'styled-components';

import ThemeV2 from '../../../componentsV2/theme';
import { FlexCSS } from '../../../Utils/styles/display';

const CountIndicatorWrapper = styled.div`
  ${FlexCSS};
  justify-content: center;
  background: ${ThemeV2.palette.geekBlue6};
  padding: 0px 8px;
  border-radius: 25px;
  width: fit-content;
  max-width: 44px;
`;

export interface CountIndicatorProps {
  children: React.ReactChild;
  className?: string;
}

const CountIndicator = (props: CountIndicatorProps) => {
  const { children, className } = props;
  return (
    <CountIndicatorWrapper className={className}>
      {children}
    </CountIndicatorWrapper>
  );
};

export default CountIndicator;
