import { rrulestr } from 'rrule';

import { capitalizeFirstLetterInText } from '../../../../Utils/text';
import { Event } from '../../../../queries/QuickSetup/interfaces';

export const getRRuleStringFromEvent = (event: Event) => {
  if (event && event.recurrence) {
    const rrule = event.recurrence[event.recurrence.length - 1];
    if (rrule) {
      const rruleObj = rrulestr(rrule.toString());
      return capitalizeFirstLetterInText(rruleObj.toText());
    }
  }

  return '';
};

export const getFormattedTimeFromDate = (date: Date) => {
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12;
  const modifiedMinutes = minutes < 10 ? '0' + minutes : minutes;
  return hours + ':' + modifiedMinutes + ' ' + ampm;
};

export const getDayFromDate = (date: Date) => {
  const weekday = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  return weekday[date.getDay()];
};
