import React, { useCallback, useMemo } from 'react';
import { NewFeedPostReactionsProps } from './interface';
import { FeedPostReactionsRoot } from './styles';
import { StyledToggleButton } from '../ReactionsBar/styles';
import { FEED_POST_REACTIONS_COMMENT_TEXT } from '../../../languages/en/home';
import ReactionBar from '../ReactionsBar/newIndex';
import Divider from '../../atoms/Divider';
import ThemeV2 from '../../../componentsV2/theme';
import { PostReaction, ReactionType } from '../../../interfaces/Feed/Reaction';
import { PostTypes } from '../../../interfaces/Home';
import { Reaction } from '../../../interfaces/Feed';
import { EmojiData } from 'emoji-mart';

const reactionSerializer = (reactions: PostReaction[], userId: string) =>
  reactions.map((reaction) => ({
    members: reaction.members.map((member) => ({
      name: member.name,
      memberID: member.memberID,
    })),
    reaction: {
      name: reaction.name,
      type:
        reaction.type === 'REGULAR'
          ? ReactionType.REGULAR
          : ReactionType.CUSTOM,
      displayName: reaction?.displayName,
      value: reaction.value,
    },
    timesReacted: reaction.members.length,
    active: reaction.members.some((member) => member.memberID === userId),
  }));

const FeedPostReactions = (props: NewFeedPostReactionsProps) => {
  const {
    isPickerDisabled,
    onCommentButtonClick,
    onReactButtonClick,
    onReactionToggleClick,
    onEmoticonSelect,
    post,
    profileData,
  } = props;

  const { currentUserId, contentId, reactions } = useMemo(() => {
    const isFlowPost = post.type === PostTypes.FLOW && post.flowResponse;
    const postID = (
      isFlowPost ? post.flowResponse?.responseId : post.postID
    ) as string;
    const userId = profileData.member.memberId;
    const reactionsFromPost = (
      isFlowPost ? post.flowResponse?.reactions : post.reactions
    ) as PostReaction[];
    const serializedReactions = reactionSerializer(reactionsFromPost, userId);
    return {
      reactions: serializedReactions,
      contentId: postID,
      currentUserId: userId,
    };
  }, [post, profileData]);

  const handleReactionToggleClick = useCallback(
    (reaction: Reaction, currentContentId: string) =>
      onReactionToggleClick(reaction, currentContentId, post),
    [onReactionToggleClick, post],
  );

  const handleEmoticonSelect = useCallback(
    (emoji: EmojiData, currentContentId: string) =>
      onEmoticonSelect(emoji, currentContentId, post),
    [onEmoticonSelect, post],
  );

  return (
    <FeedPostReactionsRoot>
      <Divider isFullWidth color={ThemeV2.palette.gray4} marginBottom="8px" />
      <StyledToggleButton onClick={onCommentButtonClick} icon="add-comment">
        {FEED_POST_REACTIONS_COMMENT_TEXT}
      </StyledToggleButton>
      <ReactionBar
        isPickerDisabled={isPickerDisabled}
        onReactButtonClick={onReactButtonClick}
        contentId={contentId}
        currentUserId={currentUserId}
        reactions={reactions}
        onReactionToggleClick={handleReactionToggleClick}
        onEmoticonSelect={handleEmoticonSelect}
      />
    </FeedPostReactionsRoot>
  );
};

const MemoizedFeedPostReactions = React.memo(FeedPostReactions);
MemoizedFeedPostReactions.displayName = 'FeedPostReactions';
export default MemoizedFeedPostReactions;
