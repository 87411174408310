import React from 'react';
import styled from 'styled-components';

import ThemeV2 from '../../../componentsV2/theme';
import { FlexCSS } from '../../../Utils/styles/display';

const IconBadgeWrapper = styled.div<{
  size: string;
  iconColor: string;
  color: string;
  radius: string;
}>`
  ${FlexCSS};
  justify-content: center;
  height: ${({ size }) => size};
  width: ${({ size }) => size};
  color: ${({ iconColor }) => iconColor};
  background-color: ${({ color }) => color};
  border-radius: ${({ radius }) => radius};
`;

interface IconBadgeProps {
  size?: string;
  color?: string;
  iconColor?: string;
  radius?: string;
  children: React.ReactChild;
}

const IconBadge = (props: IconBadgeProps) => {
  const {
    size = '48px',
    color = ThemeV2.palette.aqua,
    iconColor = ThemeV2.palette.white,
    radius = '50%',
    children,
  } = props;
  return (
    <IconBadgeWrapper
      size={size}
      color={color}
      iconColor={iconColor}
      radius={radius}
    >
      {children}
    </IconBadgeWrapper>
  );
};

export default IconBadge;
