import React, { ReactNode, SyntheticEvent } from 'react';
import styled from 'styled-components';
import { IGif } from '@giphy/js-types';

import NavigationInstructions from '../../FlowsInputBlockNavigationInstructions';
import FlowsBaseInputBlock from '../FlowsBaseInputBlock';
import GifContainer from '../../GifContainer';
import { device } from '../../../../constants/layout';
import GifCarousel from '../../GifCarousel';

const SelectedGifContainer = styled(GifContainer)`
  @media ${device.tabletV2} {
    max-width: 476px;
  }
`;

export interface FlowGIFInputBlockProps {
  fieldError?: string;
  blockError?: string;
  isRequired?: boolean;
  description?: string;
  title: string;
  info?: ReactNode;
  handleGifClick: (gifUrl: string) => void;
  selectedGif: string | null;
  goToNextStep: () => void;
  isPreviewFlow?: boolean;
  isLastBlock?: boolean;
}

const FlowGIFInputBlock = ({
  title,
  selectedGif,
  fieldError,
  description,
  info,
  isRequired,
  handleGifClick,
  blockError,
  goToNextStep,
  isLastBlock = false,
  isPreviewFlow,
}: FlowGIFInputBlockProps) => {
  const onGifClick = (gif: IGif, event: SyntheticEvent<HTMLElement, Event>) => {
    event.preventDefault();
    let selectedGifUrl = gif.images.downsized_medium.url;
    if (selectedGifUrl.length === 0) {
      selectedGifUrl = gif.images.original.url;
    }
    handleGifClick(selectedGifUrl);
  };

  return (
    <FlowsBaseInputBlock
      fieldError={fieldError}
      hasError={!!fieldError}
      isRequired={isRequired}
      title={title}
      info={info}
      description={description}
      blockError={blockError}
      navigationInstructions={
        <NavigationInstructions
          type={isLastBlock ? 'last+enter' : 'enter'}
          goToNextStep={goToNextStep}
          isPreviewFlow={isPreviewFlow}
        />
      }
    >
      {selectedGif ? (
        <SelectedGifContainer
          src={selectedGif}
          onGifRemoveClick={() => handleGifClick('')}
        />
      ) : (
        <GifCarousel onGifClick={onGifClick} />
      )}
    </FlowsBaseInputBlock>
  );
};

export default FlowGIFInputBlock;
