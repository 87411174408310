import styled from 'styled-components';
import ThemeV2 from '../../../componentsV2/theme';
import InfoAlert from '../../../atomic/molecules/InfoAlert';
import FeedEmptyState from '../../../atomic/molecules/FeedEmptyState';

export const FlowsInsightsContainer = styled.section`
  max-width: 760px;
  margin: 0 auto;
  border-radius: 4px;
  background: ${ThemeV2.palette.white};
  border: 1px solid ${ThemeV2.palette.gray5};

  .highcharts-navigator {
    pointer-events: all !important;
  }
`;

export const SisenseFrameWrapper = styled.section`
  visibility: hidden;
  iframe {
    width: 0;
    height: 0;
  }
`;

export const EmptyBlock = styled.section`
  height: 24px;
`;

export const StyledFeedEmptyState = styled(FeedEmptyState)`
  margin: 0 auto;
`;

export const StyledInfoAlert = styled(InfoAlert)`
  max-width: 760px;
  margin: 0 auto 24px;

  svg {
    margin-bottom: 4px;
  }
`;
