import React, { useState, useEffect } from 'react';
import SVGIcon from '../../atoms/SVGIcon';
import { OptionItemEditorProps, OptionItemsProps } from './interface';
import {
  LabelHelperText,
  OtherLabelText,
  LabelWrapper,
  OptionLabel,
  OptionsWrapper,
  OrTextSeparator,
  StyledAddOptionButton,
  StyledCheckbox,
  StyledClickableClose,
  StyledClickableOption,
  StyledRadio,
  StyledTextField,
  StyledNumberedOptionItem,
} from './styles';
import ThemeV2 from '../../../componentsV2/theme';
import Button from '../../atoms/Button';
import {
  ADD,
  AN,
  ANOTHER,
  OPTION,
  OTHER,
} from '../../../languages/en/singleWords';
import { ADD_YOUR_ANSWER } from '../../../languages/en/flows/builder';

const OptionItem = (props: OptionItemsProps) => {
  const {
    option,
    index = null,
    onDismissClick,
    isDismissEnabled = Boolean(option?.helperText),
    onOptionEditChange,
    type,
    readOnly = Boolean(option?.helperText) || false,
    addOptionClickProps,
    addOtherClickProps,
    isAddOtherOptionPresent = false,
  } = props;
  const [isInEditingMode, setIsInEditingMode] = useState(
    Boolean(option?.autoFocus),
  );
  const toggleSetEditingMode = () => {
    setIsInEditingMode(!isInEditingMode);
  };

  const renderChoiceIndicator = (indicatorType: string) => {
    switch (indicatorType.toLocaleLowerCase()) {
      case 'radio':
        return <StyledRadio disabled />;

      case 'numbered':
        return index ? (
          <StyledNumberedOptionItem>{index}.</StyledNumberedOptionItem>
        ) : null;

      default:
        return <StyledCheckbox checked={false} disabled />;
    }
  };

  return (
    <OptionsWrapper key={option?.value} data-testid="option-item">
      {renderChoiceIndicator(type || '')}
      {option?.value === 'other' && (
        <OtherLabelText
          color="gray9"
          variant="body2"
          inline
        >{`${OTHER}:`}</OtherLabelText>
      )}
      <LabelWrapper
        $isInEditingMode={
          isInEditingMode || readOnly || Boolean(option?.autoFocus)
        }
      >
        {!readOnly && onOptionEditChange && isInEditingMode ? (
          <StyledTextField
            background={option?.value === 'other' ? 'white' : ''}
            disabled={option?.value === 'other'}
            name={option?.value}
            onChange={(e) => onOptionEditChange(e, option)}
            value={option?.label || ''}
            removeElevation
            multiline
            onBlur={toggleSetEditingMode}
            autoFocus={Boolean(option?.autoFocus) || true}
            placeholder={option?.value === 'other' ? ADD_YOUR_ANSWER : ''}
          />
        ) : (
          <>
            {addOptionClickProps?.enabled ? (
              <>
                <StyledAddOptionButton
                  variant="text"
                  color="secondary"
                  size="small"
                  onClick={addOptionClickProps.onClick}
                >
                  {addOtherClickProps?.enabled
                    ? `${ADD} ${AN} ${OPTION}`
                    : `${ADD} ${ANOTHER} ${OPTION}`}
                </StyledAddOptionButton>
                {addOtherClickProps?.enabled && !isAddOtherOptionPresent && (
                  <>
                    <OrTextSeparator>or</OrTextSeparator>
                    <Button
                      variant="text"
                      size="small"
                      onClick={addOtherClickProps?.onClick}
                    >
                      {`${ADD} ‘${OTHER}’`}
                    </Button>
                  </>
                )}
              </>
            ) : (
              <StyledClickableOption
                $isInEditingMode={isInEditingMode || readOnly}
                onClick={toggleSetEditingMode}
                data-testid="option-label"
              >
                <OptionLabel variant="body2">
                  {option?.defaultLabel && option?.label === ''
                    ? option?.defaultLabel
                    : option?.label}
                  <LabelHelperText>{option?.helperText}</LabelHelperText>
                </OptionLabel>
              </StyledClickableOption>
            )}
          </>
        )}
      </LabelWrapper>

      {onDismissClick && isDismissEnabled && (
        <StyledClickableClose onClick={() => onDismissClick(option)}>
          <SVGIcon
            size="16px"
            icon="round-close"
            color={ThemeV2.palette.gray8}
          />
        </StyledClickableClose>
      )}
    </OptionsWrapper>
  );
};

const OptionItemsEditor = (props: OptionItemEditorProps) => {
  const {
    className,
    type,
    optionItems,
    onDismissClick,
    onOptionEditChange,
    addOptionClickProps,
    addOtherClickProps,
  } = props;

  const [isAddOtherOptionPresent, checkIfAddOtherOptionIsPresent] =
    useState<boolean>(false);

  useEffect(() => {
    const addOtherOptionPresentFlag =
      optionItems.filter((option) => option?.value === 'other').length > 0;
    checkIfAddOtherOptionIsPresent(addOtherOptionPresentFlag);
  }, [optionItems]);

  return (
    <div className={className}>
      {optionItems.map((option, index) => (
        <OptionItem
          type={type}
          index={index + 1}
          key={option.value}
          option={option}
          onDismissClick={onDismissClick}
          isDismissEnabled={optionItems.length > 0}
          onOptionEditChange={(e) => onOptionEditChange(e, option)}
        />
      ))}
      {addOptionClickProps.enabled && (
        <OptionItem
          type={type}
          addOptionClickProps={addOptionClickProps}
          addOtherClickProps={addOtherClickProps}
          readOnly
          isDismissEnabled={false}
          index={type === 'numbered' ? optionItems.length + 1 : null}
          isAddOtherOptionPresent={isAddOtherOptionPresent}
        />
      )}
    </div>
  );
};

export default OptionItemsEditor;
