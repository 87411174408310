import { useEffect, useMemo, useState, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  CheckboxFilterLoadedProps,
  CheckboxFilterValues,
} from '../../../../atomic/organism/CheckboxFilter/types';
import { DateFilterProps } from '../../../../atomic/organism/DateFilter/types';
// eslint-disable-next-line max-len
import { SelectedProfileFeedFilterValueProps } from '../../../../atomic/organism/RightDrawerFilter/ProfileFeedFilter/types';
import { ComponentStatus } from '../../../../interfaces/component';
import { FeedItemFromAPI } from '../../../../interfaces/Feed';
import {
  FILTER_BY_ANOTHER_FLOW,
  FILTER_BY_ANOTHER_MEMBER,
  POSTED_BY,
  SELECT_FLOWS,
  SELECT_PERSON,
} from '../../../../languages/en/flows';
import { DATES, FLOW, MENTIONED } from '../../../../languages/en/singleWords';
import { useGetProfileFeedQuery } from '../../../../queries/ProfileFeed';

import {
  useGetDateFilterOptionsQuery,
  useGetFlowsFilterOptionsQuery,
  useGetMentionsFilterOptionsQuery,
  useGetPostedByFilterOptionsQuery,
} from '../../../../queries/RightDrawer/Filter';
import { getFeedArrayFromQueryData } from '../../../../Utils/home/feeds/reactions';
import { capitalizeFirstLetter } from '../../../../Utils/text';
import {
  mapDataToFilterOptions,
  mapPersonDataToFilterOptions,
  serializeFilterValues,
  isDateEqual,
  isArrayEqual,
  createQueryParam,
} from '../../utils';
import { FilterControllerProps, QueryStringTypes } from '../../types';
import useGetParsedFilterOptions from '../../../../hooks/useGetParsedFilterOptions';

import useGetPageName from '../../../../hooks/useGetPageName';
import { RIGHT_DRAWER_EVENTS } from '../../../../Utils/analytics/constants';
import { DrawerTabType } from '../../../../Utils/analytics/interfaces';
import {
  getAppliedFilter,
  GetAppliedFilterProps,
} from '../../../../Utils/rightDrawer';
import { trackRightDrawerEvent } from '../../../../Utils/analytics';

const useProfileFeedRightDrawerControllerLogic = ({
  setIsFiltered,
}: FilterControllerProps) => {
  const history = useHistory();
  const {
    receivedStartDate,
    receivedEndDate,
    receivedFlowsID,
    receivedPostedById,
    receivedMentionedById,
    filter,
    parsedParams,
  } = useGetParsedFilterOptions();

  const { page } = useGetPageName();

  const drawerTabViewed: DrawerTabType = 'filter';

  const { userId } = useParams<{ userId: string }>();

  const {
    data: profileData,
    isLoading: isProfileDataLoading,
    isError: isProfileDataError,
  } = useGetProfileFeedQuery({ userId, filter });

  const feedData: FeedItemFromAPI[] = useMemo(() => {
    return getFeedArrayFromQueryData(profileData);
  }, [profileData]);

  const {
    data: dateFilterData,
    isLoading: isDateFilterLoading,
    isError: isDateFilterError,
  } = useGetDateFilterOptionsQuery();
  const {
    data: flowsData,
    isLoading: isFlowsDataLoading,
    isError: isFlowsDataError,
  } = useGetFlowsFilterOptionsQuery(userId);
  const {
    data: mentionsData,
    isLoading: isMentionDataLoading,
    isError: isMentionsDataError,
  } = useGetMentionsFilterOptionsQuery('', userId);
  const {
    data: postedByData,
    isLoading: isPostedByLoading,
    isError: isPostedByError,
  } = useGetPostedByFilterOptionsQuery('', userId);

  const [startDateValue, setStartDate] = useState<Date | undefined>(() =>
    receivedStartDate !== undefined ? new Date(receivedStartDate) : undefined,
  );
  const [endDateValue, setEndDate] = useState<Date | undefined>(() =>
    receivedEndDate !== undefined ? new Date(receivedEndDate) : undefined,
  );
  const [flowSelectedOptions, setFlowSelectedOptions] =
    useState<string[]>(receivedFlowsID);
  const [postedBySelectedOptions, setPostedSelectedByOptions] =
    useState<string[]>(receivedPostedById);
  const [mentionedSelectedOptions, setMentionedSelectedOptions] = useState<
    string[]
  >(receivedMentionedById);
  const [isEmpty, setIsEmpty] = useState<boolean>(false);

  const initialStartDate = useMemo(() => {
    if (dateFilterData?.startDate) {
      return new Date(dateFilterData?.startDate);
    }
    return undefined;
  }, [dateFilterData]);

  const flowsDataOptions: CheckboxFilterValues = useMemo(() => {
    if (flowsData?.data) {
      return mapDataToFilterOptions(flowsData, 'flows');
    }
    return [];
  }, [flowsData]);

  const mentionedOptions: CheckboxFilterValues = useMemo(() => {
    if (mentionsData?.data) {
      return mapPersonDataToFilterOptions(mentionsData, 'mentionedBy');
    }
    return [];
  }, [mentionsData]);

  const postedByOptions: CheckboxFilterValues = useMemo(() => {
    if (postedByData?.data) {
      return mapPersonDataToFilterOptions(postedByData, 'postedBy');
    }
    return [];
  }, [postedByData]);

  const collapseClickHandler = useCallback(
    (isCollapseOpen: boolean) => {
      if (isCollapseOpen) {
        trackRightDrawerEvent(RIGHT_DRAWER_EVENTS.FILTER_SECTION_COLLAPSED, {
          feedViewed: page,
          drawerTabViewed,
        });
      } else {
        trackRightDrawerEvent(RIGHT_DRAWER_EVENTS.FILTER_SECTION_EXPANDED, {
          feedViewed: page,
          drawerTabViewed,
        });
      }
    },
    [page],
  );

  const dateFilterProps: DateFilterProps = {
    status: ComponentStatus.LOADED,
    startDate: startDateValue,
    endDate: endDateValue,
    onStartDateChange: setStartDate,
    onEndDateChange: setEndDate,
    initialStartDate,
    headingText: DATES,
    onCollapseClick: collapseClickHandler,
  };

  const flowsFilterProps: CheckboxFilterLoadedProps = {
    status: ComponentStatus.LOADED,
    headingText: capitalizeFirstLetter(FLOW),
    filterByText: FILTER_BY_ANOTHER_FLOW,
    autoCompleteText: SELECT_FLOWS,
    options: flowsDataOptions,
    onSelectedOptionChange: setFlowSelectedOptions,
    selectedOptions: flowSelectedOptions,
    onCollapseClick: collapseClickHandler,
  };

  const postedByFilterProps: CheckboxFilterLoadedProps = {
    status: ComponentStatus.LOADED,
    headingText: POSTED_BY,
    filterByText: FILTER_BY_ANOTHER_MEMBER,
    autoCompleteText: SELECT_PERSON,
    options: postedByOptions,
    onSelectedOptionChange: setPostedSelectedByOptions,
    selectedOptions: postedBySelectedOptions,
    onCollapseClick: collapseClickHandler,
  };

  const mentionedFilterProps: CheckboxFilterLoadedProps = {
    status: ComponentStatus.LOADED,
    headingText: MENTIONED,
    filterByText: FILTER_BY_ANOTHER_MEMBER,
    autoCompleteText: SELECT_PERSON,
    options: mentionedOptions,
    onSelectedOptionChange: setMentionedSelectedOptions,
    selectedOptions: mentionedSelectedOptions,
    onCollapseClick: collapseClickHandler,
  };

  const selectedProfileFeedFilterValues: SelectedProfileFeedFilterValueProps = {
    selectedDates: {
      startDate: startDateValue,
      endDate: endDateValue,
    },
    selectedFlows: serializeFilterValues(flowSelectedOptions, 'flows'),
    selectedPostedBy: serializeFilterValues(
      postedBySelectedOptions,
      'postedBy',
    ),
    selectedMentions: serializeFilterValues(
      mentionedSelectedOptions,
      'mentionedBy',
    ),
  };

  const handleOnSubmit = () => {
    const startDate = startDateValue?.toString().replace('+', '%2b');
    const endDate = endDateValue?.toString().replace('+', '%2b');
    const flowsId = selectedProfileFeedFilterValues.selectedFlows.join();
    const postedBy = selectedProfileFeedFilterValues.selectedPostedBy.join();
    const mentionedBy =
      selectedProfileFeedFilterValues.selectedMentions?.join();

    const queryString: QueryStringTypes = {
      startDate,
      endDate,
      flowsId,
      postedBy,
      mentionedBy,
    };
    history.push(createQueryParam(queryString, `/user/${userId}?`));

    if (
      startDateValue === undefined &&
      endDateValue === undefined &&
      flowSelectedOptions.length === 0 &&
      postedBySelectedOptions.length === 0 &&
      mentionedSelectedOptions.length === 0
    ) {
      setIsFiltered(false);
    } else {
      setIsFiltered(true);
    }

    const getAppliedFilterProps: GetAppliedFilterProps = {
      startDate: startDateValue,
      endDate: endDateValue,
      flowsId: flowSelectedOptions,
      postedBy: postedBySelectedOptions,
      mentioned: mentionedSelectedOptions,
    };

    const filterApplied = getAppliedFilter(getAppliedFilterProps);

    trackRightDrawerEvent(RIGHT_DRAWER_EVENTS.FILTERS_APPLIED, {
      filterApplied,
      feedViewed: page,
      drawerTabViewed,
    });
  };

  const handleOnClear = () => {
    setStartDate(undefined);
    setEndDate(undefined);
    setFlowSelectedOptions([]);
    setPostedSelectedByOptions([]);
    setMentionedSelectedOptions([]);
    setIsFiltered(false);

    history.push(`/user/${userId}`);

    trackRightDrawerEvent(RIGHT_DRAWER_EVENTS.FILTERS_CLEARED, {
      feedViewed: page,
      drawerTabViewed,
    });
  };

  const isEqual =
    isDateEqual(startDateValue, receivedStartDate) &&
    isDateEqual(endDateValue, receivedEndDate) &&
    isArrayEqual(flowSelectedOptions, receivedFlowsID) &&
    isArrayEqual(postedBySelectedOptions, receivedPostedById) &&
    isArrayEqual(mentionedSelectedOptions, receivedMentionedById);

  const isLoading =
    isDateFilterLoading ||
    isFlowsDataLoading ||
    isMentionDataLoading ||
    isPostedByLoading;

  const isError =
    isProfileDataError ||
    isDateFilterError ||
    isFlowsDataError ||
    isMentionsDataError ||
    isPostedByError;

  /* this should run only when there is no query param is being passed */
  useEffect(() => {
    if (
      Object.keys(parsedParams).length === 0 &&
      !isProfileDataLoading &&
      !isLoading
    ) {
      if (initialStartDate === undefined || feedData.length === 0) {
        setIsEmpty(true);
      } else {
        setIsEmpty(false);
      }
    }
    /* this should run only once */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    models: {
      flowsFilterProps,
      dateFilterProps,
      postedByFilterProps,
      mentionedFilterProps,
      isLoading,
      isError,
      isEmpty,
      isFilterButtonEnabled: isEqual,
    },
    operations: {
      handleOnSubmit,
      handleOnClear,
    },
  };
};

export default useProfileFeedRightDrawerControllerLogic;
