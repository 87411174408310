import styled from 'styled-components';

import Button from '../../atoms/Button';

import ThemeV2 from '../../../componentsV2/theme';
import { Flex } from '../../../Utils/styles/display';
import { device } from '../../../constants/layout';
import { zIndexFooter } from '../../../Utils/styles/z-index';

export const StyledScrollWrapper = styled.div<{ isTab?: boolean }>`
  height: ${({ isTab }) =>
    isTab ? 'calc(100vh - 196px)' : 'calc(100vh - 124px)'};
  overflow-y: auto;
  padding-bottom: 20px;
`;

export const StyledFilterWrapper = styled.div`
  background: ${ThemeV2.palette.gray1};
  padding: 16px 24px 0;
`;

export const StyledSpaceSeparator = styled.div`
  margin-bottom: 24px;
`;

export const StyledButton = styled(Button)`
  width: 200px;
  margin-right: 8px;
`;

export const StyledFlex = styled(Flex)<{
  isRightAsideOpen?: boolean;
  isKioskTurnedOn: boolean;
}>`
  position: fixed;
  bottom: ${({ isKioskTurnedOn }) => (isKioskTurnedOn ? '30px' : '0')};
  background: ${ThemeV2.palette.gray1};
  z-index: ${zIndexFooter};
  padding-bottom: 16px;
  padding-top: 16px;
  right: ${({ isRightAsideOpen }) => (isRightAsideOpen ? '0' : '-296px')};
  border-top: 1px solid ${ThemeV2.palette.gray4};
  border-left: 1px solid ${ThemeV2.palette.gray4};
  width: 320px;
  transition: all 0.5s;
  display: flex;
  justify-content: center;

  @media ${device.mobile} {
    width: ${({ isRightAsideOpen }) => (isRightAsideOpen ? '100vw' : '320px')};
    display: ${({ isRightAsideOpen }) => (isRightAsideOpen ? 'flex' : 'none')};
  }
`;

export const StyledWrapper = styled.div`
  padding: 16px 0;
`;

export const Wrapper = styled.div`
  max-height: calc(100vh);
  overflow-y: auto;
`;
