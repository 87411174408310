import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import RRule from 'rrule';

import {
  TriggerType,
  ContentBlockState,
  FlowBuilderBlockTypes,
} from '../../interfaces/Flow/Builder';

import {
  getBaseEmojiFromIcon,
  mapContentBlockFromTemplateResponse,
  mapCriteriaResponseToBlockData,
} from '../../Utils/flows/builder/utils';

import {
  setEmojiSelector,
  setFlowNameSelector,
  setTemplateIdSelector,
  setTemplateNameSelector,
  setDescriptionSelector,
  setTemplateTypeSelector,
  setCurrentScheduleSelector,
  setSpecificBlockDataSelector,
  ownerSelector,
} from '../../stores/flowBuilderStore/selectors';

import useFlowBuilderStore from '../../stores/flowBuilderStore';
import { useGetFlowTemplate } from '../../queries/Flows/Template';
import { CriteriaResponse } from '../../queries/Flows/interfaces';
import { visibilityTypes } from '../../controllers/flowsBuilder/FlowsBuilderVisibilityController/data';

const useGetFlowDetailsFromTemplate = (
  defaultTemplateId?: string,
  defaultTitle?: string,
) => {
  const { search } = useLocation();
  const [template, setTemplate] = useState('');
  const { data: templateData } = useGetFlowTemplate(template);

  const owner = useFlowBuilderStore(ownerSelector);
  const setEmoji = useFlowBuilderStore(setEmojiSelector);
  const setFlowName = useFlowBuilderStore(setFlowNameSelector);
  const setTemplateId = useFlowBuilderStore(setTemplateIdSelector);
  const setDescription = useFlowBuilderStore(setDescriptionSelector);
  const setTemplateType = useFlowBuilderStore(setTemplateTypeSelector);
  const setTemplateName = useFlowBuilderStore(setTemplateNameSelector);

  const setCurrentSchedule = useFlowBuilderStore(setCurrentScheduleSelector);

  const setSpecificBlockData = useFlowBuilderStore(
    setSpecificBlockDataSelector,
  );

  useEffect(() => {
    setTemplate(
      new URLSearchParams(search).get('template') || defaultTemplateId || '',
    );
  }, [search, defaultTemplateId]);

  useEffect(() => {
    const parseRuleToOptions = (rule: string) => {
      const rrule = RRule.parseString(rule);

      rrule.dtstart = new Date();
      return RRule.optionsToString(rrule);
    };

    if (templateData) {
      const contentBlocks: ContentBlockState[] = [];

      const { templateId, templateDetails } = templateData;
      const { title, description: templateDescription } = templateDetails;

      setEmoji(getBaseEmojiFromIcon(templateDescription.icon?.value || ''));
      setFlowName(defaultTitle ? defaultTitle.substring(0, 30) : title);
      setTemplateId(templateId);
      setTemplateName(templateData.templateDetails.title);
      setDescription(templateDescription.text);
      templateDetails.blocks.forEach((block) => {
        const mappedBlockData = mapContentBlockFromTemplateResponse(block);
        if (mappedBlockData) contentBlocks.push(mappedBlockData);
      });

      setSpecificBlockData(FlowBuilderBlockTypes.CONTENT, {
        errors: null,
        contentBlocks: [...contentBlocks],
      });

      const defaultParticipantsCriteria: CriteriaResponse = {
        criteria: {
          custom: {
            rules: [
              {
                condition: 'or',
                rules: [
                  {
                    field: 'member',
                    value: [owner[0]?.id || ''],
                    operator: 'is',
                  },
                ],
              },
            ],
            condition: 'or',
          },
        },
      };

      const defaultVisibilityCriteria: CriteriaResponse = {
        criteria: {
          onlyOwners: true,
        },
      };

      setSpecificBlockData(FlowBuilderBlockTypes.PARTICIPANTS, {
        errors: null,
        participantsCriteria: mapCriteriaResponseToBlockData(
          defaultParticipantsCriteria,
        ),
      });

      setSpecificBlockData(FlowBuilderBlockTypes.VISIBILITY, {
        errors: null,
        custom: true,
        everyone: false,
        onlyOwners: false,
        onlyParticipants: false,
        type: visibilityTypes.CUSTOM,
        criteriaGroups: mapCriteriaResponseToBlockData(
          defaultVisibilityCriteria,
        ),
      });

      if (templateDetails.schedule) {
        setCurrentSchedule({
          rule: parseRuleToOptions(templateDetails.schedule.rule),
        });
      }

      let triggerType: TriggerType = 'ONDEMAND';
      if (templateDetails.kind === 'SCHEDULED') {
        triggerType = 'SCHEDULED';
      } else if (templateDetails.kind === 'NO_TRIGGER') {
        triggerType = 'NO_TRIGGER';
      }

      setSpecificBlockData(FlowBuilderBlockTypes.TRIGGER, {
        selectedCustomRecurrenceTypes: undefined,
        schedule:
          templateDetails.schedule && templateDetails.schedule.rule
            ? { rule: parseRuleToOptions(templateDetails.schedule.rule) }
            : templateDetails.schedule,
        shortcut: templateDetails.shortcut,
        endTimeInMinutes: templateDetails.endTimeInMinutes
          ? templateDetails.endTimeInMinutes
          : 1440,
        triggerType,
      });
      setTemplateType(triggerType);
    }
  }, [
    setEmoji,
    setFlowName,
    templateData,
    setTemplateId,
    setDescription,
    setTemplateType,
    setTemplateName,
    setCurrentSchedule,
    setSpecificBlockData,
  ]);
};

export default useGetFlowDetailsFromTemplate;
