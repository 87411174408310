import React, { useMemo } from 'react';
import RewardCardController from '../RewardCardController';
import RewardsHeader from '../../../atomic/molecules/RewardsHeader';
import EmptyAndErrorSwagsDisplayCard from '../../../atomic/molecules/EmptySwagsDisplayCard';
import {
  RewardsFooter,
  StyledClickable,
  StyledToggleSwagTabsAlert,
} from './styles';
import PaginationComponent from '../../../atomic/molecules/PaginationComponent';
import {
  StyledRewardsLoaderWrapper,
  StyledRewardsWrapper,
} from '../../../atomic/organism/RouteTabs/styles';
import { AxomoSwagReward } from '../../../queries/Rewards/interfaces';
import RewardsEmptyState from '../../../atomic/molecules/RewardsEmptyState';
import {
  REWARDS_PER_PAGE,
  COULD_NOT_FIND_ANY_REWARDS,
  TRY_DIFFERENT_SEARCH,
  WE_ARE_LOOKING_FOR_THE_BEST_REWARDS,
  HOLD_TIGHT,
  REWARD_SWAGS_TAB_ALERT_TEXTS,
} from '../../../languages/en/rewards';
import rewardsNotFoundImage from '../../../img/searching.svg';
import { useSwagRewardsController } from './useSwagRewardsController';
import { defaultItemsPerPageValues } from './data';
import GifLoader from '../../../atomic/molecules/GifLoader';
import rewardsLoaderImage from '../../../img/rewards-loader.gif';
import RewardCardLoader from '../../../atomic/molecules/RewardCard/Loader';
import { RewardTypes } from '../../../atomic/molecules/RewardCard/interface';
import ThemeV2 from '../../../componentsV2/theme';
import Body from '../../../atomic/atoms/Body';

interface SwagsControllerProps {
  isAxomoSwagsEnabled: boolean;
}

const SwagsController = (props: SwagsControllerProps) => {
  const {
    isError,
    isLoading,
    upgradeRequired,
    rewardsCatalogue,
    searchProps,
    filterProps,
    sortProps,
    isLeftAsideOpen,
    isRightAsideOpen,
    profileData,
    itemsPerPage,
    onHandlePaginationChange,
    onItemsPerPageValueChange,
    pageNumber,
    currentSwags,
    handleGotoAdminSwagSettings,
    isCategoryLoading,
    rewardsToDisplay,
    isSwagsTabTurnedOff,
    handleSwagsTabAlertTurnOn,
    emptyRewardsState,
  } = useSwagRewardsController();

  const toggleSwagsTabText = useMemo(() => {
    return (
      <Body variant="body2" inline>
        {REWARD_SWAGS_TAB_ALERT_TEXTS.PREFIX}
        <StyledClickable onClickCallback={handleSwagsTabAlertTurnOn}>
          {REWARD_SWAGS_TAB_ALERT_TEXTS.CLICK_HERE}
        </StyledClickable>
        {REWARD_SWAGS_TAB_ALERT_TEXTS.SUFFIX}
      </Body>
    );
  }, [handleSwagsTabAlertTurnOn]);

  if (isError || !props.isAxomoSwagsEnabled || emptyRewardsState)
    return (
      <EmptyAndErrorSwagsDisplayCard
        hasError={isError}
        upgradeRequired={!!upgradeRequired}
        onGotoAdminSwagSettingsClick={handleGotoAdminSwagSettings}
      />
    );

  return (
    <>
      {!isLoading && isSwagsTabTurnedOff && (
        <StyledToggleSwagTabsAlert
          id="turnRewardsSwagsToggleOnAlert"
          text={toggleSwagsTabText}
          icon="warning-hallow"
          customVariant={{
            background: ThemeV2.palette.gold2,
            iconColor: ThemeV2.palette.orange6,
          }}
          alertType="custom"
        />
      )}
      {!isLoading && (
        <RewardsHeader
          search={searchProps}
          filter={filterProps}
          sort={sortProps}
          isLeftAsideOpen={isLeftAsideOpen}
          isRightAsideOpen={isRightAsideOpen}
        />
      )}

      {isLoading && (
        <GifLoader
          source={rewardsLoaderImage}
          primaryText={WE_ARE_LOOKING_FOR_THE_BEST_REWARDS}
          secondaryText={HOLD_TIGHT}
        />
      )}

      {!isLoading && isCategoryLoading && (
        <StyledRewardsLoaderWrapper>
          <RewardCardLoader rewardType={RewardTypes.GiftCard} />
        </StyledRewardsLoaderWrapper>
      )}

      {!isLoading &&
        profileData &&
        rewardsCatalogue &&
        profileData.assembly?.currency && (
          <>
            {(currentSwags.length === 0 || rewardsCatalogue.length === 0) && (
              <RewardsEmptyState
                title={COULD_NOT_FIND_ANY_REWARDS}
                message={TRY_DIFFERENT_SEARCH}
                hasActionButton={false}
                image={rewardsNotFoundImage}
              />
            )}

            <StyledRewardsWrapper
              isLeftAsideOpen={isLeftAsideOpen}
              isRightAsideOpen={isRightAsideOpen}
            >
              {currentSwags?.map((rewardCardData: AxomoSwagReward) => (
                <RewardCardController
                  key={rewardCardData.uid}
                  rewardCardData={rewardCardData}
                  profileData={profileData}
                  rewardsData={currentSwags}
                  isSwagsTabTurnedOff={isSwagsTabTurnedOff}
                />
              ))}
            </StyledRewardsWrapper>

            {rewardsToDisplay.length > 0 && (
              <RewardsFooter
                isLeftAsideOpen={isLeftAsideOpen}
                isRightAsideOpen={isRightAsideOpen}
              >
                <PaginationComponent
                  dropdownLabelText={REWARDS_PER_PAGE}
                  itemCount={rewardsToDisplay.length}
                  itemsPerPage={itemsPerPage}
                  itemsPerPageValues={defaultItemsPerPageValues}
                  onItemsPerPageValueChange={onItemsPerPageValueChange}
                  onHandlePaginationChange={onHandlePaginationChange}
                  pageNumber={pageNumber}
                  hasRightSpacing
                />
              </RewardsFooter>
            )}
          </>
        )}
    </>
  );
};

export default SwagsController;
