import { useEffect, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import shallow from 'zustand/shallow';
import { TaskFromAPI } from '../../../../interfaces/notebook';
import { pusher } from '../../../../pusher/pusher-base';
import { invalidateTaskHistoryQuery } from '../../../../queries/Notebook';
import { useProfileInfoFetchQuery } from '../../../../queries/Profile';
import useNotebookStore from '../../../../stores/notebookStore';
import { NotebookStore } from '../../../../stores/notebookStore/types';

interface NotebookSocketPayloadType {
  data: {
    actedBy: string;
    task: TaskFromAPI;
  };
}

const selector = (store: NotebookStore) => ({
  onPusherCreate: store.onPusherCreate,
  onPusherDelete: store.onPusherDelete,
  onPusherUpdate: store.onPusherUpdate,
});

const useNotebookPusher = () => {
  const queryClient = useQueryClient();
  const { data } = useProfileInfoFetchQuery();
  const storeFunctions = useNotebookStore(selector, shallow);
  const currentUserTimezone = useMemo(
    () => data?.member.timeZone || '',
    [data?.member.timeZone],
  );

  useEffect(() => {
    if (data) {
      const { memberId } = data.member;
      const channelMember = pusher.subscribe(`private-member-${memberId}`);
      const pusherEventHandler = (
        socketData: string,
        socketPayload: NotebookSocketPayloadType,
      ) => {
        switch (socketData) {
          case 'TASK_CREATED': {
            storeFunctions.onPusherCreate(
              socketPayload.data.task,
              memberId,
              currentUserTimezone,
            );
            break;
          }
          case 'TASK_UPDATED': {
            storeFunctions.onPusherUpdate(
              socketPayload.data.task,
              memberId,
              currentUserTimezone,
            );
            invalidateTaskHistoryQuery(queryClient, socketPayload.data.task.id);
            break;
          }
          case 'TASK_DELETED': {
            storeFunctions.onPusherDelete(
              socketPayload.data.task,
              currentUserTimezone,
            );
            break;
          }
          default:
            break;
        }
      };
      channelMember.bind_global(pusherEventHandler);
      return () => {
        channelMember.unbind_global(pusherEventHandler);
      };
    }
    return;
  }, [data, storeFunctions, currentUserTimezone, queryClient]);
};

export default useNotebookPusher;
