import { ModalsStoreState } from '.';

export const setParticipationFlowSelector = (state: ModalsStoreState) =>
  state.setParticipationFlow;

export const removeParticipationFlowSelector = (state: ModalsStoreState) =>
  state.removeParticipationFlow;

export const setCreateFlowModalOpenSelector = (state: ModalsStoreState) =>
  state.setCreateFlowModalOpen;

export const modalsStoreSelector = ({
  participationFlow,
  isCreateFlowModalOpen,
}: ModalsStoreState) => ({
  participationFlow,
  isCreateFlowModalOpen,
});
