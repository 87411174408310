import styled from 'styled-components';
import ThemeV2 from '../../../componentsV2/theme';

import Body from '../../atoms/Body';
import DropdownCard from '../../atoms/DropdownCard_V2';
import ToolTip from '../../molecules/ToolTipV2';
import Textfield from '../../molecules/NewTextfield';
import RadioGroup from '../../molecules/RadioGroup';
import Dropdown from '../../molecules/Dropdown_V2';
import BasicModalTemplate from '../../templates/BasicModalTemplate';
import Link from '../../atoms/LinkItem';

export const LeftPaddedRadioGroup = styled(RadioGroup)`
  padding-left: 24px;

  .radio-option-container {
    label {
      padding-bottom: 12px;
      color: ${ThemeV2.palette.gray9};
    }
  }
`;

export const MinimumCharacterTextField = styled(Textfield)`
  &.MuiFormControl-root {
    margin-right: 12px;
  }
`;

export const ContainerElement = styled.div`
  position: relative;
`;

export const ContentWrapper = styled.div`
  width: 400px;
  padding: 12px 12px 0 12px;
`;

export const SettingsContent = styled.div`
  > * {
    margin: 20px 0;
  }
`;

export const SettingsHeader = styled(Body)`
  margin-left: 12px;
`;

export const RequiredText = styled(Body)`
  margin-right: 8px;
`;

export const StyledTooltip = styled(ToolTip)`
  margin-right: 8px;
  span:nth-child(3) {
    width: 200px;
  }
`;

export const StyledDropdownCard = styled(DropdownCard)`
  & > div {
    overflow: visible;
    max-height: 100%;
  }
`;

export const ToText = styled(Body)`
  margin: 0 12px;
`;

export const StyledOptionsText = styled(Body)`
  padding-left: 8px;
`;

export const StyledGroupHeader = styled(Body)`
  margin-bottom: 8px;
`;

export const OpenEndedCheckboxOptionsContainer = styled.div`
  margin-top: 12px;
  padding-left: 24px;
  > div {
    margin-bottom: 8px;
  }
`;

export const StyledDropdown = styled(Dropdown)`
  width: 50%;
`;

export const StyledModal = styled(BasicModalTemplate)`
  max-width: 650px;
`;

export const StyledLink = styled(Link)`
  margin-left: 4px;
  color: ${ThemeV2.palette.geekBlue6};

  ::before {
    content: '•';
    margin-right: 4px;
    color: ${ThemeV2.palette.gray10};
  }
`;
