import { useInfiniteQuery } from 'react-query';
import { GET_PAGINATED_INTEGRATION_DATA } from '../../../constants/endpoints';
import { PAGINATED_INTEGRATION_DATA_ITEMS_LIMIT } from '../../../constants/im';
import { makeAPICallWithDataReturn } from '../../utils';

type ParamType = {
  type: string;
  kind: string;
  keyword?: string;
  limit?: number;
  enabled?: boolean;
};

export const useGetIntegrationData = (params: ParamType) => {
  const {
    type,
    enabled,
    kind,
    keyword = '',
    limit = PAGINATED_INTEGRATION_DATA_ITEMS_LIMIT,
  } = params;
  return useInfiniteQuery(
    [GET_PAGINATED_INTEGRATION_DATA, kind, keyword],
    ({ pageParam = '' }) => {
      return makeAPICallWithDataReturn(
        GET_PAGINATED_INTEGRATION_DATA,
        undefined,
        { kind, cursor: pageParam, keyword, limit },
        { sso: type },
      );
    },
    {
      enabled,
      staleTime: Infinity,
      refetchOnMount: 'always',
      getNextPageParam: (lastPage) => {
        return lastPage?.metadata?.pagination?.cursor?.next || undefined;
      },
      getPreviousPageParam: (lastPage) => {
        return lastPage?.metadata?.pagination?.cursor.previous;
      },
    },
  );
};
