import React from 'react';
import styled from 'styled-components';
import Image from '../../atoms/Image';
import Body from '../../atoms/Body';
import LeaderboardNoActivityImage from '../../../img/svgs/LeaderboardNoActivity.svg';
import {
  LEADERBOARD_NO_ACTIVITY_HEADING,
  LEADERBOARD_NO_ACTIVITY_TEXT,
} from '../../../languages/en/home/mainFeedDrawer';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
`;

const StyledBodyHeading = styled(Body)`
  text-align: center;
  max-width: 228px;
  margin-top: 24px;
  margin-bottom: 4px;
`;

const StyledBodyText = styled(Body)`
  text-align: center;
`;

const LeaderboardNoActivity = () => {
  return (
    <Wrapper>
      <Image src={LeaderboardNoActivityImage} alt="leaderboard no activity" />
      <StyledBodyHeading variant="body1Bold">
        {LEADERBOARD_NO_ACTIVITY_HEADING}
      </StyledBodyHeading>
      <StyledBodyText variant="body2">
        {LEADERBOARD_NO_ACTIVITY_TEXT}
      </StyledBodyText>
    </Wrapper>
  );
};

export default LeaderboardNoActivity;
