import { useMemo, useRef } from 'react';
import uuid from 'uuid';
import useParticipationFlow from '../../../../hooks/useParticipationFlow';

import { FlowBlockFromAPI } from '../../../../interfaces/Flow';
import { processFlowBlocks } from '../../ParticipationFlowController/utils';
import { FlowsTemplatePreviewControllerProps } from '../interface';
// import useUppyInstances from '../../ParticipationFlowController/useUppyInstances';

const usePreviewTemplateController = ({
  flowTemplateData,
  profileInfo,
  closePreviewTemplateModal,
}: FlowsTemplatePreviewControllerProps) => {
  const { templateDetails } = flowTemplateData;
  const containerRef = useRef<HTMLDivElement>(null);
  const serializedBlocks: FlowBlockFromAPI[] = useMemo(
    () =>
      templateDetails.blocks.map((content) => ({
        blockId: uuid.v4(),
        blockType: content.type,
        content,
      })),
    [templateDetails.blocks],
  );
  const [schema, initialValues, stepData] = useMemo(
    () => processFlowBlocks(serializedBlocks, profileInfo, flowTemplateData),
    [serializedBlocks, profileInfo, flowTemplateData],
  );
  const { models, operations } = useParticipationFlow({
    staticBlockData: stepData,
    schema,
    initialValues,
    containerRef,
    onFlowSubmit: closePreviewTemplateModal,
  });
  return {
    models: {
      ...models,
      stepData,
      containerRef,
      blocks: serializedBlocks,
    },
    operations,
  };
};

export default usePreviewTemplateController;
