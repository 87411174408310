import { useProfileInfoFetchQuery } from '../../../queries/Profile/index';
import { useMembersSearch } from '../../../hooks/useMembersSearch';

const useMainFeedHeaderController = () => {
  const {
    models: {
      isError: isMembersCountError,
      isLoading: isMembersCountLoading,
      totalMembers,
    },
  } = useMembersSearch(true);
  const { data: profileInfoQueryData } = useProfileInfoFetchQuery();

  const companyName = profileInfoQueryData?.assembly.name;

  return {
    isMembersCountError,
    isMembersCountLoading,
    membersCount: totalMembers,
    companyName,
  };
};

export default useMainFeedHeaderController;
