import styled from 'styled-components';
import Body from '../../atoms/Body';
import Image from '../../atoms/Image';

export const StyledDummyLeaderboardImage = styled(Image)`
  margin-top: 16px;
  width: 100%;
`;

export const StyledHelperText = styled(Body)`
  margin-top: 4px;
  text-align: center;
`;

export const StyledWelcomeText = styled(Body)`
  text-align: center;
`;
