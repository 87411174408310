import React, { MouseEvent, useCallback, useMemo, useState } from 'react';
import {
  IconButton,
  PopoverContainer,
  RewardsTextWrapper,
  RewardsNumberWrapper,
  VerticalBar,
} from './styles';
import { RewardsButtonProps } from './interface';
import { SecondaryPanel } from '../../../interfaces/Layout';
import Popover from '@material-ui/core/Popover';
import AssemblyCurrencyIcon from '../../atoms/AssemblyCurrencyIcon';
import ThemeV2 from '../../../componentsV2/theme';
import { Flex } from '../../../Utils/styles/display';
import { useFeatureSplit } from '../../../hooks/useFeatureSplit';
import {
  SplitNames,
  TreatmentTypes,
} from '../../../hooks/useSplitSdkConfig/constants';
import RewardsPopover from '../../molecules/RewardsPopover';

const RewardsButton = (props: RewardsButtonProps) => {
  const {
    assemblyCurrency,
    totMonthlyAllowance,
    remainingMonthlyAllowance,
    refreshInfoDate,
    trophyBalance,
    hideRedeemButton,
    hideAllowanceDetails,
    hideRewardsLink,
    hideRewardsInfo,
    isObserver,
    redeemButtonClicked,
    rewardType,
  } = props;

  const { treatment: rewardsUpdate } = useFeatureSplit(
    SplitNames.REWARDS_UPDATE,
  );

  const isRewardsUpdate = rewardsUpdate === TreatmentTypes.ON;

  const [isRewardsOpen, setIsRewardsOpen] = React.useState<boolean>(false);
  const [popoverAnchorEl, setPopoverAnchorEl] = useState<HTMLLIElement | null>(
    null,
  );

  const openRewardsPopover = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      /* @ts-ignore-next-line */
      setPopoverAnchorEl(event.currentTarget);
      setIsRewardsOpen(!isRewardsOpen);
    },
    [isRewardsOpen],
  );

  const closeRewards = useCallback(() => {
    setIsRewardsOpen(false);
  }, []);

  const widthCheck = window.matchMedia('(min-width: 769px)');

  const RewardsContent = useMemo(
    () => (
      <PopoverContainer width={widthCheck.matches ? '360px' : '100vw'}>
        <RewardsPopover
          assemblyCurrency={assemblyCurrency}
          totMonthlyAllowance={totMonthlyAllowance}
          remainingMonthlyAllowance={remainingMonthlyAllowance}
          refreshInfoDate={refreshInfoDate}
          trophyBalance={trophyBalance}
          hideRedeemButton={hideRedeemButton}
          hideAllowanceDetails={hideAllowanceDetails}
          hideRewardsLink={hideRewardsLink}
          hideRewardsInfo={hideRewardsInfo}
          redeemButtonClicked={redeemButtonClicked}
          widthCheck={widthCheck.matches}
          closePopover={closeRewards}
          rewardType={rewardType}
        />
      </PopoverContainer>
    ),
    [
      assemblyCurrency,
      closeRewards,
      hideAllowanceDetails,
      hideRedeemButton,
      hideRewardsInfo,
      hideRewardsLink,
      redeemButtonClicked,
      refreshInfoDate,
      remainingMonthlyAllowance,
      rewardType,
      totMonthlyAllowance,
      trophyBalance,
      widthCheck.matches,
    ],
  );

  const currencyName = assemblyCurrency?.name || '';

  const RewardsButtonText = useCallback(
    (headerText, numberValue) => (
      <Flex flexDirection="column" alignItems="start">
        <RewardsTextWrapper isRewardsOpen={isRewardsOpen}>
          {headerText}
        </RewardsTextWrapper>
        <RewardsNumberWrapper
          className={'rewardsButtonNumberText'}
          isRewardsOpen={isRewardsOpen}
        >
          <AssemblyCurrencyIcon
            assemblyCurrency={assemblyCurrency}
            currencyName={currencyName}
            id={currencyName}
            size="12px"
          />
          {numberValue}
        </RewardsNumberWrapper>
      </Flex>
    ),
    [assemblyCurrency, currencyName, isRewardsOpen],
  );

  return (
    <>
      {widthCheck.matches &&
        !isObserver &&
        (!hideAllowanceDetails || !hideRewardsInfo) && (
          <>
            <IconButton
              type="button"
              onClick={openRewardsPopover}
              data-id={SecondaryPanel.REWARDS}
              aria-label={SecondaryPanel.REWARDS}
              isActive={false}
              isRewardsOpen={isRewardsOpen}
            >
              {isRewardsUpdate ? (
                <>
                  {!hideRewardsInfo &&
                    RewardsButtonText('EARNED', trophyBalance)}
                  {!hideAllowanceDetails && !hideRewardsInfo && (
                    <VerticalBar
                      className={'rewardsButtonVerticalBar'}
                      isButtonBar
                      isRewardsOpen={isRewardsOpen}
                    />
                  )}
                  {!hideAllowanceDetails &&
                    RewardsButtonText(
                      'ALLOWANCE',
                      remainingMonthlyAllowance + ' left',
                    )}
                </>
              ) : (
                <>
                  <AssemblyCurrencyIcon
                    assemblyCurrency={assemblyCurrency}
                    currencyName={currencyName}
                    id={currencyName}
                    size="24px"
                  />{' '}
                  <div style={{ color: ThemeV2.palette.gray8 }}>
                    {trophyBalance}
                  </div>
                </>
              )}
            </IconButton>
            <Popover
              PaperProps={{ style: { maxWidth: '100%', maxHeight: '100%' } }}
              open={isRewardsOpen}
              onClose={closeRewards}
              anchorEl={popoverAnchorEl}
              anchorOrigin={{
                horizontal: 'right',
                vertical: 'bottom',
              }}
              transformOrigin={{
                horizontal: 'right',
                vertical: widthCheck.matches ? -8 : -16,
              }}
              marginThreshold={0}
            >
              {RewardsContent}
            </Popover>
            <VerticalBar baseColor={ThemeV2.palette.gray3} />
          </>
        )}
    </>
  );
};

export default RewardsButton;
