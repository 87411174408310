import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import FlowsController from '../FlowsController';

import {
  FLOW_FEED,
  FLOWS_FEEDS,
  FLOW_INSIGHTS,
  FLOWS_BUILDER,
  NEW_FLOWS_BUILDER,
} from '../../../constants/routes';

import useGetUserFlowPermissions from '../../../hooks/useGetUserFlowPermissions';
import FlowsBuilderTabsController from '../../flowsBuilder/FlowsBuilderTabsController';

const FlowsBuilderComponent = () => {
  const { canUserCreateFlow } = useGetUserFlowPermissions();
  if (canUserCreateFlow) {
    return <FlowsBuilderTabsController />;
  }
  return <Redirect to="/" />;
};

const FlowsRoutesController = () => {
  return (
    <Switch>
      <Route exact path={FLOWS_BUILDER} component={FlowsBuilderComponent} />
      <Route exact path={NEW_FLOWS_BUILDER} component={FlowsBuilderComponent} />

      <Route
        exact
        path={FLOW_INSIGHTS}
        component={FlowsBuilderTabsController}
      />
      <Route exact path={FLOW_FEED} component={FlowsBuilderTabsController} />
      <Route path={FLOWS_FEEDS}>
        <FlowsController />
      </Route>
    </Switch>
  );
};

export default FlowsRoutesController;
