import axios from 'axios';
import titleize from 'titleize';
import uuid from 'uuid';
import fileDownload from 'js-file-download';
import ThemeV2 from '../../componentsV2/theme';
import {
  AnonymityStates,
  EmoticonResponse,
  FlowItemResponse,
  FlowResponse,
} from '../../queries/Flows/interfaces';

import {
  ONLY,
  FLOW,
  PARTICIPANTS,
  CUSTOM,
  OWNER,
  POSTED,
  PRIVATELY,
  OTHER,
  OPTION,
} from '../../languages/en/singleWords';
import { ENTIRE_ORGANIZATION } from '../../languages/en';
import {
  FileOperationsRequest,
  DownloadSecureFileResponse,
  VisibilityType,
} from '../../queries/Flows/Feed/interfaces';
import { makeAPICall, makeAPICallWithDataReturn } from '../../queries/utils';
import {
  DELETE_ATTACHMENT_FILE,
  GET_FLOW_POST_DOWNLOAD_URL,
} from '../../constants/endpoints';
import { mapHexCodeToEmoticon } from '../mappers';
import { LeftDrawerCollapsibleNavItemProps } from '../../atomic/molecules/FlowLeftDrawerCollapsibleNavigation/types';
import { IMemberDTO } from '../../interfaces/member';
import { Participant } from '../../atomic/molecules/ParticipantsList/types';
import { OptionItemProps } from '../../atomic/molecules/OptionItemsEditor/interface';
import { capitalizeFirstLetter, getFullName } from '../text';
import { AutocompleteDropdownItem } from '../../atomic/organism/Autocomplete/interfaces';
import { OptionsSelectObject } from '../../interfaces/Flow/Builder';
import { Schedule } from '../../atomic/molecules/FlowsFeedHeader/type';
import { MemberState } from '../../interfaces/user';
import { ParticipationTemplateErrorTypes } from '../../atomic/pages/ParticipationTemplate/types';
import {
  CANT_FIND_FLOW,
  HEAD_TO_MAIN_FEED,
} from '../../languages/en/flows/participation';
import { NO_DESCRIPTION } from '../../languages/en/flows/feed';

export const defaultFlow: FlowResponse = {
  state: 'ACTIVE',
  isShortcut: true,
  isFlowOwner: false,
  flowId: 'recognition',
  createdAt: new Date(),
  updatedAt: new Date(),
  name: 'Celebrate a Teammate',
  color: ThemeV2.palette.blue2,
  icon: { kind: 'HEX_CODE', value: '1F389' },
  description: `Welcome to recognition's new home`,
  creationSource: 'PRELOAD',
  responseSettings: {
    anonymity: {
      state: AnonymityStates.DISABLED,
    },
  },
};

export const getVisibilityIcon = (visibility: VisibilityType) => {
  if (visibility === 'custom' || visibility === 'onlyParticipants') {
    return `people`;
  }
  if (visibility === 'private' || visibility === 'onlyOwners') {
    return 'private';
  }
  return 'eye';
};

export const getVisibilityTooltipText = (
  visibility: VisibilityType,
  viewCount?: number,
) => {
  switch (visibility) {
    case 'onlyParticipants':
      return `${titleize(ONLY)} ${FLOW} ${PARTICIPANTS} ${
        viewCount ? `(${viewCount})` : ''
      }`;
    case 'custom':
      return `${titleize(CUSTOM)} ${viewCount ? `(${viewCount})` : ''}`;
    case 'onlyOwners':
      return `${titleize(ONLY)} ${FLOW} ${OWNER} (1)`;
    case 'private':
      return `${titleize(POSTED)} ${PRIVATELY} (1)`;
    default:
      return `${ENTIRE_ORGANIZATION} ${viewCount ? `(${viewCount})` : ''}`;
  }
};

export const downloadFeedFile = async (request: FileOperationsRequest) => {
  if (request) {
    const downloadFileResponse = await makeAPICallWithDataReturn(
      GET_FLOW_POST_DOWNLOAD_URL,
      undefined,
      undefined,
      {
        ...request,
      },
    );

    const fileToDownload = downloadFileResponse as DownloadSecureFileResponse;
    const response = await axios.get(fileToDownload.url, {
      responseType: 'blob',
    });

    fileDownload(response.data, request.fileName);
  }
};

export const deleteAttachmentFile = async (request: FileOperationsRequest) => {
  if (request) {
    await makeAPICall(DELETE_ATTACHMENT_FILE, undefined, undefined, {
      ...request,
    });
  }
};

export const getFlowNameWithEmoticon = (
  data:
    | {
        emoticon?: string;
        icon?: EmoticonResponse;
        name?: string;
        label?: string;
      }
    | undefined,
) => {
  let emoticon;
  let text;

  if (data && data.emoticon !== null && typeof data.emoticon === 'string') {
    emoticon = data.emoticon;
  }

  if (data && !data.emoticon && data.icon && typeof data.icon === 'object') {
    emoticon = mapHexCodeToEmoticon(data.icon.value);
  }

  if (data && data.name) {
    text = data.name;
  } else if (data && data.label) {
    text = data.label;
  }

  return `${emoticon} ${text}`;
};

export const canShowParticipationList = (
  data: FlowItemResponse | LeftDrawerCollapsibleNavItemProps | undefined,
) => Boolean(data?.isFlowViewer || data?.isFlowOwner);

export const getParticipantBlockForFlowParticipant = (
  participant: IMemberDTO,
  showLockIcon: boolean,
) => {
  const blockData: Participant = {
    image: participant?.image || '',
    memberId: participant.memberID,
    lastName: participant.lastName,
    firstName: participant.firstName,
    showLockIcon,
    memberState: participant.memberState,
  };
  return blockData;
};

export const getFormattedNewMembers = (emails: string[] | undefined) => {
  if (!emails) {
    return [];
  }
  return emails.map((email) => ({
    image: '',
    memberId: email,
    firstName: email,
    lastName: '',
    showLockIcon: false,
    memberState: MemberState.NEW,
  }));
};

export const pushNewOption = (
  options: OptionItemProps[],
  count: number,
  isOtherOption: boolean,
) => {
  if (isOtherOption) {
    const newOption: OptionItemProps = {
      defaultLabel: '',
      label: '',
      value: OTHER.toLowerCase(),
      autoFocus: true,
    };
    return [...options, newOption];
  }
  const isOtherOptionPresent =
    options.findIndex((option) => option?.value === OTHER.toLowerCase()) > -1;
  const newOption: OptionItemProps = {
    label: '',
    defaultLabel: `${capitalizeFirstLetter(OPTION)} ${count + 1}`,
    value: uuid.v4(),
    autoFocus: count > 0,
  };
  if (isOtherOptionPresent) {
    options.splice(options.length - 1, 0, newOption);
    return options;
  }
  return [...options, newOption];
};

export const generateMemberAutocompleteOptions = (
  members: IMemberDTO[],
  currentUserId: string,
  removeCurrentUser = true,
) => {
  return members.reduce<AutocompleteDropdownItem<string, IMemberDTO>[]>(
    (arr, member) => {
      const { memberID, image, memberState } = member;
      const name = getFullName(member);
      const isCurrentUser = memberID === currentUserId;
      if (!removeCurrentUser || !isCurrentUser) {
        arr.push({
          id: memberID,
          title: name,
          avatar: {
            img: image || '',
            name,
            userId: memberID,
            icon: memberState === MemberState.PENDING ? 'pending-person' : '',
            iconColor: ThemeV2.palette.white,
          },
          item: member,
        });
      }
      return arr;
    },
    [],
  );
};

export const getMinimumRange = (
  min: number,
  max: number,
  available: number,
) => {
  if (min >= available) {
    if (min > max) return max - 1 > 0 ? max - 1 : 1;
    return available - 1 > 0 ? available - 1 : 1;
  } else if (min < 1) return 1;
  else return min;
};

export const getMaximumRange = (
  min: number,
  max: number,
  available: number,
) => {
  if (max >= available) return available > 0 ? available : 1;
  else if (max > 0 && max < min)
    return min + 1 <= available ? min + 1 : available;
  else if (max < 1) return 1;
  else return max;
};

export const getUpdatedBlockSettingsOnOptionsUpdate = (
  optionsLength: number,
  selectedOption: OptionsSelectObject,
) => {
  let modifiedOptions: OptionsSelectObject | null;
  switch (selectedOption.type) {
    case 'EXACT_NUMBER': {
      if (selectedOption.exactOptions > optionsLength && optionsLength > 0) {
        modifiedOptions = {
          ...selectedOption,
          exactOptions: optionsLength,
        };
      } else {
        modifiedOptions = null;
      }
      break;
    }

    case 'RANGE': {
      if (selectedOption.maxOptions > optionsLength) {
        modifiedOptions = {
          ...selectedOption,
          minOptions: getMinimumRange(
            selectedOption.minOptions,
            selectedOption.maxOptions,
            optionsLength,
          ),
          maxOptions: getMaximumRange(
            selectedOption.minOptions,
            selectedOption.maxOptions,
            optionsLength,
          ),
        };
      } else {
        modifiedOptions = null;
      }
      break;
    }

    case 'UNLIMITED_OPTIONS':
      modifiedOptions = null;
      break;

    default:
      modifiedOptions = null;
      break;
  }
  return modifiedOptions;
};

type GetIsScheduledFlowEndedParams = {
  isFlowOwner?: boolean;
  kind?: string;
  hasActiveOccurrence?: boolean;
  schedule?: Schedule;
};

export const getIsScheduledFlowEnded = ({
  isFlowOwner,
  kind,
  hasActiveOccurrence,
  schedule,
}: GetIsScheduledFlowEndedParams) => {
  if (isFlowOwner && kind === 'SCHEDULED' && !hasActiveOccurrence) {
    return !schedule || (schedule && schedule.status === 'COMPLETED');
  }
  return false;
};

export const getParticipants = (
  participants: IMemberDTO[] | undefined,
  newMembers?: string[],
) => {
  if (!participants || (!participants.length && !newMembers?.length)) {
    return [];
  }

  let formattedNewMembers = [];
  const formattedParticipants = participants.map(
    (participant) =>
      ({
        image: participant.image || '',
        memberId: participant.memberID,
        lastName: participant.lastName,
        firstName: participant.firstName,
        memberState: participant.memberState,
      } as Participant),
  );

  if (newMembers) {
    formattedNewMembers = newMembers.map((email) => {
      return {
        image: '',
        memberId: email,
        firstName: email,
        lastName: '',
        memberState: MemberState.NEW,
      };
    });

    return [...formattedNewMembers, ...formattedParticipants];
  }

  return formattedParticipants;
};

export const getDummyPostFlowId = (
  templateIds: string[],
  flows: FlowResponse[] | undefined,
) => {
  for (let i = 0; i < templateIds.length; i++) {
    const currentFlowItem = flows?.find(
      (item) => item.templateId === templateIds[i],
    );

    if (currentFlowItem) {
      return currentFlowItem.flowId;
    }
  }

  return '';
};

export const mapParticipationTemplateErrorTypesToTitleMessage = (
  errorTypes: ParticipationTemplateErrorTypes,
  flowData: FlowItemResponse | null,
) => {
  switch (errorTypes) {
    case ParticipationTemplateErrorTypes.FLOW_UPDATED:
    case ParticipationTemplateErrorTypes.ACTIVE_OCCURRENCE_ENDED:
    case ParticipationTemplateErrorTypes.NO_ACTIVE_OCCURRENCE_FOUND:
    case ParticipationTemplateErrorTypes.OCCURRENCE_ALREADY_RESPONDED:
    case ParticipationTemplateErrorTypes.ACTIVE_OCCURRENCE_ID_MISMATCH:
      return `${mapHexCodeToEmoticon(flowData?.icon.value || '')} ${
        flowData?.name
      }`;
    case ParticipationTemplateErrorTypes.INVALID_FLOW_ID:
    case ParticipationTemplateErrorTypes.FLOW_NOT_FOUND:
    case ParticipationTemplateErrorTypes.FLOW_NOT_AVAILABLE:
    case ParticipationTemplateErrorTypes.INVALID_OCCURRENCE_ID:
    case ParticipationTemplateErrorTypes.UNABLE_TO_BUILD_INSTANCE_FROM_BLOCKS:
    default: {
      return CANT_FIND_FLOW;
    }
  }
};

export const mapParticipationTemplateErrorTypesToDescriptionMessage = (
  errorTypes: ParticipationTemplateErrorTypes,
  flowData: FlowItemResponse | null,
) => {
  switch (errorTypes) {
    case ParticipationTemplateErrorTypes.FLOW_UPDATED:
    case ParticipationTemplateErrorTypes.ACTIVE_OCCURRENCE_ENDED:
    case ParticipationTemplateErrorTypes.NO_ACTIVE_OCCURRENCE_FOUND:
    case ParticipationTemplateErrorTypes.OCCURRENCE_ALREADY_RESPONDED:
    case ParticipationTemplateErrorTypes.ACTIVE_OCCURRENCE_ID_MISMATCH:
      return flowData?.description || NO_DESCRIPTION;
    case ParticipationTemplateErrorTypes.FLOW_NOT_FOUND:
    case ParticipationTemplateErrorTypes.INVALID_FLOW_ID:
    case ParticipationTemplateErrorTypes.FLOW_NOT_AVAILABLE:
    case ParticipationTemplateErrorTypes.INVALID_OCCURRENCE_ID:
    case ParticipationTemplateErrorTypes.UNABLE_TO_BUILD_INSTANCE_FROM_BLOCKS:
    default:
      return HEAD_TO_MAIN_FEED;
  }
};

export const getAnswerFlowRoute = (
  flowId: string,
  occurrenceId: string | undefined,
  pathname: string,
) => {
  let answerFlowRoute = `/flows/${flowId}/answer?redirectUrl=${pathname}`;
  if (occurrenceId) {
    answerFlowRoute = `${answerFlowRoute}&occurrenceId=${occurrenceId}`;
  }

  return answerFlowRoute;
};
