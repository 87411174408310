import { CELEBRATE_TEAMMATE_FLOW } from './data';
import { getColorById } from '../../Utils/flows/colors';
import { mapHexCodeToEmoticon } from '../../Utils/mappers';
import { AnonymityStates, FlowResponse } from '../../queries/Flows/interfaces';
import { ActionBarItem } from '../../atomic/molecules/ActionMenuItem/types';
import { MAIN_FEED_ACTION_BAR_ANONYMOUS_TOOLTIP_TEXT } from '../../languages/en/feed';

export const mapFlowsToActionBarItem = (flows: FlowResponse[]) => {
  if (!flows || !flows.length) {
    return [];
  }

  return flows.map((flow) => {
    const isAnonymityEnabled =
      flow.responseSettings.anonymity.state === AnonymityStates.ENABLED;
    return {
      id: flow.flowId,
      title: flow.name,
      emoticon: mapHexCodeToEmoticon(flow.icon.value),
      emoticonBg: getColorById(flow.flowId),
      occurrence: flow.occurrence,
      ...(isAnonymityEnabled && {
        toolTipProps: {
          toolTipIcon: 'anonymous-rounded',
          toolTipText: MAIN_FEED_ACTION_BAR_ANONYMOUS_TOOLTIP_TEXT,
        },
      }),
    } as ActionBarItem;
  });
};

// Add celebrate a teammate conditionally to replicate our search functionality
export const addCelebrateTeammate = (
  items: FlowResponse[],
  searchText: string,
) => {
  const allItems = [...items];
  if (searchText) {
    if (
      CELEBRATE_TEAMMATE_FLOW.name
        .toLowerCase()
        .includes(searchText.toLowerCase())
    ) {
      allItems.unshift(CELEBRATE_TEAMMATE_FLOW);
    }
  } else {
    allItems.unshift(CELEBRATE_TEAMMATE_FLOW);
  }

  return allItems;
};
