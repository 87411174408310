import { useHistory, useParams } from 'react-router-dom';
import { useCallback, useEffect, useRef, useState } from 'react';

import { AxiosResponse } from 'axios';
import { isValidEmail } from '../../../../Utils/validation';
import { EMPLOYEE_VERIFY_LINK, SIGN_IN } from '../../../../constants/routes';
import { useValidateEmailRequestForOnboarding } from '../../../../queries/Onboarding';
import { ValidateEmailRequestForOnboardingSuccessResponse } from '../../../../queries/Onboarding/interfaces';
import { useGetExternalFlowsQuery } from '../../../../queries/Flows/ExternalFlows';

export const useExternalFlowsCompletionController = () => {
  const { flowId } = useParams<{ flowId: string }>();
  const [email, setEmail] = useState('');
  const [hasError, setHasError] = useState(false);

  const { push } = useHistory();
  const { mutate } = useValidateEmailRequestForOnboarding();

  const { data: externalFlowData } = useGetExternalFlowsQuery(flowId);
  const handleExploreForFreeClick = useCallback(() => {
    if (email) {
      setHasError(!isValidEmail(email));

      if (!isValidEmail(email)) {
        return;
      }

      const payload = { email: email };

      mutate(payload, {
        onSuccess: (
          res: AxiosResponse<ValidateEmailRequestForOnboardingSuccessResponse>,
        ) => {
          push(`${EMPLOYEE_VERIFY_LINK}/${res.data.data.userId}`);
        },
        onError: () => {
          push(SIGN_IN);
        },
      });
    }
  }, [email, mutate, push]);

  const refAnimationInstance = useRef(null);

  const getInstance = useCallback((instance) => {
    refAnimationInstance.current = instance;
  }, []);

  const makeShot = useCallback((particleRatio, opts) => {
    if (refAnimationInstance.current)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      refAnimationInstance.current({
        ...opts,
        origin: { y: 0.7 },
        particleCount: Math.floor(1000 * particleRatio),
      });
  }, []);

  const fire = useCallback(() => {
    makeShot(0.25, {
      spread: 100,
      startVelocity: 55,
    });

    makeShot(0.2, {
      spread: 100,
    });

    makeShot(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 45,
    });
  }, [makeShot]);

  useEffect(() => {
    fire();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    email,
    setHasError,
    hasError,
    setEmail,
    getInstance,
    handleExploreForFreeClick,
    creator: externalFlowData?.data.creator,
  };
};
