import {
  useFetchFlowActive,
  useGetShortcutsQuery,
  useGetToDoFlowsQuery,
} from '../../queries/Flows/Dashboard';

const useGetActionBarData = (searchQuery?: string) => {
  const {
    data: todo,
    isError: isToDosError,
    isSuccess: isToDosSuccess,
    isFetching: isFetchingToDos,
  } = useGetToDoFlowsQuery(searchQuery);

  const {
    data: shortcut,
    isError: isShortcutsError,
    isSuccess: isShortcutsSuccess,
    isFetching: isFetchingShortcuts,
  } = useGetShortcutsQuery(searchQuery);

  const {
    data: flowsList,
    isError: isFlowsListError,
    isSuccess: isFlowsListSuccess,
    isLoading: isFlowsListLoading,
  } = useFetchFlowActive(searchQuery);

  return {
    todo,
    shortcut,
    flowsList,
    isError: isToDosError || isShortcutsError || isFlowsListError,
    isSuccess: isToDosSuccess && isShortcutsSuccess && isFlowsListSuccess,
    isLoading: isFetchingShortcuts || isFetchingToDos || isFlowsListLoading,
  };
};

export default useGetActionBarData;
