import styled from 'styled-components';

export const StyledRewardsLoader = styled.div`
  text-align: center;
  max-width: 400px;
  padding-top: 56px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
`;

export const StyledRewardsLoaderImage = styled.img`
  margin-bottom: 44px;
`;
