import styled from 'styled-components';
import { Flex } from '../../../Utils/styles/display';

export const Container = styled(Flex)<{
  hasPaddingBottom: boolean;
}>`
  margin: auto;
  max-width: 502px;
  padding-top: 16px;
  padding-bottom: ${({ hasPaddingBottom }) => hasPaddingBottom && '64px'};
`;
