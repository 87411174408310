import { memo } from 'react';

import {
  AVATAR,
  BIRTHDAY,
  DATE,
  DEPARTMENT,
  DIRECT,
  EMAIL,
  HANDLE,
  INFORMATION,
  MANAGER,
  NAME,
  PROFILE,
  REPORTS,
  START,
  TIME_ZONE,
} from '../../../languages/en/singleWords';
import Body from '../../atoms/Body';
import AccordionItem from '../../molecules/AccordionItem';
import {
  AccordionHeader,
  AccordionHeaderInner,
  AccordionRoot,
} from '../../molecules/AccordionItem/styles';
import SettingsDropdown from '../../molecules/SettingsDropdown';
import { SettingsDropdownProps } from '../../molecules/SettingsDropdown/interface';
import SettingsStringField, {
  SettingsStringFieldProps,
} from '../../molecules/SettingsEditStringField';
import SettingsMultiSelectDropdown from '../../molecules/SettingsMultiSelectDropdown';
import { SettingsMultiSelectDropdownProps } from '../../molecules/SettingsMultiSelectDropdown/interface';
import SettingsAvatar from '../SettingsAvatar';
import { SettingsAvatarProps } from '../SettingsAvatar/interface';

import React from 'react';
import { JOB_TITLE, LOCATION } from '../../../languages/en/home/profileDrawer';
import SettingsAutocomplete, {
  SettingsAutocompleteProps,
} from '../../molecules/SettingsAutocomplete';

export interface SettingsHandleProps {
  handle?: string;
}

export interface SettingsEmailProps {
  email?: string;
}

export interface GeneralSettingsAccordionProps {
  settingsAvatarProps: SettingsAvatarProps;
  settingsBirthdayProps: SettingsMultiSelectDropdownProps;
  settingsEditNameProps: SettingsStringFieldProps;
  settingsEditJobTitleProps: SettingsStringFieldProps;
  settingsEditLocationProps: SettingsStringFieldProps;
  settingsHandleProps?: SettingsHandleProps;
  settingsEmailProps?: SettingsEmailProps;
  settingsDepartmentProps: SettingsDropdownProps;
  settingsTimezoneProps: SettingsDropdownProps;
  settingsManagerProps: SettingsAutocompleteProps;
  settingsReportsProps: SettingsAutocompleteProps;
  settingsStartDateProps: SettingsMultiSelectDropdownProps;
  managerPlaceholder?: string;
  accordionItems?: string[];
  handleCollapse?: (name: string) => void;
  reportsPlaceholder?: string;
  birthDayPlaceholder?: string;
  startDatePlaceholder?: string;
}

const GeneralSettingsAccordion = (props: GeneralSettingsAccordionProps) => {
  const {
    settingsAvatarProps,
    settingsEditNameProps,
    settingsEditJobTitleProps,
    settingsEditLocationProps,
    settingsHandleProps,
    settingsEmailProps,
    settingsDepartmentProps,
    settingsTimezoneProps,
    settingsManagerProps,
    settingsReportsProps,
    settingsBirthdayProps,
    settingsStartDateProps,
    managerPlaceholder,
    reportsPlaceholder,
    birthDayPlaceholder,
    startDatePlaceholder,
    accordionItems,
    handleCollapse,
  } = props;

  const { avatarImage } = settingsAvatarProps;
  const {
    initialValues: { userName },
  } = settingsEditNameProps;

  const {
    initialValues: { jobTitle },
  } = settingsEditJobTitleProps;

  const {
    initialValues: { location },
  } = settingsEditLocationProps;

  const { initialValue: departmentValue } = settingsDepartmentProps;

  return (
    <AccordionRoot>
      <AccordionHeader>
        <AccordionHeaderInner>
          <Body variant="body1Bold">{`${PROFILE} ${INFORMATION}`}</Body>
        </AccordionHeaderInner>
      </AccordionHeader>
      <AccordionItem
        itemTitle={AVATAR}
        avatarImage={avatarImage}
        itemHasNoValue={avatarImage === ''}
        handleCollapse={handleCollapse}
        isCollapseOpen={accordionItems && accordionItems.indexOf(AVATAR) === -1}
      >
        <SettingsAvatar {...settingsAvatarProps} />
      </AccordionItem>
      <AccordionItem
        itemTitle={NAME}
        placeholderValue={userName}
        itemHasNoValue={userName === ''}
        handleCollapse={handleCollapse}
        isCollapseOpen={accordionItems && accordionItems.indexOf(NAME) === -1}
      >
        <SettingsStringField {...settingsEditNameProps} />
      </AccordionItem>
      <AccordionItem
        itemTitle={HANDLE}
        placeholderValue={settingsHandleProps?.handle}
        itemHasNoValue={settingsHandleProps?.handle === ''}
        handleCollapse={handleCollapse}
        isCollapseOpen={accordionItems && accordionItems.indexOf(HANDLE) === -1}
        readOnly
      >
        Sample content
      </AccordionItem>
      <AccordionItem
        itemTitle={EMAIL}
        placeholderValue={settingsEmailProps?.email}
        handleCollapse={handleCollapse}
        isCollapseOpen={accordionItems && accordionItems.indexOf(EMAIL) === -1}
        readOnly
      >
        Sample content
      </AccordionItem>

      <AccordionItem
        itemTitle={JOB_TITLE}
        placeholderValue={jobTitle}
        itemHasNoValue={jobTitle === ''}
        handleCollapse={handleCollapse}
        isCollapseOpen={accordionItems && !accordionItems.includes(JOB_TITLE)}
      >
        <SettingsStringField {...settingsEditJobTitleProps} />
      </AccordionItem>

      <AccordionItem
        itemTitle={DEPARTMENT}
        placeholderValue={departmentValue}
        itemHasNoValue={departmentValue === ''}
        handleCollapse={handleCollapse}
        isCollapseOpen={
          accordionItems && accordionItems.indexOf(DEPARTMENT) === -1
        }
      >
        <SettingsDropdown {...settingsDepartmentProps} />
      </AccordionItem>

      <AccordionItem
        itemTitle={MANAGER}
        placeholderValue={managerPlaceholder || ''}
        itemHasNoValue={managerPlaceholder === ''}
        handleCollapse={handleCollapse}
        isCollapseOpen={
          accordionItems && accordionItems.indexOf(MANAGER) === -1
        }
      >
        <SettingsAutocomplete {...settingsManagerProps} />
      </AccordionItem>
      <AccordionItem
        itemTitle={`${DIRECT} ${REPORTS}`}
        handleCollapse={handleCollapse}
        isCollapseOpen={
          accordionItems &&
          accordionItems.indexOf(`${DIRECT} ${REPORTS}`) === -1
        }
        placeholderValue={reportsPlaceholder || ''}
        itemHasNoValue={reportsPlaceholder === ''}
      >
        <SettingsAutocomplete {...settingsReportsProps} />
      </AccordionItem>

      <AccordionItem
        itemTitle={TIME_ZONE}
        placeholderValue={settingsTimezoneProps.initialValue}
        itemHasNoValue={settingsTimezoneProps.initialValue === ''}
        handleCollapse={handleCollapse}
        isCollapseOpen={
          accordionItems && accordionItems.indexOf(TIME_ZONE) === -1
        }
      >
        <SettingsDropdown {...settingsTimezoneProps} />
      </AccordionItem>

      <AccordionItem
        itemTitle={LOCATION}
        placeholderValue={location}
        itemHasNoValue={location === ''}
        handleCollapse={handleCollapse}
        isCollapseOpen={accordionItems && !accordionItems.includes(LOCATION)}
      >
        <SettingsStringField {...settingsEditLocationProps} />
      </AccordionItem>

      <AccordionItem
        itemTitle={BIRTHDAY}
        placeholderValue={birthDayPlaceholder || ''}
        itemHasNoValue={birthDayPlaceholder === ''}
        handleCollapse={handleCollapse}
        isCollapseOpen={
          accordionItems && accordionItems.indexOf(BIRTHDAY) === -1
        }
        readOnly={!(birthDayPlaceholder === '')}
      >
        <SettingsMultiSelectDropdown
          {...settingsBirthdayProps}
          isDisabled={birthDayPlaceholder === ''}
        />
      </AccordionItem>
      <AccordionItem
        itemTitle={`${START} ${DATE}`}
        placeholderValue={startDatePlaceholder}
        itemHasNoValue={startDatePlaceholder === ''}
        handleCollapse={handleCollapse}
        isCollapseOpen={
          accordionItems && accordionItems.indexOf(`${START} ${DATE}`) === -1
        }
        readOnly={!(startDatePlaceholder === '')}
      >
        <SettingsMultiSelectDropdown
          {...settingsStartDateProps}
          isDisabled={startDatePlaceholder === ''}
        />
      </AccordionItem>
    </AccordionRoot>
  );
};

const MemoizedGeneralSettingsAccordion = memo(GeneralSettingsAccordion);
MemoizedGeneralSettingsAccordion.displayName = 'GeneralSettingsAccordion';
export default MemoizedGeneralSettingsAccordion;
