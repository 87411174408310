import React, { ReactNode } from 'react';
import SecondaryDrawer from '../SecondaryDrawer';
import PrimaryHeaderController from '../../../controllers/layout/PrimaryHeaderController';
import PrimaryNavController from '../../../controllers/layout/PrimaryNavController';
import { PageContent } from './styles';
import MainBody from '../MainBody';
import SecondaryDrawerController from '../../../controllers/home/SecondaryDrawerController';
interface PrimaryLayoutProps {
  children: ReactNode;
  isFlowOwner: boolean;
  onSecondaryDrawerToggle?: (isRightAsideOpen: boolean) => void;
  isNotebookView?: boolean;
  hasTabComponent?: boolean;
  hasContentFooter?: boolean;
}

const PrimaryLayout = ({
  children,
  isFlowOwner,
  onSecondaryDrawerToggle,
  isNotebookView,
  hasTabComponent,
  hasContentFooter,
}: PrimaryLayoutProps) => {
  return (
    <>
      <PrimaryHeaderController />
      <PageContent>
        <PrimaryNavController />
        <MainBody
          isFlowOwner={isFlowOwner}
          isNotebookView={isNotebookView}
          hasTabComponent={hasTabComponent}
          hasContentFooter={hasContentFooter}
        >
          {children}
        </MainBody>
        <SecondaryDrawer onSecondaryDrawerToggle={onSecondaryDrawerToggle}>
          <SecondaryDrawerController />
        </SecondaryDrawer>
      </PageContent>
    </>
  );
};

export default PrimaryLayout;
