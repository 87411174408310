import React from 'react';
import styled from 'styled-components';
import ThemeV2 from '../../../../componentsV2/theme';
import { ADD, THIS, BLOCK } from '../../../../languages/en/singleWords';
import FormsEditorBaseInputBlock from '../FormsEditorInputBaseBlock';

import {
  ContentBlockLocalProperties,
  ContentGivePointsBlockState,
  GivePointsBlockBuilderSettingState,
  GivePointsStackCurrencyAmount,
} from '../../../../interfaces/Flow/Builder';
import {
  GIVE_POINTS,
  BLOCK_MUST_BE_LINKED_TO,
  PERSON_SELECTION,
} from '../../../../languages/en/flows/builder';
import { capitalizeFirstLetter } from '../../../../Utils/text';
import AssemblyCurrencyIcon from '../../../atoms/AssemblyCurrencyIcon';
import Body from '../../../atoms/Body';

export interface FormsEditorGivePointsInputBlockProps
  extends ContentBlockLocalProperties {
  blockData: ContentGivePointsBlockState;
}

const StyledWrapper = styled.div`
  margin: 16px 0;
  border: 1px solid ${ThemeV2.palette.gray5};
  padding: 8px 12px;
  width: 100%;
  border-radius: 4px;
  color: ${ThemeV2.palette.gray6};
`;

const StyledContent = styled(Body)`
  margin-left: 8px;
`;

const bannerText = (
  <>
    {`${capitalizeFirstLetter(THIS)} `}
    <Body inline variant="body3Medium" color="geekBlue6">
      {GIVE_POINTS}
    </Body>
    {` ${BLOCK_MUST_BE_LINKED_TO} `}
    <Body inline variant="body3Medium" color="geekBlue6">
      {PERSON_SELECTION}
    </Body>
    {` ${BLOCK.toLowerCase()}.`}
  </>
);

const FormsEditorGivePointsInputBlock = ({
  blockData,
  currentIndex,
  handleToggleIsDescriptionNeeded,
  handleToggleIsRequired,
  onValueChange,
  totalNumberOfItems,
  onDeleteClick,
  onMoveDownClick,
  onMoveUpClick,
}: FormsEditorGivePointsInputBlockProps) => {
  const {
    hideCurrencyValues,
    limitAmountDetails,
    isRequired,
    description,
    assemblyCurrency,
  } = blockData;

  const toggleHideCurrencyValues = () => {
    if (onValueChange) {
      onValueChange({
        ...blockData,
        hideCurrencyValues: !hideCurrencyValues,
      });
    }
  };

  const changeAmountDetails = (
    amountDetails?: GivePointsStackCurrencyAmount,
  ) => {
    if (onValueChange) {
      onValueChange({
        ...blockData,
        limitAmountDetails: amountDetails,
      });
    }
  };

  const blockSettingState: GivePointsBlockBuilderSettingState = {
    type: 'GIVE_POINTS_STACK',
    isDescriptionNeeded: description !== null,
    isRequired,
    toggleIsRequired: handleToggleIsRequired,
    toggleDescriptionNeeded: handleToggleIsDescriptionNeeded,
    hideCurrencyValues,
    limitAmountDetails,
    toggleHideCurrencyValues,
    changeAmountDetails,
    disableRequiredToggle: true,
  };

  return (
    <FormsEditorBaseInputBlock
      blockData={blockData}
      currentIndex={currentIndex !== undefined ? currentIndex : 0}
      assemblyCurrency={assemblyCurrency}
      totalNumberOfItems={totalNumberOfItems}
      blockSettingState={blockSettingState}
      onDeleteClick={onDeleteClick}
      onMoveDownClick={onMoveDownClick}
      onMoveUpClick={onMoveUpClick}
      onValueChange={onValueChange}
      bannerText={bannerText}
    >
      <StyledWrapper>
        <AssemblyCurrencyIcon
          assemblyCurrency={assemblyCurrency}
          id={assemblyCurrency.name}
          currencyName={assemblyCurrency.name}
          dataTestId="flow-builder-give-points-currency"
        />
        <StyledContent variant="body2" color="grey6" inline>
          {`${ADD} ${assemblyCurrency.pluralName}`}
        </StyledContent>
      </StyledWrapper>
    </FormsEditorBaseInputBlock>
  );
};

export default FormsEditorGivePointsInputBlock;
