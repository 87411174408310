export const IconButtonWithToolTipData = {
  FORMAT: 'Show formatting',
  AT_MENTION: 'Mention a teammate',
  EMOTICONS: 'Add an emoji',
  GIF_SELECTOR: 'Add a GIF',
  ATTACHMENT: 'Attachment',
  PRIVATE_MESSAGE: 'Make it private',
};

export const PostButtonText = 'Post';
export const PostPrivateButtonText = 'Post Private';

export const EditorPlaceholder = 'What did your coworker(s) do?';

export const MODAL_POST_FORM_HEADING = 'Make it meaningful';

export const REMOVE_GIF_TO_SWITCH = 'Remove current GIF to switch';

export const CORE_VALUE_DEFAULT_TEXT = 'Choose a core value';

export const LEAVE_DRAFT_MODAL_LANGUAGES = {
  heading: 'Discard draft?',
  mainContent: `You haven't finished your post.`,
  subContent: `Are you sure you'd like to leave and discard it?`,
  successButtonText: 'Leave Draft',
  cancelButtonText: 'Go Back',
};

export const POST_CREATION_INPROGRESS_MESSAGE = 'Celebration in progress';
export const POST_CREATION_SUCCESS_MESSAGE = 'Celebration successful!';
export const POST_CREATION_ERROR_MESSAGE =
  'Oops! Celebration unsuccessful. Check your connection and try again';

export const COMMENT_CREATION_ERROR_MESSAGE =
  'Comment post unsuccessful. Check your connection and try again';

export const POST_DELETION_INPROGRESS_MESSAGE = 'Deleting the Post';
export const POST_DELETION_SUCCESS_MESSAGE = 'Post deleted successfully!';
export const POST_DELETION_ERROR_MESSAGE =
  'Oops! Post delete unsuccessful. Check your connection and try again';

export const COMMENT_DELETION_INPROGRESS_MESSAGE = 'Deleting comment';
export const COMMENT_DELETION_SUCCESS_MESSAGE = 'Comment deleted successfully!';
export const COMMENT_DELETION_ERROR_MESSAGE =
  'Oops! Comment delete unsuccessful. Check your connection and try again';

export const MESSAGE_LESS_THAN_LIMIT_ERRORS = [
  `People often say that motivation doesn’t last. Well, neither does a short message –
  we recommend writing a bit more.`,
  `The best things in life are messages a bit longer than this one. Write a bit more.`,
  `Nothing is impossible, the word itself says “I’m possible!" Write a little more.`,
  `Happiness is just sadness that hasn’t happened yet. Let's avoid sadness, write a bit more.`,
  `Write like a proton, always positive – write a bit more.`,
];

export const PROFANE_ERROR_MESSAGE_POST =
  'Please remove profanity to publish post';
export const PROFANE_ERROR_MESSAGE_COMMENT =
  'Please remove profanity to publish comment';
