import React, { useMemo } from 'react';

import { MeetingListProps } from './type';
import BlockSection from '../../BlockSection';
import ErrorComponent from './Components/ErrorComponent';
import EmptyComponent from './Components/EmptyComponent';
import LoadedComponent from './Components/LoadedComponent';
import LoadingComponent from './Components/LoadingComponent';
import { ComponentStatus } from '../../../../interfaces/component';
import { quickSetupMeetingListScreen } from '../../../../languages/en/quickSetup';

const MeetingList = (props: MeetingListProps) => {
  const renderMeetingListChildren = useMemo(() => {
    switch (props.status) {
      case ComponentStatus.LOADED:
        return <LoadedComponent {...props} />;
      case ComponentStatus.LOADING:
        return <LoadingComponent {...props} />;
      case ComponentStatus.EMPTY:
        return <EmptyComponent {...props} />;
      case ComponentStatus.ERROR:
        return <ErrorComponent />;
      default:
        throw new Error('Invalid Component Status');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return (
    <BlockSection
      headingVariant="h4"
      subHeadingColor="gray8"
      subHeadingVariant="body1"
      heading={quickSetupMeetingListScreen.headingText}
      subHeading={quickSetupMeetingListScreen.subHeadingText}
    >
      {renderMeetingListChildren}
    </BlockSection>
  );
};

export default MeetingList;
