import { AxiosError } from 'axios';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import {
  useFetchFlowDetailsQuery,
  useVerifyFlowAuthorization,
} from '../../../../queries/Flows/Feed';

import { HttpsStatus } from '../../../../interfaces/ResponseError';
import {
  FlowItemResponse,
  FlowInstanceResponse,
  AnonymityStates,
} from '../../../../queries/Flows/interfaces';
import { ParticipationFlowHeaderContent } from '../../../../atomic/molecules/FlowsParticipationHeader';
import { ComponentStatus } from '../../../../interfaces/component';
import { FLOW_NOT_FOUND } from '../../../../languages/en/flows';
import {
  ANSWER_POSTED_SUCCESSFULLY,
  ARE_ANSWERING,
  GO_BACK_TO_MAIN_FEED,
} from '../../../../languages/en/flows/participation';
import { Flex } from '../../../../Utils/styles/display';
import {
  StyledBodyFlowName,
  StyledBodyHeaderTitle,
  StyledEmoticon,
} from '../../../../atomic/molecules/FlowsParticipationHeader/styles';
import Body from '../../../../atomic/atoms/Body';
import { YOU } from '../../../../languages/en/singleWords';
import { mapHexCodeToEmoticon } from '../../../../Utils/mappers';
import {
  useGetFlowInstanceQuery,
  useSubmitFlowInstanceMutation,
} from '../../../../queries/Flows/Dashboard';
import isEmpty from 'lodash/isEmpty';
import {
  ExternalFlowCreatorDetails,
  ExternalFlowDetailsResponse,
  useGetExternalFlowsQuery,
  useSubmitExternalFlowInstanceMutation,
} from '../../../../queries/Flows/ExternalFlows';
import { profileData } from '../../../../Utils/home/feeds/dummyData';
import { useProfileInfoFetchQuery } from '../../../../queries/Profile';
import { NO_DESCRIPTION } from '../../../../languages/en/flows/feed';
import useToggle from '../../../../hooks/useToggle';
import { FlowSubmissionDetails } from '../../../../interfaces/Flow';
import { ParticipationTemplateErrorTypes } from '../../../../atomic/pages/ParticipationTemplate/types';
import { showErrorMessage, showSuccessMessage } from '../../../../Utils/toast';
import { formatBlockResponses } from '../../ParticipationFlowController/utils';
import useGoogleEnterpriseRecaptcha, {
  GoogleReCaptchaActionTypes,
} from '../../../../hooks/useGoogleEnterpriseRecaptcha';
import { GOOGLE_RECAPTCHA_SITE_KEY } from '../../../../config';
import { EXTERNAL_PATH_COMPLETION } from '../../../../constants/routes';
import { parse } from 'qs';
import useModalsStore from '../../../../stores/modalsStore';
import { removeParticipationFlowSelector } from '../../../../stores/modalsStore/selectors';

const useFlowsParticipationController = () => {
  // FlowId
  const history = useHistory();
  const { search } = useLocation();
  const { flowId } = useParams<{ flowId: string }>();

  const parsedParams = parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const occurrenceId =
    (parsedParams && (parsedParams.occurrenceId as string)) || undefined;
  const redirectedValue =
    (parsedParams && (parsedParams.isRedirected as string)) || undefined;

  const removeParticipationFlow = useModalsStore(
    removeParticipationFlowSelector,
  );

  // Internal Participation
  const [errorMessage, setErrorMessage] = useState('');
  const [customError, setCustomError] = useState<
    ParticipationTemplateErrorTypes | undefined
  >(undefined);
  const [isOccurrenceClosed, setIsOccurrenceClosed] = useState(false);
  const [isUnAuthorizedError, setIsUnAuthorizedError] = useState(false);
  const [participationType, setParticipationType] = useState<
    'INTERNAL' | 'EXTERNAL'
  >('INTERNAL');
  const [flowData, setFlowData] = useState<FlowItemResponse | null>(null);
  const [flowDetailsInstanceData, setFlowDetailsInstanceData] = useState<
    FlowInstanceResponse | ExternalFlowDetailsResponse | null
  >(null);

  const onFlowDetailsError = (error: AxiosError) => {
    if (error.response?.status === HttpsStatus.CONFLICT) {
      setErrorMessage(error.response.data.message);
    }

    setIsUnAuthorizedError(error.response?.status === HttpsStatus.UNAUTHORIZED);
  };

  const onFlowInstanceError = (error: AxiosError) => {
    if (error.response?.status === HttpsStatus.CONFLICT) {
      setCustomError(error.response.data.message);
    }

    if (error.response?.status === HttpsStatus.FORBIDDEN) {
      setErrorMessage(error.response.data.message);
    }
  };

  const {
    data: flowDetails,
    isError: isFlowDetailsError,
    isLoading: isFlowDetailsLoading,
  } = useFetchFlowDetailsQuery(
    flowId,
    '',
    !isUnAuthorizedError,
    onFlowDetailsError,
  );

  const { data: flowDetailsInstance, isLoading: isFlowDetailsInstanceLoading } =
    useGetFlowInstanceQuery({
      flow: {
        participationFlowId: flowId,
        occurrenceId: occurrenceId,
      },
      enabled: !isFlowDetailsLoading && !isUnAuthorizedError,
      onErrorCallback: onFlowInstanceError,
    });

  useEffect(() => {
    if (flowDetailsInstance) {
      setFlowDetailsInstanceData(flowDetailsInstance);
    }
  }, [flowDetailsInstance]);

  useEffect(() => {
    if (flowDetails) {
      const data = flowDetails.data;
      setFlowData(data);
      setParticipationType('INTERNAL');

      if (!data.isShortcut) {
        if (data.occurrence?.activeOccurrence) {
          if (data.occurrence.activeOccurrence.hasResponded) {
            setIsOccurrenceClosed(true);
            setErrorMessage(
              ParticipationTemplateErrorTypes.OCCURRENCE_ALREADY_RESPONDED,
            );
          }
        } else {
          setIsOccurrenceClosed(true);
          setErrorMessage(
            ParticipationTemplateErrorTypes.OCCURRENCE_ALREADY_RESPONDED,
          );
        }
      }
    }
  }, [flowDetails]);

  // External Participation
  const [identifier, setIdentifier] = useState<string>();
  const [creator, setCreator] = useState<ExternalFlowCreatorDetails>();
  const [validationError, setValidationError] = useState<
    { code: number; message: string } | undefined
  >(undefined);

  const handleValidationErrors = (error: unknown) => {
    const axiosError = error as AxiosError;
    const axiosErrorMessage = axiosError?.response?.data?.message;
    const statusCode = axiosError?.response?.status;
    setValidationError(
      !isEmpty(axiosErrorMessage) && statusCode
        ? {
            code: statusCode,
            message: axiosErrorMessage,
          }
        : undefined,
    );
  };

  const { isLoading: isFlowAuthorizationLoading } = useVerifyFlowAuthorization(
    flowId,
    isUnAuthorizedError,
    handleValidationErrors,
  );

  const { data: externalFlowData, isLoading: isExternalFlowDataLoading } =
    useGetExternalFlowsQuery(
      flowId,
      Boolean(!isFlowAuthorizationLoading && isUnAuthorizedError),
    );

  useEffect(() => {
    if (externalFlowData) {
      setParticipationType('EXTERNAL');
      setCreator(externalFlowData.data.creator);
      setIdentifier(externalFlowData.data.identifier);
      setFlowDetailsInstanceData(externalFlowData.data.flow);
    }
  }, [flowId, externalFlowData]);

  const [flowHeaderContent, headerStatus]: [
    ParticipationFlowHeaderContent,
    ComponentStatus,
  ] = useMemo(() => {
    if (externalFlowData) {
      return [
        {
          TitleContent: (
            <Flex>
              <StyledBodyHeaderTitle color="gray8" variant="body2">
                <Body color="geekblue6" inline variant="body2Medium">
                  {`${YOU} `}
                </Body>
                {`${ARE_ANSWERING} `}
              </StyledBodyHeaderTitle>
              <StyledEmoticon>
                {mapHexCodeToEmoticon(externalFlowData.data.flow.icon.value)}
              </StyledEmoticon>
              <StyledBodyFlowName
                color="geekBlue6"
                inline
                variant="body2Medium"
              >
                {externalFlowData.data.flow.name}
              </StyledBodyFlowName>
            </Flex>
          ),
          DescriptionContent: (
            <Flex key="description">
              <Body variant="body3" color="gray7">
                {externalFlowData.data.flow.description || NO_DESCRIPTION}
              </Body>
            </Flex>
          ),
        },
        ComponentStatus.LOADED,
      ];
    }

    if (isFlowDetailsError && !externalFlowData && !isExternalFlowDataLoading) {
      return [
        {
          TitleContent: FLOW_NOT_FOUND,
          DescriptionContent: GO_BACK_TO_MAIN_FEED,
        },
        ComponentStatus.ERROR,
      ];
    }

    if (isFlowDetailsLoading || !flowDetails) {
      return [
        {
          TitleContent: '',
          DescriptionContent: '',
        },
        ComponentStatus.LOADING,
      ];
    }

    return [
      {
        TitleContent: (
          <Flex>
            <StyledBodyHeaderTitle color="gray8" variant="body2">
              <Body color="geekblue6" inline variant="body2Medium">
                {`${YOU} `}
              </Body>
              {`${ARE_ANSWERING} `}
            </StyledBodyHeaderTitle>
            <StyledEmoticon>
              {mapHexCodeToEmoticon(flowDetails.data.icon.value)}
            </StyledEmoticon>
            <StyledBodyFlowName color="geekBlue6" inline variant="body2Medium">
              {flowDetails.data.name}
            </StyledBodyFlowName>
          </Flex>
        ),
        DescriptionContent: (
          <Flex key="description">
            <Body variant="body3" color="gray7">
              {flowDetails.data.description || NO_DESCRIPTION}
            </Body>
          </Flex>
        ),
      },
      ComponentStatus.LOADED,
    ];
  }, [
    isExternalFlowDataLoading,
    externalFlowData,
    flowDetails,
    isFlowDetailsError,
    isFlowDetailsLoading,
  ]);

  const { loading: loadingCaptcha, generateToken } =
    useGoogleEnterpriseRecaptcha(
      GOOGLE_RECAPTCHA_SITE_KEY,
      GoogleReCaptchaActionTypes.EXTERNAL_PARTICIPATION_SUBMISSION,
      isUnAuthorizedError,
    );

  useEffect(() => {
    // This is to hide the Google Captcha badge
    // https://developers.google.com/recaptcha/docs/faq#id-like-to-hide-the-recaptcha-badge.-what-is-allowed
    setTimeout(() => {
      const items = document.getElementsByClassName('grecaptcha-badge');
      if (items?.length) {
        items[0].setAttribute('style', 'visibility: hidden; position: fixed;');
      }
    }, 500);
  }, [loadingCaptcha]);

  const { data: profileInfoData } = useProfileInfoFetchQuery(
    !isFlowDetailsLoading && !isUnAuthorizedError,
  );

  // Misc Participation Information
  const {
    mutate: internalFlowMutation,
    isLoading: internalFlowMutationLoading,
  } = useSubmitFlowInstanceMutation(flowId || '');

  const {
    mutate: externalFlowMutation,
    isLoading: externalFlowMutationLoading,
  } = useSubmitExternalFlowInstanceMutation(flowId, identifier || '');

  const {
    models: { toggleValue: isPrivatePost },
    operations: { setToggleValue: togglePrivatePost },
  } = useToggle();
  const {
    models: { toggleValue: isAnonymousPost },
    operations: { setToggleValue: toggleAnonymousPost },
  } = useToggle();

  const formatPayload = useCallback(
    (submittedValues: Record<string, any>) =>
      formatBlockResponses(
        submittedValues,
        flowDetailsInstanceData as FlowInstanceResponse,
        isPrivatePost,
        isAnonymousPost ||
          flowDetails?.data.responseSettings.anonymity.state ===
            AnonymityStates.ENABLED,
      ),
    [
      flowDetails?.data.responseSettings.anonymity.state,
      flowDetailsInstanceData,
      isAnonymousPost,
      isPrivatePost,
    ],
  );

  const handleCloseParticipationModal = useCallback(() => {
    removeParticipationFlow();
    const params = new URLSearchParams(search);
    history.push(params.get('redirectUrl') || '/');
  }, [history, removeParticipationFlow, search]);

  const onFlowSubmit = async ({ values }: FlowSubmissionDetails) => {
    let payload = formatPayload(values);

    if (participationType === 'EXTERNAL') {
      const captchaToken = await generateToken();
      // @ts-ignore
      payload = { ...payload, captchaToken };
    }

    const mutate =
      participationType === 'EXTERNAL'
        ? externalFlowMutation
        : internalFlowMutation;

    // Manually overriding onError and onSuccess call for this mutate function.
    mutate(payload, {
      onError: (error: unknown) => {
        const saveResponseErrorMessage = (error as AxiosError).response?.data
          .message;
        if (
          saveResponseErrorMessage !==
          ParticipationTemplateErrorTypes.INVALID_BLOCK_PARAMETERS
        ) {
          setCustomError(
            saveResponseErrorMessage as ParticipationTemplateErrorTypes,
          );
        }
        if (
          saveResponseErrorMessage !==
          ParticipationTemplateErrorTypes.NO_ACTIVE_OCCURRENCE_FOUND
        )
          showErrorMessage(saveResponseErrorMessage);
      },
      onSuccess: () => {
        removeParticipationFlow();
        if (participationType === 'EXTERNAL') {
          history.push(`${EXTERNAL_PATH_COMPLETION}?flowId=${flowId}`);
        } else {
          const params = new URLSearchParams(search);
          history.push(params.get('redirectUrl') || '/home');
          showSuccessMessage(ANSWER_POSTED_SUCCESSFULLY);
        }
      },
    });
  };

  useEffect(() => {
    removeParticipationFlow();
  }, [removeParticipationFlow]);

  return {
    flowId,
    creator,
    flowData,
    identifier,
    headerStatus,
    errorMessage,
    validationError,
    flowHeaderContent,
    onFlowSubmit,
    toggleAnonymousPost,
    togglePrivatePost,
    customError,
    setCustomError,
    isRedirected: redirectedValue === 'true',
    isMutationLoading:
      internalFlowMutationLoading || externalFlowMutationLoading,
    isPrivatePost,
    isAnonymousPost,
    participationType,
    isOccurrenceClosed,
    isUnAuthorizedError,
    flowDetailsInstanceData,
    isError: isFlowDetailsError,
    handleCloseParticipationModal,
    profileData: profileInfoData || profileData,
    isLoading:
      isFlowDetailsLoading ||
      isFlowAuthorizationLoading ||
      isFlowDetailsInstanceLoading,
  };
};

export default useFlowsParticipationController;
