import { EmojiData } from 'emoji-mart';
import { EntryProps, GifRatingsProps } from '../../atoms/Giphy/interface';
import { AddTrophiesInputProps } from '../AddTrophiesInput';

export enum PostType {
  Comment = 'comment',
  Recognition = 'recognition',
}
export interface GiveRecognitionFormBottomButtonsBaseProps {
  postType?: PostType;
  isGifSelected?: boolean;
  isAttachmentSelected?: boolean;
  isPrivateMessageSelected?: boolean;
  onAtMentionClick: () => void;
  onEmoticonClick: (emoji: EmojiData) => void;
  onGifSelectorClick: (entry: EntryProps) => void;
  onAttachmentClick: () => void;
  onPrivateMessageClick: () => void;
  onPostClick: () => void;
  className?: string;
  disabled?: boolean;
  showAddTrophy?: boolean;
  isCreatingPostOrComment?: boolean;
  isAttachmentButtonHidden?: boolean;
  isPrivatePostButtonHidden?: boolean;
  isEmoticonsButtonHidden?: boolean;
  isGifButtonHidden?: boolean;
  isAtMentionButtonHidden?: boolean;
  gifRatings?: GifRatingsProps;
  isDisabled?: boolean;
}

export interface IconButtonWrapperInterface {
  icon: string;
  disabled?: boolean;
  toolTipText: string;
  onClick?: () => void;
  isSelected?: boolean;
  isHidden?: boolean;
  isDisabled?: boolean;
}

export interface ToolTipComponentProps {
  text: string;
}

export type GiveRecognitionFormBottomButtonsProps =
  GiveRecognitionFormBottomButtonsBaseProps & AddTrophiesInputProps;
