import { EditorState } from 'draft-js';
import { useEffect, useCallback, useMemo, useRef, useState } from 'react';

import { AxiosError } from 'axios';
import { AutocompleteDropdownItem } from '../../../atomic/organism/Autocomplete/interfaces';
import { useMembersSearch } from '../../../hooks/useMembersSearch';
import useParticipationFlow from '../../../hooks/useParticipationFlow';
import { CreatePostPayload } from '../../../interfaces/Feed';
import { IMemberDTO } from '../../../interfaces/member';
import { useCreatePost } from '../../../queries/Feed';
import { GetProfileInfoResponse } from '../../../queries/Profile';
import {
  canGiveCustomAmountOfPoints,
  getIsCoreValuesEnabled,
} from '../../../queries/Profile/utils';
import { trackEvent } from '../../../Utils/analytics';
import {
  ACTION_EVENTS,
  ANALYTICS_EVENTS,
  PARTICIPATION_ANALYTICS_EVENTS,
} from '../../../Utils/analytics/constants';
import { showErrorMessage, showSuccessMessage } from '../../../Utils/toast';
import { recognitionFlowStepData, STEP_IDS } from './data';
import {
  generateRecognitionMemberAutocompleteOptions,
  generateValidationSchema,
  transformMessageForApi,
} from './utils';
import { POST_CREATION_SUCCESS_MESSAGE } from '../../../languages/en/giveRecognitionForm';
import {
  StaticBlockState,
  DynamicBlockState,
  OpenEndedBlockValue,
  FlowSubmissionDetails,
  FlowPostErrorMessage,
} from '../../../interfaces/Flow';
import { getErrorMessage } from '../../../Utils/message';
import { RECOGNITION_API_GENERIC_ERROR } from '../../../languages/en/flows/participation';
import useTrackParticipationFlow from '../../../hooks/analytics/useTrackParticipationFlow';
import { canCurrentUserGiveAllowance } from '../../../Utils/user';

const NO_USER_PROPS = undefined;

const generateInitialValues = (
  selectedTeammateDropdownItem?: AutocompleteDropdownItem<string>,
) => {
  const selectedTeammates = selectedTeammateDropdownItem
    ? [selectedTeammateDropdownItem]
    : [];

  const initialValues = {
    [STEP_IDS.SELECT_TEAMMATE]: selectedTeammates,
    [STEP_IDS.WHAT_DID_THEY_DO]: {
      editorState: EditorState.createEmpty(),
      gifUrl: undefined,
      selectedMentions: [],
      tags: [],
    } as OpenEndedBlockValue,
    [STEP_IDS.SELECT_CORE_VALUE]: null,
    [STEP_IDS.TROPHIES]: '',
  };
  return initialValues;
};

const useRecognitionFlowController = (
  profileInfo: GetProfileInfoResponse,
  toggleIsRecognitionFlowModalOpen: () => void,
  selectedTeammateDropdownItem?: AutocompleteDropdownItem<string>,
  onPostSuccess?: () => void,
) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [havePointsBeenAdjusted, setHavePointsBeenAdjusted] = useState(false);
  const [isPrivatePost, setIsPrivatePost] = useState(false);
  const { assembly, member } = profileInfo;
  const { pointsLeftThisCycle } = member;

  const {
    models: {
      value: textboxValue,
      searchedMembers,
      totalMembers,
      isFetching: isMembersFetching,
      hasMoreMembers,
    },
    operations: { onChange: onTextboxValueChange, fetchMoreMembers },
  } = useMembersSearch(true);

  const onPeopleOptionsScroll = () => {
    if (hasMoreMembers && !isMembersFetching) {
      fetchMoreMembers();
    }
  };

  const coreValueOptions: AutocompleteDropdownItem<string>[] = useMemo(() => {
    if (profileInfo) {
      return profileInfo.assembly.settings.coreValues.value.map((value) => ({
        id: value,
        title: value,
      }));
    }
    return [];
  }, [profileInfo]);

  const updatedSlideData = useMemo(() => {
    let data = [...recognitionFlowStepData];
    if (!getIsCoreValuesEnabled(profileInfo)) {
      data = data.filter((slide) => slide.id !== STEP_IDS.SELECT_CORE_VALUE);
    }
    if (!canCurrentUserGiveAllowance(profileInfo.member)) {
      data = data.filter((slide) => slide.id !== STEP_IDS.TROPHIES);
    }
    return data;
  }, [profileInfo]);

  const handlePostingSuccess = (
    staticStates: StaticBlockState[],
    dynamicStates: DynamicBlockState[],
  ) => {
    trackEvent(ANALYTICS_EVENTS.RECOGNITION_PARTICIPATION_POST, NO_USER_PROPS, {
      numberOfBlocks: staticStates.length,
      numberOfBlocksRequired: staticStates.filter(
        ({ isRequired }) => isRequired,
      ).length,
      numberOfBlocksAnswered: dynamicStates.filter(({ isValid }) => isValid)
        .length,
    });
    toggleIsRecognitionFlowModalOpen();
    showSuccessMessage(POST_CREATION_SUCCESS_MESSAGE);
    if (onPostSuccess) {
      onPostSuccess();
    }
  };

  const onPostError = (
    staticStates: StaticBlockState[],
    dynamicStates: DynamicBlockState[],
  ) => {
    trackEvent(
      ANALYTICS_EVENTS.RECOGNITION_PARTICIPATION_POST_ERROR,
      NO_USER_PROPS,
      {
        numberOfBlocks: staticStates.length,
        numberOfBlocksRequired: staticStates.filter(
          ({ isRequired }) => isRequired,
        ).length,
        numberOfBlocksAnswered: dynamicStates.filter(({ isValid }) => isValid)
          .length,
      },
    );
  };

  const { isLoading: isPosting, mutate } = useCreatePost();

  const formatPayload = (finalValues: any) => {
    const { editorState, gifUrl, selectedMentions } = finalValues[
      STEP_IDS.WHAT_DID_THEY_DO
    ] as OpenEndedBlockValue;
    const { text: transformedMessage } = transformMessageForApi(
      editorState,
      selectedMentions,
    );
    const selectedTeammates = finalValues[
      STEP_IDS.SELECT_TEAMMATE
    ] as AutocompleteDropdownItem<string>[];

    const payload: CreatePostPayload = {
      carrotsEach:
        finalValues[STEP_IDS.TROPHIES] > 0 ? finalValues[STEP_IDS.TROPHIES] : 0,
      coreValue: finalValues[STEP_IDS.SELECT_CORE_VALUE]?.id || undefined,
      gifUrl: gifUrl || undefined,
      isPrivate: isPrivatePost,
      message: transformedMessage,
      to: selectedTeammates.map((teammate) => teammate.id),
    };
    return payload;
  };

  const schema = useMemo(
    () => generateValidationSchema(profileInfo, updatedSlideData),
    [profileInfo, updatedSlideData],
  );

  const initialValues = useMemo(
    () => generateInitialValues(selectedTeammateDropdownItem),
    [selectedTeammateDropdownItem],
  );

  const { trackParticipationFlow } = useTrackParticipationFlow({
    stepData: updatedSlideData,
  });

  const onFlowSubmit = ({
    values,
    dynamicBlockData,
    currentStep,
  }: FlowSubmissionDetails) => {
    const payload = formatPayload(values);
    // Manually overriding onError and onSuccess call for this mutate function.
    mutate(payload, {
      onError: (error: unknown) => {
        const apiErrorMessages = getErrorMessage(
          error as AxiosError,
          RECOGNITION_API_GENERIC_ERROR,
        ) as FlowPostErrorMessage[];
        trackParticipationFlow(
          PARTICIPATION_ANALYTICS_EVENTS.ERRORED,
          currentStep,
          ACTION_EVENTS.ERROR,
        );
        onPostError(updatedSlideData, dynamicBlockData);
        if (!apiErrorMessages.length) {
          showErrorMessage(RECOGNITION_API_GENERIC_ERROR);
        }

        showErrorMessage(apiErrorMessages.map((err) => err.message).join(','));
      },
      onSuccess: () => {
        if (handlePostingSuccess) {
          handlePostingSuccess(updatedSlideData, dynamicBlockData);
        }
      },
    });
  };

  const {
    models: {
      blockErrors,
      currentStep,
      errors,
      fieldErrors,
      hasVisitedLastStep,
      values,
      dynamicBlockData,
    },
    operations: {
      onStepChange,
      onFormCompleteClick,
      setFieldTouched,
      setFieldValue,
      resetForm,
      goToNextStep,
      goToPreviousStep,
    },
  } = useParticipationFlow({
    onFlowSubmit,
    staticBlockData: updatedSlideData,
    schema,
    initialValues,
    onSubmitFlowError: onPostError,
    containerRef,
  });

  const memberOptions: AutocompleteDropdownItem<string, IMemberDTO>[] =
    useMemo(() => {
      if (searchedMembers) {
        return generateRecognitionMemberAutocompleteOptions(
          searchedMembers,
          profileInfo.member.memberId,
        );
      }
      return [];
    }, [profileInfo.member.memberId, searchedMembers]);

  const handleAdjustmentWarningCTAClick = () => {
    const trophiesStepIndex = getIsCoreValuesEnabled(profileInfo) ? 3 : 2;
    onStepChange(trophiesStepIndex);
  };

  const handleTrophiesValueChange = useCallback(
    (value: string | number) => {
      const shouldValidate = false;
      setFieldValue(STEP_IDS.TROPHIES, value, shouldValidate);
    },
    [setFieldValue],
  );

  const handleModalClose = () => {
    trackEvent(ANALYTICS_EVENTS.RECOGNITION_PARTICIPATION_EXIT, NO_USER_PROPS, {
      numberOfBlocks: updatedSlideData.length,
      numberOfBlocksRequired: updatedSlideData.filter(
        ({ isRequired }) => isRequired,
      ).length,
      numberOfBlocksAnswered: dynamicBlockData.filter(({ isValid }) => isValid)
        .length,
    });
    resetForm();
    toggleIsRecognitionFlowModalOpen();
  };

  useEffect(() => {
    trackEvent(
      ANALYTICS_EVENTS.RECOGNITION_PARTICIPATION_START,
      NO_USER_PROPS,
      {
        numberOfBlocks: updatedSlideData.length,
        numberOfBlocksRequired: updatedSlideData.filter(
          ({ isRequired }) => isRequired,
        ).length,
      },
    );
    // ONLY RUN ON RENDER
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const trophiesInputValue = values[STEP_IDS.TROPHIES];
    const selectedTeammates = values[STEP_IDS.SELECT_TEAMMATE];
    const selectedTeammatesLength = selectedTeammates.length || 1;
    const maximumGivingPercentage =
      profileInfo.assembly.settings.postImpactLevel.value.levels[4].percentage;

    const maxPointsEach = canGiveCustomAmountOfPoints(profileInfo)
      ? Math.floor(pointsLeftThisCycle / selectedTeammatesLength)
      : Math.min(
          Math.floor(pointsLeftThisCycle / selectedTeammatesLength),
          Math.floor((member.allowance.points * maximumGivingPercentage) / 100),
        );

    const hasExceededAvailablePoints = maxPointsEach < trophiesInputValue;
    if (hasExceededAvailablePoints) {
      const newTrophiesInputValue = Math.min(
        Math.floor(maxPointsEach * selectedTeammatesLength),
        maxPointsEach,
      );
      const newFieldValue =
        newTrophiesInputValue === 0 ? '' : newTrophiesInputValue;
      setFieldValue(STEP_IDS.TROPHIES, newFieldValue);
      setHavePointsBeenAdjusted(true);
    }
  }, [
    member.allowance.points,
    pointsLeftThisCycle,
    profileInfo,
    setFieldValue,
    values,
  ]);

  useEffect(() => {
    setHavePointsBeenAdjusted(false);
  }, [currentStep]);

  return {
    models: {
      isSingleMemberAssembly: totalMembers === 1,
      updatedSlideData,
      currentStep,
      blockErrors,
      values,
      memberOptions,
      isMembersFetching,
      textboxValue,
      totalMembers,
      havePointsBeenAdjusted,
      assembly,
      fieldErrors,
      coreValueOptions,
      member,
      errors,
      pointsLeftThisCycle,
      hasVisitedLastStep,
      isPosting,
      isPrivatePost,
      dynamicBlockData,
      containerRef,
    },
    operations: {
      onStepChange,
      setFieldValue,
      onTextboxValueChange,
      setFieldTouched,
      handleAdjustmentWarningCTAClick,
      handleTrophiesValueChange,
      onFormCompleteClick,
      setIsPrivatePost,
      handleModalClose,
      goToNextStep,
      goToPreviousStep,
      onPeopleOptionsScroll,
    },
  };
};

export default useRecognitionFlowController;
