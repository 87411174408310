import React from 'react';
import Link from '../../atoms/Link_V2';
import {
  PrimaryHeaderWrapper,
  LogoContainer,
  StyledLogo,
  CustomHeader,
  HamburgerButton,
} from './styles';
import SVGIcon from '../../atoms/SVGIcon';
import { PrimaryHeaderProps } from './interface';

const PrimaryHeader = (props: PrimaryHeaderProps) => {
  const { path, companyName, companyLogo, toggleLeftAsideOpen, children } =
    props;

  return (
    <PrimaryHeaderWrapper>
      <div style={{ display: 'flex' }}>
        <HamburgerButton onClick={toggleLeftAsideOpen}>
          <SVGIcon icon="hamburger" size="39px" />
        </HamburgerButton>
        <Link to={path}>
          <div style={{ display: 'flex', marginLeft: '12px' }}>
            <LogoContainer>
              <StyledLogo src={companyLogo} alt={companyName} />
            </LogoContainer>
            <CustomHeader>{companyName}</CustomHeader>
          </div>
        </Link>
      </div>
      <div style={{ display: 'flex' }}>{children}</div>
    </PrimaryHeaderWrapper>
  );
};

export default PrimaryHeader;
