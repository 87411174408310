import React from 'react';
import styled from 'styled-components';

import { AssemblyCurrency } from '../../../interfaces/assembly';
import AssemblyCurrencyIcon from '../../atoms/AssemblyCurrencyIcon';
import Body from '../../atoms/Body';

const StyledAnchor = styled.a`
  text-decoration: underline;
`;

interface FlowsPointsAdjustmentWarningProps {
  adjustedTo: number | string;
  assemblyCurrency: AssemblyCurrency;
  onCallToActionClick: () => void;
}

const FlowsPointsAdjustmentWarning = ({
  adjustedTo,
  assemblyCurrency,
  onCallToActionClick,
}: FlowsPointsAdjustmentWarningProps) => {
  const { pluralName } = assemblyCurrency;

  if (adjustedTo === 0 || adjustedTo === '') {
    return (
      <>
        You don’t have enough {pluralName} to give to everyone. Don&apos;t let
        that stop you from celebrating.
      </>
    );
  }

  return (
    <>
      {pluralName} adjusted to{' '}
      <AssemblyCurrencyIcon
        assemblyCurrency={assemblyCurrency}
        currencyName={assemblyCurrency.name}
        id={assemblyCurrency.name}
        size="12px"
      />
      <Body inline variant="body3Bold">
        {adjustedTo}
      </Body>{' '}
      each. Go to{' '}
      <StyledAnchor onClick={onCallToActionClick} role="button" tabIndex={0}>
        this block{' '}
      </StyledAnchor>
      to make edits.
    </>
  );
};

export default FlowsPointsAdjustmentWarning;
