import React, { useMemo, memo } from 'react';

import Button from '../../atoms/Button';
import Body from '../../atoms/Body';
import GifSelector from '../GifSelector';
import { ButtonSize } from '../../atoms/IconButton_V2/interface';

import { Flex } from '../../../Utils/styles/display';
import {
  IconButtonWithToolTipData,
  PostButtonText,
  PostPrivateButtonText,
  REMOVE_GIF_TO_SWITCH,
} from '../../../languages/en/giveRecognitionForm/index';
import { TrophiesInputTooltip } from '../../../languages/en/commentsForm/index';
import { Icon } from '../../../constants/giveRecognitionForm';
import { AddTrophiesInputProps } from '../AddTrophiesInput';
import {
  PostType,
  GiveRecognitionFormBottomButtonsProps,
  ToolTipComponentProps,
  IconButtonWrapperInterface,
} from './interface';

import {
  ButtonContentFlexWrapper,
  Wrapper,
  IconButtonWrapper,
  StyledTrophiesInput,
  StyledToolTip,
  TooltipContent,
  StyledEmoticonPickerPopover,
} from './styles';

import { AssemblyCurrency } from '../../../interfaces/assembly';
import { GifRatingsProps } from '../../atoms/Giphy/interface';

const ToolTipComponent = ({ text }: ToolTipComponentProps) => (
  <Body variant="body3" color="gray1">
    {text}
  </Body>
);

const GiveRecognitionFormBottomButtons = (
  props: GiveRecognitionFormBottomButtonsProps,
) => {
  const {
    postType = PostType.Comment,
    isGifSelected = false,
    isAttachmentSelected = false,
    isPrivateMessageSelected = false,
    onAtMentionClick,
    onEmoticonClick,
    onGifSelectorClick,
    onAttachmentClick,
    onPostClick,
    isCreatingPostOrComment = false,
    disabled = false,
    className,
    showAddTrophy = false,
    onPrivateMessageClick,
    isAttachmentButtonHidden = false,
    isPrivatePostButtonHidden = false,
    gifRatings = GifRatingsProps.PG_13,
    isEmoticonsButtonHidden,
    isGifButtonHidden,
    isAtMentionButtonHidden,
    isDisabled = false,
  }: GiveRecognitionFormBottomButtonsProps = props;
  const {
    buttonText,
    value,
    assemblyCurrency,
    maxValue,
    splitBy,
    menuItems,
    canAllowCustomAmount,
    onChange,
    onBlur,
    onItemClick,
    buttonColor,
  }: AddTrophiesInputProps = props;

  const { name: currencyName }: AssemblyCurrency = assemblyCurrency;

  const updatedTrophiesInputTooltip = useMemo(() => {
    const replacedString = TrophiesInputTooltip.replace(
      ':currency',
      currencyName,
    );
    return replacedString;
  }, [currencyName]);

  const iconButtonWrapperContent = useMemo(() => {
    const result: IconButtonWrapperInterface[] = [
      {
        icon: Icon.AT_MENTION,
        onClick: onAtMentionClick,
        toolTipText: IconButtonWithToolTipData.AT_MENTION,
        isHidden: isAtMentionButtonHidden,
        isDisabled,
      },
      {
        icon: Icon.EMOTICON,
        toolTipText: IconButtonWithToolTipData.EMOTICONS,
        isHidden: isEmoticonsButtonHidden,
        isDisabled,
      },
      {
        icon: Icon.GIF_SELECTOR,
        toolTipText: IconButtonWithToolTipData.GIF_SELECTOR,
        isSelected: isGifSelected,
        isHidden: gifRatings === undefined || isGifButtonHidden,
        isDisabled,
      },
      {
        icon: Icon.ATTACHMENT,
        onClick: onAttachmentClick,
        toolTipText: IconButtonWithToolTipData.ATTACHMENT,
        isSelected: isAttachmentSelected,
        isHidden: isAttachmentButtonHidden,
        isDisabled,
      },
      {
        icon: isPrivateMessageSelected ? Icon.LOCK : Icon.UN_LOCK,
        onClick: onPrivateMessageClick,
        toolTipText: IconButtonWithToolTipData.PRIVATE_MESSAGE,
        isSelected: isPrivateMessageSelected,
        isHidden: isPrivatePostButtonHidden,
      },
    ];
    return result;
  }, [
    onAtMentionClick,
    isAtMentionButtonHidden,
    isEmoticonsButtonHidden,
    isGifSelected,
    gifRatings,
    isGifButtonHidden,
    onAttachmentClick,
    isAttachmentSelected,
    isAttachmentButtonHidden,
    isPrivateMessageSelected,
    onPrivateMessageClick,
    isPrivatePostButtonHidden,
    isDisabled,
  ]);

  const ButtonContent = useMemo(() => {
    return iconButtonWrapperContent
      .filter((iconButton) => !iconButton.isHidden)
      .map((iconButton) => {
        if (iconButton.icon === Icon.GIF_SELECTOR) {
          return (
            <GifSelector
              onGifSelect={onGifSelectorClick}
              xAxisOffset={-180}
              yAxisOffset={185}
              key={iconButton.toolTipText}
              gifRatings={gifRatings}
            >
              {({ togglePopover, isPopoverOpen }) => {
                return (
                  <IconButtonWrapper
                    disabled={isGifSelected || iconButton.isDisabled}
                    size={ButtonSize.Small}
                    rounded
                    icon={iconButton.icon}
                    toolTipContent={
                      <ToolTipComponent
                        text={
                          isGifSelected
                            ? REMOVE_GIF_TO_SWITCH
                            : iconButton.toolTipText
                        }
                      />
                    }
                    iconBgColor={iconButton.isDisabled ? 'white' : ''}
                    title={iconButton.toolTipText}
                    onClick={togglePopover}
                    isSelected={!!(isPopoverOpen || iconButton.isSelected)}
                  />
                );
              }}
            </GifSelector>
          );
        }
        if (iconButton.icon === Icon.EMOTICON) {
          return (
            <StyledEmoticonPickerPopover
              onEmoticonSelect={onEmoticonClick}
              xAxisOffset={-180}
              yAxisOffset={185}
              key={iconButton.toolTipText}
            >
              {({ togglePopover, isPopoverOpen }) => (
                <IconButtonWrapper
                  disabled={iconButton.isDisabled}
                  size={ButtonSize.Small}
                  rounded
                  icon={iconButton.icon}
                  toolTipContent={
                    <ToolTipComponent text={iconButton.toolTipText} />
                  }
                  title={iconButton.toolTipText}
                  onClick={togglePopover}
                  isSelected={!!(isPopoverOpen || iconButton.isSelected)}
                  iconBgColor={iconButton.isDisabled ? 'white' : ''}
                />
              )}
            </StyledEmoticonPickerPopover>
          );
        }
        return (
          <IconButtonWrapper
            disabled={iconButton?.isDisabled || false}
            size={ButtonSize.Small}
            rounded
            icon={iconButton.icon}
            onClick={iconButton.onClick}
            toolTipContent={<ToolTipComponent text={iconButton.toolTipText} />}
            title={iconButton.toolTipText}
            isSelected={iconButton.isSelected && iconButton.isSelected}
            key={iconButton.toolTipText}
            iconBgColor={iconButton.isDisabled ? 'white' : ''}
          />
        );
      });
  }, [
    gifRatings,
    iconButtonWrapperContent,
    isGifSelected,
    onEmoticonClick,
    onGifSelectorClick,
  ]);

  const renderTrophiesInput = useMemo(() => {
    if (postType === PostType.Comment && showAddTrophy) {
      return (
        <Flex>
          <StyledTrophiesInput
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            maxValue={maxValue}
            buttonText={buttonText}
            assemblyCurrency={assemblyCurrency}
            menuItems={menuItems}
            splitBy={splitBy}
            canAllowCustomAmount={canAllowCustomAmount}
            onItemClick={onItemClick}
            buttonColor={buttonColor}
          />
          <StyledToolTip>
            <TooltipContent>{updatedTrophiesInputTooltip}</TooltipContent>
          </StyledToolTip>
        </Flex>
      );
    }
    return null;
  }, [
    postType,
    showAddTrophy,
    updatedTrophiesInputTooltip,
    buttonText,
    assemblyCurrency,
    value,
    maxValue,
    splitBy,
    menuItems,
    canAllowCustomAmount,
    buttonColor,
    onChange,
    onBlur,
    onItemClick,
  ]);

  return (
    <Wrapper
      postType={postType}
      showAddTrophy={showAddTrophy}
      className={className}
    >
      {renderTrophiesInput}
      <ButtonContentFlexWrapper>{ButtonContent}</ButtonContentFlexWrapper>
      {postType === PostType.Recognition && (
        <Button
          size="small"
          onClick={onPostClick}
          disabled={disabled}
          isLoading={isCreatingPostOrComment}
        >
          {isPrivateMessageSelected ? PostPrivateButtonText : PostButtonText}
        </Button>
      )}
    </Wrapper>
  );
};

const MemoizedGiveRecognitionFormBottomButtons = memo(
  GiveRecognitionFormBottomButtons,
);
MemoizedGiveRecognitionFormBottomButtons.displayName =
  'GiveRecognitionFormBottomButtons';

export default MemoizedGiveRecognitionFormBottomButtons;
