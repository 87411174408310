export const feedSearchPlaceholderLanguage = {
  PAID: 'Search your Assembly',
  FREE_ADMIN: 'Upgrade to search Assembly',
  FREE_NON_ADMIN: 'Premium Feature • Search Assembly',
  USER: (profileString: string) => `Search ${profileString} profile`,
};

export const UpgradeButton = {
  DEFAULT: 'Upgrade',
};
