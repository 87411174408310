// @ts-nocheck TODO: type issues need to be fixed in this file
export const companyType = [
  {
    value: 1,
    name: 'Aerospace',
  },
  {
    value: 2,
    name: 'Biotech',
  },
  {
    value: 3,
    name: 'Cargo & Freight',
  },
  {
    value: 4,
    name: 'Cause/Charity',
  },
  {
    value: 5,
    name: 'Community',
  },
  {
    value: 6,
    name: 'Consulting Agency',
  },
  {
    value: 7,
    name: 'Education',
  },
  {
    value: 8,
    name: 'Energy',
  },
  {
    value: 9,
    name: 'Government',
  },
  {
    value: 10,
    name: 'Health/Beauty',
  },
  {
    value: 11,
    name: 'Human Resources',
  },
  {
    value: 12,
    name: 'Insurance',
  },
  {
    value: 13,
    name: 'Internet',
  },
  {
    value: 14,
    name: 'Labor Union',
  },
  {
    value: 15,
    name: 'Media/News',
  },
  {
    value: 16,
    name: 'Motor Vehicle',
  },
  {
    value: 17,
    name: 'Nonprofit',
  },
  {
    value: 18,
    name: 'Retail',
  },
  {
    value: 19,
    name: 'Science',
  },
  {
    value: 20,
    name: 'Technology',
  },
  {
    value: 21,
    name: 'Telecommunication',
  },
  {
    value: 22,
    name: 'Travel',
  },
  {
    value: 23,
    name: 'Other',
  },
];
export const companySize = [
  {
    value: 1,
    name: '1 - 10',
  },
  {
    value: 2,
    name: '11 - 20',
  },
  {
    value: 3,
    name: '21 - 50',
  },
  {
    value: 4,
    name: '51 - 100',
  },
  {
    value: 5,
    name: '101 - 250',
  },
  {
    value: 6,
    name: '251 - 500',
  },
  {
    value: 7,
    name: '501 - 1000',
  },
  {
    value: 8,
    name: '1000+',
  },
];
export const companyRoles = [
  {
    value: 1,
    name: 'Administrative',
  },
  {
    value: 2,
    name: 'C-Level',
  },
  {
    value: 3,
    name: 'Director',
  },
  {
    value: 4,
    name: 'Human Resources',
  },
  {
    value: 5,
    name: 'IT',
  },
  {
    value: 6,
    name: 'Legal',
  },
  {
    value: 7,
    name: 'Management',
  },
  {
    value: 8,
    name: 'Owner',
  },
  {
    value: 9,
    name: 'Other',
  },
];

export const EmployeeDepartments = [
  {
    value: 1,
    name: 'Administration',
  },
  {
    value: 2,
    name: 'Business Development',
  },
  {
    value: 24,
    name: 'BMO',
  },
  {
    value: 3,
    name: 'C-Level Management',
  },
  {
    value: 4,
    name: 'Communications & PR',
  },
  {
    value: 5,
    name: 'Customer Service',
  },
  {
    value: 6,
    name: 'Data & Analytics',
  },
  {
    value: 7,
    name: 'Design & User Experience',
  },
  {
    value: 8,
    name: 'Engineering',
  },
  {
    value: 26,
    name: 'Facilities Support Services',
  },
  {
    value: 9,
    name: 'Finance',
  },
  {
    value: 10,
    name: 'Human Resources',
  },
  {
    value: 11,
    name: 'Inventory',
  },
  {
    value: 12,
    name: 'IT',
  },
  {
    value: 13,
    name: 'Legal',
  },
  {
    value: 14,
    name: 'Licensing',
  },
  {
    value: 15,
    name: 'Marketing',
  },
  {
    value: 16,
    name: 'Operations',
  },
  {
    value: 17,
    name: 'Product Management',
  },
  {
    value: 18,
    name: 'Production',
  },
  {
    value: 25,
    name: 'Project',
  },
  {
    value: 19,
    name: 'Purchasing',
  },
  {
    value: 20,
    name: 'Quality Asurance',
  },
  {
    value: 21,
    name: 'Research & Development',
  },
  {
    value: 22,
    name: 'Sales',
  },
  {
    value: 23,
    name: 'Strategy',
  },
];

export const EmployeeDepartmentsWithNone = [
  {
    value: 0,
    name: 'None',
  },
  ...EmployeeDepartments,
];

export const UpdatedEmployeeDepartments = [
  {
    id: 'category-one',
    items: [
      {
        id: 1,
        value: 'Administration',
        isSelected: false,
      },
      {
        id: 2,
        value: 'Business Development',
        isSelected: false,
      },
      {
        id: 24,
        value: 'BMO',
        isSelected: false,
      },
      {
        id: 3,
        value: 'C-Level Management',
        isSelected: false,
      },
      {
        id: 4,
        value: 'Communications & PR',
        isSelected: false,
      },
      {
        id: 5,
        value: 'Customer Service',
        isSelected: false,
      },
      {
        id: 6,
        value: 'Data & Analytics',
        isSelected: false,
      },
      {
        id: 7,
        value: 'Design & User Experience',
        isSelected: false,
      },
      {
        id: 8,
        value: 'Engineering',
        isSelected: false,
      },
      {
        id: 26,
        value: 'Facilities Support Services',
        isSelected: false,
      },
      {
        id: 9,
        value: 'Finance',
        isSelected: false,
      },
      {
        id: 10,
        value: 'Human Resources',
        isSelected: false,
      },
      {
        id: 11,
        value: 'Inventory',
        isSelected: false,
      },
      {
        id: 12,
        value: 'IT',
        isSelected: false,
      },
      {
        id: 13,
        value: 'Legal',
        isSelected: false,
      },
      {
        id: 14,
        value: 'Licensing',
        isSelected: false,
      },
      {
        id: 15,
        value: 'Marketing',
        isSelected: false,
      },
      {
        id: 16,
        value: 'Operations',
        isSelected: false,
      },
      {
        id: 17,
        value: 'Product Management',
        isSelected: false,
      },
      {
        id: 18,
        value: 'Production',
        isSelected: false,
      },
      {
        id: 25,
        value: 'Project',
        isSelected: false,
      },
      {
        id: 19,
        value: 'Purchasing',
        isSelected: false,
      },
      {
        id: 20,
        value: 'Quality Asurance',
        isSelected: false,
      },
      {
        id: 21,
        value: 'Research & Development',
        isSelected: false,
      },
      {
        id: 22,
        value: 'Sales',
        isSelected: false,
      },
      {
        id: 23,
        value: 'Strategy',
        isSelected: false,
      },
    ],
  },
];

export const EmployeeStatuses = (customAllowance) => [
  {
    value: 1,
    name: 'Normal',
    status: 'normal',
  },
  {
    value: 2,
    name: `Giver (can only give ${customAllowance.NAME_TITLEIZED_PLURAL})`,
    status: 'giver',
  },
  {
    value: 3,
    name: `Receiver (can only receive ${customAllowance.NAME_TITLEIZED_PLURAL})`,
    status: 'receiver',
  },
  {
    value: 4,
    name: `Observer (can’t give or receive ${customAllowance.NAME_TITLEIZED_PLURAL})`,
    status: 'observer',
  },
];
export const EmployeeStatusesValues = [
  'normal',
  'giver',
  'receiver',
  'observer',
];
export const EmployeeAllowances = [
  {
    value: 1,
    name: 'Default',
    status: 'regular',
  },
  {
    value: 2,
    name: 'Custom',
    status: 'custom',
  },
  {
    value: 3,
    name: 'No Allowance',
    status: 'no',
  },
];

export const EmployeeRoles = [
  {
    value: 'admin',
    name: 'Admin',
  },
  {
    value: 'manager',
    name: 'Manager',
  },
  {
    value: 'adminManager',
    name: 'Admin, Manager',
  },
  {
    value: 'employee',
    name: 'Employee',
  },
];

export const EmployeeCarrots = [
  5, 10, 25, 50, 100, 150, 200, 250, 300, 350, 400, 450, 500, 550, 600, 650,
  700, 800, 900, 1000,
];
export const ManagerCarrots = [];

export const ExchangeCarrots = [
  2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 25, 30, 35, 40, 45, 50, 60, 70, 80, 90,
  100,
].concat(Array.from(new Array(90), (val, index) => 110 + index * 10));

export const BirthdayCarrots = Array.from(
  new Array(101),
  (val, index) => index * 5,
);
export const AllowanceIncrease = Array.from(new Array(11), (val, index) => {
  return index * 10;
});
