export enum GifRatingsProps {
  Y = 'y',
  G = 'g',
  PG = 'pg',
  PG_13 = 'pg-13',
  R = 'r',
}

interface UrlProps {
  url: string;
}

interface SizeProps {
  downsized_medium: UrlProps;
  original: UrlProps;
}

export interface EntryProps {
  images: SizeProps;
}

export interface GiphyProps {
  gifRatings: GifRatingsProps;
  onGifSelect: (entry: EntryProps) => void;
}
