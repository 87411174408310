import { useCallback } from 'react';

import { GetStartedControllerProps } from '../type';
import { QuickSetupStep } from '../../QuickSetupController/types';
import { useUpdateQuickSetupStatus } from '../../../../queries/QuickSetup';

import {
  QuickSetupStatusEnum,
  QuickSetupDirectionEnum,
  QuickSetupCalendarStepsEnum,
} from '../../../../queries/QuickSetup/interfaces';
import { trackOnboardingActionEvent } from '../../../../Utils/analytics/onboarding';
import { ACCOUNT_ONBOARDING_EVENTS } from '../../../../Utils/analytics/constants';

const useGetStartedController = (props: GetStartedControllerProps) => {
  const { onQuickSetupStepChange, onCurrentFlowChange } = props;
  const { mutate: mutateQuickSetupStatus } = useUpdateQuickSetupStatus();

  const handleSelectedSetupChange = useCallback(
    (selectedItem: string) => {
      trackOnboardingActionEvent({
        action: ACCOUNT_ONBOARDING_EVENTS.SELECT_PATH,
        path: selectedItem,
      });
      switch (selectedItem) {
        case 'engage_Team':
          onQuickSetupStepChange(QuickSetupStep.COMPLETION);
          onCurrentFlowChange(selectedItem);
          mutateQuickSetupStatus({
            direction: QuickSetupDirectionEnum.FORWARD,
            status: QuickSetupStatusEnum.SKIPPED,
          });
          break;
        case 'survey_Team':
          onQuickSetupStepChange(QuickSetupStep.SURVEY_TEAM);
          onCurrentFlowChange(selectedItem);
          break;
        case 'superCharge_Meetings':
          mutateQuickSetupStatus({
            direction: QuickSetupDirectionEnum.FORWARD,
            calendar: {
              value: QuickSetupCalendarStepsEnum.CONNECT_CALENDAR,
            },
          });
          onQuickSetupStepChange(QuickSetupStep.CALENDAR_SELECTION);
          onCurrentFlowChange(selectedItem);
          break;
        default:
          onQuickSetupStepChange(QuickSetupStep.COMPLETION);
          onCurrentFlowChange(selectedItem);
      }
    },
    [onCurrentFlowChange, onQuickSetupStepChange, mutateQuickSetupStatus],
  );

  return {
    operations: {
      handleSelectedSetupChange,
    },
  };
};

export default useGetStartedController;
