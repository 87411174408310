/* eslint-disable */
import copy from 'copy-to-clipboard';

export const copyToClipboard = (str: string) => {
  return copy(str);
};

export const isEmpty = (obj: any) =>
  [Object, Array].includes((obj || {}).constructor) &&
  !Object.entries(obj || {}).length;

export const groupBy = (xs: any, key: any) => {
  return xs.reduce(function (rv: any, x: any) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const pick = (object: Record<string, any>, keys: string[]) => {
  return (keys || []).reduce((acc: Record<string, any>, key) => {
    // eslint-disable-next-line no-prototype-builtins
    if (object && object.hasOwnProperty(key)) {
      acc[key] = object[key];
    }
    return acc;
  }, {});
};

export const sortBy = (key: any) => {
  return (a: any, b: any) => (a[key] < b[key] ? 1 : b[key] < a[key] ? -1 : 0);
};

export const isArrayEquals = (a: unknown[] | undefined, b: unknown[] | undefined) : Boolean => {
  return Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((val, index) => val === b[index]);
}
