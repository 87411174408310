import { useCallback, useMemo, useState } from 'react';
import { EditorProps } from '../../atomic/organism/RichTextEditor';
import useGiveRecognitionFormLogic from '../../atomic/organism/GiveRecognitionForm/useGiveRecognitionFormLogic';
import useAtMentions from '../useAtMentions';
import useOnClickOutside from '../useOnOutsideClick';
import {
  GiveRecognitionFormBottomButtonsBaseProps,
  PostType,
} from '../../atomic/molecules/GiveRecognitionFormBottomButtons/interface';
import { checkForProfanity } from '../../Utils/giveCarrots/validation';
import {
  getCanCheckForBadWords,
  getCanShowAddTrophies,
  getCurrentUserAvatar,
  getGifRatings,
  getTrophies,
} from '../../queries/Profile/utils';
import { showErrorMessage } from '../../Utils/flashHandler';
import {
  COMMENT_CREATION_ERROR_MESSAGE,
  PROFANE_ERROR_MESSAGE_COMMENT,
} from '../../languages/en/giveRecognitionForm';
import { PostInputBaseProps } from '../../atomic/organism/PostInput/interface';
import { PostInputProps } from '../../atomic/organism/PostInput';
import { GiveRecognitionTipsProps } from '../../atomic/molecules/GiveRecognitionTips';
import { AddTrophiesInputProps } from '../../atomic/molecules/AddTrophiesInput';
import { DropdownColor } from '../../atomic/molecules/Dropdown_V2/interfaces';
import { GetProfileInfoResponse } from '../../queries/Profile';
import { dismissAllToasts } from '../../Utils/toast';
import useCreatePostComment from '../../queries/Feed/useCreatePostComment';
import { PostTypes } from '../../interfaces/Home';
import { FeedPostUserProps } from '../../interfaces/Feed';

const emptyFunction = () => {};

export type UseCommentInputProps = {
  profileData?: GetProfileInfoResponse;
  postId: string;
  responseId?: string;
  flowId?: string;
  postType: PostTypes;
  trophyReceivers: FeedPostUserProps[];
};

export const useCommentInput = (props: UseCommentInputProps) => {
  const { profileData, postId, postType, flowId, responseId, trophyReceivers } =
    props;
  const giveRecognitionTipsProps: GiveRecognitionTipsProps = useMemo(
    () => ({
      isOpen: false,
      onToggleClick: emptyFunction,
      onToggleKeyPress: emptyFunction,
    }),
    [],
  );

  const { models, operations } = useGiveRecognitionFormLogic();
  const {
    message: commentMessage,
    editorState,
    gifUrl,
    hasText,
    trophiesInputValue,
    refElement,
    containerRef,
    isPrivate: isPrivateComment,
    inputErrorMessage,
    selectedMentions,
  } = models;
  const {
    setEditorState,
    onTrophiesInputChange,
    onAtMentionClick,
    onEmoticonClick,
    onGifSelectorClick,
    onGifRemoveClick,
    onAddMention,
    onEditorFocus,
    onEditorBlur,
    onPrivateMessageClick,
    onAttachmentClick,
    resetInput,
    setInputErrorMessage,
    setEditorFocus,
  } = operations;
  const {
    suggestions,
    onMentionsChange,
    isLoading: isMentionsLoading,
  } = useAtMentions();
  const [isCommentEditorFocused, setCommentEditorFocused] = useState(false);
  const [isOutsideClicked, setIsOutsideClicked] = useState(false);

  const onCommentButtonClick = useCallback(() => {
    setIsOutsideClicked(false);
    if (isOutsideClicked === false) {
      setCommentEditorFocused(true);
      setEditorFocus();
    }
  }, [isOutsideClicked, setEditorFocus]);

  const onOutsideClickHandler = useCallback(() => {
    if (isCommentEditorFocused) {
      setIsOutsideClicked(true);
      setCommentEditorFocused(false);
    }
  }, [isCommentEditorFocused]);
  useOnClickOutside(containerRef, onOutsideClickHandler);

  const addTrophies: AddTrophiesInputProps = useMemo(
    () =>
      getTrophies(
        trophiesInputValue,
        trophyReceivers.filter(
          ({ memberID }) => profileData?.member.memberId !== memberID,
        ).length,
        onTrophiesInputChange,
        profileData,
        DropdownColor.Primary,
      ),
    [onTrophiesInputChange, profileData, trophiesInputValue, trophyReceivers],
  );

  const showAddTrophy = useMemo(() => {
    if (postType === PostTypes.FLOW) {
      return false;
    }
    const canShowAddTrophies = getCanShowAddTrophies(profileData);
    const isCurrentUserOnlyReceiver =
      trophyReceivers.length === 1
        ? trophyReceivers[0].memberID === profileData?.member.memberId
        : false;
    return canShowAddTrophies && !isCurrentUserOnlyReceiver;
  }, [profileData, trophyReceivers, postType]);

  const handleEditorFocus = useCallback(() => {
    onEditorFocus();
    setCommentEditorFocused(true);
  }, [onEditorFocus]);

  const handleCreatePostCommentSuccess = useCallback(() => {
    resetInput();
  }, [resetInput]);

  const handleCreatePostCommentError = useCallback(() => {
    dismissAllToasts();
    showErrorMessage(COMMENT_CREATION_ERROR_MESSAGE);
  }, []);

  const { mutate: createPostComment, isLoading: isCreatingComment } =
    useCreatePostComment(
      handleCreatePostCommentSuccess,
      handleCreatePostCommentError,
    );

  const handlePostClick = useCallback(() => {
    if (
      getCanCheckForBadWords(profileData) &&
      checkForProfanity(commentMessage)
    ) {
      showErrorMessage(PROFANE_ERROR_MESSAGE_COMMENT);
    } else {
      setInputErrorMessage(undefined);
      if (postType === PostTypes.FLOW) {
        createPostComment({
          postId,
          text: commentMessage,
          points: trophiesInputValue > 0 ? trophiesInputValue : undefined,
          gifUrl,
          selectedMentions,
          postType,
          flowId,
          responseId,
        });
      } else {
        createPostComment({
          postId,
          text: commentMessage,
          points: trophiesInputValue > 0 ? trophiesInputValue : undefined,
          gifUrl,
          selectedMentions,
        });
      }
      resetInput();
    }
  }, [
    profileData,
    commentMessage,
    setInputErrorMessage,
    resetInput,
    postType,
    createPostComment,
    postId,
    trophiesInputValue,
    gifUrl,
    selectedMentions,
    flowId,
    responseId,
  ]);

  const postInputBaseProps: PostInputBaseProps = useMemo(
    () => ({
      containerRef,
      currentUser: getCurrentUserAvatar(profileData),
      hasText, // && trophiesInputValue !== AddTrophiesValue.Input,
      isEditorFocused: isCommentEditorFocused,
      showRecognitionTips: false,
      selectedGif: gifUrl,
      inputErrorMessage,
      onGifRemoveClick,
      setEditorFocus,
    }),
    [
      containerRef,
      profileData,
      hasText,
      isCommentEditorFocused,
      gifUrl,
      inputErrorMessage,
      onGifRemoveClick,
      setEditorFocus,
    ],
  );

  const giveRecognitionFormBottomButtonsProps: GiveRecognitionFormBottomButtonsBaseProps =
    useMemo(
      () => ({
        postType: PostType.Comment,
        onAtMentionClick,
        onEmoticonClick,
        onGifSelectorClick,
        onAttachmentClick,
        isGifSelected: gifUrl !== '',
        isPrivateMessageSelected: isPrivateComment,
        onPrivateMessageClick,
        onPostClick: handlePostClick,
        showAddTrophy,
        isCreatingPostOrComment: isCreatingComment,
        isAttachmentButtonHidden: true,
        isPrivatePostButtonHidden: true,
        gifRatings: getGifRatings(profileData),
      }),
      [
        onAtMentionClick,
        onEmoticonClick,
        onGifSelectorClick,
        onAttachmentClick,
        gifUrl,
        isPrivateComment,
        onPrivateMessageClick,
        handlePostClick,
        profileData,
        isCreatingComment,
        showAddTrophy,
      ],
    );

  const editorProps: EditorProps = useMemo(
    () => ({
      editorState,
      editorRefElement: refElement,
      onAddMention,
      onEditorBlur,
      onEditorChange: setEditorState,
      onEditorFocus: handleEditorFocus,
      suggestions,
      onSearchChange: onMentionsChange,
      isMentionsLoading,
    }),
    [
      editorState,
      refElement,
      onAddMention,
      onEditorBlur,
      setEditorState,
      handleEditorFocus,
      suggestions,
      onMentionsChange,
      isMentionsLoading,
    ],
  );
  const commentInputProps: PostInputProps = useMemo(
    () => ({
      ...giveRecognitionTipsProps,
      ...addTrophies,
      ...editorProps,
      ...giveRecognitionFormBottomButtonsProps,
      ...postInputBaseProps,
      onCommentButtonClick,
    }),
    [
      giveRecognitionTipsProps,
      addTrophies,
      editorProps,
      giveRecognitionFormBottomButtonsProps,
      postInputBaseProps,
      onCommentButtonClick,
    ],
  );
  return {
    commentInputProps,
  };
};
// Following are not needed as they are for give recognition tips and are not applicable for comments
