import React from 'react';

import TriggerConfigureSettings from '../../../../atomic/organism/TriggerConfigureSettings';

import { OnDemandControllerTypes } from './types';
import useOnDemandController from './useOnDemandController';

const OnDemandController = (props: OnDemandControllerTypes) => {
  const { models } = useOnDemandController(props);

  const { flowAnswerAnytimeProps, flowDefaultDueDateProps } = models;

  return (
    <div>
      <TriggerConfigureSettings
        triggerType="ONDEMAND"
        flowAnswerAnytimeProps={flowAnswerAnytimeProps}
        flowDefaultDueDateProps={flowDefaultDueDateProps}
      />
    </div>
  );
};

export default OnDemandController;
