import { ReactNode } from 'react';

export enum LegacyFileType {
  Image = 'IMAGE',
  File = 'FILE',
}

export enum FileUploadStatus {
  Loading = 'LOADING',
  Success = 'SUCCESS',
  Error = 'ERROR',
}

export type FileType =
  | 'doc'
  | 'docx'
  | 'jpg'
  | 'jpeg'
  | 'png'
  | 'svg'
  | 'bmp'
  | 'gif'
  | 'ppt'
  | 'pptx'
  | 'pps'
  | 'ppsx'
  | 'pdf'
  | 'xls'
  | 'xlsx'
  | 'csv'
  | 'mp3'
  | 'wav'
  | 'm4a'
  | 'ogg'
  | 'mp4'
  | 'avi'
  | 'mov'
  | 'mpg'
  | 'ogv'
  | '3gp'
  | '3d2'
  | 'zip';

export type File = {
  type: LegacyFileType | FileType;
  name: string;
  url: string;
  size?: number;
  createdAt?: string;
  thumbnails?: {
    360: string;
  };
};

export type FileCardProps = {
  file?: File;
  flowId?: string;
  responseId?: string;
  className?: string;
  errorMessage?: string;
  isParticipation: boolean;
  status: FileUploadStatus;
  loadingProgress?: number;
  onClickClose?: (data: unknown) => void;
  onClickRetry?: (data: unknown) => void;
  onClickDownload?: (data: File) => void;
  onClickPreview?: (data: File) => void;
};

export type FileCardWrapperProps = {
  children: ReactNode;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onClick?: () => void;
  isParticipation: boolean;
  isKnowledgeCenterEnabled?: boolean;
};
