import { AxiosError } from 'axios';
import { useMemo, useEffect, useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import shallow from 'zustand/shallow';
import { TaskHistoryActivity } from '../../../../atomic/molecules/Notebook/TaskHistory/interface';
import {
  LayoutStoreState,
  SelectedMember,
} from '../../../../interfaces/Layout';
import {
  Assignee,
  NotebookTask,
  TaskCategories,
} from '../../../../interfaces/notebook';
import {
  useGetNotebookRightDrawerTaskQuery,
  useGetNotebookTaskAssigneeList,
  useGetNotebookTaskHistoryQuery,
} from '../../../../queries/Notebook';
import { useProfileInfoFetchQuery } from '../../../../queries/Profile';
import useLayoutStore from '../../../../stores/layoutStore';
import useNotebookStore from '../../../../stores/notebookStore';
import { NotebookStore } from '../../../../stores/notebookStore/types';
import { isEmpty } from '../../../../Utils/common';
import { convertAPITaskToTaskAndCategory } from '../../../../Utils/notebook';
import { getAssignmentOperation } from '../../NotebookTasksController/useNotebookTasksController/utils';

const selector = (store: NotebookStore) => store.addSingleNote;
const idMapSelector = (store: NotebookStore) => store.idMap;
const functionSelector = (store: NotebookStore) => ({
  editTaskContent: store.editTaskContent,
  updateTask: store.updateTask,
  currentTab: store.currentTab,
  setModalDataForTabChange: store.setModalDataForTabChange,
});

const layoutSelector = (state: LayoutStoreState) => ({
  setRightAsideOpenToTrue: state.setRightAsideOpenToTrue,
  isRightAsideOpen: state.isRightAsideOpen,
  setSelectedMember: state.setSelectedMember,
  setRightAsideOpenToFalse: state.setRightAsideOpenToFalse,
});

const useNotebookRightDrawerController = () => {
  const { search } = useLocation();
  const selectedTaskId = useMemo(() => {
    const query = new URLSearchParams(search);
    return query.get('taskId');
  }, [search]);
  const idMap = useNotebookStore(idMapSelector);
  const { currentTab, editTaskContent, updateTask, setModalDataForTabChange } =
    useNotebookStore(functionSelector, shallow);
  const taskId = useMemo(
    () => (selectedTaskId ? idMap[selectedTaskId] : undefined),
    [idMap, selectedTaskId],
  );

  const { setRightAsideOpenToTrue, isRightAsideOpen, setSelectedMember } =
    useLayoutStore(layoutSelector, shallow);

  const note: NotebookTask | undefined = useNotebookStore(
    useCallback((store) => store.allNotes[taskId || 0], [taskId]),
  );
  const addSingleNote = useNotebookStore(selector);
  const { data: profileInfo } = useProfileInfoFetchQuery();
  const [historyLogSort, setLogSort] = useState('desc');
  const [taskIdFocused, setTaskIdOfFocusedTask] = useState<string>('');
  const [searchValueForAssignees, setSearchValueForAssignees] =
    useState<string>('');

  const shouldMakeAPICall = Boolean(selectedTaskId) && note === undefined;

  const [isAccessError, setAccessError] = useState(false);

  const handleAccessError = useCallback((error: unknown) => {
    const axiosError = error as AxiosError;
    if (
      axiosError?.response?.status === 409 &&
      axiosError?.response?.data?.message !== 'MISSING_REQUIRED_PARAMETERS'
    ) {
      setAccessError(true);
    }
  }, []);

  const { data, isLoading, isError } = useGetNotebookRightDrawerTaskQuery(
    selectedTaskId || '',
    shouldMakeAPICall,
    handleAccessError,
  );

  const toggleHistoryLogSort = useCallback(
    () => setLogSort(historyLogSort === 'asc' ? 'desc' : 'asc'),
    [historyLogSort],
  );

  useEffect(() => {
    if (selectedTaskId && !isRightAsideOpen) {
      setRightAsideOpenToTrue();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    data: taskHistoryData,
    isLoading: isTaskHistoryLoading,
    hasNextPage: hasMoreLogsToFetch,
    isFetchingNextPage: isFetchingMoreLogs,
    fetchNextPage: fetchMoreLogs,
  } = useGetNotebookTaskHistoryQuery(selectedTaskId || '', historyLogSort);

  const taskHistoryList = useMemo(() => {
    if (taskHistoryData) {
      return taskHistoryData.pages.reduce<TaskHistoryActivity[]>(
        (arr, page) => [...arr, ...page.data],
        [],
      );
    }
    return [];
  }, [taskHistoryData]);

  const {
    data: assigneesListData,
    hasNextPage: hasMoreAssigneesToFetch,
    isFetchingNextPage: isFetchingMoreAssignees,
    fetchNextPage: fetchMoreAssignees,
  } = useGetNotebookTaskAssigneeList(
    taskIdFocused,
    !isEmpty(taskIdFocused),
    searchValueForAssignees,
  );

  const assigneesList = useMemo(() => {
    if (!isEmpty(taskIdFocused)) {
      if (assigneesListData) {
        const { pages } = assigneesListData;
        return pages.reduce<Assignee[]>(
          (arr, page) => [...arr, ...page.data],
          [],
        );
      }
    }
  }, [assigneesListData, taskIdFocused]);

  const currentUserTimezone = useMemo(
    () => profileInfo?.member.timeZone || '',
    [profileInfo?.member.timeZone],
  );

  useEffect(() => {
    if (data && !note) {
      addSingleNote(
        convertAPITaskToTaskAndCategory(data, currentUserTimezone)[0],
      );
    }
  }, [data, addSingleNote, note, currentUserTimezone]);

  useEffect(() => {
    if (selectedTaskId && !isRightAsideOpen) {
      setRightAsideOpenToTrue();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onUpdateTask = useCallback(
    (updatedTask: NotebookTask, previousCategory: TaskCategories) => {
      if (profileInfo) {
        const { memberId } = profileInfo.member;
        updateTask(updatedTask, previousCategory, memberId);
      }
    },
    [profileInfo, updateTask],
  );

  const handleAssigneeChange = useCallback(
    (task: NotebookTask, oldAssignee: Assignee, newAssignee: Assignee) => {
      const operation = getAssignmentOperation(oldAssignee, newAssignee);
      setModalDataForTabChange(
        true,
        profileInfo?.member?.memberId,
        operation,
        task,
        () => {
          onUpdateTask(task, task?.type);
        },
      );
    },
    [setModalDataForTabChange, profileInfo?.member?.memberId, onUpdateTask],
  );

  const handleUserClick = useCallback(
    (memberData: SelectedMember) => {
      setSelectedMember(memberData);
      if (!isRightAsideOpen) {
        setRightAsideOpenToTrue();
      }
    },
    [isRightAsideOpen, setRightAsideOpenToTrue, setSelectedMember],
  );

  return {
    toggleHistoryLogSort,
    historyLogSort,
    currentUserId: profileInfo?.member.memberId || '',
    onUpdateTask,
    editTaskContent,
    fetchMoreAssignees,
    handleAssigneeChange,
    setTaskIdOfFocusedTask,
    setSearchValueForAssignees,
    selectedTaskId,
    currentTab,
    isLoading,
    assigneesList,
    hasMoreAssigneesToFetch,
    isFetchingMoreAssignees,
    searchValueForAssignees,
    note,
    hasMoreLogsToFetch,
    isFetchingMoreLogs,
    handleUserClick,
    fetchMoreLogs,
    currentUserTimezone,
    isTaskHistoryLoading,
    taskHistoryList,
    isError,
    isAccessError,
  };
};

export default useNotebookRightDrawerController;
