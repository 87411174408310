import styled from 'styled-components';

import Body from '../../atoms/Body';
import ThemeV2 from '../../../componentsV2/theme';
import { Flex } from '../../../Utils/styles/display';

// interface StyledButtonProps {
//   color: 'primary' | 'secondary';
//   size: 'large' | 'medium' | 'small';
//   status: 'default' | 'success' | 'warning' | 'info';
//   variant: 'contained' | 'outlined' | 'text';
// }

export const StyledFlexWrapper = styled(Flex)`
  margin-bottom: 16px;
`;

export const StyledFlex = styled(Flex)``;

export const ImageUploaderWrapper = styled.div`
  height: 40px;
`;

export const StyledChooseFile = styled(Body)`
  display: flex;
  align-items: center;
  text-align: center;
  height: 32px;
  color: ${ThemeV2.palette.geekBlue6};
  padding: 4px 16px;
  background-color: ${ThemeV2.palette.gray1};
  box-shadow: rgba(0, 0, 0, 0.04) 0px 2px 4px, rgba(0, 0, 0, 0.02) 0px 3px 4px,
    rgba(0, 0, 0, 0.12) 0px 1px 5px;
  border-radius: 4px;
  margin-right: 8px;
  transition: transform 0.25s ease 0s, box-shadow;
  &:hover {
    color: ${ThemeV2.palette.geekBlue5};
  }
  &:active {
    box-shadow: rgb(0 0 0 / 14%) 0px 0px 2px, rgb(0 0 0 / 12%) 0px 2px 2px,
      rgb(0 0 0 / 20%) 0px 1px 3px;
    transform: scale(0.95);
  }
`;

export const StyledRemoveIcon = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
  cursor: pointer;
  transition: all 0.5ms;
`;

export const StyledAvatarWrapper = styled.div<{ hasMedia: boolean }>`
  display: block;
  padding-bottom: 4px;
  position: relative;
  width: 72px;
  height: 72px;
  border-radius: 100%;
  margin-right: 16px;
  &:hover {
    ${StyledRemoveIcon} {
      ${({ hasMedia }) => hasMedia && `display: flex`};
    }
  }
`;
