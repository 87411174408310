import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { ANSWER_FLOW, FLOWS, SIGN_IN, V2_HOME } from '../../constants/routes';

export const useMemberAuthenticated = () => {
  const { push } = useHistory();

  const { pathname, search } = useLocation();
  const isAuthenticated = useSelector(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //  @ts-ignore
    (state) => state?.auth?.authenticated || false,
  );

  useEffect(() => {
    if (!isAuthenticated) {
      let path = pathname;
      if (search) {
        path = `${path}${search}`;
      }

      if (path.startsWith(FLOWS)) {
        push(`${path}${ANSWER_FLOW}?isRedirected=true`);
        return;
      }

      push({
        pathname: SIGN_IN,
        state: { origin: path },
      });
    }
  }, [isAuthenticated, pathname, search, push]);
  return isAuthenticated;
};

export const useMemberNotAuthenticated = () => {
  const { push } = useHistory();
  const isAuthenticated = useSelector(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //  @ts-ignore
    (state) => state?.auth?.authenticated || false,
  );

  useEffect(() => {
    if (isAuthenticated) {
      push(V2_HOME);
    }
  }, [isAuthenticated, push]);
  return !isAuthenticated;
};
