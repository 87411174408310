import React, { useMemo, useCallback } from 'react';

import NewTextfield from '../../../molecules/NewTextfield';
import { Category } from '../../../molecules/RequestTemplateLeftColumn/types';

import BlockSection from '../../BlockSection';
import { TemplateSelectionProps } from './type';
import { Flex } from '../../../../Utils/styles/display';
import {
  StyledAutocomplete,
  TemplateCardsWrapper,
  StyledFlowRequestTemplateCard,
  DropdownLoader,
  TemplateCardLoader,
  StyledEmptyTemplateState,
} from './styles';
import ThemeV2 from '../../../../componentsV2/theme';

import searching from '../../../../img/searching.svg';
import { AutocompleteDropdownItem } from '../../Autocomplete/interfaces';
import { quickSetupTemplateSelectionScreen } from '../../../../languages/en/quickSetup';
import ContentLoader from 'react-content-loader';
import { HeadingType } from '../../../molecules/EmptyErrorStateTemplate/interfaces';
import { FLOW_REQUEST_MODAL_LOADING_STATES } from '../../../../languages/en/flows/requestFlowModal';

const TemplateSelection = (props: TemplateSelectionProps) => {
  const {
    value,
    onChange,
    isLoading,
    categories,
    meetingName,
    selectedCategory,
    onCategoryChange,
    templateCardItems,
    onPreviewFlowClick,
  } = props;

  const handleCategoryChange = useCallback(
    (category: AutocompleteDropdownItem<string | number, unknown> | null) => {
      onCategoryChange(category as Category);
    },
    [onCategoryChange],
  );

  const TemplateSelectionChildren = useMemo(() => {
    return (
      <>
        <Flex width="100%" alignItems="center">
          <NewTextfield
            value={value}
            icon="search"
            onChange={(e) => onChange(e.target.value)}
            placeholder={
              quickSetupTemplateSelectionScreen.searchTemplatesPlaceHolder
            }
          />
          <StyledAutocomplete
            options={categories}
            showClearIcon={false}
            onChange={handleCategoryChange}
            value={selectedCategory || null}
            dataTestId="quickSetupCategorySelectAutoComplete"
            label={quickSetupTemplateSelectionScreen.useCasesPlaceHolder}
          />
        </Flex>
        {templateCardItems.length === 0 && (
          <StyledEmptyTemplateState
            variant={HeadingType.Regular}
            image={searching}
            heading={FLOW_REQUEST_MODAL_LOADING_STATES.NOTFOUND.HEADING}
            subHeading={FLOW_REQUEST_MODAL_LOADING_STATES.NOTFOUND.SUB_HEADING}
          />
        )}
        <TemplateCardsWrapper>
          {templateCardItems.map((templateCardItem) => (
            <StyledFlowRequestTemplateCard
              key={templateCardItem.templateId}
              onPreviewFlowClick={() => {
                onPreviewFlowClick(templateCardItem);
              }}
              {...templateCardItem}
              canShowParticipants={false}
            />
          ))}
        </TemplateCardsWrapper>
      </>
    );
  }, [
    value,
    onChange,
    categories,
    selectedCategory,
    templateCardItems,
    onPreviewFlowClick,
    handleCategoryChange,
  ]);

  const LoadingComponent = () => {
    return (
      <div>
        <Flex margin="0 0 24px 0">
          <ContentLoader
            speed={2}
            width={330}
            height={40}
            viewBox="0 0 330 40"
            backgroundColor={ThemeV2.palette.gray4}
            foregroundColor={ThemeV2.palette.gray3}
            data-testid="header-loader"
          >
            <rect x="0" y="0" rx="4" ry="4" width="330" height="40" />
          </ContentLoader>

          <DropdownLoader
            speed={2}
            width={156}
            height={40}
            viewBox="0 0 156 40"
            backgroundColor={ThemeV2.palette.gray4}
            foregroundColor={ThemeV2.palette.gray3}
            data-testid="header-loader"
          >
            <rect x="0" y="0" rx="4" ry="4" width="156" height="40" />
          </DropdownLoader>
        </Flex>
        <Flex>
          <ContentLoader
            speed={2}
            width={239}
            height={180}
            viewBox="0 0 239 180"
            backgroundColor={ThemeV2.palette.gray4}
            foregroundColor={ThemeV2.palette.gray3}
            data-testid="header-loader"
          >
            <rect x="0" y="0" rx="4" ry="4" width="239" height="180" />
          </ContentLoader>
          <TemplateCardLoader
            speed={2}
            width={239}
            height={180}
            viewBox="0 0 239 180"
            backgroundColor={ThemeV2.palette.gray4}
            foregroundColor={ThemeV2.palette.gray3}
            data-testid="header-loader"
          >
            <rect x="0" y="0" rx="4" ry="4" width="239" height="180" />
          </TemplateCardLoader>
        </Flex>
      </div>
    );
  };

  const headingComponent = useMemo(() => {
    return quickSetupTemplateSelectionScreen.getHeadingText(meetingName);
  }, [meetingName]);

  return (
    <BlockSection
      headingVariant="h4"
      subHeadingColor="gray8"
      subHeadingVariant="body1"
      heading={headingComponent}
      subHeading={quickSetupTemplateSelectionScreen.subHeadingText}
    >
      {isLoading ? <LoadingComponent /> : TemplateSelectionChildren}
    </BlockSection>
  );
};

export default TemplateSelection;
