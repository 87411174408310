/* eslint-disable max-len */
export const KIOSK_MODE_LABEL = `Kiosk mode`;
export const LOG_OUT_NOW_LABEL = `log out now`;
export const TURN_ON_KIOSK_LABEL = `Turn on Kiosk`;
export const TURN_OFF_KIOSK_LABEL = `Turn off Kiosk`;
export const DEVICE_LEAVING_KIOSK_MODE_LABEL = `This device is leaving`;
export const DEVICE_ENTERING_KIOSK_MODE_LABEL = `This device is entering`;
export const REDIRECT_FROM_KIOSK_PAGE_LABEL = `One moment while we redirect you to Assembly’s home page...`;
export const KIOSK_INFO_MESSAGE_WITH_LOGOUT = `This device is in kiosk mode. You will be logged out after 5 minutes of inactivity or`;
export const KIOSK_INFO_MESSAGE_WITHOUT_LOGOUT = `This device is in kiosk mode. You will be logged out after 5 minutes of inactivity`;
export const KIOSK_MODE_DISPLAY_TEXT = `Use kiosk mode to allow multiple users to safely use Assembly in one window on one single device.`;
