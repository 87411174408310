import styled from 'styled-components';
import ThemeV2 from '../../../componentsV2/theme';

export const StyledWrapper = styled.div`
  padding: 4px 0;
  width: 100%;
`;

export const SettingsWrapper = styled.div`
  background: ${ThemeV2.palette.gray1};
  padding: 24px 24px 16px;
`;

export const FormWrapper = styled.div`
  [role='button'] {
    max-width: 75%;
  }
`;
