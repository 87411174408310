/* eslint-disable max-len */
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import pluralize from 'pluralize';
import isEmpty from 'lodash/isEmpty';
import {
  AddBlockButton,
  StyledDropdownCard,
  StyledDropdownCategoryLabel,
  StyledTitleTextField,
} from './styles';
import { FlowsEditorProps } from './types';

import {
  DELETING_GIVE_POINTS_STACK_INFO,
  DELETING_LINKED_PERSON_SELECTOR_INFO,
  DELETING_GIVE_POINTS_STACK_TITLE,
  FORMS_EDITOR,
  NO_RESULTS,
} from '../../../../languages/en/flows/builder';
import {
  BLOCK,
  CANCEL,
  CONTAINS,
  DELETE,
} from '../../../../languages/en/singleWords';
import { getGivePointsStack, getMenuItems } from './data';
import {
  DropdownPlacement,
  MenuItemIndividualItem,
} from '../../../molecules/Dropdown_V2/interfaces';
import useOnClickOutside from '../../../../hooks/useOnOutsideClick';
// eslint-disable-next-line max-len
import FormsEditorScaleInputBlock from '../../FlowsBuilderInputBlocks/FormsEditorScaleInputBlock';
// eslint-disable-next-line max-len
import FormsEditorOpenEndedInputBlock from '../../FlowsBuilderInputBlocks/FormsEditorOpenEndedInputBlock';
import useToggle from '../../../../hooks/useToggle';
// eslint-disable-next-line max-len
import FormsEditorFileUploadBlock from '../../FlowsBuilderInputBlocks/FormsEditorFileUploadBlock';
import FormsEditorPersonSelectorBlock from '../../FlowsBuilderInputBlocks/FormsEditorPersonSelectorBlock';
import FormsEditorMultiChoiceInputBlock from '../../FlowsBuilderInputBlocks/FormsEditorMultiChoiceInputBlock';
import FormsEditorGifSelectorBlock from '../../FlowsBuilderInputBlocks/FormsEditorGifSelectorBlock';
import FormsEditorDropdownInputBlock from '../../FlowsBuilderInputBlocks/FormsEditorDropdownInputBlock';
import FormsEditorGivePointsInputBlock from '../../FlowsBuilderInputBlocks/FormsEditorGivePointsInputBlock';
import BasicModalTemplate from '../../../templates/BasicModalTemplate';
import Body from '../../../atoms/Body';
import { ContentBlockState } from '../../../../interfaces/Flow/Builder';
import { StyledDropdownListItem } from '../../../molecules/Dropdown_V2/styles';
import { Flex } from '../../../../Utils/styles/display';

const FlowsEditor = (props: FlowsEditorProps) => {
  const {
    blocks,
    onDeleteClick,
    onMoveUpClick,
    onMoveDownClick,
    onAddBlockClick,
    onBlockValueChange,
    canShowGivePointsStack,
  } = props;
  const addBlockButtonRef = useRef(null);
  const {
    models: { toggleValue: isDropDownOpen },
    operations: {
      setToggleToFalse: setIsDropDownOpenToFalse,
      setToggleToTrue: setIsDropDownOpenToTrue,
    },
  } = useToggle(false);

  const menuItems = getMenuItems(canShowGivePointsStack);

  const {
    models: { toggleValue: showDeleteModal },
    operations: {
      setToggleToFalse: setIsDeleteModalOpenToFalse,
      setToggleToTrue: setIsDeleteModalOpenToTrue,
    },
  } = useToggle(false);

  const [dropdownItems, setDropdownItems] = useState(menuItems);

  useEffect(() => {
    const hasGivePointsStack =
      (blocks || []).filter(
        (blockItem) => blockItem.type === 'GIVE_POINTS_STACK',
      ).length > 0;
    let updatedDropdownItems = menuItems;
    if (hasGivePointsStack) {
      updatedDropdownItems = menuItems.map((menuItem) => {
        if (menuItem.id === 'advanced-collect') {
          return {
            ...menuItem,
            items: [getGivePointsStack(true)],
          };
        }

        return menuItem;
      });
    }
    setDropdownItems(updatedDropdownItems);
  }, [blocks, menuItems]);

  const [searchText, setSearchText] = useState('');
  const [blockForDeletion, setBlockForDeletion] = useState({
    blockId: '',
    blockType: '',
    blockText: '',
  });

  const handleDelete = (
    blockId: string,
    blockType: string,
    isLinkedBlock = false,
  ) => {
    if (blockType === 'GIVE_POINTS_STACK' || isLinkedBlock) {
      const blockText = isLinkedBlock
        ? DELETING_LINKED_PERSON_SELECTOR_INFO
        : DELETING_GIVE_POINTS_STACK_INFO;
      setBlockForDeletion({ blockId, blockType, blockText });
      setIsDeleteModalOpenToTrue();
    } else {
      onDeleteClick(blockId, blockType, false);
    }
  };

  const handleDeletionModalClose = () => {
    setIsDeleteModalOpenToFalse();
    onDeleteClick(blockForDeletion?.blockId, blockForDeletion?.blockType, true);
    setBlockForDeletion({ blockId: '', blockType: '', blockText: '' });
  };

  const handleOnOutSideClick = useCallback(() => {
    setIsDropDownOpenToFalse();
  }, [setIsDropDownOpenToFalse]);

  const handleOnAddButtonClick = useCallback(() => {
    setIsDropDownOpenToTrue();
  }, [setIsDropDownOpenToTrue]);

  const handleSearchTextChange = useCallback(
    (args: ChangeEvent<HTMLInputElement>) => {
      setSearchText(args.target.value);
    },
    [setSearchText],
  );

  const handleMenuItemClick = useCallback(
    (id: number | string) => {
      const selectedItem = dropdownItems
        .map((x) => x.items)
        .flat()
        .find((x) => x.id === id);

      if (selectedItem) {
        onAddBlockClick(selectedItem);
      }
    },
    [onAddBlockClick, dropdownItems],
  );

  useOnClickOutside(addBlockButtonRef, handleOnOutSideClick);

  const renderDropdownItems = useCallback(() => {
    const condition = (item: MenuItemIndividualItem) =>
      searchText.trim() === '' ||
      item.value.toLocaleLowerCase().includes(searchText.toLocaleLowerCase());

    const dropdownFilteredResults =
      dropdownItems &&
      dropdownItems.filter(
        (dropdownItem) =>
          dropdownItem.category &&
          dropdownItem.items.length > 0 &&
          dropdownItem.items.some(condition),
      );
    if (isEmpty(dropdownFilteredResults)) {
      return (
        <StyledDropdownListItem
          key="noResults"
          value={NO_RESULTS}
          id="noResults"
          disabled
        />
      );
    }
    return (
      dropdownFilteredResults &&
      dropdownFilteredResults.map((dropdownItem) => (
        <div key={dropdownItem.id}>
          {dropdownItem.category && dropdownItem.items.some(condition) && (
            <StyledDropdownCategoryLabel variant="body3">
              {dropdownItem.category}
            </StyledDropdownCategoryLabel>
          )}
          {dropdownItem.items.filter(condition).map((item) => (
            <StyledDropdownListItem
              key={item.id}
              value={item.value}
              id={item.id}
              prefixIcon={item.prefixIcon}
              onItemClick={handleMenuItemClick}
              disabled={item.disabled || false}
            />
          ))}
        </div>
      ))
    );
  }, [handleMenuItemClick, searchText, dropdownItems]);

  const renderInputBlocks = (
    block: ContentBlockState,
    currentIndex: number,
  ) => {
    const handleToggleIsRequired = () => {
      onBlockValueChange({
        ...block,
        isRequired: !block.isRequired,
      });
    };

    const handleToggleIsDescriptionNeeded = () => {
      onBlockValueChange({
        ...block,
        description: block.description === null ? '' : null,
      });
    };

    const commonBlockProperties = {
      handleToggleIsRequired,
      handleToggleIsDescriptionNeeded,
      onDeleteClick: handleDelete,
      onMoveUpClick,
      onMoveDownClick,
      currentIndex,
      totalNumberOfItems: blocks.length,
      onValueChange: onBlockValueChange,
      key: block.id,
    };

    switch (block.type) {
      case 'SCALE': {
        return (
          <FormsEditorScaleInputBlock
            {...commonBlockProperties}
            blockData={block}
          />
        );
      }

      case 'OPEN_ENDED': {
        return (
          <FormsEditorOpenEndedInputBlock
            blockData={block}
            {...commonBlockProperties}
          />
        );
      }

      case 'FILE_UPLOAD': {
        return (
          <FormsEditorFileUploadBlock
            blockData={block}
            {...commonBlockProperties}
          />
        );
      }

      case 'PERSON_SELECTOR': {
        return (
          <FormsEditorPersonSelectorBlock
            blockData={block}
            {...commonBlockProperties}
          />
        );
      }

      case 'MULTI_CHOICE': {
        return (
          <FormsEditorMultiChoiceInputBlock
            blockData={block}
            {...commonBlockProperties}
          />
        );
      }
      case 'GIF': {
        return (
          <FormsEditorGifSelectorBlock
            blockData={block}
            {...commonBlockProperties}
          />
        );
      }
      case 'DROPDOWN': {
        return (
          <FormsEditorDropdownInputBlock
            blockData={block}
            {...commonBlockProperties}
          />
        );
      }

      case 'GIVE_POINTS_STACK': {
        return (
          <FormsEditorGivePointsInputBlock
            blockData={block}
            {...commonBlockProperties}
          />
        );
      }

      default:
        return null;
    }
  };

  return (
    <>
      {blocks.length > 0 &&
        blocks.map((block, index) => renderInputBlocks(block, index))}

      <Flex justifyContent="space-between">
        <AddBlockButton
          icon="plus"
          variant="text"
          color="primary"
          ref={addBlockButtonRef}
          onClick={handleOnAddButtonClick}
        >
          {!isDropDownOpen &&
            (blocks.length === 0
              ? FORMS_EDITOR.ADD_BLOCK_LABEL
              : FORMS_EDITOR.ADD_ANOTHER_BLOCK_LABEL)}

          {isDropDownOpen && (
            <StyledTitleTextField
              autoFocus
              placeholder="Search blocks"
              value={searchText}
              onChange={handleSearchTextChange}
              data-testid="search-blocks-input"
            />
          )}
        </AddBlockButton>
        {addBlockButtonRef && addBlockButtonRef.current && (
          <StyledDropdownCard
            isFullWidth
            id="drop-down-card"
            isOpen={isDropDownOpen}
            anchorEl={addBlockButtonRef.current}
            dropdownPlacement={DropdownPlacement.BottomStart}
          >
            {renderDropdownItems()}
          </StyledDropdownCard>
        )}
        {blocks.length > 0 && (
          <Body variant="body2" color="gray7">
            {CONTAINS} {blocks.length}{' '}
            {blocks.length === 1
              ? BLOCK.toLowerCase()
              : pluralize(BLOCK.toLowerCase())}
          </Body>
        )}
        <BasicModalTemplate
          isOpen={showDeleteModal}
          heading={DELETING_GIVE_POINTS_STACK_TITLE}
          primaryButton={{
            text: DELETE,
            status: 'warning',
            onClick: handleDeletionModalClose,
          }}
          secondaryButton={{
            text: CANCEL,
            onClick: setIsDeleteModalOpenToFalse,
          }}
          onModalClose={setIsDeleteModalOpenToFalse}
        >
          <Body variant="body2">{blockForDeletion.blockText}</Body>
        </BasicModalTemplate>
      </Flex>
    </>
  );
};

export default FlowsEditor;
