import React, { useCallback } from 'react';
import Uppy from '@uppy/core';

import { FlowFileForAPI } from '../../../interfaces/Flow';
import { deleteAttachmentFile } from '../../../Utils/flows';
import FlowsFileUploadInputBlock from '../../../atomic/molecules/FlowInputBlocks/FlowsFileUploadInputBlock';

type FlowsFileUploadInputBlockControllerProps = {
  blockId: string;
  description?: string;
  flowId: string;
  goToNextStep: () => void;
  instanceId: string;
  isRequired?: boolean;
  isLastBlock?: boolean;
  subDescription?: string;
  onBlur?: () => void;
  setFieldValue: (id: string, value: FlowFileForAPI[] | null) => void;
  title: string;
  uppy: Uppy.Uppy;
  value: string | null;
  isPreviewFlow?: boolean;
};

const FlowsFileUploadInputBlockController = ({
  blockId,
  goToNextStep,
  isRequired = false,
  setFieldValue,
  title,
  uppy,
  value,
  instanceId,
  flowId,
  isLastBlock = false,
  description,
  isPreviewFlow,
}: FlowsFileUploadInputBlockControllerProps) => {
  const handleChange = useCallback(
    (uploadedFiles: FlowFileForAPI[] | null) => {
      setFieldValue(blockId, uploadedFiles);
    },
    [blockId, setFieldValue],
  );

  const handleDeleteFileClick = useCallback(
    async (fileName) => {
      await deleteAttachmentFile({
        blockId,
        instanceId,
        fileName,
        flowId,
      });
    },
    [blockId, flowId, instanceId],
  );

  return (
    <FlowsFileUploadInputBlock
      blockId={blockId}
      goToNextStep={goToNextStep}
      isRequired={isRequired}
      onChange={handleChange}
      title={title}
      uppy={uppy}
      value={value}
      onDeleteFileClick={handleDeleteFileClick}
      isLastBlock={isLastBlock}
      description={description}
      isPreviewFlow={isPreviewFlow}
    />
  );
};

export default FlowsFileUploadInputBlockController;
