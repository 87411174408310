import React from 'react';
import Image from '../../../atoms/Image';
import ThemeV2 from '../../../../componentsV2/theme';
import {
  ArrowWrapper,
  ContentWrapper,
  HelperText,
  ImageHolder,
  StyledSVGArrowIcon,
  Title,
  TitleWrapper,
  Wrapper,
} from './styles';
import { ClickableSelectionItemProps } from './types';

const ClickableSelectionItem = (props: ClickableSelectionItemProps) => {
  const {
    className,
    imageSrc,
    variant = 'regular',
    title,
    helperText,
    onClick,
    children,
    backGroundColor,
  } = props;
  return (
    <Wrapper
      className={className}
      componentSize={variant}
      hasImage={Boolean(imageSrc)}
      onClick={onClick}
      data-testid="selection-item"
    >
      <>
        {imageSrc && (
          <ImageHolder
            size={variant}
            alignItems="center"
            justifyContent="center"
            backGroundColor={backGroundColor}
          >
            <Image src={imageSrc} alt="selection"></Image>
          </ImageHolder>
        )}
        <ContentWrapper>
          <TitleWrapper hasChildren={Boolean(children)}>
            <Title variant="body1Medium">{title}</Title>
            {helperText && (
              <HelperText variant="body2" color="gray8">
                {helperText}
              </HelperText>
            )}
          </TitleWrapper>
          {children}
        </ContentWrapper>
        <ArrowWrapper>
          <StyledSVGArrowIcon
            icon="carat-right"
            color={ThemeV2.palette.gray8}
          ></StyledSVGArrowIcon>
        </ArrowWrapper>
      </>
    </Wrapper>
  );
};

export default ClickableSelectionItem;
