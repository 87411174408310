import styled from 'styled-components';
import ThemeV2 from '../../../../componentsV2/theme';
import { Flex } from '../../../../Utils/styles/display';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 64px 1fr;
  grid-template-rows: auto;
  width: 100%;
  text-align: left;
  align-items: center;
  border-radius: 4px;
  border: 1px solid ${ThemeV2.palette.gray4};
  width: 100%;
  background: ${ThemeV2.palette.gray1};
  transition: 0.2s ease;
  transition-property: border color background;
  margin-bottom: 16px;
`;

export const RightSideWrapper = styled(Flex)`
  padding: 18px 16px;
  width: 100%;
  box-sizing: border-box;
  max-height: 64px;
  height: 100%;
  max-width: 438px;
`;

export const ButtonWrapper = styled(Flex)`
  width: 85px;
  height: 32px;
`;

export const StyledWorkatoConnectButton = styled.iframe`
  height: 38px;
  max-width: 118px;
  overflow: hidden;
  width: 100%;
`;

export const StyledWorkatoWrapper = styled.div`
  height: 38px;
  max-width: 118px;
  overflow: hidden;
  width: 100%;
`;
