import React, { useMemo } from 'react';
import {
  MultiOptionBlockBuilderSettingState,
  OptionsSelectTypes,
} from '../../../interfaces/Flow/Builder';
import {
  AN_EXACT_NUMBER,
  A_RANGE,
  PARTICIPANTS_CAN_SELECT,
} from '../../../languages/en/flows/builder';
import {
  OPTION,
  TO,
  OPTIONS,
  UNLIMITED,
} from '../../../languages/en/singleWords';
import { Flex } from '../../../Utils/styles/display';
import RadioGroup from '../../molecules/RadioGroup';
import Textfield from '../../molecules/NewTextfield';
import { StyledGroupHeader, StyledOptionsText, ToText } from './styles';
import { getMaximumRange, getMinimumRange } from '../../../Utils/flows';

const SelectOptions = ({
  blockSettingState,
}: {
  blockSettingState: MultiOptionBlockBuilderSettingState;
}) => {
  const {
    changeCurrentOptionSelectObject,
    currentOptionSelectObject,
    maximumSelectableOptions,
  } = blockSettingState;

  const radioOptionsForParticipants = useMemo(
    () => [
      {
        value: 'EXACT_NUMBER',
        label: AN_EXACT_NUMBER,
        children: (
          <Flex>
            {currentOptionSelectObject.type === 'EXACT_NUMBER' && (
              <>
                <Textfield
                  type="number"
                  value={String(currentOptionSelectObject.exactOptions)}
                  onChange={(e) => {
                    changeCurrentOptionSelectObject({
                      type: 'EXACT_NUMBER',
                      exactOptions: Number(e.target.value),
                    });
                  }}
                  onBlur={() => {
                    const maximumNumberExceeded =
                      currentOptionSelectObject.exactOptions >
                      maximumSelectableOptions;
                    const valueLessThan1 =
                      currentOptionSelectObject.exactOptions < 1;
                    if (maximumNumberExceeded) {
                      changeCurrentOptionSelectObject({
                        type: 'EXACT_NUMBER',
                        exactOptions: maximumSelectableOptions,
                      });
                    } else if (valueLessThan1) {
                      changeCurrentOptionSelectObject({
                        type: 'EXACT_NUMBER',
                        exactOptions: 1,
                      });
                    }
                  }}
                />
                <StyledOptionsText variant="body1">{`${OPTION}(s)`}</StyledOptionsText>
              </>
            )}
          </Flex>
        ),
      },
      {
        value: 'RANGE',
        label: A_RANGE,
        children: (
          <Flex>
            {currentOptionSelectObject.type === 'RANGE' && (
              <>
                <Textfield
                  type="number"
                  value={String(currentOptionSelectObject.minOptions)}
                  onChange={(e) => {
                    const { maxOptions } = currentOptionSelectObject;
                    const { value } = e.target;
                    changeCurrentOptionSelectObject({
                      type: 'RANGE',
                      minOptions: Number(value),
                      maxOptions,
                    });
                  }}
                  onBlur={() => {
                    changeCurrentOptionSelectObject({
                      type: 'RANGE',
                      minOptions: getMinimumRange(
                        currentOptionSelectObject.minOptions,
                        currentOptionSelectObject.maxOptions,
                        maximumSelectableOptions,
                      ),
                      maxOptions: currentOptionSelectObject.maxOptions,
                    });
                  }}
                />
                <ToText variant="body1">{TO}</ToText>
                <Textfield
                  type="number"
                  value={String(currentOptionSelectObject.maxOptions)}
                  onChange={(e) => {
                    const { minOptions } = currentOptionSelectObject;
                    const { value } = e.target;
                    changeCurrentOptionSelectObject({
                      type: 'RANGE',
                      minOptions,
                      maxOptions: Number(value),
                    });
                  }}
                  onBlur={() => {
                    changeCurrentOptionSelectObject({
                      type: 'RANGE',
                      minOptions: currentOptionSelectObject.minOptions,
                      maxOptions: getMaximumRange(
                        currentOptionSelectObject.minOptions,
                        currentOptionSelectObject.maxOptions,
                        maximumSelectableOptions,
                      ),
                    });
                  }}
                />
                <StyledOptionsText variant="body1">{OPTIONS}</StyledOptionsText>
              </>
            )}
          </Flex>
        ),
      },
      {
        value: 'UNLIMITED_OPTIONS',
        label: `${UNLIMITED} ${OPTIONS}`,
      },
    ],
    [
      changeCurrentOptionSelectObject,
      currentOptionSelectObject,
      maximumSelectableOptions,
    ],
  );
  return (
    <div>
      <StyledGroupHeader variant="body2">
        {`${PARTICIPANTS_CAN_SELECT}:`}
      </StyledGroupHeader>
      <RadioGroup
        radioOptions={radioOptionsForParticipants}
        value={blockSettingState.currentOptionSelectObject.type}
        name="participant-number-select"
        onChange={(e) => {
          switch (e.target.value as OptionsSelectTypes) {
            case 'EXACT_NUMBER': {
              blockSettingState.changeCurrentOptionSelectObject({
                type: 'EXACT_NUMBER',
                exactOptions: 1,
              });
              break;
            }
            case 'RANGE': {
              blockSettingState.changeCurrentOptionSelectObject({
                type: 'RANGE',
                maxOptions: 1,
                minOptions: 1, // Should be able to select at least one
              });
              break;
            }
            case 'UNLIMITED_OPTIONS': {
              blockSettingState.changeCurrentOptionSelectObject({
                type: 'UNLIMITED_OPTIONS',
              });
              break;
            }
            default: {
              break;
            }
          }
        }}
      />
    </div>
  );
};

export default SelectOptions;
