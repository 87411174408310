import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import FormsEditorBaseInputBlock from '../FormsEditorInputBaseBlock';

import {
  ContentBlockLocalProperties,
  ContentMultiOptionBlockState,
  MultiOptionBlockBuilderSettingState,
  OptionsSelectObject,
} from '../../../../interfaces/Flow/Builder';
import { SingleSelectAutoComplete } from '../../Autocomplete';
import { DROPDOWN_SELECTOR_PLACEHOLDER_LABEL } from '../../../../languages/en/flows/builder';
import OptionItemsEditor from '../../../molecules/OptionItemsEditor';
import {
  OptionItemEditorProps,
  OptionItemProps,
} from '../../../molecules/OptionItemsEditor/interface';
import { Flex } from '../../../../Utils/styles/display';
import {
  getUpdatedBlockSettingsOnOptionsUpdate,
  pushNewOption,
} from '../../../../Utils/flows';
import ThemeV2 from '../../../../componentsV2/theme';
import {
  getOptionsRangeHelperText,
  getUnlimitedOptionsHelperText,
  getExactOptionsHelperText,
  MIN_OPTION_ERROR,
} from '../../../../languages/en/flows';
import InfoAlert from '../../../molecules/InfoAlert';

export interface FormsEditorDropdownInputBlockProps
  extends ContentBlockLocalProperties {
  blockData: ContentMultiOptionBlockState;
}

const StyledSingleSelectAutoComplete = styled(SingleSelectAutoComplete)`
  margin-top: 16px;
  width: 100%;

  input {
    background: ${ThemeV2.palette.white};
  }
`;

const StyledOptionItemsEditor = styled(OptionItemsEditor)`
  margin-top: 16px;
  width: 100%;
`;

export const StyledInfoAlert = styled(InfoAlert)`
  padding: 8px;
  margin: 8px 0px;
  width: 100%;
`;

const FormsEditorDropdownInputBlock = ({
  blockData,
  currentIndex,
  handleToggleIsDescriptionNeeded,
  handleToggleIsRequired,
  onValueChange,
  totalNumberOfItems,
  onDeleteClick,
  onMoveDownClick,
  onMoveUpClick,
}: FormsEditorDropdownInputBlockProps) => {
  const {
    options,
    description,
    isRequired,
    maximumSelectableOptions,
    currentOptionSelectObject,
    assemblyCurrency,
  } = blockData;
  const [optionsCount, setOptionsCount] = useState(options.length);

  const placeHolderText = useMemo(() => {
    switch (currentOptionSelectObject.type) {
      case 'EXACT_NUMBER':
        return getExactOptionsHelperText(
          currentOptionSelectObject.exactOptions,
        );

      case 'RANGE':
        return getOptionsRangeHelperText(
          currentOptionSelectObject.minOptions,
          currentOptionSelectObject.maxOptions,
        );

      case 'UNLIMITED_OPTIONS':
        return getUnlimitedOptionsHelperText(maximumSelectableOptions);

      default:
        return DROPDOWN_SELECTOR_PLACEHOLDER_LABEL;
    }
  }, [currentOptionSelectObject, maximumSelectableOptions]);

  const changeChosenParticipantSelection = (
    updatedOptions: OptionsSelectObject,
  ) => {
    if (onValueChange) {
      onValueChange({
        ...blockData,
        currentOptionSelectObject: updatedOptions,
        maximumSelectableOptions: options.length,
      });
    }
  };

  const updateOptions = (updatedOptions: OptionItemProps[]) => {
    const modifiedCurrentSelection = getUpdatedBlockSettingsOnOptionsUpdate(
      updatedOptions.length,
      currentOptionSelectObject,
    );
    onValueChange({
      ...blockData,
      options: updatedOptions,
      maximumSelectableOptions: updatedOptions.length,
      ...(modifiedCurrentSelection !== null && {
        currentOptionSelectObject: modifiedCurrentSelection,
      }),
    });
  };

  const blockSettingState: MultiOptionBlockBuilderSettingState = {
    type: 'DROPDOWN',
    isDescriptionNeeded: description !== null,
    isRequired,
    toggleIsRequired: handleToggleIsRequired,
    toggleDescriptionNeeded: handleToggleIsDescriptionNeeded,
    currentOptionSelectObject,
    changeCurrentOptionSelectObject: changeChosenParticipantSelection,
    maximumSelectableOptions,
  };

  const optionItemsProps: OptionItemEditorProps = {
    type: 'numbered',
    optionItems: options,
    onDismissClick: (currentOption: OptionItemProps | undefined) => {
      const modifiedOptionsData = options.filter((option) => {
        return option?.value !== currentOption?.value;
      });
      updateOptions(modifiedOptionsData);
    },
    onOptionEditChange: (
      e: React.ChangeEvent<HTMLInputElement>,
      currentOption: OptionItemProps | undefined,
    ) => {
      const modifiedOptionsData = options.map((option) => {
        if (option?.value === currentOption?.value) {
          return {
            ...currentOption,
            label: e.target.value,
          };
        }
        return option;
      });
      updateOptions(modifiedOptionsData);
    },
    addOptionClickProps: {
      enabled: true,
      onClick: () => {
        const modifiedOptionsData = pushNewOption(options, optionsCount, false);
        updateOptions(modifiedOptionsData);
        setOptionsCount(optionsCount + 1);
      },
    },
  };

  return (
    <FormsEditorBaseInputBlock
      blockData={blockData}
      currentIndex={currentIndex !== undefined ? currentIndex : 0}
      assemblyCurrency={assemblyCurrency}
      totalNumberOfItems={totalNumberOfItems}
      blockSettingState={blockSettingState}
      onDeleteClick={onDeleteClick}
      onMoveDownClick={onMoveDownClick}
      onMoveUpClick={onMoveUpClick}
      onValueChange={onValueChange}
    >
      <Flex flexDirection="column" width="100%">
        {options.length < 1 && (
          <StyledInfoAlert
            id={+new Date()}
            text={MIN_OPTION_ERROR}
            icon="error-warning"
            alertType="error"
          />
        )}
        <StyledSingleSelectAutoComplete
          autoFocus
          label={placeHolderText}
          value={null}
          onChange={() => {}}
          options={[]}
          multiple={false}
          textboxSize="large"
          onTextboxValueChange={() => {}}
          disabled
        />
        <StyledOptionItemsEditor {...optionItemsProps} />
      </Flex>
    </FormsEditorBaseInputBlock>
  );
};

export default FormsEditorDropdownInputBlock;
