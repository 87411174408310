import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Category } from '../../../../../atomic/molecules/RequestTemplateLeftColumn/types';
import { FlowRequestTemplateCardProps } from '../../../../../atomic/molecules/FlowRequestTemplateCard/type';
import {
  mapColorToPalette,
  mapHexCodeToEmoticon,
} from '../../../../../Utils/mappers';
import AssemblyIcon from '../../../../../atomic/atoms/SVGIcon/icons/assembly-icon.svg';
import { APP_NAME } from '../../../../../languages/en';
import useToggle from '../../../../../hooks/useToggle';
import {
  useGetAllFlowsTemplates,
  useGetFlowsTemplateCategories,
  useGetFlowTemplate,
  useRequestTemplate,
} from '../../../../../queries/Flows/Template';
import { NEW_FLOWS_BUILDER } from '../../../../../constants/routes';
import { useProfileInfoFetchQuery } from '../../../../../queries/Profile';
import { trackTemplateGalleryActionEvent } from '../../../../../Utils/analytics/templateGallery';
import { TEMPLATE_GALLERY_EVENTS } from '../../../../../Utils/analytics/constants';

type UseCreateFlowsModalControllerProps = {
  handleCreateFlowsModalClose: () => void;
};

const useCreateFlowsModalController = (
  props: UseCreateFlowsModalControllerProps,
) => {
  const history = useHistory();
  const { data: profileInfo } = useProfileInfoFetchQuery();
  const { handleCreateFlowsModalClose } = props;
  const [selectedTemplate, setSelectedTemplate] = useState<
    FlowRequestTemplateCardProps | undefined
  >();
  const [searchValue, setSearchValue] = useState('');
  const [categories, setCategories] = useState<Category[]>([]);
  const [templates, setTemplates] =
    useState<Record<string, FlowRequestTemplateCardProps[]>>();
  const {
    models: { toggleValue: isRequestModalOpen },
    operations: { setToggleToFalse: setRequestModalClose },
  } = useToggle();
  const [requestFoTemplate, setRequestForTemplate] = useState<
    string | undefined
  >(undefined);
  const [selectedCategory, setSelectedCategory] = useState<
    Category | undefined
  >(undefined);
  const { data, isLoading: isGetCategoriesLoading } =
    useGetFlowsTemplateCategories();
  const { data: templatesData, isLoading: isGetTemplatesLoading } =
    useGetAllFlowsTemplates();
  const { isLoading: isFlowTemplateLoading, data: flowTemplateData } =
    useGetFlowTemplate(selectedTemplate ? selectedTemplate.templateId : '');

  const handleSetSearchValue = useCallback(
    (newValue: string) => {
      /* Mix panel */
      trackTemplateGalleryActionEvent({
        action: TEMPLATE_GALLERY_EVENTS.SEARCH,
        searchQuery: newValue,
        filterApplied: selectedCategory?.title,
      });
      /* Mix panel */
      setSearchValue(newValue);
    },
    [selectedCategory],
  );

  const displayTemplates = useMemo(() => {
    if (selectedCategory && templates) {
      if (searchValue) {
        return templates[selectedCategory.key].filter((template) =>
          template.title.toLowerCase().includes(searchValue.toLowerCase()),
        );
      }
      return templates[selectedCategory.key];
    }
    return [];
  }, [searchValue, selectedCategory, templates]);

  const { mutate: requestTemplateForId } = useRequestTemplate();

  const categoryAll = useMemo(() => {
    return {
      id: 'all',
      key: 'ALL',
      name: 'All',
      title: 'All',
      color: 'sunsetOrange2',
      description: '',
    };
  }, []);

  useEffect(() => {
    if (data && data.data.length > 0) {
      const transformedCategories = data.data.map((category) => ({
        id: category.key,
        title: category.name,
        ...category,
      }));

      const templateCategories = templatesData?.data.map(
        (item) => item.category,
      );

      const availableCategories = transformedCategories.filter((item) =>
        templateCategories?.includes(item.key),
      );

      availableCategories.unshift(categoryAll);

      setCategories(availableCategories);
      setSelectedCategory(availableCategories[0]);
    }
  }, [categoryAll, data, templatesData]);

  useEffect(() => {
    if (
      templatesData &&
      templatesData.data.length > 0 &&
      categories &&
      categories.length > 0
    ) {
      const templatesForCategory: Record<
        string,
        FlowRequestTemplateCardProps[]
      > = {};

      categories.forEach((category) => {
        templatesForCategory[category.key] = [];
      });
      templatesData.data.forEach((template) => {
        const categoryForTemplate = categories.find(
          (category) => category.id === template.category,
        );
        let color = '';
        if (categoryForTemplate) {
          color = mapColorToPalette(categoryForTemplate.color);
        }
        const templateCard = {
          templateId: template.templateId,
          title: template.title,
          emoticon: mapHexCodeToEmoticon(template.icon.value),
          color,
          noOfParticipants: 0,
          avatarProps: {
            // TODO: DONT HARDCODE ASSEMBLY ICON
            img: AssemblyIcon,
            name: APP_NAME,
          },
        };

        templatesForCategory[template.category]?.push(templateCard);
        templatesForCategory.ALL.push(templateCard);
      });
      setTemplates(templatesForCategory);
    }
  }, [categories, data, templatesData]);

  const handleCategoryClick = useCallback(
    (category: Category) => {
      /* Mix panel */
      trackTemplateGalleryActionEvent({
        action: TEMPLATE_GALLERY_EVENTS.START_FROM_SCRATCH,
        searchQuery: searchValue,
        filterApplied: category.title,
      });
      /* Mix panel */
      setSelectedCategory(category);
    },
    [searchValue],
  );

  const handleCategoryBlur = useCallback(
    (e) => {
      const autoCompleteValue = e.target.value;

      if (!autoCompleteValue) {
        setSelectedCategory(categoryAll);
      }
    },
    [categoryAll],
  );

  const handleRequestTemplateModalClose = useCallback(() => {
    setRequestModalClose();
    handleCreateFlowsModalClose();
    setRequestForTemplate(undefined);
  }, [handleCreateFlowsModalClose, setRequestModalClose]);

  const handleRequestTemplateFromScratchClick = useCallback(() => {
    /* Mix panel */
    trackTemplateGalleryActionEvent({
      action: TEMPLATE_GALLERY_EVENTS.START_FROM_SCRATCH,
      searchQuery: searchValue,
      filterApplied: selectedCategory?.title,
    });
    /* Mix panel */
    handleRequestTemplateModalClose();
    history.push(`${NEW_FLOWS_BUILDER}`);
  }, [handleRequestTemplateModalClose, history, searchValue, selectedCategory]);

  const handleTemplateClick = useCallback(
    (templateId) => {
      if (selectedCategory && templates) {
        const template = templates[selectedCategory.key].find(
          (templateVal) => templateVal.templateId === templateId,
        );
        if (template) {
          setSelectedTemplate(template);
          /* requestTemplateForId({ templateId });
          setRequestForTemplate(`${template.emoticon}${template.title}`);
          handleRequestTemplateModalClose();
          history.push(`${NEW_FLOWS_BUILDER}?template=${templateId}`); */
        }
      }
    },
    [selectedCategory, templates],
  );

  const onUseTemplateClick = useCallback(() => {
    if (selectedTemplate) {
      const { templateId, emoticon, title } = selectedTemplate;
      requestTemplateForId({ templateId });
      setRequestForTemplate(`${emoticon}${title}`);
      handleRequestTemplateModalClose();
      /* Mix panel */
      trackTemplateGalleryActionEvent({
        action: TEMPLATE_GALLERY_EVENTS.USE_TEMPLATE,
        templateId: templateId,
        templateName: title,
        searchQuery: searchValue,
        filterApplied: selectedCategory?.title,
      });
      /* Mix panel */
      history.push(`${NEW_FLOWS_BUILDER}?template=${templateId}`);
    }
  }, [
    history,
    searchValue,
    selectedCategory,
    selectedTemplate,
    requestTemplateForId,
    handleRequestTemplateModalClose,
  ]);

  const closePreviewTemplateModal = useCallback(
    () => setSelectedTemplate(undefined),
    [],
  );

  return {
    selectedTemplate,
    handleCategoryClick,
    handleCategoryBlur,
    selectedCategory,
    searchValue,
    setSearchValue: handleSetSearchValue,
    categories,
    isGetCategoriesLoading,
    isGetTemplatesLoading,
    handleTemplateClick,
    templates: displayTemplates,
    handleRequestTemplateFromScratchClick,
    isRequestModalOpen,
    handleRequestTemplateModalClose,
    isCreateFlowsModalOpen: true,
    requestFoTemplate,
    closePreviewTemplateModal,
    onUseTemplateClick,
    flowTemplateData,
    isFlowTemplateLoading,
    profileInfo,
  };
};

export default useCreateFlowsModalController;
