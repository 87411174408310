import { trackEvent } from '..';
import { FlowBuilderEventProps } from '../interfaces';
import { AnalyticsEvents, BUILDER_EVENTS } from '../constants';

const trackFlowBuilderShowEvent = (eventProps: FlowBuilderEventProps) => {
  trackEvent(BUILDER_EVENTS, undefined, {
    ...eventProps,
    event: AnalyticsEvents.SHOW,
  });
};
const trackFlowBuilderActionEvent = (eventProps: FlowBuilderEventProps) => {
  trackEvent(BUILDER_EVENTS, undefined, {
    ...eventProps,
    event: AnalyticsEvents.ACTION,
  });
};
const trackFlowBuilderErrorEvent = (eventProps: FlowBuilderEventProps) => {
  trackEvent(BUILDER_EVENTS, undefined, {
    ...eventProps,
    event: AnalyticsEvents.ERROR,
  });
};

export {
  trackFlowBuilderShowEvent,
  trackFlowBuilderActionEvent,
  trackFlowBuilderErrorEvent,
};
