import styled from 'styled-components';

import ThemeV2 from '../../../componentsV2/theme';
import ToggleButton from '../../atoms/ToggleButton';
import EmoticonPickerPopover from '../EmoticonPickerPopover';
import Heading from '../../atoms/Heading';
import Input from '../Input';
import Body from '../../atoms/Body';

export const FlowEmoticonAndNameWrapper = styled.div`
  display: flex;
  > div {
    margin: 0;
  }
`;

export const StyledEmoticonPickerPopover = styled(EmoticonPickerPopover)`
  margin-right: 8px;
`;

export const StyledToggleButtonStyle = styled(ToggleButton)`
  justify-content: center;
  background: transparent;
  color: ${ThemeV2.palette.gray6};
  padding: 0;
  background-color: ${ThemeV2.palette.gray1};
  box-sizing: border-box;
  > * {
    margin-bottom: 0;
    line-height: 30px;
  }
`;

export const ErrorText = styled(Body)`
  margin-top: 8px;
  color: ${ThemeV2.palette.dustRed6};
`;

export const StyledHeader = styled(Heading)<{
  borderColor?: string;
}>`
  padding: 8px 16px;
  border-radius: 4px;
  border: ${({ borderColor }) =>
    borderColor ? `1px solid ${borderColor}` : 'initial'};
  margin: 0px;
`;

export const StyledFlowNameInput = styled(Input)`
  &.MuiFormControl-root {
    margin-bottom: 0;
    margin-left: 8px;
    width: 100%;
  }

  && input {
    font-size: 20px;
    line-height: 28px;
    font-weight: ${ThemeV2.typography.fontWeightMedium};
    &::placeholder {
      font-weight: ${ThemeV2.typography.fontWeightMedium};
    }
  }
`;

export const TextAreaWrapper = styled.div`
  margin-top: 24px;
  .MuiOutlinedInput-root {
    padding: 8px 12px;
    .MuiInputBase-input {
      font-size: 14px;
      line-height: 22px;
      font-weight: ${ThemeV2.typography.fontWeightRegular};
      min-height: 126px;
    }
  }
`;
