import { AxiosResponse } from 'axios';
import { EmojiData } from 'emoji-mart';
import { useCallback } from 'react';
import { InfiniteData, UseMutateFunction } from 'react-query';
import {
  FeedItemFromAPI,
  FilterPayload,
  PostSortOptions,
  Reaction,
  UpdateReactionAction,
} from '../../interfaces/Feed';
import { PostTypes } from '../../interfaces/Home';
import {
  useMainFeedUpdateFlowPostReactionMutation,
  useMainFeedUpdatePostReactionMutation,
} from '../../queries/Feed';
import {
  FlowFeedResponse,
  ReactionRequestPayload,
} from '../../queries/Flows/Feed/interfaces';
import { PaginationResponse } from '../../queries/Flows/interfaces';
import { GetProfileInfoResponse } from '../../queries/Profile';
import { mapEmojiToReaction } from '../../Utils/flows/feeds';
import {
  doesPostReactionsHasReactionFromSameUser,
  hasReactionFromSameUser,
} from '../../Utils/home/feeds/reactions';
import { serializeReactionTogglePayload } from '../../Utils/serializers/feed';

export const useFeedReactions = (
  feedsSortValue: PostSortOptions,
  profileData?: GetProfileInfoResponse,
  filter?: FilterPayload,
) => {
  const { mutate: mutateFlowPostReaction } =
    useMainFeedUpdateFlowPostReactionMutation({
      feedsSort: feedsSortValue,
      filter,
    });
  const { mutate: mutatePostReaction } = useMainFeedUpdatePostReactionMutation({
    feedsSort: feedsSortValue,
    filter,
  });

  const onReactionSet = useCallback(
    (emoji: EmojiData, contentId: string, post: FeedItemFromAPI) => {
      if (profileData?.member) {
        let reactions;
        if (post.type === PostTypes.FLOW) {
          reactions = post.flowResponse?.reactions || [];
        } else {
          reactions = post.reactions;
        }
        if (
          doesPostReactionsHasReactionFromSameUser(
            reactions,
            emoji,
            profileData,
          )
        ) {
          return;
        }
        if (post.type === PostTypes.FLOW && mutateFlowPostReaction) {
          mutateFlowPostReaction({
            flowId: post.flowResponse?.flow.flowId || '',
            responseId: post.flowResponse?.responseId || '',
            ...serializeReactionTogglePayload(
              mapEmojiToReaction(emoji),
              post.postID,
              UpdateReactionAction.SET,
              profileData?.member,
            ),
          });
        } else {
          mutatePostReaction(
            serializeReactionTogglePayload(
              mapEmojiToReaction(emoji),
              contentId,
              UpdateReactionAction.SET,
              profileData?.member,
            ),
          );
        }
      }
    },
    [profileData, mutateFlowPostReaction, mutatePostReaction],
  );

  const onReactionUnSet = useCallback(
    (reaction: Reaction, contentId: string, post: FeedItemFromAPI) => {
      let action = UpdateReactionAction.UNSET;

      if (profileData?.member) {
        if (!hasReactionFromSameUser(reaction, profileData)) {
          action = UpdateReactionAction.SET;
        }
        if (post.type === PostTypes.FLOW && mutateFlowPostReaction) {
          mutateFlowPostReaction({
            flowId: post.flowResponse?.flow.flowId || '',
            responseId: post.flowResponse?.responseId || '',
            ...serializeReactionTogglePayload(
              reaction.reaction,
              post.postID,
              action,
              profileData?.member,
            ),
          });
        } else {
          mutatePostReaction(
            serializeReactionTogglePayload(
              reaction.reaction,
              contentId,
              action,
              profileData?.member,
            ),
          );
        }
      }
    },
    [mutateFlowPostReaction, mutatePostReaction, profileData],
  );

  return {
    onReactionSet,
    onReactionUnSet,
  };
};

type UseFlowFeedReactionsProps = {
  flowFeedData: FlowFeedResponse[];
  profileData?: GetProfileInfoResponse;
  flowId: string;
  mutateFlowPostReaction: UseMutateFunction<
    AxiosResponse<any>,
    unknown,
    ReactionRequestPayload,
    {
      previousData:
        | InfiniteData<PaginationResponse<FlowFeedResponse>>
        | FlowFeedResponse;
    }
  >;
};
export const useFlowFeedReactions = (props: UseFlowFeedReactionsProps) => {
  const { flowFeedData, profileData, mutateFlowPostReaction } = props;

  const onReactionSet = useCallback(
    (emoji: EmojiData, responseId: string) => {
      const flow = flowFeedData.find((x) => x.responseId === responseId);
      if (
        flow &&
        doesPostReactionsHasReactionFromSameUser(
          flow.reactions,
          emoji,
          profileData,
        )
      ) {
        return;
      }

      mutateFlowPostReaction({
        responseId,
        action: 'set',
        payload: mapEmojiToReaction(emoji),
        user: {
          ...profileData?.member.profile,
          memberID: profileData?.member.memberId,
        },
      });
    },
    [flowFeedData, profileData, mutateFlowPostReaction],
  );

  const onReactionUnSet = useCallback(
    (reaction: Reaction, responseId: string) => {
      let action = 'unset';
      const hasCurrentUserReactedReactions = () => {
        return (r: { memberID: string | undefined }) =>
          r.memberID === profileData?.member.memberId;
      };

      if (!reaction.members.some(hasCurrentUserReactedReactions())) {
        action = 'set';
      }

      mutateFlowPostReaction({
        action,
        responseId,
        payload: reaction.reaction,
        user: {
          ...profileData?.member.profile,
          memberID: profileData?.member.memberId,
        },
      });
    },
    [profileData, mutateFlowPostReaction],
  );

  return {
    onReactionSet,
    onReactionUnSet,
  };
};
