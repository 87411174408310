import React from 'react';
import ContentLoader from 'react-content-loader';
import NewTextfield from '../../molecules/NewTextfield';
import ThemeV2 from '../../../componentsV2/theme';

import {
  FlowRequestTemplateModalHeaderProps,
  LoadedProps,
  LoadingProps,
} from './interface';
import {
  StyledHeading,
  StyledBody,
  StyledIconButton,
  Wrapper,
  HeaderWrapper,
  InputWrapper,
  StyledAutocomplete,
} from './styles';
import { ComponentStatus } from '../../../interfaces/component';
import { FLOW_REQUEST_TEMPLATE_MODAL } from '../../../languages/en/flows/requestFlowModal';
import { AutocompleteDropdownItem } from '../Autocomplete/interfaces';
import { Category } from '../../molecules/RequestTemplateLeftColumn/types';

const LoadingComponent = (props: LoadingProps) => {
  const { inputPlaceholder, className } = props;

  return (
    <Wrapper className={className}>
      <HeaderWrapper>
        <ContentLoader
          speed={2}
          width={351}
          height={38}
          viewBox="0 0 351 38"
          backgroundColor={ThemeV2.palette.gray4}
          foregroundColor={ThemeV2.palette.gray3}
          data-testid="header-loader"
        >
          <rect x="0" y="0" rx="4" ry="4" width="351" height="38" />
        </ContentLoader>

        <ContentLoader
          speed={2}
          width={634}
          height={22}
          viewBox="0 0 634 22"
          backgroundColor={ThemeV2.palette.gray4}
          foregroundColor={ThemeV2.palette.gray3}
          data-testid="sub-header-loader"
        >
          <rect x="0" y="0" rx="4" ry="4" width="634" height="22" />
        </ContentLoader>
      </HeaderWrapper>
      <InputWrapper>
        <NewTextfield
          disabled
          icon="search"
          placeholder={inputPlaceholder}
          value=""
          onChange={() => {}}
        />
        <StyledAutocomplete
          onChange={() => {}}
          options={[]}
          value={null}
          disabled
        />
      </InputWrapper>
    </Wrapper>
  );
};

const LoadedComponent = (props: LoadedProps) => {
  const {
    heading,
    subHeading,
    inputPlaceholder,
    value,
    onChange,
    onBlur,
    onCloseButtonClick,
    className,
    categories,
    onCategoryChange,
    onCategoryBlur,
    isGetCategoriesLoading,
    selectedCategory,
  } = props;

  const handleCategoryChange = (
    category: AutocompleteDropdownItem<string | number, unknown> | null,
  ) => {
    onCategoryChange(category as Category);
  };

  return (
    <Wrapper className={className}>
      <StyledHeading variant="h5" weight="medium">
        {heading}
      </StyledHeading>
      <StyledBody variant="body2">{subHeading}</StyledBody>
      <InputWrapper>
        <NewTextfield
          icon="search"
          placeholder={inputPlaceholder}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          onBlur={onBlur}
        />
        <StyledAutocomplete
          label={FLOW_REQUEST_TEMPLATE_MODAL.autoCompletePlaceholder}
          onChange={handleCategoryChange}
          onBlur={onCategoryBlur}
          options={categories}
          value={selectedCategory || null}
          loading={isGetCategoriesLoading}
          dataTestId="categorySelectAutoComplete"
          showClearIcon={false}
        />
      </InputWrapper>
      <StyledIconButton
        icon="close-rounded"
        iconColor={ThemeV2.palette.gray6}
        onClick={onCloseButtonClick}
      />
    </Wrapper>
  );
};

const FlowRequestTemplateModalHeader = (
  props: FlowRequestTemplateModalHeaderProps,
) => {
  switch (props.status) {
    case ComponentStatus.LOADED:
      return <LoadedComponent {...props} />;
    case ComponentStatus.LOADING:
      return <LoadingComponent {...props} />;
    default:
      throw new Error('Invalid Component Status');
  }
};

export default FlowRequestTemplateModalHeader;
