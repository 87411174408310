import React from 'react';
import { EditorState } from 'draft-js';
import styled from 'styled-components';
import FormsEditorBaseInputBlock from '../FormsEditorInputBaseBlock';
import FlowsOpenEndedInputBlock from '../../../molecules/FlowInputBlocks/FlowsOpenEndedInputBlock';
import {
  ContentBlockLocalProperties,
  ContentOpenEndedBlockState,
  OpenEndedBlockBuilderSettingState,
  OpenEndedBlockSettingOptions,
} from '../../../../interfaces/Flow/Builder';
import { GifRatingsProps } from '../../../atoms/Giphy/interface';

interface FormsEditorOpenEndedInputBlockProps
  extends ContentBlockLocalProperties {
  blockData: ContentOpenEndedBlockState;
}
export const StyledFlowsOpenEndedInputBlock = styled(FlowsOpenEndedInputBlock)`
  margin: 16px 0 0 0;
  width: 100%;

  > div > div {
    margin-top: 0;
  }
`;

const FormsEditorOpenEndedInputBlock = ({
  blockData,
  currentIndex,
  handleToggleIsDescriptionNeeded,
  handleToggleIsRequired,
  onValueChange,
  totalNumberOfItems,
  onDeleteClick,
  onMoveDownClick,
  onMoveUpClick,
}: FormsEditorOpenEndedInputBlockProps) => {
  const {
    isRequired,
    description,
    openEndedOptions,
    maximumCharacters,
    minimumCharacters,
    assemblyCurrency,
  } = blockData;

  const handleChangeOpenEndedOptions = (
    updatedOptions: OpenEndedBlockSettingOptions,
  ) => {
    if (onValueChange) {
      onValueChange({
        ...blockData,
        openEndedOptions: { ...updatedOptions },
      });
    }
  };

  const handleCharactersLimitChange = (
    minCharacters?: number,
    maxCharacters?: number,
  ) => {
    if (onValueChange) {
      onValueChange({
        ...blockData,
        minimumCharacters: minCharacters,
        maximumCharacters: maxCharacters,
      });
    }
  };

  const blockSettingState: OpenEndedBlockBuilderSettingState = {
    type: 'OPEN_ENDED',
    disableRequiredToggle: false,
    toggleIsRequired: handleToggleIsRequired,
    openEndedOptions,
    minimumCharacters,
    maximumCharacters,
    changeOpenEndedOptions: handleChangeOpenEndedOptions,
    onCharactersLimitChange: handleCharactersLimitChange,
    isRequired,
    isDescriptionNeeded: description !== null,
    toggleDescriptionNeeded: handleToggleIsDescriptionNeeded,
  };

  const defaultBlockValue = {
    editorState: EditorState.createEmpty(),
    selectedMentions: [],
    tags: [],
  };

  return (
    <FormsEditorBaseInputBlock
      blockData={blockData}
      currentIndex={currentIndex !== undefined ? currentIndex : 0}
      assemblyCurrency={assemblyCurrency}
      totalNumberOfItems={totalNumberOfItems}
      blockSettingState={blockSettingState}
      onDeleteClick={onDeleteClick}
      onMoveDownClick={onMoveDownClick}
      onMoveUpClick={onMoveUpClick}
      onValueChange={onValueChange}
    >
      <StyledFlowsOpenEndedInputBlock
        title=""
        isDisabled
        goToNextStep={() => {}}
        onBlockChange={() => {}}
        blockValue={defaultBlockValue}
        gifRatings={GifRatingsProps.PG}
        hideGifs={!openEndedOptions.gifs}
        hideEmoticons={!openEndedOptions.emojis}
        hideMentions={!openEndedOptions.mentions}
        hideAttachment={!openEndedOptions.attachments}
        minHeight={70}
      />
    </FormsEditorBaseInputBlock>
  );
};

export default FormsEditorOpenEndedInputBlock;
