import React, { useState, useMemo, useCallback, useEffect } from 'react';

import {
  CHARITY_REWARDS_SEARCH_PLACEHOLDER,
  REWARDS_SORT_PLACEHOLDER,
} from '../../../languages/en/rewards';
import ThemeV2 from '../../../componentsV2/theme';
import { giftCardsSortByMenuItems } from '../../../Utils/data/rewards';
import {
  TextFieldType,
  TextFieldProps,
} from '../../../atomic/molecules/TextField_V2/interface';
import { CharityReward } from '../../../queries/Rewards/interfaces';
import useStringSearch from '../../../hooks/useSearchString';

interface CharityRewardsComponentProps {
  rewards: CharityReward[];
  onSortChange: (value: unknown) => void;
  resetCurrentPage: () => void;
}

const GiftCardControllerLogic = (props: CharityRewardsComponentProps) => {
  const { rewards, onSortChange, resetCurrentPage } = props;
  const [searchValue, setSearchValue] = useState('');
  const [sortValue, setSortValue] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedReward, setSelectedReward] = useState<
    CharityReward | undefined
  >();

  let rewardsToDisplay = [...rewards];

  const { models: searchModels, operations: searchOperations } =
    useStringSearch({ data: rewardsToDisplay, keys: ['brandName'] });

  const { searchResult } = searchModels;

  useEffect(() => {
    searchOperations.search(searchValue);
  }, [searchValue, searchOperations]);

  const onSearchChangeHandler = useCallback(
    (value: React.SetStateAction<string>) => {
      resetCurrentPage();
      setSearchValue(value);
    },
    [resetCurrentPage],
  );

  const onSortChangeHandler = useCallback(
    (value: React.SetStateAction<string>, id?: string | number) => {
      if (value !== sortValue) {
        resetCurrentPage();
        setSortValue(value);
        onSortChange(id);
      }
    },
    [onSortChange, resetCurrentPage, sortValue],
  );

  const searchProps: TextFieldProps = useMemo(() => {
    return {
      placeholder: CHARITY_REWARDS_SEARCH_PLACEHOLDER,
      type: TextFieldType.Text,
      value: searchValue,
      onChange: onSearchChangeHandler,
      icon: 'search',
    };
  }, [onSearchChangeHandler, searchValue]);

  const sortProps: TextFieldProps = useMemo(() => {
    return {
      placeholder: REWARDS_SORT_PLACEHOLDER,
      type: TextFieldType.Text,
      icon: 'sort',
      value: sortValue,
      onChange: onSortChangeHandler,
      dropdownItems: giftCardsSortByMenuItems,
      isEditable: false,
      inputIconColor: ThemeV2.palette.gray9,
    };
  }, [sortValue, onSortChangeHandler]);

  // search logic
  rewardsToDisplay = searchResult;

  const handleOnRewardSelect = useCallback(
    (id) => {
      const currentReward = rewards.find((reward) => reward.brandKey === id);
      if (currentReward) {
        setIsModalOpen(true);
        setSelectedReward(currentReward);
      }
    },
    [rewards],
  );

  const onCloseRedeemModal = useCallback(() => {
    setIsModalOpen(false);
    setSelectedReward(undefined);
  }, []);

  return {
    searchProps,
    sortProps,
    rewardsToDisplay,
    handleOnRewardSelect,
    isModalOpen,
    selectedReward,
    onCloseRedeemModal,
  };
};

export default GiftCardControllerLogic;
