import create from 'zustand';
import { persist } from 'zustand/middleware';

const useKioskStore = create(
  persist(
    (set) => ({
      isTurnedOn: false,
      setIsTurnedOn: (isTurnedOn: boolean) =>
        set(() => ({ isTurnedOn: isTurnedOn })),
    }),
    {
      name: 'kiosk-settings',
    },
  ),
);

export default useKioskStore;
