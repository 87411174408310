import isEmpty from 'lodash/isEmpty';
import { useCallback, useState, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import shallow from 'zustand/shallow';
import {
  LayoutStoreState,
  SelectedMember,
} from '../../../../interfaces/Layout';
import {
  Assignee,
  TaskCategories,
  NotebookTask,
  MiscTaskCategories,
  NotebookAnalyticsParameters,
  NotebookTaskAnalyticsParameters,
  NotebookOperations,
} from '../../../../interfaces/notebook';
import { useGetNotebookTaskAssigneeList } from '../../../../queries/Notebook';
import { useProfileInfoFetchQuery } from '../../../../queries/Profile';
import useLayoutStore from '../../../../stores/layoutStore';
import useNotebookStore from '../../../../stores/notebookStore';
import { NotebookStore } from '../../../../stores/notebookStore/types';
import {
  NOTEBOOK_EVENTS,
  NOTEBOOK_TASK_EVENTS,
} from '../../../../Utils/analytics/constants';
import {
  trackNotebookTaskActionEvent,
  trackNotebookActionEvent,
  trackNotebookTaskShowEvent,
} from '../../../../Utils/analytics/notebook';
import {
  getNotebookAnalyticsProps,
  getNotebookTaskAnalyticsProps,
  isNotebookEditorEmpty,
} from '../../../../Utils/notebook';
import { taskDropdownItems } from '../../NotebookPageController/useNotebookPageController/data';
import {
  NotebookTabs,
  DropdownOptions,
  DropdownItemOption,
} from '../../NotebookPageController/useNotebookPageController/types';
import {
  getTaskItemDueDate,
  extractAssignedToInformation,
  getCreatedByFromProfile,
} from '../../NotebookPageController/utils';
import { getAssignmentOperation } from './utils';

const HideCountTypes: TaskCategories[] = [
  TaskCategories.ARCHIVED,
  TaskCategories.COMPLETED,
];

const notebookTasksSelector = (state: NotebookStore) => ({
  erroredTaskIDs: state.erroredTaskIDs,
  setModalData: state.setModalData,
  setModalDataForTabChange: state.setModalDataForTabChange,
  createTask: state.createTask,
  updateTask: state.updateTask,
  deleteTask: state.deleteTask,
  editTaskContent: state.editTaskContent,
  uploadSectionData: state.uploadSectionData,
  updateStoreForUnreadTasks: state.updateStoreForUnreadTasks,
});

const layoutStoreSelector = (state: LayoutStoreState) => ({
  setRightAsideOpenToTrue: state.setRightAsideOpenToTrue,
  isRightAsideOpen: state.isRightAsideOpen,
  setTaskIdForNotebook: state.setTaskIdForNotebook,
  removeSelectedMember: state.removeSelectedMember,
  setSelectedMember: state.setSelectedMember,
});

const idMapSelector = (store: NotebookStore) => store.idMap;

const useNotebookTasksController = (
  type: TaskCategories,
  tabType: NotebookTabs,
  shouldShowModalForCurrentUser: boolean,
  markTasksAsRead: (taskIds: string[]) => void,
) => {
  const { search } = useLocation();
  const notes = useNotebookStore(
    useCallback((store) => store.notes[tabType][type], [tabType, type]),
  );
  const { tasks, total } = notes;

  const {
    erroredTaskIDs,
    setModalData,
    createTask,
    updateTask,
    deleteTask,
    editTaskContent,
    setModalDataForTabChange,
    updateStoreForUnreadTasks,
  } = useNotebookStore(notebookTasksSelector, shallow);
  const selectedTaskId = useMemo(() => {
    const query = new URLSearchParams(search);
    return query.get('taskId');
  }, [search]);
  const idMap = useNotebookStore(idMapSelector);

  const selectedRightDrawerTaskId = useMemo(
    () => (selectedTaskId ? idMap[selectedTaskId] : undefined),
    [idMap, selectedTaskId],
  );

  const { push } = useHistory();
  const { data: profileInfo } = useProfileInfoFetchQuery();
  const [taskIdFocused, setTaskIdOfFocusedTask] = useState<string>('');
  const [searchValueForAssignees, setSearchValueForAssignees] =
    useState<string>('');

  const currentUserId = useMemo(
    () => profileInfo?.member.memberId,
    [profileInfo?.member.memberId],
  );

  const {
    data: assigneesListData,
    isLoading,
    hasNextPage: hasMoreAssigneesToFetch,
    isFetchingNextPage: isFetchingMoreAssignees,
    fetchNextPage: fetchMoreAssignees,
  } = useGetNotebookTaskAssigneeList(
    taskIdFocused,
    !isEmpty(taskIdFocused),
    searchValueForAssignees,
  );

  const {
    setRightAsideOpenToTrue,
    isRightAsideOpen,
    setTaskIdForNotebook,
    removeSelectedMember,
    setSelectedMember,
  } = useLayoutStore(layoutStoreSelector, shallow);

  const assigneesList = useMemo(() => {
    if (!isEmpty(taskIdFocused)) {
      if (assigneesListData) {
        const { pages } = assigneesListData;
        return pages.reduce<Assignee[]>(
          (arr, page) => [...arr, ...page.data],
          [],
        );
      }
    }
  }, [assigneesListData, taskIdFocused]);

  const currentUserTimezone = useMemo(
    () => profileInfo?.member.timeZone || '',
    [profileInfo?.member.timeZone],
  );

  const onUpdateTask = useCallback(
    (
      updatedTask: NotebookTask,
      previousCategory: TaskCategories,
      operation?: NotebookOperations,
    ) => {
      if (profileInfo) {
        const { memberId } = profileInfo.member;
        updateTask(
          updatedTask,
          previousCategory,
          memberId,
          operation === NotebookOperations.ASSIGN,
        );
      }
    },
    [profileInfo, updateTask],
  );

  const onTaskClick = useCallback(
    (taskId: string) => {
      if (taskId) {
        removeSelectedMember();
        setTaskIdForNotebook(taskId);
        if (!isRightAsideOpen) {
          setRightAsideOpenToTrue();
        }
        push(`/notebook/${tabType}?taskId=${taskId}`);
      }
    },
    [
      push,
      tabType,
      isRightAsideOpen,
      setRightAsideOpenToTrue,
      setTaskIdForNotebook,
      removeSelectedMember,
    ],
  );

  const getNotebookTaskEventOnUpdate = useCallback(
    (category: TaskCategories) => {
      if (category === TaskCategories.ARCHIVED)
        return NOTEBOOK_TASK_EVENTS.NOTEBOOK_TASK_ARCHIVE;
      return NOTEBOOK_TASK_EVENTS.NOTEBOOK_TASK_STATUS_UPDATE;
    },
    [],
  );

  const setModalPropsForUpdate = useCallback(
    (
      updatedTask: NotebookTask,
      previousCategory: TaskCategories,
      updatedCategory: TaskCategories | MiscTaskCategories,
      callback?: (toggle: boolean) => void,
    ) => {
      const callbackFunction = (
        categoryFromCallback?: TaskCategories | undefined,
      ) => {
        const category = categoryFromCallback
          ? categoryFromCallback
          : (updatedCategory as TaskCategories);
        onUpdateTask(
          {
            ...updatedTask,
            dueDate: getTaskItemDueDate(
              category || TaskCategories.UNSCHEDULED,
              currentUserTimezone,
            ),
            type: category,
          },
          previousCategory,
          NotebookOperations.UPDATE,
        );
        const taskAnalyticsProps = getNotebookTaskAnalyticsProps(
          updatedTask,
          previousCategory,
          updatedTask,
          category,
          tabType,
        );
        trackNotebookTaskActionEvent({
          action: getNotebookTaskEventOnUpdate(category),
          ...taskAnalyticsProps,
        });
        if (callback) {
          callback(category === TaskCategories.COMPLETED);
        }
      };
      setModalData(
        true,
        updatedCategory,
        {
          updatedTask,
          previousCategory,
          updatedCategory,
          callback,
        },
        callbackFunction,
        currentUserId,
      );
    },
    [
      tabType,
      setModalData,
      currentUserId,
      onUpdateTask,
      currentUserTimezone,
      getNotebookTaskEventOnUpdate,
    ],
  );

  const handleTaskCreation = useCallback(
    (task: NotebookTask) => {
      if (!isNotebookEditorEmpty(task.note)) {
        const assignedTo: Assignee = extractAssignedToInformation(
          tabType === NotebookTabs.myWork ? profileInfo : undefined,
        );
        const createdBy = getCreatedByFromProfile(profileInfo);
        createTask({
          ...task,
          assignedTo,
          createdBy,
          createdAt: new Date().toISOString(),
        });
        const taskAnalyticsProps = getNotebookTaskAnalyticsProps(
          task,
          task.type,
          undefined,
          undefined,
          tabType,
        );
        trackNotebookTaskActionEvent({
          action: NOTEBOOK_TASK_EVENTS.NOTEBOOK_TASK_CREATE,
          ...taskAnalyticsProps,
        });
      }
    },
    [createTask, profileInfo, tabType],
  );

  const handleTaskUpdate = useCallback(
    (
      updatedTask: NotebookTask,
      previousCategory: TaskCategories,
      updatedCategory: TaskCategories | MiscTaskCategories,
      callback?: (toggle: boolean) => void,
      isAssignment = false,
    ) => {
      if (updatedCategory === TaskCategories.ARCHIVED) {
        setModalPropsForUpdate(updatedTask, previousCategory, updatedCategory);
      } else if (updatedCategory === MiscTaskCategories.DELETE) {
        setModalData(
          true,
          MiscTaskCategories.DELETE,
          {
            updatedTask,
            previousCategory,
            updatedCategory,
          },
          () => {
            deleteTask(updatedTask.taskId);
            const taskAnalyticsProps = getNotebookTaskAnalyticsProps(
              updatedTask,
              previousCategory,
              updatedTask,
              updatedCategory,
              tabType,
            );
            trackNotebookTaskActionEvent({
              action: NOTEBOOK_TASK_EVENTS.NOTEBOOK_TASK_DELETE,
              ...taskAnalyticsProps,
            });
          },
        );
      } else if (
        updatedCategory === MiscTaskCategories.UNARCHIVE ||
        updatedCategory === MiscTaskCategories.INCOMPLETE
      ) {
        setModalPropsForUpdate(
          updatedTask,
          previousCategory,
          updatedCategory,
          callback,
        );
      } else {
        onUpdateTask(
          {
            ...updatedTask,
            type: updatedCategory,
          },
          previousCategory,
          isAssignment ? NotebookOperations.ASSIGN : NotebookOperations.UPDATE,
        );
        const taskAnalyticsProps = getNotebookTaskAnalyticsProps(
          updatedTask,
          previousCategory,
          updatedTask,
          updatedCategory,
          tabType,
        );
        trackNotebookTaskActionEvent({
          action: NOTEBOOK_TASK_EVENTS.NOTEBOOK_TASK_STATUS_UPDATE,
          ...taskAnalyticsProps,
        });
      }
    },
    [tabType, deleteTask, onUpdateTask, setModalData, setModalPropsForUpdate],
  );

  const handleTaskEdit = useCallback(
    (task: NotebookTask) => {
      editTaskContent(task);
      const taskAnalyticsProps = getNotebookTaskAnalyticsProps(
        task,
        task.type,
        undefined,
        undefined,
        tabType,
      );
      trackNotebookTaskActionEvent({
        action: NOTEBOOK_TASK_EVENTS.NOTEBOOK_TASK_TITLE_UPDATE,
        ...taskAnalyticsProps,
      });
    },
    [tabType, editTaskContent],
  );

  const handleCreatorLinkClick = useCallback(
    (memberData: SelectedMember) => {
      setSelectedMember(memberData);
      if (!isRightAsideOpen) {
        setRightAsideOpenToTrue();
      }
    },
    [isRightAsideOpen, setRightAsideOpenToTrue, setSelectedMember],
  );

  const onTaskDataChange = useCallback(
    (data: NotebookAnalyticsParameters | NotebookTaskAnalyticsParameters) => {
      switch (data.action) {
        case NOTEBOOK_EVENTS.NOTEBOOK_TASK_CREATION:
        case NOTEBOOK_EVENTS.NOTEBOOK_SECTION_EXPANDED:
        case NOTEBOOK_EVENTS.NOTEBOOK_SECTION_COLLAPSED:
          {
            const { action, section } = data as NotebookAnalyticsParameters;
            const notebookAnalyticsProps = getNotebookAnalyticsProps(section);
            trackNotebookActionEvent({
              action,
              ...notebookAnalyticsProps,
            });
          }
          break;

        case NOTEBOOK_TASK_EVENTS.NOTEBOOK_TASK_SHOW:
          {
            const { originalTask, originalCategory } =
              data as NotebookTaskAnalyticsParameters;
            const taskAnalyticsProps = getNotebookTaskAnalyticsProps(
              undefined,
              undefined,
              originalTask,
              originalCategory,
              tabType,
            );
            trackNotebookTaskShowEvent({
              ...taskAnalyticsProps,
              show: NOTEBOOK_TASK_EVENTS.NOTEBOOK_TASK_SHOW,
            });
          }
          break;

        case NOTEBOOK_TASK_EVENTS.NOTEBOOK_TASK_DUE_DATE_UPDATE:
          {
            const {
              action,
              originalTask,
              originalCategory,
              updatedTask,
              updatedCategory,
            } = data as NotebookTaskAnalyticsParameters;
            const taskAnalyticsProps = getNotebookTaskAnalyticsProps(
              originalTask,
              originalCategory,
              updatedTask,
              updatedCategory,
              tabType,
            );
            trackNotebookTaskActionEvent({
              action,
              ...taskAnalyticsProps,
            });
          }
          break;

        default:
          return;
      }
    },
    [tabType],
  );

  const handleAssigneeChange = useCallback(
    (task: NotebookTask, oldAssignee: Assignee, newAssignee: Assignee) => {
      const operation = getAssignmentOperation(oldAssignee, newAssignee);
      if (shouldShowModalForCurrentUser) {
        setModalDataForTabChange(
          true,
          profileInfo?.member?.memberId,
          operation,
          task,
          () => {
            handleTaskUpdate(task, task?.type, task?.type, () => {}, true);
          },
          tabType === NotebookTabs.ato ? oldAssignee : undefined,
        );
      } else {
        handleTaskUpdate(task, task?.type, task?.type, () => {}, true);
      }
      const taskAnalyticsProps = getNotebookTaskAnalyticsProps(
        { ...task, assignedTo: oldAssignee },
        task?.type,
        { ...task, assignedTo: newAssignee },
        task?.type,
        tabType,
      );
      trackNotebookTaskActionEvent({
        action: NOTEBOOK_TASK_EVENTS.NOTEBOOK_TASK_ASSIGNMENT_UPDATE,
        ...taskAnalyticsProps,
      });
    },
    [
      shouldShowModalForCurrentUser,
      setModalDataForTabChange,
      profileInfo?.member?.memberId,
      tabType,
      handleTaskUpdate,
    ],
  );

  const handleAssignmentDropdown = (taskId: string, isDelete?: boolean) => {
    if (isDelete) {
      setTaskIdOfFocusedTask('');
    } else setTaskIdOfFocusedTask(taskId);
  };

  const displayTotal = useMemo(
    () => (HideCountTypes.includes(type) ? undefined : total),
    [total, type],
  );

  const postCollapsibleToggle = (isOpen: boolean) => {
    if (isOpen) {
      onTaskDataChange({
        action: NOTEBOOK_EVENTS.NOTEBOOK_SECTION_EXPANDED,
        section: type,
      });
    } else {
      onTaskDataChange({
        action: NOTEBOOK_EVENTS.NOTEBOOK_SECTION_COLLAPSED,
        section: type,
      });
    }
  };

  const handleEditorUpdate = useCallback(
    (updatedTask: NotebookTask) => {
      const { note } = updatedTask;
      if (!isNotebookEditorEmpty(note)) {
        editTaskContent(updatedTask);
      }
    },
    [editTaskContent],
  );
  const dropdownOptions: DropdownOptions[] = useMemo(
    () => [
      {
        id: 'notebook-task-options',
        items: taskDropdownItems.filter((item: DropdownItemOption) => {
          if (
            item.id === MiscTaskCategories.DELETE ||
            item.id === MiscTaskCategories.UNARCHIVE
          )
            return type === TaskCategories.ARCHIVED;

          return item.id !== type;
        }),
      },
    ],
    [type],
  );

  const updateReadStatusOfTask = (
    taskId: string,
    taskCategory: TaskCategories,
  ) => {
    updateStoreForUnreadTasks(taskId, tabType, taskCategory);
    markTasksAsRead([taskId]);
  };

  return {
    tasks,
    total,
    displayTotal,
    erroredTaskIDs,
    assigneesList,
    handleTaskCreation,
    editTaskContent: handleTaskEdit,
    updateTask: handleTaskUpdate,
    onTaskDataChange,
    onTaskClick,
    setTaskIdOfFocusedTask: handleAssignmentDropdown,
    fetchMoreAssignees,
    postCollapsibleToggle,
    handleEditorUpdate,
    dropdownOptions,
    hasMoreAssigneesToFetch,
    isFetchingMoreAssignees: isLoading || isFetchingMoreAssignees,
    searchValue: searchValueForAssignees,
    handleSearchOnAssigneeList: setSearchValueForAssignees,
    handleAssigneeChange,
    currentUserTimezone,
    updateReadStatusOfTask,
    currentUserId: profileInfo?.member.memberId || '',
    handleCreatorLinkClick,
    selectedRightDrawerTaskId,
    shouldShowModalForCurrentUser,
  };
};

export default useNotebookTasksController;
