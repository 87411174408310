import { useCallback, useMemo, useState } from 'react';
import copy from 'copy-to-clipboard';
import { EmojiData } from 'emoji-mart';
import { FeedPostCommentsProps } from '../../../../atomic/organism/FeedPostComments';
import { useCommentInput } from '../../../../hooks/useFeedCommentInput';
import {
  FeedProps,
  MemberInteractionType,
  Reaction,
  UpdateReactionAction,
} from '../../../../interfaces/Feed';
import { PostTypes } from '../../../../interfaces/Home';
import {
  COMMENT_DELETION_ERROR_MESSAGE,
  COMMENT_DELETION_INPROGRESS_MESSAGE,
  COMMENT_DELETION_SUCCESS_MESSAGE,
  POST_DELETION_ERROR_MESSAGE,
  POST_DELETION_INPROGRESS_MESSAGE,
  POST_DELETION_SUCCESS_MESSAGE,
} from '../../../../languages/en/giveRecognitionForm';
import {
  useAdminDeleteComment,
  useGetFlowPostComments,
  useGetPostComments,
  useUpdateCommentReactionMutation,
} from '../../../../queries/Feed/comments';
import { getCommentPropsFromResponse } from '../../../../queries/Feed/utils';
import { GetProfileInfoResponse } from '../../../../queries/Profile';
import { getCanDeletePosts } from '../../../../queries/Profile/utils';
import {
  dismissAllToasts,
  showErrorMessage,
  showInfoMessage,
  showSuccessMessage,
} from '../../../../Utils/toast';
import { isUserAdmin } from '../../../../Utils/user';
import {
  getEmojiPayload,
  serializeReactionTogglePayload,
} from '../../../../Utils/serializers/feed';
import {
  getFlowPostUrl,
  getRecognitionPostUrl,
} from '../../../../Utils/home/feeds';
import { POST_ANALYTICS_EVENTS } from '../../../../Utils/analytics/constants';
import useGetPageName from '../../../../hooks/useGetPageName';
import {
  ALLOW_THE_RECEIVER_TO_KEEP,
  GIVE_TROPHIES_BACK_TO_SENDER,
} from '../../../../languages/en/home';
import { useLegacyDeletePost } from '../../../../queries/Feed';
import {
  useDeleteFlowPost,
  useFetchFlowFileQuery,
} from '../../../../queries/Flows/Feed';
import { COPIED_TO_CLIPBOARD } from '../../../../languages/en/flows';
import { trackPostEvent as trackPostEventMetric } from '../../../../Utils/analytics';
import { downloadFeedFile } from '../../../../Utils/flows';
import { DownloadBlockType } from '../../../../atomic/organism/FlowPostBody/types';

type UseFlowPostControllerProps = {
  profileData?: GetProfileInfoResponse;
  post: FeedProps;
  onMemberClick: (person: MemberInteractionType) => void;
  onPostDelete?: () => void;
  onReactionSet: (emoji: EmojiData, contentId: string) => void;
  onReactionUnSet: (reaction: Reaction, contentId: string) => void;
};
const useFlowPostController = (props: UseFlowPostControllerProps) => {
  const {
    profileData,
    post,
    onPostDelete,
    onMemberClick,
    onReactionSet,
    onReactionUnSet,
  } = props;

  const { page } = useGetPageName();

  const newTrackPostEvent = useCallback(
    (action: string) => {
      const postEventProps = {
        feedViewed: page,
        numPostComments: post.commentsCount,
        responseId: post.responseId || null,
        numPostReactions: post.reactionBarProps.reactions.length,
        postId: post.type === PostTypes.RECOGNITION ? post.postId : null,
        flowId: post.flowId || PostTypes.RECOGNITION.toString(),
      };
      trackPostEventMetric('post.events', {
        ...postEventProps,
        event: 'action',
        action,
      });
    },
    [page, post],
  );

  const [isCurrentUserAdmin, currentUserId] = useMemo(() => {
    if (!profileData) {
      return [false, ''];
    }
    return [isUserAdmin(profileData.member), profileData.member.memberId];
  }, [profileData]);

  const [deletePostCarrotOption, setDeletePostCarrotOption] =
    useState<string>();

  const [filePreviewFile, setFilePreviewFile] = useState<
    DownloadBlockType | undefined
  >(undefined);

  const postType = post.type;
  const { postId, responseId, flowId, commentsCount, trophyReceivers } = post;
  const { commentInputProps } = useCommentInput({
    profileData,
    postId,
    postType,
    responseId,
    flowId,
    trophyReceivers,
  });

  const { onEditorFocus, onEditorBlur, onPostClick } = commentInputProps;

  const getPostCommentsEnabled =
    commentsCount > 0 && postType !== PostTypes.FLOW;
  const {
    data: commentsPaginatedData,
    hasNextPage: commentsHasNextPage,
    fetchNextPage: fetchNextComments,
  } = useGetPostComments(postId, getPostCommentsEnabled);

  const getFlowPostCommentsEnabled =
    commentsCount > 0 && postType === PostTypes.FLOW;
  const {
    data: flowCommentsPaginatedData,
    hasNextPage: flowCommentsHasNextPage,
    fetchNextPage: fetchNextFlowComments,
  } = useGetFlowPostComments(
    flowId || '',
    responseId || '',
    getFlowPostCommentsEnabled,
  );

  const { mutate: updateCommentReaction } = useUpdateCommentReactionMutation();

  const handleSetFilePreview = useCallback(
    (value: DownloadBlockType | undefined) => setFilePreviewFile(value),
    [setFilePreviewFile],
  );

  const handleAdminDeletePostSuccess = useCallback(() => {
    dismissAllToasts();
    showSuccessMessage(POST_DELETION_SUCCESS_MESSAGE);
    if (onPostDelete) {
      onPostDelete();
    }
  }, [onPostDelete]);

  const handleAdminDeletePostError = useCallback(() => {
    dismissAllToasts();
    showErrorMessage(POST_DELETION_ERROR_MESSAGE);
  }, []);

  const { mutate: deleteRecognitionPost } = useLegacyDeletePost(
    handleAdminDeletePostSuccess,
    handleAdminDeletePostError,
  );
  const { mutate: deleteFlowPost } = useDeleteFlowPost(
    handleAdminDeletePostSuccess,
    handleAdminDeletePostError,
  );

  const handleDeletePostOptionChange = useCallback(
    (value: string) => {
      setDeletePostCarrotOption(value);
    },
    [setDeletePostCarrotOption],
  );
  const handleDeletePost = useCallback(() => {
    dismissAllToasts();
    showInfoMessage(POST_DELETION_INPROGRESS_MESSAGE);
    if (post.headerProps.hasTrophies) {
      if (deletePostCarrotOption === GIVE_TROPHIES_BACK_TO_SENDER) {
        if (postType === PostTypes.FLOW && flowId && responseId) {
          deleteFlowPost({ flowId, responseId, returnPoints: true });
        } else {
          deleteRecognitionPost({ postId, isWithCarrots: true });
        }
      }
      if (deletePostCarrotOption === ALLOW_THE_RECEIVER_TO_KEEP) {
        if (postType === PostTypes.FLOW && flowId && responseId) {
          deleteFlowPost({ flowId, responseId, returnPoints: false });
        } else {
          deleteRecognitionPost({
            postId,
            isWithCarrots: false,
          });
        }
      }
    } else if (postType === PostTypes.FLOW && flowId && responseId) {
      deleteFlowPost({ flowId, responseId, returnPoints: false });
    } else {
      deleteRecognitionPost({ postId, isWithCarrots: false });
    }
  }, [
    deleteFlowPost,
    deletePostCarrotOption,
    deleteRecognitionPost,
    flowId,
    post.headerProps.hasTrophies,
    postId,
    postType,
    responseId,
  ]);

  const onCopyCommentLink = useCallback(
    (commentID) => {
      let link;
      if (postType === PostTypes.FLOW && flowId && responseId) {
        link = getFlowPostUrl(flowId, responseId);
      } else {
        link = getRecognitionPostUrl(postId);
      }
      link = link.concat(`&commentId=${commentID}`);
      copy(link);
      dismissAllToasts();
      showSuccessMessage(COPIED_TO_CLIPBOARD);
    },
    [flowId, postId, postType, responseId],
  );

  const handleAdminDeleteCommentSuccess = useCallback(() => {
    dismissAllToasts();
    showSuccessMessage(COMMENT_DELETION_SUCCESS_MESSAGE);
  }, []);
  const handleAdminDeleteCommentError = useCallback(() => {
    showErrorMessage(COMMENT_DELETION_ERROR_MESSAGE);
  }, []);
  const { mutate: deleteComment } = useAdminDeleteComment(
    handleAdminDeleteCommentSuccess,
    handleAdminDeleteCommentError,
  );
  const onDeleteCommentClick = useCallback(
    (commentId, hasTrophies, deleteCommentOption) => {
      dismissAllToasts();
      showInfoMessage(COMMENT_DELETION_INPROGRESS_MESSAGE);
      deleteComment({
        postId,
        commentId,
        isWithCarrots:
          hasTrophies && deleteCommentOption === GIVE_TROPHIES_BACK_TO_SENDER,
        postType,
        flowId,
        responseId,
      });
    },
    [deleteComment, flowId, postId, postType, responseId],
  );

  const onCommentReactionToggleClick = useCallback(
    ({ reaction, active }: Reaction, contentID: string) => {
      if (profileData) {
        serializeReactionTogglePayload(
          reaction,
          contentID,
          active ? UpdateReactionAction.UNSET : UpdateReactionAction.SET,
          profileData.member,
        );
        updateCommentReaction({
          ...serializeReactionTogglePayload(
            reaction,
            contentID,
            active ? UpdateReactionAction.UNSET : UpdateReactionAction.SET,
            profileData.member,
          ),
          postID: postId,
          postType,
          flowId,
          responseId,
        });
      }
    },
    [profileData, updateCommentReaction, postId, postType, flowId, responseId],
  );

  const onCommentEmoticonClick = useCallback(
    (reactions: Reaction[]) => (emoji: EmojiData, contentID: string) => {
      const [selectedReaction, payload] = getEmojiPayload(reactions, emoji);
      // CURRENTLY HANDLING IF AN EMOTICON THAT IS ALREADY SELECTED BY THE USER IS CLICKED
      if (profileData && !selectedReaction?.active) {
        updateCommentReaction({
          ...serializeReactionTogglePayload(
            payload,
            contentID,
            UpdateReactionAction.SET,
            profileData.member,
          ),
          postID: postId,
          postType,
          flowId,
          responseId,
        });
      }
    },
    [profileData, updateCommentReaction, postId, postType, flowId, responseId],
  );

  const postCommentsProps: FeedPostCommentsProps = useMemo(() => {
    const assemblyCurrency = profileData?.assembly.currency;
    if (postType === PostTypes.FLOW) {
      return {
        feedCommentProps: getCommentPropsFromResponse(
          flowCommentsPaginatedData,
          currentUserId,
          isCurrentUserAdmin,
          getCanDeletePosts(profileData),
          onMemberClick,
          assemblyCurrency,
          onDeleteCommentClick,
          onCopyCommentLink,
          onCommentEmoticonClick,
          onCommentReactionToggleClick,
        ),
        showMoreCommentsButton:
          commentsCount > 3 && (flowCommentsHasNextPage || false),
        onShowMoreCommentsButtonClick: fetchNextFlowComments,
      };
    }
    return {
      feedCommentProps: getCommentPropsFromResponse(
        commentsPaginatedData,
        currentUserId,
        isCurrentUserAdmin,
        getCanDeletePosts(profileData),
        onMemberClick,
        assemblyCurrency,
        onDeleteCommentClick,
        onCopyCommentLink,
        onCommentEmoticonClick,
        onCommentReactionToggleClick,
      ),
      showMoreCommentsButton:
        commentsCount > 3 && (commentsHasNextPage || false),
      onShowMoreCommentsButtonClick: fetchNextComments,
    };
  }, [
    postType,
    commentsPaginatedData,
    currentUserId,
    isCurrentUserAdmin,
    onMemberClick,
    profileData,
    onDeleteCommentClick,
    onCopyCommentLink,
    onCommentEmoticonClick,
    onCommentReactionToggleClick,
    commentsCount,
    commentsHasNextPage,
    fetchNextComments,
    flowCommentsPaginatedData,
    flowCommentsHasNextPage,
    fetchNextFlowComments,
  ]);

  const handleReactionSet = useCallback(
    (emoji: EmojiData, contentId: string) => {
      onReactionSet(emoji, contentId);
      newTrackPostEvent(POST_ANALYTICS_EVENTS.COMMENT_REACTION_ADDED);
    },
    [onReactionSet, newTrackPostEvent],
  );

  const handleReactionUnSet = useCallback(
    (reaction: Reaction, contentId: string) => {
      onReactionUnSet(reaction, contentId);
      newTrackPostEvent(POST_ANALYTICS_EVENTS.COMMENT_REACTION_REMOVED);
    },
    [onReactionUnSet, newTrackPostEvent],
  );

  const onFlowClick = useCallback(
    (flowID: string) => {
      post.headerProps.handleOnFlowClick({ flowId: flowID });
      newTrackPostEvent(POST_ANALYTICS_EVENTS.FLOW_CLICKED);
    },
    [newTrackPostEvent, post.headerProps],
  );

  const onSeeFullPostClick = useCallback(() => {
    newTrackPostEvent(POST_ANALYTICS_EVENTS.SEE_FULL_POST_CLICK);
  }, [newTrackPostEvent]);

  const onDownloadFileClick = useCallback(
    async (fileDownload) => {
      await downloadFeedFile({
        blockId: fileDownload.id,
        instanceId: post.instanceId,
        responseId: post.responseId,
        fileName: fileDownload.fileName,
        flowId: post.headerProps.flow.flowId,
      });
      newTrackPostEvent(POST_ANALYTICS_EVENTS.FILE_DOWNLOADED);
    },
    [post.headerProps.flow.flowId, post.instanceId, newTrackPostEvent],
  );

  const { data: fileToPreview } = useFetchFlowFileQuery(
    {
      responseId: post.responseId,
      blockId: filePreviewFile ? filePreviewFile.id : '',
      instanceId: post.instanceId ? post.instanceId : '',
      fileName: filePreviewFile ? filePreviewFile.fileName : '',
      flowId: flowId ? flowId : '',
    },
    filePreviewFile !== undefined,
  );

  const onCopyLinkClick = useCallback(() => {
    newTrackPostEvent(POST_ANALYTICS_EVENTS.LINK_COPIED);
    post.headerProps.onCopyLink();
  }, [newTrackPostEvent, post.headerProps]);

  const handleMemberClick = useCallback(
    (member: MemberInteractionType) => {
      newTrackPostEvent(POST_ANALYTICS_EVENTS.LINK_COPIED);
      onMemberClick(member);
    },
    [newTrackPostEvent, onMemberClick],
  );

  const onCommentExited = useCallback(() => {
    onEditorBlur();
    newTrackPostEvent(POST_ANALYTICS_EVENTS.COMMENT_EXITED);
  }, [newTrackPostEvent, onEditorBlur]);

  const onUserNameClick = useCallback(
    (data: MemberInteractionType) => {
      newTrackPostEvent(POST_ANALYTICS_EVENTS.MENTIONED_MEMBER_NAME_CLICKED);
      post.headerProps.handleMemberClick(data);
    },
    [newTrackPostEvent, post.headerProps],
  );

  const onCommentPosted = useCallback(() => {
    onPostClick();
    newTrackPostEvent(POST_ANALYTICS_EVENTS.COMMENT_POSTED);
  }, [newTrackPostEvent, onPostClick]);

  const onCommentTextFocus = useCallback(() => {
    onEditorFocus();
    newTrackPostEvent(POST_ANALYTICS_EVENTS.COMMENT_STARTED);
  }, [newTrackPostEvent, onEditorFocus]);

  return {
    operations: {
      onFlowClick,
      onCopyLinkClick,
      onCommentExited,
      onUserNameClick,
      onCommentPosted,
      onCommentTextFocus,
      onDownloadFileClick,
      onSeeFullPostClick,
      handleReactionSet,
      handleReactionUnSet,
      handleDeletePostOptionChange,
      handleDeletePost,
      handleMemberClick,
      handleSetFilePreview,
    },
    commentInputProps,
    postCommentsProps,
    fileToPreview,
  };
};

export default useFlowPostController;
