import { Hidden } from '@material-ui/core';
import React from 'react';
import ThemeV2 from '../../../componentsV2/theme';
import { Flex } from '../../../Utils/styles/display';
import Body from '../../atoms/Body';
import SVGIcon from '../../atoms/SVGIcon';
import { ErrorProps } from './interfaces';
import {
  ErrorIconContainer,
  StyledFlex,
  StyledHeading,
  StyledPageHeaderMainContent,
} from './styles';
import MainFeedLinkButton from '../MainFeedLinkButton';

const Error = (props: ErrorProps) => {
  const { hasBottomBorder = true, title, subTitle } = props;
  return (
    <StyledFlex borderBottom={hasBottomBorder}>
      <ErrorIconContainer>
        <SVGIcon
          icon="error-warning"
          size="32px"
          color={ThemeV2.palette.gray7}
        />
      </ErrorIconContainer>
      <StyledPageHeaderMainContent>
        <Flex>
          <StyledHeading variant="h6">{title}</StyledHeading>
        </Flex>
        <Hidden only={['xs']}>
          <Body variant="body3" color="gray7">
            {subTitle}
          </Body>
        </Hidden>
      </StyledPageHeaderMainContent>
      <MainFeedLinkButton />
    </StyledFlex>
  );
};

export default Error;
