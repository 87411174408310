import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { ANONYMOUS, DATES } from '../../../../languages/en/singleWords';
import { managerStatusOptions, responseOptions } from './data';
import { ComponentStatus } from '../../../../interfaces/component';
import { useGetFlowParticipants } from '../../../../hooks/useMembersSearch';
import { DateFilterProps } from '../../../../atomic/organism/DateFilter/types';
import { useGetOccurrencesForFlowIdQuery } from '../../../../queries/Flows/Insights';
import { CheckboxFilterProps } from '../../../../atomic/organism/CheckboxFilter/types';
import { useGetAllDepartmentsQuery } from '../../../../queries/FlowBuilder/Participants';
import useLayoutStore from '../../../../stores/layoutStore';

import {
  DEPARTMENT_LABEL,
  PARTICIPANT_LABEL,
  MANAGER_STATUS_LABEL,
  SELECT_DEPARTMENT_LABEL,
  SELECT_PARTICIPANT_LABEL,
  OCCURRENCE_START_DATE_LABEL,
  INCLUDE_RESPONSES_FROM_LABEL,
  FILTER_BY_DIFFERENT_DEPARTMENT_LABEL,
  FILTER_BY_DIFFERENT_PARTICIPANT_LABEL,
  FILTER_BY_OCCURRENCE_START_DATE_LABEL,
} from '../../../../languages/en/flows/insights';

import {
  mapOccurrenceDateToFilterOptions,
  mapDepartmentsDataToFilterOptions,
  mapParticipantsDataToFilterOptions,
} from './utils';
import { LayoutStoreState } from '../../../../interfaces/Layout';
import { useFetchFlowDetailsQuery } from '../../../../queries/Flows/Feed';
import { AnonymityStates } from '../../../../queries/Flows/interfaces';
import { ANONYMOUS_MEMBER_ID } from '../../../../Utils/rightDrawer';
import { MemberState } from '../../../../interfaces/user';

const layoutSelector = (state: LayoutStoreState) => state.toggleRightAsideOpen;

const useFlowsInsightsRightDrawerController = () => {
  const { flowId } = useParams<{ flowId: string }>();
  const { data: flowDetails } = useFetchFlowDetailsQuery(flowId, 'builder');

  const { search } = useLocation();
  const toggleRightAsideOpen = useLayoutStore(layoutSelector);
  const params = new URLSearchParams(search);

  const [isFilterButtonEnabled, setIsFilterButtonEnabled] = useState(false);

  // Date Filter
  const [startDate, setStartDate] = useState<Date | undefined>(() =>
    params.get('startDate')
      ? new Date(String(params.get('startDate')))
      : undefined,
  );

  const [endDate, setEndDate] = useState<Date | undefined>(() =>
    params.get('endDate') ? new Date(String(params.get('endDate'))) : undefined,
  );

  const dateFilterProps: DateFilterProps = useMemo(() => {
    return {
      endDate: endDate,
      headingText: DATES,
      startDate: startDate,
      onEndDateChange: setEndDate,
      status: ComponentStatus.LOADED,
      onStartDateChange: setStartDate,
      initialStartDate: new Date(1, 0, 1),
    };
  }, [endDate, startDate]);

  // Response Filter
  const [selectedResponses, setSelectedResponses] = useState<string[]>(
    params.get('responses')?.toString().split(',') || [],
  );

  const responsesFilter: CheckboxFilterProps = useMemo(() => {
    return {
      filterByText: '',
      autoCompleteText: '',
      options: responseOptions,
      status: ComponentStatus.LOADED,
      selectedOptions: selectedResponses,
      headingText: INCLUDE_RESPONSES_FROM_LABEL,
      onSelectedOptionChange: setSelectedResponses,
    };
  }, [selectedResponses]);

  // Manager Status Filter
  const [selectedManagerStatus, setSelectedManagerStatus] = useState<string[]>(
    params.get('managerStatus')?.toString().split(',') || [],
  );

  const managerStatusFilter: CheckboxFilterProps = useMemo(() => {
    return {
      filterByText: '',
      autoCompleteText: '',
      headingText: MANAGER_STATUS_LABEL,
      options: managerStatusOptions,
      status: ComponentStatus.LOADED,
      selectedOptions: selectedManagerStatus,
      onSelectedOptionChange: setSelectedManagerStatus,
    };
  }, [selectedManagerStatus]);

  // Department Filter
  const [selectedDepartment, setSelectedDepartment] = useState<string[]>(
    params.get('department')?.toString().split(',') || [],
  );

  const {
    data: departmentsData,
    isError: isDepartmentsError,
    isLoading: isDepartmentsLoading,
  } = useGetAllDepartmentsQuery();

  const departmentOptions = useMemo(() => {
    if (departmentsData) {
      return mapDepartmentsDataToFilterOptions(departmentsData?.data);
    }

    return [];
  }, [departmentsData]);

  const departmentFilter: CheckboxFilterProps = useMemo(() => {
    if (isDepartmentsLoading) {
      return {
        headingText: DEPARTMENT_LABEL,
        status: ComponentStatus.LOADING,
      };
    }

    if (isDepartmentsError) {
      return {
        headingText: DEPARTMENT_LABEL,
        status: ComponentStatus.EMPTY,
      };
    }

    return {
      options: departmentOptions,
      headingText: DEPARTMENT_LABEL,
      status: ComponentStatus.LOADED,
      selectedOptions: selectedDepartment,
      autoCompleteText: SELECT_DEPARTMENT_LABEL,
      onSelectedOptionChange: setSelectedDepartment,
      filterByText: FILTER_BY_DIFFERENT_DEPARTMENT_LABEL,
    };
  }, [
    departmentsData,
    selectedDepartment,
    isDepartmentsError,
    isDepartmentsLoading,
  ]);

  // Participant Filter
  const [selectedParticipant, setSelectedParticipant] = useState<string[]>(
    params.get('participant')?.toString().split(',') || [],
  );

  const {
    models: {
      isError: isMembersSearchError,
      isLoading: isMembersSearchLoading,
      searchedMembers,
    },
  } = useGetFlowParticipants(flowId, true);

  const participantOptions = useMemo(() => {
    let participantFilterItems: any[] = [];
    if (searchedMembers) {
      participantFilterItems =
        mapParticipantsDataToFilterOptions(searchedMembers);
    }

    if (
      flowDetails?.data.responseSettings.anonymity.state !==
      AnonymityStates.DISABLED
    ) {
      const anonymousFilterItem = {
        value: false,
        label: ANONYMOUS,
        id: ANONYMOUS_MEMBER_ID,
        img: '',
        showAvatar: true,
        memberState: MemberState.ACTIVE,
        isAnonymous: true,
      };

      participantFilterItems = [anonymousFilterItem, ...participantFilterItems];
    }

    return participantFilterItems;
  }, [searchedMembers, flowDetails]);

  const participantFilter: CheckboxFilterProps = useMemo(() => {
    if (isMembersSearchLoading) {
      return {
        headingText: PARTICIPANT_LABEL,
        status: ComponentStatus.LOADING,
      };
    }

    if (isMembersSearchError) {
      return {
        headingText: PARTICIPANT_LABEL,
        status: ComponentStatus.EMPTY,
      };
    }

    return {
      options: participantOptions,
      headingText: PARTICIPANT_LABEL,
      status: ComponentStatus.LOADED,
      selectedOptions: selectedParticipant,
      autoCompleteText: SELECT_PARTICIPANT_LABEL,
      onSelectedOptionChange: setSelectedParticipant,
      filterByText: FILTER_BY_DIFFERENT_PARTICIPANT_LABEL,
    };
  }, [
    searchedMembers,
    selectedParticipant,
    isMembersSearchError,
    isMembersSearchLoading,
  ]);

  // Occurrence Start Date
  const [occurenceStartDate, setOccurenceStartDate] = useState<string[]>(
    params.get('occurenceStartDate')?.toString().split(',') || [],
  );

  const { data: occurrencesData, isFetching: isFetchingOccurrenceData } =
    useGetOccurrencesForFlowIdQuery(flowId);

  const occurrenceDateOptions = useMemo(() => {
    if (occurrencesData) {
      return mapOccurrenceDateToFilterOptions(occurrencesData.data);
    }

    return [];
  }, [occurrencesData]);

  const occurrenceStartDateFilter: CheckboxFilterProps = useMemo(() => {
    if (isFetchingOccurrenceData) {
      return {
        status: ComponentStatus.LOADING,
        headingText: OCCURRENCE_START_DATE_LABEL,
      };
    }

    if (isMembersSearchError) {
      return {
        status: ComponentStatus.EMPTY,
        headingText: OCCURRENCE_START_DATE_LABEL,
      };
    }

    return {
      status: ComponentStatus.LOADED,
      options: occurrenceDateOptions,
      selectedOptions: occurenceStartDate,
      headingText: OCCURRENCE_START_DATE_LABEL,
      autoCompleteText: SELECT_PARTICIPANT_LABEL,
      onSelectedOptionChange: setOccurenceStartDate,
      filterByText: FILTER_BY_OCCURRENCE_START_DATE_LABEL,
    };
  }, [
    occurenceStartDate,
    isMembersSearchError,
    occurrenceDateOptions,
    isFetchingOccurrenceData,
  ]);

  // Operations
  const history = useHistory();

  const handleOnSubmit = useCallback(() => {
    const url = new URL(window.location.href);
    setIsFilterButtonEnabled(false);

    if (startDate) {
      url.searchParams.set('startDate', startDate.toString());
    } else {
      url.searchParams.delete('startDate');
    }

    if (endDate) {
      url.searchParams.set('endDate', endDate.toString());
    } else {
      url.searchParams.delete('endDate');
    }

    if (selectedResponses && selectedResponses.length) {
      url.searchParams.set('responses', selectedResponses.join(','));
    } else {
      url.searchParams.delete('responses');
    }

    if (selectedManagerStatus && selectedManagerStatus.length) {
      url.searchParams.set('managerStatus', selectedManagerStatus.join(','));
    } else {
      url.searchParams.delete('managerStatus');
    }

    if (selectedDepartment && selectedDepartment.length) {
      url.searchParams.set('department', selectedDepartment.join(','));
    } else {
      url.searchParams.delete('department');
    }

    if (selectedParticipant && selectedParticipant.length) {
      url.searchParams.set('participant', selectedParticipant.join(','));
    } else {
      url.searchParams.delete('participant');
    }

    if (occurenceStartDate && occurenceStartDate.length) {
      url.searchParams.set('occurenceStartDate', occurenceStartDate.join(','));
    } else {
      url.searchParams.delete('occurenceStartDate');
    }

    history.replace({
      pathname: window.location.pathname,
      search: url.search,
    });
  }, [
    endDate,
    history,
    startDate,
    selectedResponses,
    occurenceStartDate,
    selectedDepartment,
    selectedParticipant,
    selectedManagerStatus,
  ]);

  const handleOnClear = useCallback(() => {
    setEndDate(undefined);
    setStartDate(undefined);
    setSelectedResponses([]);
    setSelectedDepartment([]);
    setOccurenceStartDate([]);
    setSelectedParticipant([]);
    setSelectedManagerStatus([]);

    setIsFilterButtonEnabled(false);

    history.replace({
      pathname: window.location.pathname,
      search: '',
    });
  }, [flowId, history]);

  useEffect(() => {
    setIsFilterButtonEnabled(
      startDate?.toString() !==
        (params.get('startDate')
          ? new Date(String(params.get('startDate'))).toString()
          : undefined) ||
        endDate?.toString() !==
          (params.get('endDate')
            ? new Date(String(params.get('endDate'))).toString()
            : undefined) ||
        occurenceStartDate.join() !==
          (params.get('occurenceStartDate')?.toString() || '') ||
        selectedResponses.join() !==
          (params.get('responses')?.toString() || '') ||
        selectedDepartment.join() !==
          (params.get('department')?.toString() || '') ||
        selectedParticipant.join() !==
          (params.get('participant')?.toString() || '') ||
        selectedManagerStatus.join() !==
          (params.get('managerStatus')?.toString() || ''),
    );
  }, [
    params,
    endDate,
    startDate,
    selectedResponses,
    selectedDepartment,
    selectedParticipant,
    selectedManagerStatus,
  ]);

  return {
    models: {
      dateFilterProps: dateFilterProps,
      responsesFilter: responsesFilter,
      departmentsFilter: departmentFilter,
      participantFilter: participantFilter,
      managerStatusFilter: managerStatusFilter,
      isFilterButtonEnabled: isFilterButtonEnabled,
      hasShortcut: Boolean(flowDetails?.data.shortcut),
      occurrenceStartDateFilter: occurrenceStartDateFilter,
    },
    operations: {
      handleOnClear,
      handleOnSubmit,
      toggleRightAsideOpen,
    },
  };
};

export default useFlowsInsightsRightDrawerController;
