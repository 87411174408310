import { useEffect, useMemo } from 'react';
import { EmailNotificationSettingFlowItemProps } from '../../atomic/molecules/EmailNotificationSettingsRow/types';
import { EMAIL_NOTIFICATION_SETTINGS } from '../../languages/en/flows';
import {
  FlowsNotificationItem,
  FlowsNotificationItemPreferenceTypes,
  FlowsNotificationPayload,
  useFlowsEmailSettingsMutation,
  useGetFlowNotificationPreferencesQuery,
  useGlobalEmailSettingsMutation,
  User,
  useUserInfoQuery,
  useUserSettingsMutation,
} from '../../queries/Settings';
import { mapHexCodeToEmoticon } from '../../Utils/mappers';
import { showErrorMessage } from '../../Utils/toast';

const createPayloadForUpdateUserSettings = (
  currentUser: User,
  notificationIdToChange: string,
) => {
  const { department, firstName, lastName, managers, reports } =
    currentUser.profile;
  const {
    notifyActivity,
    notifyAllowance,
    notifyAnniversary,
    notifyComments,
    notifyNewCarrots,
  } = currentUser.settings.emailPreferences;

  return {
    firstName,
    lastName,
    managers,
    reports,
    department,
    notifyActivity,
    notifyAllowance,
    notifyAnniversary,
    notifyComments,
    notifyNewCarrots,
    [notificationIdToChange]:
      !currentUser.settings.emailPreferences[notificationIdToChange],
  };
};

const useNotificationSettings = () => {
  const {
    data,
    isError,
    isLoading: isGlobalSettingsLoading,
  } = useUserInfoQuery();
  const { isError: isMutationError, mutate } = useUserSettingsMutation();
  const {
    data: flowNotificationPreferences,
    isLoading: isFlowItemSettingsLoading,
  } = useGetFlowNotificationPreferencesQuery();
  const {
    isError: updateFlowSettingsOptionError,
    mutate: updateFlowSettingsOption,
  } = useFlowsEmailSettingsMutation();

  const { mutate: updateGlobalSettingsOption } =
    useGlobalEmailSettingsMutation();

  const flowItems = flowNotificationPreferences?.email.entities.flows;

  const flowItemsWithPreference:
    | EmailNotificationSettingFlowItemProps[]
    | undefined
    | 0 = useMemo(() => {
    return (
      flowItems?.length &&
      flowItems.map((flow: FlowsNotificationItem) => {
        return {
          id: flow.entityId,
          title: flow.name,
          emoticon: mapHexCodeToEmoticon(flow.icon.value),
          flowGroup: [
            {
              groupName:
                EMAIL_NOTIFICATION_SETTINGS.flows.otherFlows.groupTitle,
              options: [
                {
                  label:
                    EMAIL_NOTIFICATION_SETTINGS.flows.otherFlows.preferences
                      .whenThisFlowStarts,
                  isOn: flow.preferences[0].value || false,
                },
                {
                  label:
                    EMAIL_NOTIFICATION_SETTINGS.flows.otherFlows.preferences
                      .reminder,
                  isOn: flow.preferences[1].value || false,
                },
              ],
            },
          ],
        };
      })
    );
  }, [flowItems]);

  const isNotifyAllowanceOn =
    data?.user.settings.emailPreferences.notifyAllowance;
  const isNotifyNewCarrotsOn =
    data?.user.settings.emailPreferences.notifyNewCarrots;

  const flowItemCelebrateTeamMate: EmailNotificationSettingFlowItemProps =
    useMemo(() => {
      return {
        id: 'celebrateTeamMate',
        title: EMAIL_NOTIFICATION_SETTINGS.flows.celebrateTeamMate.title,
        emoticon: '🎉',
        flowGroup: [
          {
            groupName:
              EMAIL_NOTIFICATION_SETTINGS.flows.celebrateTeamMate.participation,
            options: [
              {
                label:
                  EMAIL_NOTIFICATION_SETTINGS.flows.celebrateTeamMate
                    .participationDescription,
                isOn: isNotifyAllowanceOn || false,
              },
            ],
          },
          {
            groupName:
              EMAIL_NOTIFICATION_SETTINGS.flows.celebrateTeamMate.response,
            options: [
              {
                label:
                  EMAIL_NOTIFICATION_SETTINGS.flows.celebrateTeamMate
                    .responseDescription,
                isOn: isNotifyNewCarrotsOn || false,
              },
            ],
          },
        ],
      };
    }, [isNotifyAllowanceOn, isNotifyNewCarrotsOn]);

  const getCombinedFlowItems: EmailNotificationSettingFlowItemProps[] =
    flowItemsWithPreference !== 0 && flowItemsWithPreference?.length
      ? [flowItemCelebrateTeamMate, ...flowItemsWithPreference]
      : [flowItemCelebrateTeamMate];

  const handleUpdateGlobalPreference = (notificationId: string) => {
    const currentUser = data?.user;
    if (currentUser) {
      const payload = createPayloadForUpdateUserSettings(
        currentUser,
        notificationId,
      );
      mutate(payload);
    }
  };

  const handleToggle = (notificationId: string) => {
    handleUpdateGlobalPreference(notificationId);
    if (notificationId === 'mentions') {
      updateGlobalSettingsOption({
        email: {
          global: {
            mentions: !flowNotificationPreferences?.email.global.mentions,
          },
        },
      });
    }
  };

  const onFlowItemOptionsToggle = (optionId?: string) => {
    let notificationId = '';
    if (optionId === 'celebrateTeamMate_0_0') {
      notificationId = 'notifyAllowance';
    }
    if (optionId === 'celebrateTeamMate_1_0') {
      notificationId = 'notifyNewCarrots';
    }
    handleUpdateGlobalPreference(notificationId);
    const flowPreference = optionId?.split('_');
    const flowId = flowPreference?.length && flowPreference[0];
    const isOptionClickFlowTriggered =
      flowPreference?.length && flowPreference[2] === '0';
    const updatingFlowItem = flowItems?.find(
      (flowItem) => flowItem.entityId === flowId,
    );

    if (updatingFlowItem) {
      let getEntityType = FlowsNotificationItemPreferenceTypes.FLOW_REMINDER;
      if (isOptionClickFlowTriggered) {
        getEntityType = FlowsNotificationItemPreferenceTypes.FLOW_TRIGGERED;
      }

      let getEntityTypeValue = !updatingFlowItem.preferences[1].value;
      if (isOptionClickFlowTriggered) {
        getEntityTypeValue = !updatingFlowItem.preferences[0].value;
      }

      const payload: FlowsNotificationPayload = {
        email: {
          entities: [
            {
              entityId: updatingFlowItem.entityId,
              entityType: 'FLOW',
              type: getEntityType,
              value: getEntityTypeValue,
            },
          ],
        },
      };
      updateFlowSettingsOption(payload);
    }
  };

  useEffect(() => {
    if (isMutationError || updateFlowSettingsOptionError) {
      showErrorMessage('Could not update your settings');
    }
  }, [isMutationError, updateFlowSettingsOptionError]);

  const preferences = {
    globalSettings: {
      ...data?.user.settings.emailPreferences,
      ...flowNotificationPreferences?.email.global,
    },
    flows: getCombinedFlowItems,
  };

  return {
    preferences,
    handleToggle,
    onFlowItemOptionsToggle,
    isError,
    isLoading: isGlobalSettingsLoading || isFlowItemSettingsLoading,
  };
};

export default useNotificationSettings;
