import { capitalizeFirstLetter } from '../../../Utils/text';
import { SOMEONE } from '../singleWords';

export const AND_SAID = 'and said: ';
export const GAVE_YOU = 'gave you';
export const ADDED = 'added';
export const AND = 'and';
export const DEACTIVATED_USER = 'Deactivated User';
export const HAPPY_BIRTHDAY = 'Happy Birthday';
export const HAPPY_ANNIVERSARY = 'Happy Anniversary';
export const MENTIONED_IN_POST = 'mentioned you in a post saying: ';
export const RECOGNIZED_YOU = 'recognized you';
export const COMMENTED_ON_POST = 'commented on a post you’re a part of: ';
export const MENTIONED_IN_COMMENT = 'mentioned you in a comment saying: ';
export const MENTIONED_YOU_IN = 'mentioned you in';
export const DEACTIVATED_USER_MENTIONED_IN_POST = `${DEACTIVATED_USER} ${MENTIONED_IN_POST}`;
export const DEACTIVATED_USER_RECOGNIZED_IN_POST = `${DEACTIVATED_USER} ${RECOGNIZED_YOU} ${AND_SAID}`;
export const RECOGNIZED_IN_POST = `${RECOGNIZED_YOU} ${AND_SAID}`;
export const DEACTIVATED_USER_GAVE_YOU = `${DEACTIVATED_USER} ${GAVE_YOU}`;
export const DEACTIVATED_USER_MENTIONED_IN_COMMENT = `${DEACTIVATED_USER} ${MENTIONED_IN_COMMENT}`;
export const DEACTIVATED_USER_ADDED = `${DEACTIVATED_USER} ${ADDED}`;
export const DEACTIVATED_USER_COMMENTED = `${DEACTIVATED_USER} ${COMMENTED_ON_POST}`;
export const AND_COMMENTED_ON_POST = `${AND} ${COMMENTED_ON_POST}`;
export const GO_TO_NOTIFICATION_SETTINGS = 'Go to Notification Settings';
export const MARK_ALL_AS_READ = 'Mark all as read';
export const REMINDER_LABEL = 'Don’t forget to answer';

export const DEACTIVATED_USER_MENTIONED_YOU = `${DEACTIVATED_USER} ${MENTIONED_YOU_IN}`;

export const getBodyTextForDeactivatedOrAnonymousMentions = (
  isAnonymous: boolean,
) =>
  `${
    isAnonymous
      ? `${capitalizeFirstLetter(SOMEONE)}`
      : DEACTIVATED_USER_MENTIONED_YOU
  }`;
