import styled from 'styled-components';

import ThemeV2 from '../../../componentsV2/theme';

export const CommentWrapper = styled.div<{ isLoading: boolean }>`
  position: relative;
  &:before {
    content: ${({ isLoading }) => (isLoading ? `''` : `unset`)};
    position: absolute;
    height: 100%;
    width: 100%;
    background: ${ThemeV2.palette.gray1};
    filter: opacity(0.5);
    z-index: 3;
  }
`;

export const StyledCommentBody = styled.div`
  padding-left: 48px;

  > div:first-child {
    padding-top: 0;
  }
`;

export const StyledReactionBarContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 4px;
`;
