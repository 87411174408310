import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import shallow from 'zustand/shallow';

import { CreditState } from '../../../interfaces/assembly';
import { UserStatus } from '../../../interfaces/user';
import { AssemblyPlanType } from '../../../interfaces/UserProfile';
import { useNotificationFetchQuery } from '../../../queries/Notifications';
import {
  useCurrentPlanFetchQuery,
  useGetQuickSetupGuideQuery,
  useProfileInfoFetchQuery,
  useRedeemInfoFetchQuery,
} from '../../../queries/Profile';
import useLayoutStore from '../../../stores/layoutStore';
import {
  canCurrentUserGiveAllowance,
  getQuickSetupProgress,
  isUserAdmin,
} from '../../../Utils/user';
import { isMobile } from '../../../Utils/window';
import { ONBOARDING_SETUP, QUICK_SETUP } from '../../../constants/routes';
import { useQuickSetupStore } from '../../../stores/quickSetupStore';
import { QuickSetupStep } from '../../quickSetup/QuickSetupController/types';

const usePrimaryNavModel = () => {
  const { pathname } = useLocation();
  const { data: notificationsData } = useNotificationFetchQuery();
  const { data: profileData } = useProfileInfoFetchQuery();
  const { data: redeemInfoData } = useRedeemInfoFetchQuery();
  const { setLeftAsideOpenToTrue, setLeftAsideOpenToFalse } = useLayoutStore(
    (state) => ({
      setLeftAsideOpenToTrue: state.setLeftAsideOpenToTrue,
      setLeftAsideOpenToFalse: state.setLeftAsideOpenToFalse,
    }),
    shallow,
  );

  useEffect(() => {
    if (profileData?.member) {
      if (profileData.member.profile.showLeftDrawer && !isMobile) {
        setLeftAsideOpenToTrue();
      } else {
        setLeftAsideOpenToFalse();
      }
    }
  }, [profileData?.member, setLeftAsideOpenToTrue, setLeftAsideOpenToFalse]);

  // Quick Setup
  const [isInQuickSetup, setIsInQuickSetup] = useState(false);
  const { currentStep, selectedTemplateName } = useQuickSetupStore();

  useEffect(() => {
    setIsInQuickSetup(
      pathname.includes(QUICK_SETUP) || pathname.includes(ONBOARDING_SETUP),
    );
  }, [pathname]);

  const hideAllowanceDetails = () => {
    if (!profileData?.member) {
      return true;
    }
    return !canCurrentUserGiveAllowance(profileData.member);
  };

  const isRedeemable = redeemInfoData ? redeemInfoData.isRedeemable : false;
  const hideRewardsLink = () => {
    if (
      profileData?.assembly.accounts.creditState === CreditState.DENIED_BY_ADMIN
    ) {
      return true;
    }
    if (
      !profileData?.member ||
      (!isRedeemable && !isUserAdmin(profileData.member))
    ) {
      return true;
    }
    if (isUserAdmin(profileData.member)) {
      return false;
    }
    return !isRedeemable;
  };

  const hideRewardsInfo = () => {
    if (!profileData?.member) {
      return true;
    }
    return (
      (profileData.member.status === UserStatus.OBSERVER ||
        profileData.member.status === UserStatus.GIVER) &&
      profileData.member.pointsLeftThisCycle === 0
    );
  };

  const { data: currentPlanData } = useCurrentPlanFetchQuery();

  const isCurrentUserAdmin = profileData?.member
    ? isUserAdmin(profileData?.member)
    : false;

  const showQuickSetupOption =
    (isCurrentUserAdmin && profileData?.member.profile.showQuickSetupPanel) ||
    false;

  const { data: quickSetupData, isLoading: isQuickSetupDataLoading } =
    useGetQuickSetupGuideQuery(
      isCurrentUserAdmin ? profileData?.member?.memberId : '',
    );

  const isPremiumUser =
    currentPlanData && currentPlanData.name !== AssemblyPlanType.FREE;

  const quickSetupProgress = () => {
    if (quickSetupData && isPremiumUser !== undefined) {
      return getQuickSetupProgress(quickSetupData, isPremiumUser);
    }
    return 0;
  };

  return {
    models: {
      isInQuickSetup,
      hideFlowsList:
        (isInQuickSetup &&
          currentStep !== QuickSetupStep.SELECT_TEMPLATE &&
          currentStep !== QuickSetupStep.ADD_PARTICIPANTS &&
          currentStep !== QuickSetupStep.COMPLETION) ||
        (isInQuickSetup && !selectedTemplateName),
      hideParticipantsList:
        isInQuickSetup && currentStep !== QuickSetupStep.COMPLETION,
      unreadNotificationsCount: notificationsData?.notificationCount || 0,
      pathname,
      currentUserData: profileData?.member,
      hideRedeemButton: !isRedeemable,
      assemblyData: profileData?.assembly,
      hideAllowanceDetails: hideAllowanceDetails(),
      hideRewardsLink: hideRewardsLink(),
      hideRewardsInfo: hideRewardsInfo(),
      isCurrentUserAdmin,
      quickSetupData,
      isQuickSetupDataLoading,
      isPremiumUser,
      quickSetupProgress: quickSetupProgress(),
      showQuickSetupOption,
    },
  };
};

export default usePrimaryNavModel;
