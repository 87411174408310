import React, { ReactNode } from 'react';
import { Main, MainHeader, MainContent } from './styles';
import PageHeaderRouter from '../../../controllers/headers/HeaderRouter';
import ThemeV2 from '../../../componentsV2/theme';
import useLayoutStore from '../../../stores/layoutStore';
import shallow from 'zustand/shallow';

interface PrimaryLayoutProps {
  children: ReactNode;
  isFlowOwner: boolean;
  isNotebookView?: boolean;
  hasTabComponent?: boolean;
  hasContentFooter?: boolean;
}

const MainBody = ({
  children,
  isFlowOwner,
  isNotebookView,
  hasTabComponent,
  hasContentFooter,
}: PrimaryLayoutProps) => {
  const { isLeftAsideOpen, isRightAsideOpen } = useLayoutStore(
    (state) => ({
      isLeftAsideOpen: state.isLeftAsideOpen,
      isRightAsideOpen: state.isRightAsideOpen,
    }),
    shallow,
  );

  return (
    <Main
      background={
        isNotebookView ? `${ThemeV2.palette.white}` : `${ThemeV2.palette.gray1}`
      }
      isLeftAsideOpen={isLeftAsideOpen}
      isRightAsideOpen={isRightAsideOpen}
    >
      <MainHeader
        removeMargin={isNotebookView}
        hasTabComponent={hasTabComponent}
        background={
          isNotebookView
            ? `${ThemeV2.palette.white}`
            : `${ThemeV2.palette.gray1}`
        }
      >
        <PageHeaderRouter />
      </MainHeader>

      <MainContent
        id="scrollableFeedContent"
        hasTabComponent={hasTabComponent}
        hasContentFooter={hasContentFooter}
        hasFlowBuilderTabs={isFlowOwner || isNotebookView}
      >
        {children}
      </MainContent>
    </Main>
  );
};

export default MainBody;
