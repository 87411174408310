import { useMemo, useRef } from 'react';
import { DropdownProps } from '../../atomic/molecules/DropdownListHolder';
import useToggle from '../useToggle';

const useDropDown = (props: DropdownProps) => {
  const { disabled, isFullWidth, menuItems, multiSelect, onItemClick } = props;
  const referenceElement = useRef(null);
  const anchorEl = useRef(null);
  const {
    models: { toggleValue: isOpen },
    operations: {
      setToggleToFalse: closeDropdown,
      setToggleToTrue: openDropdown,
      setToggleValue: toggleDropdown,
    },
  } = useToggle();

  const id = isOpen ? 'spring-popper' : undefined;

  const anchorProps = useMemo(
    () => ({
      id,
      anchorEl,
      disabled,
      isFullWidth,
      menuItems,
      multiSelect,
      onItemClick,
      // from hook
      isOpen,
      referenceElement,
      toggleDropdown,
      closeDropdown,
      openDropdown,
    }),
    [
      id,
      anchorEl,
      disabled,
      isFullWidth,
      menuItems,
      multiSelect,
      onItemClick,
      isOpen,
      toggleDropdown,
      closeDropdown,
      openDropdown,
    ],
  );

  return { anchorProps };
};

export default useDropDown;
