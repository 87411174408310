import React, { useState } from 'react';
import { ComponentStatus } from '../../../interfaces/component';
import { FLOWS_ACTION_BAR_OPTIONS } from '../../../languages/en/flows';
import { CLEAR } from '../../../languages/en/singleWords';
import Body from '../../atoms/Body';

import LoadedComponent from './LoadedComponent';
import LoadingComponent from './LoadingComponent';
import {
  StyledFlex,
  StyledIconButton,
  StyledTextField,
  StyledTextFieldWrapper,
  VerticalDivider,
} from './styles';
import { FlowsActionBarOptionsProps } from './types';

const FlowsActionBarOptions = (props: FlowsActionBarOptionsProps) => {
  const {
    todos,
    feeds,
    shortcuts,
    onCloseButtonClick,
    searchText,
    handleSearchTextChange,
    clearSearchText,
    status,
    placeholder,
    handleSuffixAdornmentClickCb,
  } = props;

  const [currentIndex, setCurrentIndex] = useState(-1);
  const menuItemsRefCount = todos.length + shortcuts.length + feeds.length + 1;

  const handleOnKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'ArrowDown') {
      const index = currentIndex + 1;
      const newIndex = index > menuItemsRefCount - 1 ? 0 : index;
      setCurrentIndex(newIndex);
      event.preventDefault();
    }

    if (event.key === 'ArrowUp') {
      const index = currentIndex - 1;
      const newIndex = index < 0 ? menuItemsRefCount - 1 : index;
      setCurrentIndex(newIndex);
      event.preventDefault();
    }
  };

  const onSearchValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: nextValue } = e.target;
    handleSearchTextChange(nextValue);
  };

  const renderContent = () => {
    if (status === ComponentStatus.LOADING) {
      return <LoadingComponent />;
    }
    if (status === ComponentStatus.ERROR) {
      return <Body color="gray8">{FLOWS_ACTION_BAR_OPTIONS.ERROR}</Body>;
    }
    return <LoadedComponent {...props} />;
  };

  const handleSuffixAdornmentClick = () => {
    clearSearchText();
    handleSuffixAdornmentClickCb();
  };

  return (
    <StyledFlex flexDirection="column" onKeyDown={handleOnKeyDown}>
      <StyledTextFieldWrapper>
        <StyledTextField
          autoFocus
          type="text"
          icon="search"
          size="large"
          value={searchText}
          onInputFocus={() => setCurrentIndex(-1)}
          placeholder={placeholder}
          suffix={searchText.length > 0 ? CLEAR : ``}
          onChange={onSearchValueChange}
          onSuffixAdornmentClick={handleSuffixAdornmentClick}
        />

        <VerticalDivider />
        <StyledIconButton
          icon="close-rounded"
          color="text"
          dataTestId="close-button"
          onClick={onCloseButtonClick}
        />
      </StyledTextFieldWrapper>
      {renderContent()}
    </StyledFlex>
  );
};

export default FlowsActionBarOptions;
