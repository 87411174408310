export const supportedPreviewFileTypes = [
  'jpg',
  'jpeg',
  'gif',
  'png',
  'bmp',
  'csv',
  'xlsx',
  'xls',
  'pdf',
  'docx',
  'mp3',
  'webm',
  'mp4',
  'txt',
];

export const supportedUploadFileTypes = [
  'jpg',
  'jpeg',
  'png',
  'svg',
  'gif',
  'pdf',
  'doc',
  'docx',
  'ppt',
  'pptx',
  'pps',
  'ppsx',
  'xls',
  'xlsx',
  'csv',
  'txt',
  'odt',
  'zip',
  'mp3',
  'm4a',
  'ogg',
  'wav',
  'mp4',
  'm4v',
  'mov',
  'wmv',
  'avi',
  'mpg',
  'ogv',
  '3gp',
  '3g2',
];

export const imagePreviewFileTypes = ['jpg', 'jpeg', 'gif', 'png', 'bmp'];
