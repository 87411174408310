// @ts-nocheck TODO: type issues need to be fixed in this file
import axios from 'axios';
import { API_URL_WITH_V2, SOURCE } from '../../../config';
import { AuthHeaders } from '../../shared/HeaderToken';
import { adminProcessError } from '../../Admin';
import Logger from '../../../Utils/Logger';
import {
  showSuccessMessage,
  showInfoMessage,
  showErrorMessage,
} from '../../../Utils/flashHandler';
import { addSourceToURL } from '../../../Utils/api';

// ------------------------------------
// Helpers
// ------------------------------------
const apiEndpoints = {
  USERS: `${API_URL_WITH_V2}/employer/employee/user`,
};

// ------------------------------------
// Constants
// ------------------------------------

export const GET_USERS_SUCCESS = 'get_users_success';
export const UPDATE_EMPLOYEE_SUCCESS = 'update_employee_success';
export const UPDATE_EMPLOYEES_SUCCESS = 'update_employees_success';
export const DELETE_EMPLOYEES_SUCCESS = 'delete_employees_success';
export const USER_MANAGE_REQUEST_FAILED = 'user_manage_request_failed';

// ------------------------------------
// Actions
// ------------------------------------

const getUsersSuccess = (users) => ({
  type: GET_USERS_SUCCESS,
  users,
});
const updateEmployeeSuccess = (user) => ({
  type: UPDATE_EMPLOYEE_SUCCESS,
  user,
});
const updateEmployeesSuccess = (users) => ({
  type: UPDATE_EMPLOYEES_SUCCESS,
  users,
});
const deleteEmployeesSuccess = (userId) => ({
  type: DELETE_EMPLOYEES_SUCCESS,
  userId,
});
const requestFailed = (errorMessage) => ({
  type: USER_MANAGE_REQUEST_FAILED,
  error: errorMessage,
});

export const actions = {
  getUsersSuccess,
  updateEmployeeSuccess,
  updateEmployeesSuccess,
  deleteEmployeesSuccess,
  requestFailed,
};

// ------------------------------------
// API Wrapper
// ------------------------------------

export const getUsersAction = (cb) => {
  Logger('getUsersAction', false);
  return async (dispatch) => {
    try {
      const res = await axios.get(apiEndpoints.USERS, AuthHeaders());
      const result = res.data;
      Logger(result);
      if (result.success) {
        if (cb) {
          cb();
        }
        dispatch(actions.getUsersSuccess(result.data));
      } else {
        if (cb) {
          cb();
        }
        // dispatch(actions.emailExists());
      }
    } catch (error) {
      adminProcessError(error, dispatch, actions);
    }
  };
};

export const editEmployeeAction = (props) => {
  const { user, onSuccess } = props;
  Logger(props);
  return async (dispatch) => {
    try {
      dispatch(showInfoMessage('Updating user(s)...'));
      const res = await axios.put(
        `${apiEndpoints.USERS}/${user._id}`,
        user,
        AuthHeaders(),
      );
      const result = res.data;
      Logger(result);
      if (result.success) {
        dispatch(showSuccessMessage('User(s) has been updated'));
        onSuccess(result.data);
        //dispatch(actions.updateEmployeeSuccess(result.data));
      } else {
        dispatch(showErrorMessage('Failed to update user, try again'));
      }
    } catch (error) {
      dispatch(showErrorMessage('Failed to update user, try again'));
      adminProcessError(error, dispatch, actions);
    }
  };
};

export const editBulkEmployeesAction = (props) => {
  const { data, onSuccess } = props;
  Logger(data);
  return async (dispatch) => {
    try {
      dispatch(showInfoMessage('Updating users...'));
      const res = await axios.post(
        `${apiEndpoints.USERS}/bulk/update`,
        data,
        AuthHeaders(),
      );
      const result = res.data;
      Logger(result);
      if (result.success) {
        dispatch(showSuccessMessage('Users has been updated'));
        onSuccess(result);
      } else {
        dispatch(showErrorMessage('Failed to bulk update users, try again'));
      }
    } catch (error) {
      dispatch(showErrorMessage('Failed to bulk update users, try again'));
      adminProcessError(error, dispatch, actions);
    }
  };
};

export const deleteEmployeesAction = (props) => {
  const { data, onSuccess } = props;
  Logger(data);
  const userIds = data;
  return async (dispatch) => {
    try {
      dispatch(showInfoMessage('Deleting user(s)...'));
      const res = await axios.request({
        method: 'delete',
        url: addSourceToURL(`${apiEndpoints.USERS}`, SOURCE),
        data: { userIds },
        headers: {
          'Content-Type': 'application/json',
          XClient:
            window.location.hostname +
            (window.location.port ? `:${window.location.port}` : ''),
        },
      });
      const result = res.data;
      Logger(result);
      if (result.success) {
        dispatch(showSuccessMessage('User(s) has been deleted'));
        onSuccess();
        // dispatch(getUsersAction());
        // dispatch(actions.updateEmployeeSuccess(result.data));
      } else {
        dispatch(showErrorMessage('Failed to delete user, try again'));
      }
    } catch (error) {
      dispatch(showErrorMessage('Failed to delete user, try again'));
      adminProcessError(error, dispatch, actions);
    }
  };
};

// ------------------------------------
// Reducers
// ------------------------------------

export default (state = {}, action) => {
  switch (action.type) {
    case GET_USERS_SUCCESS:
      return { ...state, users: action.users };
    case UPDATE_EMPLOYEE_SUCCESS: {
      const users = state.users.map((user) => {
        if (user._id === action.user._id) {
          return { ...user, ...action.user };
        }
        return user;
      });
      return { ...state, users };
    }
    case UPDATE_EMPLOYEES_SUCCESS: {
      const udpatedUsers = action.users;
      const users = state.users.map((user) => {
        const updatedUser = udpatedUsers.find((u) => user._id === u._id);
        if (updatedUser) {
          return { ...user, ...updatedUser };
        }
        return user;
      });
      return { ...state, users };
    }
    case USER_MANAGE_REQUEST_FAILED:
      return { ...state, error: action.error };
    default:
      return state;
  }
};
