import { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { FlowDefaultDeadlineProps } from '../../../../atomic/organism/TriggerConfigureSettings/types';
import { OnDemandControllerTypes } from './types';

import { defaultDueDateTypeOptions } from './data';
import { checkRecurrenceError } from '../../../../Utils/flows/scheduler/errorCheck';
import { ErrorEntry } from '../../../../interfaces/Flow/Builder';
import { ONDEMAND } from '../../../../languages/en/flows';

const error: ErrorEntry = {
  id: ONDEMAND,
  message: ONDEMAND,
};

const useOnDemandController = (props: OnDemandControllerTypes) => {
  const [deadlineErrorMessage, setDeadlineErrorMessage] = useState<
    string | null
  >('');

  const {
    canAnswerAnytime,
    onAnswerAnytimeToggle,
    defaultTimeValue,
    onDefaultTimeValueChange,
    defaultDueDateType,
    onDefaultDueDateTypeChange,
    updateErrorData,
    errors,
  } = props;

  useEffect(() => {
    setDeadlineErrorMessage(
      checkRecurrenceError(defaultTimeValue, defaultDueDateType),
    );
    if (!isEmpty(deadlineErrorMessage) && isEmpty(errors)) {
      updateErrorData({
        ...error,
        message: deadlineErrorMessage || '',
      });
    }
    if (isEmpty(deadlineErrorMessage) && !isEmpty(errors)) {
      updateErrorData(null);
    }
  }, [
    defaultDueDateType,
    defaultTimeValue,
    deadlineErrorMessage,
    updateErrorData,
    errors,
  ]);

  useEffect(() => {
    updateErrorData(null);
    // Should reset error object when component loads
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const flowDefaultDueDateProps: FlowDefaultDeadlineProps = {
    defaultTimeValue,
    onDefaultTimeValueChange,
    defaultDueDateTypeOptions,
    defaultDueDateType,
    onDefaultDueDateTypeChange,
    recurrenceErrorMessage: deadlineErrorMessage || '',
  };

  return {
    models: {
      flowAnswerAnytimeProps: {
        canAnswerAnytime,
        onAnswerAnytimeToggle,
      },
      flowDefaultDueDateProps,
    },
  };
};

export default useOnDemandController;
