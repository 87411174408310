// @ts-nocheck TODO: type issues need to be fixed in this file
import React, { useState, useCallback, useMemo } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import LazyLoad from 'react-lazyload';
import { string, bool, arrayOf, shape, func } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import FilterListIcon from '@material-ui/icons/FilterList';
import ContentLoader from 'react-content-loader';
import Checkbox from '../../../atomic/atoms/Checkbox';

import EntityItemLoader from './EntityItemLoader';

import ThemeV2 from '../../theme';
import Body from '../../../atomic/atoms/Body';
import Clickable from '../../../atomic/atoms/Clickable';
import useStringSearch from '../../../hooks/useSearchString';
import { getCheckedValue } from '../../../Utils/identity/selectPeople';

const useStyles = makeStyles({
  heading: (props) => ({
    marginBottom: '16px',
    color: props.disabled && ThemeV2.palette.lightGray6,
  }),
  searchBoxWrapper: {
    display: 'flex',
    alignItems: 'baseline',
    padding: 0,
  },
  searchBoxIcon: {
    color: ThemeV2.palette.lightGray6,
  },
  searchBoxTextField: (props) => ({
    width: '100%',
    backgroundColor: props.disabled && ThemeV2.palette.white3,
    fontFamily: ThemeV2.typography.adminFontFamily,
    fontWeight: ThemeV2.typography.fontWeightRegular,
    fontSize: '16px',
    '& .MuiInputBase-root': {
      height: '40px',
      borderRadius: '2px',
      '&.Mui-focused': {
        '& fieldset': {
          borderColor: ThemeV2.palette.geekBlue5,
        },
      },
    },
  }),
  filterIcon: (props) => ({
    marginRight: '12px',
    '&:hover': {
      cursor: props.disabled ? 'not-allowed' : 'pointer',
    },
  }),
  entityListItemRoot: {
    height: '48px',
    padding: '0px 12px',
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${ThemeV2.palette.lightGray5}`,
    borderBottom: 0,
  },
  entityListItemRootStructure: {
    height: '48px',
    border: `1px solid ${ThemeV2.palette.lightGray5}`,
    borderBottom: 0,
  },
  entityListItems: {
    borderRadius: '0px',
    '&:last-child': {
      borderBottom: `1px solid ${ThemeV2.palette.lightGray5}`,
    },
  },
  entityListItemCheckBox: {
    padding: 0,
    marginRight: '16px',
  },
  entityListItemEmptyAvatar: {
    height: '32px',
    width: '32px',
    borderRadius: '50%',
    backgroundColor: ThemeV2.palette.lightGray3,
    marginRight: '8px',
  },
  entityListItemHoverOption: {
    color: ThemeV2.palette.red6,
    marginRight: '8px',
    cursor: 'pointer',
  },
  entityListItemName: {
    color: ThemeV2.palette.darkGray9,
    maxWidth: '250px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  entitySelectAll: {
    color: ThemeV2.palette.darkGray9,
  },
  entityListItemSideText: {
    marginLeft: 'auto',
    color: ThemeV2.palette.darkGray7,
  },
  stickyHeader: {
    position: 'sticky',
    top: '0px',
    background: ThemeV2.palette.gray1,
    paddingBottom: '16px',
    zIndex: '1',
  },
});

// FIXME: Need to be an Individual Component
const SearchTextBox = ({ placeholder, disabled, onChange, value }) => {
  const classes = useStyles({ disabled });
  const onSearchChange = useCallback(
    (e) => {
      onChange(e.target.value);
    },
    [onChange],
  );
  return (
    <TextField
      variant="outlined"
      placeholder={placeholder}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon className={classes.searchBoxIcon} />
          </InputAdornment>
        ),
      }}
      classes={{
        root: classes.searchBoxTextField,
      }}
      disabled={disabled}
      onChange={onSearchChange}
      value={value}
    />
  );
};

SearchTextBox.propTypes = {
  placeholder: string,
  disabled: bool,
  onChange: func,
  value: string,
};

SearchTextBox.defaultProps = {
  placeholder: 'Search for an organizational unit',
  disabled: false,
  onChange: () => {},
  value: '',
};

// FIXME: Need to be an Individual Component
const EntityListItem = ({
  id,
  isSelected,
  checked,
  avatarUrl,
  mainText,
  sideText,
  showCheckbox,
  showAvatar,
  showHoverOption,
  hoverOptionText,
  onHoverOptionClick,
  onItemClick,
  isLoading,
}) => {
  const [isMouseOver, setIsMouseOver] = useState(false);
  const classes = useStyles();

  const handleMouseEnter = useCallback(() => setIsMouseOver(true), []);
  const handleMouseLeave = useCallback(() => setIsMouseOver(false), []);
  const handleCheckboxChange = useCallback(() => {
    onItemClick({ id, checked: !checked });
  }, [checked, id, onItemClick]);
  return (
    <div
      className={classes.entityListItemRoot}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {showCheckbox && (
        <Checkbox
          name={id}
          classes={{ root: classes.entityListItemCheckBox }}
          value={getCheckedValue({ isSelected, checked })}
          onChange={handleCheckboxChange}
          size="20px"
          color={ThemeV2.palette.secondary.main}
        />
      )}
      {showHoverOption && isMouseOver && (
        <Clickable
          classes={{
            root: classes.entityListItemHoverOption,
          }}
          onClick={onHoverOptionClick}
        >
          <Body variant="body3" color="inherit">
            {hoverOptionText}
          </Body>
        </Clickable>
      )}
      {showAvatar && !avatarUrl && (
        <span className={classes.entityListItemEmptyAvatar} />
      )}
      <Body variant="body2" className={classes.entityListItemName}>
        {mainText}
      </Body>
      {sideText && !isLoading && (
        <Body variant="body2" className={classes.entityListItemSideText}>
          {sideText}
        </Body>
      )}
      {isLoading && (
        <span className={classes.entityListItemSideText}>
          <ContentLoader
            speed={2}
            width={64}
            height={22}
            viewBox="0 0 64 22"
            backgroundColor={ThemeV2.palette.gray4}
            foregroundColor={ThemeV2.palette.gray3}
            data-testid="highlights-loader"
          >
            <rect x="0" y="0" rx="0" ry="0" width="64" height="22" />
          </ContentLoader>
        </span>
      )}
    </div>
  );
};

const MemoizedEntityListItem = React.memo(EntityListItem);
MemoizedEntityListItem.displayName = 'EntityListItem';

EntityListItem.propTypes = {
  id: string.isRequired,
  isSelected: bool,
  checked: bool,
  avatarUrl: string,
  mainText: string.isRequired,
  sideText: string,
  showCheckbox: bool,
  showAvatar: bool,
  showHoverOption: bool,
  hoverOptionText: string,
  onHoverOptionClick: func,
  onItemClick: func,
  isLoading: bool,
};

EntityListItem.defaultProps = {
  hoverOptionText: 'Remove',
  isSelected: false,
  checked: false,
  avatarUrl: undefined,
  sideText: undefined,
  showCheckbox: false,
  showAvatar: false,
  showHoverOption: false,
  onHoverOptionClick: () => {},
  onItemClick: () => {},
  isLoading: false,
};

const EntitySelectionTable = ({
  heading,
  entityList,
  onFilterClick,
  showCheckbox,
  showAvatar,
  showHoverOption,
  hoverOptionText,
  onHoverOptionClick,
  disabled,
  searchBoxPlaceHolder,
  onItemClick,
  showFilter,
  showSelectAll,
  isSelectedAll,
  onSelectAllClick,
  allEntitySearchText,
  handleAllEntitySearchText,
  handleFetchNextPage,
  hasNextPage,
  hasPaginatedIntegrationData,
}) => {
  const classes = useStyles({ disabled });

  const handleCheckboxChange = useCallback(
    () => onSelectAllClick(),
    [onSelectAllClick],
  );
  const keys = useMemo(() => ['mainText'], []);
  const { models: searchModels, operations: searchOperations } =
    useStringSearch({ data: entityList, keys });
  const { searchResult } = searchModels;
  const showEntityList = !disabled && searchResult.length > 0;

  const ItemComponents = useMemo(() => {
    return searchResult.map((i) => (
      <LazyLoad
        height={48}
        key={i.id}
        overflow
        // debounce={100}
        offset={480}
        placeholder={
          <div className={classes.entityListItemRootStructure}>&nbsp;</div>
        }
      >
        <MemoizedEntityListItem
          classes={classes}
          {...i}
          showCheckbox={showCheckbox}
          showAvatar={showAvatar}
          showHoverOption={showHoverOption}
          hoverOptionText={hoverOptionText}
          onHoverOptionClick={() => onHoverOptionClick(i)}
          onItemClick={onItemClick}
        />
      </LazyLoad>
    ));
  }, [
    searchResult,
    classes,
    showCheckbox,
    showAvatar,
    showHoverOption,
    hoverOptionText,
    onItemClick,
    onHoverOptionClick,
  ]);

  const renderContent = () => {
    if (showEntityList) {
      if (hasPaginatedIntegrationData) {
        return (
          <InfiniteScroll
            next={handleFetchNextPage}
            dataLength={entityList ? entityList.length : 0}
            hasMore={Boolean(hasNextPage)}
            style={{ overflow: 'inherit' }}
            scrollableTarget="steps-wizard-scrollable-container"
            loader={<EntityItemLoader />}
          >
            {ItemComponents}
          </InfiniteScroll>
        );
      } else {
        return ItemComponents;
      }
    } else {
      return <div className={classes.entityListItemRoot}>No items</div>;
    }
  };

  return (
    <>
      <div className={classes.stickyHeader}>
        {heading && (
          <Body className={classes.heading} variant="body2Bold">
            {heading}
          </Body>
        )}
        <div className={classes.searchBoxWrapper}>
          <SearchTextBox
            classes={classes}
            disabled={disabled}
            placeholder={searchBoxPlaceHolder}
            value={
              handleAllEntitySearchText
                ? allEntitySearchText
                : searchModels.pattern
            }
            onChange={
              handleAllEntitySearchText
                ? handleAllEntitySearchText
                : searchOperations.search
            }
          />
          {showFilter && (
            <FilterListIcon
              className={classes.filterIcon}
              onClick={onFilterClick}
              disabled={disabled}
            />
          )}
        </div>
      </div>
      {showSelectAll && !hasPaginatedIntegrationData && (
        <div className={classes.entityListItemRoot}>
          <Checkbox
            name="select_all"
            size="20px"
            color={ThemeV2.palette.secondary.main}
            classes={{ root: classes.entityListItemCheckBox }}
            value={isSelectedAll}
            onChange={handleCheckboxChange}
          />
          <Body variant="body2Bold" className={classes.entitySelectAll}>
            Select All
          </Body>
        </div>
      )}
      {!disabled && (
        <div className={classes.entityListItems}>{renderContent()}</div>
      )}
    </>
  );
};

EntitySelectionTable.propTypes = {
  entityList: arrayOf(
    shape({
      id: string.isRequired,
      avatarUrl: string,
      mainText: string.isRequired,
      isSelected: bool,
      sideText: string,
    }),
  ).isRequired,
  heading: string,
  searchBoxPlaceHolder: string,
  showFilter: bool,
  onFilterClick: func.isRequired,
  showSelectAll: bool,
  onSelectAllClick: func,
  isSelectedAll: bool,
  onHoverOptionClick: func,
  disabled: bool,
  showCheckbox: bool,
  showAvatar: bool,
  showHoverOption: bool,
  hoverOptionText: string,
  onItemClick: func,
  allEntitySearchText: string,
  handleAllEntitySearchText: func,
  handleFetchNextPage: func,
  hasNextPage: bool,
  hasPaginatedIntegrationData: bool,
};

EntitySelectionTable.defaultProps = {
  heading: undefined,
  searchBoxPlaceHolder: undefined,
  showCheckbox: false,
  showAvatar: false,
  showHoverOption: false,
  disabled: false,
  hoverOptionText: undefined,
  onHoverOptionClick: () => {},
  onItemClick: () => {},
  showFilter: false,
  showSelectAll: false,
  onSelectAllClick: () => {},
  isSelectedAll: false,
  allEntitySearchText: '',
};
const MemoizedEntitySelectionTable = React.memo(EntitySelectionTable);
MemoizedEntitySelectionTable.displayName = 'EntitySelectionTable';

export default MemoizedEntitySelectionTable;
