const API_VERSION_V2 = '1.5.0';

const getCacheControl = () => {
  return {
    Pragma: 'no-cache',
  };
};

export const AuthHeaders = () => {
  //eslint-disable-line
  return {
    headers: {
      'Content-Type': 'application/json',
      XClient:
        window.location.hostname +
        (window.location.port ? `:${window.location.port}` : ''),
      ...getCacheControl(),
    },
  };
};

export const HeadersWithoutAuth = () => {
  //eslint-disable-line
  return {
    headers: {
      'Content-Type': 'application/json',
      XClient:
        window.location.hostname +
        (window.location.port ? `:${window.location.port}` : ''),
      ...getCacheControl(),
    },
  };
};

export const HeadersWithoutAuthV2 = () => {
  //eslint-disable-line
  return {
    headers: {
      'Content-Type': 'application/json',
      XClient:
        window.location.hostname +
        (window.location.port ? `:${window.location.port}` : ''),
      clientAPIVersion: API_VERSION_V2,
      ...getCacheControl(),
    },
  };
};

export const AuthHeadersV2 = () => {
  //eslint-disable-line
  return {
    headers: {
      'Content-Type': 'application/json',
      XClient:
        window.location.hostname +
        (window.location.port ? `:${window.location.port}` : ''),
      clientAPIVersion: API_VERSION_V2,
      ...getCacheControl(),
    },
  };
};
