import React from 'react';
import styled from 'styled-components';

import Body from '../../atoms/Body';
import Image from '../../atoms/Image';
import RightDrawerErrorImage from '../../../img/home/right-drawer-error.svg';
import { SOMETHING_WENT_WRONG, TRY_AGAIN } from '../../../languages/en/home';
import ThemeV2 from '../../../componentsV2/theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  text-align: center;
  background-color: ${ThemeV2.palette.gray1};
`;

export const StyledImage = styled(Image)`
  margin-top: 56px;
  margin-bottom: 24px;
`;

export interface ErrorComponentProps {
  className?: string;
  primaryText?: string;
  secondaryText?: string;
}

const ErrorComponent = ({
  className,
  primaryText = SOMETHING_WENT_WRONG,
  secondaryText = TRY_AGAIN,
}: ErrorComponentProps) => {
  return (
    <Wrapper className={className}>
      <StyledImage alt={primaryText} src={RightDrawerErrorImage} />
      <Body variant="body1Bold">{primaryText}</Body>
      <Body variant="body2">{secondaryText}</Body>
    </Wrapper>
  );
};

export default ErrorComponent;
