import { FeedPostUserProps } from '../../../interfaces/Feed';
import { VisibilityType } from '../../../queries/Flows/Feed/interfaces';
import { PlanType } from '../../organism/RouteTabs/interfaces';
import { MenuItemProps } from '../Dropdown_V2/interfaces';

export enum HeaderType {
  FLOWS_FEED = 'flows-feed',
  MAIN_FEED = 'main-feed',
  PROFILE = 'profile',
  FLOWS = 'flows',
  OTHER = 'other',
}

type Visibility = {
  criteria: VisibilityType;
  viewersCount: number;
};
export interface PageHeaderProps {
  headerType: HeaderType;
  title?: string;
  subTitle?: string;
  imageUrl?: string;
  imageName?: string;
  membersCount?: number;
  icon?: string;
  backgroundColor?: string;
  profile?: {
    firstName: string;
    lastName: string;
    username: string;
    imageUrl: string;
    departments?: string;
  };
  userId?: string;
  showMainFeedLinkInOthers?: boolean;
  hasBottomBorder?: boolean;
  isMembersCountError?: boolean;
  isMembersCountLoading?: boolean;
  isBeta?: boolean;
  buttonText?: string;
  tabRouteBase?: string;
  pageTabs?: {
    id: string;
    label: string;
    planType: PlanType;
  }[];
  onButtonClick?: () => void;
  menuOptions?: MenuItemProps[];
  onMenuOptionClick?: (value: string | number) => void;
  users?: FeedPostUserProps[];
  visibility?: Visibility;
  onClick?: () => void;
}
export interface LoaderProps {
  hasBottomBorder?: boolean;
}

export interface ErrorProps {
  hasBottomBorder?: boolean;
  title: string;
  subTitle: string;
}
