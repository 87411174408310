// @ts-nocheck TODO: type issues need to be fixed in this file
import * as Yup from 'yup';
import { EditorState } from 'draft-js';
import { AutocompleteDropdownItem } from '../../../atomic/organism/Autocomplete/interfaces';
import { canMemberReceiveAllowance, getFullName } from '../../../Utils/user';
import { getCharacterCount } from '../../../Utils/draftjs';
import {
  getCanCheckForBadWords,
  getMinimumMessageCharacters,
} from '../../../queries/Profile/utils';
import { GetProfileInfoResponse } from '../../../queries/Profile';
import {
  getTextFromEditor,
  getTrimmedMessage,
} from '../../../Utils/draftjs/mention';
import { VALIDATION_ERRORS } from '../../../languages/en/flows/participation';
import { IMemberDTO } from '../../../interfaces/member';
import { StaticBlockState } from '../../../interfaces/Flow';
import { MemberState, Mention } from '../../../interfaces/user';
import { checkForProfanity } from '../../../Utils/giveCarrots/validation';
import ThemeV2 from '../../../componentsV2/theme';

export const generateRecognitionMemberAutocompleteOptions = (
  members: IMemberDTO[],
  currentUserId: string,
) => {
  return members.reduce<AutocompleteDropdownItem<string, IMemberDTO>[]>(
    (arr, member) => {
      const isCurrentUser = member.memberID === currentUserId;
      const canReceiveAllowance = canMemberReceiveAllowance(member);
      const fullName = getFullName(member);
      if (!isCurrentUser && canReceiveAllowance) {
        const item = {
          id: member.memberID,
          title: fullName,
          avatar: {
            img: member.image || '',
            name: fullName,
            userId: member.memberID,
            icon:
              member.memberState === MemberState.PENDING
                ? 'pending-person'
                : '',
            iconColor: ThemeV2.palette.white,
          },
          item: member,
        };

        arr.push(item);
      }
      return arr;
    },
    [],
  );
};

export const transformMessageForApi = (
  editorState: EditorState,
  selectedMentions: Mention[],
  tags?: Mention[],
) => {
  const mentionsToReplace: Mention[] = [];
  const idDict: { [Key: string]: boolean } = {};
  selectedMentions.forEach((mention: Mention) => {
    if (!idDict[mention.id]) {
      mentionsToReplace.push(mention);
      idDict[mention.id] = true;
    }
  });
  const {
    text: editorText,
    replacedMentions,
    replacedTags,
  } = getTextFromEditor(editorState, mentionsToReplace, tags);

  return {
    text: getTrimmedMessage(editorText) as string,
    replacedMentions,
    replacedTags,
  };
};

export const generateValidationSchema = (
  profileInfo: GetProfileInfoResponse,
  slideData: StaticBlockState[],
) => {
  const minimumCharacterLimit = getMinimumMessageCharacters(profileInfo) || 1;
  const canCheckForProfanity = getCanCheckForBadWords(profileInfo);

  const schemaObj: Record<string, Yup.AnySchema> = {};

  slideData.forEach(({ id, type, isRequired }) => {
    switch (type) {
      case 'MULTI_PERSON_SELECTOR_DROPDOWN': {
        schemaObj[id] = Yup.array().ensure().min(1, VALIDATION_ERRORS.REQUIRED);
        break;
      }
      case 'OPEN_ENDED': {
        schemaObj[id] = Yup.object()
          .test(
            'required',
            VALIDATION_ERRORS.REQUIRED,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            /* @ts-ignore */
            ({ editorState }: { editorState: EditorState }) =>
              editorState.getCurrentContent().hasText(),
          )
          .test(
            'minimumCharacterLimit',
            VALIDATION_ERRORS.UNDER_CHARACTER_LIMIT,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            /* @ts-ignore */
            ({ editorState }: { editorState: EditorState }, testContext) => {
              const messageLength = getCharacterCount(editorState);

              if (messageLength < minimumCharacterLimit) {
                const amountUnderCharacterLimit =
                  minimumCharacterLimit - messageLength;
                const message =
                  VALIDATION_ERRORS.generateMinimumCharacterLimitError(
                    amountUnderCharacterLimit,
                  );
                return testContext.createError({ message });
              }

              return messageLength >= minimumCharacterLimit;
            },
          )
          .test(
            'profanityCheck',
            VALIDATION_ERRORS.PROFANE_ERROR_MESSAGE,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            /* @ts-ignore */
            ({ editorState }: { editorState: EditorState }) =>
              canCheckForProfanity
                ? !checkForProfanity(editorState.getCurrentContent())
                : true,
          );
        break;
      }
      case 'SINGLE_SELECT_DROPDOWN': {
        schemaObj[id] = Yup.object().nullable();
        break;
      }
      case 'TROPHIES': {
        schemaObj[id] = Yup.number().test(
          'excessiveTrophies',
          VALIDATION_ERRORS.EXCESSIVE_TROPHIES,
          (value) => value !== -1,
        );
        break;
      }
      default: {
        break;
      }
    }
    if (isRequired && type !== 'OPEN_ENDED') {
      schemaObj[id] = schemaObj[id].required(VALIDATION_ERRORS.REQUIRED);
    }
  });
  return Yup.object().shape(schemaObj);
};
