import React from 'react';

import Divider from '../../atoms/Divider';
import ProfileHeaderSectionLoader from '../../molecules/ProfileHeaderSection/Loader';
import ProfileCelebrationStatisticsSectionLoader from '../../molecules/ProfileCelebrationStatisticsSection/Loader';
import ProfileDetailsSectionLoader from '../../molecules/ProfileDetailsSection/Loader';
import ThemeV2 from '../../../componentsV2/theme';

const ProfileViewLoader = () => {
  return (
    <>
      <ProfileHeaderSectionLoader />
      <Divider color={ThemeV2.palette.gray5} isFullWidth />
      <ProfileCelebrationStatisticsSectionLoader />
      <Divider color={ThemeV2.palette.gray5} isFullWidth />
      <ProfileDetailsSectionLoader />
    </>
  );
};

export default ProfileViewLoader;
