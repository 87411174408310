import React from 'react';
import routesList from '../../../aV2/routes/routesList';
import { UPGRADE_HERE, ACCOUNT_SETUP } from '../../../languages/en/rewards';
import Body from '../../atoms/Body';
import {
  StyledRewardsEmptyState,
  StyledRewardsEmptyStateImage,
  StyledRewardsEmptyStateLink,
  StyledRewardsEmptyStateButton,
  StyledRewardsEmptyStateTitle,
} from './styles';
import { RewardsEmptyStateProps } from './interfaces';
import accountSetupImage from '../../../img/account-set-up.svg';
import { useFeatureSplit } from '../../../hooks/useFeatureSplit';
import {
  SplitNames,
  TreatmentTypes,
} from '../../../hooks/useSplitSdkConfig/constants';
import { Link } from 'react-router-dom';

const RewardsEmptyState = (props: RewardsEmptyStateProps) => {
  const {
    title,
    message,
    actionButtonText,
    actionButtonLink = '#',
    hasActionButton = true,
    hasUpgradeLink = false,
    image = accountSetupImage,
  } = props;

  // TO DO: Sumedha - clean up and remove FF once app is stable
  const { treatment: adminRefactorTreatment } = useFeatureSplit(
    SplitNames.ADMIN_NEW_APP,
  );

  const isAdminTreatmentOn = adminRefactorTreatment === TreatmentTypes.ON;

  return (
    <StyledRewardsEmptyState>
      <StyledRewardsEmptyStateImage src={image} alt={ACCOUNT_SETUP} />
      <StyledRewardsEmptyStateTitle variant="body1Bold">
        {title}
      </StyledRewardsEmptyStateTitle>
      <Body variant="body2">{message}</Body>
      {hasUpgradeLink && (
        <StyledRewardsEmptyStateLink to={routesList.ADMIN_BILLING_ACCOUNT}>
          <Body variant="body2" color="gold6" inline>
            {UPGRADE_HERE}
          </Body>
        </StyledRewardsEmptyStateLink>
      )}
      {hasActionButton &&
        (isAdminTreatmentOn ? (
          <StyledRewardsEmptyStateButton
            onClick={() => {
              window.location.href = actionButtonLink;
            }}
            isFullWidth
          >
            {actionButtonText}
          </StyledRewardsEmptyStateButton>
        ) : (
          <Link to={actionButtonLink}>
            <StyledRewardsEmptyStateButton isFullWidth>
              {actionButtonText}
            </StyledRewardsEmptyStateButton>
          </Link>
        ))}
    </StyledRewardsEmptyState>
  );
};

export default RewardsEmptyState;
