export const MY_SETTINGS = 'My Settings';

export const GENERAL = 'General';
export const NOTIFICATIONS = 'Notifications';
export const SETTINGS_ADD_YOUR_FIELD = 'Add your';
export const SETTINGS_HOW_YOU_NOTIFIED = 'How you get notifications';
export const SETTINGS_WHAT_YOU_GET_NOTIFIED_ABOUT =
  'What do you get notified about';

export const UPDATE_AVATAR = 'Update your avatar';
export const MINIMUM_AVATAR_SIZE = 'Minimum suggested image size: 500x500';
export const CHOOSE_FILE = 'Choose File';
export const NO_FILE = 'No file chosen';
export const SAVE_CHANGES = 'Save Changes';
export const SETTINGS_EDIT_NAME_LANGUAGE = {
  MIN_LENGTH: 'Name must be more than 3 characters long',
  REQUIRE_NAME: 'Name must be more than 3 characters long',
  FIRST_LAST_NAME:
    'Whoops, it looks like you did not enter a full first and last name (example: Jane Doe).',
};

export const LABEL = {
  MANAGER: 'Who is your direct manager?',
  ADD_DEPARTMENT: 'Add Department',
  CHANGE_DEPARTMENT: 'Change Department',
  REPORTS: 'Direct Reports',
  TIMEZONE: 'Change Time Zone',
};

export const CELEBRATIONS = {
  BIRTHDAY: 'birthday',
  ANNIVERSARY: 'anniversary',
};

export const MULTI_SELECT_DROPDOWN_LANGUAGE = {
  MONTH: 'Month',
  DAY: 'Day',
  YEAR: 'Year',
  IS_NOT_ADMIN_TEXT:
    // eslint-disable-next-line max-len
    'Your :celebration is controlled by your company admin. You can edit visibility and whether or not you get celebrated.',
  IS_ADMIN_TEXT:
    // eslint-disable-next-line max-len
    'This allows your company to celebrate you on your :celebration. You can correct this one time, in case it’s wrong.',
  CANT_EDIT_TEXT: 'This field is controlled by admin',
};

export const AUTO_COMPLETE_LANGUAGE = {
  SEARCH_BY_NAME: 'Search by name',
  ADD_ANOTHER: '+ Add another',
};

export const HELPER_LINK_TEXT = 'For help setting up, click here';

export const GENERAL_SETTINGS_UPDATE_ERROR_MESSAGE =
  'Error occurred. Please try again!';
export const UPDATING_SETTINGS = 'Updating settings..';
export const SETTINGS_UPDATED = 'Settings updated!';
