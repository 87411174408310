import { EmojiData } from 'emoji-mart';
import produce from 'immer';

import { CurrentUser } from '../../../interfaces/currentUser';
import { Reaction } from '../../../interfaces/Feed';
import { ReactionType } from '../../../interfaces/Feed/Reaction';

const updateReaction = (reaction: Reaction) => {
  const hasUserAlreadyReacted = reaction.active;
  if (hasUserAlreadyReacted) {
    return reaction;
  }

  const updatedReaction = { ...reaction };
  updatedReaction.active = true;
  updatedReaction.timesReacted += 1;
  return updatedReaction;
};

const pushEmojiToReactions = (
  emoji: EmojiData,
  reactions: Reaction[],
  currentUser: CurrentUser,
) => {
  const reactionMember = {
    memberID: currentUser.memberId,
    name: `${currentUser.profile.firstName} ${currentUser.profile.lastName}`,
  };

  const selectedReaction = {
    active: true,
    members: [reactionMember],
    reaction: {
      name: emoji.colons || '',
      type: ReactionType.REGULAR,
      value: emoji.name,
    },
    timesReacted: 1,
  };

  const updatedReactions = produce(reactions, (draft) => {
    draft.push(selectedReaction);
  });
  return updatedReactions;
};

export const updateReactionsWithEmoji = (
  reactions: Reaction[],
  emoji: EmojiData,
  currentUser: CurrentUser,
): Reaction[] => {
  const updatedReactions = [...reactions];

  const indexOfReaction = reactions.findIndex(
    ({ reaction }) => reaction.name === emoji.colons,
  );

  const hasBeenReacted = indexOfReaction > -1;
  if (hasBeenReacted) {
    const reaction = reactions[indexOfReaction];
    const updatedReaction = updateReaction(reaction);
    updatedReactions[indexOfReaction] = updatedReaction;
    return updatedReactions;
  }

  return pushEmojiToReactions(emoji, reactions, currentUser);
};

const toggleReactionAtIndex = (
  reactions: Reaction[],
  index: number,
  currentUser: CurrentUser,
) => {
  const updatedReactions = produce(reactions, (draft) => {
    const reaction = draft[index];

    if (reaction.active) {
      reaction.active = false;
      reaction.timesReacted -= 1;
      reaction.members.pop();
    } else {
      reaction.active = true;
      reaction.timesReacted += 1;
      reaction.members.push({
        name: `${currentUser.profile.firstName} ${currentUser.profile.lastName}`,
        memberID: currentUser.memberId,
      });
    }

    if (reaction.timesReacted === 0) {
      draft.splice(index, 1);
    }
  });

  return updatedReactions;
};

export const toggleReactionInReactions = (
  reactionName: string,
  reactions: Reaction[],
  currentUser: CurrentUser,
): Reaction[] => {
  const reactionIndex = reactions.findIndex(
    ({ reaction }) => reaction.name === reactionName,
  );
  const isInvalidReaction = reactionIndex === -1;
  if (isInvalidReaction) {
    return reactions;
  }

  const updatedReactions = toggleReactionAtIndex(
    reactions,
    reactionIndex,
    currentUser,
  );
  return updatedReactions;
};
