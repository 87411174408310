import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import pluralize from 'pluralize';
import FormsEditorBaseInputBlock from '../FormsEditorInputBaseBlock';
import {
  ContentBlockLocalProperties,
  ContentMultiOptionBlockState,
  MultiOptionBlockBuilderSettingState,
  OptionsSelectObject,
} from '../../../../interfaces/Flow/Builder';
import {
  OptionItemEditorProps,
  OptionItemProps,
} from '../../../molecules/OptionItemsEditor/interface';
import OptionItemsEditor from '../../../molecules/OptionItemsEditor';
import Body from '../../../atoms/Body';
import { UPTO } from '../../../../languages/en/flows/builder';
import {
  AND,
  BETWEEN,
  CHOOSE,
  EXACTLY,
  OPTION,
  OPTIONS,
} from '../../../../languages/en/singleWords';
import {
  pushNewOption,
  getUpdatedBlockSettingsOnOptionsUpdate,
} from '../../../../Utils/flows';

export interface FormsEditorMultiOptionInputBlockProps
  extends ContentBlockLocalProperties {
  blockData: ContentMultiOptionBlockState;
}

const StyledWrapper = styled.div`
  margin: 12px 0;
  width: 100%;
`;

const StyledGuideText = styled(Body)`
  margin-bottom: 12px;
`;

const FormsEditorMultiChoiceInputBlock = ({
  blockData,
  currentIndex,
  handleToggleIsDescriptionNeeded,
  handleToggleIsRequired,
  onValueChange,
  totalNumberOfItems,
  onDeleteClick,
  onMoveDownClick,
  onMoveUpClick,
}: FormsEditorMultiOptionInputBlockProps) => {
  const {
    currentOptionSelectObject: selectedOption,
    maximumSelectableOptions,
    options,
    isRequired,
    description,
    assemblyCurrency,
  } = blockData;
  const [optionsCount, setOptionsCount] = useState(options.length);

  const [isSingleSelect, setSelectType] = useState<boolean>(false);
  const [guideText, setGuideText] = useState<string>('');

  const getGuideText = useCallback(() => {
    switch (selectedOption.type) {
      case 'EXACT_NUMBER':
        return `${CHOOSE} ${EXACTLY} ${pluralize(
          OPTION,
          selectedOption.exactOptions,
          true,
        )}`;

      case 'RANGE':
        return `${CHOOSE} ${BETWEEN} ${selectedOption.minOptions} ${AND} ${selectedOption.maxOptions} ${OPTIONS}.`;

      case 'UNLIMITED_OPTIONS':
        return `${CHOOSE} ${UPTO} ${pluralize(
          OPTION,
          maximumSelectableOptions,
          true,
        )}`;

      default:
        return '';
    }
  }, [selectedOption, maximumSelectableOptions]);

  useEffect(() => {
    setSelectType(
      selectedOption.type === 'EXACT_NUMBER' &&
        selectedOption.exactOptions === 1,
    );
    const displayText = getGuideText();
    setGuideText(displayText);
  }, [selectedOption, getGuideText]);

  const changeChosenParticipantSelection = (
    updatedOptions: OptionsSelectObject,
  ) => {
    if (onValueChange) {
      onValueChange({
        ...blockData,
        currentOptionSelectObject: updatedOptions,
      });
    }
  };

  const blockSettingState: MultiOptionBlockBuilderSettingState = {
    type: 'MULTI_CHOICE',
    isRequired,
    isDescriptionNeeded: description !== null,
    toggleIsRequired: handleToggleIsRequired,
    toggleDescriptionNeeded: handleToggleIsDescriptionNeeded,
    currentOptionSelectObject: selectedOption,
    changeCurrentOptionSelectObject: changeChosenParticipantSelection,
    maximumSelectableOptions,
  };

  const updateMultiChoiceOptions = (updatedOptions: OptionItemProps[]) => {
    const modifiedCurrentSelection = getUpdatedBlockSettingsOnOptionsUpdate(
      updatedOptions.length,
      selectedOption,
    );
    onValueChange({
      ...blockData,
      options: updatedOptions,
      maximumSelectableOptions: updatedOptions.length,
      ...(modifiedCurrentSelection !== null && {
        currentOptionSelectObject: modifiedCurrentSelection,
      }),
    });
  };

  const addNewOption = (isOtherOption = false) => {
    const modifiedOptionsData = pushNewOption(
      options,
      optionsCount,
      isOtherOption,
    );
    updateMultiChoiceOptions(modifiedOptionsData);
    if (!isOtherOption) {
      setOptionsCount(optionsCount + 1);
    }
  };

  const optionItemsProps: OptionItemEditorProps = {
    type: isSingleSelect ? 'radio' : 'checkbox',
    optionItems: options,
    onDismissClick: (currentOption: OptionItemProps | undefined) => {
      const modifiedOptionsData = options.filter((option) => {
        return option?.value !== currentOption?.value;
      });
      updateMultiChoiceOptions(modifiedOptionsData);
    },
    onOptionEditChange: (
      e: React.ChangeEvent<HTMLInputElement>,
      currentOption: OptionItemProps | undefined,
    ) => {
      const modifiedOptionsData = options.map((option) => {
        if (option?.value === currentOption?.value) {
          return {
            ...currentOption,
            label: e.target.value,
          };
        }
        return option;
      });
      updateMultiChoiceOptions(modifiedOptionsData);
    },
    addOptionClickProps: {
      enabled: true,
      onClick: () => addNewOption(false),
    },
    addOtherClickProps: {
      enabled: true,
      onClick: () => addNewOption(true),
    },
  };

  return (
    <FormsEditorBaseInputBlock
      blockData={blockData}
      currentIndex={currentIndex !== undefined ? currentIndex : 0}
      assemblyCurrency={assemblyCurrency}
      totalNumberOfItems={totalNumberOfItems}
      blockSettingState={blockSettingState}
      onDeleteClick={onDeleteClick}
      onMoveDownClick={onMoveDownClick}
      onMoveUpClick={onMoveUpClick}
      onValueChange={onValueChange}
    >
      <>
        <StyledWrapper>
          {!isSingleSelect && (
            <StyledGuideText color="gray8" variant="body3">
              {guideText}
            </StyledGuideText>
          )}
          <OptionItemsEditor {...optionItemsProps} />
        </StyledWrapper>
      </>
    </FormsEditorBaseInputBlock>
  );
};

export default FormsEditorMultiChoiceInputBlock;
