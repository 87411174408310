import styled from 'styled-components';

import Body from '../../../atomic/atoms/Body';
import BasicModalTemplate from '../../../atomic/templates/BasicModalTemplate';

import { Flex } from '../../../Utils/styles/display';
import ThemeV2 from '../../../componentsV2/theme';

export const StyledShareSheetBasicModalTemplate = styled(BasicModalTemplate)<{
  overflow?: 'hidden' | 'auto' | 'none' | 'inherit';
}>`
  max-width: 640px;
  transition: height 1s ease-in-out;

  .button-wrapper {
    button {
      width: auto;
    }
  }
  .modal-childWrapper {
    ${({ overflow }) => overflow && `overflow-y: ${overflow}`};
  }
`;

export const ParticipantDescription = styled(Body)`
  padding: 16px;
  border: 1px solid ${ThemeV2.palette.gray4};
`;

export const ParticipantsCriteriaContainer = styled(Flex)`
  padding: 16px 48px 30px;
  min-height: 330px;
  flex: 4;
  max-height: 380px;
  overflow-y: auto;
`;

export const ParticipationCriteriaTitle = styled(Body)`
  padding-bottom: 8px;
`;

export const ParticipantsListWrapper = styled(Flex)`
  flex: 3;
  max-width: 245px;
  overflow-x: hidden;
  & > div {
    flex-basis: 0px;
    flex-grow: 1;
    overflow-y: auto;
  }
`;
