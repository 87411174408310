import ThemeV2 from '../../../../componentsV2/theme';
import { LinkAccessType } from '../../../../queries/Flows/interfaces';

export const getIconSettingsForLinkAccessType = (type: LinkAccessType) => {
  switch (type) {
    case LinkAccessType.EXTERNAL:
      return {
        icon: 'globe',
        bgColor: `${ThemeV2.palette.green7}`,
        iconColor: `${ThemeV2.palette.white}`,
      };
    case LinkAccessType.INTERNAL:
      return {
        icon: 'building',
        iconColor: `${ThemeV2.palette.white}`,
        bgColor: `${ThemeV2.palette.geekBlue6}`,
      };
    case LinkAccessType.RESTRICTED:
    default:
      return {
        icon: 'users',
        iconColor: `${ThemeV2.palette.gray8}`,
        bgColor: `${ThemeV2.palette.gray3}`,
      };
  }
};
