import { RefObject, useEffect, useMemo, useState } from 'react';
import { FormikErrors, FormikTouched, useFormik } from 'formik';
import { AnyObjectSchema } from 'yup';
import {
  StaticBlockState,
  StepperErrorType,
  DynamicBlockState,
  FlowSubmissionDetails,
  PointStackStaticBlockState,
} from '../../interfaces/Flow';
import {
  generateBlockErrors,
  generateFieldErrors,
  isBlockContentValid,
  isBlockEmpty,
  isRequiredError,
  STEP_BUTTON_STATUS,
} from './utils';
import {
  FIX_THIS_ERROR,
  THIS_IS_REQUIRED,
} from '../../languages/en/recognitionFlow';
import useHardMouseWheel from '../useHardMouseWheel';
import { VALIDATION_ERRORS } from '../../languages/en/flows/participation';
import useTrackParticipationFlow from '../analytics/useTrackParticipationFlow';
import {
  ACTION_EVENTS,
  PARTICIPATION_ANALYTICS_EVENTS,
} from '../../Utils/analytics/constants';

type UseParticipationFlowParamater = {
  staticBlockData: StaticBlockState[];
  schema: AnyObjectSchema;
  initialValues: Record<string, any>;
  onSubmitFlowError?: (
    staticStates: StaticBlockState[],
    dynamicStates: DynamicBlockState[],
  ) => void;
  containerRef: RefObject<HTMLDivElement | null>;
  postStepChange?: (
    blockData: StaticBlockState,
    values: Record<string, any>,
  ) => void;
  onFlowSubmit: (submissionDetails: FlowSubmissionDetails) => void;
};

const checkDynamicBlockError = (
  item: StaticBlockState,
  errors: FormikErrors<Record<string, any>>,
  touched: FormikTouched<Record<string, any>>,
  values: Record<string, any>,
) => {
  const currentBlockError = errors[item.id];
  if (
    (item.type === 'SINGLE_PERSON_SELECTOR_DROPDOWN' ||
      item.type === 'MULTI_PERSON_SELECTOR_DROPDOWN') &&
    item.referenceStackId
  ) {
    return Boolean(
      currentBlockError && (values[item.referenceStackId] || touched[item.id]),
    );
  }
  return Boolean(currentBlockError && touched[item.id]);
};

function useParticipationFlow({
  initialValues,
  onSubmitFlowError,
  schema,
  staticBlockData,
  containerRef,
  onFlowSubmit,
  postStepChange = () => {},
}: UseParticipationFlowParamater) {
  const [currentStep, setCurrentStep] = useState(0);
  const [isFormValid, setFormValid] = useState(false);
  const [hasVisitedLastStep, setHasVisitedLastStep] = useState(
    staticBlockData.length === 1,
  );
  const [dynamicBlockData, setDynamicBlockData] = useState<DynamicBlockState[]>(
    [],
  );
  const [flowHasGivePointsStack] = useState(
    staticBlockData.some((block) => block.type === 'GIVE_POINTS_STACK'),
  );

  const { trackParticipationFlow } = useTrackParticipationFlow({
    stepData: staticBlockData,
  });

  const handleFlowSubmit = (values: Record<string, any>) => {
    if (isFormValid) {
      onFlowSubmit({
        values,
        dynamicBlockData,
        currentStep,
      });
    }
  };

  const {
    values,
    errors,
    touched,
    submitForm,
    submitCount,
    setFieldValue,
    setFieldTouched,
    setTouched,
    resetForm,
    setFieldError,
    isValid,
  } = useFormik({
    onSubmit: handleFlowSubmit,
    initialValues,
    validationSchema: schema,
    validateOnMount: true,
  });

  const blockErrors = useMemo(
    () => generateBlockErrors(errors, submitCount > 0),
    [errors, submitCount],
  );

  const fieldErrors = useMemo(
    () => generateFieldErrors(errors, touched),
    [errors, touched],
  );

  useEffect(() => {
    setFormValid(isValid);
  }, [isValid]);

  // This useEffect updates the local state for each block based on Formik state
  useEffect(() => {
    setDynamicBlockData(
      staticBlockData.map((item) => {
        let errorMessage: string | undefined;
        let errorType: StepperErrorType | undefined;
        const isBlockValid = isBlockContentValid(item, values[item.id]);
        const isError = checkDynamicBlockError(item, errors, touched, values);
        if (isError) {
          if (errors[item.id] === VALIDATION_ERRORS.REQUIRED) {
            errorMessage = THIS_IS_REQUIRED;
            errorType = StepperErrorType.REQUIRED;
          } else {
            errorMessage = FIX_THIS_ERROR;
            errorType = StepperErrorType.FIX_ERROR;
          }
        }
        return {
          id: item.id,
          title: item.title,
          isError,
          isValid: !errors[item.id] && isBlockValid,
          errorMessage,
          errorType,
        };
      }),
    );
  }, [errors, staticBlockData, touched, values]);

  // This useEffect is used to add custom errors for scenarios with Points Stack
  useEffect(() => {
    if (flowHasGivePointsStack) {
      const otherBlocksInFlow = staticBlockData.length > 2;
      const pointsStackBlocks = staticBlockData.filter(
        (block) => block.type === 'GIVE_POINTS_STACK',
      ) as PointStackStaticBlockState[];
      pointsStackBlocks.forEach((block) => {
        const dependentBlock = staticBlockData.find(
          ({ id }) => id === block.dependentBlockId,
        ) as StaticBlockState;
        const isPointsStackEmpty = isBlockEmpty(block, values[block.id]);
        const isDependentBlockEmpty = isBlockEmpty(
          dependentBlock,
          values[block.dependentBlockId],
        );
        if (otherBlocksInFlow) {
          if (!isPointsStackEmpty) {
            if (isDependentBlockEmpty) {
              setFieldError(block.dependentBlockId, VALIDATION_ERRORS.REQUIRED);
            } else if (
              errors[block.dependentBlockId] === VALIDATION_ERRORS.REQUIRED
            ) {
              setFieldError(block.dependentBlockId, undefined);
            }
          } else if (
            isDependentBlockEmpty &&
            errors[block.dependentBlockId] === VALIDATION_ERRORS.REQUIRED
          ) {
            setFieldError(block.dependentBlockId, undefined);
          }
        } else if (isDependentBlockEmpty) {
          setFieldError(block.dependentBlockId, VALIDATION_ERRORS.REQUIRED);
        }
      });
    }
  }, [flowHasGivePointsStack, setFieldError, staticBlockData, values, errors]);

  const invokeParticipationTrackerFunction = (from = '', step: number) => {
    switch (from) {
      case STEP_BUTTON_STATUS.NEXT:
        trackParticipationFlow(
          PARTICIPATION_ANALYTICS_EVENTS.NAVIGATION_NEXT_CLICKED,
          step,
          ACTION_EVENTS.ACTION,
        );
        break;

      case STEP_BUTTON_STATUS.PREV:
        trackParticipationFlow(
          PARTICIPATION_ANALYTICS_EVENTS.NAVIGATION_PREV_CLICKED,
          step,
          ACTION_EVENTS.ACTION,
        );
        break;

      default:
        trackParticipationFlow(
          PARTICIPATION_ANALYTICS_EVENTS.PROGRESSBAR_CLICKED,
          step,
          ACTION_EVENTS.ACTION,
        );
        break;
    }
  };

  const onStepChange = (destinationStepIndex: number, fromNav = '') => {
    const newTouched = { ...touched };
    invokeParticipationTrackerFunction(fromNav, currentStep);

    const isValidDesintationIndex =
      destinationStepIndex >= 0 &&
      destinationStepIndex <= staticBlockData.length;
    if (!isValidDesintationIndex) return;

    if (destinationStepIndex < staticBlockData.length) {
      const { id: currentStepId } = staticBlockData[currentStep];
      const nextBlockData = staticBlockData[destinationStepIndex];
      const { id: nextStepId } = nextBlockData;
      // TO SPECIFICALLY CHECK IF NEXT BLOCK IS AN OPEN ENDED BLOCK OR MULTISELECT
      // AND IS IT EMPTY
      if (isBlockEmpty(nextBlockData, values[nextStepId])) {
        newTouched[nextStepId] = false;
      }

      if (postStepChange) {
        postStepChange(staticBlockData[currentStep], values[currentStepId]);
      }

      const currentStepError = (errors[currentStepId] || '') as string;
      if (
        currentStepError.length > 0 &&
        !isRequiredError(currentStepError) &&
        staticBlockData[currentStep].type === 'MULTI_CHOICE_MULTI_SELECT'
      ) {
        newTouched[currentStepId] = true;
        setTouched(newTouched);
        return;
      }

      if (
        currentStepError &&
        !isRequiredError(currentStepError) &&
        !touched[currentStepId]
      ) {
        newTouched[currentStepId] = true;
        setTouched(newTouched);
        return;
      }
      newTouched[currentStepId] = true;
      const isChangingToLastStep =
        destinationStepIndex === staticBlockData.length - 1;
      if (isChangingToLastStep) {
        setHasVisitedLastStep(true);
      }
      setTouched(newTouched);
      setCurrentStep(destinationStepIndex);
    }
  };

  const goToNextStep = () =>
    onStepChange(currentStep + 1, STEP_BUTTON_STATUS.NEXT);

  const goToPreviousStep = () =>
    onStepChange(currentStep - 1, STEP_BUTTON_STATUS.PREV);

  const onNeedHelpButtonClick = () => {
    trackParticipationFlow(
      PARTICIPATION_ANALYTICS_EVENTS.NEED_HELP_CLICKED,
      currentStep,
      ACTION_EVENTS.ACTION,
    );
  };

  const onPromptOpen = () => {
    trackParticipationFlow(
      PARTICIPATION_ANALYTICS_EVENTS.EXIT_TRIGGERED,
      currentStep,
      ACTION_EVENTS.ACTION,
    );
  };

  const onPromptClose = () => {
    trackParticipationFlow(
      PARTICIPATION_ANALYTICS_EVENTS.EXIT_CANCELED,
      currentStep,
      ACTION_EVENTS.ACTION,
    );
  };

  // This function is called on submit to start checking whether all the validation
  // checks are passing for each block. handleSubmit is called which is a formik function
  // which does not call formikOnSubmit until there are no errors in formik state.
  const onFormCompleteClick = () => {
    const firstError = dynamicBlockData.findIndex((item) => errors[item.id]);
    if (firstError > -1) {
      setCurrentStep(firstError);
      const updatedTouched: Record<string, boolean> = {};
      dynamicBlockData.forEach((item) => {
        updatedTouched[item.id] = true;
      });
      setTouched(updatedTouched);
      if (onSubmitFlowError) {
        onSubmitFlowError(staticBlockData, dynamicBlockData);
      }
    }
    submitForm();
  };

  useHardMouseWheel(containerRef, {
    deltaThreshold: 75,
    onScrollDown: () => onStepChange(currentStep + 1),
    onScrollUp: () => onStepChange(currentStep - 1),
    customCheck: (event) => {
      const openEndedElement = document.getElementById('open-ended-block');
      if (event.target) {
        if (openEndedElement?.contains(event.target as Node)) {
          return false;
        }
      }
      return true;
    },
  });

  return {
    models: {
      blockErrors,
      currentStep,
      fieldErrors,
      hasVisitedLastStep,
      values,
      errors,
      touched,
      dynamicBlockData,
    },
    operations: {
      onStepChange,
      onFormCompleteClick,
      setFieldValue,
      setFieldTouched,
      resetForm,
      goToNextStep,
      goToPreviousStep,
      onNeedHelpButtonClick,
      onPromptOpen,
      onPromptClose,
    },
  };
}

export default useParticipationFlow;
