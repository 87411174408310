import { ComponentStatus } from '../../../../interfaces/component';
import { MenuItemProps } from '../../../molecules/Dropdown_V2/interfaces';
import { MeetingItem, NonRecurringItem } from './type';

export const calendarDropdownItems: MenuItemProps[] = [
  {
    id: 'category-one',
    items: [
      {
        value: 'Primary Calendar',
        id: '1',
        isSelected: true,
      },
      {
        value: 'Paid Time Off',
        id: '2',
        isSelected: false,
      },
      {
        value: 'Personal Events',
        id: '3',
        isSelected: false,
      },
    ],
  },
];

export const meetingTypeDropdownItems: MenuItemProps[] = [
  {
    id: 'meeting-type',
    items: [
      {
        value: 'Recurring meetings',
        id: '1',
        isSelected: false,
      },
      {
        value: 'Non-recurring meetings',
        id: '2',
        isSelected: false,
      },
    ],
  },
];

export const recurringMeetingItems: MeetingItem[] = [
  {
    status: ComponentStatus.LOADED,
    id: '1',
    title: 'Daily Standup',
    participants: 45,
    subTitle: 'Weekly on weekdays',
    isStarted: false,
    isChecked: false,
    start: {
      dateTime: new Date().toString(),
    },
    end: {
      dateTime: new Date().toString(),
    },
    recurrence: '',
  },
  {
    status: ComponentStatus.LOADED,
    id: '2',
    title: '1:1 with Brooke',
    participants: 2,
    subTitle: 'Every 2 weeks on Tues, Thurs at 1:30 - 2:30 PM PST',
    isStarted: false,
    isChecked: false,
    start: {
      dateTime: new Date().toString(),
    },
    end: {
      dateTime: new Date().toString(),
    },
    recurrence: '',
  },
  {
    status: ComponentStatus.LOADED,
    id: '3',
    title: 'Daily Standup',
    participants: 45,
    subTitle: 'Weekly on weekdays',
    isStarted: false,
    isChecked: true,
    start: {
      dateTime: new Date().toString(),
    },
    end: {
      dateTime: new Date().toString(),
    },
    recurrence: '',
  },
  {
    status: ComponentStatus.LOADING,
    id: '4',
    title: 'Daily Standup',
    participants: 45,
    subTitle: 'Weekly on weekdays',
    isStarted: false,
    isChecked: false,
    start: {
      dateTime: new Date().toString(),
    },
    end: {
      dateTime: new Date().toString(),
    },
    recurrence: '',
  },
];

export const nonRecurringMeetingItems: NonRecurringItem[] = [
  {
    title: 'Today • Monday November 9, 2021',
    items: [
      {
        status: ComponentStatus.LOADED,
        id: '1',
        title: 'Notebook Discussion',
        participants: 45,
        subTitle: 'Weekly on weekdays',
        isStarted: false,
        isChecked: false,
        start: {
          dateTime: new Date().toString(),
        },
        end: {
          dateTime: new Date().toString(),
        },
        recurrence: '',
      },
    ],
  },
  {
    title: 'Tuesday November 10, 2021 - Wednesday November 18, 2021',
    items: [],
  },
];
