import { SplitFactory } from '@splitsoftware/splitio-react';
import get from 'lodash/get';
import { parse } from 'qs';
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Route, Router, Switch, useLocation } from 'react-router-dom';
import AssemblyLanderLoadingScreen from '../atomic/molecules/AssemblyLanderLoadingScreen';
import RoutesList from '../aV2/routes/routesList';
import { PLAN_IDS, SPLIT_AUTH_KEY } from '../config';
import { DEVICE, DEVICES } from '../constants/devices';
import {
  AUTH_SAML,
  EXTERNAL_PATH,
  FLOWS,
  FLOWS_PARTICIPATE,
  GOOGLE_ONE_TAP_AUTH,
  KIOSK_PAGE_PATH,
  KNOWLEDGE_CENTER,
  NOTEBOOK,
  POST,
  QUICK_SETUP,
  RECOGNITION_PARTICIPATE,
  USER_ACCOUNT_SETUP,
  V2_GENERAL_SETTINGS,
  V2_HOME,
  V2_NOTIFICATION_SETTINGS,
  V2_REWARDS,
  V2_SETTINGS,
  V2_USER_PROFILE,
} from '../constants/routes';
import SAMLAuthController from '../controllers/auth/SAMLAuthController';
import ClientAppController from '../controllers/ClientAppController';
import ExternalRoutesController from '../controllers/ExternalRoutesController';
import V2RouteController from '../controllers/V2RouteController';
import history from '../history';
import useDataDog from '../hooks/useDataDog';
import { useFeatureSplit } from '../hooks/useFeatureSplit';
import {
  SplitNames,
  TreatmentTypes,
} from '../hooks/useSplitSdkConfig/constants';
import { HttpsStatus } from '../interfaces/ResponseError';
import {
  useCurrentPlanFetchQuery,
  useProfileInfoFetchQuery,
} from '../queries/Profile';
import ErrorBoundary from '../screens/ErrorBoundary';
import * as Analytics from '../Utils/analytics';
import { trackPageView } from '../Utils/analytics';
import { setSourceDeviceAsIPhone } from '../Utils/device';
import RoutesComponent from './routesComponent';
import KioskPage from '../atomic/pages/KioskPage';
import KioskFooter from '../atomic/organism/KioskFooter';
import FlowsParticipationController from '../controllers/flows/FlowsParticipationController';
import NewAdminRoutesComponent from '../screens/admin/NewAdminRoutes';
import RecognitionParticipationController from '../controllers/flows/RecognitionParticipationController';

const ANONYMOUS = 'anonymous';

const SplitV2 = () => {
  const { search, pathname } = useLocation();

  const { treatment: knowledgeCenter } = useFeatureSplit(
    SplitNames.KNOWLEDGE_CENTER,
  );

  const { treatment: adminRefactorTreatment } = useFeatureSplit(
    SplitNames.ADMIN_NEW_APP,
  );

  const isKnowledgeCenterEnabled = knowledgeCenter === TreatmentTypes.ON;
  const isNewAdminAppEnabled = adminRefactorTreatment === TreatmentTypes.ON;

  useEffect(() => {
    trackPageView(pathname);
  }, [pathname]);

  useEffect(() => {
    const parsedParams = parse(search, {
      ignoreQueryPrefix: true,
    });
    const isDevicePresent = parsedParams && parsedParams[DEVICE];
    if (isDevicePresent) {
      const { device } = parsedParams;
      if (device === DEVICES.IPHONE) {
        setSourceDeviceAsIPhone();
      }
    }
  }, [search]);
  return (
    <Switch>
      {isNewAdminAppEnabled && (
        <Route path="/admin" component={NewAdminRoutesComponent} />
      )}

      {isNewAdminAppEnabled && (
        <Route
          path={RoutesList.BOT_AUTHORIZE}
          component={NewAdminRoutesComponent}
        />
      )}

      {isNewAdminAppEnabled && (
        <Route
          path={RoutesList.IM_AUTHORIZE}
          component={NewAdminRoutesComponent}
        />
      )}

      {isNewAdminAppEnabled && (
        <Route
          path={RoutesList.MSTEAM_CONFIG}
          component={NewAdminRoutesComponent}
        />
      )}

      <Route
        exact
        path={RoutesList.EMPLOYEE_SET_DETAILS}
        component={RoutesComponent}
      />
      <Route
        exact
        path={RECOGNITION_PARTICIPATE}
        component={RecognitionParticipationController}
      />
      <Route
        exact
        path={FLOWS_PARTICIPATE}
        component={FlowsParticipationController}
      />
      <Route path={USER_ACCOUNT_SETUP} exact component={RoutesComponent} />
      <Route path={GOOGLE_ONE_TAP_AUTH} exact component={RoutesComponent} />
      <Route path={V2_HOME} component={V2RouteController} />
      <Route path={V2_REWARDS} component={V2RouteController} />
      <Route path={V2_USER_PROFILE} component={V2RouteController} />
      <Route path={V2_SETTINGS} component={V2RouteController} />
      <Route path={V2_GENERAL_SETTINGS} component={V2RouteController} />
      <Route path={AUTH_SAML} component={SAMLAuthController} />
      <Route path={V2_NOTIFICATION_SETTINGS} component={V2RouteController} />
      <Route path={POST} component={V2RouteController} />
      <Route path={FLOWS} component={V2RouteController} />
      <Route path={NOTEBOOK} component={V2RouteController} />
      <Route path={QUICK_SETUP} component={V2RouteController} />
      {isKnowledgeCenterEnabled && (
        <Route path={KNOWLEDGE_CENTER} component={V2RouteController} />
      )}
      <Route path={EXTERNAL_PATH} component={ExternalRoutesController} />
      <Route path={KIOSK_PAGE_PATH} component={KioskPage} />
      <Route path={RoutesList.CLIENT_APP} component={ClientAppController} />
      <Route component={RoutesComponent} />
    </Switch>
  );
};

const Routers = () => {
  const authenticated = useSelector((state) =>
    get(state, ['auth', 'authenticated'], false),
  );
  useDataDog(authenticated);

  const { data, isLoading, isError, error } =
    useProfileInfoFetchQuery(authenticated);
  const { data: planData } = useCurrentPlanFetchQuery(authenticated);

  useEffect(() => {
    if (data) {
      Analytics.registerPropsFromUser(data.member);
    }
  }, [data]);

  useEffect(() => {
    if (planData) {
      Analytics.registerUserProps({
        planType: planData.uniqueId,
        isPaid: planData.uniqueId !== PLAN_IDS.tier1,
      });
    }
  }, [planData]);

  const splitConfig = useMemo(() => {
    const assemblyId = data?.assembly?.assemblyId;
    return {
      core: {
        authorizationKey: SPLIT_AUTH_KEY,
        key: assemblyId || ANONYMOUS,
      },
      startup: {
        readyTimeout: 1.5, // 1.5 sec
      },
    };
  }, [data]);

  useEffect(() => {
    if (
      isError &&
      error &&
      error.response &&
      error.response.status === HttpsStatus.UNAUTHORIZED
    ) {
      history.push(RoutesList.SIGN_OUT);
    }
  }, [error, isError]);
  if (isLoading && authenticated) {
    return <AssemblyLanderLoadingScreen />;
  }

  return (
    <SplitFactory config={splitConfig} updateOnSdkUpdate>
      <ErrorBoundary>
        <Router history={history}>
          <SplitV2 />
          <KioskFooter />
        </Router>
      </ErrorBoundary>
    </SplitFactory>
  );
};
export default Routers;
