import { useEffect } from 'react';

import {
  useDonationFetchQuery,
  useExchangeRatesFetchQuery,
} from '../../../queries/Rewards';
import { getCurrencyCodes } from '../../../Utils/rewards';
import { RewardsFetchQueryParamsType } from '../../../queries/Rewards/interfaces';

const useCharityControllerModel = (params: RewardsFetchQueryParamsType) => {
  const {
    isLoading: isRewardsLoading,
    isError,
    refetch,
    data: rewardsData,
  } = useDonationFetchQuery(params);
  const {
    isLoading: isExchangeRatesLoading,
    data: exchangeRatesData,
    mutate: exchangeRateMutate,
  } = useExchangeRatesFetchQuery();

  useEffect(() => {
    if (rewardsData && rewardsData.rewards.length > 0 && !exchangeRatesData) {
      const currencyCodes = getCurrencyCodes(rewardsData.rewards);
      exchangeRateMutate(currencyCodes);
    }
  }, [exchangeRateMutate, exchangeRatesData, rewardsData]);

  const isLoading = isRewardsLoading || isExchangeRatesLoading;
  return {
    isLoading,
    isError,
    refetch,
    rewardsData,
    exchangeRates: exchangeRatesData?.data.data.exchangeRates,
  };
};

export default useCharityControllerModel;
