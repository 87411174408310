// Important - Dont change it unless sure of it
const APP_STAGE = process.env.REACT_APP_STAGE || 'localdev';

const PLATFORM = 'web';
const MAX_CUSTOM_ALLOWANCE = 100000;

const WEB_BASE_URL = 'joinassembly.com';

const SISENSE_CONFIG = {
  SERVER: 'https://assembly.sisense.com',
  DASHBOARD: {
    GENERAL_SUMMARY_STATS: '61f075fe86aef30035050665',
    STATS_OVERTIME_WITH_OCCURRENCES: '61f0769fca42150035f8c677',
    STATS_OVERTIME_WITHOUT_OCCURRENCES: '61f07c2786aef30035050675',
    OPEN_ENDED_DASHBOARD: '6193acd9ca42150035f8bf9b',
    GIF_DASHBOARD: '6193acc486aef3003504fec5',
    FILE_UPLOAD_DASHBOARD: '6193acb186aef3003504feaf',
    SCALE_DASHBOARD: '6193aceaca42150035f8bfa1',
    DROPDOWN_DASHBOARD: '6193acf4ca42150035f8bfa6',
    MULTI_SELECT_DASHBOARD: '6193accfca42150035f8bf96',
    MULTI_SELECT_DASHBOARD_OTHERS: '6193fe7b86aef3003504ff2d',
    PERSON_SELECTOR_SINGLE_DASHBOARD: '6193fbcaca42150035f8bff6',
    PERSON_SELECTOR_MULTI_DASHBOARD: '6193ace186aef3003504fecd',
    GIVE_POINTS_DASHBOARD: '61b2788bca42150035f8c1b7',
    TRENDS_STATS: '61945b87ca42150035f8c06d',
    FILE_UPLOAD_TRENDS: '6195a49eca42150035f8c0f2',
    GIF_TRENDS: '6195a37fca42150035f8c0e9',
    SCALE_TRENDS: '619599fc86aef3003504ffce',
    GIVE_POINTS_TRENDS: '6195a2b8ca42150035f8c0e3',
    MULTI_SELECT_TRENDS: '6195970986aef3003504ffbc',
    OPEN_ENDED_TRENDS: '61958b1486aef3003504ff97',
    PERSON_SELECTOR_SINGLE_TRENDS: '61959e17ca42150035f8c0c1',
    PERSON_SELECTOR_MULTIPLE_TRENDS: '61959f0e86aef3003504ffeb',
    SINGLE_SELECT_TRENDS: '6195927486aef3003504ffa8',
  },
  WIDGETS: {
    UNIQUE_RESPONDANTS_PER_WEEK_NO_OCCURRANCE: '61f07c2786aef30035050677',
    PERCENTAGE_BLOCK_COMPLETED_NO_OCCURRANCE: '61f07c2786aef30035050678',
    COMPLETION_RATE_BY_WEEK: '61f0769fca42150035f8c678',
    TOTAL_RESPONSES_BY_WEEK: '61f07aaeca42150035f8c683',
    PERCENTAGE_OF_BLOCKS_COMPLETED_OVERTIME: '61f07b4bca42150035f8c685',
    STARTS: '624f659d2756a200394c5815',
    TOTAL_RESPONSES: '61f075fe86aef30035050666',
    PARTICIPATION_RATE_DASHBOARD: '61f0851b86aef30035050689',
    PERSON_RESPONSES_STAT: '61f0851b86aef30035050689',
    UNIQUE_RESPONDANTS: '61e1eab586aef30035050594',
    OCCURRENCES_PARTICIPATION_RATE: '61e1eab586aef30035050595',
    PARTICIPATION_STATS: '61f07faa86aef3003505067e',
    UNIQUE_FLOWS_PARTICIPANTS: '61f09851ca42150035f8c711',
    CURRENT_PARTICIPANTS: '6193acb486aef3003504feb5',
    COMPLETION_RATE: '624f66d22756a200394c5819',
    NUMBER_OF_OCCURANCES: '6193acb486aef3003504feb9',
    ANSWER_ANYTIME_PARTICIPATION_RATE: '6193acb486aef3003504feb7',
    ANSWER_ANYTIME_RESPONSE_PER_PERSON: '6193acb486aef3003504feb8',
    BLOCKS_COMPLETED: '6193acb486aef3003504feba',
    TOTAL_PARTICIPATION: '6193acb486aef3003504febb',
    OPEN_ENDED: '6193acd9ca42150035f8bf9c',
    GIF: '6193acc486aef3003504fec6',
    FILE_UPLOAD: '6193acb186aef3003504feb0',
    SCALE: '6193aceaca42150035f8bfa2',
    DROPDOWN: '6193acf4ca42150035f8bfa7',
    MULTI_SELECT: '6193accfca42150035f8bf97',
    MULTI_SELECT_OTHERS: '6193fe7b86aef3003504ff2e',
    MULTI_SELECT_OTHERS_VALUE: '619401eeca42150035f8c014',
    PERSON_SELECTOR_SINGLE: '6193fbcaca42150035f8bff7',
    GIVE_POINTS: '61b2788bca42150035f8c1b8',
    PERSON_SELECTOR_MULTI: '6193ace186aef3003504fece',
    TRENDS_STATS: '619461db86aef3003504ff86',
    FILE_UPLOAD_TRENDS: '6195a49eca42150035f8c0f3',
    GIF_TRENDS: '6195a39786aef3003504fff8',
    SCALE_TRENDS: '61959a7fca42150035f8c0b6',
    GIVE_POINTS_TRENDS: '6195a32286aef3003504fff2',
    MULTI_SELECT_TRENDS: '61959811ca42150035f8c0af',
    OPEN_ENDED_TRENDS: '6195917c86aef3003504ff9e',
    PERSON_SELECTOR_SINGLE_TRENDS: '61959eddca42150035f8c0dc',
    PERSON_SELECTOR_MULTIPLE_TRENDS: '61959fb186aef3003504ffee',
    SINGLE_SELECT_TRENDS: '619592ddca42150035f8c092',
  },
};

const dev = {
  API_URL: 'https://api-dev.joinassembly.com',
  STRIPE_PUBLISHABLE: 'pk_test_m8GXOCB2pjtUnvAoJxDyJ19o',
  SLACK_APP_URL: '255786340961.781607625924',
  // IP_STACK_ACCESS_TOKEN: 'fb377cf9b19ecf35b1358686fdc8a54f',
  GIPHY_KEY: 'jAE9Ya0RlClP2vSDqo8umIhz14JYKwy4',
  APP_URL: 'https://dev.joinassembly.com/',
  ADMIN_APP_URL: 'https://dev-admin.joinassembly.com',
  SOURCE: 'API',
  SPLIT_AUTH_KEY: 'ibhu998u3ndtr0num1ss8pg8jn0md822v96f',
  MS_TEAMS: {
    ENTITY_ID: 'joinAssembly-tab',
    NAME: 'Assembly-dev',
  },
  DATA_DOG: {
    applicationId: '6ef28a14-4941-44b5-935e-75c07f51044c',
    clientToken: 'pubb2d38a6d5d5b49b338441586d68746a9',
    service: 'assembly-dev',
    env: 'dev',
  },
  ADP_MARKETPLACE_URL: 'https://apps.adp.com/apps/307297',
  MIXPANEL_KEY: 'f8cf5a53c3894e8d32611034942c03df',
  PUSHER: {
    KEY: '65656f0ec6850a60d9d5',
  },
  GOOGLE_RECAPTCHA_SITE_KEY: '6Leyk-oeAAAAACCnCogUKlajDEH15trv7Q4thR0Z',
};

const local = {
  API_URL: 'https://api-stg.joinassembly.com',
  STRIPE_PUBLISHABLE: 'pk_test_m8GXOCB2pjtUnvAoJxDyJ19o',
  SLACK_APP_URL: '255786340961.407704820679',
  IP_STACK_ACCESS_TOKEN: 'fb377cf9b19ecf35b1358686fdc8a54f',
  GIPHY_KEY: 'jAE9Ya0RlClP2vSDqo8umIhz14JYKwy4',
  APP_URL: 'https://stg.joinassembly.com/',
  ADMIN_APP_URL: 'https://stg-admin.joinassembly.com',
  SOURCE: 'API',
  SPLIT_AUTH_KEY: 'ibhu998u3ndtr0num1ss8pg8jn0md822v96f',
  MS_TEAMS: {
    ENTITY_ID: 'joinAssembly-tab',
    NAME: 'Assembly-dev',
  },
  DATA_DOG: {
    applicationId: '6ef28a14-4941-44b5-935e-75c07f51044c',
    clientToken: 'pubb2d38a6d5d5b49b338441586d68746a9',
    service: 'assembly-dev',
    env: 'staging',
  },
  ADP_MARKETPLACE_URL: 'https://apps.adp.com/apps/307297',
  MIXPANEL_KEY: 'f8cf5a53c3894e8d32611034942c03df',
  PUSHER: {
    KEY: '65656f0ec6850a60d9d5',
  },
  GOOGLE_RECAPTCHA_SITE_KEY: '6Leyk-oeAAAAACCnCogUKlajDEH15trv7Q4thR0Z',
};

const production = {
  API_URL: 'https://api-stg.joinassembly.com',
  STRIPE_PUBLISHABLE: 'pk_test_m8GXOCB2pjtUnvAoJxDyJ19o',
  SLACK_APP_URL: '255786340961.407704820679',
  IP_STACK_ACCESS_TOKEN: 'fb377cf9b19ecf35b1358686fdc8a54f',
  GIPHY_KEY: 'jAE9Ya0RlClP2vSDqo8umIhz14JYKwy4',
  APP_URL: 'https://stg.joinassembly.com/',
  ADMIN_APP_URL: 'https://stg-admin.joinassembly.com',
  SOURCE: 'API',
  SPLIT_AUTH_KEY: 'ibhu998u3ndtr0num1ss8pg8jn0md822v96f',
  MS_TEAMS: {
    ENTITY_ID: 'joinAssembly-tab',
    NAME: 'Assembly-dev',
  },
  DATA_DOG: {
    applicationId: '6ef28a14-4941-44b5-935e-75c07f51044c',
    clientToken: 'pubb2d38a6d5d5b49b338441586d68746a9',
    service: 'assembly-dev',
    env: 'staging',
  },
  ADP_MARKETPLACE_URL: 'https://apps.adp.com/apps/307297',
  MIXPANEL_KEY: 'f8cf5a53c3894e8d32611034942c03df',
  PUSHER: {
    KEY: '65656f0ec6850a60d9d5',
  },
  GOOGLE_RECAPTCHA_SITE_KEY: '6Leyk-oeAAAAACCnCogUKlajDEH15trv7Q4thR0Z',
};

const staging = {
  API_URL: 'https://api-stg.joinassembly.com',
  STRIPE_PUBLISHABLE: 'pk_test_m8GXOCB2pjtUnvAoJxDyJ19o',
  SLACK_APP_URL: '255786340961.407704820679',
  IP_STACK_ACCESS_TOKEN: 'fb377cf9b19ecf35b1358686fdc8a54f',
  GIPHY_KEY: 'jAE9Ya0RlClP2vSDqo8umIhz14JYKwy4',
  APP_URL: 'https://stg.joinassembly.com/',
  ADMIN_APP_URL: 'https://stg-admin.joinassembly.com',
  SOURCE: 'API',
  SPLIT_AUTH_KEY: 'ibhu998u3ndtr0num1ss8pg8jn0md822v96f',
  MS_TEAMS: {
    ENTITY_ID: 'joinAssembly-tab',
    NAME: 'Assembly-dev',
  },
  DATA_DOG: {
    applicationId: '6ef28a14-4941-44b5-935e-75c07f51044c',
    clientToken: 'pubb2d38a6d5d5b49b338441586d68746a9',
    service: 'assembly-dev',
    env: 'staging',
  },
  ADP_MARKETPLACE_URL: 'https://apps.adp.com/apps/307297',
  MIXPANEL_KEY: 'f8cf5a53c3894e8d32611034942c03df',
  PUSHER: {
    KEY: '65656f0ec6850a60d9d5',
  },
  GOOGLE_RECAPTCHA_SITE_KEY: '6Leyk-oeAAAAACCnCogUKlajDEH15trv7Q4thR0Z',
};

const getConfig = () => {
  if (process.env.REACT_APP_STAGE === 'production') {
    return production;
  }
  if (process.env.REACT_APP_STAGE === 'development') {
    return dev;
  }
  if (process.env.REACT_APP_STAGE === 'staging') {
    return staging;
  }
  return local;
};

const config = getConfig();
if (process.env.NODE_ENV !== 'test') {
  // eslint-disable-next-line no-console
  console.log('Running in ', APP_STAGE);
}

const PLAN_IDS = {
  tier1: 'free',
  tier2: 'lite',
  tier3: 'standard',
  tier4: 'premium',
};

const { API_URL: defaultApiUrl } = config;

const API_URL = process.env.REACT_APP_API_URL || defaultApiUrl;

const API_URL_WITH_V2 = `${API_URL}/api/v2`;
const API_URL_WITH_V3 = `${API_URL}/api/v3`;
const {
  STRIPE_PUBLISHABLE,
  SLACK_APP_URL,
  // IP_STACK_ACCESS_TOKEN,
  GIPHY_KEY,
  APP_URL,
  ADMIN_APP_URL,
  SOURCE,
  SPLIT_AUTH_KEY,
  MS_TEAMS,
  ADP_MARKETPLACE_URL,
  MIXPANEL_KEY,
  DATA_DOG,
  PUSHER,
  GOOGLE_RECAPTCHA_SITE_KEY,
} = config;

export const GOOGLE_RECAPTCHA_ENDPOINT = `https://www.google.com/recaptcha/enterprise.js`;
export const IP_API_KEY = 'NiQCyrJTYLg4dlnOJNhg4zvNQaw1PLfF5V05iDLHfGhNnaTu21';

const GET_PLATFORM = (isFromMSTeams: boolean) =>
  isFromMSTeams ? 'MS Teams' : PLATFORM;

const LANDER_URL = `https://${WEB_BASE_URL}`;
const HELP_URL = `https://${WEB_BASE_URL}/faq`;
const TERMS_URL = `https://${WEB_BASE_URL}/terms-of-service`;
const PRIVACY_URL = `https://${WEB_BASE_URL}/privacy-policy`;
const BLOG_URL = `https://${WEB_BASE_URL}/blog`;

const IOS_URL = 'com.joinassembly.assembly:/';
const GO_TO_AXOMO_LINK =
  'https://shop.axomo.com/store-generator?referralCode=ASSEMBLY';
const AXOMO_URL = 'https://axomo.com';

export {
  API_URL,
  API_URL_WITH_V2,
  API_URL_WITH_V3,
  STRIPE_PUBLISHABLE,
  SLACK_APP_URL,
  // IP_STACK_ACCESS_TOKEN,
  MAX_CUSTOM_ALLOWANCE,
  GIPHY_KEY,
  APP_URL,
  ADMIN_APP_URL,
  LANDER_URL,
  HELP_URL,
  TERMS_URL,
  PRIVACY_URL,
  BLOG_URL,
  GET_PLATFORM,
  SOURCE,
  SPLIT_AUTH_KEY,
  MIXPANEL_KEY,
  MS_TEAMS,
  ADP_MARKETPLACE_URL,
  PLAN_IDS,
  IOS_URL,
  DATA_DOG,
  PUSHER,
  SISENSE_CONFIG,
  GO_TO_AXOMO_LINK,
  GOOGLE_RECAPTCHA_SITE_KEY,
  AXOMO_URL,
};
