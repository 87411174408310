import React from 'react';

import { StyledPrimaryNavItem } from './styles';
import { PrimaryNavItemProps } from './interfaces';

import SVGIcon from '../SVGIcon';

const PrimaryNavItem = (props: PrimaryNavItemProps) => {
  const { children, icon, size, isActive } = props;
  return (
    <StyledPrimaryNavItem variant="body2" isActive={isActive}>
      <SVGIcon icon={icon} size={size} />
      {children}
    </StyledPrimaryNavItem>
  );
};

PrimaryNavItem.defaultProps = {
  size: '16px',
  isActive: false,
};

export default PrimaryNavItem;
