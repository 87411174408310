import { useCallback, useMemo, useState } from 'react';

import { APP_NAME } from '../../../../languages/en';
import AssemblyIcon from '../../../../atomic/atoms/SVGIcon/icons/assembly-icon.svg';
import { Category } from '../../../../atomic/molecules/RequestTemplateLeftColumn/types';
import { FlowRequestTemplateCardProps } from '../../../../atomic/molecules/FlowRequestTemplateCard/type';

import { QuickSetupStep } from '../../QuickSetupController/types';
import { useProfileInfoFetchQuery } from '../../../../queries/Profile';

import {
  useGetFlowTemplate,
  useGetAllFlowsTemplates,
  useGetFlowsTemplateCategories,
} from '../../../../queries/Flows/Template';

import {
  mapColorToPalette,
  mapHexCodeToEmoticon,
} from '../../../../Utils/mappers';

import { isEmpty } from '../../../../Utils/common';
import { TemplateSelectionControllerProps } from '../type';
import { QUICK_SETUP_FLOWS } from '../../../../Utils/quickSetup/constants';

import {
  useUpdateQuickSetupStatus,
  useGetCalendarEventDetailsQuery,
} from '../../../../queries/QuickSetup';

import {
  QuickSetupDirectionEnum,
  QuickSetupCalendarStepsEnum,
} from '../../../../queries/QuickSetup/interfaces';
import { trackOnboardingActionEvent } from '../../../../Utils/analytics/onboarding';
import {
  ACCOUNT_ONBOARDING_EVENTS,
  TEMPLATE_GALLERY_EVENTS,
} from '../../../../Utils/analytics/constants';
import { trackTemplateGalleryActionEvent } from '../../../../Utils/analytics/templateGallery';

const useTemplateSelectionController = (
  props: TemplateSelectionControllerProps,
) => {
  const {
    flow,
    meetingId,
    calenderId,
    meetingName,
    selectedTemplate,
    meetingReccurence,
    onQuickSetupStepChange,
    onTemplateChange,
  } = props;

  const { mutate: mutateQuickSetup } = useUpdateQuickSetupStatus();

  // Profile Info
  const { data: profileInfo } = useProfileInfoFetchQuery();

  useGetCalendarEventDetailsQuery(
    calenderId,
    meetingId,
    !isEmpty(calenderId) && !isEmpty(meetingId),
  );

  // Selected Template
  const { isLoading: isFlowTemplateLoading, data: flowTemplateData } =
    useGetFlowTemplate(selectedTemplate ? selectedTemplate.templateId : '');

  const closePreviewTemplateModal = useCallback(
    () => onTemplateChange(undefined),
    [onTemplateChange],
  );

  /* Mix panel */
  const source = 'onBoarding';
  /* Mix panel */

  const handleOnPreviewFlowClick = useCallback(
    (selectedRequestTemplate: FlowRequestTemplateCardProps) => {
      trackOnboardingActionEvent({
        action: ACCOUNT_ONBOARDING_EVENTS.TEMPLATE_SELECTED,
        path: flow,
        templateId: selectedTemplate?.templateId || '',
        meetingTitle: meetingName,
        meetingRecurs: meetingReccurence
          ? !meetingReccurence.includes('COUNT=1')
          : undefined,
      });

      onTemplateChange(selectedRequestTemplate);

      /* Mix panel */
      trackTemplateGalleryActionEvent({
        action: TEMPLATE_GALLERY_EVENTS.PREVIEW_TEMPLATE,
        templateId: selectedTemplate?.templateId,
        templateName: selectedTemplate?.title,
        source: source,
      });
      /* Mix panel */
    },
    [flow, meetingName, meetingReccurence, onTemplateChange, selectedTemplate],
  );

  const handleUseTemplateClick = useCallback(() => {
    trackOnboardingActionEvent({
      action: ACCOUNT_ONBOARDING_EVENTS.USE_TEMPLATE,
      path: flow,
      templateId: selectedTemplate?.templateId || '',
      meetingTitle: meetingName,
      meetingRecurs: meetingReccurence
        ? !meetingReccurence.includes('COUNT=1')
        : undefined,
    });

    /* Mix panel */
    trackTemplateGalleryActionEvent({
      action: TEMPLATE_GALLERY_EVENTS.USE_TEMPLATE,
      templateId: selectedTemplate?.templateId,
      templateName: selectedTemplate?.title,
      source: source,
    });
    /* Mix panel */

    if (flow === QUICK_SETUP_FLOWS.SURVEY_TEAM) {
      onQuickSetupStepChange(QuickSetupStep.COMPLETION);
    } else {
      mutateQuickSetup({
        direction: QuickSetupDirectionEnum.FORWARD,
        calendar: {
          value: QuickSetupCalendarStepsEnum.INVITE_MEMBER,
          metaData: {
            meetingId: meetingId,
            calenderId: calenderId,
            meetingName: meetingName,
            rrule: meetingReccurence,
            templateId: selectedTemplate?.templateId,
          },
        },
      });

      onQuickSetupStepChange(QuickSetupStep.ADD_PARTICIPANTS);
    }
  }, [
    flow,
    selectedTemplate,
    meetingName,
    meetingReccurence,
    onQuickSetupStepChange,
    mutateQuickSetup,
    meetingId,
    calenderId,
  ]);

  // Templates & Categories
  const { data: templatesResponse, isLoading: isTemplatesLoading } =
    useGetAllFlowsTemplates();
  const { data: categoriesResponse, isLoading: isCategoriesLoading } =
    useGetFlowsTemplateCategories();

  const [searchText, setSearchText] = useState<string>('');
  const [category, setCategory] = useState<Category | undefined>(undefined);

  const handleSetSearchText = useCallback(
    (updatedSearchText: string) => {
      setSearchText(updatedSearchText);
      /* Mix panel */
      trackTemplateGalleryActionEvent({
        action: TEMPLATE_GALLERY_EVENTS.SEARCH,
        templateId: selectedTemplate?.templateId,
        templateName: selectedTemplate?.title,
        searchQuery: updatedSearchText,
        filterApplied: category?.title,
        source: source,
      });
      /* Mix panel */
    },
    [category?.title, selectedTemplate?.templateId, selectedTemplate?.title],
  );

  const handleSetCategory = useCallback(
    (updatedCategory: Category | undefined) => {
      setCategory(updatedCategory);
      /* Mix panel */
      trackTemplateGalleryActionEvent({
        action: TEMPLATE_GALLERY_EVENTS.APPLY_FILTER,
        templateId: selectedTemplate?.templateId,
        templateName: selectedTemplate?.title,
        searchQuery: searchText,
        filterApplied: updatedCategory?.title,
        source: source,
      });
      /* Mix panel */
    },
    [searchText, selectedTemplate?.templateId, selectedTemplate?.title],
  );

  const categories = useMemo(() => {
    if (!categoriesResponse || !categoriesResponse.data) {
      return [];
    }

    const categoryAll = {
      id: 'all',
      key: 'ALL',
      name: 'All',
      title: 'All',
      color: 'sunsetOrange2',
      description: '',
    };

    const availableCategories = categoriesResponse?.data?.map((x) => {
      return {
        ...x,
        id: x.key,
        title: x.name,
      };
    });

    availableCategories.unshift(categoryAll);
    setCategory(availableCategories[0]);

    return availableCategories;
  }, [categoriesResponse]);

  const templates = useMemo(() => {
    if (!templatesResponse || !templatesResponse.data) {
      return [];
    }

    return templatesResponse.data
      .map((template) => {
        const categoryForTemplate = categories.find(
          (x) => x.id === template.category,
        );
        let color = '';
        if (categoryForTemplate) {
          color = mapColorToPalette(categoryForTemplate.color);
        }
        return {
          color: color,
          noOfParticipants: 0,
          title: template.title,
          templateId: template.templateId,
          emoticon: mapHexCodeToEmoticon(template.icon.value),
          category: template.category,
          avatarProps: {
            img: AssemblyIcon,
            name: APP_NAME,
          },
        };
      })
      .filter((x) =>
        !category || category?.id === 'all'
          ? true
          : x.category === category?.id,
      )
      .filter(
        (x) =>
          !searchText ||
          x.title.toLowerCase().includes(searchText.toLowerCase()),
      );
  }, [categories, category, templatesResponse, searchText]);

  return {
    models: {
      templates,
      categories,
      searchText,
      profileInfo,
      flowTemplateData,
      selectedTemplate,
      isFlowTemplateLoading,
      isTemplateSelectionSetupLoading:
        isTemplatesLoading || isCategoriesLoading,
      selectedCategory: category,
    },
    operations: {
      handleUseTemplateClick,
      handleOnPreviewFlowClick,
      closePreviewTemplateModal,
      handleSearchTextChange: handleSetSearchText,
      handleSelectedCategoryChange: handleSetCategory,
    },
  };
};

export default useTemplateSelectionController;
