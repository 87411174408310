import React from 'react';
import useDropDown from '../../../hooks/useDropDown';
import IconButton from '../../atoms/IconButton_V2';
import DropdownListHolder, { DropdownWrapper } from '../DropdownListHolder';
import { DropdownPlacement } from '../Dropdown_V2/interfaces';
import { DropdownIconButtonProps } from './interfaces';

const DropdownIconButton = (props: DropdownIconButtonProps) => {
  const {
    size,
    icon,
    rounded,
    isSelected,
    title,
    className,
    color,
    iconColor,
    dropdownPlacement = DropdownPlacement.BottomEnd,
    isDropdownPositionFixed,
    dataTestId,
    onClick,
    stopEventPropagation,
  } = props;

  const { anchorProps } = useDropDown(props);
  const {
    referenceElement,
    isOpen,
    toggleDropdown,
    isFullWidth,
    disabled,
    anchorEl,
  } = anchorProps;

  return (
    <DropdownWrapper
      ref={referenceElement}
      isFullWidth={isFullWidth}
      className={className}
    >
      <IconButton
        color={color}
        iconColor={iconColor}
        disabled={disabled}
        size={size}
        icon={icon}
        rounded={rounded}
        isSelected={(!disabled && isOpen) || isSelected}
        title={title}
        onClick={(e: MouseEvent) => {
          toggleDropdown();
          if (onClick) onClick();
          if (stopEventPropagation) {
            e.stopPropagation();
          }
        }}
        buttonRef={anchorEl}
        dataTestId={dataTestId}
      />
      <DropdownListHolder
        {...anchorProps}
        dropdownPlacement={dropdownPlacement}
        isDropdownPositionFixed={isDropdownPositionFixed}
      />
    </DropdownWrapper>
  );
};

DropdownIconButton.defaultProps = {
  selectedMenuItemID: null,
  menuItems: [],
  onItemClick: () => {},
  disabled: false,
  size: 'normal',
  icon: '',
  rounded: false,
  isSelected: false,
  title: '',
  multiSelect: false,
  dataTestId: '',
};

const MemoizedDropdownIconButton = React.memo(DropdownIconButton);
MemoizedDropdownIconButton.displayName = 'DropdownIconButton';
export default MemoizedDropdownIconButton;
