import React from 'react';
import { Route, Switch } from 'react-router-dom';

import useLayoutStore from '../../../stores/layoutStore';

import {
  V2_HOME,
  FLOW_FEED,
  FLOWS_FEEDS,
  FLOW_INSIGHTS,
  FLOWS_BUILDER,
  V2_USER_PROFILE,
  RECOGNITION_FEEDS,
  NEW_FLOWS_BUILDER,
  V3_FLOWS_POST_PAGE,
  NOTEBOOK,
} from '../../../constants/routes';

import ProfileViewController from '../ProfileViewController';
import MainFeedDrawerController from '../MainFeedDrawerController';
import AssemblyMembersController from '../AssemblyMembersController';
import FlowFeedRightDrawerController from '../../rightDrawer/FlowFeedRightDrawerController';
import FlowBuilderRightDrawerController from '../../rightDrawer/FlowBuilderRightDrawerController';
import ProfileFeedRightDrawerController from '../../rightDrawer/ProfileFeedRightDrawerController';
import FlowsInsightsRightDrawerController from '../../rightDrawer/FlowsInsightsRightDrawerController';
import NotebookRightDrawerController from '../../notebook/NotebookRightDrawerController';
import { LayoutStoreState } from '../../../interfaces/Layout';

const layoutSelector = (state: LayoutStoreState) => state.selectedMember;

const SecondaryDrawerController = () => {
  const selectedMember = useLayoutStore(layoutSelector);

  if (selectedMember) {
    return (
      <Switch>
        <Route
          path={V2_USER_PROFILE}
          component={ProfileFeedRightDrawerController}
        />
        <Route>
          <ProfileViewController
            firstName={selectedMember.firstName}
            memberId={selectedMember.memberId}
          />
        </Route>
      </Switch>
    );
  }

  return (
    <Switch>
      <Route path={RECOGNITION_FEEDS} component={MainFeedDrawerController} />
      <Route path={V2_HOME} component={MainFeedDrawerController} />
      <Route
        exact
        path={FLOW_FEED}
        component={FlowBuilderRightDrawerController}
      />
      <Route
        exact
        path={FLOW_INSIGHTS}
        component={FlowsInsightsRightDrawerController}
      />
      <Route
        exact
        path={NEW_FLOWS_BUILDER}
        component={FlowBuilderRightDrawerController}
      />
      <Route
        exact
        path={FLOWS_BUILDER}
        component={FlowBuilderRightDrawerController}
      />
      <Route path={FLOWS_FEEDS} component={FlowFeedRightDrawerController} />
      {/* Below route is needed - when we navigate through left nav */}
      <Route
        path={V2_USER_PROFILE}
        component={ProfileFeedRightDrawerController}
      />
      <Route path={V3_FLOWS_POST_PAGE} component={AssemblyMembersController} />
      <Route path={NOTEBOOK} component={NotebookRightDrawerController} />
    </Switch>
  );
};

export default SecondaryDrawerController;
