import React, { useCallback } from 'react';

import { CurrentUser, Member } from '../../../interfaces/Leaderboard';
import Divider from '../../atoms/Divider';
import { MyRankingEntryWrapper, StyledList } from './styles';
import MyRankingEntry from '../MyRankingEntry';
import RankingEntry from '../RankingEntry';
import ThemeV2 from '../../../componentsV2/theme';
import { SelectedMember } from '../../../interfaces/Layout';
import { getProfileFullName } from '../../../Utils/user';

export interface LeaderboardListProps {
  currentMember: CurrentUser;
  members: Member[];
  setSelectedMemberProfile: (selectedMember: SelectedMember) => void;
}

const LeaderboardList = ({
  currentMember,
  members,
  setSelectedMemberProfile,
}: LeaderboardListProps) => {
  const handleMyRankingEntryClick = useCallback(() => {
    setSelectedMemberProfile({
      firstName: 'Me',
      memberId: '',
    });
  }, [setSelectedMemberProfile]);

  const handleRankingEntryClick = useCallback(
    (firstName: string, memberId: string) => {
      setSelectedMemberProfile({
        firstName,
        memberId,
      });
    },
    [setSelectedMemberProfile],
  );

  return (
    <div>
      <MyRankingEntryWrapper hasBoxShadow={members.length > 10}>
        <MyRankingEntry
          count={currentMember.count}
          fullName={getProfileFullName(currentMember.profile)}
          isLoading={false}
          onClick={handleMyRankingEntryClick}
          profilePicture={currentMember.profile.image}
          userId={currentMember.id}
        />
      </MyRankingEntryWrapper>

      <StyledList>
        {members.map((member, index) => {
          const isLastEntry = index === members.length - 1;

          return (
            <div key={member.id}>
              <RankingEntry
                count={member.count}
                fullName={getProfileFullName(member.profile)}
                isCurrentUser={currentMember.id === member.id}
                isLoading={false}
                key={member.id}
                onClick={() =>
                  handleRankingEntryClick(member.profile.firstName, member.id)
                }
                profilePicture={member.profile.image}
                userId={member.id}
              />
              {!isLastEntry && (
                <Divider color={ThemeV2.palette.gray4} isFullWidth />
              )}
            </div>
          );
        })}
      </StyledList>
    </div>
  );
};

const MemoizedLeaderboardList = React.memo(LeaderboardList);
MemoizedLeaderboardList.displayName = 'LeaderboardList';
export default MemoizedLeaderboardList;
