import styled from 'styled-components';
import DropdownListItem from '../DropdownListItem_V2';
import Body from '../../atoms/Body';
import ThemeV2 from '../../../componentsV2/theme';
import ExpandableDropdownListItem from '../ExpandableDropdownListItem';

export const StyledDropdownListItem = styled(DropdownListItem)`
  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

StyledDropdownListItem.displayName = 'StyledDropdownListItem';

export const StyledExpandableDropdownListItem = styled(
  ExpandableDropdownListItem,
)`
  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;
StyledDropdownListItem.displayName = 'StyledExpandableDropdownListItem';

export const StyledDropDownItemHelperText = styled(Body)`
  margin-left: 8px;
  margin-top: -16px;
`;

export const DropdownCategoryLabel = styled(Body)`
  margin-bottom: 8px;
  padding: 0px 12px;
  color: ${ThemeV2.palette.gray7};
  &:not(::first-child) {
    margin-top: 8px;
  }
`;
DropdownCategoryLabel.displayName = 'DropdownCategoryLabel';
