import { useMutation, useQueryClient } from 'react-query';

import { makeAPICall } from '../../utils';
import {
  SaveFlowPayload,
  UpdateFlowAccessPolicyPayload,
} from '../Feed/interfaces';

import {
  EDIT_FLOW,
  CREATE_FLOW,
  GET_ACTIVE_FLOWS,
  GET_FLOW_DETAILS,
  GET_FLOW_PARTICIPANTS,
  GET_FLOW_VIEWERS,
  UPDATE_FLOWS_ACCESS_POLICY,
  GET_MEMBERS_FROM_CRITERIA,
} from '../../../constants/endpoints';

export const useCreateFlowMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (payload: SaveFlowPayload) => makeAPICall(CREATE_FLOW, payload),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(GET_ACTIVE_FLOWS);
      },
    },
  );
};

export const useEditFlowMutation = (flowId: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    (payload: SaveFlowPayload) =>
      makeAPICall(EDIT_FLOW, payload, undefined, { flowId }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(GET_ACTIVE_FLOWS);
        await queryClient.invalidateQueries([
          GET_FLOW_DETAILS,
          flowId,
          'builder',
        ]);
        await queryClient.invalidateQueries([GET_FLOW_DETAILS, flowId, '']);
        await queryClient.invalidateQueries([GET_FLOW_PARTICIPANTS]);
        await queryClient.invalidateQueries([GET_FLOW_VIEWERS]);
      },
    },
  );
};

export const useUpdateFlowAccessPolicy = (flowId: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    (payload: UpdateFlowAccessPolicyPayload) =>
      makeAPICall(UPDATE_FLOWS_ACCESS_POLICY, payload, undefined, { flowId }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(GET_ACTIVE_FLOWS);
        await queryClient.invalidateQueries(GET_MEMBERS_FROM_CRITERIA);
        await queryClient.invalidateQueries([GET_FLOW_DETAILS]);
        await queryClient.invalidateQueries([GET_FLOW_VIEWERS]);
        await queryClient.invalidateQueries([GET_FLOW_PARTICIPANTS]);
      },
    },
  );
};
