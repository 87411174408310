import styled from 'styled-components';
import Body from '../../../atoms/Body';
import Heading from '../../../atoms/Heading';
import Divider from '../../../atoms/Divider';
import ThemeV2 from '../../../../componentsV2/theme';

export const WidgetContainer = styled.section`
  padding: 24px;
  margin-top: 24px;
  border-radius: 4px;
  border: 1px solid ${ThemeV2.palette.gray5};
`;

export const ContentWrapper = styled.section<{ isLoading: boolean }>`
  max-height: ${({ isLoading }) => (isLoading ? 0 : 'initial')};
  visibility: ${({ isLoading }) => (isLoading ? 'hidden' : 'initial')};
`;

export const GeneralSummaryStats = styled.section`
  display: grid;
  grid-template-areas:
    'starts total_responses completion_rate'
    'participation_stats participation_stats participation_stats';
  overflow: auto;
`;

export const Starts = styled.div`
  grid-area: starts;
`;

export const TotalResponses = styled.div`
  grid-area: total_responses;
`;

export const CompletionRate = styled.div`
  grid-area: completion_rate;
`;

export const ParticipationStats = styled.div`
  grid-area: participation_stats;
  min-height: 220px;

  .dataTables_scrollBody {
    min-height: 220px;
  }
`;

export const UniqueFlowParticipants = styled.div`
  grid-area: unique_flow_participants;
`;

export const CurrentParticipants = styled.div`
  grid-area: current_participants;
`;

export const OccurrenceParticipationRate = styled.div`
  grid-area: occurance_participantion_rate;
`;

export const NumberOfOccurrences = styled.div`
  grid-area: number_of_occurances;
`;

export const AnswerAnyTimeParticipationRate = styled.div`
  grid-area: answer_any_time_participation_rate;
`;

export const AnswerAnyTimeResponsePerPerson = styled.div`
  grid-area: answer_any_time_response_per_person;
`;

export const BlocksCompleted = styled.div`
  grid-area: blocks_completed;
`;

export const TotalParticipation = styled.div`
  grid-area: total_participation;
`;

export const StyledDivider = styled(Divider)`
  grid-area: divider;
`;

export const StyledHeading = styled(Heading)`
  margin-left: 8px;
`;

export const StyledBody = styled(Body)`
  margin-left: 8px;
`;

export const LoaderWrapper = styled.section`
  padding-top: 12px;
  padding-bottom: 12px;
`;

export const TabChildren = styled.section`
  padding: 24px;
`;
