import styled from 'styled-components';

import MyRankingEntry from '../MyRankingEntry';

export const StyledList = styled.ul`
  list-style-type: none;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
`;

export const StyledMyRankingEntry = styled(MyRankingEntry)`
  margin-left: 16px;
  margin-right: 16px;
`;

export const MyRankingEntryWrapper = styled.div<{ hasBoxShadow: boolean }>`
  ${({ hasBoxShadow }) =>
    hasBoxShadow ? 'box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15)' : null}
`;
