import React, { useCallback, useState } from 'react';
import Fuse from 'fuse.js';

type UseStringSearchParams<T> = {
  data: T[];
  keys?: string[];
  threshold?: number;
  initialPattern?: string;
};

const useStringSearch = <T>({
  data,
  keys,
  threshold = 0.5,
  initialPattern = '',
}: UseStringSearchParams<T>) => {
  const [pattern, setPattern] = useState(initialPattern);

  const fuse = React.useMemo(() => {
    return new Fuse(data, { keys, threshold });
  }, [data, keys, threshold]);

  const results = React.useMemo(() => {
    if (pattern && pattern.length > 0) {
      return fuse.search(pattern).map((res) => res.item);
    }
    return data
      .map((doc, idx) => ({
        item: doc,
        score: 1,
        refIndex: idx,
      }))
      .map((res) => res.item);
  }, [fuse, pattern, data]);

  const onPatternChange = useCallback(
    (newPattern) => setPattern(newPattern),
    [],
  );
  const models = React.useMemo(() => {
    return {
      searchResult: results,
      pattern,
    };
  }, [results, pattern]);
  const operations = React.useMemo(() => {
    return {
      search: onPatternChange,
    };
  }, [onPatternChange]);
  return {
    models,
    operations,
  };
};

export default useStringSearch;
