import AwsS3 from '@uppy/aws-s3';
import { useEffect, useState } from 'react';
import Uppy, { UppyFile } from '@uppy/core';

import { customUppyStore } from '../../../stores/uppyStore';
import { defaultAllowedFileTypes } from './constants';
import {
  generateAllowedFileTypes,
  megabytes,
} from '../FlowsFileUploadInputBlockController/utils';
import { makeAPICallWithDataReturn } from '../../../queries/utils';
import {
  FileUploadFlowBlock,
  FlowBlockFromAPI,
} from '../../../interfaces/Flow/index';
import { sanitizeFileName } from '../../../atomic/molecules/FlowInputBlocks/FlowsFileUploadInputBlock/utils';

type IDs = {
  flowId: string;
  instanceId: string;
  identifier?: string;
};

const useUppyInstances = (
  blocks: FlowBlockFromAPI[],
  { flowId, instanceId: flowInstanceId, identifier }: IDs,
  signedUrlName: string,
) => {
  const [uppyInstances, setUppyInstances] = useState<{
    [id: string]: Uppy.Uppy;
  }>({});

  const createUppyInstance = (block: FlowBlockFromAPI) => {
    const blockContent = block.content as FileUploadFlowBlock;
    const allowedFileTypes = blockContent.rules?.type
      ? generateAllowedFileTypes(blockContent.rules.type)
      : defaultAllowedFileTypes;

    return Uppy({
      autoProceed: true,
      id: block.blockId,
      restrictions: {
        allowedFileTypes,
        maxFileSize: megabytes(25),
        maxTotalFileSize: megabytes(25),
        maxNumberOfFiles: 15,
      },
      store: customUppyStore(block.blockId),
    }).use(AwsS3, {
      getUploadParameters: async (file: UppyFile) => {
        const payload = undefined;
        const params = { identifier: identifier || '' };
        const urlSubstitutions = {
          flowId,
          instanceId: flowInstanceId,
          blockId: block.blockId,
          fileId: sanitizeFileName(file.name),
        };
        const { url: signedUrl } = await makeAPICallWithDataReturn(
          signedUrlName,
          payload,
          params,
          urlSubstitutions,
        );

        return {
          method: 'PUT',
          url: signedUrl,
          headers: {
            'Content-Type': file.type,
          },
        };
      },
    });
  };

  useEffect(() => {
    blocks.forEach((block: FlowBlockFromAPI) => {
      if (
        block.blockType === 'OPEN_ENDED' ||
        block.blockType === 'FILE_UPLOAD'
      ) {
        setUppyInstances((_instances) => {
          const updatedInstances = { ..._instances };
          updatedInstances[block.blockId] = createUppyInstance(block);
          return updatedInstances;
        });
      }
    });

    return () => {
      Object.keys(uppyInstances).forEach((instanceId) => {
        uppyInstances[instanceId].close();
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return uppyInstances;
};

export default useUppyInstances;
