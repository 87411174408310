import { useState, useMemo, useCallback } from 'react';

import { useParams } from 'react-router-dom';
import shallow from 'zustand/shallow';

import { FlowDetailsLoadedProps } from '../../../../atomic/organism/RightDrawerAbout/FlowDetails/types';
import { ComponentStatus } from '../../../../interfaces/component';

import { UserListModalProps } from '../../../../atomic/molecules/UserListModal/interfaces';
import {
  FeedPostUserProps,
  PostSortOptions,
} from '../../../../interfaces/Feed';

import { users } from '../../../../atomic/molecules/UserListDropdown/data';
import {
  useFetchFlowDetailsQuery,
  useGetFlowFeedsQuery,
} from '../../../../queries/Flows/Feed';
import { AvatarProps } from '../../../../atomic/atoms/Avatar/interfaces';
import { GENERATE_FLOW_DATA } from '../../../../constants/endpoints';
import {
  showErrorMessage,
  showInfoMessage,
  showSuccessMessage,
  dismissAllToasts,
} from '../../../../Utils/toast';
import { FlowFeedResponse } from '../../../../queries/Flows/Feed/interfaces';
import useJobPollingForFlowDownload, {
  DownloadDataJobProps,
} from '../../../../hooks/useJobPollingForDownload';
import {
  DATA_DOWNLOADED_SUCCESSFULLY,
  DATA_DOWNLOAD_ERROR,
  PREPARING_DATA_FOR_DOWNLOAD,
} from '../../../../languages/en/flows';
import { useFlowViewersSearch } from '../../../../hooks/useMembersSearch';
import useLayoutStore from '../../../../stores/layoutStore';
import { getFlowFrequencyText } from '../../../../Utils/flows/builder/utils';
import useModalsStore from '../../../../stores/modalsStore';
import { setParticipationFlowSelector } from '../../../../stores/modalsStore/selectors';

const useFlowDetailsControllerLogic = () => {
  const { flowId } = useParams<{ flowId: string }>();

  const { data, isLoading, isError } = useFetchFlowDetailsQuery(flowId);
  const { data: flowDetails } = useFetchFlowDetailsQuery(flowId, 'builder');
  const { data: flowFeedsData } = useGetFlowFeedsQuery({
    flowId,
    flowFeedsSort: PostSortOptions.DESC,
  });
  const setParticipationFlow = useModalsStore(setParticipationFlowSelector);

  const {
    models: {
      searchedMembers,
      isError: isUsersError,
      value: memberSearchValue,
      isLoading: isUsersLoading,
      hasMoreMembers: hasNextPage,
    },
    operations: {
      fetchMoreMembers: fetchMoreUsers,
      onChange: onMemberSearchChange,
    },
  } = useFlowViewersSearch(flowId);

  const { downloadFlowData } = useJobPollingForFlowDownload({
    preJobAPI: {
      apiName: GENERATE_FLOW_DATA,
      substitutions: {
        flowId,
      },
    },
    postJobAPI: {
      apiName: GENERATE_FLOW_DATA,
      substitutions: {
        flowId,
      },
    },
  });

  const flowFeeds: FlowFeedResponse[] = useMemo(() => {
    if (!flowFeedsData) {
      return [];
    }

    return flowFeedsData.pages.reduce<FlowFeedResponse[]>(
      (acc, page) => [...acc, ...page.data],
      [],
    );
  }, [flowFeedsData]);

  const flowDetailsData = data?.data;

  const [isOpen, setIsOpen] = useState(false);
  // const viewersModalProps: UserListModalProps = {
  //   isOpen,
  //   handleClose: () => {
  //     setIsOpen(false);
  //   },
  //   onShowMoreUserClick: (member: FeedPostUserProps) => {
  //     console.log('member: ', member);
  //   },
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  /* @ts-ignore */
  //   users: searchedMembers || [],
  //   heading: 'Viewers',
  //   placeholder: 'Search for members with visibility',
  // };
  const viewersModalProps: UserListModalProps = useMemo(() => {
    return {
      isOpen,
      handleClose: () => {
        setIsOpen(false);
      },
      onShowMoreUserClick: (member: FeedPostUserProps) => {
        console.log('member: ', member);
      },
      users,
      heading: 'Viewers',
      placeholder: 'Search for members with visibility',
    };
  }, [isOpen]);

  const flowOwnerProps: AvatarProps = useMemo(() => {
    return {
      img: flowDetailsData?.owner.image || '',
      name: flowDetailsData?.owner.name,
      userId: flowDetailsData?.owner.memberID,
    };
  }, [flowDetailsData]);

  const WhosIncludedClickHandler = () => {
    setIsOpen(true);
  };

  const downloadClickHandler = useCallback(async () => {
    showInfoMessage(PREPARING_DATA_FOR_DOWNLOAD);
    const getDownloadFlowDataURL: DownloadDataJobProps =
      await downloadFlowData();
    if (
      getDownloadFlowDataURL.data.url !== undefined &&
      getDownloadFlowDataURL.status === 'SUCCESS'
    ) {
      window.open(getDownloadFlowDataURL.data.url, '_self');
      dismissAllToasts();
      setTimeout(() => showSuccessMessage(DATA_DOWNLOADED_SUCCESSFULLY), 500);
    } else {
      showErrorMessage(DATA_DOWNLOAD_ERROR);
    }
  }, [downloadFlowData]);

  const visibleUsers = searchedMembers?.map((member) => {
    return {
      ...member,
      image: member.image,
    } as FeedPostUserProps;
  });

  const userDetails = useMemo(() => {
    return {
      users: visibleUsers,
      hasMoreUsersToFetch: !!hasNextPage,
      fetchMoreMembers: fetchMoreUsers,
      isUsersError,
      isUsersLoading,
      onMemberSearchValueChange: onMemberSearchChange,
      memberSearchValue,
    };
  }, [
    fetchMoreUsers,
    hasNextPage,
    isUsersError,
    isUsersLoading,
    memberSearchValue,
    onMemberSearchChange,
    visibleUsers,
  ]);

  const { toggleRightAsideOpen, setMemberAndToggleRightAsideOpen } =
    useLayoutStore(
      (state) => ({
        toggleRightAsideOpen: state.toggleRightAsideOpen,
        setMemberAndToggleRightAsideOpen:
          state.setMemberAndToggleRightAsideOpen,
      }),
      shallow,
    );

  const onMemberClick = useCallback(
    ({ memberID, name }) => {
      if (memberID && name) {
        setMemberAndToggleRightAsideOpen({
          memberId: memberID,
          firstName: name,
        });
      }
    },
    [setMemberAndToggleRightAsideOpen],
  );

  const handleAnswerAnytimeClick = useCallback(() => {
    setParticipationFlow({
      participationFlowId: flowId,
      occurrenceId: data?.data.occurrence.activeOccurrence?.occurrenceId,
    });
  }, [setParticipationFlow, flowId, data]);

  const scheduleRule = flowDetailsData?.schedule?.rule;

  const flowDetailsProps: FlowDetailsLoadedProps = useMemo(() => {
    return {
      status: ComponentStatus.LOADED,
      toggleRightAsideOpen,
      flowOwnerProps,
      flowDescription: flowDetailsData?.description || '',
      flowId: flowDetailsData?.flowId || '',
      visibility: flowDetailsData?.visibility.criteria || 'everyone',
      visibilityCount: flowDetailsData?.visibility.viewersCount,
      onWhosIncludedClick: WhosIncludedClickHandler,
      viewersModalProps,
      onDownloadClick: downloadClickHandler,
      showDownloadOption: flowDetailsData?.isFlowOwner,
      disableDownloadOption: flowFeeds.length === 0,
      userDetails,
      onMemberClick,
      kind: flowDetailsData?.kind,
      isShortcut: flowDetailsData?.isShortcut,
      flowFrequencyText: getFlowFrequencyText(scheduleRule),
      onAnswerAnytimeClick: handleAnswerAnytimeClick,
      linkAccess: flowDetails?.data.accessPolicy?.linkAccess,
    };
  }, [
    flowDetails,
    downloadClickHandler,
    flowDetailsData,
    flowOwnerProps,
    viewersModalProps,
    flowFeeds,
    userDetails,
    onMemberClick,
    scheduleRule,
    handleAnswerAnytimeClick,
  ]);

  return {
    models: { flowDetailsProps, isLoading, isError },
    operations: { handleAnswerAnytimeClick },
  };
};

export default useFlowDetailsControllerLogic;
