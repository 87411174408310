export const VALIDATION_ERRORS = {
  EXCESSIVE_TROPHIES:
    "Whoops! Looks like you're giving more trophies than you have.",
  INVITE_AT_LEAST_ONE_MEMBER:
    'Please invite at least one member to celebrate a teammate.',
  REQUIRED: 'This is required. Please answer to post.',
  UNDER_CHARACTER_LIMIT: 'Your text is under the character limit.',
  OVER_CHARACTER_LIMIT: 'Your text is over the character limit.',
  PROFANE_ERROR_MESSAGE: 'Please remove profanity',
  generateMinimumCharacterLimitError: (amountUnderCharacterLimit: number) =>
    `Your text is under the character limit. Please add at least ${amountUnderCharacterLimit} ${
      amountUnderCharacterLimit === 1 ? 'character' : 'characters'
    } before you submit.`,
  generateMaximumCharacterLimitError: (amountOverCharacterLimit: number) =>
    `Your text is over the character limit. Please remove at least ${amountOverCharacterLimit} ${
      amountOverCharacterLimit === 1 ? 'character' : 'characters'
    } before you submit.`,
};

export const GENERAL_BLOCK_ERROR =
  'This block has an error, please fix this to post.';

export const PARTICIPATION_SECTION_HEADING = 'Participating and Viewing';
export const RECOGNITION_API_GENERIC_ERROR =
  'Oops! Post unsuccessful. Check your connection and try again';

export const MULTIPLE_CHOICE_MULTI_SELECT = {
  CHOOSE_AS_MANY_OPTIONS_AS_YOUD_LIKE: 'Choose as many options as you’d like',
  chooseAtLeastMinOptions: (min: number) => `Choose at least ${min} options`,
  chooseBetweenMinAndMaxOptions: (min: number, max: number) =>
    `Choose between ${min} and ${max} options`,
  chooseExactlyNOptions: (number: number) => `Choose exactly ${number} options`,
  chooseUpToMaxOptions: (max: number) => `Choose up to ${max} options`,
};

export const ARE_GIVING_RECOGNITION = 'are giving recognition';
export const ARE_ANSWERING = 'are answering';
export const ARE_PREVIEWING = 'are previewing';

export const RECOGNITION_FLOW_HEADER_DESCRIPTION = `Celebrating your teammates is a great way to boost morale and make
them feel appreciated for their hard work!`;

export const ANSWER_POSTED_SUCCESSFULLY = 'Answer posted successfully!';

export const OCCURRENCE_HAS_ENDED = 'This occurrence has ended';
export const NEW_OCCURRENCE_AVAILABLE_DESCRIPTION =
  'Don’t worry! There is a new occurrence available for you to answer now.';
export const ANSWER_NEW_OCCURRENCE = 'Answer new occurrence';
export const ANSWER_WITH_SHORTCUT = 'Answer with shortcut';
export const WAIT_TILL_NEXT_OCCURRENCE =
  'You’ll have to wait until the next occurrence is triggered to answer.';
export const ANSWER_WITH_SHORTCUT_TEXT =
  'You can still answer this flow with a shortcut at any time.';
export const ANSWER_ANYTIME_TEXT = 'You can still answer this flow any time.';
export const CANT_FIND_FLOW = 'We couldn’t find this flow';
export const HEAD_TO_MAIN_FEED = 'Head back to the main feed to see more posts';
export const LETS_GET_STARTED = 'Lets get started';
export const GO_BACK_TO_MAIN_FEED = 'Go back to the main feed';
export const ALREADY_ANSWERED_OCCURRENCE =
  'You already answered this occurrence';
export const NO_CURRENT_OCCURRENCE =
  'You’ll be able to answer again once the owner runs this flow. Until then, take a breather!';
export const STILL_ANSWER_WITH_SHORTCUT =
  'You can still answer this flow with a shortcut!';

export const NEW_VERSION_AVAILABLE = 'There’s a new version of this flow!';
export const NEW_VERSION_AVAILABLE_DESCRIPTION =
  'Reload to answer the updated version. Don’t worry, we’ll copy over your answers for blocks that didn’t change.';
export const RELOAD_FLOW = 'Reload flow';
export const NEED_HELP = 'Need help?';
export const USE_THE = 'Use the';
export const KEYS_NAVIGATE = 'keys to navigate';
export const CLOSE_PREVIEW = 'Close preview';
export const NAME_YOUR_FLOW = 'Name your flow...';
export const YOU_ARE_PREVIEWING = 'You are previewing';
export const ANSWER_NOW = 'Answer now';
export const POST_PRIVATELY = 'Post privately';
export const POST_ANONYMOUSLY = 'Post anonymously';
export const POST_ANONYMOUSLY_AND_PRIVATELY = 'Post anonymously and privately';
export const ANONYMOUS_BANNER_TEXT = 'Your answers are anonymous';
export const ACCEPTING_RESPONSES_UNTIL = 'Accepting responses until';
export const EXTERNAL_PARTICIPATION_ERROR_SCREENS_TEXTS = {
  FLOW_NOT_FOUND_HEADING: "We couldn't find this flow",
  FLOW_NOT_FOUND_SUB_HEADING:
    'You may not have access to this flow, or it may have been deleted.',
  PLACEHOLDER_TEXT: 'Enter your email',
  ERROR_TEXT: 'Please enter a valid email to continue',
  EXPLORE_FOR_FREE: 'Explore for free',
  NEW_VERSION_AVAILABLE_HEADING: 'There’s a new version of this flow!',
  NEW_VERSION_AVAILABLE_SUB_HEADING: 'Reload to answer the updated version.',
  ACTIVE_OCCURRENCE_ENDED_HEADING: 'This flow is no longer accepting responses',
  ACTIVE_OCCURRENCE_ENDED_SUB_HEADING:
    'You’ll have to wait until the owner re-opens it to respond. Want to build your own form or survey?',
};
export const ASSEMBLY_HOMEPAGE = 'Assembly Homepage';
export const EXTERNAL_PARTICIPATION_ERROR_DESCRIPTION =
  'Go to the Assembly homepage to get started.';
