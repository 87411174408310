import { useCallback, useReducer } from 'react';

interface AddNotificationArgs {
  message: React.ReactNode;
  type?: string;
  autoClose?: boolean;
  timeout?: number;
}

export interface Notification extends AddNotificationArgs {
  id: number;
  removeNotification(id: number): void;
}

enum NotificationAction {
  ADD_NOTIFICATION = 'ADD_NOTIFICATION',
  REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION',
}

export enum NotificationType {
  INFO = 'info',
  ERROR = 'error',
}

const initialState = {
  notifications: [],
};

type State = {
  notifications: Notification[];
};

interface AddNotificationAction {
  type: typeof NotificationAction.ADD_NOTIFICATION;
  payload: Notification;
}

interface RemoveNotificationAction {
  type: typeof NotificationAction.REMOVE_NOTIFICATION;
  payload: number;
}

type NotificationActionTypes = AddNotificationAction | RemoveNotificationAction;

function reducer(state: State, action: NotificationActionTypes) {
  switch (action.type) {
    case NotificationAction.ADD_NOTIFICATION: {
      const notifications = state.notifications.concat([action.payload]);
      return { ...state, notifications };
    }
    case NotificationAction.REMOVE_NOTIFICATION: {
      const notifications = state.notifications.filter(
        (notification) => notification.id !== action.payload,
      );
      return { ...state, notifications };
    }
    default:
      throw new Error();
  }
}

const useNotification = ({ maxNotifications = 1 }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { notifications } = state;

  const removeNotification = useCallback((id) => {
    dispatch({
      type: NotificationAction.REMOVE_NOTIFICATION,
      payload: id,
    });
  }, []);

  const addToRemove = useCallback(
    (notification: Notification, timeout: number) => {
      setTimeout(() => {
        removeNotification(notification.id);
      }, timeout);
    },
    [removeNotification],
  );

  const addNotification = useCallback(
    ({
      message,
      type = NotificationType.INFO,
      autoClose = false,
      timeout = 4000,
    }: AddNotificationArgs) => {
      const id = +new Date();
      const notification = {
        id,
        message,
        type,
        autoClose,
        timeout,
        removeNotification,
      };

      dispatch({
        type: NotificationAction.ADD_NOTIFICATION,
        payload: notification,
      });
      if (autoClose) {
        addToRemove(notification, timeout);
      }
      return id;
    },
    [addToRemove, removeNotification],
  );

  return {
    notifications: notifications.slice(
      notifications.length - maxNotifications,
      notifications.length,
    ),
    addNotification,
    removeNotification,
  };
};

export default useNotification;
