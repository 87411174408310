import React, { useEffect, useCallback, useMemo } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import shallow from 'zustand/shallow';
import {
  V2_GIFTCARD_REWARDS,
  V2_CULTURE_REWARDS,
  V2_CHARITY_REWARDS,
  V2_MY_REWARDS,
  V2_HOME,
  V2_REWARDS,
  V2_AXOMO_SWAGS,
} from '../../../constants/routes';

import CultureController from '../../../controllers/rewards/CultureController';
import GiftCardsController from '../../../controllers/rewards/GiftCardsController';
import CharityController from '../../../controllers/rewards/CharityController';
import MyRewardsController from '../../../controllers/rewards/MyRewardsController';
import SwagsController from '../../../controllers/rewards/SwagsController';

import useLayoutStore from '../../../stores/layoutStore';
import useRewardsRoutesControllerModel from './useRewardsRoutesControllerModel';
import {
  useDonationFetchQuery,
  useGiftCardFetchQuery,
  useMyRewardsFetchQuery,
  useRewardsFetchQuery,
} from '../../../queries/Rewards';
import { DEFAULT_REWARDS_QUERY_PARAMS } from '../../../Utils/data/rewards';
import GifLoader from '../../../atomic/molecules/GifLoader';
import rewardsLoaderImage from '../../../img/rewards-loader.gif';
import {
  WE_ARE_LOOKING_FOR_THE_BEST_REWARDS,
  HOLD_TIGHT,
} from '../../../languages/en/rewards';
import { isMobile } from '../../../Utils/window';

const RewardsController = () => {
  const {
    isAdmin,
    isCreditStateDeniedByAdmin,
    isCultureRewardsEnabled,
    isGiftCardsEnabled,
    isCharitiesEnabled,
    isChargeable,
    isAxomoSwagsEnabled,
  } = useRewardsRoutesControllerModel();

  const { isRightAsideOpen, toggleRightAsideOpen } = useLayoutStore(
    (state) => ({
      isRightAsideOpen: state.isRightAsideOpen,
      toggleRightAsideOpen: state.toggleRightAsideOpen,
    }),
    shallow,
  );

  const history = useHistory();
  const location = useLocation();

  if (isCreditStateDeniedByAdmin) {
    history.push(V2_HOME);
  }

  const { isLoading: isCultureRewardsLoading, data: cultureRewardsData } =
    useRewardsFetchQuery(DEFAULT_REWARDS_QUERY_PARAMS.cultureRewards);

  const { isLoading: isGiftCardsLoading, data: giftCardsData } =
    useGiftCardFetchQuery(DEFAULT_REWARDS_QUERY_PARAMS.giftCards);
  const { isLoading: isCharityRewardsLoading, data: charityRewardsData } =
    useDonationFetchQuery(DEFAULT_REWARDS_QUERY_PARAMS.charitySortParams);

  const { isLoading: isMyRewardsLoading } = useMyRewardsFetchQuery();

  useEffect(() => {
    if (isRightAsideOpen) {
      toggleRightAsideOpen();
    }
    return () => {
      if (isRightAsideOpen === false && isMobile === false) {
        toggleRightAsideOpen();
      }
    };
  }, [isRightAsideOpen, toggleRightAsideOpen]);

  const Culture = useCallback(() => {
    return (
      <CultureController
        isAdmin={isAdmin}
        isCultureRewardsEnabled={isCultureRewardsEnabled}
      />
    );
  }, [isAdmin, isCultureRewardsEnabled]);

  const GiftCard = useCallback(() => {
    return (
      <GiftCardsController
        isAdmin={isAdmin}
        isGiftCardsEnabled={isGiftCardsEnabled}
      />
    );
  }, [isAdmin, isGiftCardsEnabled]);

  const Charities = useCallback(() => {
    return (
      <CharityController
        isAdmin={isAdmin}
        isCharitiesEnabled={isCharitiesEnabled}
      />
    );
  }, [isAdmin, isCharitiesEnabled]);

  const MyRewards = useCallback(() => {
    return <MyRewardsController />;
  }, []);

  const AxomoSwags = useCallback(() => {
    return <SwagsController isAxomoSwagsEnabled={isAxomoSwagsEnabled} />;
  }, [isAxomoSwagsEnabled]);

  const hasCultureRewardsSetup = useMemo(() => {
    if (cultureRewardsData && cultureRewardsData.rewards) {
      const activeRewards = cultureRewardsData.rewards.filter(
        (reward) => reward.isActive,
      );
      return activeRewards.length > 0;
    }

    return false;
  }, [cultureRewardsData]);

  const hasGiftCardsSetup = useMemo(() => {
    if (giftCardsData && giftCardsData.rewards) {
      return giftCardsData.rewards.length > 0;
    }

    return false;
  }, [giftCardsData]);

  const hasCharitiesSetup = useMemo(() => {
    if (charityRewardsData && charityRewardsData.rewards) {
      return charityRewardsData.rewards.length > 0;
    }

    return false;
  }, [charityRewardsData]);

  const hideCultureRewards = useMemo(() => {
    return !isAdmin && (!isCultureRewardsEnabled || !hasCultureRewardsSetup);
  }, [hasCultureRewardsSetup, isAdmin, isCultureRewardsEnabled]);

  const hideGiftCards = useMemo(() => {
    return (
      !isAdmin && (!isGiftCardsEnabled || !hasGiftCardsSetup || !isChargeable)
    );
  }, [hasGiftCardsSetup, isAdmin, isChargeable, isGiftCardsEnabled]);

  const hideCharities = useMemo(() => {
    return (
      !isAdmin && (!isCharitiesEnabled || !hasCharitiesSetup || !isChargeable)
    );
  }, [hasCharitiesSetup, isAdmin, isChargeable, isCharitiesEnabled]);

  if (
    isCultureRewardsLoading ||
    isGiftCardsLoading ||
    isCharityRewardsLoading ||
    isMyRewardsLoading
  ) {
    return (
      <GifLoader
        source={rewardsLoaderImage}
        primaryText={WE_ARE_LOOKING_FOR_THE_BEST_REWARDS}
        secondaryText={HOLD_TIGHT}
      />
    );
  }

  if (
    !hasCultureRewardsSetup &&
    !hasGiftCardsSetup &&
    !hasCharitiesSetup &&
    !isAdmin
  ) {
    return <Redirect to={V2_HOME} />;
  }

  // current routes equal to rewards then set respective rewards page
  if (location.pathname === V2_REWARDS) {
    if (isAdmin) {
      history.push(V2_GIFTCARD_REWARDS);
    } else {
      if (hasGiftCardsSetup) {
        history.push(V2_GIFTCARD_REWARDS);
      }

      if (!hasGiftCardsSetup && hasCharitiesSetup) {
        history.push(V2_CHARITY_REWARDS);
      }

      if (!hasGiftCardsSetup && !hasCharitiesSetup && hasCultureRewardsSetup) {
        history.push(V2_CULTURE_REWARDS);
      }
    }
  } else if (!isAdmin) {
    switch (location.pathname) {
      case V2_CULTURE_REWARDS:
        if (hideCultureRewards) {
          history.push(V2_REWARDS);
        }
        break;

      case V2_GIFTCARD_REWARDS:
        if (hideGiftCards) {
          history.push(V2_REWARDS);
        }
        break;

      case V2_CHARITY_REWARDS:
        if (hideCharities) {
          history.push(V2_REWARDS);
        }
        break;

      default:
        break;
    }
  }

  return (
    <Switch>
      <Route exact path={V2_GIFTCARD_REWARDS} component={GiftCard} />
      <Route exact path={V2_CHARITY_REWARDS} component={Charities} />
      <Route exact path={V2_CULTURE_REWARDS} component={Culture} />
      <Route exact path={V2_MY_REWARDS} component={MyRewards} />
      <Route exact path={V2_AXOMO_SWAGS} component={AxomoSwags} />
      <Redirect to={V2_REWARDS} />
    </Switch>
  );
};

export default RewardsController;
