import React from 'react';

import Body from '../Body';
import { TextWithIconProps } from './types';
import { StyledIconWrapper, StyledTextWithIcon } from './styles';
import SVGIcon from '../SVGIcon';

const TextWithIcon = (props: TextWithIconProps) => {
  const {
    icon,
    color = 'darkGray9',
    children,
    iconSize = '8px',
    iconWrapperSize = '20px',
    iconWrapperBackground,
    iconWrapperRightMargin,
    variant = 'body3',
    hasGlow = false,
    iconColor = '',
    className,
    onClick,
    roundedIcon = false,
  } = props;

  return (
    <StyledTextWithIcon className={className} onClick={onClick}>
      {icon && (
        <StyledIconWrapper
          roundedIcon={roundedIcon}
          size={iconWrapperSize}
          hasGlow={hasGlow}
          iconWrapperBackground={iconWrapperBackground}
          iconWrapperRightMargin={iconWrapperRightMargin}
        >
          <SVGIcon icon={icon} size={iconSize} color={iconColor} />
        </StyledIconWrapper>
      )}
      <Body variant={variant} color={color} inline>
        {children}
      </Body>
    </StyledTextWithIcon>
  );
};

export default TextWithIcon;
