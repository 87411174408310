import React from 'react';
import ContentLoader from 'react-content-loader';

import ThemeV2 from '../../../componentsV2/theme';
import { StyledFlexWrapper } from './styles';

const EventsLoader = () => (
  <StyledFlexWrapper padding="24px" justifyContent="space-between">
    <ContentLoader
      speed={2}
      width={300}
      height={60}
      viewBox="0 0 300 60"
      backgroundColor={ThemeV2.palette.gray4}
      foregroundColor={ThemeV2.palette.gray3}
    >
      <rect x="0" y="0" rx="4" ry="4" width="102" height="60" />
      <rect x="116" y="4" rx="4" ry="4" width="148" height="16" />
      <rect x="117" y="25" rx="4" ry="4" width="112" height="12" />
      <rect x="117" y="42" rx="4" ry="4" width="112" height="12" />
    </ContentLoader>
  </StyledFlexWrapper>
);

export default EventsLoader;
