import React from 'react';

import { MainFeedFilterProps } from './types';
import { ComponentStatus } from '../../../../interfaces/component';

import LoadedComponent from './LoadedComponent';
import LoadingComponent from '../LoadingComponent';
import EmptyComponent from '../EmptyComponent';
import ErrorComponent from '../ErrorComponent';

const MainFeedFilter = (props: MainFeedFilterProps) => {
  switch (props.status) {
    case ComponentStatus.LOADED:
      return <LoadedComponent {...props} />;
    case ComponentStatus.LOADING:
      return <LoadingComponent isMainFeedFilterLoading />;
    case ComponentStatus.ERROR:
      return <ErrorComponent />;
    default: {
      return <EmptyComponent isMainFeedFilterEmpty />;
    }
  }
};

export default MainFeedFilter;
