// @ts-nocheck TODO: type issues need to be fixed in this file
import axios from 'axios';
import _ from 'lodash';
import history from '../history';
import processErrorResponse, {
  ErrorMessages,
} from '../containers/helpers/ErrorHelper';
import Logger from '../Utils/Logger';
import { API_URL_WITH_V2 } from '../config';
import { AuthHeaders } from './shared/HeaderToken';
import { updateIntercom } from '../Utils/Intercom';
import {
  showSuccessMessage,
  showInfoMessage,
  showErrorMessage,
} from '../Utils/flashHandler';
import { hasRewardsPayments } from '../Utils/admin';

// ------------------------------------
// Helpers
// ------------------------------------
const apiEndpoints = {
  EMPLOTER_INFO_SETTINGS: `${API_URL_WITH_V2}/employer/employer_info/settings`,
};

export const ADMIN_REQUEST_STATUSES = {
  BLANK: 'blank',
  UPDATING: 'updating',
};

export const adminProcessError = (error = {}, dispatch, actions) => {
  Logger('processError');
  Logger(error.response);
  Logger(error);
  const statusCode = error.response ? error.response.status : 0;
  switch (statusCode) {
    case 400: // bad request
      dispatch(actions.requestFailed(ErrorMessages.serverError));
      break;
    case 401: // Not authorised
      history.push('/home');
      break;
    case 403: // Forbidden
      history.push('/404');
      break;
    case 404: // Page not found
      dispatch(actions.requestFailed(ErrorMessages.serverError));
      break;
    //  mostly my error
    case 422:
    case 500:
      if (error.response && error.response.data) {
        const responseData = error.response.data;
        const msg = processErrorResponse(responseData);
        if (responseData.code === 100) {
          const message = _.get(responseData, ['message', 'msg']);
          if (message) {
            dispatch(showErrorMessage(message));
          }
        } else {
          dispatch(actions.requestFailed(msg));
          return msg;
        }
      }
      break;
    default:
      dispatch(actions.requestFailed(ErrorMessages.networkError));
      break;
  }
};

// ------------------------------------
// Constants
// ------------------------------------
export const GOT_MY_EMPLOYER = 'got_my_employer';
export const GOT_COMPANY_DETAILS = 'GOT_COMPANY_DETAILS';
export const GOT_PAYMENT_DETAILS = 'got_payment_details';
export const REQUEST_FAILED = 'admin_request_failed';
export const ADMIN_REQUEST_STATUS = 'ADMIN_REQUEST_STATUS';
// ------------------------------------
// Actions
// ------------------------------------

const gotMyEmployer = (employer) => {
  updateIntercom({
    employerId: employer._id,
    employerName: employer.name,
    rewardsPayment: !!(employer && hasRewardsPayments(employer)), // ? true : false
  });
  return {
    type: GOT_MY_EMPLOYER,
    employer,
  };
};

const gotCompanyDetails = (companyDetails) => ({
  type: GOT_COMPANY_DETAILS,
  companyDetails,
});

const updateRequestStatus = (status) => ({
  type: ADMIN_REQUEST_STATUS,
  status,
});

const gotPaymentDetails = (paymentDetails) => ({
  type: GOT_PAYMENT_DETAILS,
  paymentDetails,
});

const requestFailed = (errorMessage) => ({
  type: REQUEST_FAILED,
  error: errorMessage,
});

export const adminActions = {
  gotMyEmployer,
  gotCompanyDetails,
  updateRequestStatus,
  gotPaymentDetails,
  requestFailed,
};

// ------------------------------------
// API Wrapper
// ------------------------------------

export const updateEmployerInfoAction = (props) => {
  Logger(props, true);
  return async (dispatch) => {
    try {
      dispatch(showInfoMessage('Saving company settings...'));
      dispatch(
        adminActions.updateRequestStatus(ADMIN_REQUEST_STATUSES.UPDATING),
      );
      const res = await axios.put(
        `${apiEndpoints.EMPLOTER_INFO_SETTINGS}`,
        props,
        AuthHeaders(),
      );
      const result = res.data;
      Logger(result, true);
      dispatch(adminActions.updateRequestStatus(ADMIN_REQUEST_STATUSES.BLANK));
      if (result.success) {
        const { employer } = result.data;
        dispatch(gotMyEmployer(employer));
        dispatch(showSuccessMessage('Company settings have been saved'));
      }
    } catch (error) {
      adminProcessError(error, dispatch);
    }
  };
};

// ------------------------------------
// Reducers
// ------------------------------------

export default (
  state = { requestStatus: ADMIN_REQUEST_STATUS.BLANK },
  action,
) => {
  switch (action.type) {
    case GOT_MY_EMPLOYER:
      return { ...state, employer: action.employer };
    case GOT_COMPANY_DETAILS:
      return { ...state, companyDetails: action.companyDetails };
    case ADMIN_REQUEST_STATUS:
      return { ...state, requestStatus: action.status };
    case GOT_PAYMENT_DETAILS:
      return { ...state, paymentDetails: action.paymentDetails };
    case REQUEST_FAILED:
      return { ...state, error: action.error };
    default:
      return state;
  }
};
