import isEmpty from 'lodash/isEmpty';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { InfiniteData, useQueryClient } from 'react-query';
import shallow from 'zustand/shallow';
import { GET_NOTEBOOK_SECTION } from '../../../../constants/endpoints';
import { NOTEBOOK_PAGINATION_LIMIT } from '../../../../constants/notebook';
import {
  GetNotebookSectionResponse,
  NotebookAnalyticsParameters,
  NotebookTask,
  NotebookTaskAnalyticsParameters,
  TaskCategories,
  TaskFromAPI,
} from '../../../../interfaces/notebook';
import {
  prefetchOtherNotebookTab,
  useGetNotebookSectionQuery,
} from '../../../../queries/Notebook';
import { useProfileInfoFetchQuery } from '../../../../queries/Profile';
import useNotebookStore from '../../../../stores/notebookStore';
import { NotebookStore } from '../../../../stores/notebookStore/types';
import {
  NOTEBOOK_EVENTS,
  NOTEBOOK_TASK_EVENTS,
} from '../../../../Utils/analytics/constants';
import {
  trackNotebookActionEvent,
  trackNotebookShowEvent,
  trackNotebookTaskActionEvent,
  trackNotebookTaskShowEvent,
} from '../../../../Utils/analytics/notebook';
import {
  convertAPITaskToTaskAndCategory,
  getNotebookAnalyticsProps,
  getNotebookTaskAnalyticsProps,
} from '../../../../Utils/notebook';
import { NotebookTabs } from '../../NotebookPageController/useNotebookPageController/types';
import { notebookSectionDetails } from './data';

const getTasksAndCountFromQueryData = (
  { pages }: InfiniteData<GetNotebookSectionResponse>,
  timezone: string,
): [NotebookTask[], number] => [
  pages
    .reduce<TaskFromAPI[]>((arr, page) => [...arr, ...page.data], [])
    .map(
      (taskFromAPI) =>
        convertAPITaskToTaskAndCategory(taskFromAPI, timezone)[0],
    ),
  pages[pages.length - 1].total,
];

const notebookGroupSelector = (state: NotebookStore) => ({
  uploadSectionData: state.uploadSectionData,
  setInitialRequestToFalse: state.setInitialRequestToFalse,
});

const HideCountTypes: TaskCategories[] = [
  TaskCategories.ARCHIVED,
  TaskCategories.COMPLETED,
];

const useNotebookGroupController = (
  type: TaskCategories,
  tabType: NotebookTabs,
) => {
  const queryClient = useQueryClient();
  const notes = useNotebookStore(
    useCallback((store) => store.notes[store.currentTab][type], [type]),
  );
  const [isCollapseOpen, setCollapseOpen] = useState(
    notebookSectionDetails[type].isSectionOpenOnLoad,
  );
  const { total, totalUnreadCount } = notes;

  const { uploadSectionData, setInitialRequestToFalse } = useNotebookStore(
    notebookGroupSelector,
    shallow,
  );

  const { data: profileInfo } = useProfileInfoFetchQuery();
  const currentUserTimezone = useMemo(
    () => profileInfo?.member.timeZone || '',
    [profileInfo?.member.timeZone],
  );

  const {
    data: sectionalData,
    isLoading,
    error,
    isFetchingNextPage: isFetchingMoreTasks,
    fetchNextPage: fetchMoreTasks,
    isRefetching,
    hasNextPage,
  } = useGetNotebookSectionQuery(
    type,
    tabType,
    NOTEBOOK_PAGINATION_LIMIT,
    isCollapseOpen,
  );

  useEffect(() => {
    const prefetchTab =
      tabType === NotebookTabs.myWork ? NotebookTabs.ato : NotebookTabs.myWork;
    const queryKey = [GET_NOTEBOOK_SECTION, type, prefetchTab];
    const data = queryClient.getQueryData(queryKey);
    if (isCollapseOpen && !data) {
      setTimeout(() => {
        prefetchOtherNotebookTab(queryClient, queryKey, type, prefetchTab).then(
          () => {
            const prefetchedData:
              | InfiniteData<GetNotebookSectionResponse>
              | undefined = queryClient.getQueryData(queryKey);
            if (prefetchedData) {
              const [tasksArr, totalTasksCount] = getTasksAndCountFromQueryData(
                prefetchedData,
                currentUserTimezone,
              );
              uploadSectionData(
                type,
                prefetchTab,
                tasksArr,
                totalTasksCount,
                NOTEBOOK_PAGINATION_LIMIT,
              );
              setInitialRequestToFalse(prefetchTab, type);
            }
          },
        );
      }, 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (sectionalData) {
      const [tasksArr, totalTasksCount] = getTasksAndCountFromQueryData(
        sectionalData,
        currentUserTimezone,
      );
      uploadSectionData(
        type,
        tabType,
        tasksArr,
        totalTasksCount,
        NOTEBOOK_PAGINATION_LIMIT,
      );
      setInitialRequestToFalse(tabType, type);
    }
  }, [
    sectionalData,
    tabType,
    type,
    currentUserTimezone,
    uploadSectionData,
    setInitialRequestToFalse,
  ]);

  useEffect(() => {
    if (sectionalData) {
      if (!isRefetching) {
        const { pages } = sectionalData;
        const taskIDs = pages
          .reduce<TaskFromAPI[]>((arr, page) => [...arr, ...page.data], [])
          .map((taskFromAPI) => taskFromAPI.transactionId);
        const notebookAnalyticsProps = getNotebookAnalyticsProps(
          type,
          undefined,
          taskIDs,
          tabType,
        );
        trackNotebookShowEvent({
          show: NOTEBOOK_EVENTS.NOTEBOOK_SHOW,
          ...notebookAnalyticsProps,
        });
      }
    }
  }, [sectionalData, type, error, isRefetching, tabType]);

  const onTaskDataChange = useCallback(
    (data: NotebookAnalyticsParameters | NotebookTaskAnalyticsParameters) => {
      switch (data.action) {
        case NOTEBOOK_EVENTS.NOTEBOOK_TASK_CREATION:
        case NOTEBOOK_EVENTS.NOTEBOOK_SECTION_EXPANDED:
        case NOTEBOOK_EVENTS.NOTEBOOK_SECTION_COLLAPSED:
          {
            const { action, section } = data as NotebookAnalyticsParameters;
            const notebookAnalyticsProps = getNotebookAnalyticsProps(section);
            trackNotebookActionEvent({
              action,
              ...notebookAnalyticsProps,
            });
          }
          break;

        case NOTEBOOK_TASK_EVENTS.NOTEBOOK_TASK_SHOW:
          {
            const { originalTask, originalCategory } =
              data as NotebookTaskAnalyticsParameters;
            const taskAnalyticsProps = getNotebookTaskAnalyticsProps(
              undefined,
              undefined,
              originalTask,
              originalCategory,
              tabType,
            );
            trackNotebookTaskShowEvent({
              ...taskAnalyticsProps,
              show: NOTEBOOK_TASK_EVENTS.NOTEBOOK_TASK_SHOW,
            });
          }
          break;

        case NOTEBOOK_TASK_EVENTS.NOTEBOOK_TASK_DUE_DATE_UPDATE:
          {
            const {
              action,
              originalTask,
              originalCategory,
              updatedTask,
              updatedCategory,
            } = data as NotebookTaskAnalyticsParameters;
            const taskAnalyticsProps = getNotebookTaskAnalyticsProps(
              originalTask,
              originalCategory,
              updatedTask,
              updatedCategory,
              tabType,
            );
            trackNotebookTaskActionEvent({
              action,
              ...taskAnalyticsProps,
            });
          }
          break;

        default:
          return;
      }
    },
    [tabType],
  );

  const displayTotal = useMemo(
    () => (HideCountTypes.includes(type) ? undefined : total),
    [total, type],
  );

  const onCollapsibleToggle = useCallback(
    (isOpen: boolean) => {
      setCollapseOpen(isOpen);
      onTaskDataChange({
        action: isOpen
          ? NOTEBOOK_EVENTS.NOTEBOOK_SECTION_EXPANDED
          : NOTEBOOK_EVENTS.NOTEBOOK_SECTION_COLLAPSED,
        section: type,
      });
    },
    [onTaskDataChange, type],
  );

  return {
    total,
    totalUnreadCount,
    displayTotal,
    isLoading,
    hasError: !isEmpty(error) && !isLoading,
    isFetchingMoreTasks,
    fetchMoreTasks,
    onTaskDataChange,
    onCollapsibleToggle,
    hasNextPage,
  };
};

export default useNotebookGroupController;
