import styled from 'styled-components';
import { motion } from 'framer-motion';

import ContentLoader from 'react-content-loader';
import React from 'react';
import ThemeV2 from '../../../componentsV2/theme';
import { device } from '../../../constants/layout';
import Image from '../../atoms/Image';
import Button from '../../atoms/Button';
import SVGIcon from '../../atoms/SVGIcon';
import { Flex } from '../../../Utils/styles/display';
import NewTextfield from '../../molecules/NewTextfield';

export const PreviewInfoContainer = styled.div`
  background: ${ThemeV2.palette.geekBlue1};
`;

export const FullScreen = styled.div`
  width: 100vw;
  height: 100vh;
`;

export const StyledFlexDiv = styled.div<{ isExternalFlow?: boolean }>`
  display: flex;
  align-items: center;
  width: 80%;
  max-width: 1000px;
  margin: 0 10%;
  @media (min-width: ${ThemeV2.breakPoints.md}) {
    margin: ${({ isExternalFlow }) => (isExternalFlow ? 'o auto' : '0 15%')};
    width: 70%;
  }
  padding: 16px 0;
`;

export const StyledSVG = styled(SVGIcon)`
  margin-right: 12px;
`;

export const ErrorImage = styled(Image)`
  margin: 20px;
  margin-bottom: 40px;
  max-width: 220px;
`;

export const ErrorCTAButton = styled(Button)`
  margin-top: 20px;
`;

export const Content = styled.main`
  grid-area: content;
  max-height: 100%;
  overflow-y: hidden;
`;

Content.displayName = 'Content';

export const Container = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: 40px 1fr;
  grid-template-rows: 73px 1fr 80px;
  grid-template-areas:
    'header header'
    'stepper content'
    'stepper footer';
  background: ${ThemeV2.palette.gray1};
  @media ${device.tabletV2} {
    grid-template-columns: 72px 1fr;
  }
`;

Container.displayName = 'Container';

export const SlideContainer = styled.div`
  height: 100%;
  overflow-y: hidden;
`;

SlideContainer.displayName = 'SlideContainer';

export const SingleSlideContainer = styled.div`
  min-height: 100%;
  max-height: 100%;
  height: 100%;
  overflow-y: auto;

  /* https://daily-dev-tips.com/posts/css-hide-scrollbars/ */
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

SingleSlideContainer.displayName = 'SingleSlideContainer';

export const Slide = styled(motion.div)`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

Slide.displayName = 'Slide';

export const transition = { duration: 0.4, ease: 'easeInOut' };

export const variants = {
  enter: {
    opacity: 0,
    transition,
  },
  center: {
    opacity: 1,
    transition,
  },
  exit: {
    opacity: 0,
    transition,
  },
};

export const MobileContentLoader = styled(ContentLoader)`
  @media ${device.tabletV2} {
    display: none;
  }
`;

export const TabContentLoader = styled(ContentLoader)`
  display: none;
  @media ${device.tablet} {
    display: block;
  }
`;

export const DesktopContentLoader = styled(ContentLoader)`
  display: none;
  @media ${device.laptop} {
    display: block;
  }
`;

export const Banner = styled(Flex)`
  background-color: ${ThemeV2.palette.gray3};
  padding: 16px 15%;
`;

export const StyledBannerIcon = styled(SVGIcon)`
  margin-right: 12px;
`;

export const StyledTextInput = styled(NewTextfield)`
  margin-right: 12px;
  max-width: 230px;
`;

export const TemplateLoader = () => (
  <>
    <MobileContentLoader
      speed={2}
      width="100%"
      height="100%"
      viewBox="0 0 100 180"
      backgroundColor={ThemeV2.palette.gray4}
      foregroundColor={ThemeV2.palette.gray3}
    >
      <rect x="15" y="50" rx="0" ry="0" width="50" height="8" />
      <rect x="15" y="61" rx="0" ry="0" width="30" height="5" />
      <rect x="15" y="69" rx="0" ry="0" width="60" height="3" />
      <circle cx="18" cy="84" r="3" />
      <rect x="24" y="82" rx="0" ry="0" width="25" height="4" />
      <circle cx="18" cy="93" r="3" />
      <rect x="24" y="91" rx="0" ry="0" width="25" height="4" />
      <circle cx="18" cy="102" r="3" />
      <rect x="24" y="100" rx="0" ry="0" width="25" height="4" />
    </MobileContentLoader>
    <TabContentLoader
      speed={2}
      width="100%"
      height="100%"
      viewBox="0 0 400 300"
      backgroundColor={ThemeV2.palette.gray4}
      foregroundColor={ThemeV2.palette.gray3}
    >
      <rect x="80" y="95" rx="0" ry="0" width="160" height="15" />
      <rect x="80" y="116" rx="0" ry="0" width="80" height="9" />
      <rect x="80" y="131" rx="0" ry="0" width="200" height="6" />
      <circle cx="84" cy="152" r="4" />
      <rect x="92" y="149" rx="0" ry="0" width="32" height="6" />
      <circle cx="84" cy="162.5" r="4" />
      <rect x="92" y="159.5" rx="0" ry="0" width="32" height="6" />
      <circle cx="84" cy="173" r="4" />
      <rect x="92" y="170" rx="0" ry="0" width="32" height="6" />
    </TabContentLoader>
    <DesktopContentLoader
      speed={2}
      width="100%"
      height="100%"
      viewBox="0 0 200 100"
      backgroundColor={ThemeV2.palette.gray4}
      foregroundColor={ThemeV2.palette.gray3}
    >
      <rect x="40" y="25" rx="0" ry="0" width="80" height="5" />
      <rect x="40" y="33" rx="0" ry="0" width="40" height="3" />
      <rect x="40" y="39" rx="0" ry="0" width="100" height="2.5" />
      <circle cx="42" cy="50" r="2" />
      <rect x="46" y="48.5" rx="0" ry="0" width="16" height="3" />
      <circle cx="42" cy="56" r="2" />
      <rect x="46" y="54.5" rx="0" ry="0" width="16" height="3" />
      <circle cx="42" cy="62" r="2" />
      <rect x="46" y="60.5" rx="0" ry="0" width="16" height="3" />
    </DesktopContentLoader>
  </>
);
