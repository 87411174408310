/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-use-before-define */
// @ts-nocheck TODO: type issues need to be fixed in this file
import axios from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import get from 'lodash/get';

import history from '../history';
import { API_URL_WITH_V2, API_URL_WITH_V3, IOS_URL } from '../config';
import processErrorResponse, {
  ErrorMessages,
} from '../containers/helpers/ErrorHelper';
import Logger from '../Utils/Logger';
import {
  updateMyDetails,
  getUserInfoAction,
  GET_MY_USER_INFO,
  userInfoSaga,
  meActions,
} from './Me';
import { HeadersWithoutAuth, AuthHeadersV2 } from './shared/HeaderToken';
import getter from '../Utils/objectUtils/getter';
import { rebootIntercom } from '../Utils/Intercom';
import { getAppSource, setAppSource } from '../Utils/msteams';
import { isSourceDeviceIPhone } from '../Utils/device';
import RoutesList from '../aV2/routes/routesList';
import { USER_ACCOUNT_SETUP, ONBOARDING_SETUP } from '../constants/routes';
// ------------------------------------
// Helpers
// ------------------------------------

const apiEndpoints = {
  LOGIN_USER: `${API_URL_WITH_V2}/auth/session/login`,
  FORGOT_PASSWORD: `${API_URL_WITH_V2}/auth/session/forgotPassword`,
  VERIFY_FORGOT_PASSWORD: `${API_URL_WITH_V2}/auth/session/forgotPassword/verify/`,
  RESET_PASSWORD: `${API_URL_WITH_V2}/auth/session/resetPassword`,
  SIGNOUT_USER: `${API_URL_WITH_V3}/users/session/logout`,
};

const processError = (error = {}, dispatch) => {
  // Logger('processError');
  // Logger(error.response);
  // Logger(error);
  const statusCode = error.response ? error.response.status : 0;
  switch (statusCode) {
    case 400: // bad request
      dispatch(sessionActions.requestFailed(ErrorMessages.serverError));
      break;
    case 403: // Forbidden
      // redirect to home
      break;
    case 404: // Page not found
      dispatch(sessionActions.requestFailed(ErrorMessages.serverError));
      break;
    // mostly my error
    case 401: // Not authorised
    case 422:
    case 500:
      if (error.response && error.response.data) {
        const responseData = error.response.data;
        const msg = processErrorResponse(responseData);
        dispatch(sessionActions.requestFailed(msg));
      }
      break;
    default:
      dispatch(sessionActions.requestFailed(ErrorMessages.networkError));
      break;
  }
};

// ------------------------------------
// Constants
// ------------------------------------

const AUTHENTICATE_USER = 'AUTHENTICATE_USER';
const AUTHENTICATED = 'authenticated_user';
const UNAUTHENTICATED = 'unauthenticated_user';
const AUTHENTICATION_ERROR = 'authentication_error';
const AUTHENTICATION_RESET = 'authentication_reset';
const REQUEST_FAILED = 'sessions_request_failed';
const FORGOTPASSWORDEMAILSENT = 'forgot_password_email_sent';
const FORGOT_PASSWORD_VERIFIED = 'forgot_password_verified';
const RESET_SESSION_STATE_MESSAGES = 'reset_sessions_state_messages';
export const SIGN_OUT = 'unauthenticated_user';

// ------------------------------------
// Actions
// ------------------------------------

const authenticated = () => ({
  type: AUTHENTICATED,
});

const unauthenticated = () => ({
  type: UNAUTHENTICATED,
});

const auth_error = () => ({
  type: AUTHENTICATION_ERROR,
  payload: 'Invalid email or password',
});

const reset_auth = () => ({
  type: AUTHENTICATION_RESET,
});

const forgotPasswordEmailSent = (email) => ({
  type: FORGOTPASSWORDEMAILSENT,
  email,
});
const forgotPasswordVerified = (user) => ({
  type: FORGOT_PASSWORD_VERIFIED,
  user,
});

const requestFailed = (errorMessage) => ({
  type: REQUEST_FAILED,
  error: errorMessage,
});
const resetStateMessages = () => ({
  type: RESET_SESSION_STATE_MESSAGES,
});

export const sessionActions = {
  authenticated,
  unauthenticated,
  auth_error,
  reset_auth,
  requestFailed,
  forgotPasswordEmailSent,
  forgotPasswordVerified,
  resetStateMessages,
};

// ------------------------------------
// API Wrapper
// ------------------------------------

export const signInAction = (props) => {
  const { email, password } = props;
  const rememberMe = props.rememberMe || false;

  return async (dispatch) => {
    try {
      const data = {
        email,
        password,
        rememberMe,
      };

      const res = await axios.post(
        apiEndpoints.LOGIN_USER,
        data,
        HeadersWithoutAuth(),
      );
      const result = res.data;
      Logger(result, true);
      if (result.success) {
        const { user } = result.data;
        authenticateUser(user, dispatch);
        const toPath =
          getter(['location', 'state', 'origin'], history) || '/home';
        if (toPath.includes('signin') || toPath.includes('signout')) {
          history.push('/home');
        } else {
          history.push(toPath);
        }
      }
    } catch (error) {
      dispatch(sessionActions.auth_error());
      processError(error, dispatch);
    }
  };
};

export const forgotPasswordAction = (props) => {
  const { email } = props;
  return async (dispatch) => {
    try {
      const data = {
        email,
      };

      const res = await axios.post(
        apiEndpoints.FORGOT_PASSWORD,
        data,
        HeadersWithoutAuth(),
      );
      const result = res.data;
      Logger(result, false);
      if (result.success) {
        dispatch(sessionActions.forgotPasswordEmailSent(email));
        history.push('/resetEmailSent');
      }
    } catch (error) {
      processError(error, dispatch);
    }
  };
};
export const forgotPasswordVerifyTokenAction = (props) => {
  const { token } = props;
  return async (dispatch) => {
    try {
      const res = await axios.get(
        `${apiEndpoints.VERIFY_FORGOT_PASSWORD}${token}`,
        HeadersWithoutAuth(),
      );
      const result = res.data;
      Logger(result, false);
      if (result.success) {
        dispatch(forgotPasswordVerified(result.data));
        history.push('/resetPassword');
      }
    } catch (error) {
      processError(error, dispatch);
    }
  };
};
export const resetPasswordAction = (props) => {
  const { userId, password } = props;
  return async (dispatch) => {
    try {
      const data = {
        userId,
        password,
      };

      const res = await axios.post(
        apiEndpoints.RESET_PASSWORD,
        data,
        HeadersWithoutAuth(),
      );
      const result = res.data;
      Logger(result, false);
      if (result.success) {
        history.push('/signin');
      }
    } catch (error) {
      processError(error, dispatch);
    }
  };
};

export const clearLocalStorage = () => {
  const appSource = getAppSource();

  // TODO: group these values into one object per assembly instance
  // to make it easier to handle this in multi-assembly world & to
  // clear the cache on sign out
  const keysToClear = [
    'user',
    'token',
    'dontShowClickedOnSlackSetupModal',
    'dontShowQuickSetupProgress',
    'userFirstLogin',
    // 'SOURCE_DEVICE', //  Vijay: I need to persist this value to identity the device after successful SSO
    'WEB_APP_SOURCE',
  ];

  keysToClear.forEach((key) => localStorage.removeItem(key));

  if (appSource) {
    setAppSource(appSource);
  }
};

export const signOutAction = () => {
  // All user info and user settings (Slack complete modal, Quick setup close state) are cleared here
  clearLocalStorage();
  rebootIntercom();

  // Clear Cookies
  // CookieHandler.remove(COOKIE_CONSTANTS.ASSEMBLY_TOKEN);
  // CookieHandler.remove(COOKIE_CONSTANTS.ASSEMBLY_USER);
  return {
    type: UNAUTHENTICATED,
  };
};

export const signoutAsyncAction = () => {
  return async (dispatch) => {
    try {
      const data = {};

      const res = await axios.post(
        apiEndpoints.SIGNOUT_USER,
        data,
        AuthHeadersV2(),
      );
      const result = res.data;
      Logger(result, false);
      dispatch(signOutAction());
    } catch (error) {
      dispatch(signOutAction());
      processError(error, dispatch);
    }
  };
};

export const authenticateUser = (me, dispatch) => {
  updateMyDetails(me, dispatch);
  dispatch(sessionActions.authenticated());
  dispatch(getUserInfoAction());
};

export function userSessionCreatedAction(user, accessToken) {
  return { type: AUTHENTICATE_USER, me: user, accessToken };
}

export const handleUserSignin = (user, to = RoutesList.HOME) => {
  const isOnboardingComplete = get(user, 'isOnboardingComplete', true);
  const isFirstLogin = get(user, 'isFirstLogin', false);
  const roles = get(user, 'role', []);
  const isOwner = roles.includes('Owner');

  if (!isOnboardingComplete && isFirstLogin && isOwner) {
    history.push(ONBOARDING_SETUP);
  } else if (!isOnboardingComplete) {
    const route = isFirstLogin
      ? `${RoutesList.EMPLOYEE_SET_DETAILS}?firstLogin=true`
      : RoutesList.EMPLOYEE_SET_DETAILS;
    history.push(route);
  } else {
    history.push(isFirstLogin ? USER_ACCOUNT_SETUP : to);
  }
};

function* authenticateUserSaga({ me, accessToken }) {
  if (isSourceDeviceIPhone()) {
    window.location.href = `${IOS_URL}auth?token=${accessToken}`;
    return;
  }

  updateMyDetails(me);
  yield put(meActions.gotMyDetails(me));
  yield put(sessionActions.authenticated());
  yield put({ type: GET_MY_USER_INFO });
}

export function* watchAuthenticateUserSaga() {
  yield takeEvery(AUTHENTICATE_USER, authenticateUserSaga);
  yield takeEvery(GET_MY_USER_INFO, userInfoSaga);
}

// ------------------------------------
// Action Handlers
// ------------------------------------

// ------------------------------------
// Reducers
// ------------------------------------

export default (state = {}, action) => {
  switch (action.type) {
    case AUTHENTICATED:
      return { ...state, authenticated: true, error: '' };
    case UNAUTHENTICATED:
      return { ...state, authenticated: false, error: '' };
    case AUTHENTICATION_ERROR:
      return { ...state, authenticated: false, error: action.payload };
    case AUTHENTICATION_RESET:
      return { ...state, authenticated: false, error: '' };
    case REQUEST_FAILED:
      return { ...state, error: action.error };
    case FORGOTPASSWORDEMAILSENT:
      return {
        ...state,
        authenticated: false,
        emailSent: true,
        error: '',
        resetEmail: action.email,
      };
    case FORGOT_PASSWORD_VERIFIED:
      return {
        ...state,
        authenticated: false,
        userVerified: action.user,
        error: '',
        resetEmail: null,
      };
    case RESET_SESSION_STATE_MESSAGES:
      return { ...state, error: null, emailSent: false };
    default:
      return state;
  }
};
