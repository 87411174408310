// @ts-nocheck TODO: type issues need to be fixed in this file
import React, { useState, useEffect, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import { Link } from 'react-router-dom';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import RoutesList from '../../../../aV2/routes/routesList';

import {
  SplitNames,
  TreatmentTypes,
} from '../../../../hooks/useSplitSdkConfig/constants';
import { getMainRoutePath } from '../../../../Utils/admin/urls';
import { useFeatureSplit } from '../../../../hooks/useFeatureSplit';
import ThemeV2 from '../../../../componentsV2/theme';
import SVGIcon from '../../../../atomic/atoms/SVGIcon';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
  },
  nested: {
    paddingLeft: theme.spacing(8),
  },
  itemText: {
    fontSize: ThemeV2.typography.fontSize,
    color: ThemeV2.palette.darkGray9,
  },
  selectedText: {
    fontSize: ThemeV2.typography.fontSize,
    color: ThemeV2.palette.primary.main,
  },
}));

const AdminMenuCategory = {
  workspace: {
    id: 'workspace',
    display: 'Workspace',
  },
  recognition: {
    id: 'recognition',
    display: 'Recognition',
  },
  flows: {
    id: 'flows',
    display: 'Flows',
  },
  users: {
    id: 'users',
    display: 'Users',
  },
  celebration: {
    id: 'celebration',
    display: 'Celebration Bots',
  },
  rewards: {
    id: 'rewards',
    display: 'Rewards',
  },
  billing: {
    id: 'billing',
    display: 'Billing',
  },
  security: {
    id: 'security',
    display: 'Security & Login',
  },
};

// YDOUBT
const AdminRouteIndex = {
  GENERAL: 2,
  ALLOWANCES: 3,
  INTEGRATIONS: 4,
  INSIGHTS: 5,
  RECOGNITION_SETTINGS: 7,
  RECOGNITION_ACTIVITY: 8,
  RECOGNITION_PARTICIPATION: 9,
  FLOW_PERMISSIONS: 11,
  USERS_MANAGE: 13,
  USERS_INVITE: 14,
  USERS_EARNINGS: 15,
  CELEBRATION_BIRTHDAY: 17,
  CELEBRATION_ANNIVERSARY: 18,
  REWARDS_GIFT_CARDS: 20,
  REWARDS_CULTURE: 21,
  REWARDS_SWAGS: 22,
  REWARDS_CHARITY: 23,
  REWARDS_REPORT: 24,
  BILLING_ACCOUNT: 25,
  BILLING_HISTORY: 26,
  BILLING_METHOD: 27,
  SECURITY_SAML: 29,
};

const AdminRoutesList = {
  workspace: [
    {
      url: RoutesList.ADMIN_WORKSPACE_GENERAL_LEGACY,
      display: 'General',
      index: AdminRouteIndex.GENERAL,
    },
    {
      url: RoutesList.ADMIN_WORKSPACE_ALLOWANCES_LEGACY,
      display: 'Allowances',
      index: AdminRouteIndex.ALLOWANCES,
    },
    {
      url: RoutesList.ADMIN_WORKSPACE_INTEGRATIONS_LEGACY,
      display: 'Integrations',
      index: AdminRouteIndex.INTEGRATIONS,
    },
    {
      url: RoutesList.ADMIN_WORKSPACE_INSIGHTS_LEGACY,
      display: 'Insights',
      index: AdminRouteIndex.INSIGHTS,
    },
  ],
  recognition: [
    {
      url: RoutesList.ADMIN_RECOGNITION_SETTINGS_LEGACY,
      display: 'Settings',
      index: AdminRouteIndex.RECOGNITION_SETTINGS,
    },
    {
      url: RoutesList.ADMIN_RECOGNITION_ACTIVITY_LEGACY,
      display: 'Activity',
      index: AdminRouteIndex.RECOGNITION_ACTIVITY,
    },
    {
      url: RoutesList.ADMIN_RECOGNITION_PARTICIPATION_LEGACY,
      display: 'Participation',
      index: AdminRouteIndex.RECOGNITION_PARTICIPATION,
    },
  ],
  flows: [
    {
      url: RoutesList.ADMIN_FLOWS_PERMISSIONS_LEGACY,
      display: 'Permissions',
      index: AdminRouteIndex.FLOW_PERMISSIONS,
    },
  ],
  users: [
    {
      url: RoutesList.ADMIN_USER_MANAGE_LEGACY,
      display: 'Manage',
      index: AdminRouteIndex.USERS_MANAGE,
    },
    {
      url: RoutesList.ADMIN_USER_INVITE_LEGACY,
      display: 'Invite',
      index: AdminRouteIndex.USERS_INVITE,
    },
    {
      url: RoutesList.ADMIN_USER_EARNINGS_LEGACY,
      display: 'Earnings',
      index: AdminRouteIndex.USERS_EARNINGS,
    },
  ],
  celebration: [
    {
      url: RoutesList.ADMIN_CELEBRATIONS_BIRTHDAYS_LEGACY,
      display: 'Birthday',
      index: AdminRouteIndex.CELEBRATION_BIRTHDAY,
    },
    {
      url: RoutesList.ADMIN_CELEBRATIONS_ANNIVERSARIES_LEGACY,
      display: 'Anniversary',
      index: AdminRouteIndex.CELEBRATION_ANNIVERSARY,
    },
  ],
  rewards: [
    {
      url: RoutesList.ADMIN_REWARDS_GIFT_CARDS_LEGACY,
      display: 'Gift Cards',
      index: AdminRouteIndex.REWARDS_GIFT_CARDS,
    },
    {
      url: RoutesList.ADMIN_REWARDS_CULTURE_LEGACY,
      display: 'Culture Rewards',
      index: AdminRouteIndex.REWARDS_CULTURE,
    },
    {
      url: RoutesList.ADMIN_REWARDS_SWAGS_LEGACY,
      display: 'Axomo Swag',
      index: AdminRouteIndex.REWARDS_SWAGS,
    },
    {
      url: RoutesList.ADMIN_REWARDS_CHARITY_LEGACY,
      display: 'Charitable Donations',
      index: AdminRouteIndex.REWARDS_CHARITY,
    },
    {
      url: RoutesList.ADMIN_REWARDS_REPORT_LEGACY,
      display: 'Redemption Report',
      index: AdminRouteIndex.REWARDS_REPORT,
    },
  ],
  billing: [
    {
      url: RoutesList.ADMIN_BILLING_ACCOUNT_LEGACY,
      display: 'Account Plan',
      index: AdminRouteIndex.BILLING_ACCOUNT,
    },
    {
      url: RoutesList.ADMIN_BILLING_HISTORY_LEGACY,
      display: 'Payment History',
      index: AdminRouteIndex.BILLING_HISTORY,
    },
    {
      url: RoutesList.ADMIN_BILLING_METHOD_LEGACY,
      display: 'Payment Method',
      index: AdminRouteIndex.BILLING_METHOD,
    },
  ],
  security: [
    {
      url: RoutesList.ADMIN_SAML_AUTHENTICATION_LEGACY,
      display: 'SAML Authentication',
      index: AdminRouteIndex.SECURITY_SAML,
    },
  ],
};

const AdminRoutesListV1 = {
  workspace: [
    {
      url: RoutesList.ADMIN_WORKSPACE_GENERAL,
      display: 'General',
      index: AdminRouteIndex.GENERAL,
    },
    {
      url: RoutesList.ADMIN_WORKSPACE_ALLOWANCES,
      display: 'Allowances',
      index: AdminRouteIndex.ALLOWANCES,
    },
    {
      url: RoutesList.ADMIN_WORKSPACE_INTEGRATIONS,
      display: 'Integrations',
      index: AdminRouteIndex.INTEGRATIONS,
    },
    {
      url: RoutesList.ADMIN_WORKSPACE_INSIGHTS,
      display: 'Insights',
      index: AdminRouteIndex.INSIGHTS,
    },
  ],
  recognition: [
    {
      url: RoutesList.ADMIN_RECOGNITION_SETTINGS,
      display: 'Settings',
      index: AdminRouteIndex.RECOGNITION_SETTINGS,
    },
    {
      url: RoutesList.ADMIN_RECOGNITION_ACTIVITY,
      display: 'Activity',
      index: AdminRouteIndex.RECOGNITION_ACTIVITY,
    },
    {
      url: RoutesList.ADMIN_RECOGNITION_PARTICIPATION,
      display: 'Participation',
      index: AdminRouteIndex.RECOGNITION_PARTICIPATION,
    },
  ],
  flows: [
    {
      url: RoutesList.ADMIN_FLOWS_PERMISSIONS,
      display: 'Permissions',
      index: AdminRouteIndex.FLOW_PERMISSIONS,
    },
  ],
  users: [
    {
      url: RoutesList.ADMIN_USER_MANAGE,
      display: 'Manage',
      index: AdminRouteIndex.USERS_MANAGE,
    },
    {
      url: RoutesList.ADMIN_USER_INVITE,
      display: 'Invite',
      index: AdminRouteIndex.USERS_INVITE,
    },
    {
      url: RoutesList.ADMIN_USER_EARNINGS,
      display: 'Earnings',
      index: AdminRouteIndex.USERS_EARNINGS,
    },
  ],
  celebration: [
    {
      url: RoutesList.ADMIN_CELEBRATIONS_BIRTHDAYS,
      display: 'Birthday',
      index: AdminRouteIndex.CELEBRATION_BIRTHDAY,
    },
    {
      url: RoutesList.ADMIN_CELEBRATIONS_ANNIVERSARIES,
      display: 'Anniversary',
      index: AdminRouteIndex.CELEBRATION_ANNIVERSARY,
    },
  ],
  rewards: [
    {
      url: RoutesList.ADMIN_REWARDS_GIFT_CARDS,
      display: 'Gift Cards',
      index: AdminRouteIndex.REWARDS_GIFT_CARDS,
    },
    {
      url: RoutesList.ADMIN_REWARDS_CULTURE,
      display: 'Culture Rewards',
      index: AdminRouteIndex.REWARDS_CULTURE,
    },
    {
      url: RoutesList.ADMIN_REWARDS_SWAGS,
      display: 'Axomo Swag',
      index: AdminRouteIndex.REWARDS_SWAGS,
    },
    {
      url: RoutesList.ADMIN_REWARDS_CHARITY,
      display: 'Charitable Donations',
      index: AdminRouteIndex.REWARDS_CHARITY,
    },
    {
      url: RoutesList.ADMIN_REWARDS_REPORT,
      display: 'Redemption Report',
      index: AdminRouteIndex.REWARDS_REPORT,
    },
  ],
  billing: [
    {
      url: RoutesList.ADMIN_BILLING_ACCOUNT,
      display: 'Account Plan',
      index: AdminRouteIndex.BILLING_ACCOUNT,
    },
    {
      url: RoutesList.ADMIN_BILLING_HISTORY,
      display: 'Payment History',
      index: AdminRouteIndex.BILLING_HISTORY,
    },
    {
      url: RoutesList.ADMIN_BILLING_METHOD,
      display: 'Payment Method',
      index: AdminRouteIndex.BILLING_METHOD,
    },
  ],
  security: [
    {
      url: RoutesList.ADMIN_SAML_AUTHENTICATION,
      display: 'SAML Authentication',
      index: AdminRouteIndex.SECURITY_SAML,
    },
  ],
};

const getUpdatedSelectedIndex = (path) => {
  let index = AdminRouteIndex.GENERAL;
  let category = AdminMenuCategory.workspace.id;
  const mainRoutePath = getMainRoutePath(path);
  switch (mainRoutePath) {
    // YTODO
    case RoutesList.ADMIN_WORKSPACE_GENERAL_LEGACY:
      index = AdminRouteIndex.GENERAL;
      break;
    case RoutesList.ADMIN_WORKSPACE_ALLOWANCES_LEGACY:
    case RoutesList.ADMIN_WORKSPACE_ALLOWANCES_MANAGE:
      index = AdminRouteIndex.ALLOWANCES;
      break;
    case RoutesList.ADMIN_WORKSPACE_INTEGRATIONS_LEGACY:
      index = AdminRouteIndex.INTEGRATIONS;
      break;
    case RoutesList.ADMIN_WORKSPACE_INSIGHTS_LEGACY:
      index = AdminRouteIndex.INSIGHTS;
      break;
    case RoutesList.ADMIN_RECOGNITION_SETTINGS_LEGACY:
      index = AdminRouteIndex.RECOGNITION_SETTINGS;
      category = AdminMenuCategory.recognition.id;
      break;
    case RoutesList.ADMIN_RECOGNITION_ACTIVITY_LEGACY:
      index = AdminRouteIndex.RECOGNITION_ACTIVITY;
      category = AdminMenuCategory.recognition.id;
      break;
    case RoutesList.ADMIN_RECOGNITION_PARTICIPATION_LEGACY:
      index = AdminRouteIndex.RECOGNITION_PARTICIPATION;
      category = AdminMenuCategory.recognition.id;
      break;
    case RoutesList.ADMIN_FLOWS_PERMISSIONS_LEGACY:
      index = AdminRouteIndex.FLOW_PERMISSIONS;
      category = AdminMenuCategory.flows.id;
      break;
    case RoutesList.ADMIN_USER_MANAGE_LEGACY:
      index = AdminRouteIndex.USERS_MANAGE;
      category = AdminMenuCategory.users.id;
      break;
    case RoutesList.ADMIN_USER_INVITE_LEGACY:
      index = AdminRouteIndex.USERS_INVITE;
      category = AdminMenuCategory.users.id;
      break;
    case RoutesList.ADMIN_USER_EARNINGS_LEGACY:
      index = AdminRouteIndex.USERS_EARNINGS;
      category = AdminMenuCategory.users.id;
      break;
    case RoutesList.ADMIN_CELEBRATIONS_BIRTHDAYS_LEGACY:
      index = AdminRouteIndex.CELEBRATION_BIRTHDAY;
      category = AdminMenuCategory.celebration.id;
      break;
    case RoutesList.ADMIN_CELEBRATIONS_ANNIVERSARIES_LEGACY:
      index = AdminRouteIndex.CELEBRATION_ANNIVERSARY;
      category = AdminMenuCategory.celebration.id;
      break;
    case RoutesList.ADMIN_REWARDS_GIFT_CARDS_LEGACY:
      index = AdminRouteIndex.REWARDS_GIFT_CARDS;
      category = AdminMenuCategory.rewards.id;
      break;
    case RoutesList.ADMIN_REWARDS_CULTURE_LEGACY:
      index = AdminRouteIndex.REWARDS_CULTURE;
      category = AdminMenuCategory.rewards.id;
      break;
    case RoutesList.ADMIN_REWARDS_CHARITY_LEGACY:
      index = AdminRouteIndex.REWARDS_CHARITY;
      category = AdminMenuCategory.rewards.id;
      break;
    case RoutesList.ADMIN_REWARDS_REPORT_LEGACY:
      index = AdminRouteIndex.REWARDS_REPORT;
      category = AdminMenuCategory.rewards.id;
      break;
    case RoutesList.ADMIN_REWARDS_SWAGS_LEGACY:
      index = AdminRouteIndex.REWARDS_SWAGS;
      category = AdminMenuCategory.rewards.id;
      break;
    case RoutesList.ADMIN_BILLING_ACCOUNT_LEGACY:
      index = AdminRouteIndex.BILLING_ACCOUNT;
      category = AdminMenuCategory.billing.id;
      break;
    case RoutesList.ADMIN_BILLING_HISTORY_INVOICE_LEGACY:
    case RoutesList.ADMIN_BILLING_HISTORY_LEGACY:
      index = AdminRouteIndex.BILLING_HISTORY;
      category = AdminMenuCategory.billing.id;
      break;
    case RoutesList.ADMIN_BILLING_METHOD_LEGACY:
      index = AdminRouteIndex.BILLING_METHOD;
      category = AdminMenuCategory.billing.id;
      break;
    case RoutesList.ADMIN_SAML_AUTHENTICATION_LEGACY:
      index = AdminRouteIndex.SECURITY_SAML;
      category = AdminMenuCategory.security.id;
      break;
    default:
      break;
  }
  return {
    selectedItem: index,
    selectedCategory: category,
  };
};

const AdminMenu = () => {
  const { treatment: rewardsSwagsTreatment } = useFeatureSplit(
    SplitNames.AXOMO_REWARDS,
  );

  const { treatment: combinedUsersManageInviteTreatment } = useFeatureSplit(
    SplitNames.COMBINED_USERS_MANAGE_INVITE,
  );

  const { treatment: adminRefactorTreatment } = useFeatureSplit(
    SplitNames.ADMIN_NEW_APP,
  );

  const adminRoutes = useMemo(() => {
    if (adminRefactorTreatment === TreatmentTypes.ON) {
      return AdminRoutesListV1;
    }

    return AdminRoutesList;
  }, [adminRefactorTreatment]);

  const isCombinedUsersManageInviteEnabled =
    combinedUsersManageInviteTreatment === TreatmentTypes.ON;

  const classes = useStyles();

  const [workspaceOpen, setWorkspaceOpen] = useState(false);
  const [recognitionOpen, setRecognitionOpen] = useState(false);
  const [flowsOpen, setFlowsOpen] = useState(false);
  const [usersOpen, setUsersOpen] = useState(false);
  const [celebrationOpen, setCelebrationOpen] = useState(false);
  const [rewardsOpen, setRewardsOpen] = useState(false);
  const [billingOpen, setBillingOpen] = useState(false);
  const [securityOpen, setSecurityOpen] = useState(false);

  const [selectedMenuItem, setSelectedMenuItem] = useState(
    AdminRouteIndex.GENERAL,
  );

  const { pathname } = useLocation();
  useEffect(() => {
    const { selectedCategory, selectedItem } =
      getUpdatedSelectedIndex(pathname);
    setSelectedMenuItem(selectedItem);

    if (selectedCategory === AdminMenuCategory.workspace.id) {
      if (!workspaceOpen) {
        setWorkspaceOpen(true);
      }
    } else if (selectedCategory === AdminMenuCategory.recognition.id) {
      if (!recognitionOpen) {
        setRecognitionOpen(true);
      }
    } else if (selectedCategory === AdminMenuCategory.flows.id) {
      if (!flowsOpen) {
        setFlowsOpen(true);
      }
    } else if (selectedCategory === AdminMenuCategory.users.id) {
      if (!usersOpen) {
        setUsersOpen(true);
      }
    } else if (selectedCategory === AdminMenuCategory.celebration.id) {
      if (!celebrationOpen) {
        setCelebrationOpen(true);
      }
    } else if (selectedCategory === AdminMenuCategory.rewards.id) {
      if (!rewardsOpen) {
        setRewardsOpen(true);
      }
    } else if (selectedCategory === AdminMenuCategory.billing.id) {
      if (!billingOpen) {
        setBillingOpen(true);
      }
    } else if (selectedCategory === AdminMenuCategory.security.id) {
      if (!securityOpen) {
        setSecurityOpen(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, selectedMenuItem]);

  const adminRewardsFilteredList = useMemo(() => {
    return adminRoutes.rewards.filter((route) => {
      if (
        route.url === RoutesList.ADMIN_REWARDS_SWAGS ||
        route.url === RoutesList.ADMIN_REWARDS_SWAGS_LEGACY
      ) {
        if (
          rewardsSwagsTreatment === TreatmentTypes.ON ||
          rewardsSwagsTreatment === TreatmentTypes.CONTROL
        ) {
          return true;
        }
        return false;
      }
      return true;
    });
  }, [rewardsSwagsTreatment, adminRoutes]);

  const userNavList = isCombinedUsersManageInviteEnabled
    ? adminRoutes.users.filter((navItem) => navItem.display !== 'Invite')
    : adminRoutes.users;

  const handleWorkspaceClick = () => {
    setWorkspaceOpen(!workspaceOpen);
  };

  const handleRecognitionClick = () => {
    setRecognitionOpen(!recognitionOpen);
  };

  const handleFlowsClick = () => {
    setFlowsOpen(!flowsOpen);
  };
  const handleUsersClick = () => {
    setUsersOpen(!usersOpen);
  };

  const handleCelebrationClick = () => {
    setCelebrationOpen(!celebrationOpen);
  };

  const handleRewardsClick = () => {
    setRewardsOpen(!rewardsOpen);
  };

  const handleBillingClick = () => {
    setBillingOpen(!billingOpen);
  };

  const handleSecurityClick = () => {
    setSecurityOpen(!securityOpen);
  };

  return (
    <List
      component="nav"
      className={classes.root}
      data-test="admin-sidebar-nav"
    >
      <ListItem button onClick={handleWorkspaceClick}>
        <ListItemIcon>
          <SVGIcon icon="setting-filled" />
        </ListItemIcon>
        <ListItemText disableTypography primary="Workspace" />
        {workspaceOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={workspaceOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {adminRoutes.workspace.map((route) => {
            return (
              <Link key={route.url} to={route.url}>
                <ListItem button className={classes.nested}>
                  <ListItemText
                    disableTypography
                    className={
                      route.index === selectedMenuItem
                        ? classes.selectedText
                        : classes.itemText
                    }
                    primary={route.display}
                  />
                </ListItem>
              </Link>
            );
          })}
        </List>
      </Collapse>
      <ListItem button onClick={handleRecognitionClick}>
        <ListItemIcon>
          <SVGIcon icon="trophy" />
        </ListItemIcon>
        <ListItemText disableTypography primary="Recognition" />
        {recognitionOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={recognitionOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {adminRoutes.recognition.map((route) => (
            <Link key={route.url} to={route.url}>
              <ListItem button className={classes.nested}>
                <ListItemText
                  disableTypography
                  className={
                    route.index === selectedMenuItem
                      ? classes.selectedText
                      : classes.itemText
                  }
                  primary={route.display}
                />
              </ListItem>
            </Link>
          ))}
        </List>
      </Collapse>
      <ListItem button onClick={handleFlowsClick}>
        <ListItemIcon>
          <SVGIcon icon="routine" />
        </ListItemIcon>
        <ListItemText disableTypography primary="Flows" />
        {flowsOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={flowsOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {adminRoutes.flows.map((route) => (
            <Link key={route.url} to={route.url}>
              <ListItem button className={classes.nested}>
                <ListItemText
                  disableTypography
                  className={
                    route.index === selectedMenuItem
                      ? classes.selectedText
                      : classes.itemText
                  }
                  primary={route.display}
                />
              </ListItem>
            </Link>
          ))}
        </List>
      </Collapse>
      <ListItem button onClick={handleUsersClick}>
        <ListItemIcon>
          <SVGIcon icon="users" color={ThemeV2.palette.darkGray1} />
        </ListItemIcon>
        <ListItemText disableTypography primary="Users" />
        {usersOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={usersOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {userNavList.map((route) => (
            <Link key={route.url} to={route.url}>
              <ListItem button className={classes.nested}>
                <ListItemText
                  disableTypography
                  className={
                    route.index === selectedMenuItem
                      ? classes.selectedText
                      : classes.itemText
                  }
                  primary={route.display}
                />
              </ListItem>
            </Link>
          ))}
        </List>
      </Collapse>
      <ListItem button onClick={handleCelebrationClick}>
        <ListItemIcon>
          <SVGIcon icon="birthday-cake" />
        </ListItemIcon>
        <ListItemText disableTypography primary="Celebration Bots" />
        {celebrationOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={celebrationOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {adminRoutes.celebration.map((route) => (
            <Link key={route.url} to={route.url}>
              <ListItem button className={classes.nested}>
                <ListItemText
                  disableTypography
                  className={
                    route.index === selectedMenuItem
                      ? classes.selectedText
                      : classes.itemText
                  }
                  primary={route.display}
                />
              </ListItem>
            </Link>
          ))}
        </List>
      </Collapse>
      <ListItem button onClick={handleRewardsClick}>
        <ListItemIcon>
          <SVGIcon icon="reward" />
        </ListItemIcon>
        <ListItemText disableTypography primary="Rewards" />
        {rewardsOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={rewardsOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {adminRewardsFilteredList.map((route) => (
            <Link key={route.url} to={route.url}>
              <ListItem button className={classes.nested}>
                <ListItemText
                  disableTypography
                  className={
                    route.index === selectedMenuItem
                      ? classes.selectedText
                      : classes.itemText
                  }
                  primary={route.display}
                />
              </ListItem>
            </Link>
          ))}
        </List>
      </Collapse>
      <ListItem button onClick={handleBillingClick}>
        <ListItemIcon>
          <SVGIcon icon="billing" />
        </ListItemIcon>
        <ListItemText disableTypography primary="Billing" />
        {billingOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={billingOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {adminRoutes.billing.map((route) => (
            <Link key={route.url} to={route.url}>
              <ListItem button className={classes.nested}>
                <ListItemText
                  disableTypography
                  className={
                    route.index === selectedMenuItem
                      ? classes.selectedText
                      : classes.itemText
                  }
                  primary={route.display}
                />
              </ListItem>
            </Link>
          ))}
        </List>
      </Collapse>
      <ListItem button onClick={handleSecurityClick}>
        <ListItemIcon>
          <SVGIcon icon="shield" />
        </ListItemIcon>
        <ListItemText disableTypography primary="Security & Login" />
        {securityOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={securityOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {adminRoutes.security.map((route) => (
            <Link key={route.url} to={route.url}>
              <ListItem button className={classes.nested}>
                <ListItemText
                  disableTypography
                  className={
                    route.index === selectedMenuItem
                      ? classes.selectedText
                      : classes.itemText
                  }
                  primary={route.display}
                />
              </ListItem>
            </Link>
          ))}
        </List>
      </Collapse>
    </List>
  );
};

export default AdminMenu;
