// @ts-nocheck TODO: type issues need to be fixed in this file
import React, { Component } from 'react';
import { Img } from 'react-image';
import Button from '@material-ui/core/Button';
import { detect } from 'detect-browser';
import brokenCarrot from '../img/brokenBug.png';
import safariLogo from '../img/safariLogo.png';
import chromeLogo from '../img/chromeLogo.png';
import edgeLogo from '../img/edgeLogo.png';
import firefoxLogo from '../img/firefoxLogo.png';
import { APP_NAME } from '../Utils/constants';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, unSupprortedBrowser: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    const browser = detect();
    let isSupportedBrowser = true;
    if (browser) {
      // console.log(browser.name);
      // console.log(browser.version);
      // console.log(browser.os);
      if (browser.name === 'chrome') {
        if (browser.version < 66) {
          isSupportedBrowser = false;
        }
      } else if (browser.name === 'firefox') {
        if (browser.version < 60) {
          isSupportedBrowser = false;
        }
      } else if (browser.name === 'safari') {
        if (browser.version < 11) {
          isSupportedBrowser = false;
        }
      } else if (browser.name === 'edge') {
        if (browser.version < 15) {
          isSupportedBrowser = false;
        }
      } else if (browser.name === 'ie') {
        isSupportedBrowser = false;
      }
    }
    if (!isSupportedBrowser) {
      this.setState({ unSupprortedBrowser: true });
    } else {
      // this.setState({ unSupprortedBrowser: true });
      this.setState({ hasError: true });
    }
  }

  onBackHomeClick = () => {
    this.setState({ hasError: false, unSupprortedBrowser: false });
    window.location.href = `${window.location.origin}/home`;
    // history.push('/home');
  };

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="somethingwentwrong-bg__div">
          <div className="vertical-center">
            <div className="container">
              <div className="row  justify-content-center">
                <img
                  className="sww-broken-carrot__img"
                  alt="somethingwentwrong"
                  src={brokenCarrot}
                />
              </div>
              <div className="row  justify-content-center top-buffer-row">
                <div className="nopage-subheader__div">
                  Ah, a bug! Something went wrong!
                </div>
              </div>
              <div className="row  justify-content-center top-buffer-row">
                <div className="nopage-body__div">
                  Darn, this is awkward. something went wrong and our team has
                  been notified. We are looking into this asap.
                </div>
              </div>
              <div className="row  justify-content-center top-buffer-row">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={this.onBackHomeClick}
                >
                  BACK HOME
                </Button>
              </div>
            </div>
          </div>
        </div>
      );
    }
    if (this.state.unSupprortedBrowser) {
      return (
        <div className="somethingwentwrong-bg__div">
          <div className="vertical-center">
            <div className="container">
              {/* <div className="row  justify-content-center">
                <img className='sww-broken-carrot__img' alt="somethingwentwrong" src={broken_carrot} />
              </div> */}
              <div
                className="row  justify-content-center top-buffer-row"
                style={{ paddingTop: '9%' }}
              >
                <div className="nobrowser-subheader__div">
                  SORRY, THIS BROWSER OR VERSION IS NOT SUPPORTED
                </div>
              </div>
              <div className="row  justify-content-center top-buffer-row">
                <div className="nobrowser-body__div">
                  To best experience {APP_NAME}, please download or swtich to
                  the latest one of these browsers
                </div>
              </div>
              <div className="row  justify-content-center top-buffer-row">
                <div className="col-2-lg nobrowser-col__div">
                  <div className="container">
                    <div className="row justify-content-center">
                      <Img
                        className="browser-logo__img"
                        alt="safari"
                        src={safariLogo}
                      />
                    </div>
                    <div className="row justify-content-center top-buffer-row">
                      <div className="container">
                        <div className="row justify-content-center nobrowser-name__div">
                          Safari
                        </div>
                        <div className="row justify-content-center nobrowser-version__div">
                          Version: 11+
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-2-lg nobrowser-col__div">
                  <div className="container">
                    <div className="row justify-content-center">
                      <Img
                        className="browser-logo__img"
                        alt="chrome"
                        src={chromeLogo}
                      />
                    </div>
                    <div className="row justify-content-center top-buffer-row">
                      <div className="container">
                        <div className="row justify-content-center nobrowser-name__div">
                          Chrome
                        </div>
                        <div className="row justify-content-center nobrowser-version__div">
                          Version: 66+
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-2-lg nobrowser-col__div">
                  <div className="container">
                    <div className="row justify-content-center">
                      <Img
                        className="browser-logo__img"
                        alt="firefox"
                        src={firefoxLogo}
                      />
                    </div>
                    <div className="row justify-content-center top-buffer-row">
                      <div className="container">
                        <div className="row justify-content-center nobrowser-name__div">
                          Firefox
                        </div>
                        <div className="row justify-content-center nobrowser-version__div">
                          Version: 60+
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-2-lg nobrowser-col__div">
                  <div className="container">
                    <div className="row justify-content-center">
                      <Img
                        className="browser-logo__img"
                        alt="edge"
                        src={edgeLogo}
                      />
                    </div>
                    <div className="row justify-content-center top-buffer-row">
                      <div className="container">
                        <div className="row justify-content-center nobrowser-name__div">
                          Edge
                        </div>
                        <div className="row justify-content-center nobrowser-version__div">
                          Version: 15+
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
