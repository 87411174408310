import { useEffect, useMemo } from 'react';

import { AxiosError } from 'axios';
import {
  useUserProfilePictureMutation,
  useUserSettingsMutation,
} from '../../queries/Settings';

import { UserInfo } from '../../interfaces/Settings/index';
import {
  GENERAL_SETTINGS_UPDATE_ERROR_MESSAGE,
  SETTINGS_UPDATED,
  UPDATING_SETTINGS,
} from '../../languages/en/settings';
import { useProfileInfoFetchQuery } from '../../queries/Profile';
import { getMonthByNumber } from '../../Utils/date';
import { getErrorMessage } from '../../Utils/message';
import {
  dismissAllToasts,
  showErrorMessage,
  showInfoMessage,
  showSuccessMessage,
} from '../../Utils/toast';

const useSettingsControllerModel = () => {
  const {
    data: profileData,
    isLoading: isCurrentUserInfoLoading,
    isError,
  } = useProfileInfoFetchQuery();

  const {
    isError: isUpdateUserSettingsError,
    mutate: updateUserSettings,
    isLoading: isUpdateUserSettingsLoading,
    isSuccess: isUpdateUserSettingsSuccess,
    error: updateUserSettingsError,
  } = useUserSettingsMutation();
  const {
    isError: isProfilePictureUploadError,
    mutate: updateProfilePictureSettings,
    isLoading: isProfilePictureUploading,
    isSuccess: isProfilePictureUploadSuccess,
    error: updateProfilePictureUploadError,
  } = useUserProfilePictureMutation();

  const currentMember = profileData?.member;

  const currentUserInfo: UserInfo = useMemo(() => {
    return {
      _id: currentMember?.memberId,
      email: currentMember?.email,
      handle: currentMember?.profile.username,
      fullName: `${currentMember?.profile.firstName} ${currentMember?.profile.lastName}`,
      firstName: currentMember?.profile.firstName,
      lastName: currentMember?.profile.lastName,
      jobTitle: currentMember?.profile.jobTitle,
      location: currentMember?.profile.location,
      department: currentMember?.profile.department,
      managers: currentMember?.managerIds,
      reports: currentMember?.reportIds,
      image: currentMember?.profile.image,
      birthday: {
        day: currentMember?.profile.birthday.day,
        month: getMonthByNumber(currentMember?.profile.birthday.month),
      },
      startDate: {
        joiningDay: currentMember?.profile.hiredday.day,
        joiningMonth: getMonthByNumber(currentMember?.profile.hiredday.month),
        joiningYear: currentMember?.profile.hiredday.year,
      },
      timeZone: currentMember?.timeZone,
    };
  }, [currentMember]);

  useEffect(() => {
    if (isUpdateUserSettingsLoading || isProfilePictureUploading) {
      showInfoMessage(UPDATING_SETTINGS);
    }
    if (isUpdateUserSettingsSuccess || isProfilePictureUploadSuccess) {
      dismissAllToasts();
      showSuccessMessage(SETTINGS_UPDATED);
    }
    if (isUpdateUserSettingsError) {
      dismissAllToasts();
      showErrorMessage(
        getErrorMessage(
          updateUserSettingsError as AxiosError,
          GENERAL_SETTINGS_UPDATE_ERROR_MESSAGE,
        ),
      );
    }
    if (isProfilePictureUploadError) {
      dismissAllToasts();
      showErrorMessage(
        getErrorMessage(
          updateProfilePictureUploadError as AxiosError,
          GENERAL_SETTINGS_UPDATE_ERROR_MESSAGE,
        ),
      );
    }
  }, [
    isProfilePictureUploadError,
    isUpdateUserSettingsError,
    isUpdateUserSettingsLoading,
    isUpdateUserSettingsSuccess,
    updateUserSettingsError,
    isProfilePictureUploadSuccess,
    isProfilePictureUploading,
    updateProfilePictureUploadError,
  ]);

  return {
    models: {
      currentUserInfo,
    },
    operations: {
      updateUserSettings,
      updateProfilePictureSettings,
    },
    metadata: {
      isUpdateUserSettingsLoading,
      isUpdateUserSettingsError,
      isUpdateUserSettingsSuccess,
      isError,
      isCurrentUserInfoLoading: isCurrentUserInfoLoading,
    },
  };
};

export default useSettingsControllerModel;
