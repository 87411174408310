import styled from 'styled-components';

import Button from '../../atoms/Button';
import SVGIcon from '../../atoms/SVGIcon';

export const StyledButton = styled(Button)`
  margin-top: 16px;
`;

export const StyledSVGIcon = styled(SVGIcon)`
  margin-left: 20px;
  @media (max-width: 600px) {
    display: none;
  }
`;

export const StyledHelperTextWrapper = styled.div`
  margin-top: 4px;
  padding: 0 12px;
  box-sizing: border-box;
  display: inline-block;
`;
