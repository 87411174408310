import React from 'react';
import ParticipantsList from '../../../../atomic/molecules/ParticipantsList';
import {
  criteriaConditionItems,
  criteriaMenuItems,
} from '../../../../constants/flowsBuilder';
import { ComponentStatus } from '../../../../interfaces/component';
import { PARTICIPANT_LIST_TEXTS } from '../../../../languages/en/flows';
import {
  ADD_PARTICIPANT_CRITERIA_LABEL,
  SHARE_SHEET_MODAL,
} from '../../../../languages/en/flows/builder';
import { Flex } from '../../../../Utils/styles/display';
import FlowsBuilderFilterCriteriaController from '../../../flowsBuilder/FlowsBuilderFilterCriteriaController';
import {
  ParticipantDescription,
  ParticipantsCriteriaContainer,
  ParticipantsListWrapper,
  ParticipationCriteriaTitle,
} from '../styles';

import useShareSheetParticipantsController from './useShareSheetParticipantsController';
import ParticipantsNotFoundImage from '../../../../img/participants_not_found.svg';
import { ParticipantsBuilderBlockData } from '../../../../interfaces/Flow/Builder';

export type ShareSheetParticipantsControllerProps = {
  participantsBlockData: ParticipantsBuilderBlockData;
  updateParticipantsBlockData: (
    updatedContentBlock: ParticipantsBuilderBlockData,
  ) => void;
};

const ShareSheetParticipantsController = (
  props: ShareSheetParticipantsControllerProps,
) => {
  const { models, operations } = useShareSheetParticipantsController(props);
  const {
    isLoading,
    hasNextPage,
    participants,
    participantsCriteriaGroups,
    participantsCount,
    pendingMembersParticipantCount,
  } = models;
  const { fetchNextPage, handleOnCriteriaGroupsChange } = operations;
  return (
    <>
      <ParticipantDescription variant="body3">
        {SHARE_SHEET_MODAL.PARTICIPANTS_BLOCK.DESCRIPTION}
      </ParticipantDescription>
      <Flex alignItems="unset">
        <ParticipantsCriteriaContainer
          flexDirection="column"
          alignItems="flex-start"
        >
          <ParticipationCriteriaTitle variant="body1Medium" color="gray9">
            {SHARE_SHEET_MODAL.PARTICIPANTS_BLOCK.TITLE}
          </ParticipationCriteriaTitle>
          <FlowsBuilderFilterCriteriaController
            hideEveryone={false}
            criteriaGroups={participantsCriteriaGroups}
            criteriaMenuItems={criteriaMenuItems}
            criteriaLabel={ADD_PARTICIPANT_CRITERIA_LABEL}
            criteriaConditionItems={criteriaConditionItems}
            onCriteriaGroupsChange={handleOnCriteriaGroupsChange}
            from="participation"
          />
        </ParticipantsCriteriaContainer>
        <ParticipantsListWrapper flexDirection="column" alignItems="unset">
          {isLoading ? (
            <ParticipantsList
              title={PARTICIPANT_LIST_TEXTS.PARTICIPANTS}
              status={ComponentStatus.LOADING}
              restrictHeight={false}
            />
          ) : (
            <ParticipantsList
              isInEditMode={true}
              title={PARTICIPANT_LIST_TEXTS.PARTICIPANTS}
              participants={participants}
              hasMoreMembers={hasNextPage}
              fetchNextPage={fetchNextPage}
              participantsCount={participantsCount}
              pendingMembersParticipantCount={pendingMembersParticipantCount}
              status={ComponentStatus.LOADED}
              hasNoMatchingParticipants={participantsCount === 0}
              noMatchingParticipantsImage={ParticipantsNotFoundImage}
              noMatchingParticipantsLabel={
                participantsCriteriaGroups
                  ? PARTICIPANT_LIST_TEXTS.NO_MATCHING_PARTICIPANTS
                  : PARTICIPANT_LIST_TEXTS.NO_PARTICIPANTS
              }
              restrictHeight={false}
              stickyHeader
            />
          )}
        </ParticipantsListWrapper>
      </Flex>
    </>
  );
};

export default ShareSheetParticipantsController;
