import { useMutation, useQuery } from 'react-query';
import {
  GET_EXTERNAL_FLOWS,
  SUBMIT_EXTERNAL_FLOWS_INSTANCE,
} from '../../../constants/endpoints';
import { SubmitInstancePayload } from '../../../interfaces/Flow';
import { makeAPICall } from '../../utils';
import { FlowInstanceResponse } from '../interfaces';

export type ExternalFlowDetailsResponse = {
  name: string;
  description: string;
  kind: string;
  icon: {
    kind: string;
    value: string;
  };
  schedule: {
    rule: string;
  };
  endTime: string;
  endTimeInMinutes: number;
  responseSettings: {
    anonymity: {
      state: string;
    };
  };
  creator: {
    name: string;
    logo: string;
  };
};

export type ExternalFlowCreatorDetails = {
  name: string;
  logo: string;
};

type GetExternalFlowsResponse = {
  data: {
    flow: FlowInstanceResponse & ExternalFlowDetailsResponse;
    creator: ExternalFlowCreatorDetails;
    identifier: string;
  };
};

export const useGetExternalFlowsQuery = (flowId: string, enabled = true) => {
  return useQuery<GetExternalFlowsResponse>(
    [GET_EXTERNAL_FLOWS],
    () =>
      makeAPICall(GET_EXTERNAL_FLOWS, undefined, undefined, {
        flowId,
      }),
    {
      enabled,
      retry: false,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    },
  );
};

export const useSubmitExternalFlowInstanceMutation = (
  flowId: string,
  identifier: string,
) => {
  return useMutation(
    (payload: SubmitInstancePayload) =>
      makeAPICall(SUBMIT_EXTERNAL_FLOWS_INSTANCE, payload, undefined, {
        flowId,
        identifier,
      }),
    {
      onSuccess: () => {},
    },
  );
};
