import React from 'react';
import Body from '../Body';
import { HighlightedTextProps } from './interface';
import { HighlightedTextRoot } from './styles';

const HighlightedText = (props: HighlightedTextProps) => {
  const { name, onClick, disableOnClick, offsetKey } = props;
  return (
    <HighlightedTextRoot
      disableOnClick={disableOnClick}
      onClick={disableOnClick ? undefined : onClick}
      aria-label={name}
      data-offset-key={offsetKey}
    >
      <Body variant="body2" color="inherit">
        {name}
      </Body>
    </HighlightedTextRoot>
  );
};

export default HighlightedText;
