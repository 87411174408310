import React, { useMemo, useCallback, memo } from 'react';
import styled from 'styled-components';
import moment from 'moment';

import FilterCollapse from '../../molecules/FilterCollapse';
import CalendarInput from '../../molecules/CalendarInput';
import Body from '../../atoms/Body';
import { START_DATE, END_DATE } from '../../../languages/en/singleWords';
import { NOTHING_TO_FILTER_BY } from '../../../languages/en/flows';

import DateFilterLoader from './Loader';
import { DateFilterProps, DateFilterLoadedProps } from './types';

import { ComponentStatus } from '../../../interfaces/component';

const StyledCalendarInput = styled(CalendarInput)`
  border-radius: 4px;
  overflow: hidden;
`;

const StyledBody = styled(Body)`
  margin-bottom: 8px;
`;

const StyledDateFilterLoader = styled(DateFilterLoader)`
  margin-bottom: 8px;
  :last-child {
    margin-bottom: 0;
  }
`;

const StyledCalendarInputEndDate = styled(StyledCalendarInput)`
  margin-top: 16px;
`;

const CollapseBodyWrapper = styled.div`
  margin-top: 12px;
`;

export type SelectedDateTypes = {
  startDate?: Date;
  endDate?: Date;
};

const nextDay = moment.utc().add(1, 'day').toDate();

const LoadedComponent = (props: DateFilterLoadedProps) => {
  const {
    startDate,
    endDate,
    onStartDateChange,
    onEndDateChange,
    initialStartDate,
    headingText,
    onCollapseClick,
    className,
  } = props;

  const disabledDays = useMemo(() => {
    return {
      before: initialStartDate,
      after: nextDay,
    };
  }, [initialStartDate]);

  const onDisabledDays = useCallback(
    (day: Date) => {
      if (startDate) {
        return day < startDate || day > nextDay;
      }
      if (initialStartDate) {
        return day < initialStartDate || day > nextDay;
      }
      return day > nextDay;
    },
    [initialStartDate, startDate],
  );

  if (initialStartDate === undefined) {
    return (
      <FilterCollapse
        headingText={headingText}
        className={className}
        onCollapseClick={onCollapseClick}
        isCollapseOpen
      >
        <Body variant="body2" color="gray6">
          {NOTHING_TO_FILTER_BY}
        </Body>
      </FilterCollapse>
    );
  }

  return (
    <FilterCollapse
      headingText={headingText}
      isCollapseOpen
      isFiltered={startDate !== undefined || endDate !== undefined}
      onCollapseClick={onCollapseClick}
      className={className}
    >
      <CollapseBodyWrapper>
        <StyledCalendarInput
          name="startDate"
          value={startDate}
          onChange={onStartDateChange}
          placeholder={START_DATE}
          disabledDays={disabledDays}
          showDropdownIcon
        />

        <StyledCalendarInputEndDate
          name="endDate"
          value={endDate}
          onChange={onEndDateChange}
          placeholder={END_DATE}
          disabledDays={onDisabledDays}
          showDropdownIcon
        />
      </CollapseBodyWrapper>
    </FilterCollapse>
  );
};

const DateFilter = (props: DateFilterProps) => {
  switch (props.status) {
    case ComponentStatus.LOADED:
      return <LoadedComponent {...props} />;
    case ComponentStatus.EMPTY: {
      const { headingText, onCollapseClick, className } = props;
      return (
        <FilterCollapse
          headingText={headingText}
          className={className}
          onCollapseClick={onCollapseClick}
          isCollapseOpen
        >
          <Body variant="body2" color="gray6">
            {NOTHING_TO_FILTER_BY}
          </Body>
        </FilterCollapse>
      );
    }
    default: {
      const { loaderCount = 2, headingText, className } = props;
      const items = Array.from(Array(loaderCount)).map((e, i) => i + 1);
      return (
        <div className={className}>
          <StyledBody variant="body1" color="gray7">
            {headingText}
          </StyledBody>
          {items.map((i) => (
            <StyledDateFilterLoader key={i} />
          ))}
        </div>
      );
    }
  }
};

export default memo(DateFilter);
