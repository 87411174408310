import React, { useEffect } from 'react';

import TemplateSelection from '../../../atomic/organism/QuickSetup/TemplateSelection';
import { LoadingParticipationTemplate } from '../../../atomic/pages/ParticipationTemplate';

import { ComponentStatus } from '../../../interfaces/component';
import useTemplateSelectionController from './useTemplateSelectionController';
import FlowsTemplatePreviewController from '../../flows/FlowsTemplatePreviewController';
import { TemplateSelectionControllerProps } from './type';
import { trackTemplateGalleryShowEvent } from '../../../Utils/analytics/templateGallery';
import { TEMPLATE_GALLERY_EVENTS } from '../../../Utils/analytics/constants';

const TemplateSelectionController = (
  props: TemplateSelectionControllerProps,
) => {
  const { models, operations } = useTemplateSelectionController(props);

  const {
    templates,
    categories,
    searchText,
    profileInfo,
    selectedCategory,
    flowTemplateData,
    isFlowTemplateLoading,
    isTemplateSelectionSetupLoading,
  } = models;

  const {
    handleSearchTextChange,
    handleUseTemplateClick,
    handleOnPreviewFlowClick,
    closePreviewTemplateModal,
    handleSelectedCategoryChange,
  } = operations;

  /* Mix panel */
  useEffect(() => {
    trackTemplateGalleryShowEvent({
      show: TEMPLATE_GALLERY_EVENTS.TEMPLATE_GALLERY_SHOW,
      source: 'onBoarding',
    });
  }, []);
  /* Mix panel */

  const renderPreviewTemplateModal = () => {
    if (profileInfo && props.selectedTemplate) {
      if (isFlowTemplateLoading) {
        return (
          <LoadingParticipationTemplate
            flowId=""
            member={profileInfo.member}
            headerStatus={ComponentStatus.LOADING}
            onCloseModal={closePreviewTemplateModal}
            flowHeaderContent={{ DescriptionContent: '', TitleContent: '' }}
          />
        );
      }
      if (flowTemplateData) {
        return (
          <FlowsTemplatePreviewController
            profileInfo={profileInfo}
            flowTemplateData={flowTemplateData}
            onUseTemplateClick={handleUseTemplateClick}
            closePreviewTemplateModal={closePreviewTemplateModal}
          />
        );
      }
    }
    return null;
  };

  return (
    <>
      {renderPreviewTemplateModal()}
      <TemplateSelection
        value={searchText}
        categories={categories}
        meetingName={props.meetingName}
        templateCardItems={templates}
        onChange={handleSearchTextChange}
        selectedCategory={selectedCategory}
        isLoading={isTemplateSelectionSetupLoading}
        onCategoryChange={handleSelectedCategoryChange}
        onPreviewFlowClick={handleOnPreviewFlowClick}
      />
    </>
  );
};

export default TemplateSelectionController;
