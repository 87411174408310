import { AxiosResponse } from 'axios';
import { EmojiData } from 'emoji-mart';
import { useCallback } from 'react';
import { InfiniteData, UseMutateFunction } from 'react-query';
import {
  FeedItemFromAPI,
  FlowPostReactionMutationPayload,
  GetFeedResponse,
  Reaction,
  ReactionMutationPayload,
  SinglePostResponse,
  UpdateReactionAction,
} from '../../interfaces/Feed';
import { PostTypes } from '../../interfaces/Home';
import {
  FlowFeedResponse,
  ReactionRequestPayload,
} from '../../queries/Flows/Feed/interfaces';
import { PaginationResponse } from '../../queries/Flows/interfaces';
import { GetProfileInfoResponse } from '../../queries/Profile';
import { mapEmojiToReaction } from '../../Utils/flows/feeds';
import {
  doesPostReactionsHasReactionFromSameUser,
  getPostFromFeed,
  hasReactionFromSameUser,
} from '../../Utils/home/feeds/reactions';
import { serializeReactionTogglePayload } from '../../Utils/serializers/feed';

type UseFeedReactionsProps = {
  feedData: FeedItemFromAPI[];
  profileData?: GetProfileInfoResponse;
  mutateFlowPostReaction?: UseMutateFunction<
    AxiosResponse<any>,
    unknown,
    FlowPostReactionMutationPayload,
    {
      previousData: InfiniteData<GetFeedResponse> | undefined;
    }
  >;
  mutatePostReaction: UseMutateFunction<
    AxiosResponse<any>,
    unknown,
    ReactionMutationPayload,
    {
      previousData:
        | InfiniteData<GetFeedResponse>
        | SinglePostResponse
        | undefined;
    }
  >;
};

export const useFeedReactions = (props: UseFeedReactionsProps) => {
  const { profileData, feedData, mutateFlowPostReaction, mutatePostReaction } =
    props;

  const onReactionSet = useCallback(
    (emoji: EmojiData, contentId: string) => {
      if (feedData && profileData?.member) {
        const post = getPostFromFeed(feedData, contentId);

        if (post) {
          let reactions;
          if (post.type === PostTypes.FLOW) {
            reactions = post.flowResponse?.reactions || [];
          } else {
            reactions = post.reactions;
          }
          if (
            doesPostReactionsHasReactionFromSameUser(
              reactions,
              emoji,
              profileData,
            )
          ) {
            return;
          }
          if (post.type === PostTypes.FLOW && mutateFlowPostReaction) {
            mutateFlowPostReaction({
              flowId: post.flowResponse?.flow.flowId || '',
              responseId: post.flowResponse?.responseId || '',
              ...serializeReactionTogglePayload(
                mapEmojiToReaction(emoji),
                post.postID,
                UpdateReactionAction.SET,
                profileData?.member,
              ),
              // flowFeedsSort: feedsSortValue,
            });
          } else {
            mutatePostReaction(
              serializeReactionTogglePayload(
                mapEmojiToReaction(emoji),
                contentId,
                UpdateReactionAction.SET,
                profileData?.member,
              ),
            );
          }
        }
      }
    },
    [feedData, profileData, mutateFlowPostReaction, mutatePostReaction],
  );

  const onReactionUnSet = useCallback(
    (reaction: Reaction, contentId: string) => {
      let action = UpdateReactionAction.UNSET;

      if (feedData && profileData?.member) {
        const post = getPostFromFeed(feedData, contentId);
        if (post) {
          if (!hasReactionFromSameUser(reaction, profileData)) {
            action = UpdateReactionAction.SET;
          }
          if (post.type === PostTypes.FLOW && mutateFlowPostReaction) {
            mutateFlowPostReaction({
              flowId: post.flowResponse?.flow.flowId || '',
              responseId: post.flowResponse?.responseId || '',
              ...serializeReactionTogglePayload(
                reaction.reaction,
                post.postID,
                action,
                profileData?.member,
              ),
              // flowFeedsSort: feedsSortValue,
            });
          } else {
            mutatePostReaction(
              serializeReactionTogglePayload(
                reaction.reaction,
                contentId,
                action,
                profileData?.member,
              ),
            );
          }
        }
      }
    },
    [feedData, mutateFlowPostReaction, mutatePostReaction, profileData],
  );

  return {
    onReactionSet,
    onReactionUnSet,
  };
};

type UseFlowFeedReactionsProps = {
  flowFeedData: FlowFeedResponse[];
  profileData?: GetProfileInfoResponse;
  flowId: string;
  mutateFlowPostReaction: UseMutateFunction<
    AxiosResponse<any>,
    unknown,
    ReactionRequestPayload,
    {
      previousData:
        | InfiniteData<PaginationResponse<FlowFeedResponse>>
        | FlowFeedResponse;
    }
  >;
};
export const useFlowFeedReactions = (props: UseFlowFeedReactionsProps) => {
  const { flowFeedData, profileData, mutateFlowPostReaction } = props;

  const onReactionSet = useCallback(
    (emoji: EmojiData, responseId: string) => {
      const flow = flowFeedData.find((x) => x.responseId === responseId);
      if (
        flow &&
        doesPostReactionsHasReactionFromSameUser(
          flow.reactions,
          emoji,
          profileData,
        )
      ) {
        return;
      }

      mutateFlowPostReaction({
        responseId,
        action: 'set',
        payload: mapEmojiToReaction(emoji),
        user: {
          ...profileData?.member.profile,
          memberID: profileData?.member.memberId,
        },
      });
    },
    [flowFeedData, profileData, mutateFlowPostReaction],
  );

  const onReactionUnSet = useCallback(
    (reaction: Reaction, responseId: string) => {
      let action = 'unset';
      const hasCurrentUserReactedReactions = () => {
        return (r: { memberID: string | undefined }) =>
          r.memberID === profileData?.member.memberId;
      };

      if (!reaction.members.some(hasCurrentUserReactedReactions())) {
        action = 'set';
      }

      mutateFlowPostReaction({
        action,
        responseId,
        payload: reaction.reaction,
        user: {
          ...profileData?.member.profile,
          memberID: profileData?.member.memberId,
        },
      });
    },
    [profileData, mutateFlowPostReaction],
  );

  return {
    onReactionSet,
    onReactionUnSet,
  };
};
