/* eslint-disable max-len */
import React, { memo, useCallback } from 'react';
import styled from 'styled-components';
import Rating from '@material-ui/lab/Rating';
import { StarRatingProps } from './interfaces';
import SVGIcon from '../SVGIcon';
import ThemeV2 from '../../../componentsV2/theme';

export const StyledRating = styled(Rating)`
  & .MuiRating-icon {
    & svg {
      margin: 0px 4px;
    }
    & svg path {
      color: ${ThemeV2.palette.gold2};
    }
  }
  & .MuiRating-iconHover {
    & svg path {
      color: ${ThemeV2.palette.gold5};
    }
  }
  & .MuiRating-iconFilled {
    & svg path {
      color: ${ThemeV2.palette.gold6};
    }
  }

  label {
    margin-bottom: 0;
  }
`;

const StarRating = (props: StarRatingProps) => {
  const {
    name,
    value,
    handleRatingChange,
    icon = 'star',
    readOnly,
    size = '32px',
  } = props;

  const onRatingChange = useCallback(
    (event, newRating) => {
      handleRatingChange(newRating);
    },
    [handleRatingChange],
  );

  return (
    <StyledRating
      name={name}
      value={value}
      onChange={onRatingChange}
      icon={<SVGIcon size={size} icon={icon} />}
      readOnly={readOnly}
    />
  );
};

const MemoizedStarRating = memo(StarRating);
MemoizedStarRating.displayName = 'StarRating';

export default MemoizedStarRating;
