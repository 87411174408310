import { useState, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import {
  MakeAPICallInterface,
  useGenericAPIQuery,
  useJobStatusQuery,
} from '../../queries/Settings/identityManager';
import configureAssemblyStatus from '../../Utils/identity/configureAssemblyStatus';

interface UseJobPollingV2Props {
  preJobAPI: MakeAPICallInterface;
  postJobAPI?: MakeAPICallInterface;
  enabled?: boolean;
}

function useJobPollingV2<T>(props: UseJobPollingV2Props) {
  const queryClient = useQueryClient();
  const { preJobAPI, postJobAPI, enabled = false } = props;
  const [jobId, setJobId] = useState<string | undefined>(undefined);
  const [isPostJobEnabled, setIsPostJobEnabled] = useState<boolean>(false);
  const [jobFailureError, setJobFailureError] = useState<
    { message: string } | undefined
  >();

  const {
    data: preJobAPIData,
    isLoading: isPreJobAPILoading,
    error: preJobAPIError,
  } = useGenericAPIQuery(preJobAPI, enabled);

  const {
    data: postJobAPIData,
    isLoading: isPostJobAPILoading,
    error: postJobAPIError,
    refetch: refetchPostJobAPI,
  } = useGenericAPIQuery(postJobAPI, isPostJobEnabled);

  /* useEffect(() => {
    if (enabled) {
      refetchPreJobAPI();
    }
  }, [enabled, refetchPreJobAPI]); */

  useEffect(() => {
    if (preJobAPIData && preJobAPIData.job) {
      const {
        job: { id },
      } = preJobAPIData;
      setJobId(id);
    }
  }, [preJobAPIData]);

  const { data: jobData, error: jobError } = useJobStatusQuery(jobId);

  useEffect(() => {
    if (jobData) {
      const {
        data: { status },
      } = jobData;
      if (status === configureAssemblyStatus.SUCCESS) {
        setJobId(undefined);
        if (postJobAPI) {
          setIsPostJobEnabled(true);
          refetchPostJobAPI();
        }
      } else if (status === configureAssemblyStatus.ERROR) {
        setJobId(undefined);
        setJobFailureError({ message: status });
      }
    }
  }, [isPostJobEnabled, jobData, refetchPostJobAPI, postJobAPI]);

  useEffect(() => {
    if (jobError) {
      setJobId(undefined);
    }
  }, [jobError]);

  useEffect(() => {
    return () => {
      queryClient.removeQueries(preJobAPI?.apiName);
      queryClient.removeQueries(postJobAPI?.apiName);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading: isPreJobAPILoading || isPostJobAPILoading,
    isJobRunning: jobId !== undefined,
    error: preJobAPIError || postJobAPIError || jobError || jobFailureError,
    data: postJobAPIData as T,
    isJobStatusSuccessful: Boolean(
      jobData?.data?.status === configureAssemblyStatus.SUCCESS,
    ),
  };
}

export default useJobPollingV2;
