import styled from 'styled-components';
import { Flex } from '../../../../Utils/styles/display';
import Body from '../../../atoms/Body';
import ThemeV2 from '../../../../componentsV2/theme';
import Button from '../../../atoms/Button';

export const Wrapper = styled.div`
  padding: 24px;
  width: 100%;
`;
export const Block = styled.div`
  margin-bottom: 24px;
`;
export const BlockTitle = styled(Body)`
  margin: 8px 8px 8px 0;
`;

export const CreatedByText = styled(Body)``;

export const ErrorTitle = styled(Body)`
  margin-top: 20px;
`;
export const ErrorSubtitle = styled(Body)`
  margin-top: 8px;
  text-align: center;
`;

export const Header = styled(Flex)`
  margin-bottom: 16px;
`;

export const Heading = styled(Body)`
  margin-left: 4px;
`;

export const DescriptionPlaceholderText = styled(Body)`
  height: 20px;
  padding: 4px 8px;
  border: 0.5px solid ${ThemeV2.palette.white};
  border-radius: 4px;
  &:hover {
    border: 0.5px solid ${ThemeV2.palette.gray3};
    border-radius: 4px;
  }
`;

export const TextAreaWrapper = styled.div<{
  isDisabled: boolean;
}>`
  border: 0.5px solid ${ThemeV2.palette.white};
  border-radius: 4px;
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'auto')};
`;

export const AssigneeWrapper = styled.div<{
  isDisabled: boolean;
}>`
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'auto')};
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};
`;

export const ShowMoreButton = styled(Button)`
  margin-top: 8px;
  & path {
    fill: ${ThemeV2.palette.geekBlue6};
  }
`;

export const Description = styled.div`
  &:hover {
    color: ${ThemeV2.palette.gray9};
    background: ${ThemeV2.palette.gray3};
    border-radius: 4px;
  }
`;
