import theme from '../../../componentsV2/theme';

export const getColorById = (id = '') => {
  const defaultColor = theme.palette.geekBlue2;
  if (!id) {
    return defaultColor;
  }
  const lastLetterIndex = id.length - 1;
  const lastLetterCharCode = id.charCodeAt(lastLetterIndex);
  let color = theme.palette.dayBreakBlue2;
  switch (lastLetterCharCode) {
    case 97:
    case 113:
    case 103:
      color = theme.palette.dayBreakBlue2;
      break;
    case 119:
    case 49:
    case 111:
      color = theme.palette.dustRed2;
      break;
    case 98:
    case 114:
    case 104:
      color = theme.palette.volcano2;
      break;
    case 120:
    case 50:
    case 57:
      color = theme.palette.orange2;
      break;
    case 99:
    case 115:
    case 106:
      color = theme.palette.gold2;
      break;
    case 122:
    case 52:
    case 112:
      color = theme.palette.sunriseYellow;
      break;
    case 100:
    case 116:
    case 105:
      color = theme.palette.lime2;
      break;
    case 121:
    case 51:
    case 108:
      color = theme.palette.green2;
      break;
    case 101:
    case 117:
    case 107:
      color = theme.palette.cyan2;
      break;
    case 53:
    case 110:
    case 56:
      color = theme.palette.geekBlue2;
      break;
    case 54:
    case 102:
    case 118:
      color = theme.palette.purple2;
      break;
    case 48:
    case 109:
    case 55:
      color = theme.palette.magenta2;
      break;
    default:
      color = defaultColor;
      break;
  }

  return color;
};
