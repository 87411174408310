import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  GET_NOTIFICATIONS,
  MARK_NOTIFICATION_AS_READ,
} from '../../constants/endpoints';
import { makeAPICall, makeAPICallWithDataReturn } from '../utils';
import { Notification } from '../../interfaces/Notifications';

export interface GetNotificationsResponse {
  data: {
    notificationCount: number;
    notifications: Notification[];
  };
}

type MarkNotificationsAsReadContext =
  | {
      previousNotificationData: GetNotificationsResponse | undefined;
    }
  | undefined;

export const useNotificationFetchQuery = () => {
  return useQuery(
    GET_NOTIFICATIONS,
    () => makeAPICallWithDataReturn(GET_NOTIFICATIONS),
    {
      staleTime: 60 * 5 * 1000,
      select: (res: GetNotificationsResponse) => res.data,
    },
  );
};

export const useMarkNotificationsAsReadMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (notificationId?: string) =>
      makeAPICall(
        `${MARK_NOTIFICATION_AS_READ}`,
        undefined,
        notificationId
          ? {
              notificationIds: notificationId,
            }
          : undefined,
      ),
    {
      onMutate: (notificationId) => {
        const previousNotificationData: GetNotificationsResponse | undefined =
          queryClient.getQueryData(GET_NOTIFICATIONS);
        if (previousNotificationData) {
          let updatedNotifications: Notification[] = [];
          let notificationCount: number;
          const { notifications } = previousNotificationData.data;
          if (notificationId) {
            const selectedNotificationIndex = notifications.findIndex(
              (i) => i._id === notificationId,
            );
            if (selectedNotificationIndex >= 0) {
              updatedNotifications = [...notifications];
              updatedNotifications.splice(selectedNotificationIndex, 1, {
                ...notifications[selectedNotificationIndex],
                isRead: true,
              });
            }
            notificationCount =
              previousNotificationData.data.notificationCount - 1;
          } else {
            updatedNotifications = notifications.map((notification) => ({
              ...notification,
              isRead: true,
            }));
            notificationCount = 0;
          }
          queryClient.setQueryData(GET_NOTIFICATIONS, {
            ...previousNotificationData,
            ...previousNotificationData.data,
            data: {
              ...previousNotificationData.data,
              notifications: updatedNotifications,
              notificationCount,
            },
          });
        }
        return { previousNotificationData };
      },
      onError: (err, variables, context: MarkNotificationsAsReadContext) => {
        if (context?.previousNotificationData) {
          queryClient.setQueryData(
            GET_NOTIFICATIONS,
            context.previousNotificationData,
          );
        }
      },
    },
  );
};
