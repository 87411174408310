import styled, { css } from 'styled-components';
import ThemeV2 from '../../../componentsV2/theme';

export const HomeDrawerWrapper = styled.div<{ isKioskTurnedOn: boolean }>`
  width: 100%;
  height: ${({ isKioskTurnedOn }) =>
    isKioskTurnedOn ? 'calc(100vh - 114px)' : 'calc(100vh - 72px)'};
`;

export const DrawerBottomContent = styled.div<{
  isHoveredWhenCollapsed: boolean;
  onScroll: (...args: any[]) => any;
}>`
  padding: 16px 0 40px 0;
  overflow-x: hidden;

  ${({ isHoveredWhenCollapsed }) =>
    isHoveredWhenCollapsed &&
    css`
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04),
        0px 3px 4px rgba(0, 0, 0, 0.02), 0px 1px 5px rgba(0, 0, 0, 0.12);
      padding: 16px 16px 40px 0;
    `}

  @media (min-width: ${ThemeV2.breakPoints.md}) {
    height: 100%;
    overflow-y: auto;
  }
`;
