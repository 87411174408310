import styled from 'styled-components';
import Popover from '@material-ui/core/Popover';
import DropdownItem from '../../molecules/DropdownListItem_V2';
import ThemeV2 from '../../../componentsV2/theme';

export const Wrapper = styled.div`
  display: flex;
`;

export const LeftColumn = styled.div`
  width: 190px;
  margin: 8px 0;
`;

export const RightColumn = styled.div<{ show: boolean }>`
  display: ${({ show }) => !show && 'none'};
  min-width: 355px;
  padding: 20px;
  border-left: 1px solid ${ThemeV2.palette.gray4};
`;

export const StyledDropdownItem = styled(DropdownItem)`
  padding: 5px 8px;
  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const StyledPopover = styled(Popover)`
  .MuiPopover-paper {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), 0px 3px 4px rgba(0, 0, 0, 0.02),
      0px 1px 5px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
  }
`;
