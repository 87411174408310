import React from 'react';

import { ProfileFeedFilterProps } from './types';
import { ComponentStatus } from '../../../../interfaces/component';

import LoadedComponent from './LoadedComponent';
import LoadingComponent from '../LoadingComponent';
import EmptyComponent from '../EmptyComponent';
import ErrorComponent from '../ErrorComponent';

const ProfileFeedFilter = (props: ProfileFeedFilterProps) => {
  switch (props.status) {
    case ComponentStatus.LOADED:
      return <LoadedComponent {...props} />;
    case ComponentStatus.LOADING:
      return <LoadingComponent isProfileFeedFilterLoading />;
    case ComponentStatus.EMPTY:
      return <EmptyComponent isProfileFeedFilterEmpty />;
    default:
      return <ErrorComponent />;
  }
};

export default ProfileFeedFilter;
