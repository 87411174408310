import { UNASSIGNED_MEMBER_ID } from '../../../../constants/notebook';
import {
  Assignee,
  AssignmentOperations,
} from '../../../../interfaces/notebook';

export const getAssignmentOperation = (
  oldAssignee: Assignee,
  newAssignee: Assignee,
): AssignmentOperations => {
  if (newAssignee?.memberID === UNASSIGNED_MEMBER_ID) return 'unassign';
  else if (oldAssignee?.memberID !== newAssignee?.memberID) return 'reassign';
  return null;
};
