import { FlowItemResponse } from '../../../queries/Flows/interfaces';
import { mapHexCodeToEmoticon } from '../../../Utils/mappers';

export const mapFlowsArchivedResponseToNavItems = (flow: FlowItemResponse) => {
  return {
    id: flow.flowId,
    emoticon: mapHexCodeToEmoticon(flow.icon.value),
    isActive: false,
    label: flow.name,
    suffixIcon: 'revert',
    isArchived: true,
    isFlowOwner: flow.isFlowOwner || false,
    isFlowParticipant: flow.isFlowParticipant || false,
    isFlowViewer: flow.isFlowViewer || false,
  };
};
