import React from 'react';
import { FeedPostReactionsProps } from './interface';
import { FeedPostReactionsRoot } from './styles';
import { StyledToggleButton } from '../ReactionsBar/styles';
import { FEED_POST_REACTIONS_COMMENT_TEXT } from '../../../languages/en/home';
import ReactionBar from '../ReactionsBar';
import Divider from '../../atoms/Divider';
import ThemeV2 from '../../../componentsV2/theme';

const FeedPostReactions = (props: FeedPostReactionsProps) => {
  const {
    currentUserId,
    reactions,
    isPickerDisabled,
    onCommentButtonClick,
    onReactButtonClick,
    onReactionToggleClick,
    onEmoticonSelect,
    contentId,
  } = props;

  return (
    <FeedPostReactionsRoot>
      <Divider isFullWidth color={ThemeV2.palette.gray4} marginBottom="8px" />
      <StyledToggleButton onClick={onCommentButtonClick} icon="add-comment">
        {FEED_POST_REACTIONS_COMMENT_TEXT}
      </StyledToggleButton>
      <ReactionBar
        currentUserId={currentUserId}
        reactions={reactions}
        isPickerDisabled={isPickerDisabled}
        onReactButtonClick={onReactButtonClick}
        onReactionToggleClick={onReactionToggleClick}
        onEmoticonSelect={onEmoticonSelect}
        contentId={contentId}
      />
    </FeedPostReactionsRoot>
  );
};

const MemoizedFeedPostReactions = React.memo(FeedPostReactions);
MemoizedFeedPostReactions.displayName = 'FeedPostReactions';
export default MemoizedFeedPostReactions;
