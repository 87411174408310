import React, { useMemo } from 'react';
import styled from 'styled-components';
import Body from '../../../atomic/atoms/Body';
import Button from '../../../atomic/atoms/Button';
import { ParticipationFlowHeaderContent } from '../../../atomic/molecules/FlowsParticipationHeader';
import {
  StyledBodyFlowName,
  StyledBodyHeaderTitle,
  StyledEmoticon,
} from '../../../atomic/molecules/FlowsParticipationHeader/styles';
import { LoadedParticipationTemplate as ParticipationTemplate } from '../../../atomic/pages/ParticipationTemplate';
import { ComponentStatus } from '../../../interfaces/component';
import { FlowVariants } from '../../../interfaces/Flow';
import { ARE_PREVIEWING } from '../../../languages/en/flows/participation';
import { YOU } from '../../../languages/en/singleWords';
import { FlowInstanceResponse } from '../../../queries/Flows/interfaces';
import { mapHexCodeToEmoticon } from '../../../Utils/mappers';
import { Flex } from '../../../Utils/styles/display';
import useUppyInstances from '../ParticipationFlowController/useUppyInstances';
import { FlowsTemplatePreviewControllerProps } from './interface';
import usePreviewTemplateController from './usePreviewTemplateController';
import { renderParticipationSlide, RenderSlideInfoObject } from '../utils';
import { GET_FILE_UPLOAD_SIGNED_URL } from '../../../constants/endpoints';

const StyledLeftFooterButton = styled(Button)`
  margin-right: 8px;
`;

const FlowsTemplatePreviewController = (
  props: FlowsTemplatePreviewControllerProps,
) => {
  const {
    flowTemplateData,
    onUseTemplateClick,
    closePreviewTemplateModal,
    profileInfo,
  } = props;

  const {
    operations: { goToNextStep, onStepChange, setFieldValue, goToPreviousStep },
    models: {
      blockErrors,
      containerRef,
      currentStep,
      fieldErrors,
      stepData,
      touched,
      values,
      dynamicBlockData,
      blocks,
    },
  } = usePreviewTemplateController(props);
  const headerContent: ParticipationFlowHeaderContent = useMemo(() => {
    const {
      templateDetails: {
        description: { icon },
        title,
      },
    } = flowTemplateData;
    return {
      TitleContent: (
        <Flex>
          <StyledBodyHeaderTitle color="gray8" variant="body2">
            <Body color="geekBlue6" inline variant="body2Medium">
              {`${YOU} `}
            </Body>
            {`${ARE_PREVIEWING} `}
          </StyledBodyHeaderTitle>
          {icon ? (
            <StyledEmoticon>{mapHexCodeToEmoticon(icon.value)}</StyledEmoticon>
          ) : (
            <span />
          )}
          <StyledBodyFlowName
            color="geekBlue6"
            inline
            variant="body2Medium"
            addMargin={Boolean(!icon)}
          >
            {title}
          </StyledBodyFlowName>
        </Flex>
      ),
      DescriptionContent: '',
    };
  }, [flowTemplateData]);
  const footerButtons = useMemo(
    () => [
      <StyledLeftFooterButton
        size="large"
        key="1"
        color="secondary"
        onClick={closePreviewTemplateModal}
      >
        Back to browsing
      </StyledLeftFooterButton>,
      <Button size="large" key="2" onClick={onUseTemplateClick}>
        Use this template
      </Button>,
    ],
    [closePreviewTemplateModal, onUseTemplateClick],
  );

  const flowInstance: FlowInstanceResponse = useMemo(
    () => ({
      flowId: '',
      instanceId: '',
      state: '',
      blocks,
    }),
    [blocks],
  );

  const previewContent = useMemo(
    () => ({
      icon: flowTemplateData.templateDetails.description.icon?.value || '',
      title: flowTemplateData.templateDetails.title,
      isTemplate: true,
    }),
    [flowTemplateData],
  );

  const remainingAllowance = profileInfo.member.pointsLeftThisCycle;
  const uppyInstances = useUppyInstances(
    flowInstance.blocks,
    {
      flowId: flowInstance.flowId,
      instanceId: flowInstance.instanceId,
    },
    GET_FILE_UPLOAD_SIGNED_URL,
  );

  const renderSlideInfo: RenderSlideInfoObject = {
    blockErrors,
    currentStep,
    fieldErrors,
    flowInstance,
    goToNextStep,
    onStepChange,
    values,
    profileInfo,
    remainingAllowance,
    setFieldValue,
    stepData,
    touched,
    uppyInstances,
    isPreviewFlow: true,
  };

  return (
    <ParticipationTemplate
      currentStep={currentStep}
      goToNextStep={goToNextStep}
      goToPreviousStep={goToPreviousStep}
      isNextButtonDisabled={currentStep === stepData.length - 1}
      member={profileInfo.member}
      isPreviousButtonDisabled={currentStep === 0}
      onCloseModal={closePreviewTemplateModal}
      footerButtons={footerButtons}
      onStepChange={onStepChange}
      dynamicBlockData={dynamicBlockData}
      singleSlideContainerRef={containerRef}
      flowVariant={FlowVariants.PREVIEW_FLOW}
      flowId=""
      flowHeaderContent={headerContent}
      headerStatus={ComponentStatus.LOADED}
      previewContent={previewContent}
    >
      {renderParticipationSlide(renderSlideInfo)}
    </ParticipationTemplate>
  );
};

export default FlowsTemplatePreviewController;
