/* eslint-disable max-len */

import pluralize from 'pluralize';
import { NotebookTabs } from '../../../controllers/notebook/NotebookPageController/useNotebookPageController/types';
import { TaskCategories } from '../../../interfaces/notebook';
import { TASK } from '../singleWords';

export const TODAY = 'today';
export const OVERDUE = 'overdue';
export const UPCOMING = 'upcoming';
export const UNSCHEDULED = 'unscheduled';
export const COMPLETED = 'completed';
export const ARCHIVED = 'archived';
export const UNARCHIVE = 'unarchive';
export const TOMORROW = 'tomorrow';

export const TASK_CREATED_BY = 'Task • Created by';
export const DRAG_TOOLTIP_LABEL =
  '🔥Hot tip: you can move tasks within or between sections';

export const PLACEHOLDER_NOTE =
  'Start drafting a script for the sequence of events that you want to flow, users might pull you in bo different directions. I want to do something where this is going to go onto 3 different lines here look at that';

export const MOVE_TO = 'Move to';

export const NEW_TASK_TITLE = 'Type here to add a new task...';

export const NEW_TASK_DUE_DATE_LABEL = {
  [TaskCategories.TODAY]: 'Due date will be automatically be set today',
  [TaskCategories.OVERDUE]: 'Tasks for which the due date has already passed',
  [TaskCategories.UPCOMING]: 'Tasks that are coming up soon',
  [TaskCategories.UNSCHEDULED]: 'Tasks that are not pressing',
  [TaskCategories.COMPLETED]: '',
  [TaskCategories.ARCHIVED]: '',
};

export const NO_TASKS_LABELS = {
  [NotebookTabs.myWork]: {
    [TaskCategories.TODAY]: '🙌 You have nothing due today.',
    [TaskCategories.OVERDUE]: '🎉 You have nothing overdue.',
    [TaskCategories.UPCOMING]: '😎 You have nothing upcoming planned.',
    [TaskCategories.UNSCHEDULED]: '🍃 You have nothing unscheduled.',
    [TaskCategories.COMPLETED]:
      'All completed tasks will move here! Mark a task as completed by clicking the button next to your task to check it off.',
    [TaskCategories.ARCHIVED]:
      'Any tasks you won’t get to, but don’t want to delete will go here. Move a task to this section by clicking the 3-dotted menu next to your task.',
  },
  [NotebookTabs.ato]: {
    [TaskCategories.TODAY]: '🙌 No tasks assigned to other people due today.',
    [TaskCategories.OVERDUE]: '🎉 No overdue tasks assigned to other people.',
    [TaskCategories.UPCOMING]: '😎 No upcoming tasks assigned to other people.',
    [TaskCategories.UNSCHEDULED]:
      '🍃 No unscheduled tasks assigned to other people.',
    [TaskCategories.COMPLETED]:
      'All completed tasks will move here! You or the person assigned to the task to can mark it as completed by clicking the button next to the task to check it off.',
    [TaskCategories.ARCHIVED]:
      'Any task you or the person assigned to the task won’t get to, but don’t want to delete will go here. Move a task to this section by clicking the 3-dotted menu next to the task.',
  },
};

export const TASK_CREATION_ERROR = 'Task failed to save,';
export const TRY_AGAIN = 'try again';

export const INITIAL_TASK_CHECKBOX_TOOLTIP =
  'Finish creating your note to mark it as completed.';

export const SHOW_MORE = 'Show more';
export const SET_DATE = 'Set date';
export const NOTEBOOK_SECTIONAL_ERROR_TEXT =
  'Failed to load tasks. Try reloading the page to fix this error.';

export const MARK_INCOMPLETE = 'Mark incomplete';
export const CANCEL = 'Cancel';
export const ARCHIVE_TASK = 'Archive task';
export const DELETE_TASK = 'Delete task';
export const UNARCHIVE_TASK = 'Unarchive task';
export const MARK_INCOMPLETE_BODY_TEXT =
  'If you mark this task as incomplete, it will move out of Completed and into a new section. Where would you like to move it?';
export const ARCHIVE_BODY_TEXT =
  "Archiving this task will move it to 'Archived' in your Notebook. If you’d like to recover this task, you can move it out of your ‘Archived’ section. You can delete it permanently from there.";
export const UNARCHIVE_BODY_TEXT =
  'If you unarchive this task, it will move out of Archived  and into a new section. Where would you like to move it?';
export const DELETE_BODY_TEXT =
  'Once you delete this task, you will not be able to recover it. This is a permanent action. If you might want this task later, leave it in the ‘Archived’ section. Would you like to delete this task?';

export const DUE_DATE_TODAY = 'Due date: Today';
export const SET_A_DUE_DATE = 'Set due date';
export const REMOVE_DATE = '- Remove date';

export const getSectionalBannerText = (
  taskType: TaskCategories,
  tabType: NotebookTabs,
  total: number,
) => {
  if (taskType === TaskCategories.COMPLETED) {
    if (tabType === NotebookTabs.myWork) {
      return `You’re a rockstar! You’ve completed ${total} ${pluralize(
        TASK,
        total,
      )}...so far. Keep it up!`;
    }
    return `Your teammates are rockstars! They've completed ${total} ${pluralize(
      TASK,
      total,
    )} you've assigned to them!`;
  }

  if (taskType === TaskCategories.ARCHIVED) {
    if (tabType === NotebookTabs.myWork) {
      return `You have ${total} archived ${pluralize(TASK, total)}.`;
    }
    return `Your teammates have archived ${total} ${pluralize(
      TASK,
      total,
    )} you've assigned to them.`;
  }

  return '';
};

export const getReassignModalTextInSameTab = (
  assignee: string,
  previousAssignee: string,
) => {
  return `You’re assigning this to ${assignee}. This action will remove this task ${previousAssignee}’s Notebook. Please confirm you want to reassign this task to ${assignee}.`;
};

export const getUnassignModalTextInSameTab = (previousAssignee: string) => {
  return `You’re unassigning this task. This action will remove this task ${previousAssignee}’s Notebook. Please confirm you want to unassign this task.`;
};

export const getReassignModalText = (
  isCreatedByMe: boolean,
  assignee: string,
  creator?: string,
) => {
  if (isCreatedByMe)
    return `You’re assigning this to ${assignee}. This will move the task out of your “My Work” tab and into your “Assigned by Me” tab. Please confirm you want to reassign this task.`;

  return `You’re assigning this to ${assignee}. This will move the task out of your “My Work” tab and you will no longer be able to see it. ${creator}, who assigned this task to you, will see that you’ve taken this action. Are you sure you want to do this?`;
};

export const getUnassignModalText = (
  isCreatedByMe: boolean,
  creator?: string,
) => {
  if (isCreatedByMe)
    return `You’re unassigning this task. This will move the task out of your “My Work” tab and into your “Assigned by Me” tab. Please confirm you want to unassign this task.`;

  return `You’re unassigning this task. This will move the task out of your “My Work” tab and you will no longer be able to see it. ${creator}, who assigned this task to you, will see that you’ve taken this action. Are you sure you want to do this?`;
};

export const NOTEBOOK_TASK_DETAILS_HEADER = 'Task Details';
export const NOTEBOOK_TASK_DETAILS_URL_DETAILS = 'Copy task URL';
export const CREATED_BY = 'Task created by';
export const TASK_HISTORY = 'Task History';
export const DUE_DATE = 'Due Date';
export const ASSIGNEE = 'Assignee';
export const ARCHIVED_DATE = 'Archived Date';
export const COMPLETED_DATE = 'Completed Date';
export const NOTEBOOK_TASK_DETAILS_ERROR = {
  TITLE: 'Whoops! Something went wrong.',
  SUB_TITLE: 'Reload the page to try again',
};
export const NOTEBOOK_TASK_DETAILS_ACCESS_ERROR = {
  TITLE: 'Sorry, you don’t have visibility',
  SUB_TITLE: 'This task is only visibile to its creator and assignee.',
};
export const DELETED = 'Deleted';
export const DELETED_TASK_BANNER = 'This task was deleted.';

export const MY_WORK = 'My Work';
export const ASSIGNED_BY_ME = 'Assigned By Me';
export const DESCRIPTION_PLACEHOLDER = 'Enter a description';

export const NO_ASSIGNEES_FOUND = 'No assignees found';
export const UNASSIGN_TASK = 'Unassign task';
export const REASSIGN_TASK = 'Reassign task';
export const ASSIGNMENT_MODAL_FALLBACK =
  'Unassigning a task you created for yourself. Do you wish to proceed?';
export const ASSIGNEE_ME = '(me)';

export const NOTEBOOK_ASSIGNMENT_SUCCESS_TOAST =
  'Task successfully reassigned.';

export const getUnreadTasksCountText = (count: number) => {
  return `Assigned ${count} new ${pluralize('task', count)}`;
};

export const ABOUT_NOTEBOOK_TITLE = 'About Notebook';

export const ABOUT_NOTEBOOK_DESCRIPTION_1 =
  'Keep track of your work and assign tasks to others. Use the “My Work” tab to see everything you have to do, and use the “Assigned by Me” tab to track what you’ve assigned to others.';
export const ABOUT_NOTEBOOK_DESCRIPTION_2 =
  'Click on a task to see the task’s details, add a description, or see the task history';

export const getArchiveAndDeleteModalTextForAssignments = (
  isTaskCreatedByCurrentUser: boolean,
  isTaskAssigneeCreator: boolean,
  creator: string,
  assignee: string,
) => {
  if (isTaskCreatedByCurrentUser)
    return isTaskAssigneeCreator || assignee.toLocaleLowerCase() === ''
      ? ARCHIVE_BODY_TEXT
      : `Archiving a task assigned to you will move it to the 'Archived' section for yourself and the assignee, ${assignee}. If you’d like to recover this task, you or ${assignee} can move it out of the ‘Archived’ section. Either can delete it permanently from there.`;
  return `Archiving a task assigned to you will move it to the 'Archived' section for yourself and the creator, ${creator}. If you’d like to recover this task, you or ${creator} can move it out of the ‘Archived’ section. Either can delete it permanently from there.`;
};

export const NOTEBOOK_NAVIGATION_MODAL_LABELS = {
  HEADING: 'Leaving notebook?',
  NOTEBOOK_NAVIGATION_MODAL_PREFIX_TEXT:
    'Are you sure you want to leave? You will lose the data from unsaved tasks. Click on “Go back” to keep working your tasks.',
  PRIMARY_BUTTON: {
    TEXT: 'Leave notebook',
  },
  SECONDARY_BUTTON: {
    TEXT: 'Go back',
  },
};

export const CREATED_TASK_ON = 'created task on';
export const READ_THE_TASK = 'read the task';
export const CHANGED_TITLE = 'changed task title';
export const CHANGED_DESCRIPTION = 'changed description';
export const SET_DUE_DATE_TO = 'set due date to';
export const REMOVED_DUE_DATE = 'removed due date';
export const ASSIGNED_TO = 'assigned to';
export const UPDATED_STATE = 'updated state';
export const RECOVERED_TASK = 'recovered task';
export const SEE_FULL_CHANGE = 'See full change';
export const SEE_MORE = 'See more';
export const DONOT_SHOW_MODAL = 'Do not show me this.';
export const LINK_COPY_SUCCESS_TOAST = 'Link copied successfully!';
