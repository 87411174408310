import React, { useState, useCallback } from 'react';
import format from 'date-fns/format';

import ThemeV2 from '../../../../componentsV2/theme';
import Body from '../../../atoms/Body';
import {
  HistoryItemContent,
  TaskHistoryActivity,
  TaskHistoryActivityTypes,
} from './interface';
import {
  Details,
  Icon,
  TaskHistoryItem,
  PreviewContent,
  ShowFullLogButton,
} from './styles';
import SVGIcon from '../../../atoms/SVGIcon';
import { getFullName } from '../../../../Utils/user';
import { TaskContentFromAPI } from '../../../../interfaces/notebook/editor';
import { convertTokenizedObjectToString } from '../../../../Utils/notebook';
import {
  PersonTypeFromAPI,
  TaskStateInAPI,
} from '../../../../interfaces/notebook';
import { capitalizeFirstLetter } from '../../../../Utils/text';
import { SelectedMember } from '../../../../interfaces/Layout';
import {
  ASSIGNED_TO,
  CHANGED_DESCRIPTION,
  CHANGED_TITLE,
  CREATED_TASK_ON,
  READ_THE_TASK,
  RECOVERED_TASK,
  REMOVED_DUE_DATE,
  SEE_FULL_CHANGE,
  SET_DUE_DATE_TO,
  UPDATED_STATE,
} from '../../../../languages/en/notebook';
import {
  CHANGED_TO,
  INCOMPLETE,
  UNARCHIVED,
  UNASSIGNED,
} from '../../../../languages/en/singleWords';

export const INITIAL_LOG_LIMIT = 248;

const getStringAndIcon = (
  item: TaskHistoryActivity,
  handleUserClick: (person: SelectedMember) => void,
) => {
  const actionGeneratedBy: HistoryItemContent = {
    value: getFullName(item.actedBy),
    type: 'PERSON',
    onClick: () =>
      handleUserClick({
        memberId: item.actedBy.memberID,
        firstName: item.actedBy.firstName,
      }),
  };
  let secondaryContent: HistoryItemContent | undefined;
  let text = '';
  let icon = 'check-rounded';
  switch (item.activity) {
    case TaskHistoryActivityTypes.CREATE: {
      text = CREATED_TASK_ON;
      icon = 'calendar';
      secondaryContent = {
        type: 'CONTENT',
        value: format(new Date(item.createdAt), 'LLLL do, yyyy'),
      };
      break;
    }
    case TaskHistoryActivityTypes.READ: {
      text = READ_THE_TASK;
      icon = 'eye';
      break;
    }
    case TaskHistoryActivityTypes.TITLE: {
      text = CHANGED_TITLE;
      icon = 'edit';
      const updatedTitle = item.content.after as TaskContentFromAPI[];
      if (updatedTitle) {
        secondaryContent = {
          type: 'PREVIEW',
          value: convertTokenizedObjectToString(updatedTitle),
        };
      }
      break;
    }
    case TaskHistoryActivityTypes.DESCRIPTION: {
      text = CHANGED_DESCRIPTION;
      icon = 'edit';
      const updatedDescription = item.content.after;
      if (updatedDescription) {
        secondaryContent = {
          type: 'PREVIEW',
          value: convertTokenizedObjectToString(
            updatedDescription as TaskContentFromAPI[],
          ),
        };
      }
      break;
    }
    case TaskHistoryActivityTypes.DUE_DATE: {
      icon = 'calendar';
      const updatedDueDate = item.content.after;
      if (updatedDueDate) {
        text = SET_DUE_DATE_TO;
        secondaryContent = {
          type: 'CONTENT',
          value: format(new Date(updatedDueDate as string), 'LLLL do, yyyy'),
        };
      } else {
        text = REMOVED_DUE_DATE;
      }
      break;
    }
    case TaskHistoryActivityTypes.ASSIGNMENT: {
      icon = 'person-filled';
      text = ASSIGNED_TO;
      const updatedAssignment = item.content.after as
        | PersonTypeFromAPI
        | undefined;
      if (updatedAssignment) {
        secondaryContent = {
          type: 'PERSON',
          value: getFullName(updatedAssignment),
        };
      } else {
        secondaryContent = {
          type: 'CONTENT',
          value: capitalizeFirstLetter(UNASSIGNED),
        };
      }
      break;
    }
    case TaskHistoryActivityTypes.STATE: {
      text = UPDATED_STATE;
      const updatedState = item.content.after as TaskStateInAPI;
      secondaryContent = {
        type: 'CONTENT',
        value: capitalizeFirstLetter(updatedState),
      };
      switch (updatedState) {
        case 'ARCHIVED': {
          icon = 'archive';
          break;
        }
        case 'COMPLETED': {
          icon = 'check-rounded';
          break;
        }
        case 'ACTIVE': {
          const previousState = item.content.before as TaskStateInAPI;
          if (previousState === 'ARCHIVED') {
            secondaryContent = {
              type: 'CONTENT',
              value: UNARCHIVED,
            };
            icon = 'unarchive';
          } else if (previousState === 'COMPLETED') {
            secondaryContent = {
              type: 'CONTENT',
              value: INCOMPLETE,
            };
            icon = 'close-rounded';
          } else {
            text = RECOVERED_TASK;
            icon = 'arrow-thick-up';
            secondaryContent = undefined;
          }
          break;
        }
        case 'DELETED':
        default: {
          icon = 'delete';
          break;
        }
      }
      break;
    }
    default: {
      break;
    }
  }
  return {
    initiator: actionGeneratedBy,
    text: ` ${text} `,
    secondaryContent,
    icon,
  };
};

const HistoryItemContentComponent = ({
  content,
}: {
  content: HistoryItemContent;
}) => {
  const [previewContentHeight, setPreviewContentHeight] = useState(0);
  const [isExpanded, setExpanded] = useState(false);
  const previewContentRef = useCallback((node) => {
    if (node != null) {
      setPreviewContentHeight(node.getBoundingClientRect().height);
    }
  }, []);
  const { type, value, onClick } = content;
  switch (type) {
    case 'PERSON': {
      return (
        <Body
          color="geekBlue6"
          variant="body2Medium"
          inline
          style={{ cursor: 'pointer' }}
        >
          <span onClick={onClick}>{value}</span>
        </Body>
      );
    }
    case 'PREVIEW': {
      return (
        <>
          <PreviewContent isExpanded={isExpanded} ref={previewContentRef}>
            <Body color="gray8" variant="body3Bold" inline>
              {`${CHANGED_TO}:`}
            </Body>
            <Body color="gray8" variant="body3" inline>
              {` ${value}`}
            </Body>
          </PreviewContent>
          {previewContentHeight >= INITIAL_LOG_LIMIT && !isExpanded && (
            <ShowFullLogButton onClick={() => setExpanded(true)}>
              <Body color="gray8" variant="body3">
                {SEE_FULL_CHANGE}
              </Body>
            </ShowFullLogButton>
          )}
        </>
      );
    }
    default: {
      return (
        <Body color="gray9" variant="body2Medium" inline>
          {value}
        </Body>
      );
    }
  }
};

const TaskHistoryItemComponent = ({
  item,
  handleUserClick,
}: {
  item: TaskHistoryActivity;
  handleUserClick: (member: SelectedMember) => void;
}) => {
  const date = format(new Date(item.createdAt), 'pp');
  const { icon, initiator, secondaryContent, text } = getStringAndIcon(
    item,
    handleUserClick,
  );
  return (
    <TaskHistoryItem>
      <Icon justifyContent="center" alignItems="center" margin="4px 0 0 0">
        <SVGIcon icon={icon} size="14px" color={ThemeV2.palette.gray9} />
      </Icon>
      <Details>
        <HistoryItemContentComponent content={initiator} />
        <Body color="gray9" variant="body2" inline>
          {text}
        </Body>
        {secondaryContent && (
          <HistoryItemContentComponent content={secondaryContent} />
        )}
        <Body variant="body3" color="gray7">
          {date}
        </Body>
      </Details>
    </TaskHistoryItem>
  );
};

export default TaskHistoryItemComponent;
