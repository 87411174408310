import { AutocompleteDropdownItem } from '../Autocomplete/interfaces';

export enum RadioOptionValueTypes {
  Is = 'is',
  IsNot = 'isNot',
}

export type OptionItemProps = {
  label: string;
  value: string;
  defaultLabel?: string;
};

export type SelectedCriteriaProps<T extends string> = {
  field: string;
  operator: string;
  value: AutocompleteDropdownItem<T>[];
};

export type FlowParticipationBuilderOptionsContentProps<T extends string> = {
  className?: string;
  selectedOptionId: string;
  radioOptions: OptionItemProps[];
  onInputChange?: (e: string) => void;
  autocompleteItems: AutocompleteDropdownItem<T>[];
  onAddButtonClick: (data: SelectedCriteriaProps<string>) => void;
  isLoading?: boolean;
  onOptionsScroll?: () => void;
  isInviteUserOnFlowCreationEnabled?: boolean;
};
