import { useCallback, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { parse } from 'qs';

import {
  FeedItemFromAPI,
  PostSortOptions,
  PostSortValues,
} from '../../../../interfaces/Feed';
import { useGetMainFeedQuery } from '../../../../queries/MainFeed';
import useToggle from '../../../../hooks/useToggle';
import { FEED_ANALYTICS_EVENTS } from '../../../../Utils/analytics/constants';
import useCommonFeedSocket from '../../../../hooks/useCommonFeedSocket';
import { WebSocketFeedTypes } from '../../../../hooks/useCommonFeedSocket/types';

import { useFetchFlowActive } from '../../../../queries/Flows/Dashboard';
import useFlowFeed from '../../../../hooks/useFlowFeed';
import { trackFeedEvent as trackFeedEventMetric } from '../../../../Utils/analytics';
import { FeedEventNameType } from '../../../../Utils/analytics/interfaces';
import { useFeatureSplit } from '../../../../hooks/useFeatureSplit';
import {
  SplitNames,
  TreatmentTypes,
} from '../../../../hooks/useSplitSdkConfig/constants';
import { RECOGNITION_PARTICIPATE } from '../../../../constants/routes';

const useMainFeedController = () => {
  const history = useHistory();
  const { search } = useLocation();
  const parsedParams = parse(search, {
    ignoreQueryPrefix: true,
  });

  const {
    feedContextProps,
    feedsSortValue,
    filter,
    onFlowClick,
    isProfileInfoLoading,
    profileData,
    setFeedsSortValue,
    setShowLoadMoreButtonToFalse,
    setShowLoadMoreButtonToTrue,
    showLoadMoreButton,
  } = useFlowFeed();

  const trackFeedEvent = useCallback(
    (action: FeedEventNameType) => {
      trackFeedEventMetric(action, {
        feedSort: PostSortValues[feedsSortValue],
      });
    },
    [feedsSortValue],
  );

  const { treatment: pageParticipationTreatment } = useFeatureSplit(
    SplitNames.NEW_PAGE_PARTICIPATION_VIEW,
  );

  const openRecognitionFlowModalOnLoad =
    parsedParams && parsedParams?.openRecognitionFlowModalOnLoad === 'true';

  const {
    models: { toggleValue: isRecognitionFlowModalOpen },
    operations: { setToggleValue: toggleIsRecognitionFlowModalOpen },
  } = useToggle();

  const handleFeedSortClick = useCallback(
    (val: string | number) => {
      setFeedsSortValue(val as PostSortOptions);
      trackFeedEvent(FEED_ANALYTICS_EVENTS.FEED_SORTED);
    },
    [setFeedsSortValue, trackFeedEvent],
  );

  const { data: activeFlowsData } = useFetchFlowActive();
  const activeFlows = activeFlowsData?.data;

  const {
    data,
    refetch,
    isLoading: isMainFeedLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useGetMainFeedQuery({
    feedsSort: feedsSortValue,
    filter,
  });

  const handleFetchNextPage = useCallback(() => {
    fetchNextPage();
    trackFeedEvent(FEED_ANALYTICS_EVENTS.FEED_SCROLLED);
  }, [fetchNextPage, trackFeedEvent]);

  const isLoading = isMainFeedLoading || isProfileInfoLoading;

  const feedProps = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.pages.reduce<FeedItemFromAPI[]>(
      (arr, page) => [...arr, ...page.data],
      [],
    );
  }, [data]);

  useCommonFeedSocket({
    type: WebSocketFeedTypes.MAIN,
    setMainFeedShowLoadMoreButtonToTrue: setShowLoadMoreButtonToTrue,
    onMainFeedFlowResponseDelete: refetch,
    onMainFeedPostDeleted: refetch,
    feedProps,
  });

  useEffect(() => {
    if (openRecognitionFlowModalOnLoad) {
      if (pageParticipationTreatment === TreatmentTypes.ON) {
        history.push(
          `${RECOGNITION_PARTICIPATE}?redirectUrl=${location.pathname}`,
        );
      } else {
        toggleIsRecognitionFlowModalOpen();
      }
    }
  }, [
    history,
    openRecognitionFlowModalOnLoad,
    pageParticipationTreatment,
    toggleIsRecognitionFlowModalOpen,
  ]);

  return {
    models: {
      isLoading,
      activeFlows,
      hasNextPage,
      profileData,
      feedsSortValue,
      data: feedProps,
      isFetchingNextPage,
      showLoadMoreButton,
      isRecognitionFlowModalOpen,
    },
    operations: {
      feedContextProps,
      refetch,
      handleFetchNextPage,
      onFlowClick,
      handleFeedSortClick,
      setShowLoadMoreButtonToFalse,
      toggleIsRecognitionFlowModalOpen,
    },
  };
};

export default useMainFeedController;
