export enum HeadingType {
  Regular = 'regular',
  Bold = 'bold',
}
export interface EmptyErrorStateTemplateProps {
  className?: string;
  image: string;
  heading: string;
  subHeading: string;
  button?: {
    text: string;
    icon?: string;
    onClick: () => void;
  };
  variant?: HeadingType;
}
