import React from 'react';

import styled from 'styled-components';
import ResponseBlock from '../ResponseBlock';

import FileCard from '../FileCard';
import Body from '../../atoms/Body';

import GifContainer from '../GifContainer';
import { OpenEndedFlowsResponseBlockType } from './types';
import { FileUploadStatus } from '../FileCard/types';
import { Flex } from '../../../Utils/styles/display';

export const StyledGifContainer = styled(GifContainer)`
  margin-top: 8px;
`;

const StyledFeedPostMessage = styled(Body)`
  display: inline-block;
  overflow-wrap: anywhere;
`;

const OpenEndedFlowsResponseBlock = (
  props: OpenEndedFlowsResponseBlockType,
) => {
  const {
    title,
    message,
    gifUrl,
    files,
    onDownloadFileClick,
    onClickPreview,
    blockId,
    responseId,
  } = props;
  return (
    <ResponseBlock label={title}>
      <StyledFeedPostMessage variant="body1">{message}</StyledFeedPostMessage>
      <br />
      <Flex flexWrap="wrap" gap="16px" margin="8px 0 0 0">
        {files &&
          files.map((file) => (
            <FileCard
              file={file}
              key={file.url}
              isParticipation={false}
              status={FileUploadStatus.Success}
              onClickDownload={() => {
                if (onDownloadFileClick)
                  onDownloadFileClick({
                    id: blockId,
                    fileName: file.name,
                    responseId: responseId,
                  });
              }}
              onClickPreview={onClickPreview}
            />
          ))}
      </Flex>
      {gifUrl && <StyledGifContainer src={gifUrl} lazyload />}
    </ResponseBlock>
  );
};

export default OpenEndedFlowsResponseBlock;
