import {
  createFilterOptions,
  FilterOptionsState,
} from '@material-ui/lab/useAutocomplete';
import orderBy from 'lodash/orderBy';
import React, { useState, useEffect, SyntheticEvent, useCallback } from 'react';
import uuid from 'uuid';
import { MemberState } from '../../../interfaces/user';
import {
  INVITE_MEMBER_EMAIL_VALIDATION_ERROR,
  INVITE_MEMBER_HELPER_TEXT,
} from '../../../languages/en/flows';

import { ADD } from '../../../languages/en/singleWords';
import { isValidEmail } from '../../../Utils/validation';
import Radio from '../../atoms/Radio';
import { MultiSelectAutoComplete } from '../Autocomplete';
import { AutocompleteDropdownItem } from '../Autocomplete/interfaces';
import {
  MultiSelectAutoCompleteWrapper,
  OptionItemWrapper,
  StyledButton,
  StyledFormControlLabel,
  StyledRadioGroup,
  InviteMemberWrapper,
  Wrapper,
} from './styles';
import {
  FlowParticipationBuilderOptionsContentProps,
  RadioOptionValueTypes,
} from './types';

import ThemeV2 from '../../../componentsV2/theme';

const filter =
  createFilterOptions<AutocompleteDropdownItem<string, undefined>>();
let newItemValue = '';

const FlowParticipationBuilderOptionsContent = (
  props: FlowParticipationBuilderOptionsContentProps<string>,
) => {
  const {
    className,
    radioOptions,
    onInputChange,
    selectedOptionId,
    autocompleteItems,
    onAddButtonClick,
    isLoading,
    onOptionsScroll,
    isInviteUserOnFlowCreationEnabled,
  } = props;

  const [optionValue, setOptionValue] = useState<string>(
    RadioOptionValueTypes.Is,
  );
  const [criteriaItems, setCriteriaItems] = useState<
    AutocompleteDropdownItem<string>[]
  >([]);

  const addNewItem = (
    currentNewItemValue: string,
    itemsArray: AutocompleteDropdownItem<string>[],
  ) => {
    const newItem = {
      id: uuid.v4(),
      title: currentNewItemValue,
      avatar: {
        icon: 'add-person',
        backgroundColor: 'transparent',
        iconColor: ThemeV2.palette.gray9,
      },
      memberState: MemberState.NEW,
    };
    itemsArray.push(newItem);
  };

  const getHasError = (values: string[]) => {
    return values.some((item: string) => !isValidEmail(item));
  };

  const onValueChange = useCallback(
    (val: AutocompleteDropdownItem<string>[]) => {
      if (newItemValue) {
        const newItemValues = newItemValue
          .split(',')
          .map((item) => item.trim());
        newItemValues.forEach((itemValue) => {
          addNewItem(itemValue, val);
        });

        newItemValue = '';
      }

      const resultVal = val.filter(
        (item) => item.id !== 'no-results-found-item',
      );

      setCriteriaItems(resultVal);
    },
    [],
  );

  const filterOptions = (
    options: AutocompleteDropdownItem<string, undefined>[],
    state: FilterOptionsState<AutocompleteDropdownItem<string, undefined>>,
  ) => {
    const filtered = filter(options, state);

    // Suggest the creation of a new value
    if (!isLoading && state.inputValue !== '' && filtered.length === 0) {
      newItemValue = state.inputValue;
      filtered.push({
        id: 'no-results-found-item',
        title: 'No results found. Click here to invite them',
      });
    }
    return filtered;
  };

  useEffect(() => {
    setCriteriaItems([]);
  }, [selectedOptionId]);

  const handleOnAddClick = () => {
    const criteriaData = {
      field: selectedOptionId,
      operator: optionValue,
      value: criteriaItems,
    };
    onAddButtonClick(criteriaData);
  };

  const handleCriteriaChange = (value: AutocompleteDropdownItem<string>[]) => {
    setCriteriaItems(value);
    if (onInputChange) {
      onInputChange('');
    }
  };

  const handleOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOptionValue(e.target.value);
    setCriteriaItems([]);
  };

  const handleOnCriteriaItemsScroll = (event: SyntheticEvent) => {
    const listBoxNode = event.currentTarget;
    if (
      listBoxNode.scrollHeight * 0.7 <=
      listBoxNode.scrollTop + listBoxNode.clientHeight
    ) {
      if (onOptionsScroll) {
        onOptionsScroll();
      }
    }
  };

  const getHelperText = (values: string[]) => {
    return getHasError(values)
      ? INVITE_MEMBER_EMAIL_VALIDATION_ERROR
      : INVITE_MEMBER_HELPER_TEXT;
  };

  const renderMultiSelectAutoComplete = (currentOptionValue: string) => {
    if (optionValue === currentOptionValue) {
      // Todo Suren: Need to add other admin permissions too
      if (
        selectedOptionId === 'email' &&
        optionValue === RadioOptionValueTypes.Is &&
        isInviteUserOnFlowCreationEnabled
      ) {
        return (
          <InviteMemberWrapper>
            <MultiSelectAutoComplete
              className="input-new-line"
              multiline
              options={autocompleteItems}
              value={criteriaItems}
              onChange={onValueChange}
              onTextboxValueChange={onInputChange}
              removeElevation
              loading={isLoading}
              ListboxProps={{
                onScroll: handleOnCriteriaItemsScroll,
              }}
              filterOptionsFunction={filterOptions}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              helperText={getHelperText(
                criteriaItems.map((item) => item.title),
              )}
              hasError={getHasError(criteriaItems.map((item) => item.title))}
              validation={isValidEmail}
            />
          </InviteMemberWrapper>
        );
      }
      return (
        <MultiSelectAutoCompleteWrapper>
          <MultiSelectAutoComplete
            className="input-new-line"
            multiline
            options={orderBy(autocompleteItems, 'title', 'asc')}
            value={criteriaItems}
            onChange={handleCriteriaChange}
            onTextboxValueChange={onInputChange}
            removeElevation
            loading={isLoading}
            ListboxProps={{
              onScroll: handleOnCriteriaItemsScroll,
            }}
          />
        </MultiSelectAutoCompleteWrapper>
      );
    }

    return null;
  };

  return (
    <Wrapper className={className}>
      <StyledRadioGroup onChange={handleOptionChange} value={optionValue}>
        {radioOptions.map((option) => (
          <OptionItemWrapper key={option.value}>
            <StyledFormControlLabel
              value={option.value}
              control={<Radio />}
              label={option.label}
            />
            {renderMultiSelectAutoComplete(option.value)}
          </OptionItemWrapper>
        ))}
      </StyledRadioGroup>
      <StyledButton
        onClick={handleOnAddClick}
        disabled={
          criteriaItems.length < 1 ||
          (isInviteUserOnFlowCreationEnabled &&
            selectedOptionId === 'email' &&
            getHasError(criteriaItems.map((item) => item.title)))
        }
      >
        {ADD}
      </StyledButton>
    </Wrapper>
  );
};

export default FlowParticipationBuilderOptionsContent;
