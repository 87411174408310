import ContentLoader from 'react-content-loader';
import React from 'react';

import ThemeV2 from '../../../componentsV2/theme';

const ToggleSwitchLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={49}
      height={24}
      viewBox="0 0 49 24"
      backgroundColor={ThemeV2.palette.gray4}
      foregroundColor={ThemeV2.palette.gray3}
      uniqueKey="email-notification-settings-loader"
    >
      <rect x="0" y="0" rx="4" width="49" height="24" />
    </ContentLoader>
  );
};

export default ToggleSwitchLoader;
