import styled from 'styled-components';
import ThemeV2 from '../../../componentsV2/theme';

export const FlowFeedPostWrapper = styled.div`
  max-width: 474px;
  background-color: ${ThemeV2.palette.white};
  border: 1px solid ${ThemeV2.palette.gray5};
  border-radius: 4px;
  margin: 0 auto 16px;
`;
