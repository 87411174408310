import React from 'react';
import { UNABLE_TO_PREVIEW } from './languages/en';

import './styles/error.scss';

const Error = (props: { error?: React.ReactNode }) => {
  const { error } = props;
  return (
    <div className="error-message">
      {error ? { error } : <p className="alert">{UNABLE_TO_PREVIEW}</p>}
    </div>
  );
};

export default Error;
