import React, { useCallback, useMemo } from 'react';
import SVGIcon from '../SVGIcon';
import {
  RegularToggleButtonRoot,
  TextToggleButtonRoot,
  ButtonIconWrapper,
} from './styles';
import { ToggleButtonVariant, ToggleButtonProps } from './interface';
import ReactionsEmoji from '../ReactionsEmoji';

const ToggleButton = (props: ToggleButtonProps) => {
  const {
    variant,
    active,
    icon,
    iconSize = '24px',
    disabled,
    size,
    children,
    onClick,
    className,
    label,
    reaction,
    isRounded = true,
  } = props;

  const handleOnClick = useCallback(
    (event) => {
      onClick(event);
    },
    [onClick],
  );

  const renderIcon = useMemo(() => {
    if (icon) {
      return (
        <ButtonIconWrapper>
          <SVGIcon icon={icon} size={iconSize} />
        </ButtonIconWrapper>
      );
    }
    return null;
  }, [icon, iconSize]);

  if (variant === ToggleButtonVariant.Text) {
    return (
      <TextToggleButtonRoot
        active={active}
        disabled={disabled}
        onClick={handleOnClick}
        className={className}
        aria-label={label}
      >
        {renderIcon}
        {children}
      </TextToggleButtonRoot>
    );
  }

  return (
    <RegularToggleButtonRoot
      active={active}
      disabled={disabled}
      size={size}
      onClick={handleOnClick}
      className={className}
      aria-label={label}
      isRounded={isRounded}
    >
      {renderIcon}
      {reaction && <ReactionsEmoji reaction={reaction} />}
      {children}
    </RegularToggleButtonRoot>
  );
};

export default ToggleButton;
