// @ts-nocheck TODO: type issues need to be fixed in this file
import axios from 'axios';
import history from '../../history';
import processErrorResponse, {
  ErrorMessages,
} from '../../containers/helpers/ErrorHelper';
import Logger from '../../Utils/Logger';
import { API_URL_WITH_V2 } from '../../config';
import { AuthHeaders } from '../shared/HeaderToken';
import { showSuccessMessage, showInfoMessage } from '../../Utils/flashHandler';

export const CANCEL_SUBSCRIPTION_RESPONSE_STATUSES = {
  CANCELED: 'canceled',
  CANCEL_FAILED: 'cancel_fail',
  NO_STATUS: 'no_status',
};
// ------------------------------------
// Helpers
// ------------------------------------
const apiEndpoints = {
  SUBSCRIPTION_INFO: `${API_URL_WITH_V2}/employer/subscription/details`,
  CANCEL_SUBSCRIPTION: `${API_URL_WITH_V2}/employer/subscription/cancel`,
  INVOICES: `${API_URL_WITH_V2}/employer/invoices`,
};

export const billingProcessError = (error = {}, dispatch, actions) => {
  Logger('processError');
  Logger(error.response);
  Logger(error);
  const statusCode = error.response ? error.response.status : 0;
  switch (statusCode) {
    case 400: // bad request
      dispatch(actions.requestFailed(ErrorMessages.serverError));
      break;
    case 401: // Not authorised
      history.push('/home');
      break;
    case 403: // Forbidden
      history.push('/404');
      break;
    case 404: // Page not found
      dispatch(actions.requestFailed(ErrorMessages.serverError));
      break;
    case 422:
    case 500:
      if (error.response && error.response.data) {
        const responseData = error.response.data;
        const msg = processErrorResponse(responseData);
        dispatch(actions.requestFailed(msg));
      }
      break;
    default:
      dispatch(actions.requestFailed(ErrorMessages.networkError));
      break;
  }
};

// ------------------------------------
// Constants
// ------------------------------------
export const BILLING_REQUEST_FAILED = 'billing_request_failed';
export const CANCEL_SUBSCRIPTION_SUCCESS = 'cancel_subscription_success';
export const UPDATE_SUBSCRIPTION_CANCEL_RESPONSE =
  'update_subscription_cancel_response';

// ------------------------------------
// Actions
// ------------------------------------

const cancelSubscriptionSuccess = () => ({
  type: CANCEL_SUBSCRIPTION_SUCCESS,
});
const updateSubscriptionCancelResponse = (status) => ({
  type: UPDATE_SUBSCRIPTION_CANCEL_RESPONSE,
  status,
});
const requestFailed = (errorMessage) => ({
  type: BILLING_REQUEST_FAILED,
  error: errorMessage,
});

export const billingActions = {
  cancelSubscriptionSuccess,
  updateSubscriptionCancelResponse,
  requestFailed,
};

// ------------------------------------
// API Wrapper
// ------------------------------------
export const cancelSubscription = (props) => {
  Logger('cancelSubscription', false);
  return async (dispatch) => {
    try {
      dispatch(showInfoMessage('Cancelling your subscription...'));
      const res = await axios.post(
        apiEndpoints.CANCEL_SUBSCRIPTION,
        props,
        AuthHeaders(),
      );
      const result = res.data;
      Logger(result);
      if (result.success) {
        dispatch(showSuccessMessage('Your subscription has been cancelled'));
        dispatch(
          billingActions.updateSubscriptionCancelResponse(
            CANCEL_SUBSCRIPTION_RESPONSE_STATUSES.CANCELED,
          ),
        );
        dispatch(billingActions.cancelSubscriptionSuccess());
      }
    } catch (error) {
      billingProcessError(error, dispatch, billingActions);
    }
  };
};

// ------------------------------------
// Reducers
// ------------------------------------

export default (state = {}, action) => {
  switch (action.type) {
    case BILLING_REQUEST_FAILED:
      return { ...state, error: action.error };
    case UPDATE_SUBSCRIPTION_CANCEL_RESPONSE:
      return { ...state, subscriptionCancelResponse: action.status };
    default:
      return state;
  }
};
