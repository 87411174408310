import styled from 'styled-components';

import { Flex } from '../../../Utils/styles/display';
import { device } from '../../../constants/layout';

export const StyledRedemptionCardWrapper = styled.div`
  margin-bottom: 16px;
`;
export const StyledQuickStatsWrapper = styled.div`
  flex: 1;
`;
export const StyledEmptyDiv = styled.div`
  width: 12px;
  height: 12px;
`;

export const StyledFlex = styled(Flex)`
  @media ${device.mobile}, ${device.tablet} {
    display: block;
  }
`;
