import React from 'react';
import shallow from 'zustand/shallow';
import useLayoutStore from '../../../stores/layoutStore';

import { StyledBuilderPageHeader } from './styles';
import useHeaderController from './useHeaderController';

const FlowsBuilderHeaderController = () => {
  const { models, operations } = useHeaderController();

  const { isRightAsideOpen } = useLayoutStore(
    (state) => ({
      isRightAsideOpen: state.isRightAsideOpen,
    }),
    shallow,
  );

  const {
    emoji,
    flowId,
    titleValue,
    isSavingFlow,
    isInEditMode,
    hasActiveOccurrence,
    isScheduledFlowEnded,
    isPreviewButtonEnabled,
    isEditFlowButtonDisabled,
  } = models;

  const {
    onEmojiChange,
    onTitleTextBlur,
    onEditButtonClick,
    onTitleTextChange,
    previewButtonClicked,
    handleRevertChangesClick,
  } = operations;

  return (
    <StyledBuilderPageHeader
      emoji={emoji}
      flowId={flowId}
      titleValue={titleValue}
      isLoading={isSavingFlow}
      isInEditMode={isInEditMode}
      onEmojiChange={onEmojiChange}
      onTitleTextBlur={onTitleTextBlur}
      onEditButtonClick={onEditButtonClick}
      onTitleTextChange={onTitleTextChange}
      previewButtonClicked={previewButtonClicked}
      isScheduledFlowEnded={isScheduledFlowEnded}
      onRevertChangesButtonClick={handleRevertChangesClick}
      isPreviewButtonEnabled={isPreviewButtonEnabled}
      isEditFlowButtonDisabled={hasActiveOccurrence || isEditFlowButtonDisabled}
      isRightAsideOpen={isRightAsideOpen}
    />
  );
};

export default FlowsBuilderHeaderController;
