import React from 'react';

import { Flex } from '../../../Utils/styles/display';

import ThemeV2 from '../../../componentsV2/theme';
import { FlowTriggerTypeProps } from './types';

import { StyledContentWrapper, StyledSVGIcon, IconHolder } from './styles';
import Body from '../../atoms/Body';
import SVGIcon from '../../atoms/SVGIcon';

const FlowTriggerType = (props: FlowTriggerTypeProps) => {
  const {
    dataTestId = '',
    typeValue,
    icon,
    headingText,
    bodyText,
    onClick,
    isSelected,
    enableIconHolder,
    ...rest
  } = props;
  const handleClick = () => {
    onClick(typeValue);
  };

  return (
    <StyledContentWrapper
      data-testid={dataTestId}
      type="button"
      onClick={handleClick}
      isSelected={isSelected}
      aria-selected={isSelected}
      {...rest}
    >
      <Flex alignItems="center" justifyContent="space-between">
        <Flex alignItems="flex-start">
          {enableIconHolder ? (
            <IconHolder isSelected={isSelected}>
              <SVGIcon
                icon={icon}
                size="30px"
                color={ThemeV2.palette.geekBlue6}
              />
            </IconHolder>
          ) : (
            <StyledSVGIcon
              icon={icon}
              size="16px"
              color={ThemeV2.palette.geekBlue6}
            />
          )}
          <div>
            <Body variant="body1Medium">{headingText}</Body>
            <Body variant="body3">{bodyText}</Body>
          </div>
        </Flex>
        {isSelected && (
          <SVGIcon icon="check-rounded" color={ThemeV2.palette.geekBlue6} />
        )}
      </Flex>
    </StyledContentWrapper>
  );
};

export default FlowTriggerType;
