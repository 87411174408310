import React, { useCallback } from 'react';

import styled from 'styled-components';
import { Formik } from 'formik';

import Button from '../../atoms/Button';
import Field from '../Field';
import {
  SAVE_CHANGES,
  AUTO_COMPLETE_LANGUAGE,
} from '../../../languages/en/settings';
import { OptionsProps } from '../DropdownWithMultiSelect';

const StyledButton = styled(Button)`
  margin-top: 16px;
`;

export interface SettingsAutocompleteValues {
  coWorkers: OptionsProps[] | OptionsProps | null;
}

export interface SettingsAutocompleteProps {
  label: string;
  initialValues: SettingsAutocompleteValues;
  autoCompleteOptions: OptionsProps[];
  onAutoCompleteInputChange: (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => void;
  onSaveChanges: (values: SettingsAutocompleteValues) => void;
  isAutoCompleteLoading?: boolean;
  onAutoCompleteFocus?: () => void;
  autoCompleteEmptyText?: string;
  isMultiSelectEnable?: boolean;
}

const SettingsAutocomplete = (props: SettingsAutocompleteProps) => {
  const {
    label,
    initialValues,
    autoCompleteOptions,
    onSaveChanges,
    onAutoCompleteInputChange,
    isAutoCompleteLoading,
    onAutoCompleteFocus,
    autoCompleteEmptyText,
    isMultiSelectEnable = true,
  } = props;
  const getPlaceholderText = useCallback(
    (values: SettingsAutocompleteValues) => {
      if (isMultiSelectEnable && Array.isArray(values?.coWorkers)) {
        return values?.coWorkers?.length === 0
          ? AUTO_COMPLETE_LANGUAGE.SEARCH_BY_NAME
          : AUTO_COMPLETE_LANGUAGE.ADD_ANOTHER;
      }
      return AUTO_COMPLETE_LANGUAGE.SEARCH_BY_NAME;
    },
    [isMultiSelectEnable],
  );

  return (
    <div>
      <Formik initialValues={initialValues} onSubmit={onSaveChanges}>
        {({ dirty, isValid, values, handleSubmit }) => (
          <>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/*  @ts-ignore  */}
            <Field
              type="dropdownWithMultiSelect"
              label={label}
              name="coWorkers"
              options={autoCompleteOptions}
              placeholder={getPlaceholderText(values)}
              onInputChange={onAutoCompleteInputChange}
              onInputFocus={onAutoCompleteFocus}
              isLoading={isAutoCompleteLoading}
              noOptionsText={autoCompleteEmptyText}
              disableLimit
              isMultiSelectEnable={isMultiSelectEnable}
            />
            <StyledButton
              color="primary"
              variant="contained"
              status="default"
              onClick={handleSubmit}
              disabled={!(dirty && isValid)}
              size="small"
            >
              {SAVE_CHANGES}
            </StyledButton>
          </>
        )}
      </Formik>
    </div>
  );
};

export default SettingsAutocomplete;
