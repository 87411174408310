import styled from 'styled-components';
import { Flex } from '../../../Utils/styles/display';
import { RewardsFooter as StyledRewardFooterUtil } from '../../../atomic/organism/RouteTabs/styles';
import InfoAlert from '../../../atomic/molecules/InfoAlert';
import Clickable from '../../../atomic/atoms/Clickable_V2';

export const StyledSwagsContainer = styled(Flex)`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  padding: 24px;
  gap: 16px;
  max-width: 976px;
  margin-right: auto;
  margin-left: auto;
`;

export const RewardsFooter = styled(StyledRewardFooterUtil)`
  position: fixed;
  bottom: 0;
  z-index: 1;
`;

export const StyledToggleSwagTabsAlert = styled(InfoAlert)`
  margin-bottom: 16px;
`;

export const StyledClickable = styled(Clickable)`
  text-decoration: underline;
`;
