import { useCallback, useMemo } from 'react';

const usePageNavigationLogic = ({
  pageNumber,
  itemCount,
  itemsPerPage,
  onHandlePaginationChange,
}: {
  pageNumber: number;
  itemsPerPage: number;
  itemCount: number;
  onHandlePaginationChange: (pageNumber: number) => void;
}) => {
  const onPreviousButtonClick = useCallback(
    () => onHandlePaginationChange(pageNumber - 1),
    [onHandlePaginationChange, pageNumber],
  );

  const onNextButtonClick = useCallback(
    () => onHandlePaginationChange(pageNumber + 1),
    [onHandlePaginationChange, pageNumber],
  );

  const numberOfPages = useMemo(() => {
    return Math.ceil(itemCount / itemsPerPage);
  }, [itemCount, itemsPerPage]);

  return {
    models: {
      numberOfPages,
    },
    operations: {
      onPreviousButtonClick,
      onNextButtonClick,
    },
  };
};

export default usePageNavigationLogic;
