import { useParams } from 'react-router-dom';

import { useFetchMembersDetailsQuery } from '../../../queries/MembersDetails';
import { trackFeedEvent } from '../../../Utils/analytics';
import { FEED_ANALYTICS_EVENTS } from '../../../Utils/analytics/constants';

const useProfileHeaderController = () => {
  const { userId } = useParams<{ userId: string }>();

  const query = useFetchMembersDetailsQuery([userId]);

  const trackHomeClicked = () => {
    trackFeedEvent(FEED_ANALYTICS_EVENTS.HOME_CLICKED, {});
  };

  return {
    userId,
    query,
    trackHomeClicked,
  };
};

export default useProfileHeaderController;
