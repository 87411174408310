import { DAYS, WEEKS, MONTHS, YEARS } from '../../../languages/en/singleWords';
import { MenuItemProps } from '../../molecules/Dropdown_V2/interfaces';

export const repeatEveryOptions: MenuItemProps[] = [
  {
    id: 'repeat-every',
    items: [
      {
        value: DAYS,
        id: 'days',
      },
      {
        value: WEEKS,
        id: 'weeks',
      },
      {
        value: MONTHS,
        id: 'months',
      },
      {
        value: YEARS,
        id: 'years',
      },
    ],
  },
];

/* keep the id name as same as it's
it's kept in a way as it should match with getDayNameFromNumber & 
getDayNameRRule */
export const repeatOnDayList = [
  {
    id: 6,
    label: 'S',
    isSelected: false,
  },
  {
    id: 0,
    label: 'M',
    isSelected: false,
  },
  {
    id: 1,
    label: 'T',
    isSelected: false,
  },
  {
    id: 2,
    label: 'W',
    isSelected: false,
  },
  {
    id: 3,
    label: 'T',
    isSelected: false,
  },
  {
    id: 4,
    label: 'F',
    isSelected: false,
  },
  {
    id: 5,
    label: 'S',
    isSelected: false,
  },
];
