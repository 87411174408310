import React, { useState, useCallback, useMemo, useRef } from 'react';

import { getRandomPlaceholderPun } from './utils';
import useOnClickOutside from '../../../hooks/useOnOutsideClick';
import { ComponentStatus } from '../../../interfaces/component';
import {
  StyledActionBarContainer,
  StyledActionBarWrapper,
  StyledNotification,
} from '../../../Utils/styles/ActionBarContainer';
import FlowsActionBarOptions from '../../organism/FlowsActionBarOptions';
import { FeedTypes } from '../ActionBar/types';
import ActionBar from '../ActionBar';
import { StyledActionModal } from './styles';
import { MainFeedActionBarContainerProps } from './types';
import RecognitionFlowController from '../../../controllers/home/RecognitionFlowController';
import ActionBarContainer from '../ActionBarContainer';
import { ANSWER } from '../../../languages/en/singleWords';
import { CELEBRATE_TEAM_MEMBER } from '../../../languages/en/feed';

const MainFeedActionBarContainer = (props: MainFeedActionBarContainerProps) => {
  const {
    todos,
    shortcuts,
    feeds,
    avatar,
    userId,
    userName,
    text,
    feedType,
    isDisabled,
    notification = '',
    isLoading,
    isError,
    isSuccess,
    searchText,
    isRecognitionFlowModalOpen,
    profileInfo,
    isAddNewFlowOn,
    handleSearchTextChange,
    onFlowClick,
    onFlowFeedClick,
    onAddNewFlowClick,
    handleCelebrateTeammateClick,
    clearSearchText,
    toggleIsRecognitionFlowModalOpen,
    closeModalCb,
    onNeedHelpClick,
    onClickActionBarButtonCb,
    handleSuffixAdornmentClickCb,
    openActionBarOnLoad,
  } = props;

  const celebrateActionBarText = `${ANSWER} 🎉 ${CELEBRATE_TEAM_MEMBER}`;

  let componentStatus = ComponentStatus.LOADING;

  if (isLoading) {
    componentStatus = ComponentStatus.LOADING;
  }

  if (isError) {
    componentStatus = ComponentStatus.ERROR;
  }

  if (isSuccess) {
    componentStatus = ComponentStatus.LOADED;
  }

  const [isModalOpened, setIsModalOpened] = useState(openActionBarOnLoad);
  const containerRef = useRef<HTMLDivElement>(null);

  const onClickActionBarButton = () => {
    setIsModalOpened(!isModalOpened);
    onClickActionBarButtonCb();
  };

  const closeModal = useCallback(() => {
    if (isModalOpened) {
      setIsModalOpened(false);
      clearSearchText();
      closeModalCb();
    }
  }, [clearSearchText, closeModalCb, isModalOpened]);

  const onOutsideClickHandler = useCallback(() => {
    closeModal();
  }, [closeModal]);

  useOnClickOutside(containerRef, onOutsideClickHandler);

  const placeholder = useMemo(
    () => getRandomPlaceholderPun(),
    // isModalOpened dependency pulls a new pun only when user opens/closes the action bar,
    // not every time MainFeedActionBarContainer re-renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isModalOpened],
  );

  return (
    <StyledActionBarContainer ref={containerRef}>
      {notification && (
        <StyledNotification variant="body3Medium">
          {notification}
        </StyledNotification>
      )}
      <StyledActionBarWrapper>
        {!isAddNewFlowOn && feeds.length === 1 ? (
          <ActionBarContainer
            avatar={avatar}
            userName={userName}
            userId={userId}
            feedType={FeedTypes.Flow}
            text={celebrateActionBarText}
            onClick={handleCelebrateTeammateClick}
          />
        ) : (
          <div>
            <ActionBar
              avatar={avatar}
              userName={userName}
              userId={userId}
              text={text}
              onClick={onClickActionBarButton}
              feedType={feedType}
              isDisabled={isDisabled}
            />
            {isModalOpened && (
              <StyledActionModal>
                <FlowsActionBarOptions
                  placeholder={placeholder}
                  status={componentStatus}
                  onAddFlowClick={onAddNewFlowClick}
                  onCloseButtonClick={closeModal}
                  onFlowClick={onFlowClick}
                  todos={todos}
                  feeds={feeds}
                  shortcuts={shortcuts}
                  onFlowFeedClick={onFlowFeedClick}
                  searchText={searchText}
                  handleSearchTextChange={handleSearchTextChange}
                  clearSearchText={clearSearchText}
                  isAddNewFlowOn={isAddNewFlowOn}
                  onNeedHelpClick={onNeedHelpClick}
                  handleSuffixAdornmentClickCb={handleSuffixAdornmentClickCb}
                />
              </StyledActionModal>
            )}
          </div>
        )}
      </StyledActionBarWrapper>

      {isRecognitionFlowModalOpen && profileInfo && (
        <RecognitionFlowController
          profileInfo={profileInfo}
          toggleIsRecognitionFlowModalOpen={toggleIsRecognitionFlowModalOpen}
        />
      )}
    </StyledActionBarContainer>
  );
};

export default MainFeedActionBarContainer;
