import { useCallback, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import {
  TriggerType,
  ContentBlockState,
  FlowBuilderBlockTypes,
} from '../../../../interfaces/Flow/Builder';

import { getIsScheduledFlowEnded } from '../../../../Utils/flows';
import useFlowBuilderStore from '../../../../stores/flowBuilderStore';
import { useGetFlowTemplate } from '../../../../queries/Flows/Template';
import { useFetchFlowDetailsQuery } from '../../../../queries/Flows/Feed';
import { visibilityTypes } from '../../FlowsBuilderVisibilityController/data';
import useGetFlowDetailsFromTemplate from '../../../../hooks/useGetFlowDetailsFromTemplate';

import {
  getBaseEmojiFromIcon,
  mapCriteriaResponseToBlockData,
  mapContentBlockFromTemplateResponse,
} from '../../../../Utils/flows/builder/utils';

import {
  setEmojiSelector,
  blockDataSelector,
  setFlowNameSelector,
  setDescriptionSelector,
  setCurrentScheduleSelector,
  setSpecificBlockDataSelector,
  revertBlockDataToDefaultStateSelector,
  setPendingEmailsSelector,
  ownerSelector,
  shareSheetSetAnonymousSettingsSelector,
} from '../../../../stores/flowBuilderStore/selectors';
import { useQueryClient } from 'react-query';
import { GET_MEMBERS_DETAILS } from '../../../../constants/endpoints';
import {
  AnonymityStates,
  CriteriaResponse,
} from '../../../../queries/Flows/interfaces';
import { anonymousSettingsOptions } from '../../../flows/FlowsShareSheetController/data';

const useFlowBuilderEdit = () => {
  const { search } = useLocation();

  const { flowId } = useParams<{ flowId: string }>();
  const [template, setTemplate] = useState('');

  const { data: templateData } = useGetFlowTemplate(template);
  const { data: flowDetails } = useFetchFlowDetailsQuery(flowId, 'builder');
  const [hasActiveOccurrence, setHasActiveOccurrence] = useState(false);

  const queryClient = useQueryClient();
  const owner = useFlowBuilderStore(ownerSelector);
  const setEmoji = useFlowBuilderStore(setEmojiSelector);
  const blockData = useFlowBuilderStore(blockDataSelector);
  const setFlowName = useFlowBuilderStore(setFlowNameSelector);
  const setDescription = useFlowBuilderStore(setDescriptionSelector);
  const setPendingEmails = useFlowBuilderStore(setPendingEmailsSelector);
  const setCurrentSchedule = useFlowBuilderStore(setCurrentScheduleSelector);

  const setSpecificBlockData = useFlowBuilderStore(
    setSpecificBlockDataSelector,
  );

  const revertBlockDataToDefaultState = useFlowBuilderStore(
    revertBlockDataToDefaultStateSelector,
  );
  const setAnonymousSettings = useFlowBuilderStore(
    shareSheetSetAnonymousSettingsSelector,
  );

  // eslint-disable-next-line
  useEffect(() => revertBlockDataToDefaultState(), [flowDetails, templateData]);

  useEffect(() => {
    setTemplate(new URLSearchParams(search).get('template') || '');
  }, [search]);

  useGetFlowDetailsFromTemplate();

  const populateFlowDetailsFromAPI = useCallback(async () => {
    if (flowDetails?.data) {
      const currentFlow = flowDetails.data;
      const contentBlocks: ContentBlockState[] = [];

      setFlowName(currentFlow.name);
      setDescription(currentFlow.description);
      setCurrentSchedule(currentFlow.schedule);
      setHasActiveOccurrence(Boolean(currentFlow.hasActiveOccurrence));
      setEmoji(getBaseEmojiFromIcon(currentFlow.icon?.value || ''));
      setSpecificBlockData(FlowBuilderBlockTypes.TRIGGER, {
        ...blockData.TRIGGER,
        isSchedulerTouched: false,
      });
      setAnonymousSettings(
        flowDetails?.data?.responseSettings?.anonymity?.state ===
          AnonymityStates.ENABLED
          ? anonymousSettingsOptions[2]
          : anonymousSettingsOptions[0],
      );

      setPendingEmails(currentFlow.pendingEmails);

      if (currentFlow.viewing) {
        const { criteria } = currentFlow.viewing;
        let type = visibilityTypes.ENTIRE_ORGANIZATION;
        if (criteria.onlyOwners) {
          type = visibilityTypes.OWNER_ONLY;
        }

        if (criteria.onlyParticipants) {
          type = visibilityTypes.PARTICIPANTS_ONLY;
        }

        if (criteria.custom) {
          type = visibilityTypes.CUSTOM;
        }

        setSpecificBlockData(FlowBuilderBlockTypes.VISIBILITY, {
          type,
          errors: null,
          custom: Boolean(criteria.custom),
          everyone: Boolean(criteria.everyone),
          onlyOwners: Boolean(criteria.onlyOwners),
          onlyParticipants: Boolean(criteria.onlyParticipants),
          criteriaGroups: mapCriteriaResponseToBlockData(currentFlow.viewing),
        });
      }

      if (currentFlow.participation) {
        const newCriteriaGroups = mapCriteriaResponseToBlockData(
          currentFlow.participation,
        );
        await queryClient.invalidateQueries(GET_MEMBERS_DETAILS);

        setSpecificBlockData(FlowBuilderBlockTypes.PARTICIPANTS, {
          errors: null,
          participantsCriteria: newCriteriaGroups,
        });
      }

      if (currentFlow.action) {
        const { action } = currentFlow;

        if (action.blocks && action.blocks.length) {
          action.blocks.forEach((block) => {
            const mappedBlockData = mapContentBlockFromTemplateResponse(block);
            if (mappedBlockData) contentBlocks.push(mappedBlockData);
          });

          setSpecificBlockData(FlowBuilderBlockTypes.CONTENT, {
            errors: null,
            contentBlocks: [...contentBlocks],
          });
        }
      }

      let triggerType: TriggerType = 'ONDEMAND';
      if (currentFlow.kind === 'SCHEDULED') {
        triggerType = 'SCHEDULED';
      } else if (currentFlow.kind === 'NO_TRIGGER') {
        triggerType = 'NO_TRIGGER';
      }

      setSpecificBlockData(FlowBuilderBlockTypes.TRIGGER, {
        selectedCustomRecurrenceTypes: undefined,
        schedule: currentFlow.schedule,
        shortcut: currentFlow.shortcut,
        endTimeInMinutes: currentFlow.endTimeInMinutes
          ? currentFlow.endTimeInMinutes
          : 0,
        triggerType,
        isSchedulerTouched: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    setEmoji,
    setFlowName,
    flowDetails,
    setDescription,
    setCurrentSchedule,
    setSpecificBlockData,
  ]);

  useEffect(() => {
    if (!flowId) {
      const defaultParticipantsCriteria: CriteriaResponse = {
        criteria: {
          custom: {
            rules: [
              {
                condition: 'or',
                rules: [
                  {
                    field: 'member',
                    value: [owner[0]?.id || ''],
                    operator: 'is',
                  },
                ],
              },
            ],
            condition: 'or',
          },
        },
      };

      const defaultVisibilityCriteria: CriteriaResponse = {
        criteria: {
          onlyOwners: true,
        },
      };

      setSpecificBlockData(FlowBuilderBlockTypes.PARTICIPANTS, {
        errors: null,
        participantsCriteria: mapCriteriaResponseToBlockData(
          defaultParticipantsCriteria,
        ),
      });

      setSpecificBlockData(FlowBuilderBlockTypes.VISIBILITY, {
        errors: null,
        custom: true,
        everyone: false,
        onlyOwners: false,
        onlyParticipants: false,
        type: visibilityTypes.CUSTOM,
        criteriaGroups: mapCriteriaResponseToBlockData(
          defaultVisibilityCriteria,
        ),
      });
    }
  }, [flowId, owner, setSpecificBlockData]);

  useEffect(() => {
    populateFlowDetailsFromAPI();
  }, [populateFlowDetailsFromAPI]);

  const isScheduledFlowEnded = getIsScheduledFlowEnded({
    isFlowOwner: flowDetails?.data.shortcut,
    kind: flowDetails?.data.kind,
    hasActiveOccurrence: flowDetails?.data.hasActiveOccurrence,
    schedule: flowDetails?.data.schedule,
  });

  return {
    flowId,
    hasActiveOccurrence,
    isScheduledFlowEnded,
    resetFlowDetails: populateFlowDetailsFromAPI,
  };
};

export default useFlowBuilderEdit;
