import React from 'react';
import PeopleMenuAccordion from '../../../atomic/organism/MenuAccordion/PeopleMenuAccordion';
import { PEOPLE } from '../../../languages/en/home';
import InviteModalController from '../../InviteModalController';
import usePeopleDropdownListController from './usePeopleDropdownListController';

const PeopleDropdownListController = ({
  isLeftAsideOpen,
  isLeftNavHovered,
}: {
  isLeftAsideOpen: boolean;
  isLeftNavHovered: boolean;
}) => {
  const {
    disableParticipantsMenuList,
    hasMoreMembersToFetch,
    canInviteMembers,
    isOpen,
    isModalOpen,
    infiniteMembers,
    membersSearchValue,
    onMembersSearchChange,
    searchedMembers,
    handleEntryClick,
    toggleExpandNavigation,
    toggleInviteModal,
  } = usePeopleDropdownListController();

  return (
    <>
      <PeopleMenuAccordion
        disabled={disableParticipantsMenuList}
        isAccordionOpen={isOpen}
        onAccordionClick={toggleExpandNavigation}
        accordionTitle={PEOPLE}
        lists={infiniteMembers}
        canInviteMembers={!disableParticipantsMenuList && canInviteMembers}
        hasMoreMembersToFetch={hasMoreMembersToFetch}
        searchedMembers={searchedMembers}
        membersSearchValue={membersSearchValue}
        handleEntryClick={handleEntryClick}
        toggleInviteModal={toggleInviteModal}
        onMembersSearchChange={onMembersSearchChange}
        isHovered={isLeftNavHovered || isLeftAsideOpen}
      />
      <InviteModalController
        isModalOpen={isModalOpen}
        closeInviteModal={toggleInviteModal}
      />
    </>
  );
};

export default PeopleDropdownListController;
