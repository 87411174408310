/* eslint-disable max-len */
export const flowSelectTooltipText =
  'This is a recurring meeting (recommended)';

export const SETUP_INITIALIZE_ERROR =
  'Something went wrong while initializing workato during setup.';

export const quickSetupHeader = 'Quick Setup';
export const quickSetupSubHeader = 'Get started with Assembly';

export const CALENDAR_ALREADY_CONNECTED = 'CALENDAR_ALREADY_CONNECTED';
export const PRIMARY_CALENDAR = 'Primary Calendar';

export const quickSetupGetStartedScreen = {
  headingText: 'What’s most important to you?',
  subHeadingText:
    'Assembly is where teams gather to collaborate, celebrate one another, communicate, and hold each other accountable. Choose a way to get started so that we know how to best help you succeed.',
};

export const quickSetupCalendarSetupScreen = {
  googleCalendarTitle: 'Google Calendar',
  outlookCalendarTitle: 'Outlook Calendar',
  subHeadingText:
    'Connect your work calendar and link an important meeting of your choice with a flow and save yourself some time.',
  errorScreenHeading: 'Error loading calendars',
  errorScreenSubHeading: 'Try refreshing the page.',
};

export const quickSetupTemplateSelectionScreen = {
  getHeadingText: (meetingName: string) =>
    `Pick a template for your ${meetingName} meeting`,
  subHeadingText:
    'Click a template below to preview and decide if you want to use it. After choosing one, you can always adjust or customize it later to more accurately match your needs.',
  searchTemplatesPlaceHolder: 'Search templates',
  useCasesPlaceHolder: 'Use Cases',
};

export const quickSetupMeetingListScreen = {
  headingText: 'Choose one of your meetings to create a flow',
  subHeadingText:
    'We recommend choosing a recurring meeting such as a one-on-one, team standup, team retrospective, team sync, or all hands.',
  errorComponent: {
    heading: 'Your calendar failed to load.',
    subHeading: 'Try refreshing the page.',
  },
  emptyComponent: {
    contentHeading: 'Create a recurring calendar event to get started',
    contentSubHeading:
      'Set up a recurring event, and then come back and refresh this list to start creating your flow.',
    goToCalendar: ' Go to calendar',
    separator: ', or ',
    exploreOnOwm: 'explore on your own.',
  },
  loadedComponent: {
    noNonRecurringItemsText:
      '🍃 You have no meetings on this date, time to chillax!',
  },
};

export const quickSetupMeetingParticipantsListScreen = {
  headingText: 'Add the meeting participants to',
  getQuickSetupMeetingListSubHeadingText: (flowName: string) =>
    `Invite the meeting participants to join you in your ${flowName} flow. Participants respond to a flow, while viewers can see all of the responses. Participants can also be viewers.`,
  learnMoreLink: 'Learn more about participants and viewers',
  label: 'Meeting participant’s email',
  helperText: 'Not a valid email, please fix to invite',
  toolTipText: "This can't be changed during setup - you can change later",
};

export const quickSetupModalTexts = {
  heading: 'Are you sure?',
  leaveSetupModalSubHeading:
    'Leaving quick setup at this point will not create a flow for your meeting. You can still create a flow on your own later, but you are so close to finishing this one, why stop now?!',
  leaveSetupModalSubHeading2: 'Are you sure you’d like to do this?',
  inviteLaterModalSubHeading:
    'Creating your flow without inviting anyone means your flow will not have any participants besides yourself.',
  inviteLaterModalSubHeading2:
    'You can always invite teammates later. Would you still like to create without inviting any teammates to participate in it?',
};
export const quickSetUpCompletionScreen = {
  headingText: 'We’re setting up your workspace!',
  subHeadingText:
    'You’re almost ready to join your workspace. Hold tight for a minute while we get it ready for you. You can leave this window, but please keep the tab open for us.',
};

export const quickSetupButtonTexts = {
  leaveSetup: {
    primaryButtonText: 'Skip flow creation',
    secondaryButtonText: 'Finish Setup',
  },
  inviteLater: {
    primaryButtonText: 'Yes, create flow',
    secondaryButtonText: 'No, go back',
  },
  leaveSetupButton: 'Leave setup',
  goBackButton: 'Back',
  meetingsListScreenButtons: {
    primaryButtonText: 'Next',
    tertiaryButtonText: 'Select a meeting',
  },
  addParticipantsScreenButtons: {
    primaryButtonText: 'Invite and create',
    secondaryButtonText: 'Invite later',
  },
  exploreOnOwnButton: 'Explore on my own',
  exploreTemplatesMyself: 'Explore templates myself',
};

export const quickSetupSurveyController = {
  headingText:
    'Book a demo with our experts to get your survey up and running.',
  subHeadingText:
    'We’re here to help you with implementation and launch plans.',
};
