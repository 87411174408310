import { v4 as uuidv4 } from 'uuid';
import { useCallback, useEffect, useState } from 'react';

import { MeetingParticipant } from '../../../../atomic/organism/QuickSetup/MeetingParticipantsList/type';

import { isEmpty } from '../../../../Utils/common';
import { AddParticipantsControllerProps } from '../types';
import { isValidEmail } from '../../../../Utils/validation';
import { useProfileInfoFetchQuery } from '../../../../queries/Profile';
import { defaultParticipant, getMeetingParticipantsList } from '../utils';
import { useGetCalendarEventDetailsQuery } from '../../../../queries/QuickSetup';

const useAddParticipantsController = (
  props: AddParticipantsControllerProps,
) => {
  const {
    meetingId,
    calenderId,
    isWorkatoSetupComplete,
    onMeetingParticipantsChange,
  } = props;

  const { data: profileInfoQueryData } = useProfileInfoFetchQuery();

  // Participants List
  const { data: participantsData, isLoading: isParticipantsDataLoading } =
    useGetCalendarEventDetailsQuery(
      calenderId,
      meetingId,
      isWorkatoSetupComplete && !isEmpty(calenderId) && !isEmpty(meetingId),
    );
  const [participantsList, setParticipantsList] = useState<
    MeetingParticipant[]
  >([]);

  useEffect(() => {
    if (participantsData) {
      setParticipantsList(
        getMeetingParticipantsList(
          participantsData.attendees,
          profileInfoQueryData?.member.email || '',
        ),
      );
    }
  }, [participantsData, profileInfoQueryData?.member.email]);

  useEffect(() => {
    onMeetingParticipantsChange(participantsList);
  }, [onMeetingParticipantsChange, participantsList]);

  const handleOnDeleteItemClick = useCallback(
    (id: string) => {
      setParticipantsList(
        participantsList.filter((participant) => participant.id !== id),
      );
    },
    [participantsList],
  );

  const handleOnValueChange = useCallback(
    (participant: MeetingParticipant) => {
      if (participantsList[participantsList.length - 1].id === participant.id) {
        setParticipantsList([
          ...participantsList.map((item) => {
            return item.id === participant.id ? participant : item;
          }),
          { ...defaultParticipant, id: uuidv4() },
        ]);
      } else {
        setParticipantsList(
          participantsList.map((item) => {
            return item.id === participant.id ? participant : item;
          }),
        );
      }
    },
    [participantsList],
  );

  const handleOnBlur = useCallback(() => {
    setParticipantsList(
      participantsList.map((item, index) => {
        if (
          index === 0 ||
          item.email.length === 0 ||
          isValidEmail(item.email) ||
          index === participantsList.length - 1
        ) {
          return { ...item, hasError: false };
        } else {
          return { ...item, hasError: true };
        }
      }),
    );
  }, [participantsList]);

  return {
    models: { participantsList, isParticipantsDataLoading },
    operations: { handleOnDeleteItemClick, handleOnValueChange, handleOnBlur },
  };
};

export default useAddParticipantsController;
