import React from 'react';
import ContentLoader from 'react-content-loader';
import ThemeV2 from '../../../componentsV2/theme';

const LoaderSmall = () => {
  return (
    <ContentLoader
      speed={2}
      width={270}
      height={62}
      viewBox="0 0 270 62"
      backgroundColor={ThemeV2.palette.gray4}
      foregroundColor={ThemeV2.palette.gray3}
      style={{ transform: 'translateY(-7px)' }}
      data-testid="loadingWrapper"
    >
      <rect x="16" y="0" rx="4" ry="4" width="32" height="32" />
      <rect x="16" y="46" rx="4" ry="4" width="133" height="16" />
    </ContentLoader>
  );
};
export default LoaderSmall;
