import { MenuItemProps } from '../atomic/molecules/Dropdown_V2/interfaces';
import { MenuItemsTexts } from '../languages/en/flows/builder';

export const criteriaMenuItems: MenuItemProps[] = [
  {
    id: 'criteria-items',
    items: [
      {
        value: `${MenuItemsTexts.criteriaMenuItems.PARTICIPANTS_MATCHES_ANY}`,
        id: `${MenuItemsTexts.criteriaMenuItems.PARTICIPANTS_MATCHES_ANY}`,
      },
      {
        value: `${MenuItemsTexts.criteriaMenuItems.PARTICIPANTS_MATCHES_ALL}`,
        id: `${MenuItemsTexts.criteriaMenuItems.PARTICIPANTS_MATCHES_ALL}`,
      },
    ],
  },
];
export const criteriaConditionItems: MenuItemProps[] = [
  {
    id: 'criteria-condition-items',
    items: [
      {
        value: `${MenuItemsTexts.criteriaConditionItems.OR_PARTICIPANT_MATCHES}`,
        id: `${MenuItemsTexts.criteriaConditionItems.OR_PARTICIPANT_MATCHES}`,
      },
      {
        value: `${MenuItemsTexts.criteriaConditionItems.AND_PARTICIPANT_MATCHES}`,
        id: `${MenuItemsTexts.criteriaConditionItems.AND_PARTICIPANT_MATCHES}`,
      },
    ],
  },
];

export const BuilderFlowNameCharacterLimit = 30;

export const supportedWholeNumberKeyCodes = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  'Backspace',
  'Delete',
];
