import React from 'react';
import { Collapse } from '@material-ui/core';
import Body from '../../atoms/Body';
import { ButtonSize } from '../../atoms/IconButton_V2/interface';
import {
  Badge,
  NavigationExpandBar,
  StyledBetaIcon,
  StyledIconButton,
  StyledSVGIcon,
  StyledToolTip,
  TextWrapper,
} from './styles';
import { ExpandableListHolderProps } from './types';
import ThemeV2 from '../../../componentsV2/theme';

const ExpandableListHolder = (props: ExpandableListHolderProps) => {
  const {
    onClick,
    onMouseEnter,
    onMouseLeave,
    heading,
    isExpanded,
    isBeta,
    addButton,
    arrowIcon,
    arrowIconSize,
    children,
    className,
    totalNotificationsCount,
  } = props;
  return (
    <div className={className}>
      <NavigationExpandBar
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <TextWrapper>
          <StyledSVGIcon
            icon={arrowIcon || 'caret-rounded'}
            rotate={isExpanded ? 180 : 0}
            color={ThemeV2.palette.gray8}
            size={arrowIconSize}
          />
          <Body
            variant={
              !isExpanded && totalNotificationsCount ? 'body1Bold' : 'body1'
            }
          >
            {heading}
          </Body>
          {!isExpanded && totalNotificationsCount && (
            <Badge>
              <Body variant="body3" color="gray1">
                {totalNotificationsCount}
              </Body>
            </Badge>
          )}
          {isBeta && <StyledBetaIcon icon="beta-mono" data-testid="betaIcon" />}
        </TextWrapper>
        {addButton && addButton.enabled && (
          <StyledToolTip
            toolTipComponent={
              <StyledIconButton
                icon={addButton.icon || 'union-rounded'}
                color="textWithBackground"
                size={ButtonSize.Small}
                onClick={addButton.onClick}
                dataTestId={addButton.dataTestId}
              />
            }
            position="top"
            positionStrategy="fixed"
          >
            {addButton.text}
          </StyledToolTip>
        )}
      </NavigationExpandBar>
      <Collapse in={isExpanded}>{children}</Collapse>
    </div>
  );
};

export default ExpandableListHolder;
