import { EmojiData } from 'emoji-mart';
import { InfiniteData } from 'react-query';
import {
  FeedItemFromAPI,
  GetFeedResponse,
  Reaction,
} from '../../../../interfaces/Feed';
import { PostReaction } from '../../../../interfaces/Feed/Reaction';
import { GetProfileInfoResponse } from '../../../../queries/Profile';
import { isUserCurrentUser } from '../../../../queries/Profile/utils';

export const getPostFromFeed = (
  feedData: FeedItemFromAPI[],
  contentId: string,
) => {
  return feedData.find(
    (x) => x.postID === contentId || x.flowResponse?.responseId === contentId,
  );
};

export const doesPostReactionsHasReactionFromSameUser = (
  postReactions: PostReaction[],
  emoji: EmojiData,
  profileData?: GetProfileInfoResponse,
) => {
  return postReactions.some(
    (r) =>
      r.name === emoji.colons &&
      r.members.some((m) => isUserCurrentUser(m, profileData)),
  );
};

export const hasReactionFromSameUser = (
  reaction: Reaction,
  profileData?: GetProfileInfoResponse,
) => {
  return reaction.members.some(
    (r: { memberID: string | undefined }) =>
      r.memberID === profileData?.member.memberId,
  );
};

export const getFeedArrayFromQueryData = (
  data: InfiniteData<GetFeedResponse> | undefined,
) => {
  if (!data) {
    return [];
  }

  return data.pages.reduce<FeedItemFromAPI[]>(
    (acc, page) => [...acc, ...page.data],
    [],
  );
};
