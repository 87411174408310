import { NOTEBOOK_EVENTS, NOTEBOOK_TASK_EVENTS } from '../constants';

const eventsAttributesMap = new Map();

eventsAttributesMap.set(NOTEBOOK_EVENTS.NOTEBOOK_SHOW, [
  'tab',
  'show',
  'section',
  'todayTasks',
  'overdueTasks',
  'upcomingTasks',
  'unscheduledTasks',
]);

eventsAttributesMap.set(NOTEBOOK_EVENTS.NOTEBOOK_ERROR, [
  'error',
  'source',
  'subSource',
  'errorIds',
  'tab',
]);

// Notebook sectional events
eventsAttributesMap.set(NOTEBOOK_EVENTS.NOTEBOOK_TASK_CREATION, [
  'action',
  'section',
  'type',
  'tab',
]);
eventsAttributesMap.set(NOTEBOOK_EVENTS.NOTEBOOK_SECTION_CHANGED, [
  'section',
  'action',
  'lastSection',
  'type',
  'tab',
]);
eventsAttributesMap.set(NOTEBOOK_EVENTS.NOTEBOOK_SECTION_EXPANDED, [
  'section',
  'action',
  'tab',
]);
eventsAttributesMap.set(NOTEBOOK_EVENTS.NOTEBOOK_SECTION_COLLAPSED, [
  'section',
  'action',
  'tab',
]);

// Notebook task-based events
eventsAttributesMap.set(NOTEBOOK_TASK_EVENTS.NOTEBOOK_TASK_SHOW, [
  'show',
  'tab',
]);

eventsAttributesMap.set(NOTEBOOK_TASK_EVENTS.NOTEBOOK_TASK_CREATE, [
  'action',
  'taskId',
  'creatorId',
  'assigneeId',
  'dueDate',
  'creationDate',
  'source',
  'subSource',
  'type',
  'title',
  'status',
  'titleLinkURL',
  'tab',
]);
eventsAttributesMap.set(NOTEBOOK_TASK_EVENTS.NOTEBOOK_TASK_ARCHIVE, [
  'action',
  'taskId',
  'creatorId',
  'assigneeId',
  'dueDate',
  'creationDate',
  'source',
  'subSource',
  'agent',
  'type',
  'title',
  'titleLinkURL',
  'tab',
]);
eventsAttributesMap.set(NOTEBOOK_TASK_EVENTS.NOTEBOOK_TASK_DELETE, [
  'action',
  'taskId',
  'creatorId',
  'assigneeId',
  'dueDate',
  'creationDate',
  'source',
  'subSource',
  'agent',
  'type',
  'title',
  'titleLinkURL',
  'tab',
]);
eventsAttributesMap.set(NOTEBOOK_TASK_EVENTS.NOTEBOOK_TASK_TITLE_UPDATE, [
  'action',
  'taskId',
  'creatorId',
  'assigneeId',
  'dueDate',
  'creationDate',
  'source',
  'subSource',
  'agent',
  'type',
  'title',
  'status',
  'lastTitle',
  'titleLinkURL',
  'tab',
]);
eventsAttributesMap.set(NOTEBOOK_TASK_EVENTS.NOTEBOOK_TASK_DUE_DATE_UPDATE, [
  'action',
  'taskId',
  'creatorId',
  'assigneeId',
  'dueDate',
  'creationDate',
  'source',
  'subSource',
  'agent',
  'type',
  'title',
  'status',
  'lastDueDate',
  'titleLinkURL',
  'tab',
]);
eventsAttributesMap.set(NOTEBOOK_TASK_EVENTS.NOTEBOOK_TASK_STATUS_UPDATE, [
  'action',
  'taskId',
  'creatorId',
  'assigneeId',
  'dueDate',
  'creationDate',
  'source',
  'subSource',
  'agent',
  'type',
  'title',
  'status',
  'lastStatus',
  'titleLinkURL',
  'tab',
]);
eventsAttributesMap.set(NOTEBOOK_TASK_EVENTS.NOTEBOOK_TASK_ASSIGNMENT_UPDATE, [
  'action',
  'taskId',
  'creatorId',
  'assigneeId',
  'dueDate',
  'creationDate',
  'source',
  'subSource',
  'agent',
  'type',
  'title',
  'status',
  'lastAssigneeId',
  'titleLinkURL',
  'tab',
]);
eventsAttributesMap.set(NOTEBOOK_TASK_EVENTS.NOTEBOOK_TASK_ERROR, ['error']);

export default eventsAttributesMap;
