import shallow from 'zustand/shallow';
import React, { memo } from 'react';

import Button from '../../../atoms/Button';
import DateFilter from '../../DateFilter';
import DrawerHeaderWithIcon from '../../../molecules/DrawerHeaderWithIcon';
import CheckboxFilter from '../../CheckboxFilter';
import {
  StyledScrollWrapper,
  StyledFilterWrapper,
  StyledSpaceSeparator,
  StyledButton,
  StyledFlex,
} from '../styles';

import { MainFeedFilterLoadedProps } from './types';

import { FILTER_BY, APPLY_FILTERS } from '../../../../languages/en/flows';
import { CLEAR } from '../../../../languages/en/singleWords';
import { ComponentStatus } from '../../../../interfaces/component';
import { CheckboxFilterLoadedProps } from '../../CheckboxFilter/types';
import useLayoutStore from '../../../../stores/layoutStore';
import useKioskStore from '../../../../stores/kioskStore';

const RenderCheckboxFilter = (props: CheckboxFilterLoadedProps) => {
  const { options, headingText } = props;
  if (options.length > 0) {
    return <CheckboxFilter {...props} />;
  }
  return (
    <CheckboxFilter status={ComponentStatus.EMPTY} headingText={headingText} />
  );
};

const MemoizedRenderCheckboxFilter = memo(RenderCheckboxFilter);

const LoadedComponent = (props: MainFeedFilterLoadedProps) => {
  const {
    dateFilterProps,
    flowFilterProps,
    postedByFilterProps,
    mentionedByFilterProps,
    onSubmit,
    onClear,
    isFilterButtonEnabled = false,
  } = props;
  const { isTurnedOn: isKioskTurnedOn } = useKioskStore();
  const { isRightAsideOpen, toggleRightAsideOpen } = useLayoutStore(
    (state) => ({
      isRightAsideOpen: state.isRightAsideOpen,
      toggleRightAsideOpen: state.toggleRightAsideOpen,
    }),
    shallow,
  );

  return (
    <StyledScrollWrapper>
      <DrawerHeaderWithIcon icon="filter-by" onClick={toggleRightAsideOpen}>
        {FILTER_BY}{' '}
      </DrawerHeaderWithIcon>
      <StyledFilterWrapper>
        <DateFilter {...dateFilterProps} />
        <StyledSpaceSeparator />
        <MemoizedRenderCheckboxFilter {...flowFilterProps} />
        <StyledSpaceSeparator />
        <MemoizedRenderCheckboxFilter {...postedByFilterProps} />
        <StyledSpaceSeparator />
        {mentionedByFilterProps && (
          <MemoizedRenderCheckboxFilter {...mentionedByFilterProps} />
        )}
        <StyledSpaceSeparator />
        <StyledFlex
          isKioskTurnedOn={isKioskTurnedOn}
          isRightAsideOpen={isRightAsideOpen}
        >
          <StyledButton
            color="primary"
            onClick={onSubmit}
            size="medium"
            status="default"
            disabled={isFilterButtonEnabled}
          >
            {APPLY_FILTERS}
          </StyledButton>
          <Button
            color="primary"
            onClick={onClear}
            size="medium"
            status="default"
            variant="text"
          >
            {CLEAR}
          </Button>
        </StyledFlex>
      </StyledFilterWrapper>
    </StyledScrollWrapper>
  );
};

export default LoadedComponent;
