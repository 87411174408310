import {
  GET_FLOW_BLOCK_PARTICIPANTS,
  GET_EXTERNAL_FLOW_BLOCK_PARTICIPANTS,
  GET_MEMBERS,
  GET_FLOW_PARTICIPANTS,
  GET_FLOW_VIEWERS,
  GET_MEMBERS_FROM_CRITERIA,
} from '../../constants/endpoints';

export const GET_MEMBERS_INFINITE = `${GET_MEMBERS}_INFINITE`;

export type GetMemberQueryConstants = Record<
  string,
  { endpointKey: string; uniqueQueryKey: string }
>;

export const memberQueryConstants: GetMemberQueryConstants = {
  [GET_MEMBERS]: {
    endpointKey: GET_MEMBERS,
    uniqueQueryKey: GET_MEMBERS_INFINITE,
  },
  [GET_FLOW_BLOCK_PARTICIPANTS]: {
    endpointKey: GET_FLOW_BLOCK_PARTICIPANTS,
    uniqueQueryKey: GET_FLOW_BLOCK_PARTICIPANTS,
  },
  [GET_EXTERNAL_FLOW_BLOCK_PARTICIPANTS]: {
    endpointKey: GET_EXTERNAL_FLOW_BLOCK_PARTICIPANTS,
    uniqueQueryKey: GET_EXTERNAL_FLOW_BLOCK_PARTICIPANTS,
  },
  [GET_FLOW_PARTICIPANTS]: {
    endpointKey: GET_FLOW_PARTICIPANTS,
    uniqueQueryKey: GET_FLOW_PARTICIPANTS,
  },
  [GET_FLOW_VIEWERS]: {
    endpointKey: GET_FLOW_VIEWERS,
    uniqueQueryKey: GET_FLOW_VIEWERS,
  },
  [GET_MEMBERS_FROM_CRITERIA]: {
    endpointKey: GET_MEMBERS_FROM_CRITERIA,
    uniqueQueryKey: GET_MEMBERS_FROM_CRITERIA,
  },
};
