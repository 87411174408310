import React, { useCallback, useEffect, useState, memo, useMemo } from 'react';

import ThemeV2 from '../../../componentsV2/theme';
import { getColor } from '../../../Utils/profilePic';

import {
  AvatarBackground,
  Img,
  AvatarContainer,
  StyledOnlineStatus,
  StyledAvatarText,
} from './styles';
import SVGIcon from '../SVGIcon';

import { AvatarProps } from './interfaces';

const Avatar = ({
  img,
  className: parentClassName,
  size = '24px',
  verticalAlign,
  name = '',
  onHoverClick,
  onlineStatus,
  onlineStatusBorderColor = ThemeV2.palette.white,
  isDeleted = false,
  isAnonymous = false,
  userId = '',
  fontSize = '14px',
  icon,
  iconSize,
  iconColor = ThemeV2.palette.gray3,
  iconBgColor = ThemeV2.palette.geekBlue6,
  backgroundColor = getColor({ _id: userId }).bg || '',
}: AvatarProps) => {
  const [loading, setLoading] = useState(true);
  const onLoadingComplete = useCallback(() => setLoading(false), []);

  useEffect(() => {
    if (!loading) {
      setLoading(true);
    }
    // THIS USE-EFFECT SHOULD ONLY BE CALLED WHEN IMAGE PROP IS CHANGED.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [img]);

  const canShowAvatarText = userId && name;

  const renderImageFallback = useMemo(() => {
    // If there is no image url or image not loaded
    if (!icon && loading) {
      if (canShowAvatarText) {
        let avatarText = '';
        let color = '';
        const user = { _id: userId };
        const colors = getColor(user);
        avatarText = name.slice(0, 1).toUpperCase();
        color = colors.color;
        return (
          <StyledAvatarText
            size={size}
            backgroundColor={backgroundColor}
            color={color}
            fontSize={fontSize}
            justifyContent="center"
            alignItems="center"
          >
            {avatarText}
          </StyledAvatarText>
        );
      }

      return (
        <SVGIcon icon="user" color={ThemeV2.palette.geekBlue1} size={size} />
      );
    }
    return null;
  }, [
    icon,
    loading,
    canShowAvatarText,
    userId,
    name,
    size,
    backgroundColor,
    fontSize,
  ]);

  if (isDeleted || isAnonymous) {
    return (
      <AvatarContainer
        size={size}
        isDeleted={false}
        restrictHeight={!isAnonymous}
        className={parentClassName}
        data-testid={`avatar-with-is-${isDeleted ? 'deleted' : 'anonymous'}`}
      >
        <SVGIcon
          {...(iconSize && { size: iconSize })}
          icon={isAnonymous ? 'anonymous-avatar' : 'person-filled'}
          color={ThemeV2.palette.gray6}
        />
      </AvatarContainer>
    );
  }

  return (
    <AvatarContainer
      size={size}
      className={parentClassName}
      iconBgColor={iconBgColor}
      data-testid="avatar"
      restrictHeight
    >
      {icon ? (
        <SVGIcon icon={icon} color={iconColor} />
      ) : (
        <Img
          src={img}
          alt={name ? `${name} avatar` : 'Avatar'}
          onLoad={onLoadingComplete}
          hide={loading}
          data-testid={img}
          loading="lazy"
          verticalAlign={verticalAlign}
        />
      )}
      {renderImageFallback}
      {onHoverClick && (
        <AvatarBackground
          data-testid="avatar-background"
          onClick={onHoverClick}
        >
          <SVGIcon icon="bin" color={ThemeV2.palette.gray1} />
        </AvatarBackground>
      )}
      {onlineStatus && (
        <StyledOnlineStatus
          onlineStatus={onlineStatus}
          onlineStatusBorderColor={onlineStatusBorderColor}
          data-testid="online-status"
        />
      )}
    </AvatarContainer>
  );
};

const MemoizedAvatar = memo(Avatar);
MemoizedAvatar.displayName = 'Avatar';

export default MemoizedAvatar;
