import styled from 'styled-components';
import themeV2 from '../../../componentsV2/theme';
import { device } from '../../../constants/layout';
import Body from '../../atoms/Body';

interface PaginationContainerProps {
  hasRightSpacing?: boolean;
}

export const PaginationContainer = styled.div<PaginationContainerProps>`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border: 1px solid ${themeV2.palette.gray5};
  background-color: ${themeV2.palette.white};
  border-radius: 4px 4px 0px 0px;
  align-items: center;
  padding: ${(props) => (props.hasRightSpacing ? '6px 55px 6px 24px' : '6px')};
  width: 100%;
  @media ${device.tabletV2} {
    flex-direction: row;
  }
`;

export const PaginatedButton = styled.button<{
  isCurrentPage?: boolean;
  isArrow?: boolean;
  nonClickable?: boolean;
}>`
  height: ${({ isArrow }) => (isArrow ? '16px' : '32px')};
  width: ${({ isArrow }) => (isArrow ? '16px' : '32px')};
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${({ nonClickable }) => (nonClickable ? 'default' : 'pointer')};
  outline: none;
  background-color: ${({ isCurrentPage }) =>
    isCurrentPage ? themeV2.palette.geekBlue2 : 'inherit'};
  color: ${({ isCurrentPage }) =>
    isCurrentPage ? themeV2.palette.geekBlue6 : themeV2.palette.gray8};
  border-radius: ${({ isCurrentPage }) => (isCurrentPage ? '100%' : '0')};
  & path {
    fill: ${themeV2.palette.gray8};
  }
  &:disabled {
    & path {
      fill: ${themeV2.palette.gray6};
    }
  }
`;

export const DropdownContainer = styled.div`
  display: none;
  @media ${device.tabletV2} {
    display: block;
  }
`;

export const PaginationStatus = styled(Body)`
  padding-bottom: 12px;
  @media ${device.tabletV2} {
    padding-bottom: 0;
  }
`;
