import React, { memo } from 'react';

import FeedPostHeader from '../../molecules/FeedPostHeader';
import { FeedPostHeaderProps } from '../../molecules/FeedPostHeader/interfaces';

import FeedPostBody from '../FeedPostBody';
import { FeedPostBodyProps } from '../FeedPostBody/interface';

import FeedPostReactions from '../FeedPostReactions';
import { FeedPostReactionsProps } from '../FeedPostReactions/interface';
import { FeedPostWrapper, FeedCommentInputWrapper } from './styles';
import PostInput, { PostInputProps } from '../PostInput';
import FeedPostComments, { FeedPostCommentsProps } from '../FeedPostComments';

export interface FeedPostProps {
  feedPostHeaderProps: FeedPostHeaderProps;
  feedPostBodyProps: FeedPostBodyProps;
  feedPostReactionsProps: FeedPostReactionsProps;
  feedPostCommentsProps?: FeedPostCommentsProps;
  feedCommentInputProps?: PostInputProps;
  isDummy?: boolean;
}

const FeedPost = (props: FeedPostProps) => {
  const {
    feedPostHeaderProps,
    feedPostBodyProps,
    feedPostReactionsProps,
    feedPostCommentsProps,
    feedCommentInputProps,
    isDummy = false,
  } = props;
  return (
    <FeedPostWrapper>
      <FeedPostHeader {...feedPostHeaderProps} isDummy={isDummy} />
      <FeedPostBody {...feedPostBodyProps} />
      <FeedPostReactions {...feedPostReactionsProps} />
      {feedPostCommentsProps && (
        <FeedPostComments {...feedPostCommentsProps} isDummy={isDummy} />
      )}
      {feedCommentInputProps && (
        <FeedCommentInputWrapper>
          <PostInput {...feedCommentInputProps} />
        </FeedCommentInputWrapper>
      )}
    </FeedPostWrapper>
  );
};

const MemoizedFeedPost = memo(FeedPost);
MemoizedFeedPost.displayName = 'FeedPost';
export default MemoizedFeedPost;
