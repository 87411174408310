import React, { useCallback } from 'react';
import Collapse from '@material-ui/core/Collapse';
import {
  FlowAccordionItemProps,
  FlowAccordionItemState,
  FlowAccordionItemValidations,
} from './interface';
import {
  AccordionItemBody,
  AccordionItemHeader,
  AccordionSubTitle,
  AccordionTitle,
  StatusIcon,
  StyledAccordionIcon,
  StyledArrowIcon,
  TitleContent,
  ValidationText,
  Wrapper,
} from './styles';
import { INFORMATION, REQUIRED } from '../../../languages/en/singleWords';

const FlowAccordionItem = (props: FlowAccordionItemProps) => {
  const {
    isOpen,
    icon,
    title,
    subtitle,
    titleContent,
    validation,
    state = FlowAccordionItemState.Blur,
    children,
    onAccordionItemClick,
    dataTestId,
  } = props;

  const handleKeyPress = (event: { key: string }) => {
    if (event.key === 'Enter') {
      onAccordionItemClick();
    }
  };

  const notDisabled = state !== FlowAccordionItemState.Disabled;
  const hasWarning = validation === FlowAccordionItemValidations.Warning;
  const hasError = validation === FlowAccordionItemValidations.Error;

  const getIcon = useCallback(() => {
    if (hasError) return 'error-warning';
    if (hasWarning) return 'warning-circle';
    return 'checkmark-circle';
  }, [hasError, hasWarning]);

  return (
    <Wrapper data-testid={dataTestId}>
      <AccordionItemHeader
        type="button"
        onClick={onAccordionItemClick}
        state={state}
        data-testid="accordionItemHeader"
        onKeyPress={handleKeyPress}
        tabIndex={notDisabled ? 0 : -1}
        isOpen={isOpen}
      >
        <StyledArrowIcon
          icon={isOpen ? 'carat-left' : 'carat-right'}
          size="16px"
          rotate={90}
          state={state}
          data-testid="accordionItemCaratIcon"
        />
        <StyledAccordionIcon
          icon={icon}
          size="16px"
          state={state}
          data-testid="accordionItemIcon"
        />
        <AccordionTitle variant="body3Medium" state={state}>
          {title}
        </AccordionTitle>
        <AccordionSubTitle variant="body3" state={state} color="gray9">
          {subtitle}
        </AccordionSubTitle>
        {titleContent && notDisabled && !(hasWarning || hasError) && (
          <TitleContent state={state}>{titleContent}</TitleContent>
        )}
        {notDisabled && (hasWarning || hasError) && (
          <ValidationText
            variant="body3"
            state={state}
            validation={validation || FlowAccordionItemValidations.None}
          >
            {`${INFORMATION} ${REQUIRED}`}
          </ValidationText>
        )}
        {notDisabled &&
          validation &&
          validation !== FlowAccordionItemValidations.None && (
            <StatusIcon
              icon={getIcon()}
              validation={validation}
              state={state}
              size="16px"
              data-testid="accordionItemValidationIcon"
            />
          )}
      </AccordionItemHeader>
      {notDisabled && (
        <AccordionItemBody>
          <Collapse in={isOpen}>{children}</Collapse>
        </AccordionItemBody>
      )}
    </Wrapper>
  );
};

export default FlowAccordionItem;
