import { trackEvent } from '../index';
import { FlowInsightsEventProps, OnboardingEventProps } from '../interfaces';
import { AnalyticsEvents, FLOW_INSIGHTS_EVENTS } from '../constants';

const trackFlowInsightsShowEvent = (eventProps: FlowInsightsEventProps) => {
  trackEvent(FLOW_INSIGHTS_EVENTS, undefined, {
    ...eventProps,
    event: AnalyticsEvents.SHOW,
  });
};

const trackFlowInsightsActionEvent = (eventProps: OnboardingEventProps) => {
  trackEvent(FLOW_INSIGHTS_EVENTS, undefined, {
    ...eventProps,
    event: AnalyticsEvents.ACTION,
  });
};

export { trackFlowInsightsShowEvent, trackFlowInsightsActionEvent };
