import { useMemo, Key, useCallback } from 'react';
import usePageNavigationLogic from '../../../controllers/rewards/usePageNavigationLogic';

const usePaginationLogic = ({
  itemCount,
  onHandlePaginationChange,
  itemsPerPage,
  itemsPerPageValues,
  onItemsPerPageValueChange,
  pageNumber,
}: {
  itemCount: number;
  pageNumber: number;
  onHandlePaginationChange: (pageNumber: number) => void;
  itemsPerPage: number;
  itemsPerPageValues: number[];
  onItemsPerPageValueChange: (value: number) => void;
}) => {
  const {
    models: { numberOfPages },
    operations: {
      onNextButtonClick: onNextPageClick,
      onPreviousButtonClick: onPreviousPageClick,
    },
  } = usePageNavigationLogic({
    pageNumber,
    itemCount,
    itemsPerPage,
    onHandlePaginationChange,
  });
  const dropdownValues = useMemo(
    () =>
      itemsPerPageValues.map((val) => ({
        id: val,
        value: val.toString(),
      })),
    [itemsPerPageValues],
  );
  const arrayOfPageNumbers = useMemo(() => {
    const totalPages = Math.ceil(itemCount / itemsPerPage);
    const arrOfPageNumbers: {
      value: number;
      showDots?: boolean;
    }[] = new Array(totalPages)
      .fill(undefined)
      .map((i, index) => ({ value: index + 1 }));
    if (arrOfPageNumbers.length <= 6) {
      return arrOfPageNumbers;
    }
    if (pageNumber <= 3) {
      arrOfPageNumbers.splice(4, totalPages - 5, {
        value: 0,
        showDots: true,
      });
      return arrOfPageNumbers;
    }
    if (pageNumber >= totalPages - 2) {
      arrOfPageNumbers.splice(1, totalPages - 5, {
        value: -1,
        showDots: true,
      });
      return arrOfPageNumbers;
    }
    return [
      { value: 1 },
      { value: -1, showDots: true },
      { value: pageNumber - 1 },
      { value: pageNumber },
      { value: pageNumber + 1 },
      { value: 0, showDots: true },
      { value: totalPages },
    ];
  }, [itemCount, pageNumber, itemsPerPage]);
  const onDropdownValueChange = useCallback(
    (key: Key) => {
      onHandlePaginationChange(1);
      onItemsPerPageValueChange(parseInt(key.toString(), 10));
    },
    [onHandlePaginationChange, onItemsPerPageValueChange],
  );
  const onPageClick = useCallback(
    (num: number) => {
      onHandlePaginationChange(num);
    },
    [onHandlePaginationChange],
  );

  return {
    models: {
      numberOfPages,
      arrayOfPageNumbers,
      dropdownValues,
    },
    operations: {
      onNextPageClick,
      onPreviousPageClick,
      onPageClick,
      onDropdownValueChange,
    },
  };
};

export default usePaginationLogic;
