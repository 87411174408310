import { useMutation, useQueryClient } from 'react-query';
import {
  GET_ACTIVE_FLOWS,
  GET_FLOW_DETAILS,
  HIDE_FLOW_ACTIVITY,
  SHOW_FLOW_ACTIVITY,
} from '../../../../constants/endpoints';
import { makeAPICall } from '../../../utils';
import { ArchiveFlowPayload } from '../RunFlow/types';

export const useHideFlowActivity = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (payload: ArchiveFlowPayload) =>
      makeAPICall(HIDE_FLOW_ACTIVITY, undefined, undefined, {
        flowId: payload.flowId,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_ACTIVE_FLOWS);
        queryClient.invalidateQueries(GET_FLOW_DETAILS);
      },
    },
  );
};

export const useShowFlowActivity = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (payload: ArchiveFlowPayload) =>
      makeAPICall(SHOW_FLOW_ACTIVITY, undefined, undefined, {
        flowId: payload.flowId,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_ACTIVE_FLOWS);
        queryClient.invalidateQueries(GET_FLOW_DETAILS);
      },
    },
  );
};
