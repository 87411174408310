import {
  MenuItemIndividualItem,
  MenuItemProps,
} from '../../../atomic/molecules/Dropdown_V2/interfaces';
import { VisibilityTypes } from './types';
import { RadioOptionProps } from '../../../atomic/molecules/RadioGroup';
import {
  BUILDER_VISIBILITY_BLOCK,
  SHARE_SHEET_MODAL,
} from '../../../languages/en/flows/builder';

export const visibilityRadioOptions: RadioOptionProps[] = [
  {
    value: VisibilityTypes.ENTIRE_ORGANIZATION,
    label: BUILDER_VISIBILITY_BLOCK.ENTIRE_ORGANIZATION,
  },
  {
    value: VisibilityTypes.PARTICIPANTS_ONLY,
    label: BUILDER_VISIBILITY_BLOCK.PARTICIPANTS_ONLY,
  },
  {
    value: VisibilityTypes.OWNER_ONLY,
    label: BUILDER_VISIBILITY_BLOCK.OWNER_ONLY,
  },
  {
    value: VisibilityTypes.CUSTOM,
    label: BUILDER_VISIBILITY_BLOCK.CUSTOM,
  },
];

export enum AnonymousSettings {
  ANONYMOUS_OFF = 'anonymousOff',
  ANONYMOUS_ON = 'anonymousOn',
  ANONYMOUS_OPTIONAL = 'anonymousOptional',
}

export const anonymousSettingsOptions: MenuItemIndividualItem[] = [
  {
    id: AnonymousSettings.ANONYMOUS_OFF,
    value: SHARE_SHEET_MODAL.ALL_RESPONSES_ARE_NOT_ANONYMOUS,
  },
  {
    id: AnonymousSettings.ANONYMOUS_OPTIONAL,
    value: SHARE_SHEET_MODAL.ALL_RESPONSES_ARE_OPTIONALLY_ANONYMOUS,
  },
  {
    id: AnonymousSettings.ANONYMOUS_ON,
    value: SHARE_SHEET_MODAL.ALL_RESPONSES_ARE_ANONYMOUS,
  },
];

export const anonymousSettingsMenuItems: MenuItemProps[] = [
  {
    id: 'anonymousSettingsMenuItems',
    items: anonymousSettingsOptions,
  },
];
