import React from 'react';

import { StyledButton } from '../styles';
import DateFilter from '../../DateFilter';
import Button from '../../../atoms/Button';
import { FlowInsightsFilterProps } from './types';
import CheckboxFilter from '../../CheckboxFilter';
import { Flex } from '../../../../Utils/styles/display';
import { CLEAR } from '../../../../languages/en/singleWords';
import { APPLY_FILTERS } from '../../../../languages/en/flows';
import { FILTER_BY_LABEL } from '../../../../languages/en/flows/insights';

import {
  ButtonWrapper,
  FilterWrapper,
  SpaceSeparator,
  StyledDrawerHeaderWithIcon,
} from './style';

const FlowInsightsFilter = (props: FlowInsightsFilterProps) => {
  const {
    onClear,
    onSubmit,
    toggleRightAsideOpen,
    dateFilterProps,
    departmentsFilter,
    participantFilter,
    managerStatusFilter,
    isFilterButtonEnabled,
    occurrenceStartDateFilter,
    hasShortcut,
  } = props;

  const hasOccurrence =
    occurrenceStartDateFilter &&
    'options' in occurrenceStartDateFilter &&
    occurrenceStartDateFilter.options.length > 0;

  return (
    <>
      <StyledDrawerHeaderWithIcon
        icon="filter-by"
        onClick={toggleRightAsideOpen}
      >
        {FILTER_BY_LABEL}
      </StyledDrawerHeaderWithIcon>
      <FilterWrapper>
        <DateFilter {...dateFilterProps} />
        <SpaceSeparator />

        {hasOccurrence && !hasShortcut && (
          <>
            <CheckboxFilter {...occurrenceStartDateFilter} />
            <SpaceSeparator />
          </>
        )}

        <CheckboxFilter {...departmentsFilter} />
        <SpaceSeparator />

        <CheckboxFilter {...managerStatusFilter} />
        <SpaceSeparator />

        <CheckboxFilter {...participantFilter} />
        <SpaceSeparator />
      </FilterWrapper>

      <ButtonWrapper>
        <Flex>
          <StyledButton
            size="medium"
            color="primary"
            status="default"
            onClick={onSubmit}
            disabled={!isFilterButtonEnabled}
          >
            {APPLY_FILTERS}
          </StyledButton>
          <Button
            size="medium"
            variant="text"
            color="primary"
            status="default"
            onClick={onClear}
          >
            {CLEAR}
          </Button>
        </Flex>
      </ButtonWrapper>
    </>
  );
};

export default FlowInsightsFilter;
