import { FormikErrors, FormikTouched } from 'formik';
import { OpenEndedBlockValue, StaticBlockState } from '../../interfaces/Flow';

import {
  GENERAL_BLOCK_ERROR,
  VALIDATION_ERRORS,
} from '../../languages/en/flows/participation';
import { getCharacterCount } from '../../Utils/draftjs';

export const generateBlockErrors = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formikErrors: FormikErrors<Record<string, any>>,
  hasAttemptedPost: boolean,
) => {
  const blockErrors: { [key: string]: string } = {};

  if (!hasAttemptedPost) {
    return blockErrors;
  }

  Object.keys(formikErrors).forEach((fieldId) => {
    const error = formikErrors[fieldId];
    if (error === VALIDATION_ERRORS.REQUIRED) {
      blockErrors[fieldId] = error;
    } else {
      blockErrors[fieldId] = GENERAL_BLOCK_ERROR;
    }
  });

  return blockErrors;
};

export const generateFieldErrors = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formikErrors: FormikErrors<Record<string, any>>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formikTouched: FormikTouched<Record<string, any>>,
) => {
  const fieldErrors: { [key: string]: string } = {};

  Object.keys(formikErrors).forEach((fieldId) => {
    const error = formikErrors[fieldId];
    const touched = formikTouched[fieldId];
    if (touched && error !== VALIDATION_ERRORS.REQUIRED) {
      fieldErrors[fieldId] = error as string;
    }
  });

  return fieldErrors;
};

export const isRequiredError = (error: string) => {
  return error === VALIDATION_ERRORS.REQUIRED;
};

export const isBlockEmpty = (
  staticBlockData: StaticBlockState,
  blockValue: any,
) => {
  switch (staticBlockData.type) {
    case 'MULTI_SELECT_DROPDOWN':
    case 'MULTI_CHOICE_MULTI_SELECT':
    case 'MULTI_PERSON_SELECTOR_DROPDOWN': {
      return blockValue.length === 0;
    }
    case 'OPEN_ENDED': {
      const { editorState } = blockValue as OpenEndedBlockValue;
      return getCharacterCount(editorState) === 0;
    }
    case 'FILE_UPLOAD': {
      return blockValue.length === 0;
    }
    case 'SINGLE_SELECT_DROPDOWN':
    case 'SINGLE_PERSON_SELECTOR_DROPDOWN':
    case 'SCALE': {
      return blockValue === null;
    }
    case 'GIVE_POINTS_STACK':
    case 'TROPHIES': {
      return blockValue === '';
    }
    case 'GIF_SELECTOR':
    default: {
      return !blockValue;
    }
  }
};

export const isBlockContentValid = (
  staticBlockData: StaticBlockState,
  blockValue: any,
) => {
  switch (staticBlockData.type) {
    case 'OPEN_ENDED': {
      const { editorState, isUploading } = blockValue as OpenEndedBlockValue;
      const { maxCharacters, minCharacters } = staticBlockData;
      const { gifUrl, files = [] } = blockValue;
      const characterCount = getCharacterCount(editorState);

      if (minCharacters !== undefined || maxCharacters !== undefined) {
        const minimumLimit = minCharacters || 0;
        const maxLimit = maxCharacters || Infinity;
        return (
          characterCount > 0 &&
          maxLimit >= characterCount &&
          characterCount >= minimumLimit
        );
      }
      return characterCount > 0 || gifUrl || files.length > 0 || !isUploading;
    }
    case 'MULTI_PERSON_SELECTOR_DROPDOWN':
    case 'MULTI_SELECT_DROPDOWN': {
      const { maxOptions = Infinity, minOptions = 0 } = staticBlockData;
      const optionsSelected: number = blockValue?.length || -1;
      return maxOptions >= optionsSelected && optionsSelected >= minOptions;
    }
    default: {
      return !isBlockEmpty(staticBlockData, blockValue);
    }
  }
};

export const STEP_BUTTON_STATUS = {
  NEXT: 'next',
  PREV: 'prev',
};
