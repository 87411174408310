import styled from 'styled-components';
import React, { useCallback, useRef, useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import Body from '../../atoms/Body';
import UserNameCard from '../UserNameCard';
import { isDeactivatedUser } from '../../../Utils/user';
import { UserListDropdownWithPaginationProps } from './type';
import MembersListEntryLoader from '../MembersListEntry/Loader';
import { ComponentStatus } from '../../../interfaces/component';
import { getVisibilityTooltipText } from '../../../Utils/flows';
import useOnClickOutside from '../../../hooks/useOnOutsideClick';
import {
  PopoverContentWrapper,
  StyledPopover,
  StyledTextField,
  UserListItem,
  VisibilityText,
} from '../UserListDropdown/styles';
import MembersListError from '../../organism/AssemblyMembers/Error';
import { NO_MEMBERS_FOUND_THAT_MATCH_YOUR_CRITERIA } from '../../../languages/en/home/mainFeedDrawer';

const InfiniteScrollWrapper = styled.div`
  max-height: 450px;
  overflow-y: auto;
`;

const StyledNoMatchFoundText = styled(Body)`
  text-align: center;
  padding: 8px;
`;

const UserListDropdownWithPagination = (
  props: UserListDropdownWithPaginationProps,
) => {
  const {
    users,
    isError,
    children,
    isLoading,
    viewCount,
    visibility,
    placeholder,
    onMemberClick,
    fetchMoreMembers,
    memberSearchValue,
    hasMoreUsersToFetch,
    disableUserInteraction,
    canPopoverTriggerByClick,
    onMemberSearchValueChange,
    onPopOverOpen,
    onPopOverClose,
    position,
  } = props;

  const popoverAnchor = useRef(null);
  const popoverComponent = useRef(null);
  const [openedPopover, setOpenedPopover] = useState(false);

  const handlePopoverOpen = useCallback(() => {
    if (!canPopoverTriggerByClick) {
      setOpenedPopover(true);
    }
  }, [canPopoverTriggerByClick]);

  const handlePopoverClose = useCallback(() => {
    if (!canPopoverTriggerByClick) {
      setOpenedPopover(false);
    }
    if (onPopOverOpen) {
      onPopOverOpen();
    }
  }, [canPopoverTriggerByClick, onPopOverOpen]);

  const handlePopoverClick = useCallback(() => {
    if (canPopoverTriggerByClick) {
      setOpenedPopover(true);
    }
    if (onPopOverClose) {
      onPopOverClose();
    }
  }, [canPopoverTriggerByClick, onPopOverClose]);

  const handleKeypress = (event: { key: string }) => {
    if (event.key === 'Enter') {
      setOpenedPopover(true);
    }
  };

  const handlePopoverOutsideClick = () => {
    setOpenedPopover(false);
  };

  useOnClickOutside(popoverComponent, handlePopoverOutsideClick);

  const handleUserSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    onMemberSearchValueChange(e.target.value);
  };

  useEffect(() => {
    if (!openedPopover) {
      onMemberSearchValueChange('');
    }
  }, [onMemberSearchValueChange, openedPopover]);

  return (
    <>
      <div
        role="link"
        ref={popoverAnchor}
        aria-owns="mouse-over-popover"
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        onClick={handlePopoverClick}
        onKeyPress={handleKeypress}
        tabIndex={0}
      >
        {children}
      </div>
      <StyledPopover
        ref={popoverComponent}
        id="mouse-over-popover"
        open={openedPopover}
        anchorEl={popoverAnchor.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: position ? 'left' : 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          onMouseEnter: handlePopoverOpen,
          onMouseLeave: handlePopoverClose,
        }}
        data-testid="user-dropdown-popover"
      >
        <PopoverContentWrapper>
          <VisibilityText variant="body3" color="gray7">
            {getVisibilityTooltipText(visibility, viewCount)}
          </VisibilityText>
          <StyledTextField
            icon="search"
            onChange={handleUserSearch}
            placeholder={placeholder}
            value={memberSearchValue}
            autoFocus
          />

          <InfiniteScrollWrapper
            id="user-list-dropdown-scrollable-div"
            data-testid="user-list-dropdown-scrollable-div"
          >
            {users && (
              <InfiniteScroll
                dataLength={users.length}
                hasMore={hasMoreUsersToFetch}
                loader={<MembersListEntryLoader />}
                next={fetchMoreMembers}
                scrollableTarget="user-list-dropdown-scrollable-div"
              >
                {users.length > 0 ? (
                  users.map((user) => (
                    <UserListItem key={user.memberID}>
                      <UserNameCard
                        status={ComponentStatus.LOADED}
                        firstName={user.firstName}
                        lastName={user.lastName}
                        image={user.image}
                        imageSize="small"
                        userId={user.memberID}
                        isDeactivatedUser={isDeactivatedUser(user)}
                        onClick={() => {
                          if (onMemberClick) onMemberClick(user);
                        }}
                        disableUserInteraction={disableUserInteraction}
                        memberState={user.memberState}
                      />
                    </UserListItem>
                  ))
                ) : (
                  <StyledNoMatchFoundText variant="body3">
                    {NO_MEMBERS_FOUND_THAT_MATCH_YOUR_CRITERIA}
                  </StyledNoMatchFoundText>
                )}
              </InfiniteScroll>
            )}
            {isLoading && <MembersListEntryLoader />}
            {isError && <MembersListError />}
          </InfiniteScrollWrapper>
        </PopoverContentWrapper>
      </StyledPopover>
    </>
  );
};

export default UserListDropdownWithPagination;
