import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  KnowlegeCenterListItemWrapper,
  KnowledgeCenterListItemBody,
  KnowledgeCenterListItemImage,
  SVGKnowledgeCenterListItemIcon,
  KnowledgeCenterListItemAvatarWrapper,
  KnowledgeCenterListItemNameWrapper,
  KnowledgeCenterListItemUserWrapper,
} from './styles';
import Body from '../../atoms/Body';
import Avatar from '../../atoms/Avatar';
import { menuItemsWithoutDeletePostNoText } from './data';
import { useHistory } from 'react-router-dom';
import { V3_POST_PAGE } from '../../../constants/routes';
import { FeedAttachmentItemFromAPI } from '../../../interfaces/Feed';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import ShortcutActionListHolder from '../ShortcutActionList';
import useDropDown from '../../../hooks/useDropDown';
import { DropdownPlacement } from '../Dropdown_V2/interfaces';
import { assignFileTypeInfo } from '../../../Utils/file';

const KnowledgeCenterListItem = (props: {
  listItem: FeedAttachmentItemFromAPI;
  index: number;
  style: any;
  handleSetSelectedFile: (value: FeedAttachmentItemFromAPI) => void;
  openPreviewer: Dispatch<SetStateAction<boolean>>;
  onClickDownload: (listItemFile: FeedAttachmentItemFromAPI) => Promise<void>;
  copyPostLink: (flowId: string, responseId: string) => void;
}) => {
  const {
    listItem,
    index,
    style,
    handleSetSelectedFile,
    openPreviewer,
    onClickDownload,
    copyPostLink,
  } = props;
  const [listItemIcon, setListItemIcon] = useState('');
  const [isHovered, setIsHovered] = useState(false);

  const { push } = useHistory();

  const onOptionsSelect = (value: string | number) => {
    if (value === 'copy-link' && copyPostLink) {
      copyPostLink(listItem.response?.flowId, listItem.response?.responseId);
    }
    if (value === 'go-to-post') {
      push(
        `${V3_POST_PAGE}`.replace(
          ':postType',
          `flows?flowId=${listItem.response.flowId}&responseId=${listItem.response.responseId}`,
        ),
      );
    }
    if (value === 'download-file' && onClickDownload) {
      onClickDownload(listItem);
    }
    if (value === 'delete') {
      //  TODO: Not implemented on the BE yet.
    }
  };

  const { anchorProps } = useDropDown({
    menuItems: menuItemsWithoutDeletePostNoText,
    onItemClick: onOptionsSelect,
    isFullWidth: false,
  });

  useEffect(() => {
    assignFileTypeInfo(listItem.fileInfo?.type, undefined, setListItemIcon);
  }, [listItem.fileInfo?.type]);

  const { referenceElement, openDropdown, closeDropdown, anchorEl } =
    anchorProps;

  const listItemDate = format(
    parseISO(listItem.fileInfo?.createdAt || ''),
    'MMMM do, yyyy',
  );

  return (
    <KnowlegeCenterListItemWrapper
      onMouseEnter={() => {
        setIsHovered(true);
        openDropdown();
      }}
      onMouseLeave={() => {
        setIsHovered(false);
        closeDropdown();
      }}
      onClick={() => {
        openPreviewer(true);
        handleSetSelectedFile(listItem);
      }}
      data-testid={`knowledge-center-listItem-${index}`}
      ref={referenceElement}
      style={style}
    >
      <KnowledgeCenterListItemNameWrapper>
        {listItemIcon && listItemIcon !== 'imageFile' && (
          <SVGKnowledgeCenterListItemIcon icon={listItemIcon} size="32px" />
        )}
        {listItem.fileInfo?.thumbnails && listItemIcon === 'imageFile' && (
          <KnowledgeCenterListItemImage
            alt={listItem.fileInfo?.name}
            src={listItem.fileInfo?.thumbnails['360']}
          />
        )}
        <KnowledgeCenterListItemBody
          variant="body1Medium"
          color={isHovered ? 'geekBlue6' : 'darkGray9'}
        >
          {listItem.fileInfo?.name.split('.')[0]}
        </KnowledgeCenterListItemBody>
        <Body
          color={isHovered ? 'geekBlue6' : 'darkGray9'}
          variant="body1Medium"
        >
          .{listItem.fileInfo?.type}
        </Body>
      </KnowledgeCenterListItemNameWrapper>
      <KnowledgeCenterListItemUserWrapper>
        <KnowledgeCenterListItemAvatarWrapper>
          <Avatar
            img={listItem.respondent?.image}
            name={listItem.respondent?.name}
            userId={listItem.respondent?.memberID}
            size="16px"
            iconSize="16px"
            verticalAlign="baseline"
          />
        </KnowledgeCenterListItemAvatarWrapper>
        <KnowledgeCenterListItemBody variant="body2">
          {listItem.respondent?.name}
        </KnowledgeCenterListItemBody>
      </KnowledgeCenterListItemUserWrapper>
      <KnowledgeCenterListItemBody
        style={{ marginTop: '6px', width: '25%' }}
        variant="body2"
      >
        Shared on {listItemDate}
      </KnowledgeCenterListItemBody>
      <div ref={anchorEl}>
        <ShortcutActionListHolder
          {...anchorProps}
          dropdownPlacement={DropdownPlacement.Left}
          isDropdownPositionFixed={false}
        />
      </div>
    </KnowlegeCenterListItemWrapper>
  );
};

export default KnowledgeCenterListItem;
