import React, { useMemo, memo, useCallback } from 'react';

import { getAgoTime } from '../../../Utils/time';
import Avatar from '../../atoms/Avatar';
import Body from '../../atoms/Body';
import { Flex } from '../../../Utils/styles/display';
import { NewFlowsPostHeaderProps } from './interfaces';
import {
  StyledClickableLink,
  StyledVisibilityIcon,
  StyledFlowsPostHeaderContent,
} from './styles';
import ThemeV2 from '../../../componentsV2/theme';
import { FLOWS_FEED_POST_TYPE_TEXT } from '../../../languages/en/flows';
import ClickableLink from '../ClickableLink';
import { A, ANONYMOUS, MESSAGE, SENT } from '../../../languages/en/singleWords';
import {
  getVisibilityIcon,
  getVisibilityTooltipText,
} from '../../../Utils/flows';
import { PostTypes } from '../../../interfaces/Home';
import {
  ANNIVERSARY_TEXT,
  BIRTHDAY_TEXT,
  THE_ASSEMBLY,
} from '../../../languages/en/feed';
import { DEACTIVATED_USER } from '../../../languages/en/notifications';
import birthdayIcon from '../../atoms/SVGIcon/icons/birthday.svg';
import anniversaryIcon from '../../atoms/SVGIcon/icons/anniversary.svg';
import assemblyLogo from '../../atoms/SVGIcon/icons/assemblyLogo.svg';
import { mapHexCodeToEmoticon } from '../../../Utils/mappers';
import { YOU } from '../../../languages/en/home';
import { MemberState } from '../../../interfaces/user';
import { getFlowHeaderAttributesFromData } from '../../../Utils/home/feeds';
import { FeedItemKind, MemberInteractionType } from '../../../interfaces/Feed';

const AvatarComponent = ({
  postType,
  person,
  isAnonymous,
}: {
  postType: PostTypes;
  person: any;
  isAnonymous: boolean;
}) => {
  if (isAnonymous) {
    return <Avatar size="40px" fontSize="16px" iconSize="40px" isAnonymous />;
  }
  if (postType === PostTypes.DEFAULT) {
    return (
      <Avatar size="40px" fontSize="16px" img={assemblyLogo} name="assembly" />
    );
  }
  if (person) {
    return (
      <Avatar
        size="40px"
        fontSize="16px"
        img={person.image || ''}
        name={person.firstName || ''}
        userId={person.memberID || ''}
        isDeleted={person.memberState === MemberState.DEACTIVATED}
      />
    );
  }

  if (postType === PostTypes.BIRTHDAY) {
    return (
      <Avatar
        size="40px"
        fontSize="16px"
        img={birthdayIcon}
        name={BIRTHDAY_TEXT}
      />
    );
  }

  if (postType === PostTypes.ANNIVERSARY) {
    return (
      <Avatar
        size="40px"
        fontSize="16px"
        img={anniversaryIcon}
        name={ANNIVERSARY_TEXT}
      />
    );
  }

  return null;
};

const PersonComponent = ({
  postType,
  flow,
  handleFlowClick,
  person,
  currentUserId,
  onClickUserName,
  isAnonymous,
}: {
  postType: PostTypes;
  flow: any;
  handleFlowClick: () => void;
  person: any;
  currentUserId: string;
  onClickUserName: (member: MemberInteractionType) => void;
  isAnonymous: boolean;
}) => {
  {
    if (isAnonymous) {
      return <Body variant="body2Medium">{ANONYMOUS}</Body>;
    }
    if (postType === PostTypes.DEFAULT) {
      return (
        <ClickableLink data={flow} onClickCallback={handleFlowClick}>
          {THE_ASSEMBLY}
        </ClickableLink>
      );
    }
    if (person) {
      return (
        <>
          {person.memberState === MemberState.DEACTIVATED ? (
            <Body variant="body2Medium" color="gray6">
              {DEACTIVATED_USER}
            </Body>
          ) : (
            <ClickableLink
              data={person}
              onClickCallback={() =>
                onClickUserName({
                  memberID: person.memberID,
                  firstName: person.firstName,
                })
              }
            >
              {person.memberID === currentUserId ? YOU : person.firstName}
            </ClickableLink>
          )}
        </>
      );
    }

    if (postType === PostTypes.BIRTHDAY) {
      return (
        <Body variant="body2Bold" color="gray8" inline>
          {BIRTHDAY_TEXT}
        </Body>
      );
    }

    if (postType === PostTypes.ANNIVERSARY) {
      return (
        <Body variant="body2Bold" color="gray8" inline>
          {ANNIVERSARY_TEXT}
        </Body>
      );
    }

    return <Body variant="body2Medium">{ANONYMOUS}</Body>;
  }
};

const FlowsPostHeader = (props: NewFlowsPostHeaderProps) => {
  const { post, profileData, onFlowClick, onClickUserName, dropdownComponent } =
    props;

  const isAnonymous = useMemo(
    () =>
      post?.kind === FeedItemKind.INTERNAL_ANONYMOUS ||
      post?.kind === FeedItemKind.EXTERNAL_ANONYMOUS,
    [post?.kind],
  );

  const { createdAt, currentUserId, postType, flow, person, visibility } =
    useMemo(
      () => getFlowHeaderAttributesFromData(post, profileData),
      [post, profileData],
    );

  const isDefaultPost = postType === PostTypes.DEFAULT;

  const handleFlowClick = useCallback(
    () => onFlowClick(flow.flowId),
    [flow.flowId, onFlowClick],
  );

  return (
    <Flex padding="16px 16px 0" alignItems="start">
      <AvatarComponent
        isAnonymous={isAnonymous}
        person={person}
        postType={post.type}
      />
      <StyledFlowsPostHeaderContent>
        <Flex flexWrap="wrap">
          <PersonComponent
            postType={post.type}
            person={person}
            currentUserId={currentUserId}
            flow={flow}
            onClickUserName={onClickUserName}
            handleFlowClick={handleFlowClick}
            isAnonymous={isAnonymous}
          />
          <Body variant="body2" color="gray8">
            &nbsp;{isDefaultPost ? SENT : FLOWS_FEED_POST_TYPE_TEXT}
            &nbsp;
          </Body>
          {isDefaultPost ? (
            <>
              <ClickableLink
                onClickCallback={() =>
                  person &&
                  onClickUserName({
                    memberID: person.memberID,
                    firstName: person.firstName,
                  })
                }
              >
                {YOU}
              </ClickableLink>
              <Body variant="body2" color="gray8">
                &nbsp;{A} {MESSAGE}.
              </Body>
            </>
          ) : (
            <>
              <span>{mapHexCodeToEmoticon(flow.icon.value)}</span>
              <StyledClickableLink
                data={flow}
                onClickCallback={handleFlowClick}
              >
                {flow.name}
              </StyledClickableLink>
            </>
          )}
        </Flex>
        <Flex flexWrap="wrap">
          <Body inline variant="body3" color="gray7">
            {getAgoTime(createdAt)}
          </Body>
          {visibility && (
            <StyledVisibilityIcon
              icon={getVisibilityIcon(visibility)}
              iconColor={ThemeV2.palette.gray7}
              iconSize="16px"
            >
              {getVisibilityTooltipText(visibility)}
            </StyledVisibilityIcon>
          )}
        </Flex>
      </StyledFlowsPostHeaderContent>
      {dropdownComponent}
    </Flex>
  );
};

export default memo(FlowsPostHeader);
