import React, { useCallback, MouseEvent, useMemo } from 'react';
import Avatar from '../../atoms/Avatar';
import {
  NotificationWrapper,
  NotificationTextWrapper,
  TextContentWrapper,
  TimeAgoText,
  UnreadDot,
  NotificationLink,
  StyledBody,
} from './styles';
import { NotificationItemProps } from './interface';
import SVGIcon from '../../atoms/SVGIcon';
import { truncateText } from '../../../Utils/formatting';
import {
  generateV3PostRoute,
  generateFlowResponseRoute,
  V2_HOME,
} from '../../../constants/routes';
import ThemeV2 from '../../../componentsV2/theme';
import { EmoticonHolderSizes } from '../../atoms/EmoticonHolder/interface';
import EmoticonHolder from '../../atoms/EmoticonHolder';
import { ANSWER } from '../../../languages/en/singleWords';
import { REMINDER_LABEL } from '../../../languages/en/notifications';
import IconContainer from '../../atoms/IconContainer';
import { NotificationTypes } from '../../../interfaces/Notifications';
import {
  COMMENTED_ON_YOUR_POST,
  MENTIONED_YOU_IN_FLOW,
  MENTIONED_YOU_IN_FLOW_COMMENT,
} from '../../../Utils/languages/notifications';

const NotificationItem = (props: NotificationItemProps) => {
  const {
    timeAgo,
    avatar,
    onClick,
    closePopover,
    read,
    bodyText = '',
    headerText,
    icon,
    id,
    postID,
    notificationType = '',
    emoticon = '',
    emoticonBg = ThemeV2.palette.cyan2,
    flowId = '',
    occurrenceId = '',
    responseId = '',
    commentId = '',
  } = props;

  const onNotificationClick = useCallback(() => {
    if (onClick) {
      onClick({ notificationType, read, id, flowId, occurrenceId });
    }
    if (closePopover) {
      closePopover();
    }
  }, [closePopover, flowId, id, notificationType, occurrenceId, onClick, read]);

  const onReadDotClick = useCallback((event: MouseEvent) => {
    event.preventDefault();
  }, []);

  const truncateTextLimit = useMemo(() => {
    return 60 - headerText.length;
  }, [headerText.length]);

  const isFlowComment = useMemo(() => {
    return notificationType === NotificationTypes.COMMENT && responseId;
  }, [notificationType, responseId]);

  const notificationItemLink = useCallback(() => {
    if (isFlowComment || notificationType === NotificationTypes.MENTIONS) {
      const notificationResponseId =
        typeof responseId === 'string' ? responseId : responseId?._id;
      if (flowId && notificationResponseId) {
        return generateFlowResponseRoute(flowId, notificationResponseId);
      }

      if (postID) {
        return generateV3PostRoute(postID);
      }

      return V2_HOME;
    }

    return postID ? generateV3PostRoute(postID) : V2_HOME;
  }, [flowId, isFlowComment, notificationType, postID, responseId]);

  const renderImage = useMemo(() => {
    if (notificationType === NotificationTypes.FLOW_TRIGGERED) {
      return (
        <EmoticonHolder
          emoticon={emoticon}
          size={EmoticonHolderSizes.Medium3}
          color={emoticonBg}
          isRounded
        />
      );
    }

    if (notificationType === NotificationTypes.FLOW_REMINDER) {
      return (
        <IconContainer bgColor={ThemeV2.palette.orange2} borderRadius="50%">
          <SVGIcon color={ThemeV2.palette.orange9} icon="active-notification" />
        </IconContainer>
      );
    }

    if (icon) {
      return <SVGIcon icon={icon} size="40px" />;
    }

    if (avatar) {
      return <Avatar {...avatar} size="40px" />;
    }

    return null;
  }, [avatar, emoticon, emoticonBg, icon, notificationType]);

  const renderContent = useMemo(() => {
    let headerTextElement = (
      <StyledBody variant="body3Medium" inline>
        {headerText.length >= 80 ? truncateText(67, headerText) : headerText}
      </StyledBody>
    );

    if (notificationType === NotificationTypes.FLOW_TRIGGERED) {
      headerTextElement = (
        <StyledBody variant="body3Medium" inline>
          {bodyText.length >= 80 ? truncateText(67, bodyText) : bodyText}
        </StyledBody>
      );
      const answerTextElement = (
        <StyledBody variant="body3" inline>
          {ANSWER}
        </StyledBody>
      );
      return (
        <TextContentWrapper read={read || false}>
          {answerTextElement} {headerTextElement}
        </TextContentWrapper>
      );
    }

    if (notificationType === NotificationTypes.FLOW_REMINDER) {
      headerTextElement = (
        <StyledBody variant="body3Medium" inline>
          {bodyText.length >= 80 ? truncateText(67, bodyText) : bodyText}
        </StyledBody>
      );
      const reminderTextElement = (
        <StyledBody variant="body3" inline>
          {REMINDER_LABEL}
        </StyledBody>
      );
      return (
        <TextContentWrapper read={read || false}>
          {reminderTextElement} {headerTextElement}
        </TextContentWrapper>
      );
    }

    if (isFlowComment) {
      headerTextElement = (
        <StyledBody variant="body3Medium" inline>
          {headerText.length >= 80 ? truncateText(67, headerText) : headerText}
        </StyledBody>
      );
      const commentedOnFlow = (
        <>
          <StyledBody variant="body3" inline>
            {COMMENTED_ON_YOUR_POST}
          </StyledBody>{' '}
          <StyledBody variant="body3Medium" inline>
            {bodyText.length >= 80 ? truncateText(67, bodyText) : bodyText}
          </StyledBody>
        </>
      );
      return (
        <TextContentWrapper read={read || false}>
          {headerTextElement} {commentedOnFlow}
        </TextContentWrapper>
      );
    }

    const bodyTextElement = (
      <StyledBody variant="body3" inline>
        {headerText.length < 80 && truncateText(truncateTextLimit, bodyText)}
      </StyledBody>
    );

    if (
      notificationType === NotificationTypes.MENTIONS ||
      notificationType === NotificationTypes.TAGGED_IN_POST
    ) {
      const mentionedText = commentId
        ? MENTIONED_YOU_IN_FLOW_COMMENT
        : MENTIONED_YOU_IN_FLOW;
      return (
        <TextContentWrapper read={read || false}>
          {headerTextElement}{' '}
          <StyledBody variant="body3" inline>
            {mentionedText}{' '}
          </StyledBody>
          <StyledBody variant="body3Medium" inline>
            {headerText.length < 80 &&
              truncateText(truncateTextLimit, bodyText)}
          </StyledBody>
        </TextContentWrapper>
      );
    }

    return (
      <TextContentWrapper read={read || false}>
        {headerTextElement} {bodyTextElement}
      </TextContentWrapper>
    );
  }, [
    bodyText,
    commentId,
    headerText,
    isFlowComment,
    notificationType,
    read,
    truncateTextLimit,
  ]);

  if (
    Object.values(NotificationTypes).includes(
      notificationType as NotificationTypes,
    )
  ) {
    return (
      <NotificationLink to={notificationItemLink}>
        <NotificationWrapper onClick={onNotificationClick} read={read}>
          {renderImage}
          <NotificationTextWrapper>
            {renderContent}
            {timeAgo && (
              <TimeAgoText variant="body3" inline read={read || false}>
                {timeAgo}
              </TimeAgoText>
            )}
          </NotificationTextWrapper>
          {!read && (
            <UnreadDot
              onClick={onReadDotClick}
              data-testid="unread-indicator"
            />
          )}
        </NotificationWrapper>
      </NotificationLink>
    );
  }
  return null;
};

export default NotificationItem;
