import React, { useCallback, useMemo } from 'react';

import Body from '../../../atomic/atoms/Body';
import ShareSheetModal from '../../../atomic/organism/ShareSheetModal';
import ShareSheetAnonymousSettings from '../../../atomic/molecules/ShareSheet/ShareSheetAnonymousSettings';

import BasicModalTemplate, {
  BasicModalTemplateProps,
} from '../../../atomic/templates/BasicModalTemplate';

import { COPY_LINK, GET_HELP } from '../../../languages/en/flows';
import { BACK, CANCEL, CONFIRM, DONE } from '../../../languages/en/singleWords';
import {
  ANONYMITY_LINK_SETTINGS_BANNER_TEXT,
  ANYONE_WITH_LINK,
  SHARE_SHEET_CONFIRMATION_MODAL,
} from '../../../languages/en/flows/sharesheet';
import ThemeV2 from '../../../componentsV2/theme';

import {
  GET_HELP_LINK,
  SHARE_SHEET_MODAL,
} from '../../../languages/en/flows/builder';

import { StyledShareSheetBasicModalTemplate } from './styles';
import { ShareSheetModalButtonProps, ShareSheetModalScreen } from './types';

import useFlowsShareSheetController from './useFlowsShareSheetController';
import LinkAccessSettingsController from './LinkAccessSettingsController';
import ShareSheetVisibilityController from './ShareSheetVisibilityController';
import ShareSheetParticipantsController from './ShareSheetParticipantsController';
import { useFeatureSplit } from '../../../hooks/useFeatureSplit';
import {
  SplitNames,
  TreatmentTypes,
} from '../../../hooks/useSplitSdkConfig/constants';
import { anonymousSettingsMenuItems } from './data';
// import { motion } from 'framer-motion';

const FlowsShareSheetController = () => {
  const {
    flowName,
    subtitleContent,
    visibilityCount,
    participantsCount,
    showInDrawerValue,
    setSelectedLinkAccessType,
    selectedLinkAccessType,
    selectedLinkPermissionRole,
    currentModalScreen,
    isShareSheetModalOpen,
    visibilityTitleContent,
    isConfirmationModalOpen,
    currentVisibilityBlockData,
    isUpdatingFlowAccessPolicy,
    currentParticipantsBlockData,
    visibilityParticipantsCriteria,
    handleOnUpdateSelectionClick,
    handleOnLinkSaveButtonClick,
    handleOnLinkBackButtonClick,
    anonymousSettings,
    handleAnonymousSettingsSelection,
    handleChooseViewers,
    handleBackButtonClick,
    handleCopyFlowFeedLink,
    setShareSheetModalOpen,
    handleChooseParticipants,
    handleConfirmButtonClick,
    handleOnDoneButtonClicked,
    setSelectedLinkPermission,
    updateParticipantsBlockData,
    setIsConfirmationModalClose,
    handleOnShareSheetModalClose,
    handleVisibilityBackButtonClick,
    updateCurrentVisibilityBlockData,
    handleVisibilityConfirmButtonClick,
    updateModalCurrentState,
    handleAnonymitySettingsConfirmButtonClick,
    handleOnFlowVisibleInFlowShareSheetChanged,
  } = useFlowsShareSheetController();

  const { treatment: anonymousSettingsTreatment } = useFeatureSplit(
    SplitNames.SHARESHEET_ANONYMOUS_SETTINGS,
  );
  const isAnonymousSettingsAvailable =
    anonymousSettingsTreatment === TreatmentTypes.ON;

  const renderModalContent = useCallback(() => {
    if (
      currentParticipantsBlockData &&
      currentModalScreen === ShareSheetModalScreen.PARTICIPANTS
    ) {
      return (
        <ShareSheetParticipantsController
          participantsBlockData={currentParticipantsBlockData}
          updateParticipantsBlockData={updateParticipantsBlockData}
        />
      );
    }

    if (currentModalScreen === ShareSheetModalScreen.VIEWERS) {
      return (
        <ShareSheetVisibilityController
          visibilityBlockData={currentVisibilityBlockData}
          updateVisibilityParticipantsBlockData={
            updateCurrentVisibilityBlockData
          }
          visibilityParticipantsCriteria={visibilityParticipantsCriteria}
        />
      );
    }

    if (currentModalScreen === ShareSheetModalScreen.ANONYMOUS_SETTINGS) {
      return (
        <ShareSheetAnonymousSettings
          title={SHARE_SHEET_MODAL.ANONYMOUS_SETTINGS_BLOCK.TITLE}
          subTitle={SHARE_SHEET_MODAL.ANONYMOUS_SETTINGS_BLOCK.SUBTITLE}
          options={anonymousSettingsMenuItems}
          handleOptionSelection={handleAnonymousSettingsSelection}
          selectedOption={anonymousSettings}
          infoAlert={
            selectedLinkAccessType === ANYONE_WITH_LINK
              ? {
                  icon: 'info-outlined',
                  alertType: 'custom',
                  text: (
                    <Body variant="body3" color="gray9" margin="2px 0 0 0">
                      {ANONYMITY_LINK_SETTINGS_BANNER_TEXT}
                    </Body>
                  ),
                  customVariant: {
                    background: ThemeV2.palette.geekBlue2,
                    iconColor: ThemeV2.palette.geekBlue6,
                  },
                }
              : undefined
          }
        />
      );
    }

    if (currentModalScreen === ShareSheetModalScreen.LINK_ACCESS_SETTINGS) {
      return (
        <LinkAccessSettingsController
          isFlowVisibleInFlowSheet={showInDrawerValue}
          onChangeLinkAccess={(e: string | number) => {
            setSelectedLinkAccessType(e.toString());
          }}
          onChangeLinkPermission={(e: string | number) =>
            setSelectedLinkPermission(e.toString())
          }
          viewersCount={visibilityCount}
          participantsCount={participantsCount}
          selectedLinkAccess={selectedLinkAccessType}
          selectedLinkPermission={selectedLinkPermissionRole}
          onUpdateSelectionClick={handleOnUpdateSelectionClick}
          onFlowVisibleInFlowSheetChanged={
            handleOnFlowVisibleInFlowShareSheetChanged
          }
        />
      );
    }

    return (
      <ShareSheetModal
        selectedLinkPermissionRole={selectedLinkPermissionRole}
        subtitleContent={subtitleContent}
        visibilityTitleContent={visibilityTitleContent}
        handleChooseParticipants={handleChooseParticipants}
        handleChooseViewers={handleChooseViewers}
        updateModalCurrentState={updateModalCurrentState}
        linkAccessType={selectedLinkAccessType}
        anonymousSettings={anonymousSettings}
      />
    );
  }, [
    currentParticipantsBlockData,
    currentModalScreen,
    subtitleContent,
    visibilityTitleContent,
    handleChooseParticipants,
    handleChooseViewers,
    updateModalCurrentState,
    selectedLinkAccessType,
    anonymousSettings,
    updateParticipantsBlockData,
    currentVisibilityBlockData,
    updateCurrentVisibilityBlockData,
    visibilityParticipantsCriteria,
    handleAnonymousSettingsSelection,
    showInDrawerValue,
    visibilityCount,
    participantsCount,
    selectedLinkPermissionRole,
    handleOnUpdateSelectionClick,
    handleOnFlowVisibleInFlowShareSheetChanged,
    setSelectedLinkAccessType,
    setSelectedLinkPermission,
  ]);

  const getModalButtonProps = useCallback(
    (
      onPrimaryButtonClick: () => void,
      onSecondaryButtonClick: () => void,
      onBackButtonClick: () => void,
    ) =>
      ({
        primaryButton: {
          text: CONFIRM,
          onClick: onPrimaryButtonClick,
          size: 'medium',
        },
        secondaryButton: {
          text: CANCEL,
          onClick: onSecondaryButtonClick,
          variant: 'text',
          color: 'secondary',
          size: 'medium',
        },
        headerButton: {
          text: BACK,
          onClick: onBackButtonClick,
          variant: 'text',
          size: 'medium',
          color: 'secondary',
          icon: 'arrow-left',
        },
      } as ShareSheetModalButtonProps),
    [],
  );

  type ModalProps = Omit<BasicModalTemplateProps, 'children' | 'onModalClose'>;
  const getModalProps: ModalProps = useMemo(() => {
    const baseProps = {
      childrenPadding: '24px 32px',
      isOpen: isShareSheetModalOpen,
    };
    if (currentModalScreen === ShareSheetModalScreen.PARTICIPANTS) {
      return {
        ...baseProps,
        childrenPadding: '0',
        heading: SHARE_SHEET_MODAL.PARTICIPANTS_BLOCK.MODAL_HEADING,
        hideCloseIcon: true,
        ...getModalButtonProps(
          handleConfirmButtonClick,
          handleBackButtonClick,
          handleBackButtonClick,
        ),
      };
    } else if (currentModalScreen === ShareSheetModalScreen.VIEWERS) {
      return {
        ...baseProps,
        childrenPadding: '0',
        heading: SHARE_SHEET_MODAL.VIEWERS_BLOCK.MODAL_HEADING,
        hideCloseIcon: true,
        ...getModalButtonProps(
          handleVisibilityConfirmButtonClick,
          handleVisibilityBackButtonClick,
          handleVisibilityBackButtonClick,
        ),
      };
    } else if (
      currentModalScreen === ShareSheetModalScreen.ANONYMOUS_SETTINGS
    ) {
      return {
        ...baseProps,
        heading: SHARE_SHEET_MODAL.ANONYMOUS_SETTINGS_BLOCK.MODAL_TITLE,
        hideCloseIcon: true,
        ...getModalButtonProps(
          handleAnonymitySettingsConfirmButtonClick,
          handleBackButtonClick,
          handleBackButtonClick,
        ),
        tertiaryButton: {
          text: GET_HELP,
          onClick: () => window.open(GET_HELP_LINK),
          variant: 'text',
          size: 'medium',
        },
      };
    } else if (
      currentModalScreen === ShareSheetModalScreen.LINK_ACCESS_SETTINGS
    ) {
      return {
        ...baseProps,
        heading: SHARE_SHEET_MODAL.LINK_SETTINGS_BLOCK.MODAL_HEADING,
        hideCloseIcon: true,
        ...getModalButtonProps(
          handleOnLinkSaveButtonClick,
          handleOnLinkBackButtonClick,
          handleOnLinkBackButtonClick,
        ),
        secondaryButton: {
          text: COPY_LINK,
          icon: 'Link',
          onClick: handleCopyFlowFeedLink,
          variant: 'text',
          color: 'primary',
          size: 'medium',
        },
        tertiaryButton: {
          text: GET_HELP,
          onClick: () => window.open(GET_HELP_LINK),
          variant: 'text',
          size: 'medium',
        },
      };
    }
    return {
      ...baseProps,
      ...(isAnonymousSettingsAvailable
        ? { childrenPadding: '24px 32px 8px 32px' }
        : {}),
      heading: `${SHARE_SHEET_MODAL.HEADING} ${flowName}`,
      primaryButton: {
        text: DONE,
        isLoading: isUpdatingFlowAccessPolicy,
        onClick: handleOnDoneButtonClicked,
        size: 'medium',
      },
      secondaryButton: {
        text: COPY_LINK,
        icon: 'Link',
        onClick: handleCopyFlowFeedLink,
        variant: 'text',
        color: 'primary',
        size: 'medium',
      },
      tertiaryButton: {
        text: GET_HELP,
        onClick: () => window.open(GET_HELP_LINK),
        variant: 'text',
        size: 'medium',
      },
    };
  }, [
    isShareSheetModalOpen,
    currentModalScreen,
    isAnonymousSettingsAvailable,
    flowName,
    isUpdatingFlowAccessPolicy,
    handleOnDoneButtonClicked,
    handleCopyFlowFeedLink,
    getModalButtonProps,
    handleConfirmButtonClick,
    handleBackButtonClick,
    handleVisibilityConfirmButtonClick,
    handleVisibilityBackButtonClick,
    handleAnonymitySettingsConfirmButtonClick,
    handleOnLinkSaveButtonClick,
    handleOnLinkBackButtonClick,
  ]);

  const overflowAttribute = useMemo(() => {
    if (
      currentModalScreen === ShareSheetModalScreen.PARTICIPANTS ||
      currentModalScreen === ShareSheetModalScreen.VIEWERS
    )
      return 'hidden';

    if (currentModalScreen === ShareSheetModalScreen.ANONYMOUS_SETTINGS)
      return 'inherit';

    return undefined;
  }, [currentModalScreen]);

  return (
    <>
      <BasicModalTemplate
        isOpen={isConfirmationModalOpen}
        heading={SHARE_SHEET_CONFIRMATION_MODAL.HEADING}
        primaryButton={{
          text: `${SHARE_SHEET_CONFIRMATION_MODAL.SAVE_CHANGES_LABEL}`,
          onClick: handleOnDoneButtonClicked,
        }}
        secondaryButton={{
          text: `${SHARE_SHEET_CONFIRMATION_MODAL.DISCARD_CHANGES_LABEL}`,
          onClick: () => setShareSheetModalOpen(false),
          color: 'secondary',
          variant: 'contained',
          status: 'warning',
        }}
        onModalClose={setIsConfirmationModalClose}
      >
        <Body variant="body2">{SHARE_SHEET_CONFIRMATION_MODAL.BODY_TEXT}</Body>
      </BasicModalTemplate>

      <StyledShareSheetBasicModalTemplate
        {...getModalProps}
        overflow={overflowAttribute}
        onModalClose={handleOnShareSheetModalClose}
      >
        {/* <motion.div
          key={currentModalScreen}
          initial={{ height: '0px', x: 0 }}
          animate={
            currentModalScreen && {
              x: 0,
              height: '100%',
            }
          }
        > */}
        {renderModalContent()}
        {/* </motion.div> */}
      </StyledShareSheetBasicModalTemplate>
    </>
  );
};

export default FlowsShareSheetController;
