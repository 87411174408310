import { CriteriaItemProps } from '../../molecules/CriteriaItem/types';
import { MenuItemProps } from '../../molecules/Dropdown_V2/interfaces';

export const criteriaItems: CriteriaItemProps[] = [
  {
    id: '123',
    icon: 'person-filled',
    criteria: 'Member',
    criteriaValue: 'Bharath',
    criteriaCondition: 'is',
    isLoading: false,
    onDismissClick: (id) => {
      console.log(id);
    },
  },
  {
    id: '124',
    icon: 'department',
    criteria: 'Department',
    criteriaValue: 'Engineering',
    criteriaCondition: 'is not',
    isLoading: false,
    onDismissClick: (id) => {
      console.log(id);
    },
  },
  {
    id: '125',
    icon: 'mail',
    criteria: 'Email',
    criteriaValue: 'barath@joinassembly.com',
    criteriaCondition: 'is',
    isLoading: false,
    onDismissClick: (id) => {
      console.log(id);
    },
  },
];

export const criteriaMenuItems: MenuItemProps[] = [
  {
    id: 'criteria-items',
    items: [
      {
        value: 'Participant matches any',
        id: 'Participant matches any',
      },
      {
        value: 'Participant matches all',
        id: 'Participant matches all',
      },
    ],
  },
];
