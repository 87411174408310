import styled from 'styled-components';
import SpinningLoader from '../../atoms/SpinnerLoader';

export const StyledCommentHeader = styled.div`
  padding: 12px 16px 4px 16px;
  display: flex;
  align-items: center;

  > div:first-child {
    margin-top: 4px;
  }
`;

export const StyledCommentHeaderSecondaryContent = styled.div`
  margin-top: -4px;
`;

export const StyledFeedPostHeaderContentInner = styled.div`
  display: flex;
  align-items: center;
  white-space: break-spaces;
`;

export const StyledSpinningLoader = styled(SpinningLoader)`
  margin-left: 8px;
  margin-top: 2px;
`;
