import React, { useMemo } from 'react';

import FlowPostBody from '../FlowPostBody';
import FeedPostReactions from '../FeedPostReactions/newIndex';
import FlowsPostHeader from '../../molecules/FlowsPostHeader/newIndex';

import { NewFlowFeedPostProps } from './types';
import { FlowFeedPostWrapper } from './styles';
import FeedPostComments from '../FeedPostComments/newIndex';
import PostInput from '../PostInput';
import { FeedCommentInputWrapper } from '../FeedPost/styles';
import { getFlowHeaderAttributesFromData } from '../../../Utils/home/feeds';

const emptyButtonClick = () => {};

const FlowFeedPost = (props: NewFlowFeedPostProps) => {
  const {
    commentsData,
    onShowMoreButtonClick,
    showMoreCommentsButton,
    post,
    profileData,
    bodyProps,
    className,
    onFlowClick,
    onMemberClick,
    onReactionSet,
    onReactionUnSet,
    onCommentExited,
    onCommentPosted,
    commentInputProps,
    onCommentTextFocus,
    dropdownComponent,
  } = props;

  //Future work to clean this up https://joinassembly.atlassian.net/browse/APP-5643

  const { createdAt, flow, person, visibility, responseId } = useMemo(
    () => getFlowHeaderAttributesFromData(post, profileData),
    [post, profileData],
  );

  return (
    <FlowFeedPostWrapper className={className}>
      <FlowsPostHeader
        onClickUserName={onMemberClick}
        onFlowClick={onFlowClick}
        dropdownComponent={dropdownComponent}
        post={post}
        profileData={profileData}
      />
      <FlowPostBody
        {...bodyProps}
        flow={flow}
        responseId={responseId}
        createdAt={createdAt}
        person={person}
        visibility={visibility}
        onMemberClick={onMemberClick}
      />
      <FeedPostReactions
        post={post}
        profileData={profileData}
        onCommentButtonClick={
          commentInputProps?.onCommentButtonClick || emptyButtonClick
        }
        onEmoticonSelect={onReactionSet}
        onReactionToggleClick={onReactionUnSet}
      />
      <FeedPostComments
        isDummy={false}
        commentsData={commentsData}
        onShowMoreCommentsButtonClick={onShowMoreButtonClick}
        showMoreCommentsButton={showMoreCommentsButton}
        post={post}
        profileData={profileData}
      />
      {commentInputProps && (
        <FeedCommentInputWrapper>
          <PostInput
            {...commentInputProps}
            onEditorBlur={onCommentExited}
            onEditorFocus={onCommentTextFocus}
            onPostClick={onCommentPosted}
          />
        </FeedCommentInputWrapper>
      )}
    </FlowFeedPostWrapper>
  );
};

export default FlowFeedPost;
