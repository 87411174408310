import React, { useRef } from 'react';
import range from 'lodash/range';
import uuid from 'uuid';
import Body from '../../atoms/Body';
import {
  StyledLabelBody,
  StyledScaleRadioItem,
  StyledScaleWrapper,
  StyledLabelWrapper,
  StyledScaleItemLabel,
  StyledLabelInput,
} from './styles';
import { BlockScaleLabelProps, BlockScaleProps } from './type';

const EditableLabelComponent = (props: {
  variant: string;
  label?: string;
  labelProps?: BlockScaleLabelProps;
  className: string;
}) => {
  const { variant, label, labelProps, className } = props;
  if (labelProps) {
    return (
      <StyledLabelInput
        className={className}
        value={labelProps.value}
        onChange={labelProps.onChange}
        placeholder={labelProps.placeholder}
        removeElevation
        size="medium"
      />
    );
  }
  return (
    <StyledLabelBody className={className} variant={variant}>
      {label || null}
    </StyledLabelBody>
  );
};

const BlockScale = (props: BlockScaleProps) => {
  const {
    label,
    min,
    max,
    value: scaleValue,
    onChange: scaleValueChange,
    isReadOnly,
  } = props;

  const uniqueIdRef = useRef(uuid.v4());

  const scaleValues = range(min, max + 1);

  const scaleValueLabelBodyVariant = isReadOnly ? 'body3' : 'body1';

  const handleScaleValueChange = (e: React.MouseEvent) => {
    if (!isReadOnly) {
      const target = e.target as HTMLElement;
      let value = target.getAttribute('data-scale-value');
      // To handle situation where user clicks on the number itself.
      if (target && value === null) {
        value = target.parentElement?.getAttribute('data-scale-value') || null;
      }
      if (value && scaleValueChange) {
        if (+value !== scaleValue) {
          scaleValueChange(+value);
        } else {
          scaleValueChange(null);
        }
      }
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (!scaleValueChange) return;

    if (event.key === ' ' && scaleValue) {
      event.preventDefault();
      scaleValueChange(null);
    }
  };

  const getColor = (value: number) => {
    if (scaleValue !== value) {
      return isReadOnly ? 'gray6' : 'gray9';
    }
    return 'geekBlue6';
  };

  const renderLabelContent = () => {
    switch (props.type) {
      case 'editable':
        return (
          <>
            <EditableLabelComponent
              className="low"
              variant={scaleValueLabelBodyVariant}
              labelProps={props.labelInputOne}
            />
            <EditableLabelComponent
              className="medium"
              variant={scaleValueLabelBodyVariant}
              labelProps={props.labelInputTwo}
            />
            <EditableLabelComponent
              className="high"
              variant={scaleValueLabelBodyVariant}
              labelProps={props.labelInputThree}
            />
          </>
        );
      case 'normal':
        return (
          <>
            <EditableLabelComponent
              className="low"
              variant={scaleValueLabelBodyVariant}
              label={props.labels?.low}
            />
            <EditableLabelComponent
              className="medium"
              variant={scaleValueLabelBodyVariant}
              label={props.labels?.middle}
            />
            <EditableLabelComponent
              className="high"
              variant={scaleValueLabelBodyVariant}
              label={props.labels?.high}
            />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <section>
      {label && (
        <Body variant="body3" color="gray8">
          {label}
        </Body>
      )}
      <StyledScaleWrapper
        isReadOnly={isReadOnly}
        columns={max - min + 1}
        onClick={handleScaleValueChange}
        role="radiogroup"
      >
        {scaleValues.map((value) => {
          return (
            <React.Fragment key={value}>
              <StyledScaleRadioItem
                checked={scaleValue === value}
                id={`${value}`}
                isReadOnly={isReadOnly}
                name={uniqueIdRef.current}
                onChange={(event) => {
                  if (scaleValueChange) {
                    scaleValueChange(
                      event.target.value ? +event.target.value : null,
                    );
                  }
                }}
                onKeyDown={handleKeyDown}
                type="radio"
                value={value}
              />
              <StyledScaleItemLabel
                data-scale-value={value}
                htmlFor={`${value}`}
                key={value}
                isReadOnly={isReadOnly}
              >
                <Body
                  variant={isReadOnly ? 'body1' : 'subHead3'}
                  color={getColor(value)}
                >
                  {value}
                </Body>
              </StyledScaleItemLabel>
            </React.Fragment>
          );
        })}
      </StyledScaleWrapper>

      <StyledLabelWrapper>{renderLabelContent()}</StyledLabelWrapper>
    </section>
  );
};

export default BlockScale;
