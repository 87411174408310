import styled from 'styled-components';
import Card from '../../../atomic/atoms/Card';
import GifContainer from '../../../atomic/molecules/GifContainer';

export const CardContainer = styled(Card)`
  padding: 32px;
  width: 500px;
`;

export const StyledGifContainer = styled(GifContainer)`
  margin: 24px 0;
`;

export const MainContainer = styled.div`
  margin-top: 94px;
  display: flex;
  justify-content: center;
`;
