import React from 'react';

import BasicModalTemplate, {
  ModalButtonProps,
} from '../../templates/BasicModalTemplate';
import Body from '../../atoms/Body';
import Heading from '../../atoms/Heading';
import { marginBottom } from '../../../Utils/styles/display';

export interface BotDisconnectModalProps {
  isLoading?: boolean;
  heading: string;
  contentHeading: string;
  contentBody: string;
  onModalClose(): void;
  primaryButtonText: string;
  isPrimaryButtonLoading?: boolean;
  onPrimaryButtonOnClick(): void;
  secondaryButtonText: string;
  isSecondaryButtonLoading?: boolean;
  onSecondaryButtonOnClick(): void;
}

const BotDisconnectModal = (props: BotDisconnectModalProps) => {
  const {
    isLoading = false,
    heading,
    primaryButtonText,
    isPrimaryButtonLoading = false,
    onPrimaryButtonOnClick,
    secondaryButtonText,
    isSecondaryButtonLoading = false,
    onSecondaryButtonOnClick,
    onModalClose,
    contentHeading,
    contentBody,
  } = props;
  const primaryButton: ModalButtonProps = {
    text: primaryButtonText,
    isLoading: isPrimaryButtonLoading,
    status: 'warning',
    onClick: onPrimaryButtonOnClick,
  };
  const secondaryButton: ModalButtonProps = {
    text: secondaryButtonText,
    isLoading: isSecondaryButtonLoading,
    variant: 'contained',
    onClick: onSecondaryButtonOnClick,
  };
  return (
    <BasicModalTemplate
      isLoading={isLoading}
      heading={heading}
      primaryButton={primaryButton}
      secondaryButton={secondaryButton}
      onModalClose={onModalClose}
    >
      <Heading variant="h5" className={marginBottom(24)}>
        {contentHeading}
      </Heading>
      <Body variant="body2">{contentBody}</Body>
    </BasicModalTemplate>
  );
};

export default BotDisconnectModal;
