import React, { useEffect, useMemo } from 'react';
import { ReactQueryDevtools } from 'react-query/devtools';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import qs from 'qs';
import get from 'lodash/get';
import * as microsoftTeams from '@microsoft/teams-js';
import shallow from 'zustand/shallow';

import FlowsRoutesController from '../flows/FlowsRoutesController';
import RewardsController from '../rewards/RoutesController';
import UserSettingsController from '../settings/UserSettingsController';
/* ms-team imports */
import { isSourceMSTeams, MS_TEAMS, setAppSource } from '../../Utils/msteams';

import {
  FLOWS,
  RECOGNITION_FEEDS,
  V2_HOME,
  NOTEBOOK,
  V2_POST_PAGE,
  V2_REWARDS,
  V2_SETTINGS,
  V2_USER_PROFILE,
  V3_POST_PAGE,
  QUICK_SETUP,
  RECOGNITION_POST_PAGE,
  KNOWLEDGE_CENTER,
} from '../../constants/routes';

import useLayoutStore from '../../stores/layoutStore';
import { LayoutStoreState } from '../../interfaces/Layout';
import { useProfileInfoFetchQuery } from '../../queries/Profile';
import { getIsCurrentUserOwner } from '../../queries/Profile/utils';
import SinglePostController from '../SinglePostController';
import PrimaryLayoutController from '../layout/PrimaryLayoutController';
import { fireConversionForSignup } from '../../Utils/analytics/gtag';
import { checkIfProduction } from '../../Utils/admin';
import MainFeedController from '../home/MainFeedController/newIndex';
import ProfileFeedController from '../home/ProfileFeedController';
import SingleFlowFeedPostController from '../home/SingleFlowFeedPostController';
import KnowledgeCenterController from '../KnowledgeCenterController';

import RecognitionController from '../home/RecognitionController';
import NotebookController from '../notebook/NotebookPageController';
import { useMemberAuthenticated } from '../../hooks/useAuth';
import QuickSetupController from '../quickSetup/QuickSetupController';
import {
  SplitNames,
  TreatmentTypes,
} from '../../hooks/useSplitSdkConfig/constants';
import { useFeatureSplit } from '../../hooks/useFeatureSplit';
import useUpdateTimezone from '../../hooks/useUpdateTimezone';

const rightAsideDisabledRoutes = [
  V2_REWARDS,
  V2_SETTINGS,
  QUICK_SETUP,
  RECOGNITION_POST_PAGE,
  KNOWLEDGE_CENTER,
  V3_POST_PAGE,
];

// THIS PAGE IS EXPERIMENTAL. IT ONLY EXISTS TO PROVIDE STRUCTURE TO FUTURE ROUTES
// FOR V2.

export const layoutStoreSelectorForNotebook = (state: LayoutStoreState) => ({
  setRightAsideOpenToTrue: state.setRightAsideOpenToTrue,
  setTaskIdForNotebook: state.setTaskIdForNotebook,
  setShowRightAsideToFalse: state.setShowRightAsideToFalse,
});

export const layoutSelector = (state: LayoutStoreState) => ({
  setShowRightAsideToTrue: state.setShowRightAsideToTrue,
  setShowRightAsideToFalse: state.setShowRightAsideToFalse,
});

const NotebookRouter = () => {
  const { search } = useLocation();
  const selectedTaskId = useMemo(() => {
    const query = new URLSearchParams(search);
    return query.get('taskId');
  }, [search]);

  const { setTaskIdForNotebook, setRightAsideOpenToTrue } = useLayoutStore(
    layoutStoreSelectorForNotebook,
    shallow,
  );

  useEffect(() => {
    if (selectedTaskId) {
      setTaskIdForNotebook(selectedTaskId);
      setRightAsideOpenToTrue();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Switch>
      <Route path={NOTEBOOK} component={NotebookController} />
    </Switch>
  );
};

const V2RouteController = () => {
  const { setShowRightAsideToTrue, setShowRightAsideToFalse } = useLayoutStore(
    layoutSelector,
    shallow,
  );

  const { treatment: knowledgeCenter } = useFeatureSplit(
    SplitNames.KNOWLEDGE_CENTER,
  );

  const isKnowledgeCenterEnabled = knowledgeCenter === TreatmentTypes.ON;

  const history = useHistory();
  const { pathname, search } = useLocation();
  const { treatment } = useFeatureSplit(SplitNames.QUICK_SETUP);
  const authenticated = useMemberAuthenticated();

  useEffect(() => {
    const parsed = qs.parse(search, {
      ignoreQueryPrefix: true,
    });
    const source = get(parsed, 'source');
    if (source === MS_TEAMS) {
      setAppSource(source);
    }
    if (isSourceMSTeams()) {
      microsoftTeams.initialize();
      /* To notify app loaded to hide loading indicator */
      microsoftTeams.appInitialization.notifyAppLoaded();
      microsoftTeams.appInitialization.notifySuccess();
    }
  }, [search]);

  useEffect(() => {
    if (rightAsideDisabledRoutes.some((route) => pathname.includes(route))) {
      setShowRightAsideToFalse();
    } else {
      setShowRightAsideToTrue();
    }
  }, [pathname, setShowRightAsideToFalse, setShowRightAsideToTrue]);

  const {
    data: profileData,
    isLoading,
    isFetching,
  } = useProfileInfoFetchQuery(authenticated);
  useUpdateTimezone(profileData?.member.timeZone, isLoading, authenticated);

  useEffect(() => {
    if (profileData && getIsCurrentUserOwner(profileData)) {
      fireConversionForSignup(profileData.member.memberId);
    }
  }, [profileData]);

  // Redirect to quick setup page, if the user has not completed quick setup.
  useEffect(() => {
    const flow = new URLSearchParams(search).get('flow') || '';

    if (
      !isFetching &&
      authenticated &&
      flow !== 'survey_Team' &&
      treatment === TreatmentTypes.ON &&
      !profileData?.member.isQuickSetupComplete &&
      getIsCurrentUserOwner(profileData) &&
      pathname !== QUICK_SETUP &&
      !pathname.includes(FLOWS)
    ) {
      history.push(QUICK_SETUP);
    }
  }, [
    authenticated,
    search,
    history,
    profileData,
    treatment,
    isFetching,
    pathname,
  ]);

  if (!authenticated) {
    return <div />;
  }
  return (
    <PrimaryLayoutController>
      <Switch>
        <Route exact path={V2_HOME} component={MainFeedController} />
        <Route exact path={V2_USER_PROFILE} component={ProfileFeedController} />
        <Route path={V2_REWARDS} component={RewardsController} />
        <Route path={V2_SETTINGS} component={UserSettingsController} />
        <Route path={V3_POST_PAGE} component={SingleFlowFeedPostController} />
        <Route path={V2_POST_PAGE} component={SinglePostController} />
        <Route path={QUICK_SETUP} component={QuickSetupController} />
        <Route path={RECOGNITION_FEEDS} component={RecognitionController} />
        <Route path={FLOWS} component={FlowsRoutesController} />
        <Route path={NOTEBOOK} component={NotebookRouter} />
        {isKnowledgeCenterEnabled && (
          <Route
            path={KNOWLEDGE_CENTER}
            component={KnowledgeCenterController}
          />
        )}
        <Redirect to={V2_HOME} />
      </Switch>
      {!checkIfProduction() && (
        <ReactQueryDevtools
          toggleButtonProps={{ style: { bottom: 150 } }}
          initialIsOpen={false}
        />
      )}
    </PrimaryLayoutController>
  );
};

export default V2RouteController;
