import React, { useCallback, useMemo } from 'react';
import NotificationItem from '../../molecules/NotificationItem';
import ToggleButton from '../../atoms/ToggleButton';
import {
  DrawerHeading,
  NotificationHeader,
  ToggleButtonWrapper,
  NotificationContainer,
  NotificationDropdownContainer,
  NotificationErrorImage,
  NotificationErrorContainer,
  ContentWrapper,
} from './styles';
import ErrorImageSrc from '../../../img/error.svg';
import { NotificationDropdownProps } from './interface';
import {
  notificationDropdownAllButton,
  notificationDropdownHeading,
  notificationDropdownUnreadButton,
  SOMETHING_WENT_WRONG,
  TRY_AGAIN,
} from '../../../languages/en/home';
import useToggle from '../../../hooks/useToggle';
import { Flex } from '../../../Utils/styles/display';
import DropdownIconButton from '../../molecules/DropdownIconButton';
import Body from '../../atoms/Body';
import { MARK_ALL_AS_READ } from '../../../languages/en/notifications';
import { DropdownPlacement } from '../../molecules/Dropdown_V2/interfaces';
import EmptyNotificationsNotice from '../../molecules/EmptyNotificationsNotice';
import Loader from './Loader';
import { ButtonSize } from '../../atoms/IconButton_V2/interface';

const MARK_ALL_AS_READ_ID = 'mark-all-read';

const NotificationDropdownOptions = [
  {
    id: 'notifications-header-options',
    items: [
      {
        value: MARK_ALL_AS_READ,
        id: MARK_ALL_AS_READ_ID,
        prefixIcon: 'check-rounded',
        isSelected: false,
      },
    ],
  },
];

const NotificationDropdown = (props: NotificationDropdownProps) => {
  const {
    notificationData,
    isLoading,
    onMarkReadClick,
    handleNotificationItemClick,
    onUnreadClick,
    onShowAllClick,
    isError,
    closePopover,
  } = props;
  const {
    models: { toggleValue: isUnreadActive },
    operations: {
      setToggleToTrue: handleUnreadClick,
      setToggleToFalse: handleShowAllClick,
    },
  } = useToggle();

  const filteredData = useMemo(
    () =>
      isUnreadActive
        ? notificationData.filter((data) => data.read !== true)
        : notificationData,
    [isUnreadActive, notificationData],
  );

  const onDropdownItemClick = useCallback(
    (optionId: string | number) => {
      if (optionId === MARK_ALL_AS_READ_ID) {
        onMarkReadClick();
      }
    },
    [onMarkReadClick],
  );

  return (
    <NotificationDropdownContainer>
      <ContentWrapper>
        <NotificationHeader>
          <DrawerHeading variant="h7" weight="medium">
            {notificationDropdownHeading}
          </DrawerHeading>
          <DropdownIconButton
            icon="more"
            dropdownPlacement={DropdownPlacement.BottomEnd}
            menuItems={NotificationDropdownOptions}
            onItemClick={onDropdownItemClick}
            size={ButtonSize.Small}
          />
        </NotificationHeader>

        <Flex justifyContent="space-between">
          <ToggleButtonWrapper>
            <ToggleButton
              active={!isUnreadActive}
              onClick={() => {
                handleShowAllClick();
                if (onShowAllClick) {
                  onShowAllClick();
                }
              }}
              size={ButtonSize.Small}
            >
              {notificationDropdownAllButton}
            </ToggleButton>
            <ToggleButton
              active={isUnreadActive}
              onClick={() => {
                handleUnreadClick();
                if (onUnreadClick) {
                  onUnreadClick();
                }
              }}
              size={ButtonSize.Small}
            >
              {notificationDropdownUnreadButton}
            </ToggleButton>
          </ToggleButtonWrapper>
        </Flex>
      </ContentWrapper>
      <NotificationContainer>
        {isError && (
          <NotificationErrorContainer>
            <NotificationErrorImage src={ErrorImageSrc} alt="Error" />
            <Body variant="body1Bold">{SOMETHING_WENT_WRONG}</Body>
            <Body variant="body2">{TRY_AGAIN}</Body>
          </NotificationErrorContainer>
        )}
        {isLoading && <Loader />}
        {!isLoading && !isError && (
          <>
            {filteredData.length > 0 &&
              filteredData.map((notificationItem) => (
                <NotificationItem
                  key={notificationItem.id}
                  id={notificationItem.id}
                  avatar={notificationItem.avatar}
                  timeAgo={notificationItem.timeAgo}
                  read={notificationItem.read}
                  bodyText={notificationItem.bodyText}
                  icon={notificationItem.icon}
                  headerText={notificationItem.headerText}
                  onClick={handleNotificationItemClick}
                  closePopover={closePopover}
                  postID={notificationItem.postID}
                  notificationType={notificationItem.type}
                  emoticon={notificationItem.emoticon}
                  flowId={notificationItem.flowId}
                  responseId={notificationItem.responseId}
                  occurrenceId={notificationItem.occurrenceId}
                  commentId={notificationItem.commentId}
                />
              ))}
            {filteredData.length === 0 && !isUnreadActive && (
              <EmptyNotificationsNotice type="allNotifications" />
            )}
            {filteredData.length < 1 && isUnreadActive && (
              <EmptyNotificationsNotice type="unreadNotifications" />
            )}
          </>
        )}
      </NotificationContainer>
    </NotificationDropdownContainer>
  );
};

export default NotificationDropdown;
