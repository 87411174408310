import React from 'react';
import LoadingPulse from '../../atoms/LoadingPulse';
import { GifLoadingModalProps } from './interface';
import {
  StyledGif,
  StyledMainContent,
  StyledModal,
  StyledSubContent,
} from './styles';

const GifLoadingModal = (props: GifLoadingModalProps) => {
  const {
    isOpen,
    onModalClose,
    heading,
    mainContent,
    subContent,
    gifURL,
    canShowLoading,
  } = props;
  return (
    <StyledModal
      isOpen={isOpen}
      heading={heading}
      onModalClose={onModalClose}
      disableContentScroll
    >
      {mainContent && (
        <StyledMainContent variant="h5">{mainContent}</StyledMainContent>
      )}
      {subContent && (
        <StyledSubContent variant="body2" color="gray8">
          {subContent}
        </StyledSubContent>
      )}
      {gifURL && <StyledGif src={gifURL} alt="loadingGIF" />}
      {canShowLoading && <LoadingPulse size={24} />}
    </StyledModal>
  );
};

export default GifLoadingModal;
