import React, { useEffect, useState } from 'react';

import DataGrid from 'react-data-grid';
/* @ts-ignore-next-line */
import CSV from 'comma-separated-values';

const parseCsv = (data: any) => {
  //Replacing data
  const csvData = data.replaceAll('""', '"-"');
  const rows: any[] = [];
  const columns: any[] = [];

  //Originally the plain "data" variable was used here
  new CSV(csvData).forEach((array: any[]) => {
    if (columns.length < 1) {
      array.forEach((cell, idx) => {
        columns.push({
          key: `key-${idx}`,
          name: cell || '-',
          resizable: true,
          sortable: true,
          filterable: true,
        });
      });
    } else {
      const row = {};
      array.forEach((cell, idx) => {
        /* @ts-ignore-next-line */
        row[`key-${idx}`] = cell;
      });
      rows.push(row);
    }
  });

  return { rows, columns };
};

const CsvViewer = (props: { data: string }) => {
  const { data } = props;
  const [csvView, setCsvView] = useState(parseCsv(data));

  useEffect(() => {
    setCsvView(parseCsv(data));
  }, [data]);

  const { rows, columns } = csvView;
  return (
    <>
      <DataGrid
        className="rdg-light"
        columns={columns}
        rows={rows}
        style={{ height: '100%', margin: '0px 32px' }}
      />
      <div style={{ height: '32px' }} />
    </>
  );
};

export default CsvViewer;
