import { useHistory } from 'react-router-dom';
import { V2_NOTIFICATION_SETTINGS } from '../../constants/routes';
import { COPIED_TO_CLIPBOARD } from '../../languages/en/flows';
import {
  useHideFlowActivity,
  useShowFlowActivity,
} from '../../queries/Flows/FlowFeedOptions/Activity';
import { copyToClipboard } from '../../Utils/common';
import { showSuccessMessage } from '../../Utils/toast';

const useOptions = () => {
  const history = useHistory();

  const { mutate: mutateHideFlowActivity } = useHideFlowActivity();
  const { mutate: mutateShowFlowActivity } = useShowFlowActivity();

  const handleCopyFlowFeedLink = () => {
    const url = window.location.href;
    copyToClipboard(url.replace(/\/insights|\/editor/, ''));
    showSuccessMessage(COPIED_TO_CLIPBOARD);
  };

  const handleEditNotificationSettings = () => {
    history.push(V2_NOTIFICATION_SETTINGS);
  };

  const handleHideActivityClick = (flowId?: string) => {
    if (flowId) {
      mutateHideFlowActivity({ flowId });
    }
  };

  const handleShowActivityClick = (flowId?: string) => {
    if (flowId) {
      mutateShowFlowActivity({ flowId });
    }
  };

  return {
    operations: {
      handleCopyFlowFeedLink,
      handleEditNotificationSettings,
      handleHideActivityClick,
      handleShowActivityClick,
    },
  };
};

export default useOptions;
