import styled from 'styled-components';
import Body from '../../atoms/Body';
import Heading from '../../atoms/Heading';
import BasicModalTemplate from '../../templates/BasicModalTemplate';
import Image from '../../atoms/Image';

export const StyledModal = styled(BasicModalTemplate)`
  width: 100%;
  max-width: 600px;
`;

export const StyledMainContent = styled(Heading)`
  margin-bottom: 8px;
`;

export const StyledSubContent = styled(Body)`
  margin-bottom: 24px;
`;

export const StyledGif = styled(Image)`
  width: 330px;
  margin: 0 auto 24px;
`;
