import { MenuItemProps } from '../../../atomic/molecules/Dropdown_V2/interfaces';

export const rewardTypeMenuItems: MenuItemProps[] = [
  {
    id: 'reward-type',
    items: [
      {
        value: 'Professional Development',
        id: 'professional-development',
        isSelected: false,
      },
      {
        value: 'Office Life',
        id: 'office-life',
        isSelected: false,
      },
      {
        value: 'Work life Balance',
        id: 'work-life-balance',
        isSelected: false,
      },
      {
        value: 'Custom',
        id: 'custom',
        isSelected: false,
      },
      {
        value: 'Boss Involved',
        id: 'boss-involved',
        isSelected: false,
      },
    ],
  },
];

export enum SortByQueryParams {
  FAVORITES = 'favorites:desc',
  POPULAR = 'popular:desc',
  NEWEST = 'activatedAt:desc',
  LOW_STOCK = 'maxLimit:asc',
  POINTS_TO_REDEEM_LOW_TO_HIGH = 'pointsToRedeem:asc',
  POINTS_TO_REDEEM_HIGH_TO_LOW = 'pointsToRedeem:desc',
  ALPHABETICALLY = 'name:asc',
}

export enum GiftCardsSortByQueryParams {
  FAVORITES = 'favorites:desc',
  POPULAR = 'popular:desc',
  ASSEMBLY_POINTS_LOW_TO_HIGH = 'faceValue:asc',
  ASSEMBLY_POINTS_HIGH_TO_LOW = 'faceValue:desc',
  ALPHABETICALLY = 'brandName:asc',
}

export const sortByMenuItems: MenuItemProps[] = [
  {
    id: 'sort-by',
    items: [
      {
        value: 'My favorites',
        id: SortByQueryParams.FAVORITES,
        isSelected: false,
      },
      {
        value: 'Most Popular',
        id: SortByQueryParams.POPULAR,
        isSelected: false,
      },
      {
        value: 'Newest',
        id: SortByQueryParams.NEWEST,
        isSelected: false,
      },
      {
        value: 'Low Stock',
        id: SortByQueryParams.LOW_STOCK,
        isSelected: false,
      },
      {
        value: 'Amount of points (low to high)',
        id: SortByQueryParams.POINTS_TO_REDEEM_LOW_TO_HIGH,
        isSelected: false,
      },
      {
        value: 'Amount of points (high to low)',
        id: SortByQueryParams.POINTS_TO_REDEEM_HIGH_TO_LOW,
        isSelected: false,
      },
      {
        value: 'Alphabetically',
        id: SortByQueryParams.ALPHABETICALLY,
        isSelected: false,
      },
    ],
  },
];

export const giftCardsSortByMenuItems: MenuItemProps[] = [
  {
    id: 'sort-by',
    items: [
      {
        value: 'My favorites',
        id: GiftCardsSortByQueryParams.FAVORITES,
        isSelected: false,
      },
      {
        value: 'Most Popular',
        id: GiftCardsSortByQueryParams.POPULAR,
        isSelected: false,
      },
      {
        value: 'Amount of points (low to high)',
        id: GiftCardsSortByQueryParams.ASSEMBLY_POINTS_LOW_TO_HIGH,
        isSelected: false,
      },
      {
        value: 'Amount of points (high to low)',
        id: GiftCardsSortByQueryParams.ASSEMBLY_POINTS_HIGH_TO_LOW,
        isSelected: false,
      },
      {
        value: 'Alphabetically',
        id: GiftCardsSortByQueryParams.ALPHABETICALLY,
        isSelected: false,
      },
    ],
  },
];

export const REWARDS_PER_PAGE_VALUES = [8, 16, 32, 64];
export const REWARDS_PER_PAGE_DEFAULT = REWARDS_PER_PAGE_VALUES[1];

export const DEFAULT_REWARDS_QUERY_PARAMS = {
  cultureRewards: {
    sort: SortByQueryParams.NEWEST,
  },
  giftCards: {
    sort: SortByQueryParams.FAVORITES,
  },
  charitySortParams: {
    sort: SortByQueryParams.FAVORITES,
  },
};
