import { FlowBuilderBlockTypes } from '../../../interfaces/Flow/Builder';

type BlockType = {
  [key in keyof typeof FlowBuilderBlockTypes]: {
    index: number;
    label: key;
  };
};

export const defaultBuilderBlocks: BlockType = {
  [FlowBuilderBlockTypes.TRIGGER]: {
    index: 0,
    label: FlowBuilderBlockTypes.TRIGGER,
  },
  [FlowBuilderBlockTypes.CONTENT]: {
    index: 1,
    label: FlowBuilderBlockTypes.CONTENT,
  },
  [FlowBuilderBlockTypes.COMPLETION]: {
    index: 2,
    label: FlowBuilderBlockTypes.COMPLETION,
  },
  [FlowBuilderBlockTypes.PARTICIPANTS]: {
    index: 99,
    label: FlowBuilderBlockTypes.PARTICIPANTS,
  },
  [FlowBuilderBlockTypes.VISIBILITY]: {
    index: 99,
    label: FlowBuilderBlockTypes.VISIBILITY,
  },
  [FlowBuilderBlockTypes.ACTION]: {
    index: 99,
    label: FlowBuilderBlockTypes.ACTION,
  },
};
