import React from 'react';

import { ActionBarProps, FeedTypes } from './types';
import {
  StyledActionBar,
  StyledPromptToAnswerMain,
  StyledPromptToAnswerWrapper,
  StyledSVGIcon,
  StyledBody,
} from './styles';
import Avatar from '../../atoms/Avatar';

const ActionBar = (props: ActionBarProps) => {
  const {
    avatar = '',
    userName = '',
    userId = '',
    text = '',
    onClick,
    feedType,
    isDisabled = false,
    isAnonymous = false,
  } = props;

  return (
    <StyledActionBar
      onClick={onClick}
      disabled={isDisabled}
      data-testid="action-bar"
    >
      <StyledPromptToAnswerWrapper>
        <Avatar
          img={avatar}
          name={userName}
          userId={userId}
          size="48px"
          isAnonymous={isAnonymous}
          {...(isAnonymous && { iconSize: '40px' })}
        />
        <StyledPromptToAnswerMain>
          <StyledBody variant="body2Medium" color="geekBlue6">
            {text}
          </StyledBody>
          {feedType === FeedTypes.Main && (
            <StyledSVGIcon icon="triangle-down" size="16px" />
          )}
        </StyledPromptToAnswerMain>
      </StyledPromptToAnswerWrapper>
    </StyledActionBar>
  );
};

export default ActionBar;
