import { useMutation, useQuery, useQueryClient } from 'react-query';

import { makeAPICall, makeAPICallWithDataReturn } from '../utils';

import {
  GetQuickSetupStatusRequest,
  GetQuickSetupStatusResponse,
  GetCalendarEventsListResponse,
} from './interfaces';

import {
  GET_CALENDAR_LIST,
  GET_QUICK_SETUP_STATUS,
  CALENDAR_AUTHORIZATION,
  GET_CALENDAR_EMBED_URL,
  GET_CALENDAR_EVENTS_LIST,
  UPDATE_QUICK_SETUP_STATUS,
  GET_CALENDAR_EVENT_DETAILS,
  GET_PROFILE_INFO,
} from '../../constants/endpoints';

import {
  GetCalendarListResponse,
  GetCalendarEventDetailsResponse,
} from '../../interfaces/QuickSetup';

export const useGetCalendarEmbedUrlQuery = (
  makeRequest: boolean,
  providerName: string,
) => {
  return useQuery<{
    embedUrl: string;
  }>(
    [GET_CALENDAR_EMBED_URL, providerName],
    () => {
      return makeAPICallWithDataReturn(GET_CALENDAR_EMBED_URL, {
        providerName: providerName,
      });
    },
    {
      staleTime: 0,
      enabled: makeRequest,
      refetchOnWindowFocus: false,
    },
  );
};

export const useGetCalendarEventsList = (
  calendarId: string,
  enabled: boolean,
) => {
  return useQuery(
    [GET_CALENDAR_EVENTS_LIST, calendarId],
    () =>
      makeAPICallWithDataReturn(
        GET_CALENDAR_EVENTS_LIST,
        undefined,
        undefined,
        {
          calendarId,
        },
      ),
    {
      select: (res: GetCalendarEventsListResponse) => res.data,
      enabled: enabled,
    },
  );
};

export const useCalendarAuthorization = (
  providerName: string,
  connectionId: number,
  makeRequest: boolean,
) => {
  return useQuery(
    [CALENDAR_AUTHORIZATION],
    () => {
      return makeAPICallWithDataReturn(CALENDAR_AUTHORIZATION, {
        providerName: providerName,
        connectionId: connectionId,
      });
    },
    {
      enabled: makeRequest,
      staleTime: 60 * 5 * 1000,
      refetchOnWindowFocus: false,
    },
  );
};

export const useGetCalendarListQuery = (makeRequest: boolean) => {
  return useQuery<GetCalendarListResponse>(
    [GET_CALENDAR_LIST],
    () => {
      return makeAPICallWithDataReturn(GET_CALENDAR_LIST);
    },
    {
      enabled: makeRequest,
      staleTime: 60 * 5 * 1000,
      refetchOnWindowFocus: false,
    },
  );
};

export const useGetQuickSetupStatus = () => {
  return useQuery<GetQuickSetupStatusResponse>([GET_QUICK_SETUP_STATUS], () => {
    return makeAPICallWithDataReturn(GET_QUICK_SETUP_STATUS);
  });
};

export const useUpdateQuickSetupStatus = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (payload: GetQuickSetupStatusRequest) => {
      return makeAPICall(UPDATE_QUICK_SETUP_STATUS, {
        ...payload,
      });
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(GET_PROFILE_INFO);
      },
    },
  );
};

export const useGetCalendarEventDetailsQuery = (
  calendarId: string,
  eventId: string,
  makeRequest: boolean,
) => {
  return useQuery<GetCalendarEventDetailsResponse>(
    [GET_CALENDAR_EVENT_DETAILS, calendarId, eventId],
    () => {
      return makeAPICallWithDataReturn(
        GET_CALENDAR_EVENT_DETAILS,
        undefined,
        undefined,
        {
          calendarId,
          eventId,
        },
      );
    },

    {
      enabled: makeRequest,
      staleTime: 60 * 5 * 1000,
      refetchOnWindowFocus: false,
    },
  );
};
