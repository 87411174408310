import styled from 'styled-components';

import ThemeV2 from '../../../../componentsV2/theme';
import TextField from '../../NewTextfield';

export const StyledTextField = styled(TextField)`
  .MuiFormHelperText-root {
    position: absolute;
    top: 50px;

    .Mui-focused .focused-geekBlue6 {
      color: ${ThemeV2.palette.geekBlue6};
    }
  }
`;

export const StyledAnchor = styled.a`
  text-decoration: underline;
`;
