import React from 'react';
import styled from 'styled-components';
import FormsEditorBaseInputBlock from '../FormsEditorInputBaseBlock';
import {
  ContentBlockLocalProperties,
  ContentGifBlockState,
  GifBlockBuilderSettingState,
} from '../../../../interfaces/Flow/Builder';
import GifCarousel from '../../../molecules/GifCarousel';

export interface FormsEditorGifInputBlockProps
  extends ContentBlockLocalProperties {
  blockData: ContentGifBlockState;
}

const StyledWrapper = styled.div`
  width: 100%;
  margin-top: 16px;
`;

const FormsEditorGifSelectorBlock = ({
  blockData,
  currentIndex,
  handleToggleIsDescriptionNeeded,
  handleToggleIsRequired,
  onValueChange,
  totalNumberOfItems,
  onDeleteClick,
  onMoveDownClick,
  onMoveUpClick,
}: FormsEditorGifInputBlockProps) => {
  const { isRequired, description, assemblyCurrency } = blockData;

  const blockSettingState: GifBlockBuilderSettingState = {
    type: 'GIF',
    isRequired,
    isDescriptionNeeded: description !== null,
    toggleIsRequired: handleToggleIsRequired,
    toggleDescriptionNeeded: handleToggleIsDescriptionNeeded,
  };

  return (
    <FormsEditorBaseInputBlock
      blockData={blockData}
      currentIndex={currentIndex !== undefined ? currentIndex : 0}
      assemblyCurrency={assemblyCurrency}
      totalNumberOfItems={totalNumberOfItems}
      blockSettingState={blockSettingState}
      onDeleteClick={onDeleteClick}
      onMoveDownClick={onMoveDownClick}
      onMoveUpClick={onMoveUpClick}
      onValueChange={onValueChange}
    >
      <StyledWrapper>
        <GifCarousel onGifClick={() => {}} disabled />
      </StyledWrapper>
    </FormsEditorBaseInputBlock>
  );
};

export default FormsEditorGifSelectorBlock;
