import React from 'react';

import { Container } from './styles';
import { Flex } from '../../../Utils/styles/display';

import { QuickSetupStep } from './types';

import FooterController from '../FooterController';
import GetStartedController from '../GetStartedController';
import SurveyTeamController from '../SurveyTeamController';
import useQuickSetupController from './useQuickSetupController';
import AddParticipantsController from '../AddParticipantsController';
import SetupCompletionController from '../SetupCompletionController';
import MeetingSelectionController from '../MeetingSelectionController';
import CalendarSelectionController from '../CalendarSelectionController';
import TemplateSelectionController from '../TemplateSelectionController';

const QuickSetupController = () => {
  const { models, operations } = useQuickSetupController();

  const {
    flow,
    duration,
    meetingId,
    calenderId,
    currentStep,
    meetingName,
    providerName,
    invitedMembers,
    selectedTemplate,
    currentQueryStep,
    meetingReccurence,
    meetingParticipants,
    calendarConnectionId,
    isWorkatoSetupComplete,
    isInviteButtonDisabled,
    isInviteMembersSuccess,
    isLoadingQuickSetupStatus,
  } = models;
  const {
    handleInviteNewMembersChange,
    handleCurrentFlowChange,
    handleCurrentStepChange,
    handleCalenderIdChange,
    handleMeetingChange,
    handleTemplateChange,
    handleMeetingParticipantsChange,
    handleInviteMemberStatus,
    handleCalendarConnectionChange,
  } = operations;

  if (isLoadingQuickSetupStatus) {
    return null;
  }

  return (
    <>
      <Container
        hasPaddingBottom={
          currentStep === QuickSetupStep.MEETINGS_LIST ||
          currentStep === QuickSetupStep.SELECT_TEMPLATE ||
          currentStep === QuickSetupStep.ADD_PARTICIPANTS
        }
      >
        <Flex
          width="100%"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          {(!currentStep ||
            currentStep === QuickSetupStep.QUICK_SETUP_OPTION_SELECTION) &&
            currentQueryStep !== QuickSetupStep.COMPLETION && (
              <GetStartedController
                onCurrentFlowChange={handleCurrentFlowChange}
                onQuickSetupStepChange={handleCurrentStepChange}
              />
            )}

          {currentStep === QuickSetupStep.CALENDAR_SELECTION && (
            <CalendarSelectionController
              isWorkatoSetupComplete={isWorkatoSetupComplete}
              onQuickSetupStepChange={handleCurrentStepChange}
              onCalendarSelected={handleCalendarConnectionChange}
            />
          )}

          {currentStep === QuickSetupStep.SURVEY_TEAM && (
            <SurveyTeamController />
          )}

          {currentStep === QuickSetupStep.MEETINGS_LIST && (
            <MeetingSelectionController
              calenderId={calenderId}
              providerName={providerName}
              connectionId={calendarConnectionId}
              onMeetingChange={handleMeetingChange}
              onCalenderIdChange={handleCalenderIdChange}
              onQuickSetupStepChange={handleCurrentStepChange}
              isWorkatoSetupComplete={isWorkatoSetupComplete}
            />
          )}

          {currentStep === QuickSetupStep.SELECT_TEMPLATE && (
            <TemplateSelectionController
              flow={flow}
              meetingId={meetingId}
              calenderId={calenderId}
              meetingName={meetingName}
              selectedTemplate={selectedTemplate}
              meetingReccurence={meetingReccurence}
              onTemplateChange={handleTemplateChange}
              onQuickSetupStepChange={handleCurrentStepChange}
            />
          )}

          {currentStep === QuickSetupStep.ADD_PARTICIPANTS && (
            <AddParticipantsController
              flowName={meetingName}
              meetingId={meetingId}
              calenderId={calenderId}
              isWorkatoSetupComplete={isWorkatoSetupComplete}
              onMeetingParticipantsChange={handleMeetingParticipantsChange}
            />
          )}

          {(currentStep === QuickSetupStep.COMPLETION ||
            currentQueryStep === QuickSetupStep.COMPLETION) && (
            <SetupCompletionController
              invitedMembers={invitedMembers}
              isInviteMembersSuccess={isInviteMembersSuccess}
              meetingReccurence={meetingReccurence}
              flow={flow}
              duration={duration}
              meetingName={meetingName}
              meetingId={meetingId}
              calenderId={calenderId}
              meetingParticipants={meetingParticipants}
              templateId={selectedTemplate?.templateId || ''}
              onQuickSetupStepChange={handleCurrentStepChange}
            />
          )}
        </Flex>
        {currentStep !== QuickSetupStep.COMPLETION && (
          <FooterController
            flow={flow}
            meetingId={meetingId}
            calenderId={calenderId}
            currentStep={currentStep}
            meetingName={meetingName}
            meetingReccurence={meetingReccurence}
            meetingParticipants={meetingParticipants}
            invitedMembers={invitedMembers}
            templateId={selectedTemplate?.templateId || ''}
            onQuickSetupStepChange={handleCurrentStepChange}
            onTemplateChange={handleTemplateChange}
            onInviteNewMembersChange={handleInviteNewMembersChange}
            isInviteButtonDisabled={isInviteButtonDisabled}
            onInviteNewMembers={handleInviteMemberStatus}
          />
        )}
      </Container>
    </>
  );
};

export default QuickSetupController;
