import React, { useCallback, useMemo, useState } from 'react';

import Body from '../../atoms/Body';
import Heading from '../../atoms/Heading';
import ToolTip from '../ToolTipV2';
import AssemblyCurrencyIcon from '../../atoms/AssemblyCurrencyIcon';
import RedemptionCardLoader from './Loader';

import {
  REDEMPTION_CARD_TEXT,
  MY_REWARDS_TEXT,
  CONGRATS_YOU_HAVE_SUCCESSFULLY_REDEEMED,
  GIFT_CARD,
  REDEEM_CONTENT_MODAL_TEXTS,
} from '../../../languages/en/rewards';
import { Flex } from '../../../Utils/styles/display';
import { RewardTypes } from '../RewardCard/interface';
import { RedemptionCardProps, RedemptionCardLoadedProps } from './interface';

import { ComponentStatus } from '../../../interfaces/component';
import {
  StyledFlexWrapper,
  StyledFlexInnerWrapper,
  StyledImage,
  StyledBody,
  StyledClickableLink,
  StyledToolTipContent,
  StyledButton,
  StyledGiftCardImage,
  StyledGiftCardBody,
  StyledModal,
  StyledDetails,
} from './styles';
import { parseOrder } from '../../../controllers/rewards/RedeemModalController/utility';

import PlaceholderImage from '../../../img/my-rewards-placeholder.svg';
import Image from '../../atoms/Image';
import { OrderStatus } from '../../../queries/Rewards/interfaces';
import ThemeV2 from '../../../componentsV2/theme';

const LoadedComponent = (props: RedemptionCardLoadedProps) => {
  const {
    currencyAmount,
    rewardType,
    orderId,
    title,
    image,
    assemblyCurrency,
    redeemedPoints,
    redeemedOn,
    disabled = false,
    description,
    onButtonClick,
    orderInfo,
    className,
    swagDetails,
    isRedemptionDetailsLoading,
    trackingLink,
    shippingStatus,
  } = props;

  const [isOpenModal, setOpenModal] = useState(false);
  const openModalHandler = useCallback(() => {
    if (swagDetails?.orderStatus === OrderStatus.DELIVERED) {
      onButtonClick(orderId, rewardType);
    }
    setOpenModal(true);
  }, [onButtonClick, orderId, rewardType, swagDetails?.orderStatus]);
  const closeModalHandler = () => {
    setOpenModal(false);
  };

  const bodyVariant =
    rewardType === RewardTypes.GiftCard || RewardTypes.Swags
      ? 'body3'
      : 'body2';
  const assemblyIconSize = useMemo(() => {
    return rewardType === RewardTypes.GiftCard ? '12px' : '14px';
  }, [rewardType]);
  const renderRedeemedDetails = useMemo(() => {
    return (
      <Flex>
        <Body variant={bodyVariant}>
          {REDEMPTION_CARD_TEXT.REDEEMED_ON} {redeemedOn}
        </Body>
        {(rewardType === RewardTypes.GiftCard ||
          (rewardType === RewardTypes.Swags &&
            (swagDetails?.orderStatus === OrderStatus.CANCELED ||
              swagDetails?.orderStatus === OrderStatus.DELIVERED))) && (
          <StyledClickableLink
            onClickCallback={openModalHandler}
            fontSize="12px"
          >
            {`, ${REDEMPTION_CARD_TEXT.REDEMPTION_DETAILS}`}
          </StyledClickableLink>
        )}
      </Flex>
    );
  }, [
    bodyVariant,
    openModalHandler,
    redeemedOn,
    rewardType,
    swagDetails?.orderStatus,
  ]);
  const buttonText = useMemo(() => {
    if (rewardType === RewardTypes.GiftCard) {
      return disabled
        ? REDEMPTION_CARD_TEXT.EMAIL_RESENT
        : REDEMPTION_CARD_TEXT.RESEND_EMAIL;
    }
    if (rewardType === RewardTypes.Culture) {
      return disabled
        ? REDEMPTION_CARD_TEXT.REMINDER_SENT
        : REDEMPTION_CARD_TEXT.REMIND_ADMIN;
    }
    if (rewardType === RewardTypes.Swags) {
      if (swagDetails?.orderStatus) {
        if (swagDetails.orderStatus === OrderStatus.CANCELED) {
          return REDEMPTION_CARD_TEXT.CANCELLED;
        } else if (swagDetails.orderStatus === OrderStatus.DELIVERED) {
          return REDEMPTION_CARD_TEXT.DELIVERED;
        } else {
          return REDEMPTION_CARD_TEXT.CHECK_STATUS;
        }
      }
      return REDEMPTION_CARD_TEXT.CHECK_STATUS;
    }
    return REDEMPTION_CARD_TEXT.FULFILLED;
  }, [disabled, rewardType, swagDetails?.orderStatus]);
  const icon = useMemo(() => {
    return rewardType === RewardTypes.Charity ||
      (rewardType === RewardTypes.Swags &&
        swagDetails?.orderStatus === OrderStatus.DELIVERED)
      ? 'tick'
      : undefined;
  }, [rewardType, swagDetails?.orderStatus]);
  const status = useMemo(() => {
    return rewardType === RewardTypes.Charity ? 'success' : 'default';
  }, [rewardType]);

  const disabledButtonTextColor = useMemo(() => {
    if (swagDetails?.orderStatus) {
      if (swagDetails.orderStatus === OrderStatus.DELIVERED) {
        return ThemeV2.palette.polarGreen7;
      } else if (swagDetails.orderStatus === OrderStatus.CANCELED) {
        return ThemeV2.palette.dustRed6;
      }
    }
  }, [swagDetails?.orderStatus]);

  const handleButtonClick = useCallback(() => {
    if (buttonText === REDEMPTION_CARD_TEXT.CHECK_STATUS) {
      openModalHandler();
      if (swagDetails?.orderStatus !== OrderStatus.CANCELED) {
        onButtonClick(orderId, rewardType);
      }
    } else {
      onButtonClick(orderId, rewardType);
    }
  }, [
    buttonText,
    onButtonClick,
    openModalHandler,
    orderId,
    rewardType,
    swagDetails?.orderStatus,
  ]);

  const primaryButton = {
    text: 'Close',
    isLoading: false,
    onClick: closeModalHandler,
  };

  const trackingInformationText = useMemo(() => {
    if (!shippingStatus) {
      return REDEEM_CONTENT_MODAL_TEXTS.ORDER_NOT_SHIPPED;
    }
    switch (shippingStatus) {
      case OrderStatus.DELIVERED:
        return REDEEM_CONTENT_MODAL_TEXTS.ORDER_DELIVERED;
      case OrderStatus.SHIPPED:
        return;
      case OrderStatus.ORDER_PLACED:
        return REDEEM_CONTENT_MODAL_TEXTS.ORDER_NOT_SHIPPED;
      case OrderStatus.CANCELED:
        return REDEEM_CONTENT_MODAL_TEXTS.ORDER_CANCELLED;
      default:
        return REDEEM_CONTENT_MODAL_TEXTS.ORDER_NOT_SHIPPED;
    }
  }, [shippingStatus]);

  const renderRedemptionDetailsModalContent = useMemo(() => {
    if (rewardType === RewardTypes.Swags) {
      return (
        <>
          <StyledDetails variant="body1Bold">
            {REDEEM_CONTENT_MODAL_TEXTS.TRACKING_INFORMATION}
          </StyledDetails>
          <Body variant="body3">{trackingInformationText}</Body>
          {swagDetails?.orderStatus &&
            swagDetails?.orderStatus !== OrderStatus.CANCELED && (
              <Body variant="body3">{trackingLink}</Body>
            )}
          <StyledDetails variant="body1Bold">
            {REDEEM_CONTENT_MODAL_TEXTS.SENT_TO}
          </StyledDetails>
          {swagDetails && (
            <>
              {' '}
              <Body variant="body3">
                {`${swagDetails.firstName} ${swagDetails.lastName}`}
              </Body>
              <Body variant="body3">
                {`${swagDetails.address}, ${swagDetails.apartment}`}{' '}
              </Body>
              <Body variant="body3">
                {/*eslint-disable-next-line max-len*/}
                {`${swagDetails.city}, ${swagDetails.state} ${swagDetails.pincode}`}{' '}
              </Body>
            </>
          )}
          <StyledDetails variant="body1Bold">
            {REDEEM_CONTENT_MODAL_TEXTS.PRODUCT_INFORMATION}
          </StyledDetails>
          {image && (
            <Image alt={title} src={image} width="80px" height="80px" />
          )}
          <StyledDetails variant="body2Bold">{title}</StyledDetails>
          <Body variant="body3">
            {REDEEM_CONTENT_MODAL_TEXTS.TOTAL_COST}
            <AssemblyCurrencyIcon
              assemblyCurrency={assemblyCurrency}
              currencyName={assemblyCurrency.name}
              id={assemblyCurrency.name}
              size="12px"
            />
            {redeemedPoints && redeemedPoints}
          </Body>
          <Body variant="body3">
            {REDEEM_CONTENT_MODAL_TEXTS.GET_QUANTITY_SIZE_TEXT(
              swagDetails?.quantity,
            )}
            {swagDetails?.size &&
              REDEEM_CONTENT_MODAL_TEXTS.GET_SIZE_TEXT(swagDetails?.size)}
          </Body>
        </>
      );
    } else {
      return (
        <>
          <Heading variant="h6">
            {CONGRATS_YOU_HAVE_SUCCESSFULLY_REDEEMED}
          </Heading>
          <StyledGiftCardImage src={image || PlaceholderImage} alt={title} />
          <StyledGiftCardBody variant="body2Bold">
            ${currencyAmount} {title} {GIFT_CARD}
          </StyledGiftCardBody>
          {orderInfo && parseOrder(orderInfo)}
        </>
      );
    }
  }, [
    assemblyCurrency,
    currencyAmount,
    image,
    orderInfo,
    redeemedPoints,
    rewardType,
    swagDetails,
    title,
    trackingInformationText,
    trackingLink,
  ]);

  return (
    <>
      <StyledModal
        isOpen={isOpenModal}
        heading="Redemption Details"
        secondaryButton={primaryButton}
        onModalClose={closeModalHandler}
        isLoading={isRedemptionDetailsLoading}
      >
        {renderRedemptionDetailsModalContent}
      </StyledModal>
      <StyledFlexWrapper
        padding="24px"
        justifyContent="space-between"
        className={className}
      >
        <StyledFlexInnerWrapper>
          <StyledImage
            src={image || PlaceholderImage}
            alt={title}
            width="102px"
            height="60px"
          />
          <div>
            <Flex>
              <StyledBody variant="body2Bold">{title}</StyledBody>
              <ToolTip iconSize="12px">
                <StyledToolTipContent>{description}</StyledToolTipContent>
              </ToolTip>
            </Flex>
            <Body variant={bodyVariant} data-testid="redeemedFor">
              {swagDetails &&
                REDEMPTION_CARD_TEXT.GET_QUANTITY_TEXT(swagDetails.quantity)}
              {swagDetails?.size &&
                REDEMPTION_CARD_TEXT.GET_SIZE_TEXT(swagDetails?.size)}
              {`${' '}${REDEMPTION_CARD_TEXT.REDEEMED_FOR} `}
              <AssemblyCurrencyIcon
                id={assemblyCurrency.name}
                currencyName={assemblyCurrency.name}
                assemblyCurrency={assemblyCurrency}
                size={assemblyIconSize}
              />
              {redeemedPoints}
            </Body>
            {renderRedeemedDetails}
          </div>
        </StyledFlexInnerWrapper>
        <StyledButton
          onClick={handleButtonClick}
          variant="text"
          status={status}
          icon={icon}
          disabled={disabled}
          disabledButtonTextColor={disabledButtonTextColor}
        >
          {buttonText}
        </StyledButton>
      </StyledFlexWrapper>
    </>
  );
};

const RedemptionCard = (props: RedemptionCardProps) => {
  switch (props.status) {
    case ComponentStatus.LOADING:
      return <RedemptionCardLoader />;
    case ComponentStatus.EMPTY: {
      return (
        <div>
          <Body variant="body1Bold" margin="0 0 12px">
            {MY_REWARDS_TEXT.REDEMPTION_HISTORY}
          </Body>
          <Body variant="body1">
            {MY_REWARDS_TEXT.EMPTY_REDEMPTION_HISTORY}
          </Body>
        </div>
      );
    }
    default: {
      return <LoadedComponent {...props} />;
    }
  }
};

export default RedemptionCard;
