import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

import ThemeV2 from '../../../componentsV2/theme';
import { AsideWidthWhenClosed, device } from '../../../constants/layout';
import { zIndexFooter, zIndexTabsHeader } from '../../../Utils/styles/z-index';

export const useStyles = makeStyles({
  root: (props: { isFixed: boolean }) => {
    return {
      flexGrow: 1,
      width: '100%',
      ...(props.isFixed ? { position: 'fixed', zIndex: 3 } : {}),
    };
  },
  tabPanelRoot: {
    flexGrow: 1,
    width: '100%',
    display: 'flex',
    height: '100%',
  },
});

export const StyledTabNavDivider = styled.div`
  width: 1px;
  background-color: ${ThemeV2.palette.gray4};
  height: 20px;
  margin-top: 8px;
`;

export const StyledTabPanel = styled.div`
  position: relative;
  margin-top: 0;
  padding: 28px 0 66px;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
`;

export const RewardsFixedHeader = styled.div`
  z-index: ${zIndexTabsHeader};
  right: ${AsideWidthWhenClosed}px;
  top: 70px;
  @media ${device.mobile} {
    z-index: ${zIndexTabsHeader};
  }
`;

export const StyledTabPanelScrollable = styled.div`
  padding-bottom: 70px;
  @media ${device.tablet} {
    padding-bottom: 0px;
  }
  @media ${device.mobile} {
    padding-bottom: 88px;
  }
`;

export const StyledRewardsWrapper = styled.div<{
  isLeftAsideOpen: boolean;
  isRightAsideOpen: boolean;
}>`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  padding: 24px;
  gap: 16px;
  max-width: 976px;
  margin-right: auto;
  margin-left: auto;
  @media (max-width: 1670px) {
    grid-template-columns: ${(props) => {
      return (
        props.isLeftAsideOpen &&
        props.isRightAsideOpen &&
        'repeat(3, minmax(0, 1fr))'
      );
    }};
  }
  @media (max-width: 1462px) {
    grid-template-columns: ${(props) => {
      return (
        props.isLeftAsideOpen &&
        props.isRightAsideOpen &&
        'repeat(2, minmax(0, 1fr))'
      );
    }};
  }
  @media (max-width: 1347px) {
    grid-template-columns: ${(props) => {
      return (
        props.isLeftAsideOpen &&
        !props.isRightAsideOpen &&
        'repeat(3, minmax(0, 1fr))'
      );
    }};
  }
  @media (max-width: 1335px) {
    grid-template-columns: ${(props) => {
      return (
        !props.isLeftAsideOpen &&
        props.isRightAsideOpen &&
        'repeat(3, minmax(0, 1fr))'
      );
    }};
  }
  @media (max-width: 1254px) {
    grid-template-columns: ${(props) => {
      return (
        props.isLeftAsideOpen &&
        props.isRightAsideOpen &&
        'repeat(1, minmax(0, 1fr))'
      );
    }};
  }
  @media (max-width: 1139px) {
    grid-template-columns: ${(props) => {
      return (
        props.isLeftAsideOpen &&
        !props.isRightAsideOpen &&
        'repeat(2, minmax(0, 1fr)))'
      );
    }};
  }
  @media (max-width: 1127px) {
    grid-template-columns: ${(props) => {
      return (
        !props.isLeftAsideOpen &&
        props.isRightAsideOpen &&
        'repeat(2, minmax(0, 1fr))'
      );
    }};
  }
  @media (max-width: 1012px) {
    grid-template-columns: ${(props) => {
      return (
        !props.isLeftAsideOpen &&
        !props.isRightAsideOpen &&
        'repeat(3, minmax(0, 1fr))'
      );
    }};
  }
  @media (max-width: 931px) {
    grid-template-columns: ${(props) => {
      return (
        props.isLeftAsideOpen &&
        !props.isRightAsideOpen &&
        'repeat(1, minmax(0, 1fr))'
      );
    }};
  }
  @media (max-width: 919px) {
    grid-template-columns: ${(props) => {
      return (
        !props.isLeftAsideOpen &&
        props.isRightAsideOpen &&
        'repeat(1, minmax(0, 1fr))'
      );
    }};
  }
  @media (max-width: 804px) {
    grid-template-columns: ${(props) => {
      return (
        !props.isLeftAsideOpen &&
        !props.isRightAsideOpen &&
        'repeat(2, minmax(0, 1fr))'
      );
    }};
  }
  @media (max-width: 596px) {
    grid-template-columns: ${(props) => {
      return (
        !props.isLeftAsideOpen &&
        !props.isRightAsideOpen &&
        'repeat(1, minmax(0, 1fr))'
      );
    }};
  }
  @media (max-width: 519px) {
    max-width: 288px;
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const StyledRewardsLoaderWrapper = styled.div`
  display: flex;
  flex-flow: wrap;
  padding: 24px;
  max-width: 976px;
  margin: 0 auto;
`;

export const RewardsFooter = styled.footer<{
  isLeftAsideOpen: boolean;
  isRightAsideOpen: boolean;
}>`
  position: fixed;
  margin-left: 24px;
  width: ${(props) => {
    return (
      !props.isLeftAsideOpen && !props.isRightAsideOpen && 'calc(100% - 156px)'
    );
  }};
  width: ${(props) => {
    return (
      props.isLeftAsideOpen && !props.isRightAsideOpen && 'calc(100% - 376px)'
    );
  }};
  width: ${(props) => {
    return (
      !props.isLeftAsideOpen && props.isRightAsideOpen && 'calc(100% - 475px)'
    );
  }};
  width: ${(props) => {
    return (
      props.isLeftAsideOpen && props.isRightAsideOpen && 'calc(100% - 692px)'
    );
  }};
  bottom: 0;
  z-index: ${zIndexFooter};
  @media ${device.tablet} {
    position: relative;
    left: unset;
    right: unset;
    width: 100%;
  }
  @media ${device.mobile} {
    position: fixed;
    left: 16px;
    right: 16px;
    width: calc(100% - 32px);
  }
`;
