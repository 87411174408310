import React, { useRef, useMemo } from 'react';

import useOnClickOutside from '../../../hooks/useOnOutsideClick';
import useToggle from '../../../hooks/useToggle';

import { DropdownCardWrapper } from '../../atoms/DropdownCard_V2/styles';
import Body from '../../atoms/Body';
import IconButton from '../../atoms/IconButton_V2';
import {
  DropdownPlacement,
  MenuItemProps,
} from '../../molecules/Dropdown_V2/interfaces';

import Divider from '../../atoms/Divider';
import ThemeV2 from '../../../componentsV2/theme';
import { Flex } from '../../../Utils/styles/display';
import Button from '../../atoms/Button';
import { BlockBuilderSettingState } from '../../../interfaces/Flow/Builder';
import {
  ADD_DESCRIPTION,
  BLOCK_SETTINGS,
  CHOOSE_RANGE,
  REMOVE_DESCRIPTION,
  toolTipContentForPointsStack,
  CUSTOM_SELECTION,
} from '../../../languages/en/flows/builder';
import {
  REQUIRED,
  TO,
  CANCEL,
  CONFIRM,
} from '../../../languages/en/singleWords';
import {
  ContainerElement,
  ContentWrapper,
  RequiredText,
  SettingsContent,
  SettingsHeader,
  StyledDropdown,
  StyledDropdownCard,
  StyledTooltip,
  ToText,
  StyledModal,
} from './styles';
import { AssemblyCurrency } from '../../../interfaces/assembly';
import SelectOptions from './SelectOptions';
import OpenEndedSettings from './OpenEndedSettings';
import GivePointsStackSettings from './GivePointsStackSettings';
import PersonSelectorSettings from './PersonSelectorSettings';
import { ButtonVariants } from '../../atoms/Button/interfaces';
import { SwitchTextTypes } from '../../atoms/ToggleSwitch/types';
import ToggleSwitch from '../../atoms/ToggleSwitch';
import SVGIcon from '../../atoms/SVGIcon';

const createRangeMenuItems = (
  num: number,
  startNum: number,
): MenuItemProps[] => [
  {
    id: `${num}-${startNum}`,
    items: new Array(num).fill(startNum).map((val, index) => ({
      id: `${val + index}`,
      value: `${val + index}`,
    })),
  },
];

export type FlowBuilderBlockSettingsProps = {
  blockSettingState: BlockBuilderSettingState;
  assemblyCurrency: AssemblyCurrency;
};

const FlowBuilderBlockSettings = ({
  blockSettingState,
  assemblyCurrency,
}: FlowBuilderBlockSettingsProps) => {
  const {
    toggleIsRequired,
    isRequired,
    isDescriptionNeeded,
    toggleDescriptionNeeded,
    postCustomModalClose = () => {},
    disableRequiredToggle,
  } = blockSettingState;
  const {
    models: { toggleValue: isOpen },
    operations: {
      setToggleValue: toggleOpen,
      setToggleToFalse: setOpenToFalse,
    },
  } = useToggle();

  const {
    models: { toggleValue: showModal },
    operations: {
      setToggleToFalse: setModalOpenToFalse,
      setToggleToTrue: setModalOpenToTrue,
    },
  } = useToggle(false);

  const hasCustomModal = useMemo(
    () => blockSettingState.type === 'PERSON_SELECTOR',
    [blockSettingState.type],
  );

  const handleModalOpen = () => {
    setOpenToFalse();
    setModalOpenToTrue();
  };

  const handleModalClose = () => {
    if (hasCustomModal && postCustomModalClose) {
      postCustomModalClose();
    }
    setModalOpenToFalse();
    toggleOpen();
  };

  const referenceElement = useRef(null);
  const anchorEl = useRef(null);

  useOnClickOutside(referenceElement, setOpenToFalse);

  const renderSpecificBlockContent = () => {
    switch (blockSettingState.type) {
      case 'SCALE': {
        const { onRangeChange, minimumRange, maximumRange } = blockSettingState;
        const minimumRangeSelections = createRangeMenuItems(2, 0);
        const maximumRangeSelections = createRangeMenuItems(6, 5);
        return (
          <div>
            <Body variant="body2">{CHOOSE_RANGE}</Body>
            <Flex margin="8px 0 0 0">
              <StyledDropdown
                menuItems={minimumRangeSelections}
                value={String(minimumRange)}
                onItemClick={(val) => onRangeChange(Number(val), maximumRange)}
              />
              <ToText variant="body1">{TO}</ToText>
              <StyledDropdown
                menuItems={maximumRangeSelections}
                value={String(maximumRange)}
                onItemClick={(val) =>
                  blockSettingState.onRangeChange(minimumRange, Number(val))
                }
              />
            </Flex>
          </div>
        );
      }
      case 'MULTI_CHOICE':
      case 'DROPDOWN': {
        return <SelectOptions blockSettingState={blockSettingState} />;
      }
      case 'PERSON_SELECTOR': {
        return (
          <PersonSelectorSettings
            openModal={handleModalOpen}
            blockSettingState={blockSettingState}
          />
        );
      }
      case 'OPEN_ENDED': {
        return <OpenEndedSettings blockSettingState={blockSettingState} />;
      }
      case 'GIVE_POINTS_STACK': {
        return (
          <GivePointsStackSettings
            blockSettingState={blockSettingState}
            assemblyCurrency={assemblyCurrency}
          />
        );
      }
      case 'GIF':
      case 'FILE_UPLOAD':
      default: {
        return null;
      }
    }
  };

  const getBlockSettingsModalProps = () => {
    const tertiaryVariant: ButtonVariants = 'text';

    switch (blockSettingState.type) {
      case 'PERSON_SELECTOR':
        return {
          heading: CUSTOM_SELECTION,
          primaryButton: {
            text: CONFIRM,
            onClick: handleModalClose,
          },
          secondaryButton: {
            variant: tertiaryVariant,
            text: CANCEL,
            onClick: handleModalClose,
          },
          onModalClose: handleModalClose,
        };
      default:
        return {
          onModalClose: () => {},
        };
    }
  };

  const renderBlockSettingsModalContent = () => {
    switch (blockSettingState.type) {
      case 'PERSON_SELECTOR':
        return blockSettingState?.customModalOptions?.component;
      default:
        return null;
    }
  };

  return (
    <>
      <ContainerElement ref={referenceElement}>
        <IconButton
          iconColor={ThemeV2.palette.gray8}
          icon="setting-filled"
          isSelected={isOpen}
          onClick={toggleOpen}
          buttonRef={anchorEl}
          dataTestId="icon-button"
        />
        <DropdownCardWrapper>
          <StyledDropdownCard
            isOpen={isOpen}
            anchorEl={anchorEl.current}
            dropdownPlacement={DropdownPlacement.BottomEnd}
          >
            <ContentWrapper>
              <SettingsHeader variant="body1Medium">
                {BLOCK_SETTINGS}
              </SettingsHeader>
              <Divider
                isFullWidth
                marginTop="8px"
                color={ThemeV2.palette.gray4}
              />
              <SettingsContent>
                {isDescriptionNeeded ? (
                  <Button
                    variant="text"
                    color="primary"
                    icon="minus"
                    status="warning"
                    onClick={toggleDescriptionNeeded}
                  >
                    {REMOVE_DESCRIPTION}
                  </Button>
                ) : (
                  <Button
                    variant="text"
                    color="primary"
                    icon="union"
                    onClick={toggleDescriptionNeeded}
                  >
                    {ADD_DESCRIPTION}
                  </Button>
                )}
                {renderSpecificBlockContent()}
              </SettingsContent>
              <Divider isFullWidth color={ThemeV2.palette.gray4} />
              <Flex padding="12px 0" flexDirection="row-reverse">
                {disableRequiredToggle ? (
                  <StyledTooltip
                    toolTipComponent={
                      <>
                        <Flex alignItems="center">
                          <Body variant="body2">{REQUIRED}</Body>
                          <SVGIcon
                            size="16px"
                            color={ThemeV2.palette.gray6}
                            icon="icon-circle-outlined"
                          />
                        </Flex>
                        <ToggleSwitch
                          onChange={toggleIsRequired}
                          id="blockSettings"
                          isOn={isRequired}
                          textType={SwitchTextTypes.YES_NO}
                          disabled={disableRequiredToggle}
                        />
                      </>
                    }
                  >
                    {toolTipContentForPointsStack(assemblyCurrency.pluralName)}
                  </StyledTooltip>
                ) : (
                  <>
                    <ToggleSwitch
                      onChange={toggleIsRequired}
                      id="blockSettings"
                      isOn={isRequired}
                      textType={SwitchTextTypes.YES_NO}
                      disabled={disableRequiredToggle}
                    />
                    <RequiredText variant="body2">{REQUIRED}</RequiredText>
                  </>
                )}
              </Flex>
            </ContentWrapper>
          </StyledDropdownCard>
        </DropdownCardWrapper>
      </ContainerElement>
      {hasCustomModal && (
        <StyledModal
          childrenPadding="0px"
          alignActionButtons="right"
          isOpen={showModal}
          {...getBlockSettingsModalProps()}
        >
          {renderBlockSettingsModalContent()}
        </StyledModal>
      )}
    </>
  );
};

export default FlowBuilderBlockSettings;
