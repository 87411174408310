import React from 'react';
import { TextFieldSizes } from '../../molecules/TextField_V2/interface';
import { MultiSelectDropdownProps } from './interface';
import { StyledTextField, TextFieldGroup } from './styles';

const MultiSelectDropdown = (props: MultiSelectDropdownProps) => {
  const {
    fieldOne,
    fieldTwo,
    fieldThree,
    size = TextFieldSizes.MD,
    className,
  } = props;
  return (
    <TextFieldGroup className={className}>
      <StyledTextField {...fieldOne} size={size} fieldThree={fieldThree} />
      <StyledTextField {...fieldTwo} size={size} fieldThree={fieldThree} />
      {fieldThree && (
        <StyledTextField {...fieldThree} size={size} fieldThree={fieldThree} />
      )}
    </TextFieldGroup>
  );
};

export default MultiSelectDropdown;
