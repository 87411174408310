import React from 'react';

import { BlockSectionProps } from './type';
import { BlockSectionHeading, BlockSectionSubHeading } from './styles';

const BlockSection = (props: BlockSectionProps) => {
  const {
    heading,
    children,
    subHeading,
    marginBottom,
    headingColor,
    headingVariant,
    subHeadingColor,
    subHeadingVariant,
  } = props;
  return (
    <>
      <BlockSectionHeading
        variant={headingVariant}
        color={headingColor}
        weight="medium"
      >
        {heading}
      </BlockSectionHeading>
      <BlockSectionSubHeading
        variant={subHeadingVariant}
        color={subHeadingColor}
        marginBottom={marginBottom}
      >
        {subHeading}
      </BlockSectionSubHeading>
      {children}
    </>
  );
};

const MemoizedBlockSection = React.memo(BlockSection);
MemoizedBlockSection.displayName = 'BlockSection';

export default MemoizedBlockSection;
