import { PlanType } from '../../../../atomic/organism/RouteTabs/interfaces';
import {
  MiscTaskCategories,
  TaskCategories,
} from '../../../../interfaces/notebook';
import {
  TODAY,
  UPCOMING,
  UNSCHEDULED,
  UNARCHIVE,
  COMPLETED,
  MOVE_TO,
  OVERDUE,
  ASSIGNED_BY_ME,
  MY_WORK,
} from '../../../../languages/en/notebook';
import { ITEM, DELETE, ARCHIVE } from '../../../../languages/en/singleWords';
import { capitalizeFirstLetter } from '../../../../Utils/text';
import { DropdownItemOption } from './types';

export const notebookSections: TaskCategories[] = [
  TaskCategories.TODAY,
  TaskCategories.OVERDUE,
  TaskCategories.UPCOMING,
  TaskCategories.UNSCHEDULED,
  TaskCategories.COMPLETED,
  TaskCategories.ARCHIVED,
];

export const taskDropdownItems: DropdownItemOption[] = [
  {
    id: TaskCategories.TODAY,
    value: `${MOVE_TO} ${capitalizeFirstLetter(TODAY)}`,
    deleteItem: false,
    isSelected: false,
    prefixIcon: 'today',
  },
  {
    id: TaskCategories.UPCOMING,
    value: `${MOVE_TO} ${capitalizeFirstLetter(UPCOMING)}`,
    deleteItem: false,
    isSelected: false,
    prefixIcon: 'clock',
  },
  {
    id: TaskCategories.OVERDUE,
    value: `${MOVE_TO} ${capitalizeFirstLetter(OVERDUE)}`,
    deleteItem: false,
    isSelected: false,
    prefixIcon: 'run-now',
  },
  {
    id: TaskCategories.UNSCHEDULED,
    value: `${MOVE_TO} ${capitalizeFirstLetter(UNSCHEDULED)}`,
    deleteItem: false,
    isSelected: false,
    prefixIcon: 'unscheduled',
  },
  {
    id: TaskCategories.COMPLETED,
    value: `${MOVE_TO} ${capitalizeFirstLetter(COMPLETED)}`,
    deleteItem: false,
    isSelected: false,
    prefixIcon: 'check-rounded',
  },
  {
    id: MiscTaskCategories.UNARCHIVE,
    value: `${capitalizeFirstLetter(UNARCHIVE)} ${ITEM}`,
    deleteItem: false,
    isSelected: false,
    prefixIcon: 'unarchive',
  },
  {
    id: TaskCategories.ARCHIVED,
    value: `${capitalizeFirstLetter(ARCHIVE)} ${ITEM}`,
    deleteItem: true,
    isSelected: false,
    prefixIcon: 'archive',
  },
  {
    id: MiscTaskCategories.DELETE,
    value: `${capitalizeFirstLetter(DELETE)} ${ITEM}`,
    deleteItem: true,
    isSelected: false,
    prefixIcon: 'delete',
  },
];

export const heightAnimationProps = {
  initial: {
    y: -50,
    opacity: 0.2,
  },
  animate: {
    opacity: 1,
    y: 0,
    height: 'auto',
  },
  exit: {
    opacity: 1,
    height: '0px',
    transition: {
      type: 'tween',
      duration: 0.4,
      delay: 0.05,
    },
  },
  transition: {
    type: 'spring',
    damping: 10,
    mass: 0.9,
    stiffness: 100,
  },
};

export const opacityAnimationProps = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
};

export enum NotebookTabs {
  myWork = 'myWork',
  ato = 'ato',
}

export const TabsIndexMap = {
  [NotebookTabs.myWork]: 0,
  [NotebookTabs.ato]: 1,
};

export const notebookTabsData = [
  {
    id: NotebookTabs.myWork,
    value: NotebookTabs.myWork,
    label: MY_WORK,
    planType: PlanType.FREE,
    index: 0,
  },
  {
    id: NotebookTabs.ato,
    value: NotebookTabs.ato,
    label: ASSIGNED_BY_ME,
    planType: PlanType.FREE,
    index: 1,
  },
];
