import ContentLoader from 'react-content-loader';
import React from 'react';

import styled from 'styled-components';
import MainFeedLinkButton from '../../atomic/molecules/MainFeedLinkButton';
import ThemeV2 from '../../componentsV2/theme';
import { Flex } from '../../Utils/styles/display';

const StyledWrapper = styled(Flex)`
  width: 100%;
`;

const ProfileHeaderLoader = () => {
  return (
    <StyledWrapper padding="0 32px 0 16px">
      <ContentLoader
        speed={2}
        width={240}
        height={80}
        viewBox="0 0 240 80"
        backgroundColor={ThemeV2.palette.gray4}
        foregroundColor={ThemeV2.palette.gray3}
        uniqueKey="profile-header-loader"
      >
        <circle cx="24" cy="40" r="24" />
        <rect x="56" y="18" rx="4" width="184" height="25" />
        <rect x="56" y="46" rx="4" width="184" height="16" />
      </ContentLoader>
      <MainFeedLinkButton />
    </StyledWrapper>
  );
};

export default ProfileHeaderLoader;
