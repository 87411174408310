export interface LayoutStyleProps {
  isActive?: boolean;
  showRightAside?: boolean;
  hasFlowBuilderTabs?: boolean;
  isRewardsPage?: boolean;
  isLeftAsideOpen?: boolean;
  isRightAsideOpen?: boolean;
  hasAdminOption?: boolean;
  background?: string;
  hasTabComponent?: boolean;
  hasContentFooter?: boolean;
  removeMargin?: boolean;
  isHovered?: boolean;
  isKioskTurnedOn?: boolean;
}

export enum SecondaryPanel {
  ASSEMBLY = 'assembly',
  NOTIFICATIONS = 'notifications',
  PROFILE = 'profile',
  QUICK_SETUP = 'quick-setup',
  REWARDS = 'rewards',
}

export interface SelectedMember {
  firstName?: string;
  memberId: string;
}

export type LayoutStoreState = {
  isLeftAsideOpen: boolean;
  isRightAsideOpen: boolean;
  showRightAside: boolean;
  isLeftNavHovered: boolean;
  activeNavItem: SecondaryPanel;
  activeSecondaryPanel: SecondaryPanel;
  toggleLeftAsideOpen: () => void;
  toggleRightAsideOpen: () => void;
  toggleShowRightAside: () => void;
  setLeftAsideOpenToTrue: () => void;
  setLeftAsideOpenToFalse: () => void;
  setRightAsideOpenToTrue: () => void;
  setRightAsideOpenToFalse: () => void;
  setShowRightAsideToTrue: () => void;
  setShowRightAsideToFalse: () => void;
  setLeftNavHoveredToTrue: () => void;
  setLeftNavHoveredToFalse: () => void;
  setActiveNavItem: (navItem: SecondaryPanel) => void;
  setActiveSecondaryPanel: (navItem: SecondaryPanel) => void;
  selectedMember?: SelectedMember;
  setSelectedMember: (selectedMember: SelectedMember) => void;
  setMemberAndToggleRightAsideOpen: (selectedMember: SelectedMember) => void;
  removeSelectedMember: () => void;
  taskIdForNotebook: string;
  setTaskIdForNotebook: (taskId: string) => void;
};
