import { useHistory, useLocation } from 'react-router-dom';
import { useProfileInfoFetchQuery } from '../../../../queries/Profile';
import { useFetchMembersDetailsQuery } from '../../../../queries/MembersDetails';
import { useCallback, useMemo } from 'react';
import { transformMemberToAutoCompleteDropdownItem } from '../../../home/ProfileFeedController/utils';

const useRecognitionParticipationController = () => {
  const history = useHistory();
  const { search } = useLocation();

  const params = new URLSearchParams(search);
  const userId = params.get('userId') || '';
  const redirectUrl = params.get('redirectUrl') || '/';

  const { data: profileData, isLoading: isLoadingProfileData } =
    useProfileInfoFetchQuery();

  const { data: currentProfileUserData } = useFetchMembersDetailsQuery(
    [userId],
    Boolean(userId),
  );

  const handleCloseParticipationModal = useCallback(() => {
    history.push(redirectUrl);
  }, [history, search]);

  const loadingFlowHeaderContent = {
    TitleContent: '',
    DescriptionContent: '',
  };

  const profileDataForRecognitionModalDropdown = useMemo(() => {
    const isOwnProfileFeed = profileData?.member.memberId === userId;
    if (isOwnProfileFeed || !currentProfileUserData) {
      return undefined;
    }

    const memberDetails = currentProfileUserData.membersDetails[0];
    return transformMemberToAutoCompleteDropdownItem(memberDetails);
  }, [profileData, currentProfileUserData]);

  return {
    profileData,
    isLoadingProfileData,
    loadingFlowHeaderContent,
    handleCloseParticipationModal,
    profileDataForRecognitionModalDropdown,
  };
};

export default useRecognitionParticipationController;
