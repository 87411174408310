import React from 'react';
import { criteriaConditionItems } from '../../../../constants/flowsBuilder';
// eslint-disable-next-line max-len
import FlowsBuilderFilterCriteriaController from '../../../../controllers/flowsBuilder/FlowsBuilderFilterCriteriaController';
import {
  StyledContent,
  StyledParticipantsListWrapper,
  StyledModalTitle,
} from './styles';
import ThemeV2 from '../../../../componentsV2/theme';
import { ComponentStatus } from '../../../../interfaces/component';
import { CriteriaGroups } from '../../../../interfaces/Flow/Builder';
import {
  ADD_SELECTABLE_CRITERIA,
  CUSTOM_PERSON_SELECTOR_BLOCK_SETTINGS,
} from '../../../../languages/en/flows/builder';
import { Flex } from '../../../../Utils/styles/display';
import { criteriaMenuItems } from '../../CriteriaItemBlock/dummyData';
import ParticipantsList from '../../../molecules/ParticipantsList';
import { Participant } from '../../../molecules/ParticipantsList/types';
import Divider from '../../../atoms/Divider';
import { capitalizeFirstLetter } from '../../../../Utils/text';
import { SELECTABLE } from '../../../../languages/en/singleWords';

const participantListTitle = capitalizeFirstLetter(SELECTABLE);

const PERSON_SELECTOR_FROM_PARAM = 'personSelector';

const CustomPersonSelectorModal = ({
  criteriaGroups,
  handleOnCriteriaGroupsChange,
  participants = [],
  fetchNextPage = () => {},
  participantsCount,
  hasMoreMembers,
  isLoading = false,
  pendingMembersParticipantCount,
}: {
  criteriaGroups: CriteriaGroups | undefined;
  handleOnCriteriaGroupsChange: (value: CriteriaGroups | undefined) => void;
  participants?: Participant[];
  fetchNextPage: () => void;
  participantsCount?: number;
  hasMoreMembers?: boolean;
  isLoading?: boolean;
  pendingMembersParticipantCount?: number;
}) => {
  return (
    <div>
      <StyledModalTitle variant="body2">
        {CUSTOM_PERSON_SELECTOR_BLOCK_SETTINGS}
      </StyledModalTitle>
      <Divider isFullWidth color={ThemeV2.palette.gray4} />
      <Flex alignItems="unset">
        <StyledContent flexDirection="column" alignItems="flex-start">
          <FlowsBuilderFilterCriteriaController
            hideEveryone={true}
            criteriaGroups={criteriaGroups}
            criteriaMenuItems={criteriaMenuItems}
            criteriaConditionItems={criteriaConditionItems}
            onCriteriaGroupsChange={handleOnCriteriaGroupsChange}
            criteriaLabel={ADD_SELECTABLE_CRITERIA}
            from={PERSON_SELECTOR_FROM_PARAM}
          />
        </StyledContent>
        <StyledParticipantsListWrapper
          flexDirection="column"
          alignItems="unset"
        >
          {isLoading ? (
            <ParticipantsList
              title={participantListTitle}
              status={ComponentStatus.LOADING}
              restrictHeight={false}
            />
          ) : (
            <ParticipantsList
              title={participantListTitle}
              participants={participants}
              hasMoreMembers={hasMoreMembers}
              fetchNextPage={fetchNextPage}
              participantsCount={participantsCount}
              status={ComponentStatus.LOADED}
              restrictHeight={false}
              pendingMembersParticipantCount={pendingMembersParticipantCount}
            />
          )}
        </StyledParticipantsListWrapper>
      </Flex>
    </div>
  );
};

export default CustomPersonSelectorModal;
