import React from 'react';

import useCreateFlowsModalController from './useCreateFlowsModalController';
import FlowRequestModal from '../../../../atomic/organism/FlowRequestModal';
import RequestTemplateModal from '../../../../atomic/organism/FlowRequestModal/RequestTemplateModal';
import { LoadingParticipationTemplate } from '../../../../atomic/pages/ParticipationTemplate';
import { ComponentStatus } from '../../../../interfaces/component';
import FlowsTemplatePreviewController from '../../FlowsTemplatePreviewController';
import { trackTemplateGalleryActionEvent } from '../../../../Utils/analytics/templateGallery';
import { TEMPLATE_GALLERY_EVENTS } from '../../../../Utils/analytics/constants';

type CreateFlowsModalControllerProps = {
  handleModalClose: () => void;
};

const CreateFlowsModalController = (props: CreateFlowsModalControllerProps) => {
  const { handleModalClose } = props;
  const {
    selectedCategory,
    handleCategoryClick,
    handleCategoryBlur,
    searchValue,
    setSearchValue,
    categories,
    isGetCategoriesLoading,
    isGetTemplatesLoading,
    handleTemplateClick,
    templates,
    handleRequestTemplateFromScratchClick,
    isRequestModalOpen,
    handleRequestTemplateModalClose,
    isCreateFlowsModalOpen,
    requestFoTemplate,
    selectedTemplate,
    closePreviewTemplateModal,
    onUseTemplateClick,
    profileInfo,
    flowTemplateData,
    isFlowTemplateLoading,
  } = useCreateFlowsModalController({
    handleCreateFlowsModalClose: handleModalClose,
  });

  const renderPreviewTemplateModal = () => {
    if (profileInfo && selectedTemplate) {
      if (isFlowTemplateLoading) {
        return (
          <LoadingParticipationTemplate
            member={profileInfo.member}
            onCloseModal={closePreviewTemplateModal}
            flowId=""
            headerStatus={ComponentStatus.LOADING}
            flowHeaderContent={{ DescriptionContent: '', TitleContent: '' }}
          />
        );
      }
      if (flowTemplateData) {
        /* Mix panel */
        trackTemplateGalleryActionEvent({
          action: TEMPLATE_GALLERY_EVENTS.PREVIEW_TEMPLATE,
          templateId: selectedTemplate.templateId,
          templateName: selectedTemplate.title,
        });
        /* Mix panel */
        return (
          <FlowsTemplatePreviewController
            profileInfo={profileInfo}
            flowTemplateData={flowTemplateData}
            closePreviewTemplateModal={closePreviewTemplateModal}
            onUseTemplateClick={onUseTemplateClick}
          />
        );
      }
    }
    return null;
  };

  return (
    <>
      <FlowRequestModal
        value={searchValue}
        isOpen={isCreateFlowsModalOpen}
        handleClose={handleModalClose}
        categories={categories}
        onCategoryClick={handleCategoryClick}
        onCategoryBlur={handleCategoryBlur}
        selectedCategory={selectedCategory}
        templateCardItems={templates}
        onChange={setSearchValue}
        isGetCategoriesLoading={isGetCategoriesLoading}
        isGetTemplatesLoading={isGetTemplatesLoading}
        onTemplateClick={handleTemplateClick}
        onRequestTemplateFromScratchClick={
          handleRequestTemplateFromScratchClick
        }
      />
      {renderPreviewTemplateModal()}
      <RequestTemplateModal
        isOpen={isRequestModalOpen}
        onModalClose={handleRequestTemplateModalClose}
        templateName={requestFoTemplate}
      />
    </>
  );
};

export default CreateFlowsModalController;
