import styled from 'styled-components';
import Body from '../../../atomic/atoms/Body';
import ThemeV2 from '../../../componentsV2/theme';
import { Flex } from '../../../Utils/styles/display';
import BasicModalTemplate from '../../../atomic/templates/BasicModalTemplate';

export const FooterWrapper = styled(Flex)`
  bottom: 0;
  z-index: 1;
  width: 502px;
  height: 64px;
  padding: 12px;
  position: fixed;
  background-color: ${ThemeV2.palette.white};
`;

export const RightSideButtonsWrapper = styled(Flex)`
  button:nth-child(2) {
    margin-left: 8px;
  }
`;

export const StyledModal = styled(BasicModalTemplate)`
  max-width: 382px;
`;

export const StyledSubHeading = styled(Body)`
  margin-top: 16px;
`;

export const StyledMeetingName = styled(Body)`
  text-overflow: ellipsis;
  max-width: 100%;
  white-space: nowrap;
`;

export const Footer = styled.div`
  left: 0;
  bottom: 0;
  width: 100%;
  height: 64px;
  position: fixed;
  background-color: ${ThemeV2.palette.white};
`;
