import styled from 'styled-components';

import Button from '../../atoms/Button';
import Body from '../../atoms/Body';
import SVGIcon from '../../atoms/SVGIcon';
import Avatar from '../../atoms/Avatar';
import ThemeV2 from '../../../componentsV2/theme';
import { Flex } from '../../../Utils/styles/display';
import { StyledComponentProps } from './interface';
import { device } from '../../../constants/layout';

export const AccordionItemInner = styled.div<StyledComponentProps>`
  position: relative;
  display: flex;
  align-items: center;
  padding: ${(props) => !props.itemHasNoValue && '12px 16px'};
  &:after {
    ${(props) =>
      !props.isCollapseOpen &&
      `content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      width: calc(100% - 16px);
      margin: auto;
      background: ${ThemeV2.palette.gray4};
      `}
  }
  @media ${device.mobile} {
    padding: 8px 12px 8px;
  }
`;

export const ItemNoValueContentWrapper = styled.button`
  height: 100%;
  width: 100%;
  cursor: pointer;
  background: none;
  border: none;
  transition: 0.2s ease background;
  &:focus {
    outline: 0;
  }
  &:hover,
  &:focus-visible {
    background: ${ThemeV2.palette.gray2};
  }
`;

export const ItemRoot = styled.div`
  width: 100%;
  box-sizing: border-box;
  &:last-child ${AccordionItemInner} {
    &:after {
      display: none;
    }
  }
`;

export const StyledButton = styled(Button)`
  margin-left: auto;
`;

export const StyledSVGIcon = styled(SVGIcon)`
  margin-right: 8px;
`;

export const AccordionContent = styled(Flex)<StyledComponentProps>`
  background: ${ThemeV2.palette.gray1};
  box-shadow: inset 0px 0px 8px 1px rgba(0, 0, 0, 0.15);
  padding: 20px;
  @media ${device.mobile} {
    padding: 16px 12px;
  }
`;

export const StyledEmptyDiv = styled.div`
  width: 108px;
  @media ${device.mobile} {
    width: unset;
  }
`;

export const StyledWrapper = styled.div`
  width: 400px;
  @media ${device.mobile} {
    width: 100%;
  }
`;

export const StyledAccordionContentChildren = styled.div`
  flex: 1;
`;

export const StyledSVGIconItemLock = styled(SVGIcon)`
  margin: 8px 20px 8px auto;
`;

export const StyledSVGIconContentLock = styled(SVGIcon)`
  margin: 12px 12px 12px 8px;
`;

export const StyledBodyItemTitle = styled(Body)`
  width: 108px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StyledBodyPlaceholderValue = styled(Body)`
  color: ${ThemeV2.palette.gray8};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
`;

export const StyledAvatar = styled(Avatar)`
  margin-right: 16px;
`;

export const StyledBodyItemNoValue = styled(Body)`
  display: flex;
  align-items: center;
  color: ${ThemeV2.palette.geekBlue6};
  padding: 16px;
  & > span {
    margin-right: 4px;
  }
  @media ${device.mobile} {
    padding: 8px 0;
  }
`;

export const AccordionRoot = styled.div`
  background: ${ThemeV2.palette.gray1};
  border: 1px solid ${ThemeV2.palette.gray4};
  border-radius: 4px;
  margin-bottom: 16px;
`;

export const AccordionHeader = styled.div`
  padding: 20px 8px 0px 8px;
`;

export const AccordionHeaderInner = styled.div`
  border-bottom: 1px solid ${ThemeV2.palette.gray4};
  padding: 0px 8px 12px 8px;
`;
