import { BaseEmoji, CustomEmoji, EmojiData } from 'emoji-mart';
import { CurrentUser } from '../../interfaces/currentUser';
import {
  FeedCommentFromAPI,
  FeedItemFromAPI,
  Reaction,
  ReactionEmoji,
  UpdateReactionAction,
} from '../../interfaces/Feed';
import { ReactionType } from '../../interfaces/Feed/Reaction';
import { getFullName } from '../user';

type SerializePostFunctionType = (
  entity: FeedItemFromAPI | FeedCommentFromAPI,
  userID: string | undefined,
) => Reaction[];

export const serializeReactions: SerializePostFunctionType = (entity, userID) =>
  entity.reactions.map((reaction) => ({
    members: reaction.members.map((member) => ({
      name: member.name,
      memberID: member.memberID,
    })),
    reaction: {
      name: reaction.name,
      type: reaction.type,
      displayName: reaction?.displayName,
      value: reaction.value,
    },
    timesReacted: reaction.members.length,
    active: reaction.members.some((member) => member.memberID === userID),
  }));

export const serializeReactionTogglePayload = (
  reaction: ReactionEmoji,
  contentID: string,
  action: UpdateReactionAction,
  currentUser: CurrentUser,
) => ({
  payload: reaction,
  contentID,
  action,
  userData: {
    memberID: currentUser.memberId,
    name: getFullName(currentUser.profile),
  },
});

type GetEmojiPayloadFunction = (
  reactions: Reaction[],
  emoji: EmojiData,
) => [Reaction | undefined, ReactionEmoji];

export const getEmojiPayload: GetEmojiPayloadFunction = (reactions, emoji) => {
  const selectedReaction = reactions.find(
    ({ reaction }) => reaction.name === emoji.colons,
  );
  const customEmoji = { ...emoji } as CustomEmoji;
  let payload: ReactionEmoji;
  if (customEmoji.imageUrl) {
    payload = {
      type: ReactionType.CUSTOM,
      name: customEmoji.colons || '',
      value: customEmoji.imageUrl,
      displayName: customEmoji.name,
    };
  } else {
    const emo = { ...emoji } as BaseEmoji;
    payload = {
      type: ReactionType.REGULAR,
      name: emo.colons,
      value: '',
      displayName: emo.id,
    };
  }
  return [selectedReaction, payload];
};
