import introJS from 'intro.js';
import { useEffect, useMemo } from 'react';
import { useUpdateFeatureIntrosQuery } from '../../queries/FeatureIntros';
import { useFeatureIntrosStore } from '../../stores/featureIntrosStore';
import { featureIntrosSelector } from '../../stores/featureIntrosStore/selectors';
import {
  getShareSheetHintOptions,
  getShareSheetModalIntroOptions,
  getShareSheetButtonIntroOptions,
} from '../../Utils/featureIntros/getShareSheetIntroStrings';
import { TourStatusEnum } from '../../Utils/featureIntros/types';
import { isDesktop } from '../../Utils/window';

export enum FeatureIntro {
  ACCESS_POLICY_PARTICIPANT_VIEWER = 'ACCESS_POLICY_PARTICIPANT_VIEWER',
  ACCESS_POLICY_SHARE_BUTTON = 'ACCESS_POLICY_SHARE_BUTTON',
}

export enum FeatureHint {
  ACCESS_POLICY_SHARE_BUTTON = 'ACCESS_POLICY_SHARE_BUTTON',
}

export type FeatureIntroProps = {
  feature: FeatureIntro;
  enabled?: boolean;
};

export type FeatureHintProps = {
  feature: FeatureHint;
};

export type FeatureIntroOptions = {
  getStepDetails: () => introJS.Step[];
};

export type FeatureHintOptions = {
  getHintDetails: () => introJS.Hint[];
};

const featureIntroOptionsMap: Record<FeatureIntro, FeatureIntroOptions> = {
  ACCESS_POLICY_PARTICIPANT_VIEWER: getShareSheetModalIntroOptions(),
  ACCESS_POLICY_SHARE_BUTTON: getShareSheetButtonIntroOptions(),
};

export const useFeatureIntro = ({
  feature,
  enabled = true,
}: FeatureIntroProps) => {
  const { mutate } = useUpdateFeatureIntrosQuery(feature);
  const featureIntros = useFeatureIntrosStore(featureIntrosSelector);
  const canShowFeatureIntro =
    featureIntros[feature] &&
    featureIntros[feature] === TourStatusEnum.TODO &&
    enabled &&
    isDesktop;

  return useEffect(() => {
    const { getStepDetails } = featureIntroOptionsMap[feature];
    if (canShowFeatureIntro) {
      introJS()
        .setOptions({
          steps: getStepDetails(),
          showButtons: true,
        })
        .onexit(mutate)
        .start();
    }
  }, [feature, canShowFeatureIntro, mutate]);
};

const featureHintOptionsMap: Record<FeatureHint, FeatureHintOptions> = {
  ACCESS_POLICY_SHARE_BUTTON: getShareSheetHintOptions(),
};

export const useHintIntro = ({ feature }: FeatureHintProps) => {
  const { mutate } = useUpdateFeatureIntrosQuery(feature);
  const featureIntros = useFeatureIntrosStore(featureIntrosSelector);
  const canDisplayFeatureHint = useMemo(() => {
    return (
      featureIntros[feature] && featureIntros[feature] === TourStatusEnum.TODO
    );
  }, [feature, featureIntros]);

  return useEffect(() => {
    const { getHintDetails } = featureHintOptionsMap[feature];
    if (canDisplayFeatureHint) {
      introJS()
        .setOptions({
          hints: getHintDetails(),
          showButtons: true,
          buttonClass: 'introjs-button introjs-primarybutton',
          tooltipClass: 'introjs-paddingtop20',
        })
        .onexit(mutate)
        .onhintclose(() => mutate())
        .addHints();
    }
  }, [canDisplayFeatureHint, feature, mutate]);
};
