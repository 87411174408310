import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import styled from 'styled-components';
import ThemeV2 from '../../../componentsV2/theme';

interface StyledTabsProps {
  $isTabPositionFixed?: boolean;
}

export const StyledTabs = styled(Tabs)<StyledTabsProps>`
  padding: 0px 16px;
  min-height: auto;
  width: 100%;
  background-color: ${ThemeV2.palette.gray1};
  border-bottom: 1px solid ${ThemeV2.palette.gray4};
  position: ${({ $isTabPositionFixed }) => $isTabPositionFixed && 'fixed'};
  z-index: ${({ $isTabPositionFixed }) => $isTabPositionFixed && 2};
  margin-top: ${({ $isTabPositionFixed }) => $isTabPositionFixed && '-37px'};

  .MuiTab-root {
    color: ${ThemeV2.palette.gray7};
  }

  .MuiTabs-indicator {
    border-radius: 4px 4px 0 0;
    background-color: ${ThemeV2.palette.geekBlue6};
  }

  .MuiTabs-scrollButtonsDesktop {
    @media (max-width: 599.95px) {
      display: inline-flex;
    }

    &:disabled {
      display: none;
    }
  }
`;

export const StyledTab = styled(Tab)`
  border-radius: 4px;
  text-transform: capitalize;
  color: ${ThemeV2.palette.gray7};
  margin: 2px 4px;
  padding-top: 1px;
  padding-bottom: 1px;
  height: 32px;
  min-width: auto;
  min-height: auto;

  &:hover {
    background-color: ${ThemeV2.palette.geekBlue1};
    color: ${ThemeV2.palette.geekBlue6};
  }

  &.Mui-selected {
    color: ${ThemeV2.palette.geekBlue6};
  }
`;

export const StyledMoreButton = styled.button`
  align-items: center;
  padding: 0;
  background: transparent;
  border: 0;

  &:focus {
    outline: 0;
  }
`;
