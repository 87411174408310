import React, { useState, useEffect, useCallback } from 'react';
import useLayoutStore from '../../../stores/layoutStore';

import TabComponent, {
  TabComponentProps,
} from '../../../atomic/molecules/TabComponent';
import SVGIcon from '../../../atomic/atoms/SVGIcon';

import { FILTER, ABOUT } from '../../../languages/en/singleWords';
import ProfileViewController from '../../home/ProfileViewController';

import FilterController from './FilterController';
import {
  StyledWrapper,
  Wrapper,
} from '../../../atomic/organism/RightDrawerFilter/styles';
import useGetParsedFilterOptions from '../../../hooks/useGetParsedFilterOptions';
import useGetPageName from '../../../hooks/useGetPageName';
import { RIGHT_DRAWER_EVENTS } from '../../../Utils/analytics/constants';
import { LayoutStoreState } from '../../../interfaces/Layout';
import { trackRightDrawerEvent } from '../../../Utils/analytics';

const layoutSelector = (state: LayoutStoreState) => state.selectedMember;

const ProfileFeedRightDrawerController = () => {
  const { page } = useGetPageName();

  const [isFiltered, setIsFiltered] = useState(false);
  const { isProfileFeedFilterApplied } = useGetParsedFilterOptions();

  useEffect(() => {
    if (isProfileFeedFilterApplied) {
      setIsFiltered(true);
    } else {
      setIsFiltered(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onTabClickHandler = useCallback(
    (drawerTabViewed: any) => {
      trackRightDrawerEvent(RIGHT_DRAWER_EVENTS.TAB_CLICKED, {
        drawerTabViewed,
        feedViewed: page,
      });
    },
    [page],
  );

  const selectedMember = useLayoutStore(layoutSelector);

  const AboutComponent = (
    <ProfileViewController
      firstName={selectedMember?.firstName}
      memberId={selectedMember?.memberId || ''}
    />
  );

  const tabComponentProps: TabComponentProps = {
    tabItems: [
      {
        tabHeader: {
          label: <span>{ABOUT}</span>,
          icon: <SVGIcon icon="people" size="16px" />,
        },
        tabContent: AboutComponent,
      },
      {
        tabHeader: {
          label: <span>{FILTER}</span>,
          icon: <SVGIcon icon="filter" size="16px" />,
        },
        tabContent: (
          <Wrapper>
            <FilterController setIsFiltered={setIsFiltered} />
          </Wrapper>
        ),
        isFiltered,
      },
    ],
    splitEqually: true,
    selectedTabIndex: 0,
    onTabClick: onTabClickHandler,
  };

  return (
    <StyledWrapper>
      <TabComponent {...tabComponentProps} />
    </StyledWrapper>
  );
};

export default ProfileFeedRightDrawerController;
