import React from 'react';

import { FlowDetailsProps } from './types';
import { ComponentStatus } from '../../../../interfaces/component';

import LoadingComponent from './LoadingComponent';
import ErrorComponent from './ErrorComponent';
import LoadedComponent from './LoadedComponent';

const FlowDetails = (props: FlowDetailsProps) => {
  switch (props.status) {
    case ComponentStatus.LOADED:
      return <LoadedComponent {...props} />;
    case ComponentStatus.LOADING:
      return <LoadingComponent {...props} />;
    default:
      return <ErrorComponent {...props} />;
  }
};

export default FlowDetails;
