const status = {
  NOT_AUTHORIZED: 'NOT_AUTHORIZED',
  APP_INSTALLED: 'APP_INSTALLED',
  AUTHORIZED: 'AUTHORIZED',
  CONFIGURATION_COMPLETE: 'CONFIGURATION_COMPLETE',
  CONNECTED: 'CONNECTED',
};

export const statusText = {
  NOT_AUTHORIZED: 'Not authorized',
  APP_INSTALLED: 'App installed',
  AUTHORIZED: 'Authorized',
  CONFIGURATION_COMPLETE: 'Configuration complete',
  CONNECTED: 'Connected',
};

export const IMQuickSettingsText = {
  HEADING: `Auto approve all new members or require invite and request approvals?`,
  SUB_HEADING: `Once accounts are created, you'll need to send invitations so people can access Assembly. Decide whether to send these invitations now or later`,
};

export default status;
