import { trackEvent } from '../index';
import { OnboardingEventProps } from '../interfaces';
import { AnalyticsEvents, ONBOARDING_EVENTS } from '../constants';

const trackOnboardingShowEvent = (eventProps: OnboardingEventProps) => {
  trackEvent(ONBOARDING_EVENTS, undefined, {
    ...eventProps,
    event: AnalyticsEvents.SHOW,
  });
};

const trackOnboardingActionEvent = (eventProps: OnboardingEventProps) => {
  trackEvent(ONBOARDING_EVENTS, undefined, {
    ...eventProps,
    event: AnalyticsEvents.ACTION,
  });
};

const trackOnboardingErrorEvent = (eventProps: OnboardingEventProps) => {
  trackEvent(ONBOARDING_EVENTS, undefined, {
    ...eventProps,
    event: AnalyticsEvents.ERROR,
  });
};

export {
  trackOnboardingShowEvent,
  trackOnboardingErrorEvent,
  trackOnboardingActionEvent,
};
