import React, { useMemo } from 'react';
import { AssemblyCurrency } from '../../../interfaces/assembly';
import { GivePointsBlockBuilderSettingState } from '../../../interfaces/Flow/Builder';
import {
  LIMIT_PERCENTAGE,
  LIMIT_VALUE,
  LIMIT_AMOUNT_OF,
  GIVEN_IN_POSTS,
  AMOUNT_OF,
} from '../../../languages/en/flows/builder';
import { RadioOptionProps } from '../../molecules/RadioGroup';
import Textfield from '../../molecules/NewTextfield';
import Checkbox from '../../atoms/Checkbox';
import AssemblyCurrencyIcon from '../../atoms/AssemblyCurrencyIcon';
import { LeftPaddedRadioGroup } from './styles';
import { capitalizeFirstLetter } from '../../../Utils/text';
import { HIDE } from '../../../languages/en/singleWords';
import { supportedWholeNumberKeyCodes } from '../../../constants/flowsBuilder';

const GivePointsStackSettings = ({
  blockSettingState,
  assemblyCurrency,
}: {
  assemblyCurrency: AssemblyCurrency;
  blockSettingState: GivePointsBlockBuilderSettingState;
}) => {
  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (!supportedWholeNumberKeyCodes.includes(event.key)) {
      event.preventDefault();
    }
  };

  const {
    hideCurrencyValues,
    toggleHideCurrencyValues,
    limitAmountDetails,
    changeAmountDetails,
  } = blockSettingState;
  const trophiesAmountRadioOptions: RadioOptionProps[] = [
    {
      value: 'PERCENT',
      label: LIMIT_PERCENTAGE,
      children: (
        <Textfield
          type="number"
          value={
            limitAmountDetails?.type === 'PERCENT'
              ? String(limitAmountDetails.value)
              : ''
          }
          suffix="%"
          onChange={(e) =>
            changeAmountDetails({
              type: 'PERCENT',
              value: e.target.value ? Number(e.target.value) : 0,
            })
          }
          onKeyPress={handleKeyPress}
          onBlur={() =>
            limitAmountDetails?.value === 0 &&
            changeAmountDetails({
              type: 'PERCENT',
              value: 20,
            })
          }
        />
      ),
    },
    {
      value: 'EXACT',
      label: LIMIT_VALUE,
      children: (
        <Textfield
          type="number"
          value={
            limitAmountDetails?.type === 'EXACT'
              ? String(limitAmountDetails.value)
              : ''
          }
          onChange={(e) =>
            changeAmountDetails({
              type: 'EXACT',
              value: e.target.value ? Number(e.target.value) : 0,
            })
          }
          prefix={
            <AssemblyCurrencyIcon
              size="14px"
              assemblyCurrency={assemblyCurrency}
              currencyName={assemblyCurrency.name}
              id="trophy-selector-icon"
            />
          }
          onKeyPress={handleKeyPress}
          onBlur={() =>
            limitAmountDetails?.value === 0 &&
            changeAmountDetails({
              type: 'EXACT',
              value: 20,
            })
          }
        />
      ),
    },
  ];

  const hideCurrencyValuesTextLabel = useMemo(
    () => (
      <>
        {`${capitalizeFirstLetter(HIDE)} ${AMOUNT_OF} `}
        <AssemblyCurrencyIcon
          size="14px"
          assemblyCurrency={assemblyCurrency}
          currencyName={assemblyCurrency.pluralName}
          id="trophy-selector-icon"
        />
        {`${assemblyCurrency.pluralName} ${GIVEN_IN_POSTS}`}
      </>
    ),
    [assemblyCurrency],
  );

  const limitCurrencyAmountText = useMemo(
    () => (
      <>
        {`${LIMIT_AMOUNT_OF} `}
        <AssemblyCurrencyIcon
          size="14px"
          assemblyCurrency={assemblyCurrency}
          currencyName={assemblyCurrency.pluralName}
          id="trophy-selector-icon"
        />
        {`${assemblyCurrency.pluralName}`}
      </>
    ),
    [assemblyCurrency],
  );

  return (
    <>
      <Checkbox
        name="limit-currency-amount"
        value={Boolean(limitAmountDetails)}
        label={limitCurrencyAmountText}
        size="16px"
        onChange={() =>
          changeAmountDetails(
            limitAmountDetails ? undefined : { type: 'PERCENT', value: 20 },
          )
        }
      />
      {limitAmountDetails && (
        <LeftPaddedRadioGroup
          radioOptions={trophiesAmountRadioOptions}
          value={limitAmountDetails.type}
          name="limit-amount-details"
          onChange={({ target: { value } }) =>
            changeAmountDetails(
              value === 'PERCENT'
                ? {
                    type: value,
                    value: 20,
                  }
                : { type: 'EXACT', value: 100 },
            )
          }
        />
      )}
      <Checkbox
        name="hide-currency-values"
        value={hideCurrencyValues}
        label={hideCurrencyValuesTextLabel}
        size="16px"
        onChange={toggleHideCurrencyValues}
      />
    </>
  );
};

export default GivePointsStackSettings;
