import {
  EASY_COME_EASY_FLOW,
  FIND_YOUR_FLOW,
  GET_THIS_FLOW_ON_THE_ROAD,
  GET_YOUR_CREATIVE_JUICES_FLOWING,
  GO_WITH_THE_FLOW,
  HOW_FLOW_CAN_YOU_GO,
  MAKE_LIKE_A_RIVER_AND_FLOW,
  NO_EBBS_JUST_FLOWS,
  QUID_PRO_FLOW,
  SEARCH_ANY_WAY_THE_WIND_FLOWS,
  SEARCH_HIGH_AND_FLOW,
  SEE_WHERE_IT_FLOWS,
  WELL_ALL_FLOAT_ON,
} from '../../../languages/en/feed';

const PLACEHOLDER_PUNS = [
  EASY_COME_EASY_FLOW,
  FIND_YOUR_FLOW,
  GET_THIS_FLOW_ON_THE_ROAD,
  GET_YOUR_CREATIVE_JUICES_FLOWING,
  GO_WITH_THE_FLOW,
  HOW_FLOW_CAN_YOU_GO,
  MAKE_LIKE_A_RIVER_AND_FLOW,
  NO_EBBS_JUST_FLOWS,
  QUID_PRO_FLOW,
  SEARCH_ANY_WAY_THE_WIND_FLOWS,
  SEARCH_HIGH_AND_FLOW,
  SEE_WHERE_IT_FLOWS,
  WELL_ALL_FLOAT_ON,
];

export const getRandomPlaceholderPun = () => {
  const randomIndex = Math.floor(Math.random() * PLACEHOLDER_PUNS.length);
  return PLACEHOLDER_PUNS[randomIndex];
};
