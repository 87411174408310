/* eslint-disable max-len */
import { IntegrationProvidersEnum } from '../../../screens/admin/content/settings/Integrations/interfaces';

export const botDisconnectModalLanguage = {
  [IntegrationProvidersEnum.SLACK]: {
    heading: 'Disconnect Chat Integration',
    contentHeading: 'Disconnect Slack?',
    contentBodyIMAndBot: '',
    contentBodyIMOnly: '',
    contentBodyBotOnly: `By disconnecting, your members will no longer be able to post within Slack. If your Assembly is connected to the Slack identity manager, you will still be able to auto manage your members.`,
    secondaryButtonText: 'No, keep it',
    primaryButtonText: 'Yes, disconnect it',
  },
  'SLACK-IM': {
    heading: 'Disconnect Identity Manager',
    contentHeading: 'Disconnect Slack?',
    contentBodyIMAndBot: '',
    contentBodyIMOnly: `By disconnecting, Slack will no longer be able to auto manage your members and you will need to manually manage them. If your Assembly is connected to the Slack chat integration, members will be able to post within Slack.`,
    secondaryButtonText: 'No, keep it',
    primaryButtonText: 'Yes, disconnect it',
  },
  OFFICE365: {
    heading: 'Disconnect Integration',
    contentHeading: 'Disconnect Office365?',
    contentBodyIMOnly: `By disconnecting, Office365 will no longer be able to auto manage your members and you will need to manually manage them.`,
    secondaryButtonText: 'No, keep it',
    primaryButtonText: 'Yes, disconnect it',
  },
  [IntegrationProvidersEnum.MSTEAM]: {
    heading: 'Disconnect Integration',
    contentHeading: 'Disconnect MS Teams?',
    contentBodyBotOnly: `By disconnecting, members will no longer be able to post within MS Teams.`,
    secondaryButtonText: 'No, keep it',
    primaryButtonText: 'Yes, disconnect it',
  },
  GSUITE: {
    heading: 'Disconnect Integration',
    contentHeading: 'Disconnect GSuite?',
    contentBodyIMOnly: `By disconnecting, GSuite will no longer be able to auto manage your members and you will need to manually manage them.`,
    secondaryButtonText: 'No, keep it',
    primaryButtonText: 'Yes, disconnect it',
  },
  ADP: {
    heading: 'Disconnect Integration',
    contentHeading: 'Disconnect ADP?',
    contentBodyIMOnly: `By disconnecting, ADP will no longer be able to auto manage your members and you will need to manually manage them.`,
    secondaryButtonText: 'No, keep it',
    primaryButtonText: 'Yes, disconnect it',
  },
};

export const getGeneralDisconnectModalLanguage = (displayName: string) => ({
  heading: 'Disconnect Integration',
  contentHeading: `Disconnect ${displayName}?`,
  contentBodyIMOnly: `By disconnecting, ${displayName} will no longer be able to auto manage your members and you will need to manually manage them.`,
  secondaryButtonText: 'No, keep it',
  primaryButtonText: 'Yes, disconnect it',
});
