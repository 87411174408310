export enum TourStatusEnum {
  COMPLETED = 'COMPLETED',
  SKIPPED = 'SKIPPED',
  TODO = 'TODO',
}

export type ToursResponseData = {
  name: string;
  status: TourStatusEnum;
};

export type ToursResponse = {
  data: {
    data: ToursResponseData[];
  };
};
