import isEmpty from 'lodash/isEmpty';
import pluralize from 'pluralize';
import { useEffect, useMemo } from 'react';

import { useGetMembersFromCriteria } from '../../../../../hooks/useMembersSearch';
import { getParticipants } from '../../../../../Utils/flows';
import {
  getLengthOfConditions,
  mapRulesFromCriteriaGroups,
} from '../../../../../Utils/flows/builder/utils';
import { isArrayEquals } from '../../../../../Utils/common';
import { PARTICIPANT } from '../../../../../languages/en/singleWords';
import { CriteriaGroups } from '../../../../../interfaces/Flow/Builder';
import { ShareSheetParticipantsControllerProps } from '../index';

import useFlowBuilderStore from '../../../../../stores/flowBuilderStore';

import {
  newParticipantsToInviteSelector,
  setNewParticipantsToInviteSelector,
} from '../../../../../stores/flowBuilderStore/selectors';

const useShareSheetParticipantsController = (
  props: ShareSheetParticipantsControllerProps,
) => {
  const { participantsBlockData, updateParticipantsBlockData } = props;
  const { participantsCriteria } = participantsBlockData;

  const rulesFromCriteria = useMemo(
    () => mapRulesFromCriteriaGroups(participantsCriteria, undefined, true),
    [participantsCriteria],
  );

  const criteriaLength = useMemo(
    () => getLengthOfConditions(participantsCriteria),
    [participantsCriteria],
  );

  const {
    models: {
      isLoading,
      hasMoreMembers,
      searchedMembers,
      totalMembers,
      newMembers,
      totalPendingMembers,
    },
    operations: { fetchMoreMembers },
  } = useGetMembersFromCriteria(
    rulesFromCriteria,
    criteriaLength && criteriaLength > 5 ? criteriaLength * 5 : 20,
    { isNewMembersAdded: true },
  );

  const participants = useMemo(
    () => getParticipants(searchedMembers, newMembers),
    [newMembers, searchedMembers],
  );

  const participantsCount = useMemo(() => {
    if (
      !isEmpty(participantsCriteria) &&
      !isEmpty(participantsCriteria?.groups)
    ) {
      if (newMembers) {
        return totalMembers + newMembers.length;
      }
      return totalMembers;
    }

    return 0;
  }, [participantsCriteria, newMembers, totalMembers]);

  const pendingMembersParticipantCount = useMemo(() => {
    if (
      !isEmpty(participantsCriteria) &&
      !isEmpty(participantsCriteria?.groups)
    ) {
      if (newMembers) {
        return totalPendingMembers + newMembers.length;
      }
      return totalPendingMembers;
    }

    return 0;
  }, [newMembers, participantsCriteria, totalPendingMembers]);

  const newParticipantsToInvite = useFlowBuilderStore(
    newParticipantsToInviteSelector,
  );
  const setNewParticipantsToInvite = useFlowBuilderStore(
    setNewParticipantsToInviteSelector,
  );

  useEffect(() => {
    if (!isArrayEquals(newMembers, newParticipantsToInvite)) {
      if (newMembers) {
        setNewParticipantsToInvite(newMembers);
      }

      if (!newMembers) {
        setNewParticipantsToInvite([]);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newMembers]);

  const handleOnCriteriaGroupsChange = (value: CriteriaGroups | undefined) => {
    updateParticipantsBlockData({
      ...participantsBlockData,
      participantsCriteria: value,
    });
  };

  const subtitleContent = useMemo(() => {
    return participantsCount > 0
      ? `${participantsCount} ${pluralize(
          PARTICIPANT,
          participantsCount,
          false,
        )}`
      : '';
  }, [participantsCount]);

  return {
    models: {
      isLoading,
      hasNextPage: hasMoreMembers,
      participants,
      subtitleContent,
      participantsCount,
      pendingMembersParticipantCount,
      participantsCriteriaGroups: participantsCriteria,
    },
    operations: {
      fetchNextPage: fetchMoreMembers,
      handleOnCriteriaGroupsChange,
    },
  };
};

export default useShareSheetParticipantsController;
