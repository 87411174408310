import React from 'react';

import useMyRewardsControllerModel from './useMyRewardsControllerModel';
import useMyRewardsControllerLogic from './useMyRewardsControllerLogic';

import MyRewards from '../../../atomic/organism/MyRewards';
import { ComponentStatus } from '../../../interfaces/component';

const MyRewardsController = () => {
  const {
    models: { rewardsHistory, assemblyCurrency, myRewardsData },
    metadata: { isLoading, isError },
    actions: { resendEmailForMyRewards },
  } = useMyRewardsControllerModel();

  const {
    data: { rewards, isRedemptionDetailsLoading, trackingLink, shippingStatus },
    operations: { onButtonClick },
  } = useMyRewardsControllerLogic(myRewardsData, resendEmailForMyRewards);

  if (isError) {
    return <div>Error</div>;
  }

  if (isLoading) {
    return <MyRewards status={ComponentStatus.LOADING} />;
  }

  if (rewards && rewardsHistory && assemblyCurrency) {
    return (
      <MyRewards
        status={ComponentStatus.LOADED}
        assemblyCurrency={assemblyCurrency}
        rewards={rewards}
        rewardsHistory={rewardsHistory}
        onButtonClick={onButtonClick}
        isRedemptionDetailsLoading={isRedemptionDetailsLoading}
        trackingLink={trackingLink}
        shippingStatus={shippingStatus}
      />
    );
  }

  if (rewards?.length === 0 && assemblyCurrency) {
    return (
      <MyRewards
        status={ComponentStatus.EMPTY}
        assemblyCurrency={assemblyCurrency}
      />
    );
  }

  return null;
};

export default MyRewardsController;
