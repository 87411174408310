import styled from 'styled-components';
import ThemeV2 from '../../../componentsV2/theme';
import { device } from '../../../constants/layout';
import { zIndexPopup } from '../../../Utils/styles/z-index';

export const PrimaryHeaderWrapper = styled.div`
  position: fixed;
  height: 72px;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  background-color: ${ThemeV2.palette.gray1};
  padding: 16px;
  border-bottom: 1px solid ${ThemeV2.palette.gray4};
  z-index: ${zIndexPopup};
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  overflow: hidden;
  border-radius: 4px;
  background-color: ${ThemeV2.palette.gray1};
`;

export const StyledLogo = styled.img`
  max-width: 100%;
  background-color: ${ThemeV2.palette.gray1};
`;

export const CustomHeader = styled.h6`
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  line-height: 28px;
  color: #262626;
  margin-bottom: 0px;
  margin-top: 6px;
  margin-left: 12px;
  max-width: calc(100vw - 340px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const HamburgerButton = styled.div`
  margin-right: 4px;
  @media ${device.tabletV2} {
    display: none;
  }
`;
