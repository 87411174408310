import styled from 'styled-components';
import ThemeV2 from '../../../componentsV2/theme';
import SVGIcon from '../../atoms/SVGIcon';
import Body from '../../atoms/Body';
import {
  ellipsisStyle,
  shadowElevation2Style,
} from '../../../Utils/styles/css';
import { StyledButton } from '../../../Utils/styles/button';

export const StyledBody = styled(Body)`
  display: block;
  ${ellipsisStyle};
`;

export const StyledSVGIcon = styled(SVGIcon)`
  margin-left: auto;
`;

export const StyledPromptToAnswerMain = styled.div`
  height: 48px;
  overflow: hidden;
  padding: 10px 12px;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-grow: 1;
`;

export const StyledActionBar = styled(StyledButton)`
  width: calc(100% - 24px);
  margin: 16px 12px;
  ${StyledSVGIcon} {
    svg path {
      fill: ${ThemeV2.palette.gray6};
    }
  }

  ${StyledBody} {
    color: ${({ disabled }) =>
      disabled ? ThemeV2.palette.gray6 : ThemeV2.palette.geekBlue6};
  }

  ${({ disabled }) => {
    if (disabled) {
      return `${StyledPromptToAnswerMain} {
        background-color: ${ThemeV2.palette.gray3};
        color: ${ThemeV2.palette.gray6};
        border: 1px solid ${ThemeV2.palette.gray3};
      }`;
    }
    return `${StyledPromptToAnswerMain} {
        background-color: ${ThemeV2.palette.gray1};
        color: ${ThemeV2.palette.geekBlue6};
        border: 1px solid ${ThemeV2.palette.gray5};

        &:hover {
          ${StyledPromptToAnswerMain} {
            ${shadowElevation2Style}
          }
        }
      }`;
  }}

  ${({ disabled }) => {
    if (!disabled) {
      return `&:hover {
        ${StyledPromptToAnswerMain} {
          ${shadowElevation2Style}
        }
      }`;
    }
    return null;
  }}
`;

export const StyledPromptToAnswerWrapper = styled.div`
  display: flex;
  column-gap: 12px;
`;
