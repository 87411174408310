import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ANONYMOUS_MEMBER_ID } from '../../../../../Utils/rightDrawer';

const useGetSisenseFilter = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const [startDate, setStartDate] = useState<Date | undefined>(() =>
    params.get('startDate')
      ? new Date(String(params.get('startDate')))
      : undefined,
  );

  const [endDate, setEndDate] = useState<Date | undefined>(() =>
    params.get('endDate') ? new Date(String(params.get('endDate'))) : undefined,
  );

  const [selectedResponses, setSelectedResponses] = useState<string[]>(
    params.get('responses')?.toString().split(',') || [],
  );

  const [selectedManagerStatus, setSelectedManagerStatus] = useState<string[]>(
    params.get('managerStatus')?.toString().split(',') || [],
  );

  const [selectedDepartment, setSelectedDepartment] = useState<string[]>(
    params.get('department')?.toString().split(',') || [],
  );

  const [selectedParticipant, setSelectedParticipant] = useState<string[]>(
    params
      .get('participant')
      ?.toString()
      .split(',')
      .map((x) => (x === ANONYMOUS_MEMBER_ID ? String.raw`N\A` : x)) || [],
  );

  const [occurenceStartDate, setOccurenceStartDate] = useState<string[]>(
    params.get('occurenceStartDate')?.toString().split(',') || [],
  );

  useEffect(() => {
    setStartDate(
      params.get('startDate')
        ? new Date(String(params.get('startDate')))
        : undefined,
    );
  }, [params.get('startDate')]);

  useEffect(() => {
    setEndDate(
      params.get('endDate')
        ? new Date(String(params.get('endDate')))
        : undefined,
    );
  }, [params.get('endDate')]);

  useEffect(() => {
    setSelectedResponses(params.get('responses')?.toString().split(',') || []);
  }, [params.get('responses')]);

  useEffect(() => {
    setSelectedManagerStatus(
      params.get('managerStatus')?.toString().split(',') || [],
    );
  }, [params.get('managerStatus')]);

  useEffect(() => {
    setSelectedDepartment(
      params.get('department')?.toString().split(',') || [],
    );
  }, [params.get('department')]);

  useEffect(() => {
    setSelectedParticipant(
      params
        .get('participant')
        ?.toString()
        .split(',')
        .map((x) => (x === ANONYMOUS_MEMBER_ID ? String.raw`N\A` : x)) ||
        [] ||
        [],
    );
  }, [params.get('participant')]);

  useEffect(() => {
    setOccurenceStartDate(
      params.get('occurenceStartDate')?.toString().split(',') || [],
    );
  }, [params.get('occurenceStartDate')]);

  return {
    endDate,
    startDate,
    selectedResponses,
    occurenceStartDate,
    selectedDepartment,
    selectedParticipant,
    selectedManagerStatus,
  };
};

export default useGetSisenseFilter;
