import styled from 'styled-components';
import Button from '../../atomic/atoms/Button';

export const StyledNavigateNextButton = styled(Button)`
  width: 146px;
`;

export const StyledNavigatePreviousButton = styled(Button)`
  width: 146px;
  margin-right: 20px;
`;
