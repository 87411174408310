import React, { useCallback, memo } from 'react';

import styled from 'styled-components';
import Giphy from '../../atoms/Giphy';
import { GifRatingsProps, EntryProps } from '../../atoms/Giphy/interface';
import usePopover from '../../../hooks/usePopover';

import { GifSelectorProps } from './interface';
import ThemeV2 from '../../../componentsV2/theme';
import { zIndexPopup } from '../../../Utils/styles/z-index';

const Wrapper = styled.div`
  width: fit-content;
  & div:nth-child(2) {
    background: ${ThemeV2.palette.gray1};
    z-index: ${zIndexPopup};
  }
`;

const GifSelector = (props: GifSelectorProps) => {
  const {
    onGifSelect,
    xAxisOffset = -100,
    yAxisOffset = 205,
    popoverPosition,
    children,
    gifRatings,
  } = props;
  const { models, operations } = usePopover({
    xAxisOffset,
    yAxisOffset,
    popoverPosition,
    children,
  });
  const {
    childrenWithPopoverProps,
    isPopoverOpen,
    styles,
    containerRef,
    referenceElement,
    popperElement,
  } = models;
  const { togglePopover } = operations;

  const handleGifSelect = useCallback(
    (entry: EntryProps) => {
      onGifSelect(entry);
      togglePopover();
    },
    [onGifSelect, togglePopover],
  );

  return (
    <Wrapper ref={containerRef}>
      <div ref={referenceElement}>{childrenWithPopoverProps}</div>
      <div ref={popperElement} style={styles.popper}>
        {isPopoverOpen && (
          <Giphy
            gifRatings={gifRatings || GifRatingsProps.PG_13}
            onGifSelect={handleGifSelect}
          />
        )}
      </div>
    </Wrapper>
  );
};

const MemoizedGifSelector = memo(GifSelector);
MemoizedGifSelector.displayName = 'GifSelector';

export default MemoizedGifSelector;
