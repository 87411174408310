import React, { useState, useEffect, useCallback } from 'react';

import { SingleSelectAutoComplete } from '../Autocomplete';
import FlowCheckbox from '../../molecules/FlowCheckbox';
import FilterCollapse from '../../molecules/FilterCollapse';
import { AutocompleteDropdownItem } from '../Autocomplete/interfaces';
import Body from '../../atoms/Body';

import {
  StyledButton,
  StyledBody,
  StyledCheckboxLoader,
  CollapseBodyWrapper,
} from './styles';
import useToggle from '../../../hooks/useToggle';
import {
  CheckboxFilterProps,
  CheckboxFilterLoadedProps,
  CheckboxFilterValues,
} from './types';

import { ComponentStatus } from '../../../interfaces/component';
import { NOTHING_TO_FILTER_BY } from '../../../languages/en/flows';

const LoadedComponent = (props: CheckboxFilterLoadedProps) => {
  const {
    headingText,
    filterByText,
    autoCompleteText,
    options,
    onSelectedOptionChange,
    selectedOptions,
    onCollapseClick,
    className,
    disableFilterBy = false,
  } = props;

  const [optionsToShow, setOptionsToShow] = useState<CheckboxFilterValues>([]);

  const [autoCompleteValue, setAutoCompleteValue] =
    useState<AutocompleteDropdownItem<string> | null>(null);
  const [autoCompleteOptions, setAutoCompleteOptions] = useState<
    AutocompleteDropdownItem<string>[]
  >([]);

  useEffect(() => {
    const updatedOptionsToShow = optionsToShow.map((item) => {
      const updatedOption = options.find((i) => i?.id === item?.id);
      return updatedOption ? updatedOption : item;
    });
    setOptionsToShow(updatedOptionsToShow);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  useEffect(() => {
    /*
    when more than 4 options has been selected & when we reload the page,
    we need to show all the selected options & not just initial 4.
    This code is to achieve that & it will run only once
    */
    const filteredOptions = options.filter((option) =>
      selectedOptions.includes(option.id),
    );
    const nonFilteredOptions = options.filter(
      (option) => !selectedOptions.includes(option.id),
    );
    const updatedOptions = [...filteredOptions, ...nonFilteredOptions];
    const sliceLength = filteredOptions.length < 5 ? 4 : filteredOptions.length;
    setOptionsToShow(updatedOptions.slice(0, sliceLength));

    const autoOptions = updatedOptions.slice(sliceLength).map((obj) => {
      let avatar;
      if (obj.img !== undefined) {
        avatar = {
          img: obj.img,
          userId: obj.id,
          name: obj.label,
        };
      }
      return {
        title: obj.label,
        id: obj.id,
        avatar,
        memberState: obj.memberState,
      };
    });
    setAutoCompleteOptions([...autoOptions]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    models: { toggleValue: showAutocomplete },
    operations: { setToggleValue: toggleAutoComplete },
  } = useToggle();

  const addValueToSelectedOptions = useCallback(
    (valToBeAdded: string) => {
      onSelectedOptionChange((currentOptions) => [
        ...currentOptions,
        valToBeAdded,
      ]);
    },
    [onSelectedOptionChange],
  );

  const removeValueFromSelectedOptions = useCallback(
    (valToBeRemoved: string) => {
      onSelectedOptionChange((currentOptions) => {
        const copyOfSelectedOptions = [...currentOptions];
        copyOfSelectedOptions.splice(
          copyOfSelectedOptions.indexOf(valToBeRemoved),
          1,
        );
        return copyOfSelectedOptions;
      });
    },
    [onSelectedOptionChange],
  );

  const onFlowCheckboxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!selectedOptions.includes(event.target.name)) {
        addValueToSelectedOptions(event.target.name);
      } else {
        removeValueFromSelectedOptions(event.target.name);
      }
    },
    [
      addValueToSelectedOptions,
      removeValueFromSelectedOptions,
      selectedOptions,
    ],
  );

  const onValueChange = (val: AutocompleteDropdownItem<string> | null) => {
    const filteredOptions = options.filter((item) => item.id === val?.id);

    if (val) {
      filteredOptions[0].value = true;
      setOptionsToShow([...optionsToShow, ...filteredOptions]);
      addValueToSelectedOptions(val.id);

      const filteredAutoCompleteOptions = autoCompleteOptions.filter(
        (item) => item.id !== val.id,
      );
      setAutoCompleteOptions([...filteredAutoCompleteOptions]);
      toggleAutoComplete();
    }
    setAutoCompleteValue(null);
  };

  return (
    <FilterCollapse
      headingText={headingText}
      isFiltered={selectedOptions.length > 0}
      isCollapseOpen
      onCollapseClick={onCollapseClick}
      className={className}
    >
      <CollapseBodyWrapper>
        {optionsToShow.map((option) => {
          return (
            <FlowCheckbox
              name={option.id}
              label={option.label}
              info={option.info}
              img={option.img}
              icon={option.icon}
              value={selectedOptions.includes(option.id)}
              onChange={onFlowCheckboxChange}
              showAvatar={option.showAvatar}
              userId={option.id}
              avatarName={option.label}
              key={option.id}
              memberState={option.memberState}
              isAnonymous={option?.isAnonymous}
              disabled={option?.disabled || false}
            />
          );
        })}
        {!showAutocomplete && autoCompleteOptions.length > 0 && (
          <StyledButton
            size="small"
            status="default"
            variant="text"
            onClick={toggleAutoComplete}
            disabled={disableFilterBy}
          >
            {filterByText}
          </StyledButton>
        )}
        {showAutocomplete && (
          <SingleSelectAutoComplete
            options={autoCompleteOptions}
            placeholder={autoCompleteText}
            value={autoCompleteValue}
            onChange={onValueChange}
            textboxSize="small"
            autoFocus
            removeElevation
            onBlur={toggleAutoComplete}
          />
        )}
      </CollapseBodyWrapper>
    </FilterCollapse>
  );
};

const CheckboxFilter = (props: CheckboxFilterProps) => {
  switch (props.status) {
    case ComponentStatus.LOADED:
      return <LoadedComponent {...props} />;
    case ComponentStatus.EMPTY: {
      const { headingText, onCollapseClick, className } = props;
      return (
        <FilterCollapse
          headingText={headingText}
          className={className}
          onCollapseClick={onCollapseClick}
          isCollapseOpen
        >
          <Body variant="body2" color="gray6">
            {NOTHING_TO_FILTER_BY}
          </Body>
        </FilterCollapse>
      );
    }
    default: {
      const { loaderCount = 4, headingText, className } = props;
      const items = Array.from(Array(loaderCount)).map((e, i) => i + 1);
      return (
        <div className={className}>
          <StyledBody variant="body1" color="gray7">
            {headingText}
          </StyledBody>
          {items.map((i) => (
            <StyledCheckboxLoader key={i} />
          ))}
        </div>
      );
    }
  }
};

export default CheckboxFilter;
