import { MenuItemProps } from '../../molecules/Dropdown_V2/interfaces';

export const menuItems: MenuItemProps[] = [
  {
    id: 'occurrence',
    items: [
      {
        value: 'minutes',
        id: 'minutes',
      },
      {
        value: 'hours',
        id: 'hours',
      },
      {
        value: 'days',
        id: 'days',
      },
      {
        value: 'weeks',
        id: 'weeks',
      },
    ],
  },
];
