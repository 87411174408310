import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { parse } from 'qs';
import { AxiosError } from 'axios';

import { useProfileInfoFetchQuery } from '../../../../queries/Profile';
import useTriggerFlowOption from '../../../../hooks/useFlowFeedOptions/useTriggerFlowOption';

import {
  FeedProps,
  MemberInteractionType,
  PostSortOptions,
  PostSortValues,
} from '../../../../interfaces/Feed';

import { FlowFeedResponse } from '../../../../queries/Flows/Feed/interfaces';

import {
  useGetFlowFeedsQuery,
  useFetchFlowDetailsQuery,
  useUpdateFlowReactionMutation,
} from '../../../../queries/Flows/Feed';

import useModalsStore from '../../../../stores/modalsStore';
import { setParticipationFlowSelector } from '../../../../stores/modalsStore/selectors';
import useLayoutStore from '../../../../stores/layoutStore';
import {
  getFlowFeedUrl,
  mapFlowFeedResponseToFeedProps,
} from '../../../../Utils/home/feeds';
import { getErrorStatusCode } from '../../../../Utils/message';
import { useFlowFeedReactions } from '../../../../hooks/useFeedReactions';
import useToggle from '../../../../hooks/useToggle';
import useGetParsedFilterOptions from '../../../../hooks/useGetParsedFilterOptions';
import {
  FEED_ANALYTICS_EVENTS,
  SHARE_SHEET_EVENTS,
} from '../../../../Utils/analytics/constants';
import useCommonFeedSocket from '../../../../hooks/useCommonFeedSocket';
import { WebSocketFeedTypes } from '../../../../hooks/useCommonFeedSocket/types';
import {
  canShowParticipationList,
  getAnswerFlowRoute,
} from '../../../../Utils/flows';
import { BUILDER_FLOW_CREATED_MODAL } from '../../../../languages/en/flows/builder';
import { V2_HOME } from '../../../../constants/routes';
import useArchiveFlowOption from '../../../../hooks/useFlowFeedOptions/useArchiveFlowOption';
import { LayoutStoreState } from '../../../../interfaces/Layout';
import { trackFeedEvent as trackFeedEventMetric } from '../../../../Utils/analytics';
import { FeedEventNameType } from '../../../../Utils/analytics/interfaces';
import useFlowBuilderStore from '../../../../stores/flowBuilderStore';
import { setShareSheetModalOpenSelector } from '../../../../stores/flowBuilderStore/selectors';
import { MemberState } from '../../../../interfaces/user';
import { trackShareSheetActionEvent } from '../../../../Utils/analytics/shareSheet';
import { useFeatureSplit } from '../../../../hooks/useFeatureSplit';

import {
  SplitNames,
  TreatmentTypes,
} from '../../../../hooks/useSplitSdkConfig/constants';

const layoutSelector = (state: LayoutStoreState) =>
  state.setMemberAndToggleRightAsideOpen;

const useFlowsFeedController = () => {
  const { flowId } = useParams<{ flowId: string }>();
  const { treatment: pageParticipationTreatment } = useFeatureSplit(
    SplitNames.NEW_PAGE_PARTICIPATION_VIEW,
  );

  const [disableFlowDetailsRequest, setDisableFlowDetailsRequest] =
    useState(false);

  const { data: flowDetails } = useFetchFlowDetailsQuery(
    flowId,
    'builder',
    !disableFlowDetailsRequest,
  );
  const { search } = useLocation();
  const parsedParams = parse(search, {
    ignoreQueryPrefix: true,
  });

  const canFlowCreatedModalOpen = search.includes(
    'flow-created-confirmation=true',
  );

  const setShareSheetModalOpen = useFlowBuilderStore(
    setShareSheetModalOpenSelector,
  );

  const openParticipationFlowModalOnLoad =
    parsedParams && parsedParams?.openParticipationFlowModalOnLoad === 'true';
  const occurrenceIdFromParam =
    (parsedParams && (parsedParams.occurrenceId as string)) || undefined;
  const isFromQuickSetup =
    (parsedParams && (parsedParams.quickSetup as string)) || undefined;
  const setParticipationFlow = useModalsStore(setParticipationFlowSelector);
  const [flowFeedsSortValue, setFlowFeedsSortValue] = useState<PostSortOptions>(
    PostSortOptions.DESC,
  );
  const [isFlowCreatedModalOpen, setFlowCreatedModalOpen] = useState<boolean>(
    canFlowCreatedModalOpen,
  );

  const {
    isError: isFlowDetailError,
    isLoading: isFlowDetailLoading,
    data,
    error: flowDetailError,
  } = useFetchFlowDetailsQuery(flowId, '', !useFetchFlowDetailsQuery);

  const trackFeedEvent = useCallback(
    (action: FeedEventNameType) => {
      trackFeedEventMetric(action, {
        feedSort: PostSortValues[flowFeedsSortValue],
        flowName: data?.data.name,
      });
    },
    [data, flowFeedsSortValue],
  );

  const {
    models: { toggleValue: showLoadMoreButton },
    operations: {
      setToggleToTrue: setShowLoadMoreButtonToTrue,
      setToggleToFalse: setShowLoadMoreButtonToFalse,
    },
  } = useToggle();

  const flowVisibilityCriteria = data?.data.visibility.criteria;

  let flowDetailErrorCode = null;
  if (flowDetailError) {
    flowDetailErrorCode = getErrorStatusCode(flowDetailError as AxiosError);
  }

  const flowDetailsData = data?.data;

  const { filter } = useGetParsedFilterOptions();

  const handleFeedSortClick = useCallback(
    (val: string | number) => {
      setFlowFeedsSortValue(val as PostSortOptions);
      trackFeedEvent(FEED_ANALYTICS_EVENTS.FEED_SORTED);
    },
    [trackFeedEvent],
  );

  const {
    isLoading: isFlowFeedLoading,
    isError: isFlowFeedError,
    data: flowFeedsData,
    hasNextPage: hasMoreFeeds,
    fetchNextPage: fetchNextFeeds,
    isFetchingNextPage: isFetchingNextFeeds,
    refetch: refetchFlowFeed,
  } = useGetFlowFeedsQuery({
    flowId,
    flowFeedsSort: flowFeedsSortValue,
    filter,
  });

  const handleFetchNextPage = useCallback(() => {
    fetchNextFeeds();
    trackFeedEvent(FEED_ANALYTICS_EVENTS.FEED_SCROLLED);
  }, [fetchNextFeeds, trackFeedEvent]);

  const { mutate } = useUpdateFlowReactionMutation({
    flowId,
    flowFeedsSort: flowFeedsSortValue,
    filter,
  });
  const {
    data: profileData,
    isError: isProfileDataError,
    isLoading: isProfileInfoLoading,
  } = useProfileInfoFetchQuery();
  const history = useHistory();
  const setMemberAndToggleRightAsideOpen = useLayoutStore(layoutSelector);
  const onMemberClick = useCallback(
    (person: MemberInteractionType) => {
      setMemberAndToggleRightAsideOpen({
        memberId: person.memberID,
        firstName: person.firstName,
      });
    },
    [setMemberAndToggleRightAsideOpen],
  );

  const shouldFetchParticipantList = canShowParticipationList(flowDetailsData);

  const {
    models: {
      isTriggerFlowModalOpen,
      dueDateValue,
      occurrenceValue,
      participants,
      participantsCount,
      isTriggerFlowLoading,
      hasMoreMembers,
    },
    operations: {
      setTriggerFlowModalClose,
      handleDueDateValueChange,
      setOccurrenceValue,
      setIsTriggerFlowModalOpen,
      fetchMoreMembers,
      handleTriggerFlowButtonClick,
    },
  } = useTriggerFlowOption(flowId, shouldFetchParticipantList);
  const pendingMembersParticipantCount = useMemo(() => {
    const pendingMembers = participants.filter(
      (member) => member.memberState === MemberState.PENDING,
    );
    return pendingMembers.length;
  }, [participants]);

  const {
    models: { isArchiveModalOpen, isArchiveFlowLoading },
    operations: {
      setArchiveModalOpen,
      setArchiveModalClose,
      handleOnArchiveClick,
    },
  } = useArchiveFlowOption(flowId);

  const openArchiveModal = () => {
    setArchiveModalOpen();
  };

  const flowFeeds: FlowFeedResponse[] = useMemo(() => {
    if (!flowFeedsData) {
      return [];
    }

    return flowFeedsData.pages.reduce<FlowFeedResponse[]>(
      (acc, page) => [...acc, ...page.data],
      [],
    );
  }, [flowFeedsData]);

  const { onReactionSet, onReactionUnSet } = useFlowFeedReactions({
    flowFeedData: flowFeeds,
    profileData,
    flowId,
    mutateFlowPostReaction: mutate,
  });

  const isLoading =
    isFlowDetailLoading || isFlowFeedLoading || isProfileInfoLoading;
  const isError = isFlowDetailError || isFlowFeedError || isProfileDataError;

  useEffect(() => {
    if (flowDetailError) {
      setDisableFlowDetailsRequest(true);
    }
  }, [flowDetailError]);

  const handleActionBarClick = useCallback(() => {
    const occurrenceId = data?.data.occurrence.activeOccurrence?.occurrenceId;
    if (pageParticipationTreatment === TreatmentTypes.ON) {
      history.push(getAnswerFlowRoute(flowId, occurrenceId, location.pathname));
    } else {
      setParticipationFlow({
        participationFlowId: flowId,
        occurrenceId: occurrenceId,
      });
    }
  }, [
    flowId,
    history,
    setParticipationFlow,
    pageParticipationTreatment,
    data?.data.occurrence.activeOccurrence?.occurrenceId,
  ]);

  const handleOnMemberClick = useCallback(
    (member: MemberInteractionType) => {
      setMemberAndToggleRightAsideOpen({
        memberId: member.memberID,
        firstName: member.firstName,
      });
    },
    [setMemberAndToggleRightAsideOpen],
  );

  const openRunNowModal = useCallback(() => {
    setIsTriggerFlowModalOpen();
  }, [setIsTriggerFlowModalOpen]);

  const onRunNowButtonClick = () => {
    handleTriggerFlowButtonClick(flowId);
  };
  useEffect(() => {
    if (openParticipationFlowModalOnLoad) {
      setParticipationFlow({
        occurrenceId: occurrenceIdFromParam,
        participationFlowId: flowId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleOnFlowClick = useCallback(
    (flow: { flowId: string }) => {
      history.push(getFlowFeedUrl(flow.flowId));
    },
    [history],
  );

  const feedProps: FeedProps[] = useMemo(() => {
    if (!flowFeeds || !profileData) {
      return [];
    }

    return mapFlowFeedResponseToFeedProps(
      flowFeeds,
      profileData,
      handleOnMemberClick,
      handleOnFlowClick,
    );
  }, [flowFeeds, profileData, handleOnMemberClick, handleOnFlowClick]);

  useCommonFeedSocket({
    type: WebSocketFeedTypes.FLOW,
    flowId,
    setFlowFeedShowLoadMoreButtonToTrue: setShowLoadMoreButtonToTrue,
    onFlowFeedFlowResponseDelete: refetchFlowFeed,
    feedProps,
  });

  const resetCreatedConfirmationOpen = useCallback(() => {
    history.push({ search: '' });
  }, [history]);

  const onFlowCreateModalClose = useCallback(() => {
    setFlowCreatedModalOpen(false);
    resetCreatedConfirmationOpen();
  }, [resetCreatedConfirmationOpen]);

  const onRunFlowModalClick = useCallback(() => {
    openRunNowModal();
    onFlowCreateModalClose();
  }, [onFlowCreateModalClose, openRunNowModal]);

  const onViewMainFeedClick = useCallback(() => {
    history.push(V2_HOME);
  }, [history]);

  const handleShareSheetModalOpen = useCallback(() => {
    trackShareSheetActionEvent({
      action: SHARE_SHEET_EVENTS.SHARE_SHEET_STARTED,
      shareFlowStartSource: 'flow completion modal',
    });
    onFlowCreateModalClose();
    setShareSheetModalOpen(true);
  }, [setShareSheetModalOpen, onFlowCreateModalClose]);

  const getConfirmationModalButtonProps = useMemo(() => {
    if (isFromQuickSetup) {
      return {
        primaryButton: {
          text: BUILDER_FLOW_CREATED_MODAL.QUICK_SETUP.PRIMARY_BUTTON_TEXT,
          onClick: onFlowCreateModalClose,
        },
        secondaryButton: {
          text: BUILDER_FLOW_CREATED_MODAL.QUICK_SETUP.SECONDARY_BUTTON_TEXT,
          onClick: onViewMainFeedClick,
        },
      };
    }

    if (flowDetailsData) {
      if (flowDetailsData.kind === 'SCHEDULED') {
        return {
          primaryButton: {
            text: BUILDER_FLOW_CREATED_MODAL.SHARE_THIS_FLOW,
            onClick: handleShareSheetModalOpen,
            icon: 'add-person-transparent',
          },
          secondaryButton: {
            text: BUILDER_FLOW_CREATED_MODAL.SCHEDULED_FLOW
              .SECONDARY_BUTTON_TEXT,
            onClick: onFlowCreateModalClose,
          },
        };
      } else if (flowDetailsData.kind === 'NO_TRIGGER') {
        return {
          primaryButton: {
            text: BUILDER_FLOW_CREATED_MODAL.SHARE_THIS_FLOW,
            onClick: handleShareSheetModalOpen,
            icon: 'add-person-transparent',
          },
          secondaryButton: {
            text: BUILDER_FLOW_CREATED_MODAL.NO_TRIGGER_FLOW
              .SECONDARY_BUTTON_TEXT,
            onClick: onViewMainFeedClick,
          },
        };
      }
    }
    return {
      primaryButton: {
        text: BUILDER_FLOW_CREATED_MODAL.SHARE_THIS_FLOW,
        icon: 'add-person-transparent',
        onClick: handleShareSheetModalOpen,
      },
      secondaryButton: {
        text: BUILDER_FLOW_CREATED_MODAL.ON_DEMAND_FLOW.PRIMARY_BUTTON_TEXT,
        icon: 'play',
        onClick: onRunFlowModalClick,
      },
    };
  }, [
    flowDetailsData,
    isFromQuickSetup,
    onRunFlowModalClick,
    onViewMainFeedClick,
    onFlowCreateModalClose,
    handleShareSheetModalOpen,
  ]);

  return {
    isLoading,
    isError,
    profileData,
    isFromQuickSetup,
    flowFeedsSortValue,
    flowDetails,
    flowDetailError,
    handleOnMemberClick,
    handleFeedSortClick,
    handleActionBarClick,
    isTriggerFlowModalOpen,
    dueDateValue,
    occurrenceValue,
    participants,
    flowVisibilityCriteria,
    participantsCount,
    hasMoreMembers,
    isTriggerFlowLoading,
    handleOnFlowClick,
    flowDetailsData,
    showLoadMoreButton,
    flowDetailErrorCode,
    feedData: {
      data: feedProps,
      handleFetchNextPage,
      hasNextPage: hasMoreFeeds || false,
      isFetchingNextPage: isFetchingNextFeeds,
    },
    feedActions: {
      onReactionSet,
      onReactionUnSet,
    },
    openRunNowModal,
    setTriggerFlowModalClose,
    handleDueDateValueChange,
    setOccurrenceValue,
    fetchMoreMembers,
    onRunNowButtonClick,
    onMemberClick,
    refetchFlowFeed,
    setShowLoadMoreButtonToFalse,
    isFlowCreatedModalOpen,
    onFlowCreateModalClose,
    getConfirmationModalButtonProps,
    openArchiveModal,
    isArchiveModalOpen,
    isArchiveFlowLoading,
    setArchiveModalClose,
    handleOnArchiveClick,
    pendingMembersParticipantCount,
  };
};

export default useFlowsFeedController;
