import React from 'react';

import AssemblyMembers from '../../../atomic/organism/AssemblyMembers';
import MembersList from '../../../atomic/molecules/MembersList';
import MembersListError from '../../../atomic/organism/AssemblyMembers/Error';
import MembersListLoader from '../../../atomic/organism/AssemblyMembers/Loader';
import SearchedMembersList from '../../../atomic/molecules/SearchedMembersList';
import useAssemblyMembersController from './useAssemblyMembersController';

const AssemblyMembersController = () => {
  const {
    models: {
      assemblyName,
      hasMoreMembersToFetch,
      isError,
      isListExpanded,
      isLoading,
      isMembersSearchLoading,
      infiniteMembers,
      membersSearchValue,
      onMembersSearchChange,
      toggleRightAsideOpen,
      searchedMembers,
      toggleIsListExpanded,
      totalNumberOfAssemblyMembers,
    },
    operations: { fetchMoreInfiniteMembers, handleEntryClick },
  } = useAssemblyMembersController();

  return (
    <AssemblyMembers
      assemblyName={assemblyName || ''}
      isError={isError}
      isListExpanded={isListExpanded}
      membersSearchValue={membersSearchValue}
      onMembersSearchChange={onMembersSearchChange}
      totalNumberOfAssemblyMembers={totalNumberOfAssemblyMembers}
      toggleRightAsideOpen={toggleRightAsideOpen}
    >
      {isLoading ? <MembersListLoader /> : null}
      {isError ? <MembersListError /> : null}

      {!isLoading && !isError && membersSearchValue.length > 0 && (
        <SearchedMembersList
          isLoading={isMembersSearchLoading}
          members={searchedMembers}
          handleEntryClick={handleEntryClick}
        />
      )}
      {membersSearchValue.length === 0 &&
        infiniteMembers.length > 0 &&
        totalNumberOfAssemblyMembers && (
          <MembersList
            hasMoreMembersToFetch={!!hasMoreMembersToFetch}
            isExpanded={isListExpanded}
            fetchMoreMembers={fetchMoreInfiniteMembers}
            members={infiniteMembers}
            onExpansionButtonClick={toggleIsListExpanded}
            totalNumberOfMembers={totalNumberOfAssemblyMembers}
            handleEntryClick={handleEntryClick}
          />
        )}
    </AssemblyMembers>
  );
};

const MemoizedAssemblyMembersController = React.memo(AssemblyMembersController);
MemoizedAssemblyMembersController.displayName = 'AssemblyMembersController';
export default MemoizedAssemblyMembersController;
