import { MenuItemProps } from '../../../atomic/molecules/Dropdown_V2/interfaces';
import { SortByQueryParams } from '../../../Utils/data/rewards';

export const sortbyItems: MenuItemProps[] = [
  {
    id: 'sort-by',
    items: [
      {
        value: 'Amount of points (low to high)',
        id: SortByQueryParams.POINTS_TO_REDEEM_LOW_TO_HIGH,
        isSelected: false,
      },
      {
        value: 'Amount of points (high to low)',
        id: SortByQueryParams.POINTS_TO_REDEEM_HIGH_TO_LOW,
        isSelected: false,
      },
      {
        value: 'Alphabetically',
        id: SortByQueryParams.ALPHABETICALLY,
        isSelected: false,
      },
    ],
  },
];

export const defaultItemsPerPageValues = [8, 16, 32, 64];
