import styled from 'styled-components';

import SVGIcon from '../../atoms/SVGIcon';
import Clickable from '../../atoms/Clickable';
import ThemeV2 from '../../../componentsV2/theme';
import Body from '../../atoms/Body';
import { Flex } from '../../../Utils/styles/display';
import { ellipsisStyle } from '../../../Utils/styles/css';

export const StyledIcon = styled(SVGIcon)`
  margin: auto 6px auto 16px;
`;

export const StyledInviteIcon = styled(SVGIcon)`
  margin-right: 4px;
  svg {
    fill: ${ThemeV2.palette.geekBlue6};
  }
`;

export const RightWrapper = styled(Flex)`
  margin-right: 8px;
`;

export const Wrapper = styled.li`
  display: grid;
  grid-template-columns: auto auto;
  background: ${ThemeV2.palette.gray3};
  height: 32px;
  border-radius: 4px;
  max-height: 32px;
  border-radius: 0px;

  ${RightWrapper} {
    & svg {
      & path {
        fill: transparent;
      }
    }
  }

  &:hover {
    cursor: default;
    background: ${ThemeV2.palette.gray4};
    ${RightWrapper} {
      & svg {
        & path {
          fill: ${ThemeV2.palette.gray8};
        }
      }
    }
  }
`;

export const StyledClickableClose = styled(Clickable)`
  display: flex;
  margin-left: auto;
`;

export const CriteriaLabel = styled(Body)`
  white-space: nowrap;
`;

export const CriteriaValue = styled(Body)`
  overflow: hidden;
  display: flex;
  align-items: center;
`;

export const CriteriaValueText = styled(Body)`
  ${ellipsisStyle};
  color: ${ThemeV2.palette.geekBlue6};
`;

export const StyledFlex = styled(Flex)`
  overflow: hidden;
`;
