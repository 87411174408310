import React from 'react';

import Body from '../../atoms/Body';
import ToggleSwitch from '../../atoms/ToggleSwitch';
import ToolTip from '../../molecules/ToolTipV2';

import { StyledShortcutConfigurationBody, StyledLink } from './styles';
import { CONFIGURE_SETTINGS } from '../../../languages/en/flows/builder';
import ThemeV2 from '../../../componentsV2/theme';

import { ShortcutConfigurationProps } from './types';
import { SwitchTextTypes } from '../../atoms/ToggleSwitch/types';

const ShortcutConfiguration = (props: ShortcutConfigurationProps) => {
  const { isOccurrenceOn, onOccurrenceToggle, showTooltip = false } = props;

  return (
    <>
      <Body variant="body1Medium" color={ThemeV2.palette.gray9}>
        {CONFIGURE_SETTINGS.ALLOW_ANYTIME_CONFIGURATION_HEADING_TEXT}
      </Body>
      <StyledShortcutConfigurationBody variant="body3">
        {CONFIGURE_SETTINGS.ALLOW_ANYTIME_CONFIGURATION_BODY_TEXT}{' '}
        <StyledLink
          to={CONFIGURE_SETTINGS.SHORTCUT_CONFIGURATION_LINK}
          isAnchor
          openInNewTab
        >
          {CONFIGURE_SETTINGS.SHORTCUT_CONFIGURATION_LINK_TEXT}
        </StyledLink>
      </StyledShortcutConfigurationBody>
      {!showTooltip && (
        <ToggleSwitch
          id="occurrence"
          isOn={isOccurrenceOn}
          onChange={onOccurrenceToggle}
        />
      )}
      {showTooltip && (
        <ToolTip
          toolTipComponent={
            <ToggleSwitch
              id="occurrence"
              isOn={isOccurrenceOn}
              onChange={onOccurrenceToggle}
              textType={SwitchTextTypes.ON_OFF}
              disabled
            />
          }
          toolTipSize="196px"
        >
          {CONFIGURE_SETTINGS.SHORTCUT_CONFIGURATION_NO_TRIGGER_TOOLTIP_TEXT}
        </ToolTip>
      )}
    </>
  );
};

export default ShortcutConfiguration;
