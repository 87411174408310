import React, { useMemo } from 'react';

import { Link } from 'react-router-dom';

import { RewardsPopOverProps } from './interfaces';
import { RewardsPopoverWrapper, RewardsHeading } from './styles';
import AssemblyCurrencyIcon from '../../atoms/AssemblyCurrencyIcon';
import Button from '../../atoms/Button';
import Divider from '../../atoms/Divider';
import { Flex } from '../../../Utils/styles/display';
import {
  ButtonWrapper,
  ProgressWrapper,
  DividerWrapper,
  ProgressBody,
  TooltipWrapper,
  TooltipContent,
  TooltipPrefix,
  TooltipSuffix,
} from './styles';

import {
  ON,
  REFRESHES,
  TO,
  LEFT,
  GIVE,
  IN,
} from '../../../languages/en/singleWords';
import { HomeDrawerData } from '../../../constants/secondaryNav';
import ThemeV2 from '../../../componentsV2/theme';
import parseISO from 'date-fns/parseISO';
import sub from 'date-fns/sub';
import format from 'date-fns/format';
import styled from 'styled-components';
import { LinearProgress } from '@material-ui/core';

const GiveBorderLinearProgress = styled(LinearProgress)<{
  $moreToGive: boolean;
}>`
  top: 4px;
  left: 4px;
  height: 8px;
  width: 320px;
  border-radius: 20px;
  background-color: ${({ $moreToGive }) =>
    $moreToGive ? ThemeV2.palette.geekBlue2 : ThemeV2.palette.dustRed2};
  .MuiLinearProgress-colorPrimary {
    background-color: ${({ $moreToGive }) =>
      $moreToGive ? ThemeV2.palette.geekBlue2 : ThemeV2.palette.dustRed2};
  }
  .MuiLinearProgress-bar {
    border-radius: 17px;
    background-color: ${({ $moreToGive }) =>
      $moreToGive ? ThemeV2.palette.geekBlue6 : ThemeV2.palette.dustRed6};
  }
`;

const RewardsPopover = (props: RewardsPopOverProps) => {
  const {
    assemblyCurrency,
    totMonthlyAllowance,
    remainingMonthlyAllowance,
    refreshInfoDate,
    trophyBalance,
    hideAllowanceDetails,
    hideRedeemButton,
    hideRewardsInfo,
    redeemButtonClicked,
    closePopover,
    rewardType = '',
  } = props;

  const currencyName = assemblyCurrency?.name || '';
  const currencyNamePlural = assemblyCurrency?.pluralName || '';
  const moreToGive = remainingMonthlyAllowance !== 0;

  const refreshDate = format(parseISO(refreshInfoDate), 'MMMM d');
  const currentMonth = format(
    sub(parseISO(refreshInfoDate), { months: 1 }),
    'MMMM',
  );

  const refreshInfoText = useMemo(() => {
    return (
      <span>
        {`${REFRESHES} ${TO} `}
        <AssemblyCurrencyIcon
          assemblyCurrency={assemblyCurrency}
          currencyName={currencyName}
          id={currencyName}
          size="14px"
        />
        {`${totMonthlyAllowance.toString()} ${ON} `}
        {refreshDate}
      </span>
    );
  }, [assemblyCurrency, currencyName, refreshDate, totMonthlyAllowance]);

  const remainingInfoText = useMemo(() => {
    return (
      <span>
        <AssemblyCurrencyIcon
          assemblyCurrency={assemblyCurrency}
          currencyName={currencyName}
          id={currencyName}
          size="14px"
        />
        {`${remainingMonthlyAllowance.toString()} ${LEFT} ${TO} ${GIVE} ${IN.toLowerCase()} `}
        {currentMonth}
      </span>
    );
  }, [assemblyCurrency, currencyName, currentMonth, remainingMonthlyAllowance]);

  const thisMonthText = useMemo(() => {
    return (
      <span>
        {`Given ${IN.toLowerCase()} `}
        {currentMonth}
      </span>
    );
  }, [currentMonth]);

  const monthlyBalanceTooltip = useMemo(() => {
    let replacedString = HomeDrawerData.MONTHLY_ALLOWANCE.NEW_TOOLTIP.replace(
      ':pointsName',
      currencyNamePlural,
    );

    replacedString = replacedString.replace(
      ':monthlyAllowance',
      totMonthlyAllowance.toString(),
    );

    return replacedString;
  }, [currencyNamePlural, totMonthlyAllowance]);

  return (
    <RewardsPopoverWrapper>
      {!hideAllowanceDetails && (
        <>
          <Flex padding="0px 0px 8px 0px" justifyContent="space-between">
            <Flex justifyContent="space-between" width="100%">
              <RewardsHeading variant="h7" weight="medium">
                {thisMonthText}
              </RewardsHeading>
              <Flex>
                <AssemblyCurrencyIcon
                  assemblyCurrency={assemblyCurrency}
                  currencyName={currencyName}
                  id={currencyName}
                  size="16px"
                />
                <TooltipPrefix>
                  {totMonthlyAllowance - remainingMonthlyAllowance}
                </TooltipPrefix>
                <TooltipSuffix>/{totMonthlyAllowance}</TooltipSuffix>
                <TooltipWrapper position="left">
                  <TooltipContent>{monthlyBalanceTooltip}</TooltipContent>
                </TooltipWrapper>
              </Flex>
            </Flex>
          </Flex>
          <Flex justifyContent="space-between">
            <ProgressWrapper
              highlighted={!moreToGive}
              highlightColor={ThemeV2.palette.dustRed2}
            >
              <GiveBorderLinearProgress
                variant="determinate"
                value={
                  remainingMonthlyAllowance !== totMonthlyAllowance
                    ? ((totMonthlyAllowance - remainingMonthlyAllowance) /
                        totMonthlyAllowance) *
                      100
                    : 1
                }
                $moreToGive={moreToGive}
              />
            </ProgressWrapper>
          </Flex>
          <ProgressBody variant="body3">
            {remainingMonthlyAllowance !== 0
              ? remainingInfoText
              : refreshInfoText}
          </ProgressBody>
        </>
      )}

      {!hideAllowanceDetails && !hideRewardsInfo && (
        <DividerWrapper>
          <Divider color={ThemeV2.palette.gray4} isFullWidth />
        </DividerWrapper>
      )}

      {!hideRewardsInfo && (
        <>
          <Flex padding={'0px 0px 8px 0px'} justifyContent="space-between">
            <Flex justifyContent="space-between" width="100%">
              <RewardsHeading variant="h7" weight="medium">
                {HomeDrawerData.REDEEM_HEADER}
              </RewardsHeading>
              <Flex>
                <AssemblyCurrencyIcon
                  assemblyCurrency={assemblyCurrency}
                  currencyName={currencyName}
                  id={currencyName}
                  size="16px"
                />
                <TooltipPrefix>{trophyBalance}</TooltipPrefix>
                <TooltipWrapper position="left">
                  <TooltipContent>
                    {HomeDrawerData.REWARDS_MINIMUM_TOOLTIP}
                  </TooltipContent>
                </TooltipWrapper>
              </Flex>
            </Flex>
          </Flex>
          {!hideRedeemButton && (
            <ButtonWrapper>
              <Link to={rewardType}>
                <Button
                  color="primary"
                  variant={'contained'}
                  size="small"
                  onClick={() => {
                    if (redeemButtonClicked) {
                      redeemButtonClicked();
                    }
                    closePopover();
                  }}
                  isFullWidth
                >
                  {HomeDrawerData.DISABLED_REDEEM_TEXT}
                </Button>
              </Link>
            </ButtonWrapper>
          )}
        </>
      )}
    </RewardsPopoverWrapper>
  );
};

export default RewardsPopover;
