import styled from 'styled-components';

import ThemeV2 from '../../../componentsV2/theme';
import { SwitchTextTypes } from './types';

export const StyledLabel = styled.label`
  position: relative;
  display: inline-block;
  margin-bottom: 0px;
  width: 49px;
  height: 24px;
`;

export const Slider = styled.span<{
  disabled: boolean;
  falseText: string;
  textType: SwitchTextTypes;
}>`
  position: absolute;
  cursor: ${({ disabled }) => (disabled ? 'text' : 'pointer')};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 12px;
  transition: 'transform 0.4s';
  background-color: ${({ disabled }) =>
    disabled ? ThemeV2.palette.gray4 : ThemeV2.palette.gray6};

  &:before {
    position: absolute;
    content: '';
    left: 4px;
    bottom: 4px;
    height: 16px;
    width: 16px;
    border-radius: 8px;
    background-color: white;
    transition: transform 0.4s;
  }

  &:after {
    position: absolute;
    top: 4px;
    right: ${({ textType }) =>
      textType === SwitchTextTypes.ON_OFF ? '9px' : '5px'};
    content: ${({ falseText }) => `'${falseText}'`};
    color: ${({ disabled }) =>
      disabled ? ThemeV2.palette.gray3 : ThemeV2.palette.gray1};
    font-family: ${ThemeV2.typography.adminFontFamily};
    font-size: 12px;
  }
`;

export const StyledInput = styled.input<{
  trueText: string;
  disabled: boolean;
}>`
  visibility: hidden;

  &:checked + ${Slider} {
    background-color: ${({ disabled }) =>
      disabled ? ThemeV2.palette.gray4 : ThemeV2.palette.geekBlue6};
  }

  &:checked + ${Slider}:before {
    transform: translateX(26px);
  }

  &:checked + ${Slider}:after {
    content: ${({ trueText }) => `'${trueText}'`};
    transform: translateX(-100%);
  }
`;
