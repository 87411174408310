import { useMutation, useQueryClient } from 'react-query';
import {
  END_OCCURRENCE,
  GET_ACTIVE_FLOWS,
  GET_FLOW_DETAILS,
} from '../../../../constants/endpoints';
import { makeAPICall } from '../../../utils';

export const useEndOccurrenceQuery = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (param: { flowId: string }) =>
      makeAPICall(END_OCCURRENCE, undefined, undefined, {
        flowId: param.flowId,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_ACTIVE_FLOWS);
        queryClient.invalidateQueries(GET_FLOW_DETAILS);
      },
    },
  );
};
