import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import Body from '../../../atoms/Body';
import BasicModalTemplate from '../../../templates/BasicModalTemplate';
import { REQUEST_MODAL_HUBSPOT } from '../../../../languages/en/flows/requestFlowModal';

export const StyledBasicModal = styled(BasicModalTemplate)`
  max-width: 518px;
  width: 100%;
  height: 760px;
  & .meetings-iframe-container {
    padding: 0px 8px;
  }
`;

export const StyledBody = styled(Body)`
  padding-bottom: 24px;
`;

export type RequestTemplateModalProps = {
  isOpen: boolean;
  className?: string;
  onModalClose: () => void;
  templateName?: string;
};

const RequestTemplateModal = (props: RequestTemplateModalProps) => {
  const { className, isOpen, onModalClose, templateName } = props;
  return (
    <StyledBasicModal
      className={className}
      isOpen={isOpen}
      heading={
        templateName
          ? REQUEST_MODAL_HUBSPOT.HEADING_FOR_TEMPLATE
          : REQUEST_MODAL_HUBSPOT.HEADING_FOR_NEW_TEMPLATE
      }
      onModalClose={onModalClose}
      disableContentScroll
    >
      {templateName ? (
        <StyledBody variant="body2">
          {REQUEST_MODAL_HUBSPOT.BOOK_DEMO_FOR_TEMPLATE_START}{' '}
          <Body variant="body2Bold" inline>
            {templateName}
          </Body>{' '}
          {REQUEST_MODAL_HUBSPOT.BOOK_DEMO_FOR_TEMPLATE_END}
        </StyledBody>
      ) : (
        <StyledBody variant="body2">
          REQUEST_MODAL_HUBSPOT.BOOK_DEMO_NEW_TEMPLATE
        </StyledBody>
      )}
      <Helmet>
        <script
          type="text/javascript"
          src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"
        />
      </Helmet>
      <div
        className="meetings-iframe-container"
        data-src="https://meetings.hubspot.com/joinassembly/request-a-new-flow?embed=true"
      />
    </StyledBasicModal>
  );
};

export default RequestTemplateModal;
