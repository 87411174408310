import React from 'react';
import useNotificationsButtonController from './useNotificationsButtonController';
// HandleNotificationItemClickParamTypes,
// import { LEFT_DRAWER_ANALYTICS_EVENTS } from '../../../Utils/analytics/constants';
import NotificationsButton from '../../../atomic/organism/NotificationsButton';
import useNotificationSocket from '../../../hooks/useNotificationFeedSocket';
import useFlowActivityUpdatesSocket from '../../../hooks/useFlowActivityUpdatesSocket';
// import { trackLeftDrawerEvent } from '../../../Utils/analytics';

const NotificationsController = () => {
  const {
    notifications,
    unreadNotificationsCount,
    isLoading,
    isError,
    onMarkReadClick,
    handleNotificationItemClick,
  } = useNotificationsButtonController();

  useFlowActivityUpdatesSocket();
  useNotificationSocket();

  // const getNotificationTriggerEvents = (
  //   data: HandleNotificationItemClickParamTypes,
  // ) => {
  //   let notificationType = '';

  //   switch (data.notificationType) {
  //     case 'flow_triggered':
  //       notificationType = 'participation trigger';
  //       break;
  //     case 'flow_reminder':
  //       notificationType = 'participation reminder';
  //       break;
  //     case 'comment':
  //       notificationType = 'comment';
  //       break;
  //     case 'post':
  //       notificationType = 'recognition';
  //       break;
  //     case 'tagged_in_comment':
  //       notificationType = 'mention';
  //       break;
  //     default:
  //       break;
  //   }

  //   return {
  //     flowId: data.flowId,
  //     postId: data.id,
  //     responseId: data.occurrenceId,
  //     notificationTypeClicked: notificationType,
  //   };
  // };

  return (
    <NotificationsButton
      unreadNotificationsCount={unreadNotificationsCount}
      notificationData={notifications}
      data-testid="notificationsContent"
      isLoading={isLoading}
      onMarkReadClick={() => {
        onMarkReadClick();
        /* TODO Jayasurya -- check with product */
        // trackLeftDrawerEvent(
        //   LEFT_DRAWER_ANALYTICS_EVENTS.LEFT_DRAWER_EVENTS_MARKED_ALL_READ_CLICKED,
        // );
      }}
      handleNotificationItemClick={(item) => {
        handleNotificationItemClick(item);
        // trackLeftDrawerEvent(
        //   LEFT_DRAWER_ANALYTICS_EVENTS.LEFT_DRAWER_EVENTS_NOTIFICATION_CLICKED,
        //   getNotificationTriggerEvents(item),
        // );
      }}
      isError={isError}
      onUnreadClick={() => {
        // trackLeftDrawerEvent(
        //   LEFT_DRAWER_ANALYTICS_EVENTS.LEFT_DRAWER_EVENTS_UNREAD_NOTIFICATIONS_VIEW_CLICKED,
        // );
      }}
      onShowAllClick={() => {
        // trackLeftDrawerEvent(
        //   LEFT_DRAWER_ANALYTICS_EVENTS.LEFT_DRAWER_EVENTS_ALL_NOTIFICATIONS_VIEW_CLICKED,
        // );
      }}
      onNotificationSettingsClick={() => {
        // trackLeftDrawerEvent(
        //   LEFT_DRAWER_ANALYTICS_EVENTS.LEFT_DRAWER_EVENTS_NOTIFICATION_SETTINGS_CLICKED,
        // );
      }}
    />
  );
};

export default NotificationsController;
