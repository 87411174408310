import React, { useState } from 'react';

import Collapse from '../../atoms/Collapse';
import { StyledFlex, StyledBody, StyledIconButton } from './styles';

import { ButtonSize } from '../../atoms/IconButton_V2/interface';

export type FilterCollapseProps = {
  headingText: string;
  isFiltered?: boolean;
  children: React.ReactNode;
  isCollapseOpen?: boolean;
  className?: string;
  onCollapseClick?: (isCollapseOpen: boolean) => void;
};

const FilterCollapse = (props: FilterCollapseProps) => {
  const {
    headingText,
    isFiltered = false,
    children,
    isCollapseOpen = false,
    className,
    onCollapseClick,
  } = props;

  const [collapseOpen, toggleCollapseOpen] = useState(isCollapseOpen);

  const toggleCollapseOpenHandler = () => {
    if (onCollapseClick) {
      onCollapseClick(collapseOpen);
    }
    toggleCollapseOpen(!collapseOpen);
  };

  const CollapseHeaderComponent = () => (
    <StyledFlex justifyContent="space-between" alignItems="center" tabIndex={0}>
      <StyledBody variant="body1" color="gray7" isFiltered={isFiltered}>
        {headingText}
      </StyledBody>
      <StyledIconButton
        icon="carat-down"
        rotate={collapseOpen ? 180 : 0}
        rounded
        size={ButtonSize.Small}
      />
    </StyledFlex>
  );

  return (
    <Collapse
      collapseHeader={<CollapseHeaderComponent />}
      isCollapseOpen={isCollapseOpen}
      collapseOpenCallback={toggleCollapseOpenHandler}
      className={className}
    >
      {children}
    </Collapse>
  );
};

export default FilterCollapse;
