import React, { useEffect, useMemo, useState } from 'react';

import {
  YOUR_REWARD_WILL_BE_SENT_TO,
  AFTER_CLICKING_REDEEM,
  CONGRATS_YOU_HAVE_SUCCESSFULLY_REDEEMED,
  CULTURE_REWARD_REDEMPTION_POLICY,
  YOUR_ADMINISTRATOR_WILL_CONTACT_YOU,
  PLEASE_CHECK_YOUR_EMAIL,
  ALWAYS_CHECK_YOUR_SPAM,
  REDEEM_SUCCESS_MESSAGE,
  REDEEM_ERROR_MESSAGE,
} from '../../../languages/en/rewards';
import RedeemSelectModal from '../../../atomic/organism/RedeemSelectModal';
import {
  RedeemPolicyContent,
  RedeemPolicyHeading,
  RewardSuccessContentText,
  RewardSuccessContentTextEmail,
} from '../../../atomic/organism/RedeemSelectModal/styles';
import useRedeemModalControllerLogic from './useCultureRedeemModalControllerLogic';
import { CultureReward } from '../../../queries/Rewards/interfaces';
import { AssemblyCurrency } from '../../../interfaces/assembly';
import { useCultureRedeemPostQuery } from '../../../queries/Rewards';
import { RewardTypes } from '../../../atomic/molecules/RewardCard/interface';
import { showErrorMessage, showSuccessMessage } from '../../../Utils/toast';

interface CultureRedeemModalControllerProps {
  reward: CultureReward;
  isModalOpen: boolean;
  profileEmail: string;
  assemblyCurrency: AssemblyCurrency;
  onCloseRedeemModal: () => void;
}

const CultureRedeemModalController = (
  props: CultureRedeemModalControllerProps,
) => {
  const {
    reward,
    isModalOpen,
    profileEmail,
    assemblyCurrency,
    onCloseRedeemModal,
  } = props;

  const [hasRedeemed, setHasRedeemed] = useState(false);

  const {
    isLoading: isRedeemRewardLoading,
    isError: isRedeemRewardError,
    isSuccess: isRedeemRewardSuccess,
    data: redeemRewardSuccess,
    mutate: redeemRewardMutate,
    reset: redeemRewardReset,
  } = useCultureRedeemPostQuery();

  const {
    modelMethods,
    id,
    rewardTitle,
    isRedeemButtonDisabled,
    rewardCost,
    rewardDescription,
    rewardImage,
    isRewardRated,
    rewardSuccessTitle,
  } = useRedeemModalControllerLogic({
    reward,
    onCloseRedeemModal,
    assemblyCurrency,
    redeemRewardMutate,
    redeemRewardReset,
    profileEmail,
  });

  useEffect(() => {
    if (isRedeemRewardSuccess && !hasRedeemed) {
      setHasRedeemed(true);
    }
  }, [isRedeemRewardSuccess, hasRedeemed]);

  useEffect(() => {
    if (isRedeemRewardSuccess) {
      showSuccessMessage(REDEEM_SUCCESS_MESSAGE);
    }
  }, [isRedeemRewardSuccess]);

  useEffect(() => {
    if (isRedeemRewardError) {
      showErrorMessage(REDEEM_ERROR_MESSAGE);
    }
  }, [isRedeemRewardError]);

  const redeemPolicyContent = useMemo(() => {
    return (
      <>
        <RedeemPolicyHeading variant="h6">
          {CULTURE_REWARD_REDEMPTION_POLICY}
        </RedeemPolicyHeading>
        <RedeemPolicyContent variant="body2">
          {YOUR_REWARD_WILL_BE_SENT_TO} <b>{profileEmail}</b>.
        </RedeemPolicyContent>
        <RedeemPolicyContent variant="body2">
          {YOUR_ADMINISTRATOR_WILL_CONTACT_YOU}
        </RedeemPolicyContent>
        <RedeemPolicyContent variant="body2">
          {AFTER_CLICKING_REDEEM}
        </RedeemPolicyContent>
      </>
    );
  }, [profileEmail]);

  const rewardSuccessContent = useMemo(() => {
    if (redeemRewardSuccess && redeemRewardSuccess.data.data.order.orderInfo) {
      return (
        <>
          <RewardSuccessContentTextEmail variant="body2">
            {PLEASE_CHECK_YOUR_EMAIL}
            <br />
            <b>{profileEmail}</b>
          </RewardSuccessContentTextEmail>
          <RewardSuccessContentText variant="body2">
            {ALWAYS_CHECK_YOUR_SPAM}
          </RewardSuccessContentText>
        </>
      );
    }
    return '';
  }, [profileEmail, redeemRewardSuccess]);

  return (
    <>
      <RedeemSelectModal
        isOpen={isModalOpen}
        isLoadingPostRedeem={isRedeemRewardLoading}
        {...modelMethods}
        assemblyCurrency={assemblyCurrency}
        redeemPolicyContent={redeemPolicyContent}
        rewardSuccessContent={rewardSuccessContent}
        rewardSuccessHeading={CONGRATS_YOU_HAVE_SUCCESSFULLY_REDEEMED}
        rewardType={RewardTypes.Culture}
        id={id}
        rewardTitle={rewardTitle}
        isRedeemButtonDisabled={isRedeemButtonDisabled}
        rewardCost={rewardCost}
        rewardDescription={rewardDescription}
        rewardImage={rewardImage}
        hasRedeemed={hasRedeemed}
        isRewardRated={isRewardRated}
        rewardSuccessTitle={rewardSuccessTitle}
      />
    </>
  );
};

export default CultureRedeemModalController;
