import React, { useState } from 'react';
import ThemeV2 from '../../../../componentsV2/theme';
import {
  StyledChevronSVGIcon,
  StyledClickableWrapper,
  StyledPlaceholderImage,
  StyledRefreshButton,
  Wrapper,
} from './styles';
import { CalendarSelectDropdownBarWithRefreshProps } from './types';
import DropdownWithChildren from '../../DropdownWithChildren';
import Body from '../../../atoms/Body';
import { LIST, REFRESH } from '../../../../languages/en/singleWords';
import ContentLoader from 'react-content-loader';

const CalendarSelectDropdownBarWithRefresh = (
  props: CalendarSelectDropdownBarWithRefreshProps,
) => {
  const {
    className,
    isLoading,
    dropdownMenuItems,
    selectedValue,
    onDropdownItemClick,
    dropdownPlaceholderImage,
    isRefreshButtonDisabled,
    onRefreshListClick,
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  const onDropdownOpen = (dropdownStatus: boolean) => {
    setIsOpen(dropdownStatus);
  };

  return (
    <Wrapper className={className}>
      {isLoading ? (
        <ContentLoader
          speed={2}
          width={156}
          height={22}
          viewBox="0 156 22"
          className="contentLoader"
          data-testid="calendar-bar-content-loader"
          backgroundColor={ThemeV2.palette.gray4}
          foregroundColor={ThemeV2.palette.gray3}
        >
          <rect x="0" y="0" rx="4" ry="4" width="156" height="22" />
        </ContentLoader>
      ) : (
        <DropdownWithChildren
          menuItems={dropdownMenuItems}
          onDropdownOpen={onDropdownOpen}
          onItemClick={onDropdownItemClick}
        >
          <StyledClickableWrapper>
            <StyledPlaceholderImage
              src={dropdownPlaceholderImage}
              alt={selectedValue}
              height="18px"
              width="18px"
            />
            <Body variant="body2Medium" color="gray8">
              {selectedValue}
            </Body>
            <StyledChevronSVGIcon
              icon="caret-rounded"
              color={ThemeV2.palette.gray8}
              flip={isOpen}
              size="18px"
            ></StyledChevronSVGIcon>
          </StyledClickableWrapper>
        </DropdownWithChildren>
      )}
      <StyledRefreshButton
        color="primary"
        variant="text"
        icon="retry"
        size="small"
        onClick={onRefreshListClick}
        disabled={isRefreshButtonDisabled}
      >
        {`${REFRESH} ${LIST.toLowerCase()}`}
      </StyledRefreshButton>
    </Wrapper>
  );
};

export default CalendarSelectDropdownBarWithRefresh;
