import React from 'react';

import useSettingsMultiSelectDropdownLogic from './useSettingsMultiSelectDropdownLogic';

import Body from '../../atoms/Body';
import { Flex } from '../../../Utils/styles/display';
import { SAVE_CHANGES } from '../../../languages/en/settings';

import { SettingsMultiSelectDropdownProps } from './interface';
import { StyledButton, StyledSVGIcon, StyledHelperTextWrapper } from './styles';
import MultiSelectDropdown from '../../organism/MultiSelectDropdown';
import ThemeV2 from '../../../componentsV2/theme';

const SettingsMultiSelectDropdown = (
  props: SettingsMultiSelectDropdownProps,
) => {
  const { isDisabled } = props;
  const { models, operations } = useSettingsMultiSelectDropdownLogic(props);
  const { multiSelectProps, helperTextMessage, isButtonDisabled } = models;
  const { onSaveChangesClick } = operations;
  return (
    <>
      <Flex>
        <MultiSelectDropdown {...multiSelectProps} />
        {isDisabled && (
          <StyledSVGIcon
            icon="lock"
            color={ThemeV2.palette.lightGray6}
            size="16px"
          />
        )}
      </Flex>
      <StyledHelperTextWrapper>
        <Body variant="body4">{helperTextMessage}</Body>
      </StyledHelperTextWrapper>
      <StyledButton
        color="primary"
        variant="contained"
        status="default"
        disabled={isButtonDisabled}
        onClick={onSaveChangesClick}
        size="small"
      >
        {SAVE_CHANGES}
      </StyledButton>
    </>
  );
};

export default SettingsMultiSelectDropdown;
