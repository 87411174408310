import { EditorState } from 'draft-js';
import { TestContext } from 'yup';
import { Mention } from '../../interfaces/user';
import { VALIDATION_ERRORS } from '../../languages/en/flows/participation';
import { getCharacterCountWithoutMentions } from '../draftjs';

type TestContextType = TestContext<Record<string, any>>;

export const exactLengthTest = (exactLength: number) => (arr: any) =>
  arr?.length === 0 || arr?.length === exactLength;

export const minimumCountTest = (minLength: number) => (arr: any) =>
  arr?.length === 0 || arr?.length >= minLength;

export const maximumCountTest = (maxLength: number) => (arr: any) =>
  arr?.length === 0 || arr?.length <= maxLength;

export const arrayRequiredTest = (arr: any[] | undefined) =>
  Boolean(arr?.length);

export const openEndedRequiredTest = ({
  editorState,
}: {
  editorState: EditorState;
}) => editorState.getCurrentContent().hasText();

export const checkOpenEndedBlockMinimumCharacters = (
  editorState: EditorState,
  selectedMentions: Mention[],
  testContext: TestContextType,
  minimumCharacterLimit: number,
  tags: Mention[],
) => {
  const messageLength = getCharacterCountWithoutMentions(
    editorState,
    selectedMentions,
    tags,
  );
  if (messageLength < minimumCharacterLimit) {
    const amountUnderCharacterLimit = minimumCharacterLimit - messageLength;
    const message = VALIDATION_ERRORS.generateMinimumCharacterLimitError(
      amountUnderCharacterLimit,
    );
    return testContext.createError({ message });
  }

  return messageLength >= minimumCharacterLimit;
};

export const checkOpenEndedBlockMaximumCharacters = (
  editorState: EditorState,
  selectedMentions: Mention[],
  testContext: TestContextType,
  maximumCharacterLimit: number,
  tags: Mention[],
) => {
  const messageLength = getCharacterCountWithoutMentions(
    editorState,
    selectedMentions,
    tags,
  );
  if (messageLength > maximumCharacterLimit) {
    const amountOverCharacterLimit = messageLength - maximumCharacterLimit;
    const message = VALIDATION_ERRORS.generateMaximumCharacterLimitError(
      amountOverCharacterLimit,
    );
    return testContext.createError({ message });
  }

  return maximumCharacterLimit >= messageLength;
};
