import styled from 'styled-components';
import ThemeV2 from '../../../componentsV2/theme';
import EmoticonHolder from '../EmoticonHolder';
import { CardWithIconSize } from './interfaces';

export const WrapperBase = styled.div`
  box-sizing: border-box;
  border-radius: 4px;
  background: ${ThemeV2.palette.gray1};
  transition: 0.2s ease;
  transition-property: filter;
  cursor: ${({ onClick }) => onClick && 'pointer'};
  &:not(:hover) {
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.04))
      drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.02))
      drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.12));
  }
  &:hover {
    filter: drop-shadow(0px 8px 10px rgba(0, 0, 0, 0.04))
      drop-shadow(0px 3px 14px rgba(0, 0, 0, 0.02))
      drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.12));
  }
`;

export const Wrapper = styled(WrapperBase)<{ size: CardWithIconSize }>`
  position: relative;
  padding: ${({ size }) =>
    size === CardWithIconSize.Regular ? '20px 16px' : '12px 16px'};
`;

export const StyledEmoticonHolder = styled(EmoticonHolder)`
  position: absolute;
  top: -11%;
  left: 16px;
`;
