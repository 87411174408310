import ThemeV2 from '../../componentsV2/theme';

export const dataUrlToFile = async (dataUrl: string, fileName: string) => {
  const res: Response = await fetch(dataUrl, { credentials: 'same-origin' });
  const blob: Blob = await res.blob();
  return new File([blob], fileName, { type: 'image/png' });
};

export const assignFileTypeInfo = (
  fileType: string,
  setColor?: React.Dispatch<React.SetStateAction<string>>,
  setIcon?: React.Dispatch<React.SetStateAction<string>>,
) => {
  switch (fileType) {
    case 'doc':
    case 'docx':
      if (setColor) {
        setColor(ThemeV2.palette.blue7);
      }
      if (setIcon) {
        setIcon('docFile');
      }
      break;
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'svg':
    case 'bmp':
    case 'gif':
      if (setColor) {
        setColor(ThemeV2.palette.blue8);
      }
      if (setIcon) {
        setIcon('imageFile');
      }
      break;
    case 'ppt':
    case 'pptx':
    case 'pps':
    case 'ppsx':
      if (setColor) {
        setColor(ThemeV2.palette.volcano6);
      }
      if (setIcon) {
        setIcon('presentationFile');
      }
      break;
    case 'pdf':
      if (setColor) {
        setColor(ThemeV2.palette.red6);
      }
      if (setIcon) {
        setIcon('pdfFile');
      }
      break;
    case 'xls':
    case 'xlsx':
    case 'csv':
      if (setColor) {
        setColor(ThemeV2.palette.green7);
      }
      if (setIcon) {
        setIcon('csvFile');
      }
      break;
    case 'mp3':
    case 'wav':
    case 'm4a':
    case 'ogg':
      if (setColor) {
        setColor(ThemeV2.palette.orange6);
      }
      if (setIcon) {
        setIcon('audioFile');
      }
      break;
    case 'mp4':
    case 'avi':
    case 'mov':
    case 'mpg':
    case 'ogv':
    case '3gp':
    case '3d2':
      if (setColor) {
        setColor(ThemeV2.palette.red7);
      }
      if (setIcon) {
        setIcon('videoFile');
      }
      break;
    case 'zip':
      if (setColor) {
        setColor(ThemeV2.palette.gray8);
      }
      if (setIcon) {
        setIcon('zipFile');
      }
      break;
    default:
      if (setColor) {
        setColor(ThemeV2.palette.gray6);
      }
      if (setIcon) {
        setIcon('genericFile');
      }
      break;
  }
};
