import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { Carousel } from '@giphy/react-components';
import ContentLoader from 'react-content-loader';
import { IGif } from '@giphy/js-types';
import { GiphyFetch } from '@giphy/js-fetch-api';
import debounce from 'lodash/debounce';
import {
  LeftArrowButton,
  RightArrowButton,
  CarouselContainer,
  NoGifsFoundContainer,
} from './styles';
import Textfield from '../NewTextfield';
import { SEARCH_GIFS, NO_GIFS_FOUND } from '../../../languages/en/flows';
import ThemeV2 from '../../../componentsV2/theme';
import useToggle from '../../../hooks/useToggle';
import { GIPHY_KEY } from '../../../config';

export type GifCarouselInputProps = {
  disabled?: boolean;
  onGifClick: (gif: IGif, event: SyntheticEvent<HTMLElement, Event>) => void;
};

const GifCarousel = ({
  disabled = false,
  onGifClick,
}: GifCarouselInputProps) => {
  const noResultsFoundComponent = (
    <NoGifsFoundContainer>{NO_GIFS_FOUND}</NoGifsFoundContainer>
  );

  const [searchTerm, setSearchTerm] = useState<string>('');

  const giphyFetch = new GiphyFetch(GIPHY_KEY);

  const {
    models: { toggleValue: isGifsLoading },
    operations: {
      setToggleToFalse: setLoadingToFalse,
      setToggleToTrue: setLoadingToTrue,
    },
  } = useToggle(true);
  const {
    models: { toggleValue: noGifsFound },
    operations: {
      setToggleToFalse: setGifsFoundToFalse,
      setToggleToTrue: setGifsFoundToTrue,
    },
  } = useToggle();
  const {
    models: { toggleValue: hideLeftArrow },
    operations: {
      setToggleToTrue: toggleLeftArrowHiddenToTrue,
      setToggleToFalse: toggleLeftArrowHiddenToFalse,
    },
  } = useToggle(true);

  const debounceLeftArrowHidden = useRef(
    debounce((newValue: boolean) => {
      if (newValue) {
        toggleLeftArrowHiddenToTrue();
      } else {
        toggleLeftArrowHiddenToFalse();
      }
    }, 100),
  );

  const fetchGifs = (offset: number) => {
    const giphyPromise = searchTerm
      ? giphyFetch.search(searchTerm, { offset, limit: 10 })
      : giphyFetch.trending({ offset, limit: 10 });
    giphyPromise.then(({ data }) => {
      setLoadingToFalse();
      if (data.length === 0) {
        setGifsFoundToFalse();
      } else {
        setGifsFoundToTrue();
      }
    });
    return giphyPromise;
  };

  const shouldLeftArrowBeHidden = () => {
    const carouselElement =
      document.getElementsByClassName('giphy-carousel')[0];
    if (carouselElement) {
      return carouselElement.scrollLeft === 0;
    }
    return true;
  };

  useEffect(() => {
    const element = document.getElementsByClassName('giphy-carousel')[0];
    if (element) {
      const scrollCheckFunction = () => {
        debounceLeftArrowHidden.current(shouldLeftArrowBeHidden());
      };
      element.addEventListener('scroll', scrollCheckFunction);
      return () => element.removeEventListener('scroll', scrollCheckFunction);
    }
    return undefined;
  }, []);

  const onScrollAheadClick = () => {
    const carouselElement =
      document.getElementsByClassName('giphy-carousel')[0];
    if (carouselElement) {
      const pixelGapToEnd =
        carouselElement.scrollWidth - carouselElement.scrollLeft;
      if (pixelGapToEnd > 0) {
        carouselElement.scrollTo(
          carouselElement.scrollLeft +
            Math.min(pixelGapToEnd, carouselElement.clientWidth),
          0,
        );
      }
      if (hideLeftArrow) {
        toggleLeftArrowHiddenToFalse();
      }
    }
  };

  const onScrollBackClick = () => {
    const carouselElement =
      document.getElementsByClassName('giphy-carousel')[0];
    if (carouselElement) {
      carouselElement.scrollTo(
        carouselElement.scrollLeft -
          Math.min(carouselElement.scrollLeft, carouselElement.clientWidth),
        0,
      );
    }
  };

  const GifLoadingTemplate = () => {
    const element = document.getElementsByClassName('MuiFormControl-root')[0];
    return (
      <ContentLoader
        speed={2}
        width="100%"
        height="200px"
        viewBox={`0 0 ${element?.clientWidth || 600} 200`}
        backgroundColor={ThemeV2.palette.gray4}
        foregroundColor={ThemeV2.palette.gray3}
      >
        <rect x="0" y="0" rx="0" ry="0" width="35%" height="100%" />
        <rect x="36%" y="0" rx="0" ry="0" width="24%" height="100%" />
        <rect x="61%" y="0" rx="0" ry="0" width="39%" height="100%" />
      </ContentLoader>
    );
  };

  return (
    <>
      <Textfield
        icon={disabled ? 'disabled-search' : ''}
        disabled={disabled}
        value={searchTerm}
        onChange={(e) => {
          setLoadingToTrue();
          toggleLeftArrowHiddenToTrue();
          setSearchTerm(e.target.value);
        }}
        placeholder={SEARCH_GIFS}
        {...(disabled ? { background: ThemeV2.palette.white } : {})}
      />
      <CarouselContainer
        data-testid="giphy-carousel"
        isGifsLoading={isGifsLoading}
        isDisabled={disabled}
      >
        <Carousel
          key={searchTerm}
          fetchGifs={fetchGifs}
          gifHeight={200}
          gutter={6}
          hideAttribution
          onGifClick={onGifClick}
          noResultsMessage={noResultsFoundComponent}
        />
        {isGifsLoading && <GifLoadingTemplate />}
        {!isGifsLoading && noGifsFound && (
          <>
            <LeftArrowButton
              icon="arrow-left"
              dataTestId="arrow-left-button"
              rounded
              color="secondary"
              onClick={onScrollBackClick}
              isHidden={hideLeftArrow}
            />
            <RightArrowButton
              icon="arrow-right"
              dataTestId="arrow-right-button"
              rounded
              color="secondary"
              onClick={onScrollAheadClick}
            />
          </>
        )}
      </CarouselContainer>
    </>
  );
};

export default GifCarousel;
