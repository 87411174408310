import React from 'react';
import { useHistory } from 'react-router-dom';

import ThemeV2 from '../../../componentsV2/theme';

import { RouteTabsProps, LinkTabProp, RouteTabButton } from './interfaces';
import { useStyles } from './styles';

import SVGIcon from '../../atoms/SVGIcon';
import {
  StyledMoreButton,
  StyledTab,
  StyledTabs,
} from '../../atoms/Tabs/styles';

const LinkTab = (props: LinkTabProp) => {
  const history = useHistory();
  const { href } = props;

  return (
    <StyledTab
      onClick={(event) => {
        event.preventDefault();
        history.replace(href);
      }}
      {...props}
    />
  );
};

const MoreButton = (props: unknown) => {
  return (
    <StyledMoreButton {...props}>
      <SVGIcon icon="more" size="24px" color={ThemeV2.palette.gray8} />
    </StyledMoreButton>
  );
};

function a11yProps(id: string) {
  return {
    id: `<scrollable-auto-tab-$>id</scrollable-auto-tab-$>`,
    'aria-controls': `scrollable-auto-tabpanel-${id}`,
  };
}

const renderRouteTabButtons = (
  routeTabButtons: RouteTabButton[],
  urlBase: string,
) => {
  return routeTabButtons.map((button) => (
    <LinkTab
      label={button.label}
      href={`${urlBase}/${button.id}`}
      key={button.id}
      value={button.id}
      {...a11yProps(button.id)}
    />
  ));
};

const RouteTabs = (props: RouteTabsProps) => {
  const { routeTabButtons, urlBase, handleTabChange, isFixed = false } = props;
  const pathname = window.location.pathname;
  const activeTab = pathname.substr(pathname.lastIndexOf('/') + 1);
  const classes = useStyles({ isFixed });

  return (
    <div className={classes.root}>
      <StyledTabs
        value={activeTab}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        ScrollButtonComponent={MoreButton}
        $isTabPositionFixed={isFixed}
        onChange={(e, newTab) => {
          if (handleTabChange) {
            handleTabChange(e, newTab);
          }
        }}
      >
        {renderRouteTabButtons(routeTabButtons, urlBase)}
      </StyledTabs>
    </div>
  );
};

export default RouteTabs;
