import React from 'react';
import styled from 'styled-components';
import { EmoticonWithTextProps } from './type';
import ThemeV2 from '../../../componentsV2/theme';

const Wrapper = styled.div`
  display: inline;
`;

const Text = styled.span<{ hasBoldText?: boolean }>`
  font-weight: ${({ hasBoldText }) =>
    hasBoldText && ThemeV2.typography.fontWeightMedium};
`;

const EmoticonWithText = (props: EmoticonWithTextProps) => {
  const { text, emoticon, dataTestId, hasBoldText, className } = props;

  return (
    <Wrapper className={className} data-testid={dataTestId}>
      <span role="img" aria-label={text || 'emoticon'}>
        {emoticon}
      </span>{' '}
      <Text hasBoldText={hasBoldText}>{text && text}</Text>
    </Wrapper>
  );
};

export default EmoticonWithText;
