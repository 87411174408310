import { GetProfileInfoResponse } from '../../../queries/Profile';
import { FlowFeedResponse } from '../../../queries/Flows/Feed/interfaces';
import { ReactionType } from '../../../interfaces/Feed/Reaction';
import { FLOW_FEED_STATES } from '../../../languages/en/flows/feed';

export const mapRecognitionPostToReactionProps = (
  data: FlowFeedResponse,
  profile: GetProfileInfoResponse | undefined,
) => {
  const postID = data.responseId;
  const userId = profile?.member.memberId || '';

  const reactions = data.reactions.map((reaction) => ({
    members: reaction.members.map((member) => ({
      name: member.name,
      memberID: member.memberID,
    })),
    reaction: {
      name: reaction.name,
      type:
        reaction.type === 'REGULAR'
          ? ReactionType.REGULAR
          : ReactionType.CUSTOM,
      displayName: reaction?.displayName,
      value: reaction.value,
    },
    timesReacted: reaction.members.length,
    active: reaction.members.some((member) => member.memberID === userId),
  }));

  return {
    reactions,
    contentId: postID,
    currentUserId: userId,
  };
};

export const getScheduleFlowEmptyStateSubHeading = (
  date: string,
  time: string,
  timeZone: string | undefined,
  isShortcut: boolean,
  isOwner: boolean,
) => {
  if (isShortcut) {
    return `${FLOW_FEED_STATES.SCHEDULED_FLOW_EMPTY_STATES.GET_SUB_HEADING_PREFIX(
      date,
      time,
      timeZone,
    )} ${isOwner ? 'Participants' : 'You'} ${
      FLOW_FEED_STATES.SCHEDULED_FLOW_EMPTY_STATES
        .SUB_HEADING_SUFFIX_WITH_SHORTCUT
    }`;
  }

  return `${FLOW_FEED_STATES.SCHEDULED_FLOW_EMPTY_STATES.GET_SUB_HEADING_PREFIX(
    date,
    time,
    timeZone,
  )} ${isOwner ? 'Participants and viewers' : 'You'} ${
    FLOW_FEED_STATES.SCHEDULED_FLOW_EMPTY_STATES
      .SUB_HEADING_SUFFIX_WITHOUT_SHORTCUT
  }`;
};

export const getScheduleFlowEmptyStateTriggeredOnceSubHeading = (
  date: string,
  time: string,
  timeZone: string | undefined,
  isShortcut: boolean,
  isOwner: boolean,
) => {
  if (isShortcut) {
    return `${FLOW_FEED_STATES.SCHEDULED_FLOW_TRIGGERED_ONCE_EMPTY_STATES.GET_SUB_HEADING_PREFIX(
      date,
      time,
      timeZone,
    )} ${isOwner ? 'Participants' : 'You'} ${
      FLOW_FEED_STATES.SCHEDULED_FLOW_TRIGGERED_ONCE_EMPTY_STATES
        .SUB_HEADING_SUFFIX_WITH_SHORTCUT
    }`;
  }

  return `${FLOW_FEED_STATES.SCHEDULED_FLOW_TRIGGERED_ONCE_EMPTY_STATES.GET_SUB_HEADING_PREFIX(
    date,
    time,
    timeZone,
  )} ${
    FLOW_FEED_STATES.SCHEDULED_FLOW_TRIGGERED_ONCE_EMPTY_STATES
      .SUB_HEADING_SUFFIX_WITHOUT_SHORTCUT_1
  } ${isOwner ? 'all participants' : 'you'} ${
    FLOW_FEED_STATES.SCHEDULED_FLOW_TRIGGERED_ONCE_EMPTY_STATES
      .SUB_HEADING_SUFFIX_WITHOUT_SHORTCUT_2
  }`;
};
