import React from 'react';

import ProfileFeedFilter from '../../../../atomic/organism/RightDrawerFilter/ProfileFeedFilter';
import { ComponentStatus } from '../../../../interfaces/component';

import useProfileFeedRightDrawerControllerLogic from './useProfileFeedRightDrawerControllerLogic';
import { FilterControllerProps } from '../../types';

const FilterController = ({ setIsFiltered }: FilterControllerProps) => {
  const { models, operations } = useProfileFeedRightDrawerControllerLogic({
    setIsFiltered,
  });
  const {
    dateFilterProps,
    flowsFilterProps,
    postedByFilterProps,
    mentionedFilterProps,
    isLoading,
    isEmpty,
    isError,
    isFilterButtonEnabled,
  } = models;
  const { handleOnClear, handleOnSubmit } = operations;

  if (isLoading) {
    return <ProfileFeedFilter status={ComponentStatus.LOADING} />;
  }
  if (isError) {
    return <ProfileFeedFilter status={ComponentStatus.ERROR} />;
  }
  if (isEmpty) {
    return <ProfileFeedFilter status={ComponentStatus.EMPTY} />;
  }

  return (
    <ProfileFeedFilter
      status={ComponentStatus.LOADED}
      dateFilterProps={dateFilterProps}
      flowFilterProps={flowsFilterProps}
      postedByFilterProps={postedByFilterProps}
      mentionedByFilterProps={mentionedFilterProps}
      onSubmit={handleOnSubmit}
      onClear={handleOnClear}
      isFilterButtonEnabled={isFilterButtonEnabled}
    />
  );
};

export default FilterController;
