import React from 'react';

export enum IdentityManagerErrorCodes {
  ACCESS_DENIED = 'access_denied',
  ACCESS_DENIED_SLACK = 'access_denied',
  ACCESS_DENIED_OFFICE365 = 'consent_required',
  DIFFERENT_IDENTITY_MANAGER_REGISTERED = 'DIFFERENT_IDENTITY_MANAGER_REGISTERED',
  IM_CONNECTED_TO_ANOTHER_ASSEMBLY = 'IM_CONNECTED_TO_ANOTHER_ASSEMBLY',
  MISSING_REQUIRED_PARAMETERS = 'MISSING_REQUIRED_PARAMETERS',
  FETCH_PROFILE_ERROR = 'FETCH_PROFILE_ERROR',
  FETCH_TOKEN_ERROR = 'FETCH_TOKEN_ERROR',
  EMAIL_MISMATCH = 'EMAIL_MISMATCH',
  SERVER_ERROR = 'SERVER_ERROR',
  FETCH_DIRECTORY_USER_ERROR = 'FETCH_DIRECTORY_USER_ERROR',
  NOT_A_GSUITE_ADMIN = 'NOT_A_GSUITE_ADMIN',
  SCOPE_MISMATCH = 'SCOPE_MISMATCH',
  INSUFFICIENT_GSUITE_PERMISSIONS = 'INSUFFICIENT_GSUITE_PERMISSIONS',
  GSUITE_USER_NOT_FOUND = 'GSUITE_USER_NOT_FOUND',
  // Google SSO Errors https://github.com/anthonyjgrove/react-google-login#onfailure-callback
  POPUP_CLOSED_BY_USER = 'popup_closed_by_user',
  COOKIES_NOT_ALLOWED = 'idpiframe_initialization_failed',
  IMMEDIATE_FAILED = 'immediate_failed',
  SLACK_TEAM_MISMATCH = 'SLACK_TEAM_MISMATCH',
  OFFICE365_TENANT_MISMATCH = 'OFFICE365_TENANT_MISMATCH',
  INTERNAL_ERROR = 'internal_error',
  ADP_FETCH_SUBSCRIPTION_CREDENTIALS_FAILED = 'ADP_FETCH_SUBSCRIPTION_CREDENTIALS_FAILED',
  ADP_ALREADY_DISCONNECTED = 'ADP_ALREADY_DISCONNECTED',
  ADP_ASSEMBLY_NOT_FOUND = 'ADP_ASSEMBLY_NOT_FOUND',
  ADP_CONNECTION_FAILED = 'ADP_CONNECTION_FAILED',
  ADP_MEMBER_CREATION_FAILED = 'ADP_MEMBER_CREATION_FAILED',
  ADP_NOT_AUTHORIZED = 'ADP_NOT_AUTHORIZED',
  ADP_NOT_CONNECTED = 'ADP_NOT_CONNECTED',
  ADP_WORKER_SYNC_FAILED = 'ADP_WORKER_SYNC_FAILED',
  ADP_USER_NOT_FOUND = 'ADP_USER_NOT_FOUND',
  ASSEMBLY_CREATION_FAILED = 'ASSEMBLY_CREATION_FAILED',
  ASSEMBLY_NOT_ON_ADP_PLAN = 'ASSEMBLY_NOT_ON_ADP_PLAN',
  EVENT_FETCH_FAILED = 'EVENT_FETCH_FAILED',
  IDENTITY_MANAGER_NOT_ADP = 'IDENTITY_MANAGER_NOT_ADP',
  IDENTITY_MANAGER_NOT_FOUND = 'IDENTITY_MANAGER_NOT_FOUND',
  INVALID_ADP_EMAIL = 'INVALID_ADP_EMAIL',
  MEMBER_ASSEMBLY_MISMATCH = 'MEMBER_ASSEMBLY_MISMATCH',
  SUBMIT_BILLING_API_FAILED = 'SUBMIT_BILLING_API_FAILED',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  USER_UNASSIGNMENT_FAILED = 'USER_UNASSIGNMENT_FAILED',
}

interface ErrorDataInterface {
  [Key: string]: {
    message: string;
    link?: { href: string; text: string };
  };
}

export const ErrorData: ErrorDataInterface = {
  [IdentityManagerErrorCodes.ACCESS_DENIED_SLACK]: {
    message: 'Whoops, you declined Assembly’s permission to connect with Slack',
  },
  [IdentityManagerErrorCodes.ACCESS_DENIED_OFFICE365]: {
    message:
      'Whoops, you declined Assembly’s permission to connect with Office 365',
  },
  [IdentityManagerErrorCodes.ACCESS_DENIED]: {
    message: 'Whoops, you declined Assembly’s permission to connect with :sso',
  },
  [IdentityManagerErrorCodes.DIFFERENT_IDENTITY_MANAGER_REGISTERED]: {
    message: 'Sorry, you’re already using :sso as your Identity Manager',
  },
  [IdentityManagerErrorCodes.IM_CONNECTED_TO_ANOTHER_ASSEMBLY]: {
    message:
      'Sorry, your :sso account is already associated with another Assembly account',
  },
  [IdentityManagerErrorCodes.MISSING_REQUIRED_PARAMETERS]: {
    message:
      'Sorry, you’re missing required permission from your :sso account – please ask your :sso admin',
  },
  [IdentityManagerErrorCodes.FETCH_PROFILE_ERROR]: {
    message:
      'Sorry, we had an error trying to get your profile – please try again',
  },
  [IdentityManagerErrorCodes.FETCH_TOKEN_ERROR]: {
    message: `Whoops! We were unable to fetch information from :sso, please try again.
    If this error persists, please contact :link`,
    link: {
      href: 'mailto:support@joinassembly.com',
      text: 'support@joinassembly.com',
    },
  },
  [IdentityManagerErrorCodes.EMAIL_MISMATCH]: {
    message:
      'Whoops, your Assembly email on file and your :sso email on file doesn’t match',
  },
  [IdentityManagerErrorCodes.SERVER_ERROR]: {
    message: `Whoops! It looks like something went wrong. Please try again.
    If this issue persists, please contact :link`,
    link: {
      href: 'mailto:support@joinassembly.com',
      text: 'support@joinassembly.com',
    },
  },
  [IdentityManagerErrorCodes.FETCH_DIRECTORY_USER_ERROR]: {
    message: 'When something goes wrong while fetching directory user data',
  },
  [IdentityManagerErrorCodes.NOT_A_GSUITE_ADMIN]: {
    message:
      'You are not a GSuite admin, so you do not have the permissions to make this connection',
  },
  [IdentityManagerErrorCodes.SCOPE_MISMATCH]: {
    message: "Sorry, you don't have the proper permissions to access this",
  },
  [IdentityManagerErrorCodes.INSUFFICIENT_GSUITE_PERMISSIONS]: {
    message:
      "You don't have the proper permissions to connect to GSuite, please contact your admin",
  },
  [IdentityManagerErrorCodes.GSUITE_USER_NOT_FOUND]: {
    message:
      'Whoops! It looks like that email address does not exist within this Gsuite account. Please try again',
  },
  [IdentityManagerErrorCodes.POPUP_CLOSED_BY_USER]: {
    message: 'Incomplete login. Please try again.',
  },
  [IdentityManagerErrorCodes.IMMEDIATE_FAILED]: {
    message: 'Whoops! Some wires got crossed. Please try again.',
  },
  [IdentityManagerErrorCodes.COOKIES_NOT_ALLOWED]: {
    message: 'Please enable third party cookies to allow Google sign-in',
  },
  [IdentityManagerErrorCodes.SLACK_TEAM_MISMATCH]: {
    message: `Oops! We detect you are already set up with another Slack team in Assembly.
    Please make sure you are connecting to the same team`,
  },
  [IdentityManagerErrorCodes.OFFICE365_TENANT_MISMATCH]: {
    message: `Oops! We detect you are already set up with another Office 365 organization.
      Please make sure you are connecting to the same organization`,
  },
  [IdentityManagerErrorCodes.INTERNAL_ERROR]: {
    message: `We could not sign you in with ADP. Please contact your ADP administrator to make sure
    that you have Assembly for workforce now installed`,
  },
  [IdentityManagerErrorCodes.ADP_FETCH_SUBSCRIPTION_CREDENTIALS_FAILED]: {
    message: `Whoops! We were unable to authorize ADP. Please ensure you have provided the proper consent
    to allow Assembly to access your account. For help, see :link`,
    link: {
      href:
        // eslint-disable-next-line max-len
        'https://adpapps.adp.com/consent-manager/pending/direct?consumerApplicationID=f58294e5-2e04-46c3-890b-0c1126ef6a3d&successUri=https://apps.adp.com/myapps',
      text: 'here',
    },
  },
  [IdentityManagerErrorCodes.ADP_ALREADY_DISCONNECTED]: {
    message: `Whoops! It looks like ADP is not connected to your Assembly. Please connect and try again.`,
  },
  [IdentityManagerErrorCodes.ADP_ASSEMBLY_NOT_FOUND]: {
    message: `Whoops! We were unable to find an Assembly connected to your ADP account.
    Please ensure you are connecting with the correct email or contact your admin.`,
  },
  [IdentityManagerErrorCodes.ADP_CONNECTION_FAILED]: {
    message: `Whoops! Something went wrong and we were unable to connect your Assembly account.
    Please ensure you have the proper permissions enabled and try again. For more information, click :link`,
    link: {
      href: 'http://adpapps.adp.com/consent-manager',
      text: 'here',
    },
  },
  [IdentityManagerErrorCodes.ADP_MEMBER_CREATION_FAILED]: {
    message: `Whoops! Something went wrong and we were unable to create your Assembly account.
    Please make sure this ADP account has an email associated to it.`,
  },
  [IdentityManagerErrorCodes.ADP_NOT_AUTHORIZED]: {
    message: `Whoops! It looks like Assembly is not authorized in ADP.
    Please check the permissions in ADP and try again.`,
  },
  [IdentityManagerErrorCodes.ADP_NOT_CONNECTED]: {
    message: `Whoops! It looks like ADP is not connected to your Assembly. Please connect and try again.`,
  },
  [IdentityManagerErrorCodes.ADP_WORKER_SYNC_FAILED]: {
    message: `Whoops! It looks we can’t get your information from ADP.
    Please make sure you have the proper permissions enabled in ADP. For more information, click :link`,
    link: {
      href: 'http://adpapps.adp.com/consent-manager',
      text: 'here',
    },
  },
  [IdentityManagerErrorCodes.ADP_USER_NOT_FOUND]: {
    message: `Whoops! It looks like this user does not exist in Assembly.
    Please check to see if this user has been removed in ADP.`,
  },
  [IdentityManagerErrorCodes.ASSEMBLY_CREATION_FAILED]: {
    message: `Whoops! Something went wrong and we were unable to create your Assembly account.
    Please reach out to :link`,
    link: {
      href: 'mailto:support@joinassembly.com',
      text: 'support@joinassembly.com',
    },
  },
  [IdentityManagerErrorCodes.ASSEMBLY_NOT_ON_ADP_PLAN]: {
    message: `Whoops! It looks like you don’t have a billing account`,
  },
  [IdentityManagerErrorCodes.EVENT_FETCH_FAILED]: {
    message: `Whoops! We’re having trouble connecting to ADP. Please contact :link`,
    link: {
      href: 'mailto:support@joinassembly.com',
      text: 'support@joinassembly.com',
    },
  },
  [IdentityManagerErrorCodes.IDENTITY_MANAGER_NOT_ADP]: {
    message: `Whoops! It looks like you are already connected to another identity manager.
    Please remove that connection to connect to ADP.`,
  },
  [IdentityManagerErrorCodes.IDENTITY_MANAGER_NOT_FOUND]: {
    message: `Whoops! It looks like this account is not connected to an identity manager.
    Please connect or contact your admin. `,
  },
  [IdentityManagerErrorCodes.INVALID_ADP_EMAIL]: {
    message: `Whoops! It looks like this is not a valid email in ADP.
    Please check your email or contact your admin. `,
  },
  [IdentityManagerErrorCodes.MEMBER_ASSEMBLY_MISMATCH]: {
    message: `Whoops! It looks this email is already associated with another Assembly account. Please contact :link`,
    link: {
      href: 'mailto:support@joinassembly.com',
      text: 'support@joinassembly.com',
    },
  },
  [IdentityManagerErrorCodes.SUBMIT_BILLING_API_FAILED]: {
    message: `Whoops! It looks like we experienced an issue sending your billing information to ADP.
    Please try again. If this issue persists, please contact :link`,
    link: {
      href: 'mailto:support@joinassembly.com',
      text: 'support@joinassembly.com',
    },
  },
  [IdentityManagerErrorCodes.UNKNOWN_ERROR]: {
    message: `Whoops! It looks like something went wrong. Please try again.
    If this issue persists, please contact :link`,
    link: {
      href: 'mailto:support@joinassembly.com',
      text: 'support@joinassembly.com',
    },
  },
  [IdentityManagerErrorCodes.USER_UNASSIGNMENT_FAILED]: {
    message: `Whoops! We were unable to unassign this user. Please contact :link`,
    link: {
      href: 'mailto:support@joinassembly.com',
      text: 'support@joinassembly.com',
    },
  },
};

export const getIdentityManagerErrorData = (
  errorCode: IdentityManagerErrorCodes,
  sso: string,
) => {
  let errorCodeObj = ErrorData[IdentityManagerErrorCodes.SERVER_ERROR];
  if (Object.values(IdentityManagerErrorCodes).includes(errorCode)) {
    errorCodeObj = ErrorData[errorCode];
  }
  const { message, link } = errorCodeObj;
  let errorData = message as string;
  if (
    errorCode === IdentityManagerErrorCodes.IM_CONNECTED_TO_ANOTHER_ASSEMBLY ||
    errorCode === IdentityManagerErrorCodes.MISSING_REQUIRED_PARAMETERS ||
    errorCode === IdentityManagerErrorCodes.ACCESS_DENIED ||
    errorCode ===
      IdentityManagerErrorCodes.DIFFERENT_IDENTITY_MANAGER_REGISTERED ||
    errorCode === IdentityManagerErrorCodes.EMAIL_MISMATCH ||
    errorCode === IdentityManagerErrorCodes.FETCH_TOKEN_ERROR
  ) {
    errorData = message.replace(/:sso/g, sso);
  }
  if (link) {
    const { href, text } = link;
    errorData = errorData.replace(/:link/g, `<a href="${href}">${text}</a>`);
    return <span dangerouslySetInnerHTML={{ __html: errorData }} />;
  }
  return errorData;
};
