/* eslint-disable react/display-name */
import React, { useMemo, useCallback, useEffect, useState, memo } from 'react';
import Editor from '@draft-js-plugins/editor';
import createLinkifyPlugin from '@draft-js-plugins/linkify';
import createMentionPlugin, {
  MentionData,
  MentionPluginTheme,
} from '@draft-js-plugins/mention';
import { DraftHandleValue, EditorState } from 'draft-js';

import styled from 'styled-components';
import HighlightedText from '../../atoms/HighlightedText';
import UserNameCard from '../../molecules/UserNameCard';
import defaultMentionsStyles from './mentionsStyles.module.css';
import defaultEditorStyles from './editor.module.css';
import { EditorPlaceholder as defaultEditorPlaceholder } from '../../../languages/en/giveRecognitionForm/index';
import { ComponentStatus } from '../../../interfaces/component';
import { isMobile } from '../../../Utils/window';
import { MemberState } from '../../../interfaces/user';

const StyledWrapper = styled.div`
  width: 100%;
`;

export interface MentionsProps {
  name: string;
  link: string;
  avatar: string;
  id: string;
  icon?: string;
  info?: string;
  memberState?: MemberState | undefined;
}

export interface EditorProps {
  editorRefElement: any;
  editorState: EditorState;
  onEditorChange: (newState: EditorState) => void;
  onSearchChange?: ({ value }: { value: string }) => void;
  onAddMention?: ({ id, name }: { id: string; name: string }) => void;
  suggestions?: MentionsProps[] | MentionData[];
  onEditorFocus: () => void;
  mentionsStyles?: any;
  editorStyles?: any;
  onEditorBlur: () => void;
  editorPlaceholder?: string;
  isMentionsLoading?: boolean;
  onReturnKeyPress?: () => void;
  hideMentions?: boolean;
  isDisabled?: boolean;
  spellCheck?: boolean;
}

const linkifyPlugin = createLinkifyPlugin();

const Entry = (props: {
  mention: MentionData;
  theme?: MentionPluginTheme;
  searchValue?: string;
  isFocused: boolean;
}) => {
  // eslint-disable-next-line no-unused-vars
  const { mention, theme, searchValue, isFocused, ...parentProps } = props;
  return (
    <div {...parentProps}>
      <UserNameCard
        firstName={mention.name}
        lastName={mention.info || ''}
        userId={`${mention.id}`}
        imageSize="small"
        image={mention.avatar}
        status={ComponentStatus.LOADED}
        icon={mention.icon}
        memberState={mention.memberState}
      />
    </div>
  );
};

const EDITOR_ID = 'gr_form_ID_V2';

const MentionEditor = (props: EditorProps) => {
  const {
    mentionsStyles = defaultMentionsStyles,
    editorStyles = defaultEditorStyles,
    editorRefElement,
    editorState,
    onEditorChange,
    suggestions = [],
    onAddMention,
    onSearchChange,
    onEditorFocus,
    onEditorBlur,
    editorPlaceholder = defaultEditorPlaceholder,
    onReturnKeyPress,
    hideMentions,
    isDisabled = false,
    spellCheck = true,
  } = props;

  const [areMentionsSuggestionsOpen, setAreMentionsSuggestionsOpen] =
    useState(false);

  const handleMentionSuggestionsOpenChange = (areOpen: boolean) =>
    setAreMentionsSuggestionsOpen(areOpen);

  const handleUserKeyPress = useCallback((event) => {
    const { keyCode } = event;

    const postSuggestion = window.document.querySelector(`.${EDITOR_ID}`);
    const UP_ARROW_KEY = 38;
    const DOWN_ARROW_KEY = 40;
    if (keyCode === UP_ARROW_KEY || keyCode === DOWN_ARROW_KEY) {
      if (postSuggestion) {
        const suggestionsDiv = window.document.querySelector(
          `.${EDITOR_ID} [class^="mentionsStyles_mentionSuggestions"]`,
        );
        const suggestionsFocusedDiv: HTMLElement | null =
          window.document.querySelector(
            `.${EDITOR_ID} [class^="mentionsStyles_mentionSuggestionsEntryFocused"]`,
          );

        if (suggestionsDiv !== null) {
          suggestionsDiv.scrollTop = 0;
          if (suggestionsFocusedDiv !== null) {
            suggestionsDiv.scrollTop =
              suggestionsFocusedDiv.offsetTop -
              suggestionsDiv.clientHeight +
              100;
          }
        }
      }
    }
  }, []);

  const handleReturnKeyPress = (
    event: React.KeyboardEvent,
  ): DraftHandleValue => {
    if (
      !onReturnKeyPress ||
      event.shiftKey ||
      areMentionsSuggestionsOpen ||
      isMobile
    ) {
      return 'not-handled';
    }

    onReturnKeyPress();
    return 'handled';
  };

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);

    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  const [plugins, MentionSuggestionsComponent] = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      entityMutability: 'IMMUTABLE',
      mentionTrigger: '@',
      theme: mentionsStyles,
      supportWhitespace: false,
      mentionComponent: (mentionProps) => (
        <HighlightedText
          onClick={() => {}}
          name={mentionProps.mention.name}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          offsetKey={mentionProps.children[0].key}
        />
      ),
    });
    const pluginsArr = [mentionPlugin, linkifyPlugin];
    const { MentionSuggestions } = mentionPlugin;
    return [pluginsArr, MentionSuggestions];
  }, [mentionsStyles]);
  return (
    <StyledWrapper>
      <div className={EDITOR_ID}>
        <div className={editorStyles.editor}>
          <Editor
            ariaLabel="text editor"
            editorState={editorState}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //  @ts-ignore
            handleReturn={handleReturnKeyPress}
            onChange={onEditorChange}
            plugins={plugins}
            ref={editorRefElement}
            placeholder={editorPlaceholder}
            onFocus={onEditorFocus}
            onBlur={onEditorBlur}
            readOnly={isDisabled}
            spellCheck={spellCheck}
          />
          {!hideMentions && (
            <MentionSuggestionsComponent
              onOpenChange={handleMentionSuggestionsOpenChange}
              open={areMentionsSuggestionsOpen}
              suggestions={suggestions}
              onSearchChange={(e) => onSearchChange && onSearchChange(e)}
              onAddMention={onAddMention}
              entryComponent={Entry}
            />
          )}
        </div>
      </div>
    </StyledWrapper>
  );
};

const MemoizedMentionEditor = memo(MentionEditor);
MemoizedMentionEditor.displayName = 'MentionEditor';

export default MemoizedMentionEditor;
