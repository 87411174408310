import styled from 'styled-components';

import { device } from '../../../constants/layout';
import { shadowElevation2Style } from '../../../Utils/styles/css';
import { zIndexActionBarModal } from '../../../Utils/styles/z-index';

export const StyledActionModal = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: ${zIndexActionBarModal};
  top: 0;
  left: 0;
  background-color: #fff;
  ${shadowElevation2Style}
  border-radius: 4px;

  @media ${device.laptop2} {
    width: 540px;
    left: -32px;
    position: absolute;
    height: auto;
    max-height: 600px;
  }
`;
