// @ts-nocheck TODO: type issues need to be fixed in this file
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { string, func, bool } from 'prop-types';

import Modal from '../../../componentsV2/core/Modal';
import CloseButton from '../../../componentsV2/core/CloseButton';
import Heading from '../../atoms/Heading';
import Body from '../../atoms/Body';
import Button from '../../atoms/Button';

const useStyles = makeStyles(() => ({
  buttonWrapper: {
    display: 'flex',
    marginTop: '20px',
  },
  button: {
    width: '50%',
    '&:first-child': {
      marginRight: '16px',
    },
  },
}));

const CustomModal = ({
  isOpen,
  handleClose,
  handleConfirm,
  heading,
  leftButton,
  rightButton,
  bodyHeader,
  body,
}) => {
  const classes = useStyles();
  return (
    <Modal open={isOpen} handleClose={handleClose}>
      <CloseButton onClick={handleClose} />
      <Heading variant="h5">{heading}</Heading>
      {body && (
        <Body variant="body2">
          {bodyHeader && <b>{bodyHeader}</b>} {body}
        </Body>
      )}
      <div className={classes.buttonWrapper}>
        <Button
          color="secondary"
          onClick={handleClose}
          className={classes.button}
        >
          {leftButton}
        </Button>
        <Button onClick={handleConfirm} className={classes.button}>
          {rightButton}
        </Button>
      </div>
    </Modal>
  );
};

CustomModal.propTypes = {
  isOpen: bool.isRequired,
  handleClose: func.isRequired,
  handleConfirm: func.isRequired,
  heading: string.isRequired,
  body: string,
  bodyHeader: string,
  leftButton: string.isRequired,
  rightButton: string.isRequired,
};

CustomModal.defaultProps = {
  body: null,
  bodyHeader: null,
};

export default CustomModal;
