import { GENERAL, NOTIFICATIONS } from '../../../languages/en/settings';

import { PlanType } from './interfaces';

export const settingsTabButtons = [
  {
    id: 'general',
    label: GENERAL,
    planType: PlanType.FREE,
  },
  {
    id: 'notifications',
    label: NOTIFICATIONS,
    planType: PlanType.FREE,
  },
];
