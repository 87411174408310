export enum PlanType {
  FREE = 'free',
  PAID = 'paid',
}

export interface LinkTabProp {
  label: string;
  href: string;
  key: string;
  id: string;
  value: string;
}

export interface TabPanelProp {
  children: HTMLElement | JSX.Element | string;
  value: string;
  id: string;
}

export interface RewardsTabsProps {
  activeTab: string;
  isAdmin: boolean;
  isCultureRewardsEnabled?: boolean;
  isGiftCardsEnabled?: boolean;
  isCharitiesEnabled?: boolean;
}

export interface RouteTabButton {
  id: string;
  label: string;
  planType: PlanType;
}
export interface RouteTabsProps {
  routeTabButtons: RouteTabButton[];
  urlBase: string;
  handleTabChange?: (
    event: React.ChangeEvent<unknown>,
    newValue: string,
  ) => void;
  isFixed?: boolean;
}
