import React from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';

import ThemeV2 from '../../../../componentsV2/theme';
import { IMemberDTO } from '../../../../interfaces/member';
import { MemberState } from '../../../../interfaces/user';
import {
  FIND_MEMBERS,
  NO_MEMBERS_FOUND_THAT_MATCH_YOUR_CRITERIA,
  NO_MEMBERS_FOUND_THAT_MATCH_YOUR_CRITERIA_INVITE,
  PLEASE_ENTER_MORE_THAN_2_CHARACTERS,
} from '../../../../languages/en/home/mainFeedDrawer';

import Accordion from '../../../molecules/Accordion';
import Menu from '../../../molecules/Menu';
import MenuList from '../../../molecules/MenuItem';
import TextField from '../../../molecules/TextField_V2';
import SVGIcon from '../../../atoms/SVGIcon';
import MembersListEntryLoader from '../../../molecules/MembersListEntry/Loader';
import Body from '../../../atoms/Body';
import {
  FLOW_LEFT_DRAWER_ADD,
  FLOW_LEFT_DRAWER_TEAMMATES,
} from '../../../../languages/en/flows';

export interface PeopleMenuAccordionProps {
  isAccordionOpen: boolean;
  onAccordionClick: () => void;
  accordionTitle: string;
  lists: IMemberDTO[];
  canInviteMembers: boolean;
  membersSearchValue: string;
  handleEntryClick: (firstName: string, memberId: string) => void;
  toggleInviteModal: () => void;
  onMembersSearchChange: (newValue: string) => void;
  hasMoreMembersToFetch?: boolean;
  searchedMembers?: IMemberDTO[];
  className?: string;
  isHovered?: boolean;
  disabled?: boolean;
}

const StyledMenuList = styled(MenuList.Item)`
  margin-bottom: 4px;
  &:last-child {
    margin-bottom: 0px;
  }
`;

const StyledMenu = styled(Menu)`
  padding-right: 16px;
`;

const StyledTextField = styled(TextField)<{ isHovered: boolean }>`
  margin-bottom: 8px;
  max-width: 100%;
  margin-left: 16px;
  div > div {
    border: ${({ isHovered }) =>
      isHovered ? `1px solid ${ThemeV2.palette.gray5}` : 'none'};
    transition: all 0.5s;
  }
`;

const Wrapper = styled.div`
  position: relative;
  max-width: 100%;
  height: 40px;
  margin-bottom: 12px;
  margin-right: 16px;
`;

const StyledSVGIcon = styled(SVGIcon)`
  display: block;
  position: absolute;
  left: 25px;
  top: 12px;
`;

const AccordionHeader = ({
  title,
  isAccordionOpen,
  isExpanded,
  canInviteMembers,
  toggleInviteModal,
  disabled,
}: {
  title: string;
  isAccordionOpen: boolean;
  isExpanded: boolean;
  canInviteMembers: boolean;
  toggleInviteModal: () => void;
  disabled?: boolean;
}) => {
  return (
    <StyledMenu
      prefix="people"
      icon="caret-rounded"
      text={title}
      rotate={isAccordionOpen}
      isExpanded={isExpanded}
      showAddButton={canInviteMembers}
      onAddButtonClick={toggleInviteModal}
      addButtonText={`${FLOW_LEFT_DRAWER_ADD} ${FLOW_LEFT_DRAWER_TEAMMATES}`}
      disabled={disabled}
    />
  );
};

const PeopleMenuAccordion = (props: PeopleMenuAccordionProps) => {
  const {
    accordionTitle,
    isAccordionOpen,
    onAccordionClick,
    lists,
    onMembersSearchChange,
    membersSearchValue,
    canInviteMembers,
    searchedMembers,
    toggleInviteModal,
    handleEntryClick,
    hasMoreMembersToFetch,
    className,
    isHovered = false,
    disabled,
  } = props;

  return (
    <Accordion
      isAccordionOpen={isAccordionOpen}
      accordionHeader={
        <AccordionHeader
          title={accordionTitle}
          isAccordionOpen={isAccordionOpen}
          isExpanded={isHovered}
          canInviteMembers={canInviteMembers}
          toggleInviteModal={toggleInviteModal}
          disabled={disabled}
        />
      }
      onAccordionClick={() => {
        if (!disabled) {
          onAccordionClick();
        }
      }}
      className={className}
      disabled={disabled}
    >
      <Wrapper>
        <AnimatePresence>
          {!isHovered && (
            <motion.div
              initial={{ opacity: 0.5 }}
              exit={{ opacity: 0.5, transition: { duration: 0.5 } }}
              animate={{
                opacity: 1,
                transition: { duration: 0.1 },
              }}
            >
              <StyledSVGIcon
                icon="search"
                size="16px"
                color={ThemeV2.palette.gray6}
              />
            </motion.div>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {isHovered && (
            <motion.div
              initial={{ width: 0, opacity: 0 }}
              exit={{
                width: '60px',
                opacity: 0,
                transition: { duration: 0.1 },
              }}
              animate={{
                width: '100%',
                opacity: 1,
                transition: { duration: 0.5 },
              }}
            >
              <StyledTextField
                onChange={onMembersSearchChange}
                placeholder={FIND_MEMBERS}
                value={membersSearchValue}
                icon="search"
                isHovered={isHovered}
                disabled={disabled}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </Wrapper>
      {membersSearchValue.length > 0 && (
        <>
          {!searchedMembers && membersSearchValue.length > 2 && (
            <MembersListEntryLoader isLeftNavbar />
          )}
          {membersSearchValue.length < 3 && (
            <>
              <Body variant="body3">{PLEASE_ENTER_MORE_THAN_2_CHARACTERS}</Body>
              <MenuList>
                {canInviteMembers ? (
                  <StyledMenuList
                    onClick={toggleInviteModal}
                    isExpanded={isHovered}
                  >
                    <MenuList.IconButton icon="union-rounded" size="16px" />
                    <MenuList.Text>
                      {FLOW_LEFT_DRAWER_ADD} {FLOW_LEFT_DRAWER_TEAMMATES}
                    </MenuList.Text>
                  </StyledMenuList>
                ) : (
                  <></>
                )}
              </MenuList>
            </>
          )}
          {!canInviteMembers &&
            membersSearchValue.length > 2 &&
            searchedMembers &&
            searchedMembers.length === 0 && (
              <Body variant="body3">
                {NO_MEMBERS_FOUND_THAT_MATCH_YOUR_CRITERIA}
              </Body>
            )}
          {canInviteMembers &&
            membersSearchValue.length > 2 &&
            searchedMembers &&
            searchedMembers.length === 0 && (
              <Body
                variant="body3"
                style={{ paddingLeft: '16px', textAlign: 'center' }}
              >
                {`${NO_MEMBERS_FOUND_THAT_MATCH_YOUR_CRITERIA_INVITE}`}
              </Body>
            )}
          {searchedMembers &&
            membersSearchValue.length > 2 &&
            searchedMembers.length > 0 && (
              <MenuList>
                <>
                  {searchedMembers.map((list) => (
                    <StyledMenuList
                      key={list.memberID}
                      onClick={() =>
                        handleEntryClick(list.firstName, list.memberID)
                      }
                      isExpanded={isHovered}
                    >
                      {list.memberState === MemberState.PENDING ? (
                        <MenuList.Avatar icon="pending-person" size="24px" />
                      ) : (
                        <MenuList.Avatar
                          img={list.image || undefined}
                          userId={list.memberID}
                          size="24px"
                        />
                      )}
                      <MenuList.Text>{`${list.firstName} ${list.lastName}`}</MenuList.Text>
                    </StyledMenuList>
                  ))}
                </>
                {canInviteMembers ? (
                  <StyledMenuList
                    onClick={toggleInviteModal}
                    isExpanded={isHovered}
                  >
                    <MenuList.IconButton icon="union-rounded" size="16px" />
                    <MenuList.Text>
                      {FLOW_LEFT_DRAWER_ADD} {FLOW_LEFT_DRAWER_TEAMMATES}
                    </MenuList.Text>
                  </StyledMenuList>
                ) : (
                  <></>
                )}
              </MenuList>
            )}
        </>
      )}
      {membersSearchValue.length === 0 && lists.length > 0 && (
        <>
          <MenuList>
            <>
              {lists.map((list) => (
                <StyledMenuList
                  key={list.memberID}
                  onClick={() =>
                    handleEntryClick(list.firstName, list.memberID)
                  }
                  isExpanded={isHovered}
                  disabled={disabled}
                >
                  {list.memberState === MemberState.PENDING ? (
                    <MenuList.Avatar icon="pending-person" size="24px" />
                  ) : (
                    <MenuList.Avatar
                      img={list.image || undefined}
                      name={`${list.firstName} ${list.lastName}`}
                      userId={list.memberID}
                      size="24px"
                    />
                  )}

                  <MenuList.Text>{`${list.firstName} ${list.lastName}`}</MenuList.Text>
                </StyledMenuList>
              ))}
            </>
            {hasMoreMembersToFetch ? (
              <MembersListEntryLoader isLeftNavbar />
            ) : (
              <></>
            )}
            {canInviteMembers ? (
              <StyledMenuList
                onClick={toggleInviteModal}
                isExpanded={isHovered}
              >
                <MenuList.IconButton icon="union-rounded" size="16px" />
                <MenuList.Text>
                  {FLOW_LEFT_DRAWER_ADD} {FLOW_LEFT_DRAWER_TEAMMATES}
                </MenuList.Text>
              </StyledMenuList>
            ) : (
              <></>
            )}
          </MenuList>
        </>
      )}
    </Accordion>
  );
};

export default PeopleMenuAccordion;
