import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import ExternalFlowsController from '../flows/ExternalFlowsController';

import {
  EXTERNAL_FLOW_PATH,
  EXTERNAL_PATH_COMPLETION,
  EXTERNAL_TEMPLATE_PATH,
  V2_HOME,
} from '../../constants/routes';
import ExternalFlowsCompletionController from '../flows/ExternalFlowsCompletionController';

const ExternalRoutesController = () => {
  return (
    <>
      <Switch>
        <Route path={EXTERNAL_FLOW_PATH} component={ExternalFlowsController} />
        <Route
          path={EXTERNAL_TEMPLATE_PATH}
          component={ExternalFlowsController}
        />
        <Route
          path={EXTERNAL_PATH_COMPLETION}
          component={ExternalFlowsCompletionController}
        />
        <Redirect to={V2_HOME} />
      </Switch>
    </>
  );
};

export default ExternalRoutesController;
