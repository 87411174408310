import {
  ExternalFlowCreatorDetails,
  ExternalFlowDetailsResponse,
  useSubmitExternalFlowInstanceMutation,
} from '../../../../queries/Flows/ExternalFlows';
import useLoadedParticipationFlowController, {
  ExternalFlowDetails,
} from '../../ParticipationFlowController/useLoadedParticipationFlowController';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { profileData } from '../../../../Utils/home/feeds/dummyData';
import {
  AnonymityStates,
  FlowInstanceResponse,
} from '../../../../queries/Flows/interfaces';
import { FlowSubmissionDetails } from '../../../../interfaces/Flow';
import useUppyInstances from '../../ParticipationFlowController/useUppyInstances';
import { deleteAttachmentFile } from '../../../../Utils/flows';
import { isBlockContentValid } from '../../../../hooks/useParticipationFlow/utils';
import { formatBlockResponses } from '../../ParticipationFlowController/utils';
import useGoogleEnterpriseRecaptcha, {
  GoogleReCaptchaActionTypes,
} from '../../../../hooks/useGoogleEnterpriseRecaptcha';
import { GOOGLE_RECAPTCHA_SITE_KEY } from '../../../../config';
import { GET_EXTERNAL_FILE_UPLOAD_SIGNED_URL } from '../../../../constants/endpoints';
import { AxiosError } from 'axios';
import {
  ExternalParticipationTemplateErrorTypes,
  ParticipationTemplateErrorTypes,
} from '../../../../atomic/pages/ParticipationTemplate/types';
import { showErrorMessage } from '../../../../Utils/toast';
import { ButtonColors } from '../../../../atomic/atoms/Button/interfaces';
import { EXTERNAL_PATH_COMPLETION } from '../../../../constants/routes';
import { useHistory } from 'react-router-dom';
import { getErrorMessageAndType } from './util';

export type ExternalErrorTypes =
  | {
      type: 'external' | 'participation';
      errorMessage:
        | ExternalParticipationTemplateErrorTypes
        | ParticipationTemplateErrorTypes;
      buttonType: ButtonColors;
    }
  | undefined;

const useLoadedExternalFlowsController = (prop: {
  flowId: string;
  flow: FlowInstanceResponse & ExternalFlowDetailsResponse;
  creator: ExternalFlowCreatorDetails;
  identifier: string;
  errorType: string | undefined;
}) => {
  const { flow, creator, flowId, identifier, errorType } = prop;
  const profileInfo = profileData;
  const toggleParticipationModalOpen = () => {};
  const [submitErrors, setSubmitErrors] =
    useState<ExternalErrorTypes>(undefined);

  const [customError, setCustomError] = useState<
    ParticipationTemplateErrorTypes | undefined
  >(undefined);

  const { mutate, isLoading: isMutationLoading } =
    useSubmitExternalFlowInstanceMutation(flowId, identifier);

  const [isSubmitInProgress, setSubmitInProgress] = useState(false);
  const history = useHistory();
  const { loading, generateToken } = useGoogleEnterpriseRecaptcha(
    GOOGLE_RECAPTCHA_SITE_KEY,
    GoogleReCaptchaActionTypes.EXTERNAL_PARTICIPATION_SUBMISSION,
  );

  const formatPayload = useCallback(
    (submittedValues: Record<string, any>) =>
      formatBlockResponses(
        submittedValues.values,
        flow as FlowInstanceResponse,
        false,
        flow.responseSettings.anonymity.state === AnonymityStates.ENABLED,
        true,
      ),
    [flow],
  );

  useEffect(() => {
    const error = getErrorMessageAndType(errorType || '');
    if (error) setSubmitErrors(error);
  }, [errorType]);

  const onFlowSubmit = async (val: FlowSubmissionDetails) => {
    setSubmitInProgress(true);
    const captchaToken = await generateToken();
    const payload = {
      ...formatPayload(val),
      captchaToken,
    };
    mutate(payload, {
      onError: (error: unknown) => {
        setSubmitInProgress(false);
        const errorMessage = (error as AxiosError).response?.data.message;
        if (
          errorMessage !==
          ParticipationTemplateErrorTypes.INVALID_BLOCK_PARAMETERS
        ) {
          setCustomError(errorMessage as ParticipationTemplateErrorTypes);
        }
        /* Will be replaced with actual event */
        // trackParticipationFlow(
        //   PARTICIPATION_ANALYTICS_EVENTS.ERRORED,
        //   currentStep,
        //   ACTION_EVENTS.ERROR,
        // );
        const submitError = getErrorMessageAndType(errorMessage);
        if (submitError) setSubmitErrors(submitError);
        if (
          errorMessage !==
          ParticipationTemplateErrorTypes.NO_ACTIVE_OCCURRENCE_FOUND
        )
          showErrorMessage(errorMessage);
      },
      onSuccess: () => {
        setSubmitInProgress(false);
        history.push(`${EXTERNAL_PATH_COMPLETION}?flowId=${flowId}`);
      },
    });
  };

  const externalFlowDetails: ExternalFlowDetails = useMemo(() => {
    return {
      name: flow?.name,
      description: flow?.description,
      kind: flow?.kind,
      icon: flow?.icon,
      schedule: flow?.schedule,
      creator: creator,
      endTime: flow.endTime,
    };
  }, [flow, creator]);

  const {
    models: {
      blockErrors,
      currentStep,
      fieldErrors,
      hasVisitedLastStep,
      dynamicBlockData,
      values,
      stepData,
      containerRef,
      touched,
      isAnonymityEnabled,
      allowAnonymousResponse,
    },
    operations: {
      onFormCompleteClick,
      onStepChange,
      setFieldValue,
      goToNextStep,
      goToPreviousStep,
      handleModalClose,
      onNeedHelpButtonClick,
    },
  } = useLoadedParticipationFlowController(
    flow,
    toggleParticipationModalOpen,
    profileInfo,
    onFlowSubmit,
    externalFlowDetails,
  );

  const uppyInstances = useUppyInstances(
    flow.blocks,
    {
      flowId: flowId,
      instanceId: flow.instanceId,
      identifier,
    },
    GET_EXTERNAL_FILE_UPLOAD_SIGNED_URL,
  );

  const remainingAllowance = profileInfo.member.pointsLeftThisCycle;

  const handleDeleteFileClick = useCallback(
    async (fileName) => {
      const currentStepData = stepData[currentStep];
      const { id: blockId } = currentStepData;
      await deleteAttachmentFile({
        blockId,
        instanceId: flow.instanceId,
        fileName,
        flowId: flow.flowId,
      });
    },
    [currentStep, flow.flowId, flow.instanceId, stepData],
  );

  const isPostDisabled = !(
    stepData.filter((step) => isBlockContentValid(step, values[step.id]))
      .length > 0 && hasVisitedLastStep
  );

  return {
    models: {
      stepData,
      values,
      currentStep,
      isPostDisabled,
      remainingAllowance,
      uppyInstances,
      containerRef,
      touched,
      isAnonymityEnabled,
      allowAnonymousResponse,
      dynamicBlockData,
      fieldErrors,
      blockErrors,
      flowEndTime: flow?.endTime,
      customError,
      isMutationLoading,
      submitErrors,
      isSubmitInProgress,
      loadingCaptcha: loading,
    },
    operations: {
      handleDeleteFileClick,
      onFormCompleteClick,
      onStepChange,
      setFieldValue,
      goToNextStep,
      goToPreviousStep,
      handleModalClose,
      onNeedHelpButtonClick,
      getErrorMessageAndType,
    },
  };
};

export default useLoadedExternalFlowsController;
