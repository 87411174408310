import { AssemblyCurrency } from '../../../interfaces/assembly';
import { SwagCardStatuses } from '../../../queries/Admin/rewards/Swags/interface';

export enum RewardTypes {
  GiftCard = 'gift',
  Culture = 'culture',
  Charity = 'charity',
  Swags = 'swags',
}

export enum BackgroundSizeTypes {
  Cover = 'cover',
  Square = 'square',
}

export interface TrophyRangeItem {
  cost: string | number;
  trophies: string | number;
  currencyCode: string;
}

export interface RewardCardProps {
  id: string;
  rewardType: RewardTypes;
  image: string;
  backgroundSize?: BackgroundSizeTypes;
  title: string;
  assemblyCurrency: AssemblyCurrency;
  cost?: number | string;
  description: string;
  onRewardSelect: (id: string) => void;
  disabled?: boolean;
  rewardsCurrentAvailability?: number;
  trophyRange?: {
    range: TrophyRangeItem[];
  };
  isLimit?: boolean;
  isRewardCardDataLoading?: boolean;
  isSwagTabTurnedOff?: boolean;
  shippingCost?: number;
  status?: SwagCardStatuses;
}

export interface StyledComponentProps {
  image: string | undefined;
  backgroundSize: BackgroundSizeTypes;
  disabled?: boolean;
}
