import React from 'react';
import styled from 'styled-components';

import Body from '../Body';
import { CURRENT_MEMBERS } from '../../../languages/en/home/mainFeedDrawer';
import { formatNumberWithCommas } from '../../../Utils/formatting';
import ThemeV2 from '../../../componentsV2/theme';

export const CurrentMembersWrapper = styled.div`
  margin-top: 12px;
  margin-bottom: 8px;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 8px;
  border-radius: 4px;
  background-color: ${ThemeV2.palette.gray3};
`;

export interface CurrentMembersCountProps {
  count?: number;
}

const CurrentMembersCount = ({ count }: CurrentMembersCountProps) => (
  <CurrentMembersWrapper>
    <Body color="gray8" variant="body3Medium">
      {CURRENT_MEMBERS} &middot; {count && formatNumberWithCommas(count)}
    </Body>
  </CurrentMembersWrapper>
);

export default CurrentMembersCount;
