import styled from 'styled-components';

import Body from '../../atoms/Body';
import ThemeV2 from '../../../componentsV2/theme';
import { Flex } from '../../../Utils/styles/display';

import { FLOW_POST_MAX_HEIGHT } from '../../../Utils/constants';

export const BlockWrapper = styled.div`
  margin-bottom: 24px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const FlowPostBodyWrapper = styled.div<{ isExpanded: boolean }>`
  padding: 16px;
  max-height: ${({ isExpanded }) => !isExpanded && `${FLOW_POST_MAX_HEIGHT}px`};
  overflow-y: hidden;
`;

export const StyledBody = styled(Body)`
  margin-bottom: 12px;
`;

export const StyledMultipleChoiceText = styled(Body)`
  margin-bottom: 4px;
`;

export const FlowPostBodyContainer = styled.div`
  position: relative;
`;

export const SeeFullPostFlexWrapper = styled(Flex)`
  width: 100%;
  bottom: -21px;
  padding-top: 69px;
  position: absolute;

  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.05) 10%,
    ${ThemeV2.palette.white} 60%,
    ${ThemeV2.palette.white} 90%
  );
`;
