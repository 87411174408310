import { useCallback, useMemo } from 'react';

import { useHistory } from 'react-router-dom';

import { useProfileInfoFetchQuery } from '../../../../queries/Profile';

import {
  useGetFeedQuery,
  useGetSearchFeedQuery,
  useRecognitionFeedUpdatePostReactionMutation,
  useSearchRecognitionFeedUpdatePostReactionMutation,
} from '../../../../queries/Feed';

import {
  FeedItemFromAPI,
  FeedProps,
  FeedSearchPayload,
  MemberInteractionType,
  PostFilterOptions,
  PostSortOptions,
} from '../../../../interfaces/Feed';

import {
  getFlowFeedUrl,
  mapFeedResponseToFeedProps,
} from '../../../../Utils/home/feeds';
import useLayoutStore from '../../../../stores/layoutStore';
import { useFeedReactions } from '../../../../hooks/useFeedReactions';
import { getFeedArrayFromQueryData } from '../../../../Utils/home/feeds/reactions';
import { LayoutStoreState } from '../../../../interfaces/Layout';

const layoutSelector = (state: LayoutStoreState) =>
  state.setMemberAndToggleRightAsideOpen;

const useGetFeedsForRecognition = (
  searchPayload: FeedSearchPayload,
  feedsSortValue: PostSortOptions,
  showSearchResults: boolean,
) => {
  const { data: profileData } = useProfileInfoFetchQuery();
  const history = useHistory();
  const {
    data: searchData,
    isLoading: isLoadingSearchPosts,
    hasNextPage: hasMoreSearchPosts,
    fetchNextPage: fetchNextSearchItems,
    isFetchingNextPage: isFetchingMoreSearchResult,
  } = useGetSearchFeedQuery(searchPayload, showSearchResults);

  const {
    data: feedData,
    isLoading: isLoadingFeeds,
    refetch: refetchFeedQuery,
    hasNextPage: hasMoreFeedPosts,
    fetchNextPage: fetchNextFeedPost,
    isFetchingNextPage: isFetchingMoreFeeds,
  } = useGetFeedQuery(
    PostFilterOptions.ALL,
    feedsSortValue,
    !showSearchResults,
  );

  const { mutate: mutatePostReaction } =
    useRecognitionFeedUpdatePostReactionMutation(
      PostFilterOptions.ALL,
      feedsSortValue,
    );

  const { mutate: mutateSearchPostReaction } =
    useSearchRecognitionFeedUpdatePostReactionMutation(searchPayload);

  const setMemberAndToggleRightAsideOpen = useLayoutStore(layoutSelector);

  const handleOnMemberClick = useCallback(
    (member: MemberInteractionType) => {
      setMemberAndToggleRightAsideOpen({
        memberId: member.memberID,
        firstName: member.firstName,
      });
    },
    [setMemberAndToggleRightAsideOpen],
  );

  const handleOnFlowClick = useCallback(
    (flow: { flowId: string }) => {
      history.push(getFlowFeedUrl(flow.flowId));
    },
    [history],
  );

  const feedArrayData: FeedItemFromAPI[] = useMemo(() => {
    return getFeedArrayFromQueryData(feedData);
  }, [feedData]);

  const searchArrayData: FeedItemFromAPI[] = useMemo(() => {
    return getFeedArrayFromQueryData(searchData);
  }, [searchData]);

  const feedProps: FeedProps[] = useMemo(() => {
    if (!feedData || !profileData) {
      return [];
    }

    return mapFeedResponseToFeedProps(
      feedData,
      profileData,
      handleOnMemberClick,
      handleOnFlowClick,
    );
  }, [feedData, profileData, handleOnMemberClick, handleOnFlowClick]);

  const searchFeedProps: FeedProps[] = useMemo(() => {
    if (!searchData || !profileData) {
      return [];
    }

    return mapFeedResponseToFeedProps(
      searchData,
      profileData,
      handleOnMemberClick,
      handleOnFlowClick,
    );
  }, [searchData, profileData, handleOnMemberClick, handleOnFlowClick]);

  const { onReactionSet, onReactionUnSet } = useFeedReactions({
    profileData,
    feedData: feedArrayData,
    mutatePostReaction,
  });

  const {
    onReactionSet: onSearchPostsReactionSet,
    onReactionUnSet: onSearchPostsReactionUnSet,
  } = useFeedReactions({
    profileData,
    feedData: searchArrayData,
    mutatePostReaction: mutateSearchPostReaction,
  });

  if (showSearchResults) {
    return {
      isLoading: isLoadingSearchPosts,
      fetchNextPost: fetchNextSearchItems,
      feedProps: searchFeedProps,
      hasMorePosts: hasMoreSearchPosts,
      isFetchingMoreFeeds: isFetchingMoreSearchResult,
      onReactionSet: onSearchPostsReactionSet,
      onReactionUnSet: onSearchPostsReactionUnSet,
    };
  }

  return {
    isLoading: isLoadingFeeds,
    fetchNextPost: fetchNextFeedPost,
    feedProps,
    hasMorePosts: hasMoreFeedPosts,
    isFetchingMoreFeeds,
    onReactionSet,
    onReactionUnSet,
    refetchFeedQuery,
  };
};

export default useGetFeedsForRecognition;
