// @ts-nocheck TODO: type issues need to be fixed in this file
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

class LandingPage extends Component {
  render() {
    const { location } = this.props;
    if (this.props.userLoggedIn) {
      return <Redirect to="/home" />;
    }
    return (
      <Redirect
        to={{
          pathname: '/signin',
          state: location.state,
          search: location.search,
        }}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    userLoggedIn: state.auth.authenticated,
  };
}

export default connect(mapStateToProps)(LandingPage);
