import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import ThemeV2 from '../../../componentsV2/theme';
import {
  AvatarWrapper,
  StyledCard,
  StyledBody,
  StyledImage,
  StyledSVGCardIcon,
  StyledSVGTextIcon,
  CardTop,
  CardBottom,
  NameWrapper,
  StyledDropdownIconButton,
} from './styles';
import Body from '../../atoms/Body';
import Avatar from '../../atoms/Avatar';
import { Flex } from '../../../Utils/styles/display';
import { ButtonSize } from '../../atoms/IconButton_V2/interface';
import { menuItemsWithoutDeletePost } from './data';
import { useHistory } from 'react-router-dom';
import { V3_POST_PAGE } from '../../../constants/routes';
import { FeedAttachmentItemFromAPI } from '../../../interfaces/Feed';
import { assignFileTypeInfo } from '../../../Utils/file';

const KnowledgeCenterCard = (props: {
  card: FeedAttachmentItemFromAPI;
  pageIndex: number;
  cardIndex: number;
  handleSetSelectedFile: (value: FeedAttachmentItemFromAPI) => void;
  openPreviewer: Dispatch<SetStateAction<boolean>>;
  onClickDownload: (cardFile: FeedAttachmentItemFromAPI) => Promise<void>;
  copyPostLink: (flowId: string, responseId: string) => void;
}) => {
  const {
    card,
    pageIndex,
    cardIndex,
    handleSetSelectedFile,
    openPreviewer,
    onClickDownload,
    copyPostLink,
  } = props;
  const [cardColor, setCardColor] = useState('');
  const [cardIcon, setCardIcon] = useState('');
  const [isHovered, setIsHovered] = useState(false);

  const { push } = useHistory();

  useEffect(() => {
    assignFileTypeInfo(card.fileInfo?.type, setCardColor, setCardIcon);
  }, [card.fileInfo?.type]);

  const onOptionsSelect = (value: string | number) => {
    if (value === 'copy-link' && copyPostLink) {
      copyPostLink(card.response?.flowId, card.response?.responseId);
    }
    if (value === 'go-to-post') {
      push(
        `${V3_POST_PAGE}`.replace(
          ':postType',
          `flows?flowId=${card.response.flowId}&responseId=${card.response.responseId}`,
        ),
      );
    }
    if (value === 'download-file' && onClickDownload) {
      onClickDownload(card);
    }
    if (value === 'delete') {
      console.log('Not implemented on the BE yet.');
    }
  };

  return (
    <StyledCard
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => {
        openPreviewer(true);
        handleSetSelectedFile(card);
      }}
      data-testid={`knowledge-center-card-${pageIndex}-${cardIndex}`}
    >
      <CardTop backgroundColor={cardColor}>
        {cardIcon && cardIcon !== 'imageFile' && (
          <StyledSVGCardIcon icon={cardIcon} size="48px" />
        )}
        {isHovered && (
          <StyledDropdownIconButton
            color="tertiary"
            iconColor={ThemeV2.palette.gray8}
            menuItems={menuItemsWithoutDeletePost}
            icon={'more'}
            title={'file card options'}
            onItemClick={onOptionsSelect}
            size={ButtonSize.Normal}
            stopEventPropagation={true}
            dataTestId={`knowledge-center-card-${pageIndex}-${cardIndex}-dropdown`}
          />
        )}
        {card.fileInfo?.thumbnails && cardIcon === 'imageFile' && (
          <StyledImage
            alt={card.fileInfo?.name}
            src={card.fileInfo?.thumbnails['360']}
          />
        )}
      </CardTop>
      <CardBottom>
        <NameWrapper>
          {cardIcon && <StyledSVGTextIcon icon={cardIcon} size="16px" />}
          <StyledBody variant="body2Medium">
            {card.fileInfo?.name.split('.')[0]}
          </StyledBody>
          <Body variant="body2Medium">.{card.fileInfo?.type}</Body>
        </NameWrapper>
        <StyledBody
          color={ThemeV2.palette.gray8}
          variant="body3"
          style={{ marginTop: '4px' }}
        >
          <Flex>
            <AvatarWrapper>
              <Avatar
                img={card.respondent?.image}
                name={card.respondent?.name}
                userId={card.respondent?.memberID}
                size="16px"
                iconSize="16px"
                verticalAlign="baseline"
              />
            </AvatarWrapper>
            {card.respondent?.name}
          </Flex>
        </StyledBody>
      </CardBottom>
    </StyledCard>
  );
};

export default KnowledgeCenterCard;
