import styled from 'styled-components';
import Body from '../../atoms/Body';
import Heading from '../../atoms/Heading';

export const BlockSectionHeading = styled(Heading)`
  margin-bottom: 8px;
`;

export const BlockSectionSubHeading = styled(Body)<{ marginBottom?: string }>`
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? marginBottom : '24px'};
`;
