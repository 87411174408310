import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
//import Hidden from '@material-ui/core/Hidden';
import FlowDescriptionPopover from './flowDescriptionPopover';

//import { Flex } from '../../../Utils/styles/display';

//import Body from '../../atoms/Body';
import Button from '../../atoms/Button';
import ThemeV2 from '../../../componentsV2/theme';
import EmoticonHolder from '../../atoms/EmoticonHolder';
import {
  MORE_OPTIONS,
  RESTART_FLOW,
  //SEARCH_PLACEHOLDER_TEXT,
  //SEE_VISIBILITY_LABEL,
} from '../../../languages/en/flows';
import {
  getIsScheduledFlowEnded,
  //getVisibilityIcon,
} from '../../../Utils/flows';
import { DropdownPlacement } from '../Dropdown_V2/interfaces';
import { SHARE } from '../../../languages/en/singleWords';
import {
  NO_DESCRIPTION,
  FLOW_FEED_STATES,
  FLOW_REMIND_PARTICIPANTS,
} from '../../../languages/en/flows/feed';
import { EmoticonHolderSizes } from '../../atoms/EmoticonHolder/interface';

import { FlowsFeedHeaderProps } from './type';
import {
  FeedHeaderMainContentInnerWrapper,
  FlowsDetailsWrapper,
  Root,
  StyledBody,
  PopoverBody,
  StyledButtonContainer,
  StyledDropdownIconButton,
  StyledFlowsFeedHeaderMainContent,
  StyledHeading,
  //StyledSvgIcon,
  //StyledVisibiltyLabel,
  CopyLinkButton,
} from './styles';
//import UserListDropdownWithPagination from '../UserListDropdownWithPagination';
import { COPY_LINK } from '../../../languages/en/home';
import { useFeatureIntro, FeatureIntro } from '../../../hooks/useFeatureIntro';
import useLayoutStore from '../../../stores/layoutStore';
import shallow from 'zustand/shallow';
import { trackShareSheetActionEvent } from '../../../Utils/analytics/shareSheet';
import { SHARE_SHEET_EVENTS } from '../../../Utils/analytics/constants';
import { ButtonSize } from '../../atoms/IconButton_V2/interface';

const FlowsFeedHeader = (props: FlowsFeedHeaderProps) => {
  const {
    icon,
    title,
    //subTitle,
    description,
    //userDetails,
    menuOptions,
    isFlowOwner,
    //isFlowViewer,
    //membersCount,
    //onMemberClick,
    backgroundColor,
    openRunNowModel,
    onMenuOptionClick,
    hasActiveOccurrence,
    onClick,
    //onPopOverOpen,
    openRemindParticipantsModal,
    schedule,
    kind,
    openShareSheetModal,
    handleCopyFlowFeedLink,
  } = props;

  const { isRightAsideOpen } = useLayoutStore(
    (state) => ({
      isRightAsideOpen: state.isRightAsideOpen,
    }),
    shallow,
  );

  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  useFeatureIntro({
    feature: FeatureIntro.ACCESS_POLICY_SHARE_BUTTON,
    enabled:
      isFlowOwner === true && query.get('flow-created-confirmation') !== 'true',
  });

  const isScheduledFlowEnded = getIsScheduledFlowEnded({
    isFlowOwner,
    kind,
    hasActiveOccurrence,
    schedule,
  });

  const onRestartFlow = () => {
    const { pathname } = location;
    history.push(`${pathname}/editor`);
  };

  const handleOpenShareSheetModal = () => {
    if (openShareSheetModal) {
      trackShareSheetActionEvent({
        action: SHARE_SHEET_EVENTS.SHARE_SHEET_STARTED,
        shareFlowStartSource: 'flow header',
      });
      openShareSheetModal(true);
    }
  };

  const renderActionItem = () => {
    if (isFlowOwner) {
      if (!hasActiveOccurrence && kind === 'ONDEMAND') {
        return (
          <StyledButtonContainer>
            <Button
              icon={FLOW_FEED_STATES.FLOW_NOT_TRIGGERED.BUTTON_ICON}
              onClick={openRunNowModel}
              size="medium"
              color="secondary"
            >
              {FLOW_FEED_STATES.FLOW_NOT_TRIGGERED.BUTTON_TEXT}
            </Button>
            <Button
              icon="share"
              onClick={handleOpenShareSheetModal}
              size="medium"
              data-test-id="share-button"
              className="introjs-sharesheet-share-button"
            >
              {SHARE}
            </Button>
          </StyledButtonContainer>
        );
      } else if (hasActiveOccurrence && kind !== 'NO_TRIGGER') {
        return (
          <StyledButtonContainer>
            <Button
              icon={FLOW_REMIND_PARTICIPANTS.BUTTON_ICON}
              onClick={openRemindParticipantsModal}
              color="secondary"
              size="medium"
            >
              {FLOW_REMIND_PARTICIPANTS.BUTTON_TEXT}
            </Button>
            <Button
              icon="share"
              onClick={handleOpenShareSheetModal}
              size="medium"
              data-test-id="share-button"
              className="introjs-sharesheet-share-button"
            >
              {SHARE}
            </Button>
          </StyledButtonContainer>
        );
      } else {
        return (
          <StyledButtonContainer>
            {isScheduledFlowEnded && (
              <Button
                icon="sync"
                onClick={onRestartFlow}
                color="secondary"
                size="medium"
              >
                {RESTART_FLOW}
              </Button>
            )}
            <Button
              icon="share"
              onClick={handleOpenShareSheetModal}
              size="medium"
              data-test-id="share-button"
              className="introjs-sharesheet-share-button"
            >
              {SHARE}
            </Button>
          </StyledButtonContainer>
        );
      }
    }

    return !location.pathname.includes('recognition') ? (
      <StyledButtonContainer>
        <CopyLinkButton
          icon="copy"
          size="medium"
          color="secondary"
          data-test-id="copy-link-button"
          onClick={handleCopyFlowFeedLink}
        >
          {COPY_LINK}
        </CopyLinkButton>
      </StyledButtonContainer>
    ) : null;
  };

  return (
    <Root borderBottom={!isFlowOwner} isRightAsideOpen={isRightAsideOpen}>
      <EmoticonHolder
        emoticon={icon}
        size={EmoticonHolderSizes.Medium2}
        color={backgroundColor}
      />
      <StyledFlowsFeedHeaderMainContent>
        <FeedHeaderMainContentInnerWrapper>
          <StyledHeading variant="h6">{title}</StyledHeading>
          {menuOptions && (
            <StyledDropdownIconButton
              iconColor={ThemeV2.palette.gray8}
              dropdownPlacement={DropdownPlacement.BottomEnd}
              icon="more"
              size={ButtonSize.Small}
              menuItems={menuOptions}
              onItemClick={onMenuOptionClick}
              title={MORE_OPTIONS}
              dataTestId="flowFeedHeaderDropdownOptions"
              onClick={onClick}
            />
          )}
        </FeedHeaderMainContentInnerWrapper>
        <FlowsDetailsWrapper>
          {description ? (
            <FlowDescriptionPopover
              referenceContent={
                <StyledBody variant="body2" color="gray8" inline>
                  {description}
                </StyledBody>
              }
              popoverContent={
                <PopoverBody variant="body2" color="gray8" inline>
                  {description}
                </PopoverBody>
              }
            />
          ) : (
            <FlowDescriptionPopover
              referenceContent={
                <StyledBody variant="body2" color="gray7" inline>
                  {NO_DESCRIPTION}
                </StyledBody>
              }
              popoverContent={
                <PopoverBody variant="body2" color="gray7" inline>
                  {NO_DESCRIPTION}
                </PopoverBody>
              }
            />
          )}
        </FlowsDetailsWrapper>
      </StyledFlowsFeedHeaderMainContent>
      {renderActionItem()}
    </Root>
  );
};

export default FlowsFeedHeader;

/*
<>
  <Hidden only={['xs']}>
    <StyledBody variant="body3" color="gray7" inline>
      {subTitle}
      {membersCount && (
        <>
          {isFlowViewer && <span> • </span>}
          {isFlowViewer && `${membersCount} ${PARTICIPANTS}`}
          <span> • </span>
        </>
      )}
    </StyledBody>
  </Hidden>
  {userDetails && (
    <UserListDropdownWithPagination
      users={userDetails.users}
      isError={userDetails.isUsersError}
      placeholder={SEARCH_PLACEHOLDER_TEXT}
      canPopoverTriggerByClick={false}
      fetchMoreMembers={userDetails.fetchMoreMembers}
      hasMoreUsersToFetch={userDetails.hasMoreUsersToFetch}
      memberSearchValue={userDetails.memberSearchValue}
      visibility={userDetails.visibility.criteria}
      isLoading={userDetails.isUsersLoading}
      viewCount={userDetails.visibility.viewersCount}
      onMemberSearchValueChange={
        userDetails.onMemberSearchValueChange
      }
      onMemberClick={onMemberClick}
      onPopOverOpen={onPopOverOpen}
    >
      <Flex>
        <StyledVisibiltyLabel variant="body3" color="gray7">
          {SEE_VISIBILITY_LABEL}
        </StyledVisibiltyLabel>
        <Body variant="body3" color="gray7">
          <span>
            <StyledSvgIcon
              icon={getVisibilityIcon(
                userDetails.visibility.criteria,
              )}
              size="16px"
              color={ThemeV2.palette.gray7}
            />
          </span>
        </Body>
      </Flex>
    </UserListDropdownWithPagination>
  )}
</>
*/
