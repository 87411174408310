import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import StepsWizard from '../../organism/StepsWizard';
import InfoAlert from '../../molecules/InfoAlert';
import { Notification, NotificationType } from '../../../hooks/useNotification';

const useStyles = makeStyles({
  errorInfoAlert: {
    width: 'auto',
    marginBottom: '24px',
  },
});

const notificationIconMap: { [Key: string]: string } = {
  error: 'warning',
  info: 'info-circle',
};

export interface StepButton {
  onClick?(): void;
  disabled?: boolean;
  text?: string;
  hidden: boolean;
}

interface Step {
  id: string;
  title: string;
}

interface StepsWizardProps {
  steps: Step[];
  backgroundColor?: string;
  activeStep: string;
  nextButton: StepButton;
  leftButton: StepButton;
  cancelButton: StepButton;
  title: string;
  helperLink?: string;
}

export interface IdentityManagerFlowTemplateProps {
  children: React.ReactNode;
  stepsWizard: StepsWizardProps;
  notifications?: Notification[];
}

const IdentityManagerFlowTemplate = ({
  children,
  stepsWizard,
  notifications = [],
}: IdentityManagerFlowTemplateProps) => {
  const classes = useStyles();
  const {
    title,
    steps,
    activeStep,
    nextButton,
    cancelButton,
    leftButton,
    helperLink,
  } = stepsWizard;
  return (
    <StepsWizard
      title={title}
      steps={steps}
      activeStep={activeStep}
      nextButton={nextButton}
      cancelButton={cancelButton}
      leftButton={leftButton}
      helperLink={helperLink}
    >
      {notifications.length > 0 &&
        notifications.map(({ id, message, type, removeNotification }) => (
          <InfoAlert
            key={id}
            id={id}
            classes={{ root: classes.errorInfoAlert }}
            text={message}
            icon={notificationIconMap[type || NotificationType.INFO]}
            alertType={type || NotificationType.INFO}
            isDismissible={{
              onCloseClick: removeNotification,
              onCloseKeyPress: removeNotification,
            }}
          />
        ))}
      {children}
    </StepsWizard>
  );
};

export default IdentityManagerFlowTemplate;
