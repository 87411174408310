import styled from 'styled-components';
import Button from '../../../atomic/atoms/Button';
import { StyledBody } from '../../../atomic/molecules/ResponseBlock/styles';
import FlowFeedPost from '../../../atomic/organism/FlowFeedPost';
import theme from '../../../componentsV2/theme';

export const FeedsContainer = styled.main`
  max-width: 476px;
  margin: auto;
`;

export const StyledActionBarWrapper = styled.div`
  margin-bottom: 20px;
`;

export const ShowMoreButton = styled(Button)`
  position: sticky;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 2;
  margin-bottom: 8px;
`;

export const StyledFlowFeedPost = styled(FlowFeedPost)`
  ${StyledBody} {
    color: ${theme.palette.gray9};
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    margin-bottom: 12px;
  }
`;
