import React from 'react';
import { LeftNavOrganismProps } from './interface';
import { DrawerBottomContent, HomeDrawerWrapper } from './styles';

const HomeDrawer = (props: LeftNavOrganismProps) => {
  const { children, isHoveredWhenCollapsed, isKioskTurnedOn, handleScroll } =
    props;

  return (
    <HomeDrawerWrapper isKioskTurnedOn={isKioskTurnedOn}>
      <DrawerBottomContent
        isHoveredWhenCollapsed={isHoveredWhenCollapsed}
        onScroll={handleScroll}
      >
        {children}
      </DrawerBottomContent>
    </HomeDrawerWrapper>
  );
};

export default HomeDrawer;
