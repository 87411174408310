import { useFormik } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  DropdownValue,
  ShippingStatus,
} from '../../../atomic/organism/RedeemSelectModal/interface';
import { RATING_EXTERNAL_LINKS } from '../../../Utils/rewards/urls';
import { openInNewTab } from '../../../Utils/window';
import * as Yup from 'yup';
import { State } from 'country-state-city';
import { AutocompleteDropdownItem } from '../../../atomic/organism/Autocomplete/interfaces';
import { useHistory } from 'react-router-dom';
import { useAxomoPlaceOrder } from '../../../queries/Admin/rewards/Swags';
import { AxomoRedeemReward } from '../../../queries/Rewards/interfaces';
import { MenuItemProps } from '../../../atomic/molecules/Dropdown_V2/interfaces';
import { showErrorMessage } from '../../../Utils/toast';
import { useQueryClient } from 'react-query';
import { GET_MY_REWARDS } from '../../../constants/endpoints';
import { AxomoShippingCountry } from '../../../queries/Admin/rewards/Swags/interface';
import { PLACE_ORDER_FAILED } from '../../../languages/en/rewards';
import {
  ALPHABETS_WITH_SPACES_REGEX,
  getFirstLastName,
} from '../../../Utils/text';
import { trackAxomoActionEvent } from '../../../Utils/analytics/axomo';
import { AXOMO_EVENTS } from '../../../Utils/analytics/constants';

const useSwagsRedeemModalController = (
  redeemRewardData: AxomoRedeemReward,
  quantityDropdownValues: MenuItemProps[],
  axomoShippingCountries: AxomoShippingCountry[] | undefined,
) => {
  const history = useHistory();
  const [isRewardRated, setIsRewardRated] = useState(false);
  const [hasRedeemed, setHasRedeemed] = useState(false);
  const [shippingStatus, setShippingStatus] = useState(
    ShippingStatus.SWAG_DETAILS,
  );
  const [secondaryModalOpen, setSecondaryModalOpen] = useState(false);
  const [size, setSize] = useState<DropdownValue | undefined>(undefined);
  const [quantity, setQuantity] = useState<DropdownValue | undefined>(
    undefined,
  );
  const [color, setColor] = useState<DropdownValue | undefined>(undefined);
  const availableQuantities = useMemo(() => {
    return quantityDropdownValues[0].items.map((item) => {
      return {
        name: item.value,
        title: item.value,
        id: item.id.toString(),
        key: item.id.toString(),
      };
    });
  }, [quantityDropdownValues]);

  const sizes = useMemo(() => {
    if (redeemRewardData.sizes) {
      return redeemRewardData.sizes.map((swagSize) => {
        return {
          name: swagSize.name,
          title: swagSize.name,
          id: swagSize.id,
          key: swagSize.id,
        };
      });
    }
    return [];
  }, [redeemRewardData.sizes]);
  const colors = useMemo(() => {
    return redeemRewardData.colors.map((swagColor) => {
      return {
        name: swagColor.name,
        title: swagColor.name,
        id: swagColor.id,
        key: swagColor.id,
      };
    });
  }, [redeemRewardData.colors]);

  const handleSizeChange = useCallback((selectedSize) => {
    setSize(selectedSize);
  }, []);

  const handleQuantityChange = useCallback((selectedQty) => {
    setQuantity(selectedQty);
  }, []);

  const handleColorChange = useCallback((selectedColor) => {
    setColor(selectedColor);
  }, []);

  const swagSizeProps = {
    size,
    sizes,
    handleSizeChange,
  };

  const swagQuantityProps = {
    quantity,
    availableQuantities,
    handleQuantityChange,
  };

  const swagColorProps = {
    color,
    colors,
    handleColorChange,
  };

  const onSecondaryModalClose = useCallback(() => {
    setSecondaryModalOpen(false);
  }, []);

  const { mutate: axomoPlaceOrderMutate, isLoading: isPlaceOrderLoading } =
    useAxomoPlaceOrder();

  const queryClient = useQueryClient();

  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required('Fullname is Required'),
    address: Yup.string().required('Address is Required'),
    city: Yup.string()
      .required('City is Required')
      .matches(ALPHABETS_WITH_SPACES_REGEX, 'Enter a valid city name'),
    state: Yup.object().shape({
      title: Yup.string().required('State is Required'),
    }),
    country: Yup.object().shape({
      title: Yup.string().required('Country is Required'),
    }),
    phoneNumber: Yup.number()
      .typeError('Phone number can only contain numbers')
      .positive("A phone number can't start with a minus")
      .integer("A phone number can't include a decimal point")
      .min(8),
    pincode: Yup.number()
      .typeError('Zip code can only contain numbers')
      .required('Zipcode is Required'),
  });

  const countriesData: AutocompleteDropdownItem<string>[] = useMemo(() => {
    if (axomoShippingCountries) {
      return axomoShippingCountries?.map((country) => {
        return { title: country.shortName, id: country.countryCode };
      });
    }
    return [{ title: 'United States', id: 'US' }];
  }, [axomoShippingCountries]);

  const shippingFormik = useFormik({
    initialValues: {
      country: { title: 'United States', id: 'US' },
      fullName: '',
      companyName: '',
      address: '',
      apartment: '',
      city: '',
      state: { title: '', id: '' },
      pincode: '',
      phoneNumber: '',
    },
    validateOnBlur: true,
    onSubmit: () => {},
    validationSchema: validationSchema,
  });

  const statesData = useMemo(() => {
    if (
      !shippingFormik.values.country ||
      shippingFormik.values.country.id === ''
    ) {
      return [];
    } else {
      return State.getStatesOfCountry(shippingFormik.values.country.id).map(
        (state) => {
          return { title: state.name, id: state.isoCode };
        },
      );
    }
  }, [shippingFormik.values.country]);

  useEffect(() => {
    if (
      !shippingFormik.values.country ||
      shippingFormik.values.country.id === ' '
    ) {
      shippingFormik.setFieldValue('country', { title: '', id: '' });
      shippingFormik.setFieldValue('state', { title: '', id: '' });
    } else {
      shippingFormik.setFieldValue('fullName', '');
      shippingFormik.setFieldValue('companyName', '');
      shippingFormik.setFieldValue('address', '');
      shippingFormik.setFieldValue('city', '');
      shippingFormik.setFieldValue('pincode', '');
      shippingFormik.setFieldValue('phoneNumber', '');
      shippingFormik.setFieldValue('state', { title: '', id: '' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shippingFormik.values.country]);

  const isCityOrStateError =
    ('city' in shippingFormik.errors && 'city' in shippingFormik.touched) ||
    ('state' in shippingFormik.errors && 'state' in shippingFormik.touched);

  const handleContinueShippingClick = () => {
    setShippingStatus(ShippingStatus.SHIPPING_ADDRESS);
  };

  const handleNextButtonClick = useCallback(() => {
    setShippingStatus(ShippingStatus.ORDER_DETAILS_CONFIRMATION);
  }, []);

  const handleGoBackButtonClick = useCallback(() => {
    if (shippingStatus === ShippingStatus.SHIPPING_ADDRESS) {
      setShippingStatus(ShippingStatus.SWAG_DETAILS);
    } else {
      setShippingStatus(ShippingStatus.SHIPPING_ADDRESS);
    }
  }, [shippingStatus]);

  const [firstName, lastName] = getFirstLastName(
    shippingFormik.values.fullName,
  );

  const handlePlaceOrderClick = useCallback(() => {
    axomoPlaceOrderMutate(
      {
        storeUID: redeemRewardData.storeUID,
        uid: redeemRewardData.uid,
        size: {
          id: size?.id || '',
          name: size?.name || '',
        },
        quantity: parseInt(quantity?.id || '') || 0,
        company: shippingFormik.values.companyName,
        color: {
          id: color?.id || '',
          name: color?.name || '',
        },
        shipping: {
          method: null,
          tier: 0,
          address: {
            firstName: firstName,
            lastName: lastName,
            addressLine1: shippingFormik.values.address,
            addressLine2: shippingFormik.values.apartment,
            zipCode: shippingFormik.values.pincode,
            country: {
              name: shippingFormik.values.country.title,
              code: shippingFormik.values.country.id,
            },
            city: {
              name: shippingFormik.values.city,
              code: shippingFormik.values.city,
            },
            state: {
              name: shippingFormik.values.state.title,
              code: shippingFormik.values.state.id,
            },
            phone: shippingFormik.values.phoneNumber,
          },
        },
      },
      {
        onSuccess: () => {
          setShippingStatus(ShippingStatus.SUCCESSFULLY_REDEEMED);
          setHasRedeemed(true);
          queryClient.invalidateQueries(GET_MY_REWARDS);
          trackAxomoActionEvent({
            action: AXOMO_EVENTS.SWAG_ORDERED,
            swagItem: redeemRewardData.name,
            shippingCost: redeemRewardData.shippingCost.value,
            swagItemCost: redeemRewardData.points[0],
            swagItemQuantity: parseInt(quantity?.id || '') || 0,
          });
        },
        onError: () => {
          showErrorMessage(PLACE_ORDER_FAILED);
        },
      },
    );
  }, [
    axomoPlaceOrderMutate,
    redeemRewardData,
    size?.id,
    size?.name,
    quantity?.id,
    shippingFormik.values.companyName,
    shippingFormik.values.address,
    shippingFormik.values.apartment,
    shippingFormik.values.pincode,
    shippingFormik.values.country?.title,
    shippingFormik.values.country?.id,
    shippingFormik.values.city,
    shippingFormik.values.state.title,
    shippingFormik.values.state.id,
    shippingFormik.values.phoneNumber,
    color?.id,
    color?.name,
    firstName,
    lastName,
    queryClient,
  ]);

  const handlePrimaryButtonClick = useCallback(() => {
    switch (shippingStatus) {
      case ShippingStatus.SWAG_DETAILS:
        return handleContinueShippingClick();
      case ShippingStatus.SHIPPING_ADDRESS:
        return handleNextButtonClick();
      case ShippingStatus.ORDER_DETAILS_CONFIRMATION:
        return handlePlaceOrderClick();
      default:
        return;
    }
  }, [handleNextButtonClick, handlePlaceOrderClick, shippingStatus]);

  const handleSecondaryButtonClick = () => {
    setSecondaryModalOpen(true);
  };

  const handleRewardRatingChange = useCallback((newRating: number) => {
    if (newRating) {
      if (newRating > 3) {
        const randomIndex = Math.floor(
          Math.random() * RATING_EXTERNAL_LINKS.length,
        );
        openInNewTab(RATING_EXTERNAL_LINKS[randomIndex]);
      }
    }
    setIsRewardRated(true);
  }, []);

  const handleMyRewardsButtonClick = useCallback(() => {
    history.push('/rewards/my-rewards');
  }, [history]);

  const onPlaceOrder = useCallback(() => {
    setHasRedeemed(true);
  }, []);

  const modelMethods = {
    handleRewardRatingChange,
    onActionButtonClick: onPlaceOrder,
  };

  return {
    countriesData,
    statesData,
    modelMethods,
    hasRedeemed,
    isRewardRated,
    shippingStatus,
    shippingFormik,
    secondaryModalOpen,
    isCityOrStateError,
    swagSizeProps,
    swagQuantityProps,
    swagColorProps,
    onSecondaryModalClose,
    handlePrimaryButtonClick,
    handleSecondaryButtonClick,
    handleMyRewardsButtonClick,
    handleGoBackButtonClick,
    isPlaceOrderLoading,
    firstName,
    lastName,
  };
};

export default useSwagsRedeemModalController;
