import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import {
  getFlowFeedUrl,
  mapPostToPostProps,
} from '../../../../Utils/home/feeds';
import useLayoutStore from '../../../../stores/layoutStore';
import { FeedProps, MemberInteractionType } from '../../../../interfaces/Feed';
import {
  useGetSinglePostQuery,
  useSingleRecognitionUpdatePostReactionMutation,
} from '../../../../queries/Feed';
import { useProfileInfoFetchQuery } from '../../../../queries/Profile';
import { useFeedReactions } from '../../../../hooks/useFeedReactions';
import routesList from '../../../../aV2/routes/routesList';
import { LayoutStoreState } from '../../../../interfaces/Layout';

const layoutSelector = (state: LayoutStoreState) =>
  state.setMemberAndToggleRightAsideOpen;

const useLegacySingleFlowFeedPostController = () => {
  const { search } = useLocation();
  const postId = new URLSearchParams(search).get('postId') || '';
  const history = useHistory();

  const {
    isLoading: isSinglePostLoading,
    isError,
    data,
  } = useGetSinglePostQuery(postId);

  const { mutate: mutateSinglePostReaction } =
    useSingleRecognitionUpdatePostReactionMutation(postId);

  const { data: profileData, isLoading: isProfileInfoLoading } =
    useProfileInfoFetchQuery();

  const { onReactionSet, onReactionUnSet } = useFeedReactions({
    profileData,
    feedData: data ? [data] : [],
    mutatePostReaction: mutateSinglePostReaction,
  });

  const setMemberAndToggleRightAsideOpen = useLayoutStore(layoutSelector);

  const isLoading = isSinglePostLoading || isProfileInfoLoading;

  const handleOnMemberClick = useCallback(
    (member: MemberInteractionType) => {
      setMemberAndToggleRightAsideOpen({
        memberId: member.memberID,
        firstName: member.firstName,
      });
    },
    [setMemberAndToggleRightAsideOpen],
  );

  const onMemberClick = useCallback(
    (person: MemberInteractionType) => {
      setMemberAndToggleRightAsideOpen({
        memberId: person.memberID,
        firstName: person.firstName,
      });
    },
    [setMemberAndToggleRightAsideOpen],
  );

  const handleOnFlowClick = useCallback(
    (flow: { flowId: string }) => {
      history.push(getFlowFeedUrl(flow.flowId));
    },
    [history],
  );

  const feedProp: FeedProps | undefined = useMemo(() => {
    if (!data || !profileData) {
      return undefined;
    }

    return mapPostToPostProps(
      data,
      profileData,
      handleOnMemberClick,
      handleOnFlowClick,
    );
  }, [data, profileData, handleOnMemberClick, handleOnFlowClick]);

  const handlePostDelete = useCallback(() => {
    history.push(routesList.HOME);
  }, [history]);

  return {
    isError,
    isLoading,
    onMemberClick,
    flowFeedPostProps: feedProp,
    profileData,
    onReactionSet,
    onReactionUnSet,
    onPostDelete: handlePostDelete,
  };
};

export default useLegacySingleFlowFeedPostController;
