import styled from 'styled-components';
import Link from '../../../atoms/Link_V2';
import InfoAlert from '../../../molecules/InfoAlert';

export const StyledLink = styled(Link)`
  font-size: 16px;
  line-height: 24px;
`;

export const StyledInfoAlert = styled(InfoAlert)`
  padding: 6px 8px;
  margin-bottom: 16px;
  align-items: center;

  div:first-child {
    margin-right: 4px;
    margin-top: 0;
  }
`;
