import { useCallback, useEffect, useState } from 'react';
import {
  GET_IM_SETUP_JOB,
  GET_IM_CONFIG,
  IM_AUTHORIZE,
  GET_IM_INTEGRATION_DATA,
} from '../../constants/endpoints';
import { GET_IDENTITY_MANAGER_DATA } from '../useFetch/endpoints';
import { UseIdentityManagerProps } from '../../interfaces/identityManager/common';
import useFetch from '../useFetch';
import useIdentityManager from '../useIdentityManager';
import useJobPollingV2 from '../useJobPollingV2';

export interface WorkatoEventMessageData {
  type: string;
  payload: {
    id: number;
    provider: string;
    connected: boolean;
  };
}

const useWorkatoIdentityManager = (props: UseIdentityManagerProps) => {
  const { identityManager } = props;
  const { id } = identityManager;
  const { models: useIMModels, operations: useIMOperations } =
    useIdentityManager(props);
  const { handleError } = useIMOperations;

  const [workatoEmbedUrl, setWorkatoEmbedUrl] = useState<string>();
  const [isWorkatoEmbedJobEnabled, setIsWorkatoEmbedJobEnabled] =
    useState<boolean>();
  const [canShowIMSetupLoading, setCanShowIMSetupLoading] = useState<boolean>();

  const getIMSetupJob = useCallback(() => {
    setIsWorkatoEmbedJobEnabled(true);
  }, []);

  const {
    data: workatoEmbedURLJobData,
    isJobRunning: isIMSetupJobRunning,
    error: workatoEmbedURLJobError,
  } = useJobPollingV2<{ workatoEmbedUrl: string }>({
    preJobAPI: {
      apiName: GET_IM_SETUP_JOB,
      payload: {
        identityManager: id,
      },
    },
    postJobAPI: {
      apiName: GET_IM_CONFIG,
      params: {
        identityManagerName: id,
      },
    },
    enabled: isWorkatoEmbedJobEnabled,
  });

  useEffect(() => {
    if (workatoEmbedURLJobData) {
      setIsWorkatoEmbedJobEnabled(false);
      setWorkatoEmbedUrl(workatoEmbedURLJobData.workatoEmbedUrl);
    }
  }, [workatoEmbedURLJobData]);
  useEffect(() => {
    if (workatoEmbedURLJobError) {
      setIsWorkatoEmbedJobEnabled(false);
      handleError(workatoEmbedURLJobError);
    }
  }, [workatoEmbedURLJobError, handleError]);

  const [workatoEventData, setWorkatoEventData] = useState<
    WorkatoEventMessageData | undefined
  >();
  const handleWorkatoEvent = useCallback(
    (eventData: WorkatoEventMessageData) => {
      setWorkatoEventData(eventData);
      if (eventData.payload.connected) {
        setWorkatoEmbedUrl(undefined);
        setCanShowIMSetupLoading(true);
      }
    },
    [],
  );

  const { data: authorizeJobData } = useJobPollingV2({
    preJobAPI: {
      apiName: IM_AUTHORIZE,
      payload: {
        workatoAuthorization: {
          connection_id: workatoEventData?.payload.id,
          connected: workatoEventData?.payload.connected,
        },
      },
      params: {
        identityManagerName: id,
      },
    },
    postJobAPI: {
      apiName: GET_IM_INTEGRATION_DATA,
      params: {
        identityManagerName: id,
      },
    },
    enabled: workatoEventData && workatoEventData.payload.connected,
  });

  const { makeRequest: getIdentityManagerDataRequest } = useFetch({
    apiEndpoint: GET_IDENTITY_MANAGER_DATA,
    useCache: true,
  });

  useEffect(() => {
    if (authorizeJobData) {
      getIdentityManagerDataRequest();
      setCanShowIMSetupLoading(false);
    }
  }, [authorizeJobData, getIdentityManagerDataRequest]);

  const resetIMSetupJob = useCallback(() => {
    setWorkatoEmbedUrl('');
    setIsWorkatoEmbedJobEnabled(false);
    setCanShowIMSetupLoading(false);
  }, []);

  return {
    models: {
      ...useIMModels,
      canShowIMSetupLoading: canShowIMSetupLoading || isIMSetupJobRunning,
      workatoEmbedUrl,
    },
    operations: {
      ...useIMOperations,
      getIMSetupJob,
      handleWorkatoEvent,
      resetIMSetupJob,
    },
  };
};

export default useWorkatoIdentityManager;
