import React, { useEffect, useRef, useState } from 'react';
import Loading from '../loading';

import '../styles/photoViewer.scss';

function getImageDimensions(
  width: number,
  height: number,
  currentImageRef: HTMLImageElement | null,
  setImageDimensions: React.Dispatch<React.SetStateAction<number[]>>,
  zoomPercent: number,
  setDefaultZoom?: (value: number) => void,
) {
  // Scale image to fit into viewer
  let imgHeight;
  let imgWidth;
  const viewerHeight = height;
  const viewerWidth = width;
  const originalHeight = currentImageRef ? currentImageRef.naturalHeight : 0;
  const originalWidth = currentImageRef ? currentImageRef.naturalWidth : 0;
  if (originalHeight <= viewerHeight && originalWidth <= viewerWidth) {
    imgWidth = originalWidth * (zoomPercent / 100);
    imgHeight = originalHeight * (zoomPercent / 100);
  } else {
    const heightRatio = Math.floor((100 * viewerHeight) / originalHeight);
    const widthRatio = Math.floor((100 * viewerWidth) / originalWidth);
    if (heightRatio < widthRatio) {
      if (setDefaultZoom) {
        setDefaultZoom(heightRatio);
      }
      imgHeight = originalHeight * (zoomPercent / 100);
      imgWidth = originalWidth * (zoomPercent / 100);
    } else {
      if (setDefaultZoom) {
        setDefaultZoom(widthRatio);
      }
      imgHeight = originalHeight * (zoomPercent / 100);
      imgWidth = originalWidth * (zoomPercent / 100);
    }
  }
  setImageDimensions([imgHeight, imgWidth]);
  return { height: imgHeight, width: imgWidth };
}

const PhotoViewer = (props: {
  height: number;
  width: number;
  filePath: string;
  zoomPercent?: number;
  setDefaultZoom?: (value: number) => void;
}) => {
  const { height, width, filePath, zoomPercent = 100, setDefaultZoom } = props;
  const [imageDimensions, setImageDimensions] = useState([1, 1]);
  const [imageLoaded, setImageLoaded] = useState(false);
  const imageRef = useRef<HTMLImageElement | null>(null);

  useEffect(() => {
    if (imageLoaded) {
      getImageDimensions(
        width,
        height,
        imageRef?.current,
        setImageDimensions,
        zoomPercent,
        setDefaultZoom,
      );
    }
  }, [filePath, height, imageLoaded, setDefaultZoom, width, zoomPercent]);

  const containerStyles = {
    width: `${width}px`,
    height: `${height}px`,
  };

  return (
    <div
      style={containerStyles}
      className="photo-viewer-container"
      id="pg-photo-container"
    >
      {!imageLoaded && <Loading />}
      <img
        src={filePath}
        ref={imageRef}
        style={{
          height: imageDimensions[0],
          width: imageDimensions[1],
          margin: 'auto',
        }}
        onLoad={() => {
          setImageLoaded(true);
          getImageDimensions(
            width,
            height,
            imageRef?.current,
            setImageDimensions,
            zoomPercent,
            setDefaultZoom,
          );
        }}
      />
    </div>
  );
};

export default PhotoViewer;
