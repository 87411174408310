import Dropdown from '../../Dropdown_V2';
import styled from 'styled-components';
import InfoAlert from '../../InfoAlert';

export const Wrapper = styled.div`
  max-width: 576px;
  width: 100%;
`;

export const TextWrapper = styled.div`
  width: 100%;
`;

export const StyledInfoAlert = styled(InfoAlert)`
  margin-bottom: 16px;
`;

export const DropdownOptions = styled(Dropdown)`
  margin-top: 8px;
`;

export const DropdownWrapper = styled.div`
  width: fit-content;
`;
