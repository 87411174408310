import { GET_JOB_STATUS } from '../../constants/endpoints';
import { MakeAPICallInterface } from '../../queries/Settings/identityManager';
import { makeAPICall } from '../../queries/utils';
import configureAssemblyStatus from '../../Utils/identity/configureAssemblyStatus';

export interface DownloadDataJobProps {
  data: {
    url: string;
  };
  status: string;
}

interface UseJobPollingForDownloadProps {
  preJobAPI: MakeAPICallInterface;
  postJobAPI: MakeAPICallInterface;
}

const genericMakeAPICall = (api: MakeAPICallInterface) => {
  return makeAPICall(api.apiName, undefined, undefined, api.substitutions);
};

const genericJobMakeAPICall = (jobId?: string) => {
  return makeAPICall(GET_JOB_STATUS, undefined, undefined, {
    jobId: jobId || '',
  });
};

const useJobPollingForDownload = (props: UseJobPollingForDownloadProps) => {
  const { preJobAPI, postJobAPI } = props;

  const getJobStatus: (jobId?: string) => Promise<any> | DownloadDataJobProps =
    async (jobId?: string) => {
      const responseData = await genericJobMakeAPICall(jobId);

      if (responseData.data.data.status === configureAssemblyStatus.ERROR) {
        return {
          status: configureAssemblyStatus.ERROR,
        };
      }
      if (responseData.data.data.status !== configureAssemblyStatus.SUCCESS) {
        return getJobStatus(jobId);
      }
      const downloadResponseData = await genericMakeAPICall(postJobAPI);
      return {
        data: {
          url: downloadResponseData.data.url,
        },
        status: configureAssemblyStatus.SUCCESS,
      };
    };

  const downloadFlowData: () => Promise<any> | DownloadDataJobProps =
    async () => {
      const responseData = await genericMakeAPICall(preJobAPI);

      if (responseData.data.job !== undefined) {
        const jobCall = await getJobStatus(responseData.data.job.id);
        return jobCall;
      }
      return {
        data: {
          url: responseData.data.url,
        },
        status: configureAssemblyStatus.SUCCESS,
      };
    };

  return { downloadFlowData };
};

export default useJobPollingForDownload;
