import React from 'react';
import ContentLoader from 'react-content-loader';

import { StyledWrapper, StyledSpacing } from './styles';
import Body from '../../../atoms/Body';

import ThemeV2 from '../../../../componentsV2/theme';
import { FlowDetailsProps } from './types';

import { FLOW_ID, RESPONSE_VISIBILITY } from '../../../../languages/en/flows';
import {
  OWNER,
  DESCRIPTION,
  ABOUT,
} from '../../../../languages/en/singleWords';

import { capitalizeFirstLetter } from '../../../../Utils/text';
import DrawerHeaderWithIcon from '../../../molecules/DrawerHeaderWithIcon';

const LoadingComponent = (props: FlowDetailsProps) => {
  const { toggleRightAsideOpen } = props;
  return (
    <>
      <DrawerHeaderWithIcon icon="info" onClick={toggleRightAsideOpen}>
        {ABOUT}{' '}
      </DrawerHeaderWithIcon>
      <StyledWrapper>
        <Body variant="body2" color="gray7">
          {capitalizeFirstLetter(OWNER)}
        </Body>
        <StyledSpacing margin="0 0 8px" />
        <ContentLoader
          speed={2}
          width={220}
          height={28}
          viewBox="0 0 220 28"
          backgroundColor={ThemeV2.palette.gray4}
          foregroundColor={ThemeV2.palette.gray3}
        >
          <rect x="2" y="2" width="24" rx="12" ry="12" height="24" />
          <rect x="30" y="5" rx="4" ry="4" width="185" height="16" />
        </ContentLoader>
        <StyledSpacing margin="0 0 4px" />
        <Body variant="body2" color="gray7">
          {DESCRIPTION}
        </Body>
        <StyledSpacing margin="0 0 8px" />
        <ContentLoader
          speed={2}
          width={238}
          height={100}
          viewBox="0 0 238 100"
          backgroundColor={ThemeV2.palette.gray4}
          foregroundColor={ThemeV2.palette.gray3}
        >
          <rect x="0" y="0" width="238" rx="4" ry="4" height="16" />
          <rect x="0" y="20" width="238" rx="4" ry="4" height="16" />
          <rect x="0" y="40" width="238" rx="4" ry="4" height="16" />
          <rect x="0" y="60" width="238" rx="4" ry="4" height="16" />
          <rect x="0" y="80" width="133" rx="4" ry="4" height="16" />
        </ContentLoader>
        <StyledSpacing margin="0 0 4px" />
        <Body variant="body2" color="gray7">
          {FLOW_ID}
        </Body>
        <StyledSpacing margin="0 0 8px" />
        <ContentLoader
          speed={2}
          width={220}
          height={18}
          viewBox="0 0 220 18"
          backgroundColor={ThemeV2.palette.gray4}
          foregroundColor={ThemeV2.palette.gray3}
        >
          <rect x="0" y="0" rx="4" ry="4" width="178" height="16" />
        </ContentLoader>
        <StyledSpacing margin="0 0 4px" />
        <Body variant="body2" color="gray7">
          {RESPONSE_VISIBILITY}
        </Body>
        <StyledSpacing margin="0 0 8px" />
        <ContentLoader
          speed={2}
          width={220}
          height={16}
          viewBox="0 0 220 16"
          backgroundColor={ThemeV2.palette.gray4}
          foregroundColor={ThemeV2.palette.gray3}
        >
          <rect x="0" y="0" rx="4" ry="4" width="178" height="16" />
        </ContentLoader>
      </StyledWrapper>
    </>
  );
};

export default LoadingComponent;
