import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { device } from '../../../constants/layout';
import Heading from '../../atoms/Heading';
import Image from '../../atoms/Image';

export const ToggleButtonWrapper = styled.div`
  display: flex;
  & button {
    margin-right: 8px;
  }
`;
ToggleButtonWrapper.displayName = 'ToggleButtonWrapper';

export const DrawerHeading = styled(Heading)`
  margin: 0px;
`;
DrawerHeading.displayName = 'DrawerHeading';

export const NotificationHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
`;
NotificationHeader.displayName = 'NotificationHeader';

export const NotificationLink = styled(Link)`
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NotificationContainer = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 200px);
  padding: 0 12px 0 12px;
  @media ${device.tabletV2} {
    padding: 0px 16px 0 16px;
  }
`;

export const NotificationDropdownContainer = styled.div`
  height: 100%;
`;

export const NotificationErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 80%;
  margin: 0 auto;
  text-align: center;
`;

NotificationErrorContainer.displayName = 'NotificationErrorContainer';

export const NotificationErrorImage = styled(Image)`
  margin: 56px 0 16px 0;
`;

export const ContentWrapper = styled.div`
  padding: 16px;
  @media ${device.tablet} {
    padding: 16px;
  }
`;
