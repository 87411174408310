import React from 'react';
import styled from 'styled-components';

import Body from '../../atoms/Body';
import {
  notificationDropdownAllCaughtUpHeading,
  notificationDropdownAllCaughtUpMessage,
  notificationDropdownNoNotificationsHeading,
  notificationDropdownNoNotificationsMessage,
} from '../../../languages/en/home';
import { Flex } from '../../../Utils/styles/display';
import SVGIcon from '../../atoms/SVGIcon';

const TextWrapper = styled.div`
  margin-left: 8px;
`;

export interface EmptyNotificationsNoticeProps {
  type: 'allNotifications' | 'unreadNotifications';
}

const EmptyNotificationsNotice = ({ type }: EmptyNotificationsNoticeProps) => {
  return (
    <Flex alignItems="center">
      <SVGIcon icon="no_notification_icon" size="40px" />

      <TextWrapper>
        <Body variant="body3Medium">
          {type === 'allNotifications' &&
            notificationDropdownNoNotificationsHeading}
          {type === 'unreadNotifications' &&
            notificationDropdownAllCaughtUpHeading}
        </Body>
        <Body variant="body3">
          {type === 'allNotifications' &&
            notificationDropdownNoNotificationsMessage}
          {type === 'unreadNotifications' &&
            notificationDropdownAllCaughtUpMessage}
        </Body>
      </TextWrapper>
    </Flex>
  );
};

export default EmptyNotificationsNotice;
