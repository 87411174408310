import { useLocation } from 'react-router-dom';
import { V2_HOME } from '../../constants/routes';

const useHasActionBar = () => {
  const { pathname } = useLocation();
  if (!pathname) {
    return;
  }
  return pathname.includes(V2_HOME);
};

export default useHasActionBar;
