export const OVERVIEW_LABEL = 'Overview';
export const OVERVIEW_DESCRIPTION =
  'Get a sense of how your flow is performing';

export const BLOCK_BY_BLOCK_LABEL = 'Block by block';
export const BLOCK_BY_BLOCK_DESCRIPTION =
  'Get a sense of how your flow is performing';

export const TRENDS_LABEL = 'Trends';
export const SUMMARY_LABEL = 'Summary';
export const FILTER_BY_LABEL = 'Filter by';
export const DEPARTMENT_LABEL = 'Department';
export const PARTICIPANT_LABEL = 'Participant';
export const MANAGER_STATUS_LABEL = 'Manager Status';
export const SELECT_DEPARTMENT_LABEL = 'Select department';
export const SELECT_PARTICIPANT_LABEL = 'Select participant';
export const OCCURRENCE_START_DATE_LABEL = 'Occurrence Start Date';
export const INCLUDE_RESPONSES_FROM_LABEL = 'Include Responses from';
export const PARTICIPATION_OVER_TIME_LABEL = 'Participation over time';
export const FILTER_BY_DIFFERENT_DEPARTMENT_LABEL =
  'Filter by a different department...';
export const FILTER_BY_DIFFERENT_PARTICIPANT_LABEL =
  'Filter by a different participant...';
export const FILTER_BY_OCCURRENCE_START_DATE_LABEL =
  'Filter by occurrence Start Date';
export const INSIGHTS_SYNC_MESSAGE_LABEL =
  'Your insights update every hour, on the hour. You might not be looking at the most recent data.';
