import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Body from '../../atoms/Body';
import { Flex } from '../../../Utils/styles/display';

import {
  Box,
  KioskIcon,
  AssemblyLogo,
  KioskModeContent,
  KioskPageWrapper,
} from './styles';

import { V2_HOME } from '../../../constants/routes';
import useKioskStore from '../../../stores/kioskStore';
import assemblyLogo from '../../../img/svgs/assembly-logo-mono.svg';

import {
  KIOSK_MODE_LABEL,
  REDIRECT_FROM_KIOSK_PAGE_LABEL,
  DEVICE_LEAVING_KIOSK_MODE_LABEL,
  DEVICE_ENTERING_KIOSK_MODE_LABEL,
} from '../../../languages/en/kiosk';

const KioskPage = () => {
  const history = useHistory();
  const { setIsTurnedOn } = useKioskStore();

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const isTurnedOn = params.get('isTurnedOn') === 'true';

  useEffect(() => {
    setIsTurnedOn(isTurnedOn);
  }, [isTurnedOn, setIsTurnedOn]);

  useEffect(() => {
    const timer = setTimeout(() => {
      history.push(V2_HOME);
    }, 1000);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <KioskPageWrapper>
      <Box>
        <Flex flexDirection="column" alignItems="flex-start">
          <AssemblyLogo src={assemblyLogo} alt="assembly logo" />
          <KioskModeContent>
            {isTurnedOn && (
              <Body variant="subHead4" color="gray9">
                {DEVICE_ENTERING_KIOSK_MODE_LABEL}
              </Body>
            )}
            {!isTurnedOn && (
              <Body variant="subHead4" color="gray9">
                {DEVICE_LEAVING_KIOSK_MODE_LABEL}
              </Body>
            )}
            <KioskIcon icon="kiosk-icon" />
            <Body variant="subHead4" color="gray9">
              {KIOSK_MODE_LABEL}
            </Body>
          </KioskModeContent>
          <Body variant="subHead1" color="gray9">
            {REDIRECT_FROM_KIOSK_PAGE_LABEL}
          </Body>
        </Flex>
      </Box>
    </KioskPageWrapper>
  );
};

export default KioskPage;
