import { PersonTypeFromAPI } from '../../../interfaces/notebook';
import { MemberRole } from '../../../interfaces/user';

/* eslint-disable max-len */
export const notebookTasksForOverdueResponse = {
  unreadCount: 0,
  total: 1,
  data: [
    {
      id: '621f89364a4a53f15ec90984',
      transactionId: '810aa8ab-bcc2-4a80-be7d-efa197643544',
      orderIndex: '0|i001n3:',
      state: 'ACTIVE',
      dueDate: '2022-03-02T15:11:49.410Z',
      title: [
        {
          type: 'text',
          value: 'task today',
        },
      ],
      createdAt: '2022-03-02T15:11:50.093Z',
      createdBy: {
        name: 'Sumedha Kulkarni',
        firstName: 'Sumedha',
        lastName: 'Kulkarni',
        username: 'sumedhakulkarni',
        memberID: '60fa5cf3c6866fcbc88d0667',
        image:
          'https://s3.us-west-1.amazonaws.com/carrothrimageresized/5ae639b425ca241b3e2d4b07/60fa5cf3c6866fcbc88d0667/2020-03-05-10-48-23.jpg',
        pointsGiven: 2,
        totalPointsGiven: 2,
        memberState: 'ACTIVE',
        role: ['Employee', 'Admin'],
      },
      description: [],
      assignedTo: {
        name: 'Sumedha Notebook',
        firstName: 'Sumedha',
        lastName: 'Notebook',
        username: 'sumedhanotebook',
        memberID: '621f45a884cd3b8067fb785e',
        image: '',
        pointsGiven: 0,
        totalPointsGiven: 0,
        memberState: 'ACTIVE',
        role: ['Employee'],
      },
      assignedBy: {
        name: 'Sumedha Kulkarni',
        firstName: 'Sumedha',
        lastName: 'Kulkarni',
        username: 'sumedhakulkarni',
        memberID: '60fa5cf3c6866fcbc88d0667',
        image:
          'https://s3.us-west-1.amazonaws.com/carrothrimageresized/5ae639b425ca241b3e2d4b07/60fa5cf3c6866fcbc88d0667/2020-03-05-10-48-23.jpg',
        pointsGiven: 2,
        totalPointsGiven: 2,
        memberState: 'ACTIVE',
        role: ['Employee', 'Admin'],
      },
      assigneeTaskIndicator: ['unread'],
    },
  ],
  metadata: {
    pagination: {
      cursor: {
        previous: null,
        next: null,
      },
    },
  },
};

export const MockPersonTypeUser: PersonTypeFromAPI = {
  name: 'Test User',
  firstName: 'Test',
  lastName: 'User',
  username: 'testuser',
  memberID: '123',
  image: '',
  pointsGiven: '',
  totalPointsGiven: '2',
  memberState: 'ACTIVE',
  role: [MemberRole.Employee],
};
