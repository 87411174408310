import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';

import {
  SETUP_INITIALIZE_ERROR,
  CALENDAR_ALREADY_CONNECTED,
} from '../../../../languages/en/quickSetup';

import { CalendarSelectionProps } from '../type';
import { showErrorMessage } from '../../../../Utils/toast';
import { QuickSetupStep } from '../../QuickSetupController/types';

import {
  useUpdateQuickSetupStatus,
  useGetCalendarEmbedUrlQuery,
} from '../../../../queries/QuickSetup';

import {
  QuickSetupDirectionEnum,
  QuickSetupCalendarStepsEnum,
} from '../../../../queries/QuickSetup/interfaces';
import { trackOnboardingActionEvent } from '../../../../Utils/analytics/onboarding';
import { ACCOUNT_ONBOARDING_EVENTS } from '../../../../Utils/analytics/constants';
import { GOOGLE, OUTLOOK } from '../../../../languages/en/singleWords';
import {
  SplitNames,
  TreatmentTypes,
} from '../../../../hooks/useSplitSdkConfig/constants';
import { useFeatureSplit } from '../../../../hooks/useFeatureSplit';

const useCalendarSelectionController = (props: CalendarSelectionProps) => {
  const { mutate: mutateQuickSetup } = useUpdateQuickSetupStatus();

  const { treatment: outLookCalendarIntegrationTreatment } = useFeatureSplit(
    SplitNames.ADD_OUTLOOK_CALENDAR,
  );
  const isOutlookCalendarIntegrationAvailable =
    outLookCalendarIntegrationTreatment === TreatmentTypes.ON;

  const { isWorkatoSetupComplete, onQuickSetupStepChange, onCalendarSelected } =
    props;
  const [isWorkatoSetupError, setWorkatoSetupError] = useState(false);
  const { data: googleEmbedUrl, error: getGoogleEmbedUrlError } =
    useGetCalendarEmbedUrlQuery(isWorkatoSetupComplete, 'google_calendar');
  const { data: outlookEmbedUrl, error: getOutlookEmbedUrlError } =
    useGetCalendarEmbedUrlQuery(
      isWorkatoSetupComplete && isOutlookCalendarIntegrationAvailable,
      'outlook',
    );
  useEffect(() => {
    if (getGoogleEmbedUrlError || getOutlookEmbedUrlError) {
      const getCalendarErrorResponse = () => {
        if (getGoogleEmbedUrlError) {
          const { response: googleErrorResponse } =
            getGoogleEmbedUrlError as AxiosError<{
              body?: string;
              message?: string;
            }>;
          return googleErrorResponse;
        }
        const { response: outlookErrorResponse } =
          getOutlookEmbedUrlError as AxiosError<{
            body?: string;
            message?: string;
          }>;
        return outlookErrorResponse;
      };

      if (
        getCalendarErrorResponse()?.data.message === CALENDAR_ALREADY_CONNECTED
      ) {
        onQuickSetupStepChange(QuickSetupStep.MEETINGS_LIST);
      } else {
        showErrorMessage(SETUP_INITIALIZE_ERROR);
        setWorkatoSetupError(true);
      }
    }
  }, [
    onQuickSetupStepChange,
    isWorkatoSetupComplete,
    getGoogleEmbedUrlError,
    getOutlookEmbedUrlError,
  ]);

  const handleOnCalendarSelected = useCallback(
    (payload: { providerName: string; connectionId: number }) => {
      onCalendarSelected({
        providerName: payload.providerName,
        connectionId: payload.connectionId,
      });

      mutateQuickSetup({
        direction: QuickSetupDirectionEnum.FORWARD,
        calendar: {
          value: QuickSetupCalendarStepsEnum.LIST_MEETING,
          metaData: {
            providerName: payload.providerName,
            connectionId: payload.connectionId,
          },
        },
      });

      trackOnboardingActionEvent({
        action: ACCOUNT_ONBOARDING_EVENTS.CALENDAR_CONNECT,
        path: payload.providerName === 'google_calendar' ? GOOGLE : OUTLOOK,
      });

      onQuickSetupStepChange(QuickSetupStep.MEETINGS_LIST);
    },
    [mutateQuickSetup, onCalendarSelected, onQuickSetupStepChange],
  );

  return {
    models: {
      isWorkatoSetupError,
      googleEmbedUrl: googleEmbedUrl?.embedUrl,
      outlookEmbedUrl: outlookEmbedUrl?.embedUrl,
    },
    operations: {
      handleOnCalendarSelected,
    },
  };
};

export default useCalendarSelectionController;
