import { EditorState } from 'draft-js';
import { EmojiData } from 'emoji-mart';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import Uppy, { UploadedUppyFile, UppyFile } from '@uppy/core';

import debounce from 'lodash/debounce';
import { showErrorMessage } from '../../../../Utils/toast';
import { defaultCurrency } from '../../../../queries/Profile/utils';
import Editor from '../../../organism/RichTextEditor';
import { EntryProps, GifRatingsProps } from '../../../atoms/Giphy/interface';
import FlowsBaseInputBlock from '../FlowsBaseInputBlock';
import NavigationInstructions from '../../FlowsInputBlockNavigationInstructions';
import {
  StyledContentContainer,
  StyledFileCardList,
  StyledGifAndButtonsContainer,
  StyledGifContainer,
  StyledGiveRecognitionFormBottomButtons,
} from './styles';
import {
  addAtSymbolToEditorState,
  addEmojiToEditorState,
} from '../../../../Utils/draftjs';
import usePrevious from '../../../../hooks/usePrevious';
import {
  FlowFileForAPI,
  FlowVariants,
  OpenEndedBlockValue,
} from '../../../../interfaces/Flow';
import useAtMentions from '../../../../hooks/useAtMentions';
import { Mention } from '../../../../interfaces/user';
import { useUppyStore } from '../../../../stores/uppyStore';
import {
  determineFileStatus,
  determineFileType,
  transformUploadedUppyFileToFileForAPI,
} from '../FlowsFileUploadInputBlock/utils';
import FileCard from '../../FileCard';
import { isDesktop } from '../../../../Utils/window';

const dummyFunction = () => {};

export interface FlowsOpenEndedInputBlockProps {
  blockValue: OpenEndedBlockValue;
  onBlockChange: (newBlockValue: OpenEndedBlockValue) => void;
  blockError?: string;
  description?: string;
  fieldError?: string;
  gifRatings?: GifRatingsProps;
  goToNextStep: () => void;
  hasError?: boolean;
  isRequired?: boolean;
  isLastBlock?: boolean;
  subDescription?: string;
  title: string;
  hideMentions?: boolean;
  hideGifs?: boolean;
  blockId?: string;
  hideAttachment?: boolean;
  hideEmoticons?: boolean;
  uppy?: Uppy.Uppy | null;
  onDeleteFileClick?: (fileName: string) => void;
  isDisabled?: boolean;
  className?: string;
  flowVariant?: FlowVariants;
  minHeight?: number;
  isPreviewFlow?: boolean;
}

const FlowsOpenEndedInputBlock = ({
  blockError,
  description,
  fieldError,
  gifRatings,
  goToNextStep,
  hasError = false,
  isLastBlock = false,
  isRequired,
  blockValue,
  onBlockChange,
  subDescription,
  title,
  blockId = '',
  hideMentions,
  hideGifs,
  hideEmoticons,
  hideAttachment = true,
  uppy,
  className,
  onDeleteFileClick,
  isDisabled = false,
  flowVariant = FlowVariants.PARTICIPATION_FLOW,
  minHeight,
  isPreviewFlow,
}: FlowsOpenEndedInputBlockProps) => {
  const { gifUrl } = blockValue;
  const editorRef = useRef<HTMLElement>(null);
  const fileUploadRef = useRef<HTMLInputElement | null>(null);
  const [editorState, setEditorState] = useState(blockValue.editorState);
  const previousEditorState = usePrevious<EditorState>(editorState);
  const [fileUploadError, setFileUploadError] = useState('');
  const { onMentionsChange, suggestions } = useAtMentions(
    flowVariant === FlowVariants.PARTICIPATION_FLOW,
    !hideMentions,
  );
  const files = useUppyStore((state) => state.stores[blockId]?.files);
  const [fileUploading, setFileUploading] = useState(false);

  const uppyFiles = useMemo(
    () => (files ? Object.keys(files).map((fileId) => files[fileId]) : []),
    [files],
  );

  const handleChange = useCallback(
    (uploadedFiles: FlowFileForAPI[] | null) => {
      onBlockChange({
        ...blockValue,
        files: uploadedFiles ? [...uploadedFiles] : [],
      });
    },
    [blockValue, onBlockChange],
  );

  useEffect(() => {
    const uploadedFiles = uppyFiles.filter((uppyFile: UppyFile) =>
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      /* @ts-ignore */
      Boolean(uppyFile.uploadURL),
    ) as UploadedUppyFile<null, null>[];

    const transformedFiles = uploadedFiles.map((uploadedFile) => {
      return transformUploadedUppyFileToFileForAPI(uploadedFile);
    });

    handleChange(transformedFiles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uppyFiles]);

  const focusEditor = () => {
    if (editorRef.current) {
      editorRef.current.focus();
    }
  };

  useEffect(() => {
    if (blockValue.isUploading !== fileUploading) {
      onBlockChange({
        ...blockValue,
        isUploading: fileUploading,
      });
    }
  }, [blockValue, fileUploading, onBlockChange]);

  const onAddMention = (mention: Mention) => {
    const { tags, selectedMentions } = blockValue;
    const blockTagsAndMentions = [...tags, ...selectedMentions];
    if (!blockTagsAndMentions.some(({ id }) => id === mention.id)) {
      const updatedBlock = { ...blockValue };
      if (mention.id === 'everyone') {
        updatedBlock.tags = [mention];
      } else {
        updatedBlock.selectedMentions = [
          ...blockValue.selectedMentions,
          mention,
        ];
      }
      onBlockChange(updatedBlock);
    }
  };

  const handleEmoticonClick = (emojiData: EmojiData) => {
    const newEditorState = addEmojiToEditorState(emojiData, editorState);
    setEditorState(newEditorState);
    setTimeout(() => {
      focusEditor();
    }, 200);
  };

  const handleAtMentionClick = () => {
    const newEditorState = addAtSymbolToEditorState(editorState);
    setEditorState(newEditorState);
    setTimeout(() => {
      focusEditor();
    }, 200);
  };

  const [isEditorFocused, setIsEditorFocused] = useState(false);

  const hasText = editorState.getCurrentContent().hasText();

  const handleEditorBlur = () => setIsEditorFocused(false);

  const customEnterCheck = useCallback(() => isDesktop, []);

  const onGifSelectorClick = (entry: EntryProps) => {
    let selectedGifUrl = entry.images.downsized_medium.url;
    if (selectedGifUrl.length === 0) {
      selectedGifUrl = entry.images.original.url;
    }
    onBlockChange({
      ...blockValue,
      gifUrl: selectedGifUrl,
    });
  };

  const onGifRemoveClick = () =>
    onBlockChange({
      ...blockValue,
      gifUrl: undefined,
    });

  const handleEditorChange = (newState: EditorState) =>
    setEditorState(newState);

  const debounceSetBlockChange = useRef(
    debounce((value: OpenEndedBlockValue) => {
      onBlockChange({ ...value });
    }, 200),
  );

  useEffect(() => {
    if (
      previousEditorState &&
      previousEditorState.getCurrentContent() !==
        editorState.getCurrentContent()
    ) {
      debounceSetBlockChange?.current({
        ...blockValue,
        editorState,
      });
    }
  }, [
    blockValue,
    editorState,
    onBlockChange,
    previousEditorState,
    debounceSetBlockChange,
  ]);

  useEffect(() => {
    // https://github.com/draft-js-plugins/draft-js-plugins/issues/800#issuecomment-315950836
    if (!isDisabled) {
      setTimeout(() => editorRef?.current?.focus(), 200);
    }
  }, [editorRef, isDisabled]);

  useEffect(() => {
    const fileInput = fileUploadRef.current;

    if (fileInput && uppy) {
      fileInput.onchange = () => {
        if (fileInput.files) {
          const filesToBeUploaded = Object.values(fileInput.files);
          filesToBeUploaded.forEach((file) => {
            if (file) {
              try {
                uppy.addFile({
                  name: file.name,
                  type: file.type,
                  data: file,
                });
              } catch (err: any) {
                if (err?.isRestriction) {
                  setFileUploadError(err?.message);
                } else {
                  console.error(err);
                }
              }
            }
          });
        }
      };

      uppy.on('file-removed', () => {
        setFileUploadError('');
        fileInput.value = '';
      });

      uppy.on('files-added', () => {
        setFileUploading(true);
      });

      uppy.on('complete', () => {
        fileInput.value = '';
        setFileUploading(false);
      });
    }
  }, [blockValue, fileUploadRef, onBlockChange, uppy]);

  useEffect(() => {
    if (fileUploadError !== '') {
      showErrorMessage(fileUploadError);
    }
  }, [fileUploadError]);

  return (
    <FlowsBaseInputBlock
      className={className}
      blockError={blockError}
      description={description}
      fieldError={fieldError}
      hasError={hasError}
      isRequired={isRequired}
      navigationInstructions={
        (hasText || isEditorFocused) && (
          <NavigationInstructions
            type={isLastBlock ? 'last+shift+enter' : 'shift+enter'}
            goToNextStep={goToNextStep}
            customEnterCheck={customEnterCheck}
            isPreviewFlow={isPreviewFlow}
          />
        )
      }
      subDescription={subDescription}
      title={title}
    >
      <StyledContentContainer
        hasError={hasError}
        isFocused={isEditorFocused}
        id="open-ended-block"
        isDisabled={isDisabled}
        minHeight={minHeight}
      >
        <Editor
          editorRefElement={editorRef}
          editorPlaceholder="Start typing here..."
          editorState={editorState}
          onAddMention={onAddMention}
          onEditorChange={handleEditorChange}
          onSearchChange={onMentionsChange}
          suggestions={suggestions}
          onEditorFocus={() => setIsEditorFocused(true)}
          onEditorBlur={handleEditorBlur}
          onReturnKeyPress={goToNextStep}
          hideMentions={hideMentions}
          isDisabled={isDisabled}
        />

        <StyledGifAndButtonsContainer isDisabled={isDisabled}>
          {gifUrl && (
            <StyledGifContainer
              src={gifUrl}
              onGifRemoveClick={onGifRemoveClick}
            />
          )}

          {uppyFiles.length > 0 && (
            <StyledFileCardList>
              {uppyFiles.map((file: UppyFile) => {
                return (
                  <FileCard
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    /* @ts-ignore */
                    errorMessage={file.error && 'Error uploading'}
                    key={file.id}
                    loadingProgress={file.progress?.percentage}
                    onClickRetry={() => uppy?.retryUpload(file.id)}
                    file={{
                      type: determineFileType(file),
                      name: file.name,
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      /* @ts-ignore */
                      url: URL.createObjectURL(file.data),
                      size: file.size,
                    }}
                    isParticipation
                    onClickClose={() => {
                      uppy?.removeFile(file.id);
                      if (onDeleteFileClick) onDeleteFileClick(file.name);
                    }}
                    status={determineFileStatus(file)}
                  />
                );
              })}
            </StyledFileCardList>
          )}

          <div hidden>
            <input type="file" ref={fileUploadRef} multiple />
          </div>

          <StyledGiveRecognitionFormBottomButtons
            buttonText="button text"
            isPrivatePostButtonHidden
            gifRatings={gifRatings}
            maxValue={100}
            onAtMentionClick={handleAtMentionClick}
            onEmoticonClick={handleEmoticonClick}
            onGifSelectorClick={onGifSelectorClick}
            onAttachmentClick={() => fileUploadRef?.current?.click()}
            onPrivateMessageClick={dummyFunction}
            onPostClick={dummyFunction}
            onChange={dummyFunction}
            menuItems={[]}
            onItemClick={dummyFunction}
            assemblyCurrency={defaultCurrency}
            isGifSelected={!!gifUrl}
            isAtMentionButtonHidden={hideMentions}
            isGifButtonHidden={hideGifs}
            isEmoticonsButtonHidden={hideEmoticons}
            isAttachmentButtonHidden={hideAttachment}
            isDisabled={isDisabled}
          />
        </StyledGifAndButtonsContainer>
      </StyledContentContainer>
    </FlowsBaseInputBlock>
  );
};

export default FlowsOpenEndedInputBlock;
