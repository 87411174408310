import { CheckboxFilterValues } from '../../../../atomic/organism/CheckboxFilter/types';

export const responseOptions: CheckboxFilterValues = [
  {
    id: 'Occurrences',
    label: 'Occurrences',
    value: false,
  },
  {
    id: 'Answer Anytime',
    label: 'Answer Anytime',
    value: false,
  },
];

export const managerStatusOptions: CheckboxFilterValues = [
  {
    id: 'true',
    label: 'Managers',
    value: false,
  },
  {
    id: 'false',
    label: 'Non-Managers',
    value: false,
  },
];
