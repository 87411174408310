import styled from 'styled-components';
import ThemeV2 from '../../../componentsV2/theme';
import Body from '../../atoms/Body';
import Avatar from '../../atoms/Avatar';

export const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${ThemeV2.palette.gray5};
  padding: 16px;
  width: 100%;
  grid-area: header;
`;

export const StyledAvatar = styled(Avatar)`
  margin-right: 8px;
`;

export const StyledSubheading = styled(Body)`
  display: none;

  @media (min-width: ${ThemeV2.breakPoints.md}) {
    display: initial;
  }
`;

export const StyledBodyFlowName = styled(Body)<{ addMargin?: boolean }>`
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: ${({ addMargin }) => `${addMargin ? '6' : '2'}px`};
`;

export const StyledBodyHeaderTitle = styled(Body)`
  white-space: nowrap;
  margin-right: 4px;
`;

export const StyledEmoticon = styled.span`
  align-self: center;
  justify-self: center;
`;
