// @ts-nocheck TODO: type issues need to be fixed in this file
import moment from 'moment';
// import titleize from 'titleize';

export const getFormatedTime = (serTime) => {
  const time = moment(serTime);
  if (time.isBefore(moment().startOf('week'))) {
    return time.format('MMMM Do h:mm a');
  }

  const day = time.format('dddd');
  const timeStr = time.format('h:mm a');
  return `${day} at ${timeStr}`;
};
export const getSmallFormatedTime = (serTime) => {
  const time = moment(serTime);
  if (time.isBefore(moment().startOf('week'))) {
    return time.format('MMM Do h:mm a');
  }

  const day = time.format('ddd');
  const timeStr = time.format('h:mm a');
  return `${day} at ${timeStr}`;
};

export const getAgoSmallTime = (serTime) => {
  const time = moment(serTime);
  if (Math.abs(moment().diff(time)) < 1000) {
    // 1000 milliseconds
    return 'just now';
  }
  return time.fromNow(true);
};
export const getAgoTime = (serTime) => {
  const time = moment(serTime);
  if (Math.abs(moment().diff(time)) < 1000) {
    // 1000 milliseconds
    return 'just now';
  }
  return time.fromNow();
};

export const getReportXLSTime = (serTime) =>
  moment(serTime).format('MMMM Do YYYY, h:mm:ss a');

export const getFormattedDate = (time) => {
  return moment(time).format('MMMM Do, YYYY');
};

export const getDueTime = (expiryTime) => {
  const now = moment();
  const expiration = moment(expiryTime);
  const diff = expiration.diff(now);

  const diffDuration = moment.duration(diff);

  const diffAsMinutes = diffDuration.asMinutes();
  const diffAsHours = diffDuration.asHours();
  const diffAsDays = diffDuration.asDays();

  const diffMinutes = diffDuration.minutes();
  const diffHours = diffDuration.hours();
  const diffDays = diffDuration.days();
  const diffWeeks = diffDuration.weeks();

  const remainingDiffDays = diffDays % 7;

  if (diffAsMinutes < 1) {
    return `<1min`;
  }

  if (diffAsMinutes > 1 && diffAsHours < 1) {
    return `${diffMinutes}min`;
  }

  if (diffHours >= 1 && diffAsDays < 1) {
    if (diffMinutes === 0) {
      return `${diffHours}h`;
    }

    return `${diffHours}h ${diffMinutes}min`;
  }

  if (diffDays >= 1 && diffDays < 7) {
    if (diffHours === 0) {
      return `${diffDays}d`;
    }

    return `${diffDays}d ${diffHours}h`;
  }

  // Weeks
  if (remainingDiffDays === 0) {
    return `${diffWeeks}w`;
  }
  return `${diffWeeks}w ${remainingDiffDays}d`;
};
