import { useCallback, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import shallow from 'zustand/shallow';

import {
  FlowResponse,
  FlowsActivityStates,
} from '../../../../queries/Flows/interfaces';
import { FLOWS_FEEDS } from '../../../../constants/routes';

import {
  useFetchFlowActive,
  useFetchFlowsActivity,
} from '../../../../queries/Flows/Dashboard';
// eslint-disable-next-line max-len
import { LeftDrawerCollapsibleNavItemProps } from '../../../../atomic/molecules/FlowLeftDrawerCollapsibleNavigation/types';
import { getFlowHeaderOptionItems } from '../../FlowFeedHeaderController/utils';
import { MenuItemProps } from '../../../../atomic/molecules/Dropdown_V2/interfaces';
import useModalsStore from '../../../../stores/modalsStore';
import { setCreateFlowModalOpenSelector } from '../../../../stores/modalsStore/selectors';
import useTriggerFlowOption from '../../../../hooks/useFlowFeedOptions/useTriggerFlowOption';
import useEndOccurrenceOption from '../../../../hooks/useFlowFeedOptions/useEndOccurrenceOption';
import { allFlowFeedHeaderOptions } from '../../FlowFeedHeaderController/data';
import useOptions from '../../../../hooks/useFlowFeedOptions/useOptions';
import { canShowParticipationList, defaultFlow } from '../../../../Utils/flows';

import useArchiveFlowOption from '../../../../hooks/useFlowFeedOptions/useArchiveFlowOption';
import { mapHexCodeToEmoticon } from '../../../../Utils/mappers';
import useLayoutStore from '../../../../stores/layoutStore';
import useGetUserFlowPermissions from '../../../../hooks/useGetUserFlowPermissions';
import { useQuickSetupStore } from '../../../../stores/quickSetupStore';
import { trackTemplateGalleryShowEvent } from '../../../../Utils/analytics/templateGallery';
import { TEMPLATE_GALLERY_EVENTS } from '../../../../Utils/analytics/constants';

const mapFlowsResponseToNavItems = (flowItem: FlowResponse) => {
  return {
    isMuted: Boolean(flowItem.activityState === FlowsActivityStates.Muted),
    id: flowItem.flowId,
    label: flowItem.name,
    occurrence: flowItem.occurrence,
    isFlowOwner: Boolean(flowItem.isFlowOwner),
    isFlowViewer: Boolean(flowItem.isFlowViewer),
    emoticon: mapHexCodeToEmoticon(flowItem.icon.value),
    isFlowParticipant: Boolean(flowItem.isFlowParticipant),
  };
};

const useFlowsActiveListController = (isInQuickSetup: boolean) => {
  // Quick Setup
  const { selectedTemplateName, selectedTemplateEmoji } = useQuickSetupStore();

  const history = useHistory();
  const [isOpen, setIsOpen] = useState(true);
  const [updatingFlowDetails, setUpdatingFlowDetails] = useState<
    LeftDrawerCollapsibleNavItemProps | undefined
  >(undefined);
  const [flowsDropdownMenuItems, setFlowsDropdownMenuItems] = useState<
    MenuItemProps[]
  >([]);

  const flowId = updatingFlowDetails ? updatingFlowDetails.id : '';
  const canFetchParticipantList = canShowParticipationList(updatingFlowDetails);
  const { canUserCreateFlow } = useGetUserFlowPermissions();

  const {
    operations: { setIsTriggerFlowModalOpen },
  } = useTriggerFlowOption(flowId, canFetchParticipantList);

  const {
    models: { isArchiveModalOpen, isArchiveFlowLoading },
    operations: {
      setArchiveModalOpen,
      setArchiveModalClose,
      handleOnArchiveClick,
    },
  } = useArchiveFlowOption(flowId);

  const {
    models: { isEndOccurrenceModalOpen, isEndOccurrenceModalLoading },
    operations: {
      setEndOccurrenceModalClose,
      setIsEndOccurrenceModalOpen,
      handleEndOccurrenceButtonClick,
    },
  } = useEndOccurrenceOption();

  const {
    operations: {
      handleCopyFlowFeedLink,
      handleEditNotificationSettings,
      handleHideActivityClick,
      handleShowActivityClick,
    },
  } = useOptions();

  const {
    isLeftAsideOpen,
    isRightAsideOpen,
    toggleLeftAsideOpen,
    toggleRightAsideOpen,
  } = useLayoutStore(
    (state) => ({
      isLeftAsideOpen: state.isLeftAsideOpen,
      isRightAsideOpen: state.isRightAsideOpen,
      toggleLeftAsideOpen: state.toggleLeftAsideOpen,
      toggleRightAsideOpen: state.toggleRightAsideOpen,
    }),
    shallow,
  );

  const currentLocation = useLocation();
  const setCreateFlowModalOpen = useModalsStore(setCreateFlowModalOpenSelector);

  const { data, isLoading, isError, isSuccess } = useFetchFlowActive();
  const { data: flowsActivityUpdates } = useFetchFlowsActivity({
    isEnabled: true,
  });

  const pathNames = currentLocation.pathname
    .split('/')
    .filter((x) => x !== '' && x !== 'flows');

  const selectedId = pathNames[0] || '';
  const flows = data?.data;

  const sortedFlowItems = useMemo(() => {
    if (isInQuickSetup) {
      return [
        {
          isMuted: false,
          id: selectedTemplateName,
          label: selectedTemplateName,
          occurrence: undefined,
          isFlowOwner: true,
          isFlowViewer: true,
          emoticon: mapHexCodeToEmoticon(selectedTemplateEmoji || '1F44D'),
          isFlowParticipant: true,
        },
      ];
    }

    const flowsActivityData = flowsActivityUpdates?.data || [];
    const flowsNavItemsWithActivityUpdates: LeftDrawerCollapsibleNavItemProps[] =
      flows
        ? flows.map(mapFlowsResponseToNavItems).map((navItem) => {
            const correspondingItems = flowsActivityData.find(
              (update) => update.flowId === navItem.id,
            );
            const badgeText = correspondingItems?.unreadMentionsCount
              ? correspondingItems?.unreadMentionsCount.toString()
              : undefined;

            return {
              ...navItem,
              isUnread: correspondingItems?.hasUnreadPost,
              badgeText,
            };
          })
        : [];
    const sortByLabel = (
      a: LeftDrawerCollapsibleNavItemProps,
      b: LeftDrawerCollapsibleNavItemProps,
    ) => {
      const labelA = a.label.toLocaleLowerCase();
      const labelB = b.label.toLocaleLowerCase();
      if (labelA < labelB) {
        return -1;
      }
      if (labelA > labelB) {
        return 1;
      }
      return 0;
    };

    const activeFlowItems = flowsNavItemsWithActivityUpdates
      .filter((item) => !item.isMuted)
      .sort(sortByLabel);

    const mutedFlowItems = flowsNavItemsWithActivityUpdates
      .filter((item) => item.isMuted)
      .sort(sortByLabel);

    let sortedFlowsItemsData = activeFlowItems.concat(mutedFlowItems);

    sortedFlowsItemsData = [
      mapFlowsResponseToNavItems(
        defaultFlow,
      ) as LeftDrawerCollapsibleNavItemProps,
    ]
      .concat(sortedFlowsItemsData)
      .map((navItem) =>
        navItem.id === selectedId
          ? {
              ...navItem,
              isActive: true,
            }
          : navItem,
      );

    return sortedFlowsItemsData;
  }, [
    isInQuickSetup,
    flowsActivityUpdates?.data,
    flows,
    selectedTemplateName,
    selectedTemplateEmoji,
    selectedId,
  ]);

  const totalNotificationsCount = useMemo(() => {
    let notificationCount = 0;
    sortedFlowItems.forEach((item) => {
      if (Number(item.badgeText)) {
        notificationCount += Number(item.badgeText);
      }
    });
    return notificationCount;
  }, [sortedFlowItems]);

  const handleMenuItemClick = (id?: string) => {
    // close the left drawer before navigating to specific flow
    const widthCheck = window.matchMedia('(min-width: 769px)');
    if (isLeftAsideOpen && !widthCheck.matches) {
      toggleLeftAsideOpen();
    }
    if (!isRightAsideOpen && widthCheck.matches) {
      toggleRightAsideOpen();
    }
    const getLinkURL = FLOWS_FEEDS.replace(':flowId', `${id}`);
    history.push(getLinkURL);
  };

  const toggleExpandNavigation = () => {
    setIsOpen(!isOpen);
  };

  const handleAddNewFlowClick = useCallback(() => {
    /* Mix panel */
    trackTemplateGalleryShowEvent({
      show: TEMPLATE_GALLERY_EVENTS.TEMPLATE_GALLERY_SHOW,
      source: 'leftDrawer',
    });
    /* Mix panel */
    setCreateFlowModalOpen(true);
  }, [setCreateFlowModalOpen]);

  // Options
  const handleMenuItemOptionsSelect = (value: any) => {
    if (value === allFlowFeedHeaderOptions.runFlowNow.id) {
      setIsTriggerFlowModalOpen();
    }
    if (value === allFlowFeedHeaderOptions.endOccurrence.id) {
      setIsEndOccurrenceModalOpen();
    }

    if (value === allFlowFeedHeaderOptions.copyFlowFeedLink.id) {
      handleCopyFlowFeedLink();
    }

    if (value === allFlowFeedHeaderOptions.editNotificationSettings.id) {
      handleEditNotificationSettings();
    }

    if (value === allFlowFeedHeaderOptions.archiveFlow.id) {
      setArchiveModalOpen();
    }

    if (value === allFlowFeedHeaderOptions.hideActivity.id) {
      handleHideActivityClick(updatingFlowDetails?.id);
    }

    if (value === allFlowFeedHeaderOptions.showActivity.id) {
      handleShowActivityClick(updatingFlowDetails?.id);
    }
  };

  const onDropdownMenuItemRightClick = (
    item: LeftDrawerCollapsibleNavItemProps,
  ) => {
    const items = getFlowHeaderOptionItems({
      isFlowOwner: item.isFlowOwner,
      isFlowParticipant: item.isFlowParticipant,
      isFlowViewer: item.isFlowViewer,
      occurrence: item.occurrence || {},
      isMuted: item.isMuted,
    });
    const dropdownData: MenuItemProps[] = [
      {
        id: 'flows-navigation-dropdown-menu',
        items,
      },
    ];

    setUpdatingFlowDetails(item);
    setFlowsDropdownMenuItems(dropdownData);
  };

  const onEndOccurrenceClick = () => {
    if (flowId) {
      handleEndOccurrenceButtonClick(flowId);
    }
  };

  return {
    models: {
      isOpen,
      menuItems: sortedFlowItems,
      totalNotificationsCount,
      dropdownMenuItems: flowsDropdownMenuItems,
      isLoading,
      isError,
      isSuccess,
      isEndOccurrenceModalOpen,
      isArchiveModalOpen,
      isArchiveFlowLoading,
      updatingFlowDetails,
      isEndOccurrenceModalLoading,
      canCreateFlows: canUserCreateFlow,
    },
    operations: {
      handleMenuItemClick,
      toggleExpandNavigation,
      handleMenuItemOptionsSelect,
      onDropdownMenuItemRightClick,
      handleAddNewFlowClick,
      setEndOccurrenceModalClose,
      onEndOccurrenceClick,
      handleOnArchiveClick,
      setArchiveModalClose,
    },
  };
};

export default useFlowsActiveListController;
