import styled from 'styled-components';
import ThemeV2 from '../../../componentsV2/theme';
import {
  device,
  // SecondaryNavWidth,
  AsideWidthWhenOpen,
  MainHeaderHeight,
  MainHeaderWithTabsHeight,
} from '../../../constants/layout';
import { LayoutStyleProps } from '../../../interfaces/Layout';

export const Main = styled.main<LayoutStyleProps>`
  overflow: hidden; // Do not remove this overflow settings
  margin-left: 0;
  height: calc(100vh - 72px);
  background-color: ${({ background }) =>
    `${background || ThemeV2.palette.gray2}`};
  transition-duration: 0.5s;
  transition-property: margin-right, margin-left;
  backface-visibility: hidden;
  @media ${device.tabletV2} {
    margin-left: ${({ isRightAsideOpen, isLeftAsideOpen }) =>
      isRightAsideOpen || !isLeftAsideOpen ? `72px` : `288px`};
    margin-right: ${({ isRightAsideOpen }) =>
      isRightAsideOpen ? `${AsideWidthWhenOpen}px` : 0};
  }
  @media ${device.laptop} {
    margin-left: ${({ isLeftAsideOpen }) =>
      !isLeftAsideOpen ? `72px` : `288px`};
    margin-right: ${({ isRightAsideOpen }) =>
      isRightAsideOpen ? `${AsideWidthWhenOpen}px` : 0};
  }
  position: relative;
`;

export const MainContent = styled.div<LayoutStyleProps>`
  margin-top: ${(props) => (props.hasFlowBuilderTabs ? '29px' : '0px')};
  height: ${(props) =>
    props.hasTabComponent
      ? `calc(100vh - ${MainHeaderWithTabsHeight})`
      : `calc(100vh - ${MainHeaderHeight})`};
  overflow-y: auto;
  padding: ${(props) =>
    props.hasContentFooter
      ? '16px 16px 70px 16px'
      : props.hasFlowBuilderTabs
      ? '0px'
      : '16px'};
`;

export const MainHeader = styled.div<LayoutStyleProps>`
  position: sticky;
  top: 0px;
  display: block;
  align-items: center;
  max-height: ${({ hasTabComponent }) => (hasTabComponent ? '110px' : '80px')};
  margin-left: ${({ removeMargin }) => removeMargin && '0'};
  z-index: 3;
  background-color: ${({ background }) =>
    `${background || ThemeV2.palette.gray1}`};
`;
