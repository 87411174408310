import React from 'react';

import Avatar from '../../atoms/Avatar';
import { truncateText } from '../../../Utils/formatting';

import { ProfileNavHeaderProps } from './interfaces';
import { StyledProfileNavHeader, ProfileHeading } from './styles';

const ProfileNavHeader = (props: ProfileNavHeaderProps) => {
  const { img, avatarSize, userName, onlineStatus, userId } = props;

  return (
    <StyledProfileNavHeader>
      <Avatar
        img={img}
        size={avatarSize}
        onlineStatus={onlineStatus}
        name={userName}
        userId={userId}
      />
      <ProfileHeading variant="h7" weight="medium">
        {userName.length > 18 ? truncateText(15, userName) : userName}
      </ProfileHeading>
    </StyledProfileNavHeader>
  );
};

ProfileNavHeader.defaultProps = {
  avatarSize: '24px',
};

export default ProfileNavHeader;
