import React from 'react';

import mainFeedLogo from './main-feed-logo.svg';
import { COMPANY_LOGO_ALT_TEXT, MAIN_FEED } from '../../../languages/en/header';
import { HeaderType } from '../../../atomic/molecules/PageHeader/interfaces';
import PageHeader from '../../../atomic/molecules/PageHeader';
import useMainFeedHeaderController from './useMainFeedHeaderController';

const MainFeedHeaderController = () => {
  const { isMembersCountError, isMembersCountLoading, membersCount } =
    useMainFeedHeaderController();
  return (
    <PageHeader
      headerType={HeaderType.MAIN_FEED}
      imageName={COMPANY_LOGO_ALT_TEXT}
      imageUrl={mainFeedLogo}
      isMembersCountError={isMembersCountError}
      isMembersCountLoading={isMembersCountLoading}
      membersCount={membersCount}
      title={MAIN_FEED}
    />
  );
};

export default MainFeedHeaderController;
