import { AxiosError } from 'axios';
import React, { ReactNodeArray } from 'react';
import reactStringReplace from 'react-string-replace';

import styled from 'styled-components';
import { extractLinks } from '@draft-js-plugins/linkify';
import Body from '../atomic/atoms/Body';
import ThemeV2 from '../componentsV2/theme';
import HighlightedText from '../atomic/atoms/HighlightedText';
import { FeedPostUserProps, MemberInteractionType } from '../interfaces/Feed';
import { DEACTIVATED_USER } from '../languages/en/notifications';
import { MENTION_REGEX, getFullName } from './text';
import { isDeactivatedUser } from './user';
import { TagResponse } from '../queries/Flows/Feed/interfaces';

const StyledAnchor = styled.a`
  font-family: ${ThemeV2.typography.adminFontFamily};
  font-style: normal;
  font-weight: ${ThemeV2.typography.fontWeightRegular};
  font-size: 16px;
  line-height: 22px;
  color: ${ThemeV2.palette.geekBlue6};
  transition: 0.2s ease color;

  &:hover {
    color: ${ThemeV2.palette.geekBlue5};
  }
  &:focus {
    color: ${ThemeV2.palette.geekBlue7};
  }
  &:active {
    color: ${ThemeV2.palette.geekBlue7};
  }
`;

export const getFormattedMessage = (
  message: string,
  taggedUsers: FeedPostUserProps[],
  onUserClicked: (member: MemberInteractionType) => void,
  tags: TagResponse[] = [],
) => {
  let messageText: ReactNodeArray = [message];
  // Vijay to confirm whether we need the following commented code
  // as the Emoji's work without these code
  // messageText = reactStringReplace(message, /#(\w+)/g, (match, i) => (
  //   <span key={match + i} className="feed-card-hashtag">
  //     #{match}
  //   </span>
  // ));
  // // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  /* @ts-ignore */
  // messageText = reactStringReplace(messageText, /:([^\s]*?):/g, (match, i) => (
  //   <Emoji key={match + i} emoji={`:${match}:`} size={16} />
  // ));

  messageText = reactStringReplace(messageText, '\n', (match, index) => (
    <br key={index} />
  ));

  const matchedURLs = extractLinks(message);
  matchedURLs?.forEach((matchedURL) => {
    messageText = reactStringReplace(messageText, matchedURL.text, () => {
      return (
        <StyledAnchor
          target="_blank"
          href={matchedURL.url}
          key={matchedURL.url}
        >
          {matchedURL.text}
        </StyledAnchor>
      );
    });
  });

  if (taggedUsers.length || tags.length) {
    messageText = reactStringReplace(messageText, MENTION_REGEX, (match) => {
      if (match.startsWith('tag:')) {
        const matchingTag = tags.find(
          ({ tag }) => match.split('tag:').pop() === tag,
        );
        if (matchingTag) {
          return (
            <HighlightedText
              key={matchingTag.tag}
              name={matchingTag.displayText}
              disableOnClick
            />
          );
        }
      }
      return taggedUsers
        .filter((member) => member.memberID === match)
        .map((member) =>
          isDeactivatedUser(member) ? (
            <Body
              key={DEACTIVATED_USER}
              variant="body2Medium"
              color="gray6"
              inline
            >
              {DEACTIVATED_USER}
            </Body>
          ) : (
            <HighlightedText
              key={member.memberID}
              name={member.isDeleted ? DEACTIVATED_USER : getFullName(member)}
              onClick={() => onUserClicked(member)}
            />
          ),
        );
    });
  }
  return messageText;
};

export const getErrorMessage = (
  { response }: AxiosError,
  customErrorMessage?: string,
) => {
  if (response?.status !== 500 && response?.data?.body) {
    return response.data.body;
  }
  if (response?.status !== 500 && response?.data?.message) {
    return response.data.message;
  }
  return customErrorMessage;
};

export const getErrorStatusCode = ({ response }: AxiosError) => {
  return response?.status;
};
