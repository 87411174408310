import React from 'react';

import Body from '../../../atomic/atoms/Body';
import Heading from '../../../atomic/atoms/Heading';
import LoadingPulse from '../../../atomic/atoms/LoadingPulse';

import { SetupCompletionControllerProps } from './types';
import { SetupCompletionContainer, LoaderGifContainer } from './styles';
import useSetupCompletionController from './useSetupCompletionController';
import { quickSetUpCompletionScreen } from '../../../languages/en/quickSetup';

const SetupCompletionController = (props: SetupCompletionControllerProps) => {
  useSetupCompletionController(props);

  return (
    <SetupCompletionContainer>
      <Heading variant="h5">{quickSetUpCompletionScreen.headingText}</Heading>
      <Body variant="body2">{quickSetUpCompletionScreen.subHeadingText}</Body>
      <LoaderGifContainer src="https://media2.giphy.com/media/SWVzkIlHdEckF81gnA/200.gif" />
      <LoadingPulse size={32} />
    </SetupCompletionContainer>
  );
};

export default SetupCompletionController;
