import styled from 'styled-components';
import { Flex } from '../../../../../Utils/styles/display';
import ThemeV2 from '../../../../../componentsV2/theme';
import Body from '../../../../atoms/Body';

export const TaskItemWrapper = styled(Flex)<{
  isNewTask?: boolean;
}>`
  cursor: pointer;
  padding: 12px;

  &:hover {
    background: ${ThemeV2.palette.gray2};
    border-radius: '4px';
    .hover-icons {
      display: block;
    }
  }
`;

export const DueDateText = styled(Body)`
  margin-top: 8px;
`;

export const NewTaskText = styled(Body)``;

export const NewTaskIconWrapper = styled.div`
  margin: 4px 12px 4px 4px;
`;
