/* eslint-disable max-len */
import { ReactionType } from '../../../interfaces/Feed/Reaction';
import { PostTypes } from '../../../interfaces/Home';
import { MemberState } from '../../../interfaces/user';
import { VisibilityType } from '../../../queries/Flows/Feed/interfaces';
import { getFormattedMessage } from '../../../Utils/message';
import { DummyFlowFeedPostData } from './types';

const dummyFunction = () => {};

const visibility: VisibilityType = 'everyone';

const demoMember = {
  name: 'The Assembly Team',
  firstName: 'The Assembly Team',
  lastName: '',
  username: 'theAssemblyTeam',
  memberID: '60f66e5d8ba62526767170e2',
  image: '',
  pointsGiven: 100,
  totalPointsGiven: 100,
  memberState: MemberState.ACTIVE,
};

const reactionBarProps = {
  reactions: [
    {
      members: [demoMember],
      reaction: {
        name: ':raised_hands:',
        type: ReactionType.REGULAR,
        displayName: 'raised_hands',
        value: '',
      },
      timesReacted: 1,
      active: false,
    },
  ],
  contentId: '61713cd8d2db1fff3fb1dacb',
  currentUserId: '6081484184d8987b291e40b3',
};

const commentsProps = {
  feedCommentProps: [],
  showMoreCommentsButton: false,
  onShowMoreCommentsButtonClick: dummyFunction,
};

export const celebrateTeammateHeaderProps = {
  flow: {
    flowId: 'recognition',
    name: 'Celebrate a Teammate',
    icon: { kind: 'HEX_CODE', value: '1F389' },
    kind: '',
    version: 0,
  },
  postType: PostTypes.RECOGNITION,
  hasTrophies: false,
  showDeleteOption: true,
  createdAt: '2021-10-21T10:11:36.965Z',
  visibility,
  person: demoMember,
  onCopyLink: dummyFunction,
  handleMemberClick: dummyFunction,
  handleOnFlowClick: dummyFunction,
};

export const generalNewFeedHeaderFlowProps = {
  flowId: 'general_news_feed_flow',
  name: 'General News Feed',
  icon: { kind: 'HEX_CODE', value: '1f4f0' },
  kind: '',
  version: 0,
};

export const generalNewFeedHeaderProps = {
  postType: PostTypes.FLOW,
  hasTrophies: false,
  showDeleteOption: true,
  createdAt: '2021-10-21T10:11:36.965Z',
  visibility,
  person: demoMember,
  onCopyLink: dummyFunction,
  handleMemberClick: dummyFunction,
  handleOnFlowClick: dummyFunction,
};

export const iceBreakerHeaderFlowProps = {
  flowId: 'ice_breaker',
  name: 'Icebreaker',
  icon: { kind: 'HEX_CODE', value: '26C4' },
  kind: '',
  version: 0,
};

export const iceBreakerHeaderProps = {
  postType: PostTypes.FLOW,
  hasTrophies: false,
  showDeleteOption: true,
  createdAt: '2021-10-21T10:11:36.965Z',
  visibility,
  person: demoMember,
  onCopyLink: dummyFunction,
  handleMemberClick: dummyFunction,
  handleOnFlowClick: dummyFunction,
};

export const celebrateTeamMateDummyPostData: DummyFlowFeedPostData = {
  bodyProps: {
    blocks: [
      {
        id: '1',
        type: 'PERSON_SELECTOR',
        content: {
          title: 'Who would you like to celebrate?',
          persons: [
            {
              id: '6081484184d8987b291e40b3',
              label: 'Maybe this is the flow owner?',
              avatar: {
                img: '',
                userId: '6081484184d8987b291e40b3',
                name: 'Maybe',
              },
            },
          ],
        },
      },
      {
        id: '2',
        type: 'OPEN_ENDED',
        content: {
          gifUrl: 'https://media3.giphy.com/media/lMameLIF8voLu8HxWV/giphy.gif',
          title: 'What did your teammate(s) do?',
          message: [
            `Thanks for signing up for Assembly! We’re so excited that you took the initiative
            to empower your team to achieve more in their work. This flow allows everyone to give
            concrete, specific celebrations for jobs well done. This positive reinforcement
            is great for developing a strong company culture!`,
          ],
        },
      },
      {
        id: '3',
        type: 'DROPDOWN',
        content: {
          title: 'Choose a core value.',
          options: [],
          value: ['Initiative'],
        },
      },
    ],
    onSeeFullPostClick: dummyFunction,
    onDownloadFileClick: dummyFunction,
  },
  reactionBarProps,
  commentsProps,
};

export const generalNewsFeedDummyPostData: DummyFlowFeedPostData = {
  bodyProps: {
    blocks: [
      {
        id: '1',
        type: 'OPEN_ENDED',
        content: {
          gifUrl: '',
          title: 'What are you posting?',
          message: ['Sharing information or an idea'],
        },
      },
      {
        id: '2',
        type: 'OPEN_ENDED',
        content: {
          gifUrl:
            'https://media2.giphy.com/media/3h4Bo5fja4ZgYS0WEg/giphy-downsized-medium.gif',
          title: 'What would you like to ask/share/post?',
          message: getFormattedMessage(
            'Welcome to Assembly!! Your 📰 General News Feed flow allows you (and anyone else you choose), aka "participants", to share information or ideas, post a question, or post something just for fun. Right now this flow is configured to allow everyone in your company to post and everyone to view, but the flow owner can change this at any time by editing the Participation or Visibility sections in the Builder.\n\nThis flow can be answered at anytime, meaning any participant can post at anytime. Participants can post by either\n1) Clicking the "Answer a flow" action bar in the Main Feed and selecting 📰 General News Feed under the "Answer anytime" section; OR by\n2) Clicking the "Answer 📰 General News Feed" action bar from the flow feed itself.\n\nIf you ever want to prompt the participants to post, the flow owner can click "Run now" in the top right corner of the 📰General News Feed flow feed. This will send an email and in-app notification for all participants to respond.\n\nLASTLY. This post will disappear as soon as any participant posts in this flow for the first time. For any other flows help, please check out our help article hub:\nhttps://www.joinassembly.com/article-categories/flows',
            [],
            () => {},
            [],
          ),
        },
      },
    ],
    onSeeFullPostClick: dummyFunction,
    onDownloadFileClick: dummyFunction,
  },
  reactionBarProps,
  commentsProps,
};

export const iceBreakerDummyPostData: DummyFlowFeedPostData = {
  bodyProps: {
    blocks: [
      {
        id: '1',
        type: 'OPEN_ENDED',
        content: {
          gifUrl: '',
          title: 'What is the best piece of advice you’ve ever received?',
          message: ['Make sure your customers love Assembly!'],
        },
      },
      {
        id: '2',
        type: 'OPEN_ENDED',
        content: {
          gifUrl: '',
          title: 'What would your superpower be and why?',
          message: [
            'Making companies and and their teams’ work lives easier and more engaging by building an amazing product. And listening, we’re great listeners! Reach out to us anytime.',
          ],
        },
      },
      {
        id: '3',
        type: 'OPEN_ENDED',
        content: {
          gifUrl: '',
          title: 'As a child, what did you want to be when you grew up?',
          message: ['Your company’s Digital HQ'],
        },
      },
      {
        id: '4',
        type: 'OPEN_ENDED',
        content: {
          gifUrl:
            'https://media3.giphy.com/media/l4FGAREbOSRLKMiNq/giphy-downsized-medium.gif',
          title: 'What is your favorite tradition or holiday?',
          message: [
            'Recognizing teammates for all of the amazing things they do on a day-to-day basis.',
          ],
        },
      },
      {
        id: '5',
        type: 'OPEN_ENDED',
        content: {
          gifUrl: '',
          title: 'What fictional world or place would you like to visit?',
          message: ['THE MATRIX.'],
        },
      },
      {
        id: '6',
        type: 'OPEN_ENDED',
        content: {
          gifUrl: '',
          title: 'What is your favorite TV show?',
          message: ['Parks and Recreation'],
        },
      },
      {
        id: '7',
        type: 'OPEN_ENDED',
        content: {
          gifUrl: '',
          title:
            'What book or movie that you have read or seen recently would you recommend and why?',
          message: [
            'Big into sci-fi! However, maybe not the scene for a recommendation in that category. There is one business book worth recommending and it’s “Upstream” by Dan Heath. It’s all about solving problems at the root and explaining why we’re stuck in heroism land.',
          ],
        },
      },
      {
        id: '8',
        type: 'OPEN_ENDED',
        content: {
          gifUrl: '',
          title: 'Are you a traveler or a homebody?',
          message: [
            'Assembly travels to your office or works with you from home. We can even be in both places at the same time 🤯',
          ],
        },
      },
      {
        id: '9',
        type: 'OPEN_ENDED',
        content: {
          gifUrl: '',
          title: 'What is the most unusual food you have ever eaten?',
          message: ['Probably mac n cheese...a classic'],
        },
      },
      {
        id: '10',
        type: 'OPEN_ENDED',
        content: {
          gifUrl: '',
          title: 'If you were famous, what would you be famous for?',
          message: ['For being the best way to get to know your teammates!'],
        },
      },
      {
        id: '11',
        type: 'OPEN_ENDED',
        content: {
          gifUrl: '',
          title: 'What is one thing you would like everyone to know about you?',
          message: [
            'This flow can be edited by the flow owner at any time. This post will disappear as soon as a participant posts in here for the first time.',
          ],
        },
      },
    ],
    onSeeFullPostClick: dummyFunction,
    onDownloadFileClick: dummyFunction,
  },
  reactionBarProps,
  commentsProps,
};

export const simpleFlow = {
  bodyProps: {
    blocks: [
      {
        id: '60f136bf87b6c8e999e7d71e',
        type: 'DROPDOWN',
        content: {
          type: 'DROPDOWN',
          title: 'How are you feeling about this week, today?',
          options: [
            { id: 'op-1', value: 'Angry' },
            { id: 'op-2', value: 'Upset' },
            { id: 'op-3', value: 'Happy' },
          ],
          value: ['Happy'],
        },
      },
    ],
    onSeeFullPostClick: dummyFunction,
    onDownloadFileClick: dummyFunction,
  },
  reactionBarProps: {
    reactions: [],
    contentId: '6176aa72d5b928770db4089d',
    currentUserId: '6081484184d8987b291e40b3',
  },
  headerProps: {
    flow: {
      flowId: '60f136bf87b6c85721e7d71b',
      version: 1,
      name: 'Flow with shortcut',
      kind: 'ONDEMAND',
      icon: { kind: 'HEX_CODE', value: '1F60A' },
    },
    hasTrophies: false,
    showDeleteOption: true,
    postType: 'flow',
    createdAt: '2021-10-25T13:00:34.588Z',
    person: {
      name: 'Surender Lohia',
      firstName: 'Surender',
      lastName: 'Lohia',
      username: 'surenderlohia',
      memberID: '6081484184d8987b291e40b3',
      image: '',
      pointsGiven: 200,
      totalPointsGiven: 200,
      memberState: 'ACTIVE',
      role: ['Employee', 'Admin'],
      isDeleted: false,
    },
    onCopyLink: dummyFunction,
    handleMemberClick: dummyFunction,
    handleOnFlowClick: dummyFunction,
    currentUserId: '6081484184d8987b291e40b6',
    visibility: 'everyone',
  },
};
