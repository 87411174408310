import React, { useMemo } from 'react';
import parse from 'html-react-parser';
import { AssemblyCurrency } from '../../../interfaces/assembly';
import RedeemSelectModal from '../../../atomic/organism/RedeemSelectModal';
import useSwagsRedeemModalController from './useSwagsRedeemModalController';
import { RewardTypes } from '../../../atomic/molecules/RewardCard/interface';
import {
  RedeemPolicyContent,
  RedeemPolicyHeading,
  RewardSuccessContentText,
  RewardSuccessContentTextEmail,
} from '../../../atomic/organism/RedeemSelectModal/styles';
import {
  CONGRATS_YOU_HAVE_SUCCESSFULLY_REDEEMED,
  REDEEM_SWAG_POLICY_TITLE,
  PLACING_AN_ORDER_CONFIRMATION_WILL_SENT_TO,
  PLEASE_CHECK_YOUR_EMAIL,
  ALWAYS_CHECK_YOUR_SPAM,
  REDEEM_SWAG_POLICY_ABOUT_SHIPPING,
  REDEEM_SWAG_SHIPPING_ABOUT_CONTENT_PREFIX,
  REDEEM_SWAG_SHIPPING_ABOUT_CONTENT_SUFFIX,
  ORDERS_CANNOT_BE_CANCELLED,
  REDEEM_SWAG_POLICY_DETAILS,
  REDEEM_SWAG_NO_DESCRIPTION_FOUND,
  REDEEM_SWAG_NO_DETAILS_FOUND,
} from '../../../languages/en/rewards';
import { AxomoRedeemReward } from '../../../queries/Rewards/interfaces';
import { MenuItemProps } from '../../../atomic/molecules/Dropdown_V2/interfaces';
import Body from '../../../atomic/atoms/Body';
import { ShippingStatus } from '../../../atomic/organism/RedeemSelectModal/interface';
import { AxomoShippingCountry } from '../../../queries/Admin/rewards/Swags/interface';

interface SwagsRedeemModalProps {
  isModalOpen: boolean;
  rewardId: string;
  profileEmail: string;
  assemblyCurrency: AssemblyCurrency;
  rewardItemData: AxomoRedeemReward;
  onCloseRedeemModal: () => void;
  quantityDropdownValues: MenuItemProps[];
  axomoShippingCountries: AxomoShippingCountry[] | undefined;
}

const SwagsRedeemModalController = (props: SwagsRedeemModalProps) => {
  const {
    isModalOpen,
    profileEmail,
    assemblyCurrency,
    rewardItemData: redeemRewardData,
    onCloseRedeemModal,
    quantityDropdownValues,
    axomoShippingCountries,
  } = props;

  const {
    modelMethods,
    isRewardRated,
    hasRedeemed,
    shippingStatus,
    handlePrimaryButtonClick,
    secondaryModalOpen,
    onSecondaryModalClose,
    handleSecondaryButtonClick,
    handleMyRewardsButtonClick,
    shippingFormik,
    isCityOrStateError,
    countriesData,
    statesData,
    swagSizeProps,
    swagColorProps,
    swagQuantityProps,
    handleGoBackButtonClick,
    isPlaceOrderLoading,
    firstName,
    lastName,
  } = useSwagsRedeemModalController(
    redeemRewardData,
    quantityDropdownValues,
    axomoShippingCountries,
  );

  const updatedModelMethods = {
    ...modelMethods,
    onClose: onCloseRedeemModal,
  };
  const redeemPolicyContent = useMemo(() => {
    if (
      shippingStatus === ShippingStatus.SWAG_DETAILS ||
      shippingStatus === ShippingStatus.SHIPPING_ADDRESS
    ) {
      return (
        <>
          <RedeemPolicyHeading variant="h6">
            {REDEEM_SWAG_POLICY_TITLE}
          </RedeemPolicyHeading>
          <RedeemPolicyContent variant="body2">
            {redeemRewardData.desc ? (
              <div>
                {parse(redeemRewardData.desc, {
                  replace: (domNode) => {
                    if (domNode && domNode.type === 'tag' && domNode.next) {
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      return <>{domNode.next.children[0].data}</>;
                    }
                  },
                })}
              </div>
            ) : (
              REDEEM_SWAG_NO_DESCRIPTION_FOUND
            )}
          </RedeemPolicyContent>
          <RedeemPolicyHeading variant="h6">
            {REDEEM_SWAG_POLICY_DETAILS}
          </RedeemPolicyHeading>
          <RedeemPolicyContent variant="body2">
            {redeemRewardData.details ? (
              <div> {parse(redeemRewardData.details)} </div>
            ) : (
              REDEEM_SWAG_NO_DETAILS_FOUND
            )}
          </RedeemPolicyContent>
          <RedeemPolicyContent variant="body2">
            {PLACING_AN_ORDER_CONFIRMATION_WILL_SENT_TO}{' '}
            <Body variant="body2Bold" inline>
              {profileEmail}
              {'. '}
            </Body>
            {ORDERS_CANNOT_BE_CANCELLED}
          </RedeemPolicyContent>
        </>
      );
    }
    if (shippingStatus === ShippingStatus.ORDER_DETAILS_CONFIRMATION) {
      return (
        <>
          <RedeemPolicyHeading variant="h6">
            {REDEEM_SWAG_POLICY_ABOUT_SHIPPING}
          </RedeemPolicyHeading>
          <RedeemPolicyContent variant="body2" color="gray8">
            {REDEEM_SWAG_SHIPPING_ABOUT_CONTENT_PREFIX}
          </RedeemPolicyContent>
          <RedeemPolicyContent variant="body2" color="gray8">
            {REDEEM_SWAG_SHIPPING_ABOUT_CONTENT_SUFFIX}
          </RedeemPolicyContent>
          <RedeemPolicyContent variant="body2" color="gray8">
            {ORDERS_CANNOT_BE_CANCELLED}
          </RedeemPolicyContent>
        </>
      );
    }
  }, [
    profileEmail,
    redeemRewardData.desc,
    redeemRewardData.details,
    shippingStatus,
  ]);

  const rewardSuccessContent = useMemo(() => {
    return (
      <>
        <RewardSuccessContentTextEmail variant="body2">
          {PLEASE_CHECK_YOUR_EMAIL}
          <br />
          <b>{profileEmail}</b>
        </RewardSuccessContentTextEmail>
        <RewardSuccessContentText variant="body2">
          {ALWAYS_CHECK_YOUR_SPAM}
        </RewardSuccessContentText>
      </>
    );
  }, [profileEmail]);

  return (
    <RedeemSelectModal
      shippingDetails={{
        shippingStatus: shippingStatus,
        onSecondaryModalClose: onSecondaryModalClose,
        secondaryModalOpen: secondaryModalOpen,
        handlePrimaryButtonClick: handlePrimaryButtonClick,
        handleSecondaryButtonClick: handleSecondaryButtonClick,
        shippingFormik: shippingFormik,
        isCityOrStateError,
        countriesData,
        statesData,
        handleMyRewardsButtonClick,
        handleGoBackButtonClick,
        firstName,
        lastName,
      }}
      isOpen={isModalOpen}
      shippingCost={redeemRewardData.shippingCost.value}
      isLoadingPostRedeem={isPlaceOrderLoading}
      {...updatedModelMethods}
      assemblyCurrency={assemblyCurrency}
      redeemPolicyContent={redeemPolicyContent}
      rewardSuccessContent={rewardSuccessContent}
      rewardSuccessHeading={CONGRATS_YOU_HAVE_SUCCESSFULLY_REDEEMED}
      rewardType={RewardTypes.Swags}
      id={redeemRewardData.uid}
      rewardTitle={redeemRewardData.name}
      rewardCost={redeemRewardData.points[0]}
      rewardImage={redeemRewardData.image}
      hasRedeemed={hasRedeemed}
      isRewardRated={isRewardRated}
      swagSizeProps={swagSizeProps}
      swagColorProps={swagColorProps}
      swagQuantityProps={swagQuantityProps}
      quantityDropdownValues={quantityDropdownValues}
    />
  );
};

export default SwagsRedeemModalController;
