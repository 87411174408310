import { useCallback, useState } from 'react';
import { AxiosError } from 'axios';
import { useFetchFlowDetailsQuery } from '../../../queries/Flows/Feed';
import useTrackParticipationFlow from '../../../hooks/analytics/useTrackParticipationFlow';
import {
  AnonymityStates,
  FlowInstanceResponse,
} from '../../../queries/Flows/interfaces';
import {
  ACTION_EVENTS,
  PARTICIPATION_ANALYTICS_EVENTS,
} from '../../../Utils/analytics/constants';
import { useFlowViewersSearch } from '../../../hooks/useMembersSearch';
import { FlowSubmissionDetails } from '../../../interfaces/Flow';
import { useSubmitFlowInstanceMutation } from '../../../queries/Flows/Dashboard';
import { ANSWER_POSTED_SUCCESSFULLY } from '../../../languages/en/flows/participation';
import { showErrorMessage, showSuccessMessage } from '../../../Utils/toast';
import { formatBlockResponses } from './utils';
import useToggle from '../../../hooks/useToggle';
import { ParticipationTemplateErrorTypes } from '../../../atomic/pages/ParticipationTemplate/types';

const useParticipationFlowController = (
  flowInstance: FlowInstanceResponse | undefined,
  toggleParticipationModalOpen: () => void,
  onParticipationFlowPostSuccess?: () => void,
) => {
  const [customError, setCustomError] = useState<
    ParticipationTemplateErrorTypes | undefined
  >(undefined);
  const {
    data: flowDetails,
    isLoading: isFlowDetailsLoading,
    isError: isFlowDetailsError,
  } = useFetchFlowDetailsQuery(flowInstance?.flowId || '');
  const {
    models: { toggleValue: isPrivatePost },
    operations: { setToggleValue: togglePrivatePost },
  } = useToggle();
  const {
    models: { toggleValue: isAnonymousPost },
    operations: { setToggleValue: toggleAnonymousPost },
  } = useToggle();
  const { mutate, isLoading: isMutationLoading } =
    useSubmitFlowInstanceMutation(flowInstance?.flowId || '');
  const { trackParticipationFlow } = useTrackParticipationFlow({
    ...flowDetails?.data,
  });
  const onViewVisibilityPopOverOpen = useCallback(() => {
    trackParticipationFlow(
      PARTICIPATION_ANALYTICS_EVENTS.VIEWERS_HOVERED,
      null,
      ACTION_EVENTS.ACTION,
    );
  }, [trackParticipationFlow]);
  const trackViewersSearch = useCallback(() => {
    trackParticipationFlow(
      PARTICIPATION_ANALYTICS_EVENTS.VIEWERS_SEARCHED,
      null,
      ACTION_EVENTS.ACTION,
    );
  }, [trackParticipationFlow]);

  const flowViewsObject = useFlowViewersSearch(
    flowInstance?.flowId || '',
    trackViewersSearch,
  );

  const handlePostingSuccess = () => {
    trackParticipationFlow(
      PARTICIPATION_ANALYTICS_EVENTS.POSTED,
      null,
      ACTION_EVENTS.ACTION,
    );
    showSuccessMessage(ANSWER_POSTED_SUCCESSFULLY);

    if (onParticipationFlowPostSuccess) {
      onParticipationFlowPostSuccess();
    } else {
      toggleParticipationModalOpen();
    }
  };

  const formatPayload = useCallback(
    (submittedValues: Record<string, any>) =>
      formatBlockResponses(
        submittedValues,
        flowInstance as FlowInstanceResponse,
        isPrivatePost,
        isAnonymousPost ||
          flowDetails?.data.responseSettings.anonymity.state ===
            AnonymityStates.ENABLED,
      ),
    [
      flowDetails?.data.responseSettings.anonymity.state,
      flowInstance,
      isAnonymousPost,
      isPrivatePost,
    ],
  );

  const onFlowSubmit = ({ values, currentStep }: FlowSubmissionDetails) => {
    const payload = formatPayload(values);

    // Manually overriding onError and onSuccess call for this mutate function.
    mutate(payload, {
      onError: (error: unknown) => {
        const errorMessage = (error as AxiosError).response?.data.message;
        if (
          errorMessage !==
          ParticipationTemplateErrorTypes.INVALID_BLOCK_PARAMETERS
        ) {
          setCustomError(errorMessage as ParticipationTemplateErrorTypes);
        }
        trackParticipationFlow(
          PARTICIPATION_ANALYTICS_EVENTS.ERRORED,
          currentStep,
          ACTION_EVENTS.ERROR,
        );
        if (
          errorMessage !==
          ParticipationTemplateErrorTypes.NO_ACTIVE_OCCURRENCE_FOUND
        )
          showErrorMessage(errorMessage);
      },
      onSuccess: handlePostingSuccess,
    });
  };

  return {
    flowViewsObject,
    isFlowDetailsError,
    isFlowDetailsLoading,
    flowDetails,
    isPrivatePost,
    isAnonymousPost,
    isMutationLoading,
    togglePrivatePost,
    toggleAnonymousPost,
    onFlowSubmit,
    onViewVisibilityPopOverOpen,
    customError,
    setCustomError,
  };
};

export default useParticipationFlowController;
