import { useMemo } from 'react';

import { isUserAdmin, isUserManager } from '../../Utils/user';
import { useProfileInfoFetchQuery } from '../../queries/Profile';
import { AutocompleteDropdownItem } from '../../atomic/organism/Autocomplete/interfaces';

const useGetUserFlowPermissions = () => {
  const { data } = useProfileInfoFetchQuery();
  const userFlowPermissions = data?.assembly.flow?.creation.value;
  const isAdmin = Boolean(data?.member && isUserAdmin(data.member));
  const isManager = Boolean(data?.member && isUserManager(data.member));

  const canCreateFlows = useMemo(() => {
    const flowsPermissionSelectOptions: AutocompleteDropdownItem<string>[] = [
      {
        title: 'Entire organization',
        id: 'EVERYONE',
      },
      {
        title: 'Managers',
        id: 'MANAGER',
      },
      {
        title: 'Workspace Owners & Admins only',
        id: 'ADMINS_AND_OWNER',
      },
    ];

    if (isAdmin) {
      return true;
    }

    switch (userFlowPermissions) {
      case flowsPermissionSelectOptions[0].id:
        return true;
      case flowsPermissionSelectOptions[1].id:
        return isManager;
      default:
        return false;
    }
  }, [userFlowPermissions, isAdmin, isManager]);

  return {
    canUserCreateFlow: canCreateFlows,
  };
};

export default useGetUserFlowPermissions;
