import {
  MenuItemIndividualItem,
  MenuItemProps,
} from '../../../atomic/molecules/Dropdown_V2/interfaces';
import { CalendarItem } from '../../../interfaces/QuickSetup';
import { PRIMARY_CALENDAR } from '../../../languages/en/quickSetup';

export const getCalendarListDropdownItems = (
  calendarList: CalendarItem[],
): MenuItemProps[] => {
  const menuItems: MenuItemIndividualItem[] = calendarList.map((item) => ({
    value: item.primary ? PRIMARY_CALENDAR : item.summary,
    id: item.primary ? PRIMARY_CALENDAR : item.summary,
  }));
  return [
    {
      id: 'calendarList',
      items: menuItems,
    },
  ];
};
