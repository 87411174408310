import React, { useMemo } from 'react';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';

import FlowsBaseInputBlock from '../FlowsBaseInputBlock';
import EmoticonWithText from '../../../atoms/EmoticonWithText';
import NavigationInstructions from '../../FlowsInputBlockNavigationInstructions';
import {
  OwnerBlock,
  PreviewBlockWrapper,
  PreviewDescription,
  ContentDescription,
  ContentWrapper,
  StyledTextWithIcon,
  ByLabel,
  OwnerName,
  StyledIconWrapper,
} from './styles';
import { Flex } from '../../../../Utils/styles/display';
import { BY, BLOCKS, FORM } from '../../../../languages/en/singleWords';
import { capitalizeFirstLetter } from '../../../../Utils/text';
import ThemeV2 from '../../../../componentsV2/theme';
import {
  ONDEMAND,
  SCHEDULED,
  ASSEMBLY_TEMPLATE_DESCRIPTION,
  NO_TRIGGER,
} from '../../../../languages/en/flows';
import { PreviewSummaryStaticBlockState } from '../../../../interfaces/Flow';
import { mapHexCodeToEmoticon } from '../../../../Utils/mappers';
import SVGIcon from '../../../atoms/SVGIcon';
import {
  ACCEPTING_RESPONSES_UNTIL,
  ANONYMOUS_BANNER_TEXT,
} from '../../../../languages/en/flows/participation';

export interface FlowsPreviewInputBlockProps {
  blockValue: PreviewSummaryStaticBlockState;
  goToNextStep: () => void;
  isExternalFlow?: boolean;
  flowEndTime?: string;
}
const actionTypeMap = {
  SEND_FORM: {
    icon: 'questions',
    label: `${capitalizeFirstLetter(FORM)}`,
    iconColor: ThemeV2.palette.geekBlue6,
    iconWrapperBackground: ThemeV2.palette.geekBlue1,
  },
};

const triggerMap = {
  ONDEMAND: {
    icon: 'run-now',
    label: `${ONDEMAND}`,
    iconColor: ThemeV2.palette.geekBlue6,
    iconWrapperBackground: ThemeV2.palette.geekBlue1,
  },
  SCHEDULED: {
    icon: 'clock',
    label: `${SCHEDULED}`,
    iconColor: ThemeV2.palette.geekBlue6,
    iconWrapperBackground: ThemeV2.palette.geekBlue1,
  },
  NO_TRIGGER: {
    icon: 'close-circle-filled',
    label: `${NO_TRIGGER}`,
    iconColor: ThemeV2.palette.geekBlue6,
    iconWrapperBackground: ThemeV2.palette.geekBlue1,
  },
};

const FlowsPreviewBlock = ({
  blockValue: {
    creator: { name: creatorName },
    id,
    title,
    description,
    noOfBlocks,
    actionType,
    triggerType,
    icon,
  },
  goToNextStep,
  isExternalFlow = false,
  flowEndTime,
}: FlowsPreviewInputBlockProps) => {
  const formsContent = useMemo(() => {
    if (isExternalFlow) {
      const data = [
        {
          icon: 'anonymous',
          label: ANONYMOUS_BANNER_TEXT,
          iconColor: ThemeV2.palette.gray10,
          iconWrapperBackground: ThemeV2.palette.gray3,
        },
        {
          icon: 'block',
          label: `${noOfBlocks} ${BLOCKS}`,
          iconColor: ThemeV2.palette.geekBlue6,
          iconWrapperBackground: ThemeV2.palette.geekBlue1,
        },
      ];
      if (triggerType === 'SCHEDULED' && flowEndTime) {
        data.push({
          icon: 'clock',
          label:
            ((flowEndTime &&
              `${ACCEPTING_RESPONSES_UNTIL} ${format(
                parseISO(flowEndTime),
                "MMMM dd, 'at' hh:mm aaa",
              )}`) as string) || '',
          iconColor: ThemeV2.palette.geekBlue6,
          iconWrapperBackground: ThemeV2.palette.geekBlue1,
        });
      }
      return data;
    }
    return [
      actionTypeMap[actionType],
      triggerMap[triggerType],
      {
        icon: 'block',
        label: `${noOfBlocks} ${BLOCKS}`,
        iconColor: ThemeV2.palette.geekBlue6,
        iconWrapperBackground: ThemeV2.palette.geekBlue1,
      },
    ];
  }, [isExternalFlow, actionType, triggerType, noOfBlocks, flowEndTime]);

  return (
    <FlowsBaseInputBlock
      navigationInstructions={
        <NavigationInstructions
          type={isExternalFlow ? null : 'enter'}
          goToNextStep={goToNextStep}
        />
      }
      title={
        <EmoticonWithText text={title} emoticon={mapHexCodeToEmoticon(icon)} />
      }
      childMargin="0px"
    >
      <PreviewBlockWrapper key={id}>
        <PreviewDescription variant="body1" color="gray8">
          {description}
        </PreviewDescription>
        {!isExternalFlow && (
          <>
            <OwnerBlock>
              <ByLabel color="gray8" variant="body1">
                {capitalizeFirstLetter(BY)}:
              </ByLabel>
              <Flex>
                <StyledIconWrapper alignItems="center" justifyContent="center">
                  <SVGIcon
                    icon="assembly"
                    size="12px"
                    color={ThemeV2.palette.white}
                  />
                </StyledIconWrapper>
                <OwnerName color="gray8" variant="body1">
                  {creatorName}
                </OwnerName>
              </Flex>
            </OwnerBlock>
            <ContentDescription variant="body3" color="gray7">
              {ASSEMBLY_TEMPLATE_DESCRIPTION}
            </ContentDescription>
          </>
        )}
        <ContentWrapper>
          {formsContent.map((item) => (
            <StyledTextWithIcon
              className="preview-block-content"
              key={Math.random()}
              iconWrapperSize="32px"
              iconSize="14px"
              icon={item.icon}
              variant="body1"
              iconWrapperRightMargin="8px"
              iconWrapperBackground={item.iconWrapperBackground}
              roundedIcon
              iconColor={item.iconColor}
            >
              {item.label}
            </StyledTextWithIcon>
          ))}
        </ContentWrapper>
      </PreviewBlockWrapper>
    </FlowsBaseInputBlock>
  );
};

export default FlowsPreviewBlock;
