import styled from 'styled-components';
import Body from '../../atoms/Body';
import LinkItem from '../../atoms/LinkItem';
import IconInfo from '../../molecules/IconInfo';
import ThemeV2 from '../../../componentsV2/theme';
import GifContainer from '../../molecules/GifContainer';

export const FeedPostBodyRoot = styled.div`
  padding: 0px 16px 12px 16px;
`;
FeedPostBodyRoot.displayName = 'FeedPostBodyRoot';

export const FeedPostCoreValue = styled(IconInfo)`
  border-radius: 4px;
  margin-bottom: 12px;
`;
FeedPostCoreValue.displayName = 'FeedPostCoreValue';

export const FeedPostMessage = styled(Body)`
  display: inline-block;
  overflow-wrap: anywhere;
`;
FeedPostMessage.displayName = 'FeedPostMessage';

export const FeedPostHashTag = styled(LinkItem)`
  color: ${ThemeV2.palette.geekBlue6};
`;
FeedPostHashTag.displayName = 'FeedPostHashTag';

export const FeedPostImage = styled(GifContainer)`
  margin-top: 12px;
`;
FeedPostImage.displayName = 'FeedPostImage';
