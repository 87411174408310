import React from 'react';
import ContentLoader from 'react-content-loader';
import ThemeV2 from '../../../componentsV2/theme';

const LoaderRegular = () => {
  return (
    <ContentLoader
      speed={2}
      width={345}
      height={215}
      viewBox="0 0 345 215"
      backgroundColor={ThemeV2.palette.gray4}
      foregroundColor={ThemeV2.palette.gray3}
      data-testid="loadingWrapper"
    >
      <rect x="0" y="0" rx="0" ry="0" width="345" height="96" />
      <rect x="16" y="125" rx="4" ry="4" width="133" height="25" />
      <rect x="16" y="175" rx="4" ry="4" width="220" height="20" />
    </ContentLoader>
  );
};
export default LoaderRegular;
