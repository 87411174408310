import { useHistory, useLocation } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useState } from 'react';

import useLayoutStore from '../../../../stores/layoutStore';

import { QuickSetupStep } from '../types';
import { V2_HOME } from '../../../../constants/routes';
import useJobPollingV2 from '../../../../hooks/useJobPollingV2';
import { WORKATO_QUICKSETUP } from '../../../../constants/endpoints';
import { useProfileInfoFetchQuery } from '../../../../queries/Profile';
import { useQuickSetupStore } from '../../../../stores/quickSetupStore';
import { useGetQuickSetupStatus } from '../../../../queries/QuickSetup';
import { FlowRequestTemplateCardProps } from '../../../../atomic/molecules/FlowRequestTemplateCard/type';
import { MeetingParticipant } from '../../../../atomic/organism/QuickSetup/MeetingParticipantsList/type';

import {
  mapSurveyStepsEnumFromAPIToQuickSetupStep,
  mapQuickSetupCalendarStepsFromAPIToQuickSetupStep,
} from './utils';
import { LayoutStoreState } from '../../../../interfaces/Layout';
import { trackOnboardingActionEvent } from '../../../../Utils/analytics/onboarding';
import { ACCOUNT_ONBOARDING_EVENTS } from '../../../../Utils/analytics/constants';

const layoutSelector = (state: LayoutStoreState) =>
  state.setRightAsideOpenToFalse;

const useQuickSetupController = () => {
  // Check if the quick setup is completed
  const history = useHistory();
  const { data, isLoading } = useProfileInfoFetchQuery();

  // Current Step from Query Params
  const { search } = useLocation();
  const currentQueryStep = useMemo(() => {
    const query = new URLSearchParams(search);
    const currentStep = query.get('step');
    if (currentStep) {
      trackOnboardingActionEvent({
        action: ACCOUNT_ONBOARDING_EVENTS.REQUEST_DEMO,
      });
    }
    return currentStep;
  }, [search]);

  // State
  const { setSelectedTemplateName, setCurrentStep: setCurrentQuicksetupState } =
    useQuickSetupStore();

  // Right Sidebar
  const setRightAsideOpenToFalse = useLayoutStore(layoutSelector);

  useEffect(() => {
    setRightAsideOpenToFalse();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Quick Setup States
  const [currentStep, setCurrentStep] = useState(
    QuickSetupStep.QUICK_SETUP_OPTION_SELECTION,
  );
  const [flow, setCurrentFlow] = useState('');
  const [calenderId, setCalenderId] = useState('');
  const [meetingId, setMeetingId] = useState('');
  const [duration, setDuration] = useState(1395);
  const [meetingName, setMeetingName] = useState('');
  const [meetingReccurence, setMeetingReccurence] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState<
    FlowRequestTemplateCardProps | undefined
  >();
  const [calendarConnectionId, setCalendarConnectionId] = useState(0);
  const [providerName, setProviderName] = useState('');
  const handleCurrentStepChange = useCallback(
    (step: QuickSetupStep) => {
      setCurrentStep(step);
      setCurrentQuicksetupState(step);
    },
    [setCurrentQuicksetupState],
  );

  const handleCurrentFlowChange = useCallback((currentFlow: string) => {
    setCurrentFlow(currentFlow);
  }, []);

  const handleCalenderIdChange = useCallback((calId: string) => {
    setCalenderId(calId);
  }, []);

  const handleMeetingChange = useCallback(
    (id: string, name: string, recurrence: string, eventDuration: number) => {
      setMeetingId(id);
      setMeetingName(name);
      setDuration(eventDuration);
      setSelectedTemplateName(name);
      setMeetingReccurence(recurrence);
    },
    [setSelectedTemplateName],
  );

  const handleTemplateChange = useCallback(
    (template: FlowRequestTemplateCardProps | undefined) => {
      setSelectedTemplate(template);
    },
    [],
  );

  const handleCalendarConnectionChange = useCallback(
    (payload: { providerName: string; connectionId: number }) => {
      setProviderName(payload.providerName);
      setCalendarConnectionId(payload.connectionId);
    },
    [],
  );

  // Workato Setup
  const [isWorkatoSetupComplete, setIsWorkatoSetupComplete] = useState(false);
  const { error, isJobStatusSuccessful } = useJobPollingV2<undefined>({
    preJobAPI: {
      apiName: WORKATO_QUICKSETUP,
    },
    enabled: true,
  });

  useEffect(() => {
    if (isJobStatusSuccessful || error) {
      setIsWorkatoSetupComplete(true);
    }
  }, [error, isJobStatusSuccessful]);

  // Meeting Participants
  const [meetingParticipants, setMeetingParticipants] = useState<
    MeetingParticipant[]
  >([]);

  const [isInviteButtonDisabled, setIsInviteButtonDisabled] = useState(false);

  useEffect(() => {
    setIsInviteButtonDisabled(meetingParticipants.some((x) => x.hasError));
  }, [meetingParticipants]);

  const handleMeetingParticipantsChange = useCallback((participants) => {
    setMeetingParticipants(participants);
  }, []);

  const [isInviteMembersSuccess, setIsInviteMembersSuccess] = useState(false);

  const handleInviteMemberStatus = useCallback((status: boolean) => {
    setIsInviteMembersSuccess(status);
  }, []);

  // Quick Setup Status
  const { data: quickSetupStatusData, isLoading: isLoadingQuickSetupStatus } =
    useGetQuickSetupStatus();

  useEffect(() => {
    const setMetaData = (metadata: Record<string, unknown> | undefined) => {
      if (metadata) {
        setMeetingId(String(metadata.meetingId) || '');
        setCalenderId(String(metadata.calenderId) || '');
        setMeetingName(String(metadata.meetingName) || '');
        setMeetingReccurence(String(metadata.rrule) || '');
        setSelectedTemplateName(String(metadata.meetingName) || '');
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setSelectedTemplate({
          templateId: String(metadata?.templateId) || '',
        });
        setProviderName(String(metadata.providerName) || '');
        setCalendarConnectionId(Number(metadata.connectionId) || 0);
      }
    };

    if (quickSetupStatusData) {
      let mappedStep = QuickSetupStep.QUICK_SETUP_OPTION_SELECTION;

      if (quickSetupStatusData.calendar) {
        setCurrentFlow(QuickSetupStep.CALENDAR_SELECTION);
        setMetaData(quickSetupStatusData.calendar.metaData);
        mappedStep = mapQuickSetupCalendarStepsFromAPIToQuickSetupStep(
          quickSetupStatusData.calendar.value,
        );
      }

      if (quickSetupStatusData.survey) {
        setCurrentFlow(QuickSetupStep.SURVEY_TEAM);
        setMetaData(quickSetupStatusData.survey.metaData);
        mappedStep = mapSurveyStepsEnumFromAPIToQuickSetupStep(
          quickSetupStatusData.survey.value,
        );
      }

      setCurrentStep(mappedStep);
      setCurrentQuicksetupState(mappedStep);
    }
  }, [
    quickSetupStatusData,
    setSelectedTemplateName,
    setCurrentQuicksetupState,
  ]);

  useEffect(() => {
    if (
      !isLoading &&
      data &&
      data.member.isQuickSetupComplete &&
      currentStep !== QuickSetupStep.COMPLETION
    ) {
      history.push(V2_HOME);
    }
  }, [currentStep, data, history, isLoading]);

  const [invitedMembers, setInvitedMembers] = useState<string[]>([]);
  const handleInviteNewMembersChange = useCallback((val: string[]) => {
    setInvitedMembers(val);
  }, []);

  return {
    models: {
      flow,
      duration,
      meetingId,
      calenderId,
      meetingName,
      providerName,
      currentStep,
      invitedMembers,
      currentQueryStep,
      selectedTemplate,
      meetingReccurence,
      meetingParticipants,
      calendarConnectionId,
      isInviteButtonDisabled,
      isWorkatoSetupComplete,
      isInviteMembersSuccess,
      isLoadingQuickSetupStatus,
    },
    operations: {
      handleMeetingChange,
      handleTemplateChange,
      handleCalenderIdChange,
      handleCurrentFlowChange,
      handleCurrentStepChange,
      handleInviteMemberStatus,
      handleInviteNewMembersChange,
      handleCalendarConnectionChange,
      handleMeetingParticipantsChange,
    },
  };
};

export default useQuickSetupController;
