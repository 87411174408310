import styled from 'styled-components';
import ThemeV2 from '../../../../componentsV2/theme';
import { Flex } from '../../../../Utils/styles/display';
import Clickable from '../../../atoms/Clickable';
import Image from '../../../atoms/Image';
import Button from '../../../atoms/Button';
import SVGIcon from '../../../atoms/SVGIcon';

export const Wrapper = styled(Flex)`
  border: 1px solid ${ThemeV2.palette.gray4};
  border-radius: 4px;
  padding: 3px 4px 3px 10px;
  width: 100%;
`;

export const StyledClickableWrapper = styled(Clickable)`
  display: flex;
  align-items: center;
`;

export const StyledPlaceholderImage = styled(Image)`
  margin-right: 8px;
`;

export const StyledChevronSVGIcon = styled(SVGIcon)`
  transform: translateY(1px);
  margin-left: 4px;
`;

export const StyledRefreshButton = styled(Button)`
  margin-left: auto;
`;
