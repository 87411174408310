import produce from 'immer';
import { useQueryClient, useMutation, InfiniteData } from 'react-query';

import {
  CREATE_FLOW_POST_COMMENT,
  CREATE_POST_COMMENT,
  GET_FLOW_POST_COMMENTS,
  GET_POST_COMMENTS,
  GET_PROFILE_INFO,
  GET_SINGLE_POST,
  V3_GET_FEED,
  V3_SEARCH_FEED,
  GET_MAIN_FEED,
  GET_FLOW_FEED,
  GET_PROFILE_FEED,
  GET_FLOW_RESPONSE,
} from '../../constants/endpoints';
import {
  FeedCommentFromAPI,
  GetCommentByPostResponse,
} from '../../interfaces/Feed';
import { GetProfileInfoResponse } from '../Profile';
import { makeAPICall } from '../utils';
import { MENTION_REGEX_GLOBAL } from '../../Utils/text';
import { PostTypes } from '../../interfaces/Home';

export interface CreatePostCommentPayload {
  postId: string;
  text?: string;
  gifUrl?: string;
  points?: number;
  postType?: PostTypes;
  flowId?: string;
  responseId?: string;
  mentions?: string[];
}

interface SelectedMention {
  id: string;
  name: string;
}

interface CreatePostCommentOptimisticPayload extends CreatePostCommentPayload {
  selectedMentions: SelectedMention[];
}

const generateApiPayload = (
  optimisticPayload: CreatePostCommentOptimisticPayload,
): CreatePostCommentPayload => {
  const { gifUrl, points, postId, text, selectedMentions } = optimisticPayload;

  const filteredMentions = selectedMentions.filter((mention) =>
    text?.includes(mention.id),
  );

  return {
    gifUrl,
    points,
    postId,
    mentions: filteredMentions.map((mention) => mention.id),
    ...(text && { text }),
  };
};

const formatComment = (
  comment: string,
  selectedMentions: SelectedMention[],
) => {
  if ((comment || comment === '') && comment.replaceAll) {
    return comment.replaceAll(MENTION_REGEX_GLOBAL, (match) => {
      const mentionedMember = selectedMentions.find((member) =>
        match.includes(member.id),
      );

      return mentionedMember ? mentionedMember.name : '';
    });
  } else {
    console.log('Comment Formatting Error', comment);
    return comment;
  }
};

const generateOptimisticComment = (
  newCommentPayload: CreatePostCommentOptimisticPayload,
  profileInfoResponse: GetProfileInfoResponse,
): FeedCommentFromAPI => {
  const { memberId, totalPointsGiven } = profileInfoResponse.member;
  const { firstName, image, lastName, username } =
    profileInfoResponse.member.profile;

  const formattedComment = formatComment(
    newCommentPayload.text || '',
    newCommentPayload.selectedMentions,
  );

  return {
    commentID: 'OPTIMISTIC',
    message: formattedComment,
    postID: newCommentPayload.postId,
    fromMember: {
      firstName,
      lastName,
      username,
      memberID: memberId,
      image,
      pointsGiven: totalPointsGiven,
      isDeleted: false,
    },
    taggedUsers: [],
    reactions: [],
    pointsEach: newCommentPayload.points || 0,
    isDeleted: false,
    gifURL: newCommentPayload.gifUrl || '',
    imageURL: '',
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  };
};

const addCommentToPostComments = (
  comment: FeedCommentFromAPI,
  postComments: InfiniteData<GetCommentByPostResponse>,
): InfiniteData<GetCommentByPostResponse> => {
  const updatedPostComments = produce(postComments, (draft) => {
    const pageLength = draft.pages.length;
    const lastPage = draft.pages[pageLength - 1];
    lastPage.data.push(comment);
    lastPage.total += 1;
  });

  return updatedPostComments;
};

const addCommentToEmptyPostComments = (
  comment: FeedCommentFromAPI,
): InfiniteData<GetCommentByPostResponse> => ({
  pages: [
    {
      data: [comment],
      metadata: {
        pagination: {
          cursor: {
            previous: 'previous',
            next: 'next',
          },
        },
      },
      total: 1,
    },
  ],
  pageParams: [],
});

const useCreatePostComment = (
  onSuccessCallback: () => void,
  onErrorCallback: (error: unknown) => void,
) => {
  const queryClient = useQueryClient();
  return useMutation(
    (optimisticPayload: CreatePostCommentOptimisticPayload) => {
      const apiPayload = generateApiPayload(optimisticPayload);
      const { postType, responseId, flowId } = optimisticPayload;
      if (postType && postType === PostTypes.FLOW) {
        if (flowId && responseId) {
          return makeAPICall(CREATE_FLOW_POST_COMMENT, apiPayload, undefined, {
            flowId,
            responseId,
          });
        }
      }
      return makeAPICall(CREATE_POST_COMMENT, apiPayload, undefined, {
        postId: optimisticPayload.postId,
      });
    },
    {
      onMutate: async (
        newCommentPayload: CreatePostCommentOptimisticPayload,
      ) => {
        let queryKey = [GET_POST_COMMENTS, newCommentPayload.postId];
        const { postType, responseId, flowId } = newCommentPayload;
        if (postType && postType === PostTypes.FLOW) {
          if (flowId && responseId) {
            queryKey = [GET_FLOW_POST_COMMENTS, flowId, responseId];
          }
        }
        await queryClient.cancelQueries(queryKey);
        const previousPostComments =
          queryClient.getQueryData<InfiniteData<GetCommentByPostResponse>>(
            queryKey,
          );

        const profileInfoResponse =
          queryClient.getQueryData<GetProfileInfoResponse>(GET_PROFILE_INFO);

        if (profileInfoResponse) {
          const optimisticComment = generateOptimisticComment(
            newCommentPayload,
            profileInfoResponse,
          );

          let optimisticPostComments: InfiniteData<GetCommentByPostResponse>;
          if (previousPostComments) {
            optimisticPostComments = addCommentToPostComments(
              optimisticComment,
              previousPostComments,
            );
          } else {
            optimisticPostComments =
              addCommentToEmptyPostComments(optimisticComment);
          }

          queryClient.setQueryData(queryKey, optimisticPostComments);
        }

        return { previousPostComments };
      },
      onSuccess: (data, { postId, points, flowId, responseId }) => {
        //  TODO: Need to optimise this
        queryClient.invalidateQueries(V3_GET_FEED);
        queryClient.invalidateQueries(GET_MAIN_FEED);
        queryClient.invalidateQueries(GET_FLOW_FEED);
        queryClient.invalidateQueries(GET_PROFILE_FEED);
        queryClient.invalidateQueries([GET_POST_COMMENTS, postId]);
        queryClient.invalidateQueries([
          GET_FLOW_POST_COMMENTS,
          flowId,
          responseId,
        ]);
        queryClient.invalidateQueries([V3_SEARCH_FEED]);
        queryClient.invalidateQueries([GET_SINGLE_POST, postId]);
        queryClient.invalidateQueries([GET_FLOW_RESPONSE, flowId, responseId]);
        if (points && points > 0) {
          queryClient.invalidateQueries(GET_PROFILE_INFO);
        }
        onSuccessCallback();
      },
      onError: (error, variables, context) => {
        const { postType, responseId, flowId, postId } = variables;
        let queryKey = [GET_POST_COMMENTS, postId];
        if (postType && postType === PostTypes.FLOW) {
          if (flowId && responseId) {
            queryKey = [GET_FLOW_POST_COMMENTS, flowId, responseId];
          }
        }
        if (context?.previousPostComments) {
          queryClient.setQueryData(queryKey, context.previousPostComments);
        } else {
          queryClient.setQueryData(queryKey, undefined);
        }
        onErrorCallback(error);
      },
    },
  );
};

export default useCreatePostComment;
