import isEmpty from 'lodash/isEmpty';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import ThemeV2 from '../../../../componentsV2/theme';
import {
  getTaskCategoryBasedOnDueDate,
  getTaskItemDueDate,
} from '../../../../controllers/notebook/NotebookPageController/utils';
import useToggle from '../../../../hooks/useToggle';
import {
  Assignee,
  MiscTaskCategories,
  TaskCategories,
} from '../../../../interfaces/notebook';
import {
  DRAG_TOOLTIP_LABEL,
  TASK_CREATED_BY,
  TASK_CREATION_ERROR,
  TRY_AGAIN,
} from '../../../../languages/en/notebook';
import { ON, YOU } from '../../../../languages/en/singleWords';
import useNotebookStore from '../../../../stores/notebookStore';
import { NOTEBOOK_TASK_EVENTS } from '../../../../Utils/analytics/constants';
import {
  convertStringToTokenizedObject,
  convertTokenizedObjectToString,
} from '../../../../Utils/notebook';
import SVGIcon from '../../../atoms/SVGIcon';
import TaskItemDatePicker from '../../../molecules/Notebook/TaskItem/TaskItemDatePicker';
import TaskItemTextArea from '../../../molecules/Notebook/TaskItem/TaskItemTextArea';
import {
  CreatedByName,
  CreatedByText,
  DragIconWithTooltip,
  DragIconWrapper,
  EditIconWrapper,
  EditorFooterText,
  EditorWrapper,
  ErrorText,
  ErrorWrapper,
  StyledCheckbox,
  StyledDropdownIconButton,
  StyledTaskContent,
  StyledTiptapEditorWrapper,
  TaskItemWrapper,
  TryAgain,
} from './styles';
import { TaskItemProps } from './types';
// eslint-disable-next-line max-len
import format from 'date-fns/format';
import { motion } from 'framer-motion';
import { FORMAT_DATE } from '../../../../constants/notebook';
// eslint-disable-next-line max-len
import { opacityAnimationProps } from '../../../../controllers/notebook/NotebookPageController/useNotebookPageController/data';
import { getDateForTimeZone } from '../../../../Utils/date';
import { Flex } from '../../../../Utils/styles/display';
import { getFullName } from '../../../../Utils/user';
import TaskItemAssigneeSelector from '../../../molecules/Notebook/TaskItemAssigneeSelector';

const TaskItem = ({
  currentUserId,
  index,
  hasError,
  type,
  highlight = false,
  dropdownOptions,
  taskId,
  searchValue,
  assigneesList,
  hasMoreAssigneesToFetch,
  isFetchingMoreAssignees,
  currentUserTimezone,
  shouldShowModalForCurrentUser,
  onEditorUpdate,
  updateTask,
  onTaskDataChange,
  onTaskClick,
  setTaskIdOfFocusedTask,
  fetchMoreAssignees,
  handleSearchOnAssigneeList,
  handleAssigneeChange,
  markTasksAsRead,
  handleCreatorLinkClick,
}: TaskItemProps) => {
  const task = useNotebookStore(
    useCallback((store) => store.allNotes[taskId], [taskId]),
  );

  const [render, setRender] = useState(false);

  const {
    createdAt,
    note,
    dueDate,
    isDeleted,
    stateEffectiveAt,
    assignedTo,
    noteId,
    createdBy,
    isUnread,
  } = task;

  const createdByName = createdBy
    ? createdBy.memberID === currentUserId
      ? YOU.toLocaleLowerCase()
      : getFullName(createdBy)
    : YOU.toLocaleLowerCase();

  const serializedTitle = useMemo(
    () => convertTokenizedObjectToString(note),
    [note],
  );

  useEffect(() => {
    setRender(true);
  }, []);

  useEffect(() => {
    onTaskDataChange({
      action: NOTEBOOK_TASK_EVENTS.NOTEBOOK_TASK_SHOW,
      originalTask: task,
      originalCategory: type,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dateBasedOnCategory = useMemo(() => {
    if (
      type === TaskCategories.COMPLETED ||
      type === TaskCategories.ARCHIVED ||
      isDeleted
    )
      return stateEffectiveAt;
    else return dueDate;
  }, [dueDate, isDeleted, stateEffectiveAt, type]);

  const {
    models: { toggleValue: isInEditMode },
    operations: {
      setToggleToFalse: setEditModeToFalse,
      setToggleToTrue: setEditModeToTrue,
    },
  } = useToggle(isEmpty(note));

  const [isTaskComplete, setTaskCompletionStatus] = useState(
    type === TaskCategories.COMPLETED,
  );

  const toggleCheckbox = useCallback(() => {
    // calculate newCategory based on due date in the future
    const newCategory =
      type !== TaskCategories.COMPLETED
        ? TaskCategories.COMPLETED
        : MiscTaskCategories.INCOMPLETE;
    // UX -> First strike-through or remove it based on current type.
    // Wait for few milliseconds.
    // Then, transition state to COMPLETED animation. Hence, setTimeout.
    setTaskCompletionStatus(newCategory === TaskCategories.COMPLETED);
    if (newCategory === TaskCategories.COMPLETED) setRender(false);

    setTimeout(() => {
      updateTask(task, type, newCategory, (toggle: boolean) => {
        setTaskCompletionStatus(toggle);
      });
    }, 700);
  }, [task, type, updateTask]);

  const handleTaskUpdate = useCallback(
    (content: string) => {
      if (content && content !== serializedTitle) {
        onEditorUpdate({
          ...task,
          note: convertStringToTokenizedObject(content),
        });
      }
      setEditModeToFalse();
    },
    [onEditorUpdate, serializedTitle, setEditModeToFalse, task],
  );

  const handleDueDateChange = useCallback(
    (updatedDueDate: string | undefined) => {
      const updatedTask = { ...task, dueDate: updatedDueDate };
      const updatedCategory = getTaskCategoryBasedOnDueDate(
        updatedDueDate,
        currentUserTimezone,
      );
      updateTask(updatedTask, type, updatedCategory);
      onTaskDataChange({
        action: NOTEBOOK_TASK_EVENTS.NOTEBOOK_TASK_DUE_DATE_UPDATE,
        originalTask: task,
        originalCategory: type,
        updatedTask,
        updatedCategory,
      });
      setEditModeToFalse();
    },
    [
      setEditModeToFalse,
      task,
      type,
      currentUserTimezone,
      updateTask,
      onTaskDataChange,
    ],
  );

  const handleAssigneeSelection = useCallback(
    (assignee: Assignee) => {
      const updatedTask = { ...task, assignedTo: assignee };
      if (!shouldShowModalForCurrentUser) {
        // Animations need 700ms only when modal need not be opened
        setRender(assignee?.memberID !== currentUserId);
        setTimeout(() => {
          handleAssigneeChange(updatedTask, assignedTo, assignee);
        }, 700);
      } else {
        handleAssigneeChange(updatedTask, assignedTo, assignee);
      }
    },
    [
      assignedTo,
      currentUserId,
      handleAssigneeChange,
      shouldShowModalForCurrentUser,
      task,
    ],
  );

  const handleMovementThroughMenu = useCallback(
    (value: string | number) => {
      const selectedType = value as TaskCategories;
      setTaskCompletionStatus(selectedType === TaskCategories.COMPLETED);
      setRender(false);
      setTimeout(() => {
        updateTask(
          {
            ...task,
            dueDate: getTaskItemDueDate(selectedType, currentUserTimezone),
          },
          type,
          selectedType,
          (toggle: boolean) => {
            setTaskCompletionStatus(toggle);
          },
        );
      }, 700);
    },
    [task, type, updateTask, currentUserTimezone],
  );

  const handleEditClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      setEditModeToTrue();
      e.stopPropagation();
    },
    [setEditModeToTrue],
  );

  const renderEditor = () => {
    if (isInEditMode) {
      return (
        <StyledTiptapEditorWrapper>
          <TaskItemTextArea
            serializedTitle={serializedTitle}
            onBlur={handleTaskUpdate}
            handleEnterClick={handleTaskUpdate}
            hasHoverEffect={false}
          />
        </StyledTiptapEditorWrapper>
      );
    }
    return (
      <div>
        <EditIconWrapper className="hover-icons" onClick={handleEditClick}>
          <SVGIcon
            icon="edit"
            size="14px"
            color={ThemeV2.palette.gray8}
            hoverColor={ThemeV2.palette.geekBlue6}
          />
        </EditIconWrapper>
        <StyledTaskContent
          strikeThroughOverride={
            isTaskComplete || type === TaskCategories.ARCHIVED
          }
          content={note}
          disabled={type === TaskCategories.ARCHIVED}
          noPadding
          hasHoverEffect={false}
        />
      </div>
    );
  };

  const renderCheckbox = () => (
    <StyledCheckbox
      name={`checkbox-${taskId}`}
      value={isTaskComplete}
      onChange={toggleCheckbox}
      size="20px"
      disabled={type === TaskCategories.ARCHIVED}
      stopClickPropagation
    />
  );

  const animationStyleProps = useMemo(
    () =>
      !render
        ? {
            animation: `fadeOut 2s`,
          }
        : {},
    [render],
  );

  return (
    <div
      style={animationStyleProps}
      onMouseEnter={() => {
        if (isUnread) {
          markTasksAsRead(noteId, type);
        }
      }}
    >
      <Draggable draggableId={String(taskId)} index={index}>
        {(provided, snapshot) => (
          <motion.div {...opacityAnimationProps}>
            <TaskItemWrapper
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              highlight={highlight}
              data-testid={`task-${taskId}`}
              isDragging={snapshot.isDragging}
              onClick={(e) => {
                if (e.defaultPrevented) {
                  return;
                }
                if (task.noteId) {
                  onTaskClick(task.noteId);
                }
              }}
              isUnread={isUnread}
            >
              <DragIconWrapper>
                <DragIconWithTooltip
                  className="hover-icons"
                  icon="drag-reorder"
                  iconSize="14px"
                  iconColor={ThemeV2.palette.gray8}
                  iconHoverColor={ThemeV2.palette.geekBlue6}
                  position="top"
                >
                  {DRAG_TOOLTIP_LABEL}
                </DragIconWithTooltip>
              </DragIconWrapper>

              {renderCheckbox()}
              <EditorWrapper>
                {renderEditor()}
                <Flex>
                  <TaskItemDatePicker
                    taskType={type}
                    dueDate={dateBasedOnCategory}
                    setDueDate={handleDueDateChange}
                    stopEventPropagation
                    isDeleted={false}
                    currentUserTimezone={currentUserTimezone}
                  />
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      setTaskIdOfFocusedTask(noteId);
                    }}
                  >
                    <TaskItemAssigneeSelector
                      assigneeList={assigneesList || []}
                      isLoading={isFetchingMoreAssignees}
                      onMemberClick={handleAssigneeSelection}
                      hasMoreUsersToFetch={hasMoreAssigneesToFetch}
                      canPopoverTriggerByClick
                      position
                      fetchMoreMembers={fetchMoreAssignees}
                      id={taskId}
                      assignedTo={assignedTo}
                      searchValue={searchValue}
                      handleSearchOnAssigneeList={handleSearchOnAssigneeList}
                      setTaskIdOfFocusedTask={setTaskIdOfFocusedTask}
                      currentUserId={currentUserId}
                    />
                  </div>
                </Flex>
                <EditorFooterText>
                  <CreatedByText inline variant="body3" color="gray7">
                    {`${TASK_CREATED_BY}`}
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        handleCreatorLinkClick({
                          memberId: createdBy?.memberID || '',
                          firstName: createdBy?.firstName,
                        });
                      }}
                    >
                      <CreatedByName
                        inline
                        variant="body3"
                        color="geekBlue6"
                      >{` ${createdByName}`}</CreatedByName>
                    </span>
                    {createdAt &&
                      ` ${ON} ${format(
                        getDateForTimeZone(createdAt, currentUserTimezone),
                        FORMAT_DATE,
                      )} ${hasError ? ' • ' : ''}`}
                  </CreatedByText>
                  {hasError && (
                    <ErrorWrapper>
                      <SVGIcon
                        icon="warning-hollow"
                        size="16px"
                        color={ThemeV2.palette.dustRed6}
                      />
                      <ErrorText inline variant="body3" color="dustRed6">
                        {TASK_CREATION_ERROR}
                        <TryAgain
                          onClick={() => {
                            updateTask(task, type, type);
                          }}
                        >
                          {TRY_AGAIN}
                        </TryAgain>
                      </ErrorText>
                    </ErrorWrapper>
                  )}
                </EditorFooterText>
              </EditorWrapper>
              {!isEmpty(task.note) && (
                <StyledDropdownIconButton
                  menuItems={dropdownOptions || []}
                  icon="more"
                  title="more"
                  onItemClick={handleMovementThroughMenu}
                  iconColor={ThemeV2.palette.gray8}
                  rounded
                  stopEventPropagation
                />
              )}
            </TaskItemWrapper>
          </motion.div>
        )}
      </Draggable>
    </div>
  );
};

const MemoizedTaskItem = React.memo(TaskItem);

MemoizedTaskItem.displayName = 'TaskItem';

export default TaskItem;
