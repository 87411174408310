import { EmojiData } from 'emoji-mart';
import React, { memo, useCallback, useContext, useMemo } from 'react';
import styled from 'styled-components';
import { MainFeedContext } from '../../../controllers/home/MainFeedController/newIndex';
import {
  FeedCommentFromAPI,
  FeedItemFromAPI,
  Reaction,
} from '../../../interfaces/Feed';

import { SEE_MORE_COMMENTS } from '../../../languages/en/feed';
import { GetProfileInfoResponse } from '../../../queries/Profile';
import { getCanDeletePosts } from '../../../queries/Profile/utils';
import { Flex } from '../../../Utils/styles/display';
import { isUserAdmin } from '../../../Utils/user';
import Button from '../../atoms/Button';
import Comment from '../Comment/newIndex';

const CommentsWrapper = styled.div`
  max-height: 500px;
  overflow-y: auto;
`;

export interface NewFeedPostCommentsProps {
  commentsData: FeedCommentFromAPI[];
  isDummy?: boolean;
  showMoreCommentsButton: boolean;
  onShowMoreCommentsButtonClick: () => void;
  post: FeedItemFromAPI;
  profileData: GetProfileInfoResponse;
}

const FeedPostComments = ({
  post,
  profileData,
  commentsData,
  isDummy = false,
  showMoreCommentsButton = true,
  onShowMoreCommentsButtonClick,
}: NewFeedPostCommentsProps) => {
  const {
    onCommentEmoticonClick,
    onCommentReactionToggleClick,
    onDeleteCommentClick,
    onCopyCommentLink,
    onMemberClick,
  } = useContext(MainFeedContext);

  const { assemblyCurrency, currentUserId, isCurrentUserAdmin, canDelete } =
    useMemo(() => {
      return {
        assemblyCurrency: profileData.assembly.currency,
        currentUserId: profileData.member.memberId,
        isCurrentUserAdmin: isUserAdmin(profileData.member),
        canDelete: getCanDeletePosts(profileData),
      };
    }, [profileData]);

  const handleCopyCommentLink = useCallback(
    (commentId: string) => {
      onCopyCommentLink(commentId, post);
    },
    [onCopyCommentLink, post],
  );

  const handleCommentReactionToggleClick = useCallback(
    (reaction: Reaction, currentContentId: string) =>
      onCommentReactionToggleClick(reaction, currentContentId, post),
    [onCommentReactionToggleClick, post],
  );

  const handleDeleteCommentClick = useCallback(
    (commentId: string, hasTrophies: boolean, deleteCommentOption: string) =>
      onDeleteCommentClick(commentId, hasTrophies, deleteCommentOption, post),
    [onDeleteCommentClick, post],
  );

  const handleEmoticonSelect = useCallback(
    (reactions: Reaction[], emoji: EmojiData, contentID: string) =>
      onCommentEmoticonClick(reactions, emoji, contentID, post),
    [onCommentEmoticonClick, post],
  );

  return (
    <>
      {showMoreCommentsButton && (
        <Flex justifyContent="center" padding="16px 0">
          <Button
            icon="prompt"
            onClick={onShowMoreCommentsButtonClick}
            variant="showMore"
            size="small"
          >
            {SEE_MORE_COMMENTS}
          </Button>
        </Flex>
      )}
      <CommentsWrapper>
        {commentsData.map((comment) => (
          <Comment
            key={comment.commentID}
            isDummy={isDummy}
            comment={comment}
            assemblyCurrency={assemblyCurrency}
            currentUserId={currentUserId}
            isCurrentUserAdmin={isCurrentUserAdmin}
            onMemberClick={onMemberClick}
            onCopyLink={handleCopyCommentLink}
            onDeleteComment={handleDeleteCommentClick}
            onReactionToggleClick={handleCommentReactionToggleClick}
            onEmoticonSelect={handleEmoticonSelect}
            canDelete={canDelete}
          />
        ))}
      </CommentsWrapper>
    </>
  );
};

export default memo(FeedPostComments);
