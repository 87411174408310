import React from 'react';
import { FLOWS_END_OCCURRENCE_MODAL } from '../../../languages/en/flows';
import { CANCEL, END, OCCURRENCE } from '../../../languages/en/singleWords';
import BasicModalTemplate from '../../templates/BasicModalTemplate';
import { FlowEndOccurrenceModalProps } from './types';
import { StyledBody } from './styles';

const FlowEndOccurrenceModal = (props: FlowEndOccurrenceModalProps) => {
  const {
    className,
    isOpen,
    heading,
    onModalClose,
    onEndOccurrenceClick,
    onCancelClick,
    isLoading = false,
  } = props;

  return (
    <BasicModalTemplate
      className={className}
      isOpen={isOpen}
      heading={heading}
      primaryButton={{
        text: `${END} ${OCCURRENCE}`,
        status: 'warning',
        onClick: onEndOccurrenceClick,
        isLoading,
      }}
      secondaryButton={{
        text: CANCEL,
        onClick: onCancelClick,
      }}
      onModalClose={onModalClose}
    >
      <StyledBody variant="body2">
        {FLOWS_END_OCCURRENCE_MODAL.modalContentText}
      </StyledBody>
    </BasicModalTemplate>
  );
};
export default FlowEndOccurrenceModal;
