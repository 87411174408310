import React, { memo } from 'react';
import NotebookTaskDetails, {
  ReadOnlyComponentStatus,
} from '../../../atomic/organism/Notebook/NotebookTaskDetails';
import { ComponentStatus } from '../../../interfaces/component';
import useNotebookRightDrawerController from './useNotebookRightDrawerController';

const NotebookRightDrawerController = () => {
  const {
    currentUserId,
    onUpdateTask,
    editTaskContent,
    fetchMoreAssignees,
    handleAssigneeChange,
    setTaskIdOfFocusedTask,
    setSearchValueForAssignees,
    note,
    selectedTaskId,
    currentTab,
    isLoading,
    assigneesList,
    hasMoreAssigneesToFetch,
    isFetchingMoreAssignees,
    searchValueForAssignees,
    currentUserTimezone,
    isTaskHistoryLoading,
    taskHistoryList,
    fetchMoreLogs,
    hasMoreLogsToFetch,
    isFetchingMoreLogs,
    historyLogSort,
    handleUserClick,
    toggleHistoryLogSort,
    isError,
    isAccessError,
  } = useNotebookRightDrawerController();

  if (!selectedTaskId) {
    return <NotebookTaskDetails status={ReadOnlyComponentStatus.READ_ONLY} />;
  }

  if (!note && isLoading) {
    return <NotebookTaskDetails status={ComponentStatus.LOADING} />;
  }

  if ((isError && selectedTaskId && !isLoading) || (!note && !isLoading)) {
    return (
      <NotebookTaskDetails
        isAccessError={isAccessError}
        status={ComponentStatus.ERROR}
      />
    );
  }

  return (
    <NotebookTaskDetails
      historyLogSort={historyLogSort}
      toggleHistoryLogSort={toggleHistoryLogSort}
      currentUserId={currentUserId}
      task={note}
      editTaskContent={editTaskContent}
      handleTaskUpdate={onUpdateTask}
      status={ComponentStatus.LOADED}
      setTaskIdOfFocusedTask={setTaskIdOfFocusedTask}
      fetchMoreAssignees={fetchMoreAssignees}
      assigneesList={assigneesList}
      hasMoreAssigneesToFetch={hasMoreAssigneesToFetch || false}
      isFetchingMoreAssignees={isFetchingMoreAssignees || false}
      handleSearchOnAssigneeList={setSearchValueForAssignees}
      searchValue={searchValueForAssignees}
      tab={currentTab}
      handleAssigneeChange={handleAssigneeChange}
      isTaskHistoryLoading={isTaskHistoryLoading}
      taskHistoryList={taskHistoryList}
      fetchMoreLogs={fetchMoreLogs}
      hasMoreLogsToFetch={hasMoreLogsToFetch}
      isFetchingMoreLogs={isFetchingMoreLogs}
      currentUserTimezone={currentUserTimezone}
      handleUserClick={handleUserClick}
    />
  );
};

export default memo(NotebookRightDrawerController);
