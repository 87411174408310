import copy from 'copy-to-clipboard';
import { EmojiData } from 'emoji-mart';
import { useCallback, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { DownloadBlockType } from '../../atomic/organism/FlowPostBody/types';
import {
  DeletePostClickType,
  FeedContextState,
  FeedItemFromAPI,
  MemberInteractionType,
  PostSortOptions,
  Reaction,
  UpdateReactionAction,
} from '../../interfaces/Feed';
import { PostTypes } from '../../interfaces/Home';
import { LayoutStoreState } from '../../interfaces/Layout';
import { COPIED_TO_CLIPBOARD } from '../../languages/en/flows';
import {
  COMMENT_DELETION_ERROR_MESSAGE,
  COMMENT_DELETION_INPROGRESS_MESSAGE,
  COMMENT_DELETION_SUCCESS_MESSAGE,
  POST_DELETION_ERROR_MESSAGE,
  POST_DELETION_INPROGRESS_MESSAGE,
  POST_DELETION_SUCCESS_MESSAGE,
} from '../../languages/en/giveRecognitionForm';
import { GIVE_TROPHIES_BACK_TO_SENDER } from '../../languages/en/home';
import { useLegacyDeletePost } from '../../queries/Feed';
import {
  useAdminDeleteComment,
  useUpdateCommentReactionMutation,
} from '../../queries/Feed/comments';
import { useDeleteFlowPost } from '../../queries/Flows/Feed';
import { useProfileInfoFetchQuery } from '../../queries/Profile';
import useLayoutStore from '../../stores/layoutStore';
import { downloadFeedFile } from '../../Utils/flows';
import {
  getCommonFlowPostAttributes,
  getFlowFeedUrl,
  getFlowPostUrl,
  getRecognitionPostUrl,
} from '../../Utils/home/feeds';
import {
  getEmojiPayload,
  serializeReactionTogglePayload,
} from '../../Utils/serializers/feed';
import {
  dismissAllToasts,
  showErrorMessage,
  showInfoMessage,
  showSuccessMessage,
} from '../../Utils/toast';
import { useFeedReactions } from '../useFeedReactions/newIndex';
import useGetParsedFilterOptions from '../useGetParsedFilterOptions';
import useToggle from '../useToggle';

const layoutSelector = (state: LayoutStoreState) =>
  state.setMemberAndToggleRightAsideOpen;

const useFlowFeed = (onPostDelete?: () => void) => {
  const history = useHistory();
  const { filter } = useGetParsedFilterOptions();
  const [feedsSortValue, setFeedsSortValue] = useState<PostSortOptions>(
    PostSortOptions.DESC,
  );
  const {
    models: { toggleValue: showLoadMoreButton },
    operations: {
      setToggleToTrue: setShowLoadMoreButtonToTrue,
      setToggleToFalse: setShowLoadMoreButtonToFalse,
    },
  } = useToggle();
  const {
    data: profileData,
    isError: isProfileDataError,
    isLoading: isProfileInfoLoading,
  } = useProfileInfoFetchQuery();

  const setMemberAndToggleRightAsideOpen = useLayoutStore(layoutSelector);

  const onMemberClick = useCallback(
    (person: MemberInteractionType) => {
      setMemberAndToggleRightAsideOpen({
        memberId: person.memberID,
        firstName: person.firstName,
      });
    },
    [setMemberAndToggleRightAsideOpen],
  );

  const onFlowClick = useCallback(
    (flowId: string) => history.push(getFlowFeedUrl(flowId)),
    [history],
  );

  // TODO: FEED REFACTOR
  // MAKE THIS HOOK GENERIC ENOUGH TO HANDLE ALL FLOW FEEDS
  const { onReactionSet, onReactionUnSet } = useFeedReactions(
    feedsSortValue,
    profileData,
    filter,
  );

  const handleAdminDeletePostSuccess = useCallback(() => {
    dismissAllToasts();
    showSuccessMessage(POST_DELETION_SUCCESS_MESSAGE);
    if (onPostDelete) {
      onPostDelete();
    }
  }, [onPostDelete]);

  const handleAdminDeletePostError = useCallback(() => {
    dismissAllToasts();
    showErrorMessage(POST_DELETION_ERROR_MESSAGE);
  }, []);

  const { mutate: deleteRecognitionPost } = useLegacyDeletePost(
    handleAdminDeletePostSuccess,
    handleAdminDeletePostError,
  );
  const { mutate: deleteFlowPost } = useDeleteFlowPost(
    handleAdminDeletePostSuccess,
    handleAdminDeletePostError,
  );

  const onDeletePost: DeletePostClickType = useCallback(
    (
      {
        hasTrophies,
        currentFlowId,
        currentPostType,
        currentResponseId,
        currentPostId,
      },
      deletePostCarrotOption,
    ) => {
      let sendTrophiesVariable = false;
      if (
        hasTrophies &&
        deletePostCarrotOption === GIVE_TROPHIES_BACK_TO_SENDER
      ) {
        sendTrophiesVariable = true;
      }
      dismissAllToasts();
      showInfoMessage(POST_DELETION_INPROGRESS_MESSAGE);
      if (
        currentPostType === PostTypes.FLOW &&
        currentFlowId &&
        currentResponseId
      ) {
        deleteFlowPost({
          flowId: currentFlowId,
          responseId: currentResponseId,
          returnPoints: sendTrophiesVariable,
        });
      } else {
        deleteRecognitionPost({
          postId: currentPostId,
          isWithCarrots: sendTrophiesVariable,
        });
      }
    },
    [deleteFlowPost, deleteRecognitionPost],
  );

  const handleAdminDeleteCommentSuccess = useCallback(() => {
    dismissAllToasts();
    showSuccessMessage(COMMENT_DELETION_SUCCESS_MESSAGE);
  }, []);

  const handleAdminDeleteCommentError = useCallback(() => {
    showErrorMessage(COMMENT_DELETION_ERROR_MESSAGE);
  }, []);

  const { mutate: deleteComment } = useAdminDeleteComment(
    handleAdminDeleteCommentSuccess,
    handleAdminDeleteCommentError,
  );

  const onDeleteCommentClick = useCallback(
    (
      commentId: string,
      hasTrophies: boolean,
      deleteCommentOption: string,
      post: FeedItemFromAPI,
    ) => {
      dismissAllToasts();
      showInfoMessage(COMMENT_DELETION_INPROGRESS_MESSAGE);
      const obj = getCommonFlowPostAttributes(post);
      deleteComment({
        postId: obj.postId,
        commentId,
        isWithCarrots:
          hasTrophies && deleteCommentOption === GIVE_TROPHIES_BACK_TO_SENDER,
        postType: obj.postType,
        flowId: obj.flowId,
        responseId: obj.responseId,
      });
    },
    [deleteComment],
  );

  const { mutate: updateCommentReaction } = useUpdateCommentReactionMutation();

  const onCommentReactionToggleClick = useCallback(
    (
      { reaction, active }: Reaction,
      currentContentId: string,
      post: FeedItemFromAPI,
    ) => {
      if (profileData) {
        const obj = getCommonFlowPostAttributes(post);
        updateCommentReaction({
          ...serializeReactionTogglePayload(
            reaction,
            currentContentId,
            active ? UpdateReactionAction.UNSET : UpdateReactionAction.SET,
            profileData.member,
          ),
          postID: obj.postId,
          postType: obj.postType,
          flowId: obj.flowId,
          responseId: obj.responseId,
        });
      }
    },
    [profileData, updateCommentReaction],
  );

  const onCommentEmoticonClick = useCallback(
    (
      reactions: Reaction[],
      emoji: EmojiData,
      contentID: string,
      post: FeedItemFromAPI,
    ) => {
      const [selectedReaction, payload] = getEmojiPayload(reactions, emoji);
      // CURRENTLY HANDLING IF AN EMOTICON THAT IS ALREADY SELECTED BY THE USER IS CLICKED
      if (profileData && !selectedReaction?.active) {
        const obj = getCommonFlowPostAttributes(post);
        updateCommentReaction({
          ...serializeReactionTogglePayload(
            payload,
            contentID,
            UpdateReactionAction.SET,
            profileData.member,
          ),
          postID: obj.postId,
          postType: obj.postType,
          flowId: obj.flowId,
          responseId: obj.responseId,
        });
      }
    },
    [profileData, updateCommentReaction],
  );

  const onCopyCommentLink = useCallback(
    (commentId: string, post: FeedItemFromAPI) => {
      const {
        flowId: currentFlowId,
        postType: currentPostType,
        responseId: currentResponseId,
        postId: currentPostId,
      } = getCommonFlowPostAttributes(post);
      let link;
      if (
        currentPostType === PostTypes.FLOW &&
        currentFlowId &&
        currentResponseId
      ) {
        link = getFlowPostUrl(currentFlowId, currentResponseId);
      } else {
        link = getRecognitionPostUrl(currentPostId);
      }
      link = link.concat(`&commentId=${commentId}`);
      copy(link);
      dismissAllToasts();
      showSuccessMessage(COPIED_TO_CLIPBOARD);
    },
    [],
  );

  const onDownloadFileClick = useCallback(
    async (
      fileDownload: DownloadBlockType,
      instanceId: string,
      flowId: string,
    ) => {
      await downloadFeedFile({
        blockId: fileDownload.id,
        responseId: fileDownload.responseId,
        instanceId,
        fileName: fileDownload.fileName,
        flowId,
      });
    },
    [],
  );

  const feedContextProps: FeedContextState = useMemo(
    () => ({
      onReactionSet,
      onReactionUnSet,
      onFlowClick,
      onMemberClick,
      onDeleteCommentClick,
      onCommentReactionToggleClick,
      onCommentEmoticonClick,
      onDeletePost,
      onCopyCommentLink,
      onDownloadFileClick,
    }),
    [
      onReactionSet,
      onReactionUnSet,
      onFlowClick,
      onMemberClick,
      onDeleteCommentClick,
      onCommentReactionToggleClick,
      onCommentEmoticonClick,
      onDeletePost,
      onCopyCommentLink,
      onDownloadFileClick,
    ],
  );

  return {
    onFlowClick,
    filter,
    feedsSortValue,
    setFeedsSortValue,
    feedContextProps,
    showLoadMoreButton,
    setShowLoadMoreButtonToTrue,
    setShowLoadMoreButtonToFalse,
    isProfileDataError,
    isProfileInfoLoading,
    profileData,
  };
};

export default useFlowFeed;
