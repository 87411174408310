import React from 'react';
import styled from 'styled-components';
import InfiniteScroll from 'react-infinite-scroll-component';
import useProfileFeedController from './useProfileFeedController';
import RecognitionFlowController from '../RecognitionFlowController';
import { FeedTypes } from '../../../atomic/molecules/ActionBar/types';
import FlowsFeedPostLoader from '../../flows/FlowsFeedController/Loader';
import ActionBarContainer from '../../../atomic/molecules/ActionBarContainer';
import BottomOfFeedMessage from '../../../atomic/molecules/BottomOfFeedMessage';
import FlowPostController from '../../flows/FlowPostController';

import searching from '../../../img/searching.svg';
import EmptyErrorStateTemplate from '../../../atomic/molecules/EmptyErrorStateTemplate';
import { HeadingType } from '../../../atomic/molecules/EmptyErrorStateTemplate/interfaces';
import { FLOW_FEED_STATES } from '../../../languages/en/flows/feed';
import useGetParsedFilterOptions from '../../../hooks/useGetParsedFilterOptions';

export const FeedsContainer = styled.main`
  max-width: 476px;
  margin: auto;
`;

export const ActionBarWrapper = styled.div`
  margin-bottom: 20px;
`;

const ProfileFeedController = () => {
  const { isProfileFeedFilterApplied } = useGetParsedFilterOptions();

  const { models, operations } = useProfileFeedController();

  const {
    data,
    isLoading,
    currentUser,
    profileUser,
    hasNextPage,
    isOwnProfileFeed,
    isCelebrateTeammateModalOpen,
    profileDataForRecognitionModalDropdown,
  } = models;

  const {
    fetchNextPage,
    onReactionSet,
    onReactionUnSet,
    onMemberClick,
    refetchProfileFeed,
    handleCelebrateTeammateClick,
    toggleIsCelebrateTeammateModalOpen,
  } = operations;

  const renderEmptyState = () => {
    if (isProfileFeedFilterApplied) {
      return (
        <EmptyErrorStateTemplate
          image={searching}
          variant={HeadingType.Bold}
          heading={FLOW_FEED_STATES.NO_MATCHING_POSTS.HEADING}
          subHeading={FLOW_FEED_STATES.NO_MATCHING_POSTS.SUB_HEADING}
        />
      );
    }
    return (
      <EmptyErrorStateTemplate
        image={searching}
        variant={HeadingType.Bold}
        heading={FLOW_FEED_STATES.NO_POSTS.HEADING}
        subHeading={FLOW_FEED_STATES.NO_POSTS.SUB_HEADING}
      />
    );
  };

  return (
    <FeedsContainer>
      {!isOwnProfileFeed && profileUser && currentUser && (
        <ActionBarWrapper>
          <ActionBarContainer
            feedType={FeedTypes.Flow}
            userId={currentUser.member.memberId}
            onClick={handleCelebrateTeammateClick}
            avatar={currentUser.member.profile?.image}
            userName={currentUser.member.profile?.username}
            text={`🎉 Celebrate ${profileUser.profile.firstName} ${profileUser.profile.lastName}`}
          />
        </ActionBarWrapper>
      )}
      {isCelebrateTeammateModalOpen && currentUser && (
        <RecognitionFlowController
          profileInfo={currentUser}
          onPostSuccess={refetchProfileFeed}
          selectedTeammateDropdownItem={profileDataForRecognitionModalDropdown}
          toggleIsRecognitionFlowModalOpen={toggleIsCelebrateTeammateModalOpen}
        />
      )}
      {isLoading && <FlowsFeedPostLoader />}
      {data.length > 0 && (
        <InfiniteScroll
          next={fetchNextPage}
          dataLength={data.length}
          loader={<FlowsFeedPostLoader />}
          hasMore={Boolean(hasNextPage)}
          style={{ overflow: 'inherit' }}
          endMessage={<BottomOfFeedMessage />}
          scrollableTarget="scrollableFeedContent"
        >
          {data.map((feed) => (
            <FlowPostController
              key={feed.key}
              post={feed}
              onReactionSet={onReactionSet}
              onReactionUnSet={onReactionUnSet}
              onMemberClick={onMemberClick}
              profileData={currentUser}
            />
          ))}
        </InfiniteScroll>
      )}
      {!isLoading && data.length === 0 && renderEmptyState()}
    </FeedsContainer>
  );
};

export default ProfileFeedController;
