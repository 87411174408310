import React from 'react';
import { useParams } from 'react-router-dom';

import NewSingleFlowFeedPostController from './NewSingleFlowFeedPostController';
import LegacySingleFlowFeedPostController from './LegacySingleFlowFeedPostController';

const SingleFlowFeedPostController = () => {
  const { postType } = useParams<{ postType: string }>();

  if (postType === 'recognition') {
    return <LegacySingleFlowFeedPostController />;
  }

  return <NewSingleFlowFeedPostController />;
};

export default SingleFlowFeedPostController;
