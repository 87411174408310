import styled from 'styled-components';
import Body from '../../atoms/Body';
import Heading from '../../atoms/Heading';
import ThemeV2 from '../../../componentsV2/theme';

export const StyledHeading = styled(Heading)`
  margin: 0px 0px 8px 0px;
  color: ${ThemeV2.palette.gray9};
`;

export const StyledBody = styled(Body)`
  margin: 0px;
  color: ${ThemeV2.palette.gray8};
`;
