import React from 'react';

import Body from '../../../atomic/atoms/Body';
import { FooterControllerProps } from './type';
import Button from '../../../atomic/atoms/Button';
import useFooterController from './useFooterController';
import { QuickSetupStep } from '../QuickSetupController/types';
import {
  quickSetupButtonTexts,
  quickSetupModalTexts,
} from '../../../languages/en/quickSetup';

import {
  Footer,
  StyledModal,
  FooterWrapper,
  StyledSubHeading,
  StyledMeetingName,
  RightSideButtonsWrapper,
} from './styles';

const FooterController = (props: FooterControllerProps) => {
  const { currentStep, meetingName } = props;
  const {
    models: {
      isModalOpen,
      alignButtons,
      primaryButton,
      tertiaryButton,
      secondaryButton,
      modalPrimaryButtonProps,
      modalSecondaryButtonProps,
    },
    operations: { handleModalClose },
  } = useFooterController(props);

  return (
    <>
      <Footer />
      <FooterWrapper
        justifyContent={alignButtons === 'right' ? 'flex-end' : 'space-between'}
      >
        {currentStep === QuickSetupStep.MEETINGS_LIST ? (
          <StyledMeetingName
            variant="body2"
            color={
              meetingName === '' || meetingName === 'undefined'
                ? 'gray6'
                : 'geekBlue6'
            }
          >
            {meetingName === '' || meetingName === 'undefined'
              ? quickSetupButtonTexts.meetingsListScreenButtons
                  .tertiaryButtonText
              : `${meetingName} selected`}
          </StyledMeetingName>
        ) : (
          tertiaryButton && (
            <Button
              icon={tertiaryButton.icon}
              status={tertiaryButton.status}
              variant={tertiaryButton.variant}
              onClick={tertiaryButton.onClick}
              disabled={tertiaryButton.disabled}
              isLoading={tertiaryButton.isLoading}
              color={tertiaryButton.color ? tertiaryButton.color : 'secondary'}
            >
              {tertiaryButton.text}
            </Button>
          )
        )}

        <RightSideButtonsWrapper
          justifyContent={alignButtons === 'right' ? 'flex-end' : 'flex-start'}
        >
          {secondaryButton && (
            <Button
              icon={secondaryButton.icon}
              status={secondaryButton.status}
              onClick={secondaryButton.onClick}
              variant={secondaryButton.variant}
              disabled={secondaryButton.disabled}
              isLoading={secondaryButton.isLoading}
              color={
                secondaryButton.color ? secondaryButton.color : 'secondary'
              }
            >
              {secondaryButton.text}
            </Button>
          )}
          {primaryButton && (
            <Button
              icon={primaryButton.icon}
              status={primaryButton.status}
              onClick={primaryButton.onClick}
              variant={primaryButton.variant}
              disabled={primaryButton.disabled}
              isLoading={primaryButton.isLoading}
              color={primaryButton.color ? primaryButton.color : 'primary'}
            >
              {primaryButton.text}
            </Button>
          )}
        </RightSideButtonsWrapper>
        <StyledModal
          isOpen={isModalOpen}
          disableContentScroll
          childrenPadding="32px"
          onModalClose={handleModalClose}
          heading={quickSetupModalTexts.heading}
          primaryButton={modalPrimaryButtonProps}
          secondaryButton={modalSecondaryButtonProps}
        >
          <Body variant="body2">
            {currentStep === QuickSetupStep.ADD_PARTICIPANTS
              ? quickSetupModalTexts.inviteLaterModalSubHeading
              : quickSetupModalTexts.leaveSetupModalSubHeading}
          </Body>
          <StyledSubHeading variant="body2" margin="16px 0 0 0">
            {currentStep === QuickSetupStep.ADD_PARTICIPANTS
              ? quickSetupModalTexts.inviteLaterModalSubHeading2
              : quickSetupModalTexts.leaveSetupModalSubHeading2}
          </StyledSubHeading>
        </StyledModal>
      </FooterWrapper>
    </>
  );
};

export default FooterController;
