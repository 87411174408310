import styled from 'styled-components';
import ThemeV2 from '../../../componentsV2/theme';
import Button from '../../atoms/Button';
import IconButton from '../../atoms/IconButton_V2';
import Heading from '../../atoms/Heading';

export const ModalBackground = styled.div`
  display: flex;
  flex-direction: row;
  width: 500px;
  background: ${ThemeV2.palette.gray1};
  border-radius: 2px;
  max-height: 80vh;
  overflow: hidden;
  @media (max-width: 580px) {
    width: 100%;
    flex-wrap: wrap;
    overflow: auto;
  }
`;

export const SubmitButton = styled(Button)`
  margin-left: 12px;
`;

export const ModalHeading = styled(Heading)`
  margin-left: auto;
  text-align: center;
  display: flex;
  height: 64px;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px;
`;

export const ModalBody = styled.div`
  padding: 32px;
  text-align: center;
  overflow: scroll;
`;

export const ModalFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
  text-align: center;
  padding: 16px;
`;

export const CloseButton = styled.button`
  position: absolute;
  background: transparent;
  box-shadow: none;
  border: none;
  top: 18px;
  right: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  color: ThemeV2.palette.gray2;
  font-family: ThemeV2.typography.fontFamily;
  font-weight: ThemeV2.typography.fontWeightMedium;
  font-size: ThemeV2.typography.fontSize;
`;

export const CloseIconButton = styled(IconButton)`
  margin-left: 12px;
  position: absolute;
  top: 16px;
  right: 16px;
  height: 32px;
`;

export const CloseIcon = styled.span`
  display: block;
  margin-bottom: 5px;
`;

export const MessageBackgroundInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
