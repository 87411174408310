import React from 'react';
import styled from 'styled-components';
import { Flex } from '../../../Utils/styles/display';
import SVGIcon from '../../atoms/SVGIcon';
import Link from '../../atoms/Link_V2';
import { LinkWithIconProps } from './type';

const StyledLink = styled(Link)`
  margin-left: 8px;
  text-decoration: none;
  color: ${(props) => props.color};
`;

const LinkWithIcon = (props: LinkWithIconProps) => {
  const { icon, iconColor, iconSize, to, children, linkColor } = props;
  return (
    <Flex>
      {icon && <SVGIcon icon={icon} color={iconColor} size={iconSize} />}
      <StyledLink to={to} isAnchor openInNewTab color={linkColor}>
        {children}
      </StyledLink>
    </Flex>
  );
};

export default LinkWithIcon;
