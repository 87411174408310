import styled from 'styled-components';

import Image from '../../atoms/Image';
import ThemeV2 from '../../../componentsV2/theme';

export const MainFeedDrawerErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 16px;
  border: 1px solid ${ThemeV2.palette.gray5};
  border-radius: 4px;
  padding: 16px;
  height: 100%;
  text-align: center;
  background-color: ${ThemeV2.palette.gray1};
`;

export const MainFeedDrawerWrapper = styled.div`
  padding-bottom: 16px;
  height: calc(100vh - 72px);
  overflow-y: auto;
`;

export const StyledImage = styled(Image)`
  margin-top: 56px;
  margin-bottom: 24px;
`;

export const StyledDummyLeaderboardWrapper = styled.div`
  max-width: 245px;
  margin-left: 24px;
  margin-right: 24px;
  @media (max-width: 768px) {
    max-width: 100%;
    margin-right: 24px;
  }
`;
