import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { QUICK_SETUP } from '../../../constants/routes';
import { CreditState } from '../../../interfaces/assembly';
import { MemberInteractionType } from '../../../interfaces/Feed';
import { LayoutStoreState } from '../../../interfaces/Layout';
import { useNotificationFetchQuery } from '../../../queries/Notifications';
import {
  useProfileInfoFetchQuery,
  useRedeemInfoFetchQuery,
} from '../../../queries/Profile';
import useLayoutStore from '../../../stores/layoutStore';
import { isUserAdmin } from '../../../Utils/user';

const layoutSelector = (state: LayoutStoreState) =>
  state.setMemberAndToggleRightAsideOpen;

const usePrimaryHeaderController = () => {
  const { data: notificationsData } = useNotificationFetchQuery();
  const { data: profileData } = useProfileInfoFetchQuery();
  const { data: redeemInfoData } = useRedeemInfoFetchQuery();

  const setMemberAndToggleRightAsideOpen = useLayoutStore(layoutSelector);

  const onViewProfileClick = useCallback(
    (person: MemberInteractionType) => {
      setMemberAndToggleRightAsideOpen({
        memberId: person.memberID,
        firstName: person.firstName,
      });
    },
    [setMemberAndToggleRightAsideOpen],
  );

  const isRedeemable = redeemInfoData ? redeemInfoData.isRedeemable : false;
  const hideRewardsLink = () => {
    if (
      profileData?.assembly.accounts.creditState === CreditState.DENIED_BY_ADMIN
    ) {
      return true;
    }
    if (
      !profileData?.member ||
      (!isRedeemable && !isUserAdmin(profileData.member))
    ) {
      return true;
    }
    if (isUserAdmin(profileData.member)) {
      return false;
    }
    return !isRedeemable;
  };

  const isCurrentUserAdmin = profileData?.member
    ? isUserAdmin(profileData?.member)
    : false;

  // Quick Setup
  const { pathname } = useLocation();
  const [isInQuickSetup, setIsInQuickSetup] = useState(false);

  useEffect(() => {
    setIsInQuickSetup(pathname.includes(QUICK_SETUP));
  }, [pathname]);

  return {
    unreadNotificationsCount: notificationsData?.notificationCount || 0,
    currentUserData: profileData?.member,
    assemblyData: profileData?.assembly,
    hideRewardsLink: hideRewardsLink(),
    onViewProfileClick,
    isCurrentUserAdmin,
    isInQuickSetup,
  };
};

export default usePrimaryHeaderController;
