import { useQuery } from 'react-query';

import { makeAPICallWithDataReturn } from '../utils';
import { FlowsTodoCountResponse } from './interfaces';
import { GET_FLOWS_TODO_COUNT } from '../../constants/endpoints';

export const useGetFlowsTodoCountQuery = (enabled = true) => {
  return useQuery<FlowsTodoCountResponse>(
    [GET_FLOWS_TODO_COUNT],
    () =>
      makeAPICallWithDataReturn(GET_FLOWS_TODO_COUNT, undefined, { days: 31 }),
    {
      enabled,
      staleTime: Infinity,
    },
  );
};
