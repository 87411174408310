enum SplitNames {
  FLOWS_INSIGHTS = 'FLOWS_INSIGHTS',
  INVITE_USER_ON_FLOW_CREATION = 'INVITE_USER_ON_FLOW_CREATION',
  QUICK_SETUP = 'QUICK_SETUP',
  REWARDS_UPDATE = 'REWARDS_UPDATE',
  LOGO_AND_CURRENCY_SETTINGS = 'ADD_LOGO_AND_CURRENCY_SETTINGS',
  ADD_OUTLOOK_CALENDAR = 'ADD_OUTLOOK_CALENDAR',
  AXOMO_REWARDS = 'AXOMO_REWARDS',
  FILE_PREVIEWER = 'FILE_PREVIEWER',
  PAGINATED_INTEGRATION_DATA = 'PAGINATED_INTEGRATION_DATA',
  SHARESHEET_ANONYMOUS_SETTINGS = 'SHARESHEET_ANONYMOUS_SETTINGS',
  COMBINED_USERS_MANAGE_INVITE = 'COMBINED_USERS_MANAGE_INVITE',
  CELEBRATION_BOTS = 'CELEBRATION_BOTS',
  REMOVE_PASSWORD_BASED_AUTH = 'REMOVE_PASSWORD_BASED_AUTH',
  ADMIN_NEW_APP = 'ADMIN_NEW_APP',
  NEW_PAGE_PARTICIPATION_VIEW = 'NEW_PAGE_PARTICIPATION_VIEW',
  KNOWLEDGE_CENTER = 'KNOWLEDGE_CENTER',
  LEGACY_ADP = 'LEGACY_ADP',
}

enum TreatmentTypes {
  CONTROL = 'control',
  ON = 'on',
  OFF = 'off',
  NOT_YET_SET = 'NOT_YET_SET',
}

export { SplitNames, TreatmentTypes };
