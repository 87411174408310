import { QuickSetupStep } from '../types';

import {
  SurveyStepsEnum,
  QuickSetupCalendarStepsEnum,
} from '../../../../queries/QuickSetup/interfaces';

export const mapQuickSetupCalendarStepsFromAPIToQuickSetupStep = (
  currentStep: QuickSetupCalendarStepsEnum | undefined,
) => {
  if (!currentStep) {
    return QuickSetupStep.QUICK_SETUP_OPTION_SELECTION;
  }

  switch (currentStep) {
    case QuickSetupCalendarStepsEnum.INVITE_MEMBER:
      return QuickSetupStep.ADD_PARTICIPANTS;
    case QuickSetupCalendarStepsEnum.CONNECT_CALENDAR:
      return QuickSetupStep.CALENDAR_SELECTION;
    case QuickSetupCalendarStepsEnum.LIST_MEETING:
      return QuickSetupStep.MEETINGS_LIST;
    case QuickSetupCalendarStepsEnum.LIST_TEMPLATE:
      return QuickSetupStep.SELECT_TEMPLATE;
    default:
      return QuickSetupStep.QUICK_SETUP_OPTION_SELECTION;
  }
};

export const mapSurveyStepsEnumFromAPIToQuickSetupStep = (
  currentStep: SurveyStepsEnum | undefined,
) => {
  if (!currentStep) {
    return QuickSetupStep.QUICK_SETUP_OPTION_SELECTION;
  }

  switch (currentStep) {
    case SurveyStepsEnum.INVITE_MEMBER:
      return QuickSetupStep.ADD_PARTICIPANTS;
    case SurveyStepsEnum.LIST_TEMPLATE:
      return QuickSetupStep.SELECT_TEMPLATE;
    default:
      return QuickSetupStep.QUICK_SETUP_OPTION_SELECTION;
  }
};
