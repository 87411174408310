import styled from 'styled-components';
import ThemeV2 from '../../../componentsV2/theme';

import Body from '../../atoms/Body';
import Image from '../../atoms/Image';
import SVGIcon from '../../atoms/SVGIcon';

export const KnowlegeCenterListItemWrapper = styled.div`
  position: relative;
  padding: 8px;
  width: 100%;
  height: 48px;
  border-bottom: 1px ${ThemeV2.palette.gray5} solid;
  flex-grow: 1;
  flex-basis: 0;
  display: flex;

  &:hover {
    color: ${ThemeV2.palette.geekBlue6};
    background-color: ${ThemeV2.palette.geekBlue1};
    cursor: -moz-zoom-in;
    cursor: -webkit-zoom-in;
    cursor: zoom-in;
  }
`;

export const KnowledgeCenterListItemImage = styled(Image)`
  height: 32px;
  width: 32px;
  margin-right: 8px;
`;

export const SVGKnowledgeCenterListItemIcon = styled(SVGIcon)`
  margin-right: 8px;
`;

export const KnowledgeCenterListItemBody = styled(Body)`
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const KnowledgeCenterListItemNameWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
  padding-right: 8px;
`;

export const KnowledgeCenterListItemUserWrapper = styled.div`
  margin-top: 6px;
  width: 25%;
  display: flex;
  paddingright: 8px;
`;

export const KnowledgeCenterListItemAvatarWrapper = styled.div`
  margin-top: 2px;
  margin-right: 4px;
`;
