import React, { useRef, useEffect } from 'react';
import { Flex } from '../../../Utils/styles/display';
import { trimSpaces } from '../../../Utils/text';

import { SentenceInput, InputWrapper, WidthControl } from './styles';

export type TextEditorProps = {
  onFocus?: () => void;
  onBlur?: () => void;
  autoFocus: boolean;
  placeholder: string;
  value: string;
  onChange: (value: string) => void;
  dataTestId?: string;
  className?: string;
  required?: boolean;
  autoComplete?: boolean;
  spellCheck?: boolean;
};

const TextEditor = (props: TextEditorProps) => {
  const {
    onFocus,
    onBlur,
    autoFocus = false,
    placeholder,
    value,
    onChange,
    dataTestId,
    className,
    required = false,
    spellCheck = true,
  } = props;

  const inputRef = useRef<HTMLElement>(null);
  // We are using a Ref here because, this ContentEditable Component(SentenceInput here), has a known issue of //
  // not being compatible with useState, since it is optimized in a way that the handler function that //
  // was created the last time the component was rendered that gets called, and not a new instance //
  const titleValueRef = useRef(value);

  useEffect(() => {
    if (autoFocus) {
      inputRef?.current?.focus();
    }
    titleValueRef.current = value;
  }, [autoFocus, value]);

  const disableEnterEvent = (event: any) => {
    const keyCode = event.keyCode || event.which;
    if (keyCode === 13) {
      // eslint-disable-next-line no-param-reassign
      event.returnValue = false;
      if (event.preventDefault) event.preventDefault();
    }
  };

  const handlePaste = (e: React.ClipboardEvent) => {
    e.preventDefault();
    // Getting only the text from Clipboard , and replacing all the extra breaks and new lines.
    const text = e.clipboardData.getData('text').replace(/(\r\n|\n|\r)/gm, '');
    if (titleValueRef.current === '') {
      onChange(text);
    }
    onChange(titleValueRef.current.concat(text));
  };

  return (
    <Flex width="100%" alignItems="stretch">
      <InputWrapper>
        <WidthControl
          data-testid="width-control"
          required={required}
          className={`${className} form-input`}
        >
          {value === '' ? placeholder : value}
        </WidthControl>
        <SentenceInput
          required={required}
          data-testid={dataTestId}
          innerRef={inputRef}
          onFocus={onFocus}
          onBlur={onBlur}
          className={className}
          placeholder={placeholder}
          html={value}
          onChange={(e) => {
            onChange(trimSpaces(e.target.value || ''));
          }}
          onKeyDown={disableEnterEvent}
          spellCheck={spellCheck}
          onPaste={handlePaste}
        />
      </InputWrapper>
    </Flex>
  );
};

export default TextEditor;
