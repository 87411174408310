import React, { useMemo, memo } from 'react';
import styled from 'styled-components';

import { SEE_MORE_COMMENTS } from '../../../languages/en/feed';
import { Flex } from '../../../Utils/styles/display';
import Button from '../../atoms/Button';
import Comment from '../Comment';
import { CommentProps } from '../Comment/interfaces';

const CommentsWrapper = styled.div`
  max-height: 500px;
  overflow-y: auto;
`;

export interface FeedPostCommentsProps {
  feedCommentProps: CommentProps[];
  isDummy?: boolean;
  showMoreCommentsButton: boolean;
  onShowMoreCommentsButtonClick: () => void;
}

const FeedPostComments = ({
  feedCommentProps,
  isDummy = false,
  showMoreCommentsButton = true,
  onShowMoreCommentsButtonClick,
}: FeedPostCommentsProps) => {
  const hasComments = useMemo(() => {
    return feedCommentProps && feedCommentProps.length > 0;
  }, [feedCommentProps]);

  return (
    <>
      {showMoreCommentsButton && (
        <Flex justifyContent="center" padding="16px 0">
          <Button
            icon="prompt"
            onClick={onShowMoreCommentsButtonClick}
            variant="showMore"
            size="small"
          >
            {SEE_MORE_COMMENTS}
          </Button>
        </Flex>
      )}
      <CommentsWrapper>
        {hasComments &&
          feedCommentProps.map((feedCommentProp) => (
            <Comment
              key={feedCommentProp.contentId}
              isDummy={isDummy}
              {...feedCommentProp}
            />
          ))}
      </CommentsWrapper>
    </>
  );
};

export default memo(FeedPostComments);
