import React, { memo } from 'react';

import { CommentProps } from './interfaces';

import CommentHeader from '../CommentHeader';
import FeedPostBody from '../FeedPostBody';
import ReactionBar from '../ReactionsBar';
import { ToggleButtonVariant } from '../../atoms/ToggleButton/interface';
import { ButtonSize } from '../../atoms/IconButton_V2/interface';

import {
  CommentWrapper,
  StyledCommentBody,
  StyledReactionBarContainer,
} from './styles';

const Comment = (props: CommentProps) => {
  const {
    from,
    isDummy = false,
    trophies,
    assemblyCurrency,
    createdAt,
    currentUserId,
    avatarSize = '40px',
    onClickUserName,
    messageText,
    imageUrl,
    reactions,
    isPickerDisabled,
    dropdownOptions,
    onCopyLink,
    onDeleteCommentClick,
    hasTrophies,
    onReactButtonClick,
    onReactionToggleClick,
    onEmoticonSelect,
    contentId,
    isLoading,
  } = props;

  return (
    <CommentWrapper data-testid="comment" isLoading={isLoading || false}>
      <CommentHeader
        commentId={contentId}
        from={from}
        isDummy={isDummy}
        trophies={trophies}
        assemblyCurrency={assemblyCurrency}
        createdAt={createdAt}
        currentUserId={currentUserId}
        avatarSize={avatarSize}
        onClickUserName={onClickUserName}
        dropdownOptions={dropdownOptions}
        hasTrophies={hasTrophies}
        onCopyLink={onCopyLink}
        onDeleteCommentClick={onDeleteCommentClick}
        isLoading={isLoading}
      />
      <StyledCommentBody>
        <FeedPostBody messageText={messageText} image={imageUrl} />
        <StyledReactionBarContainer>
          <ReactionBar
            contentId={contentId}
            currentUserId={currentUserId}
            reactions={reactions}
            isPickerDisabled={isPickerDisabled}
            onReactButtonClick={onReactButtonClick}
            onReactionToggleClick={onReactionToggleClick}
            onEmoticonSelect={onEmoticonSelect}
            toggleButtonVariant={ToggleButtonVariant.Text}
            toggleButtonIconSize="20px"
            reactionButtonSize={ButtonSize.Small}
          />
        </StyledReactionBarContainer>
      </StyledCommentBody>
    </CommentWrapper>
  );
};

const MemoizedComment = memo(Comment);
MemoizedComment.displayName = 'Comment';

export default MemoizedComment;
