export const FIRST_SLIDE_TITLE = 'Who would you like to celebrate?';
export const FIRST_SLIDE_LABEL = 'Choose People';
export const SECOND_SLIDE_TITLE = 'What did your teammate(s) do?';
export const SECOND_SLIDE_DESCRIPTION =
  'Celebration tip: Add a GIF (bottom left) and make your message a little extra fun.';
export const THIRD_SLIDE_TITLE = 'Choose a core value.';
export const THIRD_SLIDE_LABEL = 'Select an option';
export const FOURTH_SLIDE_TITLE = 'Boost your celebration';

export const generateTrophiesBlockHeading = (pluralCurrencyName: string) => {
  return `Lastly, boost your celebration with ${pluralCurrencyName}!`;
};
export const YOU_ARE_GIVING_POINTS_TO = `You're giving points to`;
export const EDIT_PEOPLE = 'Edit people';
export const THIS_IS_REQUIRED = 'This is required';
export const FIX_THIS_ERROR = 'Fix this error';
