import styled from 'styled-components';
import Popover from '@material-ui/core/Popover';

import { StyledButton } from '../../../Utils/styles/button';

import Body from '../../atoms/Body';
import Heading from '../../atoms/Heading';
import SVGIcon from '../../atoms/SVGIcon';
import Button from '../../atoms/Button';
import DropdownIconButton from '../DropdownIconButton';
import ThemeV2 from '../../../componentsV2/theme';

export const StyledFlowsFeedHeaderMainContent = styled.div`
  margin-left: 8px;
`;

export const FeedHeaderMainContentInnerWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const StyledHeading = styled(Heading)`
  margin: 0 4px 0 0;
`;

export const StyledDropdownIconButton = styled(DropdownIconButton)`
  align-items: center;
  justify-content: center;
`;

export const StyledSvgIcon = styled(SVGIcon)`
  margin-top: 2px;
  margin-left: 8px;
`;

export const Root = styled.div<{
  borderBottom?: boolean;
  isRightAsideOpen: boolean;
}>`
  display: grid;
  min-width: 100%;
  grid-template-columns: 48px auto auto;
  width: 100%;
  padding: ${({ isRightAsideOpen }) =>
    isRightAsideOpen ? '16px 16px' : '16px 40px 16px 16px'};
  max-height: 80px;
  border-bottom: ${({ borderBottom }) =>
    borderBottom && `1px solid ${ThemeV2.palette.gray4}`};
  transition: all 0.5s;
`;

export const StyledVisibiltyLabel = styled(Body)`
  margin-left: 2px;
  text-decoration: underline;
  cursor: pointer;
`;

export const StyledButtonContainer = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  button:last-child {
    margin-left: 8px;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

export const LinkButton = styled(StyledButton)`
  text-decoration: underline;
  font-size: 12px;
`;

export const FlowsDetailsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const StyledBody = styled(Body)`
  max-width: 30vw;
  display: block;
  white-space: nowrap; /* forces text to single line */
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const PopoverBody = styled(Body)`
  max-width: 100%;
  display: flex;
`;

export const CopyLinkButton = styled(Button)`
  svg {
    path {
      fill: ${ThemeV2.palette.geekBlue6};
    }
  }
`;

export const StyledPopover = styled(Popover)`
  pointer-events: none;
  & .MuiPopover-paper {
    pointer-events: auto;
    box-shadow: none;
    border: 1px solid ${ThemeV2.palette.gray4};
    padding: 8px 12px;
  }
`;
