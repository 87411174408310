import { MemberDetails } from '../../interfaces/MemberDetails';
import {
  BirthdayFromAPI,
  HiredDayFromAPI,
  MemberFromAPI,
} from '../../interfaces/UserProfile';

const serializeBirthday = ({ day, month }: BirthdayFromAPI) => {
  return day && month ? { day, month } : null;
};

const serializeHiredDay = ({ day, month, year }: HiredDayFromAPI) => {
  return day && month && year ? { day, month, year } : null;
};

export const serializeMembersDetailsFromApi = (
  membersDetailsFromApi: MemberFromAPI,
): MemberDetails => {
  const {
    email,
    reportIds,
    managerIds,
    memberId,
    profile,
    totalPointsGiven,
    totalPointsEarned,
    timeZone,
    state,
  } = membersDetailsFromApi;
  const {
    birthday,
    department,
    firstName,
    hiredday,
    image,
    lastName,
    username,
    jobTitle,
    location,
  } = profile;

  const serializedBirthday = serializeBirthday(birthday);
  const serializedHiredDay = serializeHiredDay(hiredday);

  return {
    email,
    reportIds,
    managerIds,
    memberId,
    totalPointsEarned,
    totalPointsGiven,
    profile: {
      birthday: serializedBirthday,
      department,
      firstName,
      hiredDay: serializedHiredDay,
      image,
      lastName,
      username,
      jobTitle,
      location,
    },
    timeZone,
    state,
  };
};
