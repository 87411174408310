import React from 'react';

import { MeetingTypeProps } from '../type';
import Heading from '../../../../atoms/Heading';
import Dropdown from '../../../../molecules/Dropdown_V2';
import { Flex } from '../../../../../Utils/styles/display';
import {
  DropdownColor,
  DropdownSizes,
  DropdownVariants,
} from '../../../../molecules/Dropdown_V2/interfaces';

const MeetingType = (props: MeetingTypeProps) => {
  const { meetingType, value, menuItems, onItemClick } = props;
  return (
    <Flex
      alignItems="center"
      margin="34px 0 27px 0"
      justifyContent="space-between"
      width="100%"
    >
      <Heading variant="h6" marginTop="0" marginBottom="0">
        {meetingType}
      </Heading>
      <Dropdown
        value={value}
        menuItems={menuItems}
        onItemClick={onItemClick}
        size={DropdownSizes.Small}
        variant={DropdownVariants.Text}
        color={DropdownColor.Secondary}
      />
    </Flex>
  );
};

export default MeetingType;
