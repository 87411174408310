import React from 'react';

import MainFeedDrawer from '../../../atomic/organism/MainFeedDrawer';
import { CELEBRATE_TEAMMATE_FLOW_ID } from '../../../constants/common';
import useLeaderboardControllerLogic from '../LeaderboardController/useLeaderboardControllerLogic';
import useLeaderboardControllerModel from '../LeaderboardController/useLeaderboardControllerModel';
import { V2_REWARDS } from '../../../constants/routes';

const MainFeedDrawerController = () => {
  const {
    location,
    selectedCategory,
    selectedTimeFrame,
    setSelectedCategory,
    setSelectedTimeFrame,
    setSelectedMemberProfile,
    toggleRightAsideOpen,
  } = useLeaderboardControllerLogic();

  const isCelebrateTeammateFeed =
    location.pathname.split('/').slice(-1).toString() ===
    CELEBRATE_TEAMMATE_FLOW_ID;

  const isRewardsPage = location.pathname.includes(V2_REWARDS);

  const {
    metadata: { isError, isLoading },
    data: { currentUser, leaderboard },
  } = useLeaderboardControllerModel(
    selectedCategory,
    selectedTimeFrame,
    isCelebrateTeammateFeed,
  );

  return (
    <MainFeedDrawer
      canShowRightDrawer={isRewardsPage}
      currentMember={currentUser}
      hasFullError={isError}
      hasLeaderboardError={isError}
      isLeaderboardLoading={isLoading}
      leaderboardMembers={leaderboard?.leaderBoard}
      toggleRightAsideOpen={toggleRightAsideOpen}
      onCategoryChange={setSelectedCategory}
      onTimeFrameChange={setSelectedTimeFrame}
      selectedCategory={selectedCategory}
      selectedTimeFrame={selectedTimeFrame}
      setSelectedMemberProfile={setSelectedMemberProfile}
      showLegacySidebar={isCelebrateTeammateFeed}
    />
  );
};

export default MainFeedDrawerController;
