import styled from 'styled-components';
import ThemeV2 from '../../../componentsV2/theme';
import {
  device,
  PrimaryNavWidth,
  SecondaryNavWidth,
} from '../../../constants/layout';
import { LayoutStyleProps } from '../../../interfaces/Layout';
import { zIndexPopup } from '../../../Utils/styles/z-index';

export const IconButton = styled.button<{
  isActive?: boolean;
  showUnread?: number;
  isRewardsOpen?: boolean;
}>`
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  background-color: ${({ isRewardsOpen }) =>
    isRewardsOpen ? ThemeV2.palette.gold3 : ThemeV2.palette.gray1};
  border: 0;
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  outline: none;
  height: 40px;
  border-radius: 4px;
  cursor: pointer;
  padding: 0px 12px;
  color: ${({ isRewardsOpen }) =>
    isRewardsOpen ? ThemeV2.palette.gold10 : ThemeV2.palette.gray7};
  border-color: ${ThemeV2.palette.gray3};
  .rewardsButtonNumberText {
    color: ${({ isRewardsOpen }) =>
      isRewardsOpen ? ThemeV2.palette.gold10 : ThemeV2.palette.gray8};
  }
  .rewardsButtonVerticalBar {
    color: ${({ isRewardsOpen }) =>
      isRewardsOpen ? ThemeV2.palette.gold10 : ThemeV2.palette.gray3};
  }
  &:hover {
    color: ${ThemeV2.palette.gold10};
    background-color: ${ThemeV2.palette.gold3};
    border-color: ${ThemeV2.palette.gold10};
    .rewardsButtonNumberText {
      color: ${ThemeV2.palette.gold10};
    }
    .rewardsButtonVerticalBar {
      color: ${ThemeV2.palette.gold10};
    }
  }
`;

export const StyledIconButton = styled(IconButton)<LayoutStyleProps>`
  position: fixed;
  transition-duration: ${({ isLeftAsideOpen }) =>
    isLeftAsideOpen ? `550ms` : `300ms`};
  transition-timing-function: ease;
  transition-property: left;
  bottom: 24px;
  left: ${({ isLeftAsideOpen }) =>
    isLeftAsideOpen
      ? `${PrimaryNavWidth + SecondaryNavWidth - (24 + 40)}px`
      : `${PrimaryNavWidth + 24}px`};
  height: 40px;
  border-radius: 50%;

  @media ${device.tablet} {
    display: ${({ isRightAsideOpen }) => isRightAsideOpen && 'none'};
  }

  @media ${device.mobile} {
    display: none;
  }
`;

export const PopoverContainer = styled.div<{
  width?: string;
}>`
  width: ${({ width }) => (width ? width : `232px`)};
  z-index: ${zIndexPopup};
`;

export const RewardsTextWrapper = styled.div<{
  isRewardsOpen?: boolean;
}>`
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
`;

export const RewardsNumberWrapper = styled.div<{
  isRewardsOpen?: boolean;
}>`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
`;
// eslint-disable-next-line max-len
//For what it's worth I feel very dirty writing that important but trying to do what's being asked in raw CSS this was the way. Will revisit

export const VerticalBar = styled.div<{
  isButtonBar?: boolean;
  isRewardsOpen?: boolean;
  baseColor?: string;
}>`
  border-left: 1px solid
    ${({ isRewardsOpen, baseColor }) =>
      baseColor
        ? baseColor
        : isRewardsOpen
        ? ThemeV2.palette.gold10
        : undefined};
  height: 32px;
  margin-right: 16px;
  margin-left: 16px;
  vertical-align: middle;
  margin-top: ${({ isButtonBar }) => (isButtonBar ? '0px' : '4px')};
`;
