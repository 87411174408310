import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import IconButton from '../../atoms/IconButton';
import SVGIcon from '../../atoms/SVGIcon';
import Button from '../../atoms/Button';
import ThemeV2 from '../../../componentsV2/theme';
import { Flex } from '../../../Utils/styles/display';
import FilterSearchInput, {
  FilterSearchFormValues,
} from '../../molecules/FilterSearchInput';
import Body from '../../atoms/Body';
import { PREMIUM_FEATURE } from '../../../languages/en/home';

const useStyles = makeStyles({
  root: ({ disabled }) => ({
    width: '100%',
    position: 'relative',
    '& .MuiOutlinedInput-inputAdornedStart': {
      marginLeft: '-1rem',
    },
    '& .MuiInputAdornment-positionStart span svg path': {
      '&:nth-child(1)': {
        fill: ThemeV2.palette.gray6,
        transition: 'all .3s',
      },
      '&:nth-child(2)': {
        fill: disabled ? ThemeV2.palette.gray3 : ThemeV2.palette.gray1,
        stroke: ThemeV2.palette.gray6,
        transition: 'all .3s',
      },
      '&:nth-child(3)': {
        fill: ThemeV2.palette.gray5,
        stroke: ThemeV2.palette.gray6,
        transition: 'all .3s',
      },
    },
    '&:hover': {
      '& .MuiInputAdornment-root': {
        backgroundColor: disabled
          ? ThemeV2.palette.gray3
          : ThemeV2.palette.gray1,
      },
    },
    marginBottom: '12px',
  }),
  searchBar: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    height: '3rem',
    width: '100%',
    marginBottom: '.25rem',
  },
  searchInput: {
    background: ({ disabled }: { disabled: boolean }) =>
      disabled ? ThemeV2.palette.gray1 : ThemeV2.palette.gray5,
  },
  caretIconButton: {
    position: 'absolute',
    top: '8px',
    right: '12px',
    padding: '.25rem',
  },
  clearIconButton: {
    position: 'absolute',
    top: '8px',
    right: '48px',
    padding: '.25rem',
  },
  dropdownContainer: {
    position: 'absolute',
    width: '100%',
    zIndex: 99,
  },
});

const UpgradeButton = styled(Button)`
  position: absolute;
  right: 12px;
  margin-left: auto;
  max-height: 32px;
`;

const StyledPremiumMessage = styled.div`
  position: absolute;
  right: 12px;
  max-height: 32px;
`;

interface FeedFilterSearchProps {
  formValues?: FilterSearchFormValues;
  placeholderText: string;
  disabled: boolean;
  showPremiumMessage: boolean;
  upgradeButton?: {
    disabled: boolean;
    buttonText?: string;
    onUpgradeClick(): unknown;
  };
  children?: React.ReactNode;
  isDropdownOpen?: boolean;
  setIsDropdownOpen?: () => void;
  currencyNamePlural: string;
  onClearClick: () => void;
  clearButtonVisible?: boolean;
  className?: string;
}

const FeedFilterSearch = (props: FeedFilterSearchProps) => {
  const classes = useStyles(props);
  const {
    formValues,
    placeholderText,
    disabled,
    upgradeButton,
    children,
    isDropdownOpen,
    setIsDropdownOpen,
    currencyNamePlural,
    onClearClick,
    showPremiumMessage,
    clearButtonVisible = false,
    className = '',
  }: FeedFilterSearchProps = props;

  const handleOnClick = () => {
    if (setIsDropdownOpen) {
      setIsDropdownOpen();
    }
  };

  return (
    <div className={`${classes.root} ${className}`}>
      <div className={classes.searchBar}>
        {/* TODO: Add Interface for SVGIcon - Convert it to Typescript
         */}
        <FilterSearchInput
          formValues={formValues}
          placeholder={placeholderText}
          icon="magnify-thick-handle"
          disabled={disabled}
          onClick={handleOnClick}
          isFocused={isDropdownOpen}
          currencyNamePlural={currencyNamePlural}
        />
        {disabled ? (
          <>
            {upgradeButton && !showPremiumMessage && (
              <UpgradeButton
                status="info"
                icon="crown-solid"
                font="body2"
                disabled={upgradeButton.disabled}
                onClick={upgradeButton.onUpgradeClick}
                dataTestId="upgradeButton"
              >
                {upgradeButton.buttonText}
              </UpgradeButton>
            )}
            {showPremiumMessage && (
              <StyledPremiumMessage>
                <Flex>
                  <SVGIcon icon="premium-2" size="16px" />
                  <Body variant="body2" color="gold6" margin="0 0 0 8px">
                    {PREMIUM_FEATURE}
                  </Body>
                </Flex>
              </StyledPremiumMessage>
            )}
          </>
        ) : (
          // TODO: Replace all this componnt with new IconButton component
          <>
            {clearButtonVisible && (
              <IconButton
                id="clear-filters-button"
                color="primary"
                aria-label="caret icon"
                component="span"
                title="Clear Filters"
                className={classes.clearIconButton}
                onClick={onClearClick}
              >
                <SVGIcon
                  icon="round-close"
                  size="24px"
                  color={ThemeV2.palette.gray8}
                />
              </IconButton>
            )}
            <IconButton
              id="expand-filters-button"
              color="primary"
              aria-label="caret icon"
              component="span"
              title="Expand Filters"
              className={classes.caretIconButton}
              onClick={handleOnClick}
            >
              <SVGIcon
                icon="caret-rounded"
                size="24px"
                flip={isDropdownOpen}
                color={ThemeV2.palette.gray6}
              />
            </IconButton>
          </>
        )}
      </div>
      <div className={classes.dropdownContainer}>
        {isDropdownOpen && children}
      </div>
    </div>
  );
};

export default FeedFilterSearch;
