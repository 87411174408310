import { v4 as uuidv4 } from 'uuid';

import { Attendee } from '../../../interfaces/QuickSetup';
import { MeetingParticipant } from '../../../atomic/organism/QuickSetup/MeetingParticipantsList/type';

export const defaultParticipant = {
  email: ``,
  id: uuidv4(),
  owner: false,
  hasError: false,
  status: 'Participant & Viewer',
};

export const getMeetingParticipantsList = (
  attendees: Attendee[],
  owner: string,
) => {
  const defaultOwner = {
    owner: true,
    id: uuidv4(),
    hasError: false,
    email: `${owner} (you)`,
    status: 'Participant & Viewer',
  };

  const filteredAttendees = attendees.filter(
    (attendee) => attendee.email !== owner,
  );

  const transformedAttendees: MeetingParticipant[] = filteredAttendees.map(
    (participant) => ({
      owner: false,
      id: uuidv4(),
      hasError: false,
      email: participant.email,
      status: 'Participant & Viewer',
    }),
  );

  return [
    { ...defaultOwner },
    ...transformedAttendees,
    { ...defaultParticipant },
  ];
};
