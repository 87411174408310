import { useEffect } from 'react';
import { pusher } from '../../pusher/pusher-base';
import { useNotificationFetchQuery } from '../../queries/Notifications';
import { useProfileInfoFetchQuery } from '../../queries/Profile';

const useNotificationSocket = () => {
  const { data } = useProfileInfoFetchQuery();
  const { refetch: refetchNotificationItems } = useNotificationFetchQuery();
  useEffect(() => {
    if (data) {
      const { memberId } = data.member;
      const channelMember = pusher.subscribe(`private-member-${memberId}`);
      // eslint-disable-next-line no-unused-vars
      channelMember.bind('NEW_NOTIFICATION', () => {
        refetchNotificationItems();
      });
    }
  }, [data, refetchNotificationItems]);
};

export default useNotificationSocket;
