import { FeedItemFromAPI, FeedProps } from '../../interfaces/Feed';

export enum WebSocketFeedTypes {
  MAIN = 'MAIN',
  FLOW = 'FLOW',
  PROFILE = 'PROFILE',
  CELEBRATE_TEAMMATE = 'CELEBRATE_TEAMMATE',
}

type UseCommonFeedSocketBaseParamTypes = {
  type: WebSocketFeedTypes;
};

type MainFeedParamTypes = UseCommonFeedSocketBaseParamTypes & {
  type: WebSocketFeedTypes.MAIN;
  setMainFeedShowLoadMoreButtonToTrue: () => void;
  onMainFeedFlowResponseDelete: () => void;
  onMainFeedPostDeleted: () => void;
  feedProps: FeedItemFromAPI[];
};

type FlowFeedParamTypes = UseCommonFeedSocketBaseParamTypes & {
  type: WebSocketFeedTypes.FLOW;
  flowId: string;
  setFlowFeedShowLoadMoreButtonToTrue: () => void;
  onFlowFeedFlowResponseDelete: () => void;
  feedProps?: FeedProps[];
};

type CelebrateTeammateParamTypes = UseCommonFeedSocketBaseParamTypes & {
  type: WebSocketFeedTypes.CELEBRATE_TEAMMATE;
  setCelebrateTeammateFeedShowLoadMoreButtonToTrue?: () => void;
  onCelebrateATeammatePostDeleted?: () => void;
  feedProps: FeedProps[];
};

export type UseCommonFeedSocketParamTypes =
  | MainFeedParamTypes
  | FlowFeedParamTypes
  | CelebrateTeammateParamTypes;

export type SocketPayloadType = {
  fromMemberId: string;
  flowId?: string;
  postId?: string;
  postType?: string;
  commentID?: string;
  responseId?: string;
};
