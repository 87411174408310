import { COPY_LINK, DELETE_COMMENT } from '../../../languages/en/home';

import { MenuItemProps } from '../../molecules/Dropdown_V2/interfaces';

export const dropdownOptions: MenuItemProps[] = [
  {
    id: 'comment-header-options',
    items: [
      {
        value: COPY_LINK,
        id: 'copy-link',
        prefixIcon: 'Link',
        isSelected: false,
      },
      {
        value: DELETE_COMMENT,
        id: 'delete',
        prefixIcon: 'delete',
        deleteItem: true,
        isSelected: false,
      },
    ],
  },
];
