import ContentLoader from 'react-content-loader';
import React from 'react';
import styled, { css } from 'styled-components';

import ThemeV2 from '../../../componentsV2/theme';

const leftLoader = () => {
  return css`
    display: flex;
    align-items: flex-start;
    padding: 0px 12px 0px 24px;
    margin-top: -4px;
  `;
};

const rightLoader = () => {
  return css`
    display: flex;
    align-items: flex-start;
    margin-left: 8px;
  `;
};

const Wrapper = styled.div<{
  isLeftNavbar?: boolean;
}>`
  ${({ isLeftNavbar }) => (isLeftNavbar ? leftLoader() : rightLoader())}
`;

const MembersListEntryLoader = ({
  className,
  isLeftNavbar,
}: {
  className?: string;
  isLeftNavbar?: boolean;
}) => {
  return (
    <Wrapper className={className} isLeftNavbar={isLeftNavbar}>
      <ContentLoader
        speed={2}
        width={142}
        height={40}
        viewBox="0 0 142 40"
        backgroundColor={ThemeV2.palette.gray4}
        foregroundColor={ThemeV2.palette.gray3}
        uniqueKey="members-list-entry-loader"
      >
        <circle cx="12" cy="20" r="12" />
        <rect x="34" y="14" rx="4" width="94" height="12" />
      </ContentLoader>
    </Wrapper>
  );
};

const MemoizedMembersListEntryLoader = React.memo(MembersListEntryLoader);
MemoizedMembersListEntryLoader.displayName = 'MembersListEntryLoader';
export default MemoizedMembersListEntryLoader;
