import { ModalButtonProps } from '../../../atomic/templates/BasicModalTemplate';

export enum ShareSheetModalScreen {
  HOME = 'home',
  VIEWERS = 'viewers',
  PARTICIPANTS = 'participants',
  ANONYMOUS_SETTINGS = 'anonymousSettings',
  LINK_ACCESS_SETTINGS = 'link_access_settings',
}

export enum VisibilityTypes {
  ENTIRE_ORGANIZATION = 'EVERYONE',
  // eslint-disable-next-line max-len
  EVERYONE = 'EVERYONE', // Just a bit of redundancy here in case any instances of .EVERYONE still exist, pretty sure I caught them all but not 100% and don't want to break the flow builder accidentally over a text change. Once we're sure we should delete this option.
  PARTICIPANTS_ONLY = 'PARTICIPANTS_ONLY',
  OWNER_ONLY = 'OWNER_ONLY',
  CUSTOM = 'CUSTOM',
}

export type ShareSheetModalButtonProps = {
  primaryButton: ModalButtonProps;
  secondaryButton: ModalButtonProps;
  headerButton: ModalButtonProps;
};
