import React, { useState, useCallback } from 'react';

import Body from '../../atoms/Body';
import Button from '../../atoms/Button';
import SVGIcon from '../../atoms/SVGIcon';
import Avatar from '../../atoms/Avatar';
import ImageUploader from '../ImageUploader';

import { SettingsAvatarProps } from './interface';
import {
  StyledAvatarWrapper,
  ImageUploaderWrapper,
  StyledRemoveIcon,
  StyledFlexWrapper,
  StyledFlex,
  StyledChooseFile,
} from './styles';

import ThemeV2 from '../../../componentsV2/theme';
import {
  UPDATE_AVATAR,
  MINIMUM_AVATAR_SIZE,
  CHOOSE_FILE,
  // NO_FILE,
  SAVE_CHANGES,
} from '../../../languages/en/settings';
import { showErrorMessage } from '../../../Utils/toast';
import { AVATAR_SIZE } from '../../../Utils/constants/sizes';

const SettingsAvatar = (props: SettingsAvatarProps) => {
  const {
    avatarImage,
    onSaveChanges,
    onImageSelected,
    isLoading,
    isDisabled,
    onRemoveImageClick,
  } = props;
  const [profilePictureName, setProfilePictureName] = useState<string>('');

  const imageEditingDoneHandler = useCallback(
    (src: string, fileName: string) => {
      if (src !== '') setProfilePictureName(fileName.toLowerCase());
      if (onImageSelected) {
        onImageSelected(src, fileName.toLowerCase());
      }
    },
    [onImageSelected],
  );

  const onSubmit = useCallback(() => {
    onSaveChanges(profilePictureName);
  }, [onSaveChanges, profilePictureName]);

  const handleImageUploadError = useCallback((message: string) => {
    showErrorMessage(message);
  }, []);

  return (
    <div>
      <StyledFlexWrapper>
        <StyledAvatarWrapper hasMedia={!!avatarImage}>
          <Avatar img={avatarImage} size="72px" />
          {!isLoading && (
            <StyledRemoveIcon onClick={onRemoveImageClick}>
              <SVGIcon
                icon="delete"
                size="24px"
                color={ThemeV2.palette.white}
              />
            </StyledRemoveIcon>
          )}
        </StyledAvatarWrapper>
        <div>
          <Body variant="body2Bold">{UPDATE_AVATAR}</Body>
          <Body variant="body2" margin="0 0 8px">
            {MINIMUM_AVATAR_SIZE}
          </Body>
          <StyledFlex>
            <ImageUploaderWrapper>
              <ImageUploader
                onImageEditingDone={imageEditingDoneHandler}
                aspectRatio={1}
                limits={AVATAR_SIZE}
                onError={handleImageUploadError}
              >
                <StyledChooseFile variant="body2Bold">
                  {CHOOSE_FILE}
                </StyledChooseFile>
              </ImageUploader>
            </ImageUploaderWrapper>
            {/* {!mediaAdded && <Body variant="body2">{NO_FILE}</Body>}
              COMMENTED AS OF NOW AS PER THE PRODUCT TEAM REQ
             */}
          </StyledFlex>
        </div>
      </StyledFlexWrapper>
      <Button
        disabled={isDisabled}
        onClick={onSubmit}
        size="small"
        isLoading={isLoading}
      >
        {SAVE_CHANGES}
      </Button>
    </div>
  );
};

export default SettingsAvatar;
