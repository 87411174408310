import { useMemo, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import routesList from '../../aV2/routes/routesList';
import { MemberInteractionType } from '../../interfaces/Feed';
import { LayoutStoreState } from '../../interfaces/Layout';
import {
  useGetSinglePostQuery,
  useUpdatePostReactionMutation,
} from '../../queries/Feed';
import { useProfileInfoFetchQuery } from '../../queries/Profile';
import { getCurrentUserAvatar } from '../../queries/Profile/utils';
import useLayoutStore from '../../stores/layoutStore';

const layoutSelector = (state: LayoutStoreState) =>
  state.setMemberAndToggleRightAsideOpen;

const useSinglePostController = () => {
  const setMemberAndToggleRightAsideOpen = useLayoutStore(layoutSelector);
  const { postId } = useParams<{ postId: string }>();
  const history = useHistory();
  const {
    isLoading,
    isError,
    data: postData,
  } = useGetSinglePostQuery(postId, postId !== 'flow');
  const { mutate: updateReaction } = useUpdatePostReactionMutation();
  const { data: profileData, isLoading: isProfileInfoLoading } =
    useProfileInfoFetchQuery();
  const currentUserAvatar = useMemo(
    () => getCurrentUserAvatar(profileData),
    [profileData],
  );

  const onMemberClick = useCallback(
    (member: MemberInteractionType) => {
      const { memberID, firstName } = member;
      setMemberAndToggleRightAsideOpen({ memberId: memberID, firstName });
    },
    [setMemberAndToggleRightAsideOpen],
  );

  const handlePostDelete = useCallback(() => {
    history.push(routesList.HOME);
  }, [history]);

  return {
    metadata: {
      isLoading,
      isProfileInfoLoading,
    },
    data: {
      isError,
      postData,
      profileData,
      currentUserAvatar,
    },
    actions: {
      updateReaction,
      onMemberClick,
      onPostDelete: handlePostDelete,
    },
  };
};

export default useSinglePostController;
