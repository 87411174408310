import styled from 'styled-components';
import ThemeV2 from '../../../componentsV2/theme';
import { StylesComponentsProps } from './interface';

const ButtonSize = {
  large: {
    fontSize: '24px',
    lineHeight: '24px',
    padding: '8px 16px',
  },
  normal: {
    fontSize: '14px',
    lineHeight: '22px',
    padding: '4px 12px',
  },
  small: {
    fontSize: '12px',
    lineHeight: '20px',
    padding: '2px 8px',
  },
};

export const RegularToggleButtonRoot = styled.button<StylesComponentsProps>`
  display: flex;
  align-items: center;
  padding: ${(props) => ButtonSize[props.size || 'normal'].padding};
  font-family: ${ThemeV2.typography.adminFontFamily};
  font-size: ${(props) => ButtonSize[props.size || 'normal'].fontSize};
  font-style: normal;
  font-weight: ${ThemeV2.typography.fontWeightMedium};
  line-height: ${(props) => ButtonSize[props.size || 'normal'].lineHeight};
  border-radius: ${(props) => (props.isRounded ? '20px' : '4px')};
  color: ${(props) =>
    props.active ? ThemeV2.palette.geekBlue6 : ThemeV2.palette.gray8};
  background: ${(props) =>
    props.active ? ThemeV2.palette.geekBlue2 : ThemeV2.palette.gray3};
  border: none;
  outline: none;
  cursor: pointer;
  transition: 0.2s ease all;
  & span svg path {
    fill: ${(props) =>
      props.active ? ThemeV2.palette.geekBlue6 : ThemeV2.palette.gray8};
  }
  &:disabled {
    background: ${ThemeV2.palette.gray3};
    color: ${ThemeV2.palette.gray6};
    cursor: default;
    & > div {
      color: #000000;
      mix-blend-mode: luminosity;
    }
    & span svg path {
      fill: ${ThemeV2.palette.gray6};
    }
  }
  &:not(:disabled):hover {
    background: ${(props) =>
      props.active ? ThemeV2.palette.geekBlue1 : ThemeV2.palette.gray4};
    color: ${(props) =>
      props.active ? ThemeV2.palette.geekBlue6 : ThemeV2.palette.gray8};
    & span svg path {
      fill: ${(props) =>
        props.active ? ThemeV2.palette.geekBlue6 : ThemeV2.palette.gray8};
    }
  }
`;
RegularToggleButtonRoot.displayName = 'RegularToggleButtonRoot';

export const TextToggleButtonRoot = styled.button<{ active?: boolean }>`
  display: flex;
  align-items: center;
  padding: 4px 12px;
  font-family: ${ThemeV2.typography.adminFontFamily};
  font-size: 12px;
  font-style: normal;
  font-weight: ${ThemeV2.typography.fontWeightRegular};
  line-height: 20px;
  color: ${(props) =>
    props.active ? ThemeV2.palette.geekBlue6 : ThemeV2.palette.gray8};
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  transition: 0.2s ease all;

  &:disabled {
    color: ${ThemeV2.palette.gray6};
    cursor: default;
    & span svg path {
      fill: ${ThemeV2.palette.gray6};
    }
  }
  &:not(:disabled):hover {
    color: ${ThemeV2.palette.geekBlue6};
    & span svg path {
      fill: ${ThemeV2.palette.geekBlue6};
    }
  }
`;
TextToggleButtonRoot.displayName = 'TextToggleButtonRoot';

export const ButtonIconWrapper = styled.div`
  display: flex;
  margin-right: 4px;
`;
ButtonIconWrapper.displayName = 'ButtonIconWrapper';

export const EmojiWrapper = styled.div`
  margin-right: 4px;
`;
EmojiWrapper.displayName = 'EmojiWrapper';
