import { useInfiniteQuery, InfiniteData } from 'react-query';

import { GET_MEMBERS } from '../../constants/endpoints';
import { IMemberDTO } from '../../interfaces/member';
import { makeAPICallWithDataReturn } from '../utils';
import { memberQueryConstants, GET_MEMBERS_INFINITE } from './utils';

export interface GetMembersResponse {
  total: number /* total members count */;
  members: IMemberDTO[];
  metadata: {
    /* it will not present when doing keyword search */
    pagination?: {
      count: number /* page count; 30 */;
      first: string /* base64 encoded value, ex: page=1,limit=10,sort={} */;
      prev: string /* base64 encoded value */;
      self: string /* base64 encoded value */;
      next: string /* base64 encoded value */;
      last: string /* base64 encoded value */;
    };
  };
  newMembers?: string[];
  totalPending: number;
}

export interface GetFlowParticipantsResponse {
  total: number;
  data: IMemberDTO[];
  newMembers?: string[];
  totalPending?: number;
  metadata: {
    pagination: {
      cursor?: {
        previous: string | null;
        next: string | null;
      };
    };
  };
}

interface MembersFetchQueryParams {
  delta?: string;
  keyword?: string;
  email?: string;
  limit?: number;
  isNewMembersAdded?: boolean;
}

const emptyParams: MembersFetchQueryParams = {
  keyword: '',
};

// TODO: should remove duplicate
type APICallParams = Record<string, string | number | boolean>;

export const useMembersFetchInfiniteScrollQuery = (
  params: MembersFetchQueryParams = emptyParams,
  enabled = true,
) => {
  return useInfiniteQuery<GetMembersResponse>(
    [GET_MEMBERS_INFINITE, params],
    ({ pageParam }) => {
      return makeAPICallWithDataReturn(GET_MEMBERS, undefined, {
        ...params,
        delta: pageParam,
      } as APICallParams);
    },
    {
      enabled,
      refetchOnWindowFocus: false,
      staleTime: 60 * 5 * 1000,
      getNextPageParam: (lastPage) =>
        lastPage.metadata.pagination?.next || undefined,
      getPreviousPageParam: (lastPage) => lastPage.metadata.pagination?.prev,
    },
  );
};

export type GenericMemberQueryDefinition<ResponseType, MemberType> = {
  queryKey: string;
  cursorKey: string;
  requestType: 'GET' | 'POST';
  getNextPageParam: (lastPage: ResponseType) => string | undefined;
  getPreviousPageParam: (lastPage: ResponseType) => string | undefined;
  getTotalMembers: (pagesArr: InfiniteData<ResponseType> | undefined) => number;
  getInfiniteMembers: (
    pagesArr: InfiniteData<ResponseType> | undefined,
  ) => MemberType[] | undefined;
  getNewMembers: (
    pagesArr: InfiniteData<ResponseType> | undefined,
  ) => string[] | undefined;
  getTotalPendingMembers: (
    pagesArr: InfiniteData<ResponseType> | undefined,
  ) => number;
};

type GenericFetchMembersInfiniteScrollQueryParams<ResponseType, MemberType> = {
  queryDetails: GenericMemberQueryDefinition<ResponseType, MemberType>;
  params: MembersFetchQueryParams;
  enabled: boolean;
  substitutions?: Record<string, any>;
};

export const useGenericFetchMembersInfiniteScrollQuery = <
  ResponseType,
  MemberType,
>({
  queryDetails: {
    queryKey,
    getNextPageParam,
    getPreviousPageParam,
    cursorKey,
    requestType,
  },
  params = emptyParams,
  enabled = true,
  substitutions,
}: GenericFetchMembersInfiniteScrollQueryParams<ResponseType, MemberType>) => {
  const { endpointKey, uniqueQueryKey } = memberQueryConstants[queryKey];
  return useInfiniteQuery<ResponseType>(
    [uniqueQueryKey, params, ...(substitutions ? [substitutions] : [])],
    ({ pageParam }) => {
      const dataObject = {
        ...params,
        [cursorKey]: pageParam,
      } as APICallParams;
      const payload = requestType === 'POST' ? dataObject : undefined;
      const requestParams = requestType === 'GET' ? dataObject : undefined;
      return makeAPICallWithDataReturn(
        endpointKey,
        payload,
        requestParams,
        substitutions,
      );
    },
    {
      enabled,
      refetchOnWindowFocus: false,
      staleTime: 60 * 5 * 1000,
      getNextPageParam,
      getPreviousPageParam,
      retry: false,
    },
  );
};
