import Popover from '@material-ui/core/Popper';
import styled from 'styled-components';
import Avatar from '../../../atoms/Avatar';
import Body from '../../../atoms/Body';
import SVGIcon from '../../../atoms/SVGIcon';
import { Flex } from '../../../../Utils/styles/display';
import ThemeV2 from '../../../../componentsV2/theme';
import NewTextfield from '../../NewTextfield';

export const InfiniteScrollWrapper = styled.div`
  max-height: 292px;
  overflow-y: auto;
`;

export const PopoverContentWrapper = styled.div`
  padding: 4px 0;
  max-height: 300px;
`;

export const StyledPopover = styled(Popover)`
  background: ${ThemeV2.palette.white};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), 0px 3px 4px rgba(0, 0, 0, 0.02),
    0px 1px 5px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  z-index: 3;
  top: 4px;
`;

export const AssigneeName = styled(Body)`
  padding: 8px;
`;

export const UserAvatar = styled(Avatar)`
  margin-left: 4px;
`;

export const UnassignedIcon = styled(SVGIcon)`
  padding: 0 0 0 4px;
`;

export const Card = styled(Flex)`
  cursor: pointer;
  position: relative;
  min-width: 300px;
  margin: 0 4px;
  &:hover {
    background-color: ${ThemeV2.palette.geekBlue1};
    border-radius: 4px;
    .assignee-name {
      color: ${ThemeV2.palette.geekBlue6};
    }
  }
`;

export const SelectionIndicator = styled(SVGIcon)`
  position: absolute;
  right: 8px;
`;

export const AssigneeLabel = styled(Body)`
  padding: 4px 0 4px 8px;
`;

export const TaskItemAssigneeSelectorWrapper = styled(Flex)<{
  noPadding: boolean;
}>`
  background: ${ThemeV2.palette.gray3};
  width: fit-content;
  border-radius: 4px;
  cursor: default;
  margin-top: 8px;
  margin-left: ${({ noPadding }) => (noPadding ? '0px' : '8px')};
`;

export const Placeholder = styled.div`
  padding-right: 4px;
`;

export const Search = styled(NewTextfield)`
  .MuiInputBase-input {
    padding: 0 0 0 8px;
  }

  .MuiInputBase-root {
    fieldset {
      border: 1px solid ${ThemeV2.palette.gray3};
      &:hover {
          border: 1px solid ${ThemeV2.palette.gray3};
      }
    }

    &:focus,
    &:hover {
      fieldset {
        border: 1px solid ${ThemeV2.palette.gray3};
      }
    }
  }

  .MuiOutlinedInput-root.Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border-width: 1px;
      border-color: ${ThemeV2.palette.gray3};
    }
`;

export const NoAssigneesFoundLabel = styled(Body)`
  padding-left: 8px;
`;
