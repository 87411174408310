import useUppyInstances from '../../ParticipationFlowController/useUppyInstances';
import {
  GET_EXTERNAL_FILE_UPLOAD_SIGNED_URL,
  GET_FILE_UPLOAD_SIGNED_URL,
} from '../../../../constants/endpoints';
import React, { useCallback, useMemo } from 'react';
import { deleteAttachmentFile } from '../../../../Utils/flows';
import { renderParticipationSlide, RenderSlideInfoObject } from '../../utils';
import { isBlockContentValid } from '../../../../hooks/useParticipationFlow/utils';
import SplitButton from '../../../../atomic/molecules/SplitButton';
import Button from '../../../../atomic/atoms/Button';
import {
  ANONYMOUS_BANNER_TEXT,
  POST_ANONYMOUSLY,
} from '../../../../languages/en/flows/participation';
import { POST } from '../../../../languages/en/singleWords';
import { LoadedParticipationTemplate as ParticipationTemplate } from '../../../../atomic/pages/ParticipationTemplate';
import { LoadedParticipationFlowControllerProps } from './types';
import useLoadedFlowsParticipationController from './useLoadedFlowsParticipationController';
import { useParams } from 'react-router-dom';
import { ComponentStatus } from '../../../../interfaces/component';
import { ExternalFlowDetails } from '../../ParticipationFlowController/useLoadedParticipationFlowController';
import { ExternalFlowDetailsResponse } from '../../../../queries/Flows/ExternalFlows';
import { FlowInstanceResponse } from '../../../../queries/Flows/interfaces';

const LoadedFlowsParticipationFlowController = ({
  flowInstance,
  toggleParticipationModalOpen,
  profileInfo,
  flowHeaderContent,
  isPrivatePost,
  isAnonymousPost,
  isMutationLoading,
  togglePrivatePost,
  toggleAnonymousPost,
  flowVariant,
  onFlowSubmit,
  identifier,
  creator,
  participationType,
}: LoadedParticipationFlowControllerProps) => {
  const { flowId } = useParams<{ flowId: string }>();
  const flowInstanceResponse = flowInstance as FlowInstanceResponse;

  // @ts-ignore
  const externalFlowDetails: ExternalFlowDetails | undefined = useMemo(() => {
    if (participationType === 'INTERNAL') {
      return undefined;
    }

    const flow = flowInstance as ExternalFlowDetailsResponse;
    return {
      name: flow?.name,
      description: flow?.description,
      schedule: flow?.schedule,
      creator: creator,
      endTime: flow.endTime,
      icon: flow.icon,
      kind: flow.icon.kind,
    };
  }, [participationType, flowInstance, creator]);

  const {
    models: {
      blockErrors,
      currentStep,
      fieldErrors,
      hasVisitedLastStep,
      dynamicBlockData,
      values,
      stepData,
      containerRef,
      touched,
      allowPrivateResponse,
      isAnonymityEnabled,
      allowAnonymousResponse,
    },
    operations: {
      onFormCompleteClick,
      onStepChange,
      setFieldValue,
      goToNextStep,
      goToPreviousStep,
      handleModalClose,
      onNeedHelpButtonClick,
    },
  } = useLoadedFlowsParticipationController(
    flowInstance as FlowInstanceResponse,
    toggleParticipationModalOpen,
    profileInfo,
    onFlowSubmit,
    externalFlowDetails,
  );

  const uppyInstances = useUppyInstances(
    flowInstanceResponse?.blocks,
    {
      flowId: flowInstanceResponse.flowId || flowId,
      instanceId: flowInstanceResponse.instanceId,
      identifier: identifier,
    },
    identifier
      ? GET_EXTERNAL_FILE_UPLOAD_SIGNED_URL
      : GET_FILE_UPLOAD_SIGNED_URL,
  );

  const remainingAllowance = profileInfo.member.pointsLeftThisCycle;

  const handleDeleteFileClick = useCallback(
    async (fileName) => {
      const currentStepData = stepData[currentStep];
      const { id: blockId } = currentStepData;
      await deleteAttachmentFile({
        blockId,
        instanceId: flowInstanceResponse.instanceId,
        fileName,
        flowId: flowInstanceResponse.flowId,
      });
    },
    [
      currentStep,
      flowInstanceResponse.flowId,
      flowInstanceResponse.instanceId,
      stepData,
    ],
  );

  const renderSlideInfo: RenderSlideInfoObject = {
    blockErrors,
    currentStep,
    fieldErrors,
    flowInstance: flowInstanceResponse,
    goToNextStep,
    handleDeleteFileClick,
    onStepChange,
    values,
    profileInfo,
    remainingAllowance,
    setFieldValue,
    stepData,
    touched,
    uppyInstances,
    flowId,
    isPreviewFlow: false,
    isExternalFlow: participationType === 'EXTERNAL',
  };

  const isPostDisabled = !(
    stepData.filter(
      (step) => values[step.id] && isBlockContentValid(step, values[step.id]),
    ).length > 0 && hasVisitedLastStep
  );

  const externalFooterButtons: JSX.Element[] = [
    <Button
      disabled={isPostDisabled}
      isLoading={isMutationLoading}
      onClick={onFormCompleteClick}
      size="large"
      key="1"
    >
      {POST_ANONYMOUSLY}
    </Button>,
  ];

  const internalFooterButtons: JSX.Element[] = [
    allowPrivateResponse || allowAnonymousResponse ? (
      <SplitButton
        disabled={isPostDisabled}
        isLoading={isMutationLoading}
        isPrivatePost={isPrivatePost}
        isAnonymousPost={isAnonymousPost}
        onPrivatePostToggle={togglePrivatePost}
        isAnonymityEnabled={isAnonymityEnabled}
        onAnonymousPostToggle={
          allowAnonymousResponse ? toggleAnonymousPost : undefined
        }
        onPostClick={onFormCompleteClick}
        key="1"
      />
    ) : (
      <Button
        disabled={isPostDisabled}
        isLoading={isMutationLoading}
        onClick={onFormCompleteClick}
        size="large"
        key="1"
      >
        {isAnonymityEnabled ? POST_ANONYMOUSLY : POST}
      </Button>
    ),
  ];

  return (
    <ParticipationTemplate
      isFullScreen
      currentStep={currentStep}
      goToNextStep={goToNextStep}
      goToPreviousStep={goToPreviousStep}
      isNextButtonDisabled={
        participationType === 'EXTERNAL'
          ? currentStep === flowInstanceResponse.blocks.length
          : currentStep === flowInstanceResponse.blocks.length - 1
      }
      member={profileInfo.member}
      isPreviousButtonDisabled={currentStep === 0}
      onCloseModal={handleModalClose}
      footerButtons={
        participationType === 'EXTERNAL'
          ? externalFooterButtons
          : internalFooterButtons
      }
      onStepChange={onStepChange}
      dynamicBlockData={dynamicBlockData}
      singleSlideContainerRef={containerRef}
      flowVariant={flowVariant}
      flowId={flowInstanceResponse.flowId}
      onNeedHelpButtonClick={onNeedHelpButtonClick}
      flowHeaderContent={flowHeaderContent}
      headerStatus={ComponentStatus.LOADED}
      bannerProps={
        isAnonymityEnabled || isAnonymousPost
          ? {
              bannerIcon: 'anonymous',
              bannerText: ANONYMOUS_BANNER_TEXT,
            }
          : undefined
      }
      creator={creator}
    >
      {renderParticipationSlide(renderSlideInfo)}
    </ParticipationTemplate>
  );
};

export default LoadedFlowsParticipationFlowController;
