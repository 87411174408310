import React from 'react';
import DrawerHeaderWithIcon from '../../molecules/DrawerHeaderWithIcon';
import MembersListError from '../AssemblyMembers/Error';
import { FILTER_BY } from '../../../languages/en/flows';
import useLayoutStore from '../../../stores/layoutStore';
import { StyledFilterWrapper } from './styles';
import { LayoutStoreState } from '../../../interfaces/Layout';

const layoutSelector = (state: LayoutStoreState) => state.toggleRightAsideOpen;

const ErrorComponent = () => {
  const toggleRightAsideOpen = useLayoutStore(layoutSelector);
  return (
    <>
      <DrawerHeaderWithIcon icon="filter-by" onClick={toggleRightAsideOpen}>
        {FILTER_BY}{' '}
      </DrawerHeaderWithIcon>
      <StyledFilterWrapper>
        <MembersListError />
      </StyledFilterWrapper>
    </>
  );
};

export default ErrorComponent;
