import { MeetingParticipant } from '../../../../atomic/organism/QuickSetup/MeetingParticipantsList/type';

export const getEmailsFromParticipantByRole = (
  participants: MeetingParticipant[],
  filter: string[],
  emailsToBeFiltered: string[],
) => {
  return participants
    .filter((participant) => filter.includes(participant.status))
    .map((participant) => participant.email)
    .map((email) => email.replace('(you)', '').trim())
    .filter((email) => email)
    .filter((email) => !emailsToBeFiltered.includes(email));
};

export const getRulesCriteria = (emailList: string[]) => {
  return {
    criteria: {
      custom: {
        rules: [
          {
            condition: 'or',
            rules: emailList.map((email) => ({
              field: 'email',
              operator: 'is',
              value: [email],
            })),
          },
        ],
        condition: 'or',
      },
    },
    skippedMembers: [],
  };
};
