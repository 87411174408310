import { useMutation } from 'react-query';
import { SEND_FLOW_REMINDER } from '../../../../constants/endpoints';
import { makeAPICall } from '../../../utils';

export const useSendFlowReminderQuery = () => {
  return useMutation((param: { flowId: string; occurrenceId: string }) =>
    makeAPICall(
      SEND_FLOW_REMINDER,
      { occurrenceId: param.occurrenceId },
      undefined,
      {
        flowId: param.flowId,
      },
    ),
  );
};
