import React from 'react';

import { categoryMenuItems, timeFrameMenuItems } from './data';
import { DropdownColor } from '../Dropdown_V2/interfaces';
import {
  LeaderboardWrapper,
  StyledDrawerHeaderWithIcon,
  StyledDropdown,
  StyledDropdownTwo,
} from './styles';

export interface LeaderboardProps {
  children: React.ReactNode;
  toggleRightAsideOpen: () => void;
  onCategoryChange: (category: string) => void;
  onTimeFrameChange: (category: string) => void;
  selectedCategory: string;
  selectedTimeFrame: string;
}

const Leaderboard = ({
  children,
  toggleRightAsideOpen,
  onCategoryChange,
  onTimeFrameChange,
  selectedCategory,
  selectedTimeFrame,
}: LeaderboardProps) => {
  const handleCategoryChange = (category: string | number) => {
    onCategoryChange(`${category}`);
  };

  const handleTimeFrameChange = (timeFrame: string | number) => {
    onTimeFrameChange(`${timeFrame}`);
  };

  return (
    <div>
      <StyledDrawerHeaderWithIcon
        icon="leaderboard"
        onClick={toggleRightAsideOpen}
      >
        Leaderboards
      </StyledDrawerHeaderWithIcon>

      <LeaderboardWrapper>
        <StyledDropdown
          color={'secondary' as DropdownColor}
          isFullWidth
          menuItems={[
            {
              id: 'category',
              items: categoryMenuItems,
            },
          ]}
          onItemClick={handleCategoryChange}
          value={selectedCategory}
        />
        <StyledDropdownTwo
          color={'secondary' as DropdownColor}
          isFullWidth
          menuItems={[
            {
              id: 'timeFrame',
              items: timeFrameMenuItems,
            },
          ]}
          onItemClick={handleTimeFrameChange}
          value={selectedTimeFrame}
        />
        {children}
      </LeaderboardWrapper>
    </div>
  );
};

const MemoizedLeaderboard = React.memo(Leaderboard);
MemoizedLeaderboard.displayName = 'Leaderboard';
export default MemoizedLeaderboard;
