import {
  MenuItemIndividualItem,
  MenuItemProps,
} from '../../../molecules/Dropdown_V2/interfaces';

export const getMenuItems = () => {
  const menuItems: MenuItemIndividualItem[] = [
    {
      value: 'Participant & Viewer',
      id: 'Participant & Viewer',
      isSelected: false,
    },
    {
      value: 'Participant Only',
      id: 'Participant Only',
      isSelected: false,
    },
    {
      value: 'Viewer Only',
      id: 'Viewer Only',
      isSelected: false,
    },
    {
      value: "Don't Invite",
      id: "Don't Invite",
      isSelected: false,
      deleteItem: true,
    },
  ];
  return [
    {
      id: 'memberStatus',
      items: menuItems,
    },
  ] as MenuItemProps[];
};
