import styled from 'styled-components';
import ThemeV2 from '../../../componentsV2/theme';
import Body from '../../atoms/Body';
import Button from '../../atoms/Button';
import {
  CardFooter,
  StatsContainer,
  StyledCardWithIcon,
} from '../FlowsToDoCard/styles';
import { ClickHolder } from '../Popover/styles';

export const TemplateCardWrapper = styled(StyledCardWithIcon)`
  padding: 20px 16px 12px 16px;
`;

export const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const AvatarText = styled(Body)`
  margin-left: 4px;
  color: ${ThemeV2.palette.gray8};

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const StyledCardFooter = styled(CardFooter)`
  padding: 0px 16px 16px 16px;
  ${AvatarWrapper},${StatsContainer} {
    margin-bottom: 8px;
    img {
      display: flex;
    }
  }
`;

export const CardBody = styled.div<{ canShowParticipants?: boolean }>`
  display: grid;
  padding: ${({ canShowParticipants }) =>
    canShowParticipants ? '16px 16px 8px 16px' : '16px 16px 16px 16px'};

  & ${ClickHolder} {
    display: inline-grid;
  }
`;

export const StyledHeading = styled(Body)`
  display: inline;
`;

export const TruncateWrapper = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  width: 100%;
  white-space: nowrap;
`;

export const StyledButton = styled(Button)`
  width: 100px;
  margin-bottom: 4px;
`;

export const StyledFlowsTemplateCard = styled.div`
  border: 1px solid ${ThemeV2.palette.gray4};
  border-radius: 4px;
  cursor: pointer;
`;

export const CardHeader = styled.div<{ background: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 96px;
  background: ${({ background }) => background};
  border-radius: 4px 4px 0px 0px;
  font-size: 40px;
`;
