import React, { useMemo } from 'react';

import ContentLoader from 'react-content-loader';
import ThemeV2 from '../../../componentsV2/theme';
import { Flex } from '../../../Utils/styles/display';
import IconButton from '../../atoms/IconButton_V2';
import {
  StyledAvatar,
  StyledBodyHeaderTitle,
  StyledHeader,
  StyledSubheading,
} from './styles';
import SVGIcon from '../../atoms/SVGIcon';
import { CurrentUser } from '../../../interfaces/currentUser';
import { ComponentStatus } from '../../../interfaces/component';

export type ParticipationFlowHeaderContent = {
  TitleContent: string | JSX.Element;
  DescriptionContent: string | JSX.Element;
};
interface BaseParticipationFlowHeaderProps {
  member: CurrentUser;
  onCloseModal: () => void;
  status: ComponentStatus;
  isAnonymous?: boolean;
  showCloseModalOption?: boolean;
}

interface ParticipationFlowHeaderLoadingProps
  extends BaseParticipationFlowHeaderProps {
  status: ComponentStatus.LOADING | ComponentStatus.EMPTY;
}

interface ParticipationFlowHeaderResolvedProps
  extends BaseParticipationFlowHeaderProps {
  status: ComponentStatus.ERROR | ComponentStatus.LOADED;
  flowHeaderContent: ParticipationFlowHeaderContent;
}

type ParticipationFlowHeaderProps =
  | ParticipationFlowHeaderLoadingProps
  | ParticipationFlowHeaderResolvedProps;

const ParticipationFlowHeader = (props: ParticipationFlowHeaderProps) => {
  const {
    onCloseModal,
    member: {
      memberId,
      profile: { firstName, image },
    },
    isAnonymous,
    showCloseModalOption = true,
  } = props;

  const [TitleContent, DescriptionContent] = useMemo(() => {
    if (
      props.status === ComponentStatus.ERROR ||
      props.status === ComponentStatus.LOADED
    ) {
      return [
        props.flowHeaderContent.TitleContent,
        props.flowHeaderContent.DescriptionContent,
      ];
    }
    return [
      <ContentLoader
        speed={2}
        width={150}
        height={12}
        viewBox="0 0 150 12"
        backgroundColor={ThemeV2.palette.gray4}
        foregroundColor={ThemeV2.palette.gray3}
        key="headerContent"
      >
        <rect x="0" y="0" rx="4" ry="4" width="150" height="12" />
      </ContentLoader>,
      <ContentLoader
        speed={2}
        width={300}
        height={12}
        viewBox="0 0 300 12"
        backgroundColor={ThemeV2.palette.gray4}
        foregroundColor={ThemeV2.palette.gray3}
        key="descriptionContent"
      >
        <rect x="0" y="0" rx="4" ry="4" width="300" height="12" />
      </ContentLoader>,
    ];
  }, [props]);

  return (
    <StyledHeader>
      <Flex>
        <StyledAvatar
          img={image}
          name={firstName}
          userId={memberId}
          size="40px"
          isAnonymous={isAnonymous}
          {...(isAnonymous && { iconSize: '40px' })}
        />
        <div>
          <StyledBodyHeaderTitle color="gray8" variant="body2">
            {TitleContent}
          </StyledBodyHeaderTitle>
          <StyledSubheading color="gray7" variant="body3">
            {DescriptionContent}
          </StyledSubheading>
        </div>
      </Flex>

      {showCloseModalOption && (
        <IconButton onClick={onCloseModal} title="Close Recognition Flow modal">
          <SVGIcon
            color={ThemeV2.palette.gray6}
            icon="round-close"
            size="24px"
          />
        </IconButton>
      )}
    </StyledHeader>
  );
};

export default ParticipationFlowHeader;
