import React from 'react';

import ActionBar from '../ActionBar';
import {
  StyledActionBarContainer,
  StyledActionBarWrapper,
  StyledNotification,
} from '../../../Utils/styles/ActionBarContainer';
import { ActionBarContainerProps } from './types';

const ActionBarContainer = (props: ActionBarContainerProps) => {
  const {
    className,
    avatar,
    userId,
    userName,
    text,
    feedType,
    isDisabled,
    notification = '',
    onClick,
    isAnonymous,
  } = props;

  return (
    <StyledActionBarContainer
      className={className}
      data-testid="action-bar-container"
    >
      {notification && (
        <StyledNotification variant="body3Medium">
          {notification}
        </StyledNotification>
      )}
      <StyledActionBarWrapper>
        <ActionBar
          avatar={avatar}
          userName={userName}
          userId={userId}
          text={text}
          onClick={() => onClick && onClick()}
          feedType={feedType}
          isDisabled={isDisabled}
          isAnonymous={isAnonymous}
        />
      </StyledActionBarWrapper>
    </StyledActionBarContainer>
  );
};

export default ActionBarContainer;
