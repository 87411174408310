import React, { useCallback, useMemo, useRef, useState } from 'react';
import UserListItemModal from '../UserListModal';

import {
  ShowAllButton,
  StyledTextField,
  UserListItem,
  VisibilityText,
  PopoverContentWrapper,
  StyledBody,
  StyledPopover,
} from './styles';
import { UserListDropdownProps } from './interfaces';
import UserNameCard from '../UserNameCard';
import { SHOW_ALL } from '../../../languages/en/home';
import { ComponentStatus } from '../../../interfaces/component';
import { FeedPostUserProps } from '../../../interfaces/Feed';
import { isDeactivatedUser } from '../../../Utils/user';
import { FLOW_USER_LIST_DROPDOWN_NO_RESULTS } from '../../../languages/en/flows';
import useOnClickOutside from '../../../hooks/useOnOutsideClick';
import { getVisibilityTooltipText } from '../../../Utils/flows';

const UserListDropdown = ({
  children,
  users,
  startIndex = 2,
  endIndex = 12,
  onUserClick,
  disableUserInteraction = false,
  isSearchEnabled,
  visibility,
  viewCount,
  canPopoverTriggerByClick,
}: UserListDropdownProps) => {
  const [isShowAllUsersModalOpen, setShowAllUsersModalOpen] = useState(false);
  const [userSearchFieldValue, setUserSearchFieldValue] = useState('');
  const [openedPopover, setOpenedPopover] = useState(false);
  const popoverAnchor = useRef(null);
  const popoverComponent = useRef(null);
  const closeUserModalList = useCallback(() => {
    setShowAllUsersModalOpen(false);
  }, []);
  const renderUserModalList = useCallback(() => {
    setShowAllUsersModalOpen(true);
  }, []);

  const usersToDisplay = useMemo(() => {
    return users.slice(startIndex, endIndex);
  }, [users, startIndex, endIndex]);

  const onShowMoreUserClick = useCallback(
    (member: FeedPostUserProps) => {
      if (onUserClick) {
        onUserClick(member);
        setShowAllUsersModalOpen(false);
      }
    },
    [onUserClick],
  );

  const PopoverContent = () => {
    const onClick = (member: FeedPostUserProps) => () => {
      if (
        onUserClick &&
        !disableUserInteraction &&
        !isDeactivatedUser(member)
      ) {
        onUserClick(member);
      }
    };

    const handleTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setUserSearchFieldValue(e.target.value);
    };

    const filteredUsers = usersToDisplay.filter((user) => {
      return (
        user.firstName
          .toLowerCase()
          .includes(userSearchFieldValue.toLowerCase()) ||
        user.lastName.toLowerCase().includes(userSearchFieldValue.toLowerCase())
      );
    });

    return (
      <PopoverContentWrapper>
        {visibility && viewCount && (
          <VisibilityText variant="body3" color="gray7">
            {getVisibilityTooltipText(visibility, viewCount)}
          </VisibilityText>
        )}
        {isSearchEnabled && (
          <StyledTextField
            icon="search"
            onChange={handleTextFieldChange}
            placeholder="Search members"
            value={userSearchFieldValue}
            autoFocus
          />
        )}
        {filteredUsers.length ? (
          filteredUsers.map((user) => (
            <UserListItem key={user.memberID} onClick={onClick(user)}>
              <UserNameCard
                status={ComponentStatus.LOADED}
                firstName={user.firstName}
                lastName={user.lastName}
                image={user.image}
                imageSize="small"
                userId={user.memberID}
                isDeactivatedUser={isDeactivatedUser(user)}
                disableUserInteraction={disableUserInteraction}
                memberState={user.memberState}
              />
            </UserListItem>
          ))
        ) : (
          <StyledBody variant="body2">
            {FLOW_USER_LIST_DROPDOWN_NO_RESULTS}
          </StyledBody>
        )}
        {usersToDisplay.length > 12 && (
          <>
            <ShowAllButton onClick={renderUserModalList}>
              {SHOW_ALL}
            </ShowAllButton>
          </>
        )}
      </PopoverContentWrapper>
    );
  };

  const handlePopoverOpen = () => {
    if (!canPopoverTriggerByClick) {
      setOpenedPopover(true);
    }
  };

  const handlePopoverClose = () => {
    if (!canPopoverTriggerByClick) {
      setOpenedPopover(false);
    }
  };

  const handlePopoverClick = () => {
    if (canPopoverTriggerByClick) {
      setOpenedPopover(true);
    }
  };

  const handleKeypress = (event: { key: string }) => {
    if (event.key === 'Enter') {
      setOpenedPopover(true);
    }
  };

  const handlePopoverOutsideClick = () => {
    setOpenedPopover(false);
  };

  useOnClickOutside(popoverComponent, handlePopoverOutsideClick);

  return (
    <>
      <div
        role="link"
        ref={popoverAnchor}
        aria-owns="mouse-over-popover"
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        onClick={handlePopoverClick}
        onKeyPress={handleKeypress}
        tabIndex={0}
      >
        {children}
      </div>
      <StyledPopover
        ref={popoverComponent}
        id="mouse-over-popover"
        open={openedPopover}
        anchorEl={popoverAnchor.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          onMouseEnter: handlePopoverOpen,
          onMouseLeave: handlePopoverClose,
        }}
        data-testid="popover"
      >
        <PopoverContent />
      </StyledPopover>
      <UserListItemModal
        isOpen={isShowAllUsersModalOpen}
        handleClose={closeUserModalList}
        users={users}
        onShowMoreUserClick={onShowMoreUserClick}
      />
    </>
  );
};

const MemoizedUserListDropdown = React.memo(UserListDropdown);
MemoizedUserListDropdown.displayName = 'UserListDropdown';
export default MemoizedUserListDropdown;
