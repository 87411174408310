import { useCallback, useMemo } from 'react';

import isEmpty from 'lodash/isEmpty';
import useFlowBuilderStore from '../../stores/flowBuilderStore';

import { FlowBuilderBlockTypes } from '../../interfaces/Flow/Builder';
import { BuilderFlowNameCharacterLimit } from '../../constants/flowsBuilder';
import { defaultBuilderBlocks } from '../../controllers/flowsBuilder/FlowsBuilderController/data';
import { visibilityTypes } from '../../controllers/flowsBuilder/FlowsBuilderVisibilityController/data';

export const useFlowBuilderValidation = () => {
  const storeData = useFlowBuilderStore();

  const { ACTION, TRIGGER, PARTICIPANTS, VISIBILITY, CONTENT, COMPLETION } =
    defaultBuilderBlocks;

  const { emoji, owner, flowName, blockData } = storeData;

  const allBlocks = Object.keys(defaultBuilderBlocks) as Array<
    keyof typeof FlowBuilderBlockTypes
  >;

  const isCurrentBlockDataValid = useCallback(
    (stepIndex) => {
      switch (stepIndex) {
        case ACTION.index:
          return true;

        case TRIGGER.index: {
          if (blockData.TRIGGER.triggerType === 'ONDEMAND') {
            return (
              !!blockData.TRIGGER.triggerType &&
              blockData.TRIGGER.shortcut !== undefined &&
              blockData.TRIGGER.endTimeInMinutes !== undefined &&
              isEmpty(blockData.TRIGGER.errors)
            );
          }
          if (blockData.TRIGGER.triggerType === 'SCHEDULED') {
            return (
              !!blockData.TRIGGER.triggerType &&
              blockData.TRIGGER.schedule !== undefined &&
              blockData.TRIGGER.shortcut !== undefined &&
              blockData.TRIGGER.endTimeInMinutes !== undefined &&
              isEmpty(blockData.TRIGGER.errors)
            );
          }
          return (
            !!blockData.TRIGGER.triggerType && isEmpty(blockData.TRIGGER.errors)
          );
        }

        case PARTICIPANTS.index:
          return (
            !!blockData.PARTICIPANTS.participantsCriteria &&
            !isEmpty(blockData.PARTICIPANTS.participantsCriteria.groups)
          );

        case VISIBILITY.index:
          // custom criteria is selected, but no criteria is entered,
          // do not throw error since flow owner is still a viewer
          if (blockData.VISIBILITY?.type === visibilityTypes.CUSTOM) {
            return !!blockData.VISIBILITY?.criteriaGroups || owner.length > 0;
          }

          // If visibility type is owner and there are no owners in the left panel,
          // block should be invalidated
          if (blockData.VISIBILITY?.type === visibilityTypes.OWNER_ONLY)
            return owner.length > 0;

          return !!blockData.VISIBILITY?.type;

        case CONTENT.index: {
          const optionBlocksWithError = blockData.CONTENT?.contentBlocks.filter(
            (block) => {
              // Should have at least 1 option if it is a dropdown or multi choice block
              if (block.type === 'DROPDOWN' || block.type === 'MULTI_CHOICE')
                return block?.options.length < 1;
              return false;
            },
          );
          return (
            isEmpty(optionBlocksWithError) &&
            !!blockData.CONTENT?.contentBlocks.length
          );
        }

        case COMPLETION.index:
          return !!(
            flowName &&
            flowName.length <= BuilderFlowNameCharacterLimit &&
            emoji &&
            owner.length
          );

        default:
          return false;
      }
    },
    [
      blockData,
      emoji,
      flowName,
      owner.length,
      ACTION.index,
      TRIGGER.index,
      PARTICIPANTS.index,
      VISIBILITY.index,
      CONTENT.index,
      COMPLETION.index,
    ],
  );

  const hasBuilderError = useMemo(() => {
    return (
      allBlocks.filter((blockKey: keyof typeof FlowBuilderBlockTypes) => {
        return !isCurrentBlockDataValid(defaultBuilderBlocks[blockKey].index);
      }).length > 0
    );
  }, [allBlocks, isCurrentBlockDataValid]);

  return { isValid: !hasBuilderError, isCurrentBlockDataValid };
};
