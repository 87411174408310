import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import Body from '../../../../atomic/atoms/Body';
import Checkbox from '../../../../atomic/atoms/Checkbox';
import Dropdown from '../../../../atomic/molecules/Dropdown_V2';

import ThemeV2 from '../../../../componentsV2/theme';

import {
  DropdownSizes,
  DropdownColor,
  DropdownVariants,
  DropdownPlacement,
} from '../../../../atomic/molecules/Dropdown_V2/interfaces';

import { isMobile, isTablet } from '../../../../Utils/window';
import { linkAccessPermissionDropDownItems, linkPermissions } from './data';

import {
  StyledFlex,
  StyledBody,
  StyledAvatar,
  CopyLinkButton,
  CopyLinkContainer,
  HelperTextWrapper,
  UpdateSelectionButton,
  LinkAccessSettingsWrapper,
} from './styles';

import { Flex } from '../../../../Utils/styles/display';
import { LinkAccessSettingsControllerProps } from './types';
import { copyToClipboard } from '../../../../Utils/common';
import { showSuccessMessage } from '../../../../Utils/toast';

import { SHARE_SHEET_MODAL } from '../../../../languages/en/flows/builder';
import { COPIED_TO_CLIPBOARD, COPY_LINK } from '../../../../languages/en/flows';
import {
  ANYONE_CAN_RESPOND_LABEL,
  ANYONE_CAN_VIEW_AND_RESPOND_LABEL,
  ANYONE_CAN_VIEW_LABEL,
  ANYONE_WITH_LINK,
  ANYONE_WITH_LINK_CAN_RESPOND,
  EVERYONE_IN_THIS_WORKSPACE_LABEL,
  ONLY_PEOPLE_OR_RULES_LABEL,
  PARTICIPANTS_AND_VIEWERS_LABEL,
  PARTICIPANTS_LABEL,
  VIEWERS_LABEL,
} from '../../../../languages/en/flows/sharesheet';
import useGetUserAddEveryoneToFlowPermission from '../../../../hooks/useGetUserAddEveryoneToFlowPermission';
import { getIconSettingsForLinkAccess } from './utils';

const LinkAccessSettingsController = (
  props: LinkAccessSettingsControllerProps,
) => {
  const {
    viewersCount,
    participantsCount,
    selectedLinkAccess,
    onChangeLinkAccess,
    selectedLinkPermission,
    onChangeLinkPermission,
    onUpdateSelectionClick,
    isFlowVisibleInFlowSheet,
    onFlowVisibleInFlowSheetChanged,
  } = props;

  const { canUserSelectEveryOne } = useGetUserAddEveryoneToFlowPermission();

  const showCreateFlow = !isMobile && !isTablet && canUserSelectEveryOne;

  const { flowId } = useParams<{ flowId: string }>();

  const iconSettings = useMemo(() => {
    return getIconSettingsForLinkAccess(selectedLinkAccess);
  }, [selectedLinkAccess]);

  const handleCopyFlowFeedLink = () => {
    copyToClipboard(window.location.origin + `/flows/${flowId}`);
    showSuccessMessage(COPIED_TO_CLIPBOARD);
  };

  return (
    <LinkAccessSettingsWrapper>
      <Body variant="body1Medium">
        {SHARE_SHEET_MODAL.WHO_CAN_ACCESS_FLOW_LABEL}
      </Body>
      <Body variant="body3">
        {SHARE_SHEET_MODAL.CAN_ACCESS_FLOW_DESCRIPTION}
      </Body>
      <StyledFlex justifyContent="space-between">
        <Flex>
          <StyledAvatar
            size="32px"
            icon={iconSettings.icon}
            iconBgColor={iconSettings.bgColor}
            iconColor={iconSettings.iconColor}
          />
          <Dropdown
            isFullWidth
            value={selectedLinkAccess}
            size={DropdownSizes.Small}
            color={DropdownColor.Secondary}
            onItemClick={onChangeLinkAccess}
            variant={DropdownVariants.Contained}
            menuItems={linkAccessPermissionDropDownItems}
            dropdownPlacement={DropdownPlacement.BottomEnd}
            data-testid="link access dropdown"
          />
        </Flex>

        {selectedLinkAccess !== ONLY_PEOPLE_OR_RULES_LABEL && (
          <Dropdown
            size={DropdownSizes.Small}
            menuItems={linkPermissions}
            value={selectedLinkPermission}
            color={DropdownColor.Secondary}
            variant={DropdownVariants.Text}
            onItemClick={onChangeLinkPermission}
            dropdownPlacement={DropdownPlacement.BottomEnd}
            disabled={selectedLinkAccess === ANYONE_WITH_LINK}
          />
        )}
      </StyledFlex>
      <HelperTextWrapper>
        {selectedLinkAccess === EVERYONE_IN_THIS_WORKSPACE_LABEL && (
          <Body variant="body3">
            {selectedLinkPermission === PARTICIPANTS_LABEL &&
              ANYONE_CAN_RESPOND_LABEL}
            {selectedLinkPermission === VIEWERS_LABEL && ANYONE_CAN_VIEW_LABEL}
            {selectedLinkPermission === PARTICIPANTS_AND_VIEWERS_LABEL &&
              ANYONE_CAN_VIEW_AND_RESPOND_LABEL}
          </Body>
        )}
        {selectedLinkAccess === ANYONE_WITH_LINK && (
          <Body variant="body3">{ANYONE_WITH_LINK_CAN_RESPOND}</Body>
        )}
        {selectedLinkAccess === ONLY_PEOPLE_OR_RULES_LABEL &&
          (!viewersCount && !participantsCount ? (
            <Body variant="body3">
              {SHARE_SHEET_MODAL.NO_ONE_SELECTED_LABEL}
            </Body>
          ) : (
            <Body variant="body3">
              {participantsCount} participants • {viewersCount} viewers
            </Body>
          ))}
        {selectedLinkAccess === ONLY_PEOPLE_OR_RULES_LABEL && (
          <div onClick={onUpdateSelectionClick}>
            <UpdateSelectionButton variant="body3">
              Update selection
            </UpdateSelectionButton>
          </div>
        )}
      </HelperTextWrapper>

      <CopyLinkContainer>
        <Body variant="body1Medium">
          {SHARE_SHEET_MODAL.SHARE_YOUR_FLOW_LABEL}
        </Body>
        <Flex>
          <StyledBody variant="body2" color={ThemeV2.palette.gray7}>
            {window.location.origin + `/flows/${flowId}`}
          </StyledBody>
          <CopyLinkButton
            icon="Link"
            size="medium"
            variant="text"
            color="primary"
            onClick={handleCopyFlowFeedLink}
            dataTestId="link access copy button"
          >
            {COPY_LINK}
          </CopyLinkButton>
        </Flex>
      </CopyLinkContainer>
      {showCreateFlow && selectedLinkAccess !== ONLY_PEOPLE_OR_RULES_LABEL && (
        <Checkbox
          name="flow-visible-in-flow-sheet"
          size="16px"
          value={isFlowVisibleInFlowSheet}
          onChange={onFlowVisibleInFlowSheetChanged}
          label={SHARE_SHEET_MODAL.FLOWS_LINK_VISIBILITY_LABEL}
        />
      )}
    </LinkAccessSettingsWrapper>
  );
};

export default LinkAccessSettingsController;
