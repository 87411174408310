import React from 'react';
import { useParams } from 'react-router-dom';

import FlowFeedFilter from '../../../../atomic/organism/RightDrawerFilter/FlowFeedFilter';
import { ComponentStatus } from '../../../../interfaces/component';

import useFilterControllerLogic from './useFilterControllerLogic';
import { FilterControllerProps } from '../../types';

const FilterController = ({ setIsFiltered, isTab }: FilterControllerProps) => {
  const { flowId } = useParams<{ flowId: string }>();
  const { models, operations } = useFilterControllerLogic({
    flowId,
    setIsFiltered,
  });
  const {
    dateFilterProps,
    blockFilterProps,
    postedByFilterProps,
    mentionedFilterProps,
    isLoading,
    isError,
    isEmpty,
    isFilterButtonEnabled,
  } = models;
  const { handleOnClear, handleOnSubmit } = operations;

  if (isLoading) {
    return <FlowFeedFilter status={ComponentStatus.LOADING} />;
  }
  if (isError) {
    return <FlowFeedFilter status={ComponentStatus.ERROR} />;
  }
  if (isEmpty) {
    return <FlowFeedFilter status={ComponentStatus.EMPTY} />;
  }

  return (
    <FlowFeedFilter
      status={ComponentStatus.LOADED}
      dateFilterProps={dateFilterProps}
      blockFilterProps={blockFilterProps}
      postedByFilterProps={postedByFilterProps}
      mentionedByFilterProps={mentionedFilterProps}
      onClear={handleOnClear}
      onSubmit={handleOnSubmit}
      isEmpty={isEmpty}
      isTab={isTab}
      isFilterButtonEnabled={isFilterButtonEnabled}
    />
  );
};

export default FilterController;
