import styled from 'styled-components';
import { StyledIconWrapperProps } from './types';

export const StyledTextWithIcon = styled.div`
  display: flex;
  transition: 0.2s ease;
  transition-property: background;
`;

export const StyledIconWrapper = styled.div<StyledIconWrapperProps>`
  display: inline-flex;
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  margin-right: ${({ iconWrapperRightMargin }) =>
    iconWrapperRightMargin || '4px'};
  align-items: center;
  justify-content: center;
  background: ${({ iconWrapperBackground }) =>
    iconWrapperBackground && iconWrapperBackground};
  border-radius: ${({ roundedIcon }) => (roundedIcon ? '50%' : '4px')};
  transition: 0.2s ease;
  transition-property: background;
  svg {
    ${({ hasGlow }) =>
      hasGlow &&
      `box-shadow: 0px 0px 4px rgb(35 206 7 / 65%);
    border-radius: 50%;`}
  }
`;
