import { ExternalErrorTypes } from '../useLoadedExternalFlowsController';
import {
  ExternalParticipationTemplateErrorTypes,
  ParticipationTemplateErrorTypes,
} from '../../../../atomic/pages/ParticipationTemplate/types';

export const getErrorMessageAndType = (
  errorMessage: string,
): ExternalErrorTypes => {
  switch (errorMessage) {
    case ExternalParticipationTemplateErrorTypes.ACCESS_DENIED:
    case ExternalParticipationTemplateErrorTypes.INVALID_FLOW_ID:
    case ExternalParticipationTemplateErrorTypes.FLOW_NOT_FOUND:
    case ExternalParticipationTemplateErrorTypes.FLOW_NOT_AVAILABLE:
    case ExternalParticipationTemplateErrorTypes.FLOW_UPDATED:
    case ExternalParticipationTemplateErrorTypes.ACTIVE_OCCURRENCE_ENDED:
      return {
        type: 'external',
        errorMessage,
        buttonType: 'primary',
      };
    case ParticipationTemplateErrorTypes.UNABLE_TO_BUILD_INSTANCE_FROM_BLOCKS:
    case ParticipationTemplateErrorTypes.INVALID_OCCURRENCE_ID:
    case ParticipationTemplateErrorTypes.FLOW_NOT_AVAILABLE:
      return {
        type: 'external',
        errorMessage: ExternalParticipationTemplateErrorTypes.FLOW_NOT_FOUND,
        buttonType: 'primary',
      };

    case ParticipationTemplateErrorTypes.NO_ACTIVE_OCCURRENCE_FOUND:
      return {
        type: 'external',
        errorMessage:
          ExternalParticipationTemplateErrorTypes.NO_ACTIVE_OCCURRENCE_FOUND,
        buttonType: 'primary',
      };

    default:
      return undefined;
  }
};
