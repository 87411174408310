import React from 'react';
import { OpenEndedBlockBuilderSettingState } from '../../../interfaces/Flow/Builder';
import {
  MINIMUM_CHARACTERS,
  MAXIMUM_CHARACTERS,
  SHOW_ALL_FORMATTING_OPTIONS,
  MENTIONS_STRING,
} from '../../../languages/en/flows/builder';
import { EMOJIS, GIFS, ATTACHMENTS } from '../../../languages/en/singleWords';
import { Flex } from '../../../Utils/styles/display';
import Textfield from '../../molecules/NewTextfield';
import Checkbox from '../../atoms/Checkbox';
import {
  MinimumCharacterTextField,
  OpenEndedCheckboxOptionsContainer,
} from './styles';

const OpenEndedSettings = ({
  blockSettingState,
}: {
  blockSettingState: OpenEndedBlockBuilderSettingState;
}) => {
  const {
    minimumCharacters,
    maximumCharacters,
    onCharactersLimitChange,
    openEndedOptions,
    changeOpenEndedOptions,
  } = blockSettingState;
  const isAnyOptionSelected = Object.keys(openEndedOptions).length > 0;
  const onBlur = () => {
    if (
      minimumCharacters !== undefined &&
      maximumCharacters !== undefined &&
      minimumCharacters > maximumCharacters
    ) {
      onCharactersLimitChange(minimumCharacters, minimumCharacters + 1);
    }
  };
  return (
    <div>
      <Flex margin="0 0 16px 0">
        <MinimumCharacterTextField
          type="number"
          label={MINIMUM_CHARACTERS}
          value={String(
            minimumCharacters === undefined ? '' : minimumCharacters,
          )}
          onChange={(e) =>
            onCharactersLimitChange(
              e.target.value ? Math.abs(Number(e.target.value)) : undefined,
              maximumCharacters,
            )
          }
          onBlur={onBlur}
        />
        <Textfield
          type="number"
          label={MAXIMUM_CHARACTERS}
          value={String(
            maximumCharacters === undefined ? '' : maximumCharacters,
          )}
          onChange={(e) =>
            onCharactersLimitChange(
              minimumCharacters,
              e.target.value ? Math.abs(Number(e.target.value)) : undefined,
            )
          }
          onBlur={onBlur}
        />
      </Flex>
      <Checkbox
        name="select-formatting-options"
        value={isAnyOptionSelected}
        label={SHOW_ALL_FORMATTING_OPTIONS}
        size="16px"
        onChange={() =>
          changeOpenEndedOptions(
            isAnyOptionSelected
              ? {}
              : {
                  attachments: true,
                  emojis: true,
                  gifs: true,
                  mentions: true,
                },
          )
        }
      />
      <OpenEndedCheckboxOptionsContainer>
        <Checkbox
          name="formatting-mentions"
          value={openEndedOptions?.mentions}
          label={MENTIONS_STRING}
          size="16px"
          onChange={() =>
            changeOpenEndedOptions({
              ...openEndedOptions,
              mentions: !openEndedOptions?.mentions,
            })
          }
        />
        <Checkbox
          name="formatting-emojis"
          size="16px"
          value={openEndedOptions?.emojis}
          label={EMOJIS}
          onChange={() =>
            changeOpenEndedOptions({
              ...openEndedOptions,
              emojis: !openEndedOptions?.emojis,
            })
          }
        />
        <Checkbox
          name="formatting-gifs"
          size="16px"
          value={openEndedOptions?.gifs}
          label={GIFS}
          onChange={() =>
            changeOpenEndedOptions({
              ...openEndedOptions,
              gifs: !openEndedOptions?.gifs,
            })
          }
        />
        <Checkbox
          name="formatting-attachments"
          size="16px"
          value={openEndedOptions?.attachments}
          label={ATTACHMENTS}
          onChange={() =>
            changeOpenEndedOptions({
              ...openEndedOptions,
              attachments: !openEndedOptions?.attachments,
            })
          }
        />
      </OpenEndedCheckboxOptionsContainer>
    </div>
  );
};

export default OpenEndedSettings;
