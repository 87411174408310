import type {
  FeatureIntroOptions,
  FeatureHintOptions,
} from '../../hooks/useFeatureIntro';
import {
  ShareSheetHintStrings,
  shareSheetModalIntroStrings,
  shareSheetButtonIntroStrings,
} from '../../languages/en/featureIntros/ShareSheetIntroStrings';

const getShareSheetModalIntroSteps = () => {
  const steps = shareSheetModalIntroStrings();
  return [
    {
      ...steps.firstStep,
      element: '.introjs-sharesheet-modal',
    },
    {
      ...steps.secondStep,
      element: '.introjs-sharesheet-participants',
    },
    {
      ...steps.thirdStep,
      element: '.introjs-sharesheet-viewers',
    },
  ];
};

const getShareSheetHintSteps = () => {
  const hints = ShareSheetHintStrings();
  return [
    {
      ...hints.firstHint,
      element: '.introjs-sharesheet-share-button',
      hintPosition: 'middle-left',
    },
  ];
};

const getShareSheetButtonIntroSteps = () => {
  const steps = shareSheetButtonIntroStrings();
  return [
    {
      ...steps.firstStep,
    },
    {
      ...steps.secondStep,
      element: '.introjs-sharesheet-share-button',
    },
  ];
};

export const getShareSheetModalIntroOptions = (): FeatureIntroOptions => {
  return {
    getStepDetails() {
      return getShareSheetModalIntroSteps();
    },
  };
};

export const getShareSheetButtonIntroOptions = (): FeatureIntroOptions => {
  return {
    getStepDetails() {
      return getShareSheetButtonIntroSteps();
    },
  };
};

export const getShareSheetHintOptions = (): FeatureHintOptions => {
  return {
    getHintDetails() {
      return getShareSheetHintSteps();
    },
  };
};
