import React from 'react';

import Body from '../../../atoms/Body';
import {
  AutoMarginDiv,
  ChildrenWrapper,
  StyledBlockFooter,
  StyledDescriptionBody,
  StyledErrorContainer,
  StyledFieldErrorBody,
  StyledHeading,
  StyledInfoContainer,
  StyledInputActionWrapper,
  StyledNavigationInstructionsWrapper,
  StyledRequiredSpan,
  StyledSubDescriptionBody,
  StyledSVGIcon,
  StyledWarningContainer,
  StyledWrapper,
} from './styles';
import { Flex } from '../../../../Utils/styles/display';
import ThemeV2 from '../../../../componentsV2/theme';

export interface FlowsBaseInputBlockProps {
  blockError?: string;
  children: React.ReactNode;
  description?: string;
  fieldError?: string;
  hasError?: boolean;
  inputAction?: React.ReactNode;
  isRequired?: boolean;
  navigationInstructions?: React.ReactNode;
  subDescription?: React.ReactNode;
  title: string | React.ReactNode;
  warning?: React.ReactNode;
  info?: React.ReactNode;
  className?: string;
  childMargin?: string;
}

const FlowsBaseInputBlock = ({
  blockError,
  children,
  description,
  fieldError,
  hasError = false,
  inputAction,
  isRequired,
  navigationInstructions,
  subDescription,
  title,
  warning,
  info,
  className,
  childMargin,
}: FlowsBaseInputBlockProps) => {
  return (
    <StyledWrapper hasError={hasError} className={className}>
      <AutoMarginDiv>
        {blockError && (
          <StyledErrorContainer>
            <StyledSVGIcon
              color={ThemeV2.palette.dustRed6}
              icon="info-outlined"
              size="16px"
            />
            <Body variant="body3">{blockError}</Body>
          </StyledErrorContainer>
        )}

        {warning && (
          <StyledWarningContainer>
            <StyledSVGIcon
              color={ThemeV2.palette.gold6}
              icon="info-outlined"
              size="16px"
            />
            <Body variant="body3">{warning}</Body>
          </StyledWarningContainer>
        )}

        {info && (
          <StyledInfoContainer>
            <StyledSVGIcon
              color={ThemeV2.palette.geekBlue6}
              icon="info-outlined"
              size="16px"
            />
            <Body variant="body3">{info}</Body>
          </StyledInfoContainer>
        )}

        {title !== '' && (
          <Flex>
            <StyledHeading
              color={ThemeV2.palette.gray9}
              variant="h6"
              weight="medium"
            >
              {title}
              {isRequired && <StyledRequiredSpan>*</StyledRequiredSpan>}
            </StyledHeading>
          </Flex>
        )}

        {description && (
          <StyledDescriptionBody variant="body2" color="gray8">
            {description}
          </StyledDescriptionBody>
        )}
        {subDescription && (
          <StyledSubDescriptionBody
            variant="body3"
            color="gray7"
            data-testid="subDescription"
          >
            {subDescription}
          </StyledSubDescriptionBody>
        )}

        <ChildrenWrapper childMargin={childMargin}>{children}</ChildrenWrapper>
        {fieldError && (
          <StyledFieldErrorBody variant="body4Medium" color="dustRed6">
            {fieldError}
          </StyledFieldErrorBody>
        )}
        <StyledBlockFooter>
          {navigationInstructions && (
            <StyledNavigationInstructionsWrapper>
              {navigationInstructions}
            </StyledNavigationInstructionsWrapper>
          )}
          {inputAction && (
            <StyledInputActionWrapper>{inputAction}</StyledInputActionWrapper>
          )}
        </StyledBlockFooter>
      </AutoMarginDiv>
    </StyledWrapper>
  );
};

export default FlowsBaseInputBlock;
