import React, { useState, ReactElement, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import EntitySelectionTable from '../../../componentsV2/custom/EntitySelectionTable';
import { EntityListItem } from '../../../interfaces/identityManager/common';
import {
  getCheckedValue,
  getSideText,
} from '../../../Utils/identity/selectPeople';
import { AvatarProps } from '../../atoms/Avatar/interfaces';
import usePrevious from '../../../hooks/usePrevious';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  itemCol: {
    flexBasis: '0',
    flexGrow: 1,
    maxWidth: '100%',
    padding: '0px 7px',
  },
});

export interface EntityProps {
  id: string;
  mainText: string;
  sideText?: string;
  src?: string;
  isSelected?: boolean;
  checked?: boolean;
  membersCount?: number;
  avatar?: AvatarProps;
}

export const useEditEntityComponent = (entityList: EntityProps[]) => {
  const EntityDict: { [Key: string]: EntityProps } = {};
  entityList.forEach((entity) => {
    EntityDict[entity.id] = entity;
  });

  const [allList, setAllList] = useState<EntityProps[]>([]);
  const [newList, setNewList] = useState<EntityProps[]>([]);
  const [removeList, setRemoveList] = useState<EntityProps[]>([]);

  const calculateLists = useCallback((entityListArray: EntityProps[]) => {
    const newAllList: EntityProps[] = [];
    const newNewList: EntityProps[] = [];
    const newRemoveList: EntityProps[] = [];
    entityListArray.forEach((listItem) => {
      if (listItem.checked && listItem.checked === true) {
        if (listItem.isSelected && listItem.isSelected === true) {
          newRemoveList.push(listItem);
        } else {
          newNewList.push(listItem);
        }
      }
      newAllList.push(listItem);
    });
    setAllList(newAllList);
    setNewList(newNewList);
    setRemoveList(newRemoveList);
  }, []);

  useEffect(() => {
    calculateLists(entityList);
  }, [calculateLists, entityList]);

  const checkIfSelectedAll = useCallback(
    () => !allList.some((entity) => !getCheckedValue(entity)),
    [allList],
  );

  const onUpdateEntity = useCallback(
    ({ id }: { id: string }) => {
      const entitySelectedIndex = allList.findIndex(
        (entity) => entity.id === id,
      );
      const entitySelected = allList[entitySelectedIndex];
      const updatedEntity = {
        ...entitySelected,
        checked: !entitySelected.checked,
      };
      const newEntityList = [
        ...allList.slice(0, entitySelectedIndex),
        updatedEntity,
        ...allList.slice(entitySelectedIndex + 1),
      ];
      calculateLists(newEntityList);
      checkIfSelectedAll();
    },
    [allList, calculateLists, checkIfSelectedAll],
  );

  const onUpdateMultipleEntity = useCallback(
    (ids: string[]) => {
      const newEntityList = allList.map((item) => {
        if (ids.indexOf(item.id) !== -1) {
          return {
            ...item,
            checked: !item.checked,
          };
        }
        return item;
      });
      calculateLists(newEntityList);
      checkIfSelectedAll();
    },
    [allList, calculateLists, checkIfSelectedAll],
  );

  const onSelectUnSelectAllEntities = useCallback(() => {
    const selectAll = !checkIfSelectedAll();
    const newAllList = allList.map((entity) => ({
      ...entity,
      checked: entity?.isSelected === true ? !selectAll : selectAll,
    }));
    calculateLists(newAllList);
  }, [allList, checkIfSelectedAll, calculateLists]);

  const selectedList = allList.filter((entity) => getCheckedValue(entity));

  const renderList = useCallback(
    (newAllList) => {
      calculateLists(newAllList);
    },
    [calculateLists],
  );

  const models = React.useMemo(() => {
    return {
      allList,
      newList,
      removeList,
      selectedList,
      isSelectedAll: checkIfSelectedAll(),
    };
  }, [allList, checkIfSelectedAll, newList, removeList, selectedList]);

  const operations = React.useMemo(() => {
    return {
      onUpdateEntity,
      onSelectUnSelectAllEntities,
      renderList,
      onUpdateMultipleEntity,
    };
  }, [
    onSelectUnSelectAllEntities,
    onUpdateEntity,
    renderList,
    onUpdateMultipleEntity,
  ]);

  return {
    models,
    operations,
  };
};

const EditEntityComponent = ({
  entityList,
  paginatedDataAddedEntityList,
  paginatedDataRemovedEntityList,
  onChange,
  onItemClick,
  entityCountMap,
  allListEntityTableHeading,
  newListEntityTableHeading,
  removeListEntityTableHeading,
  searchBoxPlaceHolder,
  showSelectAll,
  allEntitySearchText,
  handleAllEntitySearchText,
  handleFetchNextPage,
  hasNextPage,
  hasPaginatedIntegrationData,
}: any): ReactElement => {
  const classes = useStyles();

  const handleFilterClick = () => {};

  const { models, operations } = useEditEntityComponent(entityList);
  const { onUpdateEntity, onSelectUnSelectAllEntities, renderList } =
    operations;

  const handleItemClick = useCallback(
    (entity: EntityListItem) => {
      if (onItemClick) {
        onItemClick(entity);
      }
      onUpdateEntity(entity);
    },
    [onItemClick, onUpdateEntity],
  );

  const prevAllList = usePrevious(models.allList);
  useEffect(() => {
    if (prevAllList && prevAllList !== models.allList) {
      onChange({
        allList: models.allList,
        newList: models.newList,
        removeList: models.removeList,
      });
    }
  }, [prevAllList, models, onChange]);

  const prevEntityCountMap = usePrevious(entityCountMap);
  useEffect(() => {
    if (prevEntityCountMap && prevEntityCountMap !== entityCountMap) {
      if (models.allList) {
        const newEntityList = models.allList.map((entity) => {
          const { id: entityId } = entity;
          if (entityCountMap[entityId]) {
            const { isLoading, count: entityCount } = entityCountMap[entityId];
            return {
              ...entity,
              isLoading,
              sideText: getSideText(entityCount),
              membersCount: entityCount,
            };
          }
          return { ...entity };
        });
        renderList(newEntityList);
      }
    }
  }, [entityCountMap, models.allList, prevEntityCountMap, renderList]);

  return (
    <div className={classes.root} id="entity-container">
      <div className={classes.itemCol}>
        {/* TODO: Add Interface for SVGIcon - Convert it to Typescript
      //  @ts-ignore */}
        <EntitySelectionTable
          handleFetchNextPage={handleFetchNextPage}
          hasNextPage={hasNextPage}
          hasPaginatedIntegrationData={hasPaginatedIntegrationData}
          heading={allListEntityTableHeading}
          entityList={models.allList}
          onFilterClick={handleFilterClick}
          showCheckbox
          showHoverOption={false}
          searchBoxPlaceHolder={searchBoxPlaceHolder}
          onItemClick={handleItemClick}
          showSelectAll={models.allList.length > 0 && showSelectAll}
          onSelectAllClick={onSelectUnSelectAllEntities}
          isSelectedAll={models.isSelectedAll}
          allEntitySearchText={allEntitySearchText}
          handleAllEntitySearchText={handleAllEntitySearchText}
        />
      </div>
      <div className={classes.itemCol}>
        {/* TODO: Add Interface for SVGIcon - Convert it to Typescript
      //  @ts-ignore */}
        <EntitySelectionTable
          heading={newListEntityTableHeading}
          entityList={
            hasPaginatedIntegrationData
              ? paginatedDataAddedEntityList
              : models.newList
          }
          onFilterClick={handleFilterClick}
          showCheckbox={false}
          showHoverOption
          hoverOptionText="Remove"
          onHoverOptionClick={handleItemClick}
          searchBoxPlaceHolder={searchBoxPlaceHolder}
          disabled={
            showSelectAll && hasPaginatedIntegrationData
              ? paginatedDataAddedEntityList.length === 0
              : models.newList.length === 0
          }
        />
      </div>
      <div className={classes.itemCol}>
        {/* TODO: Add Interface for SVGIcon - Convert it to Typescript
      //  @ts-ignore */}
        <EntitySelectionTable
          heading={removeListEntityTableHeading}
          entityList={
            hasPaginatedIntegrationData
              ? paginatedDataRemovedEntityList
              : models.removeList
          }
          onFilterClick={handleFilterClick}
          showCheckbox={false}
          showHoverOption
          hoverOptionText="Keep"
          onHoverOptionClick={handleItemClick}
          searchBoxPlaceHolder={searchBoxPlaceHolder}
          disabled={
            showSelectAll && hasPaginatedIntegrationData
              ? paginatedDataRemovedEntityList.length === 0
              : models.removeList.length === 0
          }
        />
      </div>
    </div>
  );
};

export default EditEntityComponent;
