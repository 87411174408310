import { Redirect } from 'react-router-dom';
import { SIGN_IN } from '../../../constants/routes';
import { ComponentStatus } from '../../../interfaces/component';
import RecognitionFlowController from '../../home/RecognitionFlowController';
import { profileData as defaultProfileData } from '../../../Utils/home/feeds/dummyData';
import { LoadingParticipationTemplate } from '../../../atomic/pages/ParticipationTemplate';
import useRecognitionParticipationController from './useRecognitionParticipationController';

const RecognitionParticipationController = () => {
  const {
    profileData,
    isLoadingProfileData,
    loadingFlowHeaderContent,
    handleCloseParticipationModal,
    profileDataForRecognitionModalDropdown,
  } = useRecognitionParticipationController();

  if (isLoadingProfileData) {
    return (
      <LoadingParticipationTemplate
        isFullScreen
        flowId={''}
        onCloseModal={() => {}}
        member={defaultProfileData.member}
        headerStatus={ComponentStatus.LOADING}
        flowHeaderContent={loadingFlowHeaderContent}
      />
    );
  }

  if (profileData && !isLoadingProfileData)
    return (
      <RecognitionFlowController
        profileInfo={profileData}
        toggleIsRecognitionFlowModalOpen={handleCloseParticipationModal}
        selectedTeammateDropdownItem={profileDataForRecognitionModalDropdown}
      />
    );

  return <Redirect to={SIGN_IN} />;
};

export default RecognitionParticipationController;
