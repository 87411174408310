import {
  ANYONE_WITH_LINK,
  ONLY_PEOPLE_OR_RULES_LABEL,
  EVERYONE_IN_THIS_WORKSPACE_LABEL,
} from '../../../../languages/en/flows/sharesheet';

import ThemeV2 from '../../../../componentsV2/theme';

export const getIconSettingsForLinkAccess = (selectedLinkAccess: string) => {
  switch (selectedLinkAccess) {
    case ANYONE_WITH_LINK:
      return {
        icon: 'globe',
        bgColor: `${ThemeV2.palette.green7}`,
        iconColor: `${ThemeV2.palette.white}`,
      };
    case EVERYONE_IN_THIS_WORKSPACE_LABEL:
      return {
        icon: 'building',
        iconColor: `${ThemeV2.palette.white}`,
        bgColor: `${ThemeV2.palette.geekBlue6}`,
      };
    case ONLY_PEOPLE_OR_RULES_LABEL:
    default:
      return {
        icon: 'users',
        iconColor: `${ThemeV2.palette.gray8}`,
        bgColor: `${ThemeV2.palette.gray3}`,
      };
  }
};
