// @ts-nocheck TODO: type issues need to be fixed in this file
import axios from 'axios';
import history from '../../history';
import { API_URL_WITH_V2 } from '../../config';
import processErrorResponse, {
  ErrorMessages,
} from '../../containers/helpers/ErrorHelper';

import { AuthHeaders, HeadersWithoutAuth } from '../shared/HeaderToken';
import { authenticateUser } from '../Session';
import Logger from '../../Utils/Logger';
import { updateMyDetails } from '../Me';
// ------------------------------------
// Helpers
// ------------------------------------
const apiEndpoints = {
  GET_DATA_FOR_TOKEN: `${API_URL_WITH_V2}/auth/onboard/token/`,
  SET_PASSWORD_FOR_TOKEN: `${API_URL_WITH_V2}/auth/onboard/employee/inviteToken/setPassword`,
  REGISTER_EMPLOYEE_WITH_EMAIL: `${API_URL_WITH_V2}/auth/onboard/employee/register`,
  VERIFY_MAGIC_LINK: `${API_URL_WITH_V2}/auth/onboard/employee/verify/magiclink/`,
  SET_PASSWORD_VERIFIED_USER: `${API_URL_WITH_V2}/auth/onboard/employee/magiclink/setPassword`,
  USER_PROFILE: `${API_URL_WITH_V2}/auth/onboard/setProfile`,
};

const processError = (error = {}, dispatch) => {
  const statusCode = error.response ? error.response.status : 0;
  switch (statusCode) {
    case 400: // bad request
      dispatch(actions.requestFailed(ErrorMessages.serverError));
      break;
    case 401: // Not authorised
      // (Log out user)
      break;
    case 403: // Forbidden
      // redirect to home
      break;
    case 404: // Page not found
      history.push('/404');
      break;
    // mostly my error
    case 422:
    case 500:
      if (error.response && error.response.data) {
        const responseData = error.response.data;
        const msg = processErrorResponse(responseData);
        Logger(msg);
        Logger(responseData);
        dispatch(actions.requestFailed(msg));
      }
      break;
    default:
      dispatch(actions.requestFailed(ErrorMessages.networkError));
      break;
  }
};

// ------------------------------------
// Constants
// ------------------------------------

export const UPDATE_INVITE_USER_DATA = 'UPDATE_INVITE_USER_DATA';
export const REMOVE_INVITE_USER_DATA = 'REMOVE_INVITE_USER_DATA';
export const GOT_DATA_FOR_TOKEN = 'got_data_for_token';
export const GOT_EMPLOYER_INFO = 'got_employer_info';
export const VERIFIED_MAGIC_LINK = 'verified_magic_link';
export const MAGIC_LINK_NOT_VALID = 'magic_link_not_valid';
export const SIGNUP_REQUEST_FAILED = 'signup_request_failes';
// export const REGISTERED_EMPLOYEE_WITH_EMAIL = 'register_employee_with_email';

// ------------------------------------
// Actions
// ------------------------------------

const updateInviteUserData = (inviteUser) => ({
  type: UPDATE_INVITE_USER_DATA,
  inviteUser,
});

const removeInviteUserData = () => ({
  type: UPDATE_INVITE_USER_DATA,
});

const gotTokenDetailsSuccess = (user, employer) => ({
  type: GOT_DATA_FOR_TOKEN,
  user,
  employer,
});
const gotEmployerInfoSuccess = (employer) => ({
  type: GOT_EMPLOYER_INFO,
  employer,
});

const verifyMagicLinkFailed = (errorMessage) => ({
  type: MAGIC_LINK_NOT_VALID,
  errorMessage,
});

const verifiedMagicLinkSuccess = (user, employer) => ({
  type: VERIFIED_MAGIC_LINK,
  user,
  employer,
});
const requestFailed = (errorMessage) => ({
  type: SIGNUP_REQUEST_FAILED,
  error: errorMessage,
});
// const registered = (employer) => {
//   return {
//     type: GOT_EMPLOYER_INFO,
//     employer,
//   };
// };

export const actions = {
  updateInviteUserData,
  removeInviteUserData,
  gotTokenDetailsSuccess,
  gotEmployerInfoSuccess,
  verifyMagicLinkFailed,
  verifiedMagicLinkSuccess,
  requestFailed,
};

// ------------------------------------
// API Wrapper
// ------------------------------------

export const getTokenDetailsAction = (token) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${apiEndpoints.GET_DATA_FOR_TOKEN}${token}`,
      HeadersWithoutAuth(),
    );
    const result = res.data;
    Logger(result, false);
    if (result.success) {
      const user = result.data;
      const company = user.employerId;
      dispatch(actions.gotTokenDetailsSuccess(user, company));
    } else {
    }
  } catch (error) {
    processError(error, dispatch);
  }
};

export const setTokenUserPasswordAction = (props) => {
  const { password, inviteToken } = props;
  return async (dispatch) => {
    try {
      const res = await axios.post(
        apiEndpoints.SET_PASSWORD_FOR_TOKEN,
        { password, inviteToken },
        HeadersWithoutAuth(),
      );
      const result = res.data;
      Logger(result, false);
      if (result.success) {
        const userData = result.data.user;
        updateMyDetails(userData, dispatch);

        // dispatch(sessionActions.authenticated());
        history.push('/signup/name');
      } else {
      }
    } catch (error) {
      processError(error, dispatch);
    }
  };
};

export const registerEmployeeWithEmail = (props) => {
  Logger(props, false);
  const domain = window.location.hostname;
  const { email, employerId } = props;
  return async (dispatch) => {
    try {
      const res = await axios.post(
        `${apiEndpoints.REGISTER_EMPLOYEE_WITH_EMAIL}`,
        { email, employerId, domain },
        HeadersWithoutAuth(),
      );
      const result = res.data;
      Logger(result, false);
      if (result.success) {
        history.push('/signup/emailsent');
      } else {
      }
    } catch (error) {
      processError(error, dispatch);
    }
  };
};

export const verifyMagicLink = (magicLink) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${apiEndpoints.VERIFY_MAGIC_LINK}${magicLink}`,
      HeadersWithoutAuth(),
    );
    const result = res.data;
    Logger(result, false);
    if (result.success) {
      const user = result.data;
      const company = user.employerId;
      dispatch(actions.verifiedMagicLinkSuccess(user, company));

      history.push('/signup/password/');
    } else {
    }
  } catch (error) {
    /* Handle the error response */
    // Special case to be handled outside of ProcessError;
    const responseData = error.response.data;
    if (responseData) {
      const msg = processErrorResponse(responseData);
      dispatch(actions.verifyMagicLinkFailed(msg));
    } else {
    }
  }
};
export const setEmailVerifiedUserPasswordAction = (props) => {
  const { password, userId } = props;
  return async (dispatch) => {
    try {
      const res = await axios.post(
        apiEndpoints.SET_PASSWORD_VERIFIED_USER,
        { password, userId },
        HeadersWithoutAuth(),
      );
      const result = res.data;
      Logger(result, false);
      if (result.success) {
        const userData = result.data.user;
        updateMyDetails(userData, dispatch);

        // dispatch(sessionActions.authenticated());
        history.push('/signup/details');
      } else {
      }
    } catch (error) {
      processError(error, dispatch);
    }
  };
};

export const updateUserDetailsAction = (props) => {
  const { firstName, lastName, department, hiredDate, birthDate, username } =
    props; //eslint-disable-line

  return async (dispatch) => {
    //eslint-disable-line
    try {
      const res = await axios.post(
        apiEndpoints.USER_PROFILE,
        {
          firstName,
          lastName,
          department,
          hiredDate,
          birthDate,
          username,
        },
        AuthHeaders(),
      );

      const result = res.data;
      if (result.success) {
        const userData = result.data;
        authenticateUser(userData, dispatch);
        history.push('/home');
      } else {
      }
    } catch (error) {
      processError(error, dispatch);
    }
  };
};

// ------------------------------------
// Reducers
// ------------------------------------

export default (state = {}, action) => {
  switch (action.type) {
    case UPDATE_INVITE_USER_DATA:
      return {
        ...state,
        inviteUser: action.inviteUser,
      };
    case REMOVE_INVITE_USER_DATA:
      return {
        ...state,
        inviteUser: null,
      };
    case GOT_DATA_FOR_TOKEN:
      return {
        ...state,
        user: action.user,
        employer: action.employer,
        error: null,
      };
    case GOT_EMPLOYER_INFO:
      return { ...state, employer: action.employer, error: null };
    case MAGIC_LINK_NOT_VALID:
      return { ...state, magicLinkError: action.errorMessage, error: null };
    case VERIFIED_MAGIC_LINK:
      return {
        ...state,
        verifiedUser: action.user,
        employer: action.employer,
        error: null,
      };
    case SIGNUP_REQUEST_FAILED:
      return { ...state, error: action.error };
    default:
      return state;
  }
};
