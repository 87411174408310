import React from 'react';
import styled from 'styled-components';

import Modal from '../../atoms/Modal';
import ThemeV2 from '../../../componentsV2/theme';

const StyledModal = styled(Modal)<{
  isFullScreen?: boolean;
}>`
  width: 100vw;
  height: 100%;

  @media (min-width: ${ThemeV2.breakPoints.md}) {
    width: ${({ isFullScreen }) => (isFullScreen ? '100vw' : '80vw')};
    height: ${({ isFullScreen }) => (isFullScreen ? '100vh' : '80vh')};
    max-width: ${({ isFullScreen }) => !isFullScreen && '1800px'};
    max-height: ${({ isFullScreen }) => !isFullScreen && '1013px'};
  }
`;

export interface FlowsParticipationModalProps {
  children: React.ReactNode;
  isOpen: boolean;
  handleClose: () => void;
  isFullScreen?: boolean;
}

const FlowsParticipationModal = ({
  children,
  handleClose,
  isOpen,
  isFullScreen,
}: FlowsParticipationModalProps) => {
  return (
    <StyledModal
      isOpen={isOpen}
      handleClose={handleClose}
      isFullScreen={isFullScreen}
    >
      {children}
    </StyledModal>
  );
};

export default FlowsParticipationModal;
