import { useState, useEffect, useCallback } from 'react';
import useInterval from '../useInterval/useInterval';

const usePolling = (
  callback: () => void, // FUNCTION TO CALL AT EACH INTERVAL.
  timeInterval = 3000,
  makeInitialRequest = true, // DECIDES WHETHER TO MAKE A CALL IMMEDIATELY ON MOUNT.
  startPollingImmediately = true,
) => {
  const [isPolling, setIsPolling] = useState(startPollingImmediately);
  useEffect(() => {
    if (makeInitialRequest && startPollingImmediately) {
      callback();
    }
  }, [callback, makeInitialRequest, startPollingImmediately]);
  useInterval(
    () => {
      callback();
    },
    isPolling ? timeInterval : null,
  );
  const stopPolling = useCallback(() => setIsPolling(false), []);
  const startPolling = useCallback(() => {
    if (makeInitialRequest) {
      callback();
    }
    setIsPolling(true);
  }, [callback, makeInitialRequest]);
  // RETURNS A FUNCTION WHICH HAS TO BE CALLED TO STOP THE POLLING.
  return { stopPolling, startPolling };
};

export default usePolling;
