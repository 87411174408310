import styled from 'styled-components';
import ThemeV2 from '../../../componentsV2/theme';
import Dropdown from '../../../atomic/molecules/Dropdown_V2';
import PopoverWithSubMenu from '../../../atomic/organism/PopoverWithSubMenu';
import Button from '../../../atomic/atoms/Button';

export const FilterCriteriaWrapper = styled.div`
  width: 100%;
`;

export const FilterCriteriaContainer = styled.div<{
  itemsLength: number;
  isEveryOneSelected: boolean;
}>`
  li {
    border-radius: ${({ itemsLength, isEveryOneSelected }) =>
      itemsLength === 1 && isEveryOneSelected && '4px'};
    border-bottom-left-radius: ${({ isEveryOneSelected }) =>
      !isEveryOneSelected && 0};
    border-bottom-right-radius: ${({ isEveryOneSelected }) =>
      !isEveryOneSelected && 0};
    border-top-left-radius: ${({ itemsLength }) => itemsLength > 1 && 0};
    border-top-right-radius: ${({ itemsLength }) => itemsLength > 1 && 0};
  }
`;

export const AddMoreCriteriaContainer = styled.div`
  border-top: 0.5px solid ${ThemeV2.palette.gray5};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  border-radius: 0 0 4px 4px;
  height: 32px;
  max-height: 32px;
  background-color: ${ThemeV2.palette.gray3};
  width: 100%;
`;

export const GroupCriteriaConditionDropdown = styled(Dropdown)`
  margin: 0 40px 8px;
  button {
    border-radius: 4px;
  }
`;

export const StyledPopoverWithSubMenu = styled(PopoverWithSubMenu)`
  width: 100%;
`;

export const AddMoreCriteriaButton = styled(Button)`
  width: 100%;

  &:not(:disabled):hover,
  &:not(:disabled):focus-visible {
    &:before {
      cursor: pointer;
      background: ${ThemeV2.palette.gray4};
    }
  }
`;
