import {
  EntityListItem,
  IdentityManagerIntegrationData,
  IntegrationDataEntity,
  IntegrationDataMember,
} from '../../interfaces/identityManager/common';
import { accountManagementTypeLanguageMap } from '../data/identityManager/common';
import accountManagementTypes from './accountManagementTypes';

export type EntityCountMapType = Record<
  string,
  { isLoading: boolean; count: number }
>;

export const getIdsFromList = (list: EntityListItem[]) =>
  list.map((item) => item.id);
export const getSideText = (membersCount: number): string => {
  const result = '';
  if (membersCount === 1) {
    return '1 Person';
  }
  if (membersCount > 1) {
    return `${membersCount} ${
      accountManagementTypeLanguageMap[accountManagementTypes.SELECTED_USERS]
    }`;
  }
  return result;
};
export const getMembersCount = (list: EntityListItem[]) =>
  list.reduce(
    (previousValue, currentValue) =>
      previousValue + (currentValue.membersCount || 0),
    0,
  );

export const getCheckedValue = ({ isSelected, checked }: EntityListItem) =>
  isSelected && isSelected === true ? !checked : checked;

export const getSelectedCount = (list: EntityListItem[]) =>
  list.reduce(
    (count, listItem) => (getCheckedValue(listItem) ? count + 1 : count),
    0,
  );

const getMembersList = (members: IntegrationDataMember[]): EntityListItem[] => {
  return members.map(
    ({ id: entityId, name, isSelected }: IntegrationDataMember) => {
      return { id: entityId, mainText: name, isSelected, checked: false };
    },
  );
};

const getEntitiesList = (
  entities: IntegrationDataEntity[],
  entityCountMap: EntityCountMapType,
): EntityListItem[] => {
  return entities.map(
    ({ id: entityId, displayName, isSelected, membersCount }) => {
      let sideText = '';
      let isLoading = false;
      if (entityCountMap[entityId]) {
        const { isLoading: isEntityLoading, count } = entityCountMap[entityId];
        sideText = getSideText(count);
        isLoading = isEntityLoading;
      }
      return {
        id: entityId,
        mainText: displayName,
        sideText,
        isSelected,
        membersCount,
        isLoading,
        checked: false,
      };
    },
  );
};

export const calculateInitialEntityList = (
  accountManagementType: string,
  integrationData: IdentityManagerIntegrationData,
  entityCountMap: EntityCountMapType,
) => {
  let list = null;
  if (accountManagementType === accountManagementTypes.SELECTED_USERS) {
    const { members, allDataItems } = integrationData;
    if (allDataItems) {
      list = getMembersList(allDataItems as IntegrationDataMember[]);
    } else {
      if (members) {
        list = getMembersList(members);
      }
    }
  } else if (accountManagementType === accountManagementTypes.DEPARTMENT) {
    const { departments } = integrationData;
    list = getEntitiesList(departments || [], entityCountMap);
  } else if (accountManagementType === accountManagementTypes.BUSINESS_UNIT) {
    const { businessUnits } = integrationData;
    list = getEntitiesList(businessUnits || [], entityCountMap);
  } else if (accountManagementType === accountManagementTypes.CHANNELS) {
    const { channels } = integrationData;
    list = getEntitiesList(channels || [], entityCountMap);
  } else if (accountManagementType === accountManagementTypes.GROUPS) {
    const { groups, allDataItems } = integrationData;
    if (allDataItems) {
      list = getEntitiesList(
        (allDataItems as IntegrationDataEntity[]) || [],
        entityCountMap,
      );
    } else {
      list = getEntitiesList(groups || [], entityCountMap);
    }
  } else if (accountManagementType === accountManagementTypes.COMPANIES) {
    const { companies, allDataItems } = integrationData;
    if (allDataItems) {
      list = getEntitiesList(
        (allDataItems as IntegrationDataEntity[]) || [],
        entityCountMap,
      );
    } else {
      list = getEntitiesList(companies || [], entityCountMap);
    }
  } else if (
    accountManagementType === accountManagementTypes.ORGANIZATIONAL_UNIT
  ) {
    const { orgUnits } = integrationData;
    list = getEntitiesList(orgUnits || [], entityCountMap);
  }
  return list;
};
