import React, { useCallback, useEffect } from 'react';
import useToggle from '../../../hooks/useToggle';
import useJobPollingForFlowDownload, {
  DownloadDataJobProps,
} from '../../../hooks/useJobPollingForDownload';
import { GENERATE_FLOW_DATA } from '../../../constants/endpoints';
import {
  dismissAllToasts,
  showErrorMessage,
  showInfoMessage,
  showSuccessMessage,
} from '../../../Utils/toast';
import {
  DATA_DOWNLOAD_ERROR,
  DATA_DOWNLOADED_SUCCESSFULLY,
  PREPARING_DATA_FOR_DOWNLOAD,
} from '../../../languages/en/flows';
import FlowsBuilderDataChangeConfirmationModal from '../../molecules/FlowsBuilderDataChangeConfirmationModal';
import useFlowBuilderEdit from '../../../controllers/flowsBuilder/FlowsBuilderController/useFlowBuilderEdit';

type DataChangeConfirmationProps = {
  flowId: string;
  showDataChangeConfirm: boolean;
  isFlowDataChangeConfirmed: boolean;
  setShowDataChangeConfirm: (value: boolean) => void;
  setIsFlowDataChangeConfirmed: (value: boolean) => void;
};

const DataChangeConfirmation = (props: DataChangeConfirmationProps) => {
  const {
    flowId,
    showDataChangeConfirm,
    setShowDataChangeConfirm,
    isFlowDataChangeConfirmed,
    setIsFlowDataChangeConfirmed,
  } = props;

  const { models, operations } = useToggle();
  const { toggleValue } = models;
  const { setToggleToFalse, setToggleToTrue } = operations;

  const { resetFlowDetails } = useFlowBuilderEdit();

  const { downloadFlowData } = useJobPollingForFlowDownload({
    preJobAPI: {
      apiName: GENERATE_FLOW_DATA,
      substitutions: {
        flowId,
      },
    },
    postJobAPI: {
      apiName: GENERATE_FLOW_DATA,
      substitutions: {
        flowId,
      },
    },
  });

  const handleOnDownloadClick = useCallback(async () => {
    showInfoMessage(PREPARING_DATA_FOR_DOWNLOAD);
    const getDownloadFlowDataURL: DownloadDataJobProps =
      await downloadFlowData();
    if (
      getDownloadFlowDataURL.data.url !== undefined &&
      getDownloadFlowDataURL.status === 'SUCCESS'
    ) {
      window.open(getDownloadFlowDataURL.data.url, '_self');
      dismissAllToasts();
      setTimeout(() => showSuccessMessage(DATA_DOWNLOADED_SUCCESSFULLY), 500);
    } else {
      showErrorMessage(DATA_DOWNLOAD_ERROR);
    }
  }, [downloadFlowData]);

  useEffect(() => {
    if (flowId && showDataChangeConfirm && !isFlowDataChangeConfirmed) {
      setToggleToTrue();
    }
  }, [
    flowId,
    isFlowDataChangeConfirmed,
    setToggleToTrue,
    showDataChangeConfirm,
  ]);

  return (
    <FlowsBuilderDataChangeConfirmationModal
      isOpen={toggleValue}
      onModalClose={() => {
        setToggleToFalse();
        resetFlowDetails();
      }}
      onAcceptClick={() => {
        setToggleToFalse();
        setIsFlowDataChangeConfirmed(true);
      }}
      onRevertChangesClick={() => {
        setToggleToFalse();
        resetFlowDetails();
        setShowDataChangeConfirm(false);
        setIsFlowDataChangeConfirmed(false);
      }}
      onDownloadClick={handleOnDownloadClick}
    />
  );
};

export default DataChangeConfirmation;
