import React, { useCallback, useEffect, useState } from 'react';

type Options = {
  deltaThreshold: number;
  enabled?: boolean;
  onScrollDown: () => void;
  onScrollUp: () => void;
  customCheck?: (event: WheelEvent) => boolean;
};

const isAtBottomOfElement = (element: HTMLElement) => {
  return element.offsetHeight + element.scrollTop === element.scrollHeight;
};

const useHardMouseWheel = (
  ref: React.MutableRefObject<HTMLElement | null>,
  {
    enabled = true,
    onScrollDown,
    onScrollUp,
    deltaThreshold,
    customCheck,
  }: Options,
) => {
  const [isListening, setIsListening] = useState(true);

  const stopListeningWithTimeout = (timeout = 750) => {
    setIsListening(false);
    setTimeout(() => setIsListening(true), timeout);
  };

  const handleWheelEvent = useCallback(
    (event: WheelEvent) => {
      const refElement = ref.current;
      if (!refElement || !isListening) return;

      if (customCheck && customCheck(event) === false) {
        return;
      }

      const exceedsDownThreshold = event.deltaY >= deltaThreshold;
      if (exceedsDownThreshold) {
        if (!isAtBottomOfElement(refElement)) {
          stopListeningWithTimeout();
        } else {
          stopListeningWithTimeout();
          onScrollDown();
        }
      }

      const exceedsUpThreshold = event.deltaY <= deltaThreshold * -1;
      if (exceedsUpThreshold) {
        const isAtTopOfElement = refElement.scrollTop === 0;
        if (!isAtTopOfElement) {
          stopListeningWithTimeout();
        } else {
          stopListeningWithTimeout();
          onScrollUp();
        }
      }
    },
    [customCheck, deltaThreshold, isListening, onScrollDown, onScrollUp, ref],
  );

  useEffect(() => {
    const refElement = ref.current;

    if (enabled) {
      refElement?.addEventListener('wheel', handleWheelEvent, {
        passive: true,
      });
    }

    return () => refElement?.removeEventListener('wheel', handleWheelEvent);
  }, [enabled, handleWheelEvent, ref]);
};

export default useHardMouseWheel;
