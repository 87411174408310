import { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import useTriggerFlowOption from '../../../hooks/useFlowFeedOptions/useTriggerFlowOption';
import useEndOccurrenceOption from '../../../hooks/useFlowFeedOptions/useEndOccurrenceOption';
import {
  useFetchFlowDetailsQuery,
  useVerifyFlowAuthorization,
} from '../../../queries/Flows/Feed';
import { allFlowFeedHeaderOptions } from './data';
import useOptions from '../../../hooks/useFlowFeedOptions/useOptions';
import { useFlowViewersSearch } from '../../../hooks/useMembersSearch';
import useArchiveFlowOption from '../../../hooks/useFlowFeedOptions/useArchiveFlowOption';
import { FeedPostUserProps } from '../../../interfaces/Feed';
import useRemindParticipantsOption from '../../../hooks/useFlowFeedOptions/useRemindParticipantsOption';
import useLayoutStore from '../../../stores/layoutStore';

import { FEED_ANALYTICS_EVENTS } from '../../../Utils/analytics/constants';
import { canShowParticipationList } from '../../../Utils/flows';
import { LayoutStoreState } from '../../../interfaces/Layout';
import { trackFeedEvent as trackFeedEventMetric } from '../../../Utils/analytics';
import { FeedEventNameType } from '../../../Utils/analytics/interfaces';
import { getFlowPerms } from '../../../Utils/flows/feeds';
import useFlowBuilderStore from '../../../stores/flowBuilderStore';
import { setShareSheetModalOpenSelector } from '../../../stores/flowBuilderStore/selectors';
import { MemberState } from '../../../interfaces/user';

const layoutSelector = (state: LayoutStoreState) =>
  state.setMemberAndToggleRightAsideOpen;

const useFlowFeedHeaderControllerLogic = () => {
  const { flowId } = useParams<{ flowId: string }>();

  const {
    isLoading: isFlowDetailsLoading,
    isError,
    data,
  } = useFetchFlowDetailsQuery(flowId);
  const { isLoading: isFlowAuthorizationLoading } = useVerifyFlowAuthorization(
    flowId,
    isError,
  );

  const flowDetailsData = data?.data;

  const flowVisibilityCriteria = flowDetailsData?.visibility.criteria;

  const setShareSheetModalOpen = useFlowBuilderStore(
    setShareSheetModalOpenSelector,
  );

  const {
    models: {
      searchedMembers,
      isError: isUsersError,
      value: memberSearchValue,
      isLoading: isUsersLoading,
      isFetching: isUsersFetching,
      hasMoreMembers: hasNextPage,
    },
    operations: {
      fetchMoreMembers: fetchMoreUsers,
      onChange: onMemberSearchChange,
      onClearValue: clearMemberSearchValue,
    },
  } = useFlowViewersSearch(flowId);

  const trackFeedEvent = useCallback(
    (action: FeedEventNameType) => {
      trackFeedEventMetric(action, {
        flowPerms: getFlowPerms(flowDetailsData),
        flowName: flowDetailsData?.name,
      });
    },
    [flowDetailsData],
  );

  useEffect(() => {
    clearMemberSearchValue();
  }, [flowId, clearMemberSearchValue]);

  const users = searchedMembers?.map((member) => {
    return {
      ...member,
      image: member.image,
    } as FeedPostUserProps;
  });

  const handleMemberSearchChange = useCallback(
    (value) => {
      onMemberSearchChange(value);
      trackFeedEvent(FEED_ANALYTICS_EVENTS.VIEWERS_SEARCHED);
    },
    [onMemberSearchChange, trackFeedEvent],
  );

  const shouldFetchParticipantList = canShowParticipationList(flowDetailsData);

  const {
    models: {
      isTriggerFlowModalOpen,
      dueDateValue,
      occurrenceValue,
      participants,
      isTriggerFlowLoading,
      hasMoreMembers,
    },
    operations: {
      setTriggerFlowModalClose,
      handleDueDateValueChange,
      setOccurrenceValue,
      setIsTriggerFlowModalOpen,
      fetchMoreMembers,
      handleTriggerFlowButtonClick,
    },
  } = useTriggerFlowOption(flowId, shouldFetchParticipantList);
  const pendingMembersParticipantCount = useMemo(() => {
    const pendingMembers = participants.filter(
      (member) => member.memberState === MemberState.PENDING,
    );
    return pendingMembers.length;
  }, [participants]);

  const openRunNowModal = () => {
    setIsTriggerFlowModalOpen();
  };

  const {
    operations: {
      handleCopyFlowFeedLink,
      handleEditNotificationSettings,
      handleShowActivityClick,
      handleHideActivityClick,
    },
  } = useOptions();

  const {
    models: { isEndOccurrenceModalOpen, isEndOccurrenceModalLoading },
    operations: {
      setEndOccurrenceModalClose,
      setIsEndOccurrenceModalOpen,
      handleEndOccurrenceButtonClick,
    },
  } = useEndOccurrenceOption();

  const {
    models: { isArchiveModalOpen, isArchiveFlowLoading },
    operations: {
      setArchiveModalOpen,
      setArchiveModalClose,
      handleOnArchiveClick,
    },
  } = useArchiveFlowOption(flowId);

  const {
    models: { isRemindParticipantsModalOpen, isRemindParticipantsLoading },
    operations: {
      setRemindParticipantsModalClose,
      setRemindParticipantsModalOpen,
      handleRemindParticipantsButtonClick,
    },
  } = useRemindParticipantsOption();

  const setMemberAndToggleRightAsideOpen = useLayoutStore(layoutSelector);

  // Options
  const handleMenuOptionOnClick = (value: string | number) => {
    if (value === allFlowFeedHeaderOptions.endOccurrence.id) {
      setIsEndOccurrenceModalOpen();
      trackFeedEvent(FEED_ANALYTICS_EVENTS.OCCURRENCE_ENDED);
    }

    if (value === allFlowFeedHeaderOptions.editNotificationSettings.id) {
      handleEditNotificationSettings();
      trackFeedEvent(FEED_ANALYTICS_EVENTS.EDIT_NOTIFICATIONS_CLICKED);
    }

    if (value === allFlowFeedHeaderOptions.archiveFlow.id) {
      setArchiveModalOpen();
      trackFeedEvent(FEED_ANALYTICS_EVENTS.FLOW_ARCHIVED);
    }

    if (value === allFlowFeedHeaderOptions.hideActivity.id) {
      handleHideActivityClick(flowDetailsData?.flowId);
    }

    if (value === allFlowFeedHeaderOptions.showActivity.id) {
      handleShowActivityClick(flowDetailsData?.flowId);
    }
  };

  const onRunNowButtonClick = () => {
    handleTriggerFlowButtonClick(flowId);
    trackFeedEvent(FEED_ANALYTICS_EVENTS.FLOW_RAN);
  };

  const hasActiveOccurrence = Boolean(
    flowDetailsData?.occurrence.activeOccurrence?.occurrenceId,
  );

  const openRemindParticipantsModal = () => {
    setRemindParticipantsModalOpen();
  };

  const onEndOccurrenceClick = () => {
    handleEndOccurrenceButtonClick(flowId);
  };

  const onRemindParticipantsClick = () => {
    const occurrenceId =
      flowDetailsData?.occurrence.activeOccurrence?.occurrenceId;
    if (occurrenceId) {
      handleRemindParticipantsButtonClick(flowId, occurrenceId);
    }
    trackFeedEvent(FEED_ANALYTICS_EVENTS.PARTICIPANTS_REMINDED);
  };

  const onMemberClick = useCallback(
    ({ memberID, name }) => {
      if (memberID && name) {
        setMemberAndToggleRightAsideOpen({
          memberId: memberID,
          firstName: name,
        });
      }
      trackFeedEvent(FEED_ANALYTICS_EVENTS.VIEWER_PROFILE_CLICKED);
    },
    [setMemberAndToggleRightAsideOpen, trackFeedEvent],
  );

  const trackFlowFeedMenuOpened = useCallback(() => {
    trackFeedEvent(FEED_ANALYTICS_EVENTS.FLOW_FEED_MENU_OPENED);
  }, [trackFeedEvent]);

  const trackViewersHovered = useCallback(() => {
    trackFeedEvent(FEED_ANALYTICS_EVENTS.VIEWERS_HOVERED);
  }, [trackFeedEvent]);

  return {
    models: {
      isLoading: isFlowDetailsLoading || isFlowAuthorizationLoading,
      isError,
      data: flowDetailsData,
      isTriggerFlowModalOpen,
      dueDateValue,
      occurrenceValue,
      participants,
      flowVisibilityCriteria,
      hasMoreMembers,
      isTriggerFlowLoading,
      isEndOccurrenceModalOpen,
      isEndOccurrenceModalLoading,
      isArchiveModalOpen,
      isArchiveFlowLoading,
      isUsersError,
      isUsersLoading: isUsersLoading || isUsersFetching,
      hasNextPage,
      memberSearchValue,
      users,
      isRemindParticipantsModalOpen,
      isRemindParticipantsLoading,
      hasActiveOccurrence,
      pendingMembersParticipantCount,
    },
    operations: {
      handleMenuOptionOnClick,
      setTriggerFlowModalClose,
      handleDueDateValueChange,
      setOccurrenceValue,
      handleTriggerFlowButtonClick,
      setEndOccurrenceModalClose,
      setIsEndOccurrenceModalOpen,
      fetchMoreMembers,
      onRunNowButtonClick,
      onEndOccurrenceClick,
      handleOnArchiveClick,
      setArchiveModalClose,
      fetchMoreUsers,
      handleMemberSearchChange,
      setRemindParticipantsModalClose,
      onRemindParticipantsClick,
      onMemberClick,
      openRunNowModal,
      openRemindParticipantsModal,
      trackFlowFeedMenuOpened,
      trackViewersHovered,
      setShareSheetModalOpen,
      handleCopyFlowFeedLink,
    },
  };
};

export default useFlowFeedHeaderControllerLogic;
