import styled from 'styled-components';
import ClickableSelectionItem from '../../../molecules/QuickSetup/ClickableSelectionItem';

export const StyledClickableSelectionItem = styled(ClickableSelectionItem)`
  margin-bottom: 16px;
`;

export const FooterPlaceholder = styled.div`
  height: 64px;
`;
