import { useProfileInfoFetchQuery } from '../../queries/Profile';
import { isUserAdmin, isUserManager } from '../../Utils/user';
import { useMemo } from 'react';
import { AutocompleteDropdownItem } from '../../atomic/organism/Autocomplete/interfaces';

const useGetUserAddEveryoneToFlowPermission = () => {
  const { data } = useProfileInfoFetchQuery();
  const permission =
    data?.assembly.flow?.participationCriteriaAccessibility.everyone;
  const isAdmin = Boolean(data?.member && isUserAdmin(data.member));
  const isManager = Boolean(data?.member && isUserManager(data.member));

  const canCreateFlows = useMemo(() => {
    const flowsPermissionSelectOptions: AutocompleteDropdownItem<string>[] = [
      {
        title: 'Entire organization',
        id: 'EVERYONE',
      },
      {
        title: 'Managers',
        id: 'MANAGER',
      },
      {
        title: 'Workspace Owners & Admins only',
        id: 'ADMINS_AND_OWNER',
      },
    ];

    if (isAdmin) {
      return true;
    }

    if (permission?.includes(flowsPermissionSelectOptions[0].id)) {
      return true;
    }

    if (permission?.includes(flowsPermissionSelectOptions[1].id)) {
      return isManager;
    }

    return false;
  }, [permission, isAdmin, isManager]);

  return {
    canUserSelectEveryOne: canCreateFlows,
  };
};

export default useGetUserAddEveryoneToFlowPermission;
