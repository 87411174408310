import { useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { GET_FLOWS_ACTIVITY } from '../../constants/endpoints';
import {
  FlowsUpdatesResponse,
  GetFlowsActivityUpdatesResponse,
} from '../../queries/Flows/interfaces';
import { useProfileInfoFetchQuery } from '../../queries/Profile';
import { pusher } from '../../pusher/pusher-base';
import { Channel } from 'pusher-js';

type WebsocketPayload = {
  flowId: string;
  fromMemberId?: string;
  unreadMentionsCount?: number;
  hasUnreadPost?: boolean;
};

let channelMember: Channel;

const useFlowActivityUpdatesSocket = () => {
  const { data } = useProfileInfoFetchQuery();
  const queryClient = useQueryClient();
  const location = useLocation();

  const updateActivityCache = useCallback(
    (eventName: string, functionPayload?: WebsocketPayload) => {
      const activityUpdatesCache: GetFlowsActivityUpdatesResponse | undefined =
        queryClient.getQueryData(GET_FLOWS_ACTIVITY);

      if (functionPayload?.flowId && activityUpdatesCache) {
        const cacheData = [
          ...activityUpdatesCache.data,
        ] as FlowsUpdatesResponse[];

        const currentActivityUpdateItemIndex = cacheData.findIndex(
          (item) => item.flowId === functionPayload.flowId,
        );

        // Update cached item
        if (currentActivityUpdateItemIndex > -1) {
          const currentItem = cacheData[currentActivityUpdateItemIndex];
          cacheData[currentActivityUpdateItemIndex] = {
            ...currentItem,
            hasUnreadPost:
              eventName === 'FLOW_ACTIVITY'
                ? functionPayload.hasUnreadPost
                : true,
            unreadMentionsCount:
              eventName === 'FLOW_ACTIVITY' ||
              eventName === 'NEW_FLOW_RESPONSE_MENTION'
                ? functionPayload.unreadMentionsCount
                : currentItem.unreadMentionsCount,
          } as FlowsUpdatesResponse;

          queryClient.setQueryData(GET_FLOWS_ACTIVITY, {
            data: cacheData,
          });
        } else {
          // Push new item
          const newItem = {
            flowId: functionPayload.flowId,
            unreadMentionsCount: functionPayload.unreadMentionsCount || 0,
            hasUnreadPost:
              eventName === 'FLOW_ACTIVITY'
                ? functionPayload.hasUnreadPost
                : true,
          } as FlowsUpdatesResponse;

          cacheData.push(newItem);
          queryClient.setQueryData(GET_FLOWS_ACTIVITY, {
            data: cacheData,
          });
        }
      }
    },
    [queryClient],
  );

  useEffect(() => {
    if (data) {
      const { memberId } = data.member;
      channelMember = pusher.subscribe(`private-member-${memberId}`);
      const channelMemberEventHandler = (
        eventName: string,
        socketPayload: WebsocketPayload,
      ) => {
        switch (eventName) {
          case 'NEW_FLOW_RESPONSE':
          case 'NEW_FLOW_RESPONSE_MENTION':
          case 'FLOW_ACTIVITY': {
            const { pathname } = location;
            if (!pathname.includes(socketPayload.flowId)) {
              updateActivityCache(eventName, socketPayload);
            }
            break;
          }
          default:
            break;
        }
      };

      channelMember.bind_global(channelMemberEventHandler);
    }
  }, [data, location, queryClient, updateActivityCache]);
};

export default useFlowActivityUpdatesSocket;
