import { SelectedMember } from '../../../../interfaces/Layout';
import { PersonTypeFromAPI } from '../../../../interfaces/notebook';

type HistoryItemContentType = 'PERSON' | 'PREVIEW' | 'CONTENT';
export type HistoryItemContent = {
  type: HistoryItemContentType;
  value: string;
  onClick?: () => void;
};

export enum TaskHistoryActivityTypes {
  TITLE = 'TITLE',
  READ = 'READ',
  DUE_DATE = 'DUE_DATE',
  ASSIGNMENT = 'ASSIGNMENT',
  DESCRIPTION = 'DESCRIPTION',
  CREATE = 'CREATE',
  STATE = 'STATE',
}

/* type TaskHistoryDifferenceObject = {
  type: string;
  value: string;
}; */

export type TaskHistoryActivity = {
  activityId: string;
  actedBy: PersonTypeFromAPI;
  activity: TaskHistoryActivityTypes;
  content: {
    before: any;
    after: any;
  };
  createdAt: string;
};

export type TaskHistoryProps = {
  taskHistory: TaskHistoryActivity[];
  handleUserClick: (member: SelectedMember) => void;
  isLoading: boolean;
};
