import React, { useState, useEffect } from 'react';

import Body from '../../atoms/Body';
import SVGIcon from '../../atoms/SVGIcon';
import { formatFileSize, truncate } from './utils';
import ProgressBar from '../../atoms/ProgressBar';
import ThemeV2 from '../../../componentsV2/theme';
import { StyledButton } from '../../../Utils/styles/button';
import { ButtonSize } from '../../atoms/IconButton_V2/interface';

import {
  File,
  LegacyFileType,
  FileCardProps,
  FileUploadStatus,
  FileCardWrapperProps,
} from './types';

import {
  StyledImage,
  StyledFileCard,
  StyledFileCardMain,
  StyledTextWithIcon,
  StyledBodyFileName,
  StyledFileCardHeader,
  StyledImageContainer,
  StyledFileCardFooter,
  StyledLoaderContainer,
  StyledFileCardFileSize,
  StyledImageDownloadIcon,
  StyledFileCardCloseIcon,
  StyledFileCardIconContainer,
  StyledClickableImageCloseIcon,
  StyledCard,
  CardTop,
  StyledSVGIcon,
  StyledDropdownIconButton,
  NewStyledImage,
  CardBottom,
  NewStyledBody,
  NameWrapper,
  StyledIconButton,
} from './styles';
import {
  SplitNames,
  TreatmentTypes,
} from '../../../hooks/useSplitSdkConfig/constants';
import { useFeatureSplit } from '../../../hooks/useFeatureSplit';
import { flowItemsWithoutDeletePost } from '../KnowledgeCenterCard/data';
import copy from 'copy-to-clipboard';
import { V3_POST_PAGE } from '../../../constants/routes';
import { DropdownPlacement } from '../Dropdown_V2/interfaces';
import { getPrettierFileSize } from '../../../Utils/formatting';

const FileCardWrapper = (props: FileCardWrapperProps) => {
  const {
    children,
    onMouseEnter,
    onMouseLeave,
    onClick,
    isParticipation,
    isKnowledgeCenterEnabled,
  } = props;
  return (
    <StyledFileCard
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      isParticipation={isParticipation}
      data-testid="file-card-wrapper"
      isKnowledgeCenterEnabled={isKnowledgeCenterEnabled}
    >
      <StyledFileCardMain>{children}</StyledFileCardMain>
    </StyledFileCard>
  );
};

const closeButtonProps = {
  icon: 'close-rounded',
  size: ButtonSize.Small,
  iconColor: ThemeV2.palette.gray8,
  dataTestId: 'close-button',
};

const FileCard = (props: FileCardProps) => {
  const {
    file,
    flowId,
    responseId,
    status,
    className,
    errorMessage,
    onClickClose,
    onClickRetry,
    onClickDownload,
    onClickPreview,
    loadingProgress = 0,
    isParticipation = true,
  } = props;

  const [cardColor, setCardColor] = useState('');
  const [cardIcon, setCardIcon] = useState<string>();
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    switch (file?.name?.split('.')[1]) {
      case 'doc':
      case 'docx':
        setCardColor(ThemeV2.palette.blue7);
        setCardIcon('docFile');
        break;
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'svg':
      case 'bmp':
      case 'gif':
        setCardColor(ThemeV2.palette.blue8);
        setCardIcon('imageFile');
        break;
      case 'ppt':
      case 'pptx':
      case 'pps':
      case 'ppsx':
        setCardColor(ThemeV2.palette.volcano6);
        setCardIcon('presentationFile');
        break;
      case 'pdf':
        setCardColor(ThemeV2.palette.red6);
        setCardIcon('pdfFile');
        break;
      case 'xls':
      case 'xlsx':
      case 'csv':
        setCardColor(ThemeV2.palette.green7);
        setCardIcon('csvFile');
        break;
      case 'mp3':
      case 'wav':
      case 'm4a':
      case 'ogg':
        setCardColor(ThemeV2.palette.orange6);
        setCardIcon('audioFile');
        break;
      case 'mp4':
      case 'avi':
      case 'mov':
      case 'mpg':
      case 'ogv':
      case '3gp':
      case '3d2':
        setCardColor(ThemeV2.palette.red7);
        setCardIcon('videoFile');
        break;
      case 'zip':
        setCardColor(ThemeV2.palette.gray8);
        setCardIcon('zipFile');
        break;
      default:
        setCardColor(ThemeV2.palette.gray6);
        setCardIcon('genericFile');
        break;
    }
  }, []);

  const { treatment: knowledgeCenter } = useFeatureSplit(
    SplitNames.KNOWLEDGE_CENTER,
  );

  const feedCardWidth = '132px';
  const flowCardWidth = '176px';

  const isKnowledgeCenterEnabled = knowledgeCenter === TreatmentTypes.ON;

  const openPreviewModal = (fileToPreview: File) => {
    if (onClickPreview && fileToPreview) {
      onClickPreview(fileToPreview);
    }
  };

  const onOptionsSelect = (value: string | number) => {
    if (value === 'copy-link') {
      copy(
        `${V3_POST_PAGE}`.replace(
          ':postType',
          `flows?flowId=${flowId}&responseId=${responseId}`,
        ),
      );
    }
    if (value === 'download-file' && onClickDownload) {
      if (file) {
        onClickDownload(file);
      }
    }
    if (value === 'delete') {
      console.log('Not implemented on the BE yet.');
    }
  };

  switch (status) {
    case FileUploadStatus.Loading:
      if (file && onClickClose) {
        return (
          <FileCardWrapper
            isParticipation={isParticipation}
            isKnowledgeCenterEnabled={isKnowledgeCenterEnabled}
          >
            <StyledFileCardHeader>
              <StyledFileCardCloseIcon
                onClick={() => onClickClose(file.name)}
                {...closeButtonProps}
              />
            </StyledFileCardHeader>
            <StyledLoaderContainer>
              <ProgressBar
                height="8px"
                color="flowProgress"
                value={loadingProgress}
              />
            </StyledLoaderContainer>
          </FileCardWrapper>
        );
      }
      return null;

    case FileUploadStatus.Success:
      if (file?.type === LegacyFileType.Image) {
        if (isKnowledgeCenterEnabled) {
          return (
            <StyledCard
              isParticipation={isParticipation}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              onClick={() => {
                if (!isParticipation) {
                  openPreviewModal(file);
                }
              }}
              cardWidth={isParticipation ? flowCardWidth : feedCardWidth}
            >
              <CardTop backgroundColor={cardColor}>
                {cardIcon && <StyledSVGIcon icon={cardIcon} size="32px" />}
                {isHovered && !isParticipation && (
                  <StyledDropdownIconButton
                    color="tertiary"
                    iconColor={ThemeV2.palette.gray8}
                    menuItems={flowItemsWithoutDeletePost}
                    icon={'more'}
                    title={'file card options'}
                    onItemClick={onOptionsSelect}
                    size={ButtonSize.Normal}
                    stopEventPropagation={true}
                    dropdownPlacement={DropdownPlacement.Bottom}
                  />
                )}
                {isHovered && isParticipation && (
                  <StyledIconButton
                    color="tertiary"
                    iconColor={ThemeV2.palette.gray8}
                    icon={'close'}
                    title={'file card close'}
                    onClick={() => onClickClose && onClickClose(file)}
                    size={ButtonSize.Normal}
                  />
                )}
                {cardIcon === 'imageFile' && (
                  <NewStyledImage
                    alt={file.name}
                    src={file.thumbnails ? file.thumbnails['360'] : file.url}
                  />
                )}
              </CardTop>
              <CardBottom>
                {isParticipation ? (
                  <>
                    <NameWrapper>
                      <NewStyledBody
                        isParticipation={isParticipation}
                        variant="body2Medium"
                      >
                        {file.name.split('.')[0]}
                      </NewStyledBody>
                      <Body variant="body2Medium">
                        .{file.name.split('.')[1]}
                      </Body>
                    </NameWrapper>
                    <NewStyledBody
                      isParticipation={false}
                      color={ThemeV2.palette.gray8}
                      variant="body3"
                    >
                      {getPrettierFileSize(file.size)}
                    </NewStyledBody>
                  </>
                ) : (
                  <NewStyledBody
                    isParticipation={isParticipation}
                    variant="body2Medium"
                  >
                    {file.name}
                  </NewStyledBody>
                )}
              </CardBottom>
            </StyledCard>
          );
        }
        return (
          <StyledImageContainer
            className={className}
            onClick={() => openPreviewModal(file)}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            isParticipation={isParticipation}
            data-testid="file-card-wrapper"
          >
            {file.thumbnails ? (
              <StyledImage alt={file.name} src={file.thumbnails['360']} />
            ) : (
              <StyledImage alt={file.name} src={file.url} />
            )}
            {isParticipation && onClickClose && (
              <StyledClickableImageCloseIcon
                onClick={() => onClickClose(file.name)}
              >
                <SVGIcon icon="close-circle-outlined" size="24px" />
              </StyledClickableImageCloseIcon>
            )}
            {!isParticipation && onClickDownload && isHovered && (
              <StyledImageDownloadIcon
                icon="download"
                size={ButtonSize.Small}
                iconColor={ThemeV2.palette.gray8}
                dataTestId="download-button"
                onClick={(event) => {
                  event.stopPropagation();
                  onClickDownload(file);
                }}
              />
            )}
          </StyledImageContainer>
        );
      }

      if (file?.type === LegacyFileType.File) {
        const maxFileNameLen = 20;
        if (isKnowledgeCenterEnabled) {
          return (
            <StyledCard
              isParticipation={isParticipation}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              onClick={() => {
                if (!isParticipation) {
                  openPreviewModal(file);
                }
              }}
              cardWidth={isParticipation ? flowCardWidth : feedCardWidth}
            >
              <CardTop backgroundColor={cardColor}>
                {cardIcon && <StyledSVGIcon icon={cardIcon} size="32px" />}
                {isHovered && !isParticipation && (
                  <StyledDropdownIconButton
                    color="tertiary"
                    iconColor={ThemeV2.palette.gray8}
                    menuItems={flowItemsWithoutDeletePost}
                    icon={'more'}
                    onItemClick={onOptionsSelect}
                    size={ButtonSize.Normal}
                    stopEventPropagation={true}
                    dropdownPlacement={DropdownPlacement.Bottom}
                  />
                )}
                {isHovered && isParticipation && (
                  <StyledIconButton
                    color="tertiary"
                    iconColor={ThemeV2.palette.gray8}
                    icon={'close'}
                    onClick={() => onClickClose && onClickClose(file)}
                    size={ButtonSize.Normal}
                  />
                )}
                {cardIcon === 'imageFile' && (
                  <NewStyledImage
                    alt={file.name}
                    src={file.thumbnails ? file.thumbnails['360'] : file.url}
                  />
                )}
              </CardTop>
              <CardBottom>
                {isParticipation ? (
                  <>
                    <NameWrapper>
                      <NewStyledBody
                        isParticipation={isParticipation}
                        variant="body2Medium"
                      >
                        {file.name.split('.')[0]}
                      </NewStyledBody>
                      <Body variant="body2Medium">
                        .{file.name.split('.')[1]}
                      </Body>
                    </NameWrapper>
                    <NewStyledBody
                      isParticipation={false}
                      color={ThemeV2.palette.gray8}
                      variant="body3"
                    >
                      {getPrettierFileSize(file.size)}
                    </NewStyledBody>
                  </>
                ) : (
                  <NewStyledBody
                    isParticipation={isParticipation}
                    variant="body2Medium"
                  >
                    {file.name}
                  </NewStyledBody>
                )}
              </CardBottom>
            </StyledCard>
          );
        }
        return (
          <FileCardWrapper
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={() => openPreviewModal(file)}
            isParticipation={isParticipation}
          >
            <StyledFileCardHeader>
              {isParticipation && (
                <>
                  <StyledFileCardFileSize variant="body3" color="gray8">
                    {file?.size ? formatFileSize(file?.size) : null}
                  </StyledFileCardFileSize>
                  <StyledFileCardCloseIcon
                    onClick={() => onClickClose && onClickClose(file)}
                    {...closeButtonProps}
                  />
                </>
              )}
              <div style={{ minHeight: '28px', width: '100%' }}>
                {!isParticipation && isHovered && (
                  <StyledFileCardCloseIcon
                    icon="download"
                    size={ButtonSize.Small}
                    iconColor={ThemeV2.palette.gray8}
                    dataTestId="download-button"
                    onClick={(event) => {
                      event.stopPropagation();
                      if (onClickDownload) {
                        onClickDownload(file);
                      }
                    }}
                  />
                )}
              </div>
            </StyledFileCardHeader>
            <StyledFileCardIconContainer>
              <SVGIcon size="48px" icon="generic-file" />
            </StyledFileCardIconContainer>
            <StyledFileCardFooter>
              <StyledBodyFileName variant="body3" color="geekBlue9">
                {truncate(file?.name, maxFileNameLen)}
              </StyledBodyFileName>
            </StyledFileCardFooter>
          </FileCardWrapper>
        );
      }
      return null;

    case FileUploadStatus.Error:
      if (file) {
        return (
          <FileCardWrapper isParticipation={isParticipation}>
            <StyledFileCardHeader>
              {onClickClose && (
                <StyledFileCardCloseIcon
                  onClick={() => onClickClose(file.name)}
                  {...closeButtonProps}
                />
              )}
            </StyledFileCardHeader>
            <StyledFileCardIconContainer>
              <SVGIcon
                size="48px"
                icon="warning-hollow"
                color={ThemeV2.palette.dustRed6}
              />
            </StyledFileCardIconContainer>
            <StyledFileCardFooter>
              <Body variant="body3Medium" color="gray9">
                {errorMessage}
              </Body>
              {onClickRetry && (
                <StyledButton onClick={() => onClickRetry(file.name)}>
                  <StyledTextWithIcon
                    color="dustRed6"
                    icon="retry"
                    iconSize="12px"
                    iconColor={ThemeV2.palette.dustRed6}
                  >
                    Try again
                  </StyledTextWithIcon>
                </StyledButton>
              )}
            </StyledFileCardFooter>
          </FileCardWrapper>
        );
      }
      return null;

    default:
      return null;
  }
};

export default FileCard;
