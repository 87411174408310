import styled from 'styled-components';

import Heading from '../../atoms/Heading';
import SVGIcon from '../../atoms/SVGIcon';

import ThemeV2 from '../../../componentsV2/theme';

export const Wrapper = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 24px;
`;

export const RowWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 8px;
`;

export const StyledHeading = styled(Heading)`
  margin-top: 0px;
  margin-bottom: 0px;
`;

export const StyledSVGIcon = styled(SVGIcon)`
  margin-right: 6px;
`;

export const StyledDivider = styled.div`
  border-bottom: 1px solid ${ThemeV2.palette.gray4};
  margin: 8px 0;
`;
