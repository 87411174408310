import React, { useMemo } from 'react';
import {
  PersonSelectorBlockBuilderSettingState,
  SelectableParticipantSelection,
} from '../../../interfaces/Flow/Builder';
import { SelectablePeopleTypes } from '../../../interfaces/Flow';
import {
  SAME_AS_PARTICIPANTS,
  SAME_AS_VIEWERS,
  ONLY_ONE_PERSON,
  UNLIMITED_PEOPLE,
  PARTICIPANTS_CAN_SELECT,
  CHOOSE_WHO_CAN_BE_SELECTED,
} from '../../../languages/en/flows/builder';
import { CUSTOM } from '../../../languages/en/singleWords';
import { ENTIRE_ORGANIZATION } from '../../../languages/en';
import { capitalizeFirstLetter } from '../../../Utils/text';
import { RadioOptionProps } from '../../molecules/RadioGroup';
import { StyledGroupHeader, LeftPaddedRadioGroup, StyledLink } from './styles';

const selectablePersonRadioOptions: RadioOptionProps[] = [
  {
    label: ENTIRE_ORGANIZATION,
    value: 'EVERYONE',
  },
  {
    label: SAME_AS_PARTICIPANTS,
    value: 'PARTICIPANTS',
  },
  {
    label: SAME_AS_VIEWERS,
    value: 'VIEWERS',
  },
];

const participantSelectionOptions: RadioOptionProps[] = [
  {
    label: ONLY_ONE_PERSON,
    value: 'ONE_PERSON',
  },
  {
    label: UNLIMITED_PEOPLE,
    value: 'UNLIMITED_PEOPLE',
  },
];

const PersonSelectorSettings = ({
  blockSettingState,
  openModal,
}: {
  blockSettingState: PersonSelectorBlockBuilderSettingState;
  openModal: () => void;
}) => {
  const handleParticipantsSelection = (e: { target: { value: string } }) => {
    const value = e.target.value as SelectablePeopleTypes;
    if (value === 'CUSTOM' && openModal) {
      openModal();
    }
    blockSettingState.changeBlockParticipants(value);
  };

  const getSelectablePersonRadioOptions = useMemo(() => {
    const { customPersonSelectorCount = 0, selectedBlockParticipants } =
      blockSettingState;

    const custom: RadioOptionProps = {
      label: capitalizeFirstLetter(CUSTOM),
      value: 'CUSTOM',
      ...(customPersonSelectorCount > 0 &&
        selectedBlockParticipants === CUSTOM.toUpperCase() && {
          inlineChildren: (
            <StyledLink
              onClick={openModal}
            >{`${customPersonSelectorCount} members selected`}</StyledLink>
          ),
        }),
    };
    return [...selectablePersonRadioOptions, custom];
  }, [blockSettingState, openModal]);

  return (
    <>
      <div>
        <StyledGroupHeader variant="body2">
          {`${PARTICIPANTS_CAN_SELECT}:`}
        </StyledGroupHeader>
        <LeftPaddedRadioGroup
          radioOptions={participantSelectionOptions}
          value={blockSettingState.chosenParticipantSelection}
          name="participant-select"
          onChange={(e) => {
            const value = e.target.value as SelectableParticipantSelection;
            blockSettingState.changeChosenParticipantSelection(value);
          }}
        />
      </div>
      <div>
        <StyledGroupHeader variant="body2">
          {`${CHOOSE_WHO_CAN_BE_SELECTED}:`}
        </StyledGroupHeader>
        <LeftPaddedRadioGroup
          radioOptions={getSelectablePersonRadioOptions}
          value={blockSettingState.selectedBlockParticipants}
          name="participant-select"
          onChange={(e) => handleParticipantsSelection(e)}
        />
      </div>
    </>
  );
};

export default PersonSelectorSettings;
