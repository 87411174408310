import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Collapse } from '@material-ui/core';
import Body from '../../atoms/Body';
import IconButton from '../../atoms/IconButton';
import SVGIcon from '../../atoms/SVGIcon';
import ThemeV2 from '../../../componentsV2/theme';
import TextureBG from '../../../img/texture-bg.svg';
import {
  recognitionTipsHeaderText,
  recognitionTips,
} from '../../../Utils/data/commons';

const useStyles = makeStyles({
  root: {
    background: ThemeV2.palette.gray2,
    borderRadius: '4px',
  },
  collapsingHeader: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '8px 12px',
    cursor: 'pointer',
    outline: 0,
    border: 'none',
    '&:focus': {
      background: ThemeV2.palette.gray3,
    },
  },
  toggleButton: {
    position: 'absolute',
    top: '50%',
    right: '4px',
    transform: 'translateY(-50%)',
  },
  tip: {
    display: 'grid',
    gridTemplateColumns: '22px auto',
    marginBottom: '12px',
    '&:last-child': {
      marginTop: '24px',
    },
  },
  tipsWrapper: {
    padding: '12px',
  },
  indexHolder: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '24px',
    width: '22px',
  },
  tipIndex: {
    fontFamily: ThemeV2.typography.adminFontFamily,
    fontWeight: ThemeV2.typography.fontWeightMedium,
    fontSize: '12px',
    color: ThemeV2.palette.gray1,
    background: `url(${TextureBG}) no-repeat center`,
    backgroundSize: 'cover',
    zIndex: 0,
    '&:after': {
      content: "''",
      height: '18px',
      width: '18px',
      position: 'absolute',
      top: '50%',
      right: 'auto',
      left: 'auto',
      margin: 'auto',
      transform: 'translateY(-50%)',
      background: ThemeV2.palette.geekBlue6,
      borderRadius: '100%',
      zIndex: -2,
    },
  },
  contentWrapper: {
    paddingLeft: '8px',
  },
});

export interface GiveRecognitionTipsProps {
  isOpen: boolean;
  onToggleClick: () => void;
  onToggleKeyPress: (event: { keyCode: number }) => void;
  className?: string;
}

const GiveRecognitionTips = (props: GiveRecognitionTipsProps) => {
  const {
    isOpen,
    onToggleClick,
    onToggleKeyPress,
    className: parentClassName,
  } = props;
  const classes = useStyles(props);
  const styles = `${classes.root} ${parentClassName}`;

  return (
    <div className={styles}>
      <div
        className={classes.collapsingHeader}
        role="button"
        tabIndex={0}
        onKeyPress={onToggleKeyPress}
        onClick={onToggleClick}
      >
        <Body variant="body2Bold">{recognitionTipsHeaderText}</Body>
        <IconButton onClick={onToggleClick} className={classes.toggleButton}>
          <SVGIcon icon="expand-more" size="8px" rotate={isOpen ? -90 : 90} />
        </IconButton>
      </div>
      <Collapse in={isOpen}>
        <div className={classes.tipsWrapper}>
          {recognitionTips.map((tip, index) => (
            <div className={classes.tip} key={tip.title}>
              <div
                className={`${classes.indexHolder} ${
                  index + 1 !== recognitionTips.length && classes.tipIndex
                }`}
              >
                {index + 1 !== recognitionTips.length ? (
                  index + 1
                ) : (
                  <SVGIcon icon="star" color={ThemeV2.palette.gold6} />
                )}
              </div>
              <div className={classes.contentWrapper}>
                <Body variant="body2Medium">{tip.title}</Body>
                <Body variant="body2">{tip.body}</Body>
              </div>
            </div>
          ))}
        </div>
      </Collapse>
    </div>
  );
};

export default GiveRecognitionTips;
