import React from 'react';

import MeetingParticipantsList from '../../../atomic/organism/QuickSetup/MeetingParticipantsList';

import { AddParticipantsControllerProps } from './types';
import useAddParticipantsController from './useAddParticipantsController';

const AddParticipantsController = (props: AddParticipantsControllerProps) => {
  const { models, operations } = useAddParticipantsController(props);

  const { participantsList, isParticipantsDataLoading } = models;
  const { handleOnBlur, handleOnDeleteItemClick, handleOnValueChange } =
    operations;

  return (
    <div>
      <MeetingParticipantsList
        onBlur={handleOnBlur}
        flowName={props.flowName}
        onValueChange={handleOnValueChange}
        isLoading={isParticipantsDataLoading}
        meetingParticipantsList={participantsList}
        onDeleteItemClick={handleOnDeleteItemClick}
      />
    </div>
  );
};

export default AddParticipantsController;
