import React, { useEffect, useMemo, useState } from 'react';

import Button from '../../../atoms/Button';
import FlowsBaseInputBlock from '../FlowsBaseInputBlock';
import NavigationInstructions from '../../FlowsInputBlockNavigationInstructions';
import RadioGroup from '../../RadioGroup';
import usePrevious from '../../../../hooks/usePrevious';

export type MultipleChoiceOption = {
  id: string;
  value: string;
};

export type FlowsMultipleChoiceSingleSelectInputBlockProps = {
  allowOther?: boolean;
  description?: string;
  goToNextStep: () => void;
  isRequired: boolean;
  isLastBlock?: boolean;
  subDescription?: string;
  options: MultipleChoiceOption[];
  onChange: (newValue: MultipleChoiceOption | null) => void;
  onBlur?: () => void;
  title: string;
  value: MultipleChoiceOption | null;
  isPreviewFlow?: boolean;
};

function FlowsMultipleChoiceSingleSelectInputBlock({
  allowOther = false,
  description,
  goToNextStep,
  isRequired,
  onChange,
  options,
  subDescription,
  title,
  value,
  isLastBlock = false,
  isPreviewFlow,
}: FlowsMultipleChoiceSingleSelectInputBlockProps) {
  const [otherInputValue, setOtherInputValue] = useState(() =>
    value?.id === 'other' ? value.value : '',
  );

  const previousOtherInputValue = usePrevious(otherInputValue);

  const radioOptions = useMemo(() => {
    const allOptions = options.map((option) => ({
      value: option.id,
      label: option.value,
    }));

    if (allowOther) {
      const otherOption = {
        label: 'Other',
        value: 'other',
      };
      allOptions.push(otherOption);
    }

    return allOptions;
  }, [allowOther, options]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedOptionValue = event.target.value;
    const selectedRadioOption = radioOptions.find(
      (option) => option.value === selectedOptionValue,
    );

    if (!selectedRadioOption) return;

    let multipleChoiceOption: MultipleChoiceOption;
    if (selectedRadioOption.value === 'other') {
      multipleChoiceOption = {
        id: selectedRadioOption.value,
        value: otherInputValue,
      };
    } else {
      multipleChoiceOption = {
        id: selectedRadioOption.value,
        value: selectedRadioOption.label,
      };
    }

    onChange(multipleChoiceOption || null);
  };

  const handleClearSelectionButtonClick = () => {
    onChange(null);
    setOtherInputValue('');
  };

  const ClearSelectionButton = (
    <Button
      onClick={handleClearSelectionButtonClick}
      variant="text"
      color="secondary"
    >
      Clear selection
    </Button>
  );

  useEffect(() => {
    if (previousOtherInputValue === otherInputValue) return;

    if (value?.id === 'other') {
      const newValue = {
        id: 'other',
        value: otherInputValue,
      };
      onChange(newValue);
    }
  }, [onChange, otherInputValue, previousOtherInputValue, value]);

  return (
    <FlowsBaseInputBlock
      description={description}
      inputAction={value ? ClearSelectionButton : undefined}
      isRequired={isRequired}
      navigationInstructions={
        <NavigationInstructions
          type={isLastBlock ? 'last+enter' : 'enter'}
          goToNextStep={goToNextStep}
          isPreviewFlow={isPreviewFlow}
        />
      }
      subDescription={subDescription}
      title={title}
    >
      <RadioGroup
        labelColor="gray9"
        labelVariant="body1"
        onOtherInputValueChange={(newValue: string) => {
          setOtherInputValue(newValue);
        }}
        onFocus={() => {
          onChange({ id: 'other', value: '' });
        }}
        otherInputValue={otherInputValue}
        radioOptions={radioOptions}
        radioSize="20px"
        name="flows-multiple-choice-single-select-input"
        onChange={handleChange}
        value={value?.id || ''}
      />
    </FlowsBaseInputBlock>
  );
}

export default FlowsMultipleChoiceSingleSelectInputBlock;
