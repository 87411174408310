import React, { ReactNode, useCallback } from 'react';
import shallow from 'zustand/shallow';

import { AsideInner, StyledAside, StyledIconButton } from './styles';
import SVGIcon from '../../atoms/SVGIcon';
import useLayoutStore from '../../../stores/layoutStore';
import useKioskStore from '../../../stores/kioskStore';
export interface SecondaryDrawerLayoutProps {
  isRightAsideOpen: boolean;
  toggleRightAsideOpen: () => void;
  showRightAside: boolean;
}

interface SecondaryDrawerProps {
  children: ReactNode;
  onSecondaryDrawerToggle?: (isRightAsideOpen: boolean) => void;
}

const SecondaryDrawer = ({
  children,
  onSecondaryDrawerToggle,
}: SecondaryDrawerProps) => {
  const { isTurnedOn: isKioskTurnedOn } = useKioskStore();
  const { isRightAsideOpen, toggleRightAsideOpen, showRightAside } =
    useLayoutStore(
      (state) => ({
        isRightAsideOpen: state.isRightAsideOpen,
        toggleRightAsideOpen: state.toggleRightAsideOpen,
        showRightAside: state.showRightAside,
      }),
      shallow,
    );

  const toggleSecondaryDrawer = useCallback(() => {
    if (onSecondaryDrawerToggle) {
      onSecondaryDrawerToggle(isRightAsideOpen);
    }
    toggleRightAsideOpen();
  }, [isRightAsideOpen, onSecondaryDrawerToggle, toggleRightAsideOpen]);

  return (
    <StyledAside
      isKioskTurnedOn={isKioskTurnedOn}
      isRightAsideOpen={isRightAsideOpen}
      aria-hidden={isRightAsideOpen ? 'false' : 'true'}
      id="side-drawer"
      data-testid="side-drawer"
      showRightAside={showRightAside}
    >
      <AsideInner showRightAside={showRightAside}>{children}</AsideInner>
      <StyledIconButton
        type="button"
        isActive
        onClick={toggleSecondaryDrawer}
        isRightAsideOpen={isRightAsideOpen}
        showRightAside={showRightAside}
        isKioskTurnedOn={isKioskTurnedOn}
        data-testid="side-drawer-toggle-button"
      >
        {isRightAsideOpen ? (
          <SVGIcon icon="carat-right" size="20px" />
        ) : (
          <SVGIcon icon="carat-left" size="20px" />
        )}
      </StyledIconButton>
    </StyledAside>
  );
};

export default SecondaryDrawer;
