import { useCallback, useMemo } from 'react';
import { trackParticipationFlowEvents } from '../../../Utils/analytics';
import { ParticipationAnalyticProps, StepProps } from './interface';
import { getFlowPerms } from '../../../Utils/flows/feeds';

const useTrackParticipationFlow = (
  data: Partial<ParticipationAnalyticProps>,
) => {
  const ON_DEMAND = 'ON DEMAND';
  const PARTICIPATION_EVENTS = 'participation.events';

  const participationEventProps = useMemo(() => {
    const {
      flowId = '',
      createdAt = '',
      updatedAt = '',
      owner = {
        memberId: '',
        role: [],
      },
      stepData = [],
      isShortcut = false,
      isFlowOwner = false,
      isFlowViewer = false,
      isFlowParticipant = false,
      name,
      templateId,
    } = data;

    const { memberId = '', role = [] } = owner;

    let numBlocksRequired = 0;

    const blockTypes = stepData.map((step: StepProps) => {
      if (step.isRequired) {
        numBlocksRequired += 1;
      }
      return step.type ? step.type : '';
    });
    const flowPerms = getFlowPerms({
      isFlowOwner: isFlowOwner || false,
      isFlowViewer: isFlowViewer || false,
      isFlowParticipant: isFlowParticipant || false,
    });
    const propsData = {
      flowName: name,
      templateId,
      flowOwnerId: memberId,
      flowOwnerEmail: '',
      flowOwnerPermissions: role,
      numBlocks: (stepData || []).length,
      numBlocksRequired,
      blockTypes,
      numOccurrences: 0,
      shortcut: isShortcut,
      trigger: ON_DEMAND,
      numVersions: 0,
      firstOccurrenceDate: createdAt,
      lastOccurrenceDate: updatedAt,
      publishedDate: createdAt,
      flowPerms,
      flowId,
    };

    return (action: string, blockPosition: number | null, event: string) => ({
      ...propsData,
      blockPosition,
      event,
      action,
    });
  }, [data]);

  const trackParticipationFlow = useCallback(
    (action: string, blockPosition: number | null, event: string) => {
      const eventProps = participationEventProps(action, blockPosition, event);
      trackParticipationFlowEvents(PARTICIPATION_EVENTS, eventProps);
    },
    [participationEventProps],
  );

  return {
    trackParticipationFlow,
  };
};

export default useTrackParticipationFlow;
