import { Formik } from 'formik';
import React from 'react';
import styled from 'styled-components';

import type * as Yup from 'yup';
import { SAVE_CHANGES } from '../../../languages/en/settings';
import Button from '../../atoms/Button';
import Field from '../Field';

const StyledButton = styled(Button)`
  margin-top: 16px;
`;

export interface SettingsStringFieldProps {
  fieldName: string;
  label: string;
  initialValues: Record<string, string>;
  validationSchema?: Yup.AnySchema;
  onSaveChanges: (values: Record<string, string>) => void;
}

const SettingsStringField = (props: SettingsStringFieldProps) => {
  const { initialValues, onSaveChanges, label, fieldName, validationSchema } =
    props;
  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSaveChanges}
      >
        {({ dirty, isValid, handleSubmit }) => (
          <>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/*  @ts-ignore  */}
            <Field type="textV2_text" label={label} name={fieldName} />
            <StyledButton
              color="primary"
              variant="contained"
              status="default"
              onClick={handleSubmit}
              disabled={!(dirty && isValid)}
              size="small"
            >
              {SAVE_CHANGES}
            </StyledButton>
          </>
        )}
      </Formik>
    </div>
  );
};

export default SettingsStringField;
