import { useCallback, useMemo } from 'react';

import { trackMainFeedActionBarEvent as trackMainFeedActionBarEventMetric } from '../../../Utils/analytics';
import {
  UseTrackMainFeedActionBarEventNewProps,
  UseTrackMainFeedActionBarEventProps,
} from './types';

const useTrackMainFeedActionBarEvent = (
  props: UseTrackMainFeedActionBarEventProps,
) => {
  const actionBarMainEvent = 'action.bar.events';
  const mainFeedActionBarEventProps = useMemo(() => {
    return {
      numToDoFlows: props.numToDoFlows,
      numShortcutFlows: props.numShortcutFlows,
      searchText: props.searchText ? props.searchText : null,
      flowId: props.flowId ? props.flowId : null,
    };
  }, [props]);

  const trackMainFeedActionBarEvent = useCallback(
    (eventName: string, newProps?: UseTrackMainFeedActionBarEventNewProps) => {
      const defaultProps = {
        event: 'action',
        action: eventName,
      };
      if (newProps) {
        const extendedProps = {
          ...mainFeedActionBarEventProps,
          ...defaultProps,
          ...newProps,
        };
        trackMainFeedActionBarEventMetric(actionBarMainEvent, extendedProps);
      } else {
        const extendedProps = {
          ...mainFeedActionBarEventProps,
          ...defaultProps,
        };
        trackMainFeedActionBarEventMetric(actionBarMainEvent, extendedProps);
      }
    },
    [mainFeedActionBarEventProps],
  );

  return { trackMainFeedActionBarEvent };
};

export default useTrackMainFeedActionBarEvent;
