// @ts-nocheck TODO: type issues need to be fixed in this file
import { useState, useEffect, useCallback } from 'react';
import useRequest from '../useRequest/useRequest';
import usePolling from '../usePolling/usePolling';
import configureAssemblyStatus from '../../Utils/identity/configureAssemblyStatus';
import { GET_JOB_STATUS } from '../../constants/endpoints';

const useJobPolling = (
  targetEndpoint: string,
  startJobPollingImmediately = true,
  targetEndpointOptions?,
  hasPaginatedIntegrationData?, // optional param to call reactQuery integration data
) => {
  const [startJobPolling, setStartPolling] = useState(
    startJobPollingImmediately,
  );
  const [jobId, setJobId] = useState();
  const [loading, setLoading] = useState(startJobPollingImmediately);
  const [responseData, setResponseData] = useState();
  const [error, setError] = useState();

  const updateError = useCallback((errorObj) => {
    setError(errorObj);
    setLoading(false);
  }, []);
  const {
    fireRequest: targetEndpointRequest,
    response: targetEndpointResponse,
  } = useRequest(targetEndpoint, {
    onError: updateError,
  });
  const { response: pollingResponse, fireRequest: pollingRequest } =
    useRequest(GET_JOB_STATUS);
  const makeTargetEndpointRequest = useCallback(() => {
    targetEndpointRequest(targetEndpointOptions);
  }, [targetEndpointRequest, targetEndpointOptions]);
  const makePollingEndpointRequest = useCallback(() => {
    if (jobId) {
      pollingRequest({ substitutions: { jobId } });
    }
  }, [jobId, pollingRequest]);
  const startPollingRequests = useCallback(() => setStartPolling(true), []);
  const { startPolling, stopPolling } = usePolling(
    makePollingEndpointRequest,
    3000,
    true,
    false,
  );

  useEffect(() => {
    if (startJobPolling) {
      setLoading(true);
      makeTargetEndpointRequest();
    }
  }, [makeTargetEndpointRequest, startJobPolling]);
  useEffect(() => {
    if (targetEndpointResponse) {
      const { data } = targetEndpointResponse;
      if (data?.integrationDataJob?.id) {
        setJobId(data.integrationDataJob.id);
      } else if (!data.integrationDataJob) {
        setResponseData(data);
        setLoading(false);
      }
    }
  }, [targetEndpointResponse]);
  useEffect(() => {
    if (pollingResponse) {
      const { data } = pollingResponse;
      if (data?.data?.status) {
        const jobStatus = data.data.status;
        if (
          jobStatus === configureAssemblyStatus.SUCCESS ||
          jobStatus === configureAssemblyStatus.ERROR
        ) {
          setJobId(undefined);
          stopPolling();
        }
        if (jobStatus === configureAssemblyStatus.SUCCESS) {
          makeTargetEndpointRequest();
        }
        if (jobStatus === configureAssemblyStatus.ERROR) {
          setError({ status: 500, message: data?.data?.message });
          setLoading(false);
        }
      }
    }
  }, [
    hasPaginatedIntegrationData,
    makeTargetEndpointRequest,
    pollingResponse,
    stopPolling,
  ]);
  useEffect(() => {
    if (jobId) {
      startPolling();
    }
  }, [jobId, startPolling]);
  return {
    responseData,
    loading,
    error,
    startPollingRequests,
  };
};

export default useJobPolling;
