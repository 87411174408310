import { BaseEmoji } from 'emoji-mart';
import { useLocation } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import uniq from 'lodash/uniq';
import { useCallback, useEffect, useMemo, useState } from 'react';

import useFlowBuilderStore, {
  createBlockDataObject,
} from '../../../../stores/flowBuilderStore';

import {
  FlowBuilderBlockData,
  FlowBuilderBlockTypes,
} from '../../../../interfaces/Flow/Builder';

import useFlowBuilderEdit from '../useFlowBuilderEdit';
import { useFlowBuilderValidation } from '../../../../hooks/useFlowBuilderValidation';

import { useProfileInfoFetchQuery } from '../../../../queries/Profile';
import {
  FLOW_BUILDER_EVENTS,
  INVITE_MEMBER_EVENTS,
} from '../../../../Utils/analytics/constants';
import useArchiveFlowOption from '../../../../hooks/useFlowFeedOptions/useArchiveFlowOption';

import useToggle from '../../../../hooks/useToggle';
import useSaveFlow from '../../../../hooks/useSaveFlow';
import {
  blockDataSelector,
  descriptionSelector,
  disableNavigationModalSelector,
  emojiSelector,
  flowNameSelector,
  isFlowDataChangeConfirmedSelector,
  isInEditModeSelector,
  isInviteNewTeammatesModalOpenSelector,
  ownerSelector,
  newParticipantsToInviteSelector,
  newVisibilityMembersToInviteSelector,
  revertBlockDataToDefaultStateSelector,
  setDescriptionSelector,
  setDisableNavigationModalSelector,
  setEmojiSelector,
  setFlowNameSelector,
  setIsFlowDataChangeConfirmedSelector,
  setIsInEditModeSelector,
  setIsInviteNewTeammatesModalOpenSelector,
  setShowDataChangeConfirmSelector,
  setSpecificBlockDataSelector,
  showDataChangeConfirmSelector,
  templateTypeSelector,
  newContentMembersToInviteSelector,
  templateNameSelector,
} from '../../../../stores/flowBuilderStore/selectors';
import {
  trackFlowBuilderShowEvent,
  trackFlowBuilderActionEvent,
} from '../../../../Utils/analytics/flowsBuilder';
import { showWelcomeToBuilderToast } from '../../../../Utils/toast';
import { trackInviteMemberActionEvent } from '../../../../Utils/analytics/inviteMembers';
import { defaultBuilderBlocks } from '../data';

const defaultBlockData = createBlockDataObject();

const useFlowBuilderController = () => {
  const { search } = useLocation();

  const emoji = useFlowBuilderStore(emojiSelector);
  const owner = useFlowBuilderStore(ownerSelector);
  const flowName = useFlowBuilderStore(flowNameSelector);
  const setEmoji = useFlowBuilderStore(setEmojiSelector);
  const blockData = useFlowBuilderStore(blockDataSelector);
  const description = useFlowBuilderStore(descriptionSelector);
  const setFlowName = useFlowBuilderStore(setFlowNameSelector);
  const isInEditMode = useFlowBuilderStore(isInEditModeSelector);
  const templateType = useFlowBuilderStore(templateTypeSelector);
  const templateName = useFlowBuilderStore(templateNameSelector);
  const setDescription = useFlowBuilderStore(setDescriptionSelector);
  const setIsInEditMode = useFlowBuilderStore(setIsInEditModeSelector);

  const { TRIGGER, PARTICIPANTS, VISIBILITY, CONTENT, COMPLETION } =
    defaultBuilderBlocks;

  const newParticipantsToInvite = useFlowBuilderStore(
    newParticipantsToInviteSelector,
  );
  const newVisibilityMembersToInvite = useFlowBuilderStore(
    newVisibilityMembersToInviteSelector,
  );

  const newContentMembersToInvite = useFlowBuilderStore(
    newContentMembersToInviteSelector,
  );

  const allNewMembers = Array.from(
    new Set([
      ...newParticipantsToInvite,
      ...newVisibilityMembersToInvite,
      ...newContentMembersToInvite,
    ]),
  );

  const setSpecificBlockData = useFlowBuilderStore(
    setSpecificBlockDataSelector,
  );

  const showDataChangeConfirm = useFlowBuilderStore(
    showDataChangeConfirmSelector,
  );

  const disableNavigationModal = useFlowBuilderStore(
    disableNavigationModalSelector,
  );

  const setShowDataChangeConfirm = useFlowBuilderStore(
    setShowDataChangeConfirmSelector,
  );

  const setDisableNavigationModal = useFlowBuilderStore(
    setDisableNavigationModalSelector,
  );

  const isFlowDataChangeConfirmed = useFlowBuilderStore(
    isFlowDataChangeConfirmedSelector,
  );

  const setIsFlowDataChangeConfirmed = useFlowBuilderStore(
    setIsFlowDataChangeConfirmedSelector,
  );

  const revertBlockDataToDefaultState = useFlowBuilderStore(
    revertBlockDataToDefaultStateSelector,
  );

  const isInviteNewTeammatesModalOpen = useFlowBuilderStore(
    isInviteNewTeammatesModalOpenSelector,
  );

  const setIsInviteNewTeammatesModalOpen = useFlowBuilderStore(
    setIsInviteNewTeammatesModalOpenSelector,
  );

  const [templateId, setTemplateId] = useState('');
  const { data: profileInfo } = useProfileInfoFetchQuery();
  const [currentStep, setCurrentStep] = useState<number>(-1);
  const [touchedBlocks, setTouchedBlocks] = useState<number[]>([]);
  const [unlockedBlocks, setUnlockedBlocks] = useState<number[]>([]);

  const { flowId, hasActiveOccurrence, isScheduledFlowEnded } =
    useFlowBuilderEdit();

  const { isValid, isCurrentBlockDataValid } = useFlowBuilderValidation();

  const {
    models: { toggleValue: isPreviewModalOpen },
    operations: { setToggleValue: togglePreviewModalOpen },
  } = useToggle();

  const openInviteNewTeammatesModal = () => {
    setIsInviteNewTeammatesModalOpen(true);
  };

  const allBlocks = Object.keys(defaultBuilderBlocks) as Array<
    keyof typeof FlowBuilderBlockTypes
  >;

  const blocksToBeUnLocked = [
    PARTICIPANTS.index,
    VISIBILITY.index,
    CONTENT.index,
    COMPLETION.index,
  ];

  useEffect(() => {
    if (isInviteNewTeammatesModalOpen) {
      let subSource = 'participantCriteria';
      if (newParticipantsToInvite.length) {
        subSource = 'participantCriteria';
      }
      if (newVisibilityMembersToInvite.length) {
        subSource = 'viewerCriteria';
      }
      if (newContentMembersToInvite.length) {
        subSource = 'personSelection';
      }
      trackInviteMemberActionEvent({
        action: INVITE_MEMBER_EVENTS.INVITE_START,
        inviterId: profileInfo?.member.memberId,
        inviterRole: profileInfo?.member.role,
        source: 'builder',
        subSource: subSource,
      });
    }
  }, [
    isInviteNewTeammatesModalOpen,
    newContentMembersToInvite,
    newParticipantsToInvite,
    newVisibilityMembersToInvite,
    profileInfo,
  ]);

  const closeInviteNewTeammatesModal = () => {
    setIsInviteNewTeammatesModalOpen(false);
  };

  const { saveFlow, isSavingFlow, inviteAndSaveFlow } = useSaveFlow({
    openInviteNewTeammatesModal,
    closeInviteNewTeammatesModal,
  });

  const {
    models: { isArchiveModalOpen, isArchiveFlowLoading },
    operations: {
      setArchiveModalOpen,
      setArchiveModalClose,
      handleOnArchiveClick,
    },
  } = useArchiveFlowOption(flowId);

  const openArchiveModal = () => {
    setArchiveModalOpen();
  };

  useEffect(() => {
    setDisableNavigationModal(false);
  }, [setDisableNavigationModal]);

  useEffect(() => {
    setTemplateId(new URLSearchParams(search).get('template') || '');
    trackFlowBuilderShowEvent({
      show: FLOW_BUILDER_EVENTS.BUILDER_SHOW,
    });
  }, [search]);

  useEffect(() => {
    if (new URLSearchParams(search).get('flow') === 'survey_Team') {
      showWelcomeToBuilderToast();
    }
  }, [search]);

  useEffect(() => {
    switch (currentStep) {
      case defaultBuilderBlocks.ACTION.index:
        // TO DO: Sumedha - Check the right event
        trackFlowBuilderActionEvent({
          action: FLOW_BUILDER_EVENTS.BUILDER_SHOW,
        });
        break;
      case defaultBuilderBlocks.TRIGGER.index:
        trackFlowBuilderActionEvent({
          action: FLOW_BUILDER_EVENTS.TRIGGER_SHOW,
        });
        break;
      case defaultBuilderBlocks.PARTICIPANTS.index:
        trackFlowBuilderActionEvent({
          action: FLOW_BUILDER_EVENTS.PARTICIPATION_SHOW,
        });
        break;
      case defaultBuilderBlocks.VISIBILITY.index:
        trackFlowBuilderActionEvent({
          action: FLOW_BUILDER_EVENTS.VISIBILITY_SHOW,
        });
        break;
      case defaultBuilderBlocks.CONTENT.index:
        trackFlowBuilderActionEvent({
          action: FLOW_BUILDER_EVENTS.CONTENT_SHOW,
        });
        break;
      case defaultBuilderBlocks.COMPLETION.index:
        trackFlowBuilderActionEvent({
          action: FLOW_BUILDER_EVENTS.CONFIRMATION_SHOW,
        });
        break;
      default:
        break;
    }
  }, [currentStep]);

  useEffect(() => {
    if (templateId) {
      setCurrentStep(0);
      setTouchedBlocks([0, 1, 2]);
      setUnlockedBlocks([0, 1, 2]);
    }

    setCurrentStep(0);

    if (flowId) {
      if (isInEditMode) {
        setCurrentStep(0);
        setTouchedBlocks([0]);
        setUnlockedBlocks([0, 1, 2]);
      } else {
        setCurrentStep(-1);
      }
      setDisableNavigationModal(!isInEditMode);
    }

    return;
  }, [
    flowId,
    isInEditMode,
    templateType,
    templateId,
    setDisableNavigationModal,
  ]);

  const disableEditMode = () => {
    if (flowId && isInEditMode) {
      setIsInEditMode(false);
      setUnlockedBlocks([]);
    }
  };

  useEffect(() => {
    // In the beginning if in EDIT mode, unlock blocks
    // Else, show builder in disabled state
    disableEditMode();

    return () => {
      disableEditMode();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFlowNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFlowName(e.target.value);
  };

  const handleSetFlowDescription = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(e.target.value);
  };

  const handledTouchedBlocks = useCallback(() => {
    const newTouchedBlocks = !touchedBlocks.includes(currentStep)
      ? [...touchedBlocks, currentStep]
      : touchedBlocks;
    setTouchedBlocks(newTouchedBlocks);
  }, [currentStep, touchedBlocks]);

  const checkBlockDataChanges = useMemo(() => {
    const currentBlockKey = allBlocks[
      currentStep
    ] as keyof FlowBuilderBlockData;
    // Check if the blockData for the current block is changed as compared to default block data
    const hasBlockDataChanged =
      currentStep !== COMPLETION.index
        ? !isEqual(
            defaultBlockData[currentBlockKey],
            blockData[currentBlockKey],
          )
        : !isEmpty(flowName) && !isEmpty(emoji);
    return hasBlockDataChanged;
  }, [blockData, currentStep, emoji, flowName]);

  useEffect(() => {
    if (checkBlockDataChanges && currentStep !== TRIGGER.index) {
      handledTouchedBlocks();
    }
  }, [
    checkBlockDataChanges,
    currentStep,
    handledTouchedBlocks,
    emoji,
    flowName,
  ]);

  const goToNextStep = useCallback(() => {
    const newUnlockedBlocks = uniq([...unlockedBlocks, currentStep]);
    setUnlockedBlocks(newUnlockedBlocks);
    handledTouchedBlocks();
    setCurrentStep(currentStep + 1);
  }, [currentStep, handledTouchedBlocks, unlockedBlocks]);

  const goToNextStepFromTrigger = useCallback(() => {
    const newUnlockedBlocks = uniq([
      ...unlockedBlocks,
      currentStep,
      ...blocksToBeUnLocked,
    ]);
    setUnlockedBlocks(newUnlockedBlocks);
    handledTouchedBlocks();
    setCurrentStep(currentStep + 1);
  }, [currentStep, handledTouchedBlocks, unlockedBlocks]);

  const handleEditButtonClick = useCallback(
    (index: number) => {
      setCurrentStep(index);
    },
    [setCurrentStep],
  );

  const handleOnSetEmoji = useCallback(
    (selectedEmoji: BaseEmoji) => {
      setEmoji(selectedEmoji);
      trackFlowBuilderActionEvent({
        action: FLOW_BUILDER_EVENTS.FLOW_EMOJI_ADDED,
        flowEmoji: selectedEmoji?.name,
      });
    },
    [setEmoji],
  );

  const handlePreviewModalOpen = useCallback(() => {
    if (!isPreviewModalOpen)
      trackFlowBuilderActionEvent({
        action: FLOW_BUILDER_EVENTS.FLOW_PREVIEWED,
      });
    togglePreviewModalOpen();
  }, [isPreviewModalOpen, togglePreviewModalOpen]);

  return {
    models: {
      emoji,
      owner,
      flowId,
      flowName,
      blockData,
      templateId,
      templateName,
      currentStep,
      description,
      profileInfo,
      isInEditMode,
      touchedBlocks,
      unlockedBlocks,
      isArchiveModalOpen,
      isPreviewModalOpen,
      isArchiveFlowLoading,
      isScheduledFlowEnded,
      hasActiveOccurrence,
      showDataChangeConfirm,
      disableNavigationModal,
      disableSubmit: !isValid,
      isFlowDataChangeConfirmed,
      revertBlockDataToDefaultState,
      isFlowBeingSaved: isSavingFlow,
      isInviteNewTeammatesModalOpen,
      inviteNewMemberEmailIds: allNewMembers,
    },
    operations: {
      goToNextStep,
      setTemplateId,
      setCurrentStep,
      openArchiveModal,
      setArchiveModalClose,
      setSpecificBlockData,
      handleFlowNameChange,
      handleOnArchiveClick,
      handleEditButtonClick,
      goToNextStepFromTrigger,
      togglePreviewModalOpen: handlePreviewModalOpen,
      isCurrentBlockDataValid,
      onSubmitClick: saveFlow,
      onInviteAndSaveFlow: inviteAndSaveFlow,
      handleSetFlowDescription,
      setShowDataChangeConfirm,
      setEmoji: handleOnSetEmoji,
      setIsFlowDataChangeConfirmed,
      openInviteNewTeammatesModal,
      closeInviteNewTeammatesModal,
    },
  };
};

export default useFlowBuilderController;
