import styled from 'styled-components';

import Avatar from '../../atoms/Avatar';
import ThemeV2 from '../../../componentsV2/theme';
import { OthersLabel } from '../FeedPostHeader/styles';

export const Wrapper = styled.div`
  padding: 16px 24px;
`;

export const MembersWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 0 6px 20px;
  cursor: pointer;
  margin-left: -20px;
  border-radius: 4px;

  &:hover,
  &:focus {
    background: ${ThemeV2.palette.geekBlue1};
  }
`;

export const StyledAvatar = styled(Avatar)`
  margin-right: 4px;
`;

export const OthersLabelWrapper = styled(OthersLabel)`
  margin-top: 4px;
`;

export const SubsectionsWrapper = styled.div`
  div:last-child {
    border-bottom: none;
  }
`;
