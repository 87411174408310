import styled from 'styled-components';
import ContentLoader from 'react-content-loader';
import ThemeV2 from '../../../componentsV2/theme';

export const FeedPostWrapper = styled.div`
  max-width: 474px;
  background-color: white;
  border: 1px solid ${ThemeV2.palette.gray5};
  border-radius: 4px;
  margin: 0 auto 16px;
`;

export const FeedCommentInputWrapper = styled.div`
  padding: 8px 16px 16px 16px;
`;

export const StyledContentLoader = styled(ContentLoader)`
  width: 100%;
  height: 100%;
`;
