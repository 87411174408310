export enum QuickSetupStatusEnum {
  TODO = 'TODO',
  SKIPPED = 'SKIPPED',
  COMPLETED = 'COMPLETED',
  INPROGRESS = 'INPROGRESS',
}

export enum QuickSetupDirectionEnum {
  FORWARD = 'FORWARD',
  BACKWARD = 'BACKWARD',
}

export enum QuickSetupCalendarStepsEnum {
  LIST_MEETING = 'LIST_MEETING',
  INVITE_MEMBER = 'INVITE_MEMBER',
  LIST_TEMPLATE = 'LIST_TEMPLATE',
  CONNECT_CALENDAR = 'CONNECT_CALENDAR',
}

export enum SurveyStepsEnum {
  INVITE_MEMBER = 'INVITE_MEMBER',
  LIST_TEMPLATE = 'LIST_TEMPLATE',
}

interface CurrentStep<T> {
  value?: T;
  metaData?: Record<string, unknown>;
}

export interface EventTime {
  dateTime: string;
}

export interface Event {
  id: string;
  end: EventTime;
  summary: string;
  start: EventTime;
  attendeesCount: number;
  recurrence: string[] | null;
}

export interface GetCalendarEventsListResponse {
  data: Event[];
}

export interface GetQuickSetupStatusResponse {
  status: QuickSetupStatusEnum;
  survey?: CurrentStep<SurveyStepsEnum>;
  calendar?: CurrentStep<QuickSetupCalendarStepsEnum>;
}

export interface GetQuickSetupStatusRequest {
  status?: QuickSetupStatusEnum;
  direction: QuickSetupDirectionEnum;
  survey?: CurrentStep<SurveyStepsEnum>;
  calendar?: CurrentStep<QuickSetupCalendarStepsEnum>;
}
