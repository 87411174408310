import ThemeV2 from '../../../../componentsV2/theme';

import SurveyImage from '../../../../img/svgs/survey.svg';
import RocketImage from '../../../../img/svgs/rocket.svg';
import SelectionImage from '../../../../img/svgs/selection.svg';

export const getStartedScreenClickableItems = [
  {
    id: 'superCharge_Meetings',
    title: 'I want to supercharge my meetings',
    imgSrc: SelectionImage,
    backgroundColor: ThemeV2.palette.geekBlue2,
    subTitle:
      // eslint-disable-next-line max-len
      'Improve one-on-ones, standups, and more with agendas, context, timely reflections.',
  },
  {
    id: 'engage_Team',
    title: 'I want to engage my team',
    imgSrc: RocketImage,
    backgroundColor: ThemeV2.palette.blue1,
    subTitle:
      'Promote recognition, employee nominations, and promote connectivity.',
  },
  {
    id: 'survey_Team',
    title: 'I want to survey my team',
    imgSrc: SurveyImage,
    backgroundColor: ThemeV2.palette.volcano,
    subTitle:
      // eslint-disable-next-line max-len
      'Measure engagement, get a pulse on team morale, or learn about career development aspirations.',
  },
];
