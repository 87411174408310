import React, { LegacyRef, useEffect, useState } from 'react';
import './styles/main.scss';

import TableWrapper from './tableWrapper';

import PDFViewer from './formats/pdfViewer';
import DocxViewer from './formats/docxViewer';
import AudioViewer from './formats/audioViewer';
import UnsupportedViewer from './formats/unsupportedViewer';
import PhotoViewer from './formats/photoViewer';
import TxtViewer from './formats/txtViewer';
import VideoViewer from './formats/videoViewer';

export interface FileViewerProps {
  fileType: string;
  filePath: string;
  zoomPercent?: number;
  setDefaultZoom: (value: number) => void;
  onError?: () => void;
  errorComponent?: React.ReactNode;
  unsupportedComponent?: React.ReactNode;
  responseType?: XMLHttpRequestResponseType;
  SubComponent?: (props: { index: number }) => JSX.Element;
  WrapperComponent?: (wrapperProps: {
    index: number;
    children: React.ReactNode;
  }) => JSX.Element;
  viewerRef?: LegacyRef<HTMLDivElement> | undefined;
  idPrefix?: string;
  hideLoader?: boolean;
}

const FileViewer = (props: FileViewerProps) => {
  const { fileType, filePath, viewerRef } = props;

  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const container = document.getElementById('pg-viewer');
    const newHeight = container ? container.clientHeight : 0;
    const newWidth = container ? container.clientWidth : 0;

    setHeight(newHeight);
    setWidth(newWidth);
  }, []);

  const getDriver = (fileExtension: string) => {
    switch (fileExtension) {
      case 'csv':
      case 'xls':
      case 'xlsx': {
        return TableWrapper;
      }
      case 'jpg':
      case 'jpeg':
      case 'gif':
      case 'bmp':
      case 'png': {
        return PhotoViewer;
      }
      case 'pdf': {
        return PDFViewer;
      }
      case 'docx': {
        return DocxViewer;
      }
      case 'mp3': {
        return AudioViewer;
      }
      case 'webm':
      case 'mp4':
      case 'mov': {
        return VideoViewer;
      }
      case 'txt': {
        return TxtViewer;
      }
      default: {
        return UnsupportedViewer;
      }
    }
  };

  const Driver = getDriver(fileType);

  return (
    <div className="pg-viewer-wrapper" ref={viewerRef}>
      <div className="pg-viewer" id="pg-viewer">
        {filePath && <Driver {...props} width={width} height={height} />}
      </div>
    </div>
  );
};

export default FileViewer;
