import styled from 'styled-components';
import ThemeV2 from '../../../../componentsV2/theme';
import { Flex } from '../../../../Utils/styles/display';
import Body from '../../../atoms/Body';
import ButtonBase from '../../../atoms/ButtonBase';
import SVGIcon from '../../../atoms/SVGIcon';

const imageHolderSize = {
  regular: '100%',
  small: '64px',
};

const imageSize = {
  regular: '64px',
  small: '32px',
};

export const ImageHolder = styled(Flex)<{
  size?: 'regular' | 'small';
  backGroundColor?: string;
}>`
  background: ${({ backGroundColor }) =>
    backGroundColor ? backGroundColor : `${ThemeV2.palette.geekBlue2}`};
  border-radius: 4px 0px 0px 4px;
  height: ${({ size }) => imageHolderSize[size || 'small']};
  width: ${({ size }) => imageHolderSize[size || 'small']};
  min-width: ${({ size }) => imageHolderSize[size || 'small']};
  & img {
    height: ${({ size }) => imageSize[size || 'small']};
    width: ${({ size }) => imageSize[size || 'small']};
    min-width: ${({ size }) => imageSize[size || 'small']};
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding: 12px 16px;
  box-sizing: border-box;
  height: 100%;
  max-width: 346px;
`;

export const TitleWrapper = styled.span<{ hasChildren: boolean }>`
  display: inline-flex;
  align-items: center;
  margin-bottom: ${({ hasChildren }) => hasChildren && '4px'};
`;

export const Title = styled(Body)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  width: 100%;
`;

export const HelperText = styled(Body)`
  white-space: nowrap;
  margin-left: 4px;
`;

export const ArrowWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 32px;
  height: 32px;
  margin-right: 16px;
  margin-left: auto;
`;

export const StyledSVGArrowIcon = styled(SVGIcon)``;

export const Wrapper = styled(ButtonBase)<{
  componentSize?: 'regular' | 'small';
  hasImage: boolean;
}>`
  display: grid;
  grid-template-columns: ${({ componentSize, hasImage }) =>
    componentSize === 'regular'
      ? `${hasImage ? '96px 1fr 46px' : '1fr 48px'}`
      : `${hasImage ? '64px 1fr 48px' : '1fr 48px'}`};
  grid-template-rows: auto;
  width: 100%;
  max-height: 98px;
  height: 100%;
  text-align: left;
  align-items: center;
  border-radius: 4px;
  border: 1px solid ${ThemeV2.palette.gray4};
  width: 100%;
  background: ${ThemeV2.palette.gray1};
  transition: 0.2s ease;
  transition-property: border color background;
  cursor: pointer;
  &:hover,
  &:focus-visible {
    border-color: ${ThemeV2.palette.geekBlue6};
    background: ${ThemeV2.palette.geekBlue1};
    & * {
      color: ${ThemeV2.palette.geekBlue6};
    }
    ${Title},${HelperText} {
      color: ${ThemeV2.palette.geekBlue6};
    }
    ${StyledSVGArrowIcon} {
      path {
        fill: ${ThemeV2.palette.geekBlue6};
      }
    }
  }
`;
