import moment from 'moment';
import { useState, useEffect } from 'react';
import { AxiosError } from 'axios';
import { OccurrenceValues } from '../../atomic/organism/FlowRunNowModal/types';
import useToggle from '../useToggle';
import { showErrorMessage, showSuccessMessage } from '../../Utils/toast';
import { getErrorMessage } from '../../Utils/message';
import { FLOW_TRIGGER_RESPONSE_MESSAGE } from '../../languages/en/flows/feed';
import { useTriggerFlow } from '../../queries/Flows/FlowFeedOptions/RunFlow';
import { useFlowParticipantsSearch } from '../useMembersSearch';
import { getParticipants } from '../../Utils/flows';
import {
  getDaysFromDeadline,
  getDeadlineConfigurationTimeType,
} from '../../Utils/flows/scheduler/deadline';
import { useFetchFlowDetailsQuery } from '../../queries/Flows/Feed';

const useTriggerFlowOption = (
  id: string,
  canFetchParticipantList?: boolean,
) => {
  const { data: flowData } = useFetchFlowDetailsQuery(id, 'builder');
  const [dueDateValue, setDueDateValue] = useState(1);
  const [occurrenceValue, setOccurrenceValue] =
    useState<OccurrenceValues>('days');

  useEffect(() => {
    if (flowData && flowData.data.endTimeInMinutes !== undefined) {
      setDueDateValue(getDaysFromDeadline(flowData.data.endTimeInMinutes));
      setOccurrenceValue(
        getDeadlineConfigurationTimeType(flowData.data.endTimeInMinutes),
      );
    }
  }, [flowData]);

  const {
    models: { toggleValue: isTriggerFlowModalOpen },
    operations: {
      setToggleToFalse: setIsTriggerFlowModalClose,
      setToggleToTrue: setIsTriggerFlowModalOpen,
    },
  } = useToggle();

  const { mutate: mutateTriggerFlow, isLoading: isTriggerFlowLoading } =
    useTriggerFlow();

  const {
    models: { searchedMembers: data, totalMembers, hasMoreMembers },
    operations: { fetchMoreMembers },
  } = useFlowParticipantsSearch(id, canFetchParticipantList);

  const participants = getParticipants(data);

  const revertModalInputFieldValues = () => {
    setDueDateValue(1);
    setOccurrenceValue('days');
  };

  const setTriggerFlowModalClose = () => {
    setIsTriggerFlowModalClose();
  };

  const handleDueDateValueChange = (value: number) => {
    setDueDateValue(value);
  };

  const handleTriggerFlowButtonClick = (flowId: string) => {
    mutateTriggerFlow(
      {
        flowId,
        endTimeInMinutes: moment
          .duration(Number(dueDateValue), occurrenceValue)
          .asMinutes(),
      },
      {
        onError: (error) => {
          showErrorMessage(
            getErrorMessage(
              error as AxiosError,
              FLOW_TRIGGER_RESPONSE_MESSAGE.ERROR,
            ),
          );
        },
        onSuccess: () => {
          showSuccessMessage(FLOW_TRIGGER_RESPONSE_MESSAGE.SUCCESS);
          setIsTriggerFlowModalClose();
          revertModalInputFieldValues();
        },
      },
    );
  };

  return {
    models: {
      isTriggerFlowModalOpen,
      dueDateValue,
      occurrenceValue,
      participants,
      participantsCount: totalMembers,
      isTriggerFlowLoading,
      hasMoreMembers,
    },
    operations: {
      setTriggerFlowModalClose,
      handleDueDateValueChange,
      setOccurrenceValue,
      setIsTriggerFlowModalOpen,
      fetchMoreMembers,
      handleTriggerFlowButtonClick,
    },
  };
};

export default useTriggerFlowOption;
