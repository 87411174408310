import styled from 'styled-components';
import Modal from '../../atoms/Modal';
import Heading from '../../atoms/Heading';
import ThemeV2 from '../../../componentsV2/theme';
import { device } from '../../../constants/layout';
import { StyledCardWithIcon } from '../../molecules/FlowsToDoCard/styles';
import Button from '../../atoms/Button';

export const StyledHeaderWrapper = styled.div`
  margin-bottom: 16px;
`;

export const Wrapper = styled.div<{
  isLoading: boolean;
  headerHeight: string;
}>`
  display: grid;
  grid-template-rows: ${({ isLoading, headerHeight }) =>
    isLoading ? '160px auto' : `${headerHeight} auto`};
  width: 100%;
`;

export const StyledModal = styled(Modal)`
  display: flex;
  width: 100vw;
  height: 100%;
  overflow: hidden;
  @media (min-width: ${ThemeV2.breakPoints.md}) {
    width: 80vw;
    max-width: 1800px;
    height: 80vh;
    max-height: 1013px;
  }

  @media ${device.mobile} {
    ${Wrapper} {
      max-width: 100%;
      border: none;
    }
  }
`;

export const TemplateCardsWrapper = styled.div`
  display: grid;
  grid-column-gap: 24px;
  grid-row-gap: 36px;
  grid-template-columns: 1fr 1fr 1fr;
  padding-bottom: 20px;
`;

export const TemplateCardWrapper = styled(StyledCardWithIcon)`
  padding: 20px 16px 12px 16px;
`;

export const LoaderCardWrapper = styled.div`
  display: inline-block;
`;

export const BannerLoaderWrapper = styled.div`
  display: grid;
  margin-bottom: 24px;
  max-width: 100%;
  & svg {
    max-width: 100%;
  }
`;

export const StyledCategoryTitle = styled(Heading)`
  margin-top: 24px;
  margin-bottom: 16px;
`;

export const ScrollableContent = styled.div`
  overflow-y: auto;
  padding: 0px 40px;
  @media ${device.mobile} {
    padding: 0px 24px;
  }
`;

export const CreateFlowBanner = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  padding: 19px 16px;
  border-radius: 4px;
  border: 1px solid ${ThemeV2.palette.gray4};
`;

export const StyledButton = styled(Button)`
  margin-left: auto;
`;
