import React from 'react';

import TextField from '../TextField_V2';

import { RewardsHeaderProps } from './interfaces';

import {
  StyledRewardsHeader,
  StyledRewardsHeaderSearchContainer,
  StyledRewardFilterContainer,
  StyledRewardSortContainer,
} from './styles';

const RewardsHeader = (props: RewardsHeaderProps) => {
  const { search, filter, sort, isLeftAsideOpen, isRightAsideOpen } = props;

  return (
    <StyledRewardsHeader data-testid="rewards-header">
      <StyledRewardsHeaderSearchContainer
        isLeftAsideOpen={isLeftAsideOpen}
        isRightAsideOpen={isRightAsideOpen}
      >
        <TextField {...search} />
      </StyledRewardsHeaderSearchContainer>
      {filter && (
        <StyledRewardFilterContainer
          isLeftAsideOpen={isLeftAsideOpen}
          isRightAsideOpen={isRightAsideOpen}
        >
          <TextField {...filter} />
        </StyledRewardFilterContainer>
      )}
      <StyledRewardSortContainer
        isLeftAsideOpen={isLeftAsideOpen}
        isRightAsideOpen={isRightAsideOpen}
      >
        <TextField {...sort} />
      </StyledRewardSortContainer>
    </StyledRewardsHeader>
  );
};

export default RewardsHeader;
