import React, { memo } from 'react';

import Body from '../../atoms/Body';
import {
  AccordionHeader,
  AccordionHeaderInner,
} from '../../molecules/AccordionItem/styles';
import { EMAIL } from '../../../languages/en/singleWords';
import EmailNotificationSettings, {
  EmailNotificationPreferencesProps,
} from '../../molecules/EmailNotificationSettings';
import { SETTINGS_HOW_YOU_NOTIFIED } from '../../../languages/en/settings';
import { EmailRowWrapper, StyledAccordionRoot, StyledSVGIcon } from './styles';

export interface NotificationSettingsAccordionProps {
  preferences?: EmailNotificationPreferencesProps;
  onFlowItemOptionsToggle: (optionId?: string) => void;
  handleToggle: (notificationId: string) => void;
  isError: boolean;
  isLoading: boolean;
}

const NotificationSettingsAccordion = ({
  preferences,
  onFlowItemOptionsToggle,
  handleToggle,
  isError,
  isLoading,
}: NotificationSettingsAccordionProps) => {
  return (
    <StyledAccordionRoot>
      <AccordionHeader>
        <AccordionHeaderInner>
          <Body variant="body1Bold">{SETTINGS_HOW_YOU_NOTIFIED}</Body>
        </AccordionHeaderInner>
      </AccordionHeader>

      <EmailRowWrapper>
        <StyledSVGIcon icon="mail" />
        <Body variant="body2Bold">{EMAIL}</Body>
      </EmailRowWrapper>

      <EmailNotificationSettings
        isError={isError}
        isLoading={isLoading}
        onToggle={handleToggle}
        preferences={preferences}
        onFlowItemOptionsToggle={onFlowItemOptionsToggle}
      />
    </StyledAccordionRoot>
  );
};

const MemoizedNotificationSettingsAccordion = memo(
  NotificationSettingsAccordion,
);
MemoizedNotificationSettingsAccordion.displayName =
  'NotificationSettingsAccordion';
export default MemoizedNotificationSettingsAccordion;
