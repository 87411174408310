import create from 'zustand';

type UppyStoreState = {
  createStore: (storeId: string) => void;
  updateStore: (storeId: string, newStore: any) => void;
  stores: any;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
export const useUppyStore = create<UppyStoreState>((set) => ({
  createStore: (storeId) =>
    set((state) => ({
      ...state,
      stores: {
        ...state.stores,
        [storeId]: {},
      },
    })),
  updateStore: (storeId, patch) =>
    set((state) => ({
      ...state,
      stores: {
        ...state.stores,
        [storeId]: {
          ...state.stores[storeId],
          ...patch,
        },
      },
    })),
  stores: {},
}));

// https://uppy.io/docs/stores/#Implementing-Stores
export function customUppyStore(storeId: string) {
  useUppyStore.getState().createStore(storeId);

  return {
    getState: () => {
      return useUppyStore.getState().stores[storeId];
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setState: (patch: any) => {
      useUppyStore.getState().updateStore(storeId, patch);
    },
    subscribe: () => {
      return () => useUppyStore.destroy();
    },
  };
}
