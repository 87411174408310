import React, { ReactNode } from 'react';

import { AssemblyCurrency } from '../../../../interfaces/assembly';
import AssemblyCurrencyIcon from '../../../atoms/AssemblyCurrencyIcon';
import Body from '../../../atoms/Body';
import FlowsBaseInputBlock from '../FlowsBaseInputBlock';
import { StyledTextField, StyledAnchor } from './styles';
import ThemeV2 from '../../../../componentsV2/theme';
import {
  SELECT_ATLEAST_ONE_PERSON_WARNING,
  SELECT_PEOPLE,
} from '../../../../languages/en/flows';

export interface FlowsTrophiesInputBlockProps {
  assemblyCurrency: AssemblyCurrency;
  fieldError?: string;
  info?: ReactNode;
  monthlyAllowance: number;
  onChange: (value: string) => void;
  remainingAllowance: number;
  splitBy: number;
  title: string;
  value: string;
  maxPoints: number;
  description?: string;
  isDependentBlockEmpty?: boolean;
  onEditPeopleClick?: () => void;
}

const FlowsTrophiesInputBlock = ({
  assemblyCurrency,
  fieldError,
  info,
  monthlyAllowance,
  onChange,
  remainingAllowance,
  splitBy,
  title,
  value,
  description,
  maxPoints,
  onEditPeopleClick,
  isDependentBlockEmpty,
}: FlowsTrophiesInputBlockProps) => {
  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const num = parseInt(newValue, 10);
    // Check for only numbers
    const numRegex = /^[0-9]*$/;
    if (numRegex.test(newValue)) {
      const hasExceededAvailablePoints = maxPoints < num;
      if (hasExceededAvailablePoints) {
        const newTrophiesInputValue = Math.min(
          Math.floor(maxPoints * splitBy),
          maxPoints,
        );
        const newFieldValue =
          newTrophiesInputValue === 0 ? '' : String(newTrophiesInputValue);
        onChange(newFieldValue);
      } else {
        onChange(newValue);
      }
    }
  };

  const renderAssemblyCurrencyIcon = (size = '14px') => (
    <AssemblyCurrencyIcon
      assemblyCurrency={assemblyCurrency}
      currencyName={assemblyCurrency.pluralName}
      id="assemblyCurrency"
      size={size}
    />
  );

  const renderLabel = () => {
    if (maxPoints === 0) {
      return (
        <>
          You don&apos;t have enough {renderAssemblyCurrencyIcon()}
          {assemblyCurrency.pluralName}
        </>
      );
    }

    return (
      <>
        Add up to {maxPoints} {assemblyCurrency.pluralName}
        {splitBy > 1 ? ' Each' : ''}
      </>
    );
  };

  const renderHelperText = () => {
    if (!value) return undefined;

    const pointsPerPerson = parseInt(value, 10) * splitBy;
    const percentageOfMonthlyAllowance = Math.ceil(
      (pointsPerPerson / monthlyAllowance) * 100,
    );
    const percentageOfMonthlyAllowancePerPerson = Math.ceil(
      percentageOfMonthlyAllowance / splitBy,
    );

    if (percentageOfMonthlyAllowancePerPerson <= 25) {
      return (
        <Body variant="body4Medium" className="focused-geekBlue6" color="gray7">
          You’re giving {percentageOfMonthlyAllowance}% of your total monthly
          allowance.
        </Body>
      );
    }
    if (percentageOfMonthlyAllowancePerPerson <= 50) {
      return (
        <Body variant="body4Medium" className="focused-geekBlue6" color="gray7">
          Woah, big spender, that is a lot of points. You’re giving{' '}
          {percentageOfMonthlyAllowance}% of your total monthly allowance.
        </Body>
      );
    }

    const halfOfMonthlyAllowance = monthlyAllowance / 2;
    return (
      <Body variant="body4Medium" color="volcano6">
        Don’t spend it all in one place! We recommend no more than{' '}
        {renderAssemblyCurrencyIcon('10px')}
        {halfOfMonthlyAllowance}. You’re giving {percentageOfMonthlyAllowance}%
        of your total monthly allowance.
      </Body>
    );
  };

  const SubDescription = (
    <>
      Your remaining allowance is {renderAssemblyCurrencyIcon()}
      <Body variant="body3Bold" color={ThemeV2.palette.gray7} inline>
        {remainingAllowance}{' '}
      </Body>
      of {renderAssemblyCurrencyIcon()}
      <Body variant="body3Bold" color={ThemeV2.palette.gray7} inline>
        {monthlyAllowance}{' '}
      </Body>
      per month.
    </>
  );

  const isDisabled = maxPoints === 0;

  const warning = (
    <>
      {SELECT_ATLEAST_ONE_PERSON_WARNING(assemblyCurrency.pluralName)}{' '}
      <StyledAnchor onClick={onEditPeopleClick} role="button" tabIndex={0}>
        {SELECT_PEOPLE}
      </StyledAnchor>
    </>
  );

  return (
    <FlowsBaseInputBlock
      fieldError={fieldError}
      hasError={!!fieldError}
      isRequired={false}
      description={description}
      subDescription={SubDescription}
      title={title}
      info={info}
      warning={isDependentBlockEmpty && value ? warning : null}
    >
      <StyledTextField
        autoFocus
        disabled={isDisabled}
        helperText={renderHelperText()}
        onChange={handleValueChange}
        label={renderLabel()}
        size="large"
        value={value}
        prefix={
          !isDisabled &&
          value && (
            <AssemblyCurrencyIcon
              size="14px"
              assemblyCurrency={assemblyCurrency}
              currencyName={assemblyCurrency.name}
              id="trophy-selector-icon"
            />
          )
        }
      />
    </FlowsBaseInputBlock>
  );
};

export default FlowsTrophiesInputBlock;
