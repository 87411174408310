import styled from 'styled-components';

import Avatar from '../../atoms/Avatar';
import Body from '../../atoms/Body';
import ThemeV2 from '../../../componentsV2/theme';

export const StyledNameBody = styled(Body)``;

export const Wrapper = styled.li<{
  isCurrentUser: boolean;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  width: 100%;
  margin: 4px 0px;
  height: 32px;
  background-color: ${({ isCurrentUser }) =>
    isCurrentUser ? ThemeV2.palette.geekBlue1 : ThemeV2.palette.gray1};

  &:hover {
    background-color: ${ThemeV2.palette.geekBlue1};
    cursor: pointer;
  }

  &:hover ${StyledNameBody} {
    color: ${ThemeV2.palette.geekBlue6};
  }
`;

export const LeftWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const RightWrapper = styled.div`
  margin-right: 8px;
`;

export const StyledAvatar = styled(Avatar)`
  margin: 4px 8px 4px 10px;
`;
