import React from 'react';

import NotificationSettingsAccordion from '../../atomic/organism/NotificationSettingsAccordion';
import useNotificationsSettings from './useNotificationSettings';

const NotificationSettingsController = () => {
  const {
    preferences,
    onFlowItemOptionsToggle,
    handleToggle,
    isError,
    isLoading,
  } = useNotificationsSettings();

  return (
    <NotificationSettingsAccordion
      preferences={preferences}
      onFlowItemOptionsToggle={onFlowItemOptionsToggle}
      handleToggle={handleToggle}
      isError={isError}
      isLoading={isLoading}
    />
  );
};

export default NotificationSettingsController;
