import React from 'react';

import MemberListEntryLoader from '../../molecules/MembersListEntry/Loader';

const MembersListLoader = () => (
  <>
    <MemberListEntryLoader />
    <MemberListEntryLoader />
    <MemberListEntryLoader />
    <MemberListEntryLoader />
    <MemberListEntryLoader />
    <MemberListEntryLoader />
    <MemberListEntryLoader />
    <MemberListEntryLoader />
    <MemberListEntryLoader />
    <MemberListEntryLoader />
  </>
);

export default MembersListLoader;
