import styled from 'styled-components';
import BuilderPageHeader from '../../../atomic/molecules/FormBuilderPageHeader';

export const StyledBuilderPageHeader = styled(BuilderPageHeader)<{
  isRightAsideOpen: boolean;
}>`
  padding-right: ${({ isRightAsideOpen }) =>
    isRightAsideOpen ? '16px' : '40px'};
  transition: all 0.5s;
`;
