import React from 'react';

import { FlowInsightsWrapper } from './styles';
import useFlowsInsightsRightDrawerController from './useFlowsInsightsRightDrawerController';
import FlowInsightsFilter from '../../../atomic/organism/RightDrawerFilter/FlowInsightsFilter';

const FlowsInsightsRightDrawerController = () => {
  const { models, operations } = useFlowsInsightsRightDrawerController();

  const {
    hasShortcut,
    dateFilterProps,
    responsesFilter,
    departmentsFilter,
    participantFilter,
    managerStatusFilter,
    isFilterButtonEnabled,
    occurrenceStartDateFilter,
  } = models;

  const { handleOnClear, handleOnSubmit, toggleRightAsideOpen } = operations;

  return (
    <FlowInsightsWrapper>
      <FlowInsightsFilter
        onClear={handleOnClear}
        hasShortcut={hasShortcut}
        onSubmit={handleOnSubmit}
        toggleRightAsideOpen={toggleRightAsideOpen}
        dateFilterProps={dateFilterProps}
        responsesFilter={responsesFilter}
        participantFilter={participantFilter}
        departmentsFilter={departmentsFilter}
        managerStatusFilter={managerStatusFilter}
        isFilterButtonEnabled={isFilterButtonEnabled}
        occurrenceStartDateFilter={occurrenceStartDateFilter}
      />
    </FlowInsightsWrapper>
  );
};

export default FlowsInsightsRightDrawerController;
