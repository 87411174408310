import React from 'react';

import Body from '../../atoms/Body';

import ThemeV2 from '../../../componentsV2/theme';
import {
  CONFIGURE_SETTINGS,
  REMINDER_EXTENDS_ERROR,
} from '../../../languages/en/flows/builder';
import {
  DropdownColor,
  DropdownVariants,
} from '../../molecules/Dropdown_V2/interfaces';

import {
  StyledDeadlineConfigurationFlex,
  StyledDropdown,
  StyledInput,
} from './styles';

import { FlowDeadlineProps } from './types';
import { singularizeWord } from '../../../Utils/text';
import { supportedWholeNumberKeyCodes } from '../../../constants/flowsBuilder';

const FlowDeadline = (props: FlowDeadlineProps) => {
  const {
    deadlineConfiguration,
    onDeadlineConfigurationChange,
    deadlineConfigurationTimeOptions,
    deadlineConfigurationTIme,
    onDeadlineConfigurationTImeChange,
    recurrenceError = false,
    reminderExtendsError = false,
    recurrenceErrorMessage,
    onDeadlineConfigurationBlur,
    onDeadlineConfigurationTimeFocus,
  } = props;

  const handleOnChange = (e: { target: { value: number } }) => {
    onDeadlineConfigurationChange(e.target.value < 0 ? 0 : e.target.value);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (!supportedWholeNumberKeyCodes.includes(event.key)) {
      event.preventDefault();
    }
  };

  return (
    <>
      <Body variant="body1Medium" color={ThemeV2.palette.gray9}>
        {CONFIGURE_SETTINGS.DEADLINE_CONFIGURATION_HEADING_TEXT}
      </Body>
      <Body variant="body3">
        {CONFIGURE_SETTINGS.DEADLINE_CONFIGURATION_BODY_TEXT}
      </Body>
      <StyledDeadlineConfigurationFlex>
        <StyledInput
          type="textV3_number"
          value={deadlineConfiguration}
          onChange={handleOnChange}
          onKeyPress={handleKeyPress}
          hasError={recurrenceError || reminderExtendsError}
          onInputFocus={onDeadlineConfigurationBlur}
          name="flowDeadlineInput"
        />
        <StyledDropdown
          color={'secondary' as DropdownColor}
          menuItems={deadlineConfigurationTimeOptions}
          onItemClick={onDeadlineConfigurationTImeChange}
          value={deadlineConfigurationTIme}
          variant={'text' as DropdownVariants}
          getItemLabel={(label: string) =>
            deadlineConfiguration > 1 ? label : singularizeWord(label)
          }
          onFocus={onDeadlineConfigurationTimeFocus}
        />
      </StyledDeadlineConfigurationFlex>
      {recurrenceError && (
        <Body variant="body4" color="red6" data-testid="dueDateRecurrenceError">
          {recurrenceErrorMessage}
        </Body>
      )}
      {!recurrenceError && reminderExtendsError && (
        <Body variant="body4" color="red6" data-testid="dueDateError">
          {REMINDER_EXTENDS_ERROR}
        </Body>
      )}
    </>
  );
};

export default FlowDeadline;
