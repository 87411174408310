import React, { useMemo } from 'react';

import Hidden from '@material-ui/core/Hidden';
import {
  FAILED_TO_LOAD_MEMBER_COUNT,
  MEMBERS,
} from '../../../languages/en/home';
import { getFullName } from '../../../Utils/user';

import Body from '../../atoms/Body';
import Avatar from '../../atoms/Avatar';
import SVGIcon from '../../atoms/SVGIcon';
import { Flex } from '../../../Utils/styles/display';
import MembersCountLoader from './MembersCountLoader';
import MainFeedLinkButton from '../MainFeedLinkButton';
import { HeaderType, PageHeaderProps } from './interfaces';
import { formatNumberWithCommas } from '../../../Utils/formatting';

import {
  LogoContainer,
  StyledButtonContainer,
  StyledFlex,
  StyledHeading,
  StyledLogo,
  StyledPageHeaderMainContent,
} from './styles';
import RouteTabs from '../../organism/RouteTabs';
import Button from '../../atoms/Button';
import { NOTEBOOK_HEADER_TITLE } from '../../../languages/en/header';

const PageHeader = (props: PageHeaderProps) => {
  const {
    headerType,
    title = '',
    imageUrl,
    imageName,
    membersCount,
    profile = null,
    subTitle = '',
    icon = '',
    userId,
    showMainFeedLinkInOthers,
    hasBottomBorder = true,
    isMembersCountError,
    isMembersCountLoading,
    buttonText,
    pageTabs,
    tabRouteBase,
    onButtonClick,
    isBeta,
    onClick,
  } = props;

  const Home = useMemo(
    () => (
      <>
        <LogoContainer>
          <StyledLogo src={imageUrl} alt={imageName} />
        </LogoContainer>

        <StyledPageHeaderMainContent>
          <StyledHeading variant="h6">{title}</StyledHeading>
          {isMembersCountError && (
            <Body variant="body3" color="gray7">
              {FAILED_TO_LOAD_MEMBER_COUNT}
            </Body>
          )}
          {isMembersCountLoading && <MembersCountLoader />}
          {!isMembersCountLoading && !isMembersCountError && (
            <Body variant="body3" color="gray7">
              {membersCount && formatNumberWithCommas(membersCount)} {MEMBERS}
            </Body>
          )}
        </StyledPageHeaderMainContent>
      </>
    ),
    [
      imageName,
      imageUrl,
      isMembersCountError,
      isMembersCountLoading,
      membersCount,
      title,
    ],
  );

  const Flows = useMemo(
    () => (
      <>
        <SVGIcon icon={icon} size="48px" />
        <StyledPageHeaderMainContent>
          <Flex>
            <StyledHeading variant="h6">{title}</StyledHeading>
            {isBeta && <SVGIcon icon="beta" size="32px" />}
          </Flex>
          <Hidden only={['xs']}>
            <Body variant="body3" color="gray7">
              {subTitle}
            </Body>
          </Hidden>
        </StyledPageHeaderMainContent>
        <Hidden only={['xs']}>
          {onButtonClick && (
            <StyledButtonContainer>
              <Button
                icon="union"
                size="medium"
                variant="contained"
                dataTestId="request-flow"
                onClick={() => onButtonClick()}
              >
                {buttonText}
              </Button>
            </StyledButtonContainer>
          )}
        </Hidden>
      </>
    ),
    [icon, title, isBeta, subTitle, onButtonClick, buttonText],
  );

  const Profile = useMemo(() => {
    if (profile) {
      const fullName = getFullName(profile);
      return (
        <>
          <Avatar
            userId={userId}
            img={profile.imageUrl}
            name={fullName}
            size="48px"
            fontSize="20px"
          />
          <StyledPageHeaderMainContent>
            <StyledHeading variant="h6">{fullName}</StyledHeading>
            <Body variant="body3" color="gray7">
              @{profile.username}{' '}
              {profile.departments && `• ${profile.departments}`}
            </Body>
          </StyledPageHeaderMainContent>
          <MainFeedLinkButton onClick={onClick} />
        </>
      );
    }

    return null;
  }, [onClick, profile, userId]);

  const Others = useMemo(
    () => (
      <>
        <SVGIcon icon={icon} size="48px" />
        <StyledPageHeaderMainContent>
          <StyledHeading variant="h6">{title}</StyledHeading>
          <Body variant="body3" color="gray7">
            {subTitle}
          </Body>
        </StyledPageHeaderMainContent>
        {showMainFeedLinkInOthers && <MainFeedLinkButton />}
      </>
    ),
    [icon, showMainFeedLinkInOthers, subTitle, title],
  );

  const renderHeader = useMemo(() => {
    switch (headerType) {
      case HeaderType.MAIN_FEED:
        return Home;
      case HeaderType.PROFILE:
        return Profile;
      case HeaderType.FLOWS:
        return Flows;

      default:
        return Others;
    }
  }, [Home, Others, Profile, headerType, Flows]);

  return (
    <>
      <StyledFlex
        borderBottom={hasBottomBorder}
        hasPaddingLeft={title === NOTEBOOK_HEADER_TITLE}
        tabRouteBase={tabRouteBase}
      >
        {renderHeader}
      </StyledFlex>
      {pageTabs && tabRouteBase && (
        <RouteTabs routeTabButtons={pageTabs} urlBase={tabRouteBase} />
      )}
    </>
  );
};

export default PageHeader;
