import styled from 'styled-components';

import Avatar from '../../atoms/Avatar';
import ThemeV2 from '../../../componentsV2/theme';

export const Wrapper = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  border-radius: 4px;
  height: 48px;
  max-height: 48px;
  background-color: ${ThemeV2.palette.geekBlue1};

  &:hover {
    cursor: pointer;
  }
`;

export const LeftWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const RightWrapper = styled.div`
  margin-right: 8px;
`;

export const StyledAvatar = styled(Avatar)`
  margin: 8px;
`;
