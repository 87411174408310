import React, { memo } from 'react';

import CommentHeader from '../CommentHeader';
import FeedPostBody from '../FeedPostBody';
import ReactionBar from '../ReactionsBar';
import { ToggleButtonVariant } from '../../atoms/ToggleButton/interface';
import { ButtonSize } from '../../atoms/IconButton_V2/interface';
import { dropdownOptions as commentsDropdownOptions } from '../CommentHeader/data';

import {
  CommentWrapper,
  StyledCommentBody,
  StyledReactionBarContainer,
} from './styles';
import {
  FeedCommentFromAPI,
  MemberInteractionType,
  Reaction,
} from '../../../interfaces/Feed';
import { AssemblyCurrency } from '../../../interfaces/assembly';
import { getFormattedMessage } from '../../../Utils/message';
import { serializeReactions } from '../../../Utils/serializers/feed';
import { EmojiData } from 'emoji-mart';
import { getDropDownOptions, isGifURLPresent } from '../../../Utils/posts';

interface NewCommentProps {
  isDummy?: boolean;
  comment: FeedCommentFromAPI;
  assemblyCurrency: AssemblyCurrency;
  currentUserId: string;
  isCurrentUserAdmin: boolean;
  onMemberClick: (person: MemberInteractionType) => void;
  onCopyLink: (commentId: string) => void;
  onDeleteComment: (
    commentId: string,
    commentHasTrophies: boolean,
    deleteCommentCarrotOption: string,
  ) => void;
  onReactionToggleClick: (reaction: Reaction, contentId: string) => void;
  onEmoticonSelect: (
    reactions: Reaction[],
    emoji: EmojiData,
    contentId: string,
  ) => void;
  canDelete: boolean;
}

const Comment = ({
  comment,
  isDummy = false,
  assemblyCurrency,
  currentUserId,
  isCurrentUserAdmin,
  onMemberClick,
  onDeleteComment,
  onCopyLink,
  onReactionToggleClick,
  onEmoticonSelect,
  canDelete,
}: NewCommentProps) => {
  const {
    fromMember,
    pointsEach,
    createdAt,
    gifURL,
    imageURL,
    commentID,
    message,
    taggedUsers,
  } = comment;
  const messageText = getFormattedMessage(message, taggedUsers, onMemberClick);
  const reactions = serializeReactions(comment, currentUserId);

  const handleEmoticonSelect = (emoji: EmojiData, contentId: string) =>
    onEmoticonSelect(reactions, emoji, contentId);

  const commentDropdownOptions = getDropDownOptions(
    commentsDropdownOptions,
    isCurrentUserAdmin,
    isGifURLPresent(gifURL),
    fromMember.memberID === currentUserId || canDelete,
  );

  return (
    <CommentWrapper
      data-testid="comment"
      isLoading={commentID === 'OPTIMISTIC' || false}
    >
      <CommentHeader
        commentId={commentID}
        from={fromMember}
        isDummy={isDummy}
        trophies={pointsEach || undefined}
        assemblyCurrency={assemblyCurrency}
        createdAt={createdAt}
        currentUserId={currentUserId}
        avatarSize="40px"
        onClickUserName={onMemberClick}
        dropdownOptions={commentDropdownOptions}
        hasTrophies={pointsEach > 0}
        onCopyLink={onCopyLink}
        onDeleteCommentClick={onDeleteComment}
        isLoading={commentID === 'OPTIMISTIC'}
      />
      <StyledCommentBody>
        <FeedPostBody messageText={messageText} image={gifURL || imageURL} />
        <StyledReactionBarContainer>
          <ReactionBar
            contentId={commentID}
            currentUserId={currentUserId}
            reactions={reactions}
            onReactionToggleClick={onReactionToggleClick}
            onEmoticonSelect={handleEmoticonSelect}
            toggleButtonVariant={ToggleButtonVariant.Text}
            toggleButtonIconSize="20px"
            reactionButtonSize={ButtonSize.Small}
          />
        </StyledReactionBarContainer>
      </StyledCommentBody>
    </CommentWrapper>
  );
};

const MemoizedComment = memo(Comment);
MemoizedComment.displayName = 'Comment';

export default MemoizedComment;
