import styled from 'styled-components';

export const FeedPostReactionsRoot = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0px 16px;
`;
FeedPostReactionsRoot.displayName = 'FeedPostReactionsRoot';
