import styled, { css } from 'styled-components';
import { StyledIconHolderProps } from './interface';

export const IconHolder = styled.div<StyledIconHolderProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ size }) => size};
  width: ${({ size }) => size};
  min-height: ${({ size }) => size};
  min-width: ${({ size }) => size};
  background: ${({ color }) => color};
  border-radius: 4px;
  border: ${({ borderColor }) =>
    borderColor ? `1px solid ${borderColor}` : 'initial'};

  ${({ isRounded }) =>
    isRounded &&
    css`
      border-radius: 50%;
    `}
`;

export const StyledEmoticon = styled.div<{
  size: string;
}>`
  font-size: ${({ size }) => size};
`;
