import styled from 'styled-components';
import Button from '../../atoms/Button';
import Heading from '../../atoms/Heading';
import ThemeV2 from '../../../componentsV2/theme';
import ToggleButton from '../../atoms/ToggleButton';
import { Flex } from '../../../Utils/styles/display';
import NewTextfield from '../NewTextfield';

export const StyledToggleButtonStyle = styled(ToggleButton)`
  color: ${ThemeV2.palette.gray6};
  padding: 0;
  background-color: ${ThemeV2.palette.gray1};
  justify-content: center;
  &:not(:disabled):hover {
    background-color: ${ThemeV2.palette.gray2};
  }
  > * {
    margin-bottom: 0px;
    line-height: 30px;
  }
`;

export const StyledHeader = styled(Heading)`
  padding: 8px 16px;
  border-radius: 4px;
  border: 1px solid ${ThemeV2.palette.gray6};
`;

export const StyledTitleTextField = styled(NewTextfield)`
  max-width: 375px;
  width: 100%;
  height: 48px;
  background: ${ThemeV2.palette.gray1};
  flex-direction: unset;
  position: relative;

  .MuiInputBase-root input {
    border: none;
    height: 28px;
    font-size: 20px;
    line-height: 28px;
    color: ${ThemeV2.palette.gray9};
    font-weight: ${ThemeV2.typography.fontWeightBold};
    font-family: ${ThemeV2.typography.adminFontFamily};

    &::placeholder {
      opacity: 1;
      color: ${ThemeV2.palette.gray6};
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: ${ThemeV2.palette.gray6};
  }

  .MuiFormHelperText-root.Mui-error {
    top: 48px;
    position: absolute;
  }
`;

export const StyledFlex = styled(Flex)`
  flex: 1;
  width: 100%;
  margin-left: 8px;
`;

export const StyledFullWidthFlex = styled(Flex)`
  width: 100%;
`;

export const FormBuilderPageHeaderRoot = styled(Flex)`
  width: 100%;
  padding: 16px;
  background: ${ThemeV2.palette.gray1};
`;

export const EditButton = styled(Button)`
  path {
    fill: ${ThemeV2.palette.geekBlue6};
  }

  &:disabled {
    path {
      fill: ${ThemeV2.palette.gray6};
    }
  }
`;
