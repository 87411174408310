import React from 'react';
import styled from 'styled-components';

import { Flex } from '../../../Utils/styles/display';
import SVGIcon from '../../atoms/SVGIcon';
import Body from '../../atoms/Body';
import ToolTip from '../ToolTipV2';
import IconButton from '../../atoms/IconButton_V2';

import ThemeV2 from '../../../componentsV2/theme';
import CountIndicator from '../../atoms/CountIndicator';
import { ButtonSize } from '../../atoms/IconButton_V2/interface';

interface MenuProps {
  prefix?: string;
  icon: string;
  text: string;
  count?: number;
  showCount?: boolean;
  rotate?: boolean;
  disabled?: boolean;
  isExpanded?: boolean;
  className?: string;
  showAddButton?: boolean;
  addButtonText?: string;
  onAddButtonClick?: () => void;
}

const StyledIconButtonWrapper = styled.div`
  display: none;
  transition: all 0.3s;
`;

const StyledMenu = styled(Flex)<{ disabled?: boolean }>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  min-height: 24px;
  justify-content: space-between;
  transition: all o.3s;
  &:hover {
    ${StyledIconButtonWrapper} {
      display: block;
    }
  }
`;

const StyledSVGIcon = styled(SVGIcon)`
  margin: 0 4px;
`;

const StyledPrefixIcon = styled(SVGIcon)`
  margin-left: 24px;
  display: block;
`;

const StyledCountIndicator = styled(CountIndicator)`
  height: 20px;
  margin-top: 3px;
  margin-left: 4px;
`;

const Menu = (props: MenuProps) => {
  const {
    prefix,
    icon,
    text,
    count,
    showCount,
    rotate,
    isExpanded,
    showAddButton = false,
    addButtonText,
    onAddButtonClick,
    className,
    disabled,
  } = props;

  return (
    <StyledMenu className={className} disabled={disabled}>
      <Flex>
        {prefix && !isExpanded && (
          <div>
            <StyledPrefixIcon icon={prefix} size="24px" />
          </div>
        )}
        {isExpanded && (
          <div style={{ display: 'flex' }}>
            <StyledSVGIcon
              icon={icon}
              size="24px"
              rotate={rotate ? 180 : 0}
              color={ThemeV2.palette.gray8}
            />
            <Body>{text}</Body>
            {showCount && count !== 0 && count && (
              <StyledCountIndicator>
                <Body variant="body3" color="gray1">
                  {count}
                </Body>
              </StyledCountIndicator>
            )}
          </div>
        )}
      </Flex>
      <StyledIconButtonWrapper>
        {showAddButton && addButtonText && (
          <ToolTip
            toolTipComponent={
              <IconButton
                icon={'union-rounded'}
                color="textWithBackground"
                size={ButtonSize.Small}
                onClick={(e) => {
                  if (onAddButtonClick) {
                    onAddButtonClick();
                  }
                  e.stopPropagation();
                }}
                dataTestId={'addButton.dataTestId'}
              />
            }
            position="bottom"
            positionStrategy="fixed"
          >
            {addButtonText}
          </ToolTip>
        )}
      </StyledIconButtonWrapper>
    </StyledMenu>
  );
};

export default Menu;
