import {
  COPY_POST_LINK,
  DELETE_POST_AND_ANSWER,
} from '../../../languages/en/home';

const copyLinkItem = {
  id: 'copy-link',
  prefixIcon: 'Link',
  isSelected: false,
  value: COPY_POST_LINK,
};

const deleteItem = {
  id: 'delete',
  prefixIcon: 'delete',
  deleteItem: true,
  isSelected: false,
  value: DELETE_POST_AND_ANSWER,
};

export const menuItemsWithCopyLink = [
  {
    id: 'feed-post-header-options',
    items: [copyLinkItem],
  },
];

export const menuItemsWithCopyLinkAndDeletePost = [
  {
    id: 'feed-post-header-options',
    items: [copyLinkItem, deleteItem],
  },
];
