import {
  CULTURE,
  GIFT_CARDS,
  CHARITIES,
  MY_REWARDS,
  AXOMO_SWAGS,
} from '../../../languages/en/rewards';

export enum PlanType {
  FREE = 'free',
  PAID = 'paid',
}

export const rewardsTabButtons = [
  {
    id: 'gift-cards',
    label: GIFT_CARDS,
    planType: PlanType.PAID,
  },
  {
    id: 'swag',
    label: AXOMO_SWAGS,
    planType: PlanType.PAID,
  },
  {
    id: 'charities',
    label: CHARITIES,
    planType: PlanType.PAID,
  },
  {
    id: 'culture',
    label: CULTURE,
    planType: PlanType.FREE,
  },
  {
    id: 'my-rewards',
    label: MY_REWARDS,
    planType: PlanType.FREE,
  },
];
