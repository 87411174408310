import {
  useQuery,
  useMutation,
  InfiniteData,
  useQueryClient,
  useInfiniteQuery,
} from 'react-query';

import produce from 'immer';

import {
  GetFlowDetailsResponse,
  PaginationResponse,
  GetFlowsActivityUpdatesResponse,
  GetFlowFileResponse,
  GetFlowAuthorizationResponse,
} from '../interfaces';
import {
  PostFlowFeedPayload,
  UpdateReactionAction,
} from '../../../interfaces/Feed';
import {
  FileOperationsRequest,
  FlowFeedResponse,
  ReactionRequestPayload,
} from './interfaces';

import { makeAPICall, makeAPICallWithDataReturn } from '../../utils';

import {
  DELETE_FLOW_RESPONSE,
  GET_FLOWS_ACTIVITY,
  GET_FLOW_DETAILS,
  GET_FLOW_FEED,
  GET_FLOW_POST_DOWNLOAD_URL,
  GET_FLOW_RESPONSE,
  GET_MAIN_FEED,
  GET_PROFILE_FEED,
  GET_PROFILE_INFO,
  UPDATE_FLOW_POST_REACTION,
  VERIFY_FLOW_AUTHORIZATION,
} from '../../../constants/endpoints';

import {
  findInPaginatedFlowFeedData,
  updatePostReactionsForFlowFeedInDraft,
} from './utils';
import { AxiosError } from 'axios';

export const useGetFlowFeedsQuery = ({
  flowId,
  flowFeedsSort,
  filter = {},
}: PostFlowFeedPayload) => {
  const queryClient = useQueryClient();
  const cachedActivityData =
    queryClient.getQueryData<GetFlowsActivityUpdatesResponse>(
      GET_FLOWS_ACTIVITY,
    );
  return useInfiniteQuery<PaginationResponse<FlowFeedResponse>>(
    [GET_FLOW_FEED, flowFeedsSort, flowId, filter],
    ({ pageParam = '' }) =>
      makeAPICallWithDataReturn(
        GET_FLOW_FEED,
        {
          cursor: pageParam,
          sortBy: flowFeedsSort,
          filter,
        },
        undefined,
        { flowId },
      ),
    {
      staleTime: Infinity,
      refetchOnMount: 'always',
      getNextPageParam: (lastPage) =>
        lastPage.metadata.pagination?.cursor?.next || undefined,
      getPreviousPageParam: (lastPage) => {
        return lastPage.metadata.pagination.cursor.previous;
      },
      enabled: Boolean(flowId.toLowerCase() === 'editor' ? false : flowId),
      onSuccess: () => {
        if (cachedActivityData) {
          const updatedActivityUpdatesCache = cachedActivityData.data.map(
            (item) => {
              if (item.flowId === flowId) {
                return {
                  ...item,
                  unreadMentionsCount: 0,
                  hasUnreadPost: false,
                };
              }
              return item;
            },
          );

          queryClient.setQueryData(GET_FLOWS_ACTIVITY, {
            data: updatedActivityUpdatesCache,
          });
        }
      },
    },
  );
};

// ToDo: Arun - Handle Error in mutation? We need to show error message to the user - as discussed in FE Call.
export const useUpdateFlowReactionMutation = ({
  flowId,
  flowFeedsSort,
  filter,
}: PostFlowFeedPayload) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ payload, responseId, action }: ReactionRequestPayload) => {
      return makeAPICall(
        UPDATE_FLOW_POST_REACTION,
        payload,
        {},
        { flowId, responseId, action },
      );
    },
    {
      onMutate: ({
        payload,
        responseId,
        action,
        user,
      }: ReactionRequestPayload) => {
        const queryKey = [GET_FLOW_FEED, flowFeedsSort, flowId, filter];
        const flowFeed = queryClient.getQueryData(queryKey);
        const previousData = flowFeed as InfiniteData<
          PaginationResponse<FlowFeedResponse>
        >;
        const updatedData = produce(previousData, (draft) => {
          const updatedPost = findInPaginatedFlowFeedData(
            draft.pages,
            responseId,
          );
          if (updatedPost) {
            updatePostReactionsForFlowFeedInDraft(
              updatedPost,
              payload,
              action === 'set'
                ? UpdateReactionAction.SET
                : UpdateReactionAction.UNSET,
              user,
            );
          }
        });
        queryClient.setQueryData(queryKey, updatedData);
        return { previousData };
      },
    },
  );
};

export const useUpdateSingleFlowReactionMutation = (flowId: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ payload, responseId, action }: ReactionRequestPayload) => {
      return makeAPICall(
        UPDATE_FLOW_POST_REACTION,
        payload,
        {},
        { flowId, responseId, action },
      );
    },
    {
      onMutate: ({
        payload,
        responseId,
        action,
        user,
      }: ReactionRequestPayload) => {
        const queryKey = [GET_FLOW_RESPONSE, flowId, responseId];
        const flowFeed = queryClient.getQueryData(queryKey);
        const previousData = flowFeed as FlowFeedResponse;
        const updatedData = produce(previousData, (post) => {
          updatePostReactionsForFlowFeedInDraft(
            post,
            payload,
            action === 'set'
              ? UpdateReactionAction.SET
              : UpdateReactionAction.UNSET,
            user,
          );
        });
        queryClient.setQueryData(queryKey, updatedData);
        return { previousData };
      },
    },
  );
};

export const useDeleteFlowPost = (
  onSuccessCallback?: () => void,
  onErrorCallback?: () => void,
) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({
      flowId,
      responseId,
      returnPoints,
    }: {
      flowId: string;
      responseId: string;
      returnPoints: boolean;
    }) => {
      return makeAPICall(
        DELETE_FLOW_RESPONSE,
        undefined,
        { returnPoints },
        {
          flowId,
          responseId,
        },
      );
    },
    {
      onSuccess: (flowId, responseId, returnPoints) => {
        queryClient.invalidateQueries(GET_MAIN_FEED);
        queryClient.invalidateQueries(GET_PROFILE_FEED);
        queryClient.invalidateQueries(GET_FLOW_FEED);
        queryClient.invalidateQueries([GET_FLOW_RESPONSE, flowId, responseId]);
        if (returnPoints) {
          queryClient.refetchQueries(GET_PROFILE_INFO);
        }
        if (onSuccessCallback) {
          onSuccessCallback();
        }
      },
      onError: () => {
        if (onErrorCallback) {
          onErrorCallback();
        }
      },
    },
  );
};

export const useFetchFlowDetailsQuery = (
  flowId = '',
  request = '',
  enabled = true,
  onErrorCallback?: (error: AxiosError) => void,
) => {
  return useQuery<GetFlowDetailsResponse>(
    [GET_FLOW_DETAILS, flowId, request],
    () =>
      makeAPICall(
        GET_FLOW_DETAILS,
        undefined,
        { type: request },
        {
          flowId,
        },
      ),
    {
      onError: (error: unknown) => {
        if (onErrorCallback) {
          onErrorCallback(error as AxiosError);
        }
      },
      retry: false,
      refetchOnMount: !request,
      staleTime: Infinity,
      enabled:
        enabled && Boolean(flowId.toLowerCase() === 'editor' ? false : flowId),
    },
  );
};

export const useFetchFlowFileQuery = (
  request: FileOperationsRequest,
  fileValue: boolean | undefined = undefined,
) => {
  return useQuery<GetFlowFileResponse>(
    [GET_FLOW_POST_DOWNLOAD_URL, request],
    () =>
      makeAPICall(GET_FLOW_POST_DOWNLOAD_URL, undefined, undefined, {
        ...request,
      }),
    {
      enabled: Boolean(fileValue),
    },
  );
};

export const useVerifyFlowAuthorization = (
  flowId: string,
  enabled = true,
  onError?: (error: unknown) => void,
) => {
  return useQuery<GetFlowAuthorizationResponse>(
    [VERIFY_FLOW_AUTHORIZATION, flowId],
    () =>
      makeAPICall(VERIFY_FLOW_AUTHORIZATION, undefined, undefined, {
        flowId,
      }),
    {
      retry: false,
      enabled,
      onError,
    },
  );
};
