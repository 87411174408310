import styled from 'styled-components';
import ContentLoader from 'react-content-loader';

import ThemeV2 from '../../../componentsV2/theme';
import BasicModalTemplate from '../../../atomic/templates/BasicModalTemplate';
import Image from '../../../atomic/atoms/Image';
import Dropdown from '../../../atomic/molecules/Dropdown_V2';
import Body from '../../../atomic/atoms/Body';
import InfoAlert from '../../../atomic/molecules/InfoAlert';

export const FeedsContainer = styled.main`
  max-width: 476px;
  margin: auto;
`;

export const StyledActionBarWrapper = styled.div`
  margin-bottom: 20px;
`;

export const FlowFeedPostWrapper = styled.div`
  max-width: 474px;
  background-color: white;
  border: 1px solid ${ThemeV2.palette.gray5};
  border-radius: 4px;
  margin: 0 auto 16px;
`;

export const StyledContentLoader = styled(ContentLoader)`
  width: 100%;
  height: 100%;
`;

export const FlowCreatedModal = styled(BasicModalTemplate)<{
  isFromQuickSetup: string | undefined;
}>`
  width: 100%;
  max-width: ${({ isFromQuickSetup }) =>
    isFromQuickSetup ? '600px' : '496px'};
`;

export const StyledModalBodyContent = styled(Body)`
  b {
    font-weight: 500;
  }
`;

export const StyledImage = styled(Image)`
  margin: 16px auto 16px auto;
`;

export const StyledDropdown = styled(Dropdown)`
  div:first-child {
    z-index: 0;
  }
`;

export const StyledInfoAlert = styled(InfoAlert)`
  margin-bottom: 24px;
`;
