import React, { useCallback, useMemo } from 'react';
import shallow from 'zustand/shallow';
import ThemeV2 from '../../../componentsV2/theme';
import { ProfilePopOverProps } from './interfaces';
import { openIntercomMessenger } from '../../../Utils/Intercom';
import ProfileNavHeader from '../ProfileNavHeader';
import Divider from '../../atoms/Divider';
import PrimaryNavLink from '../PrimaryNavLink';
import routesList from '../../../aV2/routes/routesList';
import { SecondaryPanel } from '../../../interfaces/Layout';
import useLayoutStore from '../../../stores/layoutStore';
import {
  generateV2UserProfileRoute,
  V2_GENERAL_SETTINGS,
  V2_MY_REWARDS,
  HELP_CENTER,
  PRODUCT_FEEDBACK,
} from '../../../constants/routes';
import {
  GET_LIVE_SUPPORT,
  GIVE_US_FEEDBACK,
  GO_TO_ADMIN,
  GO_TO_HELP_CENTER,
  VIEW_PROFILE,
  SIGN_OUT,
} from '../../../languages/en/home/profileDrawer';
import { DividerWrapper, ProfilePopoverWrapper } from './styles';
import RewardsPopover from '../RewardsPopover';
import { MY_REWARDS } from '../../../languages/en/rewards';
import { MY_SETTINGS } from '../../../languages/en/settings';
import { ADMIN_APP_URL } from '../../../config';
import { useFeatureSplit } from '../../../hooks/useFeatureSplit';
import {
  SplitNames,
  TreatmentTypes,
} from '../../../hooks/useSplitSdkConfig/constants';

const ProfilePopover = (props: ProfilePopOverProps) => {
  const {
    img,
    onProfilePopoverClick,
    avatarSize,
    userName,
    onlineStatus,
    userId,
    isCurrentUserAdmin,
    hideRewardsLink,
    widthCheck,
    closePopover,
    rewardsProps,
  } = props;

  const { setActiveNavItem, setActiveSecondaryPanel } = useLayoutStore(
    (state) => ({
      setActiveNavItem: state.setActiveNavItem,
      setActiveSecondaryPanel: state.setActiveSecondaryPanel,
    }),
    shallow,
  );

  const { treatment: adminRefactorTreatment } = useFeatureSplit(
    SplitNames.ADMIN_NEW_APP,
  );

  const isAdminTreatmentOn = adminRefactorTreatment === TreatmentTypes.ON;

  const setMySettingsPanel = () => {
    setActiveNavItem(SecondaryPanel.PROFILE);
    setActiveSecondaryPanel(SecondaryPanel.PROFILE);
  };

  const setAssemblyPanel = () => {
    setActiveNavItem(SecondaryPanel.ASSEMBLY);
    setActiveSecondaryPanel(SecondaryPanel.ASSEMBLY);
  };

  const profileRoute = useMemo(
    () => generateV2UserProfileRoute(userId),
    [userId],
  );

  const handleNavClick = useCallback(() => {
    onProfilePopoverClick({ memberID: userId, firstName: userName });
    closePopover();
  }, [closePopover, onProfilePopoverClick, userId, userName]);

  return (
    <ProfilePopoverWrapper>
      <ProfileNavHeader
        img={img}
        avatarSize={avatarSize}
        userName={userName}
        onlineStatus={onlineStatus}
        userId={userId}
      />
      <DividerWrapper>
        <Divider color={ThemeV2.palette.gray4} isFullWidth />
      </DividerWrapper>
      {!widthCheck && (
        <>
          <RewardsPopover
            assemblyCurrency={rewardsProps.assemblyCurrency}
            totMonthlyAllowance={rewardsProps.totMonthlyAllowance}
            remainingMonthlyAllowance={rewardsProps.remainingMonthlyAllowance}
            refreshInfoDate={rewardsProps.refreshInfoDate}
            trophyBalance={rewardsProps.trophyBalance}
            hideRedeemButton={rewardsProps.hideRedeemButton}
            hideAllowanceDetails={rewardsProps.hideAllowanceDetails}
            hideRewardsLink={hideRewardsLink}
            hideRewardsInfo={rewardsProps.hideRewardsInfo}
            redeemButtonClicked={rewardsProps.redeemButtonClicked}
            widthCheck={widthCheck}
            closePopover={closePopover}
            rewardType={rewardsProps.rewardType}
          />
          <DividerWrapper>
            <Divider color={ThemeV2.palette.gray4} isFullWidth />
          </DividerWrapper>
        </>
      )}
      <PrimaryNavLink
        icon="person-filled"
        isActive={false}
        onClick={handleNavClick}
        to={profileRoute}
      >
        {VIEW_PROFILE}
      </PrimaryNavLink>
      {isCurrentUserAdmin && (
        <PrimaryNavLink
          icon="admin-with-gear"
          isActive={false}
          data-testid="adminNavigation"
          onClick={() => {
            closePopover();
            if (isAdminTreatmentOn) {
              window.location.href = `${ADMIN_APP_URL}/settings`;
            }
          }}
          {...(!isAdminTreatmentOn && {
            to: routesList.ADMIN,
          })}
        >
          {GO_TO_ADMIN}
        </PrimaryNavLink>
      )}
      {!hideRewardsLink && (
        <PrimaryNavLink
          to={V2_MY_REWARDS}
          icon="gift"
          isActive={false}
          onClick={() => {
            setAssemblyPanel();
            closePopover();
          }}
        >
          {MY_REWARDS}
        </PrimaryNavLink>
      )}
      <PrimaryNavLink
        icon="settings-2"
        isActive={false}
        onClick={() => {
          setMySettingsPanel();
          closePopover();
        }}
        to={V2_GENERAL_SETTINGS}
        data-testid="mySettings"
      >
        {MY_SETTINGS}
      </PrimaryNavLink>

      <DividerWrapper>
        <Divider color={ThemeV2.palette.gray4} isFullWidth />
      </DividerWrapper>

      <PrimaryNavLink
        icon="question-mark"
        isActive={false}
        to={HELP_CENTER}
        onClick={closePopover}
      >
        {GO_TO_HELP_CENTER}
      </PrimaryNavLink>

      <PrimaryNavLink
        icon="answer"
        isActive={false}
        onClick={() => {
          openIntercomMessenger();
          closePopover();
        }}
      >
        {GET_LIVE_SUPPORT}
      </PrimaryNavLink>

      <PrimaryNavLink
        icon="survey"
        isActive={false}
        to={PRODUCT_FEEDBACK}
        onClick={closePopover}
      >
        {GIVE_US_FEEDBACK}
      </PrimaryNavLink>

      <DividerWrapper>
        <Divider color={ThemeV2.palette.gray4} isFullWidth />
      </DividerWrapper>

      <PrimaryNavLink
        icon="exit"
        to={routesList.SIGN_OUT}
        isActive={false}
        onClick={closePopover}
      >
        {SIGN_OUT}
      </PrimaryNavLink>
    </ProfilePopoverWrapper>
  );
};

export default ProfilePopover;
