import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import TabComponent, {
  TabComponentProps,
} from '../../../atomic/molecules/TabComponent';
import SVGIcon from '../../../atomic/atoms/SVGIcon';

import FlowDetailsController from './FlowDetailsController';
import FilterController from './FilterController';

import { FILTER, ABOUT } from '../../../languages/en/singleWords';
import { CELEBRATE_TEAMMATE_FLOW_ID } from '../../../constants/common';
import {
  StyledWrapper,
  Wrapper,
} from '../../../atomic/organism/RightDrawerFilter/styles';
import useGetParsedFilterOptions from '../../../hooks/useGetParsedFilterOptions';
import { RIGHT_DRAWER_EVENTS } from '../../../Utils/analytics/constants';
import useGetPageName from '../../../hooks/useGetPageName';
import { trackRightDrawerEvent } from '../../../Utils/analytics';

const AboutTabContentWrapper = styled.div`
  max-height: calc(100vh - 124px);
  overflow-y: auto;
`;

const FlowFeedRightDrawerController = () => {
  const { page } = useGetPageName();

  const [isFiltered, setIsFiltered] = useState(false);
  const { isFlowsFeedFilterApplied } = useGetParsedFilterOptions();

  useEffect(() => {
    if (isFlowsFeedFilterApplied) {
      setIsFiltered(true);
    } else {
      setIsFiltered(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onTabClickHandler = useCallback(
    (drawerTabViewed: any) => {
      trackRightDrawerEvent(RIGHT_DRAWER_EVENTS.TAB_CLICKED, {
        drawerTabViewed,
        feedViewed: page,
      });
    },
    [page],
  );

  const { flowId } = useParams<{ flowId: string }>();

  if (flowId === CELEBRATE_TEAMMATE_FLOW_ID) {
    return null;
  }

  const AboutTabContent = (
    <AboutTabContentWrapper>
      <FlowDetailsController />
    </AboutTabContentWrapper>
  );

  const tabComponentProps: TabComponentProps = {
    tabItems: [
      {
        tabHeader: {
          label: <span>{ABOUT}</span>,
          icon: <SVGIcon icon="info-filled" size="16px" />,
        },
        tabContent: AboutTabContent,
      },
      {
        tabHeader: {
          label: <span>{FILTER}</span>,
          icon: <SVGIcon icon="filter" size="16px" />,
        },
        tabContent: (
          <Wrapper>
            <FilterController setIsFiltered={setIsFiltered} isTab />
          </Wrapper>
        ),
        isFiltered,
      },
    ],
    splitEqually: true,
    selectedTabIndex: 0,
    onTabClick: onTabClickHandler,
  };

  return (
    <StyledWrapper>
      <TabComponent {...tabComponentProps} />
    </StyledWrapper>
  );
};

export default FlowFeedRightDrawerController;
