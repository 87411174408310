import uuid from 'uuid';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { IMemberDTO } from '../../../../interfaces/member';
import { useMembersSearch } from '../../../../hooks/useMembersSearch';
import { useFetchMembersDetailsQuery } from '../../../../queries/MembersDetails';
import { useGetAllDepartmentsQuery } from '../../../../queries/FlowBuilder/Participants';
import { AutocompleteDropdownItem } from '../../../../atomic/organism/Autocomplete/interfaces';
import { SelectedCriteriaProps } from '../../../../atomic/organism/FlowParticipationBuilderOptionsContent/types';
import { popoverItems } from '../../FlowsBuilderParticipantsController/useFlowsBuilderParticipantController/data';

import ThemeV2 from '../../../../componentsV2/theme';

import { defaultPopoverItems } from '../utils';

import {
  CriteriaField,
  CriteriaGroup,
  CriteriaGroups,
  CriteriaOperator,
  CriteriaCondition,
} from '../../../../interfaces/Flow/Builder';

import {
  filterByUniqueCriteria,
  getPreExistingMemberIDs,
} from '../../../../Utils/flows/builder/utils';
import { FLOW_BUILDER_EVENTS } from '../../../../Utils/analytics/constants';
import { useProfileInfoFetchQuery } from '../../../../queries/Profile';
import {
  canInviteMembers,
  getCanShowParticipationCriteriaEveryone,
  getIsInviteRequiresApproval,
} from '../../../../Utils/permissions';
import { trackFlowBuilderActionEvent } from '../../../../Utils/analytics/flowsBuilder';
import { MemberState } from '../../../../interfaces/user';
import { useFeatureSplit } from '../../../../hooks/useFeatureSplit';
import {
  SplitNames,
  TreatmentTypes,
} from '../../../../hooks/useSplitSdkConfig/constants';
import useFlowBuilderStore from '../../../../stores/flowBuilderStore';
import {
  flowNameSelector,
  templateIdSelector,
  templateNameSelector,
} from '../../../../stores/flowBuilderStore/selectors';

const attributeLabelForAnalytics = {
  [FLOW_BUILDER_EVENTS.PARTICIPANTS_SELECTED]: 'participantCriteriaSelected',
  [FLOW_BUILDER_EVENTS.VIEWERS_SELECTED]: 'viewerCriteriaSelected',
};

const useFlowsBuilderFilterCriteriaController = (
  hideEveryone: boolean,
  criteriaGroups: CriteriaGroups | undefined,
  onCriteriaGroupsChange: (value: CriteriaGroups | undefined) => void,
  from: string,
) => {
  const templateId = useFlowBuilderStore(templateIdSelector);
  const templateName = useFlowBuilderStore(templateNameSelector);
  const flowName = useFlowBuilderStore(flowNameSelector);

  let memberIds: string[] = [];
  const [selectedGroupId, setSelectedGroupId] = useState<string>();
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [isEveryOneSelected, setIsEveryOneSelected] = useState(false);

  const isCriteriaPopoverOpen = Boolean(anchorEl);
  const popoverId = isCriteriaPopoverOpen ? 'material-popover' : undefined;

  const paramLabel = useMemo(() => {
    switch (from) {
      case 'visibility':
        return FLOW_BUILDER_EVENTS.VIEWERS_SELECTED;
      case 'participation':
      case 'personSelector':
      default:
        return FLOW_BUILDER_EVENTS.PARTICIPANTS_SELECTED;
    }
  }, [from]);

  const {
    models: {
      hasMoreMembers,
      searchedMembers,
      isLoading: isMembersSearchLoading,
      isFetching: isMembersSearchFetching,
    },
    operations: { onChange: onMembersSearchChange, fetchMoreMembers },
  } = useMembersSearch(true, 20);

  const { data: profileData } = useProfileInfoFetchQuery();

  const canShowParticipationCriteriaEveryone =
    getCanShowParticipationCriteriaEveryone(profileData);

  const { treatment: inviteUserOnFlowCreationTreatment } = useFeatureSplit(
    SplitNames.INVITE_USER_ON_FLOW_CREATION,
  );

  const isInviteUserOnFlowCreationEnabled =
    inviteUserOnFlowCreationTreatment !== TreatmentTypes.OFF;

  const isInviteRequiresApproval = getIsInviteRequiresApproval(profileData);

  const canInviteMembersOnFlowBuilder =
    isInviteUserOnFlowCreationEnabled &&
    canInviteMembers(profileData?.member, profileData?.assembly.settings) &&
    !isInviteRequiresApproval;

  const {
    models: {
      hasMoreMembers: hasMoreEmails,
      searchedMembers: searchedMembersByEmail,
      isLoading: isMembersSearchByEmailLoading,
      isFetching: isMembersSearchByEmailFetching,
    },
    operations: {
      fetchMoreMembers: fetchMoreEmails,
      onChange: onMembersSearchByEmailChange,
    },
  } = useMembersSearch(true, 20, 'email');

  criteriaGroups?.groups.forEach((group) => {
    group.groupRules.forEach((rule) => {
      if (rule.field === 'member') {
        rule.value.forEach((val) => {
          if (val.id === val.value) {
            memberIds = [...memberIds, val.id];
          }
        });
      }
    });
  });

  const { data } = useFetchMembersDetailsQuery(
    memberIds,
    memberIds && memberIds.length > 0,
  );

  const popoverMenuItems = useMemo(() => {
    if (hideEveryone || !canShowParticipationCriteriaEveryone) {
      return popoverItems;
    }

    return defaultPopoverItems;
  }, [canShowParticipationCriteriaEveryone, hideEveryone]);

  useEffect(() => {
    setIsEveryOneSelected(
      Boolean(
        criteriaGroups?.groups.length === 1 &&
          criteriaGroups?.groups[0].groupRules.length === 1 &&
          criteriaGroups?.groups[0].groupRules[0].field === 'everyone',
      ),
    );

    criteriaGroups?.groups.forEach((group) => {
      group.groupRules.forEach((rule) => {
        if (rule.field === 'member') {
          rule.value.forEach((val) => {
            if (val.id === val.value) {
              const memberDetails = data?.membersDetails.find(
                (x) => x.memberId === val.id,
              );
              if (memberDetails) {
                const { firstName, lastName } = memberDetails.profile;
                // eslint-disable-next-line no-param-reassign
                val.value = `${firstName} ${lastName}`;
              }
            }
          });
        }
      });
    });
  }, [data, criteriaGroups, setIsEveryOneSelected, onCriteriaGroupsChange]);

  useEffect(() => {
    if (criteriaGroups) {
      onCriteriaGroupsChange({ ...criteriaGroups });
    }
  }, [data]);

  const getParticipantMembers: () => AutocompleteDropdownItem<string>[] =
    useMemo(() => {
      return () => {
        const preExistingIDs = getPreExistingMemberIDs(
          criteriaGroups?.groups || [],
          selectedGroupId || '',
        );
        if (searchedMembers) {
          return searchedMembers
            .filter(
              (member) => !(preExistingIDs || []).includes(member.memberID),
            )
            .map((member) => {
              return {
                title: `${member.firstName} ${member.lastName}`,
                id: member.memberID,
                avatar: {
                  img: member.image || '',
                  userId: member.memberID,
                  name: `${member.firstName} ${member.lastName}`,
                  icon:
                    member.memberState === MemberState.PENDING
                      ? 'pending-person'
                      : '',
                },
                memberState: member.memberState,
              };
            });
        }
        return [];
      };
    }, [searchedMembers, criteriaGroups, selectedGroupId]);

  const getParticipantsMembersByEmail: () => AutocompleteDropdownItem<string>[] =
    useMemo(() => {
      return () => {
        const preExistingIDs = getPreExistingMemberIDs(
          criteriaGroups?.groups || [],
          selectedGroupId || '',
        );
        return searchedMembersByEmail
          ? searchedMembersByEmail
              ?.filter(
                (member) => !(preExistingIDs || []).includes(member.memberID),
              )
              ?.map((memberData: IMemberDTO) => {
                return {
                  title: memberData.email || '',
                  id: memberData.memberID,
                  avatar: {
                    img: memberData.image || '',
                    userId: memberData.memberID,
                    name: `${memberData.firstName} ${memberData.lastName}`,
                    icon:
                      memberData.memberState === MemberState.PENDING
                        ? 'pending-person'
                        : '',
                    iconBgColor: 'transparent',
                    iconColor: ThemeV2.palette.gray9,
                  },
                };
              })
          : [];
      };
    }, [searchedMembersByEmail, criteriaGroups, selectedGroupId]);

  const { data: departments, isLoading: isDepartmentsLoading } =
    useGetAllDepartmentsQuery();

  const participationDepartments: AutocompleteDropdownItem<string>[] =
    useMemo(() => {
      if (departments) {
        return departments.data.map((department: string) => {
          return {
            title: department,
            id: department,
          };
        });
      }
      return [];
    }, [departments]);

  const getParticipationCriteriaActiveSlideId = (itemId?: string) => {
    if (itemId === defaultPopoverItems[0].id) {
      setAnchorEl(null);
    }
  };

  const handleGroupConditionChange = useCallback(
    (groupId: string, value: CriteriaCondition) => {
      if (!criteriaGroups) {
        return;
      }

      const currentCriteriaGroups = criteriaGroups;
      const criteriaToBeUpdated = criteriaGroups.groups.find(
        (x) => x.groupId === groupId,
      );

      if (criteriaToBeUpdated) {
        criteriaToBeUpdated.groupCondition = value;
        const index = criteriaGroups.groups.findIndex(
          (x) => x.groupId === groupId,
        );

        currentCriteriaGroups.groups[index] = { ...criteriaToBeUpdated };
        onCriteriaGroupsChange({ ...currentCriteriaGroups });
      }
    },
    [criteriaGroups, onCriteriaGroupsChange],
  );

  const handleCriteriaGroupConditionChange = useCallback(
    (value: CriteriaCondition) => {
      if (!criteriaGroups) {
        return;
      }
      onCriteriaGroupsChange({ ...criteriaGroups, groupsCondition: value });
    },
    [criteriaGroups, onCriteriaGroupsChange],
  );

  const handleOnEveryOneSelected = useCallback(() => {
    setAnchorEl(null);
    trackFlowBuilderActionEvent({
      action: paramLabel,
      [attributeLabelForAnalytics[paramLabel]]: ['everyone'],
      templateId,
      templateName,
      flowTitle: flowName,
    });
    const groups: CriteriaGroup[] = [
      {
        groupId: uuid.v4(),
        groupCondition: 'and',
        groupRules: [
          {
            value: [{ id: 'everyone', value: 'everyone' }],
            operator: 'is',
            field: 'everyone',
            ruleId: uuid.v4(),
          },
        ],
      },
    ];
    const newCriteriaGroups: CriteriaGroups = {
      groups,
      groupsCondition: 'and',
    };

    onCriteriaGroupsChange(newCriteriaGroups);
  }, [onCriteriaGroupsChange, paramLabel, templateId, templateName]);

  const handleOnGroupChange = useCallback(
    (
      groupId: string | undefined,
      selectedCriteriaProps: SelectedCriteriaProps<string>,
    ) => {
      const addNewCriteriaGroup = () => {
        const groups: CriteriaGroup[] = [
          // Default for adding more than 1 criteria in the same group should be “Participant matches any”
          {
            groupId: uuid.v4(),
            groupCondition: 'or',
            groupRules: [
              {
                value: selectedCriteriaProps.value.map((x) => ({
                  id: x.id,
                  value: x.title,
                  memberState: x.memberState,
                })),
                operator: selectedCriteriaProps.operator as CriteriaOperator,
                field: selectedCriteriaProps.field as CriteriaField,
                ruleId: uuid.v4(),
              },
            ],
          },
        ];
        const newCriteriaGroups: CriteriaGroups = {
          groups,
          groupsCondition: 'or',
        };

        if (!criteriaGroups) {
          onCriteriaGroupsChange(newCriteriaGroups);
        } else {
          const criteriaGroupToBeUpdated = criteriaGroups;
          criteriaGroupToBeUpdated.groups = [
            ...criteriaGroupToBeUpdated.groups,
            ...groups,
          ];
          onCriteriaGroupsChange({ ...criteriaGroupToBeUpdated });
        }
      };

      if (!groupId || !criteriaGroups) {
        addNewCriteriaGroup();
      } else {
        const currentCriteriaGroups = criteriaGroups;
        const criteriaToBeUpdated = criteriaGroups.groups.find(
          (x) => x.groupId === groupId,
        );
        const uniqueValues = filterByUniqueCriteria(
          criteriaGroups.groups,
          selectedCriteriaProps.value,
        );

        if (criteriaToBeUpdated && uniqueValues.length > 0) {
          criteriaToBeUpdated.groupRules = [
            ...criteriaToBeUpdated.groupRules,
            {
              value: uniqueValues.map((x) => ({
                id: x.id,
                value: x.title,
                memberState: x.memberState,
              })),
              operator: selectedCriteriaProps.operator as CriteriaOperator,
              field: selectedCriteriaProps.field as CriteriaField,
              ruleId: uuid.v4(),
            },
          ];
          const index = criteriaGroups.groups.findIndex(
            (x) => x.groupId === groupId,
          );

          currentCriteriaGroups.groups[index] = { ...criteriaToBeUpdated };
          onCriteriaGroupsChange({ ...currentCriteriaGroups });
        }
      }
    },
    [criteriaGroups, onCriteriaGroupsChange],
  );

  const handleRemoveRuleClick = useCallback(
    (ruleId: string, criteriaValue: string) => {
      if (criteriaValue === 'everyone') {
        setIsEveryOneSelected(false);
        onCriteriaGroupsChange(undefined);
        return;
      }

      const currentCriteriaGroups = criteriaGroups;

      if (currentCriteriaGroups) {
        const criteriaToBeUpdated = currentCriteriaGroups.groups.find((x) =>
          x.groupRules.some((y) => y.ruleId === ruleId),
        );

        if (criteriaToBeUpdated) {
          const index = currentCriteriaGroups.groups.findIndex(
            (x) => x.groupId === criteriaToBeUpdated.groupId,
          );

          const selectedGroupRule = currentCriteriaGroups.groups[
            index
          ].groupRules.find((x) => x.ruleId === ruleId);

          const selectedGroupRuleIndex = currentCriteriaGroups.groups[
            index
          ].groupRules.findIndex((x) => x.ruleId === ruleId);

          if (
            selectedGroupRule &&
            selectedGroupRule.value &&
            selectedGroupRule.value.length > 1
          ) {
            currentCriteriaGroups.groups[index].groupRules[
              selectedGroupRuleIndex
            ].value = currentCriteriaGroups.groups[index].groupRules[
              selectedGroupRuleIndex
            ].value.filter((x) => x.id !== criteriaValue);

            onCriteriaGroupsChange({ ...currentCriteriaGroups });
          } else {
            currentCriteriaGroups.groups[index].groupRules =
              currentCriteriaGroups.groups[index].groupRules.filter(
                (x) => x.ruleId !== ruleId,
              );
          }
          onCriteriaGroupsChange({ ...currentCriteriaGroups });
        }

        const groupIdToBeRemoved = currentCriteriaGroups.groups.find(
          (x) => x.groupRules.length === 0,
        )?.groupId;

        if (groupIdToBeRemoved) {
          currentCriteriaGroups.groups = currentCriteriaGroups.groups.filter(
            (x) => x.groupId !== groupIdToBeRemoved,
          );
          onCriteriaGroupsChange({ ...currentCriteriaGroups });
        }
      }
    },
    [criteriaGroups, onCriteriaGroupsChange],
  );

  const handleOnMemberAddClick = useCallback(
    (selectedCriteria: SelectedCriteriaProps<string>) => {
      handleOnGroupChange(selectedGroupId, selectedCriteria);
      if (selectedCriteria) {
        trackFlowBuilderActionEvent({
          action: paramLabel,
          [attributeLabelForAnalytics[paramLabel]]: [
            `${selectedCriteria.field} ${selectedCriteria.operator}`,
          ],
          templateId,
          templateName,
          flowTitle: flowName,
        });
      }
      setAnchorEl(null);
    },
    [
      handleOnGroupChange,
      paramLabel,
      selectedGroupId,
      templateId,
      templateName,
      flowName,
    ],
  );

  const handleOnDepartmentAddClick = useCallback(
    (selectedCriteria: SelectedCriteriaProps<string>) => {
      handleOnGroupChange(selectedGroupId, selectedCriteria);
      if (selectedCriteria) {
        trackFlowBuilderActionEvent({
          action: paramLabel,
          [attributeLabelForAnalytics[paramLabel]]: [
            `${selectedCriteria.field} ${selectedCriteria.operator}`,
          ],
          templateId,
          templateName,
          flowTitle: flowName,
        });
      }
      setAnchorEl(null);
    },
    [
      handleOnGroupChange,
      paramLabel,
      selectedGroupId,
      templateId,
      templateName,
      flowName,
    ],
  );

  const handleOnCriteriaMemberOptionsScroll = useCallback(async () => {
    if (hasMoreMembers && !isMembersSearchFetching) {
      await fetchMoreMembers();
    }
  }, [fetchMoreMembers, hasMoreMembers, isMembersSearchFetching]);

  const handleOnCriteriaEmailOptionsScroll = useCallback(async () => {
    if (hasMoreEmails && !isMembersSearchByEmailFetching) {
      await fetchMoreEmails();
    }
  }, [fetchMoreEmails, hasMoreEmails, isMembersSearchByEmailFetching]);

  return {
    models: {
      anchorEl,
      popoverId,
      departments,
      hasMoreEmails,
      hasMoreMembers,
      selectedGroupId,
      popoverMenuItems,
      isEveryOneSelected,
      isDepartmentsLoading,
      isCriteriaPopoverOpen,
      isMembersSearchLoading,
      isMembersSearchFetching,
      participationDepartments,
      canInviteMembersOnFlowBuilder,
      isMembersSearchByEmailLoading,
      isMembersSearchByEmailFetching,
    },
    operations: {
      setAnchorEl,
      fetchMoreEmails,
      fetchMoreMembers,
      setSelectedGroupId,
      handleOnGroupChange,
      setIsEveryOneSelected,
      handleRemoveRuleClick,
      onMembersSearchChange,
      handleOnEveryOneSelected,
      handleGroupConditionChange,
      onMembersSearchByEmailChange,
      getParticipantMembers,
      getParticipantsMembersByEmail,
      handleCriteriaGroupConditionChange,
      getParticipationCriteriaActiveSlideId,
      onMemberAddClick: handleOnMemberAddClick,
      onDepartmentAddClick: handleOnDepartmentAddClick,
      onCriteriaEmailOptionsScroll: handleOnCriteriaEmailOptionsScroll,
      onCriteriaMemberOptionsScroll: handleOnCriteriaMemberOptionsScroll,
    },
  };
};

export default useFlowsBuilderFilterCriteriaController;
