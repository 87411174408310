import React, { useCallback, useState, memo } from 'react';

import Body from '../../atoms/Body';
import Button from '../../atoms/Button';
import FileCard from '../../molecules/FileCard';
import FilePreviewModal from '../../molecules/FilePreviewModal';
import BlockScale from '../../molecules/BlockScale';
import GifContainer from '../../molecules/GifContainer';
import ResponseBlock from '../../molecules/ResponseBlock';
import { FLOW_POST_MAX_HEIGHT } from '../../../Utils/constants';
import { Flex } from '../../../Utils/styles/display';

import { useFeatureSplit } from '../../../hooks/useFeatureSplit';
import FlowBlockGiveTrophies from '../../molecules/FlowBlockGiveTrophies';
import FlowBlockPersonSelection from '../../molecules/FlowBlockPersonSelection';
import OpenEndedFlowsResponseBlock from '../../molecules/OpenEndedFlowsResponseBlock';

import {
  SplitNames,
  TreatmentTypes,
} from '../../../hooks/useSplitSdkConfig/constants';

import {
  BlockWrapper,
  FlowPostBodyContainer,
  FlowPostBodyWrapper,
  SeeFullPostFlexWrapper,
  StyledBody,
  StyledMultipleChoiceText,
} from './styles';

import {
  BlockProps,
  DropDownBlockType,
  FileUploadBlockType,
  FlowPostBodyProps,
  GifBlockType,
  GiveTrophiesBlockType,
  NoResponseBlockType,
  OpenEndedBlockType,
  PersonSelectorBlockType,
  ScaleBlockType,
} from './types';

import { File } from '../../molecules/FileCard/types';

const GifBlock = (props: BlockProps) => {
  const { content } = props;
  const gifBlockProps = content as GifBlockType;

  return (
    <ResponseBlock label={gifBlockProps.title}>
      <GifContainer src={gifBlockProps.src} lazyload />
    </ResponseBlock>
  );
};

const ScaleBlock = (props: BlockProps) => {
  const { content } = props;
  return (
    <BlockScale
      type="normal"
      isReadOnly
      label={content.title}
      {...(content as ScaleBlockType)}
    />
  );
};

const TrophiesBlock = (props: BlockProps) => {
  const { content } = props;
  return <FlowBlockGiveTrophies {...(content as GiveTrophiesBlockType)} />;
};

/*
   https://gist.github.com/markmcgowan/4ae4b897bc3db402fa5e0710a0369348
*/

const OpenEndedBlock = (props: BlockProps) => {
  const {
    content,
    onDownloadFileClick,
    id,
    fileToPreview,
    flow,
    person,
    createdAt,
    visibility,
    handleSetFilePreview,
    responseId,
    isAnonymous = false,
  } = props;

  const openEndedBlockProps = content as OpenEndedBlockType;

  const [modalOpen, setModalOpen] = useState(false);
  const [fileInformation, setFileInformation] = useState<File | undefined>();

  const { treatment: filePreviewerTreatment } = useFeatureSplit(
    SplitNames.FILE_PREVIEWER,
  );

  const doesBlockHaveFile =
    onDownloadFileClick &&
    handleSetFilePreview &&
    openEndedBlockProps?.files &&
    openEndedBlockProps.files[0] &&
    filePreviewerTreatment === TreatmentTypes.ON;

  return (
    <>
      <OpenEndedFlowsResponseBlock
        blockId={id}
        {...(content as OpenEndedBlockType)}
        responseId={responseId}
        onDownloadFileClick={onDownloadFileClick}
        onClickPreview={(previewFile) => {
          if (
            handleSetFilePreview &&
            filePreviewerTreatment === TreatmentTypes.ON
          ) {
            setModalOpen(true);
            setFileInformation(previewFile);
            handleSetFilePreview({
              id: props.id,
              fileName: previewFile.name,
              responseId: responseId,
            });
          }
        }}
      />
      {doesBlockHaveFile && (
        <FilePreviewModal
          flow={flow}
          person={person}
          createdAt={createdAt}
          visibility={visibility}
          fileInformation={fileInformation}
          fileToPreview={fileToPreview}
          isOpen={modalOpen}
          onClickDownload={(fileToDownload) =>
            onDownloadFileClick({
              id: props.id,
              fileName: fileToDownload.name,
              responseId: responseId,
            })
          }
          onCloseModal={() => {
            setModalOpen(false);
            setFileInformation(undefined);
            handleSetFilePreview(undefined);
          }}
          isAnonymous={isAnonymous}
        />
      )}
    </>
  );
};

const FileUploadBlock = (props: BlockProps) => {
  const {
    content,
    onDownloadFileClick,
    fileToPreview,
    flow,
    person,
    createdAt,
    visibility,
    handleSetFilePreview,
    responseId,
    isAnonymous = false,
  } = props;
  //This is just a multipurpose isExpanded, so I can use this A: as a way to determine if the modal should be open
  //or not and B to know which file in the array to open the preview for.
  const fileUploadBlockProps = content as FileUploadBlockType;

  const [modalOpen, setModalOpen] = useState(false);
  const [fileInformation, setFileInformation] = useState<File | undefined>();

  const { treatment: filePreviewerTreatment } = useFeatureSplit(
    SplitNames.FILE_PREVIEWER,
  );

  if (!onDownloadFileClick || !handleSetFilePreview) {
    return null;
  }

  return (
    <ResponseBlock label={fileUploadBlockProps.title}>
      <Flex flexWrap="wrap" gap="16px" margin="8px 0 0 0">
        {fileUploadBlockProps.files?.map((file) => (
          <FileCard
            key={file.name}
            flowId={flow?.flowId}
            responseId={responseId}
            file={file}
            status={fileUploadBlockProps.status}
            onClickDownload={(fileToDownload) =>
              onDownloadFileClick({
                id: props.id,
                fileName: fileToDownload.name,
                responseId: responseId,
              })
            }
            isParticipation={fileUploadBlockProps.isParticipation}
            onClickPreview={(previewFile) => {
              if (filePreviewerTreatment === TreatmentTypes.ON) {
                setModalOpen(true);
                setFileInformation(previewFile);
                handleSetFilePreview({
                  id: props.id,
                  fileName: previewFile.name,
                  responseId: responseId,
                });
              }
            }}
          />
        ))}
      </Flex>
      {filePreviewerTreatment === TreatmentTypes.ON && (
        <FilePreviewModal
          flow={flow}
          person={person}
          createdAt={createdAt}
          visibility={visibility}
          fileInformation={fileInformation}
          fileToPreview={fileToPreview}
          isOpen={modalOpen}
          onClickDownload={(fileToDownload) =>
            onDownloadFileClick({
              id: props.id,
              fileName: fileToDownload.name,
              responseId: responseId,
            })
          }
          onCloseModal={() => {
            setModalOpen(false);
            setFileInformation(undefined);
            handleSetFilePreview(undefined);
          }}
          isAnonymous={isAnonymous}
        />
      )}
    </ResponseBlock>
  );
};

const PersonSelectorBlock = (props: BlockProps) => {
  const { content, onMemberClick } = props;

  return (
    <FlowBlockPersonSelection
      {...(content as PersonSelectorBlockType)}
      onMemberClick={onMemberClick}
    />
  );
};

const MultiChoiceSelectBlock = (props: BlockProps) => {
  const { content } = props;
  const multiChoiceSelectBlockProps = content as DropDownBlockType;

  return (
    <ResponseBlock label={multiChoiceSelectBlockProps.title}>
      {multiChoiceSelectBlockProps.value.map((val) => (
        <StyledMultipleChoiceText variant="body1" key={val}>
          {val}
        </StyledMultipleChoiceText>
      ))}
    </ResponseBlock>
  );
};

const NoResponseBlock = (props: BlockProps) => {
  const { content } = props;
  const noResponseBlockProps = content as NoResponseBlockType;

  return (
    <div>
      <StyledBody variant="body3" color="gray8">
        {noResponseBlockProps.title}
      </StyledBody>
      <Body variant="body1" color="gray6">
        {noResponseBlockProps.value}
      </Body>
    </div>
  );
};

const FlowPostBody = (props: FlowPostBodyProps) => {
  const {
    blocks,
    responseId,
    onMemberClick,
    onSeeFullPostClick,
    onDownloadFileClick,
    flow,
    person,
    createdAt,
    visibility,
    fileToPreview,
    handleSetFilePreview,
    isAnonymous = false,
  } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  const [flowPostBodyHeight, setFlowPostBodyHeight] = useState(0);

  const handleSeeFullPostClick = useCallback(() => {
    if (onSeeFullPostClick) {
      onSeeFullPostClick();
    }

    setIsExpanded(true);
  }, [onSeeFullPostClick]);

  const flowPostBodyRef = useCallback((node) => {
    if (node != null) {
      setFlowPostBodyHeight(node.getBoundingClientRect().height);
    }
  }, []);

  return (
    <FlowPostBodyContainer>
      <FlowPostBodyWrapper isExpanded={isExpanded} ref={flowPostBodyRef}>
        {blocks
          .map((block) => {
            switch (block.type) {
              case 'SCALE':
                return <ScaleBlock key={block.id} {...block} />;
              case 'PERSON_SELECTOR':
                return (
                  <PersonSelectorBlock
                    key={block.id}
                    {...block}
                    onMemberClick={onMemberClick}
                  />
                );
              case 'GIVE_TROPHIES': {
                return <TrophiesBlock key={block.id} {...block} />;
              }
              case 'OPEN_ENDED':
                return (
                  <OpenEndedBlock
                    responseId={responseId}
                    key={block.id}
                    {...block}
                    onDownloadFileClick={onDownloadFileClick}
                    fileToPreview={fileToPreview}
                    flow={flow}
                    person={person}
                    createdAt={createdAt}
                    visibility={visibility}
                    handleSetFilePreview={handleSetFilePreview}
                    isAnonymous={isAnonymous}
                  />
                );
              case 'DROPDOWN':
              case 'MULTI_CHOICE':
                return <MultiChoiceSelectBlock key={block.id} {...block} />;
              case 'FILE_UPLOAD':
                return (
                  <FileUploadBlock
                    key={block.id}
                    responseId={responseId}
                    {...block}
                    onDownloadFileClick={onDownloadFileClick}
                    fileToPreview={fileToPreview}
                    flow={flow}
                    person={person}
                    createdAt={createdAt}
                    visibility={visibility}
                    handleSetFilePreview={handleSetFilePreview}
                    isAnonymous={isAnonymous}
                  />
                );
              case 'GIF_SELECTOR':
                return <GifBlock key={block.id} {...block} />;
              case 'NO_RESPONSE':
                return <NoResponseBlock key={block.id} {...block} />;
              default:
                throw Error('Invalid Type');
            }
          })
          .map((block) => (
            <BlockWrapper key={block.key}>{block}</BlockWrapper>
          ))}
      </FlowPostBodyWrapper>
      {flowPostBodyHeight >= FLOW_POST_MAX_HEIGHT && !isExpanded && (
        <SeeFullPostFlexWrapper justifyContent="center" margin="16px 0 21px 0">
          <Button
            onClick={handleSeeFullPostClick}
            icon="arrow-thick-down"
            size="small"
            variant="text"
          >
            See full post
          </Button>
        </SeeFullPostFlexWrapper>
      )}
    </FlowPostBodyContainer>
  );
};

export default memo(FlowPostBody);
