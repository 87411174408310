import React, { useCallback } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FeedsContainer, ShowMoreButton } from '../MainFeedController/styles';

import { Flex } from '../../../Utils/styles/display';
import { feedSortItems } from '../MainFeedController/data';
import Dropdown from '../../../atomic/molecules/Dropdown_V2';

import {
  DropdownColor,
  DropdownPlacement,
  DropdownSizes,
  DropdownVariants,
} from '../../../atomic/molecules/Dropdown_V2/interfaces';
import FeedSearchBarController from '../FeedSearchBarController';
import useRecognitionController from './useRecognitionController';
import {
  CELEBRATE_TEAM_MEMBER,
  SEE_NEW_POSTS,
} from '../../../languages/en/feed';
import RecognitionFlowController from '../RecognitionFlowController';
import FlowsFeedPostLoader from '../../flows/FlowsFeedController/Loader';
import { PostSortValues } from '../../../interfaces/Feed';
import BottomOfFeedMessage from '../../../atomic/molecules/BottomOfFeedMessage';

import ActionBarContainer from '../../../atomic/molecules/ActionBarContainer';
import { FeedTypes } from '../../../atomic/molecules/ActionBar/types';
import { ANSWER } from '../../../languages/en/singleWords';
import { StyledActionBarWrapper } from '../../flows/FlowsFeedController/styles';
import FlowPostController from '../../flows/FlowPostController';

import searching from '../../../img/searching.svg';
import EmptyErrorStateTemplate from '../../../atomic/molecules/EmptyErrorStateTemplate';
import { HeadingType } from '../../../atomic/molecules/EmptyErrorStateTemplate/interfaces';
import { FLOW_FEED_STATES } from '../../../languages/en/flows/feed';
import DummyFlowFeedPost from '../../../atomic/organism/DummyFlowFeedPost';
import {
  celebrateTeamMateDummyPostData,
  celebrateTeammateHeaderProps,
} from '../../../atomic/organism/DummyFlowFeedPost/data';

const RecognitionController = () => {
  const { models, operations } = useRecognitionController();

  const {
    data,
    isLoading,
    hasNextPage,
    profileData,
    feedsSortValue,
    searchFeedValue,
    isFilterApplied,
    showSearchResults,
    showLoadMoreButton,
    isRecognitionFlowModalOpen,
    canCurrentUserGiveRecognition,
  } = models;

  const {
    fetchNextPage,
    onMemberClick,
    onSearchChange,
    handleFeedSortClick,
    setShowLoadMoreButtonToFalse,
    handleCelebrateTeammateClick,
    toggleIsRecognitionFlowModalOpen,
    onReactionSet,
    onReactionUnSet,
    refetchFeedQuery,
  } = operations;

  const actionBarText = `${ANSWER} 🎉 ${CELEBRATE_TEAM_MEMBER}`;

  const onLoadMoreClick = useCallback(() => {
    if (refetchFeedQuery) {
      document.getElementById('scrollableFeedContent')?.scroll({
        top: 0,
        behavior: 'smooth',
      });
      refetchFeedQuery();
      setShowLoadMoreButtonToFalse();
    }
  }, [refetchFeedQuery, setShowLoadMoreButtonToFalse]);

  const isEmpty = Boolean(data && data.length === 0 && !isLoading);
  const canShowDummyPost = isEmpty && !isFilterApplied;
  const canShowEmptySearchResult = isEmpty && isFilterApplied;

  if (profileData) {
    celebrateTeamMateDummyPostData.bodyProps.blocks[0] = {
      id: '1',
      type: 'PERSON_SELECTOR',
      content: {
        title: 'Who would you like to celebrate?',
        persons: [
          {
            id: '6081484184d8987b291e40b3',
            label: `${profileData.member.profile.firstName} ${profileData.member.profile.lastName}`,
            avatar: {
              img: profileData.member.profile.image,
              userId: profileData.member.memberId,
              name: profileData.member.profile.username,
            },
          },
        ],
      },
    };
  }

  return (
    <FeedsContainer>
      <section>
        <FeedSearchBarController
          searchValue={searchFeedValue}
          onSearchChange={onSearchChange}
        />

        {canCurrentUserGiveRecognition && profileData && (
          <StyledActionBarWrapper>
            <ActionBarContainer
              avatar={profileData.member.profile.image}
              userName={profileData.member.profile.username}
              userId={profileData.member.memberId}
              feedType={FeedTypes.Flow}
              text={actionBarText}
              onClick={handleCelebrateTeammateClick}
            />
          </StyledActionBarWrapper>
        )}

        {isRecognitionFlowModalOpen && profileData && (
          <RecognitionFlowController
            profileInfo={profileData}
            toggleIsRecognitionFlowModalOpen={toggleIsRecognitionFlowModalOpen}
          />
        )}
      </section>

      {data && data.length > 0 && (
        <Flex justifyContent="space-between" margin="0 auto 4px">
          <div />
          <div>
            <Dropdown
              menuItems={feedSortItems}
              size={DropdownSizes.Small}
              variant={DropdownVariants.Text}
              color={DropdownColor.Secondary}
              value={PostSortValues[feedsSortValue]}
              dropdownPlacement={DropdownPlacement.BottomEnd}
              onItemClick={(val) => handleFeedSortClick(val)}
            />
          </div>
        </Flex>
      )}
      {isLoading && <FlowsFeedPostLoader />}
      {canShowDummyPost && profileData && (
        <DummyFlowFeedPost
          currentUser={profileData.member}
          headerProps={celebrateTeammateHeaderProps}
          {...celebrateTeamMateDummyPostData}
        />
      )}
      {canShowEmptySearchResult && (
        <EmptyErrorStateTemplate
          image={searching}
          variant={HeadingType.Bold}
          heading={FLOW_FEED_STATES.NO_MATCHING_POSTS.HEADING}
          subHeading={FLOW_FEED_STATES.NO_MATCHING_POSTS.SUB_HEADING}
        />
      )}
      {data && data.length > 0 && (
        <InfiniteScroll
          next={fetchNextPage}
          dataLength={data.length}
          loader={<FlowsFeedPostLoader />}
          hasMore={Boolean(hasNextPage)}
          style={{ overflow: 'inherit' }}
          endMessage={<BottomOfFeedMessage />}
          scrollableTarget="scrollableFeedContent"
        >
          {!showSearchResults && showLoadMoreButton && (
            <ShowMoreButton
              size="small"
              variant="showMore"
              onClick={onLoadMoreClick}
              icon="arrow-thick-up"
            >
              {SEE_NEW_POSTS}
            </ShowMoreButton>
          )}
          {data.map((feed) => (
            <FlowPostController
              key={feed.key}
              post={feed}
              onReactionSet={onReactionSet}
              onReactionUnSet={onReactionUnSet}
              onMemberClick={onMemberClick}
              profileData={profileData}
            />
          ))}
        </InfiniteScroll>
      )}
    </FeedsContainer>
  );
};

export default RecognitionController;
