import React from 'react';
import BlockScale from '../../../molecules/BlockScale';
import FormsEditorBaseInputBlock from '../FormsEditorInputBaseBlock';

import { BlockScaleLabelProps } from '../../../molecules/BlockScale/type';

import {
  ContentScaleBlockState,
  ContentBlockLocalProperties,
  ScaleBlockBuilderSettingState,
} from '../../../../interfaces/Flow/Builder';
import {
  ADD_LEFT_LABEL,
  ADD_MIDDLE_LABEL,
  ADD_RIGHT_LABEL,
} from '../../../../languages/en/flows/builder';

const LABEL_ONE = 'labelInputOne';
const LABEL_TWO = 'labelInputTwo';
const LABEL_THREE = 'labelInputThree';

export interface FormsEditorScaleInputBlockProps
  extends ContentBlockLocalProperties {
  blockData: ContentScaleBlockState;
}

const FormsEditorScaleInputBlock = ({
  blockData,
  currentIndex,
  handleToggleIsDescriptionNeeded,
  handleToggleIsRequired,
  onValueChange,
  totalNumberOfItems,
  onDeleteClick,
  onMoveDownClick,
  onMoveUpClick,
}: FormsEditorScaleInputBlockProps) => {
  const {
    labelInputOne,
    labelInputTwo,
    labelInputThree,
    isRequired,
    minimumRange,
    maximumRange,
    description,
    assemblyCurrency,
  } = blockData;

  const handleInputChange = (newValue: string, label: string) => {
    if (!onValueChange) {
      return;
    }

    if (label === LABEL_ONE) {
      onValueChange({ ...blockData, labelInputOne: newValue });
    }

    if (label === LABEL_TWO) {
      onValueChange({ ...blockData, labelInputTwo: newValue });
    }

    if (label === LABEL_THREE) {
      onValueChange({ ...blockData, labelInputThree: newValue });
    }
  };

  const handleOnRangeChange = (minRange: number, maxRange: number) => {
    if (onValueChange) {
      onValueChange({
        ...blockData,
        minimumRange: minRange || minimumRange,
        maximumRange: maxRange || maximumRange,
      });
    }
  };

  const blockSettingState: ScaleBlockBuilderSettingState = {
    type: 'SCALE',
    disableRequiredToggle: false,
    maximumRange,
    minimumRange,
    onRangeChange: handleOnRangeChange,
    toggleIsRequired: handleToggleIsRequired,
    isRequired,
    isDescriptionNeeded: description !== null,
    toggleDescriptionNeeded: handleToggleIsDescriptionNeeded,
  };

  const labelInputOneProps: BlockScaleLabelProps = {
    value: labelInputOne,
    placeholder: ADD_LEFT_LABEL,
    onChange: (e) => {
      handleInputChange(e.target.value, LABEL_ONE);
    },
  };

  const labelInputTwoProps: BlockScaleLabelProps = {
    value: labelInputTwo,
    placeholder: ADD_MIDDLE_LABEL,
    onChange: (e) => {
      handleInputChange(e.target.value, LABEL_TWO);
    },
  };

  const labelInputThreeProps: BlockScaleLabelProps = {
    value: labelInputThree,
    placeholder: ADD_RIGHT_LABEL,
    onChange: (e) => {
      handleInputChange(e.target.value, LABEL_THREE);
    },
  };

  return (
    <FormsEditorBaseInputBlock
      blockData={blockData}
      onDeleteClick={onDeleteClick}
      onMoveDownClick={onMoveDownClick}
      onMoveUpClick={onMoveUpClick}
      onValueChange={onValueChange}
      currentIndex={currentIndex !== undefined ? currentIndex : 0}
      assemblyCurrency={assemblyCurrency}
      totalNumberOfItems={totalNumberOfItems}
      blockSettingState={blockSettingState}
    >
      <BlockScale
        isReadOnly
        value={null}
        type="editable"
        max={maximumRange}
        min={minimumRange}
        labelInputOne={labelInputOneProps}
        labelInputTwo={labelInputTwoProps}
        labelInputThree={labelInputThreeProps}
      />
    </FormsEditorBaseInputBlock>
  );
};

export default FormsEditorScaleInputBlock;
