import { Limit } from './types';
import { MULTIPLE_CHOICE_MULTI_SELECT } from '../../../../languages/en/flows/participation';

const generateSubDescriptionForRange = (
  min: number,
  max: number,
  optionsLength: number,
) => {
  const {
    chooseAtLeastMinOptions,
    chooseBetweenMinAndMaxOptions,
    chooseUpToMaxOptions,
  } = MULTIPLE_CHOICE_MULTI_SELECT;

  if (min === 0) {
    return chooseUpToMaxOptions(max);
  }
  if (max === optionsLength) {
    return chooseAtLeastMinOptions(min);
  }

  return chooseBetweenMinAndMaxOptions(min, max);
};

export const generateSubDescription = (limit: Limit, optionsLength: number) => {
  const { CHOOSE_AS_MANY_OPTIONS_AS_YOUD_LIKE, chooseExactlyNOptions } =
    MULTIPLE_CHOICE_MULTI_SELECT;
  if (limit.noLimit) {
    return CHOOSE_AS_MANY_OPTIONS_AS_YOUD_LIKE;
  }
  if (limit.exact) {
    return chooseExactlyNOptions(limit.exact);
  }
  if (limit.range) {
    const { min, max } = limit.range;
    return generateSubDescriptionForRange(min, max, optionsLength);
  }
  return undefined;
};
