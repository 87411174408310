import React from 'react';
import styled from 'styled-components';
import { HeaderCard } from '../../../controllers/admin/rewards/SwagsController/styles';
import SVGIcon from '../../atoms/SVGIcon';
import Body from '../../atoms/Body';
import { REWARDS_SWAGS } from '../../../languages/en/admin';
import UpgradeButton from '../../atoms/Button';
import {
  REWARD_NAVIGATE_TO_ADMIN_SWAG_SETTINGS,
  UPGRADE_BUTTON_TEXT,
  REWARD_EMPTY_SWAG_TITLE,
  REWARD_EMPTY_SWAG_DESCRIPTION,
  REWARD_UPGRAGE_SWAG_TITLE,
  REWARD_UPGRADE_SWAG_DESCRIPTION,
  RE_CONNECT_AXOMO,
} from '../../../languages/en/rewards';
import ThemeV2 from '../../../componentsV2/theme';
import { Flex } from '../../../Utils/styles/display';
import Button from '../../atoms/Button';
import InfoAlert from '../InfoAlert';

interface EmptySwagCardProps {
  upgradeRequired: boolean;
  onGotoAdminSwagSettingsClick: () => void;
  hasError?: boolean;
}

const StyledEmptySwagCard = styled(Flex)`
  max-width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 25px;
`;

const StyledEmptySwagNote = styled(Flex)`
  max-width: 310px;
  flex-direction: column;
  align-items: center;
  gap: 5px;
`;

const StyledDescriptionWrapper = styled.div`
  width: calc(100% - 50px);
`;

const StyledInfoAlert = styled(InfoAlert)`
  margin: 48px auto;
  max-width: 550px;
  width: 100%;
`;

const EmptyAndErrorSwagsDisplayCard = (props: EmptySwagCardProps) => {
  const { upgradeRequired, onGotoAdminSwagSettingsClick, hasError } = props;

  return (
    <>
      {hasError && (
        <StyledInfoAlert
          id="swagsErrorAlert"
          text={RE_CONNECT_AXOMO}
          alertType="custom"
          icon="warning-hollow"
          customVariant={{
            color: ThemeV2.palette.gray9,
            iconColor: ThemeV2.palette.dustRed6,
            background: ThemeV2.palette.dustRed2,
          }}
        />
      )}
      <StyledEmptySwagCard>
        <HeaderCard>
          <SVGIcon icon="admin-assembly-axomo-logos" size="30px" />
          <Body
            variant="body2"
            color={ThemeV2.palette.gray8}
            textAlign="center"
          >
            {REWARDS_SWAGS.HEADER_CARD_TEXT}
          </Body>
        </HeaderCard>

        <StyledEmptySwagNote>
          {upgradeRequired ? (
            <>
              <Body variant="body1Bold">{REWARD_UPGRAGE_SWAG_TITLE}</Body>
              <StyledDescriptionWrapper>
                <Body variant="body2" margin="0 0 17px 0;" textAlign="center">
                  {REWARD_UPGRADE_SWAG_DESCRIPTION}
                </Body>
                <UpgradeButton
                  status="info"
                  icon="crown-solid"
                  isFullWidth={true}
                >
                  {UPGRADE_BUTTON_TEXT}
                </UpgradeButton>
              </StyledDescriptionWrapper>
            </>
          ) : (
            <>
              <Body variant="body1Bold">{REWARD_EMPTY_SWAG_TITLE}</Body>
              <StyledDescriptionWrapper>
                <Body variant="body2" margin="0 0 17px 0;" textAlign="center">
                  {REWARD_EMPTY_SWAG_DESCRIPTION}
                </Body>
                <Button
                  color="primary"
                  isFullWidth={true}
                  onClick={onGotoAdminSwagSettingsClick}
                >
                  {REWARD_NAVIGATE_TO_ADMIN_SWAG_SETTINGS}
                </Button>
              </StyledDescriptionWrapper>
            </>
          )}
        </StyledEmptySwagNote>
      </StyledEmptySwagCard>
    </>
  );
};

export default EmptyAndErrorSwagsDisplayCard;
