import React, { useCallback } from 'react';

import Button from '../../../atomic/atoms/Button';
import Dropdown from '../../../atomic/molecules/Dropdown_V2';
import CriteriaItem from '../../../atomic/molecules/CriteriaItem';
import PopoverWithSubMenu from '../../../atomic/organism/PopoverWithSubMenu';

import { FlowsBuilderFilterCriteriaControllerProps } from './types';

import { capitalizeFirstLetter } from '../../../Utils/text';
import { ENTIRE_ORGANIZATION } from '../../../languages/en';
import { AND, OR } from '../../../languages/en/singleWords';
import { ADD_MORE_CRITERIA_LABEL } from '../../../languages/en/flows/builder';
import useFlowsBuilderFilterCriteriaController from './useFlowsBuilderFilterCriteriaController';
import { optionItems } from '../FlowsBuilderParticipantsController/useFlowsBuilderParticipantController/data';
import FlowParticipationBuilderOptionsContent from '../../../atomic/organism/FlowParticipationBuilderOptionsContent';

import {
  DropdownColor,
  DropdownVariants,
} from '../../../atomic/molecules/Dropdown_V2/interfaces';

import {
  getCriteriaValue,
  getCriteriaCondition,
  getCriteriaFieldIcon,
} from './utils';

import {
  AddMoreCriteriaButton,
  FilterCriteriaWrapper,
  FilterCriteriaContainer,
  AddMoreCriteriaContainer,
  StyledPopoverWithSubMenu,
  GroupCriteriaConditionDropdown,
} from './styles';

import {
  CriteriaCondition,
  CriteriaRule,
} from '../../../interfaces/Flow/Builder';

import useFlowBuilderStore from '../../../stores/flowBuilderStore';
import { pendingEmailsSelector } from '../../../stores/flowBuilderStore/selectors';
import { MemberState } from '../../../interfaces/user';

const OR_CONDITION = OR.toLowerCase() as CriteriaCondition;
const AND_CONDITION = AND.toLowerCase() as CriteriaCondition;

const FlowsBuilderFilterCriteriaController = (
  props: FlowsBuilderFilterCriteriaControllerProps,
) => {
  const {
    criteriaLabel,
    criteriaGroups,
    criteriaMenuItems,
    hideEveryone = false,
    onCriteriaGroupsChange,
    criteriaConditionItems,
    from,
  } = props;

  const { models, operations } = useFlowsBuilderFilterCriteriaController(
    hideEveryone,
    criteriaGroups,
    onCriteriaGroupsChange,
    from,
  );

  const pendingEmails = useFlowBuilderStore(pendingEmailsSelector);

  const {
    anchorEl,
    popoverId,
    popoverMenuItems,
    isEveryOneSelected,
    isDepartmentsLoading,
    isCriteriaPopoverOpen,
    isMembersSearchLoading,
    isMembersSearchFetching,
    participationDepartments,
    canInviteMembersOnFlowBuilder,
    isMembersSearchByEmailLoading,
    isMembersSearchByEmailFetching,
  } = models;

  const {
    setAnchorEl,
    onMemberAddClick,
    setSelectedGroupId,
    onDepartmentAddClick,
    setIsEveryOneSelected,
    onMembersSearchChange,
    getParticipantMembers,
    handleRemoveRuleClick,
    handleOnEveryOneSelected,
    handleGroupConditionChange,
    onCriteriaEmailOptionsScroll,
    onMembersSearchByEmailChange,
    onCriteriaMemberOptionsScroll,
    getParticipantsMembersByEmail,
    handleCriteriaGroupConditionChange,
  } = operations;

  const renderSlideContent = useCallback(
    (id: string) => {
      switch (id) {
        case 'everyone': {
          if (!isEveryOneSelected) {
            handleOnEveryOneSelected();
            setIsEveryOneSelected(true);
          }

          return null;
        }

        case 'member':
          return (
            <FlowParticipationBuilderOptionsContent
              selectedOptionId={id}
              radioOptions={optionItems}
              onAddButtonClick={onMemberAddClick}
              onInputChange={onMembersSearchChange}
              autocompleteItems={getParticipantMembers()}
              onOptionsScroll={onCriteriaMemberOptionsScroll}
              isLoading={isMembersSearchLoading || isMembersSearchFetching}
            />
          );

        case 'email': {
          const isFetching =
            isMembersSearchByEmailFetching || isMembersSearchByEmailLoading;

          return (
            <FlowParticipationBuilderOptionsContent
              selectedOptionId={id}
              isLoading={isFetching}
              radioOptions={optionItems}
              onAddButtonClick={onMemberAddClick}
              onInputChange={onMembersSearchByEmailChange}
              onOptionsScroll={onCriteriaEmailOptionsScroll}
              autocompleteItems={getParticipantsMembersByEmail()}
              isInviteUserOnFlowCreationEnabled={canInviteMembersOnFlowBuilder}
            />
          );
        }

        case 'department':
          return (
            <FlowParticipationBuilderOptionsContent
              selectedOptionId={id}
              radioOptions={optionItems}
              autocompleteItems={participationDepartments}
              onAddButtonClick={onDepartmentAddClick}
              isLoading={isDepartmentsLoading}
            />
          );

        default:
          return null;
      }
    },
    [
      onMemberAddClick,
      onMembersSearchChange,
      getParticipantMembers,
      onCriteriaMemberOptionsScroll,
      isMembersSearchLoading,
      isMembersSearchFetching,
      participationDepartments,
      onDepartmentAddClick,
      isDepartmentsLoading,
      isEveryOneSelected,
      handleOnEveryOneSelected,
      setIsEveryOneSelected,
      isMembersSearchByEmailFetching,
      isMembersSearchByEmailLoading,
      onMembersSearchByEmailChange,
      onCriteriaEmailOptionsScroll,
      getParticipantsMembersByEmail,
    ],
  );

  const getMembersState = (email: string) => {
    if (pendingEmails.includes(email)) {
      return MemberState.PENDING;
    }

    return undefined;
  };

  return (
    <>
      {criteriaGroups &&
        criteriaGroups.groups.length > 0 &&
        criteriaGroups.groups.map((criteriaItem, criteriaIndex) => {
          return (
            <FilterCriteriaWrapper key={criteriaItem.groupId}>
              <FilterCriteriaContainer
                key={criteriaItem.groupId}
                itemsLength={criteriaItem.groupRules.length}
                isEveryOneSelected={isEveryOneSelected}
              >
                {criteriaItem.groupRules
                  .map((y) => y.value)
                  .flat()
                  .flat().length > 1 && (
                  <Dropdown
                    isFullWidth
                    onItemClick={(value) =>
                      handleGroupConditionChange(
                        criteriaItem.groupId,
                        value.toString().includes('any')
                          ? OR_CONDITION
                          : AND_CONDITION,
                      )
                    }
                    menuItems={criteriaMenuItems}
                    color={DropdownColor.Secondary2}
                    value={
                      criteriaItem.groupCondition === AND_CONDITION
                        ? criteriaMenuItems[0].items[1].value
                        : criteriaMenuItems[0].items[0].value
                    }
                  />
                )}
                {criteriaItem.groupRules.map((criteriaRule: CriteriaRule) =>
                  criteriaRule.value.map((criteriaValue) => (
                    <CriteriaItem
                      data-testid="CriteriaItem-1"
                      isLoading={
                        criteriaValue.id === criteriaValue.value &&
                        criteriaRule.field === 'member'
                      }
                      key={criteriaValue.id}
                      id={criteriaRule.ruleId}
                      criteriaValue={getCriteriaValue(
                        criteriaRule,
                        criteriaValue,
                      )}
                      onDismissClick={(ruleId) =>
                        handleRemoveRuleClick(ruleId, criteriaValue.id)
                      }
                      icon={getCriteriaFieldIcon(criteriaRule.field)}
                      criteria={
                        criteriaRule.field !== 'everyone'
                          ? capitalizeFirstLetter(criteriaRule.field)
                          : ENTIRE_ORGANIZATION
                      }
                      criteriaCondition={
                        criteriaRule.field !== 'everyone'
                          ? getCriteriaCondition(criteriaRule.operator)
                          : ''
                      }
                      memberState={
                        criteriaValue.memberState ||
                        getMembersState(
                          getCriteriaValue(criteriaRule, criteriaValue),
                        )
                      }
                    />
                  )),
                )}

                {criteriaItem.groupRules
                  .map((x) => x.value)
                  .flat()
                  .flat().length > 0 &&
                  !isEveryOneSelected && (
                    <AddMoreCriteriaContainer>
                      <StyledPopoverWithSubMenu
                        id={popoverId}
                        isOpen={isCriteriaPopoverOpen}
                        anchorEl={anchorEl}
                        handleClose={() => {
                          setAnchorEl(null);
                        }}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                        transformOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                        popoverItems={popoverMenuItems}
                        referenceContent={
                          <AddMoreCriteriaButton
                            aria-describedby={popoverId}
                            variant="text"
                            icon="union-rounded"
                            color="secondary2"
                            onClick={(event) => {
                              setAnchorEl(event.currentTarget);
                              setSelectedGroupId(criteriaItem.groupId);
                            }}
                          >
                            {ADD_MORE_CRITERIA_LABEL}
                          </AddMoreCriteriaButton>
                        }
                        slideContent={renderSlideContent}
                      />
                    </AddMoreCriteriaContainer>
                  )}
              </FilterCriteriaContainer>
              {criteriaGroups.groups.length > 1 &&
                criteriaIndex !== criteriaGroups.groups.length - 1 && (
                  <GroupCriteriaConditionDropdown
                    onItemClick={(value) =>
                      handleCriteriaGroupConditionChange(
                        value.toString().toLowerCase().includes(OR_CONDITION)
                          ? OR_CONDITION
                          : AND_CONDITION,
                      )
                    }
                    menuItems={criteriaConditionItems}
                    variant={DropdownVariants.Text}
                    color={DropdownColor.Secondary2}
                    value={
                      criteriaGroups.groupsCondition === OR_CONDITION
                        ? criteriaConditionItems[0].items[0].value
                        : criteriaConditionItems[0].items[1].value
                    }
                  />
                )}
            </FilterCriteriaWrapper>
          );
        })}

      <PopoverWithSubMenu
        id={popoverId}
        isOpen={isCriteriaPopoverOpen}
        anchorEl={anchorEl}
        handleClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        popoverItems={popoverMenuItems}
        referenceContent={
          <Button
            variant="text"
            icon="union-rounded"
            aria-describedby={popoverId}
            disabled={isEveryOneSelected}
            onClick={(event) => {
              setAnchorEl(event.currentTarget);
              setSelectedGroupId(undefined);
            }}
          >
            {criteriaLabel}
          </Button>
        }
        slideContent={renderSlideContent}
      />
    </>
  );
};

export default FlowsBuilderFilterCriteriaController;
