import { MenuItemProps } from '../Dropdown_V2/interfaces';

export enum MultiSelectDropdownType {
  Birthday = 'birthday',
  StartDate = 'startDate',
}

export interface MultiSelectDropdownValues {
  fieldOneValue: string;
  fieldTwoValue: string;
  fieldThreeValue?: string;
}
export interface SettingsMultiSelectDropdownProps {
  multiSelectDropDownType: MultiSelectDropdownType;
  initialValues: MultiSelectDropdownValues;
  isDisabled: boolean;
  monthDropDownItems: MenuItemProps[];
  dayDropDownItems: MenuItemProps[];
  yearDropDownItems?: MenuItemProps[];
  onSaveChanges: (dateValues: MultiSelectDropdownValues) => void;
}
